import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { subUser } from 'src/models/pages/subUserModel';
import { API } from 'src/util/api';
import { ManufacturerType } from './actionTpes';
import { createManufacturerError, createManufacturerSucess, deleteManufacturerError, deleteManufacturerProfileError, deleteManufacturerProfileSuccess, deleteManufacturerSuccess, getManufacturerByIdError, getManufacturerByIdSuccess, getManufacturerListError, getManufacturerListSuccess, getManufacturerProfileError, getManufacturerProfileSuccess, ManufacturerLatLagError, ManufacturerLatLagSuccess, manufacturerVehicleBrandListError, manufacturerVehicleBrandListSuccess, resendOtpManufacturerError, resendOtpManufacturerSuccess, sendManufacturerOtpError, sendManufacturerOtpSuccess, setManufacturerEmailIdError, setManufacturerEmailIdSuccess, setManufacturerMobileNumberError, setManufacturerMobileNumberSuccess, updateManufacturerError, updateManufacturerSuccess, uploadManufacturerProfileError, uploadManufacturerProfileSuccess } from './action';


//worker function

//getFranchiseeList

function* getManufacturerListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getManufacturerListAPI, { ...action.lazyState })
    yield put(getManufacturerListSuccess(response))
  }
  catch (error) {
    yield put(getManufacturerListError(error))
  }
}


//create Franchisee
function* createManufacturerAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.createManufacturerApi, action.payload);
    yield put(createManufacturerSucess(response))
  }
  catch (error) {
    yield put(createManufacturerError(error))
  }
}

//get franchisee details by id

function* getInduvidualSubuser(action: any) {
  try {
    const response: Promise<any> = yield call(API.getManufacturerDetailsApi, action.payload)
    yield put(getManufacturerByIdSuccess(response))
  } catch (error) {
    yield put(getManufacturerByIdError(error))
  }
}

//update franchisee 

function* updateSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.updateManufacturerApi, action.payload, action.id);
    yield put(updateManufacturerSuccess(response))
  } catch (error) {
    yield put(updateManufacturerError(error))
  }
}

//delete franchisee

function* deleteManufacturerAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteManufacturerApi, { id: action.payload.id, undo:action.payload.undo });
    yield put(deleteManufacturerSuccess(response,action.payload.undo));
  } catch (error) {
    yield put(deleteManufacturerError(error))
  }
}

function* getManufacturerProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.fetchManufacturerImage, action.id);
    yield put(getManufacturerProfileSuccess(response));
  } catch (error) {
    yield put(getManufacturerProfileError(error))
  }
}

function* uploadFranchiseSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.uploadManufacturerImage, action.payload,action.id);
    yield put(uploadManufacturerProfileSuccess(response));
  } catch (error) {
    yield put(uploadManufacturerProfileError(error))
  }
}

function* deleteManufacturerProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteManufacturerImage, action.id);
    yield put(deleteManufacturerProfileSuccess(response));
  } catch (error) {
    yield put(deleteManufacturerProfileError(error))
  }
}

function* setFranchiseSubuserMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setManufacturerMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setManufacturerMobileNumberError(error))
  }
}

function* setFranchiseSubuserEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setFranchiseSubuserEmailApi, action.payload.id,action.payload.email);
    yield put(setManufacturerEmailIdSuccess(response))
  } catch (error) {
    yield put(setManufacturerEmailIdError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendManufacturerOtpSuccess(response))
  } catch (error) {
    yield put(sendManufacturerOtpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpManufacturerSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpManufacturerError(error))
  }
}

function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(ManufacturerLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(ManufacturerLatLagError(error));
  }
}

function* getManufacturerVahicleBrandListAsync() {

  try {
    const response: Promise<any> = yield call(API.getJobPrefixforManufacturer)
    yield put(manufacturerVehicleBrandListSuccess(response));
  } catch (error) {
    yield put(manufacturerVehicleBrandListError(error));
  }
}
//watcher function

function* ManufacturerSaga() {
  yield takeLatest(ManufacturerType.GET_MANUFACTURER_LIST_LOADING, getManufacturerListAsync);
  yield takeLatest(ManufacturerType.CREATE_MANUFACTURER_LOADING, createManufacturerAsync);
  yield takeEvery(ManufacturerType.GET_MANUFACTURERBYID_LOADING, getInduvidualSubuser);
  yield takeLatest(ManufacturerType.UPDATE_MANUFACTURER_LOADING, updateSubuserAsync);
  yield takeLatest(ManufacturerType.DELETE_MANUFACTURER_LOADING, deleteManufacturerAsync)
  yield takeLatest(ManufacturerType.UPLOAD_MANUFACTURER_PROFILE_LOADING, uploadFranchiseSubuserProfileAsync)
  yield takeLatest(ManufacturerType.DELETE_MANUFACTURER_PROFILE_LOADING, deleteManufacturerProfileAsync)
  yield takeLatest(ManufacturerType.GET_MANUFACTURER_PROFILE_LOADING, getManufacturerProfileAsync)
  yield takeLatest(ManufacturerType.MANUFACTURER_SET_EMAIL_LOADING, setFranchiseSubuserEmailAsync)
  yield takeLatest(ManufacturerType.MANUFACTURER_SET_PHONE_LOADING, setFranchiseSubuserMobileAsync)
  yield takeLatest(ManufacturerType.SEND_OTP_MANUFACTURER, sendOtpAsync)
  yield takeLatest(ManufacturerType.RESEND_MANUFACTURER_OTP, resendOtpAsync)
  yield takeLatest(ManufacturerType.GET_MANUFACTURER_LOCATION_LOADING, getlatLngAsync)
  yield takeLatest(ManufacturerType.GET_VEHICLE_BRAND_LIST, getManufacturerVahicleBrandListAsync)
}

export default ManufacturerSaga