export enum PartsTypes {

  // Grid
  GET_PARTS_LIST = "@@parts/GET_PARTS_LIST",
  GET_PARTS_LIST_SUCCESS = "@@parts/GET_PARTS_LIST_SUCCESS",
  GET_PARTS_LIST_FAIL = "@@parts/GET_PARTS_LIST_FAIL",

  DELETE_PARTS = "@@parts/DELETE_PARTS_MODELS",
  DELETE_PARTS_SUCCESS = "@@parts/DELETE_PARTS_SUCCESS",
  DELETE_PARTS_FAIL = "@@parts/DELETE_PARTS_FAIL",
  
  //filter
  FILTER_PAGE_LOADING = "@@parts/FILTER_PAGE_LOADING",
  FILTER_PAGE_SUCCESS = "@@parts/FILTER_PAGE_SUCCESS",
  FILTER_PAGE_ERROR = "@@parts/FILTER_PAGE_ERROR",

  RESET_PARTS = "@@parts/RESET_PARTS",
  CLEAR_PARTS_MESSAGE = "@@parts/CLEAR_PARTS_MESSAGE",


  UPDATE_PARTS = "@@parts/UPDATE_PARTS",
  UPDATE_PARTS_SUCCESS = "@@parts/UPDATE_PARTS_SUCCESS",
  UPDATE_PARTS_FAIL = "@@parts/UPDATE_PARTS_FAIL",

  //POST

  POST_PARTS = "@@parts/POST_PARTS",
  POST_PARTS_SUCCESS = "@@parts/POST_PARTS_SUCCESS",
  POST_PARTS_FAIL = "@@parts/POST_PARTS_FAIL",

  //GET

  GET_PARTS_BY_ID = "@@parts/GET_PARTS_BY_ID",
  GET_PARTS_BY_ID_SUCCESS = "@@parts/GET_PARTS_BY_ID_SUCCESS",
  GET_PARTS_BY_ID_FAIL = "@@parts/GET_PARTS_BY_ID_FAIL",

  //GET AUDIO 
  GET_AUDIO_BRANDS_LIST_FOR_PARTS = "@@parts/GET_AUDIO_BRANDS_LIST_FOR_PARTS",
  GET_AUDIO_BRANDS_LIST_FOR_PARTS_SUCCESS = "@@parts/GET_AUDIO_BRANDS_LIST_FOR_PARTS_SUCCESS",
  GET_AUDIO_BRANDS_LIST_FOR_PARTS_FAIL = "@@parts/GET_AUDIO_BRANDS_LIST_FOR_PARTS_FAIL",

  //GET_PURCHASE_ORDER
  PARTS_VIEW_PURCHASE_ORDER_DETAILS_LOADING = "@@parts/PARTS_VIEW_PURCHASE_ORDER_DETAILS_LOADING",
  PARTS_VIEW_PURCHASE_ORDER_DETAILS_SUCCESS = "@@parts/PARTS_VIEW_PURCHASE_ORDER_DETAILS_SUCCESS",
  PARTS_VIEW_PURCHASE_ORDER_DETAILS_ERROR = "@@parts/PARTS_VIEW_PURCHASE_ORDER_DETAILS_ERROR",

  //GET-PARTS-INBOUND
   PARTS_VIEW_PARTS_INBOUND_LOADING="@@parts/PARTS_VIEW_PARTS_INBOUND_LOADING",
   PARTS_VIEW_PARTS_INBOUND_SUCCESS="@@parts/PARTS_VIEW_PARTS_INBOUND_SUCCESS",
   PARTS_VIEW_PARTS_INBOUND_ERROR="@@parts/PARTS_VIEW_PARTS_INBOUND_ERROR",

}
