import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { StockLocationTypes } from "./actionTypes";
import { API } from "src/util/api";
import {  deleteStockLocationFromListError, deleteStockLocationFromListSuccess, getStockLocationListError, getStockLocationListSuccess, createStockLocationSuccess, createStockLocationError, getStockLocationBYIDSuccess, getStockLocationBYIDError, updateStockLocationError, updateStockLocationSuccess } from "./action";
import { stocklocation } from "src/models/pages/stockLocationModel";

function* getStockLocationListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getStockLocationListAPI,  {
        ...action.lazyState,
      }
    )
    yield put(getStockLocationListSuccess(response));
  } catch (error) {
    yield put(getStockLocationListError(error));
  }
}

function* deleteStockLocationAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deleteStockLocationListAPI, { id: action.payload.id, lazyEvent: JSON.stringify(action) }
    )
    yield put(deleteStockLocationFromListSuccess(response))
  } catch (error) {
    yield put(deleteStockLocationFromListError(error));
  }
}

// create stocklocation
function* createStockLocationAsync({ payload }: any) {
  try {
    const response:  stocklocation = yield call(API.postStockLocationAPI, payload);
    yield put(createStockLocationSuccess(response));
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createStockLocationError(error));
  }
}

//  update stocklocation
function* updateStockLocation({
  payload: { stocklocation, stocklocationId },
}: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updateStockLocationAPI, stocklocation, stocklocationId);
    yield put(updateStockLocationSuccess(data));

  } catch (error) {
    yield put(updateStockLocationError(error));
  }
}

function* fetchStockLocation({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getStockLocationByIdAPI, payload);
    yield put(getStockLocationBYIDSuccess(data));
  } catch (error) {
    yield put(getStockLocationBYIDError(error));
  }
}


function* stockLocationSaga() {
  yield takeLatest(StockLocationTypes.GET_STOCKLOCATION_LIST, getStockLocationListAsync);
  yield takeLatest(StockLocationTypes.DELETE_STOCKLOCATION, deleteStockLocationAsync);
  yield takeLatest(StockLocationTypes.POST_STOCKLOCATION, createStockLocationAsync);
  yield takeLatest(StockLocationTypes.GET_STOCKLOCATION_BY_ID, fetchStockLocation);
  yield takeLatest(StockLocationTypes.UPDATE_STOCKLOCATION, updateStockLocation);
}

export default stockLocationSaga;