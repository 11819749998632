export enum ManufacturerType {

    //Grid
    GET_MANUFACTURER_LIST_LOADING = "@@subuser/GET_MANUFACTURER_LIST_LOADING",
    GET_MANUFACTURER_LIST_SUCCESS = "@@subuser/GET_MANUFACTURER_LIST_SUCCESS",
    GET_MANUFACTURER_LIST_ERROR = "@@subuser/GET_MANUFACTURER_LIST_ERROR",

    //Delete Grid
    DELETE_MANUFACTURER_LOADING = "@@subuser/DELETE_MANUFACTURER_LOADING",
    DELETE_MANUFACTURER_SUCCESS = "@@subuser/DELETE_MANUFACTURER_SUCCESS",
    DELETE_MANUFACTURER_ERROR = "@@subuser/DELETE_MANUFACTURER_ERROR",

    //reset franchisee data form
    CLEAR_MANUFACTURER_MESSAGE = "@@subuser/CLEAR_MANUFACTURER_MESSAGE",
    RESET_MANUFACTURER = "@@subuser/RESET_MANUFACTURER",

    //Create Franchisee
    CREATE_MANUFACTURER_LOADING = "@@subuser/CREATE_MANUFACTURER_LOADING",
    CREATE_MANUFACTURER_SUCCESS = "@@subuser/CREATE_MANUFACTURER_SUCCESS",
    CREATE_MANUFACTURER_ERROR = "@@subuser/CREATE_MANUFACTURER_ERROR",

    //Update Franchisee
    UPDATE_MANUFACTURER_LOADING = "@@subuser/UPDATE_MANUFACTURER_LOADING",
    UPDATE_MANUFACTURER_SUCCESS = "@@subuser/UPDATE_MANUFACTURER_SUCCESS",
    UPDATE_MANUFACTURER_ERROR = "@@subuser/UPDATE_MANUFACTURER_ERROR",

    //getFranchise details by id
    GET_MANUFACTURERBYID_LOADING = "@@subuser/GET_MANUFACTURERBYID_LOADING",
    GET_MANUFACTURERBYID_SUCCESS = "@@subuser/GET_MANUFACTURERBYID_SUCCESS",
    GET_MANUFACTURERID_ERROR = "@@subuser/GET_MANUFACTURERID_ERROR",

    
    GET_MANUFACTURER_PROFILE_LOADING = "@@subuser/GET_MANUFACTURER_PROFILE_LOADING",
    GET_MANUFACTURER_PROFILE_SUCCESS = "@@subuser/GET_MANUFACTURER_PROFILE_SUCCESS",
    GET_MANUFACTURER_PROFILE_ERROR = "@@subuser/GET_MANUFACTURER_PROFILE_ERROR",

    UPLOAD_MANUFACTURER_PROFILE_LOADING = "@@subuser/UPLOAD_MANUFACTURER_PROFILE_LOADING",
    UPLOAD_MANUFACTURER_PROFILE_SUCCESS = "@@subuser/UPLOAD_MANUFACTURER_PROFILE_SUCCESS",
    UPLOAD_MANUFACTURER_PROFILE_ERROR = "@@subuser/UPLOAD_MANUFACTURER_PROFILE_ERROR",

    DELETE_MANUFACTURER_PROFILE_LOADING = "@@subuser/DELETE_MANUFACTURER_PROFILE_LOADING",
    DELETE_MANUFACTURER_PROFILE_SUCCESS = "@@subuser/DELETE_MANUFACTURER_PROFILE_SUCCESS",
    DELETE_MANUFACTURER_PROFILE_ERROR = "@@subuser/DELETE_MANUFACTURER_PROFILE_ERROR",
    
    MANUFACTURER_SET_PHONE_LOADING="@@subuser/MANUFACTURER_SET_PHONE_LOADING",
    MANUFACTURER_SET_PHONE_SUCCESS="@@subuser/MANUFACTURER_SET_PHONE_SUCCESS",
    MANUFACTURER_SET_PHONE_ERROR="@@subuser/MANUFACTURER_SET_PHONE_ERROR",

    MANUFACTURER_SET_EMAIL_LOADING="@@subuser/MANUFACTURER_SET_EMAIL_LOADING",
    MANUFACTURER_SET_EMAIL_SUCCESS="@@subuser/MANUFACTURER_SET_EMAIL_SUCCESS",
    MANUFACTURER_SET_EMAIL_ERROR="@@subuser/MANUFACTURER_SET_EMAIL_ERROR",

    SEND_OTP_MANUFACTURER ="@@subuser/SEND_OTP_MANUFACTURER",
    SEND_OTP_MANUFACTURER_SUCCESS ="@@subuser/SEND_OTP_MANUFACTURER_SUCCESS",
    SEND_OTP_MANUFACTURER_ERROR ="@@subuser/SEND_OTP_MANUFACTURER_ERROR",

    RESEND_MANUFACTURER_OTP = "@@subuser/RESEND_MANUFACTURER_OTP",
    RESEND_MANUFACTURER_OTP_SUCCESS = "@@subuser/RESEND_MANUFACTURER_OTP_SUCCESS",
    RESEND_MANUFACTURER_OTP_ERROR = "@@subuser/RESEND_MANUFACTURER_OTP_ERROR",
    
    RESET_MANUFACTURER_OTP_MESSAGE = "@@subuser/RESET_MANUFACTURER_OTP_MESSAGE",

    GET_MANUFACTURER_LOCATION_LOADING="@@subuser/GET_MANUFACTURER_LOCATION_LOADING",
    GET_MANUFACTURER_LOCATION_SUCCESS="@@subuser/GET_MANUFACTURER_LOCATION_SUCCESS",
    GET_MANUFACTURER_LOCATION_ERROR="@@subuser/GET_MANUFACTURER_LOCATION_ERROR",

    GET_VEHICLE_BRAND_LIST="@@subuser/GET_VEHICLE_BRAND_LIST",
    GET_VEHICLE_BRAND_LIST_SUCCESS="@@subuser/GET_VEHICLE_BRAND_LIST_SUCCESS",
    GET_VEHICLE_BRAND_LIST_ERROR="@@subuser/GET_VEHICLE_BRAND_LIST_ERROR",
}