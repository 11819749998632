export enum StockLocationTypes {

    // Grid
    GET_STOCKLOCATION_LIST = "@@stockLocation/GET_STOCKLOCATION_LIST",
    GET_STOCKLOCATION_LIST_SUCCESS = "@@stockLocation/GET_STOCKLOCATION_LIST_SUCCESS",
    GET_STOCKLOCATION_LIST_FAIL = "@@stockLocation/GET_STOCKLOCATION_LIST_FAIL",

    //Delete
    DELETE_STOCKLOCATION = "@@stockLocation/DELETE_STOCKLOCATION_MODELS",
    DELETE_STOCKLOCATION_SUCCESS = "@@stockLocation/DELETE_STOCKLOCATION_SUCCESS",
    DELETE_STOCKLOCATION_FAIL = "@@stockLocation/DELETE_STOCKLOCATION_FAIL",

    //filter
    FILTER_PAGE_LOADING = "@@stockLocation/FILTER_PAGE_LOADING",
    FILTER_PAGE_SUCCESS = "@@stockLocation/FILTER_PAGE_SUCCESS",
    FILTER_PAGE_ERROR = "@@stockLocation/FILTER_PAGE_ERROR",

    //RESET
    RESET_STOCKLOCATION = "@@stockLocation/RESET_STOCKLOCATION",
    CLEAR_STOCKLOCATION_MESSAGE = "@@stockLocation/CLEAR_STOCKLOCATION_MESSAGE",

    //UPDATE
    UPDATE_STOCKLOCATION = "@@stockLocation/UPDATE_STOCKLOCATION",
    UPDATE_STOCKLOCATION_SUCCESS = "@@stockLocation/UPDATE_STOCKLOCATION_SUCCESS",
    UPDATE_STOCKLOCATION_FAIL = "@@stockLocation/UPDATE_STOCKLOCATION_FAIL",

    // POST
    POST_STOCKLOCATION = "@@stockLocation/POST_STOCKLOCATION",
    POST_STOCKLOCATION_SUCCESS = "@@stockLocation/POST_STOCKLOCATION_SUCCESS",
    POST_STOCKLOCATION_FAIL = "@@stockLocation/POST_STOCKLOCATION_FAIL",

    // GET
    GET_STOCKLOCATION_BY_ID = "@@stockLocation/GET_STOCKLOCATION_BY_ID",
    GET_STOCKLOCATION_BY_ID_SUCCESS = "@@stockLocation/GET_STOCKLOCATION_BY_ID_SUCCESS",
    GET_STOCKLOCATION_BY_ID_FAIL = "@@stockLocation/GET_STOCKLOCATION_BY_ID_FAIL",

}
