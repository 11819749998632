import React, { useEffect, useState } from 'react'
import { Html5QrcodeScanner } from "html5-qrcode";
import { useDispatch } from 'react-redux';
import { useToast } from 'src/components/Common/ReactToaster';
import { Dialog } from 'primereact/dialog';
import { getPartsListDropForInvoiceLoading } from 'src/store/actions';

interface ScannerPops {
    visible: boolean;
    onHide: () => void;
    allValue: any;
    setOpenScanner: any

}

const InvoiceScanner = ({ visible, onHide, allValue, setOpenScanner }: ScannerPops) => {
    const [qrCodeScanner, setQrCodeScanner] = useState<Html5QrcodeScanner | null>(null);
    const [paused, setPaused] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { showToast } = useToast();


    const clearScanner = () => {
        qrCodeScanner?.clear();
        onHide();
    };

    useEffect(() => {
        if (paused) {
            setTimeout(() => {
                qrCodeScanner?.resume();
                setPaused(false)
            }, 2000);
        }
    }, [paused]);

    const scan = () => {
        const html5QrcodeScanner = new Html5QrcodeScanner(
            "reader",
            { fps: 10, qrbox: { width: 400, height: 230, },supportedScanTypes:[0], showZoomSliderIfSupported: true, showTorchButtonIfSupported: true, defaultZoomValueIfSupported: 5, useBarCodeDetectorIfSupported: true, formatsToSupport: [0, 5] }, false);

        setQrCodeScanner(html5QrcodeScanner);


        const onScanSuccess = (decodedText: any, decodedResult: any) => {
            debugger;
            dispatch(getPartsListDropForInvoiceLoading(decodedText, allValue?.index, allValue?.setFieldValue, allValue?.passpartSkuDetails, allValue?.passtaxesDetails, allValue?.passdeleteState, allValue?.reducerState,allValue?.push))
            if (html5QrcodeScanner) {
                clearScanner()
                html5QrcodeScanner.pause();
            }
            if (html5QrcodeScanner.getState() === 3) {
                setPaused(true);
            }
            clearScanner()
            setOpenScanner(false)
        };

        const onScanFailure = (error: any) => {
        };
        html5QrcodeScanner.render(onScanSuccess, onScanFailure);
    };
    return (
        <div>
            {visible && <React.Fragment>
                <Dialog header="Scan Sku here" visible={visible} maximizable onHide={clearScanner} onShow={scan} style={{ width: '33%' }} >
                    <div style={{ display: 'grid', placeItems: 'center' }} className={paused ? 'scannerContainer' : ''}>
                        <div id="reader" style={{ width: '30vw', height: 'auto', textAlign: 'center' }} ></div>
                        <div className='d-flex justify-content-center'></div>
                    </div>
                </Dialog>
            </React.Fragment>}

        </div>
    )
}

export default InvoiceScanner