import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FieldArray } from "formik";
import CheckboxInput from "src/components/UI/CheckboxInput";
import TooltipMEL from "src/components/UI/Tooltip";
import FloatInput from 'src/components/Common/FloatInput';

interface TabProps {
    activeTab: string | number;
    status: string;
    handleChange?: () => void;
    onBlur?: () => void;
}

const InspectionInformation = (props: TabProps) => {
    const handleAddPendingComments = (push: any) => {
        push({
            inspectedBy: "",
            inspectionCompletedAt: "",
            comments: "",
        });

    };
    return (
        <div className={`job-sheet-tab-content  ${props.activeTab === "Inspection Information" ? "" : "d-none"}`}>
            <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
                <div className='dealer-title-header font-size-15 border-bottom'> Inspection Information</div>
                <CardBody className='p-0'>
                    <div className='px-3 pb-3'>
                        <Row>
                            <Col lg={3} className="mb-1 d-flex align-items-center">
                                <CheckboxInput
                                    name="packingComplete"
                                    label="Packing complete"
                                    value=""
                                    onChange={props.handleChange}
                                />
                            </Col>
                            <Col lg={3} className="mb-1 d-flex align-items-center">
                                <CheckboxInput
                                    name="cleaningComplete"
                                    label="Cleaning complete"
                                    value=""
                                    onChange={props.handleChange}
                                />
                            </Col>
                            <Col lg={3} className="mt-mb">
                                
                                <FloatInput
                                    label="Packaged at"
                                    type="date"
                                    name="packagedAt"
                                    disabled={true}
                                    required={false}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <div className='dealer-title-header border-bottom font-size-15'>
                                <span className='pull-left'> System Inspection</span>
                                <div className="add-btn-container mt-0 pull-right" >
                                    <i className="fas fa-plus-circle"></i>
                                </div>
                            </div>
                        </Row>
                        <Row >
                            <FieldArray name="createOrUpdatePurchaseOrderDetailsDtos">
                                {({ insert, remove, push }) => (
                                    <div>
                                        <Row>
                                            <Col lg={3} className="mt-mb">
                                                <FloatInput
                                                    type="dropdown"
                                                    name="inspectedBy"
                                                    label="Inspected by"
                                                    disabled={false}
                                                    required={true}
                                                    options={[]}
                                                    invalid={false}
                                                    onChange={props.handleChange}
                                                    onBlur={props.onBlur}
                                                />
                                            </Col>
                                            <Col lg={3} className="mt-mb">
                                                <FloatInput
                                                    label="Inspection completed at"
                                                    type="datetime"
                                                    name="inspectionCompletedAt"
                                                    disabled={false}
                                                    required={false}
                                                    onChange={props.handleChange}
                                                    onBlur={props.onBlur}
                                                />
                                            </Col>
                                            <Col lg={6} className="mt-mb-textarea">
                                                <div className="d-flex align-items-start">
                                                    <div className="d-block w-100">
                                                        <FloatInput
                                                            label="Comments"
                                                            type="textarea"
                                                            name="comments"
                                                            disabled={false}
                                                            required={true}
                                                            onChange={props.handleChange}
                                                            onBlur={props.onBlur}
                                                        />
                                                    </div>
                                                    <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                                        <TooltipMEL title="Remove this Part" position="left">
                                                            <i className="fas fa-times-circle color-red"></i>
                                                        </TooltipMEL>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </FieldArray>
                        </Row>
                    </div>
                </CardBody >
            </Card >
        </div >
    )
}

export default InspectionInformation;