import React from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MelTable from 'src/components/Common/MelTable';
import { HeaderData } from 'src/common/data/tablesdata/Header';
import { tableResponse } from 'src/common/data/tablesdata/TableContent';

const ReusableTable: React.FC = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Tables" breadcrumbItem="MelTable" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardHeader>
                                <h4 className="card-title">Reusable Datatable</h4>
                            </CardHeader>
                            <CardBody>
                                <MelTable columns={HeaderData} data={tableResponse} globalFilter={true} customPageSize={10}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ReusableTable