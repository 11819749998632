import { TaxesDropDown, taxes } from "src/models/pages/taxes";
import { TaxesTypes } from "./actionTypes";



// For Grid

export const getTaxesList = (lazyState: any) => {
  return {
    type: TaxesTypes.GET_TAXES_LIST,
    lazyState,
  };
};



export const getTaxesListSuccess = (administrators: any) => {
  return {
    type: TaxesTypes.GET_TAXES_LIST_SUCCESS,
    payload: administrators,
  };
};

export const getTaxesListError = (error: any) => {
  return {
    type: TaxesTypes.GET_TAXES_LIST_FAIL,
    payload: error,
  };
};
export const getTaxesBYID = (id: string) => {
  return { type: TaxesTypes.GET_TAXES_BY_ID, payload: id };
};

export const getTaxesBYIDSuccess = (taxes: any) => {
  return { type: TaxesTypes.GET_TAXES_BY_ID_SUCCESS, payload: taxes };
};

export const getTaxesBYIDError = (error: any) => {
  return { type: TaxesTypes.GET_TAXES_BY_ID_FAIL, payload: error };
};

export const resetTaxesMessage = ()=>{
  return { type: TaxesTypes.RESET_TAXES};
}

export const clearTaxesMessage = ()=>{
  return { type: TaxesTypes.CLEAR_TAXES_MESSAGE};
}

export const updateTaxes = (parts: taxes, partId: string = "") => {
  return { type: TaxesTypes.UPDATE_TAXES , payload: { parts, partId } };
};

export const updateTaxesSuccess = (parts: any) => {
  return { type: TaxesTypes.UPDATE_TAXES_SUCCESS, payload: parts };
};

export const updateTaxesError = (error: any) => {
  return { type: TaxesTypes.UPDATE_TAXES_FAIL, payload: error };
};


export const createTaxes = (parts: any) => {
  return { type: TaxesTypes.POST_TAXES, payload: parts };
};

export const createTaxesSuccess = (parts: any) => {
  return { type: TaxesTypes.POST_TAXES_SUCCESS, payload: parts };
};

export const createTaxesError = (error: any) => {
  return { type: TaxesTypes.POST_TAXES_FAIL, payload: error };
};

export const deleteTaxesFromList = (payload:any) => {
  return {
    type: TaxesTypes.DELETE_TAXES,
    payload: payload,
  }
}

export const deleteTaxesFromListSuccess = (response:any,undo : boolean) => {
  return {
    type: TaxesTypes.DELETE_TAXES_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteTaxesFromListError = (error:any) => {
  return {
    type: TaxesTypes.DELETE_TAXES_FAIL,
    payload: error,
  }
}


//taxes dropdownList 

export const getDropDownTaxesListLoading=()=>{
  return {
    type : TaxesTypes.GET_TAXES_DROPDOWN_LIST_LOADING
  }
}

export const getDropDownTaxesListSuccess=(dropdownTaxes :TaxesDropDown[] )=>{
   return {
    type : TaxesTypes.GET_TAXES_DROPDOWN_LIST_SUCCESS,
    payload : dropdownTaxes
   }
}

export const getDropDownTaxesListError=(error : any)=>{
   return {
    type : TaxesTypes.GET_TAXES_DROPDOWN_LIST_ERROR,
    payload : error
   }
}


//get taxes page invoice dropdown

export const getTaxesPageInvoicetypeDropdownLoading=()=>{
  return {
    type : TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_LOADING
  }
}

export const getTaxesPageInvoicetypeDropdownSuccess=(resp : any)=>{
  return {
    type : TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_SUCCESS,
    payload : resp
  }
}

export const getTaxesPageInvoicetypeDropdownError=(error : any)=>{
  return {
    type : TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_ERROR,
    payload : error
  }
}