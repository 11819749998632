import React, { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";


const Authmiddleware = (props:PropsWithChildren) => {
  const location = useLocation();
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login"}} state={{prevUrl : location.pathname}} />
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
