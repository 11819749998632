import React, { useEffect, useState } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useDispatch, useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

import { createSelector } from "reselect";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { RootReducerState } from "./store/reducers";
import { OAuthService } from "./util/auth/oauthService";
import { generateToken, messaging } from "./Notifications/firebase";
import { onMessage } from "@firebase/messaging";

import logoMiniImage from "./assets/images/logo/mount-min-logo-blue.png";
import { APPENV_URL } from "./util/appUrlEnv";

import { ROUTER } from "./constants/routes";
import { getMapKeyApiLoading } from "./store/actions";

const App = () => {
  const { mapKey } = useSelector(
    (state: RootReducerState) => state.admnistratorReducer
  );
  const selectCalendar = createSelector(
    (state: any) => state.Layout,
    (state) => ({
      layoutType: localStorage.getItem("layout"),
    })
  );
  const firebaseToken = localStorage.getItem("firebaseToken");
  const firebase = localStorage.getItem("firebase");
  let expireTime = localStorage.getItem("exp");
  const loginToken = localStorage.getItem("token");
  const dispatch = useDispatch();


  const navigate = useNavigate();
  // FireBase Start
  
  useEffect(() => {
    onMessage(messaging, (payload) => {
      handleMessage(payload);
    });
  }, []);

  const passFirebaseToken = async (token: any) => {
    try {
      const platform = navigator.platform;
      let prepareData = {
        fcmToken: token,
        platform: platform === "Win32" ? "Web" : "Mobile",
        expireInMinutes: Number(expireTime),
      };
      if (token) {
        const response = await fetch(
          APPENV_URL.identityUrl + "/" + "f-cMDetail/f-cMDetail-on-login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json; text/plain",
              Authorization: `Bearer ${loginToken}`,
            },
            body: JSON.stringify(prepareData),
          }
        );
        if (response) {
          localStorage.removeItem("firebase");
          localStorage.setItem("firebaseToken", token);
        }
      }
    } catch (error) {
      console.log("error to pass firebase token");
    }
  };
  // FireBase End

  // useEffect(() => {
  //   const initiateTokenFetch = async () => {
  //     const firebase = await generateToken();
  //     passFirebaseToken(firebase);
  //   };
  //   if (!firebaseToken && loginToken) {
  //     initiateTokenFetch();
  //   }
  // }, [loginToken]);

  // FireBase Start
  useEffect(() => {
    let isMounted = true;
    const initiateTokenFetch = async () => {
      try {
        if (loginToken && !firebaseToken) {
          if (firebase && isMounted) {
            await passFirebaseToken(firebase);
          }
        }
      } catch (error) {
        console.error("Error in initiateTokenFetch:", error);
      }
    };
    initiateTokenFetch();
    return () => {
      isMounted = false;
    };
  }, [loginToken, firebase]);
 

  const handleMessage = (payload: any) => {
    if (!("Notification" in window)) {
    } else if (Notification.permission === "granted") {
      createNotification(payload);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          createNotification(payload);
        }
      });
    }
  };
   // FireBase End

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createNotification = (payload: any) => {
    const { title, body, image } = payload.notification;
    const notification = new Notification(title, {
      body: body,
      icon: logoMiniImage,
    });
    notification.onclick = () => {
      if (payload?.data?.job_id) {
        navigate(`${ROUTER.JOB_SHEETS_VIEW}/${payload?.data?.job_id}`);
      }
    };
  };

  useEffect(() => {
    const iFrame = document?.querySelectorAll("refreshTokenIframe");
    if (iFrame?.length > 0) {
      for (let i = 0; i < iFrame?.length; i++) {
        document.removeChild(iFrame?.[i]);
      }
    }
  }, []);

  const { refreshAt } = useSelector(
    (state: RootReducerState) => state.loginAuthReducer
  );
  const { layoutType } = useSelector((state) => selectCalendar(state));

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout: any = getLayout();

  const oauthService = new OAuthService();

  useEffect(() => {
    const currentTime = new Date().getTime();
    const timeInFiftyMinutes =
      (Number(refreshAt) - Math.floor(currentTime / 1000 + 600)) * 1000; // seconds in milliseconds

    if (timeInFiftyMinutes < 0) {
      return;
    }
    setTimeout(() => {
      if (timeInFiftyMinutes > 0) {
        oauthService.autoRefresh();
      }
    }, timeInFiftyMinutes);
  }, [refreshAt]);

  return (
    <React.Fragment>
      <Routes>
        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
          />
        ))}

        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default App;
