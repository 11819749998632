import { BulkSmsTypes } from "./actionTypes";


export const getBulkSmsList = (lazyState: any) => {
    return {
      type: BulkSmsTypes.GET_BULK_SMS_LIST_LOADING,
      lazyState,
    };
  };
  
  
  export const getBulkSmsListSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_BULK_SMS_LIST_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBulkSmsListError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_BULK_SMS_LIST_ERROR,
      payload: error,
    };
  };

export const getEmployeeNumList = () => {
    return {
      type: BulkSmsTypes.GET_EMPLOYEE_NUM_LOADING,
    };
  };
  
  
  export const getEmployeeNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_EMPLOYEE_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getEmployeeNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_EMPLOYEE_NUM_ERROR,
      payload: error,
    };
  };

export const getDealerNumList = () => {
    return {
      type: BulkSmsTypes.GET_DEALER_NUM_LOADING,
    };
  };
  
  
  export const getDealerNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_DEALER_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getDealerNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_DEALER_NUM_ERROR,
      payload: error,
    };
  };

export const getCustomerNumList = () => {
    return {
      type: BulkSmsTypes.GET_CUSTOMER_NUM_LOADING,
    };
  };
  
  
  export const getCustomerNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_CUSTOMER_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getCustomerNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_CUSTOMER_NUM_ERROR,
      payload: error,
    };
  };

export const getFranchiseNumList = () => {
    return {
      type: BulkSmsTypes.GET_FRANCHISE_NUM_LOADING,
    };
  };
  
  
  export const getFranchiseNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_FRANCHISE_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getFranchiseNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_FRANCHISE_NUM_ERROR,
      payload: error,
    };
  };

export const getDealerSubuserNumList = () => {
    return {
      type: BulkSmsTypes.GET_DEALER_SUBUSER_NUM_LOADING,
    };
  };
  
  
  export const getDealerSubuserNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_DEALER_SUBUSER_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getDealerSubuserNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_DEALER_SUBUSER_NUM_ERROR,
      payload: error,
    };
  };

export const getFranchiseSubuserNumList = () => {
    return {
      type: BulkSmsTypes.GET_FRANCHISE_SUBUSER_NUM_LOADING,
    };
  };
  
  
  export const getFranchiseSubuserNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_FRANCHISE_SUBUSER_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getFranchiseSubuserNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_FRANCHISE_SUBUSER_NUM_ERROR,
      payload: error,
    };
  };

export const getSupplierNumList = () => {
    return {
      type: BulkSmsTypes.GET_SUPPLIER_NUM_LOADING,
    };
  };
  
  
  export const getSupplierNumSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_SUPPLIER_NUM_SUCCESS,
      payload: payload,
    };
  };
  
  export const getSupplierNumError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_SUPPLIER_NUM_ERROR,
      payload: error,
    };
  };

export const createBulkSms = (payload:any) => {
    return {
      type: BulkSmsTypes.CREATE_BULK_SMS_LOADING,
      payload
    };
  };
  
  
  export const createBulkSmsSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.CREATE_BULK_SMS_SUCCESS,
      payload: payload,
    };
  };
  
  export const createBulkSmsError = (error: any) => {
    return {
      type: BulkSmsTypes.CREATE_BULK_SMS_ERROR,
      payload: error,
    };
  };
export const deleteBulkSms = (id:any) => {
    return {
      type: BulkSmsTypes.DELETE_BULK_SMS_LOADING,
      id:id
    };
  };
  
  
  export const deleteBulkSmsSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.DELETE_BULK_SMS_SUCCESS,
      payload: payload,
    };
  };
  
  export const deleteBulkSmsError = (error: any) => {
    return {
      type: BulkSmsTypes.DELETE_BULK_SMS_ERROR,
      payload: error,
    };
  };
export const getBulkSmsStatus = () => {
    return {
      type: BulkSmsTypes.GET_BULK_SMS_STATUS_LOADING,
    };
  };
  
  
  export const getBulkSmsStatusSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_BULK_SMS_STATUS_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBulkSmsStatusError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_BULK_SMS_STATUS_ERROR,
      payload: error,
    };
  };
export const getBulkSmsById = (id:any) => {
    return {
      type: BulkSmsTypes.GET_BY_ID_BULK_SMS_LOADING,
      id:id
    };
  };
  
  
  export const getBulkSmsByIdSuccess = (payload: any) => {
    return {
      type: BulkSmsTypes.GET_BY_ID_BULK_SMS_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBulkSmsByIdError = (error: any) => {
    return {
      type: BulkSmsTypes.GET_BY_ID_BULK_SMS_ERROR,
      payload: error,
    };
  };
  
  export const clearBulkSmsMessage = () => {
    return {
      type: BulkSmsTypes.CLEAR_BULK_SMS_MESSAGE,
    };
  };