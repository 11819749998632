import { API } from "src/util/api";
import { getjobsheetTatReportsTatSuccess, getJobsheetReportsTatError } from "./action";
import { jobsheetTatReportsActions } from "./actionTypes";
import { call, put, takeLatest } from "redux-saga/effects"


//worker function

//get method

function* getJobsheetReportAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getJobSheetTatReportsApi, { ...action.lazyState });
    yield put(getjobsheetTatReportsTatSuccess(response));
  } catch (error) {
    yield put(getJobsheetReportsTatError(error));
  } 
}


//watcher function

function* jobsheetTatReportsSaga() {
  yield takeLatest(jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_LOADING, getJobsheetReportAsync)
}


export default jobsheetTatReportsSaga ;