import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Col, Container, Form, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { useToast } from "src/components/Common/ReactToaster";
import CheckboxInput from 'src/components/UI/CheckboxInput'
import Button from 'src/components/UI/Button'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import './Permission.scss'
import { TabPanel, TabView } from 'primereact/tabview'
import { clearPermissionMessage, getPermissionsLoading, getRolesLoading, updatePermissionsLoading } from 'src/store/permission/action'
import { Avatar } from 'primereact/avatar'
import { Steps } from 'primereact/steps'

function generateInitialValues(permissionDetails:any) {
  const initialValues:any = {};
  if (permissionDetails?.groups) {
      permissionDetails.groups.forEach((group:any) => {
          group.permissions.forEach((permission:any) => {
              initialValues[permission.name] = permission.isGranted;
          });
      });
  }
  return initialValues;
}

const PermissionNew = () => {
  const navigate = useNavigate()
  const { id } = useParams()


  // Inside your component
  const { error, loading, message, Roles, permissionDetails } = useSelector(
    (state: RootReducerState) => state.permissionReducer
  );

  const[selectedRole,setSelectedRole]=useState("admin")
  const { showToast } = useToast();
  const [visible, setVisible] = useState<any>()

  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [EditMode, setEditMode] = useState(false)
  useEffect(() => {
    dispatch(getRolesLoading())
  }, []);





  const handleSubmit = async (values: any) => {
    let keys=Object.keys(values)
    let data={
      "permissions": keys?.map((item:any)=>({
        "name":item,
        "isGranted":values[item]
      }))
    }
    
    
    dispatch(updatePermissionsLoading(selectedRole,data))
  };

  const handleReset = (resetForm: any) => {
    setEditMode(false)
  }
  useEffect(() => {
    if (error) {
      setVisible(true)
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(getPermissionsLoading(selectedRole))
      window.scrollTo({top:1})
      dispatch(clearPermissionMessage())
    }
  }, [error, message])
  const hidePopup = () => {
    setVisible(false);
    dispatch(clearPermissionMessage())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  function capitalizeWords(string:any) {
    return string?.split(' ')?.map((word :any)=> word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');
}

function getInitials(string:any) {
  const initials = string.match(/[A-Z]/g);
  return initials ? initials.splice(0,2).join('') : '';
}

let colors = [
  'linear-gradient(#33bfff, #007eb8)',
  'linear-gradient(#e5ccfd -34.88%, #7d2ae8)',
  'linear-gradient(#ffd6da -41.53%, #f84856)',
  'linear-gradient(#145DA0 -41.53%, #2E8BC0)',
  'linear-gradient(#EF7C8E -41.53%, #D8A7B1)',
  'linear-gradient(#167D7F -41.53%, #29A0B1)',
  'linear-gradient(#38668E -41.53%, #658891)',
  'linear-gradient(#0074B7 -41.53%, #003B73)',
];
  const TabHeader = (options:any,role:any,index:any) => {
    let name=capitalizeWords(role.name)
    let initialLetter=getInitials(name)
    let color=colors[index]

    let style={ background: color, color: '#ffffff',marginRight:"10px",width:"24px",height:"24px" , fontWeight:"bolder"}
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={()=>{options.onClick();setSelectedRole(role.name)}}>
           <Avatar label={initialLetter} size="normal" style={style} shape="circle" />
            <b className="font-bold white-space-nowrap">{String(name)}</b>
        </div>
    );
};

useEffect(()=>{
  
  dispatch(getPermissionsLoading(selectedRole))
  
},[selectedRole])

let [initialValues,setInitialValues]=useState<any>(null)
useEffect(()=>{

  let initialValues =  generateInitialValues(permissionDetails)
  setInitialValues(initialValues)
},[permissionDetails])

const [activeIndex,setActiveIndex]=useState(0)

const handleChange = (e:any) => {
  const { name, checked } = e.target;
  setInitialValues((prevValues:any) => ({
    ...prevValues,
    [name]: checked
  }));
};

const handleFormSubmit = (e:any) => {
  e.preventDefault();
  handleSubmit(initialValues);
};

const items=permissionDetails?.groups?.map((item:any, idx:any) => ({label:item.displayName,command:(event:any)=>{setActiveIndex(idx)}}))
  return (
      <React.Fragment>
            <div className='userpage' id="permission">
              <h4 className='font-size-18'>Permission</h4>
              {error && dialogContainer(error)}
                <Container fluid>
                    <div id="bulkEmail">
                        <TabView>
                            {Roles.length > 0 && Roles.map((role:any, index:any) => (
                                <TabPanel header={role.name} headerTemplate={(options) => TabHeader(options, role, index)} key={index}>
                                    <div className=''>
                                      {initialValues && 
                                        // <Formik
                                        //     onSubmit={handleSubmit}
                                        //     initialValues={initialValues}
                                        //     enableReinitialize
                                        // >
                                        //     {({ values, handleChange, touched, errors, handleBlur, resetForm, setFieldValue, setFieldTouched, ...rest }) => (
                                                <Form onChange={handleChange} onSubmit={handleFormSubmit}>
                                                    <h4>{capitalizeWords(permissionDetails?.entityDisplayName)}</h4>
                                                    <div className='mb-3'>
                                                    <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                                                            {permissionDetails?.groups?.length > 0 && permissionDetails?.groups?.map((item:any, idx:any) => (

                                                               <div key={idx} className={idx==activeIndex ? 'mt-5':'d-none'}>
                                                                    <b className='bold-text-parent'>{item?.displayName}</b>
                                                                    <div>
                                                                        {item?.permissions?.map((permission:any, pidx:any) => (
                                                                            <div key={pidx} className={permission.parentName ? 'margin-left-20' : 'border-parent'}>
                                                                           
                                                                                <CheckboxInput
                                                                                    name={String(permission.name)}
                                                                                    label={permission.displayName}
                                                                                    value={initialValues[String(permission.name)]}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        {/* </Stepper> */}
                                                    </div>
                                                        <Row>
                                                            <Col lg={12} className='d-flex justify-content-end'>
                                                                            <Button
                                                                              className="secondary-btn me-2"
                                                                              label="Cancel"
                                                                              type="reset"
                                                                              onClick={() =>{dispatch(getPermissionsLoading(selectedRole))}}
                                                                            />
                                                                            <Button
                                                                              className="btn-primary me-2"
                                                                              label="Update"
                                                                              type="submit"
                                                                            />
                                                                          
                                                            </Col>
                                                            </Row>
                                                </Form>
//                                             )}
//                                         </Formik>
}
                                    </div>
                                </TabPanel>
                            ))}
                        </TabView>
                    </div>
                </Container>
            </div>
        </React.Fragment>
  )
}

export default PermissionNew