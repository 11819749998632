import { ErrorMessage } from 'formik';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React from 'react';
import { Option } from 'src/models/components/inputWrapperModel';
import './AutoComplete.scss'
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';
import Label from 'src/components/UI/Label';
import { AutoComplete } from 'primereact/autocomplete';
import ErrorText from 'src/components/UI/ErrorText';

export interface OptionsModal {
  name: string;
  key: string;
  value: string;
}

interface DropdownProps {
  name: string;
  label: string;
  options: any[];
  value?: string;
  required?: boolean;
  // onChange?: (event: DropdownChangeEvent) => {},
  onChange?: (event: DropdownChangeEvent) => void,
  searchMethod?: (event: any) => void,
  invalid: boolean;
  labelClassName?: any;
  inputClassName?: string;
  onBlur?: any;
  disabled?: boolean;
  customInputClassName?: string;
  field?: string;
  allowBr?:boolean;
  onSelect?:any;
}

const AutoCompleteComponent = (props: DropdownProps) => {
  const { options, name, label = "", required, invalid, value, labelClassName, inputClassName, onBlur, disabled = false, customInputClassName,
  allowBr=true, searchMethod,onSelect, ...rest } = props;
  
  return (
    <div id={`${customInputClassName ? customInputClassName : "auto-complete"}`}>
      <Label name={name} label={label} required={required} className={labelClassName} />
      {/* {(!invalid && allowBr) ? <br /> : null} */}
     <AutoComplete value={value}   name={name}  suggestions={options} completeMethod={searchMethod}  disabled={disabled}
                                              onBlur={onBlur} onSelect={onSelect}
                                              {...rest}  className={invalid ? "p-invalid w-full md:w-14rem padding-none" : "w-full md:w-20rem padding-none" + inputClassName}
                                              />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default AutoCompleteComponent;
