import { administratorsInitialState } from "src/models/pages/administratorModel";
import { AdministratorTypes } from "./actionTypes";




const initialState: administratorsInitialState = {
  error: "",
  loading: false,
  administratorList: {
    values: [],
    totalRecords: 0
  },
  mapKey: "",
  tokenGet : false
};



const Customer = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid
    case AdministratorTypes.GET_ADMINISTRATOR_LIST:
    case AdministratorTypes.GET_MAP_KEY_API_LOADING:
      return {
        ...state,
        loading: true,
      };

    case AdministratorTypes.GET_ADMINISTRATOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        administratorList: {
          ...state.administratorList,
          // values: action.payload.data.values,
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        }
      };

    case AdministratorTypes.GET_ADMINISTRATOR_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case AdministratorTypes.DELETE_ADMINISTRATOR:
      return {
        ...state,
        loading: true,
      };

    case AdministratorTypes.DELETE_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case AdministratorTypes.GET_MAP_KEY_API_SUCCESS:

      return {
        ...state,
        loading: false,
        mapKey: action.payload?.data?.mapKey
      };

    case AdministratorTypes.SAVE_MAP_KEY:
      return {
        ...state,
        mapKey: action?.payload
      };

    case AdministratorTypes.DELETE_ADMINISTRATOR_FAIL:
    case AdministratorTypes.GET_MAP_KEY_API_ERROR:
      return {
        ...state,
        loading: false,
      };


    default:
      return { ...state };
  }
};

export default Customer;
