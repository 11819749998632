import { FeedbackActions } from "./actionTypes"


export const getFeedbackListLoading = (lazyState: any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_LIST_LOADING,
        lazyState
    }
}
export const getFeedbackListSuccess = (feedback: any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_LIST_SUCCESS,
        payload: feedback
    }
}
export const getFeedbackListError = (error: any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_LIST_ERROR,
        payload: error
    }
}


export const getJobsheetByIdForFeedback = (jobsheetId: any) => {
    return {
      type: FeedbackActions.GET_JOBSHEET_DETAILS_LOADING,
      payload: jobsheetId,
    };
  }
  
  export const getJobsheetByIdForFeedbackSuccess = (jobsheet: any) => {
    return {
      type: FeedbackActions.GET_JOBSHEET_DETAILS_SUCCESS,
      payload: jobsheet,
    }
  }
  
  export const getJobsheetByIdForFeedbackError = (error: any) => {
    return {
      type: FeedbackActions.GET_JOBSHEET_DETAILS_ERROR,
      payload: error
    }
  }


  export const getVehiclebrandDropdownForFeedbackLoading=()=>{
    return {
      type : FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_LOADING
    }
  }
  export const getVehiclebrandDropdownForFeedbackSuccess=(resp : any)=>{
    return {
      type : FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_SUCCESS,
      payload : resp
    }
  }
  export const getVehiclebrandDropdownForFeedbackError=(error : any)=>{
    return {
      type : FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_ERROR,
      payload : error
    }
  }


  export const createFeedbackLoading = (feedback: any) => {
    debugger;
    return {
      type: FeedbackActions.CREATE_FEEDBACK_LOADING,
      payload: feedback,
    };
  }
  
  export const createFeedbackSuccess = (response: any) => {
    return {
      type: FeedbackActions.CREATE_FEEDBACK_SUCCESS,
      payload: response,
    }
  }
  
  export const createFeedbackError = (error: any) => {
    debugger;
    return {
      type: FeedbackActions.CREATE_FEEDBACK_ERROR,
      payload: error
    }
  }


  export const resetFeedbackMessage = ()=>{
    return {
        type: FeedbackActions.RESET_FEEDBACK_MESSAGE,
      }
  }

  export const clearFeedbackMessage = ()=>{
    return {
        type: FeedbackActions.CLEAR_FEEDBACK_VALUE,
      }
  }


//============= get vehicle barnd list dropdown grid page ==============

export const getVehicleBrandFeedBackLoading = () => {
    return {
        type: FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_LOADING
    }
}
export const getVehicleBrandFeedBackSucces = (resp: any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_SUCCESS,
        payload: resp
    }
}
export const getVehicleBrandFeedBackError = (error: any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_ERRROR,
        payload: error
    }
}



//====================== get by id feedback ==================

export const getFeedbackIdDetailsLoading = (id : any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_BYID_LOADING,
        payload : id
    }
}
export const getFeedbackIdDetailsSuccess = (resp : any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_BYID_SUCCESS,
        payload : resp
    }
}
export const getFeedbackIdDetailsError = (error : any) => {
    return {
        type: FeedbackActions.GET_FEEDBACK_BYID_ERROR,
        payload : error
    }
}