import {monthlyPartsSummaryActions} from "./actionTypes" ;


export const getMonthlypartsSummaryLoading=(lazyState :any)=>{
    return {
      type : monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_LOADING,
      lazyState
    }
  }
  
  export const getMonthlypartsSummarySuccess=(resp : any)=>{
    return {
      type : monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_SUCCESS,
      payload : resp
    }
  }
  
  export const getMonthlypartsSummaryError=(error : any)=>{
    return {
      type : monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_ERROR,
      payload : error
    }
  }