import { call, put, takeLatest } from "redux-saga/effects";
import { StockQuantityBySupplierTypes } from "./actionTypes";
import { API } from "src/util/api";
import {getStockQuantityBySupplierError,getStockQuantityBySupplierSuccess, getSupplierDropdownError, getSupplierDropdownSuccess} from "./action";



//worker function
function* getQuantityValueBySupplierListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getStockQuantitybySupplierAPI, {...action.lazyState})
    yield put(getStockQuantityBySupplierSuccess(response));
  } catch (error) {
    yield put(getStockQuantityBySupplierError(error));
  }
}
function* getSupplierDropdownAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getSupplierListFromMasterManagement, '')
    yield put(getSupplierDropdownSuccess(response));
  } catch (error) {
    yield put(getSupplierDropdownError(error));
  }
}

//watcher function

function* StockQuantityBySupplierSaga() {
  yield takeLatest(StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_LOADING, getQuantityValueBySupplierListAsync);
  yield takeLatest(StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_LOADING, getSupplierDropdownAsync);
}


export default StockQuantityBySupplierSaga