import { Form, Formik } from 'formik'
import React from 'react'
import InputController from './InputController'
import * as Yup from 'yup';
import Button from './Button';
import './commonUI.styles.scss'

interface ResendConfirmationModal {
  email: string
}

const ResendConfirmation = () => {

  const initialValues = {
    email: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email").required('Email is required')
  })

  const handleSubmit = (values: ResendConfirmationModal) => {
    
  }

  return (
    <div className='full-width'>
      <div className="d-flex p-5  justify-content-center align-items-center">

        <div className="card custom-card-width" >

          <div className="p-2 text-center">
            <b className='resend'>Resend Confirmation</b>
            <div className='underline-content'></div>
          </div>

          <div className="card-body">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
              {
                ({ values, handleChange }) => {

                  return <Form onChange={handleChange}>

                    <InputController control="text" type="email" name="email" label="Email" value={values.email} onChange={handleChange} />

                    <Button className="btn-primary" label="Resend confirmation instructions" type="submit" />

                    {/* <div className="d-grid">
              <button className="btn btn-primary " type="submit">Submit</button>
            </div> */}
                  </Form>
                }
              }
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ResendConfirmation;
