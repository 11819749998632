import { DealersModal, dealerInitialState } from "src/models/pages/dealerModel";
import { DealerActionTypes } from "./actionTypes";
import { messages } from "src/common/data";
import { customerImageDeleteSuccess } from "../customers/reducer";




const INITIAL_STATE: dealerInitialState = {
  dealers: [],
  dealer: null,
  error: "",
  message:"",
  loading:false,
  paginatorCount : 0,
  dealerTaxList:[],
  vehicleBrandList:[],
  dealerList:{
    values:[],
    totalRecords:0
  },
  dealerImage:"",
  phoneMessage:"",
  mailMessage:"",
  editedMobileNumber:"",
  createdData:"",
  otpmessage:"",
  updatedmessage:"",
  dealerLatLng:""
};

const dealerImageDeleteSuccess = "Dealer image is deleted successfully"

const DealerReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {

    case DealerActionTypes.GET_DEALERS_SUCCESS:
      return { ...state, dealers: payload };
  
    case DealerActionTypes.GET_DEALER_DETAIL_SUCCESS:
      return { ...state,loading: false, dealer: payload ,phoneMessage:"",mailMessage:""};

    case DealerActionTypes.GET_DEALER_CONTACT_SUCCESS:
      return { 
        ...state,
        loading: false, 
        dealer : {
          ...state.dealer,
          contactDetails:payload
        } 
      };

    case DealerActionTypes.ADD_DEALER_DETAIL_SUCCESS:
      return { ...state,loading: false,error:"", message: "Dealer created successfully",createdData:action.payload.data};
   
    case DealerActionTypes.UPDATE_DEALER_DETAIL_SUCCESS:
      return { ...state, dealer: payload,error:"", updatedmessage:"Dealer updated successfully" };
   
    case DealerActionTypes.GET_DEALER_LOCATION_SUCCESS:
      return {
         ...state, 
         loading:false,
         dealerLatLng:action.payload.latLag.data
      };
   
      case DealerActionTypes.DEALER_FILE_CLEAR:
        return{
          ...state,
          dealerImage:""
        }

    // Grid

    case DealerActionTypes.GET_DEALER_LIST:
      return  {
        ...state,
        loading: true,
        paginatorCount : 0
      };

    case DealerActionTypes.GET_DEALER_LIST_SUCCESS:
    
    return  {
      ...state,
      loading: false,
      dealerList:{
        ...state.dealerList,
        values: action.payload.data.items,
        totalRecords: action.payload.data.totalCount,
      },
      paginatorCount : action.payload.data.items.length
    };

    case DealerActionTypes.SEND_OTP_DEALER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        otpmessage: "OTP Verified Successfully",
        editedMobileNumber:""
      };

    case DealerActionTypes.DELETE_DEALER:
    case DealerActionTypes.DEALER_TAX_TYPE_LIST_LOADING:
    case DealerActionTypes.GET_DEALERS :   
    case DealerActionTypes.DEALER_FILE_UPLOAD_LOADING:   
    case DealerActionTypes.DEALER_FILE_FETCH_LOADING:   
    case DealerActionTypes.DEALER_FILE_DELETE_LOADING: 
    case DealerActionTypes.ADD_DEALER_DETAIL:
    case DealerActionTypes.DEALER_SET_PHONE_LOADING:
    case DealerActionTypes.DEALER_SET_EMAIL_LOADING:
    case DealerActionTypes.SEND_OTP_DEALER:
    case DealerActionTypes.RESEND_DEALER_OTP:
    case DealerActionTypes.GET_DEALER_LOCATION_LOADING:
      return {
        ...state,
        error:"",
        message:"",
        loading: true,
      };

    case DealerActionTypes.DELETE_DEALER_SUCCESS:

      return {
        ...state,
        loading:false,
        message: action.undo ? "Dealer is restored successfully" : "Dealer is deleted successfully" 
      }

    case DealerActionTypes.DELETE_DEALER_CONTACT_LOADING:
      return {
        ...state,
        loading:true,
      }
    case DealerActionTypes.GET_DEALER_DETAIL:
      return {
        ...state,
        error:"",
        message:"",
        loading:true,
      }

    case DealerActionTypes.DELETE_DEALER_CONTACT_SUCCESS:
      return {
        ...state,
        loading:false,
        error:"",
        message:"Contact is deleted successfully"
      }

    case DealerActionTypes.DEALER_TAX_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading:false,
        error:"",
        message:"",
        dealerTaxList:action.payload.data.taxList,
        vehicleBrandList: action.payload.data.vehicleBrandList
      }


    
    case DealerActionTypes.DEALER_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error : "",
        message:""
      };
  
    case DealerActionTypes.DEALER_FILE_FETCH_SUCCESS:  

        return {
          ...state,
          loading: false,
          error : "",
          message : "",
          dealerImage: action.payload.data,
        };

    case DealerActionTypes.DEALER_FILE_DELETE_SUCCESS:

      return {
        ...state,
        loading: false,
        error : "",
        message : dealerImageDeleteSuccess,
        dealerImage: "",
      };

    case DealerActionTypes.DEALER_SET_PHONE_SUCCESS:

      return {
        ...state,
        loading: false,
        error : "",
        phoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number
      };

    case DealerActionTypes.RESEND_DEALER_OTP_SUCCESS:

      return {
        ...state,
        loading: false,
        error : "",
        phoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number
      };
    case DealerActionTypes.DEALER_SET_EMAIL_SUCCESS:

      return {
        ...state,
        loading: false,
        error : "",
        mailMessage:"Email Id updated Successfully"
      };

    case DealerActionTypes.GET_DEALER_LIST_FAIL:
    case DealerActionTypes.DELETE_DEALER_FAIL:
    case DealerActionTypes.GET_DEALER_DETAIL_FAIL:
    case DealerActionTypes.UPDATE_DEALER_DETAIL_FAIL:
    case DealerActionTypes.ADD_DEALER_DETAIL_FAIL:
    case DealerActionTypes.GET_DEALERS_FAIL:
    case DealerActionTypes.DELETE_DEALER_CONTACT_FAIL:
    case DealerActionTypes.DEALER_TAX_TYPE_LIST_FAIL:
    case DealerActionTypes.DEALER_FILE_UPLOAD_ERROR:
    case DealerActionTypes.DEALER_FILE_FETCH_ERROR:
    case DealerActionTypes.DEALER_FILE_DELETE_ERROR:
    case DealerActionTypes.DEALER_SET_PHONE_ERROR:
    case DealerActionTypes.DEALER_SET_EMAIL_ERROR:
    case DealerActionTypes.SEND_OTP_DEALER_ERROR:
    case DealerActionTypes.GET_DEALER_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        // error: action.payload.message
        phoneMessage:""
      };

    // clear dealer
    case DealerActionTypes.CLEAR_DEALER_DATA:
      return{
        ...state,
        loading: false,
        dealer: null,
        message:"",
        error:"",
        dealers:[],
        dealerList:{
          values:[],
          totalRecords:0
        },
        updatedmessage:"",
        dealerLatLng:""
      }
    case DealerActionTypes.CLAER_DEALER_MESSAGE:
      return{
        ...state,
        message:"",
        error:"",
        loading:false
      } 
    case DealerActionTypes.RESET_DEALER_OTP_MESSAGE:
      return{
        ...state,
        message:"",
        error:"",
        loading:false,
        createdData:"",
        editedMobileNumber:"",
        phoneMessage:"",
        otpmessage:"",
        updatedmessage:""
      } 

    default:
      return { ...state };
  }
};

export default DealerReducer;
