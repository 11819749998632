import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { RootReducerState } from "src/store/reducers";
import { CurrencyFormatter, formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED, LOCKED, UNLOCKED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { StockAdjustmentReset, deleteStockAdjustmentFromList, getInitiatedByDropdown, getStockAdjustmentList, resetStockAdjustemntMessage } from "src/store/stockAdjustment/actions";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const partsListConverson = (values: any[], initiatedBy: any) => {

  const modifeidValue = values?.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id;
    data['number'] = eachValue?.number;
    data['creationTime'] = formatDateMEL(eachValue?.creationTime);
    data['supplierName'] = eachValue?.supplierName;
    const initiatedObject = initiatedBy?.find((item: any) => item.id === eachValue?.initiatedById);
    data['initiatedById'] = initiatedObject ? initiatedObject.name : null;
    data['creatorId'] = eachValue?.creatorId;
    data['status'] = eachValue?.status;
    data['totalGatepassValue'] = eachValue?.totalGatepassValue;
    data['locked'] = eachValue?.locked;
    data['deliveryDate'] = formatDateMEL(eachValue?.deliveryDate);
    data['deliveryType'] = eachValue?.deliveryType;
    data['noOfItems'] = eachValue?.noOfItems;
    data['courierId'] = eachValue?.courierId;
    data['isDeleted'] = eachValue?.isDeleted;
    data['supplierIds'] = eachValue?.supplierId;
    data['initiator'] = eachValue?.initiatedById;
    data['courierName']=eachValue?.courierName;
    return data;
  });
  return modifeidValue;
}

export default function StockAdjustment() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.StockAdjustmentReducer.StockAdjustmentList);
  const initiatedBy = useSelector((state: RootReducerState) => state.StockAdjustmentReducer?.initiatedBy);
  const modifiedValues: parts[] = partsListConverson(values, initiatedBy);
  const { showToast } = useToast();
  const { error, message, loading } = useSelector((state: any) => state.StockAdjustmentReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<parts | null>(null);
  const filterDetails: DataTableFilterMeta = {
    number: { value: "", matchMode: "contains" },
    supplierName: { value: "", matchMode: "contains" },
    totalGatepassValue: { value: "", matchMode: "contains" },
    deliveryDate: { value: "", matchMode: "contains" },
    DeliveryStartDate: { value: "", matchMode: "contains" },
    DeliveryEndDate: { value: "", matchMode: "contains" },
    deliveryType: { value: "", matchMode: "contains" },
    Locked: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" }
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "",
    // sortOrder: 0,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const initialValues = {
    status: "",
  };
  useEffect(() => {
    dispatch(getStockAdjustmentList(lazyState));
    dispatch(getInitiatedByDropdown());

    return () => {
      dispatch(StockAdjustmentReset())
    }
  }, [])

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.STOCK_ADJUSTMENT_CREATE);
  };

  const viewHandler = (rowData: parts) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: parts) => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: parts) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {

    return (
      <>
        {
          (rowData.locked === true && rowData.isDeleted === false) ? (
            <ColumnAction
              displayData={rowData[field] ? rowData[field] : 'WIP' as string}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
            />
          ) : (rowData.isDeleted === true) ? (
            <ColumnAction
              displayData={rowData[field] ? rowData[field] : 'WIP' as string}
              isEdit={false}
              isDelete={false}
              isView={false}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          ) : (
            <ColumnAction
              displayData={rowData[field] ? rowData[field] : 'WIP' as string}
              isEdit={true}
              isDelete={true}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }

      </>
    );
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error && error != null) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockAdjustemntMessage());
    }

  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetStockAdjustemntMessage())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };
  // useEffect(() => {
  //   if (error) {
  //     setVisible(true);
  //   }
  //   if (message) {
  //     showToast(message, { type: "success" });
  //     // dispatch(resetparts());
  //   }
  //   console.log("Error=>", error, "/n", "Message", message);

  // }, [error, message]);

  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };
  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['startDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['endDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>)
  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    { label: "Locked", value: "Locked" },
    { label: "Unlocked", value: "Unlocked" },
  ];;


  const deliveryType = [
    { label: "Courier", value: "Courier" },
    { label: "Hand Carry", value: "Hand Carry" },
  ]
  const deliveryTypeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(deliveryType?.length > 0) ? (deliveryType?.map((eachSupplier: any) => {
          let data: any = {};
          data['label'] = eachSupplier?.label;
          data['value'] = eachSupplier?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };

  const courierTypeTemplate = (rowData: any) => {

    return  <Link to={`${ROUTER.VIEW_COURIER}/${rowData.courierId}`}>{rowData.courierName}</Link>
  }

  const currencyBodyTemplate = (rowData: any, field: string) => {
    let amount = rowData[field];
    return (<div className="align-right">{CurrencyFormatter({ value: amount, currencyCode: "INR" })}</div>);
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData['supplierIds']}`}>{rowData[field]}</Link>
    );
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      frozen : true,
    },
    {
      field: "number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "number"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "initiatedById",
      sortable: true,
      header: "Initiated By",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "supplierName",
      sortable: true,
      header: "Supplier",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      body: (rowData) => supplierBodyTemplate(rowData, "supplierName"),

      isStatic: false,
    },

    {
      field: "creationTime",
      sortable: false,
      header: "Stock Adjustment Date",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => formatDateMEL(rowData.creationTime),

    },
    {
      field: "status",
      sortable: true,
      header: "Status",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalGatepassValue",
      sortable: true,
      header: "TGP Value",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      isStatic: false,
      className: "align-right pr-4",
      headerStyle: { whiteSpace: "nowrap" },
      tooltip: true,
      body: (rowData) => currencyBodyTemplate(rowData, 'totalGatepassValue'),
    },
    {
      field: "locked",
      sortable: false,
      header: "Locked",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'locked'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "noOfItems",
      sortable: false,
      header: "Items",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      isStatic: false,
      className: "align-right",
      tooltip: true,
      tooltipTitle: "No of Items"
    },
    {
      field: "deliveryDate",
      sortable: true,
      header: "Delivery Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => formatDateMEL(rowData.deliveryDate),
      filterElement: dateFilterTemplate,
    },

    {
      field: "deliveryType",
      sortable: true,
      header: "Delivery Type",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      filterElement: deliveryTypeFilterTemplate
    },
    {
      field: "courierId",
      sortable: true,
      header: "Courier",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => courierTypeTemplate(rowData),
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values?.map((eachDealer) => {
      const { id, number, creationTime, supplierName, initiatedById, creatorId, inStockQuantity, status, totalGatepassValue, deliveryDate, deliveryType, noOfItems, courierName, isDeleted } = eachDealer;
      let data: any = {};
        data['Number'] = number;
      data['creation Time'] = creationTime;
      data['supplier Name'] = supplierName;
      data['Initiated By'] = initiatedById;
      data['Creator'] = creatorId;
      data['status'] = status;
      data['Total Gatepass Value'] = totalGatepassValue;
      data['Delivery Date'] = deliveryDate;
      data['Delivery Type'] = deliveryType;
      data['No Of Items'] = noOfItems;
      data['Courier'] = courierName;
      data['Is Deleted'] = isDeleted;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        number: data.number,
        initiatedById: data.initiatedById,
        supplierName: data.supplierName,
        creationTime: data.creationTime,
        status: data.status,
        totalGatepassValue: data.totalGatepassValue,
        noOfItems: data.noOfItems,
        deliveryDate: formatDateMEL(data.deliveryDate),
        deliveryType: data.deliveryType,
        courierId: data.courierName
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: "landscape",
          format: "a4"
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    try {
      setPdfLoading(true)
      const response:any = await API.getStockAdjustmentListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let resp=await partsListConverson(response?.data?.items,initiatedBy)
     if(isPdf){

       saveDataAsPdf(resp, columns, "Stock_Adjustment");
     }else{
      saveDataAsExcel(resp, "Stock_Adjustment");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Stock_Adjustment");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Stock_Adjustment");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.deliveryDate) {
      modifiedLazyState.filters.deliveryDate.value = "";
    }

    dispatch(getStockAdjustmentList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {

    const value = event.value.filter((eachStock: any) => !eachStock.isDeleted && !eachStock.locked);

    setSelectedParts(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      if (modifiedValues.filter((eachStock: any) => !eachStock.isDeleted && !eachStock.locked).length == modifiedValues.length) {
        setSelectAll(true);

      } else {
        setSelectAll(false);
      }
      setSelectedParts(modifiedValues.filter((eachStock: any) => !eachStock.isDeleted && !eachStock.locked));
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };


  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['DeliveryStartDate']['value'] = event?.filters?.deliveryDate?.value?.startDate ? event?.filters?.deliveryDate?.value?.startDate : ''
    event['filters']['DeliveryEndDate']['value'] = event?.filters?.deliveryDate?.value?.endDate ? event?.filters?.deliveryDate?.value?.endDate : ''

    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == LOCKED) {

      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "true", matchMode: "contains" },

        },
      }));
    } else if (formValue == UNLOCKED) {

      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },

        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedParts([]);
    callbackFunction(lazyState);
  }, [lazyState]);



  const handleDeleteUsers = (rowData: parts, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    dispatch(deleteStockAdjustmentFromList(payload));
    setDeleteModal(false);
    setRowData(null);



    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      // showToast("Part Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(getStockAdjustmentList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      let filtered = modifiedValues.filter((eachStock: any) => !eachStock.isDeleted && !eachStock.locked)
      setSelectedParts(modifiedValues.filter((eachStock: any) => !eachStock.isDeleted && !eachStock.locked));
      setSelectAll(filtered.length==totalRecords);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>Stock Adjustment List</div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Parts"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedParts}
          bulkActionSatusTitle="Parts"
          showBorderRight={true}
        />  */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedParts.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 delete-space " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedParts.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  <FeatherIcon icon="trash-2" className="mr-1" />
                  <span className="remove_lables">Delete</span>
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedParts.length > 0 && <BulkActionStatus count={selectedParts.length} title="Stock Adjustments"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} isFilter={true} clearFilter={clearFilter} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>

        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="mr-1" />
        </Button>
        {/* <TooltipMEL title="Add Parts" position="bottom"> */}
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Stock Adjustment
        </Button>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const isSelectable = (data: any) => {

    return !data?.isDeleted;
  }

  const rowClassName = (data: any) => (isSelectable(data) ? '' : 'p-disabled');





  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No Stock Adjustment found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Stock Adjustment  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          rowClassName={rowClassName}
        ></DataTableWrapper>
      </div>
    </>
  );
}
