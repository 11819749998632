import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import DeleteModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { formatDateTOYYYYMMDD } from "src/helpers/common_helpers";
import { getAudioBrandsListForParts } from "src/store/parts/action";
import MultiSelector from "src/components/Common/MultiSelector";
import { clearTaxesMessage, createTaxes, getDropDownTaxesListLoading, getTaxesBYID, getTaxesPageInvoicetypeDropdownLoading, resetTaxesMessage, updateTaxes } from "src/store/actions";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";

interface TaxTypeMap {
  id: number;
  taxId: number;
  taxTypeId: number;
}

interface invoiceMap {
  id: number;
  taxId: number;
  invoiceTypeId: number;
}

interface FormData {
  name: string;
  sacNumber: string;
  taxPercentage: number;
  priority: string;
  startsAt: string;
  endsAt: string;
  taxtype: number[];
  invoiceType: number[];
}

const AddEditTaxes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue: any = useSelector(
    (state: RootReducerState) => state.taxesReducer.taxDetails
  );
  const { error, message, loading, taxDropDownList, invoiceDropdown } = useSelector(
    (state: RootReducerState) => state.taxesReducer
  );

  const taxTypes = taxDropDownList.length > 0 ? (taxDropDownList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.id;
    data['label'] = eachTax.taxName;
    return data;
  })) : [];

  const invoiceType = invoiceDropdown.length > 0 ? (invoiceDropdown.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.id;
    data['label'] = eachTax.name;
    return data;
  })) : [];

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getTaxesBYID(id));
    } else {
      dispatch(getAudioBrandsListForParts());
    }
    return () => {
      setVisible(false)
      dispatch(resetTaxesMessage())
    }
  }, [id]);


  useEffect(() => {
    if (error) {
      setIsSubmitting(false)
      setVisible(true)
    }
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.TAXES_GRID);
      }, 500)
      dispatch(clearTaxesMessage())
    }

  }, [error, message])

  useEffect(() => {
    dispatch(getDropDownTaxesListLoading());
    dispatch(getTaxesPageInvoicetypeDropdownLoading());
  }, [])

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearTaxesMessage())
  }


  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const post = (formValue: any) => {
    dispatch(createTaxes(formValue));
  };


  const update = (formValue: any) => {
    dispatch(updateTaxes(formValue, id as string));
  };

  const formatedDropdown = (response: any): any => {
    return response?.map((tax: any) => tax.id) || [];
  }

  const formatedInvoiceDropdown=(invoiceType : any)=>{
    return invoiceType?.filter((data : any)=>!data.isDeleted)?.map((data : any)=>data.invoiceTypeId)
  }

  const initialvalue = {
    name: (formValue?.name || ""),
    sacNumber: (formValue?.sacNumber || ""),
    taxPercentage: (formValue?.taxPercentage || ""),
    priority: (formValue?.priority || ""),
    startDate: formValue?.startDate ? formatDateTOYYYYMMDD(formValue?.startDate) : "",
    endDate: formValue?.startDate ? formatDateTOYYYYMMDD(formValue?.endDate) : "",
    taxtype: (formatedDropdown(formValue?.taxTypeList) || []),
    invoiceType : (formatedInvoiceDropdown(formValue?.invoiceMapList) || []),
  };

  function transformTaxTypeList(formData: FormData): TaxTypeMap[] {
    const { taxtype } = formData;
    return taxtype.map((typeId: number) => ({
      id: 0,
      taxId: 0,
      taxTypeId: typeId
    }));
  }

  function transformInvoiceTypeList(formData: FormData): invoiceMap[] {
    const { invoiceType } = formData
    return invoiceType.map((invoiceId: number) => ({
      id: 0,
      taxId: 0,
      invoiceTypeId: invoiceId

    }))
  }

  const convertDataType = (formValue: any, taxtype: any) => {
    if (!formValue || !taxtype || !Array.isArray(taxtype)) {
      console.error("Invalid input data.");
      return [];
    }
    const response: any = [];
    taxtype.forEach((id: any) => {
      const matched = formValue?.taxTypeMapList.find((item: any) => item.taxTypeId === id);
      if (matched) {
        response.push(matched);
      } else {
        response.push({ id: 0, taxId: 0, taxTypeId: id });
      }
    });
    return response
  }
  const convertInvoiceData = (formValue: any, invoiceType: any) => {
    if (!formValue || !invoiceType || !Array.isArray(invoiceType)) {
      console.error("Invalid input data.");
      return [];
    }
    const response: any = [];
    invoiceType.forEach((id: any) => {
      const matched = formValue?.invoiceMapList.find((item: any) => item.invoiceTypeId === id);
      if (matched) {
        response.push(matched);
      } else {
        response.push({ id: 0, taxId: 0, invoiceTypeId: id });
      }
    });
    return response
  }

  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const { taxtype,invoiceType ,...data } = values;
    const taxTypeMapList = transformTaxTypeList(values);
    const invoiceTypeMapList = transformInvoiceTypeList(values)
    let formatedData;
    if (!id) {
      formatedData = {
        taxTypeMapList: taxTypeMapList,
        invoiceTypeMapList:invoiceTypeMapList ,
        ...data
      }
    } else {
      const formated = convertDataType(formValue, taxtype)
      const formatedInvoice = convertInvoiceData(formValue, invoiceType)
      formatedData = {
        taxTypeMapList: formated,
        invoiceTypeMapList : formatedInvoice,
        ...data
      }
    }
    id ? update(formatedData) : post(formatedData);
    setIsSubmitting(true)
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").max(30, "Name must be atmost 30 length"),
    sacNumber: Yup.string().required("Sac Number is required").max(8, "Sac number must be atmost 8 length"),
    taxPercentage: Yup.number()
      .min(0,"Tax percentage must be valid")
      .required("Tax percentage is required"),
    priority: Yup.number()
      .positive("Priority must be a positive number")
      .required("Priority is required"),
    startDate: Yup.date().nullable().required('Start date is required'),
    // endDate: Yup.date().nullable().required('End date is required'),
    // taxtype: Yup.array().of(Yup.number())
    //   .min(1, 'At least one tax type must be selected') // Ensure at least one value is selected
    //   .required('Tax type is required'),
    invoiceType: Yup.array()
      .of(Yup.number())
      .min(1, 'Invoice type is required') // Ensure at least one value is selected
      .required('Invoice type is required'),
  });

  const validationSchemaForEdit = Yup.object().shape({
    name: Yup.string().required("Name is required").max(30, "Name must be atmost 8 length"),
    sacNumber: Yup.string().required("Sac Number is required").max(8, "Sac number must be atmost 30 length"),
    taxPercentage: Yup.number()
    .min(0,"Tax percentage must be valid")
      .required("Tax percentage is required"),
    priority: Yup.number().required("Priority is required"),
    startDate: Yup.date().nullable().required('Starts at is required'),
    // endDate: Yup.date().nullable().required('Ends at is required'),
    // taxtype: Yup.array()
    //   .of(Yup.number())
    //   .min(1, 'At least one tax type must be selected') // Ensure at least one value is selected
    //   .required('Tax type is required'),
    invoiceType: Yup.array()
      .of(Yup.number())
      .min(1, 'At least one tax type must be selected') // Ensure at least one value is selected
      .required('Invoice type is required'),
  });

  const removeIndex = (i: number) => {
    setselectedFiles((prevFiles: any) => {
      const newFiles = [...prevFiles];
      newFiles.splice(i, 1);
      return newFiles;
    });
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
     * Formats the size
     */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.TAXES_GRID)
    }
  }

  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.contactDetails || [];
    return contactDetails.every((contact: any) => {
      // Check for values in all fields except 'alternativeNumber'
      const { alternativeNumber, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      <DeleteModal
        show={deleteModal || false}
        showIcon={false}
        message="Are you sure want to delete this contact"
        onDeleteClick={() => {
          // deleteContact
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Taxes"
            titlePath={ROUTER.TAXES_GRID}
            breadcrumbItem={id ? "Edit Taxes" : "Create Taxes"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? validationSchemaForEdit : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      // console.log("error", touched, errors)
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Sac number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="sacNumber"
                                onBlur={handleBlur}
                                value={values.sacNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["sacNumber"] && errors["sacNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Tax Percentage"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="taxPercentage"
                                onBlur={handleBlur}
                                value={values.taxPercentage}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["taxPercentage"] && errors["taxPercentage"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Priority"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="priority"
                                onBlur={handleBlur}
                                value={values.priority}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["priority"] && errors["priority"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Starts at"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="startDate"
                                onBlur={handleBlur}
                                value={values.startDate}
                                onChange={handleChange}
                                max={values.endDate}
                                min={id ? "" : new Date().toISOString().split('T')[0]}
                                isRequired={true}
                                invalid={
                                  touched["startDate"] && errors["startDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Ends at"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="endDate"
                                min={id ? values?.startDate.split('T')[0] : (values.startDate || new Date().toISOString().split('T')[0])}
                                onBlur={handleBlur}
                                value={values.endDate}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["endDate"] && errors["endDate"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <MultiSelector
                                name="taxtype"
                                label="Tax Types"
                                options={taxTypes}
                                optionLabel="label"
                                id="taxtype"
                                labelClassName="form-Label"
                                //onBlur={handleBlur}
                                onBlur={handleBlur("taxtype")}
                                value={values.taxtype}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["taxtype"] && errors["taxtype"] ? true : false}
                              // invalid={touched["taxtype"] && Array.isArray(errors["taxtype"]) && errors["taxtype"].length > 0}
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <MultiSelector
                                name="invoiceType"
                                label="Invoice Type"
                                options={invoiceType}
                                optionLabel="label"
                                id="invoiceType"
                                labelClassName="form-Label"
                                onBlur={handleBlur("invoiceType")}
                                value={values.invoiceType}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["invoiceType"] && errors["invoiceType"] ? true : false}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Taxes" : "Create Taxes"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddEditTaxes;
