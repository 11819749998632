import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { ACTIVE, ALL, DELETED, LOCKED, PACKAGENOTSENT, PACKAGESENT } from 'src/constants/commonConstants';
import { Columns, LazyTableState } from "src/models/components/dataTableWrapperModel";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useToast } from 'src/components/Common/ReactToaster';
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import { courierList } from 'src/models/pages/couriersModel';
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction';
import { useNavigate } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Select from "src/components/Common/Select";
import { Option } from "src/models/components/inputWrapperModel";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import FeatherIcon from "feather-icons-react";
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import { clearCourierMessage } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { saveAsExcelFile } from 'src/helpers/common_helpers';
import { getTotalWorkingDaysLoading } from 'src/store/TotalWorkingDays/action';
import { API } from 'src/util/api';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';

function getMonthName(monthNumber:any) {
    // Create an array of month names starting from January (index 0)
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    if (monthNumber < 1 || monthNumber > 12) {
      throw new Error('Invalid month number. Month number should be between 1 and 12.');
    }
    return months[monthNumber - 1];
  }

const courierListConverson = (values: any) => {
  const modifeidValue = values?.map((eachValue: any) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['year'] = eachValue?.year;
    data['month'] = eachValue?.month ? getMonthName(Number(eachValue?.month)) : "";
    data['workingDays'] = eachValue?.workingDays;
    return data;
  });
  return modifeidValue;
};

const TotalWorkingDaysList = () => {

  const filterDetails: DataTableFilterMeta = {

  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [rowData, setRowData] = useState<any | null>(null);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCourier, setSelectedCourier] = useState<any[]>([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const dt = useRef<DataTable<any>>(null);


  const navigate = useNavigate();

  const onCreateClickHandler = () => {
    navigate(ROUTER.TOTAL_WORKING_DAYS_CREATE);
  };
  const { loading, error, message,totalWorkingDaysList,totalCount } = useSelector((state: RootReducerState) => state.totalWorkingDaysReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const modifiedValues: any[] = courierListConverson(totalWorkingDaysList);

//   const viewHandler = (rowData: courierList) => {
//     navigate(`${ROUTER.VIEW_COURIER}/${rowData["id"]}`);
//   };
useEffect(() => {
  const preloader: any = document.getElementById("preloader");
  if (loading||pdfLoading) {
    preloader.style.display = "block";
  } else {
    preloader.style.display = "none";
  }

}, [loading,pdfLoading]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearCourierMessage())
    }
  }, [error, message]);
  const editHandler = (rowData: courierList) => {
    navigate(`${ROUTER.TOTAL_WORKING_DAYS_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: courierList) => {
    setDeleteModal(true);
    setRowData(rowData);
  };



  const actionBodyTemplate = (rowData: courierList, value: string) => {
    return (

          <ColumnAction
            displayData={rowData[value] as string}
            isEdit={true}
            onEditClick={() => editHandler(rowData)}
          />

    );
  };







  const columns: Columns[] = [
    {
      field: "month",
      sortable: true,
      header: "Month",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      frozen : true,
      body: (rowData) => actionBodyTemplate(rowData, "month"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "year",
      sortable: true,
      header: "Year",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap"},
      isDefault: true,
      isStatic: false,
    },
    {
      field: "workingDays",
      sortable: true,
      header: "Working Days",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  const options: Option[] = [
    // { label: "Active", value: "Active" },
    // { label: "Deleted", value: "Deleted" },
    { label: "All", value: "all" },
    { label: "Package Sent", value: "packageSent" },
    { label: "Package Not Sent", value: "packageNotSent" },
  ];


  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const dispatch = useDispatch();
  const { showToast } = useToast();

  const hidePopup = () => {
    setVisible(false);
  }

  const defaultColumns = {
    ...columns[0]
  };

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    // dispatch(deletePurchaseOrderFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedCourier([]);
    }
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedCourier([]);
    setArchiveModal(false);
  };

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible} onHide={hidePopup} />);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const initialValues = {
    status: "",
  };

  const validationSchema = Yup.object({});

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == PACKAGESENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          All: { value: "false", matchMode: "contains" },
          // IsDeleted: { value: "false", matchMode: "contains" },
          packageSent: { value: "true", matchMode: "contains" },
          packageNotSent: { value: "false", matchMode: "contains" },

        },
      }));
    } else if (formValue == PACKAGENOTSENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          All: { value: "false", matchMode: "contains" },
          // IsDeleted: { value: "false", matchMode: "contains" },
          // Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "true", matchMode: "contains" },
          packageSent: { value: "false", matchMode: "contains" },
        },
      }));
    }else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          All: { value: "", matchMode: "contains" },
          // IsDeleted: { value: "false", matchMode: "contains" },
          // Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "", matchMode: "contains" },
          packageSent: { value: "", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          packageSent: { value: null, matchMode: "contains" },
          packageNotSent: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const handleStatusBlur = () => { };

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedCourier(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedCourier([]);
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      // data['id'] = eachValue?.id;
      data['Month'] = eachValue?.month;
      data['Year'] = eachValue?.year;
      data['Working Days'] = eachValue?.workingDays;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        month: data.month,
        year: data.year,
        workingDays: data.workingDays,

      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: modifiedValues,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalCount>5000 ? 5000 : totalCount<1 ? 1 : totalCount,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
	
    try {
      setPdfLoading(true)
      const response = await API.getTotalWorkingDaysAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['year'] = eachValue?.year;
        data['month'] = eachValue?.month ? getMonthName(Number(eachValue?.month)) : "";
        data['workingDays'] = eachValue?.workingDays;
        return data;
      });
      // let resp=await courierListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Working_Days");
     }else{
      saveDataAsExcel(modifeidValue, "Working_Days");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Working_Days");
    fetchData(false)

  };

  
  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Working_Days");
    fetchData(true)

  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Total Working Days
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
          <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            New Working Days
          </Button>
        </div>
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedCourier(value);
    // setSelectAll(value.length === totalRecords);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedCourier([]);
      } else if (selectedStatus == ACTIVE) {
        let len = modifiedValues?.length;
        let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        if (len === filterLen) {
          setSelectAll(true);
          setSelectedCourier(modifiedValues);
        } else {
          setSelectAll(false);
          setSelectedCourier(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        }
      }
      else {
        setSelectAll(true);
        setSelectedCourier(modifiedValues);
      }
    } else {
      setSelectAll(false);
      setSelectedCourier([]);
    }
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getTotalWorkingDaysLoading(lazyState));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedCourier([]);
    callbackFunction(lazyState);
    return () => {
      dispatch(clearCourierMessage());
    };
  }, [lazyState]);

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedCourier?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedCourier([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedCourier.length > 0) {
              for (let i = 0; i < selectedCourier.length; i++) {
                handleDeleteUsers(selectedCourier[i], selectedCourier.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these purchase order?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedCourier([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Total Working days  {last} records of {totalRecords} in total"
          selection={[]}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
        // rowClassName={rowClassName}
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default TotalWorkingDaysList