import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AdministratorTypes } from "./actionTypes";
import { API } from "src/util/api";
import { deleteAdministratorFromListError, deleteAdministratorFromListSuccess, getAdministratorList, getAdministratorListError, getAdministratorListSuccess, getMapKeyApiError, getMapKeyApiSuccess } from "./action";



function* getAdministratorListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAdministratorListAPI, { lazyEvent: JSON.stringify(action.lazyState) }
    );
    yield put(getAdministratorListSuccess(response));
  } catch (error) {
    yield put(getAdministratorListError(error));
  }
}

function* deleteAdministratorAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteAdministratorListAPI, { id: action.payload.id, lazyEvent: JSON.stringify(action) });
    yield put(deleteAdministratorFromListSuccess(response));
    yield put(getAdministratorList(action.lazyState));
  } catch (error) {
    yield put(deleteAdministratorFromListError(error));
  }
}


function* getMapKeyAsync() {
  try {
    const response: Promise<any> = yield call(API.getMapAPIKey);
    yield put(getMapKeyApiSuccess(response));
  } catch (error) {
    yield put(getMapKeyApiError(error));
  }
}


function* administratorSaga() {
  yield takeLatest(AdministratorTypes.GET_ADMINISTRATOR_LIST, getAdministratorListAsync);
  yield takeLatest(AdministratorTypes.DELETE_ADMINISTRATOR, deleteAdministratorAsync);
  yield takeLatest(AdministratorTypes.GET_MAP_KEY_API_LOADING, getMapKeyAsync);

}

export default administratorSaga;