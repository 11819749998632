import { VehicleBrandsTypes } from "./actionTypes";
import { vehicleBrandsInitialState } from "src/models/pages/vehicleBrandsModel";




const initialState: vehicleBrandsInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  vehicleBrandsDetails: null,
  vehicleBrandsList: {
    values: [],
    totalRecords: 0
  },
  vehicleBrandImage: "",
  dealerNames:""

};

export const vehicleBrandImageDeleteSuccess = "Vehicle Brand image is deleted successfully";

const VehicleBrands = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST:
    case VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS:
    case VehicleBrandsTypes.POST_VEHICLE_BRANDS:
    case VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID:
    case VehicleBrandsTypes.DELETE_VEHICLE_BRANDS:
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_LOADING:
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_LOADING:
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_DELETE_LOADING:
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        paginatorCount: 0
      };

    case VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicleBrandsList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length
      };

    case VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Vehicle brand is updated successfully"
      };

      case VehicleBrandsTypes.VEHICLE_BRAND_FILE_CLEAR:
        return{
          ...state,
          vehicleBrandImage:""
        }

    case VehicleBrandsTypes.POST_VEHICLE_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Vehicle brand is created successfully"
      };

    case VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        vehicleBrandsDetails: action.payload
      };

    case VehicleBrandsTypes.GET_DEALER_NAMES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        dealerNames: action.payload
      };

    case VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST_FAIL:
    case VehicleBrandsTypes.DELETE_VEHICLE_BRANDS_FAIL:
    case VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS_FAIL:
    case VehicleBrandsTypes.POST_VEHICLE_BRANDS_FAIL:
    case VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID_FAIL:
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_ERROR:
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_ERROR:
    case VehicleBrandsTypes.GET_DEALER_NAMES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.payload
      };

    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        vehicleBrandImage: action.payload,
      };
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: vehicleBrandImageDeleteSuccess,
        vehicleBrandImage: "",
      };
    case VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };
    case VehicleBrandsTypes.DELETE_VEHICLE_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo ? "Vehicle Brand restored successfully" : "Vehicle Brand deleted successfully",
      }

    case VehicleBrandsTypes.RESET_VEHICLE_BRANDS:
      return {
        ...state,
        error: "",
        loading: false,
        message: "",
      }

    case VehicleBrandsTypes.CLEAR_VEHICLE_BRANDS:
      return {
        ...state,
        ...initialState
      }

    default:
      return { ...state };
  }
};

export default VehicleBrands;
