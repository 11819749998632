export enum enquiryTypes {

    // Grid
    GET_ENQUIRY_LIST = "@@enquiry/GET_ENQUIRY_LIST",
    GET_ENQUIRY_LIST_SUCCESS = "@@enquiry/GET_ENQUIRY_LIST_SUCCESS",
    GET_ENQUIRY_LIST_FAIL = "@@enquiry/GET_ENQUIRY_LIST_FAIL",
  
    DELETE_ENQUIRY = "@@enquiry/DELETE_ENQUIRY_MODELS",
    DELETE_ENQUIRY_SUCCESS = "@@enquiry/DELETE_ENQUIRY_SUCCESS",
    DELETE_ENQUIRY_FAIL = "@@enquiry/DELETE_ENQUIRY_FAIL",
    
  
    RESET_ENQUIRY = "@@enquiry/RESET_ENQUIRY",
    CLEAR_ENQUIRY = "@@enquiry/CLEAR_ENQUIRY",
  
    // POST
  
    POST_ENQUIRY = "@@enquiry/POST_ENQUIRY",
    POST_ENQUIRY_SUCCESS = "@@enquiry/POST_ENQUIRY_SUCCESS",
    POST_ENQUIRY_FAIL = "@@enquiry/POST_ENQUIRY_FAIL",
  
    // GET BY ID
  
    GET_ENQUIRY_BY_ID = "@@enquiry/GET_ENQUIRY_BY_ID",
    GET_ENQUIRY_BY_ID_SUCCESS = "@@enquiry/GET_ENQUIRY_BY_ID_SUCCESS",
    GET_ENQUIRY_BY_ID_FAIL = "@@enquiry/GET_ENQUIRY_BY_ID_FAIL",

    GET_JOBSHEET_DROPDOWN = "@@enquiry/GET_JOBSHEET_DROPDOWN",
    GET_JOBSHEET_DROPDOWN_SUCCESS = "@@enquiry/GET_JOBSHEET_DROPDOWN_SUCCESS",
    GET_JOBSHEET_DROPDOWN_FAIL = "@@enquiry/GET_JOBSHEET_DROPDOWN_FAIL",

        // Employee Dropdown
    GET_EMPLOYEE_DROPDOWN_LIST = "@@enquiry/GET_EMPLOYEE_DROPDOWN_LIST",
    GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS = "@@enquiry/GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS",
    GET_EMPLOYEE_DROPDOWN_LIST_FAIL = "@@enquiry/GET_EMPLOYEE_DROPDOWN_LIST_FAIL",

    // Franchise Dropdown
    GET_FRANCHISE_DROPDOWN_LIST = "@@enquiry/GET_FRANCHISE_DROPDOWN_LIST",
    GET_FRANCHISE_DROPDOWN_LIST_SUCCESS = "@@enquiry/GET_FRANCHISE_DROPDOWN_LIST_SUCCESS",
    GET_FRANCHISE_DROPDOWN_LIST_FAIL = "@@enquiry/GET_FRANCHISE_DROPDOWN_LIST_FAIL",

    // Franchise Subuser Dropdown
    GET_FRANCHISE_SUBUSER_DROPDOWN_LIST = "@@enquiry/GET_FRANCHISE_SUBUSER_DROPDOWN_LIST",
    GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS = "@@enquiry/GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS",
    GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL = "@@enquiry/GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL",

    
    GET_FRANCHISE_JOBSHEET_DROPDOWN = "@@enquiry/GET_FRANCHISE_JOBSHEET_DROPDOWN",
    GET_FRANCHISE_JOBSHEET_DROPDOWN_SUCCESS = "@@enquiry/GET_FRANCHISE_JOBSHEET_DROPDOWN_SUCCESS",
    GET_FRANCHISE_JOBSHEET_DROPDOWN_FAIL = "@@enquiry/GET_FRANCHISE_JOBSHEET_DROPDOWN_FAIL"
  
  }
  