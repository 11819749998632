import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

// Crypto Redux States
import { Dashboard } from "./actiontype";
import { getMarketoverviewSuccess, getMarketoverviewFail, getWalletBalanceFail, getWalletBalanceSuccess, getInvestedOverviewSuccess, getInvestedOverviewFail, getDealerCountError, getDealerCountSuccess, getSupplierCountSuccess, getSupplierCountError, getFranchiseeCountSuccess, getFranchiseeCountError, getCustomerCountSuccess, getCustomerCountError, getJobsheetStatusSuccess, getJobsheetStatusError, getStockAdjustmentDashboardCountSuccess, getStockAdjustmentDashboardCountError, getJobsheetTrendSuccess, getJobsheetTrendError, getPurchaseOrderDashboardSuccess, getPurchaseOrderDashboardError, getJobsheetGraphDetailSuccess, getJobsheetGraphDetailError, getInvoiceCountSuccess, getInvoiceCountError, getReceivedPaymentError, getReceivedPaymentSuccess, getPendingPaymentSuccess, getPendingPaymentError } from "./actions";

//Include Both Helper File with needed methods
import {
  getMarketoverViewData, getWallentData, getInvestedData
} from "../../helpers/fakebackend_helper";
import { API } from "src/util/api";
import { getDealerContactSuccess } from "../dealer/actions";
import { cA } from "@fullcalendar/core/internal-common";

var res: Promise<any>

function* getChartsData({ payload: periodType }: any) {
  try {
    if (periodType == "ALL") {
      res = yield call(getMarketoverViewData, periodType);
    }
    if (periodType == "1M") {
      res = yield call(getMarketoverViewData, periodType);
    }
    if (periodType == "6M") {
      res = yield call(getMarketoverViewData, periodType);
    }
    if (periodType == "1Y") {
      res = yield call(getMarketoverViewData, periodType);
    }
    yield put(getMarketoverviewSuccess(Dashboard.GET_MARKET_OVERVIEW, res));
  } catch (error) {
    yield put(getMarketoverviewFail(Dashboard.GET_MARKET_OVERVIEW, error));
  }
}

var response: Promise<any>

function* getWalletBalance({ payload: data }: any) {
  try {
    if (data == "ALL") {
      response = yield call(getWallentData, data);
    }
    if (data == "6M") {
      response = yield call(getWallentData, data);
    }
    if (data == "1M") {
      response = yield call(getWallentData, data);
    }
    if (data == "1Y") {
      response = yield call(getWallentData, data);
    }
    yield put(getWalletBalanceSuccess(Dashboard.GET_WALLENT_BALANCE, response));
  }
  catch (error) {
    yield put(getWalletBalanceFail(Dashboard.GET_WALLENT_BALANCE, error));
  }
}
var investedData: Promise<any>

function* getInvestedOverviewData({ payload: data }: any) {
  try {
    if (data == "AP") {
      investedData = yield call(getInvestedData, data);
    }
    if (data == "MA") {
      investedData = yield call(getInvestedData, data);
    }
    if (data == "FE") {
      investedData = yield call(getInvestedData, data);
    }
    if (data == "JA") {
      investedData = yield call(getInvestedData, data);
    }
    if (data == "DE") {
      investedData = yield call(getInvestedData, data);
    }
    yield put(getInvestedOverviewSuccess(Dashboard.GET_Invested_Overview, investedData));
  }
  catch (error) {
    yield put(getInvestedOverviewFail(Dashboard.GET_Invested_Overview, error));
  }
}

function* getDealerCountAsync() {
  try {
    const response: Promise<any> = yield call(API.fetchDealerCountApi)
    yield put(getDealerCountSuccess(response));
  } catch (error) {
    yield put(getDealerCountError(error));
  }
}

function* getSupplierCountAsync() {
  try {
    const response: Promise<any> = yield call(API.fetchSupplierCountApi)
    yield put(getSupplierCountSuccess(response));
  } catch (error) {
    yield put(getSupplierCountError(error));
  }
}

function* getFranchiseeCountAsync() {
  try {
    const response: Promise<any> = yield call(API.fetchFranchiseeCountApi)
    yield put(getFranchiseeCountSuccess(response));
  } catch (error) {
    yield put(getFranchiseeCountError(error));
  }
}

function* getCustomerCountAsync() {
  try {
    const response: Promise<any> = yield call(API.fetchCustomerCountApi)
    yield put(getCustomerCountSuccess(response));
  } catch (error) {
    yield put(getCustomerCountError(error));
  }
}

function* getJobsheetStatusCountAsync(payload: any) {

  try {
    const response: Promise<any> = yield call(API.fetchJobsheetStatusCountApi, payload)
    yield put(getJobsheetStatusSuccess(response));
  } catch (error) {
    yield put(getJobsheetStatusError(error));
  }
}

//get stock adjustment dashboard count

function* getStockAdjusmentCountAsync(payload: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.fetchstockAdjustmentCountApi, payload);
    yield put(getStockAdjustmentDashboardCountSuccess(response));
  } catch (error) {
    yield put(getStockAdjustmentDashboardCountError(error));
  }
}

function* getJobsheetTrendCountAsync(payload: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.fetchJobsheetTrendCountApi, payload)
    yield put(getJobsheetTrendSuccess(response));
  } catch (error) {
    yield put(getJobsheetTrendError(error));
  }
}

function* PurchaseOrderDashboardDetailAsync(payload: any) {
  try {
    const response: Promise<any> = yield call(API.fetchPurchaseOrderDashboardApi, payload)
    yield put(getPurchaseOrderDashboardSuccess(response));
  } catch (error) {
    yield put(getPurchaseOrderDashboardError(error));
  }
}

function* JobsheetGraphDetailAsync() {
  try {
    const response: Promise<any> = yield call(API.fetchJobsheetGraphDetailApi)
    yield put(getJobsheetGraphDetailSuccess(response));
  } catch (error) {
    yield put(getJobsheetGraphDetailError(error));
  }
}

function* getInvoiceCountAsync(){
  debugger
  try {
    const response : Promise<any> = yield call(API.getInvoiceCountApi);
    yield put(getInvoiceCountSuccess(response));
  } catch (error) {
    yield put(getInvoiceCountError(error))
  }
}

function* getReceivedPaymentCountAsync(){
  try {
    const response : Promise<any> = yield call(API.getReceivedpaymentCountApi);
    yield put(getReceivedPaymentSuccess(response));
  } catch (error) {
    yield put(getReceivedPaymentError(error))
  }
}


function* getPendingPaymentCountAsync(){
  try {
    const response : Promise<any> =  yield call(API.getPendingpaymentCountApi);
    yield put(getPendingPaymentSuccess(response));
  } catch (error) {
    yield put(getPendingPaymentError(error))
  }
}


export function* watchGetChartsData() {
  yield takeEvery(Dashboard.GET_MARKET_OVERVIEW, getChartsData);
  yield takeEvery(Dashboard.GET_WALLENT_BALANCE, getWalletBalance);
  yield takeEvery(Dashboard.GET_Invested_Overview, getInvestedOverviewData);
  yield takeLatest(Dashboard.GET_DEALER_COUNT, getDealerCountAsync);
  yield takeLatest(Dashboard.GET_SUPPLIER_COUNT, getSupplierCountAsync);
  yield takeLatest(Dashboard.GET_FRANCHISEE_COUNT, getFranchiseeCountAsync);
  yield takeLatest(Dashboard.GET_CUSTOMER_COUNT, getCustomerCountAsync);
  yield takeLatest(Dashboard.GET_JOBSHEET_STATUS_COUNT, getJobsheetStatusCountAsync);
  yield takeLatest(Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_LOADING, getStockAdjusmentCountAsync)
  yield takeLatest(Dashboard.GET_JOBSHEET_TREND_COUNT, getJobsheetTrendCountAsync);
  yield takeLatest(Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_LOADING, PurchaseOrderDashboardDetailAsync);
  yield takeLatest(Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_LOADING, JobsheetGraphDetailAsync);
  yield takeLatest(Dashboard.GET_INVOICE_COUNT_DASHBOARD_LOADING,getInvoiceCountAsync);
  yield takeLatest(Dashboard.GET_RECEIVED_PAYMENT_COUNT_LOADING,getReceivedPaymentCountAsync);
  yield takeLatest(Dashboard.GET_PENDINGPAYMENT_COUNT_LOADING,getPendingPaymentCountAsync);
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;
