import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { API } from 'src/util/api';
import { DealerSubUserType } from './actiontypes';
import { createDealerSubuserError, createDealerSubuserSucess, dealerSubuserLatLagError, dealerSubuserLatLagSuccess, deleteDealerSubuserError, deleteDealerSubuserProfileError, deleteDealerSubuserProfileSuccess, deleteDealerSubuserSuccess, getDealerSubuserByIdError, getDealerSubuserByIdSuccess, getDealerSubuserListError, getDealerSubuserListSuccess, getDealerSubuserProfileError, getDealerSubuserProfileSuccess, resendOtpDealerSubuserError, resendOtpDealerSubuserSuccess, sendDealerSubuserOtpError, sendDealerSubuserOtpSuccess, setDealerSubuserEmailIdError, setDealerSubuserEmailIdSuccess, setDealerSubuserMobileNumberError, setDealerSubuserMobileNumberSuccess, updateDealerSubuserError, updateDealerSubuserSuccess, uploadDealerSubuserProfileError, uploadDealerSubuserProfileSuccess } from './action';
import { DealersubuserListModel } from 'src/models/pages/dealersubuserModel';



//worker function

//getDealersubuser list

function* getDealerSubuserListAsync(action: any) {
  try {
    const response: Promise<DealersubuserListModel> = yield call(API.getDealerSubUserListAPI, { ...action.lazyState })
    yield put(getDealerSubuserListSuccess(response))
  }
  catch (error) {
    yield put(getDealerSubuserListError(error))
  }
}


//create Franchisee
function* createDealerSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.createDealerSubUserApi, action.payload);
    yield put(createDealerSubuserSucess(response))
  }
  catch (error) {
    yield put(createDealerSubuserError(error))
  }
}

//get franchisee details by id

function* getInduvidualDealerSubuser(action: any) {
  try {
    const response: Promise<any> = yield call(API.getIndividualDealerSubUserApi, action.payload)
    yield put(getDealerSubuserByIdSuccess(response))
  } catch (error) {
    yield put(getDealerSubuserByIdError(error))
  }
}

//update franchisee 

function* updateDealerSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.updateDealerSubUserApi, action.payload, action.id);
    yield put(updateDealerSubuserSuccess(response))
  } catch (error) {
    yield put(updateDealerSubuserError(error))
  }
}

//delete franchisee

function* deleteDealerSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteDealerSubUserApi, { id: action.payload.id, undo:action.payload.undo });
    yield put(deleteDealerSubuserSuccess(response,action.payload.undo));
  } catch (error) {
    yield put(deleteDealerSubuserError(error))
  }
}

function* getDealerSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.fetchDealerSubuserImage,action.id );
    yield put(getDealerSubuserProfileSuccess(response));
  } catch (error) {
    yield put(getDealerSubuserProfileError(error))
  }
}

function* uploadDealerSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.uploadDealerSubuserImage, action.payload,action.id);
    yield put(uploadDealerSubuserProfileSuccess(response));
  } catch (error) {
    yield put(uploadDealerSubuserProfileError(error))
  }
}

function* deleteDealerSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteDealerSubuserImage, action.id);
    yield put(deleteDealerSubuserProfileSuccess(response));
  } catch (error) {
    yield put(deleteDealerSubuserProfileError(error))
  }
}

function* setDealerSubuserMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setDealerSubuserMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setDealerSubuserMobileNumberError(error))
  }
}

function* setDealerSubuserEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setDealerSubuserEmailApi, action.payload.id,action.payload.email);
    yield put(setDealerSubuserEmailIdSuccess(response))
  } catch (error) {
    yield put(setDealerSubuserEmailIdError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendDealerSubuserOtpSuccess(response))
  } catch (error) {
    yield put(sendDealerSubuserOtpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpDealerSubuserSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpDealerSubuserError(error))
  }
}

function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(dealerSubuserLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(dealerSubuserLatLagError(error));
  }
}
//watcher function

function* subuserSaga() {
  yield takeLatest(DealerSubUserType.GET_DEALERSUBUSER_LIST_LOADING, getDealerSubuserListAsync);
  yield takeLatest(DealerSubUserType.CREATE_DEALERSUBUSER_LOADING, createDealerSubuserAsync);
  yield takeEvery(DealerSubUserType.GET_DEALERSUBUSERBYID_LOADING, getInduvidualDealerSubuser);
  yield takeLatest(DealerSubUserType.UPDATE_DEALERSUBUSER_LOADING, updateDealerSubuserAsync);
  yield takeLatest(DealerSubUserType.DELETE_DEALERSUBUSER_LOADING, deleteDealerSubuserAsync);
  yield takeLatest(DealerSubUserType.GET_DEALERSUBUSER_PROFILE_LOADING, getDealerSubuserProfileAsync);
  yield takeLatest(DealerSubUserType.UPLOAD_DEALERSUBUSER_PROFILE_LOADING, uploadDealerSubuserProfileAsync);
  yield takeLatest(DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_LOADING, deleteDealerSubuserProfileAsync);
  yield takeLatest(DealerSubUserType.DEALERSUBUSER_SET_PHONE_LOADING, setDealerSubuserMobileAsync);
  yield takeLatest(DealerSubUserType.DEALERSUBUSER_SET_EMAIL_LOADING, setDealerSubuserEmailAsync);
  yield takeLatest(DealerSubUserType.SEND_OTP_DEALERSUBUSER, sendOtpAsync);
  yield takeLatest(DealerSubUserType.RESEND_DEALERSUBUSER_OTP, resendOtpAsync);
  yield takeLatest(DealerSubUserType.GET_DEALERSUBUSER_LOCATION_LOADING, getlatLngAsync);
}

export default subuserSaga