export enum DealerSubUserType {

    //Grid
    GET_DEALERSUBUSER_LIST_LOADING = "@@dealersubuser/GET_DEALERSUBUSER_LIST_LOADING",
    GET_DEALERSUBUSER_LIST_SUCCESS = "@@dealersubuser/GET_DEALERSUBUSER_LIST_SUCCESS",
    GET_DEALERSUBUSER_LIST_ERROR = "@@dealersubuser/GET_DEALERSUBUSER_LIST_ERROR",

    //Delete Grid
    DELETE_DEALERSUBUSER_LOADING = "@@dealersubuser/DELETE_DEALERSUBUSER_LOADING",
    DELETE_DEALERSUBUSER_SUCCESS = "@@dealersubuser/DELETE_DEALERSUBUSER_SUCCESS",
    DELETE_DEALERSUBUSER_ERROR = "@@dealersubuser/DELETE_DEALERSUBUSER_ERROR",

    //reset Dealersubuser data form
    RESET_DEALERSUBUSER = "@@dealersubuser/RESET_DEALERSUBUSER",
    CLEAR_ERROR_MESSAGE_DEALERSUBUSER = "@@dealersubuser/CLEAR_ERROR_MESSAGE_DEALERSUBUSER",

    //Create DealersubUser
    CREATE_DEALERSUBUSER_LOADING = "@@dealersubuser/CREATE_DEALERSUBUSER_LOADING",
    CREATE_DEALERSUBUSER_SUCCESS = "@@dealersubuser/CREATE_DEALERSUBUSER_SUCCESS",
    CREATE_DEALERSUBUSER_ERROR = "@@dealersubuser/CREATE_DEALERSUBUSER_ERROR",

    //Update DealersubUser
    UPDATE_DEALERSUBUSER_LOADING = "@@dealersubuser/UPDATE_DEALERSUBUSER_LOADING",
    UPDATE_DEALERSUBUSER_SUCCESS = "@@dealersubuser/UPDATE_DEALERSUBUSER_SUCCESS",
    UPDATE_DEALERSUBUSER_ERROR = "@@dealersubuser/UPDATE_DEALERSUBUSER_ERROR",

    //getDealersubUser details by id
    GET_DEALERSUBUSERBYID_LOADING = "@@dealersubuser/GET_DEALERSUBUSERBYID_LOADING",
    GET_DEALERSUBUSERBYID_SUCCESS = "@@dealersubuser/GET_DEALERSUBUSERBYID_SUCCESS",
    GET_DEALERSUBUSERID_ERROR = "@@dealersubuser/GET_DEALERSUBUSERID_ERROR",

    GET_DEALERSUBUSER_PROFILE_LOADING = "@@dealersubuser/GET_DEALERSUBUSER_PROFILE_LOADING",
    GET_DEALERSUBUSER_PROFILE_SUCCESS = "@@dealersubuser/GET_DEALERSUBUSER_PROFILE_SUCCESS",
    GET_DEALERSUBUSER_PROFILE_ERROR = "@@dealersubuser/GET_DEALERSUBUSER_PROFILE_ERROR",

    UPLOAD_DEALERSUBUSER_PROFILE_LOADING = "@@dealersubuser/UPLOAD_DEALERSUBUSER_PROFILE_LOADING",
    UPLOAD_DEALERSUBUSER_PROFILE_SUCCESS = "@@dealersubuser/UPLOAD_DEALERSUBUSER_PROFILE_SUCCESS",
    UPLOAD_DEALERSUBUSER_PROFILE_ERROR = "@@dealersubuser/UPLOAD_DEALERSUBUSER_PROFILE_ERROR",

    DELETE_DEALERSUBUSER_PROFILE_LOADING = "@@dealersubuser/DELETE_DEALERSUBUSER_PROFILE_LOADING",
    DELETE_DEALERSUBUSER_PROFILE_SUCCESS = "@@dealersubuser/DELETE_DEALERSUBUSER_PROFILE_SUCCESS",
    DELETE_DEALERSUBUSER_PROFILE_ERROR = "@@dealersubuser/DELETE_DEALERSUBUSER_PROFILE_ERROR",

    DEALERSUBUSER_SET_PHONE_LOADING="@@dealersubuser/DEALERSUBUSER_SET_PHONE_LOADING",
    DEALERSUBUSER_SET_PHONE_SUCCESS="@@dealersubuser/DEALERSUBUSER_SET_PHONE_SUCCESS",
    DEALERSUBUSER_SET_PHONE_ERROR="@@dealersubuser/DEALERSUBUSER_SET_PHONE_ERROR",

    DEALERSUBUSER_SET_EMAIL_LOADING="@@dealersubuser/DEALERSUBUSER_SET_EMAIL_LOADING",
    DEALERSUBUSER_SET_EMAIL_SUCCESS="@@dealersubuser/DEALERSUBUSER_SET_EMAIL_SUCCESS",
    DEALERSUBUSER_SET_EMAIL_ERROR="@@dealersubuser/DEALERSUBUSER_SET_EMAIL_ERROR",

    SEND_OTP_DEALERSUBUSER ="@@dealer/SEND_OTP_DEALERSUBUSER",
    SEND_OTP_DEALERSUBUSER_SUCCESS ="@@dealer/SEND_OTP_DEALERSUBUSER_SUCCESS",
    SEND_OTP_DEALERSUBUSER_ERROR ="@@dealer/SEND_OTP_DEALERSUBUSER_ERROR",

    RESEND_DEALERSUBUSER_OTP = "@@dealer/RESEND_DEALERSUBUSER_OTP",
    RESEND_DEALERSUBUSER_OTP_SUCCESS = "@@dealer/RESEND_DEALERSUBUSER_OTP_SUCCESS",
    RESEND_DEALERSUBUSER_OTP_ERROR = "@@dealer/RESEND_DEALERSUBUSER_OTP_ERROR",
    
    RESET_DEALERSUBUSER_OTP_MESSAGE = "@@dealer/RESET_DEALERSUBUSER_OTP_MESSAGE",

    GET_DEALERSUBUSER_LOCATION_LOADING="@@dealer/GET_DEALERSUBUSER_LOCATION_LOADING",
    GET_DEALERSUBUSER_LOCATION_SUCCESS="@@dealer/GET_DEALERSUBUSER_LOCATION_SUCCESS",
    GET_DEALERSUBUSER_LOCATION_ERROR="@@dealer/GET_DEALERSUBUSER_LOCATION_ERROR",
}