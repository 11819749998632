// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//     apiKey: "AIzaSyCdTRQFPmS1ftMrl0jT9NhpAc0_ZMcwmpk",
//     authDomain: "himachalisystem.firebaseapp.com",
//     projectId: "himachalisystem",
//     storageBucket: "himachalisystem.appspot.com",
//     messagingSenderId: "326666630289",
//     appId: "1:326666630289:web:db32388105665c3b727b30",
//     measurementId: "G-SK1SHS8H2N"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAaW1qsm3feq68Z2XlT0w_bs9-DgmusMYU",
  authDomain: "mount-electronics-mobile-apps.firebaseapp.com",
  projectId: "mount-electronics-mobile-apps",
  storageBucket: "mount-electronics-mobile-apps.appspot.com",
  messagingSenderId: "1057397676692",
  appId: "1:1057397676692:web:eb314bed388841d39cb281",
  measurementId: "G-XXJQGFBJ33",
};
//old vapid:- "BObr2kB1HIzChYalgQwu6I0aEgQmIZZahbz02MItas1yH2z3QGFo72KS_BOfDo8hjy_-IKGezw3tPtnvWxNc2xw"
//new vapid:- "BG-dupQJjTlomxBLcvHH46MK5H9KhT8sVZYmwQ1gqT0FtdpxwrN3rv6UGrGK84PLFjaySmtmgvVkscBsDyw6m0I"

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  // const permission = await Notification.requestPermission();

  // let token = ""
  // if (permission === "granted") {
  //     const createtoken = await getToken(messaging, {
  //         vapidKey: "BAm1em-dYYFRQC3JdwHYpHiRzqQK64sAaQwLFqcI8fspHPJgRvr0AmmQYS1Xpx_QGpikh8g0uVpdP-M6cjpRTeo"
  //     })

  //    return token = createtoken
  // }else{
  //     return token
  // }

  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Notification permissions not granted");
      return "";
    }
    const createtoken = await getToken(messaging, {
      vapidKey:
        "BAm1em-dYYFRQC3JdwHYpHiRzqQK64sAaQwLFqcI8fspHPJgRvr0AmmQYS1Xpx_QGpikh8g0uVpdP-M6cjpRTeo",
    });
    if (!createtoken) {
      console.warn("Failed to generate token");
      return "";
    }
    return createtoken;
  } catch (error) {
    console.error("Error generating token:", error);
    return "";
  }
};
