export enum StockQtybyPartTypes {

    // Grid
    GET_STOCK_QTY_BY_PART_LIST = "@@stockQtybyPart/GET_STOCK_QTY_BY_PART_LIST",
    GET_STOCK_QTY_BY_PART_LIST_SUCCESS = "@@stockQtybyPart/GET_STOCK_QTY_BY_PART_LIST_SUCCESS",
    GET_STOCK_QTY_BY_PART_LIST_FAIL = "@@stockQtybyPart/GET_STOCK_QTY_BY_PART_LIST_FAIL",

    // RESET

    RESET_STOCK_QTY_BY_PART_LIST = "@@stockQtybyPart/RESET_STOCK_QTY_BY_PART_LIST",
  
    GET_PART_LIST_LOADING = "@@stockValuebyParts/GET_PART_LIST_LOADING",
    GET_PART_LIST_SUCCESS = "@@stockValuebyParts/GET_PARTS_LIST_SUCCESS",
    GET_PART_LIST_FAIL = "@@stockValuebyParts/GET_PARTS_LIST_FAIL",
  }
  