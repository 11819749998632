import { contactModal } from './../../models/pages/dealerModel';
import { ContactDetails } from './../../components/UI/CreateDealer';
import axios from "axios";
import {
  addDealerDetailError,
  addDealerSuccess,
  dealerLatLagError,
  dealerLatLagSuccess,
  dealerTaxTypeListError,
  dealerTaxTypeListSuccess,
  deleteDealerContactError,
  deleteDealerContactSuccess,
  deleteDealerFromListError,
  deleteDealerFromListSuccess,
  deleteDealerImageError,
  deleteDealerImageSuccess,
  getDealerContactSuccess,
  getDealerDetail,
  getDealerDetailSuccess,
  getDealerImageError,
  getDealerImageSuccess,
  getDealerList,
  getDealerListError,
  getDealerListSuccess,
  getDealersError,
  getDealersSuccess,
  resendOtpDealerError,
  resendOtpDealerSuccess,
  sendDealerOtpError,
  sendDealerOtpSuccess,
  setDealerEmailIdError,
  setDealerEmailIdSuccess,
  setDealerMobileNumberError,
  setDealerMobileNumberSuccess,
  updateDealerDetailError,
  updateDealerSuccess,
  uploadDealerFileError,
  uploadDealerFileSuccess,
} from "./actions";
import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { DealerActionTypes } from "./actionTypes";
import {
  getDealer,
  getDealers,
  postDealer,
} from "src/helpers/fakebackend_helper";
import { API } from "src/util/api";
import { DealersModal} from "src/models/pages/dealerModel";
import { CustomerRoot } from 'src/models/pages/customerModel';

function* createDealer({ payload }: any) {
  try {
    const response: DealersModal = yield call(API.createDealer, payload);
    yield put(addDealerSuccess(response));
    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(addDealerDetailError(error));
  }
}

function* updateDealer({
  payload: { dealer, dealerId },
}: any): Generator<any, void, any> {
  try {

    let contactModal : contactModal = {
      userId: dealer?.contactDetails?.userId,
      contactDetails:  dealer?.contactDetails?.contactDetails
    }
    if ('contactDetails' in dealer){
      delete dealer['contactDetails']
    }
    const { data } = yield call(API.updateDealer, dealer, dealerId);
    if(contactModal?.contactDetails?.length > 0){
      const { dataOfContact } = yield call(API.updateDealerContact, contactModal);
    }
    yield put(updateDealerSuccess(data));

  } catch (error) {
    yield put(updateDealerDetailError(error));
  }
}

function* fetchDealers() {
  try {
    const response: DealersModal[] = yield call(getDealers);
    yield put(getDealersSuccess(response));
  } catch (error) {
    yield put(getDealersError(error));
  }
}

function* fetchDealer({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getDealer, payload);
    yield put(getDealerDetailSuccess(data));
    let { data: contact } = yield call(API.getDealerContact, data?.user?.id);
    yield put(getDealerContactSuccess(contact?.contactDetails))
  } catch (error) {
    yield put(getDealersError(error));
  }
}

function* getDealerListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getDealersListAPI, {
      ...action.lazyState,
    });
    yield put(getDealerListSuccess(response));
  } catch (error) {
    yield put(getDealerListError(error));
  }
}

function* deleteDealerAsync(action: any) {

  try {
    const response: Promise<any> = yield call(API.deleteDealersListAPI, {
      id: action.payload.id,
      undo:action.payload.undo
    });
    yield put(deleteDealerFromListSuccess(response,action.payload.undo));
  } catch (error) {
    yield put(deleteDealerFromListError(error));
  }
}

function* deleteDealerContactAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteDealersContactAPI, {
      id: action.payload.id,
    });
    yield put(deleteDealerContactSuccess(response));
  } catch (error) {
    yield put(deleteDealerContactError(error));
  }
}

//getCustomer list
function* getDealerTaxListAsync() {

  try {
    const response: Promise<any> = yield call(API.getDealerTaxesList)
    yield put(dealerTaxTypeListSuccess(response));
  } catch (error) {
    yield put(dealerTaxTypeListError(error));
  }
}



function* getDealerImageAsync({ payload }: any) {
  console.log("Payload id",payload);
  
  try {
    const response: Promise<any> = yield call(API.fetchDealerImage, payload)
      yield put(getDealerImageSuccess(response));
  } catch (error) {
    yield put(getDealerImageError(error));
  }
}

function* deleteDealerImageAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.deleteDealerImage, payload)

    yield put(deleteDealerImageSuccess(response));
  } catch (error) {
    yield put(deleteDealerImageError(error));
  }
}

//update customer
function* uploadDealerFileAsync(action: any) {

  try {

    const response: Promise<any>= yield call(API.uploadDealerImage, action.payload, { id: action.id });
      yield put(uploadDealerFileSuccess(response))
    
  } catch (error) {
    yield put(uploadDealerFileError(error))
  }
}

function* setDealerMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setDealerMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setDealerMobileNumberError(error))
  }
}

function* setDealerEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setDealerEmailApi, action.payload.id,action.payload.email);
    yield put(setDealerEmailIdSuccess(response))
  } catch (error) {
    yield put(setDealerEmailIdError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendDealerOtpSuccess(response))
  } catch (error) {
    yield put(sendDealerOtpError(error))
  }
}
function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpDealerSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpDealerError(error))
  }
}

function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(dealerLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(dealerLatLagError(error));
  }
}

function* DealerSaga() {
  yield takeEvery(DealerActionTypes.GET_DEALERS, fetchDealers);
  yield takeEvery(DealerActionTypes.GET_DEALER_DETAIL, fetchDealer);
  yield takeLatest(DealerActionTypes.ADD_DEALER_DETAIL, createDealer);
  yield takeEvery(DealerActionTypes.UPDATE_DEALER_DETAIL, updateDealer);
  //
  yield takeLatest(DealerActionTypes.GET_DEALER_LIST, getDealerListAsync);
  yield takeLatest(DealerActionTypes.DELETE_DEALER, deleteDealerAsync);
  yield takeLatest(DealerActionTypes.DELETE_DEALER_CONTACT_LOADING, deleteDealerContactAsync);
  yield takeLatest(DealerActionTypes.DEALER_TAX_TYPE_LIST_LOADING, getDealerTaxListAsync);

  
  yield takeLatest(DealerActionTypes.DEALER_FILE_UPLOAD_LOADING, uploadDealerFileAsync);
  yield takeLatest(DealerActionTypes.DEALER_FILE_FETCH_LOADING, getDealerImageAsync);
  yield takeLatest(DealerActionTypes.DEALER_FILE_DELETE_LOADING, deleteDealerImageAsync);
  yield takeLatest(DealerActionTypes.DEALER_SET_PHONE_LOADING, setDealerMobileAsync);
  yield takeLatest(DealerActionTypes.DEALER_SET_EMAIL_LOADING, setDealerEmailAsync);
  yield takeLatest(DealerActionTypes.SEND_OTP_DEALER, sendOtpAsync);
  yield takeLatest(DealerActionTypes.RESEND_DEALER_OTP, resendOtpAsync);
  yield takeLatest(DealerActionTypes.GET_DEALER_LOCATION_LOADING, getlatLngAsync);

}

export default DealerSaga;
