import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import { ROUTER } from 'src/constants/routes';
import { clearErrorMessage, createDealerSubuserLoading, dealerSubuserLatLagLoading, deleteDealerSubuserProfileLoading, getDealerSubuserByIdLoading, getDealerSubuserProfileLoading, resendOtpDealerSubuser, resetDealerSubuser, resetOtpDealerSubuserMessage, sendDealerSubuserOtpLoading, setDealerSubuserEmailIdLoading, setDealerSubuserMobileNumberLoading, updateDealerSubuserLoading, uploadDealerSubuserProfileLoading } from 'src/store/dealersubuser/action';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";
import { getLocationLatLng, hasAdminOrEmployeeRoleHandler, hasDealerRoleHandler, onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import Dropzone from 'react-dropzone';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import DeleteModal from '../Calendar/DeleteModal';
import FeatherIcon from "feather-icons-react";
import OtpScreen from '../Customers/OtpScreen';
import { Dialog } from 'primereact/dialog';
import { APPENV_URL } from 'src/util/appUrlEnv';

const AddEditDealerSubuser = () => {
  const [visible, setVisible] = useState(false);
  const { id, sub_id }: any = useParams();
  const { showToast } = useToast();
  const location = useLocation();
  const isOnCreatePage = location.pathname === '/dealer_subuser/create';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [customError, setcustomError] = useState(false);
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");
  const [apiKey, setApiKey] = useState<string | null>(null);

  const { error, loading, message, dealerSubuserImage, phoneMessage, mailMessage, createdData, editedMobileNumber, otpmessage, updatedmessage, dealerSubuserLatLng } = useSelector((state: RootReducerState) => state.dealerSubUserReducer);
  const { dealerSubUserDetails } = useSelector((state: RootReducerState) => state.dealerSubUserReducer.dealerSubUserList);
  const url = process.env.REACT_APP_API_CONFIGURATION + "/api/abp/application-configuration";;
  const token = localStorage.getItem("token");
  // const hasAdminOrEmployeeRole = useFetchDataAndCheckRoles(url, token);
  const hasAdminOrEmployeeRole = hasAdminOrEmployeeRoleHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const convertNumber = (phone: any) => {
    let data = parseInt(phone);
    return data;
  };


  const initialvalue: any = {
    name: dealerSubUserDetails?.name ? dealerSubUserDetails?.name : "",
    userName: dealerSubUserDetails?.userName ? dealerSubUserDetails?.userName : "",
    email: dealerSubUserDetails?.email ? dealerSubUserDetails?.email : "",
    password: "",
    confirmpassword: "",
    phoneNumber: convertNumber(dealerSubUserDetails?.phoneNumber) ? dealerSubUserDetails?.phoneNumber : "",
    sendSms: dealerSubUserDetails?.extraProperties?.SendSms ? dealerSubUserDetails?.extraProperties?.SendSms : false,
    sendEmail: dealerSubUserDetails?.extraProperties?.SendEmail ? dealerSubUserDetails?.extraProperties?.SendEmail : false,
    sendWhatsapp: dealerSubUserDetails?.extraProperties?.SendWhatsapp ? dealerSubUserDetails?.extraProperties?.SendWhatsapp : false,
    address: dealerSubUserDetails?.extraProperties?.Address ? dealerSubUserDetails?.extraProperties?.Address : "",
    isActive: dealerSubUserDetails?.isActive ? dealerSubUserDetails?.isActive : false,
    isVerfied: dealerSubUserDetails?.extraProperties?.IsVerfied ? dealerSubUserDetails?.extraProperties?.IsVerfied : false,
    lockoutEnabled: dealerSubUserDetails?.lockoutEnabled ? dealerSubUserDetails?.lockoutEnabled : false,
    alternateNumber : dealerSubUserDetails?.extraProperties?.AlternateNumber ? dealerSubUserDetails?.extraProperties?.AlternateNumber : ""
  };

  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    if (dealerSubUserDetails?.extraProperties?.Address) {
      dispatch(dealerSubuserLatLagLoading(dealerSubUserDetails?.extraProperties?.Address))
    }
  }, [dealerSubUserDetails]);
  //validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`UserName is required`),
    email: Yup.string().required(`Email is required`).email("Enter a valid email"),
    address: Yup.string().required(`Address is required`),

    // password: Yup.string().required(`Password is required`),
    // // address: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),
    // confirmpassword: Yup.string().required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match'),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
  });
  const editValidation = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`UserName is required`),
    email: Yup.string().required(`Email is required`).email("Enter a valid email"),
    address: Yup.string().required(`Address is required`),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
  });


  const hidePopup = () => {
    setVisible(false);
    dispatch(clearErrorMessage());
  };
  //validation
  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  //handle reset
  const handleReset = (resetForm: any) => {
    resetForm();
    if (!hasAdminOrEmployeeRole && id) {
      navigate(ROUTER.DEALER_SUB_USER_GRID);
    }
    if (hasAdminOrEmployeeRole && id) {
      navigate(ROUTER.DEALERS_GRID);
    }
  };

  const submitFunction = (values: any) => {
    if (isSubmitting) return;
    values.phoneNumber = String(values.phoneNumber);
    const { password, confirmpassword,alternateNumber, ...data } = values;
    const formateddata = {
      user: {
        ...data,
        userId: hasDealerRoleHandler() ? 0 : (dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId : sub_id),
        alternateNumber : alternateNumber?.toString()
      }
    };

    if (id) {
      const { phoneNumber, ...rest } = formateddata.user;
      dispatch(updateDealerSubuserLoading({ user: rest }, id));
      if (selectedFiles.length > 0) {
        dispatch(uploadDealerSubuserProfileLoading(selectedFiles[0], id));
      }
    } else {
      dispatch(createDealerSubuserLoading(formateddata));
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  useEffect(() => {
    const storedFormValues = localStorage.getItem('formvalues');
    if (storedFormValues) {
      try {
        const parsedFormValues = JSON.parse(storedFormValues);
        // Set the initial values with the retrieved form values
        setInitialValues(parsedFormValues);
      } catch (error) {
        console.error('Error parsing localStorage value:', error);
      }
    }
    if (!id) {
      getLocationLatLng(setLatitude, setLongitude);
    }
    return () => {
      dispatch(resetOtpDealerSubuserMessage());
    };
  }, []);

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
    }
  }, [error]);

  useEffect(() => {
    if (message != "" && message != undefined) {
      setIsSubmitting(false);
      localStorage.removeItem('formvalues');
      showToast(message, { type: "success" });
      // console.log("157 ----> ",hasAdminOrEmployeeRole)
      // // if (!hasAdminOrEmployeeRole) {
      // if (hasDealerRoleHandler()) {
      //   navigate(ROUTER.DEALER_SUB_USER_GRID);
      // } else {
      //   // navigate(`${ROUTER.DEALERS_EDIT}/${state?.formValue?.id ? state?.formValue?.id : initialValues.id ? initialValues.id : state?.id}`);
      //   navigate(`${ROUTER.DEALERS_EDIT}/${dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId : sub_id }`);
      //   // dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId :  initialValues.id
      // }
      // dispatch(clearErrorMessage())
    }
  }, [message]);

  useEffect(() => {
    if (updatedmessage != "" && updatedmessage != undefined) {
      setIsSubmitting(false);
      localStorage.removeItem('formvalues');
      showToast(updatedmessage, { type: "success" });

      // if (!hasAdminOrEmployeeRole) {
      if (hasDealerRoleHandler()) {
        navigate(ROUTER.DEALER_SUB_USER_GRID);
      } else {
        // navigate(`${ROUTER.DEALERS_EDIT}/${state?.formValue?.id ? state?.formValue?.id : initialValues.id ? initialValues.id : state?.id}`);
        navigate(`${ROUTER.DEALERS_EDIT}/${dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId : sub_id}`);
        // dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId :  initialValues.id
      }
      dispatch(clearErrorMessage());
    }
  }, [updatedmessage]);

  useEffect(() => {
    if (otpmessage != "" && otpmessage != undefined) {
      setOpenOtp(false);
      setIsSubmitting(false);
      localStorage.removeItem('formvalues');
      showToast(otpmessage, { type: "success" });
      // console.log("157 ----> ",hasAdminOrEmployeeRole)
      // if (!hasAdminOrEmployeeRole) {
      if (hasDealerRoleHandler()) {
        navigate(ROUTER.DEALER_SUB_USER_GRID);
      } else {
        // navigate(`${ROUTER.DEALERS_EDIT}/${state?.formValue?.id ? state?.formValue?.id : initialValues.id ? initialValues.id : state?.id}`);
        navigate(`${ROUTER.DEALERS_EDIT}/${dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId : sub_id}`);
        // dealerSubUserDetails?.extraProperties?.DealerId ? dealerSubUserDetails?.extraProperties?.DealerId :  initialValues.id
      }
      dispatch(clearErrorMessage());
    }
  }, [otpmessage]);

  useEffect(() => {
    if (id) {
      dispatch(getDealerSubuserByIdLoading(id));
      dispatch(getDealerSubuserProfileLoading(id));
    }
    return () => {
      dispatch(resetDealerSubuser());
      setLatitude("");
      setLongitude("");
      setAddress("");
    };
  }, [id]);
  useEffect(() => {
    if (mailMessage != "" && mailMessage != undefined) {
      showToast(mailMessage, { type: "success" });
      dispatch(getDealerSubuserByIdLoading(id));
      setEmailEdit(false);
    }
  }, [mailMessage]);
  useEffect(() => {
    if (phoneMessage != "" && phoneMessage != undefined) {
      showToast(phoneMessage, { type: "success" });
      // dispatch(getDealerSubuserByIdLoading(id));
      setPhoneEdit(false);
      setOpenOtp(true);
    }
  }, [phoneMessage]);
  //handle submit
  const handleSubmit = async (values: any) => {
    await submitFunction(values);
  };
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  // console.log("initialValues", initialValues);

  function handleAcceptedFiles(files: any) {

    let filesModified = files.map((file: any) => {

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );


    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }

  /**
     * Formats the size
     */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };
  const removeCustomerImage = () => {
    setDeleteModal(false);
    if (dealerSubuserImage) {
      dispatch(deleteDealerSubuserProfileLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };

  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };
  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };

  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };
  const [otpValue, setOtpValue] = useState<any>(null);
  const [openOtp, setOpenOtp] = useState<any>(false);
  const handleOtpSubmit = () => {
    if (id) {

      dispatch(sendDealerSubuserOtpLoading(otpValue, dealerSubUserDetails?.id, editedMobileNumber));
    } else {
      dispatch(sendDealerSubuserOtpLoading(otpValue, createdData?.id, createdData?.phoneNumber));
    }
    // setOpenOtp(false)
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpDealerSubuserMessage());
    if (id) {
      dispatch(getDealerSubuserByIdLoading(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };

  useEffect(() => {
    debugger;
    if (createdData) {
      dispatch(setDealerSubuserMobileNumberLoading(createdData?.phoneNumber, createdData?.id));

    }
  }, [createdData]);

  const handleResendOtp = (number: any) => {
    dispatch(resendOtpDealerSubuser(dealerSubUserDetails?.id, number));
  };

  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {

    setPhoneEdit(false);
    dispatch(setDealerSubuserMobileNumberLoading(values.phoneNumber, dealerSubUserDetails?.id));

  };
  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {
    setEmailEdit(false);
    dispatch(setDealerSubuserEmailIdLoading(values.email, dealerSubUserDetails?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const handleAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(dealerSubuserLatLagLoading(event.target.value))
      }
    }
  }

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      {customError && dialogContainerCustomError("Address field required")}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeCustomerImage();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className='page-content'>
        <Container fluid>
          {/* <Breadcrumb title="Dealer" titlePath={ROUTER.DEALERS_GRID} breadcrumbItem={id ? "Edit Dealer SubUser" : "Create Dealer SubUser"} /> */}
          <Breadcrumb title={hasDealerRoleHandler() ? "Dealer SubUser" : "Dealer"} titlePath={hasDealerRoleHandler() ? ROUTER.DEALER_SUB_USER_GRID : ROUTER.DEALERS_GRID} breadcrumbItem={id ? "Edit Dealer Subuser" : "Create Dealer Subuser"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? editValidation : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Password"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                // error={error}
                                invalid={
                                  touched["password"] && errors["password"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName={id ? "New Confirm Password" : "Confirm Password"}
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="confirmpassword"
                                onBlur={handleBlur}
                                value={values.confirmpassword}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["confirmpassword"] &&
                                    errors["confirmpassword"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}

                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e:any)=>{
                                  let event={...e}
                                  event.target.value=e.target.value.toLowerCase()
                                  handleChange(event)
                                }}
                                isRequired={true}
                                // error={error}
                                invalid={
                                  touched["email"] && errors["email"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Phone"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="phoneNumber"
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                onChange={handleChange}
                                isRequired={true}
                                // error={error}
                                invalid={
                                  touched["phoneNumber"] &&
                                    errors["phoneNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["phoneNumber"] &&
                                      errors["phoneNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (<FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                  onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                />
                                )}

                              </div>
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="alternateNumber"
                                onBlur={handleBlur}
                                value={values.alternateNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={ touched["alternateNumber"] && errors["alternateNumber"] ? true : false }
                              />
                            </Col>
                            <Col lg={12} className="mb-2">
                              <InputCom
                                labelName="Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="address"
                                // onBlur={(e:any)=>{handleBlur(e) ; if(values.address){ dispatch(dealerSubuserLatLagLoading(values.address)) }}}
                                onBlur={handleBlur}
                                onKeyDown={(e)=>{handleAddress(e)}}
                                value={values.address}
                                onChange={handleChange}
                                // error={error}
                                isRequired={false}
                                invalid={
                                  touched["address"] && errors["address"]
                                    ? true
                                    : false
                                }
                              />
                              <p style={{ fontSize: "12px",marginBottom:"3px"}}>Please press the Enter key after entering the Address.</p>
                              {dealerSubuserLatLng && 
                              <iframe src={`https://maps.google.com/maps?q=${dealerSubuserLatLng.lat},${dealerSubuserLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                              {/* <AddressMapFields address={address} setAddress={setAddress} lat={latitude} lng={longitude} title={"Address"}></AddressMapFields> */}

                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={dealerSubUserDetails?.extraProperties?.Address ? dealerSubUserDetails?.extraProperties?.Address : ""} title="Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}

                            </Col>
                            <Row>
                              <Col lg={2} md={3} sm={3} xs={6}>
                                <CheckboxInput
                                  name="lockoutEnabled"
                                  label="LockOut Enable"
                                  value={values["lockoutEnabled"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="isActive"
                                  label="Is Active"
                                  value={values["isActive"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="sendSms"
                                  label="Send Sms"
                                  value={values["sendSms"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="sendEmail"
                                  label="Send Email"
                                  value={values["sendEmail"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="sendWhatsapp"
                                  label="Whatsapp"
                                  value={values["sendWhatsapp"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="isVerfied"
                                  label="Is Verified"
                                  value={values["isVerfied"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                            </Row>
                            {id && <React.Fragment>
                              <div className="mt-1">
                                <h5>Dealer Subuser Profile</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {(selectedFiles.length == 0 && !dealerSubuserImage) && <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onError={(err: Error) => { console.log("file error", err); }}
                                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                        maxFiles={1}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} accept="image/*" />
                                              <div className="">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>}

                                      {(selectedFiles[0]?.preview || dealerSubuserImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : dealerSubuserImage} removeIndex={deleteConfirmationHandler} />}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>}

                          </Row>

                          <div className="d-flex float-end mt-2 me-0">
                            {(dealerSubUserDetails?.phoneNumberConfirmed != true && id) &&
                              <Button
                                className="next-status-btn me-2 mt-2"
                                label={"Verify Mobile No"}
                                type="button"
                                onClick={() => handleResendOtp(values.phoneNumber)}
                              />
                            }
                            <Button
                              className="secondary-btn me-2 mt-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary mt-2 btn-primary-shadow"
                              label={id ? "Update Dealer Subuser" : "Create Dealer Subuser"}
                              type="submit"
                            />

                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>

              {/* {dealerSubUserDetails?.extraProperties?.DealerId && <Row className="mt-3">
                <Col lg={12} className="dealer-title-header">
                  <div>Dealer SubUser Details</div>
                </Col>
                <DealerSubUserList DealerId={dealerSubUserDetails?.extraProperties?.DealerId} isOnCreatePage={isOnCreatePage} />
              </Row>} */}

            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditDealerSubuser;