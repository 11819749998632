import PropTypes from "prop-types";
import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";

interface DeleteModalProps {
  show: any;
  onDeleteClick: any;
  onCloseClick: any;
  message?: string;
  deleteLabel?: string;
  closeLabel?: string;
  showIcon?: boolean;
  undo?: boolean;
}

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  message = "Are you sure you want to delete this?",
  deleteLabel = "Yes",
  closeLabel = "Cancel",
  showIcon = true,
  undo
}: DeleteModalProps) => {
  const revertMeassage = "Are you sure you want to restore this? ";
  const revertlabel = "OK"
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              {showIcon && <i
                className="mdi mdi-alert-circle-outline delete-text"

              />}
              {/* <h2>Are you sure?</h2> */}
              <h4>{undo ? revertMeassage : message}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onDeleteClick}
              >
                {undo ? revertlabel : deleteLabel}
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                {closeLabel}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default DeleteModal;
