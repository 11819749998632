import { jobsheet } from "src/models/pages/jobsheetModel";
import { JobSheetTypes } from "./actionTypes";


// For Grid
export const getJobsheetsList = (lazyState: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETS_LIST,
    lazyState,
  };
};

export const getJobsheetsListSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETS_LIST_SUCCESS,
    payload: jobsheet,
  };
};

export const getJobsheetsListError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETS_LIST_FAIL,
    payload: error,
  };
};

//delete
export const deleteJobsheetsFromList = (payload: any) => {
  return {
    type: JobSheetTypes.DELETE_JOBSHEETS,
    payload: payload,
  };
};

export const deleteJobsheetsFromListSuccess = (response: any, undo: boolean) => {
  return {
    type: JobSheetTypes.DELETE_JOBSHEETS_SUCCESS,
    payload: response,
    undo
  };
};

export const deleteJobsheetsFromListError = (error: any) => {
  return {
    type: JobSheetTypes.DELETE_JOBSHEETS_FAIL,
    payload: error,
  };
};

//  GET JOBSHEET HISTORY DATA

export const getJobsheetHistoryByAudioSerial = (audioSerialNumber: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA,
    payload: audioSerialNumber,
  };
}

export const getJobsheetHistoryByAudioSerialSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA_SUCCESS,
    payload: jobsheet,
  }
}

export const getJobsheetHistoryByAudioSerialError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA_FAIL,
    payload: error
  }
}

//get Jobsheet by id
export const getJobsheetById = (jobsheetId: any) => {
  debugger;
  return {
    type: JobSheetTypes.GET_JOBSHEETBYID,
    payload: jobsheetId,
  };
}

export const getJobsheetByIdSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETBYID_SUCCESS,
    payload: jobsheet,
  }
}

export const getJobsheetByIdError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEETBYID_FAIL,
    payload: error
  }
}

//create Jobsheet
export const createJobsheetLoading = (jobsheet: any, id: any) => {
  return {
    type: JobSheetTypes.CREATE_JOBSHEET_LOADING,
    payload: { jobsheet, id },
  };
}

export const createJobsheetSuccess = (jobsheet: jobsheet, id: any) => {
  return {
    type: JobSheetTypes.CREATE_JOBSHEET_SUCCESS,
    payload: { jobsheet, id },
  }
}

export const createJobsheetError = (error: any) => {
  return {
    type: JobSheetTypes.CREATE_JOBSHEET_FAIL,
    payload: error
  }
}

//update jobsheet FOR AND NEXT OF NEW JOB
export const updateServiceRequestJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.SERVICE_REQUEST_JOB,
    payload: jobsheetDetails,

  }
}

export const updateServiceRequestJobSuccess = (jobsheet: jobsheet) => {
  return {
    type: JobSheetTypes.SERVICE_REQUEST_JOB_SUCCESS,
    payload: jobsheet
  }
}

export const updateServiceRequestJobError = (error: any) => {
  return {
    type: JobSheetTypes.SERVICE_REQUEST_JOB_FAIL,
    payload: error
  }
}

//update jobsheet FOR AND NEXT OF REPLACEMENT SYSTEM DISPATCH
export const updateReplacementSystemDispatch = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH,
    payload: jobsheetDetails,
  }
}

export const updateReplacementSystemDispatchSuccess = (jobsheet: jobsheet) => {
  return {
    type: JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH_SUCCESS,
    payload: jobsheet
  }
}

export const updateReplacementSystemDispatchError = (error: any) => {
  return {
    type: JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH_FAIL,
    payload: error
  }
}


//update jobsheet FOR AND NEXT OF SERVICE REQUEST GENEREATED
export const updateCreateJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.CREATE_JOB,
    payload: jobsheetDetails,

  }
}

export const updateCreateJobSuccess = (jobsheet: jobsheet) => {
  return {
    type: JobSheetTypes.CREATE_JOB_SUCCESS,
    payload: jobsheet
  }
}

export const updateCreateJobError = (error: any) => {
  return {
    type: JobSheetTypes.CREATE_JOB_FAIL,
    payload: error
  }
}

//update Assign to estimate job
export const updateAssignToEstimateJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB,
    payload: jobsheetDetails,

  }
}

export const updateAssignToEstimateJobSuccess = (jobsheet: jobsheet) => {
  return {
    type: JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB_SUCCESS,
    payload: jobsheet
  }
}

export const updateAssignToEstimateJobError = (error: any) => {
  return {
    type: JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB_FAIL,
    payload: error
  }
}

//update  estimate complete job
export const updateEstimateCompleteJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.ESTIMATE_COMPLETE_JOB,
    payload: jobsheetDetails,

  }
}

export const updateEstimateCompleteJobSuccess = (jobsheet: jobsheet) => {
  return {
    type: JobSheetTypes.ESTIMATE_COMPLETE_JOB_SUCCESS,
    payload: jobsheet
  }
}

export const updateEstimateCompleteJobError = (error: any) => {
  return {
    type: JobSheetTypes.ESTIMATE_COMPLETE_JOB_FAIL,
    payload: error
  }
}

//update  estimate approval job
export const updateEstimateApproveOrRejectJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.ESTIMATE_APPROVAL_JOB,
    payload: jobsheetDetails,

  }
}

export const updateEstimateApproveOrRejectJobSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.ESTIMATE_APPROVAL_JOB_SUCCESS,
    payload: jobsheet
  }
}

export const updateEstimateApproveOrRejectJobError = (error: any) => {
  return {
    type: JobSheetTypes.ESTIMATE_APPROVAL_JOB_FAIL,
    payload: error
  }
}

//update  estimate reject job
export const updateCancelJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.CANCEL_JOB,
    payload: jobsheetDetails,

  }
}

export const updateCancelJobSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.CANCEL_JOB_SUCCESS,
    payload: jobsheet
  }
}

export const updateCancelJobError = (error: any) => {
  return {
    type: JobSheetTypes.CANCEL_JOB_FAIL,
    payload: error
  }
}

//update  assign to technician
export const updateAssignToTechnicianJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.ASSIGN_TO_TECHNICIAN,
    payload: jobsheetDetails,

  }
}

export const updateAssignToTechnicianJobSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.ASSIGN_TO_TECHNICIAN_SUCCESS,
    payload: jobsheet
  }
}

export const updateAssignToTechnicianJobError = (error: any) => {
  return {
    type: JobSheetTypes.ASSIGN_TO_TECHNICIAN_FAIL,
    payload: error
  }
}

//update  ZERO ESTIMATE assign to technician
export const updateZeroEstimateAssignToTechnicianJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN,
    payload: jobsheetDetails,

  }
}

export const updateZeroEstimateAssignToTechnicianJobSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_SUCCESS,
    payload: jobsheet
  }
}

export const updateZeroEstimateAssignToTechnicianJobError = (error: any) => {
  return {
    type: JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_FAIL,
    payload: error
  }
}

//update  assign to technician
export const updateReadyToInvoiceJob = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.READY_TO_INVOICE,
    payload: jobsheetDetails,

  }
}

export const updateReadyToInvoiceJobSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.READY_TO_INVOICE_SUCCESS,
    payload: jobsheet
  }
}

export const updateReadyToInvoiceJobError = (error: any) => {
  return {
    type: JobSheetTypes.READY_TO_INVOICE_FAIL,
    payload: error
  }
}
//update Job Invoiced
export const updateJobInvoiced = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.JOB_INVOICED,
    payload: jobsheetDetails,

  }
}

export const updateJobInvoicedSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.JOB_INVOICED_SUCCESS,
    payload: jobsheet
  }
}

export const updateJobInvoicedError = (error: any) => {
  return {
    type: JobSheetTypes.JOB_INVOICED_FAIL,
    payload: error
  }
}
//update Job Close

export const updateJobClosed = (jobsheetDetails: any) => {
  return {
    type: JobSheetTypes.JOB_CLOSE,
    payload: jobsheetDetails,

  }
}

export const updateJobClosedSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.JOB_CLOSE_SUCCESS,
    payload: jobsheet
  }
}

export const updateJobClosedError = (error: any) => {
  return {
    type: JobSheetTypes.JOB_CLOSE_FAIL,
    payload: error
  }
}




//reset Jobsheet
export const JobsheetDetailsReset = () => {
  return {
    type: JobSheetTypes.RESET_JOBSHEET,
  }
}

//reset Jobsheet
export const clearJobsheet = () => {
  return {
    type: JobSheetTypes.CLEAR_JOBSHEET,
  }
}


//dealer dropdown

export const jobDealerdropdownListLoading = () => {
  return {
    type: JobSheetTypes.DEALERDROPDOWNLIST_LOADING,
  }
}

export const jobDealerdropdownListSuccess = (dealerList: any) => {
  return {
    type: JobSheetTypes.DEALERDROPDOWNLIST_SUCCESS,
    payload: dealerList
  }
}

export const jobDealerdropdownListError = (error: any) => {
  return {
    type: JobSheetTypes.DEALERDROPDOWNLIST_ERROR,
    payload: error
  }
}


//vehicleBrand related to Dealer

export const getVehiclebrandRelatedtoDealerLoading = (id: any) => {
  return {
    type: JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_LOADING,
    payload: id
  }
}

export const getVehiclebrandRelatedtoDealerSuccess = (vehicleBrand: any, id: any) => {
  return {
    type: JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_SUCCESS,
    payload: vehicleBrand,
    id
  }
}

export const getVehiclebrandRelatedtoDealerError = (error: any) => {
  return {
    type: JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_ERROR,
    payload: error
  }
}

//get customer dropdown list

export const getcustomerdropdownListLoading = () => {
  return {
    type: JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_LOADING,
  }
}

export const getcustomerdropdownListSucces = (customer: any) => {
  return {
    type: JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_SUCCESS,
    payload: customer
  }
}

export const getcustomerdropdownListError = (error: any) => {
  return {
    type: JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_ERROR,
    payload: error
  }
}


//get AudioModel_list dropdown

export const getAudioModelJobdropdownListLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_LOADING,
    payload: id
  }
}


export const getAudioModelJobdropdownListSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_SUCCESS,
    payload: response
  }
}

export const getAudioModelJobdropdownListError = (error: any) => {
  return {
    type: JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_ERROR,
    payload: error
  }
}


//get pending status

export const getPendingReasonLoading = () => {
  return {
    type: JobSheetTypes.GET_PENDING_REASON_LOADING,
  }
}
export const getPendingReasonSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_PENDING_REASON_SUCCESS,
    payload: resp
  }
}
export const getPendingReasonError = (err: any) => {
  return {
    type: JobSheetTypes.GET_PENDING_REASON_ERROR,
    payload: err
  }
}


//clear messages 

export const jobSheetClearMessage = () => {
  return {
    type: JobSheetTypes.CLEAR_JOBSHEET_MESSAGES,
  }
}


//  GET AUDIO MODEL DETAILS

export const audioModelDetailsForJobLoading = (id: any, isExchange: any) => {
  return {
    type: JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_LOADING,
    payload: id,
    isExchange: isExchange
  }
}

export const audioModelDetailsForJobSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_SUCCESS,
    payload: response
  }
}

export const audioModelDetailsForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_ERROR,
    payload: error
  }
}


//  GET EWMPLOYEE DROPDOWN

export const employeeDropdownForJobLoading = () => {
  return {
    type: JobSheetTypes.GET_EMPLOYEE_DROPDOWN_LOADING,

  }
}

export const employeeDropdownForJobSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_EMPLOYEE_DROPDOWN_SUCCESS,
    payload: response
  }
}

export const employeeDropdownForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_EMPLOYEE_DROPDOWN_FAIL,
    payload: error
  }
}

//  GET COURIER DROPDOWN by dealer or customer

export const courierDropdownForJobLoading = (dealerOrCustomerId : any) => {
  return {
    type: JobSheetTypes.GET_COURIER_DROPDOWN_LOADING,
    payload : dealerOrCustomerId

  }
}

export const courierDropdownForJobSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_COURIER_DROPDOWN_SUCCESS,
    payload: response
  }
}

export const courierDropdownForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_COURIER_DROPDOWN_FAIL,
    payload: error
  }
}


//Get courier dropdown list

export const courierDropdownAllDataForJobLoading = () => {
  return {
    type: JobSheetTypes.GET_COURIER_DROPDOWN_ALL_DATA_LOADING,
  }
}

export const courierDropdownAllDataForJobSuccess = (resp : any) => {
  return {
    type: JobSheetTypes.GET_COURIER_DROPDOWN_ALL_DATA_SUCCESS,
    payload : resp
  }
}
export const courierDropdownAllDataForJobError = (error : any) => {
  return {
    type: JobSheetTypes.GET_COURIER_DROPDOWN_ALL_DATA_ERROR,
    payload : error
  }
}


//  GET PARTS DROPDOWN

export const partsDropdownForJobLoading = () => {
  return {
    type: JobSheetTypes.GET_PARTS_DROPDOWN_LOADING,

  }
}

export const partsDropdownForJobSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_PARTS_DROPDOWN_SUCCESS,
    payload: response
  }
}

export const partsDropdownForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_PARTS_DROPDOWN_FAIL,
    payload: error
  }
}
//  GET TAXES DROPDOWN

export const taxDropdownForJobLoading = (id: any = 0) => {
  return {
    type: JobSheetTypes.GET_TAXES_DROPDOWN_LOADING,
    payload: id

  }
}

export const taxDropdownForJobSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_TAXES_DROPDOWN_SUCCESS,
    payload: response
  }
}

export const taxDropdownForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_TAXES_DROPDOWN_FAIL,
    payload: error
  }
}
//  GET TAXES DETAILS

export const taxDetailsForJobLoading = (id: any = 0, index: number) => {
  return {
    type: JobSheetTypes.GET_TAX_DETAILS_LOADING,
    payload: { id, index }

  }
}

export const taxDetailsForJobSuccess = (response: any, index: number) => {
  return {
    type: JobSheetTypes.GET_TAX_DETAILS_SUCCESS,
    payload: { response, index }
  }
}

export const taxDetailsForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_TAX_DETAILS_FAIL,
    payload: error
  }
}

//  GET PARTS DEATILS DROPDOWN

export const getPartDetailsForJobLoading = (id: number, index: number) => {
  return {
    type: JobSheetTypes.GET_PART_DETAILS_FOR_JOB,
    payload: {
      id,
      index
    }

  }
}

export const getPartDetailsForJobSuccess = (response: any, index: number) => {

  return {
    type: JobSheetTypes.GET_PART_DETAILS_FOR_JOB_SUCCESS,
    payload: { ...response, index },
  }
}

export const getPartDetailsForJobError = (error: any) => {
  return {
    type: JobSheetTypes.GET_PART_DETAILS_FOR_JOB_FAIL,
    payload: error
  }
}

export const resetTaxDetailsForJob = () => {
  return {
    type: JobSheetTypes.RESET_TAX_DETAILS_FOR_JOB,
  }
}

export const resetPartDetailsForJob = () => {
  return {
    type: JobSheetTypes.RESET_PART_DETAILS_FOR_JOB,
  }
}

// UPDATE PENDING 

export const updatePendingCommand = (pendingCommand: any) => {
  return {
    type: JobSheetTypes.UPDATE_PENDING_COMMAND_LOADING,
    payload: pendingCommand,
  }
}

export const updatePendingCommandSuccess = (jobsheet: jobsheet) => {
  return {
    type: JobSheetTypes.UPDATE_PENDING_COMMAND_SUCCESS,
    payload: jobsheet
  }
}

export const updatePendingCommandError = (error: any) => {
  return {
    type: JobSheetTypes.UPDATE_PENDING_COMMAND_FAIL,
    payload: error
  }
}

// UPDATE PENDING 

export const deletePendingCommand = (id: number) => {
  return {
    type: JobSheetTypes.DELETE_PENDING_COMMAND_LOADING,
    payload: id,
  }
}

export const deletePendingCommandSuccess = (payload: any) => {
  return {
    type: JobSheetTypes.DELETE_PENDING_COMMAND_SUCCESS,
    payload: payload
  }
}

export const deletePendingCommandError = (error: any) => {
  return {
    type: JobSheetTypes.UPDATE_PENDING_COMMAND_FAIL,
    payload: error
  }
}


//get technician dropdown
export const getTechnicianDropdownLoading = () => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_LOADING
  }
}

export const getTechnicianDropdownSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_SUCCESS,
    payload: resp
  }
}

export const getTechnicianDropdownError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_ERROR,
    payload: error
  }
}


//get estimator dropdown
export const getEstimatorDropdownLoading = () => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_LOADING
  }
}

export const getEstimatorDropdownSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_SUCCESS,
    payload: resp
  }
}

export const getEstimatorDropdownError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_ERROR,
    payload: error
  }
}

//get vehiclebrand dropdown list page

export const getVehiclebrandDropdownLoading = () => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_LOADING
  }
}
export const getVehiclebrandDropdownSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_SUCCESS,
    payload: resp
  }
}
export const getVehiclebrandDropdownError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_ERROR,
    payload: error
  }
}

//get INVOICE DETAILS 

export const getInvoiceDetailsByJobIdLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_LOADING,
    payload: id
  }
}
export const getInvoiceDetailsByJobIdSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_SUCCESS,
    payload: resp
  }
}
export const getInvoiceDetailsByJobIdError = (error: any) => {
  return {
    type: JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_ERROR,
    payload: error
  }
}



//get SERVICE REQUEST 

export const downloadServiceRequestLoading = (id: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB,
    payload: id
  }
}
export const downloadServiceRequestSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_SUCCESS,
    payload: resp
  }
}
export const downloadServiceRequestError = (error: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_FAIL,
    payload: error
  }
}

//DOWNLOAD JOB SHEET 

export const downloadJobSheetLoading = (id: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_JOB_SHEET,
    payload: id
  }
}
export const downloadJobSheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_JOB_SHEET_SUCCESS,
    payload: resp
  }
}



export const downloadJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_JOB_SHEET_FAIL,
    payload: error
  }
}

//DOWNLOAD ESTIMATE 

export const downloadEstimateJobSheetLoading = (id: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET,
    payload: id
  }
}

export const downloadEstimateJobSheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_SUCCESS,
    payload: resp
  }
}

export const downloadEstimateJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_FAIL,
    payload: error
  }
}
//DOWNLOAD SEND MAIL ESTIMATE 

export const sendMailJobSheetLoading = (id: any) => {
  return {
    type: JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET,
    payload: id
  }
}
export const sendMailJobSheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_SUCCESS,
    payload: resp
  }
}
export const sendMailJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_FAIL,
    payload: error
  }
}

//DOWNLOAD SEND INVOICE IN MAIL 

export const sendInvoiceMailJobSheetLoading = (id: any) => {
  debugger;
  return {
    type: JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET,
    payload: id
  }
}
export const sendInvoiceMailJobSheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET_SUCCESS,
    payload: resp
  }
}
export const sendInvoiceMailJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET_FAIL,
    payload: error
  }
}

//DOWNLOAD INVOICE 

export const downloadInvoiceJobSheetLoading = (id: any) => {
  debugger;
  return {
    type: JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET,
    payload: id
  }
}
export const downloadInvoiceJobSheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET_SUCCESS,
    payload: resp
  }
}
export const downloadInvoiceJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET_FAIL,
    payload: error
  }
}

//DOWNLOAD SMS MAIL ESTIMATE 

export const sendSMSJobSheetLoading = (id: any) => {
  return {
    type: JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET,
    payload: id
  }
}
export const sendSMSJobSheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET_SUCCESS,
    payload: resp
  }
}
export const sendSMSJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET_FAIL,
    payload: error
  }
}

//RECEIVED PAYMENT INVOICE DETAILS

export const receivedPaymentForInvoiceJobSheetLoading = (id: any, index: number) => {
  return {
    type: JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET,
    payload: { id, index }
  }
}
export const receivedPaymentForInvoiceJobSheetSuccess = (resp: any, index: any) => {
  return {
    type: JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET_SUCCESS,
    payload: { resp, index }
  }
}
export const receivedPaymentForInvoiceJobSheetError = (error: any) => {
  return {
    type: JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET_ERROR,
    payload: error
  }
}

//get CONCERN SIGNATURE 

export const getEstimateConcernSignatureLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE,
    payload: id
  }
}


export const getEstimateConcernSignatureSuccess = (response: any) => {
  return {
    type: JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE_SUCCESS,
    payload: response
  }
}

export const getEstimateConcernSignatureError = (error: any) => {
  return {
    type: JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE_FAIL,
    payload: error
  }
}


// ======== get prepareby id =============

export const getPreparebyIdLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_PREPARED_BYID_LOADING,
    payload: id
  }
}

export const getPreparebyIdSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_PREPARED_BYID_SUCCESS,
    payload: resp
  }
}

export const getPreparebyIdErrro = (error: any) => {
  return {
    type: JobSheetTypes.GET_PREPARED_BYID_ERROR,
    payload: error
  }
}

// ============ get updated by details ================ 

export const updatedByUserDetailsLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_UPDATED_BYID_USER_LOADING,
    payload: id
  }
}
export const updatedByUserDetailsSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_UPDATED_BYID_USER_SUCCESS,
    payload: resp
  }
}
export const updatedByUserDetailsError = (error: any) => {
  return {
    type: JobSheetTypes.GET_UPDATED_BYID_USER_ERROR,
    payload: error
  }
}

export const getTechnicianbyIdLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_TECHNICIAN_BYID_LOADING,
    payload: id
  }
}

export const getTechnicianbyIdSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_TECHNICIAN_BYID_SUCCESS,
    payload: resp
  }
}

export const getTechnicianbyIdError = (error: any) => {
  return {
    type: JobSheetTypes.GET_TECHNICIAN_BYID_ERROR,
    payload: error
  }
}


export const estimatorByUserDetailsLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_ESTIMATOR_BYID_USER_LOADING,
    payload: id
  }
}
export const estimatorByUserDetailsSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_ESTIMATOR_BYID_USER_SUCCESS,
    payload: resp
  }
}
export const estimatorByUserDetailsError = (error: any) => {
  return {
    type: JobSheetTypes.GET_ESTIMATOR_BYID_USER_ERROR,
    payload: error
  }
}

//get taxes fropdown in jobsheet

export const getTaxesDropdownInJobsheetLoading = () => {
  return {
    type: JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_LOADING
  }
}

export const getTaxesDropdownInJobsheetSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_SUCCESS,
    payload: resp
  }
}
export const getTaxesDropdownInJobsheetError = (error: any) => {
  return {
    type: JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_ERROR,
    payload: error
  }
}

//franchise arrived 

export const franchiseArrivedLoading = (franchiseDetails: any) => {
  return {
    type: JobSheetTypes.FRANCHISEE_ARRIVED_LOADING,
    payload: franchiseDetails
  }
}
export const franchiseArrivedSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.FRANCHISEE_ARRIVED_SUCCESS,
    payload: resp
  }
}
export const franchiseArrivedError = (error: any) => {
  return {
    type: JobSheetTypes.FRANCHISEE_ARRIVED_ERROR,
    payload: error
  }
}

///franchise completed job 

export const franchiseCompletedJobLoading = (jobdetails: any) => {
  return {
    type: JobSheetTypes.FRANCHISEE_COMPLETED_JOB_LOADING,
    payload: jobdetails
  }
}
export const franchiseCompletedJobSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.FRANCHISEE_COMPLETED_JOB_SUCCESS,
    payload: resp
  }
}
export const franchiseCompletedJobError = (error: any) => {
  return {
    type: JobSheetTypes.FRANCHISEE_COMPLETED_JOB_ERROR,
    payload: error
  }
}

///get customer or delaer lat and lang

export const customerOrDelaerLatLagLoading = (selectedArea: string) => {
  return {
    type: JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const customerOrDelaerLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const customerOrDelaerLatLagError = (error: any) => {
  return {
    type: JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_ERROR,
    payload: error
  }
}

//upload additional expenses

export const additionalExpensedocumentuploadLoading = (id: any, formData: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_LOADING,
    payload: { id, formData }
  }
}
export const additionalExpensedocumentuploadSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_SUCCESS,
    payload: resp
  }
}
export const additionalExpensedocumentuploadError = (error: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_ERROR,
    payload: error
  }
}


//get addition expenses details list

export const additionalExpenseGridLoading = (lazyState: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_GRID_LOADING,
    lazyState,
  };
}
export const additionalExpenseGridSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_GRID_SUCCESS,
    payload: resp
  };
}
export const additionalExpenseGridError = (error: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_GRID_ERROR,
    payload: error
  };
}

// expense approve
export const additionalExpenseApproveLoading = (id: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_LOADING,
    payload: id
  };
}
export const additionalExpenseApproveSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_SUCCESS,
    payload: resp
  };
}
export const additionalExpenseApproveError = (error: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_ERROR,
    payload: error
  };
}

//get additional expense details

export const getAdditionalExpenseDetailsLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_LOADING,
    payload: id
  }
}

export const getAdditionalExpenseDetailsSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_SUCCESS,
    payload: resp
  }
}
export const getAdditionalExpenseDetailsError = (error: any) => {
  return {
    type: JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_ERROR,
    payload: error
  }
}

//additinal expense upload 

export const additionalExpenseUpdateLoading = (id: any, body: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_LOADING,
    payload: { id, body }
  }
}
export const additionalExpenseUpdateSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_SUCCESS,
    payload: resp
  }
}
export const additionalExpenseUpdateError = (error: any) => {
  return {
    type: JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_ERROR,
    payload: error
  }
}


//get franchise uploaded additional details 

export const getFranchiseUploadedDocumentLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LOADING,
    payload: id
  }
}
export const getFranchiseUploadedDocumentSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_SUCCESS,
    payload: resp
  }
}
export const getFranchiseUploadedDocumentError = (error: any) => {
  return {
    type: JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_ERROR,
    payload: error
  }
}


//franchise documnet link

export const getFranchiseUploadedDocumentLinkLoading = (id: any) => {
  return {
    type: JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_LOADING,
    payload: id
  }
}
export const getFranchiseUploadedDocumentLinkSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_SUCCESS,
    payload: resp
  }
}
export const getFranchiseUploadedDocumentLinkError = (error: any) => {
  return {
    type: JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_ERROR,
    payload: error
  }
}

//delete franchise uploaded documents

export const franchiseUploadedDocumentDeleteLoading = (id: any,jobId:any) => {
  return {
    type: JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_LOADING,
    payload:{id,jobId}
  }
}
export const franchiseUploadedDocumentDeleteSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_SUCCESS,
    payload: resp
  }
}
export const franchiseUploadedDocumentDeleteError = (error: any) => {
  return {
    type: JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_ERROR,
    payload: error
  }
}

//clear franchise document link

export const clearFranchiseDocumentLink = () => {
  return {
    type: JobSheetTypes.CLEAR_FRANCHISE_DOCUMENT_LINK,
  }
}
export const getJobsheetsStatus = (status: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_STATUS,
    payload: status
  }
}
export const getJobsheetsStatusSuccess = (status: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_STATUS_SUCCESS,
    payload: status
  }
}
export const setJobsheetsStatus = (status: any) => {
  return {
    type: JobSheetTypes.SET_JOBSHEET_STATUS,
    payload: status
  }
}
export const setJobsheetsStatusSuccess = (status: any) => {
  return {
    type: JobSheetTypes.SET_JOBSHEET_STATUS_SUCCESS,
    payload: status
  }
}

//get Jobsheet by id
export const getJobsheetEnquiryById = (lazystate: any) => {
  

  return {
    type: JobSheetTypes.GET_JOBSHEET_ENQUIRY_BY_ID,
    payload: lazystate,
  };
}

export const getJobsheetEnquiryByIdSuccess = (jobsheet: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_ENQUIRY_BY_ID_SUCCESS,
    payload: jobsheet,
  }
}

export const getJobsheetEnquiryByIdError = (error: any) => {
  return {
    type: JobSheetTypes.GET_JOBSHEET_ENQUIRY_BY_ID_FAIL,
    payload: error
  }
}


// =========== print exchange invoice ===========

export const printExchangeInvoiceLoading = (id: any) => {
  return {
    type: JobSheetTypes.PRINT_EXCHANGE_INVOICE_LOADING,
    payload: id
  }
}
export const printExchangeInvoiceSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.PRINT_EXCHANGE_INVOICE_SUCCESS,
    payload: resp
  }
}
export const printExchangeInvoiceError = (error: any) => {
  return {
    type: JobSheetTypes.PRINT_EXCHANGE_INVOICE_ERROR,
    payload: error
  }
}


// =============== email exchange invoice ===============


export const emailExchangeInvoiceLoading = (id: any) => {
  return {
    type: JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_LOADING,
    payload: id
  }
}
export const emailExchangeInvoiceSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_SUCCESS,
    payload: resp
  }
}
export const emailExchangeInvoiceError = (error: any) => {
  return {
    type: JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_ERROR,
    payload: error
  }
}


// ============= delete additional expenses documents =============

export const deleteAdditionalExpensesDocumentLoading = (id: any, jobId: any) => {
  return {
    type: JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_LOADING,
    payload: { id, jobId }
  }
}

export const deleteAdditionalExpensesDocumentSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_SUCCESS,
    payload: resp
  }
}
export const deleteAdditionalExpensesDocumentError = (error: any) => {
  return {
    type: JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_ERROR,
    payload: error
  }
}


// ============= delete additional expenses documents =============

export const getAdditionalExpenseWithoutqueryLoading = (jobId: any) => {
  return {
    type: JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_LOADING,
    payload: jobId
  }
}

export const getAdditionalExpenseWithoutquerySuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_SUCCESS,
    payload: resp
  }
}
export const getAdditionalExpenseWithoutqueryError = (error: any) => {
  return {
    type: JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_ERROR,
    payload: error
  }
}


//get approve document list 

export const getApproveDocumentLoading = (jobId: any) => {
  return {
    type: JobSheetTypes.GET_APPROVE_DOCUMENT_LOADING,
    payload: jobId
  }
}
export const getApproveDocumentSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_APPROVE_DOCUMENT_SUCCESS,
    payload: resp
  }
}
export const getApproveDocumentError = (error: any) => {
  return {
    type: JobSheetTypes.GET_APPROVE_DOCUMENT_ERROR,
    payload: error
  }
}


//================  get approve document link action ==============
export const getApproveDocumentLinkLoading = (documentId: any) => {
  return {
    type: JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_LOADING,
    payload: documentId
  }
}
export const getApproveDocumentLinkSuccess = (resp: any) => {
  return {
    type: JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_SUCCESS,
    payload: resp
  }
}
export const getApproveDocumentLinkError = (error: any) => {
  return {
    type: JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_ERROR,
    payload: error
  }
}


// ============ clear approve document link =============

export const clearApproveDocumentLink=()=>{
  return {
    type : JobSheetTypes.CLEAR_APPROVE_DOCUMENT_LINK
  }
}


//additional expense document loading

export const additionalExpensesDocumentLinkLoading=(id : any)=>{
  return {
    type : JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_LOADING,
    payload : id
  }
}

export const additionalExpensesDocumentLinkSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_SUCCESS,
    payload :resp
  }
}
export const additionalExpensesDocumentLinkError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_ERROR,
    payload : error
  }
}

export const clearAdditionalExpenseDocumentLink=()=>{
  return {
    type : JobSheetTypes.CLEAR_ADDITIONAL_EXPENSES_DOCUMENT_LINK
  }
}

export const getJobsheetFeedbackPendingListLoading=(dealerOrCustomerId : any,feedbackStatus : boolean,job_status : any)=>{
  return {
    type : JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_LOADING,
    payload : {dealerOrCustomerId,feedbackStatus,job_status}
  }
}

export const getJobsheetFeedbackPendingListSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_SUCCESS,
    payload : resp
  }
}

export const getJobsheetFeedbackPendingListError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_ERROR,
    payload : error
  }
}



//get dealer have feedback pending details

export const checkDealerHaveFeedbackPendingLoading=(id : any)=>{
  return {
    type : JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_LOADING,
    payload : id
  }
}

export const checkDealerHaveFeedbackPendingSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_SUCCESS,
    payload :resp
  }
}
export const checkDealerHaveFeedbackPendingError=(error : any)=>{
  return {
    type : JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_ERROR,
    payload : error
  }
}


export const getCustmerDetailsInJobsheetLoading=(currentValue:any,type : any)=>{
  return {
    type : JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_LOADING,
    payload : {currentValue,type}
  }
}

export const getCustmerDetailsInJobsheetSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_SUCCESS,
    payload : resp
  }
}
export const getCustmerDetailsInJobsheetError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_ERROR,
    payload : error
  }
}

export const getCustomerProfile=()=>{
  return {
    type : JobSheetTypes.GET_CUSTOMER_PROFILE_LOADING,
  }
}

export const getCustmerProfileSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_CUSTOMER_PROFILE_SUCCESS,
    payload : resp
  }
}
export const getCustmerProfileError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_CUSTOMER_PROFILE_ERROR,
    payload : error
  }
}



//get user current address lat lng action


export const getUserCurretnAddressLatLngLoading=(address : any)=>{
  return {
    type : JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_LOADING,
    payload : address,
  }
}

export const getUserCurretnAddressLatLngSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_SUCCESS,
    payload : resp,
  }
}

export const getUserCurretnAddressLatLngError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_ERROR,
    payload : error,
  }
}

export const getJobsheetNumberLoading=()=>{
  return {
    type : JobSheetTypes.GET_JOBSHEET_NUMBER_LOADING,
  }
}

export const getJobsheetNumberSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_JOBSHEET_NUMBER_SUCCESS,
    payload : resp,
  }
}

export const getJobsheetNumberError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_JOBSHEET_NUMBER_ERROR,
    payload : error,
  }
}

export const getAudioModelLoading=()=>{
  return {
    type : JobSheetTypes.GET_AUDIO_MODEL_LOADING,
  }
}

export const getAudioModelSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_AUDIO_MODEL_SUCCESS,
    payload : resp,
  }
}

export const getAudioModelError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_AUDIO_MODEL_ERROR,
    payload : error,
  }
}



/// new addirtional expenses updated 

export const newAdditionalExpensesUpdateLoading=(id : any,content : any)=>{
  return {
    type : JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_LOADING,
    payload : {id , content}
  }
}

export const newAdditionalExpensesUpdateSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_SUCCESS,
    payload : resp
  }
}

export const newAdditionalExpensesUpdateError=(error : any)=>{
  return {
    type : JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_ERROR,
    payload : error
  }
}

export const getAdditionalExpenseUserDetailsLoading=(id : any)=>{
  return {
    type : JobSheetTypes.GET_ADDITIONAL_EXPENSE_USER_DETAILS_LOADING,
    payload : id
  }
}

export const getAdditionalExpenseUserDetailsSuccess=(resp : any)=>{
  return {
    type : JobSheetTypes.GET_ADDITIONAL_EXPENSE_USER_DETAILS_SUCCESS,
    payload : resp
  }
}

export const getAdditionalExpenseUserDetailsError=(error : any)=>{
  return {
    type : JobSheetTypes.GET_ADDITIONAL_EXPENSE_USER_DETAILS_ERROR,
    payload : error
  }
}

export const emailServiceHistoryLoading = (id: any) => {
  return {
    type: JobSheetTypes.EMAIL_SERVICE_HISTORY_LOADING,
    payload: id
  }
}
export const emailServiceHistorySuccess = (resp: any) => {
  return {
    type: JobSheetTypes.EMAIL_SERVICE_HISTORY_SUCCESS,
    payload: resp
  }
}
export const emailServiceHistoryError = (error: any) => {
  return {
    type: JobSheetTypes.EMAIL_SERVICE_HISTORY_ERROR,
    payload: error
  }
}