import { enquiryModel } from "src/models/pages/enquiryModel";
import { enquiryTypes } from "./actionTypes";




const initialState: enquiryModel = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  enquiryList: {
    values: [],
    totalRecords: 0
  },
  enquiry: null,
  jobSheetList:[],
  employeeDropdownList:[],
  franchiseDropdownList:[],
  franchiseSubuserDropdownList:[]
};



const Enquiry = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case enquiryTypes.GET_ENQUIRY_LIST:
    case enquiryTypes.POST_ENQUIRY:
    case enquiryTypes.GET_ENQUIRY_BY_ID:
      return {
        ...state,
        loading: true,
        paginatorCount: 0
      };

    case enquiryTypes.GET_ENQUIRY_LIST_SUCCESS:

      return {
        ...state,
        loading: false,
        enquiryList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount : action.payload.data.items?.length
      };
    case enquiryTypes.GET_JOBSHEET_DROPDOWN_SUCCESS:
      
      return {
        ...state,
        loading: false,
        jobSheetList:action.payload
      };
    case enquiryTypes.GET_FRANCHISE_JOBSHEET_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        jobSheetList:action.payload
      };
      case enquiryTypes.GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS:
            
      return {
          ...state,
          loading: false,
          employeeDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
      };
  case enquiryTypes.GET_FRANCHISE_DROPDOWN_LIST_SUCCESS:
      
      return {
          ...state,
          loading: false,
          franchiseDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
      };
  case enquiryTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS:
      
      return {
          ...state,
          loading: false,
          franchiseSubuserDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
      };
    case enquiryTypes.GET_ENQUIRY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        enquiry: action.payload
      };

    case enquiryTypes.GET_ENQUIRY_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case enquiryTypes.DELETE_ENQUIRY:
      return {
        ...state,
        loading: true,
      };

    case enquiryTypes.DELETE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "ENQUIRY deleted successfully"
      }

    case enquiryTypes.POST_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "ENQUIRY created successfully",
      }

    case enquiryTypes.DELETE_ENQUIRY_FAIL:  
    case enquiryTypes.POST_ENQUIRY_FAIL:  
    case enquiryTypes.GET_ENQUIRY_BY_ID_FAIL:  
    case enquiryTypes.GET_JOBSHEET_DROPDOWN_FAIL:  
    console.log(action.payload);
    
    return {
      ...state,
      message:"",
      loading: false,
      error: action.payload
    };

    
    case enquiryTypes.RESET_ENQUIRY:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        enquiry:{},
        jobSheetList:[]
      };
    
    case enquiryTypes.CLEAR_ENQUIRY:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
      };

    default:
      return { ...state };
  }
};

export default Enquiry;
