import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { formatDateTOYYYYMMDD } from 'src/helpers/common_helpers';
import { createSelector } from 'reselect'
import { useToast } from "src/components/Common/ReactToaster";
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import InputCom from 'src/components/Common/Input'
import Button from 'src/components/UI/Button'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import { createRewardValueLoading, getRewardValueLoading } from 'src/store/RewardValue/action'
import './Reward.scss'

const RewardPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const editUserClick = () => {
    // navigate(`${ROUTER.PROFILE_EDIT}/${"1"}`)
    setEditMode(!EditMode)
  }
  const profiledata = createSelector(

    (state: any) => state.profile,
    (state) => ({
      success: state.success,
    })
  );
  // Inside your component
  const { error, loading, message, rewardValue } = useSelector(
    (state: RootReducerState) => state.rewardValueReducer
  );


  const { showToast } = useToast();
  const [visible, setVisible] = useState<any>()

  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [EditMode, setEditMode] = useState(false)
  useEffect(() => {
    dispatch(getRewardValueLoading())
  }, []);
  const [generaldetails, setGeneralDetail] = useState<any>([]);


  const initialvalue = {
    value: rewardValue?.value ? rewardValue?.value : 0,
    startDate: rewardValue?.startDate ? formatDateTOYYYYMMDD(rewardValue?.startDate) : "",
    endDate: rewardValue?.endDate ? formatDateTOYYYYMMDD(rewardValue?.endDate) : "",
  }


  const handleSubmit = async (values: any) => {

    dispatch(createRewardValueLoading(values))
  };

  const validationSchema = Yup.object().shape({
    value: Yup.number().required('Value is required').positive("Value must be greater than 0").integer("Value must be an integer").test('is-max-7-digits', 'Value must be at most a 7-digit number', value =>
      value ? /^[0-9]{1,7}$/.test(value.toString().replace('.', '')) : true
    ),
    startDate: Yup.date()
      .required('Start Date is required')
      .typeError('Start Date must be a valid date'),
    endDate: Yup.date()
      .required('End Date is required')
      .typeError('End Date must be a valid date')
      .test('is-greater', 'End Date must be later than Start Date', function (value) {
        const { startDate } = this.parent;
        return startDate ? value > startDate : true;
      }),

  });

  const handleReset = (resetForm: any) => {
    setEditMode(false)
  }
  useEffect(() => {
    if (error) {
      setVisible(true)
    }
    if (message) {
      showToast(message, { type: "success" });
      setEditMode(false)
      dispatch(getRewardValueLoading())
    }
  }, [error, message])
  const hidePopup = () => {
    setVisible(false);
    // dispatch(getRewardValueLoading())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const navigateMoreDetails = () => {
    navigate(ROUTER.FRANCHISEE_PROFILE_VIEW)
  }
  const navigateEmployeeDetails = () => {
    navigate(ROUTER.PROFILE_EMPLOYEE)
  }
  return (
    <React.Fragment>
      <div className='userpage' id="profilebody">
        <Container fluid>
          {/* <BreadcrumbWithFilter
            // title="Profile" breadcrumbItem='User Details'
            // isShowCreate={false}
            // isShowFilter={false}
            filter={false}
            isShowFirstButton={!EditMode}
            firstButtonLabel='Edit Profile'
            onFirstButtonClick={editUserClick}
          /> */}


          <Row id="reward">
            <Card style={{ borderRadius: "20px" }} id="profile">
              <CardBody>
                {error && dialogContainer(error)}
                <h5 className='mb-4 font-size-18'>Edit Reward Value</h5>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row className=''>
                            <div className="grid-container">
                              <div className="grid-item label">
                                <label htmlFor="value">Value <span style={{color:"red"}}>*</span></label>
                              </div>
                              <div className="grid-item input">
                                <Col lg={12} className="mb-2">
                                  <InputCom
                                    labelName=""
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="value"
                                    onBlur={handleBlur}
                                    value={values.value}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={touched["value"] && errors["value"] ? true : false}
                                  />
                                </Col>
                              </div>

                              <div className="grid-item label">
                                <label htmlFor="startDate">Start Date <span style={{color:"red"}}>*</span> </label>
                              </div>
                              <div className="grid-item input">
                                <Col lg={12} className="mb-2">
                                  <InputCom
                                    labelName=""
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="startDate"
                                    onBlur={handleBlur}
                                    value={values.startDate}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={touched["startDate"] && errors["startDate"] ? true : false}
                                  />
                                </Col>
                              </div>

                              <div className="grid-item label">
                                <label htmlFor="endDate">End Date <span style={{color:"red"}}>*</span> </label>
                              </div>
                              <div className="grid-item input">
                                <Col lg={12} className="mb-2">
                                  <InputCom
                                    labelName=""
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="endDate"
                                    onBlur={handleBlur}
                                    value={values.endDate}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={touched["endDate"] && errors["endDate"] ? true : false}
                                  />
                                </Col>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-5">

                              <Button
                                className="secondary-btn me-2"
                                label="Reset"
                                type="button"
                                onClick={() => resetForm()}
                              />
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label="Update Reward Value"
                                type="submit"
                              />
                            </div>

                          </Row>


                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>

        </Container>
      </div >
    </React.Fragment >
  )
}

export default RewardPage