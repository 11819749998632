import React, { useEffect, useRef, useState } from 'react'
import DeleteModal from '../Calendar/DeleteModal';
import ArchiveModal from "../Calendar/DeleteModal";
import CustomizeTable from 'src/components/Common/CustomizeTable/CustomizeTable';
import { Form, Formik } from 'formik';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'src/components/Common/Select';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { subUser } from 'src/models/pages/subUserModel';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate';
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { Option } from "src/models/components/inputWrapperModel";
import { Dropdown } from 'primereact/dropdown';
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from 'react-redux';
import { clearFranchiseSubuserMessage, deleteSubuserLoading, getSubuserListLoading, resetSubuser } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from 'src/constants/bulkActionVariant';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import TooltipMEL from 'src/components/UI/Tooltip';
import BulkActionStatus from 'src/components/Common/BulkActionStatus/BulkActionStatus';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import { hasAdminOrEmployeeRoleHandler, saveAsExcelFile } from 'src/helpers/common_helpers';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import { ACTIVE, ALL, DELETED } from 'src/constants/commonConstants';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { API } from 'src/util/api';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';

interface SubuserProps {
  id?: any;
  formValue?: any;
  isOnCreatePage?: boolean;
}

const SubUserList = ({ id, formValue, isOnCreatePage }: SubuserProps) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [selectedsubUser, setSelectedsubUser] = useState<any>([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  if (formValue) {
    localStorage.setItem('formvalues', JSON.stringify(formValue));
  }
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const dt = useRef<DataTable<any>>(null);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.subUserReducer.subUserList);
  const { loading, paginatorCount,message,error } = useSelector((state: RootReducerState) => state.subUserReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const initialValues = {
    status: "",
  };
  const hasAdminOrEmployeeRole = hasAdminOrEmployeeRoleHandler();


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);


  const [visible, setVisible] = useState(false);
  useEffect(() => {

    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearFranchiseSubuserMessage());
      dispatch(getSubuserListLoading(lazyState))
    }
  }, [error, message]);

  useEffect(()=>{
    return ()=>{
      dispatch(resetSubuser())
    }
  },[])


  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedsubUser([]);
  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };
  const navigate = useNavigate()

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    // { label: "Inactive", value: "Inactive" },
    // {
    //   label: "Awaiting Login Approval",
    //   value: "Awaiting Login Approval",
    // },
    // {
    //   label: "Awaiting Verification",
    //   value: "Awaiting Verification",
    // },
    // {
    //   label: "Awaiting Confirmation",
    //   value: "Awaiting Confirmation",
    // },
    // {
    //   label: "Active Selected",
    //   value: "Active Selected",
    // },
  ];
  const filterDetails: DataTableFilterMeta = {
    userName: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    email: { value: "", matchMode: "contains" },
    phoneNumber: { value: "", matchMode: "contains" },
    FranchiseId: { value: id ? id : "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "userName",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const bulkActionClickHandler = () => {
    if (values?.length > 0) {
      setSelectAll(true);
      setSelectedsubUser(values);
    }
  };

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((a) => {
      const { id, name: Name, email: Email, phoneNumber: Phone, address: Address, isActive: IsActive, lockoutEnabled: LockoutEnable,sendSms,sendEmail,sendWhatsapp,phoneNumberConfirmed,alternateNumber,panCardNo} = a;
      let data:any={};
      data['PAN No']=panCardNo;
      data['Send Sms']=sendSms;
      data['Alternate Mobile Number']=alternateNumber;
      data['Send Email']=sendEmail;
      data['Send Whatsapp']=sendWhatsapp;
      data['Mobile Number Verified']=phoneNumberConfirmed;

      return { ...{ Name, Email, Phone, Address, IsActive,LockoutEnable },...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = async (values: any[], fileName: string) => {
    let modifiedColumnDetails = await changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
	
    try {
      setPdfLoading(true)
      const response = await API.getFranchiseSubUserListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      // let resp=await dealerListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "Franchise SubUser");
     }else{
      saveDataAsExcel(response?.data?.items, "Franchise SubUser");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(values, "Franchise_SubUser");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        userName: data?.userName,
        name: data?.name,
        email: data?.email,
        phoneNumberConfirmed:data?.phoneNumberConfirmed,
        phoneNumber: data?.phoneNumber,
        alternateNumber: data?.alternateNumber,
        isActive: data?.isActive,
        sendSms:data?.sendSms,
        sendEmail:data?.sendEmail,
        sendWhatsapp:data?.sendWhatsapp,
        lockoutEnabled: data?.lockoutEnabled,
        address: data?.address,
        panCardNo: data?.panCardNo
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },
        }));
        let columnsWithVerified=[...exportColumns,{
          title:"Mobile Number Verified",
          dataKey:"phoneNumberConfirmed",
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },

        }]
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        doc.autoTable(columnsWithVerified, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "Franchise SubUser");
    fetchData(true)
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.SUB_USER_CREATE);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Franchise Subusers List
      </div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Sub Users"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedsubUser}
          bulkActionSatusTitle='Subusers'
          showBorderRight={true}
        /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {selectedsubUser.length == 0 && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedsubUser.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
          <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
            <FeatherIcon icon="archive" className="mr-1" />
            Archive
          </Button>
        </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space" onClick={() => { setDeleteModal(true); }}>
                  {/* <FeatherIcon icon="trash-2" className="mr-1" /> */}
                  {/* {selectedStatus == DELETED ? "Restore" : "Delete"} */}
                  {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> Cancel selection</Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedsubUser.length > 0 && <BulkActionStatus count={selectedsubUser.length} title="Sub Users"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>

        <Button type="button" className="customize-table-button mr-1 ml-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="mr-1" />
        </Button>
        {
          !hasAdminOrEmployeeRole && !isOnCreatePage && !id &&
          <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Franchise Subuser
          </Button>

        }
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const phoneVerifiedTemplate =(rowData:any) => {
    return(
      <div className="d-flex gap-1 align-items-center">
      <b>{rowData.phoneNumber}</b>
      {rowData.phoneNumberConfirmed ? <FeatherIcon icon="check-circle" className={`icon-lg right-icon`} style={{ color:"green",width:"20px"}}/>:null}
      </div>
    )
  }

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
      frozen : true,
    },
    {
      field: "userName",
      sortable: true,
      header: "User Name",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "userName"),
      isDefault: true,
      isStatic: true,
    },

    {
      field: "name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "200px", marginLeft: "20px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => emailBodyTemplate(rowData, "email"),
      isStatic: false,
    },
    {
      field: "phoneNumber",
      sortable: true,
      header: "Mobile",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>phoneVerifiedTemplate(rowData),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "alternateNumber",
      sortable: false,
      header: "Alternate Mobile Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "panCardNo",
      sortable: false,
      header: "PAN",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: true,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sendSms",
      sortable: true,
      header: "Send Sms",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'sendSms'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sendEmail",
      sortable: true,
      header: "Send Email",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'sendEmail'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sendWhatsapp",
      sortable: true,
      header: "Send Whatsapp",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'sendWhatsapp'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "lockoutEnabled",
      sortable: true,
      header: "Lockout Enabled",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'lockoutEnabled'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "address",
      sortable: true,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  const defaultColumns = {
    ...columns[0]
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [rowData, setRowData] = useState<subUser | null>(null);
  const [archiveModal, setArchiveModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  // const handleDeleteUsers = (rowData: subUser) => {
  //   let payload = {
  //     id: rowData["id"],
  //     lazyState,
  //   };
  //   dispatch(deleteSubuserLoading(payload));
  //   dispatch(getSubuserListLoading(lazyState));
  //   setDeleteModal(false);
  //   setRowData(null);
  //   showToast("SubUser Deleted Successfully", { type: "success" });
  // };
  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {

    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      lazyState,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
    };
    dispatch(deleteSubuserLoading(payload));

    // dispatch(getSubuserListLoading(lazyState));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setTimeout(() => {
        dispatch(getSubuserListLoading(lazyState));
      }, 500);
      setSelectAll(false);
      setSelectedsubUser([]);

    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedsubUser([]);
    setArchiveModal(false);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const emailBodyTemplate = (rowData: subUser, field: string) => {
    let data = rowData[field];
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const statusBodyTemplate = (rowData: subUser, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );

  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false)
  };

  const viewHandler = (rowData: subUser) => {
    if(id){
      navigate(`${ROUTER.SUB_USER_VIEW}/${rowData["id"]}/${id}`);
    }else{
      navigate(`${ROUTER.SUB_USER_VIEW}/${rowData["id"]}/0`);
    }
  };

  const editHandler = (rowData: subUser) => {
    if(id){
      navigate(`${ROUTER.SUB_USER_EDIT}/${rowData["id"]}/${id}`);
    }else{
      navigate(`${ROUTER.SUB_USER_EDIT}/${rowData["id"]}/0`);
    }
  };

  const deleteHandler = (rowData: subUser) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const actionBodyTemplate = (rowData: subUser, field: string) => {
    return (
      // <ColumnAction
      //   displayData={rowData[field] as string}
      //   isEdit={true}
      //   isDelete={true}
      //   isView={true}
      //   onViewClick={() => viewHandler(rowData)}
      //   onEditClick={() => editHandler(rowData)}
      //   onDeleteClick={() => deleteHandler(rowData)}
      // />
      <>
      {
        rowData.isDeleted == false ?
          (
            <ColumnAction
              displayData={rowData[field] as string}
              isEdit={true}
              isDelete={true}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )

      }
    </>
      
    );
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const handleStatusBlur = () => { };

  const onSelectionChange = (event: any) => {
    const value = event.value;

    setSelectedsubUser(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      setSelectAll(true);
      setSelectedsubUser(values);
    } else {
      setSelectAll(false);
      setSelectedsubUser([]);
    }
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };
  const onPage = (event: any) => {
    setLazyState(event);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getSubuserListLoading(lazyState));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);


  const deleteSubUser = () => {
    // if (rowData) {
    //   handleDeleteUsers(rowData);
    // }
    if (selectedsubUser?.length > 10) {
      showToast("Cannot delete more than 10 items at once", { type: "error" });
      setSelectedsubUser([]);
      setDeleteModal(false);
      setSelectAll(false);
      return setRowData(null);
    }

    if (rowData || selectedsubUser.length == 1) {
      handleDeleteUsers(rowData || selectedsubUser);
    } else if (selectedsubUser.length > 1) {
      for (let i = 0; i < selectedsubUser.length; i++) {
        handleDeleteUsers(selectedsubUser[i], selectedsubUser.length, i);
      }
    }
  }

  
  const hidePopup = () => { 
    setVisible(false); 
    dispatch(clearFranchiseSubuserMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
         <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className='vw30' onHide={() => { setVisible(false); dispatch(resetFranchisee()); }}>
          <div className="m-0 validation-text">
            {error}
          </div>
        </Dialog> */}
      </>
    );
  };
  const [subUserPage,setSubUserPage]=useState<boolean>(true)
  const currentUrl = window.location.href;
  const res=currentUrl.split('/')

  useEffect(()=>{
    if(`/${res[res.length-1]}`==ROUTER.SUB_USER_GRID){
      setSubUserPage(true)
    }else{
      setSubUserPage(false)
    }
  },[currentUrl])
  
  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <div className={!subUserPage ? "" : "page-content"}>
      {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={deleteSubUser}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these customers?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedsubUser([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus == "All" ? [...visibleColumns]?.filter((items: any) => items.field != "select") : visibleColumns}
          filterIcon={FilterIcon}
          header={header}
          value={values}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying subuser  {last} records of {totalRecords} in total"
          selection={selectedsubUser}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default SubUserList


