import { StockQuantityByMusicSystemBrandInitialStore } from "src/models/pages/stockQuantityByMusicSystemModel";
import { PendingPartsConsolidatedTypes } from "./actionTypes";
import { PendingPartsConsolidatedListStore } from "src/models/pages/pendingPartsConsolidate";

const initialValue: PendingPartsConsolidatedListStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  pendingPartsConsolidatedList: {
    values: [],
    totalRecords: 0
  },
}

const pendingPartsConsolodated = (state = initialValue, action: any) => {
  switch (action.type) {
    case PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }

    case PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingPartsConsolidatedList: {
          ...state.pendingPartsConsolidatedList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount
        },
        paginatorCount : action.payload.data.items.length
      }

    case PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state };
  }
}


export default pendingPartsConsolodated;