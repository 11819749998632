export enum FeedbackActions {

    //grid 
    GET_FEEDBACK_LIST_LOADING = "@@feedback/GET_FEEDBACK_LIST_LOADING",
    GET_FEEDBACK_LIST_SUCCESS = "@@feedback/GET_FEEDBACK_LIST_SUCCESS",
    GET_FEEDBACK_LIST_ERROR = "@@feedback/GET_FEEDBACK_LIST_ERROR",

    GET_JOBSHEET_DETAILS_LOADING = "@@feedback/GET_JOBSHEET_DETAILS_LOADING",
    GET_JOBSHEET_DETAILS_SUCCESS = "@@feedback/GET_JOBSHEET_DETAILS_SUCCESS",
    GET_JOBSHEET_DETAILS_ERROR = "@@feedback/GET_JOBSHEET_DETAILS_ERROR",

    GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_LOADING = "@@feedback/GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_LOADING",
    GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_SUCCESS = "@@feedback/GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_SUCCESS",
    GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_ERROR = "@@feedback/GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_ERROR",

    CREATE_FEEDBACK_LOADING = "@@feedback/CREATE_FEEDBACK_LOADING",
    CREATE_FEEDBACK_SUCCESS = "@@feedback/CREATE_FEEDBACK_SUCCESS",
    CREATE_FEEDBACK_ERROR = "@@feedback/CREATE_FEEDBACK_ERROR",

    RESET_FEEDBACK_MESSAGE = "@@feedback/RESET_FEEDBACK_MESSAGE",
    CLEAR_FEEDBACK_VALUE = "@@feedback/CLEAR_FEEDBACK_VALUE",



    //get vehicle brand data in grid page

    GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_LOADING = "@@feedback/GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_LOADING",
    GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_SUCCESS = "@@feedback/GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_SUCCESS",
    GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_ERRROR = "@@feedback/GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_ERROR",

    //get feedback by id
    GET_FEEDBACK_BYID_LOADING = "@@feedback/GET_FEEDBACK_BYID_LOADING",
    GET_FEEDBACK_BYID_SUCCESS = "@@feedback/GET_FEEDBACK_BYID_SUCCESS",
    GET_FEEDBACK_BYID_ERROR = "@@feedback/GET_FEEDBACK_BYID_ERROR",

}