export const supplier=[
  {value:"Appline - supplier",label:"Appline - supplier"},
  {value:"GUANGDONG CREATOR & FLYAUDIO ELANDTECT CO.LTD",label:"GUANGDONG CREATOR & FLYAUDIO ELANDTECT CO.LTD"},
  {value:"Annai Auto Accessories",label:"Annai Auto Accessories"},
  {value:"AUTO IMPEX",label:"AUTO IMPEX"},
  {value:"BALUPUNKT INDIA PRIVATE LIMITED",label:"BALUPUNKT INDIA PRIVATE LIMITED"},
  {value:"CE INFO SYSTEMS PVT LTD",label:"CE INFO SYSTEMS PVT LTD"},
  {value:"FLYAUDIO SERVICES INDIA PRIVATE LIMITED-Hyderabad",label:"FLYAUDIO SERVICES INDIA PRIVATE LIMITED-Hyderabad"},
  {value:"Jiangsu Toppower Automotive",label:"Jiangsu Toppower Automotive"},
  {value:"Mectronics Marketing Services",label:"Mectronics Marketing Services"},

];

export const status=[
  {value:"Missing",label:"Missing"},
  {value:"Rejected",label:"Rejected"},
  {value:"Scrapped",label:"Scrapped"}
];

export const deliveryType=[
  {value:"Courier",label:"Courier"},
  {value:"HandCarry",label:"Hand Carry"}
]

export const currency=[
  {value:"INR",label:"INR"},
  {value:"USD",label:"USD"},
  {value:"CNY",label:"CNY"},
  {value:"EUR",label:"EUR"},
  {value:"GBP",label:"GBP"},
  {value:"MYR",label:"MYR"},
  {value:"THB",label:"THB"}
]
export const yesno=[
  {
    value:"YES",label:"YES"
  },
  {
    value:"NO",label:"NO"
  }
]

export const courier=[
  {value:1,label:"Courier 1"},
  {value:2,label:"Courier 2"}

]
// export const PurchaseOrderFormFields  = [
//     // {
//     //   id: "number",
//     //   labelClassName: "form-Label",
//     //   inputclassName: "form-control",
//     //   type: "text",
//     //   name: "number",
//     //   labelName: "Number",
//     //   required: true,
//     //   initialValue: "",
//     //   xs: 12, //extra small
//     //   sm: 6, //tablet
//     //   md: 4, // laptop
//     //   lg: 3, //desktop
//     // },
//     {
//       id: "generatedDate",
//       labelClassName: "form-Label",
//       inputclassName: "form-control",
//       type: "date",
//       name: "generatedDate",
//       labelName: "Generated Date",
//       required: true,
//       initialValue: "",
//       xs: 12, //extra small
//       sm: 6, //tablet
//       md: 4, // laptop
//       lg: 3, //desktop
//     },
//     {
//       id: "buyingCurrency",
//       labelClassName: "form-Label",
//       inputclassName: "form-control",
//       type: "select",
//       options:currency,
//       name: "buyingCurrency",
//       labelName: "Buying Currency",
//       required: true,
//       initialValue: "",
//       xs: 12, //extra small
//       sm: 6, //tablet
//       md: 4, // laptop
//       lg: 3, //desktop
//     },
//     {
//       id: "supplier",
//       labelClassName: "form-Label",
//       inputclassName: "form-control",
//       type: "select",
//       name: "supplier",
//       options:supplier,
//       labelName: "Supplier",
//       required: true,
//       initialValue: "",
//       xs: 12, //extra small
//       sm: 6, //tablet
//       md: 4, // laptop
//       lg: 3, //desktop
//     },
//     {
//       id: "remarks",
//       labelClassName: "form-Label",
//       inputclassName: "form-control",
//       type: "textarea",
//       name: "remarks",
//       labelName: "Remarks",
//       required: false,
//       initialValue: "",
//       xs: 12, //extra small
//       sm: 6, //tablet
//       md: 4, // laptop
//       lg: 3, //desktop
//     },
  
//     {
//       id: "locked",
//       labelClassName: "form-Label",
//       inputclassName: "form-control",
//       type: "select",
//       name: "locked",
//       labelName: "Locked",
//       options:[
//         {
//           value:"YES",label:"YES"
//         },
//         {
//           value:"NO",label:"NO"
//         }
//       ],
//       initialValue: "",
//       required: true,
//       xs: 12, //extra small
//       sm: 6, //tablet
//       md: 4, // laptop
//       lg: 3, //desktop
//     },

//   ]  

