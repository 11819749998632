import { stockAdjustmentInitialState } from './../../models/pages/stockAdjustment';
import { StockAdjustmentTypes } from "./actionTypes";


const initialState: stockAdjustmentInitialState = {
  error: "",
  loading: false,
  message: "",
  StockAdjustmentList: {
    values: [],
    totalRecords: 0
  },
  editOrder: null,
  partSku: null,
  supplierList: null,
  initiatedBy: null,
  courierList: [],
  push: () => { },
  partSkuStockList: [],
  partsSkuStockIndex: -1,
  partsSkuStockSetFieldValue: () => { },
  values:""

};



const StockAdjustment = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST:
    case StockAdjustmentTypes.PARTS_SKU_API_CALL:
    case StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT:
    case StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT:
    case StockAdjustmentTypes.GET_COURIER_DROPDOWN_LOADING:
    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID:
      return {
        ...state,
        loading: true,
      };

    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST_SUCCESS:

      return {
        ...state,
        loading: false,
        StockAdjustmentList: {
          ...state.StockAdjustmentList,
          values: action.payload.data.items,
          totalRecords: action.payload.data?.totalCount,
        }
      };
    case StockAdjustmentTypes.UPDATE_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Stock Adjustment Updated Successfully"
      }
    case StockAdjustmentTypes.CREATE_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Stock Adjustment Created Successfully"
      }
    case StockAdjustmentTypes.GET_SUPPLIER_DROPDOWN_SUCCESS:
      

      return {
        ...state,
        supplierList: action.payload.data
      }
    case StockAdjustmentTypes.GET_INITIATED_BY_DROPDOWN_SUCCESS:
      return {
        ...state,
        initiatedBy: action.payload.data
      }
    case StockAdjustmentTypes.PARTS_SKU_API_CALL_SUCCESS:
      

      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        // partSku: {
        //   list: action.payload.response.data,
        //   index: action.payload.index,
        //   setFieldValue: action.payload.setFieldValue
        // },
        partSkuStockList: [
          // ...state.partSkuStockList,
          ...action.payload.response.data
        ],
        partsSkuStockIndex: action.payload.index,
        partsSkuStockSetFieldValue: action.payload.setFieldValue,
        push: action.payload.push,
        values:action.payload.values
      }
    case StockAdjustmentTypes.REASET_PARTSKU_INDEX:
      

      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        partSku: null,
        partsSkuStockIndex: -1
      }

    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT:
      

      return {
        ...state,
        loading: true,
      };

    case StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT_SUCCESS:
      
      return {
        ...state,
        loading: false,
        message: "Stock Adjustment Deleted Successfully"
      }

    case StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: ""
      }

    case StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT_FAIL:
      
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS:

      return {
        ...state,
        loading: false,
        editOrder: action.payload.data

      }
    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID_SUCCESS:

      return {
        ...state,
        loading: false,
        editOrder: action.payload.data

      }

    case StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT_SUCCESS:

      return {
        ...state,
        loading: false,
        message: "Successfully emailed the part rejected order to the supplier!",
        error: ""

      }

    case StockAdjustmentTypes.RESET_MESSAGE:
      return {
        ...state,
        loading: false,
        message: "",
        error: ""
      }

    case StockAdjustmentTypes.DELETE_PATS_SKU_LIST:
      const updatedPartsSkuList = state.partSkuStockList.filter((data: any, index: number) => index !== action.payload);
      return {
        ...state,
        loading: false,
        partSkuStockList: updatedPartsSkuList
      }

    case StockAdjustmentTypes.RESET_STOCK_ADJUSTMENT:
      return {
        ...state,
        ...initialState
      }

    case StockAdjustmentTypes.GET_COURIER_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        courierList: action.payload.data?.filter((eachCourier: any) => !eachCourier.isDeleted).filter((eachCourier: any) => eachCourier.companyName != "")
      }


    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_ID_FAIL:
    case StockAdjustmentTypes.CREATE_STOCK_ADJUSTMENT_ERROR:
    case StockAdjustmentTypes.UPDATE_STOCK_ADJUSTMENT_ERROR:
    case StockAdjustmentTypes.PARTS_SKU_API_CALL_ERROR:
    case StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT_FAIL:
    case StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT_FAIL:
    case StockAdjustmentTypes.GET_COURIER_DROPDOWN_ERROR:
    case StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: ""
      }

    default:
      return { ...state };
  }
};

export default StockAdjustment;