import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import JobTooltipMEL from 'src/components/UI/JobTooltip';
import TooltipMEL from 'src/components/UI/Tooltip';
import { ROUTER } from 'src/constants/routes';
import TimeAgo from 'src/helpers/TimeAgo';
import { formatDateTimeMEL } from 'src/helpers/common_helpers';
import { getEnquiryDocumentLinkLoading, getEnquiryUpoadedDocumentLoading, getInduvidualEnquiryLoading, getReplyMessageFromEmployeeLoading, resetEnquiryState } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';




const ViewTicketSystem = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser: any = localStorage.getItem("authUser");
  const parseUser = JSON.parse(currentUser);
  const { error, loading, induvidualEnquiry, enquiryDocument, replyMessages, enquiryDocumentLink } = useSelector((state: RootReducerState) => state.ticketSystemReducer)
  console.log("induvidualEnquiry", induvidualEnquiry)
  const [visible, setVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [editTooltipVisible, setEditTooltipVisible] = useState<boolean>(false);
  // const replyMessage: any[] = [
  //   {
  //     name: "Ragu",
  //     createdOn: "April 21, 2024 at 11:40",
  //     description: "My audio part is not able to increase the volume",
  //     attachments: ["payment_screenshot.pdf", "payment_error_screenshot.pdf"],
  //     className: "message-align-left"
  //   },
  //   {
  //     name: "Sangeeth Rajan",
  //     createdOn: "April 21, 2024 at 11:40",
  //     description: "My audio part is not able to increase the volume",
  //     className: "message-align-right"
  //   },
  //   {
  //     name: "Karthik Pandin",
  //     createdOn: "April 21, 2024 at 11:40",
  //     description: "My audio part is not able to increase the volume",
  //     className: "message-align-left"
  //   },
  // ]
  const replyMessage = useMemo(() => {
    return [
      {
        name: induvidualEnquiry?.customerName ? induvidualEnquiry?.customerName : "testUser",
        createdOn: formatDateTimeMEL(induvidualEnquiry?.creationTime),
        description: induvidualEnquiry?.description.split('\r\n')[1],
        attachments: enquiryDocument?.length > 0 ? enquiryDocument?.map((data: any) => {
          let object = {
            name: data.name,
            id: data.id
          }
          return object
        }) : [],
        className: "message-align-left"
      },
    ];
  }, [induvidualEnquiry, enquiryDocument]);



  const editData: any = {
    subject: induvidualEnquiry?.subject,
    description: induvidualEnquiry?.description,
    status: induvidualEnquiry?.status,
    priority: induvidualEnquiry?.priority,
    createdOn: formatDateTimeMEL(induvidualEnquiry?.creationTime),
    attachement: induvidualEnquiry?.documents.map((data) => {
      let object = {
        name: data.name,
        type: data.mimeType,
        id: data.id
      }
      return object
    })
  }

  const hidePopup = () => {
    setVisible(false);
    // dispatch(resetEnquiryState())
  }
  useEffect(() => {
    if (induvidualEnquiry) {
      dispatch(getReplyMessageFromEmployeeLoading(induvidualEnquiry?.id))
    }
  }, [induvidualEnquiry])
  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    dispatch(getInduvidualEnquiryLoading(id))
    dispatch(getEnquiryUpoadedDocumentLoading(id, "Enquiry"))
  }, [id])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error])

  const createEnqiuiryDocumentLink = (id: string) => {
    dispatch(getEnquiryDocumentLinkLoading(id))
  }
  useEffect(() => {
    if (enquiryDocumentLink != "" && enquiryDocumentLink != undefined && enquiryDocumentLink != null) {
      const newWindow = window.open(enquiryDocumentLink)
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(resetEnquiryState())
    }
  }, [enquiryDocumentLink])

  const openDocumet = (id: string) => {
    dispatch(getEnquiryDocumentLinkLoading(id))
  }

  const combinedMessages = replyMessages?.map(data => {
    const isCustomer = data.isCustomer;
    const classDecide = data.creatorId;
    const name = isCustomer ? data.customerName : (data.supporterName ? data.supporterName : "test");
    // const className = isCustomer ? "message-align-right" : "message-align-left";
    const className = (classDecide == parseUser?.uid) ? "message-align-right" : "message-align-left";
    const messageClass = (classDecide == parseUser?.uid) ? "message-box-container" : "message-box-container_response"

    return {
      name: name,
      createdOn: <TimeAgo timeStamp={data.creationTime} />,
      description: data.description,
      className: className,
      attachments: data.documents.length > 0 ? data.documents.map(doc => ({
        name: doc.name,
        id: doc.id,
        type: doc.mimeType
      })) : [],
      messageClass: messageClass
    };
  });

  const isImageMimeType = (mimeType: any) => {
    const imageMimeTypes = /^image\/(png|jpeg|jpg|gif|svg\+xml|bmp|webp|tiff|ico)$/;
    return imageMimeTypes.test(mimeType);
  }

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className="page-content" id="ticket-system">
        <Container fluid>
          <BreadcrumbWithFilter
            title="Ticket system"
            titlePath={ROUTER.TICKET_SYSTEM}
            breadcrumbItem={"View Ticket System"}
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
          />

          <Row>
            <div className="general-details">
              <b>Ticket Details</b>
            </div>
            <Card>
              {/* <CardBody className='container'> */}
              <CardBody className=''>
                <React.Fragment>
                  <Row>
                    {id &&
                      <>
                        <div className="view-details-container">
                          <div className={` pt-3 viewTicketCls`}>
                            <div>Subject</div>
                            <div className='childTicket'>:</div>
                            <div className='weight-700'>{editData['subject']}</div>
                          </div>
                         
                          <div className={` pt-3 viewTicketCls`}>
                            <div>Status</div>
                            <div className='childTicket'>:</div>
                            <div className='weight-700'>{editData['status']}</div>
                          </div>
                         
                          <div className={` pt-3 viewTicketCls`}>
                            <div>Priority</div>
                            <div className='childTicket'>:</div>
                            <div className='weight-700'>{editData['priority']}</div>
                          </div>
                         
                          <div className={` pt-3 viewTicketCls`}>
                            <div>Description</div>
                            <div className='childTicket'>:</div>
                            <div className='weight-700'>{editData['description']}</div>
                          </div>
                         
                          <div className={` pt-3 viewTicketCls`}>
                            <div>Created On</div>
                            <div className='childTicket'>:</div>
                            <div className='weight-700'>{editData['createdOn']}</div>
                          </div>
                         
                          <div className={` pt-3 viewTicketCls`}>
                            <div>Attachements</div>
                            <div className='childTicket'>:</div>
                            <div className='weight-700'>
                              <div className='editedDate_file'>
                                {
                                  editData?.attachement?.length > 0 ? editData?.attachement?.map((data: any, i: number) => {
                                    if (data.type == "application/pdf") {
                                      return (
                                        <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                          <i onClick={() => openDocumet(data.id)} className="fas fa-file-pdf"></i>
                                        </JobTooltipMEL>
                                      )
                                    } else if (data.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || data.type == "application/vnd.ms-excel") {
                                      return (
                                        <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                          <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-csv"></i>
                                        </JobTooltipMEL>
                                      )
                                    } else if (isImageMimeType(data.type)) {
                                      return (
                                        <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                          <i key={i} onClick={() => openDocumet(data.id)} className="fas fas fa-image"></i>
                                        </JobTooltipMEL>
                                      )
                                    } else {
                                      return (
                                        <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                          <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-alt"></i>
                                        </JobTooltipMEL>
                                      )
                                    }
                                  }) : <span style={{ fontSize: "14px" }}>No attachement here</span>
                                }
                              </div>
                            </div>
                          </div>
                          {
                            induvidualEnquiry?.parentId && <><div className={` pt-3 viewTicketCls`}>
                              <div>Check prevoius conversation</div>
                              <div className='childTicket'>:</div>
                              <Link to={ROUTER.VIEW_TICKET_SYSTEM + "/" + induvidualEnquiry?.parentId} >Show</Link>
                            </div>
                            </>
                          }
                        </div>
                      </>
                    }
                    {selectedFiles?.length > 0 && <Col lg={12}>
                      <div className='selected-file-container'>
                        <h5>Selected Files</h5>
                        <div className=''>
                          <Row>
                            {selectedFiles.map((eachValues: any, index: number) => {
                              return (<Col lg={6} key={eachValues.name + index}>
                                <div className='reply-attachment-container'>
                                  <div>{eachValues.name}</div>
                                  <div><i className="far fa-trash-alt selected-file-delete-icon" onClick={() => {
                                    setSelectedFiles((prevFiles: any[]) => {
                                      let data: any = [...prevFiles];
                                      data = data.filter((eachFiles: any, fileIndex: number) => fileIndex != index);
                                      return data;
                                    })
                                  }}></i></div>
                                </div>
                              </Col>)
                            })}
                          </Row>
                        </div>
                      </div>
                    </Col>}
                  </Row>
                  {(id && replyMessages?.length > 0) && <Row className='reply-container'>
                    <div>
                      {combinedMessages.map((eachMessage: any, index: number) => {
                        return (
                          <div key={index} className={eachMessage.className}>
                            <div className='message-sender-container'>
                              <div className="weight-700">{eachMessage?.name}</div>
                              <div className='message-box-date'>{eachMessage?.createdOn}</div>
                            </div>
                            <div className={eachMessage.messageClass}>
                              {eachMessage?.description}
                            </div>
                            <div className='file_structures'>
                              {
                                eachMessage?.attachments?.map((data: any, i: number) => {
                                  if (data.type == "application/pdf") {
                                    return (
                                      <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                        <i onClick={() => openDocumet(data.id)} className="fas fa-file-pdf"></i>
                                      </JobTooltipMEL>
                                    )
                                  } else if (data.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                                    return (
                                      <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                        <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-csv"></i>
                                      </JobTooltipMEL>
                                    )
                                  } else if (isImageMimeType(data.type)) {
                                    return (
                                      <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                        <i key={i} onClick={() => openDocumet(data.id)} className="fas fas fa-image"></i>
                                      </JobTooltipMEL>
                                    )
                                  } else {
                                    return (
                                      <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                        <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-alt"></i>
                                      </JobTooltipMEL>
                                    )
                                  }
                                })
                              }
                            </div>
                            <div>
                            </div>
                          </div>)
                      })}
                    </div>


                  </Row>}

                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewTicketSystem