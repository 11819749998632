import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Calendar/DeleteModal";
import ArchiveModal from "../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import { ClearCustomerImage, clearCustomMessage, customerTaxTypeList, deleteCustomersFromList, getCustomersList } from "src/store/customers/action";
import Select from "src/components/Common/Select";
import { Item, customers, taxDetails } from "src/models/pages/customerModel";
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { formatDateMEL, formatDateTOYYYYMMDD, saveAsExcelFile } from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { Dropdown } from "primereact/dropdown";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import './styles.scss';
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { ACTIVE, ALL, DELETED, EMAIL_VERIFIED, TAX_TYPES_OBJECT } from "src/constants/commonConstants";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { API } from "src/util/api";



export default function Customers() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.customerReducer.customerList);
  const [customerData, setCustomerData] = useState<any>([]);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.customerReducer.loading);
  const { error, message, customerTaxList, paginationCount } = useSelector((state: any) => state.customerReducer);
  const dispatch: any = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<customers | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const[pdfLoadind,setPdfLoading]=useState<boolean>(false)
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    PhoneNumber: { value: "", matchMode: "contains" },
    Email: { value: "", matchMode: "contains" },
    IsVerfied: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Name",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearCustomMessage());
    }
    
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearCustomMessage());
  }

  const dialogContainer = (error: any) => {
    return (

      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(clearDealerMessage()); }}>
          <div className="m-0 validation-text">
            {error.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>);
            })}
          </div>
        </Dialog> */}
      </>
    );
  };

  useEffect(() => {
    dispatch(ClearCustomerImage())
  }, [])
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoadind) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoadind]);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.CUSTOMERS_CREATE);
  };

  const viewHandler = (rowData: customers) => {
    navigate(`${ROUTER.CUSTOMERS_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: customers) => {
    navigate(`${ROUTER.CUSTOMERS_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: customers) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: customers, field: string) => {

    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }

      </>
    );
  };

  const statusBodyTemplate = (rowData: customers, field: string) => {
    // let status = rowData[field].toString();
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };

  const taskTypeBodyTemplate = (rowData: customers, field: string) => {
    let status = rowData[field].toString();
    // let status = rowData[field];
    return (
      <>{TAX_TYPES_OBJECT[status]}</>
    );
  };

  const emailBodyTemplate = (rowData: customers, field: string) => {
    let data = rowData[field];
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const phoneVerifiedTemplate =(rowData:any) => {
    return(
      <div className="d-flex gap-1 align-items-center">
      <b>{rowData.PhoneNumber}</b>
      {rowData.phoneNumberConfirmed ? <FeatherIcon icon="check-circle" className={`icon-lg right-icon`} style={{ color:"green",width:"20px"}}/>:null}
      </div>
    )
  }
  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    { label: "Email verified", value: "Email verified" },
  ];

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      frozen : true,
      showFilterMenu: false,

      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "Name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "Name"),
      isDefault: true,
      frozen : true,
      isStatic: true,
    },
    {
      field: "Email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => emailBodyTemplate(rowData, "Email"),
      isStatic: false,
    },

    {
      field: "PhoneNumber",
      sortable: true,
      header: "Mobile",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>phoneVerifiedTemplate(rowData),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },

    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: false,
      isStatic: false,
      className: "table-cell-style"

    },
    {
      field: "gstNumber",
      sortable: false,
      header: "Gst Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "taxType",
      sortable: false,
      header: "Tax Type",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",
      // body: (rowData) => taskTypeBodyTemplate(rowData, 'taxType'),

    },
    {
      field: "alternateNumber",
      sortable: false,
      header: "Alternate Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "dateOfBirth",
      sortable: false,
      header: "DOB",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.dateOfBirth),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "weddingDate",
      sortable: false,
      header: "Wedding Date",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => formatDateMEL(rowData.weddingDate),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "sendSms",
      sortable: false,
      header: "Send Sms",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendSms'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "sendEmail",
      sortable: false,
      header: "Send Email",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendEmail'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "sendWhatsapp",
      sortable: false,
      header: "Send Whatsapp",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendWhatsapp'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachCustomer) => {
      const { user: { name: Name, email: Email, phoneNumber: Mobile,isActive ,phoneNumberConfirmed:phoneNumberConfirmed}, taxTypeId ,alternateNumber, user: { extraProperties: { Address, SendEmail, SendSms,SendWhatsapp, WeddingDate, GstNumber, DateOfBirth } } } = eachCustomer;
      let data: any = {};
      data['GST number'] = GstNumber;
      data['Tax type'] = customerTaxList.find((item: taxDetails) => item.id === taxTypeId)?.taxName
      data['Alternate number'] = alternateNumber;
      data['DOB'] = formatDateMEL(DateOfBirth);
      data['Wedding date'] = formatDateMEL(WeddingDate);
      data['Send SMS'] = SendSms;
      data['Send Email'] = SendEmail;
      data['Send Whatsapp'] = SendWhatsapp;
      data['Mobile Number Verified']=phoneNumberConfirmed;
      data['Is Active'] = isActive;
      return { ...{ Name, Email, Mobile, Address }, ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = async (values: any[], fileName: string) => {
    const modifiedColumnDetails = await changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    
    
    let destructureedData = values.map(eachCustomer => {
      let { id, user: { name: Name, email: Email, phoneNumber: PhoneNumber,isActive, phoneNumberConfirmed:phoneNumberConfirmed}, taxTypeId: taxType, alternateNumber, user: { extraProperties: { Address: address, SendEmail: sendEmail, SendSms: sendSms,SendWhatsapp:sendWhatsapp, WeddingDate, GstNumber: gstNumber, DateOfBirth } } } = eachCustomer;
      const weddingDate = formatDateMEL(WeddingDate)
      const dateOfBirth = formatDateMEL(DateOfBirth)
      taxType = customerTaxList.find((item: taxDetails) => item.id === taxType)?.taxName
      return { id, Name, Email, PhoneNumber, taxType, alternateNumber, address, sendEmail, sendSms, weddingDate, gstNumber, dateOfBirth,sendWhatsapp,isActive,phoneNumberConfirmed }
    })


    import("jspdf").then((jsPDF) => {

      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },
        }));
        let columnsWithVerified=[...exportColumns,{
          title:"Mobile Number Verified",
          dataKey:"phoneNumberConfirmed",
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },

        }]
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        doc.autoTable(columnsWithVerified, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const csvClickHandler = () => {  
    fetchData(false)
  };

  const fetchData = async (isPdf:boolean) => {
    let state= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    
    try {
      setPdfLoading(true)
      const response = await API.getCustomersListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "customers");
     }else{
      saveDataAsExcel(response?.data?.items, "customers");
     }
     setPdfLoading(false)
    } catch (error:any) {
      // console.log(error);
      showToast(error?.message,{type : "error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const pdfClickHandler = () => {
     fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(customerTaxTypeList())
    dispatch(getCustomersList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedCustomers(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginationCount);
  };

  const onSelectAllChange = (event: any) => {

    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedCustomers(customerData);
    } else {
      setSelectAll(false);
      setSelectedCustomers([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedCustomers([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setVisibleTooltip(false)
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == EMAIL_VERIFIED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: "true", matchMode: "contains" },
        },
      }));
    } else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    } 
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false)
    setSelectedCustomers([])
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    if (values) {
      const customerStructuredData: Item[] = values.map((data: Item | any) => {
        let modified = {
          id: data?.id,
          Name: data?.user.name,
          PhoneNumber: data?.user?.phoneNumber,
          Email: data?.user?.email,
          gstNumber: data?.user?.extraProperties?.GstNumber,
          phoneNumberConfirmed:data?.user?.phoneNumberConfirmed,
          taxType: customerTaxList.find((item: taxDetails) => item.id === data?.taxTypeId)?.taxName,
          dateOfBirth: data?.user?.extraProperties?.DateOfBirth ? formatDateTOYYYYMMDD(data?.user?.extraProperties?.DateOfBirth) : "",
          sendEmail: data?.user?.extraProperties?.SendEmail,
          sendSms: data?.user?.extraProperties?.SendSms,
          sendWhatsapp: data?.user?.extraProperties?.SendWhatsapp,
          address: data?.user?.extraProperties?.Address,
          alternateNumber: data?.alternateNumber,
          weddingDate: data?.user?.extraProperties?.WeddingDate,
          isActive : data?.user?.isActive,
          isDeleted: data?.isDeleted
        };
        return modified;
      });
      setCustomerData(customerStructuredData);
    } else {
      callbackFunction(lazyState);
    }
  }, [values]);

  const handleDeleteUsers = (rowData: customers, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    dispatch(deleteCustomersFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedCustomers([]);
      // showToast("Customer Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(customerTaxTypeList())
        dispatch(getCustomersList(lazyState));
      }, 500);
    }

  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedCustomers([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };



  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (customerData.length > 0) {
      setSelectAll(true);
      setSelectedCustomers(customerData);
    }
  };
const clearFilter=()=>{
  setLazyState(initialLazyState)
  setselectedStatus(ACTIVE)
}
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedCustomers([]);
  };
  const header =
    <div className="table-header-container">
      <div>
        Customers List
      </div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Customers"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedCustomers}
          bulkActionSatusTitle="Customers"
          showBorderRight={true}
        /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedCustomers.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
         <FeatherIcon  icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedCustomers.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
              <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
                <FeatherIcon icon="archive" className="mr-1" />
                Archive
              </Button>
            </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedCustomers.length > 0 && <BulkActionStatus count={selectedCustomers.length} title="Customers"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} isFilter clearFilter={clearFilter}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>
          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Customer
          </Button>
        </div>
        {/* <div className="reponsive_position">
          <FeatherIcon size={20} onClick={() => setVisibleRight(true)} icon="filter" className={"feathet-icon"} />
        </div> */}
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>
    </div>;




  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');


  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const initialvalue: any = {
    name: "",
    gstNumber: "",
  }

  return (
    <>
      <div className="page-content">
        {/* <Sidebar style={{width:"20%"}} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
          <h4>Filter Details</h4>
          <Row>
            <Col lg={12} className="mt-4">
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialvalue}
                enableReinitialize
              >
                {({
                  values,
                  handleChange,
                  touched,
                  errors,
                  handleBlur,
                  resetForm,
                  reset,
                  isSubmitting,
                  ...rest
                }: any) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col lg={12} className="mb-2">
                          <InputCom
                            labelName="Name"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="text"
                            name="name"
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isRequired={true}
                            invalid={
                              touched["name"] && errors["name"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={12} className="mb-2">
                          <InputCom
                            labelName="Gst Number"
                            inputclassName="form-control"
                            labelClassName="form-Label"
                            type="text"
                            name="gstNumber"
                            onBlur={handleBlur}
                            value={values.gstNumber}
                            onChange={handleChange}
                            isRequired={false}
                            invalid={
                              touched["gstNumber"] &&
                                errors["gstNumber"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>

                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label="Reset"
                          type="button">Clear Filters
                        </Button>

                        <Button
                          className="btn-primary mt-2"
                          label="Create Customer"
                          type="submit">
                          filter  
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Sidebar> */}

        {/* {visibleRight && <div style={{ width: "20%", position: "fixed", height: "100%", left: "0px",top: "0px",display: "flex",justifyContent: "flex-end",alignItems: "center", zIndex: 1101 }}>
        test
      </div>} */}


      {visible && dialogContainer(error)}
      <DeleteModal
        undo={selectedStatus == DELETED ? true : false}
        show={deleteModal}
        onDeleteClick={() => {
          if (rowData) {
            return handleDeleteUsers(rowData);
          }
          if (selectedCustomers?.length > 10) {
            showToast("Cannot delete more than 10 items at once", { type: "error" });
            setSelectedCustomers([]);
            setDeleteModal(false);
            setSelectAll(false);
            return setRowData(null);
          }

          if (selectedCustomers.length > 0) {
            for (let i = 0; i < selectedCustomers.length; i++) {
              handleDeleteUsers(selectedCustomers[i], selectedCustomers.length, i);
            }
          }
          // else {
          //   setDeleteModal(false);
          //   setRowData(null);
          //   setSelectedCustomers([]);
          //   setSelectAll(false);
          // }
        }}
        onCloseClick={() => {
          setDeleteModal(false);
          setRowData(null);
        }}
      />
      <ArchiveModal
        show={archiveModal}
        message="you want to archieve these customers?"
        deleteLabel="Yes"
        closeLabel="No"
        onDeleteClick={() => {
          archiveHandler();
        }}
        onCloseClick={() => {
          setSelectedCustomers([]);
          setSelectAll(false);
          setArchiveModal(false);
        }}
      />

      {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


      {filter && (
        <div className="card">
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onReset={handleReset}
              enableReinitialize
            >
              {({ values, handleChange }) => {
                return (
                  <Form onChange={handleChange}>
                    <Row>
                      <Col xs={12} lg={7} id="export-search-clear">

                      </Col>
                      <Col xs={12} lg={5} id="export-search-clear" className="d-flex">
                        <Select
                          label=""
                          name="status"
                          onBlur={handleStatusBlur}
                          value={values.status}
                          onChange={handleChange}
                          options={options}
                          invalid={false}
                        />
                        <Button className="btn btn-primary ms-2" color="primary" type="reset">
                          Clear </Button>
                        <Button className="btn btn-primary ms-2" color="primary" type="submit">
                          Search </Button>
                      </Col>


                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}

      <DataTableWrapper
        // style={{ width: visibleRight ? "80%" : "100%" }}
        ref={dt}
        columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
        header={header}
        value={customerData}
        lazy
        defaultSortOrder={1}
        sortIcon={(setOrder) => <SortIcon order={setOrder} />}
        filterIcon={FilterIcon}
        paginator={true}
        stripedRows={true}
        rowHover={true}
        // filterDisplay="row"
        rowsPerPageOptions={[5, 10, 25, 50]}
        totalRecords={totalRecords}
        // loading={loading}
        emptyMessage="No customers found."
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Displaying Customers  {last} records of {totalRecords} in total"
        selection={selectedCustomers}
        onSelectionChange={onSelectionChange}
        selectAll={selectAll}
        onSelectAllChange={onSelectAllChange}
        first={lazyState.first}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        onFilter={onFilter}
        filters={lazyState.filters}
        onPage={onPage}
        rows={lazyState.rows}
      ></DataTableWrapper>
    </div >
    </>
  );
}
