import { ContactDetail, DealerCreateModel, DealerDetails } from './../../models/pages/dealerModel';
import { DealersModal } from "src/models/pages/dealerModel";
import { DealerActionTypes } from "./actionTypes";
import { type } from 'os';

// get Dealers
export const getDealers = () => {
  return { type: DealerActionTypes.GET_DEALERS };
};

export const getDealersSuccess = (dealers: DealersModal[]) => {
  return { type: DealerActionTypes.GET_DEALERS_SUCCESS, payload: dealers };
};

export const getDealersError = (error: any) => {
  return { type: DealerActionTypes.GET_DEALERS_FAIL, payload: error };
};

// get Dealer
export const getDealerDetail = (id: string) => {
  return { type: DealerActionTypes.GET_DEALER_DETAIL, payload: id };
};

export const getDealerDetailSuccess = (dealer: DealerCreateModel) => {
  return { type: DealerActionTypes.GET_DEALER_DETAIL_SUCCESS, payload: dealer };
};

export const getDealerContactSuccess = (contactDetail: ContactDetail[]) => {
  return { type: DealerActionTypes.GET_DEALER_CONTACT_SUCCESS, payload: contactDetail };
};

export const getDealerDetailError = (error: any) => {
  return { type: DealerActionTypes.GET_DEALER_DETAIL_FAIL, payload: error };
};


// update Dealer
export const updateDealer = (dealer: DealerCreateModel, dealerId: string = "") => {
  return { type: DealerActionTypes.UPDATE_DEALER_DETAIL, payload: { dealer, dealerId } };
};

export const updateDealerSuccess = (dealer: DealersModal) => {
  return { type: DealerActionTypes.UPDATE_DEALER_DETAIL_SUCCESS, payload: dealer };
};

export const updateDealerDetailError = (error: any) => {
  return { type: DealerActionTypes.UPDATE_DEALER_DETAIL_FAIL, payload: error };
};


// post Dealer
export const addDealer = (dealer: DealerCreateModel) => {
  return { type: DealerActionTypes.ADD_DEALER_DETAIL, payload: dealer };
};

export const addDealerSuccess = (dealers: any) => {
  return { type: DealerActionTypes.ADD_DEALER_DETAIL_SUCCESS, payload: dealers };
};

export const addDealerDetailError = (error: any) => {
  return { type: DealerActionTypes.ADD_DEALER_DETAIL_FAIL, payload: error };
};



// For Grid

export const getDealerList = (lazyState: any) => {
  return {
    type: DealerActionTypes.GET_DEALER_LIST,
    lazyState,
  };
};



export const getDealerListSuccess = (dealers: any) => {
  return {
    type: DealerActionTypes.GET_DEALER_LIST_SUCCESS,
    payload: dealers,
  };
};

export const getDealerListError = (error: any) => {
  return {
    type: DealerActionTypes.GET_DEALER_LIST_FAIL,
    payload: error,
  };
};


export const deleteDealerFromList = (payload:any) => {
  return {
    type: DealerActionTypes.DELETE_DEALER,
    payload: payload,
  }
}

export const deleteDealerFromListSuccess = (response:any,undo:boolean) => {

  return {
    type: DealerActionTypes.DELETE_DEALER_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteDealerFromListError = (error:any) => {
  return {
    type: DealerActionTypes.DELETE_DEALER_FAIL,
    payload: error,
  }
}

export const clearDealerData = ()=>{
  return {
    type: DealerActionTypes.CLEAR_DEALER_DATA,
    
  }
}


export const clearDealerMessage = ()=>{
  return{
    type: DealerActionTypes.CLAER_DEALER_MESSAGE,
  }
}


export const deleteDealerContact = (payload:any) => {
  return {
    type: DealerActionTypes.DELETE_DEALER_CONTACT_LOADING,
    payload: payload,
  }
}

export const deleteDealerContactSuccess = (response:any) => {
  return {
    type: DealerActionTypes.DELETE_DEALER_CONTACT_SUCCESS,
    payload: response,
  }
}

export const deleteDealerContactError = (error:any) => {
  return {
    type: DealerActionTypes.DELETE_DEALER_CONTACT_FAIL,
    payload: error,
  }
}



// Dealer Tax Type

export const dealerTaxTypeList=()=>{
  return {
    type : DealerActionTypes.DEALER_TAX_TYPE_LIST_LOADING,
  }
}

export const dealerTaxTypeListSuccess=(taxList : any)=>{
  return {
    type : DealerActionTypes.DEALER_TAX_TYPE_LIST_SUCCESS,
    payload : taxList
  }
}

export const dealerTaxTypeListError=(error : any)=>{
  return {
    type : DealerActionTypes.DEALER_TAX_TYPE_LIST_FAIL,
    payload : error
  }
}

	
// get DEALER image

export const getDealerImageLoading =(customerId : any)=>{
  return {
    type: DealerActionTypes.DEALER_FILE_FETCH_LOADING,
    payload: customerId,
  };
}

export const getDealerImageSuccess =(customerDetails : any)=>{
  return {
    type: DealerActionTypes.DEALER_FILE_FETCH_SUCCESS,
    payload: customerDetails,
  }
}

export const getDealerImageError =(error : any)=>{
  return {
    type : DealerActionTypes.DEALER_FILE_UPLOAD_ERROR,
    payload : error
  }
}


// delete customer image

export const deleteDealerImageLoading = (customerId : any)=>{
  return {
    type: DealerActionTypes.DEALER_FILE_DELETE_LOADING,
    payload: customerId,
  };
}

export const deleteDealerImageSuccess = (customerDetails : any)=>{
  return {
    type: DealerActionTypes.DEALER_FILE_DELETE_SUCCESS,
    payload: customerDetails,
  }
}

export const deleteDealerImageError = (error : any)=>{
  return {
    type : DealerActionTypes.DEALER_FILE_DELETE_ERROR,
    payload : error
  }
}



//  upload the file
export const uploadDealerFileLoading = (file :any,id : any )=>{
  return {
    type : DealerActionTypes.DEALER_FILE_UPLOAD_LOADING,
    payload : file,
    id: id
  }
}


export const uploadDealerFileSuccess=(customer : any)=>{
  return {
    type : DealerActionTypes.DEALER_FILE_UPLOAD_SUCCESS,
  }
}

export const uploadDealerFileError=(error : any)=>{
  return {
    type : DealerActionTypes.DEALER_FILE_UPLOAD_ERROR,
    payload : error
  }
}

export const ClearDealerImage=()=>{
	return {
	  type : DealerActionTypes.DEALER_FILE_CLEAR,
	}
  }

  export const setDealerMobileNumberLoading = (number:any,id: any) => {
    return {
      type: DealerActionTypes.DEALER_SET_PHONE_LOADING,
      payload: {id:id,number:number},
    };
  }
  export const setDealerMobileNumberSuccess = (resp: any) => {
    return {
      type: DealerActionTypes.DEALER_SET_PHONE_SUCCESS,
      payload: resp,
    };
  }
  export const setDealerMobileNumberError = (error: any) => {
    return {
      type: DealerActionTypes.DEALER_SET_PHONE_ERROR,
      payload: error,
    };
  }
  
  export const setDealerEmailIdLoading = (email:any,id: any) => {
    return {
      type: DealerActionTypes.DEALER_SET_EMAIL_LOADING,
      payload: {id:id,email:email},
    };
  }
  export const setDealerEmailIdSuccess = (resp: any) => {
    return {
      type: DealerActionTypes.DEALER_SET_EMAIL_SUCCESS,
      payload: resp,
    };
  }
  export const setDealerEmailIdError = (error: any) => {
    return {
      type: DealerActionTypes.DEALER_SET_EMAIL_ERROR,
      payload: error,
    };
  }

  export const sendDealerOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
    console.log(id);
    
    return {
      type: DealerActionTypes.SEND_OTP_DEALER,
      payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
    };
  }
  export const sendDealerOtpSuccess = (resp: any) => {
    return {
      type: DealerActionTypes.SEND_OTP_DEALER_SUCCESS,
      payload: resp,
    };
  }
  export const sendDealerOtpError = (error: any) => {
    return {
      type: DealerActionTypes.SEND_OTP_DEALER_ERROR,
      payload: error,
    };
  }
  export const resendOtpDealer = (id: any,number:any) => {
    return {
      type: DealerActionTypes.RESEND_DEALER_OTP,
      payload: id,
      number:number
    };
  }
  export const resendOtpDealerSuccess = (resp: any) => {
    return {
      type: DealerActionTypes.RESEND_DEALER_OTP_SUCCESS,
      payload: resp,
    };
  }
  export const resendOtpDealerError = (resp: any) => {
    return {
      type: DealerActionTypes.RESEND_DEALER_OTP_ERROR,
      payload: resp,
    };
  }

  export const resetOtpDealerMessage = () => {
    return {
      type: DealerActionTypes.RESET_DEALER_OTP_MESSAGE,
    };
  }

  export const dealerLatLagLoading = (selectedArea: string) => {
    return {
      type: DealerActionTypes.GET_DEALER_LOCATION_LOADING,
      payload: selectedArea
    }
  }
  export const dealerLatLagSuccess = (latLag: any, selectedArea: any) => {
    return {
      type: DealerActionTypes.GET_DEALER_LOCATION_SUCCESS,
      payload: { latLag, selectedArea }
    }
  }
  export const dealerLatLagError = (error: any) => {
    return {
      type: DealerActionTypes.GET_DEALER_LOCATION_ERROR,
      payload: error
    }
  }