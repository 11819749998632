import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AudioModelsTypes } from "./actionTypes";
import { API } from "src/util/api";
import { createAudioModelError, createAudioModelSuccess, deleteAudioModelsFromListError, deleteAudioModelsFromListSuccess, getAudioBrandDropDownListError, getAudioBrandDropDownListSuccess, getAudioModelAutoCompleteError, getAudioModelAutoCompleteSuccess, getAudioModelsListError, getAudioModelsListSuccess, getByIdAudioModelError, getByIdAudioModelSuccess, updateAudioModelError, updateAudioModelSuccess } from "./action";
import { audioModelErrorState } from "src/models/pages/audioModelsModel";


//getAudioModel
function* getAudioModelsListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getAudioModelsListAPI, { ...action.lazyState })
    yield put(getAudioModelsListSuccess(response));
  } catch (error) {
    yield put(getAudioModelsListError(error));
  }
}

//delete AudioModel
function* deleteAudioModelsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteAudioModelsListAPI, { id: action.payload.id, undo:action.payload.undo })
    yield put(deleteAudioModelsFromListSuccess(response,action.payload.undo))
  } catch (error) {
    yield put(deleteAudioModelsFromListError(error));
  }
}

//create audioModel
function* createAudioModelAsync(action: any) {

  try {
    const response: Promise<any> = yield call(API.createAudioModelApi, action.payload);
    yield put(createAudioModelSuccess(response))
  } catch (error: any) {
    let errorState: audioModelErrorState = error
    yield put(createAudioModelError(errorState))
  }
}


//update audioModel;
function* updateAudioModelAsync(action: any) {
  
  try {
    const response: Promise<any> = yield call(API.updateAudioModelApi, action.payload, action.id);
    yield put(updateAudioModelSuccess(response))
  } catch (error) {
    yield put(updateAudioModelError(error))
  }
}

//get dropdown getAudioBrandAsync
function* getAudioBrandAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.getAudioBrandFromMasterManagement);
    yield put(getAudioBrandDropDownListSuccess(response));
  } catch (error) {
    yield put(getAudioBrandDropDownListError(error));
  }
}

//get by id Audio model
function* getAudioModelByIdAsync(action:any){
  try {
    const response : Promise<any> = yield call(API.getAudioModelByIdApi,action.payload);
    yield put(getByIdAudioModelSuccess(response));
  } catch (error) {
    yield put(getByIdAudioModelError(error));
  }
}
function* getAudioModelAutocompleteListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAudioModelAutoCompleteAPI, action.params
    )
    yield put(getAudioModelAutoCompleteSuccess(response));
  } catch (error) {
    yield put(getAudioModelAutoCompleteError(error));
  }
}
function* audioModelsSaga() {
  yield takeLatest(AudioModelsTypes.GET_AUDIO_MODELS_LIST_LOADING, getAudioModelsListAsync);
  yield takeLatest(AudioModelsTypes.DELETE_AUDIO_MODELS_LOADING, deleteAudioModelsAsync);
  yield takeLatest(AudioModelsTypes.CREATE_AUDIO_MODELS_PENDING, createAudioModelAsync);
  yield takeLatest(AudioModelsTypes.UPDATE_AUDIO_MODELS_PENDING, updateAudioModelAsync);
  yield takeLatest(AudioModelsTypes.GET_AUDIO_BRAND_NAMES_LOADING,getAudioBrandAsync);
  yield takeLatest(AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_PENDING,getAudioModelByIdAsync)
  yield takeLatest(AudioModelsTypes.GET_AUDIO_MODEL_AUTOCOMPLETE,getAudioModelAutocompleteListAsync)
}

export default audioModelsSaga;