import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';

import { ROUTER } from 'src/constants/routes';
import { formatDateMEL } from 'src/helpers/common_helpers';
import { clearOutgoingDispatch, downloadOutgoingDispatch, getDeliveriedBYID, resetOutgoingDispatchMessage, sentMailToOutgoingDispatch, viewOutgoingDispatchLoading } from 'src/store/outGoingDispatch/action';

import { RootReducerState } from 'src/store/reducers';
import { useToast } from "src/components/Common/ReactToaster";
import { Link } from 'react-router-dom';
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText';

const ViewOutgoingDispatch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, outGoingDispatchDetails, error, message,DeliveriedById } = useSelector((state: RootReducerState) => state.outGoingDispatchReducer)

  

  const { showToast } = useToast();


  useEffect(() => {
    if (id) {
      dispatch(viewOutgoingDispatchLoading(id));
    }

    return () => {
      dispatch(clearOutgoingDispatch())
    }

  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }

  }, [loading]);

  useEffect(()=>{
    if(DeliveriedById){
      if(DeliveriedById.userType=="Employee"){
        navigate(`${ROUTER.USERS_VIEW}/${DeliveriedById.melEmployeeId}`)
      }
      if(DeliveriedById.userType=="Franchise"){
        navigate(`${ROUTER.FRANCHISEE_VIEW}/${DeliveriedById.franchiseId}`)
      }
      if(DeliveriedById.userType=="Dealer"){
        navigate(`${ROUTER.DEALERS_VIEW}/${DeliveriedById.dealerId}`)
      }
      if(DeliveriedById.userType=="Customer"){
        navigate(`${ROUTER.USERS_VIEW}/${DeliveriedById.customerId}`)
      }
    }
      },[DeliveriedById])

  const userView = {
    generalDetails: [
      {
        name: "Number",
        value: outGoingDispatchDetails?.number
      },
      {
        name: outGoingDispatchDetails?.jobId ? "Job Sheet" : "Intend",
        value:(outGoingDispatchDetails?.jobId ? <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${outGoingDispatchDetails?.jobId}`}>{outGoingDispatchDetails?.jobNumber ? ("Job Sheet: "+ outGoingDispatchDetails?.jobNumber ): "-"}</Link>:
        <Link to={`${ROUTER.INTENT_VIEW}/${outGoingDispatchDetails?.intentId}`}>{"View Intend"}</Link>)
      },
      {
        name: "Notes",
        value: outGoingDispatchDetails?.notes ? <HtmltoPlainText htmlString={outGoingDispatchDetails?.notes} /> : ""
      },
      {
        name: "Packed By",
        value: outGoingDispatchDetails?.packedBy
      },
      {
        name: "Awb Number",
        value: outGoingDispatchDetails?.awbNumber
      },
      {
        name: "Packed Date",
        value: formatDateMEL(outGoingDispatchDetails?.packedDate)
      },
      {
        name: "Delivery Date",
        value: formatDateMEL(outGoingDispatchDetails?.deliveryDate)
      },
      {
        name: "Delivery Type",
        value: outGoingDispatchDetails?.deliveryType
      },
      {
        name: "Delivered To",
        value: outGoingDispatchDetails?.deliveredTo
      },
      {
        name: "Delivered By",
        value: <Link to={''} onClick={()=>{dispatch(getDeliveriedBYID(outGoingDispatchDetails.deliveredById))}}>{outGoingDispatchDetails?.deliveredByName}</Link>
      },
      {
        name: "Approximate Value",
        value: outGoingDispatchDetails?.approximateValue
      },
      {
        name: "Courier Name",
        // value: outGoingDispatchDetails?.courierName ? <Link to={`${ROUTER.VIEW_COURIER}/${outGoingDispatchDetails?.courierId}`}>{outGoingDispatchDetails?.courierName}</Link> : "-"
        value: outGoingDispatchDetails?.courierName ? `${outGoingDispatchDetails?.courierName} ${outGoingDispatchDetails?.consigneeName ? `: ${outGoingDispatchDetails?.consigneeName}` : ''} ${outGoingDispatchDetails?.awbNumber ? `(${outGoingDispatchDetails?.awbNumber})` : ''}` : "-"
      },
    ]
  }

  
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetOutgoingDispatchMessage());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetOutgoingDispatchMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const emailOutgoingDispatchHandler  = ()=>{
    dispatch(sentMailToOutgoingDispatch(id))
  }
  const printOutgoingDispatchHandler = ()=>{
    dispatch(downloadOutgoingDispatch(id))
  }

  const isHandCarry = (dealerId: any, intentNumber: any) => {
    if ((dealerId && dealerId != 0)) {
      return true;
    } else if (intentNumber && intentNumber != 0) {
      return true;
    }
    else {
      return false;
    }
  };


  return (
    <React.Fragment>
      <div className='userpage'>
      {visible && dialogContainer(error)}
        <Container fluid>
          <BreadcrumbWithFilter
            title="Outgoing Dispatch" titlePath={ROUTER.OUTCOMING_DISPATCH} breadcrumbItem='Outgoing Dispatch Details'
            isShowCreate={false}
            isShowFilter={false}
            
            isShowFirstButton={isHandCarry(outGoingDispatchDetails?.dealerId,outGoingDispatchDetails?.intentId)}
            firstButtonLabel="Email outgoing Dispatch"
            onFirstButtonClick={emailOutgoingDispatchHandler}


            isShowSecondButton={isHandCarry(outGoingDispatchDetails?.dealerId,outGoingDispatchDetails?.intentId)}
            secondButtonLabel='Print outgoing Dispatch'
            onSecondButtonClick={printOutgoingDispatchHandler}
            secondButtonDisabled={false}


            filter={false}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Outgoing Dispatch Details</b>
              </div>
              <div className="subview">
              <div className="parentCls">
                  {
                    userView.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div className='mb-2'>{data.name}</div>
                          <b>{data.value ? data.value : <div className='view-none-text'>-</div>}</b>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewOutgoingDispatch