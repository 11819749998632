import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import FeatherIcon from "feather-icons-react";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { ClearEmployeeImage, ReserEmployeeDetails, deleteUserFromList, getUsersList } from "src/store/users/action";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { UserListDetails, users } from "src/models/pages/userModel";
import { useNavigate } from "react-router-dom";
import Select from "src/components/Common/Select";
import DeleteModal from "../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import { Row, Col } from "reactstrap";
import ArchiveModal from "../Calendar/DeleteModal";
import { formatDateMEL, formatDateTOYYYYMMDD, onlyAdminRoleHandler, saveAsExcelFile, } from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import TooltipMEL from "src/components/UI/Tooltip";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { getProfile } from "src/store/profile/actions";
import { RootReducerState } from "src/store/reducers";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";

const employeesListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['Name'] = eachValue?.user?.name;
    data['Email'] = eachValue?.user?.email;
    data['PhoneNumber'] = eachValue?.user?.phoneNumber;
    data['alternateNumber'] = eachValue?.alternateContactMobileNo;
    data['address'] = eachValue?.user?.extraProperties?.Address;
    data['phoneNumberConfirmed']=eachValue?.user?.phoneNumberConfirmed;
    data['fatherName'] = eachValue?.fatherName;
    data['joiningDate'] = eachValue?.joiningDate;
    data['bloodGroup'] = eachValue?.bloodGroup;
    data['sendSms'] = eachValue?.user?.extraProperties?.SendSms;
    data['sendWhatsapp'] = eachValue?.user?.extraProperties?.SendWhatsapp;
    data['sendEmail'] = eachValue?.user?.extraProperties?.SendEmail;
    data['inTime']=eachValue?.inTime;
    data['isActive']=eachValue?.user?.isActive;
    data['outTime']=eachValue?.outTime;
    data['isDeleted'] = eachValue?.isDeleted
    return data;
  });
  return modifeidValue;
};

export default function UserList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector(
    (state: any) => state.userReducer.userList
  );
  const { error, message, paginatorCount } = useSelector(
    (state: any) => state.userReducer
  );
  const { profile} = useSelector((state: RootReducerState) => state.profilesReducer);

  const modifiedValues: UserListDetails[] = employeesListConverson(values);

  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.userReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)
  useEffect(()=>{
dispatch(ClearEmployeeImage())
  },[])
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [isBtnShow, setIsBtnShow] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<users | null>(null);
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    PhoneNumber: { value: "", matchMode: "contains" },
    Email: { value: "", matchMode: "contains" },
    IsVerfied: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Name",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
    createdAt: null,
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.USERS_CREATE);
  };

  const viewHandler = (rowData: users) => {
    navigate(`${ROUTER.USERS_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: users) => {
    navigate(`${ROUTER.USERS_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: users) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const statusBodyTemplate = (rowData: UserListDetails, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );
  };

  const dateBodyTemplate = (rowData: UserListDetails, field: string) => {
    let date = formatDateTOYYYYMMDD(rowData[field]);
    return (
      <>{date}</>
    );

  }
  const emailBodyTemplate = (rowData: users, field: string) => {
    let data = rowData[field] as string;
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const actionBodyTemplate = (rowData: users, field: string) => {

    return (
      <>
        {
          rowData.isDeleted == false ? (
            <ColumnAction
              displayData={rowData[field] as string}
              isEdit={true}
              isDelete={true}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }

      </>
    );
  };

  const phoneVerifiedTemplate =(rowData:any) => {
    return(
      <div className="d-flex gap-1 align-items-center">
      <b>{rowData.PhoneNumber}</b>
      {rowData.phoneNumberConfirmed ? <FeatherIcon icon="check-circle" className={`icon-lg right-icon`} style={{ color:"green",width:"20px"}}/>:null}
      </div>
    )
  }

  const formatTimeTemplate = (time:any)=>{
    const dateTime =new Date(`1970-01-01T${time}`)
    const formattedTime = dateTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
  });
  return formattedTime
  }

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: ACTIVE, value: ACTIVE },
    { label: DELETED, value: DELETED },
    { label: EMAIL_VERIFIED, value: EMAIL_VERIFIED },
    // {
    //   label: "Locked",
    //   value: "Locked",
    // },

  ];

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      frozen : true,
      isStatic: false,
    },
    {
      field: "Name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "Name"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "Email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => emailBodyTemplate(rowData, "Email"),
      isDefault: true,
      isStatic: false,

    },
    {
      field: "PhoneNumber",
      sortable: true,
      header: "Mobile",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>phoneVerifiedTemplate(rowData),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "alternateNumber",
      sortable: false,
      header: "Alternate Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },

    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: true,
      showFilterMenu: false,
      className: "addressstyle",
      headerStyle: { width: "300px", textOverflow: "ellipsis", overflowX: "hidden" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "fatherName",
      sortable: false,
      header: "Father Name",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "joiningDate",
      sortable: false,
      header: "Joining Date",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      body: (rowData) => formatDateMEL(rowData.joiningDate)
    },
    {
      field: "bloodGroup",
      sortable: false,
      header: "Blood Group",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
    },
    {
      field: "sendSms",
      sortable: false,
      header: "Send SMS",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendSms'),
    },
    {
      field: "sendEmail",
      sortable: false,
      header: "Send Email",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendEmail'),
    },
    {
      field: "sendWhatsapp",
      sortable: false,
      header: "Send Whatsapp",
      filter: true,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendWhatsapp'),
    },
    {
      field: "inTime",
      sortable: false,
      header: "Shiftin Time",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      body: (rowData) => formatTimeTemplate(rowData.inTime),
    },
    {
      field: "outTime",
      sortable: false,
      header: "Shiftout Time",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      body: (rowData) => formatTimeTemplate(rowData.outTime),
    },

  ];


  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((a) => {
      const { id: Id, Name, Email, PhoneNumber: Mobile, address: Address, fatherName, joiningDate, bloodGroup, sendSms, sendEmail,sendWhatsapp,inTime,outTime,isActive,phoneNumberConfirmed,alternateNumber } = a
      let data: any = {}
      data['Alternate Number'] = alternateNumber
      data['Father Name'] = fatherName
      data['Joining Date'] = formatDateMEL(joiningDate)
      data['Blood Group'] = bloodGroup
      data['Send SMS'] = sendSms
      data['Send Email'] = sendEmail
      data['Send Whatsapp'] = sendWhatsapp
      data['Is Active'] = isActive
      data['Mobile Number Verified']=phoneNumberConfirmed;
      data['Shiftin Time'] = formatTimeTemplate(inTime)
      data['Shiftout Time'] = formatTimeTemplate(outTime)
      return { ...{  Name, Email, Mobile, Address }, ...data };
    })
    return modifiedColumnDetails
  }

  const saveDataAsExcel = (values: any[], fileName: string) => {
    let modifiedColumnDetails = changeColumnDetails(values)

    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    debugger
    // let modifiedColumnDetails = changeColumnDetails(values)
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        Name: data.Name,
        Email: data.Email,
        PhoneNumber: data?.PhoneNumber,
        alternateNumber: data?.alternateNumber,
        fatherName: data?.fatherName,
        phoneNumberConfirmed:data?.phoneNumberConfirmed,
        joiningDate: formatDateMEL(data?.joiningDate),
        bloodGroup: data?.bloodGroup,
        sendSms: data?.sendSms,
        sendEmail: data?.sendEmail,
        sendWhatsapp: data?.sendWhatsapp,
        isActive: data?.isActive,
        address: data?.address,
        inTime: formatTimeTemplate(data?.inTime),
        outTime: formatTimeTemplate(data?.outTime)
      }
      return datas
    })
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },
        }));
        let columnsWithVerified=[...exportColumns,{
          title:"Mobile Number Verified",
          dataKey:"phoneNumberConfirmed",
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },

        }]
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        doc.autoTable(columnsWithVerified, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getUsersLists(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let resp=await employeesListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(resp, columns, "Employees");
     }else{
      saveDataAsExcel(resp, "Employees");
     }
     setPdfLoading(false)
    } catch (error : any) {
      showToast(error?.message,{type : "error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Employees");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Employees");
    fetchData(true)
  };

useEffect(()=>{
dispatch(getProfile())
},[])

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address"),
    mobileNo: Yup.string().transform((originalValue, originalObject) => {
      // Replace non-numeric characters with an empty string
      const transformedValue = originalValue
        ? originalValue.replace(/[^\d]/g, "")
        : originalValue;
      return transformedValue;
    }),
  });

  const callbackFunction = (lazyState: LazyTableState) => {
    setSelectAll(false)
    setSelectedUsers([])
    dispatch(getUsersList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;

    setSelectedUsers(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedUsers(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedUsers([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedUsers([]);
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };


  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true)
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(ReserEmployeeDetails())
      dispatch(getUsersList(lazyState))
    }
  }, [error, message])

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }else if (formValue == "All") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == EMAIL_VERIFIED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: "true", matchMode: "contains" },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        },
        createdAt: {
          ...state.filters.createdAt,
          value: "",
        },
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false)
    setSelectedUsers([])
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: any, length = 0, index = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
      lazyState,
    };
    dispatch(deleteUserFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || length === index) {
      setTimeout(() => {
        dispatch(getUsersList(lazyState));
      }, 500);
      setSelectAll(false);
      setSelectedUsers([]);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedUsers([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedUsers(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedUsers([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const header =
    <div className="table-header-container">
      <div>
        Employees List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Employees"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedUsers}
        bulkActionSatusTitle="Employee"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedUsers.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedUsers.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
                  <FeatherIcon icon="archive" className="mr-1" />
                  Archive
                </Button>
              </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                  {/* <FeatherIcon icon="trash-2" className="mr-1" />
                  {selectedStatus == DELETED ? "Restore" : "Delete"} */}
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space  mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>

              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedUsers.length > 0 && <BulkActionStatus count={selectedUsers.length} title="Employees"></BulkActionStatus>}
              </motion.div>

            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
      <div style={{ display: "flex" }}>
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} showClear={false} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button>
        </div>
        {(profile?.extraProperties?.IsAdmin==true || onlyAdminRoleHandler()) && 
        <div className="reponsive_position">
        {/* <TooltipMEL title="Add Employee" position="bottom"> */}
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Employee
        </Button>
        </div>
}
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName='tooltip-color'
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;
  // ).filter((eachColumn: Columns) => {
  //   return eachColumn['field'] != 'select';
  // })}
  //   options={columns.map((eachColumns: Columns) => {
  //     let data: any = {};
  //     data['field'] = eachColumns['field'];
  //     data['header'] = eachColumns['header'];
  //     return data;
  //   }).filter((eachColumn: Columns) => {
  //     return eachColumn['field'] != 'select';
  //   })} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />;

  // const bulkActionClickHandler = () => {
  //   setSelectAll(true);
  //   setSelectedUsers(values);
  //   setIsBtnShow(true);
  // };
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const hidePopup = ()=>{
    setVisible(false); 
    dispatch(ReserEmployeeDetails());
  }


  const dialogContainer = (error: any) => {
    return (
      <>
         <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(ReserEmployeeDetails()) }}>
          <div className="m-0 validation-text">
            {error && error.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>)
            })}
          </div>
        </Dialog> */}
      </>
    )
  }

  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
         
            if (selectedUsers?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedUsers([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }
            if (rowData || selectedUsers.length === 1) {
              handleDeleteUsers(rowData || selectedUsers);
            } else if (selectedUsers.length > 1) {
              for (let i = 0; i < selectedUsers.length; i++) {
                handleDeleteUsers(selectedUsers[i], selectedUsers.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these users?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedUsers([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}
        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          // loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Employees  {last} records of {totalRecords} in total"
          selection={selectedUsers}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
