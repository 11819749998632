import { StockValuebyPartTypes } from "./actionTypes";



// For Grid

export const getStockValuebyPartsList = (lazyState: any) => {
  return {
    type: StockValuebyPartTypes.GET_STOCK_VALUE_BY_PART_LIST,
    lazyState,
  };
};



export const getStockValuebyPartListSuccess = (stockValuebyPart: any) => {
  return {
    type: StockValuebyPartTypes.GET_STOCK_VALUE_BY_PART_LIST_SUCCESS,
    payload: stockValuebyPart,
  };
};

export const getStockValuebyPartListError = (error: any) => {
  return {
    type: StockValuebyPartTypes.GET_STOCK_VALUE_BY_PART_LIST_FAIL,
    payload: error,
  };
};

export const resetStockValuebyPartMessage = ()=>{
    return { type: StockValuebyPartTypes.RESET_STOCK_VALUE_BY_PART_LIST};
  }

  export const getPartsDropdownList = () => {
    return {
      type: StockValuebyPartTypes.GET_STOCK_PART_LIST_LOADING,
    };
  };
  
  export const getPartsDropdownListSuccess = (resp: any) => {
    return {
      type: StockValuebyPartTypes.GET_STOCK_PART_LIST_SUCCESS,
      payload: resp,
    };
  };
  
  export const getPartsDropdownListError = (error: any) => {
    return {
      type: StockValuebyPartTypes.GET_STOCK_PART_LIST_ERROR,
      payload: error,
    };
  };