import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import { ROUTER } from 'src/constants/routes';
import { clearFranchiseSubuserMessage, createSubuserLoading, deleteFranchiseSubuserProfileLoading, franchiseSubuserLatLagLoading, getFranchiseSubuserProfileLoading, getSubuserByIdLoading, resendOtpFranchiseSubuser, resetOtpFranchiseSubuserMessage, resetSubuser, sendFranchiseSubuserOtpLoading, setFranchiseSubuserEmailIdLoading, setFranchiseSubuserMobileNumberLoading, updateSubuserLoading, uploadFranchiseSubuserProfileLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";
import { getLocationLatLng, hasAdminOrEmployeeRoleHandler, onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import Dropzone from 'react-dropzone';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import DeleteModal from '../Calendar/DeleteModal';
import FeatherIcon from "feather-icons-react";
import OtpScreen from '../Customers/OtpScreen';
import { Dialog } from 'primereact/dialog';
import { APPENV_URL } from 'src/util/appUrlEnv';

const AddEditSubUser = () => {
  const { error, loading, message, createMessage, createError, franchiseSubuserImage, mailMessage, phoneMessage, editedMobileNumber, createdData, otpmessage, updatedmessage,subuserLatLng } = useSelector((state: RootReducerState) => state.subUserReducer);
  const { subUserDetails } = useSelector((state: RootReducerState) => state.subUserReducer.subUserList);
  const [visible, setVisible] = useState(false);
  const [createvisible, setCreateVisible] = useState(false);
  const [editedValue, setEditedValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [customError, setcustomError] = useState(false);
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");
  const [apiKey, setApiKey] = useState<string | null>(null);

  const dispatch = useDispatch();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { id, sub_id }: any = useParams();
  const state = useLocation();
  const location = useLocation();
  const isOnCreatePage = location.pathname === '/franchise_subuser/create';
  let currentUser: string[];
  const roleString = localStorage.getItem("role");
  if (roleString == "admin") {
    const myArray = roleString.split(' ');
    currentUser = myArray;
  } else {
    currentUser = roleString ? JSON.parse(roleString) : null;
  }

  const url = process.env.REACT_APP_API_CONFIGURATION + "/api/abp/application-configuration";
  const token = localStorage.getItem("token");
  // const hasAdminOrEmployeeRole = useFetchDataAndCheckRoles(url, token);
  const hasAdminOrEmployeeRole = hasAdminOrEmployeeRoleHandler();

  const convertNumber = (phone: any) => {
    let data = parseInt(phone);
    return data;
  };

  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  useEffect(() => {
    const storedFormValues = localStorage.getItem('formvalues');
    if (storedFormValues) {
      try {
        const parsedFormValues = JSON.parse(storedFormValues);
        // Set the initial values with the retrieved form values
        setInitialValues(parsedFormValues);
      } catch (error) {
        console.error('Error parsing localStorage value:', error);
      }
    }
    if (!id) {
      getLocationLatLng(setLatitude, setLongitude);
    }
  }, []);


  const handleReset = (resetForm: any) => {
    resetForm();
    if (!hasAdminOrEmployeeRole && id) {
      navigate(ROUTER.SUB_USER_GRID);
    }
    if (hasAdminOrEmployeeRole && id) {
      navigate(ROUTER.FRANCHISEE_GRID);
    }
  };


  //new work
  const initialvalue: any = {
    name: subUserDetails?.name ? subUserDetails?.name : "",
    userName: subUserDetails?.userName ? subUserDetails?.userName : "",
    email: subUserDetails?.email ? subUserDetails?.email : "",
    phoneNumber: convertNumber(subUserDetails?.phoneNumber) ? subUserDetails?.phoneNumber : "",
    alternateNumber: convertNumber(subUserDetails?.extraProperties?.AlternateNumber) ? subUserDetails?.extraProperties?.AlternateNumber : "",
    sendSms: subUserDetails?.extraProperties?.SendSms ? subUserDetails?.extraProperties?.SendSms : false,
    sendEmail: subUserDetails?.extraProperties?.SendEmail ? subUserDetails?.extraProperties?.SendEmail : false,
    sendWhatsapp: subUserDetails?.extraProperties?.SendWhatsapp ? subUserDetails?.extraProperties?.SendWhatsapp : false,
    address: subUserDetails?.extraProperties?.Address ? subUserDetails?.extraProperties?.Address : "",
    isActive: subUserDetails?.isActive ? subUserDetails?.isActive : false,
    isVerfied: subUserDetails?.extraProperties?.IsVerfied ? subUserDetails?.extraProperties?.IsVerfied : false,
    lockoutEnabled: subUserDetails?.lockoutEnabled ? subUserDetails?.lockoutEnabled : false,
    panCardNo : subUserDetails?.extraProperties?.PANCardNo ? subUserDetails?.extraProperties?.PANCardNo : "",
  };
  useEffect(() => {
    if (subUserDetails?.extraProperties?.Address) {
      dispatch(franchiseSubuserLatLagLoading(subUserDetails?.extraProperties?.Address))
    }
  }, [subUserDetails]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User Name is required`),
    email: Yup.string()
    .required('Email is required')
    .email('Enter a valid email')
    .test('valid-email', 'Enter a valid email', function (value) {
      if (!value) return true;
      return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
    }),
    
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    address: Yup.string().required(`Address is required`),
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'PAN number must be valid'),
  });
  const editValidataionSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User Name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; 
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    address: Yup.string().required(`Address is required`),
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'PAN number must be valid'),
  });


  const submitFunction = (values: any) => {

    if (isSubmitting) return;
    values.phoneNumber = String(values.phoneNumber);
    values.alternateNumber = String(values.alternateNumber);
    const { confirmpassword, ...states } = values;
    const formateddata = {
      user: {
        ...states,
        userId: sub_id,        
      }
    };
    if (id) {
      const { phoneNumber, ...rest } = formateddata.user;
      dispatch(updateSubuserLoading({ user: rest }, id));
      if (selectedFiles.length > 0) {
        dispatch(uploadFranchiseSubuserProfileLoading(selectedFiles[0], id));
      }

    } else {
      dispatch(createSubuserLoading(formateddata));

    }
    setIsSubmitting(true);
  };

  const handleSubmit = async (values: any) => {
    await submitFunction(values);
  };

  const hidePopup = () => {
    dispatch(clearFranchiseSubuserMessage());
    setVisible(false);
  };


  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const hidePopupNew = () => {
    dispatch(clearFranchiseSubuserMessage());
    setCreateVisible(false);
  };
  const dialogContainerNew = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={createvisible} onHide={hidePopupNew} />
      </>
    );
  };

  useEffect(() => {
    if (message != "" && message != undefined) {

      setIsSubmitting(false);
      showToast(message, { type: "success" });
      // if (!hasAdminOrEmployeeRole) {
      //   navigate(ROUTER.SUB_USER_GRID)
      // } else {
      //   navigate(`${ROUTER.FRANCHISEE_EDIT}/${sub_id}`);
      // }


    }
    if (createMessage) {
      setIsSubmitting(false);
      showToast(createMessage, { type: "success" });
      // if (!hasAdminOrEmployeeRole) {
      //   navigate(ROUTER.SUB_USER_GRID)
      // } else {
      //   navigate(`${ROUTER.FRANCHISEE_EDIT}/${sub_id}`);
      // }
    }

    dispatch(clearFranchiseSubuserMessage());
  }, [message, createMessage]);

  useEffect(() => {
    if (updatedmessage != "" && updatedmessage != undefined) {

      setIsSubmitting(false);
      showToast(updatedmessage, { type: "success" });
      if (!hasAdminOrEmployeeRole) {
        navigate(ROUTER.SUB_USER_GRID);
      } else {
        navigate(`${ROUTER.FRANCHISEE_EDIT}/${sub_id}`);
      }
    }

    dispatch(clearFranchiseSubuserMessage());
  }, [updatedmessage]);

  useEffect(() => {
    if (otpmessage != "" && otpmessage != undefined) {
      setOpenOtp(false);
      setIsSubmitting(false);
      showToast(otpmessage, { type: "success" });
      if (!hasAdminOrEmployeeRole) {
        navigate(ROUTER.SUB_USER_GRID);
      } else {
        navigate(`${ROUTER.FRANCHISEE_EDIT}/${sub_id}`);
      }


    }

  }, [otpmessage]);
  useEffect(() => {
    if (mailMessage != "" && mailMessage != undefined) {
      showToast(mailMessage, { type: "success" });
      dispatch(getSubuserByIdLoading(id));
      setEmailEdit(false);
    }
  }, [mailMessage]);
  useEffect(() => {
    if (phoneMessage != "" && phoneMessage != undefined) {
      showToast(phoneMessage, { type: "success" });
      // dispatch(getSubuserByIdLoading(id));
      setPhoneEdit(false);
      setOpenOtp(true);
    }
  }, [phoneMessage]);
  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
    }
  }, [error]);
  useEffect(() => {
    if (createError) {
      setIsSubmitting(false);
      setCreateVisible(true);
    }
  }, [createError]);
  useEffect(() => {
    if (id) {
      dispatch(getSubuserByIdLoading(id));
      dispatch(getFranchiseSubuserProfileLoading(id));
    }

    return () => {
      dispatch(resetSubuser());
      setLatitude("");
      setLongitude("");
      setAddress("");
    };
  }, [id]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  function handleAcceptedFiles(files: any) {
    console.log("accepted --> ", files);
    let filesModified = files.map((file: any) => {
      console.log("Files --> ", file);
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );

    console.log("FilesModifid --- > ", filesModified);
    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }

  /**
     * Formats the size
     */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };
  const removeCustomerImage = () => {
    setDeleteModal(false);
    if (franchiseSubuserImage) {
      dispatch(deleteFranchiseSubuserProfileLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };
  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };
  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };
  const [otpValue, setOtpValue] = useState<any>(null);
  const [openOtp, setOpenOtp] = useState<any>(false);
  const handleOtpSubmit = () => {
    if (id) {

      dispatch(sendFranchiseSubuserOtpLoading(otpValue, subUserDetails?.id, editedMobileNumber));
    } else {
      dispatch(sendFranchiseSubuserOtpLoading(otpValue, createdData?.id, createdData?.phoneNumber));
    }
    // setOpenOtp(false)
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpFranchiseSubuserMessage());
    if (id) {
      dispatch(getSubuserByIdLoading(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };

  useEffect(() => {
    debugger;
    if (createdData) {
      dispatch(setFranchiseSubuserMobileNumberLoading(createdData?.phoneNumber, createdData?.id));

    }
  }, [createdData]);

  const handleResendOtp = (number: any) => {
    dispatch(resendOtpFranchiseSubuser(subUserDetails?.id, number));
  };

  useEffect(() => {
    return () => {
      dispatch(resetOtpFranchiseSubuserMessage());
    };
  }, []);

  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {
    setPhoneEdit(false);
    dispatch(setFranchiseSubuserMobileNumberLoading(values.phoneNumber, subUserDetails?.id));
  };

  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {
    setEmailEdit(false);
    dispatch(setFranchiseSubuserEmailIdLoading(values.email, subUserDetails?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const handleAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(franchiseSubuserLatLagLoading(event.target.value))
      }
    }
  }

  return (
    <React.Fragment>
      {createvisible && dialogContainerNew(createError ? createError : error)}
      {visible && dialogContainer(error)}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      {customError && dialogContainerCustomError("Address field required")}
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeCustomerImage();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title={!hasAdminOrEmployeeRole ? "Franchise Subuser" : "Franchise"} titlePath={!hasAdminOrEmployeeRole ? ROUTER.SUB_USER_GRID : ROUTER.FRANCHISEE_GRID} breadcrumbItem={id ? "Edit Franchise Subuser" : "Create Franchise Subuser"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? editValidataionSchema : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={6} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={6} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Password"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["password"] && errors["password"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName={id ? "New Confirm Password" : "Confirm Password"}
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="confirmpassword"
                                onBlur={handleBlur}
                                value={values.confirmpassword}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["confirmpassword"] &&
                                    errors["confirmpassword"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}

                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e)=>{
                                  let event={...e}
                                  event.target.value=e.target.value.toLocaleLowerCase()
                                  handleChange(event)
                                }}
                                isRequired={true}
                                invalid={
                                  touched["email"] && errors["email"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                         
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["phoneNumber"] &&
                                      errors["phoneNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (<FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                  onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                />
                                )}

                              </div>
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="alternateNumber"
                                onBlur={handleBlur}
                                value={values.alternateNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["alternateNumber"] && errors["alternateNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Pan Card"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="panCardNo"
                                onBlur={handleBlur}
                                value={values.panCardNo}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["panCardNo"] && errors["panCardNo"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>


                            <Col lg={12} className="mb-2">
                              <InputCom
                                labelName="Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="address"
                                // onBlur={(e:any)=>{handleBlur(e) ; if(values.address){ dispatch(franchiseSubuserLatLagLoading(values.address)) }}}
                                onBlur={handleBlur}
                                onKeyDown={(e)=>{handleAddress(e)}}
                                value={values.address}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["address"] && errors["address"]
                                    ? true
                                    : false
                                }
                              />
                              <p style={{ fontSize: "12px",marginBottom:"3px"}}>Please press the Enter key after entering the Address.</p>
                              {subuserLatLng && 
                              <iframe src={`https://maps.google.com/maps?q=${subuserLatLng.lat},${subuserLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                              {/* <AddressMapFields address={address} setAddress={setAddress} lat={latitude} lng={longitude} title={"Address"}></AddressMapFields> */}
                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={subUserDetails?.extraProperties?.Address ? subUserDetails?.extraProperties?.Address : ""} title="Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}


                            </Col>
                            <Row>
                              <Col lg={2} md={3} sm={3} xs={6}>
                                <CheckboxInput
                                  name="lockoutEnabled"
                                  label="LockOut Enable"
                                  value={values["lockoutEnabled"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="isActive"
                                  label="Is Active"
                                  value={values["isActive"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="sendSms"
                                  label="Send Sms"
                                  value={values["sendSms"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="sendEmail"
                                  label="Send Email"
                                  value={values["sendEmail"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="sendWhatsapp"
                                  label="Whatsapp"
                                  value={values["sendWhatsapp"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} md={3} sm={3} xs={6} >
                                <CheckboxInput
                                  name="isVerfied"
                                  label="Is Verified"
                                  value={values["isVerfied"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                            </Row>
                            {id && <React.Fragment>
                              <div className="mt-1">
                                <h5>Franchise Subuser Profile</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {(selectedFiles.length == 0 && !franchiseSubuserImage) && <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onError={(err: Error) => { console.log("file error", err); }}
                                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                        maxFiles={1}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} accept="image/*" />
                                              <div className="">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>}

                                      {(selectedFiles[0]?.preview || franchiseSubuserImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : franchiseSubuserImage} removeIndex={deleteConfirmationHandler} />}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>}

                          </Row>

                          <div className="d-flex float-end mt-2 me-0">
                            {(subUserDetails?.phoneNumberConfirmed != true && id) &&
                              <Button
                                className="next-status-btn me-2 mt-2"
                                label={"Verify Mobile No"}
                                type="button"
                                onClick={() => handleResendOtp(values.phoneNumber)}
                              />
                            }
                            <Button
                              className="secondary-btn me-2 mt-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary mt-2 btn-primary-shadow"
                              label={id ? "Update Franchise Subuser" : "Create Franchise Subuser"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>

              {/* {
                (!id && hasAdminOrEmployeeRole ) && <Row className="mt-3">
                  <Col lg={12} className="dealer-title-header">
                    <div>Franchise SubUser Details</div>
                  </Col>
                  <SubUserList id={state?.state?.id} isOnCreatePage={isOnCreatePage} />
                </Row>
              } */}

            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditSubUser;