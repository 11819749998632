import React from "react";

interface buttonProps {
  buttonName : string ;
  className?: string;
  type: "submit" | "button" | "reset";
  onClick? :()=>void ;
}

const Button: React.FC <buttonProps> = ({buttonName,className,onClick,type}) => {
  return(
    <>
     <button onClick={onClick} className={className} type={type}>{buttonName}</button>
    </>
  )
};

export default Button;
