import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { TaxesTypes } from "./actionTypes";
import { createTaxesError, createTaxesSuccess, deleteTaxesFromListError, deleteTaxesFromListSuccess, getDropDownTaxesListError, getDropDownTaxesListSuccess, getTaxesBYIDError, getTaxesBYIDSuccess, getTaxesList, getTaxesListError, getTaxesListSuccess, getTaxesPageInvoicetypeDropdownError, getTaxesPageInvoicetypeDropdownSuccess, updateTaxesError, updateTaxesSuccess } from "./action";
import { TaxesDropDown, TaxesResponse, taxes } from "src/models/pages/taxes";


//worker function 

//get taxes list
function* getTaxesListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getTaxesListAPI, { ...action.lazyState })
    
    yield put(getTaxesListSuccess(response));
  } catch (error) {
    yield put(getTaxesListError(error));
  }
}

function* deleteTaxesAsync(action: any) {
  try {
    const response :Promise<any> = yield call(API.deleteTaxesListAPI, { id: action.payload.id, undo: action.payload.undo })    
    yield put(deleteTaxesFromListSuccess(response,action.payload.undo))
  } catch (error) {
    
    yield put(deleteTaxesFromListError(error));
  }
}

function* createTaxesAsync({ payload }: any) {
  try {
    const response: taxes = yield call(API.postTaxesListAPI, payload);
    
    yield put(createTaxesSuccess(response));

  } catch (error) {
    
    yield put(createTaxesError(error));
  }
}


function* updateTaxes({ payload: { parts, partId } }: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updateTaxesAPI, parts, partId);
    yield put(updateTaxesSuccess(data));
  } catch (error) {
    yield put(updateTaxesError(error));
  }
}

function* fetchTaxes({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getTaxesByIdAPI, payload);
    
    yield put(getTaxesBYIDSuccess(data));

  } catch (error) {
    yield put(getTaxesBYIDError(error));
  }
}

//taxes dropdown 
function* getTaxesDropdownAsyncList() {
  try {
    const response: TaxesDropDown[] = yield call(API.getTaxesDropDownListApi);
    
    yield put(getDropDownTaxesListSuccess(response));
  } catch (error) {
    yield put(getDropDownTaxesListError(error));
  }
}

function* getInvoiceDropdownAsync(){
  try {
    const response: Promise<any> = yield call(API.getTaxesPageInvoiceFropdownApi);
    yield put(getTaxesPageInvoicetypeDropdownSuccess(response));
  } catch (error) {
    yield put(getTaxesPageInvoicetypeDropdownError(error));
  }
}


//generator function
function* taxesSaga() {
  yield takeLatest(TaxesTypes.GET_TAXES_LIST, getTaxesListAsync);
  yield takeLatest(TaxesTypes.DELETE_TAXES, deleteTaxesAsync);
  yield takeLatest(TaxesTypes.POST_TAXES, createTaxesAsync);
  yield takeLatest(TaxesTypes.UPDATE_TAXES, updateTaxes);
  yield takeLatest(TaxesTypes.GET_TAXES_BY_ID, fetchTaxes);
  yield takeLatest(TaxesTypes.GET_TAXES_DROPDOWN_LIST_LOADING, getTaxesDropdownAsyncList);
  yield takeLatest(TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_LOADING ,getInvoiceDropdownAsync)
}

export default taxesSaga;