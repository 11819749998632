export enum permissionTypes {
    //Grid
    GET_ROLES_LOADING = "@@permission/GET_ROLES_LOADING",
    GET_ROLES_SUCCESS = "@@permission/GET_ROLES_SUCCESS",
    GET_ROLES_ERROR = "@@permission/GET_ROLES_ERROR",

    GET_PERMISSIONS_LOADING = "@@permission/GET_PERMISSIONS_LOADING",
    GET_PERMISSIONS_SUCCESS = "@@permission/GET_PERMISSIONS_SUCCESS",
    GET_PERMISSIONS_ERROR = "@@permission/GET_PERMISSIONS_ERROR",

    UPDATE_PERMISSIONS_LOADING = "@@permission/UPDATE_PERMISSIONS_LOADING",
    UPDATE_PERMISSIONS_SUCCESS = "@@permission/UPDATE_PERMISSIONS_SUCCESS",
    UPDATE_PERMISSIONS_ERROR = "@@permission/UPDATE_PERMISSIONS_ERROR",

    CREATE_ROLE_LOADING = "@@permission/CREATE_ROLE_LOADING",
    CREATE_ROLE_SUCCESS = "@@permission/CREATE_ROLE_SUCCESS",
    CREATE_ROLE_ERROR = "@@permission/CREATE_ROLE_ERROR",

    CLEAR_PERMISSIONS_LOADING = "@@permission/CLEAR_PERMISSIONS_LOADING",
  }