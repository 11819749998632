import { InputText } from 'primereact/inputtext';
import React from 'react'
import { Col, Row } from 'reactstrap';
import Button from 'src/components/UI/Button';


interface otpModalProps {
  onHide: any;
  visible?: boolean;
  onSubmit: any;
  setOtpValue:any;
  otpValue:any;
}

const OtpScreen = ({ onHide, visible = true, onSubmit,otpValue,setOtpValue }: otpModalProps) => {

  return (
    <React.Fragment>
      <div className="dialog_whole_section">
        <div className='close_Menu'>
          <div>Close</div>
          <div className='close_menu_Cross' onClick={onHide}>
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div className='dialog_header'>Verification</div>
        <div className='logo_container'>
          <div className="icon-container">
            <span className="icon">
              <i className="fas fa-lock"></i>
            </span>
          </div>
        </div>
        <div className='otp_dialog_container'>
          <div className='otp_sologan'>Enter the verification code</div>
          <div>
            <Row>
              <Col lg={12} style={{ marginTop: "10px" }}>
                <InputText style={{width:"100%"}} value={otpValue} onChange={(e) => setOtpValue(e.target.value)} />
              </Col>
            </Row>
            <div className='otp_dialog_button_groups'>
              <div className='btnsnsn'>
                <Row>
                  <Col lg={6}>
                    <Button className="secondary-btn me-2 w-100" label="Cancel" onClick={onHide} type="button" />
                  </Col>
                  <Col lg={6}>
                    <Button disabled={otpValue != "" ? false : true} className="btn-primary me-2 w-100" label="Confirm" type="button" onClick={()=>onSubmit()} />
                  </Col>
                </Row>
              </div>
            </div>
            {/* </Form>
                )
              }}
            </Formik> */}
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default OtpScreen


{/* <OtpInput length={6} /> */ }  // == old design ====