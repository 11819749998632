import { __awaiter } from 'tslib';
// crypto is replaced sha256
// const data = ""

// export default data;

import { AUTH_LOGIN_URL, AuthConfig } from "./authConfig";
import { base64UrlEncode } from "./base64-helper";
import { DefaultHashHandler } from "./hash-helper";

export class OAuthService extends AuthConfig {
  config: any;
  document = document;
  silentRefreshIFrameName: string;
  silentRefreshSubject?: string;
  silentRefreshRedirectUri: string;
  logoutUrl: string;
  //   crypto: sha256;

  constructor(json: any = {}) {
    super(json);
    this.saveNoncesInLocalStorage = true;
    this.config = {
      responseType: "code",
    };
    this.silentRefreshIFrameName = "refresh-frame";
    this.silentRefreshRedirectUri = "";
    // this.crypto = crypto;
    this.logoutUrl = "/connect/Logout";
  }
  saveNoncesInLocalStorage: boolean;

  createAndSaveNonce() {
    const that = this;
    return this.createNonce().then(function (nonce) {
      // Use localStorage for nonce if possible
      // localStorage is the only storage who survives a
      // redirect in ALL browsers (also IE)
      // Otherwiese we'd force teams who have to support
      // IE into using localStorage for everything+

      localStorage.setItem("nonce", JSON.stringify(nonce));
      //   if (
      //     that.saveNoncesInLocalStorage &&
      //     typeof window["localStorage"] !== "undefined"
      //   ) {
      //     localStorage.setItem("nonce", JSON.stringify(nonce));
      //   } else {
      //     that._storage.setItem("nonce", nonce);
      //   }
      return nonce;
    });
  }

  createNonce() {
    return new Promise((resolve) => {
      if (this.rngUrl) {
        throw new Error(
          "createNonce with rng-web-api has not been implemented so far"
        );
      }
      const unreserved =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
      let size = 45;
      let id = "";
      debugger;
      const crypto =
        // typeof self === "undefined" ? null : self.crypto || self["msCrypto"];
        typeof self === "undefined" ? null : self.crypto;
      if (crypto) {
        let bytes = new Uint8Array(size);
        crypto.getRandomValues(bytes);
        // Needed for IE

        // uncomment this code starts
        // if (!bytes?.map) {
        //   bytes.map = Array.prototype.map;
        // }
        // uncomment this code end
        bytes = bytes.map((x) => unreserved.charCodeAt(x % unreserved.length));
        // uncomment this code starts
        // id = String.fromCharCode.apply(null, ...Array.from(bytes));
        // uncomment this code end
        id = String.fromCharCode(...Array.from(bytes));
      } else {
        while (0 < size--) {
          id += unreserved[(Math.random() * unreserved.length) | 0];
        }
      }
      resolve(base64UrlEncode(id));
    });
  }

  async createLoginUrl(
    state = "",
    loginHint = "",
    customRedirectUri = "",
    noPrompt = false,
    params: any = {},
    refresh = false,
    fronendredirect = ""
  ) {
    const that = this;
    let redirectUri;
    if (customRedirectUri) {
      redirectUri = customRedirectUri;
    } else {
      redirectUri = this.redirectUri;
    }
    const nonce: any = await this.createAndSaveNonce();
    if (state) {
      state =
        nonce + this.config.nonceStateSeparator + encodeURIComponent(state);
    } else {
      state = nonce;
    }
    if (!this.requestAccessToken && !this.oidc) {
      throw new Error("Either requestAccessToken or oidc or both must be true");
    }
    console.log(
      "this.config.responseType", this.config.responseType
    );
    if (refresh) {
      // needs changes for refresh token
      this.config.responseType = "id_token";
    }
    if (this.config.responseType) {
      this.responseType = this.config.responseType;
    } else {
      if (this.oidc && this.requestAccessToken) {
        this.responseType = "id_token token";
      } else if (this.oidc && !this.requestAccessToken) {
        this.responseType = "id_token";
      } else {
        this.responseType = "token";
      }
    }
    const seperationChar = that.loginUrl.indexOf("?") > -1 ? "&" : "?";
    let scope = that.scope;
    if (this.oidc && !scope.match(/(^|\s)openid($|\s)/)) {
      scope = "openid " + scope;
    }
    let url =
      that.loginUrl +
      seperationChar +
      "response_type=" +
      encodeURIComponent(that.responseType) +
      "&client_id=" +
      encodeURIComponent(that.clientId) +
      "&state=" +
      encodeURIComponent(state) +
      "&redirect_uri=" +
      encodeURIComponent(redirectUri) +
      "&scope=" +
      encodeURIComponent(scope) +
      "&fronend=" + 
      encodeURIComponent(fronendredirect);
    debugger;
    if (this.responseType.includes("code") && !this.disablePKCE) {
      const [challenge, verifier]: any = await this.createChallangeVerifierPairForPKCE();
      debugger;
      localStorage.setItem("PKCE_verifier", verifier);
      localStorage.setItem("fronendUrl", fronendredirect);

      url += "&code_challenge=" + challenge;
      url += "&code_challenge_method=S256";
    }
    if (loginHint) {
      url += "&login_hint=" + encodeURIComponent(loginHint);
    }
    if (that.resource) {
      url += "&resource=" + encodeURIComponent(that.resource);
    }
    if (that.oidc) {
      url += "&nonce=" + encodeURIComponent(nonce);
    }
    if (noPrompt) {
      url += "&prompt=none";
    }
    for (const key of Object.keys(params)) {
      url +=
        "&" + encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    }
    if (this.customQueryParams) {
      for (const key of Object.getOwnPropertyNames(this.customQueryParams)) {
        url +=
          "&" + key + "=" + encodeURIComponent(this.customQueryParams[key]);
      }
    }
    return url;
  }

  async createChallangeVerifierPairForPKCE() {

    // if (!sha256) {
    //   throw new Error(
    //     "PKCE support for code flow needs a CryptoHander. Did you import the OAuthModule using forRoot() ?"
    //   );
    // }
    const verifier: any = await this.createNonce();
    let hashHandler = new DefaultHashHandler();
    // const challengeRaw = await this.crypto.calcHash(verifier, "sha-256");
    console.log("verifier", verifier);
    const challengeRaw = await hashHandler.calcHash(verifier, "sha-256");
    console.log(challengeRaw);
    // const challengeRaw = await sha256(verifier).toString();
    const challenge = base64UrlEncode(challengeRaw);
    console.log([challenge, verifier]);
    return [challenge, verifier];
  }

  hasValidIdToken() {
    const token = localStorage.getItem("token");
    if (token) {
      const payloadEncoded = token.split('.')?.[1];
      const decodedPayload = JSON.parse(atob(payloadEncoded));
      const expiresAt = decodedPayload?.['exp'];
      const nowInSeconds = Math.floor(new Date().getTime() / 1000);
      if (expiresAt && parseInt(expiresAt, 10) <= (nowInSeconds - 600)) {
        return false;
      }
      return true;
    }
    return false;
  }


  // setupSilentRefreshEventListener() {
  //   this.removeSilentRefreshEventListener();
  //   this.silentRefreshPostMessageEventListener = (e) => {
  //     const message = this.processMessageEventMessage(e);
  //     if (this.checkOrigin && e.origin !== location.origin) {
  //       console.error('wrong origin requested silent refresh!');
  //     }
  //     this.tryLogin({
  //       customHashFragment: message,
  //       preventClearHashAfterLogin: true,
  //       customRedirectUri: this.silentRefreshRedirectUri || this.redirectUri,
  //     }).catch((err) => this.debug('tryLogin during silent refresh failed', err));
  //   };
  //   window.addEventListener('message', this.silentRefreshPostMessageEventListener);
  // }

  getDiscovery(fullUrl: string) {
    fetch(fullUrl).then((response: any) => {
      console.log(response);
      console.log(response.authorization_endpoint);
    });
  }

  autoRefresh() {
    this.createLoginUrl().then(data => {
      let authUrl = AUTH_LOGIN_URL + "/connect/authorize";
      let culture = localStorage.getItem("i18nextLng");
      const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
      authUrl = authUrl + loginURL;
      const iframe = this.document.createElement('iframe');
      iframe.setAttribute("id", "refreshTokenIframe");
      iframe.setAttribute('src', authUrl);
      iframe.width = "600px";
      iframe.height = "600px";
      iframe.style.display = 'none';
      // iframe.setAttribute("sandbox", "SAMEORIGIN")
      this.document.body.appendChild(iframe);
    }).catch(error => {
      console.log("Hellow catch",error);
    });
  }

  logOut() {
    localStorage.clear()
    const iframe = this.document.createElement('iframe');
    let authUrl = AUTH_LOGIN_URL + "/Account/Logout";
    iframe.setAttribute("id", "logoutFrame");
    iframe.setAttribute('src', authUrl);
    iframe.width = "600px";
    iframe.height = "600px";
    iframe.style.display = 'none';
    this.document.body.appendChild(iframe);
  }

  silentRefresh(params: any = {}, noPrompt = true) {
    const token = localStorage.getItem("token") || "";
    if (this.hasValidIdToken()) {
      params['id_token_hint'] = localStorage.getItem('id_token') || "";
    }

    if (typeof this.document === 'undefined') {
      throw new Error('silent refresh is not supported on this platform');
    }
    const existingIframe = this.document.getElementById(this.silentRefreshIFrameName);
    if (existingIframe) {
      this.document.body.removeChild(existingIframe);
    }
    const payloadEncoded = token.split('.')?.[1];
    const decodedPayload = JSON.parse(atob(payloadEncoded));
    this.silentRefreshSubject = decodedPayload['sub'];
    const iframe = this.document.createElement('iframe');
    iframe.id = this.silentRefreshIFrameName;

    // uncomment this later starts
    // this.setupSilentRefreshEventListener();
    // uncomment this later ends

    const redirectUri = this.silentRefreshRedirectUri || this.redirectUri;
    this.createLoginUrl("", "", redirectUri, noPrompt, params, true).then((url) => {
      console.log(url);
      iframe.setAttribute('src', AUTH_LOGIN_URL + url);
      iframe.style['display'] = 'none';
      iframe.width = "600px";
      iframe.height = "600px";
      this.document.body.appendChild(iframe);
    });
    // const errors = this.events.pipe(filter((e) => e instanceof OAuthErrorEvent), first());
    // const success = this.events.pipe(filter((e) => e.type === 'token_received'), first());
    // const timeout = of(new OAuthErrorEvent('silent_refresh_timeout', null)).pipe(delay(this.silentRefreshTimeout));
    // return race([errors, success, timeout])
    //   .pipe(map((e) => {
    //     if (e instanceof OAuthErrorEvent) {
    //       if (e.type === 'silent_refresh_timeout') {
    //         this.eventsSubject.next(e);
    //       }
    //       else {
    //         e = new OAuthErrorEvent('silent_refresh_error', e);
    //         this.eventsSubject.next(e);
    //       }
    //       throw e;
    //     }
    //     else if (e.type === 'token_received') {
    //       e = new OAuthSuccessEvent('silently_refreshed');
    //       this.eventsSubject.next(e);
    //     }
    //     return e;
    //   }))
    //   .toPromise();
  }
}