export enum DealerActionTypes {
  GET_DEALERS = '@@dealer/GET_DEALERS',
  GET_DEALERS_SUCCESS = '@@dealer/GET_DEALERS_SUCCESS',
  GET_DEALERS_FAIL = '@@dealer/GET_DEALERS_FAIL',
  GET_DEALER_DETAIL = '@@dealer/GET_DEALER_DETAIL',
  GET_DEALER_DETAIL_SUCCESS = '@@dealer/GET_DEALER_DETAIL_SUCCESS',
  GET_DEALER_DETAIL_FAIL = '@@dealer/GET_DEALER_DETAIL_FAIL',

  ADD_DEALER_DETAIL = "@@dealer/ADD_DEALER_DETAIL",
  ADD_DEALER_DETAIL_SUCCESS = "@@dealer/ADD_DEALER_DETAIL_SUCCESS",
  ADD_DEALER_DETAIL_FAIL = "@@dealer/ADD_DEALER_DETAIL_FAIL",

  DELETE_DEALER_DETAIL = "@@dealer/DELETE_DEALER_DETAIL",
  DELETE_DEALER_DETAIL_SUCCESS = "@@dealer/DELETE_DEALER_DETAIL_SUCCESS",
  DELETE_DEALER_DETAIL_FAIL = "@@dealer/DELETE_DEALER_DETAIL_FAIL",

  UPDATE_DEALER_DETAIL = "@@dealer/UPDATE_DEALER_DETAIL",
  UPDATE_DEALER_DETAIL_SUCCESS = "@@dealer/UPDATE_DEALER_DETAIL_SUCCESS",
  UPDATE_DEALER_DETAIL_FAIL = "@@dealer/UPDATE_DEALER_DETAIL_FAIL",

  // grid
  GET_DEALER_LIST = "@@dealer/GET_DEALER_LIST",
  GET_DEALER_LIST_SUCCESS = "@@dealer/GET_DEALER_LIST_SUCCESS",
  GET_DEALER_LIST_FAIL = "@@dealer/GET_DEALER_LIST_FAIL",

  DELETE_DEALER = "@@dealer/DELETE_DEALER",
  DELETE_DEALER_SUCCESS = "@@dealer/DELETE_DEALER_SUCCESS",
  DELETE_DEALER_FAIL = "@@dealer/DELETE_DEALER_FAIL",
  // RESET
  CLEAR_DEALER_DATA = "@@dealer/CLEAR_DEALER_DATA",
  CLAER_DEALER_MESSAGE = "@@dealer/CLAER_DEALER_MESSAGE",


  // CONTACT
  GET_DEALER_CONTACT_SUCCESS = "@@dealer/GET_DEALER_CONTACT_SUCCESS",

  // DELETE CONTACT

  DELETE_DEALER_CONTACT_LOADING = "@@dealer/DELETE_DEALER_CONTACT_LOADING",
  DELETE_DEALER_CONTACT_SUCCESS = "@@dealer/DELETE_DEALER_CONTACT_SUCCESS",
  DELETE_DEALER_CONTACT_FAIL = "@@dealer/DELETE_DEALER_CONTACT_FAIL",

  // Tax type
  DEALER_TAX_TYPE_LIST_LOADING = "@@dealer/DEALER_TAX_TYPE_LIST_LOADING",
  DEALER_TAX_TYPE_LIST_SUCCESS = "@@dealer/DEALER_TAX_TYPE_LIST_SUCCESS",
  DEALER_TAX_TYPE_LIST_FAIL = "@@dealer/DEALER_TAX_TYPE_LIST_FAIL",



  // Dealer image

  DEALER_FILE_UPLOAD_LOADING = "@@dealer/DEALER_FILE_UPLOAD_LOADING",
  DEALER_FILE_UPLOAD_SUCCESS = "@@dealer/DEALER_FILE_UPLOAD_SUCCESS",
  DEALER_FILE_UPLOAD_ERROR = "@@dealer/DEALER_FILE_UPLOAD_ERROR",

  DEALER_FILE_FETCH_LOADING = "@@dealer/DEALER_FILE_FETCH_LOADING",
  DEALER_FILE_FETCH_SUCCESS = "@@dealer/DEALER_FILE_FETCH_SUCCESS",
  DEALER_FILE_FETCH_ERROR = "@@dealer/DEALER_FILE_FETCH_ERROR",

  DEALER_FILE_DELETE_LOADING = "@@dealer/DEALER_FILE_DELETE_LOADING",
  DEALER_FILE_DELETE_SUCCESS = "@@dealer/DEALER_FILE_DELETE_SUCCESS",
  DEALER_FILE_DELETE_ERROR = "@@dealer/DEALER_FILE_DELETE_ERROR",

  DEALER_FILE_CLEAR = "@@dealer/DEALER_FILE_CLEAR",

  DEALER_SET_PHONE_LOADING="@@dealer/DEALER_SET_PHONE_LOADING",
  DEALER_SET_PHONE_SUCCESS="@@dealer/DEALER_SET_PHONE_SUCCESS",
  DEALER_SET_PHONE_ERROR="@@dealer/DEALER_SET_PHONE_ERROR",

  DEALER_SET_EMAIL_LOADING="@@dealer/DEALER_SET_EMAIL_LOADING",
  DEALER_SET_EMAIL_SUCCESS="@@dealer/DEALER_SET_EMAIL_SUCCESS",
  DEALER_SET_EMAIL_ERROR="@@dealer/DEALER_SET_EMAIL_ERROR",

  SEND_OTP_DEALER ="@@dealer/SEND_OTP_DEALER",
  SEND_OTP_DEALER_SUCCESS ="@@dealer/SEND_OTP_DEALER_SUCCESS",
  SEND_OTP_DEALER_ERROR ="@@dealer/SEND_OTP_DEALER_ERROR",

  RESEND_DEALER_OTP = "@@dealer/RESEND_DEALER_OTP",
  RESEND_DEALER_OTP_SUCCESS = "@@dealer/RESEND_DEALER_OTP_SUCCESS",
  RESEND_DEALER_OTP_ERROR = "@@dealer/RESEND_DEALER_OTP_ERROR",
  
  RESET_DEALER_OTP_MESSAGE = "@@dealer/RESET_DEALER_OTP_MESSAGE",

  GET_DEALER_LOCATION_LOADING="@@dealer/GET_DEALER_LOCATION_LOADING",
  GET_DEALER_LOCATION_SUCCESS="@@dealer/GET_DEALER_LOCATION_SUCCESS",
  GET_DEALER_LOCATION_ERROR="@@dealer/GET_DEALER_LOCATION_ERROR",
}
