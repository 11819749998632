export enum AudioModelsTypes {

  // Grid
  GET_AUDIO_MODELS_LIST_LOADING = "@@audioModels/GET_AUDIO_MODELS_LIST_LOADING",
  GET_AUDIO_MODELS_LIST_SUCCESS = "@@audioModels/GET_AUDIO_MODELS_LIST_SUCCESS",
  GET_AUDIO_MODELS_LIST_FAIL = "@@audioModels/GET_AUDIO_MODELS_LIST_FAIL",

  //delete
  DELETE_AUDIO_MODELS_LOADING = "@@audioModels/DELETE_AUDIO_MODELS_LOADING",
  DELETE_AUDIO_MODELS_SUCCESS = "@@audioModels/DELETE_AUDIO_MODELS_SUCCESS",
  DELETE_AUDIO_MODELS_FAIL = "@@audioModels/DELETE_AUDIO_MODELS_FAIL",
 
  //create audio model
  CREATE_AUDIO_MODELS_PENDING = "@@audioModels/CREATE_AUDIO_MODELS_PENDING",
  CREATE_AUDIO_MODELS_SUCCESS = "@@audioModels/CREATE_AUDIO_MODELS_SUCCESS",
  CREATE_AUDIO_MODELS_ERROR = "@@audioModels/CREATE_AUDIO_MODELS_ERROR",

  //update audio model
  UPDATE_AUDIO_MODELS_PENDING = "@@audioModels/UPDATE_AUDIO_MODELS_PENDING",
  UPDATE_AUDIO_MODELS_SUCCESS = "@@audioModels/UPDATE_AUDIO_MODELS_SUCCESS",
  UPDATE_AUDIO_MODELS_ERROR = "@@audioModels/UPDATE_AUDIO_MODELS_ERROR",

  //get by id audio model
  GET_BY_ID_AUDIO_MODEL_PENDING = "@@audioModels/GET_BY_ID_AUDIO_MODEL_PENDING",
  GET_BY_ID_AUDIO_MODEL_SUCCESS = "@@audioModels/GET_BY_ID_AUDIO_MODEL_SUCCESS",
  GET_BY_ID_AUDIO_MODEL_ERROR = "@@audioModels/GET_BY_ID_AUDIO_MODEL_ERROR",

  //reset
  RESET_AUDIO_MODEL = "@@audioModels/RESET_AUDIO_MODEL",

  //audio brand name
  GET_AUDIO_BRAND_NAMES_LOADING = "@@audioBrands/GET_AUDIO_BRAND_NAMES_LOADING",
  GET_AUDIO_BRAND_NAMES_SUCCESS = "@@audioBrands/GET_AUDIO_BRAND_NAMES_SUCCESS",
  GET_AUDIO_BRAND_NAMES_ERROR = "@@audioBrands/GET_AUDIO_BRAND_NAMES_ERROR",

  
  GET_AUDIO_MODEL_AUTOCOMPLETE = "@@audioBrands/GET_AUDIO_MODEL_AUTOCOMPLETE",
  GET_AUDIO_MODEL_AUTOCOMPLETE_SUCCESS = "@@audioBrands/GET_AUDIO_MODEL_AUTOCOMPLETE_SUCCESS",
  GET_AUDIO_MODEL_AUTOCOMPLETE_FAIL = "@@audioBrands/GET_AUDIO_MODEL_AUTOCOMPLETE_FAIL",
}
