import { API } from "src/util/api";
import { getMonthlypartsSummarySuccess, getMonthlypartsSummaryError } from "./action";
import { monthlyPartsSummaryActions } from "./actionTypes";
import { call, put, takeLatest } from "redux-saga/effects"


//worker function

//get method

function* getMonthlyPartsSummaryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getMonthlyPartsSummarysApi, { ...action.lazyState });
    yield put(getMonthlypartsSummarySuccess(response));
  } catch (error) {
    yield put(getMonthlypartsSummaryError(error));
  } 
}


//watcher function

function* monthlyPartsSummarySaga() {
  yield takeLatest(monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_LOADING, getMonthlyPartsSummaryAsync)
}


export default monthlyPartsSummarySaga ;