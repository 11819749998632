import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getPurchaseOrderDashboardLoading } from "src/store/actions";
import 'bootstrap/dist/css/bootstrap.min.css'
import { RootReducerState } from "src/store/reducers";

interface purchaseOrderChart {
  date: any,
  realDate: any
}

const PurchaseOrderStatusChart = ({ date, realDate }: purchaseOrderChart) => {
  const [month, year] = date.split(' ').map((value: any, index: any) => index === 0 ? new Date(`${value} 1, 2000`).getMonth() + 1 : parseInt(value));
  const dispatch = useDispatch();
  const [state, setState] = useState<any>("ALL");
  const [modifiedData, setModifiedData] = useState<any>([]);
  const [monthName, setMonthName] = useState<any>([])
  const { purchaseOrderData } = useSelector((state: RootReducerState) => state.dashboard);


  useEffect(() => {
  }, [state]);
  let day = new Date()
const [monthTotal,setmonthTotal]=useState<any>("")
  useEffect(() => {
    if (month && year) {
      dispatch(getPurchaseOrderDashboardLoading(month, year));
    }
  }, [month, year]);
  
  useEffect(() => {
    if (purchaseOrderData.length > 0) {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const categories = purchaseOrderData.map(item => `${item.month}-${item.year}`);
const sortedArr = categories.sort((a:any, b:any) => {
  const [monthA, yearA] = a.split('-').map(Number);
  const [monthB, yearB] = b.split('-').map(Number);
  
  if (yearA === yearB) {
    return monthA - monthB; // Compare months if years are the same
  } else {
    return yearA - yearB; // Compare years
  }
});

let monthValue=sortedArr.map((item:any) => monthNames[Number(item.split('-')[0]) - 1])
const seriesData = purchaseOrderData.map(item => item.noOfItems);
setModifiedData(seriesData)
setMonthName(monthValue)
    } else {
      setModifiedData([]);
      setMonthName([]);
    }
  }, [purchaseOrderData]);

  
  // Define chart options
  const options:object  = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        endingShape: 'rounded'
      }
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: monthName,
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Purchase Order'
      },
      min: 1,
      max: monthTotal.noOfItems ? Math.max(...monthTotal.noOfItems, 40) : 40
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };


  const series = [
    {
        name: "No of Items",
        data: modifiedData
    }
];
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Purchase Order Status</h5>
              <div className="ms-auto">
                <div>
                  <b>{date}</b>
                  {false && <>
                    <button
                      type="button"
                      className="btn btn-soft-primary btn-sm"
                    // onClick={() => onChangehandle("ALL")}
                    >
                      ALL
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    // onClick={() => onChangehandle("1M")}
                    >
                      1M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    // onClick={() => onChangehandle("6M")}
                    >
                      6M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    // onClick={() => onChangehandle("1Y")}
                    >
                      1Y
                    </button>
                  </>}

                </div>
              </div>
            </div>

            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <Row className="align-items-center">
                    <Col lg={12}>
                      <div style={{ width: "100%", height: "370px" }}>
                        {
                          modifiedData?.length > 0 ? <ReactApexChart options={options} series={series} type="bar" width={"100%"} height={"100%"} /> : <div>No data available</div>
                        } 

                      </div>
                    </Col>

                  </Row>
                </div>
              </div>

            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default PurchaseOrderStatusChart;
