
import { ManufacturerState } from "src/models/pages/manufacturerModel";
import { ManufacturerType } from "./actionTpes";

const initialState: ManufacturerState = {
  loading: false,
  error: "",
  message : "",
  createMessage:"",
  createError : "",
  paginatorCount : 0,
  manufacturerList: {
    values: [],
    totalCount: 0,
    subUserDetails: undefined
  },
  manufacturerImage:"",
  mailMessage:"",
  phoneMessage:"",
  editedMobileNumber:"",
  createdData:"",
  otpmessage:"",
  updatedmessage:"",
  subuserLatLng:"",
  jobPrefixList:[]
}

const Manufacturer = (state = initialState, action: any) => {
  switch (action.type) {
    case ManufacturerType.GET_MANUFACTURER_LIST_LOADING:
    case ManufacturerType.CREATE_MANUFACTURER_LOADING:
    case ManufacturerType.GET_MANUFACTURERBYID_LOADING:
    case ManufacturerType.UPDATE_MANUFACTURER_LOADING:
    case ManufacturerType.DELETE_MANUFACTURER_LOADING:
    case ManufacturerType.DELETE_MANUFACTURER_PROFILE_LOADING:
    case ManufacturerType.MANUFACTURER_SET_PHONE_LOADING:
    case ManufacturerType.MANUFACTURER_SET_EMAIL_LOADING:
    case ManufacturerType.SEND_OTP_MANUFACTURER:
    case ManufacturerType.RESEND_MANUFACTURER_OTP:
    case ManufacturerType.GET_MANUFACTURER_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        message : "",
        error : "",
        paginatorCount :0
      }
    case ManufacturerType.GET_MANUFACTURER_LIST_SUCCESS:
      return {
        loading: false,
        manufacturerList: {
          ...state.manufacturerList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount
        },
        paginatorCount :action.payload.data.items.length 
      }
    case ManufacturerType.CREATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"",
        createMessage : "Manufacturer Created Successfully",
        createdData:action.payload.data
      }
    case ManufacturerType.GET_MANUFACTURER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        subuserLatLng:action.payload.latLag.data
      }
    case ManufacturerType.GET_MANUFACTURER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"",
        manufacturerImage:action.payload.data
      }
    case ManufacturerType.DELETE_MANUFACTURER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Profile Deleted Successfully",
        manufacturerImage:""
      }
    case ManufacturerType.UPLOAD_MANUFACTURER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"",
      }

    case ManufacturerType.UPDATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        createMessage:"",
        updatedmessage : "Manufacturer Updated Successfully"
      }

    case ManufacturerType.GET_MANUFACTURERBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        manufacturerList: {
          ...state.manufacturerList,
          subUserDetails: action.payload.data,
        },
        phoneMessage:"",
        mailMessage:""
      };

    case ManufacturerType.DELETE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        error:"",
        createMessage:"",
        message:action.undo ? "Manufacturer Restored Successfully" : "Manufacturer Deleted Successfully"
      }
    
    case ManufacturerType.RESET_MANUFACTURER : 
       return {
         ...state,
        ...initialState,
        manufacturerList: {
          values: [],
          totalCount: 0,
          subUserDetails: undefined
        },
        manufacturerImage:"",
        subuserLatLng:""
       }  
    case ManufacturerType.CLEAR_MANUFACTURER_MESSAGE : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        updatedmessage:""
       }  
    case ManufacturerType.MANUFACTURER_SET_PHONE_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        phoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number
       }  
    case ManufacturerType.MANUFACTURER_SET_EMAIL_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        mailMessage:"Email Id Updated Successfully"
       }  
    case ManufacturerType.SEND_OTP_MANUFACTURER_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        otpmessage:"OTP verified Successfully"
       }  
    case ManufacturerType.RESEND_MANUFACTURER_OTP_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        phoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number
       }  
       case ManufacturerType.GET_VEHICLE_BRAND_LIST_SUCCESS:
        return {
          ...state,
          loading:false,
          error:"",
          message:"",
          jobPrefixList: action.payload.data?.filter((data:any)=>data.isDeleted != true)
        }

    case ManufacturerType.CREATE_MANUFACTURER_ERROR: 
      return {
        ...state,
        loading: false,
        createError: action.payload,
        error : "",
        updatedmessage:""
      }

    case ManufacturerType.GET_MANUFACTURER_LIST_ERROR:    
    case ManufacturerType.GET_MANUFACTURERID_ERROR:
    case ManufacturerType.UPDATE_MANUFACTURER_ERROR:
    case ManufacturerType.DELETE_MANUFACTURER_ERROR:
    case ManufacturerType.UPLOAD_MANUFACTURER_PROFILE_ERROR:
    case ManufacturerType.DELETE_MANUFACTURER_PROFILE_ERROR:
    case ManufacturerType.MANUFACTURER_SET_EMAIL_ERROR:
    case ManufacturerType.MANUFACTURER_SET_PHONE_ERROR:
    case ManufacturerType.SEND_OTP_MANUFACTURER_ERROR:
    case ManufacturerType.RESEND_MANUFACTURER_OTP_ERROR:
    case ManufacturerType.GET_MANUFACTURER_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state }
  }
}

export default Manufacturer