import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import './parts.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { clearPartsMessage, getAudioBrandsListForParts, getPartsBYID, getViewPartsPartInboundDetailsLoading, getViewPartsPurchaseOrderLoading, resetPartsMessage } from 'src/store/actions'
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel'
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon'
import { DataTableFilterMeta } from 'primereact/datatable'
import { Link } from 'react-router-dom'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';


const ViewParts = () => {


  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" }
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",
    filters: filterDetails,
  };

  const navigate = useNavigate()
  const { id } = useParams()
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [purchaseOrderLazyState,setPurchaseOrderLazyState] = useState(initialLazyState)
  const [visible, setVisible] = useState(false);
  const editUserClick = () => {
    navigate(`${ROUTER.PARTS_EDIT}/${id}`)
  }

  const formValue: any = useSelector(
    (state: RootReducerState) => state.partsReducer.parts
  );
  const { error, message, loading, audioBrandList } = useSelector((state: RootReducerState) => state.partsReducer);

  const { partinbounditems, totalCount } = useSelector((state: RootReducerState) => state.partsReducer.partInboundDetails)

  const { purchaseitems, count } = useSelector((state: RootReducerState) => state.partsReducer.purchaseOrderDetails)
  
  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }


  useEffect(() => {
    if (id) {
      dispatch(getAudioBrandsListForParts());
      dispatch(getPartsBYID(id));
      
      // dispatch(getViewPartsPurchaseOrderLoading(id))
    }
    return () => {
      dispatch(resetPartsMessage())
    }
  }, []);

  const callbackFunction = (lazyState: LazyTableState) => {
    let object = {
      id : id,
      lazyState : lazyState
    }
    dispatch(getViewPartsPartInboundDetailsLoading(object))
  };

  const purchaseOrderCallback = (lazyState: LazyTableState) => {
    let object = {
      id : id,
      lazyState : lazyState
    }
    dispatch(getViewPartsPurchaseOrderLoading(object))
  };



  useEffect(()=>{
    callbackFunction(lazyState);
  },[lazyState])


  useEffect(()=>{
    purchaseOrderCallback(purchaseOrderLazyState)
  },[purchaseOrderLazyState])

  useEffect(()=>{
   if(error){
    setVisible(true);
   }
  },[error])

  const AudioBrandTemplate = (rowData: any, field: any) => {    
    return (
      <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${field}`}>{rowData}</Link>
    )
  }
  const userView = {
    generalDetails: [
      {
        name: "Part Number",
        value: formValue?.partNumber
      },
      {
        name: "Part Description",
        value: formValue?.description
      },
      {
        name: "HSN Number",
        value: formValue?.hsnNumber
      },
      {
        name: "Tax Percentage",
        value: formValue?.taxPercentage + " %"
      },
      {
        name: "Reorder Level",
        value: String(formValue?.reorderLevel)
      },
      {
        name:"In Stock Quantity",
        value:formValue?.inStockQuantity
      },
      {
        name: "Audio Brand ",
        value: formValue?.audioBrandId ? AudioBrandTemplate(audioBrandList.find((item: any) => item.id === formValue?.audioBrandId)?.brandName,formValue?.audioBrandId) : ""
      }
    ]
  }

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }
  const partinboundColumn: Columns[] = [
    {
      field: "invoiceNumber",
      sortable: false,
      header: "Part Inbound",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "quantity",
      sortable: false,
      header: "Quantity",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.quantity),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "buyingPrice",
      sortable: false,
      header: "Buying Price",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.buyingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "profitPercentage",
      sortable: false,
      header: "Profit Percentage",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.profitPercentage),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "sellingPrice",
      sortable: false,
      header: "Selling Price",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.sellingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },

  ];

  const purchaseOrderColumn: Columns[] = [
    {
      field: "poNumber",
      sortable: false,
      header: "Purchase Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "quantity",
      sortable: false,
      header: "Quantity",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.quantity),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "buyingPrice",
      sortable: false,
      header: "Buying Price",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body:(rowData:any)=>headerTemplate(rowData.buyingPrice),
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalBuyingPrice",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.totalBuyingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
  ];

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onPagination = (event: any) => {
    setPurchaseOrderLazyState(event);
  };

  

  const hidePopup = ()=>{
    setVisible(false); 
    dispatch(clearPartsMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className='userpage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Parts" titlePath={ROUTER.PARTS_GRID} breadcrumbItem='Part Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Parts'
            onFirstButtonClick={editUserClick}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
              <div className="parentCls">
                  {
                    userView.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Purchase Order</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={purchaseOrderColumn}
                        value={purchaseitems}
                        sortIcon={(setOrder) => <SortIcon order={setOrder} />}
                        emptyMessage="No Purchase Order found"
                        paginator={true}
                        lazy
                        stripedRows={true}
                        rowHover={true}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        totalRecords={count}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Displaying purchaseOrder {last} records of {totalRecords} in total"
                        first={purchaseOrderLazyState.first}
                        sortField={purchaseOrderLazyState.sortField}
                        sortOrder={purchaseOrderLazyState.sortOrder}
                        filters={purchaseOrderLazyState.filters}
                        onPage={onPagination}
                        rows={purchaseOrderLazyState.rows}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          </Row>

          <Row>
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Part Inbound</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={partinboundColumn}
                        value={partinbounditems}
                        paginator={true}
                        stripedRows={true}
                        lazy
                        rowHover={true}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        totalRecords={totalCount}
                        emptyMessage="No Part Inbound found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Displaying PartInbound {last} records of {totalRecords} in total"
                        first={lazyState.first}
                        sortField={lazyState.sortField}
                        sortOrder={lazyState.sortOrder}
                        filters={lazyState.filters}
                        onPage={onPage}
                        rows={lazyState.rows}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          </Row>

        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewParts