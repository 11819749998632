import { ErrorMessage } from "formik";
import React, { FocusEvent } from "react";
import { Option } from "src/models/components/inputWrapperModel";
import ErrorText from "../UI/ErrorText";


interface SelectProps {
  
  label: string;
  options: Option[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  value: string;
  invalid: boolean;
  name: string;
  isRequired?: boolean;
  labelClassName?:string;
  inputClassName?:string; 
}
const Select: React.FC<SelectProps> = (props) => {

  const {label,isRequired,invalid,options,name,labelClassName,inputClassName,...rest} = props ;
  
  return (
    <>
     {label && <label className={`form-label ${labelClassName}`}>
        {label}{" "}
        {isRequired && <span className="text-danger">*</span>}
      </label>}
      <select
        className={`form-select ${invalid ? "is-invalid" : ""}${inputClassName}`}
        name={name}
        {...rest}
      >
        <option value="">Select</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
      <ErrorMessage name={name || ''} component={ErrorText} />
      {/* {invalid && (
        <div className="invalid-feedback">Please make a selection</div>
      )} */}
    </>
  );
};

export default Select;












