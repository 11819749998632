import { messages } from "src/common/data";
import { permissionTypes } from "./actionTypes";

const initialValue: any = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  Roles:"",
  permissionDetails:""
}

const permissionReducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case permissionTypes.GET_ROLES_LOADING:
    case permissionTypes.UPDATE_PERMISSIONS_LOADING:
    case permissionTypes.CREATE_ROLE_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }

    case permissionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        Roles:action.payload.data.items
      }

    case permissionTypes.GET_PERMISSIONS_SUCCESS:
        
        
      return {
        ...state,
        loading: false,
        permissionDetails:action.payload.data
      }

    case permissionTypes.UPDATE_PERMISSIONS_SUCCESS:
        
        
      return {
        ...state,
        loading: false,
        message:`${action.payload.role} Permissions Updated Sucessfully`
      }

    case permissionTypes.CREATE_ROLE_SUCCESS:;
        
      return {
        ...state,
        loading: false,
        message:"Role Created Successfully"
      }
    case permissionTypes.CLEAR_PERMISSIONS_LOADING:
        
      return {
        ...state,
        loading: false,
        message:"",
        error:""
      }

    case permissionTypes.GET_ROLES_ERROR:
    case permissionTypes.GET_PERMISSIONS_ERROR:
    case permissionTypes.UPDATE_PERMISSIONS_ERROR:
    case permissionTypes.CREATE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state };
  }
}


export default permissionReducer;