import { UserModel, usersInitialState } from "src/models/pages/userModel";
import { UserTypes } from "./actionTypes";




const employeeImageDeleteSuccess = "Employee image is deleted successfully";




const initialState: usersInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  userDetails: {
    id: "",
    name: "",
    mobileNumber: "",
    email: "",
    Role: "",
    password: "",
    confirmPassword: "",
    address: ""
  },
  userList: {
    values: [],
    totalRecords: 0
  },
  employeeImage: "",
  documentList: [],
  generateLink:null,
  phoneMessage:"",
  emailMessage:"",
  otpMessage:"",
  editedMobileNumber:"",
  createdData:"",
  updatedmessage:"",
  employeeLatLng:""
};



const User = (state = initialState, action: any) => {
  switch (action.type) {
    case UserTypes.GET_USERLOADING:
    case UserTypes.CREATE_USER_LOADING:
    case UserTypes.UPDATE_USER_LOADING:
    case UserTypes.EMPLOYEE_FILE_DELETE_LOADING:
    case UserTypes.EMPLOYEE_FILE_FETCH_LOADING:
    case UserTypes.EMPLOYEE_FILE_UPLOAD_LOADING:
    case UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_LOADING:
    case UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING:
    case UserTypes.EMPLOYEE_DELETE_DOCUMENT_LOADING:
    case UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING:
    case UserTypes.EMPLOYEE_SET_PHONE_LOADING:
    case UserTypes.EMPLOYEE_SET_EMAIL_LOADING:
    case UserTypes.SEND_OTP_EMPLOYEE:
    case UserTypes.RESEND_EMPLOYEE_OTP:
    case UserTypes.GET_EMPLOYEE_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0
      };
    case UserTypes.EMPLOYEE_FILE_CLEAR:
      return {
        ...state,
        employeeImage: ""
      }
    case UserTypes.GET_USERSUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        emailMessage:"",
        phoneMessage:""
      };

    case UserTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Employee created successfully.",
        createdData:action.payload.data
      };

    case UserTypes.GET_EMPLOYEE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeLatLng:action.payload.latLag.data
      };

    case UserTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        updatedmessage: "Employee updated successfully."
      };



    case UserTypes.EMPLOYEE_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };

    case UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };

    case UserTypes.EMPLOYEE_FILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        employeeImage: action.payload.data,
      };

    case UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        documentList: action.payload.data,
      };

    case UserTypes.EMPLOYEE_FILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: employeeImageDeleteSuccess,
        dealerImage: "",
      };

    case UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        error: "",
        generateLink : action.payload.data,
      };

    case UserTypes.EMPLOYEE_DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Employee documented deleted successfully",
        dealerImage: "",
      };

    case UserTypes.EMPLOYEE_SET_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        phoneMessage:"OTP sent successfully",
        editedMobileNumber:action.payload.number
      };

      case UserTypes.RESEND_EMPLOYEE_OTP_SUCCESS:
        return {
          ...state,
          loading: false,
          error: "",
          message: "",
          phoneMessage:"OTP Sent Successfully ",
          editedMobileNumber:action.payload.number
        };
        
    case UserTypes.RESEND_EMPLOYEE_OTP_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        phoneMessage:"OTP sent successfully",
        editedMobileNumber:action.payload.number
      };

    case UserTypes.SEND_OTP_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        phoneMessage:"",
        otpMessage:"OTP verified successfully"
      };

    case UserTypes.EMPLOYEE_SET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        emailMessage:"Email Id Updated Successfully"
      };
    case UserTypes.RESET_EMPLOYEE_OTP_MESSAGE:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        emailMessage:"",
        phoneMessage:"",
        editedMobileNumber:"",
        createdData:"",
        updatedmessage:""
      };

    case UserTypes.GET_USERERROR:
    case UserTypes.CREATE_USER_ERROR:
    case UserTypes.UPDATE_USER_ERROR:
    case UserTypes.GET_USERS_LIST_FAIL:
    case UserTypes.DELETE_USER_FAIL:
    case UserTypes.EMPLOYEE_FILE_DELETE_ERROR:
    case UserTypes.EMPLOYEE_FILE_FETCH_ERROR:
    case UserTypes.EMPLOYEE_FILE_UPLOAD_ERROR:
    case UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_ERROR:
    case UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR:
    case UserTypes.EMPLOYEE_DELETE_DOCUMENT_ERROR:
    case UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR:
    case UserTypes.EMPLOYEE_SET_PHONE_ERROR:
    case UserTypes.EMPLOYEE_SET_EMAIL_ERROR:
    case UserTypes.SEND_OTP_EMPLOYEE_ERROR:
    case UserTypes.RESEND_EMPLOYEE_OTP_ERROR:
    case UserTypes.GET_EMPLOYEE_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UserTypes.RESET_USER: {
      return {
        ...state,
        error: "",
        loading: false,
        message: "",
        generateLink:null
      };
    }

    case UserTypes.EMPLOYEE_DOCUMENTED_LINK_RESET :
      return {
        ...state,
        loading:false,
        generateLink:null
      }
    // Grid

    case UserTypes.GET_USERS_LIST:
      return {
        ...state,
        loading: true,
      };

    case UserTypes.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: {
          ...state.userList,
          // values: action.payload.data.values,
          values: action.payload?.items,
          totalRecords: action.payload?.totalCount,
        },
        paginatorCount: action.payload?.items.length
      };


    case UserTypes.DELETE_USER:
      return {
        ...state,
        loading: true,
      };

    case UserTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: action.undo ? "Employee is restored successfully" : "Employee is deleted successfully"
      };


    case UserTypes.CLEAR_USER_DATA:
      return {
        error: "",
        loading: false,
        message: "",
        updatedmessage:"",
        paginatorCount: 0,
        userDetails: {
          id: "",
          name: "",
          mobileNumber: "",
          email: "",
          Role: "",
          password: "",
          confirmPassword: "",
          address: ""
        },
        userList: {
          values: [],
          totalRecords: 0
        },
        employeeLatLng:""
      };



    default:
      return { ...state };
  }
};

export default User;
