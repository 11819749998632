import { LoginAuthState } from "src/models/auth/loginAuthState";
import { LoginActionTypes } from "./actionTypes";

const getExpTime = localStorage.getItem("exp")

const INITIAL_STATE: LoginAuthState = {
  refreshAt: getExpTime
};


const loginAuthReducer = (state: LoginAuthState = INITIAL_STATE, action: { type: string, payload: any; }) => {
  const { type, payload } = action;
  // alert(payload)
  switch (type) {
    case LoginActionTypes.SET_REFRESH_TOKEN_TIME:
      return { ...state, refreshAt: payload };
    default:
      return { ...state };
  }
};

export default loginAuthReducer;