import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { ROUTER } from 'src/constants/routes';
import {
  formatDateMEL,
  hasCustomerRoleHandler,
  hasDealerRoleHandler,
  hasFranchiseRoleHandler,
} from 'src/helpers/common_helpers';
import { Columns } from 'src/models/components/dataTableWrapperModel';
import { clearCourierDetails, clearCourierMessage, courierStatusTrackingLoading, dealerListLoading, donloadPdfCourierPageLoading, viewCourierLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { Link } from 'react-router-dom';
import CourierTimeLine from 'src/components/atoms/CourierTimeLine';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';

const ViewCourier = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const smartrCompany = "Smartr";
  const blueDartCompany = "Blue Dart";
  const blueDartEComCompany = "Blue Dart eCOM";
  const [visible, setVisible] = useState(false);
  const { error, courierInformation, companyList, courierTrackingStatus, loading } = useSelector((state: RootReducerState) => state.courierReducer);
  const navigate = useNavigate();
  const isDealerLogin = hasDealerRoleHandler();
  const isFranchise = hasFranchiseRoleHandler();
  const isCustomer = hasCustomerRoleHandler();


  useEffect(() => {
    if (id) {
      dispatch(viewCourierLoading(id));
      dispatch(dealerListLoading());
    }
    return () => {
      dispatch(clearCourierDetails());
      dispatch(clearCourierMessage());
    };
  }, []);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (courierInformation?.company == smartrCompany || courierInformation?.company == blueDartCompany || courierInformation?.company == blueDartEComCompany) {
      dispatch(courierStatusTrackingLoading(id));
    }
  }, [courierInformation]);

  const statusBodyTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );
  };


  const navigateCorrespondedPage = (receiverType: any, recieverId: any, recieverName: any) => {
    if (receiverType == "dealer") {
      if (recieverName) {
        return (
          <>
            {
              (isDealerLogin || isFranchise || isCustomer) ? recieverName : <Link to={`${ROUTER.DEALERS_VIEW}/${recieverId}`}> {recieverName}</Link>
            }
          </>
        );
      } else {
        return "";
      }
    } else if (receiverType == "customer") {
      if (recieverName) {
        return (
          <>
            {
              (isDealerLogin || isFranchise || isCustomer) ? recieverName : <Link to={`${ROUTER.CUSTOMERS_VIEW}/${recieverId}`}> {recieverName}</Link>
            }
          </>
        );
      } else {
        return "";
      }
    } else if (receiverType == "franchise") {
      if (recieverName) {
        return (
          <>
            {
              (isDealerLogin || isFranchise || isCustomer) ? recieverName : <Link to={`${ROUTER.FRANCHISEE_VIEW}/${recieverId}`}> {recieverName}</Link>
            }
          </>
        );
      } else {
        return "";
      }
    } else {
      return recieverName;
    }
  };
  const navigateSenderPage = (senderType: any, senderId: any, senderName: any) => {
    if (senderType == "dealer") {
      if (senderName) {
        return (
          <>
            {
              (isDealerLogin || isFranchise || isCustomer) ? senderName : <Link to={`${ROUTER.DEALERS_VIEW}/${senderId}`}> {senderName}</Link>
            }
          </>
        );
      } else {
        return "";
      }
    } else if (senderType == "customer") {
      if (senderName) {
        return (
          <>
            {
              (isDealerLogin || isFranchise || isCustomer) ? senderName : <Link to={`${ROUTER.CUSTOMERS_VIEW}/${senderId}`}> {senderName}</Link>
            }
          </>
        );
      } else {
        return "";
      }
    } else if (senderType == "franchise") {
      if (senderName) {
        return (
          <>
            {
              (isDealerLogin || isFranchise || isCustomer) ? senderName : <Link to={`${ROUTER.FRANCHISEE_VIEW}/${senderId}`}> {senderName}</Link>
            }
          </>
        );
      } else {
        return "";
      }
    } else {
      return (senderName);
    }
  };

  const CourierDetails = {
    generalDetails: [
      {
        name: "Company",
        value: courierInformation?.company ? courierInformation?.company : "-"
      },
      {
        name: "Other Company",
        value: courierInformation?.otherCompany
      },
      {
        name: "Package Sender",
        value: courierInformation?.packageSenderName ? navigateSenderPage(courierInformation?.shipperUserType, courierInformation?.packageSenderId, courierInformation?.packageSenderName) : "-"
      },
      {
        name: "Package Receiver",
        value: courierInformation?.packageReceiverName ? navigateCorrespondedPage(courierInformation?.consigneeUserType, courierInformation?.packageReceiverId, courierInformation?.packageReceiverName) : "-"
      },
      {
        name: "Pickup Request At",
        // value: formatDateTimeMEL(courierInformation?.pickupRequestAt)
        value: formatDateMEL(courierInformation?.pickupRequestAt)
      },
      {
        name: "Package Sent At",
        // value: formatDateTimeMEL(courierInformation?.packageSentAt)
        value: formatDateMEL(courierInformation?.packageSentAt)
      },
      {
        name: "Credit Reference Number",
        value: courierInformation?.creditReferenceNumber
      },
      {
        name: "Product Code",
        value: courierInformation?.productCode
      },
      {
        name: "Shipment Weight",
        value: courierInformation?.shipmentWeight
      },
      {
        name: "Declared Value",
        value: courierInformation?.declaredValue
      },
      {
        name: "Commodity",
        value: courierInformation?.commodity
      },
      {
        name: "Special Instruction",
        value: courierInformation?.specialInstruction
      },
      {
        name: "Is To Pay Customer",
        value: statusBodyTemplate(courierInformation?.isToPayCustomer)
      },
      {
        name: "Awb Number",
        value: courierInformation?.awbNumber
      },
      {
        name: "Token Number",
        value: courierInformation?.tokenNumber
      },
      {
        name: "Sender City",
        value: courierInformation?.shipperCity
      },
      {
        name: "Sender State",
        value: courierInformation?.shipperState
      },
      {
        name: "Receiver City",
        value: courierInformation?.consigneeCity
      },
      {
        name: "Reciever State",
        value: courierInformation?.consigneeState
      },
    ]
  };
  const courierDetailFiltered = (values: any) => {
    return values.filter((value: any) => value.isDeleted !== true);
  };

  const formvalue = courierInformation?.courierPackageDetails ? courierDetailFiltered(courierInformation?.courierPackageDetails) : [];

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "50px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };


  const columns: Columns[] = [
    {
      field: "length",
      sortable: false,
      header: "Length",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
      body: (rowData: any) => headerTemplate(rowData.length),
    },
    {
      field: "breadth",
      sortable: false,
      header: "Breadth",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      body: (rowData: any) => headerTemplate(rowData.breadth),
      isStatic: true,
    },
    {
      field: "height",
      sortable: false,
      header: "Height",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => headerTemplate(rowData.height),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "weight",
      sortable: false,
      header: "Weight",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => headerTemplate(rowData.weight),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "numberOfPkgs",
      sortable: false,
      header: "No of pkg",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
      body: (rowData: any) => headerTemplate(rowData.numberOfPkgs)
    },
  ];

  const editCourier = () => {
    navigate(`${ROUTER.EDIT_COURIER}/${id}`);
  };

  const newCourier = () => {
    navigate(`${ROUTER.CREATE_COURIER}`);
  };
  const printCourier = () => {
    dispatch(donloadPdfCourierPageLoading(id));
  };

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearCourierMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  return (
    <React.Fragment>
      <div className='userpage'>
        {visible && dialogContainer(error)}
        <Container fluid>
          <BreadcrumbWithFilter
            title="Courier" titlePath={ROUTER.COURIER_LIST} breadcrumbItem='Courier Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='New Courier'
            onFirstButtonClick={newCourier}
            isShowSecondButton={courierInformation?.packageSentAt ? false : true}
            onSecondButtonClick={editCourier}
            secondButtonLabel='Edit Courier'
            isShowThirdButton={(courierInformation?.company == blueDartCompany || courierInformation?.company == smartrCompany || courierInformation?.company == blueDartEComCompany) ? true : false}
            thirdButtonLabel='Print courier'
            onThirdButtonClick={printCourier}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Courier Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    CourierDetails.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </Row>

          {formvalue?.length > 0 &&
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Courier Packages</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={columns}
                        value={formvalue}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          }

          {
            (courierInformation?.company == smartrCompany || courierInformation?.company == blueDartCompany || courierInformation?.company == blueDartEComCompany) && <Row>
              <CourierTimeLine courierTrackingStatus={courierTrackingStatus} />
            </Row>
          }
        </Container>
      </div >
    </React.Fragment >
  );
};

export default ViewCourier;