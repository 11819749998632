import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FloatInput from 'src/components/Common/FloatInput';
import { CHECK_VALUE_IN_ARRAY } from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { courierDropdownAllDataForJobLoading } from 'src/store/actions';
import TextEditor from 'src/components/atoms/TextEditor';

interface TabProps {
    activeTab: string | number;
    status: string;
    handleChange?: any;
    onBlur?: () => void;
    values: any;
    touch: any;
    error: any;
    validationConfiguration?: any;
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    accordion?: any;
    changeAccorditionHandler?: any;
    changeFlow: any;
}

const OutgoingCourier = (props: TabProps) => {

    const { employeeList, courierList, courierAllList } = useSelector((state: RootReducerState) => state.jobsheetReducer);
    const dispatch = useDispatch();

    const courierDropList = courierAllList?.length > 0 ? (courierAllList.map((eachCourier: any) => {
        let data: any = {};
        data['value'] = eachCourier.id;
        data['label'] = eachCourier.companyName == "Other" ? `${eachCourier.otherCompany} ${eachCourier?.packageReceiverName ? `courier :(${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}` : `${eachCourier.companyName} ${eachCourier?.packageReceiverName ? `courier : (${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}`;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : [];

    // const courierDropList = Array.from({ length: 100000 }).map((_, i) => ({ label: `Item #${i}`, value: i }));


    const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
        let data: any = {};
        data['value'] = eachEmployee.userId;
        data['label'] = eachEmployee.name;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : [];

    useEffect(() => {
        dispatch(courierDropdownAllDataForJobLoading());
    }, []);

    const deliveryType = [
        { "value": "Hand Carry", "label": "Hand Carry" },
        { "value": "Courier", "label": "Courier" },
    ];

    return (
        <div className={`job-sheet-tab-content  ${props.activeTab === "Outgoing Courier" ? "" : "d-none"}`} >
            <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
                <div className='dealer-title-header font-size-15 border-bottom'>
                    <span className='pull-left'>Outgoing Dispatch</span>
                </div>
                <CardBody className='p-0'>
                    <div className='px-3 pb-3'>
                        <Row className={''} id="customer-add-panel">
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Packed by"
                                    type="text"
                                    name="packedBy"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.packedBy}
                                    invalid={props.touch["packedBy"] && props.error["packedBy"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "packedBy")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "packedBy")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Packed date"
                                    type="date"
                                    name="packedDate"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.packedDate}
                                    invalid={props.touch["packedDate"] && props.error["packedDate"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "packedDate")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "packedDate")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Delivery date"
                                    type="datetime"
                                    name="deliveryDate"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.deliveryDate}
                                    invalid={props.touch["deliveryDate"] && props.error["deliveryDate"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "deliveryDate")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "deliveryDate")}
                                    // today={true}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    type="dropdown"
                                    name="deliveryType"
                                    label="Delivery type"
                                    options={deliveryType}
                                    onChange={(e: any) => {
                                        debugger;
                                        props.values['deliveryType'] = e.target.value;
                                        props.changeFlow(props.values, props.values?.status, props.setFieldError, props.setFieldTouched, props.setFieldValue);
                                        props.handleChange(e);

                                    }}
                                    onBlur={props.onBlur}
                                    value={props.values.deliveryType}
                                    invalid={props.touch["deliveryType"] && props.error["deliveryType"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "deliveryType")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "deliveryType")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Delivered to"
                                    type="text"
                                    name="deliveredTo"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.deliveredTo}
                                    invalid={props.touch["deliveredTo"] && props.error["deliveredTo"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "deliveredTo")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "deliveredTo")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    name="deliveredById"
                                    label="Delivered by"
                                    options={employeeDropList}
                                    type="dropdown"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.deliveredById}
                                    invalid={props.touch["deliveredById"] && props.error["deliveredById"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "deliveredById")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "deliveredById")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Approximate value"
                                    type="number"
                                    name="approximateValue"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.approximateValue}
                                    invalid={props.touch["approximateValue"] && props.error["approximateValue"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "approximateValue")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approximateValue")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    name="courierId"
                                    label="Courier"
                                    options={courierDropList}
                                    type="dropdown"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.courierId}
                                    invalid={props.touch["courierId"] && props.error["courierId"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "courierId")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "courierId")}
                                />
                            </Col>
                            <Col lg={6} md={6} className="mt-mb-textarea">
                                <div className="d-flex align-items-start">
                                    <div className="d-block  w-100">
                                        {/* <FloatInput
                                            label="Notes"
                                            type="textarea"
                                            name="notes"
                                            onChange={props.handleChange}
                                            onBlur={props.onBlur}
                                            value={props.values.notes}
                                            invalid={props.touch["notes"] && props.error["notes"] ? true : false}
                                            required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "notes")}
                                            disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "notes")}
                                        /> */}

                                        <TextEditor
                                            onChange={props.handleChange}
                                            labelName='Notes'
                                            name='notes'
                                            required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "notes") ? true : false}
                                            value={props.values.notes}
                                            invalid={props.touch["notes"] && props.error["notes"] ? true : false}
                                            setFieldTouched={props.setFieldTouched}
                                            setFieldValue={props.setFieldValue}
                                            onBlur={props.onBlur}
                                            readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "notes") ? true : false}
                                        />
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default OutgoingCourier;