import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import {
  ClearPartsInboundMessage,
  PartsInboundReset,
  createPartsInbound,
  getPartsDropdown,
  getPartsInboundById,
  getPurchaseOrderDropdown,
  getSupplierDropdown,
  updatePartsInbound,
} from "src/store/partsInbound/action";
import { currency } from "src/constants/purchaseOrderFormFields";
import DropdownInput from "src/components/UI/DropdownInput";
import { formatDateTOYYYYMMDD } from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { RootReducerState } from "src/store/reducers";
import JobTooltipMEL from "src/components/UI/JobTooltip";

const AddorEditPartsInbound = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue = useSelector((state: any) => state.PartsInboundReducer.editInbound);
  const { error, loading, message } = useSelector((state: any) => state.PartsInboundReducer)
  const supplier = useSelector((state: RootReducerState) => state.PartsInboundReducer.supplierList);
  const stockLocation = useSelector((state: RootReducerState) => state.PartsInboundReducer.stockLocationList);
  const purchaseOrder = useSelector((state: RootReducerState) => state.PartsInboundReducer.purchaseOrderList);
  const parts = useSelector((state: RootReducerState) => state.PartsInboundReducer.partsList);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  useEffect(() => {
    dispatch(getSupplierDropdown());
    // dispatch(getStockLocationDropdown());
    dispatch(getPurchaseOrderDropdown());
    dispatch(getPartsDropdown());

  }, [])

  const post = (formValue: any) => {
    dispatch(createPartsInbound(formValue));
  };

  const update = (formValue: any) => {
    dispatch(updatePartsInbound(formValue));
  };

  const supplierdropdownOptions = supplier?.map((item: any) => ({
    label: item.name,
    value: item.id
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
  const stockLocationdropdownOptions = stockLocation?.map((item: any) => ({
    label: item.locationName,
    value: item.id
  }));
  const purchaseOrderdropdownOptions = purchaseOrder?.map((item: any) => ({
    label: item.poNumber,
    value: item.id,
    supplierId: item.supplierId
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });


  const partsdropdownOptions = parts?.map((item: any) => ({
    label: item.partNumber,
    value: item.id
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });



  const initialvalue = {
    receivedDate: formValue?.["receivedDate"] ? formatDateTOYYYYMMDD(formValue?.['receivedDate']) : "",
    shippingDocumentDate: formValue?.["shippingDocumentDate"] ? formatDateTOYYYYMMDD(formValue?.['shippingDocumentDate']) : "",
    invoiceNumber: formValue?.["invoiceNumber"] ? formValue?.["invoiceNumber"] : "",
    airwayBillNumber: formValue?.["airwayBillNumber"] ? formValue?.["airwayBillNumber"] : "",
    courierName: formValue?.["courierName"] ? formValue?.["courierName"] : "",
    buyingCurrency: formValue?.["buyingCurrency"] ? formValue?.["buyingCurrency"] : "INR",
    currencyConversionRate: formValue?.["currencyConversionRate"] ? formValue?.["currencyConversionRate"] : 1,
    purchaseOrderId: formValue?.["purchaseOrderId"] ? formValue?.["purchaseOrderId"] : "",
    supplierId: formValue?.["supplierId"] ? formValue?.["supplierId"] : "",
    // stockLocationId:formValue?.["stockLocationId"] ? formValue?.["stockLocationId"] : "",
    inBoundDetails: formValue?.partsInboundDetailDtos?.length > 0 ? formValue?.partsInboundDetailDtos.filter((eachOrder: any) => !eachOrder.isDeleted).map((eachOrder: any) => {
      let data: any = {};
      data['id'] = eachOrder.id ? eachOrder.id : 0;
      data['partId'] = eachOrder.partId ? eachOrder.partId : "";
      data['quantity'] = eachOrder.quantity ? eachOrder.quantity : 0;
      data['buyingPrice'] = eachOrder.buyingPrice ? eachOrder.buyingPrice : 0;
      data['profitPercentage'] = eachOrder.profitPercentage ? eachOrder.profitPercentage : 0;
      data['sellingPrice'] = eachOrder.sellingPrice ? eachOrder.sellingPrice : 0;
      data["partInBoundId"] = eachOrder.partInBoundId ? eachOrder.partInBoundId : 0;
      data['isSkuGenerated'] = eachOrder?.isLabelGenerated ? eachOrder?.isLabelGenerated : false;
      return data;
    }) : [
      {
        partId: "",
        quantity: "",
        buyingPrice: "",
        partInBoundId: 0,
        profitPercentage: "",
        sellingPrice: "",
      }
    ],
  };



  const handleSubmit = async (values: any) => {
    
    let data = values.inBoundDetails.map((data: any) => {
      let object = {
        partInBoundId: 0,
        stockLocationId: 0,
        ...data
      }
      return object
    })
    let ccr= values.currencyConversionRate=="" ? 0 : values.currencyConversionRate
    if (isSubmitting) return
    if (id) {

      await update({ ...values,currencyConversionRate:ccr, labelGeneratedStatus: formValue?.labelGeneratedStatus, stockLocationId: formValue?.labelGeneratedStatus, id: id });
    } else {
      await post({ ...values,currencyConversionRate:ccr, labelGeneratedStatus: 1, stockLocationId: 0 });


    }

    setIsSubmitting(true)
  };
  const validationSchema : any = Yup.object().shape({
    receivedDate: Yup.string().required('Received Date is required'),
    shippingDocumentDate: Yup.string().required('Shipping Date is required').test('shippingDate', 'Shipping Date should not be greater than Received Date', function (value) {
      const receivedDate = this.parent.receivedDate;
      if (receivedDate && value) {
        return new Date(value) <= new Date(receivedDate);
      }
      return true;
    }),
    invoiceNumber: Yup.string().required('Invoice Number is required'),
    airwayBillNumber: Yup.string().required('Airway Bill Number is required'),
    courierName: Yup.string().required('Courier Name is required'),
    buyingCurrency: Yup.string().required('Buying Currency is required'),
    currencyConversionRate: Yup.string().test(
      'is-required-if-not-inr',
      'Currency Conversion Rate is required if Buying Currency is not INR',
      function (value) {
        const { buyingCurrency } = this.parent;
        if (buyingCurrency && buyingCurrency.toLowerCase() !== 'inr') {
          return !!value;
        }
        return true;
      }
    ),
    purchaseOrderId: Yup.string().required('Purchase Order is required'),
    supplierId: Yup.string().required('Supplier is required'),
    // stockLocationId:Yup.string().required('Stock Location is required'),
    inBoundDetails: Yup.array().of(
      Yup.object().shape({
        partId: Yup.string().required('Part is required'),
        quantity: Yup.number().required('Quantity is required').test('check quantity count', 'Quantity must be greater than 0', (value) => {
          if (Number(value) > 0) {
            return true
          } else {
            return false
          }
        }),
        // buyingPrice: Yup.string().matches(/^\d{1,7}$/, 'Buying Price must be at most a 7-digit number').required('Buying Price is required'),
        buyingPrice: Yup.string()
          .matches(/^\d{1,5}(\.\d{1,5})?$/, 'Buying Price must be at most a 5-digit number')
          .required('Buying Price is required').test('max-value', 'Buying Price must not exceed 90000', (value) => {
            return parseFloat(value) <= 90000;
          }),
        profitPercentage: Yup.string().required('Profit Percentage is required'),
        sellingPrice: Yup.string().required('Selling Price is required').test('max-value', 'Selling   price must not exceed 90000', (value) => {
          return parseFloat(value) <= 90000;
        }),
      })
    ),
  });

  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.PARTS_INBOUND_GRID)
    }
  }

  const hidePopup = () => {
    setVisible(false);
    dispatch(ClearPartsInboundMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleAddContactDetails = (values: any, push: any, formik: any, setFieldTouched: any) => {
    const isValid = values.inBoundDetails.every((each: any) => each[Object.keys(each)[0]]);
    if (!isValid) {
      // formik.validateForm(values.inBoundDetails)      
      values.inBoundDetails?.forEach((_: any, index: number) => {
        setFieldTouched(`inBoundDetails[${index}].partId`, true);
        setFieldTouched(`inBoundDetails[${index}].quantity`, true);
        setFieldTouched(`inBoundDetails[${index}].buyingPrice`, true);
        setFieldTouched(`inBoundDetails[${index}].profitPercentage`, true);
        setFieldTouched(`inBoundDetails[${index}].sellingPrice`, true);
      })
      return
    }
    if (isContactDetailFilled(values)) {
      push({
        partId: "",
        quantity: null,
        buyingPrice: null,
        profitPercentage: null,
        sellingPrice: null
      });
    }
  };
  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.parts || [];
    return contactDetails.every((contact: any) => {
      const { buyingPrice, stockQuantity, reorderLevel, priceHistory, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  const calculateSellingPrice = (event: any, buyingPrice: any, profitPercentage: any, index: number = 0, setFieldValue: any, value: any) => {
    debugger
    const conversionRate =value.currencyConversionRate
    if (buyingPrice && profitPercentage) {
      const profitAmount = (buyingPrice * profitPercentage) / 100;
      setFieldValue(`inBoundDetails.${index}.sellingPrice`, ((buyingPrice + profitAmount) * conversionRate));
      return event;
    } else if (buyingPrice && profitPercentage == 0) {
      setFieldValue(`inBoundDetails.${index}.sellingPrice`, (buyingPrice * conversionRate));
      return event;
    }
    else {
      return null;
    }
  };



  const changeSellingPrice = (event: any, value: any, setFieldValue: any) => {
    debugger
    // buyingPrice,
    // profitPercentage,
    const currencyrate = value.currencyConversionRate;
    value?.inBoundDetails.length > 0 && value?.inBoundDetails.forEach((element: any, index: number) => {
      const profitAmount = ((element.buyingPrice * element.profitPercentage) / 100) + element.buyingPrice;
      setFieldValue(`inBoundDetails.${index}.sellingPrice`, (profitAmount * currencyrate))
      return event
    });

  };


  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false)
    }

    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.PARTS_INBOUND_GRID);
      }, 500)
      dispatch(PartsInboundReset())
    }
  }, [error, message])

  useEffect(() => {
    if (id) {
      dispatch(getPartsInboundById(id));
    }
    return () => {
      dispatch(PartsInboundReset())
    }
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }

  }, [loading]);


  const generateSkuHandler = (partID: number) => {
    navigate(`${ROUTER.PARTS_SKU_GENERATOR}/${id}/${partID}`)
  }

  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };

  const assignSupplier = (setFieldValue: any, currentPurchaseOrder: any) => {
    const getSupplierID = purchaseOrderdropdownOptions?.filter((data: any) => data.value == currentPurchaseOrder)
    const selectedSupplier = supplierdropdownOptions?.filter((data: any) => data.value === getSupplierID[0]?.supplierId)
    setFieldValue("supplierId", selectedSupplier[0]?.value)
  }

  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      <DeleteModal
        show={deleteModal || false}
        showIcon={false}
        message="Are you sure want to delete this contact"
        onDeleteClick={() => {
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Parts Inbound"
            titlePath={ROUTER.PARTS_INBOUND_GRID}
            breadcrumbItem={id ? "Update Parts Inbound" : "Create Parts Inbound"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      formik,
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldValue,
                      setFieldTouched,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Shipping Document Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="shippingDocumentDate"
                                onBlur={handleBlur}
                                value={values.shippingDocumentDate}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["shippingDocumentDate"] && errors["shippingDocumentDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Received Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="receivedDate"
                                onBlur={handleBlur}
                                value={values.receivedDate}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["receivedDate"] && errors["receivedDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Invoice Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="invoiceNumber"
                                onBlur={handleBlur}
                                value={values.invoiceNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["invoiceNumber"] &&
                                    errors["invoiceNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Airway Bill Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="airwayBillNumber"
                                onBlur={handleBlur}
                                value={values.airwayBillNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["airwayBillNumber"] && errors["airwayBillNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Courier Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="courierName"
                                onBlur={handleBlur}
                                value={values.courierName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["courierName"] && errors["courierName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={currency}
                                name="buyingCurrency"
                                label="Buying Currency"
                                // onChange={handleChange}
                                onChange={(e)=>{
                                  handleChange(e)
                                  if(e.value == "INR"){
                                    setFieldValue("currencyConversionRate",1)
                                  }else{
                                    setFieldValue("currencyConversionRate","")
                                  }
                                }}
                                required={true}
                                onBlur={handleBlur}
                                value={values.buyingCurrency}
                                invalid={
                                  touched["buyingCurrency"] && errors["buyingCurrency"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Currency Conversion Rate"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="currencyConversionRate"
                                onBlur={handleBlur}
                                disabled={values.buyingCurrency=="INR" ? true : false}
                                value={values.currencyConversionRate}
                                onKeyUp={(event) => changeSellingPrice(event, values, setFieldValue)}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["currencyConversionRate"] && errors["currencyConversionRate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={purchaseOrderdropdownOptions}
                                name="purchaseOrderId"
                                label="Purchase Order"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  assignSupplier(setFieldValue, e.target.value)
                                  handleChange(e);

                                }}
                                onBlur={handleBlur}
                                required={true}
                                value={values.purchaseOrderId}
                                invalid={
                                  touched["purchaseOrderId"] && errors["purchaseOrderId"]
                                    ? true
                                    : false
                                } />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={supplierdropdownOptions}
                                name="supplierId"
                                label="Supplier"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.supplierId}
                                invalid={
                                  touched["supplierId"] && errors["supplierId"]
                                    ? true
                                    : false
                                } />
                            </Col>

                          </Row>
                          {/* dynamic field */}
                          <Row>
                            <hr />
                            <Col lg={12} className="dealer-title-header">
                              <div>Add Parts</div>
                            </Col>
                            <FieldArray name="inBoundDetails">
                              {({ insert, remove, push }) => (
                                <div >
                                  {values.inBoundDetails?.length > 0 && values.inBoundDetails.map((value: any, index: any) => {
                                    const isLastContact = index === values.inBoundDetails.length - 1;
                                    const isFirstContact = index === 0;
                                    return <div key={index + value.partId}>
                                      <Row >
                                        <Col lg={2}>
                                          <DropdownInput
                                            options={partsdropdownOptions}
                                            name={`inBoundDetails.${index}.partId`}
                                            label="Part"
                                            labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                            inputClassName={` ${index == 0 ? "" : "custom_select"}`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            value={value.partId}
                                            // invalid={
                                            //   touched["partId"] && errors["partId"]
                                            //     ? true
                                            //     : false} 
                                            invalid={touched.inBoundDetails?.[index]?.partId && errors.inBoundDetails?.[index]?.partId ? true : false}
                                            disabled={values.inBoundDetails[index].isSkuGenerated}
                                          />
                                        </Col>
                                        <Col lg={2}>
                                          <InputCom
                                            labelName="Quantity"
                                            inputclassName="form-control"
                                            labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                            type="number"
                                            name={`inBoundDetails.${index}.quantity`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isRequired={true}
                                            min={0}
                                            onKeyDown={preventSpecialChar}
                                            value={value.quantity}
                                            invalid={
                                              touched.inBoundDetails?.[
                                                index
                                              ]?.quantity &&
                                                errors.inBoundDetails?.[index]
                                                  ?.quantity
                                                ? true
                                                : false
                                            }
                                            disabled={values.inBoundDetails[index].isSkuGenerated}
                                          />
                                        </Col>
                                        <Col lg={2}>
                                          <InputCom
                                            labelName="Buying Price"
                                            inputclassName="form-control"
                                            labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                            type="number"
                                            name={`inBoundDetails.${index}.buyingPrice`}
                                            onBlur={handleBlur}
                                            // onChange={handleChange}
                                            onChange={() => { handleChange }}
                                            onKeyUp={(event) => calculateSellingPrice(event, value.buyingPrice, value.profitPercentage, index, setFieldValue, values)}
                                            isRequired={true}
                                            min={0}
                                            value={value.buyingPrice}
                                            invalid={
                                              touched.inBoundDetails?.[
                                                index
                                              ]?.buyingPrice &&
                                                errors.inBoundDetails?.[index]
                                                  ?.buyingPrice
                                                ? true
                                                : false
                                            }
                                            disabled={values.inBoundDetails[index].isSkuGenerated}
                                          />

                                        </Col>
                                        <Col lg={2} className="d-none">
                                          <InputCom
                                            labelName="Parts Inbound"
                                            inputclassName="form-control"
                                            labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                            type="number"
                                            name={`inBoundDetails.${index}.partInBoundId`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isRequired={true}
                                            value={value.partInBoundId}
                                            invalid={
                                              touched.inBoundDetails?.[
                                                index
                                              ]?.partInBoundId &&
                                                errors.inBoundDetails?.[index]
                                                  ?.partInBoundId
                                                ? true
                                                : false
                                            }
                                            disabled={values.inBoundDetails[index].isSkuGenerated}
                                          />

                                        </Col>
                                        <Col lg={2}>
                                          <InputCom
                                            labelName="Profit Percentage"
                                            inputclassName="form-control"
                                            labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                            type="number"
                                            name={`inBoundDetails.${index}.profitPercentage`}
                                            onBlur={handleBlur}
                                            min={0}
                                            onKeyUp={(event) => calculateSellingPrice(event, value.buyingPrice, value.profitPercentage, index, setFieldValue, values)}
                                            onChange={handleChange}
                                            isRequired={true}
                                            value={value.profitPercentage}
                                            invalid={
                                              touched.inBoundDetails?.[index]?.profitPercentage &&
                                                errors.inBoundDetails?.[index]?.profitPercentage ? true : false
                                            }
                                            disabled={values.inBoundDetails[index].isSkuGenerated}
                                          />
                                        </Col>
                                        <Col lg={2} className="mb-2">
                                          <div className="d-flex align-items-end">
                                            <div className="d-block w-100">
                                              <InputCom
                                                labelName="Selling Price"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`inBoundDetails.${index}.sellingPrice`}
                                                onBlur={handleBlur}
                                                // onKeyUp={(event) => calculateSellingPrice(event, value.buyingPrice, value.profitPercentage, index, setFieldValue, value)}
                                                onChange={handleChange}
                                                isRequired={true}
                                                min={0}
                                                value={value.sellingPrice}
                                                // value={calculateSellingPrice(value.buyingPrice, value.profitPercentage,setFieldValue,index)}
                                                disabled={(values.inBoundDetails[index].profitPercentage ||values.inBoundDetails[index].isSkuGenerated )}
                                                invalid={
                                                  touched.inBoundDetails?.[index]?.sellingPrice && errors.inBoundDetails?.[index]?.sellingPrice
                                                    ? true : false
                                                }
                                              />
                                            </div>
                                            {!value.isSkuGenerated && value.id ?
                                              <div className="justify-content-center remove-tooltip-margin">
                                                <JobTooltipMEL title="Generate SKU" position="bottom">
                                                  {/* <i className="fas fa-barcode"> */}
                                                  <i className="fas fa-qrcode big-font pointer" onClick={() => generateSkuHandler(value.id)} style={{fontSize:"36px"}}>
                                                  {/* <Button
                                                          className="btn-success me-2"
                                                          label={"Generate SKU"}
                                                          type="button"
                                                          onClick={() => generateSkuHandler(value.id)}
                                                        /> */}
                                                  </i>
                                                </JobTooltipMEL>
                                                </div> : null}
                                            {(id ? (formValue?.labelGeneratedStatus == 1 ? true : false) : true) && <div className="justify-content-center remove-tooltip-margin">
                                              <JobTooltipMEL title="Remove part" position="bottom">
                                                {values.inBoundDetails?.length > 1 &&
                                                  <i className="fas fa-times-circle color-red pointer"
                                                    onClick={() => {
                                                      remove(index);
                                                    }
                                                    }>
                                                  </i>
                                                }
                                              </JobTooltipMEL>
                                            </div>}
                                          </div>
                                        </Col>
                                      </Row>
                                      {(id ? (formValue?.labelGeneratedStatus == 1 ? true : false) : true) && <div className="multiadd-container">
                                        {isLastContact &&
                                          <div className="add-btn-container" onClick={() => { handleAddContactDetails(values, push, formik, setFieldTouched); }}>
                                            <JobTooltipMEL title="Add Parts" position='bottom'>
                                              <i className="fas fa-plus-circle"></i><span>Add</span>
                                            </JobTooltipMEL>
                                          </div>
                                        }
                                      </div>}
                                    </div>
                                  }
                                  )}
                                  <hr className="mt-1" />
                                </div>
                              )}
                            </FieldArray>
                          </Row>
                          {(id ? (formValue?.labelGeneratedStatus == 1 ? true : false) : true) && <div className="d-flex justify-content-end mt-2" >
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Parts Inbound" : "Create Parts Inbound"}
                              type="submit"
                            />
                          </div>}
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddorEditPartsInbound;
