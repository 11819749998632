import React from "react"

interface LabelProps {
  label: string
  required?: boolean
  name: string
  id?: string
  className?:string;
}

const Label = (props: LabelProps) => {
  const { name, id, required = false,className, label } = props
  return (
    <label htmlFor={name || id} className={required ? `req form-label ${className}` : 'form-label'+ (className || '')}>{label}</label>
  )
};

export default Label;
