export enum SubUserType {

    //Grid
    GET_FRANCHISESUBUSER_LIST_LOADING = "@@subuser/GET_FRANCHISESUBUSER_LIST_LOADING",
    GET_FRANCHISESUBUSER_LIST_SUCCESS = "@@subuser/GET_FRANCHISESUBUSER_LIST_SUCCESS",
    GET_FRANCHISESUBUSER_LIST_ERROR = "@@subuser/GET_FRANCHISESUBUSER_LIST_ERROR",

    //Delete Grid
    DELETE_FRANCHISESUBUSER_LOADING = "@@subuser/DELETE_FRANCHISESUBUSER_LOADING",
    DELETE_FRANCHISESUBUSER_SUCCESS = "@@subuser/DELETE_FRANCHISESUBUSER_SUCCESS",
    DELETE_FRANCHISESUBUSER_ERROR = "@@subuser/DELETE_FRANCHISESUBUSER_ERROR",

    //reset franchisee data form
    CLEAR_FRANCHISESUBUSER_MESSAGE = "@@subuser/CLEAR_FRANCHISESUBUSER_MESSAGE",
    RESET_FRANCHISESUBUSER = "@@subuser/RESET_FRANCHISESUBUSER",

    //Create Franchisee
    CREATE_FRANCHISESUBUSER_LOADING = "@@subuser/CREATE_FRANCHISESUBUSER_LOADING",
    CREATE_FRANCHISESUBUSER_SUCCESS = "@@subuser/CREATE_FRANCHISESUBUSER_SUCCESS",
    CREATE_FRANCHISESUBUSER_ERROR = "@@subuser/CREATE_FRANCHISESUBUSER_ERROR",

    //Update Franchisee
    UPDATE_FRANCHISESUBUSER_LOADING = "@@subuser/UPDATE_FRANCHISESUBUSER_LOADING",
    UPDATE_FRANCHISESUBUSER_SUCCESS = "@@subuser/UPDATE_FRANCHISESUBUSER_SUCCESS",
    UPDATE_FRANCHISESUBUSER_ERROR = "@@subuser/UPDATE_FRANCHISESUBUSER_ERROR",

    //getFranchise details by id
    GET_FRANCHISESUBUSERBYID_LOADING = "@@subuser/GET_FRANCHISESUBUSERBYID_LOADING",
    GET_FRANCHISESUBUSERBYID_SUCCESS = "@@subuser/GET_FRANCHISESUBUSERBYID_SUCCESS",
    GET_FRANCHISESUBUSERID_ERROR = "@@subuser/GET_FRANCHISESUBUSERID_ERROR",

    
    GET_FRANCHISESUBUSER_PROFILE_LOADING = "@@subuser/GET_FRANCHISESUBUSER_PROFILE_LOADING",
    GET_FRANCHISESUBUSER_PROFILE_SUCCESS = "@@subuser/GET_FRANCHISESUBUSER_PROFILE_SUCCESS",
    GET_FRANCHISESUBUSER_PROFILE_ERROR = "@@subuser/GET_FRANCHISESUBUSER_PROFILE_ERROR",

    UPLOAD_FRANCHISESUBUSER_PROFILE_LOADING = "@@subuser/UPLOAD_FRANCHISESUBUSER_PROFILE_LOADING",
    UPLOAD_FRANCHISESUBUSER_PROFILE_SUCCESS = "@@subuser/UPLOAD_FRANCHISESUBUSER_PROFILE_SUCCESS",
    UPLOAD_FRANCHISESUBUSER_PROFILE_ERROR = "@@subuser/UPLOAD_FRANCHISESUBUSER_PROFILE_ERROR",

    DELETE_FRANCHISESUBUSER_PROFILE_LOADING = "@@subuser/DELETE_FRANCHISESUBUSER_PROFILE_LOADING",
    DELETE_FRANCHISESUBUSER_PROFILE_SUCCESS = "@@subuser/DELETE_FRANCHISESUBUSER_PROFILE_SUCCESS",
    DELETE_FRANCHISESUBUSER_PROFILE_ERROR = "@@subuser/DELETE_FRANCHISESUBUSER_PROFILE_ERROR",
    
    FRANCHISESUBUSER_SET_PHONE_LOADING="@@subuser/FRANCHISESUBUSER_SET_PHONE_LOADING",
    FRANCHISESUBUSER_SET_PHONE_SUCCESS="@@subuser/FRANCHISESUBUSER_SET_PHONE_SUCCESS",
    FRANCHISESUBUSER_SET_PHONE_ERROR="@@subuser/FRANCHISESUBUSER_SET_PHONE_ERROR",

    FRANCHISESUBUSER_SET_EMAIL_LOADING="@@subuser/FRANCHISESUBUSER_SET_EMAIL_LOADING",
    FRANCHISESUBUSER_SET_EMAIL_SUCCESS="@@subuser/FRANCHISESUBUSER_SET_EMAIL_SUCCESS",
    FRANCHISESUBUSER_SET_EMAIL_ERROR="@@subuser/FRANCHISESUBUSER_SET_EMAIL_ERROR",

    SEND_OTP_FRANCHISESUBUSER ="@@subuser/SEND_OTP_FRANCHISESUBUSER",
    SEND_OTP_FRANCHISESUBUSER_SUCCESS ="@@subuser/SEND_OTP_FRANCHISESUBUSER_SUCCESS",
    SEND_OTP_FRANCHISESUBUSER_ERROR ="@@subuser/SEND_OTP_FRANCHISESUBUSER_ERROR",

    RESEND_FRANCHISESUBUSER_OTP = "@@subuser/RESEND_FRANCHISESUBUSER_OTP",
    RESEND_FRANCHISESUBUSER_OTP_SUCCESS = "@@subuser/RESEND_FRANCHISESUBUSER_OTP_SUCCESS",
    RESEND_FRANCHISESUBUSER_OTP_ERROR = "@@subuser/RESEND_FRANCHISESUBUSER_OTP_ERROR",
    
    RESET_FRANCHISESUBUSER_OTP_MESSAGE = "@@subuser/RESET_FRANCHISESUBUSER_OTP_MESSAGE",

    GET_FRANCHISESUBUSER_LOCATION_LOADING="@@subuser/GET_FRANCHISESUBUSER_LOCATION_LOADING",
    GET_FRANCHISESUBUSER_LOCATION_SUCCESS="@@subuser/GET_FRANCHISESUBUSER_LOCATION_SUCCESS",
    GET_FRANCHISESUBUSER_LOCATION_ERROR="@@subuser/GET_FRANCHISESUBUSER_LOCATION_ERROR",
}