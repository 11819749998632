import {pendingPartsActions} from "./actionTypes" ;

export const getPendingPartsLoading=(lazyState :any)=>{
    return {
      type : pendingPartsActions.GET_PENDING_PARTS_LOADING,
      lazyState
    }
  }
  
  export const getPendingPartsSuccess=(resp : any)=>{
    return {
      type : pendingPartsActions.GET_PENDING_PARTS_SUCCESS,
      payload : resp
    }
  }
  
  export const getPendingPartsError=(error : any)=>{
    return {
      type : pendingPartsActions.GET_PENDING_PARTS_ERROR,
      payload : error
    }
  }