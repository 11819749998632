import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { RootReducerState } from 'src/store/reducers'
import "../../SubUserManagement/subUserstyle.scss"
import { StockAdjustmentReset, deleteStockAdjustmentFromList, downloadStockAdjustment, getInitiatedByDropdown, getStockAdjustmentById, getStockAdjustmentByPartId, getSupplierDropdown, resetStockAdjustemntMessage, sentMailToSupplierAdjustment } from 'src/store/stockAdjustment/actions'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { CurrencyFormatter, formatDateMEL } from 'src/helpers/common_helpers'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import { useToast } from "src/components/Common/ReactToaster";
import DeleteModal from "../../Calendar/DeleteModal";
import { Link } from 'react-router-dom'
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText'

const ViewStockAdjustment = () => {
  const { id } = useParams();
  const { sub_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const StockAdjustmentDetails = useSelector((state: any) => state.StockAdjustmentReducer?.editOrder?.stockAdjustmentDto);
  const stock_id = StockAdjustmentDetails?.id ? StockAdjustmentDetails?.id : "";
  const initiatedBy = useSelector((state: RootReducerState) => state.StockAdjustmentReducer?.initiatedBy);
  const supplier = useSelector((state: RootReducerState) => state.StockAdjustmentReducer?.supplierList);
  const [details, setDetails] = useState<any>([])
  const { error, message,loading } = useSelector(
    (state: any) => state.StockAdjustmentReducer
  );
  useEffect(() => {
    if (id) {
      dispatch(getStockAdjustmentById(id));
      dispatch(getInitiatedByDropdown());
      dispatch(getSupplierDropdown());
      
    }

    return ()=>{
      dispatch(StockAdjustmentReset())
    }
  }, [id])

  useEffect(() => {
    if (sub_id) {
      dispatch(getStockAdjustmentByPartId(sub_id));
      dispatch(getInitiatedByDropdown());
      dispatch(getSupplierDropdown());
      
    }

    return ()=>{
      dispatch(StockAdjustmentReset())
    }
  }, [sub_id])


  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }


  
  const [visible, setVisible] = useState(false);
  const { showToast } = useToast();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockAdjustemntMessage());
      if(message == "Stock Adjustment Deleted Successfully"){
        navigate(`${ROUTER.STOCK_ADJUSTMENT_GRID}`)
      }
    }
  }, [error, message]);

  const hidePopup = () => {
		setVisible(false);
    dispatch(resetStockAdjustemntMessage());
	};

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  const currencyBodyTemplate = (amount:any) => {
    return (<div className="">{CurrencyFormatter({ value: amount, currencyCode: "INR" })}</div>);
  };

  const initiatedObject = initiatedBy?.find((item: any) => item.id === StockAdjustmentDetails?.initiatedById);
  const initiatedByName = initiatedObject ? initiatedObject.name : null; 
  const supplierObject=supplier?.find((item:any)=>item.id==StockAdjustmentDetails?.supplierId);
  const supplierName=supplierObject ? supplierObject.name : null;

  const supplierBodyTemplate = (name:any) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${StockAdjustmentDetails?.supplierId}`}>{name}</Link>
    );
  };
  const courierBodyTemplate = (name:any) => {
    return (
      <Link to={`${ROUTER.VIEW_COURIER}/${StockAdjustmentDetails?.courierId}`}>{name}</Link>
    );
  };


  const userView = {
    generalDetails: [
      {
        name: "Number",
        value: StockAdjustmentDetails?.number
      },
      {
        name: "Generated Date",
        value: formatDateMEL(StockAdjustmentDetails?.creationTime)
      },
      {
        name: "Initiated By",
        value: initiatedByName
      },
      {
        name: "Supplier",
        value: supplierBodyTemplate(supplierName)
      },
      {
        name: "Status",
        value: StockAdjustmentDetails?.status
      },
      {
        name: "Part Rejected/Scrapped/Missing Date",
        value: formatDateMEL(StockAdjustmentDetails?.lockedDate)
      },
      {
        name: "Delivery Date",
        value: formatDateMEL(StockAdjustmentDetails?.deliveryDate)
      },
      
      {
        name: "Delivery Type",
        value: StockAdjustmentDetails?.deliveryType
      },      
      {
        name: "No Of Items",
        value: StockAdjustmentDetails?.noOfItems
      },
      
      {
        name: "Locked",
        value: statusBodyTemplate(StockAdjustmentDetails?.locked)
      },
      {
        name: "Total Gatepass Value",
        value:currencyBodyTemplate(StockAdjustmentDetails?.totalGatepassValue) 
      },
      {
        name: "Courier",
        value: courierBodyTemplate(StockAdjustmentDetails?.courierName)
      },

    ]
  }

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const editStockAdjustmentClickHandler = () => {
    let routerId = id ? id : (stock_id ? stock_id : "") 
    navigate(`${ROUTER.STOCK_ADJUSTMENT_EDIT}/${routerId}`);
  };

  

  const archiveDealerClickHandler = () => {

  };

  const percentageBodyTemplate = (rowData: any, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const createStockAdjustment = () => {
    navigate(`${ROUTER.STOCK_ADJUSTMENT_GRID}`)
  }

  const sendEmailStockAdjustment = () => {
    let routerId = id ? id : (stock_id ? stock_id : "") 
    dispatch(sentMailToSupplierAdjustment(routerId))
    // navigate(`${ROUTER.PURCHASE_ORDER_CREATE}`)
  }

  const printStockAdjustment = () => {
    let routerId = id ? id : (stock_id ? stock_id : "") 
    dispatch(downloadStockAdjustment(routerId))
  }

  const archiveStockAdjustment = ()=>{
    setDeleteModal(true)
  }

  return (
    <div className="pagescontent " id="view-dealer">

       {visible && dialogContainer(error)}
      <DeleteModal
          show={deleteModal}
          undo={false}
          onDeleteClick={() => {
            let routerId = id ? id : (stock_id ? stock_id : "") 
            let payload = {
              id: routerId,
            };
            dispatch(deleteStockAdjustmentFromList(payload));
            setDeleteModal(false);
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />

    <BreadcrumbWithFilter
       title="Stock Adjustment"
       titlePath={ROUTER.STOCK_ADJUSTMENT_GRID}
      breadcrumbItem="Stock Adjustment Details"
      isShowCreate={false}
      isShowFilter={false}
      filter={false}

      isShowFirstButton={StockAdjustmentDetails?.locked ? false : true}
      firstButtonLabel='Edit Stock Adjustment'
      onFirstButtonClick={editStockAdjustmentClickHandler}

      isShowSecondButton={StockAdjustmentDetails?.locked ? false : true}
      secondButtonLabel='Archive Stock Adjustment'
      onSecondButtonClick={archiveStockAdjustment}

      isShowThirdButton={StockAdjustmentDetails?.locked ? true : false}
      thirdButtonLabel='Email Rejection Order to Supplier'
      onThirdButtonClick={sendEmailStockAdjustment}

      isShowForthButton={StockAdjustmentDetails?.locked ? true : false}
      forthButtonLabel='Print Gatepass'
      onForthButtonClick={printStockAdjustment}
      forthButtonDisabled={false}

      isShowFifthButton = {true}
      fifthButtonLabel ='New Stock Adjustment'
      onFifthButtonClick={createStockAdjustment}

 

    />

    <Row>
    <div className="view">
            <div className="general-details">
              <b>Stock Adjustment Details</b>
            </div>
            <div className="subview">
            <div className="parentCls">
            {
              userView.generalDetails.map((data, index) => {
                return (
                  <div key={index} className={` pt-1 childCls`}>
                    <div>{data.name}</div>
                    <div>{data.value?data.value:<div className="none-text">-</div>}</div>
                  </div>
                )
              })
            }
          </div>
          <div className="parent-div mt-2">

          <div className='text-bold'>Comments :</div>
          <div>{<HtmltoPlainText htmlString={StockAdjustmentDetails?.comments} />}</div>
        </div>
          </div>
        </div>
    </Row>
    <Row>
    <div className="view">
            <div className="general-details">
              <b>Stock Adjustment Details</b>
            </div>
            <div className="subview">
            <Row>
            <Col  lg={12}>
              <div className="card ps-0 pe-0 " id="table-border">
                <DataTable size='large' value={StockAdjustmentDetails?.stockAdjustmentDetailsDtos} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                  <Column field="partSkuNo" header="Part SKU Number" className=''></Column>
                  <Column field="price" header="Price" className='align-right ' body={ (rowData) => currencyBodyTemplate(rowData['price'])}></Column>
                  <Column field="taxPercentage" header="Tax Percentage" className='align-right ' body = {(rowData) => percentageBodyTemplate(rowData, "taxPercentage")}  ></Column>
                  <Column field="totalPrice" header="Total Price" className='align-right ' body={ (rowData) => currencyBodyTemplate(rowData['totalPrice'])} ></Column>
                  <Column field="partNumber" header="Part Number" className=' '></Column>
                  <Column field="partDescription" header="Part Description" className=' ' ></Column>
                  <Column field="partHsnNumber" header="Part HSN Number" className='' ></Column>
                </DataTable>
              </div>
              </Col>
            </Row>
        </div>
        </div>
    </Row>

    
  </div>
  )
}

export default ViewStockAdjustment