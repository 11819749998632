
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const NEW_JOB="New Job";
export const AFCS_GENERATED="Afcs Generated";
export const EXCHANGE_GENERATED="Exchange Generated";
export const REPLACEMENT_SYSTEM_DISPATCHED="Replacement System Dispatched";
export const JOB_SHEET_CREATED="Job Sheet Created";
export const ASSIGNED_TO_ESTIMATOR="Assigned To Estimator";
export const JOB_ESTIMATED_AWAITING_APPROVAL="Job Estimated Awaiting Approval";
export const JOB_ASSIGNABLE_TO_TECHNICIAN="Job Assignable To Technician";
export const ASSIGNED_TO_TECHNICIAN="Assigned To Technician";
export const TECHNICIAN_COMPLETED_JOB="Technician Completed Job";
export const READY_TO_INVOICE="Ready To Invoice";
export const READY_FOR_INSPECTION="Ready For Inspection";
export const INVOICED_JOB="Invoiced Job";
export const FRANCHISE_JOB_CLOSED = "Franchise Job Closed";
export const JOB_CLOSED="Job Closed";
export const JOB_CANCELLED="Job Cancelled";
export const IN_PENDING="In Pending";
export const IS_EXCHANGE="Is Exchange";
export const FACULTY_SYATEM_AVAILABLE="Faulty System Available";
export const PERMIT_FORM_PENDING="Permit Form Pending";
export const FEEDBACK_PENDING="Feedback Pending";
export const ESTIMATION_REJECTED="Estimation Rejected";

export const SERVICE_REQUEST_GENERATED = "Service Request Generated"
export const IS_REMOTE_SERIVICE = "Is Remote Service"
