export enum FranchiseAddtionalDetails {
  //get profile details
  GET_CURRENT_FRANCHISE_ID_LOADING = "@@FranchiseAdditionalInformation/GET_CURRENT_FRANCHISE_ID_LOADING",
  GET_CURRENT_FRANCHISE_ID_SUCCESS = "@@FranchiseAdditionalInformation/GET_CURRENT_FRANCHISE_ID_SUCCESS",
  GET_CURRENT_FRANCHISE_ID_ERROR = "@@FranchiseAdditionalInformation/GET_CURRENT_FRANCHISE_ID_ERROR",

  //get additional details
  GET_FRANCHISE_ADDITIONAL_DETAILS_LOADING = "@@FranchiseAdditionalInformation/GET_FRANCHISE_ADDITIONAL_DETAILS_LOADING",
  GET_FRANCHISE_ADDITIONAL_DETAILS_SUCCESS = "@@FranchiseAdditionalInformation/GET_FRANCHISE_ADDITIONAL_DETAILS_SUCCESS",
  GET_FRANCHISE_ADDITIONAL_DETAILS_ERROR = "@@FranchiseAdditionalInformation/GET_FRANCHISE_ADDITIONAL_DETAILS_ERROR",

  GET_ADDITIONAL_DETAILS_RESET = "@@FranchiseAdditionalInformation/GET_ADDITIONAL_DETAILS_RESET"
}