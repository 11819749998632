import { TotalWorkingDayState } from "src/models/pages/totalWorkingDaysModel";
import { totalWorkingDaysTypes } from "./actionTypes";


const initialState: TotalWorkingDayState = {
  error: "",
  loading: false,
  message: "",
  totalCount:"",
 totalWorkingDaysList:[],
 totalWorkingDayDetails:"",
 months:[]
};



const totalWorkingDays = (state = initialState, action: any) => {
  switch (action.type) {

    case totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_LOADING:
    case totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_LOADING:
      return {
        ...state,
        loading: true,
        message:""
      };

    case totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalWorkingDaysList:action.payload.data.items,
        totalCount:action.payload.data.totalCount
      };
    case totalWorkingDaysTypes.GET_TOTAL_WORKING_DAY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        totalWorkingDayDetails:action.payload.data
      };

    case totalWorkingDaysTypes.GET_MONTHS_SUCCESS:
      return {
        ...state,
        loading: false,
        months:action.payload.data
      };

    
    case totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_SUCCESS:
        let message="Total Working Days updated Successfully"
        if(action.payload.create===true){
            message="Total Working Days created Successfully"
        }
      return {
        ...state,
        loading: false,
        message:message,
        error:""
      };
    case totalWorkingDaysTypes.CLEAR_TOTAL_WORKING_DAYS:
      return {
        ...state,
        loading: false,
        message:"",
        error:""
      };
    case totalWorkingDaysTypes.RESET_TOTAL_WORKING_DAYS:
      return {
        ...state,
        loading: false,
        message:"",
        error:"",
        totalWorkingDaysList:[],
        totalWorkingDayDetails:""
      };

    case totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_ERROR:
    case totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_ERROR:
      return {
        ...state,
        message: "",
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default totalWorkingDays;