import { attendance } from "src/models/pages/attendanceModel";
import { AttendanceTypes } from "./actionTypes";

// For Grid

export const getAttendanceList = (lazyState: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_LIST,
    lazyState,
  };
};

export const getAttendanceListSuccess = (attendance: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_LIST_SUCCESS,
    payload: attendance,
  };
};

export const getAttendanceListError = (error: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_LIST_FAIL,
    payload: error,
  };
};

// update attendance

export const updateAttendance = (attendance: attendance, isLogout: boolean = false) => {
  return { type: AttendanceTypes.UPDATE_ATTENDANCE, payload: { attendance, isLogout } };
};

export const updateAttendanceSuccess = (attendance: attendance) => {

  return { type: AttendanceTypes.UPDATE_ATTENDANCE_SUCCESS, payload: attendance };
};

export const updateAttendanceError = (error: any) => {
  return { type: AttendanceTypes.UPDATE_ATTENDANCE_FAIL, payload: error };
};

// post attendance
export const createAttendance = (attendance: attendance) => {
  return { type: AttendanceTypes.POST_ATTENDANCE, payload: attendance };
};

export const createAttendanceSuccess = (attendance: attendance) => {

  return { type: AttendanceTypes.POST_ATTENDANCE_SUCCESS, payload: attendance };
};

export const createAttendanceError = (error: any) => {
  return { type: AttendanceTypes.POST_ATTENDANCE_FAIL, payload: error };
};

// get single user attendance
export const getAttendanceBYIDList = (lazyState: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST,
    lazyState,
  };
};

export const getAttendanceBYIDListSuccess = (attendance: any) => {
  return { type: AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST_SUCCESS, payload: attendance };
};

export const getAttendanceBYIDListError = (error: any) => {
  return { type: AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST_FAIL, payload: error };
};

//Get current User state
export const getUserCurrentAttendance = (id: string) => {
  return { type: AttendanceTypes.GET_CURRENT_USER_ATTENDANCE, payload: id };
};

export const getUserCurrentAttendanceSuccess = (attendance: attendance) => {
  return { type: AttendanceTypes.GET_CURRENT_USER_ATTENDANCE_SUCCESS, payload: attendance };
};

export const getUserCurrentAttendanceError = (error: any) => {
  return { type: AttendanceTypes.GET_CURRENT_USER_ATTENDANCE_FAIL, payload: error };
};

export const clearAttendanceMessage = () => {
  return { type: AttendanceTypes.CLEAR_ATTENDANCE_MESSAGE };
}

//Get the Previous Working Hours
export const getUserWorkingHours = () => {
  return { type: AttendanceTypes.GET_USER_WORKING_HOURS };
};

export const getUserWorkingHoursSuccess = (attendance: attendance) => {
  return { type: AttendanceTypes.GET_USER_WORKING_HOURS_SUCCESS, payload: attendance };
};

export const getUserWorkingHoursError = (error: any) => {
  return { type: AttendanceTypes.GET_USER_WORKING_HOURS_FAIL, payload: error };
};

export const reloadUserWorkingHours = (canReload: boolean = false) => {
  return { type: AttendanceTypes.RELOAD_USER_WORKING_HOURS, payload: canReload }
}

export const getAttendanceDetailById = (userDetails: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID,
    payload: userDetails
  };
};

export const getAttendanceDetailByIdSuccess = (attendance: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID_SUCCESS,
    payload: attendance
  };
};

export const getAttendanceDetailByIdError = (error: any) => {
  return {
    type: AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID_FAIL,
    payload: error
  };
};


//reset induvidula attendance

export const resetInduvidualAttendance=()=>{
  return {
    type : AttendanceTypes.GET_RESET_INDUVIDUAL_ATTENDANCE_DETAILS
  }
}