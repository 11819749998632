export enum SupplierTypes {

    // Grid
    GET_SUPPLIER_LIST = "@@supplier/GET_SUPPLIER_LIST",
    GET_SUPPLIER_LIST_SUCCESS = "@@supplier/GET_SUPPLIER_LIST_SUCCESS",
    GET_SUPPLIER_LIST_FAIL = "@@supplier/GET_SUPPLIER_LIST_FAIL",
  
    DELETE_SUPPLIER_BRANDS = "@@supplier/DELETE_SUPPLIER_BRANDS",
    DELETE_SUPPLIER_BRANDS_SUCCESS = "@@supplier/DELETE_SUPPLIER_BRANDS_SUCCESS",
    DELETE_SUPPLIER_BRANDS_FAIL = "@@supplier/DELETE_SUPPLIER_BRANDS_FAIL",

    // //reset
    RESET_SUPPLIER = "@@supplier/RESET_SUPPLIER",

    CLEAR_SUPPLIER_MESSAGE = "@@supplier/CLEAR_SUPPLIER_MESSAGE",

    // //create user
    // CREATE_USER_LOADING = "CREATE_USER_LOADING",
    // CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
    // CREATE_USER_ERROR = "CREATE_USER_ERROR",
  
    // //update user
    // UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
    // UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    // UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

    UPDATE_SUPPLIER = "@@supplier/UPDATE_SUPPLIER",
    UPDATE_SUPPLIER_SUCCESS = "@@supplier/UPDATE_SUPPLIER_SUCCESS",
    UPDATE_SUPPLIER_FAIL = "@@supplier/UPDATE_SUPPLIER_FAIL",

    // POST

    POST_SUPPLIER = "@@supplier/POST_SUPPLIER",
    POST_SUPPLIER_SUCCESS = "@@supplier/POST_SUPPLIER_SUCCESS",
    POST_SUPPLIER_FAIL = "@@supplier/POST_SUPPLIER_FAIL",

    POST_SUPPLIER_CONTACT = "@@supplier/POST_SUPPLIER_CONTACT",
    POST_SUPPLIER_CONTACT_SUCCESS = "@@supplier/POST_SUPPLIER_CONTACT_SUCCESS",
    POST_SUPPLIER_CONTACT_FAIL = "@@supplier/POST_SUPPLIER_CONTACT_FAIL",

    POST_SUPPLIER_DOCUMENT = "@@supplier/POST_SUPPLIER_DOCUMENT",
    POST_SUPPLIER_DOCUMENT_SUCCESS = "@@supplier/POST_SUPPLIER_DOCUMENT_SUCCESS",
    POST_SUPPLIER_DOCUMENT_FAIL = "@@supplier/POST_SUPPLIER_DOCUMENT_FAIL",

    GET_SUPPLIER_DOCUMENT = "@@supplier/GET_SUPPLIER_DOCUMENT",
    GET_SUPPLIER_DOCUMENT_SUCCESS = "@@supplier/GET_SUPPLIER_DOCUMENT_SUCCESS",
    GET_SUPPLIER_DOCUMENT_FAIL = "@@supplier/GET_SUPPLIER_DOCUMENT_FAIL",

    GET_SUPPLIER_DOCUMENT_LINK = "@@supplier/GET_SUPPLIER_DOCUMENT_LINK",
    GET_SUPPLIER_DOCUMENT_LINK_SUCCESS = "@@supplier/GET_SUPPLIER_DOCUMENT_LINK_SUCCESS",
    GET_SUPPLIER_DOCUMENT_LINK_FAIL = "@@supplier/GET_SUPPLIER_DOCUMENT_LINK_FAIL",

    DELETE_SUPPLIER_DOCUMENT = "@@supplier/DELETE_SUPPLIER_DOCUMENT",
    DELETE_SUPPLIER_DOCUMENT_SUCCESS = "@@supplier/DELETE_SUPPLIER_DOCUMENT_SUCCESS",
    DELETE_SUPPLIER_DOCUMENT_FAIL = "@@supplier/DELETE_SUPPLIER_DOCUMENT_FAIL",

    GET_SUPPLIER_CONTACT = "@@supplier/GET_SUPPLIER_CONTACT",
    GET_SUPPLIER_CONTACT_SUCCESS = "@@supplier/GET_SUPPLIER_CONTACT_SUCCESS",
    GET_SUPPLIER_CONTACT_FAIL = "@@supplier/GET_SUPPLIER_CONTACT_FAIL",

    DELETE_SUPPLIER_CONTACT = "@@supplier/DELETE_SUPPLIER_CONTACT",
    DELETE_SUPPLIER_CONTACT_SUCCESS = "@@supplier/DELETE_SUPPLIER_CONTACT_SUCCESS",
    DELETE_SUPPLIER_CONTACT_FAIL = "@@supplier/DELETE_SUPPLIER_CONTACT_FAIL",

    // GET

    GET_SUPPLIER_BY_ID = "@@supplier/GET_SUPPLIER_BY_ID",
    GET_SUPPLIER_BY_ID_SUCCESS = "@@supplier/GET_SUPPLIER_BY_ID_SUCCESS",
    GET_SUPPLIER_BY_ID_FAIL = "@@supplier/GET_SUPPLIER_BY_ID_FAIL",

  //file uppload
  SUPPLIER_FILE_UPLOAD_LOADING="@@supplier/SUPPLIER_FILE_UPLOAD_LOADING",
  SUPPLIER_FILE_UPLOAD_SUCCESS="@@supplier/SUPPLIER_FILE_UPLOAD_SUCCESS",
  SUPPLIER_FILE_UPLOAD_ERROR="@@supplier/SUPPLIER_FILE_UPLOAD_ERROR",

  //file fetch
  SUPPLIER_FILE_FETCH_LOADING="@@supplier/SUPPLIER_FILE_FETCH_LOADING",
  SUPPLIER_FILE_FETCH_SUCCESS="@@supplier/SUPPLIER_FILE_FETCH_SUCCESS",
  SUPPLIER_FILE_FETCH_ERROR="@@supplier/SUPPLIER_FILE_FETCH_ERROR",
  
  //file delete
  SUPPLIER_FILE_DELETE_LOADING="@@supplier/SUPPLIER_FILE_DELETE_LOADING",
  SUPPLIER_FILE_DELETE_SUCCESS="@@supplier/SUPPLIER_FILE_DELETE_SUCCESS",
  SUPPLIER_FILE_DELETE_ERROR="@@supplier/SUPPLIER_FILE_DELETE_ERROR",

  SUPPLIER_FILE_CLEAR = "@@supplier/SUPPLIER_FILE_CLEAR",

  RESET_SUPLIER_CONTACT = "@@supplier/RESET_SUPLIER_CONTACT",
  
  GET_SUPPLIER_LOCATION_LOADING="@@supplier/GET_SUPPLIER_LOCATION_LOADING",
  GET_SUPPLIER_LOCATION_SUCCESS="@@supplier/GET_SUPPLIER_LOCATION_SUCCESS",
  GET_SUPPLIER_LOCATION_ERROR="@@supplier/GET_SUPPLIER_LOCATION_ERROR",
  }
  