import React from 'react';
import { Row } from 'reactstrap';
import { CourierTrackingStatus } from 'src/models/pages/couriersModel';


interface CourierTimeLineProps {
  courierTrackingStatus: CourierTrackingStatus | any;
}

const CourierTimeLine = ({ courierTrackingStatus }: CourierTimeLineProps) => {
  let events: any = [];
  events = courierTrackingStatus?.details?.length > 0 ? [...courierTrackingStatus?.details].reverse() : [];
  let windowWidth = window.innerWidth;
  const formatDateTime = (datetime: any) => {
    const date = new Date(datetime);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return { day, month, time };
  };

  return (
    <div
      className='fullWidthOfdotm'>
      <div className='layoutdiv' style={{ width: events?.length <= 2 ? windowWidth + 'px' : events?.length * 350 + 'px' }}>
        {events?.map((data: any, i: number) => {
          const { day, month, time } = formatDateTime(data.time);
          return (
            <div className='dotmark' key={i} style={{ left: i * 350 + 'px' }}>
              <div style={{ position: 'relative' }}>
                {/* <div className={i % 2 === 0 ? 'clildDivtop' : 'clildDivbottom'}> */}
                <div className={i % 2 === 0 ? 'clildDivbottom' : 'clildDivtop'}>
                  <Row className="timeline-right">
                    <div className="timeline-box">
                      <div className="timeline-date bg-primary text-center rounded">
                        <h6 className="text-white mb-0">{day}</h6>
                        <p className="mb-0 text-white-50 font-size-12">{month}</p>
                      </div>
                      <div className="event-content">
                        <div className="timeline-text">
                          <div className={`font-size-12 ${data.status === "Shipment Cancelled" ? "status_of_courier_cancel" : "status_of_courier"}`} title={data?.status}>
                            {data.status}
                          </div>
                          <div className='font-size-12 mt-2'>{data.location} ({data.locationCode})</div>
                          <div className='font-size-12 mt-2' style={{ color: "gray" }}>{time}</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourierTimeLine;