import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { RootReducerState } from 'src/store/reducers'
import "../../SubUserManagement/subUserstyle.scss"
import { getPartsInboundById, getPurchaseOrderDropdown } from 'src/store/partsInbound/action'
import { CurrencyFormatter, formatDateMEL } from 'src/helpers/common_helpers'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'

const ViewPartsInbound = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PartsInboundDetails = useSelector((state: any) => state.PartsInboundReducer.editInbound);
  const purchaseOrder = useSelector((state: RootReducerState) => state.PartsInboundReducer.purchaseOrderList);
  const [details, setDetails] = useState<any>([])
  useEffect(() => {
    if (id) {
      dispatch(getPartsInboundById(id));
      dispatch(getPurchaseOrderDropdown());
    }
  }, [id])


  const { error, message,loading,dealerTaxList } = useSelector(
    (state: RootReducerState) => state.dealer
  );
  const statusBodyTemplate = (value: number) => {
    let status = value;
    // return (
    //   <StatusHghlighter status={status} />
    // )
switch(value){
  case 1:
    return(
      <div> <b>Pending</b></div>
    )
  case 2:
    return(
      <div> <b>Partial</b></div>
    )
  case 3:
    return(
      <div> <b>Done</b></div>
    )
  default:
    return(
      <div> <b>-</b></div>
    )
}
  }
  const PurchaseOrderTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.PURCHASE_ORDER_VIEW}/${field}`}>{rowData}</Link>
    );
  };

  const userView = {
    generalDetails: [
      {
        name: "Received Date",
        value: formatDateMEL(PartsInboundDetails?.receivedDate)
      },
      {
        name: "Shipping Document Date",
        value: formatDateMEL(PartsInboundDetails?.shippingDocumentDate)
      },
      {
        name: "Invoice Number",
        value: PartsInboundDetails?.invoiceNumber
      },
      {
        name: "Airway Bill Number",
        value: PartsInboundDetails?.airwayBillNumber
      },
      {
        name: "Courier Name",
        value: PartsInboundDetails?.courierName
      },
      {
        name: "Buying Currency",
        value: PartsInboundDetails?.buyingCurrency
      },
      {
        name: "Currency Conversion Rate",
        value: PartsInboundDetails?.currencyConversionRate
      },
      {
        name: "Purchase Order",
        value:(PartsInboundDetails?.purchaseOrderId && purchaseOrder) ?  PurchaseOrderTemplate( purchaseOrder.find((val:any)=>val.id===PartsInboundDetails?.purchaseOrderId)?.poNumber , PartsInboundDetails?.purchaseOrderId) : "-" 
      },
      {
        name: "Supplier",
        value: PartsInboundDetails?.supplierName
      },
      {
        name: "Labels Generated",
        value: statusBodyTemplate(PartsInboundDetails?.labelGeneratedStatus)
      },
    ]
  }
  
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const editDealerClickHandler = () => {
    navigate(`${ROUTER.PARTS_INBOUND_EDIT}/${id}`);
  };

  

  const archiveDealerClickHandler = () => {

  };


  const editPartsInbound = () => {
    navigate(`${ROUTER.PARTS_INBOUND_EDIT}/${id}`)
  }


  const currencyBodyTemplate = (rowData: any, field: string, code: string) => {
    let amount = rowData[field];
    let currencyCode = rowData[code];

    // let currencyCode = PartsInboundDetails?.buyingCurrency ? PartsInboundDetails?.buyingCurrency : "INR";
    
    return (<div className="align-right">{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>);
  };

  const percentageBodyTemplate = (rowData: any, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const isShowEditOption:boolean = PartsInboundDetails?.labelGeneratedStatus != 3 ? true : false

  return (
    <div className="pagescontent " id="view-dealer">

    <BreadcrumbWithFilter
       title="Parts Inbound"
       titlePath={ROUTER.PARTS_INBOUND_GRID}
      breadcrumbItem="Parts Inbound Details"
      isShowCreate={false}
      isShowFilter={false}
      filter={false}
      isShowFirstButton={isShowEditOption}
      firstButtonLabel='Edit Parts Inbound'
      onFirstButtonClick={editDealerClickHandler}
      isShowSecondButton={false}
      onSecondButtonClick={archiveDealerClickHandler}
    />

    <Row>
    <div className="view">
            <div className="general-details">
              <b>Parts Inbound Details</b>
            </div>
            <div className="subview">
            <div className="parentCls">
            {
              userView.generalDetails.map((data, index) => {
                return (
                  <div key={index} className={` pt-1 childCls`}>
                    <div>{data.name}</div>
                    <div>{data.value?data.value:<div className="none-text">-</div>}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        </div>
    </Row>
    <Row>
    <div className="view">
            <div className="general-details">
              <b>Parts Details</b>
            </div>
            <div className="subview">
          {/* <Row>
            {
              PartsInboundDetails?.partsInboundDetailDtos?.map((data:any, index:number) => {
                return (
                  <>
                  <Col lg={2} key={index} className={`${index >= 1 ? "mt-4" : "mt-1"}`}>
                    <div className='mb-2'>Part</div>
                    <b>{data?.partNo ? data?.partNo :<div className="none-text">-</div>}</b>
                  </Col>
                  <Col lg={2} key={index} className={`${index >= 1 ? "mt-4" : "mt-1"}`}>
                    <div className='mb-2'>Quantity</div>
                    <b>{data?.quantity ? data?.quantity :<div className="none-text">-</div>}</b>
                  </Col>
                  <Col lg={2} key={index} className={`${index >= 1 ? "mt-4" : "mt-1"}`}>
                    <div className='mb-2'>Buying Price</div>
                    <b>{data?.buyingPrice ? data?.buyingPrice :<div className="none-text">-</div>}</b>
                  </Col>
                  <Col lg={3} key={index} className={`${index >= 1 ? "mt-4" : "mt-1"}`}>
                    <div className='mb-2'>Profit Percentage</div>
                    <b>{data?.profitPercentage ? data?.profitPercentage :<div className="none-text">-</div>}</b>
                  </Col>
                  <Col lg={3} key={index} className={`${index >= 1 ? "mt-4" : "mt-1"}`}>
                    <div className='mb-2'>Selling Price</div>
                    <b>{data?.sellingPrice ? data?.sellingPrice :<div className="none-text">-</div>}</b>
                  </Col>
                  </>
                  
                )
              })
            }
          </Row> */}
          <Row>
            <Col xxl={7} xl={12} lg={12} md={12} >
              <div className="card ps-0 pe-0 " id="table-border">
                <DataTable size='large' value={PartsInboundDetails?.partsInboundDetailDtos} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                  <Column field="partNo" header="Part" className='width-100-per'></Column>
                  <Column field="quantity" header="Quantity" className='align-right width-100'></Column>
                  <Column field="buyingPrice" header="Buying Price" className='align-right width-100'  body={ (rowData) => currencyBodyTemplate(rowData, 'buyingPrice', 'buyingCurrency')}></Column>
                  <Column field="profitPercentage" header="Profit Percentage" className='align-right width-100'  body = {(rowData) => percentageBodyTemplate(rowData, "profitPercentage")}></Column>
                  <Column field="sellingPrice" header="Selling Price" className='align-right width-100' body={ (rowData) => currencyBodyTemplate(rowData, 'sellingPrice', 'buyingCurrency')}></Column>
                </DataTable>
              </div>
              </Col>
            </Row>
        </div>
        </div>
    </Row>
    
  </div>
  )
}

export default ViewPartsInbound