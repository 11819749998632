import React, { useEffect, useRef, useState } from 'react';
import './CustomizeTable.scss';
import { Field, FieldArray, Form, Formik } from "formik";
import { Button, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import { Checkbox } from 'primereact/checkbox';
import { customizeTableColumns, customizeTableProbs } from 'src/models/components/CustomizeTableColumns';
import { Variants, motion } from 'framer-motion';

const prepareInitialValues = (columns: any[]) => {
  const initialValues: any = {};
  initialValues['searchValue'] = "";
  
  columns.forEach((column: any) => {
    
    initialValues[column.field] = column.value;
  });

  return initialValues;
};




function CustomizeTable({onCancelHandler,onCustomizeColumnSubmitHandler,columns}:customizeTableProbs) {



 
  const [searchedValues, setSearchedValues] = useState(columns);


  // const initialValues = prepareInitialValues(columns);
  const initialValues = prepareInitialValues(columns);

  const handleSubmit = (formValue: any) => {
      const trueFields = Object.entries(formValue)
        .filter(([key, value]) => value === true && key !== "searchValue")
        .map(([key]) => key);

      
      onCustomizeColumnSubmitHandler(trueFields)
  };

  const searchColumnsHandler = (formValue: any) => {
    
    
    const searchValue = formValue.target.value.toLowerCase(); 
  
    const filteredValues = columns.filter((column) => {
      // Filter based on search input and field name
      return (
        column.header.toLowerCase().includes(searchValue) ||
        column.value.toString() === searchValue
      );
    });
  
    setSearchedValues(filteredValues);
    
  };

  const totalColumns = columns.length
  const visibleColumns = columns.filter((column) => column.value).length;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onCancelHandler()
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div id="customize-table" ref={containerRef}>
      <h4>Customize table</h4>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange }) => {
          
          return (
            <Form onChange={handleChange}>
              <Row>
                <Col xs={12} lg={12} >
                  <InputGroup>
                    <InputGroupText>
                      <FeatherIcon icon="search" className="margin-right-10"/>
                    </InputGroupText>
                    <Input type="text" name="searchValue" value={values.searchValue}  onChange={searchColumnsHandler} placeholder='Search' />
                  </InputGroup>
                  <h6>Field visible in the table <span className='span-total-column-display'>{visibleColumns}/{totalColumns}</span></h6>
                </Col>
                <Col xs={12} lg={12} className='field-list-container'>
                      {searchedValues.map((category:customizeTableColumns) => {
                        return (
                             <div key={category.field} className="flex align-items-center">
                            <Checkbox inputId={category.field} disabled={category.isDisable}  name={category.field} value={values[category.field]} onChange={handleChange} checked={values[category.field]} />
                            <label htmlFor={category.field} className="ml-2">
                              {category.header}
                            </label>
                          </div>
                         
                        );
                      })}
                      {
                        (searchedValues.length == 0 ) &&
                        <div className='no-match-found'>No match found.</div>

                      }
                </Col>
                <Col xs={12} lg={12} className='btn-container' >
                  {/* <Button className="ms-2"  type="reset" style={{background: "white",
    color: "black",
    border: "none",
    boxShadow: "none"}} >
                  Reset to default </Button> */}
                  <div className='flex-grow-1'></div>
                
                <Button className="secondary-btn ms-2 " color="primary" type="button" onClick={onCancelHandler} >
                            Cancel </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit" >
                            Apply </Button>
                </Col>


              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CustomizeTable;
