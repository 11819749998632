import { InvoiceItemWiseRegistersTypes } from "./actionTypes";



// For Grid

export const getInvoiceItemWiseRegistersList = (lazyState: any) => {
  return {
    type: InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST,
    lazyState,
  };
};


export const getInvoiceItemWiseRegistersListSuccess = (parts: any) => {
  return {
    type: InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST_SUCCESS,
    payload: parts,
  };
};

export const getInvoiceItemWiseRegistersListError = (error: any) => {
  return {
    type: InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST_FAIL,
    payload: error,
  };
};

export const resetInvoiceItemWiseRegistersList = ()=>{
  return { type: InvoiceItemWiseRegistersTypes.RESET_INVOICE_ITEM_WISE_REGISTERS_LIST};
}

export const getGstNoList = () => {
  return {
    type: InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST,
  };
};


export const getGstNoSuccess = (parts: any) => {
  return {
    type: InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST_SUCCESS,
    payload: parts,
  };
};

export const getGstNoError = (error: any) => {
  return {
    type: InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST_FAIL,
    payload: error,
  };
};
