
import React, { useEffect, useRef, useState } from 'react';
import { DataTable, DataTableFilterMeta, DataTableRowEditCompleteEvent } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { incentiveListLoading, updateIncentiveLoading } from 'src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { incentive } from 'src/models/pages/incentiveModels';
import Button from 'src/components/UI/Button';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';





export default function IncentivePages() {
  const [products, setProducts] = useState<incentive[] | any>();
  const [statuses] = useState<string[]>(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);
  const { incentiveList, error, loading, message } = useSelector((state: RootReducerState) => state.incentiveReducer)
  const dispatch = useDispatch();
  const filterDetails: DataTableFilterMeta = {
    UserId: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  useEffect(() => {
    const modifiedData = incentiveList.values.map((data: incentive) => {
      let object = {
        id: data.id,
        scenario: data.scenario,
        workProcess: data.workProcess,
        process: data.process,
        reward: data.reward ? data.reward : 0,
        tatReward: data.tatReward ? data.tatReward : 0,
        tatInMinutes: data.tatInMinutes ? data.tatInMinutes : 0
      }
      return object
    })
    setProducts(modifiedData)
  }, [incentiveList]);

  const { showToast } = useToast();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {

    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(incentiveListLoading(lazyState));
    }
  }, [error, message]);

  const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData as any;
    

    setProducts(_products);
  };


  const textEditor = (options: any) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const RewardEditor = (options: ColumnEditorOptions) => {

    return <InputNumber inputRef={inputRef} value={options.value} min={0} onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback!(e.value)} />;
  };
  const TatRewardEditor = (options: ColumnEditorOptions) => {
    return <InputNumber value={options.value} min={0} onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback!(e.value)} />;
  };
  const TatInMinusEditor = (options: ColumnEditorOptions) => {
    return <InputNumber value={options.value} min={0} onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback!(e.value)} />;
  };



  const header =
    <div className="table-header-container">
      <div>Incentive List</div>
      <div className="table-header-container-item table-header-container-item-special">

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
      </div>

    </div>;

  const hidePopup = () => {
    setVisible(false);
    // dispatch(incentiveListLoading(lazyState));
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const allowEdit = (rowData: any) => {
    if (rowData && rowData.name !== 'Blue Band') {
      return true;
    }
    return false
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(incentiveListLoading(lazyState));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [])
  const handleSubmit = () => {

    dispatch(updateIncentiveLoading(products))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DataTable reorderableColumns header={header} value={products} editMode="column" dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
          <Column frozen field="scenario" header="Scenario" style={{ width: '20%' }}></Column>
          <Column field="process" header="Work Process" style={{ width: '20%' }}></Column>
          <Column field="reward" header="Reward" editor={(options) => RewardEditor(options)} style={{ width: '20%' }}></Column>
          <Column field="tatReward" header="Tat Reward" editor={(options) => TatRewardEditor(options)} style={{ width: '20%' }}></Column>
          <Column field="tatInMinutes" header="Tat in Minutes" editor={(options) => TatInMinusEditor(options)} style={{ width: '20%' }}></Column>
          <Column rowEditor={allowEdit(products)} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
        </DataTable>
        <div className="d-flex justify-content-end mt-2">
          <Button
            className="secondary-btn me-2"
            label={"Reset"}
            type="button"
            onClick={() => { dispatch(incentiveListLoading(lazyState)) }}
          />
          <Button
            className="btn-primary me-2"
            label={"Update Incentive"}
            onClick={() => handleSubmit()}
            type="button"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

