export enum AttendanceTypes {

    // Grid
    GET_ATTENDANCE_LIST = "@@attendance/GET_ATTENDANCE_LIST",
    GET_ATTENDANCE_LIST_SUCCESS = "@@attendance/GET_ATTENDANCE_LIST_SUCCESS",
    GET_ATTENDANCE_LIST_FAIL = "@@attendance/GET_ATTENDANCE_LIST_FAIL",

    //filter
    FILTER_PAGE_LOADING = "@@attendance/FILTER_PAGE_LOADING",
    FILTER_PAGE_SUCCESS = "@@attendance/FILTER_PAGE_SUCCESS",
    FILTER_PAGE_ERROR = "@@attendance/FILTER_PAGE_ERROR",

    //UPDATE
    UPDATE_ATTENDANCE = "@@attendance/UPDATE_ATTENDANCE",
    UPDATE_ATTENDANCE_SUCCESS = "@@attendance/UPDATE_ATTENDANCE_SUCCESS",
    UPDATE_ATTENDANCE_FAIL = "@@attendance/UPDATE_ATTENDANCE_FAIL",

    // POST
    POST_ATTENDANCE = "@@attendance/POST_ATTENDANCE",
    POST_ATTENDANCE_SUCCESS = "@@attendance/POST_ATTENDANCE_SUCCESS",
    POST_ATTENDANCE_FAIL = "@@attendance/POST_ATTENDANCE_FAIL",

    // GET Single User
    GET_ATTENDANCE_BY_ID_LIST = "@@attendance/GET_ATTENDANCE_BY_ID",
    GET_ATTENDANCE_BY_ID_LIST_SUCCESS = "@@attendance/GET_ATTENDANCE_BY_ID_SUCCESS",
    GET_ATTENDANCE_BY_ID_LIST_FAIL = "@@attendance/GET_ATTENDANCE_BY_ID_FAIL",

    //Get Cure userStatue
    GET_CURRENT_USER_ATTENDANCE = "@@attendance/GET_CURRENT_USER_ATTENDANCE",
    GET_CURRENT_USER_ATTENDANCE_SUCCESS = "@@attendance/GET_CURRENT_USER_ATTENDANCE_SUCCESS",
    GET_CURRENT_USER_ATTENDANCE_FAIL = "@@attendance/GET_CURRENT_USER_ATTENDANCE_FAIL",

    //RESET
    CLEAR_ATTENDANCE_MESSAGE = "@@attendance/CLEAR_ATTENDANCE_MESSAGE",

    // getUserWorkingHours
    GET_USER_WORKING_HOURS = "@@attendance/GET_USER_WORKING_HOURS",
    GET_USER_WORKING_HOURS_SUCCESS = "@@attendance/GET_USER_WORKING_HOURS_SUCCESS",
    GET_USER_WORKING_HOURS_FAIL = "@@attendance/GET_USER_WORKING_HOURS_FAIL",
    RELOAD_USER_WORKING_HOURS = "@@attendance/RELOAD_USER_WORKING_HOURS",

    GET_ATTENDANCE_DETAIL_BY_ID = "@@attendance/GET_ATTENDANCE_DETAIL_BY_ID",
    GET_ATTENDANCE_DETAIL_BY_ID_SUCCESS = "@@attendance/GET_ATTENDANCE_DETAIL_BY_ID_SUCCESS",
    GET_ATTENDANCE_DETAIL_BY_ID_FAIL = "@@attendance/GET_ATTENDANCE_DETAIL_BY_ID_FAIL",

    //Reset=-individual id

    GET_RESET_INDUVIDUAL_ATTENDANCE_DETAILS = "@@attendance/GET_RESET_INDUVIDUAL_ATTENDANCE_DETAILS"
}
