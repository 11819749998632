import React from "react";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { Col, Row } from "reactstrap";
import InputCom from "src/components/Common/Input";
import Button from "src/components/UI/Button";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createRoleLoading } from "src/store/permission/action";

const ShowDialog = ({ openModal, setOpenModal }: any) => {
    const dispatch =useDispatch()
  const handleFromSubmit = (values:any) => {
    let obj={
      name:values.name,
      isDefault:false,
      isPublic:false
    }
    dispatch(createRoleLoading(obj))
    
  };
  const newvalue = {
    name:"",
    isDefault:false,
    isPublic:false
  };
  const formSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
  });
  return (
    <Dialog
    id="permission"
      header={"Add Role"}
      visible={openModal}
      style={{ width: "auto" }}
      onHide={() => {
        setOpenModal(false);
      }}
    >
      <Formik
        onSubmit={handleFromSubmit}
        initialValues={newvalue}
        validationSchema={formSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          resetForm,
          reset,
          setFieldTouched,
          ...rest
        }: any) => {
          return (
            <Form onChange={handleChange}>
              <Row>
                <Col lg={12} className="mb-3">
                  <InputCom
                    labelName="Name"
                    inputclassName="form-control"
                    labelClassName={`form-Label`}
                    type="text"
                    name={`name`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isRequired={true}
                    value={values.name}
                    invalid={touched?.name && errors?.name ? true : false}
                  />
                </Col>
                {/* <Col lg={12} className="mb-3">
                  <CheckboxInput
                    name="isDefault"
                    label="Default"
                    value={values["isDefault"]}
                    onChange={handleChange}
                    {...rest}
                  />
                  <CheckboxInput
                    name="isPublic"
                    label="Public"
                    value={values["isPublic"]}
                    onChange={handleChange}
                    {...rest}
                  />
                </Col> */}

                <div className="d-flex justify-content-end mt-2">
                  <Button
                    onClick={() => setOpenModal(false)}
                    className="secondary-btn me-2"
                    label={"Cancel"}
                    type="button"
                  />
                  <Button
                    className="btn-primary me-2"
                    label={"Submit"}
                    type="submit"
                  />
                </div>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ShowDialog;
