import { IncentiveTypes } from "./actionTypes";


export const incentiveListLoading = (lazyState: any) => {
    return {
        type: IncentiveTypes.GET_INCENTIVE_LIST_LOADING,
        lazyState,
    }
}

export const incentiveListSuccess = (resp: any) => {
    return {
        type: IncentiveTypes.GET_INCENTIVE_LIST_SUCCESS,
        payload: resp
    }
}
export const incentiveListError = (error: any) => {
    return {
        type: IncentiveTypes.GET_INCENTIVE_LIST_FAIL,
        payload: error
    }
}

export const incentiveEmptyListLoading = () => {
    return {
        type: IncentiveTypes.GET_INCENTIVE_EMPTY_LIST_LOADING,
    }
}

export const incentiveEmptyListSuccess = (resp: any) => {
    return {
        type: IncentiveTypes.GET_INCENTIVE_EMPTY_LIST_SUCCESS,
        payload: resp
    }
}
export const incentiveEmptyListError = (error: any) => {
    return {
        type: IncentiveTypes.GET_INCENTIVE_EMPTY_LIST_FAIL,
        payload: error
    }
}

export const updateIncentiveLoading = (incentive: any) => {
    return {
        type: IncentiveTypes.UPDATE_INCENTIVE_LOADING,
        payload:incentive,
    }
}

export const updateIncentiveSuccess = (resp: any) => {
    return {
        type: IncentiveTypes.UPDATE_INCENTIVE_SUCCESS,
        payload: resp
    }
}
export const updateIncentiveError = (error: any) => {
    return {
        type: IncentiveTypes.UPDATE_INCENTIVE_FAIL,
        payload: error
    }
}
