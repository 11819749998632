export enum StockAdjustmentTypes {
    // Grid
    GET_STOCK_ADJUSTMENT_LIST = "@@stock_adjustment/GET_STOCK_ADJUSTMENT_LIST",
    GET_STOCK_ADJUSTMENT_LIST_SUCCESS = "@@stock_adjustment/GET_STOCK_ADJUSTMENT_LIST_SUCCESS",
    GET_STOCK_ADJUSTMENT_LIST_FAIL = "@@stock_adjustment/GET_STOCK_ADJUSTMENT_LIST_FAIL",
    
    GET_STOCK_ADJUSTMENT_BY_ID="@@stock_adjustment/GET_STOCK_ADJUSTMENT_BY_ID",
    GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS="@@stock_adjustment/GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS",
    GET_STOCK_ADJUSTMENT_BY_ID_FAIL="@@stock_adjustment/GET_STOCK_ADJUSTMENT_BY_ID_FAIL",

    GET_STOCK_ADJUSTMENT_BY_PART_ID = "@@stock_adjustment/GET_STOCK_ADJUSTMENT_BY_PART_ID",
    GET_STOCK_ADJUSTMENT_BY_PART_ID_SUCCESS = "@@stock_adjustment/GET_STOCK_ADJUSTMENT_BY_PART_ID_SUCCESS",
    GET_STOCK_ADJUSTMENT_BY_PART_ID_FAIL = "@@stock_adjustment/GET_STOCK_ADJUSTMENT_BY_PART_ID_FAIL",
  
    DELETE_STOCK_ADJUSTMENT = "@@stock_adjustment/DELETE_STOCK_ADJUSTMENT",
    DELETE_STOCK_ADJUSTMENT_SUCCESS = "@@stock_adjustment/DELETE_STOCK_ADJUSTMENT_SUCCESS",
    DELETE_STOCK_ADJUSTMENT_FAIL = "@@stock_adjustment/DELETE_STOCK_ADJUSTMENT_FAIL",
    // //reset
    RESET_STOCK_ADJUSTMENT = "@@stock_adjustment/RESET_STOCK_ADJUSTMENT",
   
    // //update STOCK_ADJUSTMENT
    UPDATE_STOCK_ADJUSTMENT = "@@stock_adjustment/UPDATE_STOCK_ADJUSTMENT",
    UPDATE_STOCK_ADJUSTMENT_SUCCESS = "@@stock_adjustment/UPDATE_STOCK_ADJUSTMENT_SUCCESS",
    UPDATE_STOCK_ADJUSTMENT_ERROR = "@@stock_adjustment/UPDATE_STOCK_ADJUSTMENT_ERROR",

    //create
    CREATE_STOCK_ADJUSTMENT = "@@stock_adjustment/CREATE_STOCK_ADJUSTMENT",
    CREATE_STOCK_ADJUSTMENT_SUCCESS = "@@stock_adjustment/CREATE_STOCK_ADJUSTMENT_SUCCESS",
    CREATE_STOCK_ADJUSTMENT_ERROR = "@@stock_adjustment/CREATE_STOCK_ADJUSTMENT_ERROR",
    
    PARTS_SKU_API_CALL="@@stock_adjustment/PARTS_SKU_API_CALL",
    PARTS_SKU_API_CALL_SUCCESS="@@stock_adjustment/PARTS_SKU_API_CALL_SUCCESS",
    PARTS_SKU_API_CALL_ERROR="@@stock_adjustment/PARTS_SKU_API_CALL_ERROR",

    GET_SUPPLIER_DROPDOWN="@@stock_adjustment/GET_SUPPLIER_DROPDOWN",
    GET_SUPPLIER_DROPDOWN_SUCCESS="@@stock_adjustment/GET_SUPPLIER_DROPDOWN_SUCCESS",
    GET_SUPPLIER_DROPDOWN_ERROR="@@stock_adjustment/GET_SUPPLIER_DROPDOWN_ERROR",

    RESET_MESSAGE="@@stock_adjustment/RESET_MESSAGE",

    GET_INITIATED_BY_DROPDOWN="@@stock_adjustment/GET_INITIATED_BY_DROPDOWN",
    GET_INITIATED_BY_DROPDOWN_SUCCESS="@@stock_adjustment/GET_INITIATED_BY_DROPDOWN_SUCCESS",
    GET_INITIATED_BY_DROPDOWN_ERROR="@@stock_adjustment/GET_INITIATED_BY_DROPDOWN_ERROR",

    //  SENT EMAIL TO STOCK ADJUSTMENT

    SENT_EMAIL_TO_STOCK_ADJUSTMENT = "@@stock_inspection/SENT_EMAIL_TO_STOCK_ADJUSTMENT",
    SENT_EMAIL_TO_STOCK_ADJUSTMENT_SUCCESS = "@@stock_inspection/SENT_EMAIL_TO_STOCK_ADJUSTMENT_SUCCESS",
    SENT_EMAIL_TO_STOCK_ADJUSTMENT_FAIL = "@@stock_inspection/SENT_EMAIL_TO_STOCK_ADJUSTMENT_FAIL",

    DOWNLOAD_STOCK_ADJUSTMENT = "@@stock_inspection/DOWNLOAD_STOCK_ADJUSTMENT",
    DOWNLOAD_STOCK_ADJUSTMENT_SUCCESS = "@@stock_inspection/DOWNLOAD_STOCK_ADJUSTMENT_SUCCESS",
    DOWNLOAD_STOCK_ADJUSTMENT_FAIL = "@@stock_inspection/DOWNLOAD_STOCK_ADJUSTMENT_FAIL",

    //GET COURIER DROPDOWN
    GET_COURIER_DROPDOWN_LOADING = "@@stock_inspection/GET_COURIER_DROPDOWN_LOADING",
    GET_COURIER_DROPDOWN_SUCCESS = "@@stock_inspection/GET_COURIER_DROPDOWN_SUCCESS",
    GET_COURIER_DROPDOWN_ERROR = "@@stock_inspection/GET_COURIER_DROPDOWN_ERROR",

    REASET_PARTSKU_INDEX="@@stock_adjustment/REASET_PARTSKU_INDEX",

    DELETE_PATS_SKU_LIST = "@@stock_adjustment/DELETE_PATS_SKU_LIST"
  }