import { PartsTypes } from "./actionTypes";
import { partsInitialState } from "src/models/pages/partsModel";




const initialState: partsInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  audioBrandList: [],
  partsList: {
    values: [],
    totalRecords: 0
  },
  parts: null,
  purchaseOrderDetails: {
    purchaseitems: [],
    count: 0
  },
  partInboundDetails: {
    partinbounditems: [],
    totalCount: 0
  }

};



const Parts = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case PartsTypes.GET_PARTS_LIST:
    case PartsTypes.FILTER_PAGE_LOADING:
    case PartsTypes.UPDATE_PARTS:
    case PartsTypes.POST_PARTS:
    case PartsTypes.GET_PARTS_BY_ID:
    case PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS:
    case PartsTypes.PARTS_VIEW_PARTS_INBOUND_LOADING:
    case PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0
      };

    case PartsTypes.GET_PARTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        partsList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length
      };

    case PartsTypes.GET_PARTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        parts: action.payload
      };

    case PartsTypes.GET_PARTS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case PartsTypes.DELETE_PARTS:
      return {
        ...state,
        loading: true,
      };

    case PartsTypes.DELETE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: action.undo ? "Parts restored successfully" : "Parts deleted successfully"
      }

    case PartsTypes.UPDATE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Parts updated successfully"
      }
    case PartsTypes.FILTER_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        partsList: {
          ...state.partsList,
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        }
      }
    case PartsTypes.POST_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Parts created successfully",
      }

    case PartsTypes.PARTS_VIEW_PARTS_INBOUND_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        error: "",
        partInboundDetails: {
          ...state.partInboundDetails,
          partinbounditems: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        }
      }

    case PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        purchaseOrderDetails: {
          ...state.purchaseOrderDetails,
          purchaseitems: action.payload.data.items,
          count: action.payload.data.totalCount
        }
      }

    case PartsTypes.DELETE_PARTS_FAIL:
    case PartsTypes.FILTER_PAGE_ERROR:
    case PartsTypes.UPDATE_PARTS_FAIL:
    case PartsTypes.POST_PARTS_FAIL:
    case PartsTypes.GET_PARTS_BY_ID_FAIL:
    case PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS_FAIL:
    case PartsTypes.PARTS_VIEW_PARTS_INBOUND_ERROR:
    case PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_ERROR:
      return {
        ...state,
        message: "",
        loading: false,
        error: action.payload
      };


    case PartsTypes.RESET_PARTS:
      return {
        ...initialState
      };

    case PartsTypes.CLEAR_PARTS_MESSAGE:
      return {
        ...state,
        error: "",
        loading: false,
        message: "",
      };


    case PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        audioBrandList: action.payload.data
      };
    default:
      return { ...state };
  }
};

export default Parts;
