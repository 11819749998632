import { invoiceItemWiseRegistersInitialState } from "src/models/pages/invoiceItemWiseRegistersModel";
import { InvoiceItemWiseRegistersTypes } from "./actionTypes";




const initialState: invoiceItemWiseRegistersInitialState = {
  error: "",
  loading: false,
  message: "",
  invoiceItemList: {
    values: [],
    totalRecords: 0
  },
  gstList:""
};



const InvoiceItemWiseResisters = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST:
    case InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST:
      return {
        ...state,
        loading: true,
        paginatorCount: 0
      };

    case InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        invoiceItemList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
      };

    case InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        gstList:action.payload.data
      };


    case InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST_FAIL:
    case InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST_FAIL:
      return {
        ...state,
        message:"",
        loading: false,
        error: action.payload
      };

    
    case InvoiceItemWiseRegistersTypes.RESET_INVOICE_ITEM_WISE_REGISTERS_LIST:
      return {
        ...state,
        ...initialState
      };
    
    default:
      return { ...state };
  }
};

export default InvoiceItemWiseResisters;
