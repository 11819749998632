export enum AdministratorTypes {

  // Grid
  GET_ADMINISTRATOR_LIST = "@@administrator/GET_ADMINISTRATOR_LIST",
  GET_ADMINISTRATOR_LIST_SUCCESS = "@@administrator/GET_ADMINISTRATOR_LIST_SUCCESS",
  GET_ADMINISTRATOR_LIST_FAIL = "@@administrator/GET_ADMINISTRATOR_LIST_FAIL",

  DELETE_ADMINISTRATOR = "@@administrator/DELETE_ADMINISTRATOR",
  DELETE_ADMINISTRATOR_SUCCESS = "@@administrator/DELETE_ADMINISTRATOR_SUCCESS",
  DELETE_ADMINISTRATOR_FAIL = "@@administrator/DELETE_ADMINISTRATOR_FAIL",
  // //reset
  RESET_ADMINISTRATOR = "@@administrator/RESET_ADMINISTRATOR",

  // //update administrator
  UPDATE_ADMINISTRATOR_LOADING = "@@administrator/UPDATE_ADMINISTRATOR_LOADING",
  UPDATE_ADMINISTRATOR_SUCCESS = "@@administrator/UPDATE_ADMINISTRATOR_SUCCESS",
  UPDATE_ADMINISTRATOR_ERROR = "@@administrator/UPDATE_ADMINISTRATOR_ERROR",


  //get map key api
  GET_MAP_KEY_API_LOADING = "@@administrator/GET_MAP_KEY_API_LOADING",
  GET_MAP_KEY_API_SUCCESS = "@@administrator/GET_MAP_KEY_API_SUCCESS",
  GET_MAP_KEY_API_ERROR = "@@administrator/GET_MAP_KEY_API_ERROR",

  //save map key

  SAVE_MAP_KEY = "@@administrator/SAVE_MAP_KEY",

}
