import { audioModelsInitialState } from "src/models/pages/audioModelsModel";
import { AudioModelsTypes } from "./actionTypes";


const initialState: audioModelsInitialState = {
  loading: false,
  message: "",
  paginatorCount: 0,
  audioBrandNames: {
    audioBrandList: [],
    audioModels: undefined
  },
  audioModelsList: {
    values: [],
    totalRecords: 0
  },
  error: "",
  audioModelAudoCompleteList:""
};

const AudioBrands = (state = initialState, action: any) => {
  switch (action.type) {
    case AudioModelsTypes.GET_AUDIO_MODELS_LIST_LOADING:
    case AudioModelsTypes.DELETE_AUDIO_MODELS_LOADING:
    case AudioModelsTypes.CREATE_AUDIO_MODELS_PENDING:
    case AudioModelsTypes.UPDATE_AUDIO_MODELS_PENDING:
    case AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_PENDING:
    case AudioModelsTypes.GET_AUDIO_BRAND_NAMES_LOADING:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        paginatorCount: 0
      };

    case AudioModelsTypes.GET_AUDIO_MODELS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        audioModelsList: {
          ...state.audioModelsList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length
      };

    case AudioModelsTypes.DELETE_AUDIO_MODELS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo ? "Audio Model is restored successfully" : "Audio Model is deleted successfully",
        error: "",
      }

    case AudioModelsTypes.GET_AUDIO_BRAND_NAMES_SUCCESS:
      console.log("audio brand",action.payload)
      return {
        ...state,
        loading: false,
        audioBrandNames: {
          ...state.audioBrandNames,
          audioBrandList: action.payload.data
        }
      }

    case AudioModelsTypes.GET_AUDIO_MODEL_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        audioModelAudoCompleteList:action.payload.data
      }

    case AudioModelsTypes.CREATE_AUDIO_MODELS_SUCCESS:
      return {
        ...state,
        loading: false, 
        message: "Audio Model Created Successfully"
      }

    case AudioModelsTypes.UPDATE_AUDIO_MODELS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Audio Model Updated Successfully"
      }

    case AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBrandNames: {
          ...state.audioBrandNames,
          audioModels: action.payload.data
        }
      }

    case AudioModelsTypes.RESET_AUDIO_MODEL:
      return {
        ...state,
        message: "",
        error: ""
      }

    case AudioModelsTypes.GET_AUDIO_MODELS_LIST_FAIL:
    case AudioModelsTypes.DELETE_AUDIO_MODELS_FAIL:
    case AudioModelsTypes.CREATE_AUDIO_MODELS_ERROR:
    case AudioModelsTypes.UPDATE_AUDIO_MODELS_ERROR:
    case AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_ERROR:
    case AudioModelsTypes.GET_AUDIO_BRAND_NAMES_ERROR:
    case AudioModelsTypes.GET_AUDIO_MODEL_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default AudioBrands;
