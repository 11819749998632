import { taxesInitialState } from "src/models/pages/taxes";
import { TaxesTypes } from "./actionTypes";

const initialState: taxesInitialState = {
  error: "",
  loading: false,
  message: "",
  taxesList: {
    values: [],
    totalRecords: 0
  },
  taxDetails: null,
  taxDropDownList: [],
  paginatorCount: 0,
  invoiceDropdown: []
};

const Taxes = (state = initialState, action: any) => {
  switch (action.type) {

    case TaxesTypes.GET_TAXES_LIST:
    case TaxesTypes.DELETE_TAXES:
    case TaxesTypes.UPDATE_TAXES:
    case TaxesTypes.POST_TAXES:
    case TaxesTypes.GET_TAXES_BY_ID:
    case TaxesTypes.GET_TAXES_DROPDOWN_LIST_LOADING:
    case TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_LOADING:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        paginatorCount: 0
      };

    case TaxesTypes.GET_TAXES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taxesList: {
          ...state.taxesList,
          values: action.payload.data,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data?.items?.length
      };

    case TaxesTypes.GET_TAXES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        taxDetails: action.payload
      };

    case TaxesTypes.UPDATE_TAXES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Tax updated successfully"
      }

    case TaxesTypes.POST_TAXES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Tax created successfully",
      }
    case TaxesTypes.DELETE_TAXES_SUCCESS:
      return {
        ...state,
        loading: false,
        // error : action.payload.message,
        message: action.undo ? "Taxes restored successfully" : "Taxes deleted successfully",
      }

    case TaxesTypes.GET_TAXES_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taxDropDownList: action.payload.data
      };

    case TaxesTypes.RESET_TAXES:
      return {
        ...initialState
      };

    case TaxesTypes.CLEAR_TAXES_MESSAGE:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };

    case TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        invoiceDropdown : action.payload.data
      };


    case TaxesTypes.GET_TAXES_LIST_FAIL:
    case TaxesTypes.DELETE_TAXES_FAIL:
    case TaxesTypes.UPDATE_TAXES_FAIL:
    case TaxesTypes.POST_TAXES_FAIL:
    case TaxesTypes.GET_TAXES_BY_ID_FAIL:
    case TaxesTypes.GET_TAXES_DROPDOWN_LIST_ERROR:
    case TaxesTypes.GET_TAXES_INVOICETYPE_DROPDOWN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default Taxes;
