import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { clearStockLocationMessage, createStockLocations, getStockLocationBYID, updateStockLocations } from "src/store/actions";
import { useToast } from "src/components/Common/ReactToaster";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { stocklocation } from "src/models/pages/stockLocationModel";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';

const AddOrEditStockLocation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const formValue: stocklocation = useSelector(
    (state: RootReducerState) => state.stockLocationReducer?.stocklocation as stocklocation
  );
  const { error, message, loading } = useSelector(
    (state: RootReducerState) => state.stockLocationReducer
  );

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
    return () => {
      dispatch(clearStockLocationMessage())
    }
  }, [loading]);

  useEffect(() => {
    if (id) {
      dispatch(getStockLocationBYID(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (error) {
      setIsSubmitting(false)
      setVisible(true)
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearStockLocationMessage())
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.STOCK_LOCATION_GRID)
      }, 500)
      if (id) {
        dispatch(getStockLocationBYID(id))
      } else {
        navigate(ROUTER.STOCK_LOCATION_GRID);
      }

    }
  }, [error, message])

  const validationSchema = Yup.object().shape({
    locationName: Yup.string().required('Location is required'),

  });

  const getInitialValue = () => {
    if (id) {
      return {
        locationName: (formValue?.['locationName'] || ""),
      }
    } else {
      return {
        locationName: ""
      }
    }
  }
  const initialvalue = getInitialValue();

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearStockLocationMessage())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: stocklocation = {
      locationName: values.locationName as string
    }
    setIsSubmitting(true)
    id ? update(submittedData) : post(submittedData);
  };

  const update = (formValue: stocklocation) => {
    dispatch(updateStockLocations(formValue, id));
  };

  const post = (formValue: stocklocation) => {
    dispatch(createStockLocations(formValue));
  };

  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.STOCK_LOCATION_GRID)
    }
  }

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.locationName] = '';
      } else {
        acc[field.locationName] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className="page-content" id="stock-location">
        <Container fluid>
          <Breadcrumb
            title="Stock Location"
            titlePath={ROUTER.STOCK_LOCATION_GRID}
            breadcrumbItem={id ? "Edit Stock Location" : "Create Stock Location"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={4} className="mb-2">
                              <InputCom
                                labelName="Location"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="locationName"
                                onBlur={handleBlur}
                                value={values.locationName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["locationName"] && errors["locationName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                          
                          <Col lg={4} className="btn-alignment">
                          <div className="d-flex">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2"
                              label={id ? "Update Stock Location" : "Create Stock Location"}
                              type="submit"
                            />
                          </div>
                          </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddOrEditStockLocation;
