import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { incomingDispatchTypes } from "./actionTypes";
import { getIncomingDispatchBYIDError, getIncomingDispatchBYIDSuccess, getIncomingDispatchListError, getIncomingDispatchListSuccess, getReceivedBYIDError, getReceivedBYIDSuccess } from "./action";



function* getIncomingDispatchListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getincomingDispatchList, { ...action.lazyState})    
    yield put(getIncomingDispatchListSuccess(response));
  } catch (error) {
    yield put(getIncomingDispatchListError(error));
  }
}

function* fetchIncomingDispatch({ payload }: any): Generator<any, void, any> {
  console.log("fetch id",payload);
  
  try {
    let { data } = yield call(API.getincomingDispatchById, payload);
    
    yield put(getIncomingDispatchBYIDSuccess(data));

  } catch (error) {
    yield put(getIncomingDispatchBYIDError(error));
  }
}

function* getReceivedByIdAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPrepareyIdData, action.id)    
    yield put(getReceivedBYIDSuccess(response));
  } catch (error) {
    yield put(getReceivedBYIDError(error));
  }
}


function* incomingDispatchSaga() {
  yield takeLatest(incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_LOADING, getIncomingDispatchListAsync);
  yield takeLatest(incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_LOADING, fetchIncomingDispatch);
  yield takeLatest(incomingDispatchTypes.GET_RECEIVED_BY_ID_LOADING, getReceivedByIdAsync);
}

export default incomingDispatchSaga;