import { WhatsappTypes } from "./actionTypes";

export const getWhatsappList = (lazyState: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPP_LIST_LOADING,
    lazyState,
  };
};

export const getWhatsappListSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPP_LIST_SUCCESS,
    payload: payload,
  };
};

export const getWhatsappListError = (error: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPP_LIST_ERROR,
    payload: error,
  };
};

export const getEmployeeNumList = () => {
  return {
    type: WhatsappTypes.GET_EMPLOYEE_NUM_LOADING,
  };
};

export const getEmployeeNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_EMPLOYEE_NUM_SUCCESS,
    payload: payload,
  };
};

export const getEmployeeNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_EMPLOYEE_NUM_ERROR,
    payload: error,
  };
};

export const getDealerNumList = () => {
  return {
    type: WhatsappTypes.GET_DEALER_NUM_LOADING,
  };
};

export const getDealerNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_DEALER_NUM_SUCCESS,
    payload: payload,
  };
};

export const getDealerNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_DEALER_NUM_ERROR,
    payload: error,
  };
};

export const getCustomerNumList = () => {
  return {
    type: WhatsappTypes.GET_CUSTOMER_NUM_LOADING,
  };
};

export const getCustomerNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_CUSTOMER_NUM_SUCCESS,
    payload: payload,
  };
};

export const getCustomerNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_CUSTOMER_NUM_ERROR,
    payload: error,
  };
};

export const getFranchiseNumList = () => {
  return {
    type: WhatsappTypes.GET_FRANCHISE_NUM_LOADING,
  };
};

export const getFranchiseNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_FRANCHISE_NUM_SUCCESS,
    payload: payload,
  };
};

export const getFranchiseNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_FRANCHISE_NUM_ERROR,
    payload: error,
  };
};

export const getDealerSubuserNumList = () => {
  return {
    type: WhatsappTypes.GET_DEALER_SUBUSER_NUM_LOADING,
  };
};

export const getDealerSubuserNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_DEALER_SUBUSER_NUM_SUCCESS,
    payload: payload,
  };
};

export const getDealerSubuserNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_DEALER_SUBUSER_NUM_ERROR,
    payload: error,
  };
};

export const getFranchiseSubuserNumList = () => {
  return {
    type: WhatsappTypes.GET_FRANCHISE_SUBUSER_NUM_LOADING,
  };
};

export const getFranchiseSubuserNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_FRANCHISE_SUBUSER_NUM_SUCCESS,
    payload: payload,
  };
};

export const getFranchiseSubuserNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_FRANCHISE_SUBUSER_NUM_ERROR,
    payload: error,
  };
};

export const getSupplierNumList = () => {
  return {
    type: WhatsappTypes.GET_SUPPLIER_NUM_LOADING,
  };
};

export const getSupplierNumSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_SUPPLIER_NUM_SUCCESS,
    payload: payload,
  };
};

export const getSupplierNumError = (error: any) => {
  return {
    type: WhatsappTypes.GET_SUPPLIER_NUM_ERROR,
    payload: error,
  };
};

export const createWhatsappMessage = (payload: any) => {
  return {
    type: WhatsappTypes.CREATE_WHATSAPP_MESSAGE_LOADING,
    payload,
  };
};

export const createWhatsappMessageSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.CREATE_WHATSAPP_MESSAGE_SUCCESS,
    payload: payload,
  };
};

export const createWhatsappMessageError = (error: any) => {
  return {
    type: WhatsappTypes.CREATE_WHATSAPP_MESSAGE_ERROR,
    payload: error,
  };
};
export const deleteWhatsappMessage = (id: any) => {
  return {
    type: WhatsappTypes.DELETE_WHATSAPP_MESSAGE_LOADING,
    id: id,
  };
};

export const deleteWhatsappMessageSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.DELETE_WHATSAPP_MESSAGE_SUCCESS,
    payload: payload,
  };
};

export const deleteWhatsappMessageError = (error: any) => {
  return {
    type: WhatsappTypes.DELETE_WHATSAPP_MESSAGE_ERROR,
    payload: error,
  };
};
export const getWhatsappMessageStatus = () => {
  return {
    type: WhatsappTypes.GET_WHATSAPP_MESSAGE_STATUS_LOADING,
  };
};

export const getWhatsappMessageStatusSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPP_MESSAGE_STATUS_SUCCESS,
    payload: payload,
  };
};

export const getWhatsappMessageStatusError = (error: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPP_MESSAGE_STATUS_ERROR,
    payload: error,
  };
};
export const getWhatsappMessageById = (id: any) => {
  return {
    type: WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_LOADING,
    id: id,
  };
};

export const getWhatsappMessageByIdSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_SUCCESS,
    payload: payload,
  };
};

export const getWhatsappMessageByIdError = (error: any) => {
  return {
    type: WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_ERROR,
    payload: error,
  };
};

export const getWhatsappDocumentLink = (id: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPPDOCUMENT_LINK_LOADING,
    id: id,
  };
};

export const getWhatsappDocumentLinkSuccess = (payload: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPPDOCUMENT_LINK_SUCCESS,
    payload: payload,
  };
};

export const getWhatsappDocumentLinkError = (error: any) => {
  return {
    type: WhatsappTypes.GET_WHATSAPPDOCUMENT_LINK_ERROR,
    payload: error,
  };
};

export const clearWhatsappMessageMessage = () => {
  return {
    type: WhatsappTypes.CLEAR_WHATSAPP_MESSAGE_MESSAGE,
  };
};

export const resetWhatsappMessageMessage = () => {
  return {
    type: WhatsappTypes.RESET_WHATSAPP_MESSAGE_MESSAGE,
  };
};
