import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { CurrencyFormatter, saveAsExcelFile } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { clearPartsMessage, deletePartsFromList, getAudioBrandsListForParts, getPartsList, resetPartsMessage } from "src/store/parts/action";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const partsListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id;
    data['PartNumber'] = eachValue?.partNumber;
    data['HsnNumber'] = eachValue?.hsnNumber;
    data['Description'] = eachValue?.description;
    data['ReorderLevel'] = eachValue?.reorderLevel;
    data['taxPercentage'] = eachValue?.taxPercentage;
    data['inStockQuantity'] = eachValue?.inStockQuantity;
    data['audioBrandName'] = eachValue?.audioBrandName;
    data['audioBrandId']=eachValue?.audioBrandId;
    data['isDeleted'] = eachValue?.isDeleted;
    return data;
  });
  return modifeidValue;
}

export default function Parts() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.partsReducer.partsList);
  const modifiedValues: parts[] = partsListConverson(values);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.partsReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { error, message, paginatorCount,audioBrandList } = useSelector((state: any) => state.partsReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [rowData, setRowData] = useState<parts | null>(null);
  const filterDetails: DataTableFilterMeta = {
    PartNumber: { value: "", matchMode: "contains" },
    HsnNumber: { value: "", matchMode: "contains" },
    Description: { value: "", matchMode: "contains" },
    audioBrandName:{ value: "", matchMode: "contains" },
    ReorderLevel: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "PartNumber",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };
useEffect(()=>{
  dispatch(getAudioBrandsListForParts());
},[])
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.PARTS_CREATE);
  };

  const viewHandler = (rowData: parts) => {
    navigate(`${ROUTER.PARTS_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: parts) => {
    navigate(`${ROUTER.PARTS_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: parts) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  }, [audioBrandList])

  const actionBodyTemplate = (rowData: parts, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
      // <ColumnAction
      //   displayData={rowData[field] as string}
      //   isEdit={true}
      //   isDelete={true}
      //   isView={true}
      //   onViewClick={() => viewHandler(rowData)}
      //   onEditClick={() => editHandler(rowData)}
      //   onDeleteClick={() => deleteHandler(rowData)}
      // />
    );
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearPartsMessage());
    }

    () => {
      dispatch(resetPartsMessage())
    }
  }, [error, message]);

  const hidePopup = ()=>{
    setVisible(false); 
    dispatch(clearPartsMessage());
  }

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);
  
  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const currencyBodyTemplate = (rowData: parts, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };
  const AudioBrandFliter = (options: ColumnFilterElementTemplateOptions) => {

    return (<>
      <Dropdown
        options={(audioBrandList?.length > 0) ? (audioBrandList?.map((eachSupplier: any) => {
          let data: any = {};
          data['label'] = eachSupplier?.brandName;
          data['value'] = eachSupplier?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="audiobrandId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };

  const AudioBrandTemplate = (rowData: any, field: any) => {    
    return (
      <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData['audioBrandId']}`}>{rowData[field]}</Link>
    )
  }

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: " ",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      isDefault: true,
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isStatic: false,
      frozen : true,
    },
    {
      field: "PartNumber",
      sortable: true,
      header: "Part Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      isDefault: true,
      body: (rowData) => actionBodyTemplate(rowData, "PartNumber"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isStatic: true,
    },
    {
      field: "HsnNumber",
      sortable: true,
      header: "HSN Number",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "Description",
      sortable: true,
      header: "Description",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "audioBrandName",
      sortable: true,
      header: "Audio Brand",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>AudioBrandTemplate(rowData,'audioBrandName'),
      isStatic: false,
      filterElement: AudioBrandFliter
    },
    {
      field: "ReorderLevel",
      sortable: true,
      header: "Reorder Level",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right pr-4"
    },

    {
      field: "taxPercentage",
      sortable: true,
      header: "Tax Percentage",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => percentageBodyTemplate(rowData, "taxPercentage"),
      isStatic: false,
      className: "align-right"
    },
    {
      field: "inStockQuantity",
      sortable: true,
      header: "In Stock Quantity",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { id, PartNumber, HsnNumber, Description, ReorderLevel, taxPercentage, inStockQuantity, audioBrandName } = eachDealer;
      let data: any = {};
      //data['id'] = id,
        data['Part Number'] = PartNumber;
      data['HSN Number'] = HsnNumber;
      data['Description'] = Description;
      data['Reorder Level'] = ReorderLevel;
      data['Tax Percentage'] = taxPercentage;
      data['In Stock Quantity'] = inStockQuantity;
      data['Audio Brand'] = audioBrandName;
      return { ...data };
    });

    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        PartNumber: data.PartNumber,
        HsnNumber: data.HsnNumber,
        Description: data?.Description,
        ReorderLevel: data?.ReorderLevel,
        taxPercentage: data?.taxPercentage,
        inStockQuantity: data?.inStockQuantity,
        audioBrandName: data?.audioBrandName,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getPartsListAPI(lazy)
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue:any) => {
        let data: any = {};
        data['id'] = eachValue.id;
        data['PartNumber'] = eachValue?.partNumber;
        data['HsnNumber'] = eachValue?.hsnNumber;
        data['Description'] = eachValue?.description;
        data['ReorderLevel'] = eachValue?.reorderLevel;
        data['taxPercentage'] = eachValue?.taxPercentage;
        data['inStockQuantity'] = eachValue?.inStockQuantity;
        data['audioBrandName'] = eachValue?.audioBrandName;
        data['audioBrandId']=eachValue?.audioBrandId;
        data['isDeleted'] = eachValue?.isDeleted;
        return data;
      });
      // let resp=await partsListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "parts");
     }else{
      saveDataAsExcel(modifeidValue, "parts");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "parts");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "parts");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getPartsList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    if(selectedStatus=="All"){
      let filteredData=value?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedParts(filteredData);
      setSelectAll(value.length==paginatorCount)
      
    }else{
    setSelectedParts(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
    }
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if(selectedStatus=="All"){
      if (selectAll) {
      let filteredData=modifiedValues?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedParts(filteredData);
      setSelectAll(modifiedValues.length==filteredData.length)
      }else{
        setSelectAll(false);
        setSelectedParts([]);
      }
    }else{
    if (selectAll) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedParts([]);
    setLazyState(event);
  };


  const onFilter = (event: any) => {
    event["first"] = 0;
    const partDescriptionValue = JSON.parse(JSON.stringify(event.filters.audioBrandName));
    let obj = event
    obj.filters['audioBrandId']=partDescriptionValue
    setLazyState(obj);
  };

  const handleSubmit = (formValue: any) => {
    
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedParts([]);
    callbackFunction(lazyState);
  }, [lazyState]);



  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      lazyState,
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
    };
    dispatch(deletePartsFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      // showToast("Part Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(getPartsList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if(selectedStatus=="All"){
      let filteredData=modifiedValues?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedParts(filteredData);
      setSelectAll(modifiedValues.length==filteredData.length)
      
    }else{
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    }
  }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }


  const header =
    <div className="table-header-container">
      <div>
        Parts List
      </div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Parts"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedParts}
          bulkActionSatusTitle="Parts"
          showBorderRight={true}
        />  */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedParts.length == 0 && selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedParts.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedParts.length > 0 && <BulkActionStatus count={selectedParts.length} title="Parts"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>

          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          {/* <TooltipMEL title="Add Parts" position="bottom"> */}
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Parts
          </Button>
        </div>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }
            if (rowData || selectedParts.length === 1) {
              handleDeleteUsers(rowData || selectedParts);
            } else if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Parts  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
