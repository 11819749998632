import { EmployeeProfileTypes } from "./actionTypes"

export const getEmployeeIdLoading = () => {
    return {
      type: EmployeeProfileTypes.GET_EMPLOYEE_ID_LOADING,
    }
  }
  
  export const getEmployeeIdSuccess = (profile: any) => {
    return {
      type: EmployeeProfileTypes.GET_EMPLOYEE_ID_SUCCESS,
      payload: profile
    }
  }
  
  export const getEmployeeIdError = (error: any) => {
    return {
      type: EmployeeProfileTypes.GET_EMPLOYEE_ID_ERROR,
      payload: error
    }
  }

export const getEmployeeProfileLoading = (id: any) => {
    return {
      type: EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_LOADING,
      lazyState:id,
    }
  }
  
  export const getEmployeeProfileSuccess = (profile: any) => {
    return {
      type: EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_SUCCESS,
      payload: profile
    }
  }
  
  export const getEmployeeProfileError = (error: any) => {
    return {
      type: EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_ERROR,
      payload: error
    }
  }

  export const resetEmployeeProfile = () => {
    return{
        type:EmployeeProfileTypes.RESET_EMPLOYEE_PROFILE
    }
  }

  export const clearEmployeeProfileMessage = () => {
    return{
        type:EmployeeProfileTypes.CLEAR_EMPLOYEE_PROFILE
    }
  }

  export const getEmployeeImageProfileLoading = (customerId: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_FILE_FETCH_LOADING,
      payload: customerId,
    };
  }
  
  export const getEmployeeImageProfileSuccess = (customerDetails: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_FILE_FETCH_SUCCESS,
      payload: customerDetails,
    }
  }
  
  export const getEmployeeImageProfileError = (error: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_FILE_FETCH_ERROR,
      payload: error
    }
  }

  export const employeeDocumentProfileGetLoading = (customerId: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING,
      payload: customerId,
    };
  }
  
  export const employeeDocumentProfileGetSucces = (customerDetails: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS,
      payload: customerDetails,
    }
  }
  
  export const employeeDocumentProfileGetError = (error: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR,
      payload: error
    }
  }

  export const generateEmployeeDocumentLinkLoading = (customerId: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING,
      payload: customerId,
    };
  }
  export const generateEmployeeDocumentLinkSuccess = (customerId: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS,
      payload: customerId,
    };
  }
  export const generateEmployeeDocumentLinkError = (error: any) => {
    return {
      type: EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR,
      payload: error,
    };
  }