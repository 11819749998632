import React from 'react'
import { useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap'
import Button from 'src/components/UI/Button';
import { ROUTER } from 'src/constants/routes';


interface FeedbackPendingWaring {
    onHide: any;
    text?: string;
    feedbackList?: any
}

const FeedBackPendingStatus = (props: FeedbackPendingWaring) => {
    
    const navigate = useNavigate()
    const navigateFeedback = () => {
        navigate(`${ROUTER.FEEDBACK_CREATE}/${props.feedbackList[0]?.id}`);
    }
    return (
        <React.Fragment>
            <div className='feedback_container' id='feedback_container'>
                <div className='close_Menu'>
                    <div>Close</div>
                    <div className='close_menu_Cross' onClick={props.onHide}>
                        <span>
                            <i className="fas fa-times"></i>
                        </span>
                    </div>
                </div>
                <div className='feed_back_body'>
                    <div className='feed_back_headers'>
                        <div className='text'>Feedback Required</div>
                    </div>
                    <div className='text_btn_container'>
                        <div className='text'>
                            {/* Pending feedback on one or more past job sheets for this dealer */}
                            {/* Awaiting feedback for one or more previous job sheets */}
                            {`Awaiting feedback for one or more previous job sheets for this ${props.text} `}
                        </div>
                        <div className='btn_container'>
                            <Row>
                                <Col lg={12}>
                                    <Button className="btn-primary me-2 w-100" label="Redirect to Feedback" type="button" onClick={navigateFeedback} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FeedBackPendingStatus