import { permissionTypes } from "./actionTypes";

//grid
export const getRolesLoading = () => {
  
    return {
      type: permissionTypes.GET_ROLES_LOADING,
      
    };
  };
  export const getRolesSuccess = (Roles : any) => {
    return {
      type: permissionTypes.GET_ROLES_SUCCESS,
      payload : Roles   
    };
  };
  export const getRolesError = (error : any) => {
    return {
      type: permissionTypes.GET_ROLES_ERROR,
      payload : error
    };
  };
  
export const getPermissionsLoading = (role:any) => {
  
    return {
      type: permissionTypes.GET_PERMISSIONS_LOADING,
      role:role
    };
  };
  export const getPermissionsSuccess = (data : any) => {
    
    return {
      type: permissionTypes.GET_PERMISSIONS_SUCCESS,
      payload : data   
    };
  };
  export const getPermissionsError = (error : any) => {
    return {
      type: permissionTypes.GET_PERMISSIONS_ERROR,
      payload : error
    };
  };
  
  
export const updatePermissionsLoading = (role:any,permission:any) => {
  
    return {
      type: permissionTypes.UPDATE_PERMISSIONS_LOADING,
      role:role,
      permission:permission
    };
  };
  export const updatePermissionsSuccess = (data : any) => {
    
    
    return {
      type: permissionTypes.UPDATE_PERMISSIONS_SUCCESS,
      payload : data   
    };
  };
  export const updatePermissionsError = (error : any) => {
    return {
      type: permissionTypes.UPDATE_PERMISSIONS_ERROR,
      payload : error
    };
  };
  
  
export const createRoleLoading = (payload:any) => {
  
    return {
      type: permissionTypes.CREATE_ROLE_LOADING,
      payload:payload
    };
  };
  export const createRoleSuccess = (data : any) => {    
    return {
      type: permissionTypes.CREATE_ROLE_SUCCESS,
      payload : data   
    };
  };
  export const createRoleError = (error : any) => {
    return {
      type: permissionTypes.CREATE_ROLE_ERROR,
      payload : error
    };
  };
  
  export const clearPermissionMessage = () => {
    return {
      type: permissionTypes.CLEAR_PERMISSIONS_LOADING,
    };
  };
  