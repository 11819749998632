import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { ROUTER } from 'src/constants/routes';
import { CurrencyFormatter, formatDateMEL, hasCustomerRoleHandler, hasDealerRoleHandler } from 'src/helpers/common_helpers';
import { Columns } from 'src/models/components/dataTableWrapperModel';
import { clearReceivedPaymentMessage, downloadReceivedPayment, viewReceivedPaymentLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';

const ViewReceivedPayment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [visible, setVisible] = useState(false);
  const [razorpaymentDetails, setRazorPaymentDetails] = useState<any>([]);
  const [razorPaystatus, setRazorPaystatus] = useState(false);
  const { receivedPaymentDetails, loading, message, error } = useSelector((state: RootReducerState) => state.receivedPaymentReducer);


  useEffect(() => {
    if (id) {
      dispatch(viewReceivedPaymentLoading(id));
    }
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearReceivedPaymentMessage());
    }
  }, [message, error]);

  
  const htmlHandler = (htmlElement: string) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlElement }} className='pending-information-text'/>
      </div>
    );
  };
  
  const CourierDetails = {
    generalDetails: [
      // {
      //   name: "Invoice",
      //   value: receivedPaymentDetails?.invoices
      // },
      {
        name: "Payment Date",
        value: receivedPaymentDetails?.paymentDate ? formatDateMEL(receivedPaymentDetails?.paymentDate) : ""
      },
      {
        name: "Amount",
        value: receivedPaymentDetails?.amount
      },
      {
        name: "Total",
        value: receivedPaymentDetails?.total >= 0 ? CurrencyFormatter({ value: receivedPaymentDetails?.total }) : "-"
      },
      {
        name: "Tds Deduction",
        value: receivedPaymentDetails?.tdsDeduction
      },

      {
        name: "Recipient Name",
        value: receivedPaymentDetails?.recipientName
      },
    ],
  };
  const formvalue = receivedPaymentDetails?.paymentSplitByTypesDto.length > 0 ? receivedPaymentDetails?.paymentSplitByTypesDto?.map((eachValue: any) => {
    let data: any = {};
    data['paymenttype'] = eachValue?.paymenttype;
    data['amount'] = eachValue?.amount >= 0 ? CurrencyFormatter({ value: eachValue?.amount }) : "-";
    data['tdsdeduction'] = eachValue?.tdsdeduction >= 0 ? CurrencyFormatter({ value: eachValue?.tdsdeduction }) : "-";
    data['total'] = eachValue?.total;
    data['paymentnotes'] = eachValue?.paymentnotes;
    return data;
  }) : [];

  const taxvalue = receivedPaymentDetails?.invoiceReceivedPaymentDtoList.length > 0 ? receivedPaymentDetails?.invoiceReceivedPaymentDtoList?.map((eachValue: any) => {
    let data: any = {};
    data['invoicenumber'] = eachValue?.invoicenumber;
    data['amount'] = eachValue?.amount >= 0 ? CurrencyFormatter({ value: eachValue?.amount }) : "-";
    data['tdsdeduction'] = eachValue?.tdsdeduction >= 0 ? CurrencyFormatter({ value: eachValue?.tdsdeduction }) : "-";
    data['total'] = eachValue?.total;
    return data;
  }) : [];





  useEffect(() => {
    debugger;
    if (receivedPaymentDetails?.paymentSplitByTypesDto[0]?.paymenttype == "RazorPay") {
      setRazorPaystatus(true);
    } else {
      setRazorPaystatus(false);
    }
    const razorPaymentDetails = {
      payeeEmail: receivedPaymentDetails?.razorPayPaymentDto?.payeeEmail,
      payeeName: receivedPaymentDetails?.razorPayPaymentDto?.payeeName,
      payeePhone: receivedPaymentDetails?.razorPayPaymentDto?.payeePhone,
      razorPayId: receivedPaymentDetails?.razorPayPaymentDto?.razorPayId,
      razorPayStatus: receivedPaymentDetails?.razorPayPaymentDto?.razorPayStatus,
      razorPayUrl: receivedPaymentDetails?.razorPayPaymentDto?.razorPayUrl
    };
    setRazorPaymentDetails([razorPaymentDetails]);
  }, [receivedPaymentDetails]);



  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const columns: Columns[] = [
    {
      field: "paymenttype",
      sortable: false,
      header: "Payment Type",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      body: (rowData: any) => headerTemplate(rowData.amount),
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "tdsdeduction",
      sortable: false,
      header: "Tds Deduction",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.total),
      headerStyle: { whiteSpace: "nowrap", width: "150px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "paymentnotes",
      sortable: false,
      header: "Payment Notes",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "400px" },
      body: (rowData: any) => htmlHandler(rowData.paymentnotes),
      isDefault: true,
      isStatic: true,
    },
  ];

  const taxcolumns: Columns[] = [
    {
      field: "invoicenumber",
      sortable: false,
      header: "Invoices",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      body: (rowData: any) => headerTemplate(rowData.amount),
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "tdsdeduction",
      sortable: false,
      header: "Tds Deduction",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.total),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
  ];


  const razorPaymentColumn: Columns[] = [
    {
      field: "razorPayId",
      sortable: false,
      header: "RazorPayId",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "payeeName",
      sortable: false,
      header: "Payer Name",
      filter: false,
      // body: (rowData: any) => headerTemplate(rowData.amount),
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "payeeEmail",
      sortable: false,
      header: "Payer Email",
      filter: false,
      // body: (rowData: any) => headerTemplate(rowData.amount),
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "payeePhone",
      sortable: false,
      header: "Payer Phone",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "razorPayStatus",
      sortable: false,
      header: "Razor Pay Status",
      filter: false,
      showFilterMenu: false,
      // body: (rowData: any) => headerTemplate(rowData.total),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "razorPayUrl",
      sortable: false,
      header: "Razor Pay Url",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body:(rowData:any)=><b><Link to={rowData.razorPayUrl} target='_blank'>{rowData.razorPayUrl}</Link></b>,
      isDefault: true,
      isStatic: true,
    },
  ];

  const handleReceivedPayment = () => {

    dispatch(downloadReceivedPayment(id));
  };

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

      const htmlHandlerComments = (htmlElement: string) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlElement }} className='pending-information-Full'/>
          </div>
        );
      };

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className='userpage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Received Payment" titlePath={ROUTER.RECEIVED_PAYMENT_LIST} breadcrumbItem='Received Payment'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={((hasDealerRoleHandler() || hasCustomerRoleHandler()) ? false : true)}
            firstButtonLabel="Print Received Payment"
            onFirstButtonClick={handleReceivedPayment}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Received Payment Details</b>
              </div>
              <div className="subview">
                <Row>
                  {
                    CourierDetails.generalDetails.map((data, index) => {
                      return (
                        <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                          <div className='mb-2'>{data.name}</div>
                          <b>{data.value ? data.value : <div className='view-none-text'>-</div>}</b>
                        </Col>
                      );
                    })
                  }
                </Row>
                                    <div className="parent-div mt-2">
                                        <div className='text-bold'>Payment Notes :</div>
                                        <div>{receivedPaymentDetails?.paymentNotes ? htmlHandlerComments(receivedPaymentDetails?.paymentNotes) : "-"}</div>
                                    </div>
              </div>
            </div>
          </Row>

          {formvalue?.length > 0 &&
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Payment Modes</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={columns}
                        value={formvalue}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          }

          {taxvalue?.length > 0 &&
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Invoices</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={taxcolumns}
                        value={taxvalue}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          }


          { razorPaystatus &&
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Razor Pay</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={razorPaymentColumn}
                        value={razorpaymentDetails}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          }
        </Container>
      </div >
    </React.Fragment >
  );
};

export default ViewReceivedPayment;