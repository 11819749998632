import { Dialog } from "primereact/dialog";
import React from "react";
import DigitalSignature from "src/components/Common/DigitalSignature/DigitalSignature";


interface SignatureModalProps {
  onHide: any;
  visible?: boolean;
  onSubmit:any;
}

const JobsheetSignatureModal = ({
  onHide,
  visible = true,
  onSubmit
}: SignatureModalProps) => {
  const revertMeassage = "Are you sure you want to restore this? ";
  const revertlabel = "OK"
  return (
    <React.Fragment>
          <Dialog header="Consent Signature" visible={visible}  className='vw30' onHide={onHide}>
            <div className="m-0 .validation-text">
                <DigitalSignature onSubmit={onSubmit}></DigitalSignature>
            </div>
          </Dialog>
        </React.Fragment>
  );
};


export default JobsheetSignatureModal;
