import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Col, Row } from 'reactstrap';
import Button from 'src/components/UI/Button';

interface digitalSignatureProps{
    onSubmit:any;
}

const DigitalSignature = (props:digitalSignatureProps) => {

    const sigRef: any = useRef();
    const [signature, setSignature] = useState(null);
    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    }
    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
        if(showSubmit){
            setShowSubmit(false)
        }
    }
    const [showSubmit,setShowSubmit] = useState(false);

    useEffect(() => {
        
        if(!showSubmit){
            setShowSubmit(true)
        }
    }, [signature]);
    return (
        <Row id="signature">
            <Col lg={12} className='sign-border'>
                <SignatureCanvas
                    penColor="#5156BE"
                    canvasProps={{ className: 'sign-width' }}
                    ref={sigRef}
                    onEnd={handleSignatureEnd}
                />
            </Col>
            <Col lg={12}>
                <div className="d-flex justify-content-end mt-2 mb-2">
                    <Button
                        className="secondary-btn me-2"
                        label="Clear"
                        onClick={() => {
                            clearSignature()
                        }}
                        type="button"
                        />

                    <Button
                        className="btn-primary me-2"
                        label={"Submit"}
                        disabled={!signature}
                        onClick={() => {
                            props.onSubmit(signature)
                        }}
                        type="submit"
                    />
                </div>
            </Col>
           
        </Row>
    )
}

export default DigitalSignature