import React, { useRef } from 'react';
import BarcodeLabel from './BarcodeLabel';
import ReactToPrint from 'react-to-print';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import FeatherIcon from "feather-icons-react";
const BarcodePrinter = () => {
  const printStyle: any = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%', // Adjust based on sticker sheet width
    height: 'auto', // Let the height adjust based on content
    padding: '10px'
  };
  const componentRef: any = useRef();
  const { values } = useSelector((state: RootReducerState) => state.partSkuReducer.partSkuList);
  let numbers =values.map((val:any)=>val.number)
  return (
    <div className="page-content" id="stock-music-system">
    <div style={printStyle}>
        
      <ReactToPrint
        trigger={() => <Button type="button" className="customize-table-button mr-1 ml-1">
        <FeatherIcon icon="printer" className="mr-1" />
        Print Labels
      </Button>}
        content={() => componentRef.current}
      />

      <div ref={componentRef}>
        <Row className='d-flex justify-content-center'>
          {numbers.map((number: any, index: any) => (
            <Col lg={4} key={index} style={{width:"40%",display:"flex",justifyContent:"center"}}>
              <BarcodeLabel number={number} />
            </Col>
          ))}
        </Row>
      </div>
      </div>
    </div>
  );
};

export default BarcodePrinter;
