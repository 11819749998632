export enum IncentiveRewardTypes {
    GET_INCENTIVE_REWARD_LIST_LOADING = "@@incentive_reward/GET_INCENTIVE_REWARD_LIST_LOADING",
    GET_INCENTIVE_REWARD_LIST_SUCCESS = "@@incentive_reward/GET_INCENTIVE_REWARD_LIST_SUCCESS",
    GET_INCENTIVE_REWARD_LIST_FAIL = "@@incentive_reward/GET_INCENTIVE_REWARD_LIST_FAIL",

    GET_INCENTIVE_REWARD_LIST_BY_ID_LOADING = "@@incentive_reward/GET_INCENTIVE_REWARD_LIST_BY_ID_LOADING",
    GET_INCENTIVE_REWARD_LIST_BY_ID_SUCCESS = "@@incentive_reward/GET_INCENTIVE_REWARD_LIST_BY_ID_SUCCESS",
    GET_INCENTIVE_REWARD_LIST_BY_ID_FAIL = "@@incentive_reward/GET_INCENTIVE_REWARD_LIST_BY_ID_FAIL",
  
    UPDATE_PAYMENT_LOADING = "@@incentive_reward/UPDATE_PAYMENT_LOADING",
    UPDATE_PAYMENT_SUCCESS = "@@incentive_reward/UPDATE_PAYMENT_SUCCESS",
    UPDATE_PAYMENT_FAIL = "@@incentive_reward/UPDATE_PAYMENT_FAIL",
  
    UPDATE_ADJUSTMENT_POINT_LOADING = "@@incentive_reward/UPDATE_ADJUSTMENT_POINT_LOADING",
    UPDATE_ADJUSTMENT_POINT_SUCCESS = "@@incentive_reward/UPDATE_ADJUSTMENT_POINT_SUCCESS",
    UPDATE_ADJUSTMENT_POINT_FAIL = "@@incentive_reward/UPDATE_ADJUSTMENT_POINT_FAIL",

    
    CLEAR_MESSAGE_INCENTIVE_REWARD = "@@incentive_reward/CLEAR_MESSAGE_INCENTIVE_REWARD",
    RESET_MESSAGE_INCENTIVE_REWARD = "@@incentive_reward/RESET_MESSAGE_INCENTIVE_REWARD",
  }
  