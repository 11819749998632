import {StockQuantityBySupplierTypes} from './actionTypes'

//grid
export const getStockQuantityBySupplierLoading = (lazyState: any) => {
  return {
    type: StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_LOADING,
    lazyState,
  };
};
export const getStockQuantityBySupplierSuccess = (stockvalueList : any) => {
  return {
    type: StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_SUCCESS,
    payload : stockvalueList   
  };
};
export const getStockQuantityBySupplierError = (error : any) => {
  return {
    type: StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_ERROR,
    payload : error
  };
};
export const getSupplierDropdownLoading = () => {
  return {
    type: StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_LOADING,
  };
};
export const getSupplierDropdownSuccess = (stockvalueList : any) => {
  return {
    type: StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_SUCCESS,
    payload : stockvalueList   
  };
};
export const getSupplierDropdownError = (error : any) => {
  return {
    type: StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_ERROR,
    payload : error
  };
};