import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { StockRegisterTypes } from "./actionTypes";

import {createStockRegisterError, createStockRegisterSuccess, deleteStockREGISTERFromListSuccess, deleteStockRegisterFromListError, getStockREGISTERListSuccess, getStockRegisterByIdSuccess, getStockRegisterList, getStockRegisterListError, updateStockRegisterError, updateStockRegisterSuccess } from './action'


function* getStockRegisterListAsync(action : any) {
  
  try {
    const response : Promise<any> = yield call(API.getStockRegisterListAPI,{...action.lazyState,})
    
    yield put(getStockREGISTERListSuccess(response));
  } catch (error) {
    yield put(getStockRegisterListError(error));
  }
}


function* getStockRegisterByIdAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.getStockRegisterById,{id : action.id}
    )
    yield put(getStockRegisterByIdSuccess(response));
  } catch (error) {
  }
}




function* updateStockRegisterAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.updateStockRegisterListAPI ,{action}
        )
    yield put(updateStockRegisterSuccess(response))
    
    yield put(getStockRegisterList(action.lazyState))
  } catch (error) {
    yield put(updateStockRegisterError(error));
  }
}


function* deleteStockRegisterAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.deleteStockRegisterListAPI ,{id : action.payload.id ,lazyEvent : JSON.stringify(action)}
        )
    yield put(deleteStockREGISTERFromListSuccess(response))
    
    yield put(getStockRegisterList(action.lazyState))
  } catch (error) {
    yield put(deleteStockRegisterFromListError(error));
  }
}


function* StockRegisterSaga() {
  yield takeLatest(StockRegisterTypes.GET_STOCK_REGISTER_LIST, getStockRegisterListAsync);
  yield takeLatest(StockRegisterTypes.DELETE_STOCK_REGISTER, deleteStockRegisterAsync);
  yield takeLatest(StockRegisterTypes.GET_STOCK_REGISTER_BY_ID,getStockRegisterByIdAsync);
  yield takeLatest(StockRegisterTypes.UPDATE_STOCK_REGISTER,updateStockRegisterAsync);
}

export default StockRegisterSaga;