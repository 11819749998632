import { Form, Formik, FormikProps } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import Breadcrumb from 'src/components/Common/Breadcrumb'
import InputCom from 'src/components/Common/Input'
import { useToast } from 'src/components/Common/ReactToaster'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import Button from 'src/components/UI/Button'
import TooltipMEL from 'src/components/UI/Tooltip'
import { ROUTER } from 'src/constants/routes'
import { hasAdminOrEmployeeRoleHandler, hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler } from 'src/helpers/common_helpers'
import {
  additionalExpensesDocumentLinkLoading,
  clearAdditionalExpenseDocumentLink,
  getAdditionalExpenseDetailsLoading,
  jobSheetClearMessage,
  newAdditionalExpensesUpdateLoading,
} from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers'
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";


interface FormValues {
  expensesName: string;
  taxCode: string;
  taxPercentage: number;
  amountWithoutTax: number;
  finalAmount: number;
  netAmount: number;
  file: string
}

const EditAdditionalExpenses: React.FC = () => {
  const { id, job_id } = useParams();
  const [visible, setVisible] = useState(false);
  const [base64stringForUploadedFiles, setBase64stringForUploadedFiles] = useState<any>([]);
  const [addtionalExpensesFiles, setAddtionalExpensesFiles] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { additionalExpenseDetails, error, message, additionalExpensesLink } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const initialvalue = useMemo(() => {
    return {
      expensesName: additionalExpenseDetails?.expensesName ? additionalExpenseDetails?.expensesName : "",
      taxCode: additionalExpenseDetails?.taxCode ? additionalExpenseDetails?.taxCode : "",
      taxPercentage: additionalExpenseDetails?.taxPercentage ? additionalExpenseDetails?.taxPercentage : 0,
      amountWithoutTax: additionalExpenseDetails?.amountWithoutTax ? additionalExpenseDetails?.amountWithoutTax : 0,
      finalAmount: additionalExpenseDetails?.finalAmount ? additionalExpenseDetails?.finalAmount : 0,
      netAmount: additionalExpenseDetails?.netAmount ? additionalExpenseDetails?.netAmount : 0,
      file: ""
    }
  }, [id, additionalExpenseDetails])

  useEffect(() => {
    if (id) {
      dispatch(getAdditionalExpenseDetailsLoading(id))
    }
    return () => {
      dispatch(jobSheetClearMessage())
    }
  }, [id])

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        dispatch(jobSheetClearMessage())
        navigate(ROUTER.ADDITIONAL_EXPENSE_GRID + '/' + job_id);
      }, 500)
    }

  }, [error, message]);

  useEffect(()=>{
    return()=>{
      dispatch(jobSheetClearMessage())
    }
  },[])

  const handleSubmit = (values: any) => {
    debugger
    let base = base64stringForUploadedFiles?.length > 0 ? base64stringForUploadedFiles[0]?.split(",")[1] : null
    addtionalExpensesFiles
    let {file,...data} = values

    
    const payload = {
        jobId: Number(job_id),
        id: additionalExpenseDetails?.id,
        file:(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) ? base : null,
        fileContentType:(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) ?  (addtionalExpensesFiles?.length > 0 ? addtionalExpensesFiles[0][0]?.type :  null) : null,
        fileName:(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) ? (addtionalExpensesFiles?.length > 0 ? addtionalExpensesFiles[0][0]?.name : null) : null,
        ...data
      }
    
    dispatch(newAdditionalExpensesUpdateLoading(id, payload))
  }

  const validationSchema = Yup.object().shape({
    finalAmount: Yup.number().required(hasAdminOrEmployeeRoleHandler() ? 'Final Amount is required for admin employees' : undefined)
      .min(0, 'Final Amount must be at least 0'),
  })

  const hidePopup = () => {
    setVisible(false);
    dispatch(jobSheetClearMessage())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleFileRead = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFilesToBase64 = async (files: any) => {
    if (files?.length > 0) {
      const fileArray = Array.from(files);
      const promises = fileArray.map(file => handleFileRead(file));
      return await Promise.all(promises);
    } else {
      return
    }
  };

  useEffect(() => {
    if (additionalExpensesLink != "" && additionalExpensesLink != undefined && additionalExpensesLink != null) {
      const newWindow = window.open(additionalExpensesLink)
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearAdditionalExpenseDocumentLink())
    }
  }, [additionalExpensesLink])

  const opendocument = (id: any) => {
    dispatch(additionalExpensesDocumentLinkLoading(id))
  }

  const handleChangeAmount = (taxPercentage: number,amountWithTax: number, setFieldValue: any) => {
    debugger
    const netAmount = ((+amountWithTax * taxPercentage) / 100) + +amountWithTax
    setFieldValue(`netAmount`, +netAmount)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        {visible && dialogContainer(error)}
        <Container fluid>
          <Breadcrumb title='Additional Expense' titlePath={ROUTER.ADDITIONAL_EXPENSE_GRID + "/" + job_id} breadcrumbItem='Edit Additional Expense' />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      setFieldValue
                    }: FormikProps<FormValues>) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Expenses Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="expensesName"
                                onBlur={handleBlur}
                                value={values.expensesName}
                                disabled={hasFranchiseRoleHandler() ? false : true}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["expensesName"] && errors["expensesName"] ? true : false}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Tax Code"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="taxCode"
                                onBlur={handleBlur}
                                value={values.taxCode}
                                disabled={hasFranchiseRoleHandler() ? false : true}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["taxCode"] && errors["taxCode"] ? true : false}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Tax Percentage"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="taxPercentage"
                                onBlur={handleBlur}
                                value={values.taxPercentage}
                                disabled={hasFranchiseRoleHandler() ? false : true}
                                onChange={(e:any)=>{
                                  handleChangeAmount(e.target.value, values.amountWithoutTax,setFieldValue)
                                  handleChange(e)
                                }}
                                isRequired={false}
                                invalid={touched["taxPercentage"] && errors["taxPercentage"] ? true : false}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Amount"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="amountWithoutTax"
                                onBlur={handleBlur}
                                value={values.amountWithoutTax}
                                disabled={hasFranchiseRoleHandler() ? false : true}
                                onChange={(e:any)=>{
                                  handleChangeAmount(values.taxPercentage, e.target.value,setFieldValue)
                                  handleChange(e)
                                }}
                                isRequired={false}
                                invalid={touched["amountWithoutTax"] && errors["amountWithoutTax"] ? true : false}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Net Amount"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="netAmount"
                                onBlur={handleBlur}
                                value={values.netAmount}
                                disabled={hasFranchiseRoleHandler() ? false : true}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["netAmount"] && errors["netAmount"] ? true : false}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Final Amount"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="finalAmount"
                                onBlur={handleBlur}
                                value={values.finalAmount}
                                disabled={hasAdminOrEmployeeRoleHandler() ? false : true}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["finalAmount"] && errors["finalAmount"] ? true : false}
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2" >
                              { (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) &&
                                <>
                                <label htmlFor="Attachements">Attachements</label>
                                  <Input
                                    id="Attachements"
                                    labelName="Attachments"
                                    className={`form-control ${errors["file"] && "is-invalid"}`}
                                    type="file"
                                    name={`file`}
                                    onBlur={handleBlur}
                                    invalid={touched["file"] && errors["file"] ? true : false}
                                    value={values.file}
                                    onChange={async (event) => {
                                      const files: any = event.target.files;
                                      const fileArray = Array.from(files);
                                      const base64files = handleFilesToBase64(fileArray)
                                      base64files.then((res) => {
                                        
                                        setBase64stringForUploadedFiles((prevState: any, index: number) => {
                                          const newState = [...prevState];
                                          newState[0] = res?.[0];
                                          return newState;
                                        });
                                      })
                                      setAddtionalExpensesFiles((prevState: any) => {
                                        const newState = [...prevState];
                                        newState[0] = fileArray;
                                        return newState;
                                      });
                                      handleChange(event)
                                    }}
                                  />
                                </>
                              }
                            </Col>
                            <Col xl={4} lg={4} className="mb-2" style={{ marginTop: "30px" }}>
                              {
                                (additionalExpenseDetails?.document?.name != null) &&
                                <div style={{ width: "90%" }}>
                                   <b>Document</b>
                                  <div className='additionalExpenses'>
                                    <div style={{ color: "blue" }}>{additionalExpenseDetails?.document?.name}</div>
                                    <div style={{ cursor: "pointer", display: "flex", alignItems: 'center', gap: '10px' }}>
                                      {
                                        additionalExpenseDetails?.document?.name != null &&
                                        <FeatherIcon size={18} icon="eye" onClick={() => opendocument(additionalExpenseDetails?.document?.id)} />
                                      }
                                    </div>
                                  </div>
                                </div>
                              }
                            </Col>

                          </Row>
                          <div className="d-flex justify-content-end mt-2">
                            <TooltipMEL title={"Update Additional Details"} position='top'>
                              <Button
                                className="btn-primary me-2"
                                label={"Update Additional Details"}
                                type="submit"
                              />
                            </TooltipMEL>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditAdditionalExpenses