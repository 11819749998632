import React from 'react'
import { bulkActionStatusProbs } from 'src/models/components/bulkActionStatusModel';
import "./BulkActionStatus.scss"
import { motion } from 'framer-motion';
import { BUTTON_VARIANT } from 'src/constants/bulkActionVariant';


function BulkActionStatus({count,title}:bulkActionStatusProbs) {
  return (
    <div id="bulk-action-status">
      <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
      <div><span className='status'>{count}</span> <span className='remove_lables'>{`${title}`}</span> <span>selected</span></div>
      {/* <div><span className='status'>{count}</span> {`${title} selected`}</div> */}
      
      </motion.div>
       
    </div>
  )
}

export default BulkActionStatus
