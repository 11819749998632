import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { formatDateMEL } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { clearEnquiryMessage, getEnquiryBYID, resetEnquiryMessage } from 'src/store/enquiries/action'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'


const ViewEnquiries = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [visible, setVisible] = useState<boolean>(false);

    const { error, message, loading, jobSheetList, enquiry } = useSelector(
        (state: RootReducerState) => state.EnquiryReducer
    );

    const dispatch = useDispatch();

    useEffect(() => {
        const preloader: any = document.getElementById("preloader");
        if (loading) {
            preloader.style.display = "block";
        } else {
            preloader.style.display = "none";
        }
    }, [loading]);

    useEffect(() => {
        if (error) {
            setVisible(true)
            // showToast(error, { type: "error" })
        }

    }, [error])

    const hidePopup = () => {
        setVisible(false);
        dispatch(clearEnquiryMessage())
    };

    const dialogContainer = (error: any) => {
        return (
            <>
                <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
            </>
        )
    }


    const statusBodyTemplate = (value: boolean) => {
        let status = value;
        return (
            <StatusHghlighter status={status} />
        )

    }


    useEffect(() => {
        if (id) {
            dispatch(getEnquiryBYID(id));
        }
        return () => {
            dispatch(resetEnquiryMessage())
        }
    }, []);

    const userView = {
        generalDetails: [
            {
                name: "Job Sheet",
                value: enquiry?.jobNumber
            },
            {
                name: "Date Of Enquiry",
                value: formatDateMEL(enquiry?.dateOfEnquiry)
            },
            {
                name: "Enquirer Name",
                value: enquiry?.enquirerName
            },
            {
                name: "Is Customer",
                value: statusBodyTemplate(enquiry?.isCustomer)
            },
            {
                name: "Attended By",
                value: enquiry?.userName
            },

        ]
    }
    const htmlHandler = (htmlElement: string) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlElement }} className='pending-information-Full'/>
          </div>
        );
      };
    return (
        <React.Fragment>
            <div className='userpage'>
                {visible && dialogContainer(error)}
                <Container fluid>
                    <BreadcrumbWithFilter
                        title="Enquiry" titlePath={ROUTER.ENQUIRY_GRID} breadcrumbItem='Enquiry Details'
                        isShowCreate={false}
                        isShowFilter={false}
                        filter={false}
                    />
                    <Row>
                        <div className="view">
                            <div className="general-details">
                                <b>Enquiry Details</b>
                            </div>
                            <div className="subview">
                                <Row>
                                    {
                                        userView.generalDetails.map((data, index) => {
                                            return (
                                                <Col lg={3} key={index} className={`${index >= 4 ? "mt-1 mt-lg-4 gridCls" : "mt-1 gridCls"}`}>
                                                    <div className='mb-2'>{data.name}</div>
                                                    <b>{data.value ? data.value : <div className='view-none-text'>-</div>}</b>
                                                </Col>
                                            )
                                        })
                                    }
                                    {/* <hr /> */}

                                </Row>
                                    <div className="parent-div mt-2">
                                        <div className='text-bold'>Description :</div>
                                        <div>{enquiry?.description ? htmlHandler(enquiry?.description) : "-"}</div>
                                    </div>
                                    <div className="parent-div mt-2">
                                        <div className='text-bold'>Action Taken :</div>
                                        <div>{enquiry?.actionTaken ? htmlHandler(enquiry?.actionTaken) : "-"}</div>
                                    </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div >
        </React.Fragment >
    )
}

export default ViewEnquiries