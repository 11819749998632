import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import React from "react";

const statusBodyTemplate = (value:string)=>{
  let status = value as string;
  return (
    <>
    <StatusHghlighter status={status} />
    </>
  )

}

export const AdministratorDetails = [
    {
      name : "Profile Image",
      value : "_"
    },
    {
      name : "Name",
      value : "Ekaksh nayar dvm"
    },
    {
      name : "Email",
      value : "yevette@gmil-test.com"
    },
    {
      name : "Mobile Number",
      value : "+91 3208685654"
    },
    {
      name : "Address",
      value : "280, Ring road housing sector, Madhavaram bus station"
    },
    {
      name : "Detail Verified",
      value : statusBodyTemplate("YES")
    },
    {
      name : "Status",
      value : "✅"
    },
]