import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { createSupplierContactDetailsError, createSupplierContactDetailsSuccess, createSupplierError, createSupplierSuccess, deleteSupplierContactError, deleteSupplierContactSuccess, deleteSupplierDocumentError, deleteSupplierDocumentSuccess, deleteSupplierFromListError, deleteSupplierFromListSuccess, deleteSupplierImageError, deleteSupplierImageSuccess, getSupplierByIdSuccess, getSupplierByIsError, getSupplierContactDetailsError, getSupplierContactDetailsSuccess, getSupplierImageError, getSupplierImageSuccess, getSupplierListError, getSupplierListSuccess, supplierDocumentGetError, supplierDocumentGetSucces, supplierDocumentUploadError, supplierDocumentUploadSuccess, supplierLatLagError, supplierLatLagSuccess, supplierLinkGetError, supplierLinkGetSucces, updateSupplierError, updateSupplierSuccess, uploadSupplierFileError, uploadSupplierFileSuccess } from "./action";
import { SupplierTypes } from "./actionTypes";
import { SupplierData, supplierDetails } from "src/models/pages/supplierModel";
import { CustomerData } from "src/models/pages/customerModel";



function* getSupplierListAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.getSupplierListAPI,{
          ...action.lazyState,
        }
    )
    yield put(getSupplierListSuccess(response));
  } catch (error) {
    yield put(getSupplierListError(error));
  }
}

function* deleteSupplierAsync(action : any) {
  try {
    const response : Promise<any> = yield call(API.deleteSupplierListAPI,{id : action.payload.id ,undo: action.payload.undo})
    yield put(deleteSupplierFromListSuccess(response,action.payload.undo))  
  } catch (error) {
    yield put(deleteSupplierFromListError(error));
  }
}

function* createSupplierAsync({ payload }: any) {
  try {
    const response: supplierDetails = yield call(API.postSupplierAPI, payload);
    yield put(createSupplierSuccess(response));
    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createSupplierError(error));
  }
}
function* createSupplierContactAsync({ payload }: any) {
  try {
    const response: supplierDetails = yield call(API.postSupplierContactAPI, payload);
    yield put(createSupplierContactDetailsSuccess(response,));    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(updateSupplierError(error));
  }
}
function* getSupplierContactAsync({ payload }: any) {
  try {
    const response: supplierDetails = yield call(API.getSupplierContactAPI, payload);
    yield put(getSupplierContactDetailsSuccess(response));
    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(getSupplierContactDetailsError(error));
  }
}


function* deleteSupplierContactAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteSupplierContactAPI, {
      id: action.payload.id,
    });
    yield put(deleteSupplierContactSuccess(response));
  } catch (error) {
    yield put(deleteSupplierContactError(error));
  }
}

function* updateSupplierAsync({
    payload: { supplier, supplierId },
  }: any): Generator<any, void, any> {
    try {
      const { data } = yield call(API.updateSupplierAPI, supplier, supplierId);
   
      yield put(updateSupplierSuccess(data));
  
    } catch (error) {
      yield put(updateSupplierError(error));
    }
  }

  function* getSupplierByIDAsync({ payload }: any): Generator<any, void, any> {
    try {
      let { data } = yield call(API.getSupplierByIdAPI, payload);
      yield put(getSupplierByIdSuccess(data));
  
    } catch (error) {
      yield put(getSupplierByIsError(error));
    }
  }
  function* getSupplierImageAsync({ payload }: any) {
    try {
      const response: SupplierData = yield call(API.fetchSupplierImage, payload)
        yield put(getSupplierImageSuccess(response.data));
    } catch (error) {
      yield put(getSupplierImageError(error));
    }
  }
  
  function* deleteSupplierImageAsync({ payload }: any) {
    try {
      const response: SupplierData = yield call(API.deleteSupplierImage, payload)

      yield put(deleteSupplierImageSuccess(response.data));
    } catch (error) {
      yield put(deleteSupplierImageError(error));
    }
  }
  
  //update customer
  function* uploadSupplierFileAsync(action: any) {
  
    try {

      const response: Promise<any>= yield call(API.uploadSupplierImage, action.payload, { id: action.id });
        yield put(uploadSupplierFileSuccess(response))
      
    } catch (error) {
      yield put(uploadSupplierFileError(error))
    }
  }

  function* uploadDocumentSUpplierAsync(action: any) {
    try {
      const response: Promise<any> = yield call(API.uploadSupplierDoumentApi, action.payload, { id: action.id },action.fileType);
      yield put(supplierDocumentUploadSuccess(response))
    } catch (error) {
      yield put(supplierDocumentUploadError(error))
    }
  }
   
  function* getDocumentSupplierAsync({ payload }: any) {
    try {
      const response: Promise<any> = yield call(API.fetchSupplierdocumentApi, payload)
      console.count("saga")
      yield put(supplierDocumentGetSucces(response));
    } catch (error) {
      yield put(supplierDocumentGetError(error));
    }
  }
  function* getDocumentLinkSupplierAsync({ payload }: any) {
    try {
      let res = payload.id;
      const response: Promise<any> = yield call(API.fetchSupplierLinkdocumentApi, payload.id)
      yield put(supplierLinkGetSucces({response , res}));
    } catch (error) {
      yield put(supplierLinkGetError(error));
    }
  }
   
  function* deleteDocumentSupplierAsync(action : any) {
    debugger
    try {
      const response: Promise<any> = yield call(API.deleteSupplierDocumentApi, action.payload)
      yield put(deleteSupplierDocumentSuccess(response,action.payload));
    } catch (error) {
      yield put(deleteSupplierDocumentError(error));
    }
  }

  function* getlatLngAsync(action: any) {
    try {
      const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
      yield put(supplierLatLagSuccess(response, action.payload));
    } catch (error) {
      yield put(supplierLatLagError(error));
    }
  }
  
function* supplierSaga() {
  yield takeLatest(SupplierTypes.GET_SUPPLIER_LIST, getSupplierListAsync);
  yield takeLatest(SupplierTypes.DELETE_SUPPLIER_BRANDS, deleteSupplierAsync);
  yield takeLatest(SupplierTypes.POST_SUPPLIER, createSupplierAsync);
  yield takeLatest(SupplierTypes.POST_SUPPLIER_CONTACT, createSupplierContactAsync);
  yield takeLatest(SupplierTypes.GET_SUPPLIER_CONTACT, getSupplierContactAsync);
  yield takeLatest(SupplierTypes.DELETE_SUPPLIER_CONTACT, deleteSupplierContactAsync);
  yield takeLatest(SupplierTypes.UPDATE_SUPPLIER, updateSupplierAsync);
  yield takeLatest(SupplierTypes.GET_SUPPLIER_BY_ID, getSupplierByIDAsync);
  yield takeLatest(SupplierTypes.SUPPLIER_FILE_UPLOAD_LOADING, uploadSupplierFileAsync);
  yield takeLatest(SupplierTypes.SUPPLIER_FILE_FETCH_LOADING, getSupplierImageAsync);
  yield takeLatest(SupplierTypes.SUPPLIER_FILE_DELETE_LOADING, deleteSupplierImageAsync);
  yield takeLatest(SupplierTypes.POST_SUPPLIER_DOCUMENT, uploadDocumentSUpplierAsync);
  yield takeEvery(SupplierTypes.GET_SUPPLIER_DOCUMENT, getDocumentSupplierAsync);
  yield takeEvery(SupplierTypes.GET_SUPPLIER_DOCUMENT_LINK, getDocumentLinkSupplierAsync);
  yield takeEvery(SupplierTypes.DELETE_SUPPLIER_DOCUMENT, deleteDocumentSupplierAsync);
  yield takeEvery(SupplierTypes.GET_SUPPLIER_LOCATION_LOADING, getlatLngAsync);
}

export default supplierSaga;