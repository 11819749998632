import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { ContactDetail, DealerCreateModel } from "src/models/pages/dealerModel";
import {
  addDealer,
  clearDealerData,
  clearDealerMessage,
  dealerLatLagLoading,
  dealerTaxTypeList,
  deleteDealerContact,
  deleteDealerImageLoading,
  getDealerDetail,
  getDealerImageLoading,
  resendOtpDealer,
  resetOtpDealerMessage,
  sendDealerOtpLoading,
  setDealerEmailIdLoading,
  setDealerMobileNumberLoading,
  updateDealer,
  uploadDealerFileLoading,
} from "src/store/dealer/actions";
import DeleteModal from "../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import Dropzone from "react-dropzone";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { formatDateTOYYYYMMDD, getLocationLatLng, onDropRejectedFileHandler } from "src/helpers/common_helpers";
import { Dialog } from "primereact/dialog";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import TooltipMEL from "src/components/UI/Tooltip";
import DropdownInput from "src/components/UI/DropdownInput";
import DealerSubUserList from "../DealerSubUser/DealerSubUserList";
import MultiSelector from "src/components/Common/MultiSelector";
import ImagePreviewer from "src/components/Common/ImagePreviewer/ImagePreviewer";
import { FILE_UPLOAD_SIZE, errorMessage } from "src/constants/commonConstants";
import OtpScreen from "../Customers/OtpScreen";
import { APPENV_URL } from "src/util/appUrlEnv";

const DealerForm = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [dynamicForm, setDynamicForm] = useState(false);
  const [customError, setcustomError] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);

  const url = process.env.REACT_APP_API_CONFIGURATION + "/api/abp/application-configuration";;

  const formValue: any = useSelector(
    (state: RootReducerState) => state.dealer?.dealer as DealerCreateModel
  );




  const { error, message, loading, dealerTaxList, vehicleBrandList, dealerImage, phoneMessage, mailMessage, editedMobileNumber, createdData, otpmessage, updatedmessage,dealerLatLng } = useSelector(
    (state: RootReducerState) => state.dealer
  );
  const [canRedirect, setCanRedirect] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dobError, setDobError] = useState("");
  const [weddingDateError, setWeddingDateError] = useState("");
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");
  const taxTypes = dealerTaxList.length > 0 ? (dealerTaxList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.id;
    data['label'] = eachTax.taxName;
    return data;
  })) : [];


  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    vehicleBrand: Yup.array()
      .of(Yup.number())
      .min(1, 'At least one vehicle brand must be selected') // Ensure at least one value is selected
      .required('Vehicle brand is required'),

    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number').required('GST number is required'),
    // password: Yup.string().required(`Password is required`),
    // confirmpassword: Yup.string().required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match'),
    dealerCode: Yup.string().required(`Dealer code is required`),
    paymentType: Yup.string().required(`Payment type is required`),
    taxTypeId: Yup.string().required(`Tax type is required`),
    address: Yup.string().required("Address is required"),
    creditDays: Yup.number().required("Credit days is required").min(0, 'Credit days cannot be negative').max(999, 'Enter a valid credit days'),
    creditAmount: Yup.string().required("Credit amount is required").matches(/^[0-9]+$/, 'Enter a valid positive number for credit amount').min(0, 'Credit amount cannot be negative'),
    // dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
    //   const currentDate = moment();
    //   const { dateOfBirth } = this.parent;

    //   if (dateOfBirth) {
    //     if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
    //       return false;
    //     }        
    //   }

    //   setDobError("");
    //   return true;
    // }),
    // weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
    //   const { dateOfBirth } = this.parent;
    //   const dobDate = moment(dateOfBirth);

    //   if (dateOfBirth) {
    //     if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
    //       return false;
    //     }
    //   }
    //   setWeddingDateError("");
    //   return true;
    // }),
  });


  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [contactDetailFalse, setContactDetailFalse] = useState(false);
  const [visible, setVisible] = useState(false);
  const [validation, setvalidation] = useState<any>(validationSchema);
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);



  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(dealerTaxTypeList());
      dispatch(getDealerDetail(id));
    } else {
      dispatch(dealerTaxTypeList());
      getLocationLatLng(setLatitude, setLongitude);
    }
    return () => {
      setVisible(false);
      dispatch(clearDealerData());
      setLatitude("");
      setLongitude("");
      setAddress("");
      dispatch(resetOtpDealerMessage());
    };
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getDealerImageLoading(id));
    }
  }, [id, dispatch]);
  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
      // showToast(error, { type: "error" })
    }
    if (message) {
      setIsSubmitting(false);
      showToast(message, { type: "success" });
      dispatch(clearDealerData());
      if (message === 'Dealer updated successfully') {
        setIsSubmitting(false);
        // navigate(ROUTER.DEALERS_GRID);
      }
      if (id) {
        dispatch(dealerTaxTypeList());
        dispatch(getDealerDetail(id));
      } else {
        // navigate(ROUTER.DEALERS_GRID);
      }
      setLatitude("");
      setLongitude("");
      setAddress("");
    }
  }, [error, message]);
  useEffect(() => {
    if (updatedmessage) {
      setIsSubmitting(false);
      showToast(updatedmessage, { type: "success" });
      dispatch(clearDealerData());

      navigate(ROUTER.DEALERS_GRID);

      setLatitude("");
      setLongitude("");
      setAddress("");
    }
  }, [updatedmessage]);
  useEffect(() => {
    if (mailMessage != "" && mailMessage != undefined) {
      showToast(mailMessage, { type: "success" });
      dispatch(getDealerDetail(id));
      setEmailEdit(false);
    }
  }, [mailMessage]);
  useEffect(() => {
    if (otpmessage != "" && otpmessage != undefined) {
      showToast(otpmessage, { type: "success" });
      setOpenOtp(false);
      navigate(ROUTER.DEALERS_GRID);
      dispatch(resetOtpDealerMessage());
    }
  }, [otpmessage]);
  useEffect(() => {
    if (phoneMessage != "" && phoneMessage != undefined) {
      showToast(phoneMessage, { type: "success" });
      // dispatch(getDealerDetail(id));
      setPhoneEdit(false);
      setOpenOtp(true);
    }
  }, [phoneMessage]);

  useEffect(() => {
    if (createdData) {
      dispatch(setDealerMobileNumberLoading(createdData?.user?.phoneNumber, createdData?.user?.id));

    }
  }, [createdData]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearDealerMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const post = (formValue: DealerCreateModel) => {
    dispatch(addDealer(formValue));
  };

  const update = (formValue: DealerCreateModel) => {
    dispatch(updateDealer(formValue, id));
  };

  const validateCredit = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 56 || event.which === 69) {
      event.preventDefault();
    }
  };

  const initialvalue = {
    name: (formValue?.['user']?.['name'] || ""),
    email: (formValue?.['user']?.["email"] || ""),
    phoneNumber: (formValue?.['user']?.["phoneNumber"] || ""),
    alternateNumber: (formValue?.["user"]?.['extraProperties']?.["AlternateNumber"] || ""),
    userName: (formValue?.['user']?.["userName"] || ""),
    dealerCode: (formValue?.["dealerCode"] || ""),
    paymentType: (formValue?.["paymentType"] || ""),
    taxTypeId: (formValue?.["taxTypeId"] || ""),
    feedbackRequired: (formValue?.["feedbackRequired"] || false),
    permitFormRequired: (formValue?.["permitFormRequired"] || false),
    otherDetails: (formValue?.["otherDetails"] || ""),
    address: (formValue?.["user"]?.['extraProperties']?.["Address"] || ""),
    gstNumber: (formValue?.["user"]?.['extraProperties']?.['GstNumber'] || ""),
    sendSms: (formValue?.["user"]?.['extraProperties']?.["SendSms"] || false),
    sendEmail: (formValue?.["user"]?.['extraProperties']?.["SendEmail"] || false),
    sendWhatsapp: (formValue?.["user"]?.['extraProperties']?.["SendWhatsapp"] || false),
    vehicleBrand: formValue?.['dealerVehicleBrandsMaps']?.length > 0 ? formValue?.['dealerVehicleBrandsMaps'].filter((eachValue: any) => !eachValue.isDeleted).map((eachValue: any) => {
      return eachValue.vehicleBrandId;
    }) : [],
    weddingDate: (formValue?.["user"]?.['extraProperties']?.["WeddingDate"] ? formatDateTOYYYYMMDD(formValue?.["user"]?.['extraProperties']?.["WeddingDate"]) : "" || ""),
    dateOfBirth: (formValue?.["user"]?.['extraProperties']?.["DateOfBirth"] ? formatDateTOYYYYMMDD(formValue?.["user"]?.['extraProperties']?.["DateOfBirth"]) : "" || ""),
    creditAmount: formValue?.["creditAmount"] ?? "",
    creditDays: formValue?.["creditDays"] ?? "",
    isActive: (formValue?.["user"]?.["isActive"] || false),
    lockoutEnabled: (formValue?.["user"]?.["lockoutEnabled"] || false),
    isVerfied: (formValue?.["user"]?.["extraProperties"]?.["IsVerfied"] || false),
    userId: (formValue?.["user"]?.["id"] || ""),
    contactDetails: (formValue?.contactDetails?.length > 0 ? formValue.contactDetails.map((eachContact: ContactDetail) => {
      let data: any = {};
      data['id'] = eachContact?.id;
      data['name'] = eachContact?.name;
      data['designation'] = eachContact?.designation;
      data['mobileNumber'] = eachContact?.mobileNumber;
      data['alternateNumber'] = eachContact?.alternateNumber;
      data['email'] = eachContact?.email;
      data['sendSms'] = eachContact?.sendSms;
      data['sendEmail'] = eachContact?.sendEmail;
      return data;
    }) : [
    ])

  };
  useEffect(() => {
    if (formValue?.["user"]?.['extraProperties']?.["Address"]) {
      setAddress(formValue?.["user"]?.['extraProperties']?.["Address"]);
      dispatch(dealerLatLagLoading(formValue?.["user"]?.['extraProperties']?.["Address"]))
    }
  }, [formValue]);
  const paymentTypes = [
    { label: "Cash", value: "Cash" },
    { label: "Card", value: "Card" },
    { label: "Credit", value: "Credit" },
  ];

  const handleSubmit = async (values: any) => { 
    if (true) {
      if (isSubmitting ) return;
      const submittedData: any = {
        "dealerCode": values.dealerCode,
        "paymentType": values.paymentType,
        "taxTypeId": values.taxTypeId,
        "feedbackRequired": values.feedbackRequired,
        "permitFormRequired": null,
        "otherDetails": null,
        "creditAmount": values.creditAmount,
        "creditDays": values.creditDays,
        "user": {
          "userName": values.userName,
          "name": values.name,
          "email": values.email,
          "phoneNumber": id ? null : values.phoneNumber.toString(),
          // "address": address,
          "address": values.address,
          "dateOfBirth": null,
          "gstNumber": values.gstNumber,
          "sendSms": values.sendSms,
          "sendEmail": values.sendEmail,
          "sendWhatsapp": values.sendWhatsapp,
          "weddingDate": null,
          "password": values.password,
          "lockoutEnabled": values.lockoutEnabled,
          "isActive": values.isActive,
          "isVerfied": values.isVerfied,
          "emailConfirmed": true,
          "alternateNumber": values.alternateNumber?.toString()
        },
        "vehicleBrandMapList": values.vehicleBrand.map((eachvalue: any) => {
          let vehicleId = formValue?.['dealerVehicleBrandsMaps'].filter((each: any) => each.vehicleBrandId == eachvalue)?.[0]?.id;
          let vehicleLength = formValue?.['dealerVehicleBrandsMaps'].filter((each: any) => each.vehicleBrandId == eachvalue).length;
          let data = {
            "id": vehicleId ?? 0,
            "dealerId": (vehicleLength && id) ? id : 0,
            "vehicleBrandId": eachvalue
          };
          return data;
        })
      };



      if (id) {
        submittedData['user']['id'] = values.userId;
        submittedData['contactDetails'] = {
          "userId": formValue.user.id,
          "contactDetails": values.contactDetails.map((eachContact: ContactDetail) => {
            let data: any = eachContact;
            data['mobileNumber'] = eachContact.mobileNumber.toString();
            data['alternateNumber'] = eachContact.alternateNumber.toString();
            return data;
          })
        };
        if (selectedFiles.length > 0) {
          dispatch(uploadDealerFileLoading(selectedFiles[0], id));
        }
      }
      id ? update(submittedData) : post(submittedData);
      setIsSubmitting(true);
    }
  };

  const validateWeddingDate = (dateOfBirth: any, weddingDate: any) => {
    // alert(123);
  };


  const validationSchemaForEdit = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    vehicleBrand: Yup.array()
      .of(Yup.number())
      .min(1, 'At least one vehicle brand must be selected') // Ensure at least one value is selected
      .required('Vehicle brand is required'),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number').required('GST number is required'),
    // password: Yup.string(),
    // confirmpassword: Yup.string().test('match-password', 'Password must match', function (value) {
    //   const { password } = this.parent;
    //   if (password && password.length > 0) {
    //     return value === password;
    //   }
    //   return true;
    // }),

    // dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
    //   const currentDate = moment();
    //   const { dateOfBirth } = this.parent;
    //   if (dateOfBirth) {
    //     if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
    //       return false;
    //     }
    //   }
    //   setDobError("");
    //   return true;
    // }),
    // weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
    //   const { dateOfBirth } = this.parent;
    //   const dobDate = moment(dateOfBirth);
    //   if (dateOfBirth) {
    //     if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
    //       return false;
    //     } else if (value && moment(value).isBefore(dateOfBirth)) {
    //       return false;
    //     }
    //   }
    //   setWeddingDateError("");
    //   return true;
    // }),
    dealerCode: Yup.string().required(`Dealer code is required`),
    paymentType: Yup.string().required(`Payment type is required`),
    taxTypeId: Yup.string().required(`Tax type is required`),
    address: Yup.string().required("Address is required"),
    creditDays: Yup.number().required("Credit days is required").min(0, 'Credit days cannot be negative').max(999, 'Enter a valid credit days'),
    creditAmount: Yup.string().required("Credit amount is required"),
    contactDetails: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`Name is required`),
        designation: Yup.string().required('Designation is required'),
        mobileNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
        email: Yup.string().required(`Email is required`).test('valid-email', 'Enter a valid email', function (value) {
          if (!value) return true; // Let Yup handle the "required" validation
          return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
        }),
        alternateNumber: Yup.string().matches(/^\d{10}$/, 'Alternate number must be 10 digits'),
      }))
  });


  const validationWithoutDynamic = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    vehicleBrand: Yup.array()
      .of(Yup.number())
      .min(1, 'At least one vehicle brand must be selected') // Ensure at least one value is selected
      .required('Vehicle brand is required'),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number').required('GST number is required'),
    // password: Yup.string(),
    // confirmpassword: Yup.string().test('match-password', 'Password must match', function (value) {
    //   const { password } = this.parent;
    //   if (password && password.length > 0) {
    //     return value === password;
    //   }
    //   return true;
    // }),
    // dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
    //   const currentDate = moment();
    //   const { dateOfBirth } = this.parent;
    //   if (dateOfBirth) {
    //     if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
    //       return false;
    //     }
    //   }
    //   setDobError("");
    //   return true;
    // }),
    // weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
    //   const { dateOfBirth } = this.parent;
    //   const dobDate = moment(dateOfBirth);
    //   if (dateOfBirth) {
    //     if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
    //       // setWeddingDateError("Dob and Wedding date must not be same");
    //       return false;
    //     }
    //     // else if (value && moment(value).isBefore(dateOfBirth)) {
    //     //   // setWeddingDateError("Wedding date cannot be before date of birth");
    //     //   return false;
    //     // }
    //   }
    //   setWeddingDateError("");
    //   return true;
    // }),
    dealerCode: Yup.string().required(`Dealer code is required`),
    paymentType: Yup.string().required(`Payment type is required`),
    taxTypeId: Yup.string().required(`Tax type is required`),
    address: Yup.string().required("Address is required"),
    creditDays: Yup.number().required("Credit days is required").min(0, 'Credit days cannot be negative').max(999, 'Enter a valid credit days'),
    creditAmount: Yup.string().required("Credit amount is required"),
  });



  const removeIndex = (i: number) => {
    setselectedFiles((prevFiles: any) => {
      const newFiles = [...prevFiles];
      newFiles.splice(i, 1);
      return newFiles;
    });
  };

  function handleAcceptedFiles(files: any) {

    let filesModified = files.map((file: any) => {

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );


    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }

  /**
     * Formats the size
     */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.DEALERS_GRID);
    }
  };



  const handleAddContactDetails = (values: any, push: any, setFieldTouched: any) => {
    if (isContactDetailFilled(values)) {
      push({
        name: "",
        designation: "",
        mobileNumber: "",
        alternateNumber: "",
        email: "",
        sendSms: false,
        sendEmail: false,
        isDelete: false,
      });
      setDynamicForm(true);
      setContactDetailFalse(false);
    } else {
      values.contactDetails?.forEach((_: any, index: number) => {
        setFieldTouched(`contactDetails[${index}].name`, true);
        setFieldTouched(`contactDetails[${index}].designation`, true);
        setFieldTouched(`contactDetails[${index}].mobileNumber`, true);
        setFieldTouched(`contactDetails[${index}].alternateNumber`, true);
        setFieldTouched(`contactDetails[${index}].email`, true);
      });
      setContactDetailFalse(true);
    }
  };

  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.contactDetails || [];
    return contactDetails.every((contact: any) => {
      const { alternateNumber, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (formValue?.contactDetails?.length > 0) {
      setDynamicForm(true);
    } else {
      setDynamicForm(false);
    }
  }, [formValue]);


  useEffect(() => {
    if (id) {
      setvalidation(validationSchemaForEdit);
    } else if (id && !dynamicForm) {
      setvalidation(validationWithoutDynamic);
    } else {
      setvalidation(validationSchema);
    }
  }, [id, dynamicForm]);

  const handleAddDynamic = () => {
    setDynamicForm(true);
  };


  const dealerSubUserClick = () => {
    if (formValue) {
      localStorage.setItem('formvalues', JSON.stringify(formValue));
    }
    navigate(ROUTER.DEALER_SUB_USER_CREATE_ID + '/' + id, { state: { formValue } });

  };


  const removeCustomerImage = () => {
    setDeleteModal(false);
    if (dealerImage) {
      dispatch(deleteDealerImageLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };

  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };

  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };


  const result = formValue?.['dealerVehicleBrandsMaps']?.map((item: any) => {
    const matchedVehicle = vehicleBrandList.find((v: any) => v.id === item.vehicleBrandId);
    return {
      ...item,
      vehicleName: matchedVehicle ? matchedVehicle.name : null,
    };
  });

  const [otpValue, setOtpValue] = useState<any>(null);
  const [openOtp, setOpenOtp] = useState<any>(false);
  const handleOtpSubmit = () => {
    if (id) {

      dispatch(sendDealerOtpLoading(otpValue, formValue?.user?.id, editedMobileNumber));
    } else {
      dispatch(sendDealerOtpLoading(otpValue, createdData?.user?.id, createdData?.user?.phoneNumber));
    }
    // setOpenOtp(false)
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpDealerMessage());
    if (id) {
      dispatch(getDealerDetail(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };

  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const handleResendOtp = (number: any) => {
    dispatch(resendOtpDealer(formValue?.user?.id, number));
  };

  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {

    setPhoneEdit(false);
    dispatch(setDealerMobileNumberLoading(values.phoneNumber, formValue?.user?.id));


  };
  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };


  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {
    setEmailEdit(false);
    dispatch(setDealerEmailIdLoading(values.email, formValue?.user?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const handleAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(dealerLatLagLoading(event.target.value))
      }
    }
  }

  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeCustomerImage();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {customError && dialogContainerCustomError(errorMessage)}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dealers"
            titlePath={ROUTER.DEALERS_GRID}
            breadcrumbItem={id ? "Edit Dealer" : "Create Dealer"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validation}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      setFieldTouched,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Dealer Code"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="dealerCode"
                                onBlur={handleBlur}
                                value={values.dealerCode}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["dealerCode"] &&
                                    errors["dealerCode"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="GST Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="gstNumber"
                                onBlur={handleBlur}
                                value={values.gstNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["gstNumber"] &&
                                    errors["gstNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>


                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Phone"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="phoneNumber"
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={true}
                                invalid={
                                  touched["phoneNumber"] &&
                                    errors["phoneNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["phoneNumber"] &&
                                      errors["phoneNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (<FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                  onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                />
                                )}

                              </div>
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="alternateNumber"
                                onBlur={handleBlur}
                                value={values.alternateNumber}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={false}
                                invalid={
                                  touched["alternateNumber"] &&
                                    errors["alternateNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName={"Password"}
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["password"] &&
                                    errors["password"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName={"Confirm Password"}
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="confirmpassword"
                                onBlur={handleBlur}
                                value={values.confirmpassword}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["confirmpassword"] &&
                                    errors["confirmpassword"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e) => {
                                  let event = { ...e }
                                  event.target.value = e.target.value.toLocaleLowerCase()
                                  handleChange(event)
                                }}
                                isRequired={true}
                                invalid={
                                  touched["email"] && errors["email"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Official Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={paymentTypes}
                                name="paymentType"
                                label="Payment Type"
                                onChange={handleChange}
                                required={true}
                                value={values.paymentType}
                                onBlur={handleBlur}
                                invalid={
                                  touched["paymentType"] && errors["paymentType"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={taxTypes}
                                name="taxTypeId"
                                label="Tax Type"
                                onChange={handleChange}
                                required={true}
                                value={values.taxTypeId}
                                onBlur={handleBlur}
                                invalid={
                                  touched["taxTypeId"] && errors["taxTypeId"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Credit Amount"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="creditAmount"
                                onBlur={handleBlur}
                                value={values.creditAmount}
                                onChange={handleChange}
                                onKeyDown={validateCredit}
                                isRequired={true}
                                min={0}
                                invalid={
                                  touched["creditAmount"] &&
                                    errors["creditAmount"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Credit Days"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="creditDays"
                                onBlur={handleBlur}
                                value={values.creditDays}
                                onChange={handleChange}
                                isRequired={true}
                                onKeyDown={validateCredit}
                                min={0}
                                invalid={
                                  touched["creditDays"] &&
                                    errors["creditDays"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Date of Birth"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="dateOfBirth"
                                onBlur={handleBlur}
                                value={values.dateOfBirth}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["dateOfBirth"] &&
                                    errors["dateOfBirth"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Wedding Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="weddingDate"
                                onBlur={handleBlur}
                                value={values.weddingDate}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["weddingDate"] &&
                                    errors["weddingDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col xl={3} lg={4} className="mb-2">
                              <MultiSelector
                                name="vehicleBrand"
                                id="vehicleBrand"
                                label="Vehicle brands"
                                options={vehicleBrandList.filter((eachValue) => !eachValue.isDeleted).sort((a: any, b: any) => {
                                  const labelA = a.name.toUpperCase().trim();
                                  const labelB = b.name.toUpperCase().trim();
                                  if (labelA < labelB) {
                                    return -1;
                                  }
                                  if (labelA > labelB) {
                                    return 1;
                                  }
                                  return 0;
                                })}
                                optionLabel="name"
                                optionValue="id"
                                labelClassName="form-Label"
                                onBlur={handleBlur}
                                value={values.vehicleBrand}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["vehicleBrand"] && errors["vehicleBrand"] ? true : false}

                              />
                            </Col>
                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Other Details"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="otherDetails"
                                rowHeight={4}
                                onBlur={handleBlur}
                                value={values.otherDetails}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["otherDetails"] &&
                                    errors["otherDetails"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Row>

                              <Col lg={3} className="custom_gap mb-2" >
                                {/* <CheckboxInput
                                name="permitFormRequired"
                                label="Permit Form Required"
                                value={values["permitFormRequired"]}
                                onChange={handleChange}
                                {...rest}
                              /> */}
                                <CheckboxInput
                                  name="feedbackRequired"
                                  label="Feedback Required"
                                  value={values["feedbackRequired"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                                <CheckboxInput
                                  name="sendSms"
                                  label="Send SMS"
                                  value={values["sendSms"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>

                              <Col lg={3} className="custom_gap mb-2" >


                                <CheckboxInput
                                  name="sendEmail"
                                  label="Send Email"
                                  value={values["sendEmail"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                                <CheckboxInput
                                  name="sendWhatsapp"
                                  label="Whatsapp"
                                  value={values["sendWhatsapp"]}
                                  onChange={handleChange}
                                  {...rest}
                                />

                              </Col>
                              <Col lg={3} className="custom_gap mb-2" >

                                <CheckboxInput
                                  name="isActive"
                                  label="Is Active"
                                  value={values["isActive"]}
                                  onChange={handleChange}
                                  {...rest}
                                />

                                <CheckboxInput
                                  name="isVerfied"
                                  label="Is Verified"
                                  value={values["isVerfied"]}
                                  onChange={handleChange}
                                  {...rest}
                                />


                              </Col>
                              <Col lg={3} className="custom_gap mb-2" >
                                <CheckboxInput
                                  name="lockoutEnabled"
                                  label="Lockout Enabled"
                                  value={values["lockoutEnabled"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                            </Row>
                            <Col lg={12} className="mb-2">
                              <InputCom
                                labelName="Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="address"
                                // onBlur={(e:any)=>{handleBlur(e) ; if(values.address){ dispatch(dealerLatLagLoading(values.address)) }}}
                                onBlur={handleBlur}
                                onKeyDown={(e)=>{handleAddress(e)}}

                                value={values.address}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["address"] && errors["address"]
                                    ? true
                                    : false
                                }
                              />
                              <p style={{ fontSize: "12px",marginBottom:"3px"}}>Please press the Enter key after entering the Address.</p>
                              {dealerLatLng && 
                              <iframe src={`https://maps.google.com/maps?q=${dealerLatLng.lat},${dealerLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }

                              {/* <AddressMapFields address={address} setAddress={setAddress} lat={latitude} lng={longitude} title="Address" ></AddressMapFields> */}

                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={formValue?.["user"]?.['extraProperties']?.["Address"] ? formValue?.["user"]?.['extraProperties']?.["Address"] : ""} title="Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}

                            </Col>
                            {id && <React.Fragment>
                              <div className="mt-1">
                                <h5>Dealer Profile</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {(selectedFiles.length == 0 && !dealerImage) && <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onError={(err: Error) => { console.log("file error", err); }}
                                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                        maxFiles={1}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} accept="image/*" />
                                              <div className="">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>}

                                      {(selectedFiles[0]?.preview || dealerImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : dealerImage} removeIndex={deleteConfirmationHandler} />}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>}





                            {/* <div style={{ marginBottom: "20px" }}></div> */}
                            {!id &&
                              <div className="d-flex justify-content-end">
                                {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'> */}
                                <Button
                                  className="secondary-btn me-2"
                                  label={id ? "Cancel" : "Reset"}
                                  type="button"
                                  onClick={() => handleReset(resetForm)}
                                />
                                {/* </TooltipMEL>
                                <TooltipMEL title={id ? "Update Dealer" : "Create Dealer"} position='top'> */}
                                <Button
                                  className="btn-primary me-2 btn-primary-shadow"
                                  label={id ? "Update Dealer" : "Create Dealer"}
                                  type="submit"
                                />
                                {/*   </TooltipMEL> */}
                              </div>
                            }

                          </Row>

                          {id && <Row>
                            <hr />
                            <Col lg={12} className="dealer-title-header">
                              <div>Contact Details</div>
                            </Col>


                            <FieldArray name="contactDetails">
                              {({ insert, remove, push }) => (
                                <div >
                                  {values.contactDetails?.length > 0 &&

                                    values.contactDetails.map(
                                      (value: any, index: any) => {
                                        return <div key={index}>
                                          <div className="dynamic-form-container">
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Name"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="text"
                                                name={`contactDetails[${index}].name`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={!dynamicForm ? false : true}
                                                value={value.name}
                                                invalid={
                                                  touched.contactDetails?.[
                                                    index
                                                  ]?.name &&
                                                    errors.contactDetails?.[index]
                                                      ?.name
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Designation"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="text"
                                                name={`contactDetails.${index}.designation`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={!dynamicForm ? false : true}
                                                value={value.designation}
                                                invalid={
                                                  touched.contactDetails?.[
                                                    index
                                                  ]?.designation &&
                                                    errors.contactDetails?.[index]
                                                      ?.designation
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Mobile"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`contactDetails.${index}.mobileNumber`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={!dynamicForm ? false : true}
                                                value={value.mobileNumber}
                                                onKeyDown={preventSpecialChar}
                                                invalid={
                                                  touched.contactDetails?.[
                                                    index
                                                  ]?.mobileNumber &&
                                                    errors.contactDetails?.[index]
                                                      ?.mobileNumber
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>

                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Email"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="email"
                                                name={`contactDetails.${index}.email`}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                  let event = { ...e };
                                                  event.target.value = e.target.value.toLocaleLowerCase();
                                                  handleChange(event);
                                                }}
                                                isRequired={!dynamicForm ? false : true}
                                                value={value.email}
                                                invalid={
                                                  touched.contactDetails?.[index]?.email && errors.contactDetails?.[index]?.email
                                                }
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Alternate Number"
                                                inputclassName={`form-control`}
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`contactDetails.${index}.alternateNumber`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={false}
                                                value={value.alternateNumber}
                                                invalid={
                                                  touched.contactDetails?.[
                                                    index
                                                  ]?.alternateNumber &&
                                                    errors.contactDetails?.[index]
                                                      ?.alternateNumber
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-last" >
                                              <CheckboxInput
                                                name={`contactDetails.${index}.sendSms`}
                                                label="Send SMS"
                                                value={value.sendSms}
                                                onChange={handleChange}
                                                {...rest}
                                              />

                                              <CheckboxInput
                                                name={`contactDetails.${index}.sendEmail`}
                                                label="Send Email"
                                                // value={values.contactDetails[index].sendEmail}
                                                value={value.sendEmail}
                                                onChange={handleChange}
                                                {...rest}
                                              />

                                              <div className="space-equally-2 add-btn-container">
                                                <TooltipMEL title="Remove Contact Details" position='bottom'>

                                                  <i className="fas fa-times-circle color-red" style={{ fontSize: "16px" }}
                                                    // icon="x-circle"
                                                    onClick={() => {
                                                      if (!value.id) {
                                                        remove(index);
                                                        setContactDetailFalse(false);
                                                      } else {
                                                        let payload = {
                                                          id: value.id
                                                        };
                                                        dispatch(deleteDealerContact(payload));
                                                        setCanRedirect(false);
                                                      }
                                                    }
                                                    }>
                                                  </i>
                                                </TooltipMEL>
                                              </div>
                                            </div>

                                          </div>
                                        </div>;
                                      }
                                    )}

                                  <div className="multiadd-container">
                                    {<div className="add-btn-container" onClick={() => {
                                      handleAddContactDetails(values, push, setFieldTouched);
                                    }}>
                                      <TooltipMEL title="Add Contact Details" position='bottom'>
                                        <i className="fas fa-plus-circle"></i><span>Add</span>
                                      </TooltipMEL>
                                    </div>
                                    }
                                  </div>
                                  <hr className="mt-1" />
                                </div>
                              )}
                            </FieldArray>

                          </Row>
                          }

                          {id &&
                            <div className="d-flex justify-content-end mt-2">
                              {(formValue?.user?.phoneNumberConfirmed != true) &&
                                <Button
                                  className="next-status-btn me-2 mt-2"
                                  label={"Verify Mobile No"}
                                  type="button"
                                  onClick={() => handleResendOtp(values.phoneNumber)}
                                />
                              }
                              <Button
                                className="secondary-btn me-2"
                                label={id ? "Cancel" : "Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />

                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={id ? "Update Dealer" : "Create Dealer"}
                                type="submit"
                              />

                            </div>
                          }
                        </Form>
                      );
                    }}
                  </Formik>

                  {/* Dealer SubUser List */}
                  {id && <Row className="mt-3">
                    <Col lg={12} className="dealer-newtitle-header">
                      <div>Dealer SubUser Details</div>
                      <Button
                        className="btn-primary btn-primary-shadow"
                        label={id && "Dealer Subuser Create"}
                        type="submit"
                        onClick={dealerSubUserClick}
                      />
                    </Col>
                    <DealerSubUserList DealerId={id} formValue={formValue} />
                  </Row>}
                </React.Fragment>
              </CardBody>


            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default DealerForm;
