import { subUser } from "src/models/pages/subUserModel";
import { SubUserType } from "./actionTpes";



//grid

export const getSubuserListLoading = (lazyState: any) => {
  debugger
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_LIST_LOADING,
    lazyState,
  }
}

export const getSubuserListSuccess = (subuser: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_LIST_SUCCESS,
    payload: subuser
  }
}

export const getSubuserListError = (error: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_LIST_ERROR,
    payload: error
  }
}

//delete

export const deleteSubuserLoading = (payload: any) => {
  return {
    type: SubUserType.DELETE_FRANCHISESUBUSER_LOADING,
    payload: payload,
    
  }
}

export const deleteSubuserSuccess = (response: any,undo:boolean) => {
  return {
    type: SubUserType.DELETE_FRANCHISESUBUSER_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteSubuserError = (error: any) => {
  return {
    type: SubUserType.DELETE_FRANCHISESUBUSER_ERROR,
    payload: error
  }
}

//create Subuser

export const createSubuserLoading = (subuser: any) => {
  return {
    type: SubUserType.CREATE_FRANCHISESUBUSER_LOADING,
    payload: subuser,
    
  }
}

export const createSubuserSucess = (subuser : any) => {
  return {
    type: SubUserType.CREATE_FRANCHISESUBUSER_SUCCESS,
    payload : subuser
  }
}

export const createSubuserError = (error: any) => {
  return {
    type: SubUserType.CREATE_FRANCHISESUBUSER_ERROR,
    payload: error
  }
}

//update Subuser

export const updateSubuserLoading = (subuser: any,id : any) => {
  return {
    type: SubUserType.UPDATE_FRANCHISESUBUSER_LOADING,
    payload: subuser,
    id
  }
}

export const updateSubuserSuccess = (subUser : any) => {
  return {
    type: SubUserType.UPDATE_FRANCHISESUBUSER_SUCCESS,
    payload : subUser
  }
}

export const updateSubuserError = (error: any) => {
  return {
    type: SubUserType.UPDATE_FRANCHISESUBUSER_ERROR,
    payload: error
  }
}

//getSubuser detail by id

export const getSubuserByIdLoading = (id: string) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSERBYID_LOADING,
    payload: id,
  }
}

export const getSubuserByIdSuccess = (subUserDetails: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSERBYID_SUCCESS,
    payload: subUserDetails,
  };
};

export const getSubuserByIdError = (error: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSERID_ERROR,
    payload: error,
  };
};

//reset

export const resetSubuser = () => {
  return {
    type: SubUserType.RESET_FRANCHISESUBUSER,
  }
}

export const clearFranchiseSubuserMessage = () => {
  return {
    type: SubUserType.CLEAR_FRANCHISESUBUSER_MESSAGE,
  }
}

export const getFranchiseSubuserProfileLoading = (id: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_PROFILE_LOADING,
    id:id
  }
}

export const getFranchiseSubuserProfileSuccess = (profile: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_PROFILE_SUCCESS,
    payload: profile,
  };
};

export const getFranchiseSubuserProfileError = (error: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_PROFILE_ERROR,
    payload: error,
  };
};

export const uploadFranchiseSubuserProfileLoading = (file:any,id: string) => {
  return {
    type: SubUserType.UPLOAD_FRANCHISESUBUSER_PROFILE_LOADING,
    payload: file,
    id:id
  }
}

export const uploadFranchiseSubuserProfileSuccess = (resp: any) => {
  return {
    type: SubUserType.UPLOAD_FRANCHISESUBUSER_PROFILE_SUCCESS,
    payload: resp,
  };
};

export const uploadFranchiseSubuserProfileError = (error: any) => {
  return {
    type: SubUserType.UPLOAD_FRANCHISESUBUSER_PROFILE_ERROR,
    payload: error,
  };
};

export const deleteFranchiseSubuserProfileLoading = (id: any) => {
  return {
    type: SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_LOADING,
    id:id
  }
}

export const deleteFranchiseSubuserProfileSuccess = (FRANCHISESUBUSERDetails: any) => {
  return {
    type: SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_SUCCESS,
    payload: FRANCHISESUBUSERDetails,
  };
};

export const deleteFranchiseSubuserProfileError = (error: any) => {
  return {
    type: SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_ERROR,
    payload: error,
  };
};

export const setFranchiseSubuserMobileNumberLoading = (number:any,id: any) => {
  return {
    type: SubUserType.FRANCHISESUBUSER_SET_PHONE_LOADING,
    payload: {id:id,number:number},
  };
}
export const setFranchiseSubuserMobileNumberSuccess = (resp: any) => {
  return {
    type: SubUserType.FRANCHISESUBUSER_SET_PHONE_SUCCESS,
    payload: resp,
  };
}
export const setFranchiseSubuserMobileNumberError = (error: any) => {
  return {
    type: SubUserType.FRANCHISESUBUSER_SET_PHONE_ERROR,
    payload: error,
  };
}

export const setFranchiseSubuserEmailIdLoading = (email:any,id: any) => {
  return {
    type: SubUserType.FRANCHISESUBUSER_SET_EMAIL_LOADING,
    payload: {id:id,email:email},
  };
}
export const setFranchiseSubuserEmailIdSuccess = (resp: any) => {
  return {
    type: SubUserType.FRANCHISESUBUSER_SET_EMAIL_SUCCESS,
    payload: resp,
  };
}
export const setFranchiseSubuserEmailIdError = (error: any) => {
  return {
    type: SubUserType.FRANCHISESUBUSER_SET_EMAIL_ERROR,
    payload: error,
  };
}


export const sendFranchiseSubuserOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
  return {
    type: SubUserType.SEND_OTP_FRANCHISESUBUSER,
    payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
  };
}
export const sendFranchiseSubuserOtpSuccess = (resp: any) => {
  return {
    type: SubUserType.SEND_OTP_FRANCHISESUBUSER_SUCCESS,
    payload: resp,
  };
}
export const sendFranchiseSubuserOtpError = (error: any) => {
  return {
    type: SubUserType.SEND_OTP_FRANCHISESUBUSER_ERROR,
    payload: error,
  };
}
export const resendOtpFranchiseSubuser = (id: any,number:any) => {
  return {
    type: SubUserType.RESEND_FRANCHISESUBUSER_OTP,
    payload: id,
    number:number
  };
}
export const resendOtpFranchiseSubuserSuccess = (resp: any) => {
  return {
    type: SubUserType.RESEND_FRANCHISESUBUSER_OTP_SUCCESS,
    payload: resp,
  };
}
export const resendOtpFranchiseSubuserError = (resp: any) => {
  return {
    type: SubUserType.RESEND_FRANCHISESUBUSER_OTP_ERROR,
    payload: resp,
  };
}

export const resetOtpFranchiseSubuserMessage = () => {
  return {
    type: SubUserType.RESET_FRANCHISESUBUSER_OTP_MESSAGE,
  };
}

export const franchiseSubuserLatLagLoading = (selectedArea: string) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const franchiseSubuserLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const franchiseSubuserLatLagError = (error: any) => {
  return {
    type: SubUserType.GET_FRANCHISESUBUSER_LOCATION_ERROR,
    payload: error
  }
}