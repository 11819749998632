import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { CustomerService } from "src/components/Grid/CustomerService";
import { getCustomersList, getCustomersListSuccess, getCustomersListError, deleteCustomersFromList, deleteCustomersFromListSuccess, deleteCustomersFromListError, getCustomerByIdSuccess, getCustomerByIdError, createCustomerError, updateCustomerError, updateCustomerSuccess, createCustomerSuccess, customerTaxTypeListSuccess, customerTaxTypeListError, uploadCustomerFileSuccess, uploadCustomerFileError, getCustomerImageError, getCustomerImageSuccess, deleteCustomerImageSuccess, deleteCustomerImageError, createCustomerWithRedirectSuccess, createCustomerWithRedirectError, setCustomerEmailIdSuccess, setCustomerEmailIdError, setCustomerMobileNumberSuccess, setCustomerMobileNumberError, sendCustomerOtpSuccess, sendCustomerOtpError, resendOtpSuccess, resendOtpError, customerLatLagSuccess, customerLatLagError } from "./action";
import { API } from "src/util/api";
import { CustomerData, CustomerRoot } from "src/models/pages/customerModel";
import { CustomerTypes } from "./actionTypes";


//worker function


//getCustomer list
function* getCustomerListAsync(action: any) {

  try {
    const response: CustomerRoot = yield call(API.getCustomersListAPI, { ...action.lazyState })
    yield put(getCustomersListSuccess(response));
  } catch (error) {
    yield put(getCustomersListError(error));
  }
}

//deleteCustomerList
function* deleteCustomerAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deleteCustomersListAPI, { id: action.payload.id, undo: action.payload.undo }
    )

    yield put(deleteCustomersFromListSuccess(response, action.payload.undo))
  } catch (error) {
    yield put(deleteCustomersFromListError(error));
  }
}

//customer by id

function* getCustomerByIdAsync({ payload }: any) {
  try {
    const response: CustomerData = yield call(API.getIndividualCustomer, payload)


    yield put(getCustomerByIdSuccess(response.data));
  } catch (error) {
    yield put(getCustomerByIdError(error));
  }
}

//create Customer
function* createCustomerAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.createCustomer, payload);
    yield put(createCustomerSuccess(response))

  } catch (error) {
    yield put(createCustomerError(error))
  }
}

//update customer
function* updateCustomerAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.updateCustomer, {
      ...action.payload,
    }, { id: action.id });
    yield put(updateCustomerSuccess(response))

  } catch (error) {
    yield put(updateCustomerError(error))
  }
}



//getCustomer list
function* getCustomerTaxListAsync() {
  try {
    const response: Promise<any> = yield call(API.getCustomerTaxesList)
    yield put(customerTaxTypeListSuccess(response));
  } catch (error) {
    yield put(customerTaxTypeListError(error));
  }
}


function* getCustomerImageAsync({ payload }: any) {
  try {
    const response: CustomerData = yield call(API.fetchCustomerImage, payload)
    yield put(getCustomerImageSuccess(response.data));
  } catch (error) {
    yield put(getCustomerImageError(error));
  }
}

function* deleteCustomerImageAsync({ payload }: any) {
  try {
    const response: CustomerData = yield call(API.deleteCustomerImage, payload)

    yield put(deleteCustomerImageSuccess(response.data));
  } catch (error) {
    yield put(deleteCustomerImageError(error));
  }
}

//update customer
function* uploadCustomerFileAsync(action: any) {

  try {

    const response: Promise<any> = yield call(API.uploadCustomerImage, action.payload, { id: action.id });
    yield put(uploadCustomerFileSuccess(response))

  } catch (error) {
    yield put(uploadCustomerFileError(error))
  }
}


//create customer and redirect the page
function* createCustomerAndRedirectPage(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.createCustomer, action.payload);
    yield put(createCustomerWithRedirectSuccess(response))
  } catch (error) {
    yield put(createCustomerWithRedirectError(error))
  }
}

function* setCustomerEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerEmailApi, action.payload.id,action.payload.email);
    yield put(setCustomerEmailIdSuccess(response))
  } catch (error) {
    yield put(setCustomerEmailIdError(error))
  }
}

function* setCustomerMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setCustomerMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setCustomerMobileNumberError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendCustomerOtpSuccess(response))
  } catch (error) {
    yield put(sendCustomerOtpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.resendOtpCustomerApi, action.payload);
    yield put(resendOtpSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpError(error))
  }
}

function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(customerLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(customerLatLagError(error));
  }
}

//watcher function
function* customerSaga() {
  yield takeLatest(CustomerTypes.GET_CUSTOMERS_LIST, getCustomerListAsync);
  yield takeLatest(CustomerTypes.DELETE_CUSTOMERS, deleteCustomerAsync);
  yield takeEvery(CustomerTypes.GET_CUSTOMERBYID_LOADING, getCustomerByIdAsync);
  yield takeLatest(CustomerTypes.CREATE_CUSTOMER_LOADING, createCustomerAsync);
  yield takeLatest(CustomerTypes.UPDATE_CUSTOMER_LOADING, updateCustomerAsync);
  yield takeLatest(CustomerTypes.CUSTOMER_TAX_TYPE_LIST_LOADING, getCustomerTaxListAsync);
  yield takeLatest(CustomerTypes.CUSTOMER_FILE_UPLOAD_LOADING, uploadCustomerFileAsync);
  yield takeLatest(CustomerTypes.CUSTOMER_FILE_FETCH_LOADING, getCustomerImageAsync);
  yield takeLatest(CustomerTypes.CUSTOMER_FILE_DELETE_LOADING, deleteCustomerImageAsync);
  yield takeLatest(CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_LOADING, createCustomerAndRedirectPage);
  yield takeLatest(CustomerTypes.CUSTOMER_SET_EMAIL_LOADING, setCustomerEmailAsync);
  yield takeLatest(CustomerTypes.CUSTOMER_SET_PHONE_LOADING, setCustomerMobileAsync);
  yield takeLatest(CustomerTypes.SEND_OTP_CUSTOMER, sendOtpAsync);
  yield takeLatest(CustomerTypes.RESEND_CUSTOMER_OTP, resendOtpAsync);
  yield takeLatest(CustomerTypes.GET_CUSTOMER_LOCATION_LOADING, getlatLngAsync);
}

export default customerSaga;