import { call, put, takeLatest, delay } from "redux-saga/effects";
import { closeTicketByEmployeeError, closeTicketByEmployeeSuccess, createEnquiryError, createEnquirySuccess, getEnquiryDocumentLinkError, getEnquiryDocumentLinkSuccess, getEnquiryListPageError, getEnquiryListPageSuccess, getEnquiryUpoadedDocumentError, getEnquiryUpoadedDocumentLoading, getEnquiryUpoadedDocumentSuccess, getInduvidualEnquiryError, getInduvidualEnquirySuccess, getReassignEmployeeListError, getReassignEmployeeListSuccess, getReplyMessageFromEmployeeError, getReplyMessageFromEmployeeLoading, getReplyMessageFromEmployeeSuccess, getReplyUpoadedDocumentError, getReplyUpoadedDocumentLoading, getReplyUpoadedDocumentSuccess, getTicketCreateCustomerDataError, getTicketCreateCustomerDataSuccess, reAssignTicketFromEmployeeError, reAssignTicketFromEmployeeSuccess, reOpenTicketFromCustomerError, reOpenTicketFromCustomerSuccess, replyTheQueryError, replyTheQuerySuccess, responseTheQueryError, responseTheQuerySuccess } from "./action";

import { TicketSystemActions } from "./actiontypes";
import { API } from "src/util/api";
import { EnquiryItems, EnquiryListApiResponse, ReassignEmployeeModel, customerModelinTicket } from "src/models/pages/ticketSystemModel";


//============= worker function =================== 


// ================== get enquiry list api ==================

function* getenquiryListAsync(action: any) {
  try {
    const response: Promise<EnquiryListApiResponse[]> = yield call(API.getEnquiryListApi, { ...action.lazyState });
    yield put(getEnquiryListPageSuccess(response));
  } catch (error) {
    yield put(getEnquiryListPageError(error))
  }
}

// ============ create createEnquiry api call ================

function* createEnquiryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.createEnquiryApi, action.payload.quiryData)
    yield put(createEnquirySuccess(response));
  } catch (error) {
    yield put(createEnquiryError(error));
  }
}


// ===================get Induvidual Enquiry =================

function* getInduvidualEnquiryAsync(action: any) {
  try {
    const response: Promise<EnquiryItems[]> = yield call(API.getInduvidualEnquiryApi, action.payload)
    yield put(getInduvidualEnquirySuccess(response));
  } catch (error) {
    yield put(getInduvidualEnquiryError(error));
  }
}


// =================== reply Enquiry ================ 

function* replyEnquiryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.replyEnquiryApi, action.payload.id, action.payload.responsblePersonDetails)
    yield put(replyTheQuerySuccess(response));
    yield delay(500)
    yield put(getReplyMessageFromEmployeeLoading(action.payload.id))
    // yield put (getEnquiryUpoadedDocumentLoading(action.payload.id, "Enquiry"))
    // yield put (getReplyUpoadedDocumentLoading(action.payload.id, "Reply"))

  } catch (error) {
    yield put(replyTheQueryError(error));
  }
}


function* responseEnquiryAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.responseEnquiryApi, action.payload.id, action.payload.responsblePersonDetails)
    yield put(responseTheQuerySuccess(response));
    yield delay(500)
    yield put(getReplyMessageFromEmployeeLoading(action.payload.id))
  } catch (error) {
    yield put(responseTheQueryError(error));
  }
}


// =============== Get Enquiry Document ==============
function* getEnquiryDocumentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getEnquiryDocumentApi, action.payload.id, action.payload.sourceType)
    yield put(getEnquiryUpoadedDocumentSuccess(response));
  } catch (error) {
    yield put(getEnquiryUpoadedDocumentError(error));
  }
}

// ================ getEnquiryDocumentLinkAsync ===========
function* getEnquiryDocumentLinkAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getEnquiryDocumentLinkApi, action.payload);
    yield put(getEnquiryDocumentLinkSuccess(response));
  } catch (error) {
    yield put(getEnquiryDocumentLinkError(error));
  }
}

// ============== Get Replay Document  Async ===================

function* getReplayDocumentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getEnquiryDocumentApi, action.payload.id, action.payload.sourceType)
    yield put(getReplyUpoadedDocumentSuccess(response));
  } catch (error) {
    yield put(getReplyUpoadedDocumentError(error));
  }
}

// ================== Get Replay Message From Employee ==================

function* getReplayMessageFromEmployeeAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getReplyMessageFromEmployeeApi, action.payload);
    yield put(getReplyMessageFromEmployeeSuccess(response));
  } catch (error) {
    yield put(getReplyMessageFromEmployeeError(error));
  }
}

// ================ Re Assign Ticket ==================

function* reAssignTicketAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.reAssignTicketApi, action.payload.ticketId, action.payload.supportorId, action.payload.supporterName);
    yield put(reAssignTicketFromEmployeeSuccess(response, action.payload.supporterName));
  } catch (error) {
    yield put(reAssignTicketFromEmployeeError(error));
  }
}

// =================== ReOpen Ticket ===============
function* reOpenTicketAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.reOpenTicketApi, action.payload);
    yield put(reOpenTicketFromCustomerSuccess(response));
  } catch (error) {
    yield put(reOpenTicketFromCustomerError(error));
  }
}

// ========================= close Ticket ================

function* closeTicketAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.closeTicketApi, action.payload);
    yield put(closeTicketByEmployeeSuccess(response));
  } catch (error) {
    yield put(closeTicketByEmployeeError(error));
  }
}

// =============== re assign Employee list ========================

function* reassignEmployeeAsync() {
  try {
    const response: Promise<ReassignEmployeeModel[]> = yield call(API.getEmployeeDrpFromIdentity);
    yield put(getReassignEmployeeListSuccess(response));
  } catch (error) {
    yield put(getReassignEmployeeListError(error));
  }
}


// ================ get customer data =============

function* getcustomerAsync() {
  try {
    const response: Promise<customerModelinTicket[]> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getTicketCreateCustomerDataSuccess(response));
  } catch (error) {
    yield put(getTicketCreateCustomerDataError(error));
  }
}

//============ watcher function ==============

function* ticketsystemSaga() {
  yield takeLatest(TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_LOADING, getenquiryListAsync);
  yield takeLatest(TicketSystemActions.CREATE_ENQUIRY_LOADING, createEnquiryAsync);
  yield takeLatest(TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_LOADING, getInduvidualEnquiryAsync);
  yield takeLatest(TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_LOADING, replyEnquiryAsync);
  yield takeLatest(TicketSystemActions.RESPONSE_ENQUIRY_LOADING, responseEnquiryAsync);
  yield takeLatest(TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_LOADING, getEnquiryDocumentAsync);
  yield takeLatest(TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_LOADING, getEnquiryDocumentLinkAsync);
  yield takeLatest(TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_LOADING, getReplayMessageFromEmployeeAsync);
  yield takeLatest(TicketSystemActions.GET_REPLY_DOCUMENT_LOADING, getReplayDocumentAsync);
  yield takeLatest(TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_LOADING, reAssignTicketAsync);
  yield takeLatest(TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_LOADING, reOpenTicketAsync);
  yield takeLatest(TicketSystemActions.CLOSE_TICKET_EMPLOYEE_LOADING, closeTicketAsync);
  yield takeLatest(TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_LOADING, reassignEmployeeAsync);
  yield takeLatest(TicketSystemActions.GET_CUSTOMER_DATA_LOADING, getcustomerAsync);
}

export default ticketsystemSaga