import React from 'react'
import { emailBodyTemplateProbs } from 'src/models/components/emailBodyTemplateProbsModel';
import "./EmailBodyTemplate.scss";

function EmailBodyTemplate({data}:emailBodyTemplateProbs) {
  return (
    <div id="email-body-template">
       <a className='email-tag' href={`mailto:${data}`}>
        {data}
      </a>
    </div>
  )
}

export default EmailBodyTemplate
