import { FeedbackInitialState } from "src/models/pages/feedbackModel";
import { FeedbackActions } from "./actionTypes";


const initialState: FeedbackInitialState = {
  loading: false,
  error: "",
  message: "",
  feedbackList: {
    totalCount: 0,
    items: []
  },
  vehiclebrandDrop: [],
  feedbackDetails: null,
    jobsheet:{},
    vehicleBrandDropdown:[]
}

const Feedback = (state = initialState, action: any) => {
  switch (action.type) {
    case FeedbackActions.GET_FEEDBACK_LIST_LOADING:
    case FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_LOADING:
    case FeedbackActions.GET_FEEDBACK_BYID_LOADING:
    case FeedbackActions.GET_JOBSHEET_DETAILS_LOADING:
    case FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_LOADING:
    case FeedbackActions.CREATE_FEEDBACK_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      }
    case FeedbackActions.GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackList: {
          ...state.feedbackList,
          items: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        },

      }
    case FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        vehiclebrandDrop: action.payload.data
      }

    case FeedbackActions.GET_FEEDBACK_BYID_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        feedbackDetails: action.payload.data
      }
    
      case FeedbackActions.GET_JOBSHEET_DETAILS_SUCCESS:
        return {
            ...state,
            loading: false,
            jobsheet: action.payload.data,
            error: ""
        }
        
    case FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_SUCCESS:
        return {
        ...state,
        loading: false,
        vehicleBrandDropdown: action.payload.data?.vehicleBrands
        }

    case FeedbackActions.CREATE_FEEDBACK_SUCCESS:
        return {
        ...state,
        loading: false,
        message: "Feedback created successfully"
        }
    case FeedbackActions.RESET_FEEDBACK_MESSAGE:
        return {
        ...state,
        loading: false,
        message: "",
        error:""
        }
    case FeedbackActions.CLEAR_FEEDBACK_VALUE:
        return {
            loading: false,
            error: "",
            message: "",
            feedbackList: {
                totalCount: 0,
                items: []
            },
            jobsheet:{},
            vehicleBrandDropdown:[]
        }

    case FeedbackActions.GET_FEEDBACK_LIST_ERROR:
    case FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_ERRROR:
    case FeedbackActions.GET_FEEDBACK_BYID_ERROR:
    case FeedbackActions.GET_JOBSHEET_DETAILS_ERROR:
    case FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_ERROR:
    case FeedbackActions.CREATE_FEEDBACK_ERROR:
          debugger;
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return { ...state }
  }
}


export default Feedback