import { call, put, takeLatest } from "redux-saga/effects";
import { getAdditionalDetailsError, getAdditionalDetailsSuccess, getCurrentFranchiseIdError, getCurrentFranchiseIdSuccess } from "./action" ;
import {FranchiseAddtionalDetails} from "./actionTypes";
import { API } from "src/util/api";

// ============= worker function ===========

//get current userid

function* getCurrentUserIdAsync(){
   try {
    const response : Promise<any> = yield call(API.getCurrentUserProfile);
    yield put(getCurrentFranchiseIdSuccess(response)) ;
   } catch (error) {
    yield put(getCurrentFranchiseIdError(error));
   } 
}

//get franchise extra details

function* getFranchiseMoreDetailsAsync(action:any){
  try {
    const response : Promise<any> = yield call(API.getFranchiseAdditonalDetailsApi,action.payload)
    yield put(getAdditionalDetailsSuccess(response)) ;
  } catch (error) {
    yield put(getAdditionalDetailsError(error)) ;
  }
}

// ======== watcher function ========
function* franchiseAdditionalDetailSaga() {
    yield takeLatest(FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_LOADING,getCurrentUserIdAsync);
    yield takeLatest(FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_LOADING,getFranchiseMoreDetailsAsync);
}

export default franchiseAdditionalDetailSaga;