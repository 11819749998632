import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import JobTooltipMEL from 'src/components/UI/JobTooltip';
import { JOB_CLOSED } from 'src/constants/JobSheetStatus';
import { ROUTER } from 'src/constants/routes';
import { hasAdminOrEmployeeRoleHandler } from 'src/helpers/common_helpers';
import { additionalExpensesDocumentLinkLoading, clearAdditionalExpenseDocumentLink, getAdditionalExpenseDetailsLoading, getAdditionalExpenseUserDetailsLoading, jobSheetClearMessage } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';

const ViewAdditionalExpense = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const { additionalExpenseDetails, error, franchiseDocumet, franchiseDocumetLink, additionalExpensesLink, additionalExpenseUser } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAdditionalExpenseDetailsLoading(id));
    return () => {
      dispatch(jobSheetClearMessage());
    };
  }, []);

  const statusBodyTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );
  };

  const openDocumet = (id: any) => {
    // dispatch(getFranchiseUploadedDocumentLinkLoading(id))
    dispatch(additionalExpensesDocumentLinkLoading(id));
  };

  const isImageMimeType = (mimeType: any) => {
    const imageMimeTypes = /^image\/(png|jpeg|jpg|gif|svg\+xml|bmp|webp|tiff|ico)$/;
    return imageMimeTypes.test(mimeType);
  };

  useEffect(() => {
    if (additionalExpensesLink != "" && additionalExpensesLink != undefined && additionalExpensesLink != null) {
      const newWindow = window.open(additionalExpensesLink);
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      // dispatch(clearFranchiseDocumentLink())
      dispatch(clearAdditionalExpenseDocumentLink());
    }
  }, [additionalExpensesLink]);

  const documentTemplate = (franchiseDocumet: any) => {
    return (
      <div className='Franchise_Documnets'>
        {/* {
          franchiseDocumet?.length > 0 ? franchiseDocumet?.map((data: any, i: number) => {
            if (data.mimeType == "application/pdf") {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  <i onClick={() => openDocumet(data.id)} className="fas fa-file-pdf"></i>
                </JobTooltipMEL>
              )
            } else if (data.mimeType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || data.mimeType == "application/vnd.ms-excel") {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-csv"></i>
                </JobTooltipMEL>
              )
            } else if (isImageMimeType(data.mimeType)) {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  <i key={i} onClick={() => openDocumet(data.id)} className="fas fas fa-image"></i>
                </JobTooltipMEL>
              )
            } else {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-alt"></i>
                </JobTooltipMEL>
              )
            }
          }) : <span style={{ fontSize: "14px" }}>No attachement here</span>
        } */}
        {
          (franchiseDocumet !== null && franchiseDocumet != "") ? (
            <>
              <JobTooltipMEL title={franchiseDocumet.name} position='bottom'>
                <i onClick={() => openDocumet(franchiseDocumet?.['id'])} className="fas fas fa-image"></i>
              </JobTooltipMEL>
            </>
          ) : <span style={{ fontSize: "14px" }}>No attachement here</span>
        }
      </div>
    );
  };

  useEffect(()=>{
    if(additionalExpenseDetails?.userId){
      dispatch(getAdditionalExpenseUserDetailsLoading(additionalExpenseDetails?.userId))
    }
  },[additionalExpenseDetails?.userId])

const userNavigationTemplate = (name:string)=>{
let url=""
if(additionalExpenseDetails.userType=="Franchise"){
  url = `${ROUTER.FRANCHISEE_VIEW}/${additionalExpenseUser.extraProperties.FranchiseId}`
}
else if(additionalExpenseDetails.userType=="FranchiseSubUser"){
  url =  `${ROUTER.SUB_USER_VIEW}/${additionalExpenseUser.id}/${additionalExpenseUser.extraProperties.FranchiseId}`
}
  return(
    <Link to={url}>{name}</Link>
  )
}

  const additionalExpense = useMemo(() => [
    {
      name: "Name",
      value: (additionalExpenseDetails?.name && additionalExpenseUser && hasAdminOrEmployeeRoleHandler() ) ? userNavigationTemplate(additionalExpenseDetails?.name) :(additionalExpenseDetails?.name && !additionalExpenseUser) ? additionalExpenseDetails?.name : "-"
    },
    {
      name: "Expenses Name",
      value: additionalExpenseDetails?.expensesName ? additionalExpenseDetails?.expensesName : "-"
    },
    {
      name: "Tax Code",
      value: additionalExpenseDetails?.taxCode ? additionalExpenseDetails?.taxCode : "-"
    },
    {
      name: "Amount",
      value: additionalExpenseDetails?.amountWithoutTax ? additionalExpenseDetails?.amountWithoutTax : "-"
    },
    {
      name: "Tax Amount",
      value: additionalExpenseDetails?.taxAmount ? additionalExpenseDetails?.taxAmount : "-"
    },
    {
      name: "Tax Percentage",
      value: additionalExpenseDetails?.taxPercentage ? additionalExpenseDetails?.taxPercentage : "-"
    },
    {
      name: "Net Amount",
      value: additionalExpenseDetails?.netAmount ? additionalExpenseDetails?.netAmount : "-"
    },
    {
      name: "Is Approved",
      value: statusBodyTemplate(additionalExpenseDetails?.isApproved)
    },
    {
      name: "Documents",
      value: documentTemplate(additionalExpenseDetails?.document ? additionalExpenseDetails?.document : "")
    }
  ], [additionalExpenseDetails, franchiseDocumet,additionalExpenseUser]);
  
  const moveEditPage = () => {
    navigate(ROUTER.ADDITIONAL_EXPENSE_EDIT + '/' + additionalExpenseDetails?.jobId + '/' + additionalExpenseDetails?.id);
  };

  const hidePopup = () => {
    dispatch(jobSheetClearMessage());
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    if (error) {
      setVisible(true);
    }

  }, [error]);

  return (
    <React.Fragment>
      <div className="audiobrandPage" id='audiobrandPage'>
        {visible && dialogContainer(error)}
        <Container fluid>
          <BreadcrumbWithFilter
            title="Additional Expenses"
            titlePath={ROUTER.ADDITIONAL_EXPENSE_GRID + '/' + additionalExpenseDetails?.jobId}
            breadcrumbItem="Additional Expenses Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={(additionalExpenseDetails?.isApproved == true || additionalExpenseDetails?.jobStatus == JOB_CLOSED) ? false : true}
            firstButtonLabel='Edit Additional Expenses'
            onFirstButtonClick={moveEditPage}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Additional Expenses Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    additionalExpense.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div >{data.name}</div>
                          <div>{data.value}</div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewAdditionalExpense;