import { Formik, Form, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumb from 'src/components/Common/Breadcrumb'
import InputCom from 'src/components/Common/Input';
import * as Yup from 'yup';
import { ROUTER } from 'src/constants/routes';
import Button from 'src/components/UI/Button';
import { FieldModal } from 'src/models/components/inputWrapperModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { ResetTotalWorkingDaysMessage, clearTotalWorkingDaysMessage, createTotalWorkingDaysLoading, getMonthsLoading, getTotalWorkingDayIdLoading } from 'src/store/TotalWorkingDays/action';
import DropdownInput from 'src/components/UI/DropdownInput';
import { Calendar } from 'primereact/calendar';
import ErrorText from 'src/components/UI/ErrorText';


const AddorEditTotalWorkingDays: React.FC = () => {
  const { id } = useParams()
  const { showToast } = useToast();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const today = new Date();
  const endOfYear = new Date(today.getFullYear(), 11, 31); // December 31 of the current year
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    // month: Yup.string().required('Month is required'),
    // year: Yup.string().required('Year is required'),
    month: Yup.string()
    .required('Month is required')
    .test('valid-month', "Month shouldn't be future", function (value) {
      const month = parseInt(value, 10);
      if (isNaN(month) || month < 1 || month > 12) {
        return false;
      }

      const { year } = this.parent;
      let date = new Date(`${year?.split(' ')[3]}-${String(month)?.padStart(2,'0')}-01`)
      
      if (year) {
        const currentDate = new Date();
        
        
        if (date > currentDate) {
          return false;
        }
      }

      return true;
    }),
  year: Yup.string()
    .required('Year is required')
    .test('valid-year', 'Invalid year', function (value) {
      const currentYear = new Date().getFullYear();
      const YearDate:any = new Date(value).getFullYear();
      const year = parseInt(YearDate, 10);
      
      return !isNaN(year) && year >= 1900 && year <= currentYear;
    }),
    workingDays: Yup.string().test('valid-working-days', 'Invalid working days', function(value) {
        const { month, year } = this.parent;
    
        if (month && year && value) {
            const dateStr = `${getYear(year)}-${month.padStart(2, '0')}-01`;
            const selectedDate = new Date(dateStr);
            const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
          return Number(value) > 0 && Number(value) <= daysInMonth;
        }
    
        return false;
      }).required('Working days is required')
  });
  const navigate = useNavigate();

  
  useEffect(()=>{
    dispatch(getMonthsLoading())
    return ()=>{
        dispatch(ResetTotalWorkingDaysMessage())
    }
  },[])

  useEffect(()=>{
    if(id){
        dispatch(getTotalWorkingDayIdLoading(id))
    }
  },[id])



  const { loading, error, message,totalWorkingDayDetails,months } = useSelector((state: RootReducerState) => state.totalWorkingDaysReducer);

  
  const initialvalue = {
    month: (totalWorkingDayDetails?.['month'] || ""),
    year: totalWorkingDayDetails?.['year'] ? new Date(`01-01-${totalWorkingDayDetails?.['year']}`) : "",
    workingDays: (totalWorkingDayDetails?.['workingDays'] || ""),
  }
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (error) {
      setVisible(true)
      setIsSubmitting(false)
    }
    if (message !== "" ) {
      showToast(message, { type: "success" });
     
        dispatch(clearTotalWorkingDaysMessage())
        setTimeout(() => {
          setIsSubmitting(false)
          navigate(ROUTER.TOTAL_WORKING_DAYS)
        }, 500)
    
    }
  }, [error, message])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }

  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearTotalWorkingDaysMessage())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const getYear = (date:any)=>{
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    return year.toString()
  }
  const handleSubmit = async (values: any) => {
let submitValue={
    id: id ? id : 0,
    month:values.month,
    year:getYear(values.year),
    workingDays:values.workingDays
}
    id ? update(submitValue) : post(submitValue);
  };

  const update = (formValue: any) => {
    dispatch(createTotalWorkingDaysLoading(formValue));
  };


  const post = (formValue: any) => {
    
    
    dispatch(createTotalWorkingDaysLoading(formValue));
  };
 

  
  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.TOTAL_WORKING_DAYS)
    }
  }

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.name] = '';
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  const monthsDropDown = months?.map((item:any)=>({label:item.value,value:item.id.toString()}))
  return (
    <React.Fragment>
      
      {visible && dialogContainer(error)}
      <div className="page-content" id="view-brand">
        <Container fluid>
          <Breadcrumb title="Total Working Days" titlePath={ROUTER.TOTAL_WORKING_DAYS} breadcrumbItem={`${id ? "Edit Total Working Days" : "Create Total Working Days"}`} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} >
                            <DropdownInput
                                options={monthsDropDown}
                                name="month"
                                label="Month"
                                onChange={handleChange}
                                required={true}
                                onBlur={handleBlur}
                                value={values.month}
                                invalid={
                                  touched["month"] && errors["month"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} >
                            {/* <InputCom
                                labelName="Year"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="month"
                                name="year"
                                onBlur={handleBlur}
                                value={values.year}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["year"] && errors["year"]
                                    ? true
                                    : false
                                }
                              /> */}
                              <label htmlFor="">Year</label>
                              <Calendar value={values.year} onChange={(e)=>handleChange(e)} name="year" view="year" dateFormat="yy" className={(touched["year"] && errors["year"]) ? 'p-invalid': ""} maxDate={endOfYear}/>
                              <ErrorMessage name={"year"} component={ErrorText} />
                            </Col>
                            <Col lg={3} >
                            <InputCom
                                labelName="Working Days"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="workingDays"
                                onBlur={handleBlur}
                                value={values.workingDays}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["workingDays"] && errors["workingDays"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                     
                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                className="secondary-btn me-2"
                                label={id ? "Cancel" : "Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={id ? "Update Total Working Days" : "Create Total Working Days"}
                                type="submit"
                              />
                            </div>

                          </Row>


                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddorEditTotalWorkingDays