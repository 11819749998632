import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getJobsheetStatus } from "src/store/actions";
import 'bootstrap/dist/css/bootstrap.min.css'
import { RootReducerState } from "src/store/reducers";
interface propsJobsheet {
  date?: string,
  realDate?: any
}
const JobsheetStatusPieChart = ({ date }: propsJobsheet) => {
  
  const dispatch = useDispatch();
  const [state, setState] = useState<any>("ALL");
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  const { jobsheetStatus } = useSelector((state: RootReducerState) => state.dashboard);
  const [month, year]:any = date?.split(' ').map((value: any, index: any) => index === 0 ? new Date(`${value} 1, 2000`).getMonth() + 1 : parseInt(value));
  const onChangehandle = (data: any) => {
    setState(data);
    // dispatch(getWalletBalance(data));
  };


  //   const piechartColors =  ["#5885AF","#05445E","#AF5E58","#5EAF58","#5E58AF","#AF585E","#5858AF","#AF5E5E","#5EAF5E","#5E5EAF","#AF8E58","#AF58AF"];
  // const piechartColors =  ["#5885AF","#05445E","#41729F","#274472","#C3E0E5","#5885AF","#05445E","#41729F","#274472","#C3E0E5","#5885AF","#05445E","#41729F"];
  const piechartColors = [
    "#41729F",
    "#6C8CA4",
    "#385D7A",
    "#295C7F",
    "#5D7E9E",
    "#5A7196",
    "#3D6F8C",
    "#3F6485",
    "#31607B",
    "#4D86A9"
  ];
  const options: Object = {
    chart: {
      width: 227,
      height: 227,
      type: "pie",
    },
    // labels: [NEW_JOB, SERVICE_REQUEST_GENERATED, REPLACEMENT_SYSTEM_DISPATCHED, JOB_SHEET_CREATED, ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL, JOB_ASSIGNABLE_TO_TECHNICIAN, ASSIGNED_TO_TECHNICIAN, READY_TO_INVOICE, INVOICED_JOB, JOB_CLOSED, JOB_CANCELLED],
    labels: jobsheetStatus?.length > 0 ? jobsheetStatus?.map((item: any) => item.status) : [],
    colors: piechartColors,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };
  let initialLazyState = {
    startDate: "",
    endDate: ""
  };
  const [lazyState, setLazyState] = useState(initialLazyState);


  const series: any = jobsheetStatus?.length > 0 ? jobsheetStatus?.map((item: any) => item.count) : []

  const toggleCalendar = (event: any) => {
    setIsCalendarOpen(prevState => !prevState);
  };

  useEffect(() => {
    debugger
    if(month && year){
      dispatch(getJobsheetStatus({month:month,year:year}))
    }
  }, [month, year])

  // useEffect(() => {
  //   if(!date){
  //     dispatch(getJobsheetStatus(""))
  //   }
  // }, [])
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-h-100">

          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Job Sheet Status</h5>
              <div className="ms-auto d-flex">

                <div className="d-flex gap-2">
                  {false &&
                    <div className="table-header-container-item">
                      <div className="btn-group">
                        <button
                          className="btn btn-soft-primary waves-effect waves-light"
                          onClick={toggleCalendar}>
                          <i className="bx bx-calendar font-size-16 align-middle"></i>
                        </button>
                      </div>
                    </div>
                  }
                  {/* {isCalendarOpen && (
                <div className="parent-container" ref={calendarRef}>
                    <div className="p-column-filter-overlay p-component p-fluid p-column-filter-overlay-menu p-ripple-disabled p-connected-overlay-enter-done custom-calendar">
                        <div className="p-column-filter-constraints" >
                            <div className="p-column-filter-constraint">
                                <label htmlFor="">Start Date</label>
                                <Input type="date" name="startDate" format={"mm/dd/yyyy"} required value={selectedDate ? selectedDate : ""} max={selectedEndDate ? selectedEndDate : ""}
                                    onChange={(e: any) => {
                                        setSelectedDate(e.target.value)
                                    }} />
                                <br />
                                <label htmlFor="">End Date</label>
                                <Input type="date" name="endDate" format={"mm/dd/yyyy"} required value={selectedEndDate ? selectedEndDate : ""} min={selectedDate ? selectedDate : ""}
                                    onChange={(e: any) => { setSelectedEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="p-column-filter-buttonbar">
                            <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
                                <span className="p-button-label p-c">Clear</span>
                            </button>
                            <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
                                <span className="p-button-label p-c">Apply</span>
                            </button>
                        </div>
                    </div>
                </div>
            )} */}

                  <b>{date}</b>
                  {false &&
                    <>
                      <button
                        type="button"
                        className="btn btn-soft-primary btn-sm"
                        onClick={() => onChangehandle("ALL")}
                      >
                        ALL
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm"
                        onClick={() => onChangehandle("1M")}
                      >
                        1M
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm"
                        onClick={() => onChangehandle("6M")}
                      >
                        6M
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm"
                        onClick={() => onChangehandle("1Y")}
                      >
                        1Y
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
            
            {
              jobsheetStatus?.length > 0 ? <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">

                  <Row className="align-items-center">
                    <Col lg={4}>
                      <div style={{ maxWidth: "400px" }}>
                         
                        <ReactApexChart options={options} series={series} type="pie" />
                      </div>
                    </Col>
                    <Col lg={8}>
                      <Row>
                        {jobsheetStatus.map((item: any, index: any) => {
                          return (
                            <Col lg={6} key={index}>
                              <div className="mt-3">

                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm m-auto">
                                    <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                                      {index + 1}
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-3 d-flex gap-2">
                                    <span className="font-size-16">{item.status}</span>
                                    <div className="flex-shrink-0">
                                      <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                                        {item.count}
                                      </span>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Col>
                          )
                        })}

                      </Row>
                    </Col>
                    {/* <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          2
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{SERVICE_REQUEST_GENERATED}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[1]}
                        </span>
                      </div>
                    </div>
                  </div> */}

                    {/* <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          3
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{REPLACEMENT_SYSTEM_DISPATCHED}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[2]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          4
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{JOB_SHEET_CREATED}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[3]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          5
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{ASSIGNED_TO_ESTIMATOR}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[4]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          6
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{JOB_ESTIMATED_AWAITING_APPROVAL}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[5]}
                        </span>
                      </div>
                    </div>
                  </div> */}


                    {/* <Col lg={4}>
                  <div className="p-4">
                  <div className="mt-0">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          7
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{JOB_ASSIGNABLE_TO_TECHNICIAN}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[6]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          8
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{ASSIGNED_TO_TECHNICIAN}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[7]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          9
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{READY_TO_INVOICE}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[8]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          10
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{INVOICED_JOB}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[8]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          11
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{JOB_CLOSED}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[10]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                          12
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="font-size-16">{JOB_CANCELLED}</span>
                      </div>

                      <div className="flex-shrink-0">
                        <span className="badge rounded-pill jobsheet-subtle jobsheet-count font-size-12 fw-medium">
                        {series[11]}
                        </span>
                      </div>
                    </div>
                  </div>
             
                </div>
                  </Col> */}
                  </Row>
                </div>
              </div>
              {/* <div className="col-sm align-self-center">
                <div className="mt-4 mt-sm-0">
                  <div>
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i>{" "}
                      Bitcoin
                    </p>
                    <h6>
                      0.4412 BTC ={" "}
                      <span className="text-muted font-size-14 fw-normal">
                        $ 4025.32
                      </span>
                    </h6>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-additional-dark"></i>{" "}
                      Ethereum
                    </p>
                    <h6>
                      4.5701 ETH ={" "}
                      <span className="text-muted font-size-14 fw-normal">
                        $ 1123.64
                      </span>
                    </h6>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-additional-light"></i>{" "}
                      Litecoin
                    </p>
                    <h6>
                      35.3811 LTC ={" "}
                      <span className="text-muted font-size-14 fw-normal">
                        $ 2263.09
                      </span>
                    </h6>
                  </div>
                </div>
              </div> */}
            </Row> : <div>No data found</div>
            }
            
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default JobsheetStatusPieChart;
