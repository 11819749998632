export enum TicketSystemActions {

  //grid
  GET_TICKET_SYSTEM_ENQUIRY_LIST_LOADING = "@@ticketsystem/GET_TICKET_SYSTEM_ENQUIRY_LIST_LOADING",
  GET_TICKET_SYSTEM_ENQUIRY_LIST_SUCCESS = "@@ticketsystem/GET_TICKET_SYSTEM_ENQUIRY_LIST_SUCCESS",
  GET_TICKET_SYSTEM_ENQUIRY_LIST_ERROR = "@@ticketsystem/GET_TICKET_SYSTEM_ENQUIRY_LIST_ERROR",

  //create enquiry
  CREATE_ENQUIRY_LOADING = "@@ticketsystem/CREATE_ENQUIRY_LOADING",
  CREATE_ENQUIRY_SUCCESS = "@@ticketsystem/CREATE_ENQUIRY_SUCCESS",
  CREATE_ENQUIRY_ERROR = "@@ticketsystem/CREATE_ENQUIRY_ERROR",

  //reset enquiry ;
  RESET_ENQUIRY_STATE = "@@ticketsystem/RESET_ENQUIRY_STATE",

  //get enquiry by induvidual
  GET_INDUVIDUAL_ENQUIRY_LOADING = "@@ticketsystem/GET_INDUVIDUAL_ENQUIRY_LOADING",
  GET_INDUVIDUAL_ENQUIRY_SUCCESS = "@@ticketsystem/GET_INDUVIDUAL_ENQUIRY_SUCCESS",
  GET_INDUVIDUAL_ENQUIRY_ERROR = "@@ticketsystem/GET_INDUVIDUAL_ENQUIRY_ERROR",

  //get enquiry uploaded document
  GET_ENQUIRY_UPLOADED_DOCUMENT_LOADING = "@@ticketsystem/GET_ENQUIRY_UPLOADED_DOCUMENT_LOADING",
  GET_ENQUIRY_UPLOADED_DOCUMENT_SUCCESS = "@@ticketsystem/GET_ENQUIRY_UPLOADED_DOCUMENT_SUCCESS",
  GET_ENQUIRY_UPLOADED_DOCUMENT_ERROR = "@@ticketsystem/GET_ENQUIRY_UPLOADED_DOCUMENT_ERROR",

  //get enquiry document link
  GET_ENQUIRY_DOCUMENT_LINK_LOADING = "@@ticketsystem/GET_ENQUIRY_DOCUMENT_LINK_LOADING",
  GET_ENQUIRY_DOCUMENT_LINK_SUCCESS = "@@ticketsystem/GET_ENQUIRY_DOCUMENT_LINK_SUCCESS",
  GET_ENQUIRY_DOCUMENT_LINK_ERROR = "@@ticketsystem/GET_ENQUIRY_DOCUMENT_LINK_ERROR",

  //reset enquiry document link ;
  RESET_ENQUIRY_DOCUMENT_LINK = "@@ticketsystem/RESET_ENQUIRY_DOCUMENT_LINK",

  //get reply document list
  GET_REPLY_DOCUMENT_LOADING = "@@ticketsystem/GET_REPLY_DOCUMENT_LOADING",
  GET_REPLY_DOCUMENT_SUCCESS = "@@ticketsystem/GET_REPLY_DOCUMENT_SUCCESS",
  GET_REPLY_DOCUMENT_ERROR = "@@ticketsystem/GET_REPLY_DOCUMENT_ERROR",

  //get reply document link 
  GET_REPLY_DOCUMENT_LINK_LOADING = "@@ticketsystem/GET_REPLY_DOCUMENT_LINK_LOADING",
  GET_REPLY_DOCUMENT_LINK_SUCCESS = "@@ticketsystem/GET_REPLY_DOCUMENT_LINK_SUCCESS",
  GET_REPLY_DOCUMENT_LINK_ERROR = "@@ticketsystem/GET_REPLY_DOCUMENT_LINK_ERROR",

  //reply enquiry by employee
  REPLY_EMPLOYEE_TO_ENQUIRY_LOADING = "@@ticketsystem/REPLY_EMPLOYEE_TO_ENQUIRY_LOADING",
  REPLY_EMPLOYEE_TO_ENQUIRY_SUCCESS = "@@ticketsystem/REPLY_EMPLOYEE_TO_ENQUIRY_SUCCESS",
  REPLY_EMPLOYEE_TO_ENQUIRY_ERROR = "@@ticketsystem/REPLY_EMPLOYEE_TO_ENQUIRY_ERROR",

  //response the enquiry

  RESPONSE_ENQUIRY_LOADING = "@@ticketsystem/RESPONSE_ENQUIRY_LOADING",
  RESPONSE_ENQUIRY_SUCCESS = "@@ticketsystem/RESPONSE_ENQUIRY_SUCCESS",
  RESPONSE_ENQUIRY_ERROR = "@@ticketsystem/RESPONSE_ENQUIRY_ERROR",

  // get reply message from employee
  GET_REPLY_MESSAGE_FROM_EMPLOYEE_LOADING = "@@ticketsystem/GET_REPLY_MESSAGE_FROM_EMPLOYEE_LOADING",
  GET_REPLY_MESSAGE_FROM_EMPLOYEE_SUCCESS = "@@ticketsystem/GET_REPLY_MESSAGE_FROM_EMPLOYEE_SUCCESS",
  GET_REPLY_MESSAGE_FROM_EMPLOYEE_ERROR = "@@ticketsystem/GET_REPLY_MESSAGE_FROM_EMPLOYEE_ERROR",


  //re assign ticket 
  REASSIGN_TICKET_BY_EMPLOYEE_LOADING = "@@ticketsystem/REASSIGN_TICKET_BY_EMPLOYEE_LOADING",
  REASSIGN_TICKET_BY_EMPLOYEE_SUCCESS = "@@ticketsystem/REASSIGN_TICKET_BY_EMPLOYEE_SUCCESS",
  REASSIGN_TICKET_BY_EMPLOYEE_ERROR = "@@ticketsystem/REASSIGN_TICKET_BY_EMPLOYEE_ERROR",

  //reopen ticket
  REOPEN_TICKET_BY_CUSTOMER_LOADING = "@@ticketsystem/REOPEN_TICKET_BY_CUSTOMER_LOADING",
  REOPEN_TICKET_BY_CUSTOMER_SUCCESS = "@@ticketsystem/REOPEN_TICKET_BY_CUSTOMER_SUCCESS",
  REOPEN_TICKET_BY_CUSTOMER_ERROR = "@@ticketsystem/REOPEN_TICKET_BY_CUSTOMER_ERROR",

  //close Ticket
  CLOSE_TICKET_EMPLOYEE_LOADING = "@@ticketsystem/CLOSE_TICKET_EMPLOYEE_LOADING",
  CLOSE_TICKET_EMPLOYEE_SUCCESS = "@@ticketsystem/CLOSE_TICKET_EMPLOYEE_SUCCESS",
  CLOSE_TICKET_EMPLOYEE_ERROR = "@@ticketsystem/CLOSE_TICKET_EMPLOYEE_ERROR",

  //get reassign employee list

  GET_REASSIGN_EMPLOYEE_LIST_LOADING = "@@ticketsystem/GET_REASSIGN_EMPLOYEE_LIST_LOADING",
  GET_REASSIGN_EMPLOYEE_LIST_SUCCESS = "@@ticketsystem/GET_REASSIGN_EMPLOYEE_LIST_SUCCESS",
  GET_REASSIGN_EMPLOYEE_LIST_ERROR = "@@ticketsystem/GET_REASSIGN_EMPLOYEE_LIST_ERROR",

  //reset the induvidual query
  RESET_INDUVIDUAL_QUERY_DATA = "@@ticketsystem/RESET_INDUVIDUAL_QUERY_DATA",

  //get customerlist 
  GET_CUSTOMER_DATA_LOADING = "@@ticketsystem/GET_CUSTOMER_DATA_LOADING",
  GET_CUSTOMER_DATA_SUCCESS = "@@ticketsystem/GET_CUSTOMER_DATA_SUCCESS",
  GET_CUSTOMER_DATA_ERROR = "@@ticketsystem/GET_CUSTOMER_DATA_ERROR",

}