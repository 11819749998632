import { API } from "src/util/api";
import { getPendingPartsSuccess, getPendingPartsError } from "./action";
import { pendingPartsActions } from "./actionTypes";
import { call, put, takeLatest } from "redux-saga/effects"


//worker function

//get method

function* getPendingPartsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPendingPartsApi, { ...action.lazyState });
    yield put(getPendingPartsSuccess(response));
  } catch (error) {
    yield put(getPendingPartsError(error));
  } 
}


//watcher function

function* pendingPartsSaga() {
  yield takeLatest(pendingPartsActions.GET_PENDING_PARTS_LOADING, getPendingPartsAsync)
}


export default pendingPartsSaga ;