import { stockValuebyPartInitialState } from "src/models/pages/stockValuebyPart";
import { StockValuebyPartTypes } from "./actionTypes";

const initialState: stockValuebyPartInitialState = {
  error: "",
  loading: false,
  message: "",
  stockValuebyPartList: {
    values: [],
    totalRecords: 0,
  },
  partDropdownList: "",
};

const StockValuebyPart = (state = initialState, action: any) => {
  switch (action.type) {
    // Grid

    case StockValuebyPartTypes.GET_STOCK_VALUE_BY_PART_LIST:
    case StockValuebyPartTypes.GET_STOCK_PART_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case StockValuebyPartTypes.GET_STOCK_VALUE_BY_PART_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stockValuebyPartList: {
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        },
      };

    case StockValuebyPartTypes.GET_STOCK_PART_LIST_SUCCESS:

      return {
        ...state,
        loading: false,
        partDropdownList: action.payload.data,
      };

    case StockValuebyPartTypes.RESET_STOCK_VALUE_BY_PART_LIST:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
      };

    default:
      return { ...state };
  }
};

export default StockValuebyPart;
