
import { monthlyPartsSummaryReducerState } from 'src/models/pages/monthlyPartSummaryModel';
import { monthlyPartsSummaryActions } from './actionTypes';

const initialState: monthlyPartsSummaryReducerState = {
  loading: false,
  error: "",
  message: "",
  monthlyPartsSummaryList: {
    items: [],
    totalCount: 0
  }
}


const monthlyPartSummaryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: ""
      }

    case monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        monthlyPartsSummaryList: {
          ...state.monthlyPartsSummaryList,
          items: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        }
      }

    case monthlyPartsSummaryActions.GET_MONTHLY_PARTS_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return { ...state }
  }
}

export default monthlyPartSummaryReducer;