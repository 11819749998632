import {StockValueByMusicSystemBrandTypes } from "./actionTypes";
import { StockValueByMusicSystemInitialStore } from "src/models/pages/stockValueMusicSystemBrandModel";

const initialValue: StockValueByMusicSystemInitialStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  stockValueByMusicSystemBrandList: {
    values: [],
    totalRecords: 0
  },
  audioBrandDropdownList:""
}

const stockValueBySupplier = (state = initialValue, action: any) => {
  switch (action.type) {
    case StockValueByMusicSystemBrandTypes.GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING:
    case StockValueByMusicSystemBrandTypes.GET_AUDIO_BRAND_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }

    case StockValueByMusicSystemBrandTypes.GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_SUCCESS:
      console.log("datadata",action.payload.data);
      
      return {
        ...state,
        loading: false,
        stockValueByMusicSystemBrandList: {
          ...state.stockValueByMusicSystemBrandList,
          // values: action.payload.items,
          values: action.payload.data,
          totalRecords: action.payload.data?.length
          // totalRecords: action.payload.data.totalCount
        },
        paginatorCount : action.payload.data?.length
      }

    case StockValueByMusicSystemBrandTypes.GET_AUDIO_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBrandDropdownList:action.payload.data
      }

    case StockValueByMusicSystemBrandTypes.GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_ERROR:
    case StockValueByMusicSystemBrandTypes.GET_AUDIO_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state };
  }
}


export default stockValueBySupplier;