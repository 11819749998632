export enum IncentiveTypes {
  GET_INCENTIVE_LIST_LOADING = "@@incentive/GET_INCENTIVE_LIST_LOADING",
  GET_INCENTIVE_LIST_SUCCESS = "@@incentive/GET_INCENTIVE_LIST_SUCCESS",
  GET_INCENTIVE_LIST_FAIL = "@@incentive/GET_INCENTIVE_LIST_FAIL",

  GET_INCENTIVE_EMPTY_LIST_LOADING = "@@incentive/GET_INCENTIVE_EMPTY_LIST_LOADING",
  GET_INCENTIVE_EMPTY_LIST_SUCCESS = "@@incentive/GET_INCENTIVE_EMPTY_LIST_SUCCESS",
  GET_INCENTIVE_EMPTY_LIST_FAIL = "@@incentive/GET_INCENTIVE_EMPTY_LIST_FAIL",

  UPDATE_INCENTIVE_LOADING = "@@incentive/UPDATE_INCENTIVE_LOADING",
  UPDATE_INCENTIVE_SUCCESS = "@@incentive/UPDATE_INCENTIVE_SUCCESS",
  UPDATE_INCENTIVE_FAIL = "@@incentive/UPDATE_INCENTIVE_FAIL",
}
