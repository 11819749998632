import { MelTableTypes } from "src/store/melTable/actionTypes";


export const MelTableFetchLoading = (lazyState : any) => {
  return {
    type: MelTableTypes.TABLE_LOADING,
    lazyState
  };
};

export const MelTableFetchSuccess = (user: any) => {
  return {
    type: MelTableTypes.TABLE_SUCCESS,
    payload: user,
  };
};

export const MelTableFetchError = (error: any) => {
  return {
    type: MelTableTypes.TABLE_FETCHERROR,
    payload: error,
  };
};
