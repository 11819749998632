import { call, put, takeLatest } from "redux-saga/effects";
import { courierActionsTypes } from "./actionTypes";
import { courierListError, courierListLoading, courierListSuccess, courierStatusTrackingError, courierStatusTrackingSuccess, createCourierError, createCourierSuccess, dealerListError, dealerListSuccess, donloadPdfCourierPageError, donloadPdfCourierPageSuccess, getContactDetailsByRecievedUserError, getContactDetailsByRecievedUserSuccess, getContactDetailsByUSerIDError, getContactDetailsByUSerIDSuccess, getMelEmployeeAddressError, getMelEmployeeAddressSuccess, getbyIdCourierDetailsError, getbyIdCourierDetailsSuccess, updateCourierError, updateCourierSuccess, viewCourierError, viewCourierSuccess } from "./action";
import { API } from "src/util/api";


//worker function

//courier list
function* fetchCourierListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getCourierListApi, { ...action.lazyState });
    yield put(courierListSuccess(response))
  } catch (error) {
    yield put(courierListError(error))
  }
}


//view courier page

function* viewCourierLoadingAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.viewCourierPage, action.payload);
    yield put(viewCourierSuccess(response))
  } catch (error) {
    yield put(viewCourierError(error));
  }
}

//create courier

function* createCourierAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.createCourierApi, action.payload);
    yield put(createCourierSuccess(response))
  } catch (error) {
    yield put(createCourierError(error));
  }
}


//get by id 
function* getbyIdCourierAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getByIdCourierApi, action.payload);
    yield put(getbyIdCourierDetailsSuccess(response))
  } catch (error) {
    yield put(getbyIdCourierDetailsError(error));
  }
}

//update courier
function* updateCourierAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.updateCourierApi, action.payload, action.id);
    yield put(updateCourierSuccess(response))
  } catch (error) {
    yield put(updateCourierError(error))
  }
}

//dealer dropdown

function* getDealerListAsync() {
  try {
    const response: Promise<any> = yield call(API.getDealerListApi);
    yield put(dealerListSuccess(response));
  } catch (error) {
    yield put(dealerListError(error));
  }
}



//get contact details by userId

function* getContactDetailsAsync(action: any) {
  try {
    debugger
    const response: Promise<any> = yield call(API.getContactDetailsrelatedUserIdApi, action.payload.selectedUser)
    yield put(getContactDetailsByUSerIDSuccess(response, action.payload.setFieldValue, action.payload.currentSenderType, action.payload.selectedVaue))
  } catch (error) {
    yield put(getContactDetailsByUSerIDError(error))
  }
}

//get contact details by userId receiver type

function* getContactDetailsReceiverAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getContactDetailsrelatedUserIdApi, action.payload.selectedUser)
    yield put(getContactDetailsByRecievedUserSuccess(response, action.payload.selectedUser, action.payload.setFieldValue, action.payload.currentReceiverType, action.payload.selectedVaue))
  } catch (error) {
    yield put(getContactDetailsByRecievedUserError(error))
  }
}

//get mel employee address

function* getMelEmployeeAddressAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getmelEmployeeAddressApi);
    yield put(getMelEmployeeAddressSuccess(response, action.payload.setFieldValue, action.payload.currentType));
  } catch (error) {
    yield put(getMelEmployeeAddressError(error));
  }
}

//download courier page

function* downloadCourierPageAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.downloadCourierPage, action.payload);
    yield put(donloadPdfCourierPageSuccess(response));
  } catch (error) {
    yield put(donloadPdfCourierPageError(error));
  }
}

//tracking courier status

function* trackCourierStatusAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.courierTrackingStatusApi,action.payload);
    yield put(courierStatusTrackingSuccess(response))
  } catch (error) {
    yield put(courierStatusTrackingError(error))
  }
}


//watcher function

function* courierSaga() {
  yield takeLatest(courierActionsTypes.GET_COURIERS_LIST_LOADING, fetchCourierListAsync);
  yield takeLatest(courierActionsTypes.VIEW_COURIER_LOADING, viewCourierLoadingAsync);
  yield takeLatest(courierActionsTypes.CREATE_COURIER_LOADING, createCourierAsync);
  yield takeLatest(courierActionsTypes.GETBY_ID_COURIER_LOADING, getbyIdCourierAsync);
  yield takeLatest(courierActionsTypes.UPDATE_COURIER_LOADING, updateCourierAsync);
  yield takeLatest(courierActionsTypes.GET_DEALER_DROPDOWN_LOADING, getDealerListAsync);
  yield takeLatest(courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_LOADING, getContactDetailsAsync);
  yield takeLatest(courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_LOADING, getContactDetailsReceiverAsync);
  yield takeLatest(courierActionsTypes.GET_MEl_ADDRESS_LOADING, getMelEmployeeAddressAsync);
  yield takeLatest(courierActionsTypes.DOWNLOAD_PDF_COURIER_LOADING, downloadCourierPageAsync)
  yield takeLatest(courierActionsTypes.COURIER_TRACKING_STATUS_LOADING,trackCourierStatusAsync)


}

export default courierSaga;