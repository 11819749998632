import React, { FocusEvent } from "react";
import { InputProps, Label } from "reactstrap";
import Input from "../atoms/Input";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import ErrorText from "../UI/ErrorText";
import { ErrorMessage } from "formik";
import FeatherIcon from "feather-icons-react";

interface InputWrapperProps extends InputProps {
  id?: string;
  labelName?: string;
  inputclassName?: string;
  checked?: any;
  labelClassName?: string;
  name?: string;
  value?: string | any;
  isRequired?: boolean;
  error?: string;
  rowHeight?: number;
  loading?: boolean;
  cameraIcon?: boolean;
  camerClick?: any;
  uploadIcon?: boolean;
  clickClearButton?: any;
  camerIconDisable?: boolean;
  clear?: boolean;
  filehandleChange ? : any;
  fileValue?:any ;
  fileName? :any;
  panIcon ?: boolean ;
  panValue?:any;
  panName?:any
}

const InputCom: React.FC<InputWrapperProps> = (props) => {
  const {
    id,
    labelClassName,
    labelName,
    isRequired,
    inputclassName,
    invalid,
    type,
    name,
    rowHeight,
    checked,
    value,
    error,
    loading = false,
    onChange,
    cameraIcon,
    camerClick,
    clickClearButton,
    camerIconDisable,
    clear,
    uploadIcon,
    filehandleChange,
    fileName,
    fileValue,
    panIcon,
    panValue,
    panName,
    max,
    ...rest
  } = props;


  return (
    <>
      {type === "checkbox" ? (
        <div className="checkboxName input-style" >
          {/* {checked ? "checked": "not checked"} */}
          <Input
            id={id}
            className={`${inputclassName} ${invalid && "is-invalid"}`}
            type={type}
            name={name}
            invalid={invalid}
            value={value}
            checked={checked}
            onChange={onChange}
            {...rest}
          />
          <Label htmlFor={id} className={labelClassName}>
            {labelName} {isRequired && <span className="text-danger">*</span>}
          </Label>
        </div>
      ) : (
        <>
          {labelName && <> <Label htmlFor={id} className={labelClassName}>
            {labelName} {isRequired && <span className="text-danger">*</span>}
          </Label>
            <br className={labelClassName} /></>
          }
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}>
            <Input
              id={id}
              className={`${inputclassName} ${invalid && "is-invalid"}`}
              type={type}
              name={name}
              autoComplete="off"
              rowHeight={rowHeight}
              max={max}
              invalid={invalid}
              value={value}
              onChange={onChange}
              {...rest}
            />
            {
              cameraIcon && <div className="camera-icon-input">
                <FeatherIcon icon="camera" className={`icon-lg right-icon ${camerIconDisable ? 'camera_disabled' : 'pointer'}`} onClick={camerClick} />
              </div>
            }
            {
              clear && <div style={{ position: "absolute", right: "30px", padding: " 0px 10px" }}>
                <FeatherIcon icon="x" className={`icon-lg right-icon`} onClick={clickClearButton} />
              </div>
            }

            {
              uploadIcon && <div style={{ position: "absolute", right: "30px", padding: " 0px 10px" }}>
                <Label style={{marginBottom:"0"}} htmlFor="file-input" className={labelClassName}>
                  <FeatherIcon icon="upload" className={`icon-lg right-icon`} />
                </Label>
                <Input
                  style={{ display: "none" }}
                  id="file-input"
                  className={`${inputclassName} ${invalid && "is-invalid"}`}
                  type="file"
                  name={fileName}
                  rowHeight={rowHeight}
                  invalid={invalid}
                  value={fileValue}
                  onChange={filehandleChange}
                  {...rest}
                />
              </div>
            }
            {
              panIcon && <div style={{ position: "absolute", right: "30px", padding: " 0px 10px" }}>
                <Label style={{marginBottom:"0"}} htmlFor="pan-input" className={labelClassName}>
                  <FeatherIcon icon="upload" className={`icon-lg right-icon`} />
                </Label>
                <Input
                  style={{ display: "none" }}
                  id="pan-input"
                  className={`${inputclassName} ${invalid && "is-invalid"}`}
                  type="file"
                  name={panName}
                  rowHeight={rowHeight}
                  invalid={invalid}
                  value={panValue}
                  onChange={filehandleChange}
                  {...rest}
                />
              </div>
            }


          </div>
          {!loading && <ErrorMessage name={name || error || ''} component={ErrorText} />}
        </>
      )}
    </>
  );
};

export default InputCom;
