export enum EmployeeProfileTypes {

    GET_EMPLOYEE_ID_LOADING = "@@employeeID/GET_EMPLOYEE_ID_LOADING",
    GET_EMPLOYEE_ID_SUCCESS = "@@employeeID/GET_EMPLOYEE_ID_SUCCESS",
    GET_EMPLOYEE_ID_ERROR = "@@employeeID/GET_EMPLOYEE_ID_ERROR",
    //get
    GET_EMPLOYEE_PROFILE_LOADING = "@@employeeprofile/GET_EMPLOYEE_PROFILE_LOADING",
    GET_EMPLOYEE_PROFILE_SUCCESS = "@@employeeprofile/GET_EMPLOYEE_PROFILE_SUCCESS",
    GET_EMPLOYEE_PROFILE_ERROR = "@@employeeprofile/GET_EMPLOYEE_PROFILE_ERROR",

    EMPLOYEE_FILE_FETCH_LOADING="@@employeeprofile/EMPLOYEE_FILE_FETCH_LOADING",
    EMPLOYEE_FILE_FETCH_SUCCESS="@@employeeprofile/EMPLOYEE_FILE_FETCH_SUCCESS",
    EMPLOYEE_FILE_FETCH_ERROR="@@employeeprofile/EMPLOYEE_FILE_FETCH_ERROR",

    EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING="@@employeeprofile/EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING",
    EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS="@@employeeprofile/EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS",
    EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR="@@employeeprofile/EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR",

    EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING="@@employeeprofile/EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING",
    EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR="@@employeeprofile/EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR",
    EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS="@@employeeprofile/EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS",

    //clear
    CLEAR_EMPLOYEE_PROFILE = "@@employeeprofile/CLEAR_EMPLOYEE_PROFILE",

    //reset
    RESET_EMPLOYEE_PROFILE = "@@employeeprofile/RESET_EMPLOYEE_PROFILE",
}