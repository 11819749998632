import React from "react";
import ActionTable from "./ActionTable";

export const HeaderData = [
  {
    Header: "First Name",
    accessor: "firstName",
    canFilter: true,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    canFilter: true,
  },
  {
    Header: "Age",
    accessor: "age",
    canFilter: true,
  },
  {
    Header: "Visits",
    accessor: "visits",
    canFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    canFilter: true,
  },
  {
    Header: "Profile Progress",
    accessor: "progress",
    canFilter: true,
  },
  {
    Header: "Action",
    canFilter: false,
    Cell: (cellProps: any) => {
      return (
        <>
          <ActionTable cellProps={cellProps} />
        </>
      )
    }
  },
];



