import { enquiryTypes } from "./actionTypes";


// For Grid

export const getEnquiryList = (lazyState: any) => {
  return {
    type: enquiryTypes.GET_ENQUIRY_LIST,
    lazyState,
  };
};



export const getEnquiryListSuccess = (enquiry: any) => {
  return {
    type: enquiryTypes.GET_ENQUIRY_LIST_SUCCESS,
    payload: enquiry,
  };
};

export const getEnquiryListError = (error: any) => {
  return {
    type: enquiryTypes.GET_ENQUIRY_LIST_FAIL,
    payload: error,
  };
};


export const deleteEnquiryFromList = (payload:any) => {
  return {
    type: enquiryTypes.DELETE_ENQUIRY,
    payload: payload,
  }
}

export const deleteEnquiryFromListSuccess = (response:any) => {
  return {
    type: enquiryTypes.DELETE_ENQUIRY_SUCCESS,
    payload: response,
  }
}

export const deleteEnquiryFromListError = (error:any) => {
  return {
    type: enquiryTypes.DELETE_ENQUIRY_FAIL,
    payload: error,
  }
}

// post Enquiry
export const createEnquiry = (enquiry: any) => {
  return { type: enquiryTypes.POST_ENQUIRY, payload: enquiry };
};

export const createEnquirySuccess = (enquiry: any) => {
  return { type: enquiryTypes.POST_ENQUIRY_SUCCESS, payload: enquiry };
};

export const createEnquiryError = (error: any) => {
  return { type: enquiryTypes.POST_ENQUIRY_FAIL, payload: error };
};


// get Enquiry
export const getEnquiryBYID = (id: string) => {
  return { type: enquiryTypes.GET_ENQUIRY_BY_ID, payload: id };
};

export const getEnquiryBYIDSuccess = (enquiry: any) => {
  return { type: enquiryTypes.GET_ENQUIRY_BY_ID_SUCCESS, payload: enquiry };
};

export const getEnquiryBYIDError = (error: any) => {
  return { type: enquiryTypes.GET_ENQUIRY_BY_ID_FAIL, payload: error };
};


export const getJobsheetDropdownForEnquiry = () => {
  return { type: enquiryTypes.GET_JOBSHEET_DROPDOWN};
};

export const getJobsheetDropdownForEnquirySuccess = (enquiry: any) => {
  return { type: enquiryTypes.GET_JOBSHEET_DROPDOWN_SUCCESS, payload: enquiry };
};

export const getJobsheetDropdownForEnquiryError = (error: any) => {
  return { type: enquiryTypes.GET_JOBSHEET_DROPDOWN_FAIL, payload: error };
};

export const resetEnquiryMessage = ()=>{
  return { type: enquiryTypes.RESET_ENQUIRY};
}
export const clearEnquiryMessage = ()=>{
  return { type: enquiryTypes.CLEAR_ENQUIRY};
}

export const getEmployeeDropdownList = () => {
  return {
    type: enquiryTypes.GET_EMPLOYEE_DROPDOWN_LIST,
  };
};

export const getEmployeeDropdownListSuccess = (attendance: any) => {
  return {
    type: enquiryTypes.GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS,
    payload: attendance,
  };
};

export const getEmployeeDropdownListError = (error: any) => {
  return {
    type: enquiryTypes.GET_EMPLOYEE_DROPDOWN_LIST_FAIL,
    payload: error,
  };
};

export const getFranchiseDropdownList = () => {
  return {
    type: enquiryTypes.GET_FRANCHISE_DROPDOWN_LIST,
  };
};

export const getFranchiseDropdownListSuccess = (attendance: any) => {
  return {
    type: enquiryTypes.GET_FRANCHISE_DROPDOWN_LIST_SUCCESS,
    payload: attendance,
  };
};

export const getFranchiseDropdownListError = (error: any) => {
  return {
    type: enquiryTypes.GET_FRANCHISE_DROPDOWN_LIST_FAIL,
    payload: error,
  };
};

export const getFranchiseSubuserDropdownList = () => {
  return {
    type: enquiryTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST,
  };
};

export const getFranchiseSubuserDropdownListSuccess = (attendance: any) => {
  return {
    type: enquiryTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS,
    payload: attendance,
  };
};

export const getFranchiseSubuserDropdownListError = (error: any) => {
  return {
    type: enquiryTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL,
    payload: error,
  };
};


export const getFranchiseJobsheetDropdownForEnquiry = (id:any) => {
  return { type: enquiryTypes.GET_FRANCHISE_JOBSHEET_DROPDOWN , id:id};
};

export const getFranchiseJobsheetDropdownForEnquirySuccess = (enquiry: any) => {
  return { type: enquiryTypes.GET_FRANCHISE_JOBSHEET_DROPDOWN_SUCCESS, payload: enquiry };
};

export const getFranchiseJobsheetDropdownForEnquiryError = (error: any) => {
  return { type: enquiryTypes.GET_FRANCHISE_JOBSHEET_DROPDOWN_FAIL, payload: error };
};

