import React from 'react'
import './payment.scss'
import  Checked  from 'src/assets/images/checked.png'

export const PaymentSuccesss = () => {
  return (
    <div className="page-content">
        <div className="payment-sucess-container">
            <div className="payment-dialog-container">
                <div className="img-container">
                    <img src={Checked} alt="" />
                </div>
                <div className="text-container">
                    <h5>Payment Received Successfully!</h5>
                </div>
            </div>
        </div>
    </div>
  )
}
