import React from 'react';
import { useAsyncDebounce } from "react-table";

interface Globalfilter {
    preGlobalFilteredRows: any;
    globalFilter: any;
    setGlobalFilter: any;
}

function GlobalFilter({
    //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}: Globalfilter) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value: any) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input 
                className="form-control mt-1"
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </span>
    );
}


export default GlobalFilter;