import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import {
  CurrencyFormatter,
  formatDateMEL,
  hasAdminOrEmployeeRoleHandler,
  hasFranchiseRoleHandler,
} from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import {
  additionalExpenseReportsListByIdLoading,
  deletePurchaseOrderFromList,
  resetPurchaseOrder,
} from 'src/store/actions';
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DeleteModal from "../../Calendar/DeleteModal";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns } from 'src/models/components/dataTableWrapperModel'
import { Link } from 'react-router-dom'
import { clearMessageForIncentiveReward, resetIncentiveReward } from 'src/store/IncentiveReward/action';
import UpdateAdjustmentPoint from './UpdateAdjustmentPoint'
import UpdatePaymentDetail from './UpdatePaymentDetail'
// import UpdatePaymentDetail from './UpdatePaymentDetail'
// import UpdateAdjustmentPoint from './UpdateAdjustmentPoint'


const ViewAdditionalExpenseSettings = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { additionalExpenseDetails } = useSelector(
    (state: RootReducerState) => state.additionaExpenseReportsReducer
  );
  const { error, message, loading } = useSelector(
    (state: RootReducerState) => state.additionaExpenseReportsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )
  }


  const [visible, setVisible] = useState(false);
  const { showToast } = useToast();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearMessageForIncentiveReward());
      dispatch(additionalExpenseReportsListByIdLoading(id));
      window.scrollTo({top:1})
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPurchaseOrder())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(additionalExpenseReportsListByIdLoading(id));
    }
    return () => {
      dispatch(resetIncentiveReward());
    };
  }, [id]);

  const currencyBodyTemplate = (totalamount: string, code: string) => {
    let amount = totalamount;
    let currencyCode = code;
    return <div>{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>;
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${field}`}>{rowData}</Link>
    );
  };

  const userView = {
    generalDetails: [
      {
        name: "User Name",
        value: additionalExpenseDetails?.userName
      },
      {
        name: "User Type",
        value: additionalExpenseDetails?.userType
      },
      {
        name: "Month",
        value: `${String(additionalExpenseDetails?.month)?.padStart(2,'0')}`
      },

      {
        name: "Original Amount",
        value:currencyBodyTemplate(additionalExpenseDetails?.originalAmount,'INR')
      },
      {
        name: "Total Amount",
        value:currencyBodyTemplate(additionalExpenseDetails?.totalAmount,'INR')
      },
    ],
      paymentDetails:[


        {
          name: "Paid At :",
          value: formatDateMEL(additionalExpenseDetails?.paymentDate)
        },
      {
        name: "Description :",
        value: additionalExpenseDetails?.paymentDescription
      },
    ],
      adjustmentDetails:[
      {
        name: "Amount :",
        value: currencyBodyTemplate(additionalExpenseDetails?.adjustedAmount,'INR')
      },
      {
        name: "Notes :",
        value: additionalExpenseDetails?.adjustmentNotes
      },


    ]
  }


  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"150px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }

    const JobNavigateTemplate=(jobId:any)=>{
      if(jobId){

        return(
          <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${jobId}`}>{`JobSheet (${jobId})`}</Link>
        )
      }
      else{
        return ""
      }
    }
  const additionalExpenseColumn: Columns[] = [
      {
        field: "expensesName",
        sortable: false,
        header: "Expense Name",
        filter: false,
        showFilterMenu: false,
        headerStyle: { whiteSpace: "nowrap", width: "250px" },
        body:(rowData:any)=>{return String(rowData.expensesName).toLocaleUpperCase()},
        isDefault: true,
        isStatic: true,
  
      },
    {
        field: "jobId",
        sortable: false,
        header: "Jobsheet",
        filter: false,
        showFilterMenu: false,
        body:(rowData:any)=>JobNavigateTemplate(rowData.jobId),
        headerStyle: { whiteSpace: "nowrap", width: "250px" },
        isDefault: true,
        isStatic: true,
      },
    {
      field: "taxCode",
      sortable: false,
      header: "Tax Code",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
        field: "taxPercentage",
        sortable: false,
        header: "Tax Percentage",
        filter: false,
        showFilterMenu: false,
        body:(rowData:any)=>{return (rowData.taxPercentage!==null ? `${rowData.taxPercentage}%` : "")},
        className:"align-right",
        headerStyle: { whiteSpace: "nowrap", width: "250px" },
        isDefault: true,
        isStatic: true,
      },
      {
        field: "amountWithoutTax",
        sortable: false,
        header: "Amount Without Tax",
        filter: false,
        showFilterMenu: false,
        body:(rowData:any)=>currencyBodyTemplate(rowData.amountWithoutTax,'INR'),
        className:"align-right",
        headerStyle: { whiteSpace: "nowrap", width: "250px" },
        isDefault: true,
        isStatic: true,
      },
      {
        field: "taxAmount",
        sortable: false,
        header: "Tax Amount",
        filter: false,
        showFilterMenu: false,
        body:(rowData:any)=>currencyBodyTemplate(rowData.taxAmount,'INR'),
        className:"align-right",
        headerStyle: { whiteSpace: "nowrap", width: "250px" },
        isDefault: true,
        isStatic: true,
      },
    {
      field: "netAmount",
      sortable: false,
      header: "Net Amount",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>currencyBodyTemplate(rowData.netAmount,'INR'),
      className:"align-right",
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "finalAmount",
      sortable: false,
      header: "Final Amount",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>currencyBodyTemplate(rowData.finalAmount,'INR'),
      className:"align-right",
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },

  ];


  return (
    <React.Fragment>
      <div className='userpage' id="incentiveReports">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={false}
          onDeleteClick={() => {
            let payload = {
              id: id,
              undo: false,
            };
            dispatch(deletePurchaseOrderFromList(payload));
            setDeleteModal(false);
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />
        <Container fluid>
          <BreadcrumbWithFilter
            title="Additional Expenses Reports" titlePath={ROUTER.ADDITIONAL_EXPENSES_REPORTS_TABLE} breadcrumbItem='Additional Expenses Reports Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}

          />
          <Row>
            <Col lg={hasFranchiseRoleHandler() ? 12 : 6}>
            <Col lg={12}>
              <div className="view" > 
                <div className="general-details">
                  <b>Additional Expense Details</b>
                </div>
                <div className="subview">
                  <div className="AdjustmetnCls">
                    {
                      userView.generalDetails.map((data, index) => {
                        return (
                          <div key={index} className={` pt-1 childClsNew`}>
                            <div>{data.name}</div>
                            <div>{data.value ? data.value : <div className=''>-</div>}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            
            <Row>
            <Col lg={hasFranchiseRoleHandler() ? 6 : 12}>
              <div className="view"  id="paymentDetails">
                <div className="general-details d-flex justify-content-between" style={{padding:"9px"}}>
                  <b>Payment Details</b>

                  <b className={additionalExpenseDetails.isPaid? "low" : "highStatus"}>{additionalExpenseDetails.isPaid ? "Paid" : "Un Paid"}</b>
                </div>
                <div className="subview">
                  <div className="AdjustmetnCls">
                    {
                      userView.paymentDetails.map((data, index) => {
                        return (
                          <div key={index} className={` pt-1 childClsNew`}>
                            <div>{data.name}</div>
                            <div>{data.value ? data.value : <div className=''>-</div>}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={hasFranchiseRoleHandler() ? 6 : 12}>
              <div className="view"  id="paymentDetails">
                <div className="general-details">
                  <b>Adjustment Details</b>
                </div>
                <div className="subview">
                  <div className="AdjustmetnCls">
                    {
                      userView.adjustmentDetails.map((data, index) => {
                        return (
                          <div key={index} className={` pt-1 childClsNew`}>
                            <div>{data.name}</div>
                            <div>{data.value ? data.value : <div className=''>-</div>}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            </Col>
          
            <Col lg={6}>
            {(hasAdminOrEmployeeRoleHandler())&& 
          <Row>
            <Col lg={12}>
                <Card style={{ borderRadius: "20px" }}>
                    <CardBody>
                        <h5 className='mb-4'>Update Adjustment Amount</h5>
                        <UpdateAdjustmentPoint details={additionalExpenseDetails} id={id} disabled={additionalExpenseDetails?.isPaid}/>
                    </CardBody>
                </Card>
            </Col>
          </Row>
          }
          {hasAdminOrEmployeeRoleHandler() && 
          <Row>
            <Col lg={12}>
                <Card style={{ borderRadius: "20px" }}>
                    <CardBody>
                        <h5 className='mb-4'>Update Payment Details</h5>
                        <UpdatePaymentDetail details={additionalExpenseDetails} id={id}/>
                    </CardBody>
                </Card>
            </Col>
          </Row>
          }
            </Col>
          </Row>

          <Row>
            {additionalExpenseDetails?.expenseDetails?.length > 0 &&
                <div className="view">
                  <div className="general-details">
                    <b>Expense Details List</b>
                  </div>
                  <div className="subview">
                    <Row>
                      <Col lg={12}>
                      <div className="card ps-0 pe-0 " id="table-border">
                        <DataTableWrapper
                          stripedRows
                          rowHover
                          columnDetails={additionalExpenseColumn}
                          value={additionalExpenseDetails?.expenseDetails}
                          scrollable={true}
                          scrollHeight="400px"
                        >
                        </DataTableWrapper>
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div>
            }
          </Row>
          
          

        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewAdditionalExpenseSettings