import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { ROUTER } from 'src/constants/routes';
import { Form, Formik } from 'formik';
import InputCom from 'src/components/Common/Input';
import Button from 'src/components/UI/Button';
import * as Yup from "yup";
// import MelSelect from 'src/components/atoms/MelSelect';
import { useDispatch, useSelector } from 'react-redux';
import { createAudioModelPending, getAudioBrandDropDownListLoading, getByIdAudioModelPending, resetAudioModelState, updateAudioModelPending } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import DropdownInput from 'src/components/UI/DropdownInput';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';

const EditAudioModels = () => {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { error, message, loading } = useSelector((state: RootReducerState) => state.audioModelsReducer)
  const { audioBrandList, audioModels } = useSelector((state: RootReducerState) => state.audioModelsReducer.audioBrandNames) || {};
  const initial: any = {
    number: "",
    serviceCharge: "",
    exchangeFixedFee: 0,
    sacNumber: "",
    taxpercentage: "",
    audioBrandId: "",
    audioPartNumber: "",
    description: ""
  }

  const getInitialValue = () => {
    if (id) {
      return {
        number: audioModels?.number ? audioModels?.number : "",
        serviceCharge: audioModels?.serviceCharge ? audioModels?.serviceCharge : "",
        exchangeFixedFee: audioModels?.exchangeFixedFee ? audioModels?.exchangeFixedFee : "",
        sacNumber: audioModels?.sacNumber ? audioModels?.sacNumber : "",
        taxpercentage: audioModels?.taxpercentage ? audioModels?.taxpercentage : "",
        audioBrandId: audioModels?.audioBrandId ? audioModels?.audioBrandId : "",
        audioPartNumber: audioModels?.audioPartNumber ? audioModels?.audioPartNumber : "",
        description: audioModels?.description ? audioModels?.description : "",
      }
    } else {
      return initial
    }
  }

  const initialValues = getInitialValue()

  const audioBrandDropDownData = audioBrandList?.length > 0 ? (audioBrandList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.id;
    data['label'] = eachTax.brandName;
    return data;
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []

if(id){
  let find=audioBrandDropDownData.find((item:any)=>item.value==audioModels?.audioBrandId)
  if(find==undefined){
    initialValues.audioBrandId=""
  }
}

  //unmount
  useEffect(() => {
    dispatch(getAudioBrandDropDownListLoading())
    return () => {
      dispatch(resetAudioModelState())
    }
  }, [])

  useEffect(() => {

    if (error) {
      setIsSubmitting(false)
      setVisible(true)
    }
    if (message != "" && message != undefined) {
      showToast(message, { type: "success" })
      dispatch(resetAudioModelState())
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.AUDIO_MODELS_GRID)
      }, 500)
    }

  }, [error, message])

  // useEffect(() => {
  //   // if (message != "" && message != undefined) {      
  //   //   showToast(message, { type: "success" })
  //   //   dispatch(resetAudioModelState())
  //   //   setTimeout(() => {
  //   //     setIsSubmitting(false) 
  //   //     navigate(ROUTER.AUDIO_MODELS_GRID) }, 500)
  //   // }
  // }, [message])

  const validationSchema = Yup.object().shape({
    number: Yup.string().required(`Number is required`),
    serviceCharge: Yup.number().required("Service Charge is required").positive("Service Charge must be a positive number"),
    exchangeFixedFee: Yup.number().required("Exchange Fixed Fee is required"),
    // .positive("Exchange Fixed Fee must be a positive number"),
    sacNumber: Yup.string().required("Sac Number is required").max(8, 'Sac Number must be less than 8 characters'),
    taxpercentage: Yup.number().required("Sac Tax Percentage is required").positive("Sac Tax Percentage must be a positive number"),
    audioBrandId: Yup.string().required("Audio brand is required"),
    audioPartNumber: Yup.string().required("Audio Part Number is required"),
    description: Yup.string().test('check the length', 'Maximum of 120 characters allowed', (value) => {
      if (value && String(value)?.length > 120) {
        return false
      }
      return true
    }),
  });

  const handleReset = (resetForm: any) => {
    if (id) {
      navigate(ROUTER.AUDIO_MODELS_GRID)
    } else {
      resetForm();
    }
  }

  const handleSubmit = (values: any) => {
    if (isSubmitting) return
    // const updatedValues = {
    //   ...values,
    //   audioBrandId: parseInt(values.audioBrandId)
    // };
    if (!id) {
      // values.audioBrandId = parseInt(values.audioBrandId);
      dispatch(createAudioModelPending(values));
    } else {
      // values.audioBrandId = parseInt(values.audioBrandId);
      dispatch(updateAudioModelPending(values, id))
    }
    setIsSubmitting(true)
  }



  useEffect(() => {
    if (id !== "" && id != undefined && id !== null) {
      dispatch(getByIdAudioModelPending(id))
    }
  }, [id])
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const hidePopup = () => {
    setVisible(false);
    dispatch(resetAudioModelState())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error?.message} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Audio Model" titlePath={ROUTER.AUDIO_MODELS_GRID} breadcrumbItem={id ? "Edit Audio Model" : "New Audio Model"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Model Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="number"
                                onBlur={handleBlur}
                                value={values.number}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["number"] && errors["number"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Service Charge"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="serviceCharge"
                                onBlur={handleBlur}
                                value={values.serviceCharge}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["serviceCharge"] && errors["serviceCharge"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Exchange Fixed Fee"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="exchangeFixedFee"
                                onBlur={handleBlur}
                                value={values.exchangeFixedFee}
                                onChange={handleChange}
                                // isRequired={!id ? true : false}
                                isRequired={true}
                                // error={error}
                                invalid={
                                  touched["exchangeFixedFee"] && errors["exchangeFixedFee"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Sac Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="sacNumber"
                                onBlur={handleBlur}
                                value={values.sacNumber}
                                onChange={handleChange}
                                // isRequired={id ? false : true}
                                isRequired={true}
                                invalid={
                                  touched["sacNumber"] &&
                                    errors["sacNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Sac Tax Percentage"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="taxpercentage"
                                onBlur={handleBlur}
                                value={values.taxpercentage}
                                onChange={handleChange}
                                isRequired={true}
                                // error={error}
                                invalid={
                                  touched["taxpercentage"] && errors["taxpercentage"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Audio Part Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="audioPartNumber"
                                onBlur={handleBlur}
                                value={values.audioPartNumber}
                                onChange={handleChange}
                                isRequired={true}
                                // error={error}
                                invalid={
                                  touched["audioPartNumber"] &&
                                    errors["audioPartNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={audioBrandDropDownData}
                                name="audioBrandId"
                                label="Audio Brand"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.audioBrandId}
                                invalid={
                                  touched["audioBrandId"] && errors["audioBrandId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Description"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="description"
                                onBlur={handleBlur}
                                value={values.description}
                                onChange={handleChange}
                                // error={error}
                                isRequired={false}
                                invalid={
                                  touched["description"] && errors["description"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                          </Row>
                          <div className="d-flex float-end mt-2 me-0">
                            <Button
                              className="secondary-btn me-2 mt-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary mt-2 btn-primary-shadow"
                              label={id ? "Update Audio Model" : "Create Audio Model"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditAudioModels