import { profileTableState } from "src/models/pages/profileModel";
import { ProfileTypes } from "./actionTypes";



const initialState: profileTableState = {
  error: "",
  message:null,
  loading: false,
  profile : null,
  profilePicture:"",
  phoneMessage:"",
  editedNumber:"",
  otpMessage:""
};

const profiles = (state = initialState, action: any) => {

  switch (action.type) {
    case ProfileTypes.GET_PROFILES:
    case ProfileTypes.SEND_OTP_PROFILE:
    case ProfileTypes.RESEND_PROFILE_OTP:
      return  {
        ...state,
        loading: true,
      };
      
    case ProfileTypes.GET_PROFILES_SUCCESS:
      return {
        ...state,
        profile: action.payload.data,
        loading: false,
        phoneMessage:""
      };
      
    case ProfileTypes.GET_PROFILES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ProfileTypes.UPDATE_PROFILE_DETAIL_SUCCESS:
      return{
        ...state,
        loading:false,
        message:"Profile Updated Successfully"
      }
    case ProfileTypes.UPDATE_PROFILE_DETAIL_FAIL:
    case ProfileTypes.UPDATE_PROFILE_PICTURE_FAIL:
    case ProfileTypes.DELETE_PROFILE_PICTURE_FAIL:
    case ProfileTypes.SEND_OTP_PROFILE_ERROR:
    case ProfileTypes.RESEND_PROFILE_OTP_ERROR:
      
      return{
        ...state,
        loading:false,
        error:action.payload,
        message:""
      }
    case ProfileTypes.GET_PROFILE_PICTURE_SUCCESS:
      
      return{
        ...state,
        loading:false,
        error:"",
        profilePicture:action.payload.data
      }
    case ProfileTypes.UPDATE_PROFILE_PICTURE_SUCCESS:
      
      return{
        ...state,
        loading:false,
        error:"",
        message:"Profile Picture Updated Successfully"
      }
    case ProfileTypes.DELETE_PROFILE_PICTURE_SUCCESS:
      
      return{
        ...state,
        loading:false,
        error:"",
        message:"Profile Picture deleted Successfully"
      }
    case ProfileTypes.SET_PROFILE_MOBILE_SUCCESS:
      
      return{
        ...state,
        loading:false,
        error:"",
        phoneMessage:"Otp sent Successfully",
        editedNumber:action.payload.number
      }
    case ProfileTypes.RESEND_PROFILE_OTP_SUCCESS:
      
      return{
        ...state,
        loading:false,
        error:"",
        phoneMessage:"Otp Sent Successfully",
        editedNumber:action.payload.number
      }
    case ProfileTypes.SEND_OTP_PROFILE_SUCCESS:
      
      return{
        ...state,
        loading:false,
        error:"",
        otpMessage:"Otp Verified Successfully"
      }
    case ProfileTypes.CLEAR_PROFILE_MESSAGE:
      return{
        ...state,
        error:false,
        message:"",
        editedNumber:"",
        phoneMessage:"",
        otpMessage:""
      }
    default:
      return { ...state };
      
  }
};


export default profiles;
