import { StockQuantityByMusicSystemBrandInitialStore } from "src/models/pages/stockQuantityByMusicSystemModel";
import { StockQuantityByMusicSystemBrandTypes } from "./actionTypes";


const initialValue: StockQuantityByMusicSystemBrandInitialStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  stockQuantityByMusicSystemBrandList: {
    values: [],
    totalRecords: 0
  },
  audioBrandDropdownList:""
}

const stockQuantityByMusicSystem = (state = initialValue, action: any) => {
  switch (action.type) {
    case StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING:
    case StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }

    case StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stockQuantityByMusicSystemBrandList: {
          ...state.stockQuantityByMusicSystemBrandList,
          // values: action.payload.items,
          // totalRecords: action.payload.data.totalCount
          values: action.payload.data,
          totalRecords: action.payload.data.length
        },
        paginatorCount : action.payload.data.length
      }

    case StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBrandDropdownList:action.payload.data
      }

    case StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_ERROR:
    case StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state };
  }
}


export default stockQuantityByMusicSystem;