import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getStockAdjustmentDashboardCountLoading } from "src/store/actions";
import 'bootstrap/dist/css/bootstrap.min.css'
import { RootReducerState } from "src/store/reducers";

interface stockAdjustmentChart {
  date: any;
  realDate: any;
}

const StockAdjustmentStatusChart = ({ date, realDate }: stockAdjustmentChart) => {

  const [month, year] = date.split(' ').map((value: any, index: any) => index === 0 ? new Date(`${value} 1, 2000`).getMonth() + 1 : parseInt(value));
  const dispatch = useDispatch();
  const [state, setState] = useState<any>("ALL");
  const [modifiedData, setModifiedData] = useState<any>([]);
  const [monthName, setMonthName] = useState<any>([])
  const { stockAdjustmentCount } = useSelector((state: RootReducerState) => state.dashboard);
  const onChangehandle = (data: any) => {
    setState(data);
    // dispatch(getWalletBalance(data));
  };

  const walletData = createSelector(

    (state: any) => state.dashboard,
    (state) => ({
      WallentBalanceData: state.WallentBalanceData,
    })
  );
  // Inside your component
  const { WallentBalanceData } = useSelector(walletData);

  useEffect(() => {
    if (month && year) {
      dispatch(getStockAdjustmentDashboardCountLoading(month, year))
    }
  }, [month, year])

  useEffect(() => {
    debugger
    if (stockAdjustmentCount.length > 0) {

      const setName = (name: string) => {
        switch (name) {
          case "noOfMissingItems":
            return "No Of Missing Items"
          case "noScrappedItems":
            return "No Of Scrapped Items"
          case "noRejectedItems":
            return "No Of Rejected Items"
          case "noUsedItems":
            return "No Of Used Items"
          case "noUnusedItems":
            return "No Of Unused Items"
          case "noFloatingItems":
            return "No Of Floating Items"
          default:
            return name
        }
      }
      const months = stockAdjustmentCount.reduce((acc, obj) => {
        const monthYear = `${obj.month}-${obj.year}`;
        if (!acc.includes(monthYear)) {
            acc.push(monthYear);
        }
        return acc;
    }, []);
    
    const sortedArr = months.sort((a:any, b:any) => {
        const [monthA, yearA] = a.split('-').map(Number);
        const [monthB, yearB] = b.split('-').map(Number);
        
        return yearA === yearB ? monthA - monthB : yearA - yearB;
    });
    
    // Step 2: Map Sorted Months to Month Names
    const monthNames = sortedArr.map((month:any) => {
        const mon = Number(month.split('-')[0]);
        
        switch (mon) {
            case 1: return "Jan";
            case 2: return "Feb";
            case 3: return "Mar";
            case 4: return "Apr";
            case 5: return "May";
            case 6: return "Jun";
            case 7: return "Jul";
            case 8: return "Aug";
            case 9: return "Sep";
            case 10: return "Oct";
            case 11: return "Nov";
            case 12: return "Dec";
            default: return "";
        }
    });
    setMonthName(monthNames);
    
    // Step 3: Process the Data
    const result = Object.keys(stockAdjustmentCount[0])
      .filter(key => key !== 'month' && key !== 'year')
      .map(name => {
        const counts = sortedArr.map((monthYear:any) => {
            const [month, year] = monthYear.split('-').map(Number);
            const dataEntry = stockAdjustmentCount.find(obj => obj.month === month && obj.year === year);
            return dataEntry ? dataEntry[name] : 0;
        });
    
        return { name: setName(name), data: counts };
      });
    
    // Step 4: Clean Up the Data
    const modifiedResult = JSON.parse(JSON.stringify(result)).map((item:any) => ({
      name: item.name,
      data: item.data.map((value:any) => (value == null ? "" : value))
    }));
    
    let data = modifiedResult.map((val:any) => ({
      name: val.name,
      data: val.data.filter((item:any) => item !== "")
    }));
    setModifiedData(data)
      setMonthName(monthNames)
    }else{
      setModifiedData([])
      setMonthName([])
    }

  }, [stockAdjustmentCount?.length > 0, stockAdjustmentCount])

  const maxNonNull = (arr : any) => Math.max(...arr.filter((val:any) => val !== null));



  

  //   const piechartColors =  ["#5885AF","#05445E","#AF5E58","#5EAF58","#5E58AF","#AF585E","#5858AF","#AF5E5E","#5EAF5E","#5E5EAF","#AF8E58","#AF58AF"];
  const piechartColors = ["#5885AF", "#05445E", "#41729F", "#274472", "#C3E0E5", "#5885AF", "#05445E", "#41729F", "#274472", "#C3E0E5", "#5885AF", "#05445E", "#41729F"];




  // const series: any[] = [
  //   {
  //     name: "Scrapped",
  //     data: [28, 29, 33, 36, 32, 32, 33],
  //     color: "#41729F"
  //   },
  //   {
  //     name: "Rejected",
  //     data: [12, 11, 14, 18, 17, 13, 13],
  //     color: "#05445E"
  //   },
  //   {
  //     name: "Missing",
  //     data: [2, 18, 20, 9, 11, 10, 5],
  //     color: "#5885AF"
  //   }

  // ]


  let day = new Date()
  const[ aggregateData,setAggregatedData]=useState<any>({
    noUsedItems: {},
    noUnusedItems: {},
    noScrappedItems: {},
    noRejectedItems: {},
    noFloatingItems: {}
  })
  
  // Aggregate data by month and year for each item type
  useEffect(() => {
    if (stockAdjustmentCount?.length > 0) {
      // Create a new object to avoid mutating the previous state directly
      const newAggregateData:any = {
        noUsedItems: {},
        noUnusedItems: {},
        noScrappedItems: {},
        noRejectedItems: {},
        noFloatingItems: {}
      };
  
      stockAdjustmentCount.forEach(item => {
        const key = `${item.year}-${item.month}`;
        newAggregateData.noUsedItems[key] = (newAggregateData.noUsedItems[key] || 0) + item.noUsedItems;
        newAggregateData.noUnusedItems[key] = (newAggregateData.noUnusedItems[key] || 0) + item.noUnusedItems;
        newAggregateData.noScrappedItems[key] = (newAggregateData.noScrappedItems[key] || 0) + item.noScrappedItems;
        newAggregateData.noRejectedItems[key] = (newAggregateData.noRejectedItems[key] || 0) + item.noRejectedItems;
        newAggregateData.noFloatingItems[key] = (newAggregateData.noFloatingItems[key] || 0) + item.noFloatingItems;
      });
  
      // // Update state with the new aggregate data
      // stockAdjustmentCount.forEach(item => {
      //   const key = `${item.year}-${item.month}`;
      //   newAggregateData.noUsedItems[key] = item.noUsedItems;
      //   newAggregateData.noUnusedItems[key] = item.noUnusedItems;
      //   newAggregateData.noScrappedItems[key] = item.noScrappedItems;
      //   newAggregateData.noRejectedItems[key] = item.noRejectedItems;
      //   newAggregateData.noFloatingItems[key] = item.noFloatingItems;
      // });
  
      // Update state with the new aggregate data
      setAggregatedData(newAggregateData);
    }
  }, [stockAdjustmentCount]);
  
  // Convert to array and sort by year and month
  const sortedKeys = Object.keys(aggregateData.noUsedItems).sort((a, b) => {
    const [yearA, monthA] = a.split('-').map(Number);
    const [yearB, monthB] = b.split('-').map(Number);
    if (yearA === yearB) {
      return monthA - monthB;
    }
    return yearA - yearB;
  });
  // const sortedKeys = Object.keys(aggregateData.noUsedItems)
  // .filter((key) => {
  //   const [year] = key.split('-').map(Number);
  //   return year !== 0;
  // })
  // .sort((a, b) => {
  //   const [yearA, monthA] = a.split('-').map(Number);
  //   const [yearB, monthB] = b.split('-').map(Number);
  //   if (yearA === yearB) {
  //     return monthA - monthB;
  //   }
  //   return yearA - yearB;
  // });
  
  const sortedData = sortedKeys.map(key => {
    const [year, month] = key.split('-').map(Number);
    return {
      month,
      year,
      noUsedItems: aggregateData.noUsedItems[key],
      noUnusedItems: aggregateData.noUnusedItems[key],
      noScrappedItems: aggregateData.noScrappedItems[key],
      noRejectedItems: aggregateData.noRejectedItems[key],
      noFloatingItems: aggregateData.noFloatingItems[key],
    };
  });
  const months = sortedData.map(d => `${d.year}-${d.month}`);
  const noUsedItems = sortedData.map(d => d.noUsedItems);
  const noUnusedItems = sortedData.map(d => d.noUnusedItems);
  const noScrappedItems = sortedData.map(d => d.noScrappedItems);
  const noRejectedItems = sortedData.map(d => d.noRejectedItems);
  const noFloatingItems = sortedData.map(d => d.noFloatingItems);


  const series = [
    {
      name: 'Used Items',
      data: noUsedItems,
    },
    {
      name: 'Unused Items',
      data: noUnusedItems,
    },
    {
      name: 'Scrapped Items',
      data: noScrappedItems,
    },
    {
      name: 'Rejected Items',
      data: noRejectedItems,
    },
    {
      name: 'Floating Items',
      data: noFloatingItems,
    },
  ];
  let monthNames=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"]

  const allValues:any = [
    ...Object.values(aggregateData.noUsedItems),
    ...Object.values(aggregateData.noUnusedItems),
    ...Object.values(aggregateData.noScrappedItems),
    ...Object.values(aggregateData.noRejectedItems),
    ...Object.values(aggregateData.noFloatingItems)
  ];
  
  const highestValue = Math.max(...allValues);
  
  

  const options: object = {
    chart: {
      height: 100,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    // colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: months?.map((val:any)=>monthNames[val.split('-')[1]-1]),
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"],
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Stock Adjustment'
      },
      min: 0,
      max: highestValue+10
    },
    legend: {
      position: 'top',
      show: false
      // horizontalAlign: 'right',
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
    }
  };


  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Stock Adjustment Status</h5>
              <div className="ms-auto">
                <div>
                  <b>{date}</b>
                  {
                    false && <>
                      <button
                        type="button"
                        className="btn btn-soft-primary btn-sm"
                        onClick={() => onChangehandle("ALL")}
                      >
                        ALL
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm"
                        onClick={() => onChangehandle("1M")}
                      >
                        1M
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm"
                        onClick={() => onChangehandle("6M")}
                      >
                        6M
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm"
                        onClick={() => onChangehandle("1Y")}
                      >
                        1Y
                      </button>
                    </>
                  }

                </div>
              </div>
            </div>

            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <Row className="align-items-center">
                    <Col lg={12}>
                      <div style={{ width: "100%", height: "370px" }}>
                        {stockAdjustmentCount.length > 0 ? (<ReactApexChart options={options} series={series} type="line" width={"100%"} height={"100%"} />) : (<div>No data found</div>)}
                        {/* <ReactApexChart options={options} series={modifiedData} type="line" width={"100%"} height={"100%"} /> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default StockAdjustmentStatusChart;
