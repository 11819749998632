import {  Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";

import DeleteModal from "../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import Dropzone from "react-dropzone";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { Option } from "src/models/components/inputWrapperModel";
import { formatDateTOYYYYMMDD, getLocationLatLng, onDropRejectedFileHandler, onlyAdminRoleHandler } from "src/helpers/common_helpers";
import { Dialog } from "primereact/dialog";
import { CreateMelUserLoading, GetMeluserDetailsLoading, ReserEmployeeDetails, UpdateMelUserLoading, clearUserData, deleteEmployeeDocumentLoading, deleteEmployeeImageLoading, employeeDocumentGetLoading, employeeDocumentUploadLoading, employeeLatLagLoading, getEmployeeImageLoading, resendOtpEmployee, resetOtpEmployeeMessage, sendEmployeeOtpLoading, setEmployeeEmailIdLoading, setEmployeeMobileNumberLoading, uploadEmployeeFileLoading } from "src/store/users/action";
import { EmployeePostModel } from "src/models/pages/userModel";
import moment from "moment";
import ImagePreviewer from "src/components/Common/ImagePreviewer/ImagePreviewer";
import { FILE_UPLOAD_SIZE, errorMessage } from "src/constants/commonConstants";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { getProfile } from "src/store/profile/actions";
import OtpScreen from "../Customers/OtpScreen";
import { APPENV_URL } from "src/util/appUrlEnv";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [apiKey, setApiKey] = useState<string | null>(null);
  const formValue: any = useSelector(
    (state: RootReducerState) => state.userReducer?.userDetails
  );
  const { error, message, loading, employeeImage, documentList, phoneMessage, emailMessage, otpMessage, editedMobileNumber, createdData, updatedmessage,employeeLatLng } = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const { profile } = useSelector((state: RootReducerState) => state.profilesReducer);

  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [AadharFile, setAadharFile] = useState<any>([]);
  const [customError, setcustomError] = useState(false);
  const [panCardFile, setPancardFile] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [contactDetailFalse, setContactDetailFalse] = useState(false);
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");
  const [visible, setVisible] = useState(false);


  const [dobError, setDobError] = useState("");
  const [weddingDateError, setWeddingDateError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const timeInputRef = useRef<any>(null);
  const timeInputRefOutTime = useRef<any>(null);
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);

  const handleClick = () => {
    if (timeInputRef.current) {
      timeInputRef.current.showPicker();
    }
  };
  const handleClickOutTime = () => {
    if (timeInputRefOutTime.current) {
      timeInputRefOutTime.current.showPicker();
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(getEmployeeImageLoading(id));
      dispatch(employeeDocumentGetLoading(id));
      dispatch(GetMeluserDetailsLoading(id));
    } else {

      getLocationLatLng(setLatitude, setLongitude);
    }
    return () => {
      setVisible(false);
      dispatch(clearUserData());
      dispatch(resetOtpEmployeeMessage());
      setLatitude("");
      setLongitude("");
      setAddress("");
    };
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, []);


  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      if (message != "Employee documented deleted successfully") {
        setTimeout(() => {
          setIsSubmitting(false);
          // navigate(ROUTER.USERS_GRID);
        }, 500);
        // dispatch(clearUserData())
      } else {
        // dispatch(getEmployeeImageLoading(id))
        // dispatch(employeeDocumentGetLoading(id))
      }
      setLatitude("");
      setLongitude("");
      setAddress("");
    }
  }, [error, message]);

  useEffect(() => {
    if (updatedmessage) {
      showToast(updatedmessage, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.USERS_GRID);
      }, 500);
      dispatch(clearUserData());

      setLatitude("");
      setLongitude("");
      setAddress("");
    }
  }, [updatedmessage]);

  useEffect(() => {
    if (emailMessage != "" && emailMessage != undefined) {
      showToast(emailMessage, { type: "success" });
      dispatch(GetMeluserDetailsLoading(id));
      setEmailEdit(false);
    }
  }, [emailMessage]);

  useEffect(() => {
    return () => {
      setVisible(false);
      dispatch(clearUserData());
    };
  }, []);


  const hidePopup = () => {
    setVisible(false);
    dispatch(ReserEmployeeDetails());
  };


  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(ReserEmployeeDetails()) }}>
          <div className="m-0 validation-text">
            {error && error && error && error && error && error && error && error && error.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>)
            })}
          </div>
        </Dialog> */}
      </>
    );
  };

  const post = (formValue: EmployeePostModel) => {
    dispatch(CreateMelUserLoading(formValue));
  };


  const update = (formValue: EmployeePostModel) => {
    dispatch(UpdateMelUserLoading(formValue, id as string));
    if (selectedFiles.length > 0) {
      dispatch(uploadEmployeeFileLoading(selectedFiles[0], id));
    }
    if (AadharFile?.length > 0) {
      dispatch(employeeDocumentUploadLoading(AadharFile[0], id, "Aadhar"));
    }
    if (panCardFile?.length > 0) {
      dispatch(employeeDocumentUploadLoading(panCardFile[0], id, "pancard"));
    }
  };

  const initialvalue = {
    id: (formValue?.['user']?.['id'] || ""),
    name: (formValue?.['user']?.['name'] || ""),
    email: (formValue?.['user']?.["email"] || ""),
    phoneNumber: (formValue?.['user']?.["phoneNumber"] || ""),
    gstNumber: (formValue?.['user']?.['extraProperties']?.["GstNumber"] || ""),
    personalEmail: (formValue?.['personalEmail'] || ""),
    alternateContactMobileNo: (formValue?.['alternateContactMobileNo'] || ""),
    motherName: (formValue?.['motherName'] || ""),
    aadharCardNo: (formValue?.['aadharCardNo'] || ""),
    panCardNo: (formValue?.['user']?.['extraProperties']?.['PANCardNo'] || ""),
    esiNo: (formValue?.['esiNo'] || ""),
    pfNo: (formValue?.['pfNo'] || ""),
    userName: (formValue?.['user']?.["userName"] || ""),
    surname: (formValue?.['user']?.["surname"] || ""),
    homeAddress: (formValue?.['user']?.['extraProperties']?.["HomeAddress"] || ""),
    password: "",
    confirmpassword: "",
    educationalQualification: (formValue?.["educationalQualification"] || ""),
    fatherName: (formValue?.["fatherName"] || ""),
    bloodGroup: (formValue?.["bloodGroup"] || ""),
    residenceContactNumber: (formValue?.["residenceContactNumber"] || ""),
    address: (formValue?.["user"]?.['extraProperties']?.["Address"] || ""),
    joiningDate: (formValue?.joiningDate ? formatDateTOYYYYMMDD(formValue?.joiningDate) : ""),
    dateOfBirth: (formValue?.["user"]?.['extraProperties']?.['DateOfBirth'] ? formatDateTOYYYYMMDD(formValue?.["user"]?.['extraProperties']?.['DateOfBirth']) : ""),
    weddingDate: (formValue?.["user"]?.['extraProperties']?.['WeddingDate'] ? formatDateTOYYYYMMDD(formValue?.["user"]?.['extraProperties']?.['weddingDate']) : ""),
    sendSms: (formValue?.["user"]?.['extraProperties']?.["SendSms"] || false),
    sendEmail: (formValue?.["user"]?.['extraProperties']?.["SendEmail"] || false),
    sendWhatsapp: (formValue?.["user"]?.['extraProperties']?.["SendWhatsapp"] || false),
    lockoutEnabled: (formValue?.["user"]?.['lockoutEnabled'] || false),
    emailConfirmed: (formValue?.["user"]?.['emailConfirmed'] || false),
    isActive: (formValue?.["user"]?.["isActive"] || false),
    isAdmin: (formValue?.["user"]?.['extraProperties']?.["IsAdmin"] || false),
    isVerfied: (formValue?.["user"]?.["extraProperties"]?.["IsVerfied"] || false),
    da: (formValue?.["da"] || ""),
    hra: (formValue?.["hra"] || ""),
    specialAllowance1: (formValue?.["specialAllowance1"] || ""),
    specialAllowance2: (formValue?.["specialAllowance2"] || ""),
    employeeNo: (formValue?.["employeeNo"] || ""),
    basic: (formValue?.["basic"] || ""),
    inTime: (formValue?.['inTime'] || ""),
    outTime: (formValue?.['outTime'] || ""),
  };
  useEffect(() => {
    if (formValue?.["user"]?.['extraProperties']?.["Address"]) {
      // setAddress(formValue?.["user"]?.['extraProperties']?.["Address"]);
      dispatch(employeeLatLagLoading(formValue?.["user"]?.['extraProperties']?.["Address"]))
    }
  }, [formValue]);
  const paymentTypes = [
    { label: "Cash", value: "Cash" },
    { label: "Card", value: "Card" },
    { label: "Credit", value: "Credit" },
  ];

  const taxTypes: Option[] = [
    { value: 1, label: "IGST" },
    { value: 2, label: "SGST+CGST" },
    { value: 3, label: "UTGST" },
  ];

  const brandOptions = [
    { label: "Ford", value: "Ford" },
    { label: "Generic", value: "Generic" },
    { label: "Honda", value: "Honda" },
    { label: "Hyundai", value: "Hyundai" },
    { label: "Mahindra", value: "Mahindra" },
    { label: "Maruthi", value: "Maruthi" },
    { label: "Nissan", value: "Nissan" },
    { label: "Scoda", value: "Scoda" },
    { label: "Tata", value: "Tata" },
    { label: "Toyato", value: "Toyato" },
    { label: "Volkswagen", value: "Volkswagen" },
  ];

  const formatTime = (time: string): string => {
    if (time && typeof time === 'string') {
      const timeParts = time.split(':');
      if (timeParts.length === 2) {
        // Append ":00" to the time string (for seconds) if it's missing
        return `${time}:00`;
      } else if (timeParts.length === 3) {
        // Ensure time is in "HH:mm:ss" format
        const [hours, minutes, seconds] = timeParts;
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
      }
    }
    return time;
  };

  const handleSubmit = async (values: any) => {
    debugger;
    if (isSubmitting) return;
    const submittedData: EmployeePostModel = {
      "educationalQualification": values.educationalQualification,
      "fatherName": values.fatherName,
      "joiningDate": values.joiningDate,
      "bloodGroup": values.bloodGroup,
      "residenceContactNumber": values.residenceContactNumber.toString(),
      "personalEmail": values.personalEmail,
      "alternateContactMobileNo": values.alternateContactMobileNo.toString(),
      "motherName": values.motherName,
      "esiNo": values.esiNo.toString(),
      "pfNo": values.pfNo,
      "aadharCardNo": values.aadharCardNo.toString(),
      "basic": values.basic ? values.basic : null,
      "da": values.da ? values.da : null,
      "hra": values.hra ? values.hra : null,
      "specialAllowance1": values.specialAllowance1 ? values.specialAllowance1 : null,
      "specialAllowance2": values.specialAllowance2 ? values.specialAllowance2 : null,
      "employeeNo": values.employeeNo ? values.employeeNo.toString() : null,
      "inTime": formatTime(values.inTime),
      "outTime": formatTime(values.outTime),
      "user": {
        "id": values.id,
        "userName": values.userName,
        "surname": values.surname,
        "name": values.name,
        "email": values.email,
        "phoneNumber": id ? null : values.phoneNumber.toString(),
        "gstNumber": values.gstNumber,
        // "address": address,
        "address": values.address,
        "dateOfBirth": values.dateOfBirth,
        "sendSms": values.sendSms,
        "sendEmail": values.sendEmail,
        "sendWhatsapp": values.sendWhatsapp,
        "weddingDate": values.weddingDate,
        "panCardNo": values.panCardNo,
        "lockoutEnabled": values.lockoutEnabled,
        "homeAddress": values.homeAddress,
        "isVerfied": values.isVerfied,
        "emailConfirmed": values.emailConfirmed,
        "isActive": values.isActive,
        "isAdmin": values.isAdmin,
      }
    };
    if (id) {
      update(submittedData);
    } else {
      delete submittedData.user.id;
      post(submittedData);
    }
    setIsSubmitting(true);
  };

  const isValidYear = (value: any) => {
    const currentYear = new Date().getFullYear();
    const yearRegex = /^(\d{2}-\d{2}-\d{4})$/;
    if (!yearRegex.test(value)) {
      return false;
    }
    const enteredYear = parseInt(value.split('-')[2], 10);
    const minYear = 1900;
    const maxYear = currentYear + 10;

    return enteredYear >= minYear && enteredYear <= maxYear;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
    .required('Email is required')
    .email('Enter a valid email')
    .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true;
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
      
    personalEmail: Yup.string()
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true;
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    address: Yup.string().required(`Address is required`),
    aadharCardNo: Yup.string().matches(/^\d{12}$/, 'Aadhar Number must be valid'),
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'PAN number must be valid'),
    esiNo: Yup.string().matches(/^\d{17}$/, 'ESI number must be valid'),
    pfNo: Yup.string().matches(/^[A-Z]{2}[\\s\\/]?[A-Z]{3}[\\s\\/]?[0-9]{7}[\\s\\/]?[0-9]{3}[\\s\\/]?[0-9]{7}$/, 'PF number must be valid'),
    // homeAddress: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    // alternateContactMobileNo: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits').notOneOf(
    //   [Yup.ref('phoneNumber')],
    //   'Alternate contact number cannot be the same as phone number'
    // ),
    inTime:Yup.string().required("In Time is required"),
    outTime:Yup.string().required("Out Time is required"),
    educationalQualification: Yup.string().required(`Educational Qualification is required`),
    // password: Yup.string().required(`Password is required`),
    // confirmpassword: Yup.string().required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match'),
    fatherName: Yup.string().required(`Father name is required`),
    bloodGroup: Yup.string().required(`Blood group is required`),
    residenceContactNumber: Yup.string().required(`Residence contact Number is required`),
    // .matches(/^\d{10}$/, 'Residence contact number must be 10 digits'),
    employeeNo: Yup.string().max(30, 'Employee number must be lower than 30 characters'),  
    joiningDate: Yup.date()
      .required('Joining date is required')
      .min(new Date('1000-01-01'), 'Invalid date')
      .max(new Date('9999-12-31'), 'Invalid date'),
    dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
      const currentDate = moment();
      const { dateOfBirth } = this.parent;

      if (dateOfBirth) {
        if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
          return false;
        }

      }


      setDobError("");
      return true;
    }),
    weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
      const { dateOfBirth } = this.parent;
      const dobDate = moment(dateOfBirth);
      if (dateOfBirth) {
        if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
          // setWeddingDateError("Dob and Wedding date must not be same");
          return false;
        }
        // else if (value && moment(value).isBefore(dateOfBirth)) {
        //   // setWeddingDateError("Wedding date cannot be before date of birth");
        //   return false;
        // }
      }
      setWeddingDateError("");
      return true;
    }),


  });

  const validationSchemaForEdit = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    personalEmail: Yup.string()
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    address: Yup.string().required(`Address is required`),
    // alternateContactMobileNo: Yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits').notOneOf(
    //   [Yup.ref('phoneNumber')],
    //   'Alternate contact number cannot be the same as phone number'
    // ),
    employeeNo: Yup.string().max(30, 'Employee number must be lower than 30 characters'),
    aadharCardNo: Yup.string().matches(/^\d{12}$/, 'Aadhar Number must be valid'),
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'PAN number must be valid'),
    esiNo: Yup.string().matches(/^\d{17}$/, 'ESI number must be valid'),
    pfNo: Yup.string().matches(/^[A-Z]{2}[\\s\\/]?[A-Z]{3}[\\s\\/]?[0-9]{7}[\\s\\/]?[0-9]{3}[\\s\\/]?[0-9]{7}$/, 'PF number must be valid'),
    inTime: Yup.string().required("In Time is required"),
    outTime: Yup.string().required("Out Time is required"),
    educationalQualification: Yup.string().required(`Educational Qualification is required`),
    // password: Yup.string(),
    // confirmpassword: Yup.string().test('match-password', 'Password must match', function (value) {
    //   const { password } = this.parent;
    //   if (password && password.length > 0) {
    //     return value === password;
    //   }
    //   return true;
    // }),
    // confirmpassword:Yup.string().required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match'),

    fatherName: Yup.string().required(`Father name is required`),
    bloodGroup: Yup.string().required(`Blood group is required`),
    residenceContactNumber: Yup.string().required(`Residence contact Number is required`),
    // .matches(/^\d{10}$/, 'Residence contact number must be 10 digits'),
    // address: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)').required("Address is required"),
    // homeAddress: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),
    joiningDate: Yup.date()
      .required('Joining date is required')
      .min(new Date('1000-01-01'), 'Invalid date')
      .max(new Date('9999-12-31'), 'Invalid date'),
    dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
      const currentDate = moment();
      const { dateOfBirth } = this.parent;

      if (dateOfBirth) {
        if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
          // setDobError("Date of birth must not be today or future date");
          return false;
        }
        //  else if (value && moment(value).isAfter(currentDate)) {
        //   // setDobError("Date of birth cannot be a future date");
        //   return false;
        // }
      }

      // if (dateOfBirth) {
      //   if (value && moment(value).isSame(currentDate, 'day')) {
      //     setDobError("Date of birth must not be today");
      //     return false;
      //   } else if (value && moment(value).isAfter(currentDate)) {
      //     setDobError("Date of birth cannot be a future date");
      //     return false;
      //   }
      // }
      setDobError("");
      return true;
    }),
    weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
      const { dateOfBirth } = this.parent;
      const dobDate = moment(dateOfBirth);

      if (dateOfBirth) {
        if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
          // setWeddingDateError("Dob and Wedding date must not be same");
          return false;
        }
        // else if (value && moment(value).isBefore(dateOfBirth)) {
        //   // setWeddingDateError("Wedding date cannot be before date of birth");
        //   return false;
        // }
      }

      // if (dateOfBirth) {
      //   if (value && moment(value).isSame(dateOfBirth, 'day')) {
      //     setWeddingDateError("Dob and Wedding date must not be same");
      //     return true;
      //   } else if (value && moment(value).isBefore(dateOfBirth)) {
      //     setWeddingDateError("Wedding date cannot be before date of birth");
      //     return false;
      //   }
      // }
      setWeddingDateError("");
      return true;
    }),
  });

  function handleAcceptedFiles(files: any) {
    let filesModified = files.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );
    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeIndex = () => {
    setDeleteModal(false);
    if (employeeImage) {
      dispatch(deleteEmployeeImageLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.name] = '';
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };

  const handleAddContactDetails = (values: any) => {
    if (isContactDetailFilled(values)) {
      values.contactDetails.push({
        name: "",
        designation: "",
        mobileNumber: "",
        alternativeNumber: "",
        email: "",
        sendSms: false,
        sendEmail: false,
        isDelete: false,
      });
      setContactDetailFalse(false);
    } else {
      setContactDetailFalse(true);
    }
  };
  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.contactDetails || [];
    return contactDetails.every((contact: any) => {
      // Check for values in all fields except 'alternativeNumber'
      const { alternativeNumber, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };

  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.USERS_GRID);
    }
  };

  const removeAadharFile = (document?: any) => {
    if (document?.id) {
      dispatch(deleteEmployeeDocumentLoading(document?.id));
    } else {
      setAadharFile([]);
    }
  };

  const removepanFile = (document?: any) => {
    if (document?.id) {
      dispatch(deleteEmployeeDocumentLoading(document?.id));
    } else {
      setPancardFile([]);
    }
  };

  const assigntheValue = (uploadFile: any) => {
    debugger;
    const filesArray = Array.from(uploadFile);
    // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"];
    if (uploadFile && allowedTypes.includes(uploadFile[0].type)) {
      setAadharFile(filesArray);
    } else {
      showToast(`Please Upload Pdf or Image File`, { type: "error" });
      setAadharFile([]);
    }
    // let modifiedData = filesArray?.map((file: any) => {
    //   console.log("Files --> ", file);
    //   return Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: formatBytes(file.size),
    //     isImage: file.type.startsWith('image/'),
    //     FileName: "Aadhar",
    //   })
    // })

  };
  const assignPanValue = (uploadFile: any) => {
    // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"];
    const filesArray = Array.from(uploadFile);
    if (uploadFile && allowedTypes.includes(uploadFile[0].type)) {
      setPancardFile(filesArray);
    } else {
      showToast(`Please Upload  Pdf or Image File`, { type: "error" });
      setPancardFile([]);
    }
  };

  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };

  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };

  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  useEffect(() => {
    if (otpMessage != "" && otpMessage != undefined) {
      setOpenOtp(false);
      showToast(otpMessage, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.USERS_GRID);
      }, 500);
    }
  }, [otpMessage]);
  const [openOtp, setOpenOtp] = useState<any>(false);
  useEffect(() => {
    if (
      phoneMessage != "" &&
      phoneMessage != undefined
    ) {
      showToast(phoneMessage, { type: "success" });
      // dispatch(getCustomerByIdLoading(id));
      setPhoneEdit(false);
      setOpenOtp(true);
    }
  }, [phoneMessage]);
  useEffect(() => {
    debugger;
    if (createdData) {

      dispatch(setEmployeeMobileNumberLoading(createdData?.user?.phoneNumber, createdData?.user?.id));

    }
  }, [createdData]);
  const [otpValue, setOtpValue] = useState<any>(null);
  const handleOtpSubmit = () => {
    if (id) {

      dispatch(sendEmployeeOtpLoading(otpValue, formValue?.user?.id, editedMobileNumber));
    } else {
      dispatch(sendEmployeeOtpLoading(otpValue, createdData?.user?.id, createdData?.user?.phoneNumber));
    }
    // setOpenOtp(false)
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpEmployeeMessage());
    if (id) {
      dispatch(GetMeluserDetailsLoading(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };
  const handleResendOtp = (number: any) => {
    dispatch(resendOtpEmployee(formValue?.user?.id, number));
  };


  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {

    setPhoneEdit(false);
    dispatch(setEmployeeMobileNumberLoading(values.phoneNumber, formValue?.user?.id));


  };
  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {
    setEmailEdit(false);
    dispatch(setEmployeeEmailIdLoading(values.email, formValue?.user?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };
  const handleAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(employeeLatLagLoading(event.target.value))
      }
    }
  }
  return (
    <div id="view-dealer">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeIndex();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      {visible && dialogContainer(error)}
      {customError && dialogContainerCustomError(errorMessage)}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Employees"
            titlePath={ROUTER.USERS_GRID}
            breadcrumbItem={id ? "Edit Employee" : "Create Employee"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? validationSchemaForEdit : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {



                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName={id ? "New Password" : "Password"}
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["password"] &&
                                    errors["password"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName={id ? "New Confirm Password" : "Confirm Password"}
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="confirmpassword"
                                onBlur={handleBlur}
                                value={values.confirmpassword}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["confirmpassword"] &&
                                    errors["confirmpassword"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Official Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e)=>{
                                  let event={...e}
                                  event.target.value=e.target.value.toLocaleLowerCase()
                                  handleChange(event)
                                }}
                                isRequired={true}
                                invalid={
                                  touched["email"] && errors["email"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Official Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Personal Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="personalEmail"
                                onBlur={handleBlur}
                                value={values.personalEmail}
                                onChange={(e) => {
                                  let event = { ...e };
                                  event.target.value = e.target.value.toLocaleLowerCase();
                                  handleChange(event);
                                }}
                                isRequired={false}
                                invalid={
                                  touched["personalEmail"] && errors["personalEmail"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {/* <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Phone"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="phoneNumber"
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={true}
                                invalid={
                                  touched["phoneNumber"] &&
                                    errors["phoneNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["phoneNumber"] &&
                                      errors["phoneNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (<FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                  onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                />
                                )}

                              </div>
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="alternateContactMobileNo"
                                onBlur={handleBlur}
                                value={values.alternateContactMobileNo}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={false}
                                invalid={
                                  touched["alternateContactMobileNo"] &&
                                    errors["alternateContactMobileNo"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>


                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Father/Husband Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="fatherName"
                                onBlur={handleBlur}
                                value={values.fatherName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["fatherName"] &&
                                    errors["fatherName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Mother Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="motherName"
                                onBlur={handleBlur}
                                value={values.motherName}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["motherName"] &&
                                    errors["motherName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Educational Qualification"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="educationalQualification"
                                onBlur={handleBlur}
                                value={values.educationalQualification}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["educationalQualification"] &&
                                    errors["educationalQualification"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Blood Group"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="bloodGroup"
                                onBlur={handleBlur}
                                value={values.bloodGroup}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["bloodGroup"] &&
                                    errors["bloodGroup"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Aadhar Card No"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="aadharCardNo"
                                uploadIcon={documentList?.filter((data) => data.fileType === "Aadhar")?.length > 0 ? false : true}
                                onBlur={handleBlur}
                                value={values.aadharCardNo}
                                onChange={handleChange}
                                fileName="upload"
                                fileValue={values.fileValue}
                                filehandleChange={(e: any) => {
                                  handleChange(e);
                                  assigntheValue(e.target.files);
                                }}
                                onKeyDown={preventSpecialChar}
                                isRequired={false}
                                invalid={
                                  touched["aadharCardNo"] &&
                                    errors["aadharCardNo"]
                                    ? true
                                    : false
                                }
                              />
                              {AadharFile?.length > 0 && <div className="Aadhar_details_preview" id="Aadhar_details_preview">
                                <span className="file_name">{AadharFile[0]?.name}</span>
                                <span className="trash_icon">
                                  <FeatherIcon onClick={removeAadharFile} icon="trash-2" className={`icon-sm right-icon pointer`} />
                                </span>
                              </div>}
                              {documentList?.length > 0 && (
                                <div>
                                  {documentList
                                    ?.filter((data) => data.fileType === "Aadhar")
                                    .map((data) => (
                                      <div className="Aadhar_details_preview" id="Aadhar_details_preview" key={data.id}>
                                        <span className="file_name">{data?.name}</span>
                                        <span className="trash_icon">
                                          <FeatherIcon
                                            onClick={() => removeAadharFile(data)}
                                            icon="trash-2"
                                            className={`icon-sm right-icon pointer`}
                                          />
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              )}

                              <div>
                              </div>
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="PAN Card No"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="panCardNo"
                                onBlur={handleBlur}
                                panIcon={documentList?.filter((data) => data.fileType === "pancard")?.length > 0 ? false : true}
                                value={values.panCardNo}
                                panValue={values.panValue}
                                onChange={handleChange}
                                panName="panupload"
                                filehandleChange={(e: any) => {
                                  handleChange(e);
                                  assignPanValue(e.target.files);
                                }}
                                onKeyDown={preventSpecialChar}
                                isRequired={false}
                                invalid={
                                  touched["panCardNo"] &&
                                    errors["panCardNo"]
                                    ? true
                                    : false
                                }
                              />
                              {panCardFile?.length > 0 && <div className="Aadhar_details_preview" id="Aadhar_details_preview">
                                <span className="file_name">{panCardFile[0]?.name}</span>
                                <span className="trash_icon">
                                  <FeatherIcon onClick={removepanFile} icon="trash-2" className={`icon-sm right-icon pointer`} />
                                </span>
                              </div>}
                              {documentList?.length > 0 && (
                                <div>
                                  {documentList
                                    ?.filter((data) => data.fileType === "pancard")
                                    .map((data) => (
                                      <div className="Aadhar_details_preview" id="Aadhar_details_preview" key={data.id}>
                                        <span className="file_name">{data?.name}</span>
                                        <span className="trash_icon">
                                          <FeatherIcon
                                            onClick={() => removepanFile(data)}
                                            icon="trash-2"
                                            className={`icon-sm right-icon pointer`}
                                          />
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="ESI No"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="esiNo"
                                onBlur={handleBlur}
                                value={values.esiNo}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={false}
                                invalid={
                                  touched["esiNo"] &&
                                    errors["esiNo"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="PF No"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="pfNo"
                                onBlur={handleBlur}
                                value={values.pfNo}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={false}
                                invalid={
                                  touched["pfNo"] &&
                                    errors["pfNo"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Residence Contact Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="residenceContactNumber"
                                onBlur={handleBlur}
                                value={values.residenceContactNumber}
                                onChange={handleChange}
                                onKeyDown={preventSpecialChar}
                                isRequired={true}
                                invalid={
                                  touched["residenceContactNumber"] &&
                                    errors["residenceContactNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Joining Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="joiningDate"
                                onBlur={handleBlur}
                                value={values.joiningDate}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["joiningDate"] &&
                                    errors["joiningDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Date of Birth"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="dateOfBirth"
                                onBlur={handleBlur}
                                value={values.dateOfBirth}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["dateOfBirth"] &&
                                    errors["dateOfBirth"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={12} className="mb-2">
                              <InputCom
                                labelName="Wedding Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="weddingDate"
                                onBlur={handleBlur}
                                value={values.weddingDate}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["weddingDate"] &&
                                    errors["weddingDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>


                            <Col xl={3} lg={12} className="mb-2">
                              <InputCom
                                labelName="ShiftIn Time"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="time"
                                name="inTime"
                                onBlur={handleBlur}
                                value={values.inTime}
                                onChange={handleChange}
                                innerRef={timeInputRef}
                                onClick={handleClick}
                                isRequired={true}
                                invalid={
                                  touched["inTime"] &&
                                    errors["inTime"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={12} className="mb-2">
                              <InputCom
                                labelName="ShiftOut Time"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="time"
                                name="outTime"
                                onBlur={handleBlur}
                                value={values.outTime}
                                onChange={handleChange}
                                innerRef={timeInputRefOutTime}
                                onClick={handleClickOutTime}
                                isRequired={true}
                                invalid={
                                  touched["outTime"] &&
                                    errors["outTime"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>


                            <Col xl={6} lg={12} className="mb-2">
                              <InputCom
                                labelName="Home Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="address"
                                // onBlur={(e:any)=>{handleBlur(e) ; if(values.address){ dispatch(employeeLatLagLoading(values.address)) }}}
                                onBlur={handleBlur}
                                onKeyDown={(e)=>{handleAddress(e)}}

                                value={values.address}
                                onChange={handleChange}
                                rowHeight={4}
                                isRequired={true}
                                invalid={
                                  touched["address"] && errors["address"]
                                    ? true
                                    : false
                                }
                              />
                              <p style={{ fontSize: "12px",marginBottom:"3px"}}>Please press the Enter key after entering the Address.</p>
                              {employeeLatLng && 
                              <iframe src={`https://maps.google.com/maps?q=${employeeLatLng.lat},${employeeLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                              {/* <AddressMapFields address={address} setAddress={setAddress} lat={latitude} lng={longitude} title="Home Address" ></AddressMapFields> */}
                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={formValue?.["user"]?.['extraProperties']?.["Address"] ? formValue?.["user"]?.['extraProperties']?.["Address"] : ""} title="Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}

                            </Col>
                            <Col xl={6} lg={12} className="mb-2">
                              <InputCom
                                labelName="Alternate Home Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="homeAddress"
                                onBlur={handleBlur}
                                value={values.homeAddress}
                                onChange={handleChange}
                                rowHeight={4}
                                isRequired={false}
                                invalid={
                                  touched["homeAddress"] && errors["homeAddress"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="d-flex gap-5" >
                              <CheckboxInput
                                name="sendSms"
                                label="Send SMS"
                                value={values["sendSms"]}
                                onChange={handleChange}
                                {...rest}
                              />

                              <CheckboxInput
                                name="sendEmail"
                                label="Send Email"
                                value={values["sendEmail"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              <CheckboxInput
                                name="sendWhatsapp"
                                label="Whatsapp"
                                value={values["sendWhatsapp"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="d-flex gap-5" >
                              {/* <CheckboxInput
                                  name="lockoutEnabled"
                                  label="Lockout Enabled"
                                  value={values["lockoutEnabled"]}
                                  onChange={handleChange}
                                  {...rest}
                                /> */}

                              <CheckboxInput
                                name="isActive"
                                label="Is Active"
                                value={values["isActive"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              {/* </Col>
                            <Col xl={3} lg={4} className="custom_gap mb-2" > */}
                              <CheckboxInput
                                name="isVerfied"
                                label="Is Verified"
                                value={values["isVerfied"]}
                                onChange={handleChange}
                                {...rest}
                              />


                              {(profile?.extraProperties?.IsAdmin == true || onlyAdminRoleHandler()) &&

                                <CheckboxInput
                                  name="isAdmin"
                                  label="Is Admin"
                                  value={values["isAdmin"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              }
                            </Col>
                            <React.Fragment>
                              <div className="mt-5">
                                <h5>Payslip Info</h5>
                                <hr />
                                <Row>
                                  <Col xl={3} lg={12} className="mb-2">
                                    <InputCom
                                      labelName="Basic Pay"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="number"
                                      name="basic"
                                      onBlur={handleBlur}
                                      value={values.basic}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["basic"] &&
                                          errors["basic"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  <Col xl={3} lg={12} className="mb-2">
                                    <InputCom
                                      labelName="DA"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="number"
                                      name="da"
                                      onBlur={handleBlur}
                                      value={values.da}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["da"] &&
                                          errors["da"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  <Col xl={3} lg={12} className="mb-2">
                                    <InputCom
                                      labelName="HRA"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="number"
                                      name="hra"
                                      onBlur={handleBlur}
                                      value={values.hra}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["hra"] &&
                                          errors["hra"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  <Col xl={3} lg={12} className="mb-2">
                                    <InputCom
                                      labelName="Special Allowance 1"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="number"
                                      name="specialAllowance1"
                                      onBlur={handleBlur}
                                      value={values.specialAllowance1}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["specialAllowance1"] &&
                                          errors["specialAllowance1"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  <Col xl={3} lg={12} className="mb-2">
                                    <InputCom
                                      labelName="Special Allowance 2"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="number"
                                      name="specialAllowance2"
                                      onBlur={handleBlur}
                                      value={values.specialAllowance2}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["specialAllowance2"] &&
                                          errors["specialAllowance2"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>

                                  <Col xl={3} lg={12} className="mb-2">
                                    <InputCom
                                      labelName="Employee Number"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="text"
                                      name="employeeNo"
                                      onBlur={handleBlur}
                                      value={values.employeeNo}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["employeeNo"] &&
                                          errors["employeeNo"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>


                          </Row>
                          {
                            id && <React.Fragment>
                              <div className="mt-1">
                                <h5>Image</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {(selectedFiles.length == 0 && !employeeImage) && <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onError={(err: Error) => { console.log("file error", err); }}
                                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                        maxFiles={1}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} accept="image/*" />
                                              <div className="">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>}

                                      {(selectedFiles[0]?.preview || employeeImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : employeeImage} removeIndex={deleteConfirmationHandler} />}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>
                          }

                          <div className="d-flex justify-content-end mt-2">
                            {(formValue?.user?.phoneNumberConfirmed != true && id) &&
                              <Button
                                className="next-status-btn me-2 mt-2"
                                label={"Verify Mobile No"}
                                type="button"
                                onClick={() => handleResendOtp(values.phoneNumber)}
                              />
                            }
                            {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'> */}
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            {/* </TooltipMEL> */}
                            {/* <TooltipMEL title={id ? "Update Employee" : "Create Employee"} position='top'> */}
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Employee" : "Create Employee"}
                              type="submit"
                            />
                            {/* </TooltipMEL> */}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div >
  );
};

export default EditUser;
