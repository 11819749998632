import React, { useEffect, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

import { Link } from "react-router-dom";

// Redux Store
import { toggleLeftmenu, showRightSidebarAction } from "../../store/actions";
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import logo from "../../assets/images/logo-sm.svg";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
// import logoImage from "../../assets/images/logo/mount-logo-blue.png";
import logoImage from "../../assets/images/logo/mount-logo.png";
import logoMiniImage from "../../assets/images/logo/mount-min-logo-blue.png";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import CheckIn from "../Common/CheckIn/CheckIn";
import { attendanceApplicableUsers } from "src/models/pages/attendanceModel";

const Header = (props: any) => {
  const dispatch = useDispatch();

  const headerData = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      leftMenu: layout.leftMenu,
      layoutMode: layout.layoutMode,
      showRightSidebar: layout.showRightSide
    })
  );
  // Inside your component
  const { leftMenu, layoutMode, showRightSidebar } = useSelector(headerData);

  const [isSearch, setSearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  useEffect(() => {
    const getAuthUser = localStorage.getItem("role");
    if (getAuthUser) {
      const obj = JSON.parse(getAuthUser);
      setRole(obj);
    }
  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box navbar-brand">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  {/* <img src={logoMiniImage} alt="" height="45" /> */}

                  <img src={logoMiniImage} alt="" className="logo-mini-width" />

                </span>
                <span className="logo-lg">
                  {/* <b>Hello World</b> */}
                  <img src={logoImage} alt="" className="logo-width" />{" "}
                  {/* <span className="logo-txt ms-2">Minia</span> */}
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={logoMiniImage} alt="" height="45" /\
                    */}
                  <img src="../../assets/images/logo/mount-min-logo-blue.png" alt="" height="45" />
                </span>
                <span className="logo-lg">
                  <img src={logoImage} alt="" height="45" />
                  {/* <span className="logo-txt ms-2">Minia</span> */}
                </span>
              </Link>
            </div>
            
            <button
              type="button"
              className="btn btn-sm d-lg-none font-size-16 header-item"
              data-toggle="collapse"
              onClick={() => {
                dispatch(toggleLeftmenu(!leftMenu));
              }}
              data-target="#topnav-menu-content" style={{padding: "0px", height:"100%", marginLeft: "15px"}}
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {false && <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <button className="btn btn-primary" type="button">
                  <i className="bx bx-search-alt align-middle"></i>
                </button>
              </div>
            </form>}
          </div>

          <div className="d-flex align-items-center">
            {false && <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>}


            {false && <LanguageDropdown />}

            {/* light / dark mode */}
            {false && <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />}

            {false && <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <FeatherIcon icon="grid" className="icon-lg" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>}
            {attendanceApplicableUsers.map((eachTitle: any) => {
              return role.includes(eachTitle.value) ?
                <CheckIn key={eachTitle.value} /> : null;
            })}
            {/* <NotificationDropdown /> */}

            {true && <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  dispatch(showRightSidebarAction(!showRightSidebar));
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle"  style={{padding: "0px", height:"100%", marginRight: "15px"}} >
                <FeatherIcon icon="arrow-left-circle" className="icon-lg" />
              </button>
            </div>}

            <ProfileMenu role={role} />
          </div>
        </div>
      </header>

    </React.Fragment>
  );
};

export default Header;
