import { StockInspectionTypes } from "./actionTypes";



// For Grid

export const getStockInspectionList = (lazyState: any) => {

  return {
    type: StockInspectionTypes.GET_STOCK_INSPECTION_LIST,
    lazyState,
  };
};



export const getStockInspectionListSuccess = (StockInspection: any) => {
  return {
    type: StockInspectionTypes.GET_STOCK_INSPECTION_LIST_SUCCESS,
    payload: StockInspection,
  };
};

export const getStockInspectionListError = (error: any) => {
  return {
    type: StockInspectionTypes.GET_STOCK_INSPECTION_LIST_FAIL,
    payload: error,
  };
};


export const deleteStockInspectionFromList = (payload: any) => {
  return {
    type: StockInspectionTypes.DELETE_STOCK_INSPECTION,
    payload: payload,
  }
}

export const deleteStockInspectionFromListSuccess = (response: any) => {
  return {
    type: StockInspectionTypes.DELETE_STOCK_INSPECTION_SUCCESS,
    payload: response,
  }
}

export const deleteStockInspectionFromListError = (error: any) => {
  return {
    type: StockInspectionTypes.DELETE_STOCK_INSPECTION_FAIL,
    payload: error,
  }
}




// update StockInspection

export const updateStockInspection = (stockInspection: any, stockInspectionId: string = "") => {
  return {
    type: StockInspectionTypes.UPDATE_STOCK_INSPECTION
    , payload: { stockInspection, stockInspectionId }
  };
};

export const updateStockInspectionSuccess = (payload: any) => {
  return { type: StockInspectionTypes.UPDATE_STOCK_INSPECTION_SUCCESS, payload };
};

export const updateStockInspectionError = (error: any) => {
  return { type: StockInspectionTypes.UPDATE_STOCK_INSPECTION_FAIL, payload: error };
};


// post StockInspection
export const createStockInspection = (StockInspection: any) => {
  return { type: StockInspectionTypes.POST_STOCK_INSPECTION, payload: StockInspection };
};

export const createStockInspectionSuccess = (StockInspection: any,formValue:any) => {
  return { type: StockInspectionTypes.POST_STOCK_INSPECTION_SUCCESS, payload: StockInspection, formValue: formValue };
};

export const createStockInspectionError = (error: any) => {
  return { type: StockInspectionTypes.POST_STOCK_INSPECTION_FAIL, payload: error };
};


// get StockInspection
export const getStockInspectionBYID = (id: string) => {
  return { type: StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID, payload: id };
};

export const getStockInspectionBYIDSuccess = (StockInspection: any) => {
  return { type: StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID_SUCCESS, payload: StockInspection };
};

export const getStockInspectionBYIDError = (error: any) => {
  return { type: StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID_FAIL, payload: error };
};


export const resetStockInspectionMessage = () => {
  return { type: StockInspectionTypes.RESET_STOCK_INSPECTION };
}


export const getPartSkuListBYID = (id: string) => {
  return { type: StockInspectionTypes.GET_PART_SKU_LIST, payload: id };
};

export const getPartSkuListBYIDSuccess = (partSkuList: any) => {
  return { type: StockInspectionTypes.GET_PART_SKU_LIST_SUCCESS, payload: partSkuList };
};

export const getPartSkuListBYIDError = (error: any) => {
  return { type: StockInspectionTypes.GET_PART_SKU_LIST_FAIL, payload: error };
};


export const getAudioBrandDropdown = () => {
  return { type: StockInspectionTypes.GET_AUDIO_BRAND_DROPDOWN_LIST };
};

export const getAudioBrandDropdownSuccess = (audiobrand: any) => {

  return { type: StockInspectionTypes.GET_AUDIO_BRAND_DROPDOWN_LIST_SUCCESS, payload: audiobrand };
};

export const getAudioBrandDropdownError = (error: any) => {
  return { type: StockInspectionTypes.GET_AUDIO_BRAND_DROPDOWN_LIST_FAIL, payload: error };
};

export const getSupplierDropdown = () => {
  return {
    type: StockInspectionTypes.GET_SUPPLIER_DROPDOWN,
  }
}

export const getSupplierDropdownSuccess = (payload: any) => {
  return {
    type: StockInspectionTypes.GET_SUPPLIER_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getSupplierDropdownError = (err: any) => {
  return {
    type: StockInspectionTypes.GET_SUPPLIER_DROPDOWN_ERROR,
    payload: err
  }
}

export const getInitiatedByDropdown = () => {

  return {
    type: StockInspectionTypes.GET_INITIATED_BY_DROPDOWN,
  }
}

export const getInitiatedByDropdownSuccess = (payload: any) => {

  return {
    type: StockInspectionTypes.GET_INITIATED_BY_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getInitiatedByDropdownError = (err: any) => {
  return {
    type: StockInspectionTypes.GET_INITIATED_BY_DROPDOWN_ERROR,
    payload: err
  }
}

export const getStockLocationDropdown = () => {
  return {
    type: StockInspectionTypes.GET_STOCK_LOCATION_DROPDOWN,
  }
}

export const getStockLocationDropdownSuccess = (payload: any) => {
  
  return {
    type: StockInspectionTypes.GET_STOCK_LOCATION_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getStockLocationDropdownError = (err: any) => {
  return {
    type: StockInspectionTypes.GET_STOCK_LOCATION_DROPDOWN_ERROR,
    payload: err
  }
}

export const getSupplierPartSkuDetails = (payload: any,stockLocationId:any) => {

  return {
    type: StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS,
    payload: payload,stockLocationId
  }
}

export const getSupplierPartSkuDetailsSuccess = (payload: any) => {

  return {
    type: StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS_SUCCESS,
    payload: payload
  }
}
export const getSupplierPartSkuDetailsError = (err: any) => {
  return {
    type: StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS_ERROR,
    payload: err
  }
}

export const getAudioBrandPartSkuDetails = (payload: any,stockLocationId:any) => {
  

  return {
    type: StockInspectionTypes.GET_AUDIO_BRAND_PART_SKU_DETAILS,
    payload: payload,stockLocationId
  }
}

export const getAudioBrandPartSkuDetailsSuccess = (payload: any) => {

  return {
    type: StockInspectionTypes.GET_AUDIO_BRAND_PART_SKU_DETAILS_SUCCESS,
    payload: payload
  }
}

export const getAudioBrandPartSkuDetailsError = (err: any) => {
  return {
    type: StockInspectionTypes.GET_AUDIO_BRAND_PART_SKU_DETAILS_ERROR,
    payload: err
  }
}

export const getPostPartSku = (payload:any) => {
  return{
    type:StockInspectionTypes.GET_SCAN_PART_SKU,
    payload:payload
  }
}

export const getPartSkuWithLocation = (payload:any) => {
  
  return{
    type:StockInspectionTypes.GET_SCAN_PART_SKU_WITH_LOCATION,
    payload:payload
  }
}

export const getPartSkuWithLocationInspection = (payload:any) => {
  return{
    type:StockInspectionTypes.GET_SCAN_PART_SKU_WITH_LOCATION_INSPECTION,
    payload:payload
  }
}

export const getPostPartSkuSuccess = (payload:any) => {
  return{
    type:StockInspectionTypes.GET_SCAN_PART_SKU_SUCCESS,
    payload:payload
  }
}
export const getPostPartSkuError = (err:any) => {
  return{
    type:StockInspectionTypes.GET_SCAN_PART_SKU_ERROR,
    payload:err
  }
}

export const clearErrorMessage = () => {
  return {
    type: StockInspectionTypes.CLEAR_ERROR_MESSAGE
  };
};

export const clearValues = () => {
  return {
    type: StockInspectionTypes.CLEAR_VALUES
  };
};

export const skusStatus = (skuNumbers: { barCode: string; }[]) => {
  return {
    type: StockInspectionTypes.SKUS_STATUS, payload: skuNumbers
  };
};

export const skuStatusSuccess = (payload: any) => {
  return {
    type: StockInspectionTypes.SKUS_STATUS_SUCCESS, payload
  };
};

export const skuStatusError = (error: any) => {
  return { type: StockInspectionTypes.SKUS_STATUS_FAIL, payload: error };
};

export const getSkuNumberWithStatus = (payload: any,isSupplier:boolean,isEdit:boolean) => {
  return {type: StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS, payload,isSupplier,isEdit};
}

export const getSkuNumberWithStatusSuccess = (payload: any, status: string) => {
  payload = {...payload, name: status}
  return {type: StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS_SUCCESS, payload};
}

export const getSkuNumberWithStatusFail = (error: any) => {
  return {type: StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS_FAIL, payload: error};
}

// SENT MAIL TO STOCK INSPECTION

export const sentMailToStockInspection = (id:any)=>{
  
  return {
    type : StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION,
    payload : id
  }
}
export const sentMailToStockInspectionSuccess = (data :any )=>{  
  return {
    type : StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION_SUCCESS,
    payload : data
  }
}

export const sentMailToStockInspectionError = (error : any)=>{
  return {
    type : StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION_FAIL,
    payload : error
  }
}

// DOWNLOAD STOCK INSPECTION

export const downloadStockInspection = (id:any)=>{
  
  return {
    type : StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION,
    payload : id
  }
}
export const downloadStockInspectionSuccess = (data :any )=>{  
  return {
    type : StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION_SUCCESS,
    payload : data
  }
}

export const downloadStockInspectionError = (error : any)=>{
  return {
    type : StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION_FAIL,
    payload : error
  }
}