import { EmployeeProfileTypes } from "./actionTypes";
import { employeeProfileModel } from "src/models/pages/employeProfileModel";

const initialState: employeeProfileModel = {
  loading: false,
  error: "",
  message: "",
  employeedata:null,
  ProfileIdData:null,
  employeeImage:null,
  documentList:null,
  generateLink:null,
}

const employeeProfile = (state = initialState, action: any) => {
  switch (action.type) {
    case EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_LOADING:
    case EmployeeProfileTypes.GET_EMPLOYEE_ID_LOADING:
    case EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING:
      
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
      }

    case EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        employeedata:action.payload.data
      }
    case EmployeeProfileTypes.GET_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ProfileIdData:action.payload.data
      }

  
    case EmployeeProfileTypes.CLEAR_EMPLOYEE_PROFILE:
        return{
            ...state,
            message:"",
            error:"",
        }

    case EmployeeProfileTypes.RESET_EMPLOYEE_PROFILE:
        return{
            ...state,
            message:"",
            error:"",
            ProfileIdData:null,
            employeedata:null
        }
        case EmployeeProfileTypes.EMPLOYEE_FILE_FETCH_SUCCESS:
          return {
            ...state,
            loading: false,
            error: "",
            message: "",
            employeeImage: action.payload.data,
          };

          case EmployeeProfileTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS:
            debugger;
            return {
              ...state,
              loading: false,
              error: "",
              message: "",
              documentList: action.payload.data,

            };

            case EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS:
              debugger
              return {
                ...state,
                loading: false,
                error: "",
                generateLink : action.payload.data,
              };
    case EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_ERROR:
    case EmployeeProfileTypes.GET_EMPLOYEE_ID_ERROR:
    case EmployeeProfileTypes.EMPLOYEE_FILE_FETCH_ERROR:
    case EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state }
  }
}

export default employeeProfile