import { hasCustomerRoleHandler, hasDealerRoleHandler } from "src/helpers/common_helpers";
import { ROUTER } from "./routes";
import { UserTypes } from "./userRoles";

export let menu: any[] = [
  {
    title: "Dashboard",
    path: ROUTER.DASHBOARD,
    icon: "home",
    isAllow: true,
    children: [],
    access: [UserTypes.admin, UserTypes.Employee],
  },
  {
    title: "Job Sheets",
    path: "/#",
    isAllow: true,
    activePaths: [ROUTER.JOB_SHEETS_GRID, ROUTER.JOB_SHEETS_CREATE, ROUTER.JOB_SHEETS_EDIT, ROUTER.JOB_SHEETS_VIEW, ROUTER.FEEDBACK_CREATE, ROUTER.FEEDBACK_GRID, ROUTER.FEEDBACK_VIEW, ROUTER.ENQUIRY_GRID, ROUTER.ENQUIRY_CREATE, ROUTER.ENQUIRY_VIEW],
    icon: "clipboard",
    access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer, , UserTypes.Customer, UserTypes.FranchiseSubUser,UserTypes.DealerSubUser,UserTypes.Manufacturer],
    children: [
      {
        title: "Job Sheets",
        path: ROUTER.JOB_SHEETS_GRID,
        access: [UserTypes.admin, UserTypes.Franchise, UserTypes.Dealer, UserTypes.Employee, UserTypes.Customer, UserTypes.FranchiseSubUser,UserTypes.DealerSubUser,UserTypes.Manufacturer],
        isAllow: true,
        activePaths: [ROUTER.JOB_SHEETS_GRID, ROUTER.JOB_SHEETS_CREATE, ROUTER.JOB_SHEETS_EDIT, ROUTER.JOB_SHEETS_VIEW]
      },
      {
        title: "Enquiries",
        path: ROUTER.ENQUIRY_GRID,
        access: [UserTypes.admin, UserTypes.Franchise, UserTypes.Employee],
        isAllow: true,
        activePaths: [ROUTER.ENQUIRY_GRID, ROUTER.ENQUIRY_CREATE, ROUTER.ENQUIRY_VIEW]
      },
      {
        title: "Feedbacks",
        path: ROUTER.FEEDBACK_GRID,
        access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee, UserTypes.Customer],
        activePaths: [ROUTER.FEEDBACK_CREATE, ROUTER.FEEDBACK_GRID, ROUTER.FEEDBACK_VIEW],
        isAllow: true,
      },
    ],
  },
  {
    title: "Dispatches",
    path: "/#",
    isAllow: true,
    icon: "truck",                   //UserTypes.Dealer
    access: [UserTypes.admin, UserTypes.Employee],
    children: [
      {
        title: "Incoming Dispatches",
        path: ROUTER.INCOMING_DISPATCH,
        access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee],
        activePaths: [ROUTER.INCOMING_DISPATCH, ROUTER.VIEW_INCOMING_DISPATCH],
        isAllow: true,
      },
      {
        title: "Outgoing Dispatches",
        // path: "",
        path: ROUTER.OUTCOMING_DISPATCH,
        access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee],
        activePaths: [ROUTER.OUTCOMING_DISPATCH, ROUTER.VIEW_OUTGOING_DISPATCH],
        isAllow: true,
      },
      {
        title: "Couriers",
        path: ROUTER.COURIER_LIST,
        access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee],
        activePaths: [ROUTER.COURIER_LIST, ROUTER.VIEW_COURIER, ROUTER.EDIT_COURIER, ROUTER.CREATE_COURIER],
        isAllow: true,
      },
    ],
  },
  {
    title: "Invoices",
    path: ROUTER.INVOICE_GRID,
    isAllow: true,
    access: [UserTypes.admin, UserTypes.Employee,UserTypes.Dealer,UserTypes.Customer],
    activePaths: [ROUTER.INVOICE_GRID, ROUTER.INVOICE_EDIT, ROUTER.INVOICE_CREATE, ROUTER.INVOICE_VIEW],
    icon: "file-text",
    children: [],
  },
  {
    title: "Received Payments",
    path: ROUTER.RECEIVED_PAYMENT_LIST,
    isAllow: true,
    access: [UserTypes.admin, UserTypes.Employee, UserTypes.Customer, UserTypes.Dealer],
    icon: "briefcase",
    activePaths: [ROUTER.RECEIVED_PAYMENT_LIST,ROUTER.RECEIVED_PAYMENT_CREATE,ROUTER.RECEIVED_PAYMENT_VIEW],
    children: [],
  },
  {
    title: "Master Mgmt",
    path: "/#",
    isAllow: true,
    icon: "box",
    access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
    children: [
      {
        title: "Audio Brands",
        path: ROUTER.AUDIO_BRANDS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.AUDIO_BRANDS_GRID, ROUTER.AUDIO_BRANDS_CREATE, ROUTER.AUDIO_BRANDS_EDIT, ROUTER.AUDIO_BRANDS_VIEW]
      },
      {
        title: "Audio Models",
        path: ROUTER.AUDIO_MODELS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.AUDIO_MODELS_GRID, ROUTER.AUDIO_MODELS_CREATE, ROUTER.AUDIO_MODELS_EDIT, ROUTER.AUDIO_MODELS_VIEW]
      },
      {
        title: "Part SKUs",
        path: ROUTER.PARTS_SKU,
        // path: "",
        isAllow: true,
        activePaths: [ROUTER.PARTS_SKU],
        access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
      },
      {
        title: "Parts",
        path: ROUTER.PARTS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.PARTS_GRID, ROUTER.PARTS_CREATE, ROUTER.PARTS_EDIT]
      },
      {
        title: "Taxes",
        path: ROUTER.TAXES_GRID,
        // path: '',
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.TAXES_GRID, ROUTER.TAXES_CREATE, ROUTER.TAXES_EDIT, ROUTER.TAXES_VIEW]
      },
      {
        title: "Vehicle Brands",
        path: ROUTER.VEHICLE_BRANDS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.VEHICLE_BRANDS_GRID, ROUTER.VEHICLE_BRANDS_CREATE, ROUTER.VEHICLE_BRANDS_EDIT, ROUTER.VEHICLE_BRANDS_VIEW]
      },
      {
        title: "Supplier",
        path: ROUTER.SUPPLIER_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.SUPPLIER_GRID, ROUTER.SUPPLIER_CREATE, ROUTER.SUPPLIER_EDIT, ROUTER.SUPPLIER_VIEW]
      },
    ],
  },
  {
    title: "People Mgmt",
    path: "/#",
    isAllow: true,
    icon: "users",
    access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer],
    children: [
      {
        title: "Customers",
        path: ROUTER.CUSTOMERS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.CUSTOMERS_GRID, ROUTER.CUSTOMERS_CREATE, ROUTER.CUSTOMERS_VIEW, ROUTER.CUSTOMERS_EDIT]
      },
      {
        title: "Dealers",
        path: ROUTER.DEALERS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.DEALERS_GRID, ROUTER.DEALERS_CREATE, ROUTER.DEALERS_EDIT, ROUTER.DEALERS_VIEW]
      },
      // {
      //   title: "Front office",
      //   path: "/apps-calendar",
      //   isAllow: true,
      // },
      // {
      //   title: "Suppliers",
      //   path: "/apps-calendar",
      //   isAllow: true,
      // },
      // {
      //   title: "Technicians",
      //   path: "/apps-calendar",
      //   isAllow: true,
      // },
      {
        title: "Employees",
        path: ROUTER.USERS_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.USERS_GRID, ROUTER.USERS_CREATE, ROUTER.USERS_EDIT, ROUTER.USERS_VIEW]
      },
      {
        title: "Franchise",
        path: ROUTER.FRANCHISEE_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.FRANCHISEE_GRID, ROUTER.FRANCHISEE_CREATE, ROUTER.FRANCHISEE_EDIT, ROUTER.FRANCHISEE_VIEW]
      },
      {
        title: "Franchise Sub User",
        path: ROUTER.SUB_USER_GRID,
        isAllow: true,
        access: [UserTypes.Franchise],
        activePaths: [ROUTER.SUB_USER_GRID, ROUTER.SUB_USER_CREATE, ROUTER.SUB_USER_EDIT, ROUTER.SUB_USER_VIEW]
      },
      {
        title: "Dealer Sub User",
        path: ROUTER.DEALER_SUB_USER_GRID,
        isAllow: true,
        access: [UserTypes.Dealer],
        activePaths: [ROUTER.DEALER_SUB_USER_GRID, ROUTER.DEALER_SUB_USER_CREATE, ROUTER.DEALER_SUB_USER_EDIT, ROUTER.DEALER_SUB_USER_VIEW]
      },
      {
        title: "Manufacturer",
        path: ROUTER.MANUFACTURER_GRID,
        isAllow: true,
        access: [UserTypes.admin,UserTypes.Employee],
        activePaths: [ROUTER.MANUFACTURER_GRID, ROUTER.MANUFACTURER_CREATE, ROUTER.MANUFACTURER_EDIT, ROUTER.MANUFACTURER_VIEW]
      },
    ],
  },
  {
    title: "Stock Mgmt",
    path: "/#",
    isAllow: true,
    icon: "pie-chart",
    access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
    children: [
      {
        title: "Purchase Orders",
        path: ROUTER.PURCHASE_ORDER_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.PURCHASE_ORDER_GRID, ROUTER.PURCHASE_ORDER_CREATE, ROUTER.PURCHASE_ORDER_EDIT, ROUTER.PURCHASE_ORDER_VIEW]
      },
      {
        title: "Parts Inbound",
        // path: "",
        path: ROUTER.PARTS_INBOUND_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.PARTS_INBOUND_GRID, ROUTER.PARTS_INBOUND_CREATE, ROUTER.PARTS_INBOUND_EDIT, ROUTER.PARTS_INBOUND_VIEW, ROUTER.PARTS_SKU_GENERATOR]
      },
      {
        title: "Intend",
        // path: "",
        path: ROUTER.INTENT_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
        activePaths: [ROUTER.INTENT_GRID, ROUTER.INTENT_EDIT, ROUTER.INTENT_CREATE, ROUTER.INTENT_VIEW]
      },
      {
        title: "Stock Adjustment",
        // path: "",
        path: ROUTER.STOCK_ADJUSTMENT_GRID,
        isAllow: true,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_ADJUSTMENT_GRID, ROUTER.STOCK_ADJUSTMENT_CREATE, ROUTER.STOCK_ADJUSTMENT_EDIT, ROUTER.STOCK_ADJUSTMENT_VIEW]
      },
      {
        title: "Stock Register",
        path: ROUTER.STOCK_REGISTER_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        isAllow: true,
        activePaths: [ROUTER.STOCK_REGISTER_GRID]
      },
      {
        title: "Stock Inspection",
        path: ROUTER.STOCK_INSPECTION_GRID,
        access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
        isAllow: true,
        activePaths: [ROUTER.STOCK_INSPECTION_GRID, ROUTER.STOCK_INSPECTION_CREATE, ROUTER.STOCK_INSPECTION_EDIT, ROUTER.STOCK_INSPECTION_VIEW]
      },
      {
        title: "Stock Location",
        // path: "",
        path: ROUTER.STOCK_LOCATION_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        isAllow: true,
        activePaths: [ROUTER.STOCK_LOCATION_GRID, ROUTER.STOCK_LOCATION_CREATE, ROUTER.STOCK_LOCATION_EDIT, ROUTER.STOCK_LOCATION_VIEW]
      },

    ],
  },
  {
    title: "Stock Reports",
    path: "/#",
    isAllow: true,
    icon: "airplay",
    access: [UserTypes.admin, UserTypes.Employee],
    children: [
      {
        title: "Stock Value by Supplier",
        path: ROUTER.STOCK_VALUE_BY_SUPPLIER_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_VALUE_BY_SUPPLIER_GRID],
        isAllow: true,
      },
      {
        title: "Stock Qty by Supplier",
        // path: "",
        path: ROUTER.STOCK_QUANTITY_BY_SUPPLIER_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_QUANTITY_BY_SUPPLIER_GRID],
        isAllow: true,
      },
      {
        title: "Stock Value by Musicsystem brand",
        // path: "",
        path: ROUTER.STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_GRID],
        isAllow: true,
      },
      {
        title: "Stock Qty by Musicsystem brand",
        // path: "",
        path: ROUTER.STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_GRID],
        isAllow: true,
      },
      {
        title: "Stock Value by Part",
        // path: "",
        path: ROUTER.STOCK_VALUE_BY_PART_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_VALUE_BY_PART_GRID],
        isAllow: true,
      },
      {
        title: "Stock Qty by Part",
        // path: "",
        path: ROUTER.STOCK_QUANTITY_BY_PART_GRID,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.STOCK_QUANTITY_BY_PART_GRID],
        isAllow: true,
      },
    ]
  },
  {
    title: "Reports",
    path: "/#",
    isAllow: true,
    icon: "bar-chart-2",
    access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer, UserTypes.DealerSubUser, UserTypes.FranchiseSubUser],
    children: [
      {
        title: "Invoice Item Wise Registers",
        path: ROUTER.INVOICE_ITEM_WISE_REGISTERS_GRID,
        // path: "",
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.INVOICE_ITEM_WISE_REGISTERS_GRID],
        isAllow: true,
      },
      {
        title: "Job Sheet Reports",
        path: ROUTER.JOB_SHEET_REPORTS,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.JOB_SHEET_REPORTS],
        isAllow: true,
      },
      {
        title: "Job Sheet TAT Reports",
        path: ROUTER.JOB_SHEET_TAT_REPORTS,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.JOB_SHEET_TAT_REPORTS],
        isAllow: true,
      },
      {
        title: "Monthly Parts Summary",
        path: ROUTER.MONTHLY_PARTS_SUMMARY,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.MONTHLY_PARTS_SUMMARY],
        isAllow: true,
      },
      {
        title: "Pending Parts",
        path: ROUTER.PENDING_PARTS,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.PENDING_PARTS],
        isAllow: true,
      },
      {
        title: "Pending Parts (Consolidated)",
        path: ROUTER.PENDING_PARTS_CONSOLIDATED,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.PENDING_PARTS_CONSOLIDATED],

        isAllow: true,
      },
      {
        title: "Attendance Reports",
        path: ROUTER.ATTENDANCE_REPORT,
        access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer, UserTypes.DealerSubUser, UserTypes.FranchiseSubUser],
        activePaths: [ROUTER.ATTENDANCE_REPORT],
        isAllow: true,
      },
    ],
  },
  {
    title: "Show Attendance",
    path: ROUTER.SHOW_ATTENDANCE,
    icon: "calendar",
    isAllow: true,
    access: [UserTypes.Employee, UserTypes.admin],
    activePaths: [ROUTER.SHOW_ATTENDANCE],
    children: []
  },
  {
    title: "Ticketing System",
    path: "/#",
    icon: "help-circle",
    isAllow: false,
    access: [UserTypes.Employee, UserTypes.Customer, UserTypes.admin],
    activePaths: [ROUTER.TICKET_SYSTEM],
    children: [
      {
        title: hasCustomerRoleHandler() ? "My tickets" : "All tickets",
        path: ROUTER.TICKET_SYSTEM,
        access: [UserTypes.admin, UserTypes.Employee, UserTypes.Customer],
        activePaths: [ROUTER.TICKET_SYSTEM],
        isAllow: false,
      },
    ]
  },
  {
    title: "Marketing",
    path: "/#",
    icon: "mail",
    isAllow: true,
    access: [UserTypes.Employee, UserTypes.admin],
    activePaths: [ROUTER.BULK_EMAIL, ROUTER.BULK_EMAIL_SENDER, ROUTER.BULK_EMAIL_VIEW, ROUTER.BULK_SMS, ROUTER.BULK_SMS_SENDER, ROUTER.BULK_SMS_VIEW],
    children: [
      {
        title: "Bulk Email",
        path: ROUTER.BULK_EMAIL,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.BULK_EMAIL, ROUTER.BULK_EMAIL_SENDER, ROUTER.BULK_EMAIL_VIEW],
        isAllow: true,
      },
      {
        title: "Bulk Sms",
        path: ROUTER.BULK_SMS,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.BULK_SMS, ROUTER.BULK_SMS_SENDER, ROUTER.BULK_SMS_VIEW],
        isAllow: true,
      },
      {
        title: "Whats App",
        path: ROUTER.WHATSAPP_MESSAGE_LIST,
        access: [UserTypes.admin, UserTypes.Employee],
        activePaths: [ROUTER.WHATSAPP_MESSAGE_LIST, ROUTER.WHATSAPP_MESSAGE_SENDER, ROUTER.WHATSAPP_MESSAGE_VIEW],
        isAllow: true,
      },
    ]
  },
  {
    title: "Settings",
    path: "/#",
    icon: "settings",
    isAllow: true,
    access: [UserTypes.Employee, UserTypes.admin, UserTypes.Franchise],
    activePaths: [ROUTER.INCENTIVE_SYSTEM, ROUTER.REWARD_VALUE, ROUTER.TOTAL_WORKING_DAYS, ROUTER.TOTAL_WORKING_DAYS_CREATE, ROUTER.TOTAL_WORKING_DAYS_EDIT],
    children: [
      {
        title: "Permission",
        path: ROUTER.PERMISSION,
        isAllow: true,
        access: [UserTypes.admin],
        activePaths: [ROUTER.PERMISSION],
      },
      {
        title: "Incentive",
        path: ROUTER.INCENTIVE_SYSTEM,
        isAllow: true,
        access: [UserTypes.Employee, UserTypes.admin],
        activePaths: [ROUTER.INCENTIVE_SYSTEM],
      },
      {
        title: "Incentive Reports",
        path: ROUTER.INCENTIVE_REWARD_SYSTEM,
        isAllow: true,
        access: [UserTypes.Employee, UserTypes.admin, UserTypes.Franchise],
        activePaths: [ROUTER.INCENTIVE_REWARD_SYSTEM, ROUTER.INCENTIVE_REWARD_SYSTEM_VIEW],
      },
      {
        title: "Reward Value",
        path: ROUTER.REWARD_VALUE,
        isAllow: true,
        access: [UserTypes.Employee, UserTypes.admin],
        activePaths: [ROUTER.REWARD_VALUE],
      },
      {
        title: "Total Working Days",
        path: ROUTER.TOTAL_WORKING_DAYS,
        isAllow: true,
        access: [UserTypes.Employee, UserTypes.admin],
        activePaths: [ROUTER.TOTAL_WORKING_DAYS, ROUTER.TOTAL_WORKING_DAYS_CREATE, ROUTER.TOTAL_WORKING_DAYS_EDIT],
      },
      {
        title: "Additional Expense Reports",
        path: ROUTER.ADDITIONAL_EXPENSES_REPORTS_TABLE,
        isAllow: true,
        access: [UserTypes.Employee, UserTypes.admin, UserTypes.Franchise],
        activePaths: [ROUTER.ADDITIONAL_EXPENSES_REPORTS_TABLE, ROUTER.ADDITIONAL_EXPENSES_REPORTS_VIEW],
      },
    ]
  },
];