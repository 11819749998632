

export const APPENV_URL = { 
   identityUrl : process.env.REACT_APP_IDENTITY_URL,
   masterManagementUrl : process.env.REACT_APP_MASTERMANAGEMENT_URL,
   jobServiceUrl : process.env.REACT_APP_JOBSERVICE_URL,
   courierServiceUrl: process.env.REACT_APP_COURIER_SERVICE_URL,
   jsonServer : "http://localhost:3200",
   accountUrl : process.env.REACT_APP_API_ACCOUNT_URL,
   paymentUrl : process.env.REACT_APP_API_PAYMENT_URL,
   ticketSystemUrl : process.env.REACT_APP_API_TICKETSYSTEM_URL,
   MassEmailUrl:process.env.REACT_APP_API_MASS_MAIL_URL,
   MassSmsUrl:process.env.REACT_APP_API_MASS_SMS_URL,
   AppUrl : process.env.REACT_APP_APP_SERVICE_URL,
   permissionUrl : process.env.REACT_APP_API_PERMISSION_URL,
   whatsAppUrl : process.env.REACT_APP_API_WHATS_APP_URL,
}