import { incomingDispatchTypes } from "./actionTypes";

// For Grid
export const getIncomingDispatchList = (lazyState: any) => {
    return {
        type: incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_LOADING,
        lazyState,
    };
};

export const getIncomingDispatchListSuccess = (enquiry: any) => {
    return {
        type: incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_SUCCESS,
        payload: enquiry,
    };
};

export const getIncomingDispatchListError = (error: any) => {
    return {
        type: incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_FAIL,
        payload: error,
    };
};


// get Enquiry
export const getIncomingDispatchBYID = (id: string) => {
    return { type: incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_LOADING, payload: id };
};

export const getIncomingDispatchBYIDSuccess = (enquiry: any) => {
    return { type: incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_SUCCESS, payload: enquiry };
};

export const getIncomingDispatchBYIDError = (error: any) => {
    return { type: incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_FAIL, payload: error };
};



export const clearIncomingDispatch = () => {
    return { type: incomingDispatchTypes.CLEAR_INCOMING_DISPATCH };
};

export const resetIncomingDispatch = () => {
    return { type: incomingDispatchTypes.RESET_INCOMING_DISPATCH_MESSAGE };
};

// get Enquiry
export const getReceivedBYID = (id: any) => {
    return { type: incomingDispatchTypes.GET_RECEIVED_BY_ID_LOADING, id: id };
};

export const getReceivedBYIDSuccess = (response: any) => {
    return { type: incomingDispatchTypes.GET_RECEIVED_BY_ID_SUCCESS, payload: response };
};

export const getReceivedBYIDError = (error: any) => {
    return { type: incomingDispatchTypes.GET_RECEIVED_BY_ID_FAIL, payload: error };
};