import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from "src/components/UI/Button";
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { getLocationLatLng, onDropRejectedFileHandler } from "src/helpers/common_helpers";
import { Dialog } from "primereact/dialog";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import TooltipMEL from "src/components/UI/Tooltip";
import DeleteModal from "src/pages/Calendar/DeleteModal";
import {
  ClearSupplierImage,
  ResetSupplierMessage,
  clearSupplierMessage,
  createSupplier,
  createSupplierContactDetails,
  deleteSupplierContact,
  deleteSupplierDocumentLoading,
  deleteSupplierImageLoading,
  getSupplierById,
  getSupplierContactDetails,
  getSupplierImageLoading,
  supplierDocumentGetLoading,
  supplierDocumentUploadLoading,
  supplierLatLagLoading,
  supplierLinkGetLoading,
  updateSupplier,
  uploadSupplierFileLoading,
} from "src/store/actions";
import { supplierDetails } from "src/models/pages/supplierModel";
import { FILE_UPLOAD_SIZE } from "src/constants/commonConstants";
import ImagePreviewer from "src/components/Common/ImagePreviewer/ImagePreviewer";
import { supplierImageDeleteSuccess, supplierMessage } from "src/store/supplier/reducer";
import "./Supplier.scss";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { Columns } from "src/models/components/dataTableWrapperModel";
import { Skeleton } from "primereact/skeleton";
import TextEditor from "src/components/atoms/TextEditor";
import { APPENV_URL } from "src/util/appUrlEnv";

const AddEditSupplier = () => {
  const { id } = useParams();
  const [contactdetailvalue, setcontactdetailvalue] = useState<any>(null);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue: supplierDetails = useSelector(
    (state: RootReducerState) =>
      state.supplierReducer.supplierDetails as supplierDetails
  );
  const {
    error,
    message,
    loading,
    supplierImage,
    contactDetails,
    contacterror,
    supplierDocumentList,
    documentLink,
    documentMessage,
    documentuploadMessge,
    supplierLatLng
  } = useSelector((state: RootReducerState) => state.supplierReducer);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);
  const [customError, setcustomError] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(getSupplierById(id));
      dispatch(getSupplierContactDetails(id));
      dispatch(getSupplierImageLoading(id));
    } else {
      getLocationLatLng(setLatitude, setLongitude);
    }
    return () => {
      setVisible(false);
      dispatch(ResetSupplierMessage());
      setLatitude("");
      setLongitude("");
      setAddress("");
    };
  }, []);
  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    if (error == "" && documentMessage != "") {
      showToast(documentMessage, { type: "success" });
      dispatch(getSupplierContactDetails(id));

      // if(error == "" && documentuploadMessge != "" && documentMessage != ""){
      //   dispatch(getSupplierContactDetails(id))
      //   contactDetails?.forEach((element: any) => {
      //     dispatch(supplierDocumentGetLoading(element?.id));
      //   });
      // }

      dispatch(ClearSupplierImage());
    }
  }, [documentMessage]);



  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);

    }
    // dispatch(getSupplierContactDetails(id));
    if (message !== "" && message != undefined) {
      if (error == "") {
        showToast(message, { type: "success" });
      }
      if (message != supplierImageDeleteSuccess && message != supplierMessage && error == "") {
        setTimeout(() => {
          setIsSubmitting(false);
          navigate(ROUTER.SUPPLIER_GRID);
        }, 500);
        dispatch(clearSupplierMessage());

        // dispatch(getSupplierById(id));
        // dispatch(getSupplierImageLoading(id));
      }
      if (message == supplierMessage) {
        dispatch(getSupplierContactDetails(id));
        dispatch(clearSupplierMessage());
      }
      setLatitude("");
      setLongitude("");
      setAddress("");
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearSupplierMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const post = (formValue: supplierDetails) => {
    dispatch(createSupplier(formValue));
  };

  const update = (formValue: supplierDetails) => {
    dispatch(updateSupplier(formValue, id));

    // dispatch(createSupplierContactDetails(contactForm))
    if (selectedFiles.length > 0) {
      dispatch(uploadSupplierFileLoading(selectedFiles[0], id));
    }
  };

  const initialvalue: any = {
    name: formValue?.name ? formValue?.name : "",
    companyName: formValue?.companyName ? formValue?.companyName : "",
    email: formValue?.email ? formValue?.email : "",
    mobileNumber: formValue?.mobileNumber ? formValue?.mobileNumber : "",
    gstNumber: formValue?.gstNumber ? formValue?.gstNumber : "",
    iecNumber: formValue?.iecNumber ? formValue?.iecNumber : "",
    accountInformation: formValue?.accountInformation
      ? formValue?.accountInformation
      : "",
    termsAndConditions: formValue?.termsAndConditions
      ? formValue?.termsAndConditions
      : "",
    address: formValue?.address ? formValue?.address : "",
    detailsVerified: formValue?.detailsVerified
      ? formValue?.detailsVerified
      : false,
    sendEmail: formValue?.sendEmail ? formValue?.sendEmail : false,
    sendSMS: formValue?.sendSMS ? formValue?.sendSMS : false,
    sendWhatsapp: formValue?.sendWhatsapp ? formValue?.sendWhatsapp : false,

    // contactDetails: (contactDetails?.length > 0 ? contactDetails?.map((eachContact: ContactDetail) => {
    //   let data: any = {};
    //   data['id'] = eachContact?.id;
    //   data['name'] = eachContact?.name;
    //   data['designation'] = eachContact?.designation;
    //   data['mobileNumber'] = eachContact?.mobileNumber;
    //   data['alternateNumber'] = eachContact?.alternateNumber;
    //   data['email'] = eachContact?.email;
    //   data['smeNo'] = eachContact?.smeNo;
    //   data['gstNo'] = eachContact?.gstNo;
    //   return data;
    // }) : ([]))
  };
  useEffect(() => {
    if (formValue?.address) {
      // setAddress(formValue?.address)
      dispatch(supplierLatLagLoading(formValue?.address));
    }
  }, [formValue?.address]);

  const handleSubmit = async (values: any) => {
    debugger;
    if (isSubmitting) return;
    const submittedData: any = {
      name: values.name,
      email: values.email,
      companyName: values.companyName,
      mobileNumber: values.mobileNumber.toString(),
      detailsVerified: values.detailsVerified,
      gstNumber: values.gstNumber,
      iecNumber: values.iecNumber,
      accountInformation: values.accountInformation,
      termsAndConditions: values.termsAndConditions,
      address: values.address,
      sendEmail: values.sendEmail,
      sendSMS: values.sendSMS,
      sendWhatsapp: values.sendWhatsapp,
    };
    // let contactDetails = values.contactDetails.map((val: any) => ({

    //   id: val.id,
    //   supplierId: Number(id),
    //   name: val.name,
    //   designation: val.designation,
    //   mobileNumber: String(val.mobileNumber),
    //   alternateNumber: String(val.alternateNumber),
    //   email: val.email,
    //   smeNo: val.smeNo,
    //   gstNo: val.gstNo,


    // }));

    // console.log(contactDetails)


    // let res = {
    //   supplierId: Number(id),
    //   contactDetails: contactDetails,
    // };
    setIsSubmitting(true);
    id ? update(submittedData) : post(submittedData);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    companyName: Yup.string().required("Company Name is required"),
    email: Yup.string()
      .required(`Email is required`)
      .email("Enter a valid email"),
    mobileNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    gstNumber: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        "Invalid Gst number"
      )
      .required("Gst number is required"),
    iecNumber: Yup.string()
      .required(`Iec Number is required`)
      .max(10, "IEC Number must be less than 8 characters"),
    address: Yup.string()
      .required("Address is required"),
    accountInformation: Yup.string().required(
      "Account Information is required"
    ),
    termsAndConditions: Yup.string().required("Terms & Conditions is required"),
  });

  const validationSchemaForEdit = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    companyName: Yup.string(),
    email: Yup.string()
      .required(`Email is required`)
      .email("Enter a valid email"),
    mobileNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    address: Yup.string().required("Address is required"),
    gstNumber: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        "Invalid Gst number"
      )
      .required("Gst number is required"),
    iecNumber: Yup.string().required(`Iec Number is required`),
    // address: Yup.string()
    //   .matches(/^(.+\s+)(\d{6})$/, "Enter with valid pincode Ex:-(chennai 600028)")
    //   .required("Address is required"),
    accountInformation: Yup.string().required(
      "Account Information is required"
    ),
    termsAndConditions: Yup.string().required("Terms & Conditions is required"),
    contactDetails: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`Name is required`),
        designation: Yup.string().required(`Designation is required`),
        email: Yup.string()
          .required("Email is required")
          .email("Enter a valid email")
          .test("valid-email", "Enter a valid email", function (value) {
            if (!value) return true; // Let Yup handle the "required" validation
            return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
          }),
        mobileNumber: Yup.string()
          .required(`Mobile number is required`)
          .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
        alternateNumber: Yup.string().matches(
          /^\d{10}$/,
          "Alternate number must be 10 digits"
        ),
        gstNo: Yup.string()
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
            "Invalid GST number"
          )
          .required("GST number is required"),
        smeNo: Yup.string().required("SME No is required"),
      })
    ),
  });

  const handleReset = (resetForm: any) => {
    if (id) {
      navigate(ROUTER.SUPPLIER_GRID);
    }
    resetForm();
  };
  // const getEmptyInitialValues = () => {
  //   const initialValue = localFormFields?.reduce((acc: any, field: any) => {
  //     if (field.type !== 'checkbox') {
  //       acc[field.name] = '';
  //     } else {
  //       acc[field.name] = false;
  //     }
  //     return acc;
  //   }, {});
  //   return initialValue;
  // };
  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };
  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  function handleAcceptedFiles(files: any) {
    let filesModified = files.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith("image/"),
      });
    });

    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeIndex = () => {
    setDeleteModal(false);
    if (supplierImage) {
      dispatch(deleteSupplierImageLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };

  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.contactDetails || [];
    return contactDetails.every((contact: any) => {
      const { alternateNumber, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  const handleAddContactDetails = (
    values: any,
    push: any,
    setFieldTouched: any
  ) => {
    if (isContactDetailFilled(values)) {
      push({
        name: "",
        designation: "",
        mobileNumber: "",
        alternateNumber: "",
        email: "",
        smeNo: "",
        gstNo: "",
        isDelete: false,
      });
    } else {
      values.contactDetails?.forEach((_: any, index: number) => {
        setFieldTouched(`contactDetails[${index}].name`, true);
        setFieldTouched(`contactDetails[${index}].designation`, true);
        setFieldTouched(`contactDetails[${index}].mobileNumber`, true);
        setFieldTouched(`contactDetails[${index}].alternateNumber`, true);
        setFieldTouched(`contactDetails[${index}].email`, true);
        setFieldTouched(`contactDetails[${index}].smeNo`, true);
        setFieldTouched(`contactDetails[${index}].gstNo`, true);
      });
    }
  };

  const [contactDetailFalse, setContactDetailFalse] = useState(false);
  const [canRedirect, setCanRedirect] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedindex, setselectedindex] = useState<any>(null);

  const formSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    designation: Yup.string().required(`Designation is required`),
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    mobileNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    alternateNumber: Yup.string().matches(
      /^\d{10}$/,
      "Alternate number must be 10 digits"
    ),
    gstNo: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        "Invalid GST number"
      )
      .required("GST number is required"),
    smeNo: Yup.string().required("SME No is required"),
  });

  const handleFromSubmit = async (val: any) => {
    debugger;
    setOpenModal(false);
    let contactDetail = {
      id: val.id ? val.id : 0,
      supplierId: Number(id),
      name: val.name,
      designation: val.designation,
      mobileNumber: String(val.mobileNumber),
      alternateNumber: String(val.alternateNumber),
      email: val.email,
      smeNo: val.smeNo,
      gstNo: val.gstNo,
    };
    let res = {
      supplierId: Number(id),
      contactDetails: [contactDetail],
    };

    try {
      if (contactDetail.id !== 0) {
        const uploadTasks = [];
        if (gstfile) {
          const gstUploadTask = dispatch(supplierDocumentUploadLoading(gstfile, contactDetail.id, "GSTDOCUMENT"));
          uploadTasks.push(gstUploadTask);
          setGstFile(null);
        }
        if (smefile) {
          const smeUploadTask = dispatch(supplierDocumentUploadLoading(smefile, contactDetail.id, "SMEDOCUMENT"));
          uploadTasks.push(smeUploadTask);
          setSmeFile(null);
        }
        await Promise.all(uploadTasks);
      }
      dispatch(createSupplierContactDetails(res));
    } catch (error) {

    }
    // if (contactDetail.id != 0) {
    //   if (gstfile) {
    //     dispatch(supplierDocumentUploadLoading(gstfile, contactDetail.id, "GSTDOCUMENT"));       
    //     setGstFile(null);
    //   }
    //   if (smefile) {       
    //     dispatch(supplierDocumentUploadLoading(smefile, contactDetail.id, "SMEDOCUMENT"));       
    //     setSmeFile(null);
    //   }
    // }
    // dispatch(createSupplierContactDetails(res));
  };
  const handleAddDynamic = () => {
    setContactVal({});
    setOpenModal(true);
  };
  const [contactVal, setContactVal] = useState<any>({
    id: null,
    supplierId: Number(id),
    name: "",
    designation: "",
    mobileNumber: "",
    alternateNumber: "",
    email: "",
    smeNo: "",
    gstNo: "",
  });

  const newvalue = {
    id: contactVal.id ? contactVal.id : 0,
    supplierId: Number(id),
    name: contactVal.name ? contactVal.name : "",
    designation: contactVal.designation ? contactVal.designation : "",
    mobileNumber: contactVal.mobileNumber ? contactVal.mobileNumber : "",
    alternateNumber: contactVal.alternateNumber
      ? contactVal.alternateNumber
      : "",
    email: contactVal.email ? contactVal.email : "",
    smeNo: contactVal.smeNo ? contactVal.smeNo : "",
    gstNo: contactVal.gstNo ? contactVal.gstNo : "",
    gstfilename: contactVal.gstfilename ? contactVal.gstfilename : "",
    smefilename: contactVal.smefilename ? contactVal.smefilename : "",
    gstDocId: contactVal.gstDocId ? contactVal.gstDocId : "",
    smeDocId: contactVal.smeDocId ? contactVal.smeDocId : "",
    gstDocument: null,
    smeDocument: null,
  };

  const editButtonTemplate = (rowData: any) => {
    return (
      <div
        className="table-icon"
        onClick={(index) => {
          debugger;
          setOpenModal(true);
          setselectedindex(0);
          setContactVal({
            id: rowData.id,
            name: rowData.name,
            designation: rowData.designation,
            mobileNumber: rowData.mobileNumber,
            alternateNumber: rowData.alternateNumber,
            email: rowData.email,
            smeNo: rowData.smeNo,
            gstNo: rowData.gstNo,
            gstfilename: rowData.gstfilename,
            smefilename: rowData.smefilename,
            // gstDocId: rowData.gstDocument,
            // smeDocId: rowData.smeDocument,            
            gstDocId: rowData.gstId,
            smeDocId: rowData.smeId,
          });

        }}
      >
        <FeatherIcon icon={"edit-3"} />
      </div>
    );
  };
  const [file, setFile] = useState<any>({
    gstDocument: null,
    smeDocument: null,
  });
  const [gstfile, setGstFile] = useState<any>(null);
  const [smefile, setSmeFile] = useState<any>(null);
  // const handleFileChange = (e: any, documentType: string) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedTypes = ["application/pdf"];
  //   if (!file || allowedTypes.includes(selectedFile.type)) {
  //     if (documentType == "gstDocument") {

  //       setGstFile(selectedFile);
  //     } else {
  //       // assigntheValue(selectedFile,setSmeFile)
  //       setSmeFile(selectedFile);
  //     }
  //   } else {
  //     showToast("Please Upload Pdf File", { type: "error" });
  //     e.target.value = "";
  //   }
  // };
  const handleFileChange = (e: any, documentType: string) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"];
    if (!file || allowedTypes.includes(selectedFile.type)) {
      if (documentType == "gstDocument") {
        setGstFile(selectedFile);
      } else {
        // assigntheValue(selectedFile,setSmeFile)
        setSmeFile(selectedFile);
      }
    } else {
      showToast("Please Upload Pdf or Image File", { type: "error" });
      e.target.value = "";
    }
  };


  const removegstFile = (id: any) => {
    dispatch(deleteSupplierDocumentLoading(id));
    setOpenModal(false);
  };
  const removesmeFile = (id: any) => {
    dispatch(deleteSupplierDocumentLoading(id));
    setOpenModal(false);
  };
  const showDialog = () => {
    return (
      <Dialog
        header={newvalue?.id != 0 ? "Edit Contact" : "Add Contact"}
        visible={openModal}
        style={{ width: "50vw" }}
        onHide={() => {
          setOpenModal(false);
          setContactVal({});
        }}
      >
        {customError && dialogContainerCustomError("Address field required")}
        <Formik
          onSubmit={handleFromSubmit}
          initialValues={newvalue}
          validationSchema={formSchema}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            touched,
            errors,
            handleBlur,
            resetForm,
            reset,
            setFieldTouched,
            ...rest
          }: any) => {
            return (
              <Form onChange={handleChange}>
                <Row>
                  <Col lg={6} className="mb-3">
                    <InputCom
                      labelName="Name"
                      inputclassName="form-control"
                      labelClassName={`form-Label`}
                      type="text"
                      name={`name`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isRequired={true}
                      value={values.name}
                      invalid={touched?.name && errors?.name ? true : false}
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputCom
                      labelName="Designation"
                      inputclassName="form-control"
                      labelClassName={`form-Label`}
                      type="text"
                      name={`designation`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isRequired={true}
                      value={values.designation}
                      invalid={
                        touched?.designation && errors?.designation
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputCom
                      labelName="Mobile"
                      inputclassName="form-control"
                      labelClassName={`form-Label`}
                      type="number"
                      name={`mobileNumber`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isRequired={true}
                      value={values.mobileNumber}
                      invalid={
                        touched?.mobileNumber && errors?.mobileNumber
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputCom
                      labelName="Email"
                      inputclassName="form-control"
                      labelClassName={`form-Label`}
                      type="email"
                      name={`email`}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let event = { ...e };
                        event.target.value = e.target.value.toLocaleLowerCase();
                        handleChange(event);
                      }}
                      isRequired={true}
                      value={values.email}
                      invalid={touched?.email && errors?.email}
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputCom
                      labelName="Alternate Number"
                      inputclassName={`form-control`}
                      labelClassName={`form-Label`}
                      type="number"
                      name={`alternateNumber`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isRequired={false}
                      value={values.alternateNumber}
                      invalid={
                        touched?.alternateNumber && errors?.alternateNumber
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputCom
                      labelName="SME Number"
                      inputclassName={`form-control`}
                      labelClassName={`form-Label`}
                      type="text"
                      name={`smeNo`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isRequired={true}
                      value={values.smeNo}
                      invalid={touched.smeNo && errors.smeNo ? true : false}
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="space-100">
                      <InputCom
                        labelName="Gst Number"
                        inputclassName={`form-control`}
                        labelClassName={`form-Label`}
                        type="text"
                        name={`gstNo`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isRequired={true}
                        value={values.gstNo}
                        invalid={touched?.gstNo && errors?.gstNo ? true : false}
                      />
                    </div>
                  </Col>

                  <React.Fragment>
                    {values.id != 0 && values.gstDocId == "" && (
                      <Col lg={6} className="mb-3">
                        <div className="space-100">
                          <InputCom
                            labelName="Gst Document"
                            inputclassName={`form-control`}
                            labelClassName={`form-Label`}
                            type="file"
                            name={`gstDocument`}
                            onBlur={handleBlur}
                            onChange={(e) => handleFileChange(e, "gstDocument")}
                            isRequired={false}
                            value={values.gstDocument}
                            invalid={
                              touched?.gstDocument && errors?.gstDocument
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                    )}
                    {values.id != 0 && values.gstDocId != "" && (
                      <Col lg={6} className="mb-3">
                        <label htmlFor="" style={{ marginBottom: "-5px" }}>
                          GST Document
                        </label>
                        <div
                          className="Aadhar_details_preview"
                          id="Aadhar_details_preview"
                        >
                          <span className="file_name">
                            {values.gstfilename}
                          </span>
                          <span className="trash_icon">
                            <FeatherIcon
                              onClick={() => removegstFile(values.gstDocId)}
                              icon="trash-2"
                              className={`icon-sm right-icon pointer`}
                            />
                          </span>
                        </div>
                      </Col>
                    )}
                    {values.id != 0 && values.smeDocId == "" && (
                      <Col lg={6} className="mb-3">
                        <div className="space-100">
                          <InputCom
                            labelName="SME No Document"
                            inputclassName={`form-control`}
                            labelClassName={`form-Label`}
                            type="file"
                            name={`smeDocument`}
                            onBlur={handleBlur}
                            onChange={(e) => handleFileChange(e, "smeDocument")}
                            isRequired={false}
                            value={values.smeDocument}
                            invalid={
                              touched?.smeDocument && errors?.smeDocument
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                    )}

                    {values.id != 0 && values.smeDocId != "" && (
                      <Col lg={6} className="mb-3">
                        <label htmlFor="" style={{ marginBottom: "-5px" }}>
                          SME Document
                        </label>
                        <div
                          className="Aadhar_details_preview"
                          id="Aadhar_details_preview"
                        >
                          <span className="file_name">
                            {values.smefilename}
                          </span>
                          <span className="trash_icon">
                            <FeatherIcon
                              onClick={() => removesmeFile(values.smeDocId)}
                              icon="trash-2"
                              className={`icon-sm right-icon pointer`}
                            />
                          </span>
                        </div>{" "}
                      </Col>
                    )}
                  </React.Fragment>

                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      onClick={() => {
                        if (!values.id) {
                          setContactDetailFalse(false);
                          setOpenModal(false);
                        } else {
                          let payload = {
                            id: values.id,
                          };
                          dispatch(deleteSupplierContact(payload));
                          // dispatch(getSupplierContactDetails(id));
                          setOpenModal(false);
                          setCanRedirect(false);
                        }
                      }}
                      className="btn-danger me-2"
                      label={"Delete"}
                      type="button"
                    />
                    <Button
                      className="btn-primary me-2"
                      label={"Submit"}
                      type="submit"
                    />
                  </div>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

  //get documet by using supplier id
  useEffect(() => {
    setTimeout(() => {
      contactDetails?.forEach((element: any) => {
        dispatch(supplierDocumentGetLoading(element?.id));
      });
    }, 1500);
  }, [contactDetails]);


  const [uniqueLinks, setUniqueLinks] = useState<any>([]);


  useEffect(() => {
    const uniqueDocumentLinks = documentLink.filter(
      (item: any, index: any, self: any) =>
        index === self.findIndex((obj: any) => obj.id === item.id)
    );
    setUniqueLinks(uniqueDocumentLinks);
  }, [documentLink]);

  const DocumentIdCall = (id: any) => { };

  const gstDocumentTemplate = (rowData: any) => {
    let link = uniqueLinks?.find(
      (item: any) => item.id == rowData.gstId

    )?.link;
    return (
      <>
        {rowData.gstId && !link ? (
          <Skeleton height="2rem" width="2rem" className="mb-2"></Skeleton>
        ) : rowData.gstId && link ? (
          <Link to={link} target="_blank">
            <FeatherIcon
              icon={"file-text"}
              onClick={() => DocumentIdCall(rowData.gstId)}
            />
          </Link>
        ) : (
          <div className="">-</div>
        )}
      </>
    );
  };
  const smeDocumentTemplate = (rowData: any) => {
    let link = uniqueLinks?.find(
      (item: any) => item.id == rowData.smeId
    )?.link;
    return (
      <>
        {rowData.smeId && !link ? (
          <Skeleton height="2rem" width="2rem" className="mb-2"></Skeleton>
        ) : rowData.smeId && link ? (
          <Link to={link} target="_blank">
            <FeatherIcon
              icon={"file-text"}
              onClick={() => DocumentIdCall(rowData.smeId)}
            />
          </Link>
        ) : (
          <div className="">-</div>
        )}
      </>
    );
  };

  const column: Columns[] = [
    {
      field: "name",
      sortable: false,
      header: "Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "designation",
      sortable: false,
      header: "Designation",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "mobileNumber",
      sortable: false,
      header: "Mobile",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "email",
      sortable: false,
      header: "Email",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "alternateNumber",
      sortable: false,
      header: "Alternate Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "gstNo",
      sortable: false,
      header: "GST No",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "smeNo",
      sortable: false,
      header: "SME No",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "gstDocument",
      sortable: false,
      header: "Gst Document",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => gstDocumentTemplate(rowData),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "smeDocument",
      sortable: false,
      header: "SME Document",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => smeDocumentTemplate(rowData),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "edit",
      sortable: false,
      header: "Action",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => editButtonTemplate(rowData),
      isDefault: true,
      isStatic: false,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(column.filter((eachColumn: Columns) => eachColumn.isDefault));


  const [combinedSupplierDocuments, setCombinedSupplierDocuments] = useState<any>([]);

  // useEffect(() => {
  //   debugger
  //   const updatedSupplierDocuments = [...combinedSupplierDocuments];
  //   supplierDocumentList.forEach((document: any) => {
  //     const existingDocumentIndex = updatedSupplierDocuments.findIndex(
  //       (existingDoc: any) => existingDoc.id === document.id
  //     );
  //     if (existingDocumentIndex === -1) {
  //       updatedSupplierDocuments.push(document);
  //     }
  //   });
  //  
  //   setCombinedSupplierDocuments(updatedSupplierDocuments);
  // }, [supplierDocumentList]);


  // useEffect(() => {
  //   const mergedSupplierDetails = contactDetails.map((contact: any) => {

  //     const supplierDoc = combinedSupplierDocuments.filter(
  //       (doc: any) => doc.sourceId == contact.id
  //     );
  //     if (supplierDoc) {
  //       let data = {
  //         gstDocument: "",
  //         gstfilename: "",
  //         smeDocument: "",
  //         smefilename: "",
  //       };
  //       supplierDoc.forEach((doc: any) => {
  //         if (doc.fileType === "GSTDOCUMENT" && !data.gstDocument) {
  //           data.gstDocument = doc.id;
  //           data.gstfilename = doc.name;
  //         } else if (doc.fileType === "SMEDOCUMENT" && !data.smeDocument) {
  //           data.smeDocument = doc.id;
  //           data.smefilename = doc.name;
  //         }
  //       });
  //       return {
  //         ...contact,
  //         ...data,
  //       };

  //     } else {
  //       return { ...contact, gstDocument: "", smeDocument: "" };
  //     }
  //   });
  //   setcontactdetailvalue(mergedSupplierDetails);
  //   dispatch(resetSuplierContact())
  // }, [combinedSupplierDocuments]);



  // useEffect(() => {
  //   contactdetailvalue?.map((val: any) => {
  //     if (val.gstDocument != "") {
  //       dispatch(supplierLinkGetLoading(val.gstDocument, val.id));
  //     }
  //     if (val.smeDocument != "") {
  //       dispatch(supplierLinkGetLoading(val.smeDocument, val.id));
  //     }
  //   });
  // }, [contactdetailvalue]);

  useEffect(() => {
    debugger;
    const result = contactDetails.map((contact: any) => {
      const gstDoc = supplierDocumentList.find((doc: any) => doc.sourceId == contact.id && doc.fileType === "GSTDOCUMENT");
      const smeDoc = supplierDocumentList.find((doc: any) => doc.sourceId == contact.id && doc.fileType === "SMEDOCUMENT");

      return {
        ...contact,
        "gstId": gstDoc ? gstDoc.id : null,
        "gstfilename": gstDoc ? gstDoc.name : null,
        "smeId": smeDoc ? smeDoc.id : null,
        "smefilename": smeDoc ? smeDoc.name : null,
      };
    });
    result?.forEach((val: any) => {
      if (val.gstId != null) {
        dispatch(supplierLinkGetLoading(val.gstId, val.id));
      }
      if (val.smeId != null) {
        dispatch(supplierLinkGetLoading(val.smeId, val.id));
      }
    });
    setcontactdetailvalue(result);
  }, [supplierDocumentList, contactDetails]);



  useEffect(() => {
    setVisibleColumns(
      column.filter((eachColumn: Columns) => eachColumn.isDefault)
    );
  }, [uniqueLinks, documentLink]);

  const handleChangeAddressField = (value: any, event: any,) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      if (value) {
        dispatch(supplierLatLagLoading(value));
      }
    }
  };


  return (
    <div id="view-dealer">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeIndex();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {visible && dialogContainer(error)}
      {openModal && showDialog()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Supplier"
            titlePath={ROUTER.SUPPLIER_GRID}
            breadcrumbItem={id ? "Edit Supplier" : "Create Supplier"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={
                      id ? validationSchemaForEdit : validationSchema
                    }
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldTouched,
                      setFieldValue,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Company Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="companyName"
                                onBlur={handleBlur}
                                value={values.companyName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["companyName"] &&
                                    errors["companyName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e) => {
                                  let event = { ...e };
                                  event.target.value = e.target.value.toLocaleLowerCase();
                                  handleChange(event);
                                }}
                                isRequired={true}
                                invalid={
                                  touched["email"] && errors["email"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Mobile"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="mobileNumber"
                                onBlur={handleBlur}
                                value={values.mobileNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["mobileNumber"] &&
                                    errors["mobileNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Gst Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="gstNumber"
                                onBlur={handleBlur}
                                value={values.gstNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["gstNumber"] && errors["gstNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Iec Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="iecNumber"
                                onBlur={handleBlur}
                                value={values.iecNumber}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["iecNumber"] && errors["iecNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={6} className="mb-2"></Col>
                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Account Information"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="accountInformation"
                                onBlur={handleBlur}
                                value={values.accountInformation}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["accountInformation"] &&
                                    errors["accountInformation"]
                                    ? true
                                    : false
                                }
                              /> */}

                              <TextEditor
                                onChange={handleChange}
                                labelName='Account Information'
                                name='accountInformation'
                                required={id ? false : true}
                                value={values.accountInformation}
                                invalid={touched["accountInformation"] &&
                                  errors["accountInformation"]
                                  ? true
                                  : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>

                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Terms & Conditions"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="termsAndConditions"
                                onBlur={handleBlur}
                                value={values.termsAndConditions}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["termsAndConditions"] &&
                                    errors["termsAndConditions"]
                                    ? true
                                    : false
                                }
                              /> */}
                              <TextEditor
                                onChange={handleChange}
                                labelName='Terms & Conditions'
                                name='termsAndConditions'
                                required={true}
                                value={values.termsAndConditions}
                                invalid={touched["termsAndConditions"] &&
                                  errors["termsAndConditions"]
                                  ? true
                                  : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>
                            <Col lg={12} className="mb-2">
                              <InputCom
                                labelName="Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="address"
                                // onBlur={(e: any) => { handleBlur(e); if (values.address) { dispatch(supplierLatLagLoading(values.address)) } }}
                                onBlur={handleBlur}
                                onKeyDown={(e: any) => handleChangeAddressField(e.target.value, e)}
                                value={values.address}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["address"] && errors["address"]
                                    ? true
                                    : false
                                }
                              />
                              <p style={{ fontSize: "12px" }}>Please press the Enter key after entering the address.</p>
                              {supplierLatLng &&
                                <iframe src={`https://maps.google.com/maps?q=${supplierLatLng.lat},${supplierLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                              {/* <AddressMapFields address={address} setAddress={setAddress} lat={latitude} lng={longitude} title="Address" ></AddressMapFields> */}
                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={formValue?.address ? formValue?.address : ""} title="Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}
                            </Col>

                            <Row>
                              <Col lg={2} md={3} sm={3} xs={6}>
                                <CheckboxInput
                                  name="detailsVerified"
                                  label="Details Verified"
                                  value={values["detailsVerified"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>

                              <Col lg={2} className="mb-2">
                                <CheckboxInput
                                  name="sendEmail"
                                  label="Send Email"
                                  value={values["sendEmail"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} className="mb-2">
                                <CheckboxInput
                                  name="sendSMS"
                                  label="Send Sms"
                                  value={values["sendSMS"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={2} className="mb-2">
                                <CheckboxInput
                                  name="sendWhatsapp"
                                  label="Whatsapp"
                                  value={values["sendWhatsapp"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                            </Row>
                          </Row>
                          {id && (
                            <React.Fragment>
                              <div className="mt-1">
                                <h5>Image</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {selectedFiles.length == 0 &&
                                        !supplierImage && (
                                          <Dropzone
                                            onDrop={(acceptedFiles) => {
                                              handleAcceptedFiles(
                                                acceptedFiles
                                              );
                                            }}
                                            onError={(err: Error) => {

                                            }}
                                            onDropRejected={(
                                              fileRejections,
                                              event
                                            ) =>
                                              onDropRejectedFileHandler(
                                                fileRejections,
                                                event,
                                                showToast
                                              )
                                            }
                                            maxFiles={1}
                                          >
                                            {({
                                              getRootProps,
                                              getInputProps,
                                            }) => (
                                              <div className="dropzone">
                                                <div
                                                  className="dz-message needsclick"
                                                  {...getRootProps()}
                                                >
                                                  <input
                                                    {...getInputProps()}
                                                    accept="image/*"
                                                  />
                                                  <div className="">
                                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                                  </div>
                                                  <h4>
                                                    Drop files here or click to
                                                    upload.
                                                  </h4>
                                                </div>
                                              </div>
                                            )}
                                          </Dropzone>
                                        )}

                                      {(selectedFiles[0]?.preview ||
                                        supplierImage) && (
                                          <ImagePreviewer
                                            selectedFile={
                                              selectedFiles[0]?.preview
                                                ? selectedFiles[0]?.preview
                                                : supplierImage
                                            }
                                            removeIndex={
                                              deleteConfirmationHandler
                                            }
                                          />
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>
                          )}

                          {id && (
                            <Row>
                              <hr />
                              <Col
                                lg={12}
                                className="dealer-title-header d-flex justify-content-between align-items-center"
                              >
                                <div>Contact Details</div>
                                <div
                                  className="add-btn-container"
                                  onClick={handleAddDynamic}
                                >
                                  <TooltipMEL
                                    title="Add Contact Details"
                                    position="bottom"
                                  >
                                    <i className="fas fa-plus-circle"></i>
                                    <span>Add</span>
                                  </TooltipMEL>
                                </div>
                              </Col>
                              {/* {
                              !dynamicForm && <div className="add-btn-container" onClick={handleAddDynamic}>
                                <TooltipMEL title="Add Contact Details" position='bottom'>
                                  <i className="fas fa-plus-circle"></i><span>Add</span>
                                </TooltipMEL>
                              </div>
                            } */}

                              {contactdetailvalue?.length > 0 && (
                                <DataTableWrapper
                                  columnDetails={visibleColumns}
                                  value={contactdetailvalue}
                                  loading={loading}
                                ></DataTableWrapper>
                              )}
                            </Row>
                          )}
                          <div className="d-flex justify-content-end mt-2">
                            {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position="top"> */}
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            {/* </TooltipMEL> */}
                            {/* <TooltipMEL title={id ? "Update Supplier" : "Create Supplier"} position="top"> */}
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Supplier" : "Create Supplier"}
                              type="submit"
                            />
                            {/* </TooltipMEL> */}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddEditSupplier;
