import React from "react";
import { Column, ColumnProps } from "primereact/column";
import { CustomColumnProps } from "src/models/components/columnWrapperModel";



const ColumnWrapper: React.FC<CustomColumnProps> = (props: CustomColumnProps)  => {
  const { sortableColumn, ...rest } = props; 
  return (
    <>
      <Column {...rest}></Column>
    </>
  )
}

export default ColumnWrapper;