import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux';
import InputCom from 'src/components/Common/Input';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import { formatDateTOYYYYMMDD } from 'src/helpers/common_helpers';
import { updatePaymentLoading } from 'src/store/IncentiveReward/action';
import * as Yup from 'yup';

const UpdatePaymentDetail = ({details,id}:any) => {
  const dispatch=useDispatch()

    const initialvalue={
        paymentDescription:details?.paymentDescription ? JSON.parse(JSON.stringify(details?.paymentDescription)) : "",
        paymentDate:details?.paymentDate ? formatDateTOYYYYMMDD(JSON.parse(JSON.stringify(details?.paymentDate))) : "",
        isPaid:details?.isPaid ? JSON.parse(JSON.stringify(details?.isPaid)) : false
    }

    const handleSubmit=(values:any)=>{
      dispatch(updatePaymentLoading(values,id))
    }

    const validationSchema=Yup.object().shape({
      isPaid: Yup.boolean(),
      paymentDescription: Yup.string().test(
        'is-required-when-paid',
        'Payment Description is required',
        function (value) {
          const { isPaid } = this.parent;
          if (isPaid) {
            return !!value; 
          }
          return true; 
        }
      ),
      paymentDate: Yup.string().test(
        'is-required-when-paid',
        'Payment Date is required',
        function (value) {
          const { isPaid } = this.parent;
          if (isPaid) {
            return !!value; 
          }
          return true; 
        }
      ),
    })

    const handleReset = (setFieldValue: any) => {
        setFieldValue('paymentDescription',details?.paymentDescription)
        setFieldValue('paymentDate',details?.paymentDate)
        setFieldValue('isPaid',details?.isPaid)
      }

      const handleValue=(value:any,setFieldValue:any)=>{
        if(!value){
          setFieldValue('paymentDescription','')
          setFieldValue('paymentDate','')
        }
      }

  return (

      <React.Fragment>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialvalue}
          validationSchema={validationSchema}
          enableReinitialize>
          {({ values, handleChange, touched, errors, handleBlur, resetForm, reset,setFieldValue, ...rest }: any) => {
            return (
              <Form onChange={handleChange}>
                <div className="grid-container">

                  <div className="grid-item">
                    <InputCom labelName="Payment Date"
                      inputclassName="form-control"
                      labelClassName="form-Label"
                      type="date"
                      name="paymentDate"
                      onBlur={handleBlur}
                      value={values.paymentDate}
                      onChange={handleChange}
                      isRequired={true}
                      invalid={touched["paymentDate"] && errors["paymentDate"] ? true : false}
                    />

                  </div>
                  <div className="grid-item">
                    <InputCom labelName="Payment Description"
                      inputclassName="form-control"
                      labelClassName="form-Label"
                      type="textarea"
                      name="paymentDescription"
                      onBlur={handleBlur}
                      value={values.paymentDescription}
                      onChange={handleChange}
                      isRequired={true}
                      invalid={touched["paymentDescription"] && errors["paymentDescription"] ? true : false}
                    />

                  </div>


                 
                    <div className="grid-item justify-new">
                      <CheckboxInput
                        name="isPaid"
                        label="Paid"
                        value={values["isPaid"]}
                        onChange={(e)=>{handleChange(e); handleValue(e.checked,setFieldValue)}}
                        {...rest}
                      />
                    </div>
                    <div className="grid-item justify">
                    <Button
                      className="secondary-btn me-2"
                      label="Reset"
                      type="reset"
                      onClick={() => handleReset(setFieldValue)}
                    />
                    <Button
                      className="btn-primary me-2 btn-primary-shadow"
                      label="Update"
                      type="submit"
                    />
                   
     </div>
                </div>


              </Form>
            )
          }}
        </Formik>
      </React.Fragment>

  )
}

export default UpdatePaymentDetail