import { PartsInboundTypes } from "./acionTypes";

export const getPartsInboundList = (lazyState: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_INBOUND_LIST,
    lazyState,
  };
};

export const getPartsInboundById = (id: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_INBOUND_BY_ID,
    id: id,
  };
};
export const getPartsInboundByIdSuccess = (payload: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_INBOUND_BY_ID_SUCCESS,
    payload: payload,
  };
};
export const getPartsInboundListSuccess = (payload: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_INBOUND_LIST_SUCCESS,
    payload: payload,
  };
};

export const getPartsInboundListError = (error: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_INBOUND_LIST_FAIL,
    payload: error,
  };
};


export const deletePartsInboundFromList = (payload: any) => {
  return {
    type: PartsInboundTypes.DELETE_PARTS_INBOUND,
    payload: payload,
  }
}

export const deletePartsInboundFromListSuccess = (response: any, undo: boolean) => {
  return {
    type: PartsInboundTypes.DELETE_PARTS_INBOUND_SUCCESS,
    payload: response,
    undo
  }
}

export const deletePartsInboundFromListError = (error: any) => {
  return {
    type: PartsInboundTypes.DELETE_PARTS_INBOUND_FAIL,
    payload: error,
  }
}

export const updatePartsInbound = (PARTSINBOUNDDetails: any) => {
  return {
    type: PartsInboundTypes.UPDATE_PARTS_INBOUND,
    payload: PARTSINBOUNDDetails
  }
}


export const updatePartsInboundSuccess = (data: any) => {
  return {
    type: PartsInboundTypes.UPDATE_PARTS_INBOUND_SUCCESS
  }
}

export const updatePartsInboundError = (error: any) => {
  return {
    type: PartsInboundTypes.UPDATE_PARTS_INBOUND_ERROR,
    payload: error
  }
}
export const createPartsInbound = (data: any) => {

  return {
    type: PartsInboundTypes.CREATE_PARTS_INBOUND,
    payload: data
  }
}
export const createPartsInboundSuccess = (data: any) => {
  return {
    type: PartsInboundTypes.CREATE_PARTS_INBOUND_SUCCESS
  }
}

export const createPartsInboundError = (error: any) => {
  return {
    type: PartsInboundTypes.CREATE_PARTS_INBOUND_ERROR,
    payload: error
  }
}

//reset PARTSINBOUND
export const PartsInboundReset = () => {
  return {
    type: PartsInboundTypes.RESET_PARTS_INBOUND,
  }

}


//clear part inbound message

export const ClearPartsInboundMessage = () => {
  return {
    type: PartsInboundTypes.CLEAR_PARTS_INBOUND_MESSAGE,
  }

}


export const getSupplierDropdown = () => {
  return {
    type: PartsInboundTypes.GET_SUPPLIER_DROPDOWN,
  }
}

export const getSupplierDropdownSuccess = (payload: any) => {
  return {
    type: PartsInboundTypes.GET_SUPPLIER_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getSupplierDropdownError = (err: any) => {
  return {
    type: PartsInboundTypes.GET_SUPPLIER_DROPDOWN_ERROR,
    payload: err
  }
}

export const getStockLocationDropdown = () => {
  return {
    type: PartsInboundTypes.GET_STOCK_LOCATION_DROPDOWN,
  }
}

export const getStockLocationDropdownSuccess = (payload: any) => {
  return {
    type: PartsInboundTypes.GET_STOCK_LOCATION_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getStockLocationDropdownError = (err: any) => {
  return {
    type: PartsInboundTypes.GET_STOCK_LOCATION_DROPDOWN_ERROR,
    payload: err
  }
}

export const getPurchaseOrderDropdown = () => {
  return {
    type: PartsInboundTypes.GET_PURCHASE_ORDER_DROPDOWN,
  }
}

export const getPurchaseOrderDropdownSuccess = (payload: any) => {
  return {
    type: PartsInboundTypes.GET_PURCHASE_ORDER_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getPurchaseOrderDropdownError = (err: any) => {
  return {
    type: PartsInboundTypes.GET_PURCHASE_ORDER_DROPDOWN_ERROR,
    payload: err
  }
}

export const getPartsDropdown = () => {
  return {
    type: PartsInboundTypes.GET_PARTS_DROPDOWN,
  }
}

export const getPartsDropdownSuccess = (payload: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_DROPDOWN_SUCCESS,
    payload: payload
  }
}
export const getPartsDropdownError = (err: any) => {
  return {
    type: PartsInboundTypes.GET_PARTS_DROPDOWN_ERROR,
    payload: err
  }
}

//parst inbound sku generated

export const partsinboundSkugeneratedLoading=(partsSkuDetails : any)=>{
  return {
    type: PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_LOADING,
    payload : partsSkuDetails
  }
} 
export const partsinboundSkugeneratedSuccess=(response : any)=>{
  return {
    type: PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_SUCCESS,
    payload : response
  }
} 
export const partsinboundSkugeneratedError=(error : any)=>{
  return {
    type: PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_ERROR,
    payload : error
  }
} 