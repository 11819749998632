import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { ACTIVE, ALL, DELETED, LOCKED, PACKAGENOTSENT, PACKAGESENT } from 'src/constants/commonConstants';
import { Columns, LazyTableState } from "src/models/components/dataTableWrapperModel";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useToast } from 'src/components/Common/ReactToaster';
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction';
import { useNavigate } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { Button, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Select from "src/components/Common/Select";
import { Option } from "src/models/components/inputWrapperModel";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import FeatherIcon from "feather-icons-react";
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import TooltipMEL from 'src/components/UI/Tooltip';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import { clearCourierDetails, clearCourierMessage, courierListLoading, dealerListLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { formatDateMEL, formatDateTimeMEL, saveAsExcelFile } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Link } from 'react-router-dom';
import { API } from 'src/util/api';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';
import { courierList } from 'src/models/pages/couriersModel';


const courierListConverson = (values: any) => {
  const modifeidValue = values?.items?.map((eachValue: any) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['companyName'] = eachValue?.companyName;
    data['otherCompany'] = eachValue?.otherCompany;
    data['PackageSenderName'] = eachValue?.packageSenderName;
    data['packageReceiverName'] = eachValue?.packageReceiverName;
    data['creditReferenceNumber'] = eachValue?.creditReferenceNumber;
    data['declaredValue'] = eachValue?.declaredValue;
    data['isToPayCustomer'] = eachValue?.isToPayCustomer;
    data['otherCompany'] = eachValue?.otherCompany;
    data['packageReceiver'] = eachValue?.packageReceiver;
    data['packageSentAt'] = eachValue?.packageSentAt;
    data['packageSender'] = eachValue?.packageSender;
    data['pickupRequestAt'] = eachValue?.pickupRequestAt;
    data['productCode'] = eachValue?.productCode;
    data['shipmentWeight'] = eachValue?.shipmentWeight;
    data['tokenNumber'] = eachValue?.tokenNumber;
    data['awbNumber'] = eachValue?.awbNumber;
    data["packageSendeName"] = eachValue?.packageSenderId;
    data["packageReceiverr"] = eachValue?.packageReceiverId;
    data['senderUserType'] = eachValue?.senderUserType;
    data['receiverUserType'] = eachValue?.receiverUserType;
    return data;
  });
  return modifeidValue;
};

const CouriersList = () => {

  const filterDetails: DataTableFilterMeta = {
    IsDeleted: { value: "false", matchMode: "contains" },
    PackageSenderName: { value: "", matchMode: "contains" },
    packageReceiverName: { value: "", matchMode: "contains" },
    CreditReferenceNumber: { value: "", matchMode: "contains" },
    ProductCode: { value: "", matchMode: "contains" },
    awbNumber: { value: "", matchMode: "contains" },
    companyName: { value: "", matchMode: "contains" },
    packageSentAt: { value: "", matchMode: "contains" },
    PackageSentAtStartDate: { value: "", matchMode: "contains" },
    PackageSentAtEndDate: { value: "", matchMode: "contains" },
    pickupRequestAt: { value: "", matchMode: "contains" },
    PickupRequestAtStartDate: { value: "", matchMode: "contains" },
    PickupRequestAtEndDate: { value: "", matchMode: "contains" },
    tokenNumber: { value: "", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStatus, setselectedStatus] = useState("all");
  const [rowData, setRowData] = useState<any | null>(null);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCourier, setSelectedCourier] = useState<any[]>([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const dt = useRef<DataTable<any>>(null);
  const { totalCount } = useSelector((state: RootReducerState) => state.courierReducer.courierList);


  const navigate = useNavigate();

  const onCreateClickHandler = () => {
    navigate(ROUTER.CREATE_COURIER);
  };

  useEffect(() => {
    dispatch(dealerListLoading());
    dispatch(clearCourierDetails());
  }, []);



  const { loading, error, message, courierList, dealerList } = useSelector((state: RootReducerState) => state.courierReducer);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const modifiedValues: any[] = courierListConverson(courierList);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const viewHandler = (rowData: courierList) => {
    navigate(`${ROUTER.VIEW_COURIER}/${rowData["id"]}`);
  };
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearCourierMessage());
    }
  }, [error, message]);
  const editHandler = (rowData: courierList) => {
    navigate(`${ROUTER.EDIT_COURIER}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: courierList) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  }, [dealerList]);

  const actionBodyTemplate = (rowData: courierList, value: string) => {
    return (
      <>
        {rowData.packageSentAt ? (
          <ColumnAction
            displayData={rowData[value] as string}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
          />
        ) : (
          <ColumnAction
            displayData={rowData[value] as string}
            isView={true}
            isEdit={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
          />
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData: courierList, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );
  };
  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date"
        required
        value={options?.value?.startDate || ""}
        max={options?.value?.endDate ? options?.value?.endDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['startDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input
        type="date"
        required
        value={options?.value?.endDate || ""}
        min={options?.value?.startDate ? options?.value?.startDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['endDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
    </>);
  };

  const dealer = dealerList?.filter((item: any) => item.isDeleted !== true);
  const dealerDropdown = dealer?.map((item: any) => ({
    label: item.name,
    value: item.userId
  }));


  const packageSenderFilter = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(dealerDropdown?.length > 0) ? (dealerDropdown?.map((ealer: any) => {
          let data: any = {};
          data['label'] = ealer?.label;
          data['value'] = ealer?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const moveDealerPage = (rowData: any, field: string) => {
    if (rowData[field] == "Mount Electronics" || rowData.receiverUserType == "string" || !rowData.senderUserType || rowData["packageSendeName"] == 0) {
      return (rowData[field] as string ? rowData[field] as string : "-");
    } else {
      let senderUrl = "";
      if (rowData.senderUserType == "employee") {
        senderUrl = ROUTER.USERS_VIEW;
      }
      if (rowData.senderUserType == "dealer") {
        senderUrl = ROUTER.DEALERS_VIEW;
      }
      if (rowData.senderUserType == "customer") {
        senderUrl = ROUTER.CUSTOMERS_VIEW;
      }
      if (rowData.senderUserType == "franchise") {
        senderUrl = ROUTER.FRANCHISEE_VIEW;
      }
      return (
        <>
          {
            rowData[field] ? <Link to={`${senderUrl}/${rowData["packageSendeName"]}`}>{rowData[field] as string ? rowData[field] as string : "-"}</Link> : ""
          }
        </>
      );
    }
  };

  const moveReceiverPage = (rowData: any, field: string) => {
    if (rowData[field] == "Mount Electronics" || rowData.receiverUserType == "string" || !rowData.receiverUserType || rowData["packageReceiverr"] == 0) {
      return (rowData[field] as string ? rowData[field] as string : "-");
    } else {


      let receiverUrl = "";
      if (rowData.receiverUserType == "employee") {
        receiverUrl = ROUTER.USERS_VIEW;
      }
      if (rowData.receiverUserType == "dealer") {
        receiverUrl = ROUTER.DEALERS_VIEW;
      }
      if (rowData.receiverUserType == "customer") {
        receiverUrl = ROUTER.CUSTOMERS_VIEW;
      }
      if (rowData.receiverUserType == "franchise") {
        receiverUrl = ROUTER.FRANCHISEE_VIEW;
      }

      return (
        <>
          {
            rowData[field] ? <Link to={`${receiverUrl}/${rowData["packageReceiverr"]}`}>{rowData[field] as string ? rowData[field] as string : "-"}</Link> : ""
          }

        </>
      );
    }
  };


  const columns: Columns[] = [
    {
      field: "companyName",
      sortable: false,
      header: "Company",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "companyName"),
      isDefault: true,
      frozen: true,
      isStatic: true,
    },
    {
      field: "awbNumber",
      sortable: true,
      header: "Awb Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: false,
    },
    {
      field: "otherCompany",
      sortable: true,
      header: "Other Company",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PackageSenderName",
      sortable: true,
      header: "Package Sender",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => moveDealerPage(rowData, "PackageSenderName"),
      isDefault: true,
      isStatic: false,
      // filterElement: packageSenderFilter
    },

    {
      field: "packageReceiverName",
      sortable: true,
      header: "Package Receiver",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => moveReceiverPage(rowData, "packageReceiverName"),
      isDefault: true,
      isStatic: false,
      // filterElement: packageSenderFilter
    },
    {
      field: "pickupRequestAt",
      sortable: true,
      header: "Pickup Request At",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "100px" },
      body: (rowData) => formatDateTimeMEL(rowData.pickupRequestAt),
      isDefault: true,
      isStatic: false,
      // className: "align-right pr-2",
      // tooltip: true,
      // tooltipTitle:"No of Items",
      filterElement: dateFilterTemplate
    },
    {
      field: "packageSentAt",
      sortable: true,
      header: "Package Send At",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateTimeMEL(rowData.packageSentAt),
      isDefault: true,
      isStatic: false,
      filterElement: dateFilterTemplate

    },
    {
      field: "creditReferenceNumber",
      sortable: true,
      header: "Credit Reference Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "productCode",
      sortable: true,
      header: "Product Code",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "shipmentWeight",
      sortable: true,
      header: "Shipment Weight",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      className: "align-right pr-2",
      isStatic: false,
    },
    {
      field: "declaredValue",
      sortable: true,
      header: "Declared Value",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      className: "align-right pr-2",
      isStatic: false,
    },

    {
      field: "tokenNumber",
      sortable: true,
      header: "Token Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      className: "align-right pr-2",
      isStatic: false,
    },
    {
      field: "isToPayCustomer",
      sortable: true,
      header: "Is To Pay Customer",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'isToPayCustomer'),
      isDefault: true,
      isStatic: false,
    }
  ];

  const options: Option[] = [
    // { label: "Active", value: "Active" },
    // { label: "Deleted", value: "Deleted" },
    { label: "All", value: "all" },
    { label: "Package Sent", value: "packageSent" },
    { label: "Package Not Sent", value: "packageNotSent" },
  ];


  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const dispatch = useDispatch();
  const { showToast } = useToast();

  const hidePopup = () => {
    setVisible(false);

  };

  const defaultColumns = {
    ...columns[0]
  };

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    // dispatch(deletePurchaseOrderFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedCourier([]);
    }
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedCourier([]);
    setArchiveModal(false);
  };

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible} onHide={hidePopup} />);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const initialValues = {
    status: "",
  };

  const validationSchema = Yup.object({});

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == PACKAGESENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          All: { value: "false", matchMode: "contains" },
          IsDeleted: { value: "", matchMode: "contains" },
          packageSent: { value: "true", matchMode: "contains" },
          packageNotSent: { value: "false", matchMode: "contains" },

        },
      }));
    } else if (formValue == PACKAGENOTSENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          All: { value: "false", matchMode: "contains" },
          IsDeleted: { value: "", matchMode: "contains" },
          // Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "true", matchMode: "contains" },
          packageSent: { value: "false", matchMode: "contains" },
        },
      }));
    } else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          All: { value: "", matchMode: "contains" },
          IsDeleted: { value: "", matchMode: "contains" },
          // Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "", matchMode: "contains" },
          packageSent: { value: "", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          packageSent: { value: null, matchMode: "contains" },
          packageNotSent: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const handleStatusBlur = () => { };

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedCourier(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedCourier([]);
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      // data['id'] = eachValue?.id;
      data['companyName'] = eachValue?.companyName;
      data['otherCompany'] = eachValue?.otherCompany;
      data['PackageSenderName'] = eachValue?.PackageSenderName;
      data['packageReceiverName'] = eachValue?.packageReceiverName;
      data['creditReferenceNumber'] = eachValue?.creditReferenceNumber;
      data['declaredValue'] = eachValue?.declaredValue;
      data['isToPayCustomer'] = eachValue?.isToPayCustomer;
      data['otherCompany'] = eachValue?.otherCompany;
      data['packageSentAt'] = formatDateMEL(eachValue?.packageSentAt);
      data['pickupRequestAt'] = formatDateMEL(eachValue?.pickupRequestAt);
      data['productCode'] = eachValue?.productCode;
      data['shipmentWeight'] = eachValue?.shipmentWeight;
      data['tokenNumber'] = eachValue?.tokenNumber;
      data['awbNumber'] = eachValue?.awbNumber;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        companyName: data.companyName,
        otherCompany: data.otherCompany,
        PackageSenderName: data.PackageSenderName,
        packageReceiverName: data?.packageReceiverName,
        pickupRequestAt: formatDateMEL(data?.pickupRequestAt),
        packageSentAt: formatDateMEL(data.packageSentAt),
        creditReferenceNumber: data.creditReferenceNumber,
        productCode: data.productCode,
        shipmentWeight: data.shipmentWeight,
        declaredValue: data.declaredValue,
        awbNumber: data.awbNumber,
        tokenNumber: data.tokenNumber,
        isToPayCustomer: data.isToPayCustomer,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: modifiedValues,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalCount > 5000 ? 5000 : totalCount < 1 ? 1 : totalCount,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getCourierListApi(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['companyName'] = eachValue?.companyName;
        data['otherCompany'] = eachValue?.otherCompany;
        data['PackageSenderName'] = eachValue?.packageSenderName;
        data['packageReceiverName'] = eachValue?.packageReceiverName;
        data['creditReferenceNumber'] = eachValue?.creditReferenceNumber;
        data['declaredValue'] = eachValue?.declaredValue;
        data['isToPayCustomer'] = eachValue?.isToPayCustomer;
        data['otherCompany'] = eachValue?.otherCompany;
        data['packageReceiver'] = eachValue?.packageReceiver;
        data['packageSentAt'] = eachValue?.packageSentAt;
        data['packageSender'] = eachValue?.packageSender;
        data['pickupRequestAt'] = eachValue?.pickupRequestAt;
        data['productCode'] = eachValue?.productCode;
        data['shipmentWeight'] = eachValue?.shipmentWeight;
        data['tokenNumber'] = eachValue?.tokenNumber;
        data['awbNumber'] = eachValue?.awbNumber;
        data["packageSendeName"] = eachValue?.packageSenderId;
        data["packageReceiverr"] = eachValue?.packageReceiverId;
        data['senderUserType'] = eachValue?.senderUserType;
        data['receiverUserType'] = eachValue?.receiverUserType;
        return data;
      });
      // let resp=await courierListConverson(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "couriers");
      } else {
        saveDataAsExcel(modifeidValue, "couriers");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "couriers");
    fetchData(false);

  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "couriers");
    fetchData(true);
  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const clearFilter = () => {
    setLazyState(initialLazyState);
    setselectedStatus("all");
  };

  const header =
    <div className="table-header-container">
      <div>
        Couriers
      </div>

      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} isFilter={true} clearFilter={clearFilter} />
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label"
              placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>
          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            New Courier
          </Button>
        </div>
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedCourier(value);
    // setSelectAll(value.length === totalRecords);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedCourier([]);
      } else if (selectedStatus == ACTIVE) {
        let len = modifiedValues?.length;
        let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        if (len === filterLen) {
          setSelectAll(true);
          setSelectedCourier(modifiedValues);
        } else {
          setSelectAll(false);
          setSelectedCourier(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        }
      }
      else {
        setSelectAll(true);
        setSelectedCourier(modifiedValues);
      }
    } else {
      setSelectAll(false);
      setSelectedCourier([]);
    }
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.pickupRequestAt) {
      modifiedLazyState.filters.pickupRequestAt.value = "";
    }
    if (modifiedLazyState.filters && modifiedLazyState.filters.packageSentAt) {
      modifiedLazyState.filters.packageSentAt.value = "";
    }
    dispatch(courierListLoading(modifiedLazyState));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedCourier([]);
    callbackFunction(lazyState);
    return () => {
      dispatch(clearCourierMessage());
    };
  }, [lazyState]);

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['PickupRequestAtStartDate']['value'] = event?.filters?.pickupRequestAt?.value?.startDate ? event?.filters?.pickupRequestAt?.value?.startDate : '';
    event['filters']['PickupRequestAtEndDate']['value'] = event?.filters?.pickupRequestAt?.value?.endDate ? event?.filters?.pickupRequestAt?.value?.endDate : '';
    event['filters']['PackageSentAtStartDate']['value'] = event?.filters?.packageSentAt?.value?.startDate ? event?.filters?.packageSentAt?.value?.startDate : '';
    event['filters']['PackageSentAtEndDate']['value'] = event?.filters?.packageSentAt?.value?.endDate ? event?.filters?.packageSentAt?.value?.endDate : '';
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedCourier?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedCourier([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedCourier.length > 0) {
              for (let i = 0; i < selectedCourier.length; i++) {
                handleDeleteUsers(selectedCourier[i], selectedCourier.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these purchase order?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedCourier([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Couriers  {last} records of {totalRecords} in total"
          selection={[]}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
        // rowClassName={rowClassName}
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  );
};

export default CouriersList;