import { ProfileTypes } from "./actionTypes";

export const getProfile = () => {
  return {
    type: ProfileTypes.GET_PROFILES,
  };
};

export const getProfileSuccess = (user: any) => {
  return {
    type: ProfileTypes.GET_PROFILES_SUCCESS,
    payload: user,
  };
};

export const getProfileError = (error: any) => {
  return {
    type: ProfileTypes.GET_PROFILES_FAIL,
    payload: error,
  };
};
export const updateProfile = (data:any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_DETAIL,
    payload:data
  };
};

export const updateProfileSuccess = (user: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_DETAIL_SUCCESS,
    payload: user,
  };
};

export const UpdateProfileError = (error: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_DETAIL_FAIL,
    payload: error,
  };
};

export const clearProfileMessage = () => {
  return{
    type:ProfileTypes.CLEAR_PROFILE_MESSAGE
  }
}

export const uploadProfilePictureLoading = (file: any, id: any,user:any) => {
  
  
  return {
    type: ProfileTypes.UPDATE_PROFILE_PICTURE,
    payload: file,
    id: id,
    user:user
  }
}


export const uploaProfilePictureSuccess = (customer: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_PICTURE_SUCCESS,
  }
}

export const uploaProfilePictureError = (error: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_PICTURE_FAIL,
    payload: error
  }
}



// get Employee image

export const getProfilePictureLoading = (id: any,user:any) => {
  return {
    type: ProfileTypes.GET_PROFILE_PICTURE,
    id: id,
    user:user
  };
}

export const getProfilePictureSuccess = (customerDetails: any) => {
  return {
    type: ProfileTypes.GET_PROFILE_PICTURE_SUCCESS,
    payload: customerDetails,
  }
}

export const getProfilePictureError = (error: any) => {
  return {
    type: ProfileTypes.GET_PROFILE_PICTURE_FAIL,
    payload: error
  }
}


// delete customer image

export const deleteProfilePictureLoading = (id: any,user:any) => {
  return {
    type: ProfileTypes.DELETE_PROFILE_PICTURE,
    payload: id,
    user:user
  };
}

export const deleteProfilePictureSuccess = (customerDetails: any) => {
  return {
    type: ProfileTypes.DELETE_PROFILE_PICTURE_SUCCESS,
    payload: customerDetails,
  }
}

export const deleteProfilePictureError = (error: any) => {
  return {
    type: ProfileTypes.DELETE_PROFILE_PICTURE_FAIL,
    payload: error
  }
}

export const uploadProfileMobileNumberLoading = (number: any, id: any,user:any) => {
  return {
    type: ProfileTypes.SET_PROFILE_MOBILE_LOADING,
    payload: number,
    id: id,
    user:user
  }
}

export const uploadProfileMobileNumberSuccess = (resp:any) => {
  return {
    type: ProfileTypes.SET_PROFILE_MOBILE_SUCCESS,
    payload: resp,
  }
}

export const uploadProfileMobileNumberError = (error:any) => {
  return {
    type: ProfileTypes.SET_PROFILE_MOBILE_ERROR,
    payload: error,
  }
}


export const sendProfileotpLoading = (otp:any,id: any,phoneNUmber:any) => {
  return {
    type: ProfileTypes.SEND_OTP_PROFILE,
    payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
  };
}
export const sendProfileotpSuccess = (resp: any) => {
  return {
    type: ProfileTypes.SEND_OTP_PROFILE_SUCCESS,
    payload: resp,
  };
}
export const sendProfileotpError = (error: any) => {
  return {
    type: ProfileTypes.SEND_OTP_PROFILE_ERROR,
    payload: error,
  };
}
export const resendOtpProfile = (id: any,number:any) => {
  return {
    type: ProfileTypes.RESEND_PROFILE_OTP,
    payload: id,
    number:number
  };
}
export const resendOtpProfileSuccess = (resp: any) => {
  return {
    type: ProfileTypes.RESEND_PROFILE_OTP_SUCCESS,
    payload: resp,
  };
}
export const resendOtpProfileError = (resp: any) => {
  return {
    type: ProfileTypes.RESEND_PROFILE_OTP_ERROR,
    payload: resp,
  };
}

export const resetOtpProfileMessage = () => {
  return {
    type: ProfileTypes.RESET_PROFILE_OTP_MESSAGE,
  };
}