import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import TextEditor from 'src/components/atoms/TextEditor';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import DropdownInput from 'src/components/UI/DropdownInput';
import JobTooltipMEL from 'src/components/UI/JobTooltip';
import { ROUTER } from 'src/constants/routes';
import { formatDateTOYYYYMMDD } from 'src/helpers/common_helpers';
import {
  clearReceivedPaymentMessage,
  createReceivedPaymentLoading,
  getCustomerReceivedPaymentLoading,
  getDealerReceivedPaymentLoading,
  getInvoiceDetailsReceivedPaymentLoading,
  getInvoiceListCreatePageLoading,
} from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";

const CreateReceivedPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [visible, setVisible] = useState(false);
  const [newErrorvisible, setNewErrorVisible] = useState(false);
  const [remainAmount, setRemainAmount] = useState(0);
  const initialvalue = {
    recipient: "",
    customerId: "",
    dealerId: "",
    paymentDate: "",
    amount: "",
    tdsDeduction: 0,
    total: "",
    paymentNotes: "",
    paymentMode: [
      {
        paymentType: "",
        amount: "",
        tdsDeduction: 0,
        total: "",
        paymentNotes: ""
      }
    ],
    invoices: [
      {
        invoice: "",
        amount: "",
        tdsDeduction: 0,
        invoiceTotal: "",
        jobNumber: "",
        invoiceLockedDate: ""
      }
    ]
  };
  const { error, loading, message, dealerList, customerList, createInvoiceList, invoiceDetail, setInvoiceValueHandler, invoiceIndex } = useSelector((state: RootReducerState) => state.receivedPaymentReducer);

  const validationSchema = Yup.object().shape({
    recipient: Yup.string().required('Recipient is required'),
    paymentDate: Yup.string().required('Payment date is required'),
    paymentMode: Yup.array().of(
      Yup.object().shape({
        paymentType: Yup.string().required("Payment type is required").test('is-unique', 'Payment type should not be duplicate', function (value) {
          let test = this.options?.context?.paymentMode?.filter((detail: any) => detail?.paymentType === value).length;
          return test == 1;
        }),
        // amount: Yup.string().required("Amount is required"),
        amount: Yup.number().required("Amount is required").min(0.1, "must be greater than or equal to 0.1"),
        // tdsDeduction: Yup.number().required("Tds deduction is required").min(0.1,"must be greater than or equal to 0.1"),
        tdsDeduction: Yup.number().min(0, "must be greater than or equal to 0"),

      })),
    invoices: Yup.array().of(
      Yup.object().shape({
        invoice: Yup.string()
        .required("Invoice is required")
        .test('unique', 'Invoice must be unique', function (value, context) {
          const { invoices }: any = context.options.context;
          const partSkuNumbers = invoices.map((item: any) => item.invoice);
          
          const occurrences = partSkuNumbers.filter((sku: any) => sku == value).length;
          return occurrences == 1;
        }),
        amount: Yup.number()
      .required("Amount is required")
      .min(0.1, "must be greater than or equal to 0.1")
      .test('checkPaidAmount', 'Amount cannot exceed the remaining balance', function (value) {
        const { paidAmount, invoiceTotal } = this.parent; // Use this.parent to get other sibling fields
        const invoiceTotalParsed = parseFloat(invoiceTotal);
        // If no paidAmount is available, the amount should not exceed the invoiceTotal
        if (paidAmount == null) {
          return value <= invoiceTotalParsed;
        } 
        // If paidAmount exists, calculate the remaining balance and compare
        const remainingBalance =invoiceTotalParsed - paidAmount;

        if (value > remainingBalance) {
          return value < remainingBalance
        }

        return true; // Validation passes if the condition is not met
      }),
        tdsDeduction: Yup.number().min(0, "must be greater than or equal to 0"),
        invoiceTotal: Yup.string()
      })),


  }).test({
    name: 'one-field-required',
    test: function (value: any) {
      const { customerId, dealerId } = value;
      if (!customerId && !dealerId) {
        return this.createError({
          path: 'dealerId',
          message: 'Either dealer name or customer name is required',
        });
      }
      return true;
    },
  });

  const dealerDropdown = dealerList.filter((eachValue: any) => !eachValue.isDeleted)?.map((eachDealer: any) => {
    let data: any = {};
    data['value'] = eachDealer.id;
    data['label'] = eachDealer.name + `(${eachDealer.phoneNumber})`;
    data['isDealer'] = true;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const customerDropdown = customerList.filter((eachValue: any) => !eachValue.isDeleted)?.map((eachCustomer: any) => {
    let data: any = {};
    data['value'] = eachCustomer.id;
    data['label'] = eachCustomer.name + `(${eachCustomer.phoneNumber})`;
    data['isDealer'] = false;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const invoiceDropdown = createInvoiceList?.map((eachInvoice) => {
    let data: any = {};
    data['value'] = eachInvoice.id;
    data['label'] = eachInvoice.number;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });


  const recipientDropdown = [...dealerDropdown, ...customerDropdown];


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const calculateAmountTotal = (values: any, amount: number, tds: number, invoiceIndex: number, setFieldValue: any) => {
    debugger;
    if (invoiceIndex != -1) {
      let convertedAmount = +amount >= 0 ? +amount : 0;
      let convertedTds = +tds >= 0 ? +tds : 0;
      let total = convertedAmount + convertedTds;
      setFieldValue(`paymentMode[${invoiceIndex}].total`, parseFloat(total.toFixed(2)));
    }

    let totalAmount = 0;
    let totalTds = 0;
    let total = 0;

    if (values?.paymentMode?.length > 0) {
      total = values?.paymentMode?.reduce((accumulator: any, currentValue: any, index: number) => {
        let totalPriceObj = 0;
        let currentTdsDeduction = +currentValue.tdsDeduction >= 0 ? +currentValue.tdsDeduction : 0;
        let currentAmount = +currentValue.amount >= 0 ? +currentValue.amount : 0;
        if (index == invoiceIndex) {
          currentTdsDeduction = +tds;
          currentAmount = +amount;
        }

        totalPriceObj = currentTdsDeduction + +currentAmount;
        return +accumulator + +totalPriceObj;
      }, 0);

      totalTds = values?.paymentMode?.reduce((accumulator: any, currentValue: any, index: number) => {
        let currentTdsDeduction = +currentValue.tdsDeduction >= 0 ? +currentValue.tdsDeduction : 0;
        if (index == invoiceIndex) {
          currentTdsDeduction = +tds;
        }
        return +accumulator + +currentTdsDeduction;
      }, 0);

      totalAmount = values?.paymentMode?.reduce((accumulator: any, currentValue: any, index: number) => {
        let currentAmount = +currentValue.amount >= 0 ? +currentValue.amount : 0;
        if (index == invoiceIndex) {
          currentAmount = +amount;
        }
        return +accumulator + +currentAmount;
      }, 0);

      setFieldValue("amount", parseFloat(totalAmount.toFixed(2)));
      setFieldValue("tdsDeduction", parseFloat(totalTds.toFixed(2)));
      setFieldValue("total", parseFloat(total.toFixed(2)));
    }


  };

  const handleAddPaymentMode = (values: any, push: any, setFieldTouched: any) => {
    if (isPaymentModeFilled(values)) {
      push({
        paymentType: "",
        amount: "",
        tdsDeduction: 0,
        total: "",
        paymentNotes: "",
      });
    } else {

      values.paymentMode?.forEach((_: any, index: number) => {
        setFieldTouched(`paymentMode.${index}.paymentType`, true);
        setFieldTouched(`paymentMode.${index}.amount`, true);
        setFieldTouched(`paymentMode.${index}.tdsDeduction`, true);
      });

    }
  };


  const handleAddInvoice = (values: any, push: any, setFieldTouched: any) => {
    if (isInvoicesFilled(values)) {
      push({
        invoice: "",
        amount: "",
        tdsDeduction: 0,
        invoiceTotal: "",
        jobNumber: "",
        invoiceLockedDate: "",
      });
    } else {

      values.invoices?.forEach((_: any, index: number) => {
        setFieldTouched(`invoices[${index}].invoice`, true);
        setFieldTouched(`invoices[${index}].amount`, true);
        setFieldTouched(`invoices[${index}].tdsDeduction`, true);
      });
    }

  };

  const paymentsTypes = [
    { value: "cash", label: "Cash" },
    { value: "card", label: "Card" },
    { value: "cheque", label: "Cheque" },
    { value: "NEFT", label: "NEFT" },
    { value: "others", label: "Others" },
  ];

  const invoicesList = [
    { value: "invoice:IN2324-000021", label: "invoice:IN2324-000021" },
    { value: "invoice:IN2324-000024", label: "invoice:IN2324-000024" },
    { value: "invoice:IN2324-000028", label: "invoice:IN2324-000028" },
  ];

  const isPaymentModeFilled = (values: any) => {
    const paymentDetails = values.paymentMode || [];
    return paymentDetails.every((courier: any) => {
      const { paymentNotes, total, ...fieldsExceptAlternativeNumber } = courier;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  const isInvoicesFilled = (values: any) => {
    debugger
    const invoiceDetails = values.invoices || [];
    return invoiceDetails.every((invoice: any) => {
      const { invoiceTotal, jobNumber, invoiceLockedDate, paidAmount, ...fieldsExceptAlternativeNumber } = invoice;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  const handleSubmit = async (values: any) => {

    let sentData: any = {
      "recipient": values.recipient ? values.recipient : "",
      "paymentDate": values.paymentDate,
      "amount": +values.amount,
      "tdsDeduction": +values.tdsDeduction ? +values.tdsDeduction : 0,
      "paymentNotes": values.paymentNotes ? values.paymentNotes : "",
      "dealerId": values?.dealerId ? values?.dealerId : null,
      "customerId": values?.customerId ? values?.customerId : null,
      "paymentSplitList": values?.paymentMode?.map((eachData: any) => {
        let data: any = {};
        data['id'] = 0;
        data['paymentType'] = eachData?.paymentType;
        data['amount'] = +eachData?.amount;
        data['tdsDeduction'] = +eachData?.tdsDeduction ? +eachData?.tdsDeduction : 0;
        data['paymentNotes'] = eachData?.paymentNotes;
        return data;
      }),
      "invoiceReceivedPaymentDtoList": values?.invoices?.map((eachData: any) => {
        let data: any = {};
        data['invoiceId'] = eachData?.invoice;
        data['amount'] = +eachData.amount;
        data['tdsDeduction'] = +eachData.tdsDeduction ? +eachData.tdsDeduction : 0;
        return data;

      })
    };

    dispatch(createReceivedPaymentLoading(sentData));
  };

  useEffect(() => {
    if (message) {
      showToast(message, { type: "success" });
      navigate(ROUTER.RECEIVED_PAYMENT_LIST);
      dispatch(clearReceivedPaymentMessage());
    }
    if (error) {
      setVisible(true);
    }
  }, [message, error]);


  useEffect(() => {
    dispatch(getDealerReceivedPaymentLoading());
    dispatch(getCustomerReceivedPaymentLoading());
    // dispatch(getInvoiceListCreatePageLoading());
  }, []);

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible } onHide={hidePopup} />);
  };

  const hidePopup = () => {
    setVisible(false);
    setNewErrorVisible(false);
    dispatch(clearReceivedPaymentMessage());
  };

  const getInvoiceDetail = (e: any, index: number, setFieldValue: any, values: any) => {

    setFieldValue(`invoices.${index}.id`, 0);
    // setInvoiceValueHandler(`invoices.${index}.invoice`, "");
    setInvoiceValueHandler(`invoices.${index}.amount`, "");
    setInvoiceValueHandler(`invoices.${index}.tdsDeduction`, 0);
    setInvoiceValueHandler(`invoices.${index}.invoiceTotal`, "");
    setInvoiceValueHandler(`invoices.${index}.jobNumber`, "");
    setInvoiceValueHandler(`invoices.${index}.invoiceLockedDate`, "");
    // setFieldValue(`invoices.${index}.partSkuId`, "");
    // setFieldValue(`invoices.${index}.price`, "");
    // setFieldValue(`invoices.${index}.taxPercentage`, "");
    // setFieldValue(`invoices.${index}.totalPrice`, "");
    // setFieldValue(`invoices.${index}.partNumber`, "");
    // setFieldValue(`invoices.${index}.partDescription`, "");
    // setFieldValue(`invoices.${index}.partHsnNumber`, "");
    dispatch(getInvoiceDetailsReceivedPaymentLoading(e?.value, index, setFieldValue));
  };

  useEffect(() => {
    if (invoiceDetail) {
      setInvoiceValueHandler(`invoices.${invoiceIndex}.jobNumber`, invoiceDetail?.jobSheetNumber);
      setInvoiceValueHandler(`invoices.${invoiceIndex}.invoiceTotal`, invoiceDetail?.totalAfterDiscount);
      setInvoiceValueHandler(`invoices.${invoiceIndex}.paidAmount`, invoiceDetail?.paidAmount);
      setInvoiceValueHandler(`invoices.${invoiceIndex}.invoiceLockedDate`, formatDateTOYYYYMMDD(invoiceDetail?.lockedDate));
      setInvoiceValueHandler(`invoices.${invoiceIndex}.tdsDeduction`, 0);
      setInvoiceValueHandler(`customerId`, invoiceDetail?.customerId);
      setInvoiceValueHandler(`dealerId`, invoiceDetail?.dealerId);
    }
  }, [invoiceDetail, setInvoiceValueHandler, invoiceIndex]);

  const moveBack = () => {
    navigate(ROUTER.RECEIVED_PAYMENT_LIST);
  };


  const checkErrorValidation = (index: number, currentValue: any, setFieldError: any, invoiceTotal: any) => {
    if (invoiceDetail?.paidAmount) {
      let lessAmount = parseFloat(invoiceTotal) - invoiceDetail?.paidAmount;
      if (lessAmount < currentValue) {
        setNewErrorVisible(true);
        setRemainAmount(lessAmount);
      }
    }
  };

  const getDropdownForRecipient = (customerId: any, dealerId: any) => {
    let isCustomer = (customerId >= 0 && customerId != "");
    let isDealer = (dealerId >= 0 && dealerId != "");
    let option: any[] = [];
    
    if (isCustomer || isDealer) {
      if (isCustomer) {
        option = [...customerList.filter((eachValue: any) => eachValue.id == customerId)?.map((eachCustomer: any) => {
          let data: any = {};
          data['value'] = eachCustomer.userId;
          data['label'] = eachCustomer.name + `(${eachCustomer.phoneNumber})`;
          return data;
        })];
      }
      if (isDealer) {
        option = [...option, ...dealerList.filter((eachValue: any) => eachValue.id == dealerId)?.map((eachCustomer: any) => {
          let data: any = {};
          data['value'] = eachCustomer.userId;
          data['label'] = eachCustomer.name + `(${eachCustomer.phoneNumber})`;
          return data;
        })];
      }
      return option.sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    } else {
      return option;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        {/* {newErrorvisible && dialogContainer(`Amount value cannot exceed the ${remainAmount?.toFixed(2)}`)} */}
        <Container fluid>
          <Breadcrumb
            title="Received Payment"
            titlePath={ROUTER.RECEIVED_PAYMENT_LIST}
            breadcrumbItem={"New Received Payment"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik onSubmit={handleSubmit} initialValues={initialvalue} validationSchema={validationSchema} enableReinitialize >
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldValue, setFieldTouched, setFieldError, ...rest }: any) => {
                      return (
                        <Form>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={customerDropdown}
                                name="customerId"
                                label="Customer"
                                onChange={(e) => {
                                  if (e.target.value != null) {
                                    if (values.dealerId) {
                                      dispatch(getInvoiceListCreatePageLoading(values.dealerId, e.target.value));
                                    } else {
                                      dispatch(getInvoiceListCreatePageLoading(null, e.target.value));
                                    }
                                  }
                                  else {
                                    if (values.dealerId) {
                                      dispatch(getInvoiceListCreatePageLoading(values.dealerId, e.target.value));
                                    }
                                  }
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                required={(values.customerId || values.dealerId) ? false : true}
                                // disabled={invoiceDetail?.customerId}
                                disabled={false}
                                value={values.customerId}
                                invalid={
                                  touched["customerId"] && errors["customerId"]
                                    ? true
                                    : false
                                }
                                showClear={true}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={dealerDropdown}
                                name="dealerId"
                                label="Dealer"
                                onChange={(e) => {
                                  if (e.target.value != null) {
                                    if (values.customerId) {
                                      dispatch(getInvoiceListCreatePageLoading(e.target.value, values.customerId));
                                    } else {
                                      dispatch(getInvoiceListCreatePageLoading(e.target.value, null));
                                    }
                                  } else {
                                    if (values.customerId) {
                                      dispatch(getInvoiceListCreatePageLoading(e.target.value, values.customerId));
                                    }
                                  }
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                required={(values.customerId || values.dealerId) ? false : true}
                                // disabled={invoiceDetail?.dealerId}
                                disabled={false}
                                value={values.dealerId}
                                invalid={
                                  touched["dealerId"] && errors["dealerId"]
                                    ? true
                                    : false
                                }
                                showClear={true}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={(values.customerId || values.dealerId) ? getDropdownForRecipient(values.customerId, values.dealerId) : []}
                                name="recipient"
                                label="Recipient"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                disabled={false}
                                value={values.recipient}
                                invalid={
                                  touched["recipient"] && errors["recipient"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Payment Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name={`paymentDate`}
                                onBlur={handleBlur}
                                value={values.paymentDate}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["paymentDate"] &&
                                    errors["paymentDate"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Amount"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="amount"
                                onBlur={handleBlur}
                                value={values.amount}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["amount"] && errors["amount"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Tds Deduction"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="tdsDeduction"
                                onBlur={handleBlur}
                                value={values.tdsDeduction}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["tdsDeduction"] && errors["tdsDeduction"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Total"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="total"
                                onBlur={handleBlur}
                                value={values.total}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["total"] && errors["total"] ? true : false}
                              />
                            </Col>
                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Payment Notes"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="paymentNotes"
                                onBlur={handleBlur}
                                value={values.paymentNotes}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["paymentNotes"] && errors["paymentNotes"] ? true : false}
                              /> */}
                              <TextEditor
                                onChange={handleChange}
                                labelName='Payment Notes'
                                name='paymentNotes'
                                required={false}
                                value={values.paymentNotes}
                                invalid={touched["paymentNotes"] && errors["paymentNotes"] ? true : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Col lg={12} className="dealer-title-header">
                            <div>Payment Modes</div>
                          </Col>
                          <FieldArray name='paymentMode'>
                            {({ insert, remove, push }) => (
                              <div>
                                {values.paymentMode?.length > 0 && values.paymentMode?.map((value: any, index: any) => {
                                  const isFirstContact = index === 0;
                                  return <div key={index}>
                                    <div className="dynamic-form-container">
                                      <div className="space-equally">
                                        <DropdownInput
                                          options={paymentsTypes}
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          name={`paymentMode.${index}.paymentType`}
                                          label="Payment type"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          required={true}
                                          value={value.paymentType}
                                          invalid={touched.paymentMode?.[index]?.paymentType && errors.paymentMode?.[index]?.paymentType ? true : false} />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Amount"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`paymentMode[${index}].amount`}
                                          onBlur={handleBlur}
                                          onChange={
                                            (e: any) => {
                                              handleChange(e);
                                              calculateAmountTotal(values, e.target.value, value.tdsDeduction, index, setFieldValue);
                                            }
                                          }
                                          isRequired={true}
                                          value={value.amount}
                                          invalid={touched.paymentMode?.[index]?.amount && errors.paymentMode?.[index]?.amount ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Tds deduction"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`paymentMode[${index}].tdsDeduction`}
                                          onBlur={handleBlur}
                                          onChange={(e: any) => {
                                            handleChange(e);
                                            calculateAmountTotal(values, value.amount, e.target.value, index, setFieldValue);
                                          }}
                                          isRequired={false}
                                          value={value.tdsDeduction}
                                          invalid={touched.paymentMode?.[index]?.tdsDeduction && errors.paymentMode?.[index]?.tdsDeduction ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Total"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="text"
                                          name={`paymentMode[${index}].total`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={false}
                                          disabled={true}
                                          value={value.total}
                                          invalid={touched.paymentMode?.[index]?.total && errors.paymentMode?.[index]?.total ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Payment notes"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="text"
                                          name={`paymentMode[${index}].paymentNotes`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={false}
                                          value={value.paymentNotes}
                                          invalid={touched.paymentMode?.[index]?.paymentNotes && errors.paymentMode?.[index]?.paymentNotes ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally-last">
                                        <div className="space-equally-2 add-btn-container">
                                          <JobTooltipMEL title="Remove Contact Details" position='bottom'>
                                            <i className="fas fa-times-circle" style={{ fontSize: "16px" }}
                                              onClick={() => {
                                                if (!value.id) {
                                                  calculateAmountTotal(values, 0, 0, index, setFieldValue);
                                                  remove(index);
                                                }
                                              }}>
                                            </i>
                                          </JobTooltipMEL>
                                        </div>
                                      </div>
                                    </div>
                                  </div>;
                                })}

                                <div className="multiadd-container">
                                  {<div className="add-btn-container" onClick={() => { handleAddPaymentMode(values, push, setFieldTouched); }}>
                                    <JobTooltipMEL title="Add Payment Details" position='bottom'>
                                      <i className="fas fa-plus-circle"></i><span>Add</span>
                                    </JobTooltipMEL>
                                  </div>
                                  }
                                </div>
                              </div>
                            )}
                          </FieldArray>

                          <hr />
                          <Col lg={12} className="dealer-title-header">
                            <div>Invoices</div>
                          </Col>
                          <FieldArray name='invoices'>
                            {({ insert, remove, push }) => (
                              <div>
                                {values.invoices?.length > 0 && values.invoices?.map((value: any, index: any) => {
                                  const isFirstContact = index === 0;
                                  return <div key={index}>
                                    <div className="dynamic-form-container">
                                      <div className="space-equally">
                                        <DropdownInput
                                          options={invoiceDropdown}
                                          name={`invoices[${index}].invoice`}
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          label="Invoice"
                                          onChange={(event) => {
                                            handleChange(event);
                                            if(event.value){
                                              getInvoiceDetail(event, index, setFieldValue, values);
                                            }else{
                                              setFieldValue(`invoices.${index}.jobNumber`,"")
                                              setFieldValue(`invoices.${index}.invoiceTotal`,"")
                                              setFieldValue(`invoices.${index}.paidAmount`,"")
                                              setFieldValue(`invoices.${index}.invoiceLockedDate`,"")
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          required={true}
                                          value={value?.invoice}
                                          showClear={true}
                                          invalid={touched.invoices?.[index]?.invoice && errors.invoices?.[index]?.invoice ? true : false} />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Amount"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`invoices[${index}].amount`}
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            handleChange(e);
                                            checkErrorValidation(index, e.target.value, setFieldError, value.invoiceTotal);
                                          }}
                                          isRequired={true}
                                          value={value.amount}
                                          invalid={touched.invoices?.[index]?.amount && errors.invoices?.[index]?.amount ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Tds deduction"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`invoices[${index}].tdsDeduction`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={false}
                                          value={value.tdsDeduction}
                                          invalid={touched.invoices?.[index]?.tdsDeduction && errors.invoices?.[index]?.tdsDeduction ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Invoice total"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="text"
                                          name={`invoices[${index}].invoiceTotal`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={false}
                                          disabled={true}
                                          value={value.invoiceTotal}
                                          invalid={touched.invoices?.[index]?.invoiceTotal && errors.invoices?.[index]?.invoiceTotal ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Job number"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="text"
                                          name={`invoices[${index}].jobNumber`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={false}
                                          disabled={true}
                                          value={value.jobNumber}
                                          invalid={touched.invoices?.[index]?.jobNumber && errors.invoices?.[index]?.jobNumber ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Invoice locked date"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="date"
                                          name={`invoices[${index}].invoiceLockedDate`}
                                          onBlur={handleBlur}
                                          value={value.invoiceLockedDate}
                                          onChange={handleChange}
                                          isRequired={false}
                                          disabled={true}
                                          invalid={
                                            touched["weddingDate"] &&
                                              errors["weddingDate"]
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      {
                                        <div className="space-equally">
                                          <InputCom
                                            labelName="Paid Amount"
                                            inputclassName="form-control"
                                            labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                            type="number"
                                            name={`invoices[${index}].paidAmount`}
                                            onBlur={handleBlur}
                                            value={value.paidAmount}
                                            onChange={handleChange}
                                            isRequired={false}
                                            disabled={true}
                                            invalid={touched["paidAmount"] && errors["paidAmount"] ? true : false}
                                          />
                                        </div>
                                      }


                                      <div className="space-equally-last">
                                        <div className="space-equally-2 add-btn-container">
                                          <JobTooltipMEL title="Remove Invoice" position='bottom'>
                                            <i className="fas fa-times-circle" style={{ fontSize: "16px" }}
                                              onClick={() => {
                                                if (!value.id) {
                                                  remove(index);
                                                }
                                              }}>
                                            </i>
                                          </JobTooltipMEL>
                                        </div>
                                      </div>
                                    </div>
                                  </div>;
                                })}

                                <div className="multiadd-container">
                                  {<div className="add-btn-container" onClick={() => { handleAddInvoice(values, push, setFieldTouched); }}>
                                    <JobTooltipMEL title="Add Invoices Details" position='bottom'>
                                      <i className="fas fa-plus-circle"></i><span>Add</span>
                                    </JobTooltipMEL>
                                  </div>
                                  }
                                </div>
                              </div>
                            )}
                          </FieldArray>

                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label="Cancel"
                              type="button"
                              onClick={() => moveBack()}
                            />
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label="Create Received payment"
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}

                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateReceivedPayment;