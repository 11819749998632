import { totalWorkingDaysTypes } from './actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from 'src/util/api';
import { createTotalWorkingDaysError, createTotalWorkingDaysSuccess, getMonthsError, getMonthsSuccess, getTotalWorkingDayByIdError, getTotalWorkingDayByIdSuccess, getTotalWorkingDaysError, getTotalWorkingDaysSuccess } from './action';

function* getTotalWorkingDaysAsync(action:any) {
    try {
        const response: Promise<any> = yield call(API.getTotalWorkingDaysAPI,{...action.payload})
        yield put(getTotalWorkingDaysSuccess(response));
    } catch (error) {
        yield put(getTotalWorkingDaysError(error));
    }
}

function* createTotalWorkingDaysAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.createTotalDaysAPI, action.payload )
        let create
        if(action.payload.id==0){
            create=true
        }
        yield put(createTotalWorkingDaysSuccess({response,create}));   
    } catch (error) {
        yield put(createTotalWorkingDaysError(error));
    }
}

function* getMonthsAsync() {
    try {
        const response: Promise<any> = yield call(API.getMonthsForTotalWorkingDaysAPI)
        yield put(getMonthsSuccess(response));
    } catch (error) {
        yield put(getMonthsError(error));
    }
}

function* getTotalWorkingDayByIdAsync(action:any) {
    try {
        const response: Promise<any> = yield call(API.getTotalWorkingDayByIdAPI,action.payload)
        yield put(getTotalWorkingDayByIdSuccess(response));
    } catch (error) {
        yield put(getTotalWorkingDayByIdError(error));
    }
}

function* totalWorkingDaysSaga() {
    yield takeLatest(totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_LOADING, getTotalWorkingDaysAsync);
    yield takeLatest(totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_LOADING, createTotalWorkingDaysAsync);
    yield takeLatest(totalWorkingDaysTypes.GET_MONTHS_LOADING, getMonthsAsync);
    yield takeLatest(totalWorkingDaysTypes.GET_TOTAL_WORKING_DAY_BY_ID_LOADING, getTotalWorkingDayByIdAsync);
}

export default totalWorkingDaysSaga ;