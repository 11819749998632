import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import {
  ResetSupplierMessage,
  clearSupplierMessage,
  getSupplierById,
  getSupplierContactDetails,
  getSupplierImageLoading,
  supplierDocumentGetLoading,
  supplierLinkGetLoading,
} from 'src/store/actions';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate'
import { Image } from 'primereact/image'
import { Skeleton } from 'primereact/skeleton'
import { Link } from 'react-router-dom'
import FeatherIcon from "feather-icons-react";
import { Columns } from 'src/models/components/dataTableWrapperModel'
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText'

const ViewSupplier = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [visible, setVisible] = useState(false);
    const editUserClick = () => {
        navigate(`${ROUTER.SUPPLIER_EDIT}/${id}`)
    }

    const formValue: any = useSelector(
        (state: RootReducerState) => state.supplierReducer.supplierDetails
    );
    const { error, message, loading, supplierImage,contactDetails,documentLink ,supplierDocumentList} = useSelector(
        (state: RootReducerState) => state.supplierReducer
    );
    const dispatch = useDispatch();
    const hidePopup = () => {
        setVisible(false);
        dispatch(clearSupplierMessage());
    };
    const [contactdetailvalue, setcontactdetailvalue] = useState<any>(null);
    const dialogContainer = (error: any) => {
        return (
            <>
                <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
            </>
        );
    };
    useEffect(() => {
        const preloader: any = document.getElementById("preloader");
        if (loading) {
            preloader.style.display = "block";
        } else {
            preloader.style.display = "none";
        }
    }, [loading]);

    const statusBodyTemplate = (value: boolean) => {
        let status = value;
        return (
            <StatusHghlighter status={status} />
        )

    }


    useEffect(() => {
        if (id) {
            dispatch(getSupplierById(id));
            dispatch(getSupplierImageLoading(id))
            dispatch(getSupplierContactDetails(id))
        }
        return () => {
            dispatch(ResetSupplierMessage())
        }
    }, []);

    const userView = {
        generalDetails: [
            {
                name: "Name",
                value: formValue?.name
            },
            {
              name: "Company Name",
              value: formValue?.companyName
            },
            {
                name: "Email",
                value: formValue?.email
            },
            {
                name: "Mobile",
                value: formValue?.mobileNumber
            },
         
            {
                name: "Gst Number",
                value: formValue?.gstNumber
            },
            {
                name: "Iec Number",
                value: formValue?.iecNumber
            },
       
            {
                name: "Account Information",
                value: formValue?.accountInformation ? <HtmltoPlainText htmlString={formValue?.accountInformation} /> : ""
            },
            {
                name: "Terms & Conditions",
                value: formValue?.termsAndConditions ? <HtmltoPlainText htmlString={formValue?.termsAndConditions} /> : ""
            },
            {
                name: "Address",
                value: formValue?.address
            },
            {
              name: "Details Verified",
              value: statusBodyTemplate(formValue?.detailsVerified)
            },
            {
                name: "Send Email",
                value: statusBodyTemplate(formValue?.sendEmail)
            },
            {
                name: "Send Sms",
                value: statusBodyTemplate(formValue?.sendSMS)
            }
        ]
    }

    const emailBodyTemplate = (rowData: any, field: string) => {
        let data = rowData[field];
        return (
          <EmailBodyTemplate data={data} />
        );
      };
    const emptyTemplate = (rowData: any, field: string) => {
        let data = rowData[field];
        return (
          <div>{data ? data :"-"}</div>
        );
      };

      const [uniqueLinks, setUniqueLinks] = useState<any>([]);

      useEffect(() => {
        const uniqueDocumentLinks = documentLink.filter(
          (item: any, index: any, self: any) =>
            index === self.findIndex((obj: any) => obj.id === item.id)
        );
        setUniqueLinks(uniqueDocumentLinks);
      }, [documentLink]);
      const DocumentIdCall = (id: any) => {};
      const gstDocumentTemplate = (rowData: any) => {
        let link = uniqueLinks?.find(
          (item: any) => item.id == rowData.gstDocument
        )?.link;
        return (
          <>
            {rowData.gstDocument && !link ? (
              <Skeleton height="2rem" width="2rem" className="mb-2"></Skeleton>
            ) : rowData.gstDocument && link ? (
              <Link to={link} target="_blank">
                <FeatherIcon
                  icon={"file-text"}
                  onClick={() => DocumentIdCall(rowData.gstDocument)}
                />
              </Link>
            ) : (
              <div className="">-</div>
            )}
          </>
        );
      };
      const smeDocumentTemplate = (rowData: any) => {
        let link = uniqueLinks?.find(
          (item: any) => item.id == rowData.smeDocument
        )?.link;
        return (
          <>
            {rowData.smeDocument && !link ? (
              <Skeleton height="2rem" width="2rem" className="mb-2"></Skeleton>
            ) : rowData.smeDocument && link ? (
              <Link to={link} target="_blank">
                <FeatherIcon
                  icon={"file-text"}
                  onClick={() => DocumentIdCall(rowData.smeDocument)}
                />
              </Link>
            ) : (
              <div className="">-</div>
            )}
          </>
        );
      };
    
      const column: Columns[] = [
        {
          field: "name",
          sortable: false,
          header: "Name",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "designation",
          sortable: false,
          header: "Designation",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "mobileNumber",
          sortable: false,
          header: "Mobile",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "email",
          sortable: false,
          header: "Email",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "alternateNumber",
          sortable: false,
          header: "Alternate Number",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "gstNo",
          sortable: false,
          header: "GST No",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "smeNo",
          sortable: false,
          header: "SME No",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          isDefault: true,
          isStatic: false,
        },
        {
          field: "gstDocument",
          sortable: false,
          header: "Gst Document",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          body: (rowData: any) => gstDocumentTemplate(rowData),
          isDefault: true,
          isStatic: false,
        },
        {
          field: "smeDocument",
          sortable: false,
          header: "SME Document",
          filter: false,
          showFilterMenu: false,
          headerStyle: { whiteSpace: "nowrap", width: "250px" },
          body: (rowData: any) => smeDocumentTemplate(rowData),
          isDefault: true,
          isStatic: false,
        },
      ];

      
  const [combinedSupplierDocuments, setCombinedSupplierDocuments] =
  useState<any>([]);

useEffect(() => {
  const updatedSupplierDocuments = [...combinedSupplierDocuments];

  supplierDocumentList.forEach((document: any) => {
    const existingDocumentIndex = updatedSupplierDocuments.findIndex(
      (existingDoc: any) => existingDoc.id === document.id
    );

    if (existingDocumentIndex === -1) {
      updatedSupplierDocuments.push(document);
    }
  });

  setCombinedSupplierDocuments(updatedSupplierDocuments);
}, [supplierDocumentList]);


useEffect(() => {
  const mergedSupplierDetails = contactDetails.map((contact: any) => {

    const supplierDoc = combinedSupplierDocuments.filter(
      (doc: any) => doc.sourceId == contact.id
    );
    if (supplierDoc) {
      let data = {
        gstDocument: "",
        gstfilename: "",
        smeDocument: "",
        smefilename: "",
      };
      supplierDoc.forEach((doc: any) => {
        if (doc.fileType === "GSTDOCUMENT" && !data.gstDocument) {
          data.gstDocument = doc.id;
          data.gstfilename = doc.name;
        } else if (doc.fileType === "SMEDOCUMENT" && !data.smeDocument) {
          data.smeDocument = doc.id;
          data.smefilename = doc.name;
        }
      });

      return {
        ...contact,
        ...data,
      };
    } else {
      return { ...contact, gstDocument: "", smeDocument: "" };
    }
  });
  setcontactdetailvalue(mergedSupplierDetails);
}, [combinedSupplierDocuments]);

useEffect(() => {
}, [contactdetailvalue]);
useEffect(() => {
  contactdetailvalue?.map((val: any) => {
    if (val.gstDocument != "") {
      dispatch(supplierLinkGetLoading(val.gstDocument, val.id));
    }
    if (val.smeDocument != "") {
      dispatch(supplierLinkGetLoading(val.smeDocument, val.id));
    }
  });
}, [contactdetailvalue]);

useEffect(()=>{
    contactDetails?.forEach((element: any) => {
        dispatch(supplierDocumentGetLoading(element?.id));
      }, 500);
},[contactDetails])
    return (
        <React.Fragment>
            <div className='userpage'>
                {visible && dialogContainer(error)}
                <Container fluid>
                    <BreadcrumbWithFilter
                        title="Supplier" titlePath={ROUTER.SUPPLIER_GRID} breadcrumbItem='Supplier Details'
                        isShowCreate={false}
                        isShowFilter={false}
                        filter={false}
                        isShowFirstButton={true}
                        firstButtonLabel='Edit Supplier'
                        onFirstButtonClick={editUserClick}
                    />
                    <Row>
                        <div className="view">
                            <div className="general-details">
                                <b>General Details</b>
                            </div>
                            <div className="subview">
                                <Row>
                                    <Col lg={6}>
                                        <div className="parentCls2">
                                            {
                                                userView.generalDetails.map((data, index) => {
                                                    return (
                                                        <div key={index} className={` pt-1 childCls`}>
                                                            <div>{data.name}</div>
                                                            <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <hr /> */}

                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <Row>
                                            <Col lg={4}>
                                                <div className='mb-2'>Image</div>
                                                {supplierImage ?
                                                    // <img src={supplierImage} className='view-image' />
                                                    <Image src={supplierImage} alt="Image" className='view-image' height='150px' preview />
                                                    :
                                                    <div className='none-text'>-</div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {contactDetails?.length > 0 && <Row>
                         <div className="view mt-5">
                            <div className="general-details">
                                <b>Contact Details</b>
                            </div>
                            <div className="subview">
                                <Row>
                                    {/* <div className="card ps-0 pe-0">
                                        <DataTable size='large' value={contactDetails} showGridlines tableStyle={{ minWidth: '30rem', padding: "30px" }}>
                                            <Column field="name" header="Name" className=''></Column>
                                            <Column field="designation" header="Designation" className=""></Column>
                                            <Column field="mobileNumber" header="Phone" className=""></Column>
                                            <Column field="alternateNumber" header="Alternate Number" body={(rowData)=>emptyTemplate(rowData,'alternateNumber')} className=""></Column>
                                            <Column field="email" header="Email" body={(rowData)=>emailBodyTemplate(rowData,'email')} className=""></Column>
                                            <Column field="smeNo" header="SME No" className=""></Column>
                                            <Column field="gstNo" header="Gst No" className=""></Column>
                                        </DataTable>
                                    </div> */}
                                    <DataTableWrapper
                                  columnDetails={column}
                                  value={contactdetailvalue}
                                ></DataTableWrapper>
                                </Row>
                            </div>
                        </div>
                            </Row>
                                }
                            </div>
                        </div>
                    </Row>
                                                
                    {/* <Row>
          <div className="view">
              <div className="general-details">
                <b>Other Details</b>
              </div>
              <div className="subview">
                <Row>
                  {
                    userView.otherDetails.map((data, index) => {
                      return (
                        <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                          <div className='mb-2'>{data.name}</div>
                          <b>{data.value?data.value:<div className='none-text'>-</div>}</b>
                        </Col>
                      )
                    })
                  }
                </Row>
                </div>
                </div>
          </Row> */}
                </Container>
            </div >
        </React.Fragment >
    )
}

export default ViewSupplier