import React from 'react'
import './ImagePreviewer.scss'
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import { StringChain } from 'lodash';
import { ImagePreviewerModel } from 'src/models/components/imagePreviewerModel';


const ImagePreviewer = ({selectedFile,removeIndex}:ImagePreviewerModel) => {
  return (
    <div className="dropzone-previews " id="file-previews">
        <div
          className="mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
          key={"file"}
        >
          <div className="p-2">
            <Row className="align-items-center">
              <Col className="col-auto  img-container">
                <img
                  data-dz-thumbnail=""
                  height="80" 
                  className="avatar-sm rounded bg-light image-size"
                  // alt="Image"
                  src={selectedFile}
                />
                <div className="img-del-icon">
                  <FeatherIcon onClick={removeIndex} icon="trash-2" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
  </div>
  )
}

export default ImagePreviewer