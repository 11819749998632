import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface TooltipProps {
  title: string | undefined;
  position?: 'top' | 'right' | 'bottom' | 'left';
  children: ReactNode;
}

const JobTooltipMEL: React.FC<TooltipProps> = ({ title, position = 'top', children}) => {
  const [isVisible, setIsVisible] = useState<any>(false);
  const showTooltip = () => {
    setIsVisible(true);
   
    
  };

  const hideTooltip = () => {
    
    setIsVisible(false);    
  }

  const getTooltipStyle = () => {
    switch (position) {
      case 'right':
        return { top: '50%', left: '100%', transform: 'translate(5px, -50%)' };
      case 'bottom':
        return { top: '100%', left: '50%', transform: 'translate(-50%, 5px)' };
      case 'left':
        return { top: '50%', right: '100%', transform: 'translate(-5px, -50%)' };
      default:
        return { bottom: '100%', left: '50%', transform: 'translate(-50%, -5px)' };
    }
  };

  const getArrowStyle = () => {
    switch (position) {
      case 'right':
        return { top: '32%', left: '0%', marginLeft: '-10px', borderWidth: '5px', borderStyle: 'solid', borderColor: 'transparent #32506bda transparent transparent' };
      case 'bottom':
        return { top: '0%', left: '45%', marginTop: '-10px', borderWidth: '5px', borderStyle: 'solid', borderColor: 'transparent transparent #32506bda transparent' };
      case 'left':
        return { top: '32%', right: '0%', marginRight: '-10px', borderWidth: '5px', borderStyle: 'solid', borderColor: 'transparent transparent transparent #32506bda' };
      default:
        return { bottom: '0%', left: '45%', marginBottom: '-10px', borderWidth: '5px', borderStyle: 'solid', borderColor: '#32506bda transparent transparent transparent' };
    }
  };


  return (
    <div className="tooltip-container"  onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isVisible && (
        <div className={`tooltip-${position}`} style={getTooltipStyle()} id="tooltip">
          {title}
          <div className="arrow" style={getArrowStyle()}></div>
        </div>
      )}
    </div>
  );
};

export default JobTooltipMEL;
