export enum totalWorkingDaysTypes {
    GET_TOTAL_WORKING_DAYS_LOADING = "@@totalWorkingDays/GET_TOTAL_WORKING_DAYS_LOADING",
    GET_TOTAL_WORKING_DAYS_SUCCESS = "@@totalWorkingDays/GET_TOTAL_WORKING_DAYS_SUCCESS",
    GET_TOTAL_WORKING_DAYS_ERROR = "@@totalWorkingDays/GET_TOTAL_WORKING_DAYS_ERROR",
  
    CREATE_TOTAL_WORKING_DAYS_LOADING = "@@totalWorkingDays/CREATE_TOTAL_WORKING_DAYS_LOADING",
    CREATE_TOTAL_WORKING_DAYS_SUCCESS = "@@totalWorkingDays/CREATE_TOTAL_WORKING_DAYS_SUCCESS",
    CREATE_TOTAL_WORKING_DAYS_ERROR = "@@totalWorkingDays/CREATE_TOTAL_WORKING_DAYS_ERROR",
  
    GET_MONTHS_LOADING = "@@totalWorkingDays/GET_MONTHS_LOADING",
    GET_MONTHS_SUCCESS = "@@totalWorkingDays/GET_MONTHS_SUCCESS",
    GET_MONTHS_ERROR = "@@totalWorkingDays/GET_MONTHS_ERROR",

    GET_TOTAL_WORKING_DAY_BY_ID_LOADING = "@@totalWorkingDays/GET_TOTAL_WORKING_DAY_BY_ID_LOADING",
    GET_TOTAL_WORKING_DAY_BY_ID_SUCCESS = "@@totalWorkingDays/GET_TOTAL_WORKING_DAY_BY_ID_SUCCESS",
    GET_TOTAL_WORKING_DAY_BY_ID_ERROR = "@@totalWorkingDays/GET_TOTAL_WORKING_DAY_BY_ID_ERROR",

    CLEAR_TOTAL_WORKING_DAYS = "@@totalWorkingDays/CLEAR_TOTAL_WORKING_DAYS",
    RESET_TOTAL_WORKING_DAYS = "@@totalWorkingDays/RESET_TOTAL_WORKING_DAYS"
}