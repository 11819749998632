import { API } from "src/util/api";
import { call, put, takeLatest } from "redux-saga/effects"
import { LoginActionTypes } from "./actionTypes";


function* getRefreshToken() {
  try {
    yield console.log("RefreshToken")
  } catch (error) {
    yield console.log(error)
  }
};

function* loginSaga() {
  yield takeLatest(LoginActionTypes.GET_REFRESH_TOKEN_TIME, getRefreshToken)
};

export default loginSaga;