import { StockLocationTypes } from "./actionTypes";
import { stockLocationInitialState } from "src/models/pages/stockLocationModel";

const initialState: stockLocationInitialState = {
    error: "",
    loading: false,
    message: "",
    paginatorCount: 0,
    stocklocationList: {
        values: [],
        totalRecords: 0
    },
    stocklocation: null
};



const StockLocation = (state = initialState, action: any) => {
    switch (action.type) {

        // Grid
        case StockLocationTypes.GET_STOCKLOCATION_LIST:
        case StockLocationTypes.FILTER_PAGE_LOADING:
        case StockLocationTypes.UPDATE_STOCKLOCATION:
        case StockLocationTypes.POST_STOCKLOCATION:
        case StockLocationTypes.GET_STOCKLOCATION_BY_ID:
        case StockLocationTypes.DELETE_STOCKLOCATION:
            return {
                ...state,
                loading: true,
                paginatorCount: 0
            };

        case StockLocationTypes.GET_STOCKLOCATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                stocklocationList: {
                    values: action.payload.data.items,
                    totalRecords: action.payload.data.totalCount,
                },
                paginatorCount: action.payload.data.items?.length
            };

        case StockLocationTypes.GET_STOCKLOCATION_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "",
                error: "",
                stocklocation: action.payload
            };

        case StockLocationTypes.DELETE_STOCKLOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                message: "Stock Location deleted successfully"
            }

        case StockLocationTypes.UPDATE_STOCKLOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                message: "Stock Location updated successfully"
            }
        case StockLocationTypes.FILTER_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                stocklocationList: {
                    ...state.stocklocationList,
                    values: action.payload.data,
                    totalRecords: action.payload.data.length,
                }
            }
        case StockLocationTypes.POST_STOCKLOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                message: "Stock Location created successfully",
            }
        case StockLocationTypes.GET_STOCKLOCATION_LIST_FAIL:
        case StockLocationTypes.DELETE_STOCKLOCATION_FAIL:
        case StockLocationTypes.FILTER_PAGE_ERROR:
        case StockLocationTypes.UPDATE_STOCKLOCATION_FAIL:
        case StockLocationTypes.POST_STOCKLOCATION_FAIL:
        case StockLocationTypes.GET_STOCKLOCATION_BY_ID_FAIL:
            return {
                ...state,
                message: "",
                loading: false,
                error: action.payload
            };


        case StockLocationTypes.RESET_STOCKLOCATION:
            return {
                ...initialState
            };

        case StockLocationTypes.CLEAR_STOCKLOCATION_MESSAGE:
            return {
                ...state,
                error: "",
                loading: false,
                message: "",
            };
        default:
            return { ...state };
    }
};

export default StockLocation;
