export enum StockValuebyPartTypes {

    // Grid
    GET_STOCK_VALUE_BY_PART_LIST = "@@stockValuebyParts/GET_STOCK_VALUE_BY_PART_LIST",
    GET_STOCK_VALUE_BY_PART_LIST_SUCCESS = "@@stockValuebyParts/GET_STOCK_VALUE_BY_PART_LIST_SUCCESS",
    GET_STOCK_VALUE_BY_PART_LIST_FAIL = "@@stockValuebyParts/GET_STOCK_VALUE_BY_PART_LIST_FAIL",

    // RESET

    RESET_STOCK_VALUE_BY_PART_LIST = "@@stockValuebyParts/RESET_STOCK_VALUE_BY_PART_LIST",

    GET_STOCK_PART_LIST_LOADING = "@@stockValuebyParts/GET_STOCK_PART_LIST_LOADING",
    GET_STOCK_PART_LIST_SUCCESS = "@@stockValuebyParts/GET_STOCK_PART_LIST_SUCCESS",
    GET_STOCK_PART_LIST_ERROR = "@@stockValuebyParts/GET_STOCK_PART_LIST_ERROR",
  }
  