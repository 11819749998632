import React, { useEffect, useRef, useState } from 'react'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import { Columns, LazyTableState } from "src/models/components/dataTableWrapperModel";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Formik } from 'formik';
import * as Yup from "yup";
import { ACTIVE, ALL, LOCKED, PACKAGENOTSENT, PACKAGESENT } from 'src/constants/commonConstants';
import { Button, Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'src/components/Common/Select';
import { Option } from "src/models/components/inputWrapperModel";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import FeatherIcon from "feather-icons-react";
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsheetTatReportsTatLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { saveAsExcelFile } from 'src/helpers/common_helpers';
import { useToast } from 'src/components/Common/ReactToaster';
import { API } from 'src/util/api';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';



const jobsheetTatreportListConverson = (values: any) => {
  const modifeidValue = values?.items?.map((eachValue: any) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['jobSheetNumber'] = eachValue?.jobSheetnumber ? eachValue?.jobSheetnumber : "-";
    data['totalTat'] = eachValue?.tat;
    return data;
  });
  return modifeidValue;
};

const convertTimesToMinutes = (minutes: any) => {
  let years = Math.floor(minutes / (60 * 24 * 365));
  minutes %= (60 * 24 * 365);
  let months = Math.floor(minutes / (60 * 24 * 30));
  minutes %= (60 * 24 * 30);
  let days = Math.floor(minutes / (60 * 24));
  minutes %= (60 * 24);
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;
  let output = [];
  if (years > 0) {
    output.push(`${years} year${years > 1 ? 's' : ''}`);
  }
  if (months > 0) {
    output.push(`${months} month${months > 1 ? 's' : ''}`);
  }
  if (days > 0) {
    output.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    output.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (remainingMinutes > 0) {
    output.push(`${remainingMinutes.toFixed(1)} minute${remainingMinutes > 1 ? 's' : ''}`);
  }
  if (output.length === 0) {
    return "less than a minute";
  } else {
    return (output.join(", "));
  }
}

const headerTemplate = (val: any) : any => {
  return (
    convertTimesToMinutes(val)
  )
}

const JobSheetTATReports = () => {
  const columns: Columns[] = [
    // {
    //   field: "id",
    //   sortable: false,
    //   header: "#",
    //   filter: true,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "jobSheetNumber",
      sortable: false,
      header: "Job Sheet Number",
      filter: false,
      showFilterMenu: false,
      frozen: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalTat",
      sortable: false,
      header: "Total Tat",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      // body: (rowData: any) => headerTemplate(rowData.totalTat)
      body: (rowData: any) => headerTemplate(((rowData.totalTat ? rowData.totalTat : 0) + 1) / 60)
    },
  ];

  const filterDetails: DataTableFilterMeta = {
    id: { value: "", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",  //CreationTime
    sortOrder: 1,
    filters: filterDetails,
  };
  const { showToast } = useToast()
  const [visible, setVisible] = useState(false);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const [filter, setFilter] = useState(false);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [selectedJobTatReport, setSelectedJobTatReport] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const dt = useRef<DataTable<any>>(null);
  const dispatch = useDispatch();
  const { error, message, jobsheetTatReportList, loading } = useSelector((state: RootReducerState) => state.jobSheetTatReportsReducer)
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)

  const { totalCount } = useSelector((state: RootReducerState) => state.jobSheetTatReportsReducer.jobsheetTatReportList)

  
  const modifiedValues: any[] = jobsheetTatreportListConverson(jobsheetTatReportList);
  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const hidePopup = () => {
    setVisible(false);
    // dispatch(); 
  }

  const defaultColumns = {
    ...columns[0]
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const initialValues = {
    status: "",
  };
  const validationSchema = Yup.object({});

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible} onHide={hidePopup} />);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          packageSent: { value: null, matchMode: "contains" },
          packageNotSent: { value: null, matchMode: "contains" },

        },
      }));
    }
    else if (formValue == PACKAGESENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          packageSent: { value: "true", matchMode: "contains" },
          packageNotSent: { value: "false", matchMode: "contains" },

        },
      }));
    } else if (formValue == PACKAGENOTSENT) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "true", matchMode: "contains" },
          packageSent: { value: "false", matchMode: "contains" },
        },
      }));
    } else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: "false", matchMode: "contains" },
          packageNotSent: { value: "false", matchMode: "contains" },
          packageSent: { value: "false", matchMode: "contains" },
          all: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          packageSent: { value: null, matchMode: "contains" },
          packageNotSent: { value: null, matchMode: "contains" },
        },
      }));
    }
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const handleStatusBlur = () => { };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedJobTatReport(value);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedJobTatReport([]);
      } else if (selectedStatus == ACTIVE) {
        // let len = modifiedValues?.length;
        // let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        // if (len === filterLen) {
        //   setSelectAll(true);
        //   setSelectedJobReport(modifiedValues);
        // } 
        // else {
        //   setSelectAll(false);
        //   setSelectedJobReport(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        // }
      }
      else {
        setSelectAll(true);

      }
    } else {
      setSelectAll(false);
      setSelectedJobTatReport([]);
    }
  };

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['jobSheetNumber'] = eachValue?.jobSheetNumber;
      data['totalTat'] = eachValue?.totalTat;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalCount > 5000 ? 5000 : totalCount < 1 ? 1 : totalCount,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true)
      const response = await API.getJobSheetTatReportsApi(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['jobSheetNumber'] = eachValue?.jobSheetnumber ? eachValue?.jobSheetnumber : "-";
        data['totalTat'] = eachValue?.tat;
        return data;
      });
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "jobsheet Tat Reports");
      } else {
        saveDataAsExcel(modifeidValue, "jobsheet Tat Reports");
      }
      setPdfLoading(false)
    } catch (error) {
      // console.log(error);
      setPdfLoading(false)

    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "jobsheet Tat Reports");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        jobSheetNumber: data.jobSheetNumber,
        totalTat: data.totalTat,

      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: modifiedValues,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "jobsheet Tat Reports");
    fetchData(true)
  };

  const header =
    <div className="table-header-container">
      <div>
        Job Sheet TAT Reports
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} />
          {/* <TooltipMEL title="Select Status" position="bottom">
            <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label"
              placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL> */}
          <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler} style={{ paddingLeft: "0px" }}>
            <FeatherIcon icon="sliders" />
          </Button>
        </div>
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    dispatch(getJobsheetTatReportsTatLoading(modifiedLazyState));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedJobTatReport([]);
    callbackFunction(lazyState);
    return () => {
      // dispatch(clearCourierMessage());
    };
  }, [lazyState]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
    }
  }, [error, message]);

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}
        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Job Sheet TAT Reports  {last} records of {totalRecords} in total"
          selection={selectedJobTatReport}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default JobSheetTATReports