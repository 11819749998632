import {call,put,takeLatest} from 'redux-saga/effects';
import {jobSheetReportsActions} from "./actionTypes";
import { API } from 'src/util/api';
import { getJobSheetReportsError, getJobSheetReportsSuccess } from './action';


//worker function

function* getJobsheetReportsAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.getJobSheetReportsApi,{...action.lazyState});
    yield put(getJobSheetReportsSuccess(response));
  } catch (error) {
    yield put(getJobSheetReportsError(error));
  }
}


//watcher function
function* jobSheetReportsSaga () {
    yield takeLatest(jobSheetReportsActions.GET_JOBSHEET_REPORTS_LOADING,getJobsheetReportsAsync)
}

export default jobSheetReportsSaga ;