import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import {
  CurrencyFormatter,
  formatDateMEL,
  hasAdminOrEmployeeRoleHandler,
  hasFranchiseRoleHandler,
} from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { deletePurchaseOrderFromList, resetPurchaseOrder } from 'src/store/actions';
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DeleteModal from "../../Calendar/DeleteModal";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns } from 'src/models/components/dataTableWrapperModel'
import { Link } from 'react-router-dom'
import { clearMessageForIncentiveReward, incentiveRewardListByIdLoading, resetIncentiveReward } from 'src/store/IncentiveReward/action'
import UpdatePaymentDetail from './UpdatePaymentDetail'
import UpdateAdjustmentPoint from './UpdateAdjustmentPoint'


const ViewIncentiveReward = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { incentiveDetail } = useSelector(
    (state: RootReducerState) => state.incentiveRewardReducer
  );
  const { error, message, loading } = useSelector(
    (state: RootReducerState) => state.incentiveRewardReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )
  }


  const [visible, setVisible] = useState(false);
  const { showToast } = useToast();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearMessageForIncentiveReward());
      dispatch(incentiveRewardListByIdLoading(id));
      window.scrollTo({ top: 1 })
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPurchaseOrder())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(incentiveRewardListByIdLoading(id));
    }
    return () => {
      dispatch(resetIncentiveReward());
    };
  }, [id]);

  const currencyBodyTemplate = (totalamount: string, code: string) => {
    let amount = totalamount;
    let currencyCode = code;
    return <div>{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>;
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${field}`}>{rowData}</Link>
    );
  };

  const userView = {
    generalDetails: [
      {
        name: "User Name",
        value: incentiveDetail?.userName
      },
      {
        name: "User Type",
        value: incentiveDetail?.userType
      },
      {
        name: "Month",
        value: incentiveDetail?.month
      },
      {
        name: "Reward Value",
        value: incentiveDetail?.rewardValue
      },
      {
        name: "Total reward Value",
        value: incentiveDetail?.totalRewardValue
      },
      {
        name: "Total Reward Point",
        value: incentiveDetail?.totalRewardPoint
      },
      {
        name: "Total Tat Reward Point",
        value: incentiveDetail?.totalTatRewardPoint
      },
      {
        name: "Total Process Reward Point",
        value: incentiveDetail?.totalProcessRewardPoint
      },
      {
        name: "Original Reward Point",
        value: incentiveDetail?.originalRewardPoint
      },

      // {
      //   name: "Payment Description",
      //   value: incentiveDetail?.paymentDescription
      // },
      // {
      //   name: "Payment Date",
      //   value: formatDateMEL(incentiveDetail?.paymentDate)
      // },
      // {
      //   name: "Paid",
      //   value: statusBodyTemplate(incentiveDetail?.isPaid)
      // },
      // {
      //   name: "Reward Point",
      //   value: incentiveDetail?.adjustedRewardPoint
      // },
      // {
      //   name: "Adjustment Notes",
      //   value: incentiveDetail?.adjustmentNotes
      // },
    ],
    paymentDetails: [
      {
        name: "Paid At :",
        value: formatDateMEL(incentiveDetail?.paymentDate)
      },
      {
        name: "Description :",
        value: incentiveDetail?.paymentDescription
      },
    ],
    adjustmentDetails: [
      {
        name: "Adjusted Reward Point :",
        value: incentiveDetail?.adjustedRewardPoint
      },
      {
        name: "Notes :",
        value: incentiveDetail?.adjustmentNotes
      }
    ]
  }


  const JobNavigateTemplate=(jobId:any)=>{
    if(jobId){

      return(
        <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${jobId}`}>{`JobSheet (${jobId})`}</Link>
      )
    }
    else{
      return ""
    }
  }

  const IncentiveRewardDetailColumn: Columns[] = [
    {
      field: "jobId",
      sortable: false,
      header: "Jobsheet",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>JobNavigateTemplate(rowData.jobId),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "scenario",
      sortable: false,
      header: "Scenario",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "process",
      sortable: false,
      header: "Process",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "processRewardPoint",
      sortable: false,
      header: "Process Reward Point",
      filter: false,
      showFilterMenu: false,
      //   body:(rowData:any)=>headerTemplate(rowData.quantity),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "tatRewardPoint",
      sortable: false,
      header: "Tat Reward Point",
      filter: false,
      showFilterMenu: false,
      //   body:(rowData:any)=>headerTemplate(rowData.buyingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalRewardPoint",
      sortable: false,
      header: "Total Reward Point",
      filter: false,
      showFilterMenu: false,
      //   body:(rowData:any)=>headerTemplate(rowData.totalBuyingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    }
  ];


  return (
    <React.Fragment>
      <div className='userpage' id="incentiveReports">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={false}
          onDeleteClick={() => {
            let payload = {
              id: id,
              undo: false,
            };
            dispatch(deletePurchaseOrderFromList(payload));
            setDeleteModal(false);
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />
        <Container fluid>
          <BreadcrumbWithFilter
            title="Incentive Reward Point" titlePath={ROUTER.INCENTIVE_REWARD_SYSTEM} breadcrumbItem='Incentive Reward Point Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}

          />
          <Row>
          <Col lg={hasFranchiseRoleHandler()?12: 6}>
            <Col lg={12}>
              <div className="view" > 
                <div className="general-details">
                  <b>Incentive Reward Point Details</b>
                </div>
                <div className="subview">
                  <div className="AdjustmetnCls">
                    {
                      userView.generalDetails.map((data, index) => {
                        return (
                          <div key={index} className={` pt-1 childClsNew`}>
                            <div>{data.name}</div>
                            <div>{data.value ? data.value : <div className=''>-</div>}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Row>
            <Col lg={hasFranchiseRoleHandler() ? 6 : 12}>
              <div className="view"  id="paymentDetails">
                <div className="general-details d-flex justify-content-between" style={{padding:"9px"}}>
                  <b>Payment Details</b>

                  <b className={incentiveDetail.isPaid? "low" : "highStatus"}>{incentiveDetail.isPaid ? "Paid" : "Un Paid"}</b>
                </div>
                <div className="subview">
                  <div className="AdjustmetnCls">
                    {
                      userView.paymentDetails.map((data, index) => {
                        return (
                          <div key={index} className={` pt-1 childClsNew`}>
                            <div>{data.name}</div>
                            <div>{data.value ? data.value : <div className=''>-</div>}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={hasFranchiseRoleHandler() ? 6 : 12}>
              <div className="view"  id="paymentDetails">
                <div className="general-details">
                  <b>Adjustment Details</b>
                </div>
                <div className="subview">
                  <div className="AdjustmetnCls">
                    {
                      userView.adjustmentDetails.map((data, index) => {
                        return (
                          <div key={index} className={` pt-1 childClsNew`}>
                            <div>{data.name}</div>
                            <div>{data.value ? data.value : <div className=''>-</div>}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            </Col>
            <Col mlg={6}>
            {hasAdminOrEmployeeRoleHandler() &&
            <Row>
              <Col lg={12}>
                <Card style={{ borderRadius: "20px" }}>
                  <CardBody>
                    <h5 className='mb-4'>Update Adjustment Point</h5>
                    <UpdateAdjustmentPoint details={incentiveDetail} id={id} disabled={incentiveDetail?.isPaid}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          {hasAdminOrEmployeeRoleHandler() &&
            <Row>
              <Col lg={12}>
                <Card style={{ borderRadius: "20px" }}>
                  <CardBody>
                    <h5 className='mb-4'>Update Payment Details</h5>
                    <UpdatePaymentDetail details={incentiveDetail} id={id} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
            </Col>
          </Row>

          <Row>
            {incentiveDetail?.incentiveRewardDetail?.length > 0 &&
              <div className="view">
                <div className="general-details">
                  <b>Incentive Reward Details</b>
                </div>
                <div className="subview">
                  <Row>
                  <div className="card ps-0 pe-0 " id="table-border">
                      <DataTableWrapper
                        stripedRows
                        rowHover
                        columnDetails={IncentiveRewardDetailColumn}
                        value={incentiveDetail?.incentiveRewardDetail}
                        scrollable={true}
                        scrollHeight="400px"
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            }
          </Row>
          
          


        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewIncentiveReward