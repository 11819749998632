import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Input } from "reactstrap";
import  "src/components/UI/commonUI.styles.scss";
import { convertToMonthYear } from "src/helpers/common_helpers";

interface BreadcrumbProps {
  breadcrumbItem: string;
  title?: string;
  titlePath?: string;
  button?:boolean;
  setDate?:any
  date?:any
}
const monthConverter = () =>{

    const data=new Date()
    const year =data.getFullYear()
    const month =data.getMonth()+1
    const monthFormat = month>9 ? month : `0${month}`
    
    return `${year}-${monthFormat}`
  
  
}
const DashboardBreadcrumb = ({ breadcrumbItem, title, titlePath = "", setDate,date}: BreadcrumbProps) => {

    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
    const calendarRef = useRef<any>(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const toggleCalendar = (e:any) => {
      setShowCalendar(!showCalendar);
    };

  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2">
          <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
          <div id="calendar">
          <div className="table-header-container-item">
                  <div className="btn-group">
                      <button
                          className="btn btn-soft-primary waves-effect waves-light"
                          // onClick={toggleCalendar}
                          onClick={(e) => calendarRef.current.toggle(e)}
                          >
                          <i className="bx bx-calendar font-size-16 align-middle"></i>
                      </button>
                  </div>
                </div>
                </div>
                <OverlayPanel ref={calendarRef}>
                  {/* <Calendar value={date} onChange={(e:any) => setDate(e.value)} view="month" dateFormat="mm/yy" className="dashboard-calendar"/> */}
                  <label htmlFor="month">Select Month</label>
                  <Row>
                                <input
                                  className="form-control"
                                  type="month"
                                  name="month"
                                  value={date||monthConverter()}
                                  onChange={(e)=>setDate(e.target.value)}
                                />
                                </Row>
                  </OverlayPanel>
                <b className="jobsheet-date">{convertToMonthYear(date)}</b>
                </div>
          <div className="page-title-right" id="Dashboardbrteadcrumb">
            <ol className="breadcrumb m-0">

              {title &&
                <>
                  <BreadcrumbItem>
                    {/* <Link to={titlePath ? titlePath : title}>{title}</Link> */}
  
                  </BreadcrumbItem>

                  {/* <BreadcrumbItem active>
                    <Link to="#">
                      {breadcrumbItem}
                    </Link>
                  </BreadcrumbItem> */}
                </>
              }
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DashboardBreadcrumb;
