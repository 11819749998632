import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { createBulkEmailError, createBulkEmailSuccess, deleteBulkEmailError, deleteBulkEmailSuccess, getBulkEmailByIdError, getBulkEmailByIdSuccess, getBulkEmailListError, getBulkEmailListSuccess, getBulkEmailStatusError, getBulkEmailStatusSuccess, getCustomerEmailError, getCustomerEmailSuccess, getDealerEmailError, getDealerEmailSuccess, getEmployeeEmailError, getEmployeeEmailSuccess, getFranchiseEmailError, getFranchiseEmailSuccess, getSupplierEmailError, getSupplierEmailSuccess } from "./action";
import { BulkEmailTypes } from "./actionTypes";

function* getBulkEmailListAsync(action : any) {

    try {
      const response : Promise<any> = yield call(
          API.getBulkEmailListAPI,{...action.lazyState}
      )
      yield put(getBulkEmailListSuccess(response));
    } catch (error) {
      yield put(getBulkEmailListError(error));
    }
  }

  
function* getEmployeeDropdownListAsync() {
    
  try {
      const response: Promise<any> = yield call( API.getEmployeeDrpFromIdentity )        
      yield put(getEmployeeEmailSuccess(response));
  } catch (error) {
      yield put(getEmployeeEmailError(error));
  }
}


function* getDealerListAsync() {
  try {
    const response: Promise<any> = yield call(API.getDealerDropdownApi);
    yield put(getDealerEmailSuccess(response));
  } catch (error) {
    yield put(getDealerEmailError(error));
  }
}

function* getcustomerDropdownListAsync() {
  try {
    // const response: Promise<any> = yield call(API.getCustomerDropForJobAPI);
    const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getCustomerEmailSuccess(response));
  } catch (error) {
    yield put(getCustomerEmailError(error));
  }
}

function* getFranchiseDropdownAsync() {
  try {
    const response : Promise<any> = yield call(API.getFranchiseDrpFromIdentity);    
    yield put(getFranchiseEmailSuccess(response));

  } catch (error) {
    yield put(getFranchiseEmailError(error));
  }
}

function* getSupplierDropdownAsync() {
  try {
    const response : Promise<any> = yield call(API.getSupplierDropdown);    
    yield put(getSupplierEmailSuccess(response));

  } catch (error) {
    yield put(getSupplierEmailError(error));
  }
}

function* craeteBulkEmailAsync(action:any) {
  try {
    const response : Promise<any> = yield call(API.createBulkEmailApi,action.payload);    
    yield put(createBulkEmailSuccess(response));

  } catch (error) {
    yield put(createBulkEmailError(error));
  }
}

function* deleteBulkEmailAsync(action:any) {
  try {
    const response : Promise<any> = yield call(API.deleteBulkEmailApi,action.id);    
    yield put(deleteBulkEmailSuccess(response));

  } catch (error) {
    yield put(deleteBulkEmailError(error));
  }
}

function* getBulkEmailByIdAsync(action:any) {
  try {
    const response : Promise<any> = yield call(API.getBulkEmailByIdApi,action.id);    
    yield put(getBulkEmailByIdSuccess(response));

  } catch (error) {
    yield put(getBulkEmailByIdError(error));
  }
}

function* getBulkEmailStatusAsync() {
  try {
    const response : Promise<any> = yield call(API.getBulkEmailStatusApi);    
    yield put(getBulkEmailStatusSuccess(response));

  } catch (error) {
    yield put(getBulkEmailStatusError(error));
  }
}

  function* BulkEmailSaga() {
    yield takeLatest(BulkEmailTypes.GET_BULK_EMAIL_LIST_LOADING, getBulkEmailListAsync);
    yield takeLatest(BulkEmailTypes.GET_EMPLOYEE_EMAIL_LOADING, getEmployeeDropdownListAsync);
    yield takeLatest(BulkEmailTypes.GET_DEALER_EMAIL_LOADING, getDealerListAsync);
    yield takeLatest(BulkEmailTypes.GET_CUSTOMER_EMAIL_LOADING, getcustomerDropdownListAsync);
    yield takeLatest(BulkEmailTypes.GET_FRANCHISE_EMAIL_LOADING, getFranchiseDropdownAsync);
    yield takeLatest(BulkEmailTypes.GET_SUPPLIER_EMAIL_LOADING, getSupplierDropdownAsync);
    yield takeLatest(BulkEmailTypes.CREATE_BULK_EMAIL_LOADING, craeteBulkEmailAsync);
    yield takeEvery(BulkEmailTypes.DELETE_BULK_EMAIL_LOADING, deleteBulkEmailAsync);
    yield takeEvery(BulkEmailTypes.GET_BY_ID_BULK_EMAIL_LOADING, getBulkEmailByIdAsync);
    yield takeEvery(BulkEmailTypes.GET_BULK_EMAILSTATUS_LOADING, getBulkEmailStatusAsync);
  }
  
  export default BulkEmailSaga;