import { customers } from "src/models/pages/customerModel";
import { CustomerTypes } from "./actionTypes";
import { availableParallelism } from "os";

// For Grid

export const getCustomersList = (lazyState: any) => {
  return {
    type: CustomerTypes.GET_CUSTOMERS_LIST,
    lazyState,
  };
};

export const getCustomersListSuccess = (customers: any) => {
  return {
    type: CustomerTypes.GET_CUSTOMERS_LIST_SUCCESS,
    payload: customers,
  };
};

export const getCustomersListError = (error: any) => {
  return {
    type: CustomerTypes.GET_CUSTOMERS_LIST_FAIL,
    payload: error,
  };
};

export const deleteCustomersFromList = (payload: any) => {
  return {
    type: CustomerTypes.DELETE_CUSTOMERS,
    payload: payload,
  };
};

export const deleteCustomersFromListSuccess = (response: any,undo:boolean) => {
;
  return {
    type: CustomerTypes.DELETE_CUSTOMERS_SUCCESS,
    payload: response,
    undo
  };
};

export const deleteCustomersFromListError = (error: any) => {
  return {
    type: CustomerTypes.DELETE_CUSTOMERS_FAIL,
    payload: error,
  };
};


//get customer by id
export const getCustomerByIdLoading =(customerId : any)=>{
  return {
    type: CustomerTypes.GET_CUSTOMERBYID_LOADING,
    payload: customerId,
  };
}

export const getCustomerByIdSuccess =(customerDetails : any)=>{
  return {
    type: CustomerTypes.GET_CUSTOMERBYID_SUCCESS,
    payload: customerDetails,
  }
}

export const getCustomerByIdError =(error : any)=>{
  return {
    type : CustomerTypes.GET_CUSTOMERBYID_ERROR,
    payload : error
  }
}

//create customer
export const createCustomer =(customer : any)=>{
  return {
    type: CustomerTypes.CREATE_CUSTOMER_LOADING,
    payload: customer,
  };
}

export const createCustomerSuccess=(customer : any)=>{
  return {
    type : CustomerTypes.CREATE_CUSTOMER_SUCCESS,
    payload: customer,
  }
}

export const createCustomerError=(error : any)=>{
  return{
    type : CustomerTypes.CREATE_CUSTOMER_ERROR,
    payload : error
  }
} 

//create customer with redirect

export const createCustomerWithRedirectLoading =(customer:any)=>{
  return {
    type: CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_LOADING,
    payload: customer,
  };
}

export const createCustomerWithRedirectSuccess =(customer:any)=>{
  return {
    type: CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_SUCCESS,
    payload: customer,
  };
}

export const createCustomerWithRedirectError =(error:any)=>{
  return {
    type: CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_ERROR,
    payload: error,
  };
}


//update customer
export const updateCustomerLoading=(userDetails :any,id : any )=>{
  return {
    type : CustomerTypes.UPDATE_CUSTOMER_LOADING,
    payload : userDetails,
    id: id
  }
}



export const updateCustomerSuccess=(customer : any)=>{
  return {
    type : CustomerTypes.UPDATE_CUSTOMER_SUCCESS,
    payload : customer
  }
}

export const updateCustomerError=(error : any)=>{
  return {
    type : CustomerTypes.UPDATE_CUSTOMER_ERROR,
    payload : error
  }
}

//  upload the file
export const uploadCustomerFileLoading = (file :any,id : any )=>{
  return {
    type : CustomerTypes.CUSTOMER_FILE_UPLOAD_LOADING,
    payload : file,
    id: id
  }
}


export const uploadCustomerFileSuccess=(customer : any)=>{
  return {
    type : CustomerTypes.CUSTOMER_FILE_UPLOAD_SUCCESS,
  }
}

export const uploadCustomerFileError=(error : any)=>{
  return {
    type : CustomerTypes.CUSTOMER_FILE_UPLOAD_ERROR,
    payload : error
  }
}

//reset customer
export const customerDetailsReset=()=>{
  return {
    type : CustomerTypes.RESET_CUSTOMER,
  }
}

export const clearCustomMessage = ()=>{
  return {
    type : CustomerTypes.CLEAR_CUSTOMER_MESSAGE,
  }
}

// Customer Tax Type

export const customerTaxTypeList=()=>{
  return {
    type : CustomerTypes.CUSTOMER_TAX_TYPE_LIST_LOADING,
  }
}

export const customerTaxTypeListSuccess=(taxList : any)=>{
  return {
    type : CustomerTypes.CUSTOMER_TAX_TYPE_LIST_SUCCESS,
    payload : taxList
  }
}

export const customerTaxTypeListError=(error : any)=>{
  return {
    type : CustomerTypes.CUSTOMER_TAX_TYPE_LIST_ERROR,
    payload : error
  }
}


// get customer image

export const getCustomerImageLoading =(customerId : any)=>{
  return {
    type: CustomerTypes.CUSTOMER_FILE_FETCH_LOADING,
    payload: customerId,
  };
}

export const getCustomerImageSuccess =(customerDetails : any)=>{
  return {
    type: CustomerTypes.CUSTOMER_FILE_FETCH_SUCCESS,
    payload: customerDetails,
  }
}

export const getCustomerImageError =(error : any)=>{
  return {
    type : CustomerTypes.CUSTOMER_FILE_FETCH_ERROR,
    payload : error
  }
}


// delete customer image

export const deleteCustomerImageLoading = (customerId : any)=>{
  return {
    type: CustomerTypes.CUSTOMER_FILE_DELETE_LOADING,
    payload: customerId,
  };
}

export const deleteCustomerImageSuccess = (customerDetails : any)=>{
  return {
    type: CustomerTypes.CUSTOMER_FILE_DELETE_SUCCESS,
    payload: customerDetails,
  }
}

export const deleteCustomerImageError = (error : any)=>{
  return {
    type : CustomerTypes.CUSTOMER_FILE_DELETE_ERROR,
    payload : error
  }
}

export const ClearCustomerImage=()=>{
	return {
	  type : CustomerTypes.CUSTOMER_FILE_CLEAR,
	}
  }

  export const setCustomerMobileNumberLoading = (number:any,id: any) => {
    debugger
    return {
      type: CustomerTypes.CUSTOMER_SET_PHONE_LOADING,
      payload: {id:id,number:number},
    };
  }
  export const setCustomerMobileNumberSuccess = (resp: any) => {
    return {
      type: CustomerTypes.CUSTOMER_SET_PHONE_SUCCESS,
      payload: resp,
    };
  }
  export const setCustomerMobileNumberError = (error: any) => {
    return {
      type: CustomerTypes.CUSTOMER_SET_PHONE_ERROR,
      payload: error,
    };
  }
  
  export const setCustomerEmailIdLoading = (email:any,id: any) => {
    return {
      type: CustomerTypes.CUSTOMER_SET_EMAIL_LOADING,
      payload: {id:id,email:email},
    };
  }
  export const setCustomerEmailIdSuccess = (resp: any) => {
    return {
      type: CustomerTypes.CUSTOMER_SET_EMAIL_SUCCESS,
      payload: resp,
    };
  }
  export const setCustomerEmailIdError = (error: any) => {
    return {
      type: CustomerTypes.CUSTOMER_SET_EMAIL_ERROR,
      payload: error,
    };
  }
  export const sendCustomerOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
    console.log(id);
    
    return {
      type: CustomerTypes.SEND_OTP_CUSTOMER,
      payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
    };
  }
  export const sendCustomerOtpSuccess = (resp: any) => {
    return {
      type: CustomerTypes.SEND_OTP_CUSTOMER_SUCCESS,
      payload: resp,
    };
  }
  export const sendCustomerOtpError = (error: any) => {
    return {
      type: CustomerTypes.SEND_OTP_CUSTOMER_ERROR,
      payload: error,
    };
  }
  export const resendOtpCustomer = (id: any,number:any) => {
    return {
      type: CustomerTypes.RESEND_CUSTOMER_OTP,
      payload: id,
      number:number
    };
  }
  export const resendOtpSuccess = (resp: any) => {
    return {
      type: CustomerTypes.RESEND_CUSTOMER_OTP_SUCCESS,
      payload: resp,
    };
  }
  export const resendOtpError = (resp: any) => {
    return {
      type: CustomerTypes.RESEND_CUSTOMER_OTP_ERROR,
      payload: resp,
    };
  }

  export const resetOtpMessage = () => {
    return {
      type: CustomerTypes.RESET_CUSTOMER_OTP_MESSAGE,
    };
  }

  
export const customerLatLagLoading = (selectedArea: string) => {
  return {
    type: CustomerTypes.GET_CUSTOMER_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const customerLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: CustomerTypes.GET_CUSTOMER_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const customerLatLagError = (error: any) => {
  return {
    type: CustomerTypes.GET_CUSTOMER_LOCATION_ERROR,
    payload: error
  }
}