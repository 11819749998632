import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumb from 'src/components/Common/Breadcrumb'
import InputCom from 'src/components/Common/Input';
import * as Yup from 'yup';
import { ROUTER } from 'src/constants/routes';
import Dropzone from "react-dropzone";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import Button from 'src/components/UI/Button';
import { FieldModal } from 'src/models/components/inputWrapperModel';
import { vehicleBrandsDetails } from 'src/models/pages/vehicleBrandsModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { clearVehicleBrandsMessage, createVehicleBrands, deleteVehicleBrandImageLoading, getVehicleBrandImageLoading, getVehicleBrandsBYID, resetVehicleBrand, updateVehicleBrands, uploadVehicleBrandFileLoading } from 'src/store/actions';
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import TooltipMEL from 'src/components/UI/Tooltip';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import { onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import { vehicleBrandImageDeleteSuccess } from 'src/store/vehicleBrands/reducer';
import { Chips } from 'primereact/chips';
const BulkSmsSender: React.FC = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const { id } = useParams()
  const { showToast } = useToast();



  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Number is required'),
    message:Yup.string().required('Message is required')

  });
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getVehicleBrandsBYID(id));
      dispatch(getVehicleBrandImageLoading(id))
    }

    
    return () => {
      dispatch(resetVehicleBrand())
    }
  }, [id, dispatch]);

  const formValue: vehicleBrandsDetails = useSelector(
    (state: RootReducerState) => state.vehicleBrandsReducer?.vehicleBrandsDetails as vehicleBrandsDetails
  );

  
  const { error, message, loading, vehicleBrandImage } = useSelector(
    (state: RootReducerState) => state.vehicleBrandsReducer
  );

  
  const initialvalue = {
    name:  "",
    message: ""
  }
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (error) {
      setVisible(true)
      setIsSubmitting(false)
    }
    if (message !== "" && message != undefined) {
      showToast(message, { type: "success" });
      if (message != vehicleBrandImageDeleteSuccess) {
        dispatch(clearVehicleBrandsMessage())
        setTimeout(() => {
          setIsSubmitting(false)
          navigate(ROUTER.VEHICLE_BRANDS_GRID)
        }, 500)
        if (id) {
          dispatch(getVehicleBrandsBYID(id))
        } else {
          navigate(ROUTER.VEHICLE_BRANDS_GRID);
        }
      }else{
        dispatch(clearVehicleBrandsMessage())
        if (id) {
          dispatch(getVehicleBrandsBYID(id))
        }

 
      }
    

    }
  }, [error, message])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }

  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearVehicleBrandsMessage())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: vehicleBrandsDetails = {
      name: values.name as string
    }
    setIsSubmitting(true)
    // id ? update(submittedData) : post(submittedData);
  };

  const update = (formValue: vehicleBrandsDetails) => {
    dispatch(updateVehicleBrands(formValue, id));
    if (selectedFiles.length > 0) {
      dispatch(uploadVehicleBrandFileLoading(selectedFiles[0], id))
    }
  };


  const post = (formValue: vehicleBrandsDetails) => {
    dispatch(createVehicleBrands(formValue));
  };

  const removeIndex = () => {
    setDeleteModal(false);
    if (vehicleBrandImage) {
      dispatch(deleteVehicleBrandImageLoading(id))
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  }

  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.VEHICLE_BRANDS_GRID)
    }
  }

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.name] = '';
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };
const [number,  setNumber]=useState<any>([])
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeIndex()
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {visible && dialogContainer(error)}
      <div className="page-content" id="view-brand">
        <Container fluid>
          <Breadcrumb 
        //   title="Vehicle Brands" 
        //   titlePath={ROUTER.VEHICLE_BRANDS_GRID} 
          breadcrumbItem={"Send Message"} 
        />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={4} >
                              <InputCom labelName="Send to"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["name"] && errors["name"] ? true : false}
                              />
                        {/* <Chips value={number} onChange={(e) => setNumber(e.value)} /> */}
                            </Col>
                            {/* </Row>
                            <Row> */}
                            <Col lg={4} >
                              <InputCom labelName="Message"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="message"
                                onBlur={handleBlur}
                                value={values.message}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["message"] && errors["message"] ? true : false}
                              />

                            </Col>

                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                className="secondary-btn me-2"
                                label={id ? "Cancel" : "Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              <Button
                                className="btn-primary me-2"
                                label={"Send"}
                                type="submit"
                              />
                            </div>

                          </Row>


                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BulkSmsSender