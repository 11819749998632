import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { RootReducerState } from 'src/store/reducers'
import "../../SubUserManagement/subUserstyle.scss"
import {
  hasAdminOrEmployeeRoleHandler,
  hasCustomerRoleHandler,
  hasDealerRoleHandler,
  hasFranchiseRoleHandler,
} from 'src/helpers/common_helpers';
import ArchiveModal from "../../Calendar/DeleteModal";
import {
  clearIntentMessageData,
  emailIntendLoading,
  franchiseListforIntent,
  getIntentDetail,
  printIntendLoading,
  resetIntentValue,
} from 'src/store/actions';
import { IntentDetailById } from 'src/models/pages/intentModel'
import { useToast } from "src/components/Common/ReactToaster";
import { INTENT_STATUS } from 'src/constants/commonConstants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'

const ViewIntentComponent = () => {
  const { id, sub_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { intentDetail, franchiseList, error, message,mailmessage, loading } = useSelector((state: RootReducerState) => state.intentReducer);
  const [intentDetails, setIntentDetails] = useState<any>([])
  const [visible, setVisible] = useState(false);
  const [generateSKUModal, setGenerateSKUModal] = useState<boolean>(false)
  const { showToast } = useToast();
  const isDealerLogin = hasDealerRoleHandler();
  const isFranchise = hasFranchiseRoleHandler();
  const isCustomer = hasCustomerRoleHandler();

  useEffect(() => {
    if (id) {
      dispatch(franchiseListforIntent())
      dispatch(getIntentDetail(id));
    }

    return () => {
      dispatch(resetIntentValue())
      dispatch(clearIntentMessageData())
    }
  }, [id])

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearIntentMessageData());
      navigate(`${ROUTER.INTENT_GRID}`);

    }
    if (mailmessage) {
      showToast(mailmessage, { type: "success" });
      dispatch(clearIntentMessageData());

    }
  }, [error, message,mailmessage]);

  useEffect(()=>{
    return () => {
      dispatch(resetIntentValue())
      dispatch(clearIntentMessageData())
    }
  },[])

  useEffect(() => {
    const intentDetails = (intentDetail as IntentDetailById)?.intentDetails?.filter((item: any) => (item.id == sub_id))
    if (intentDetails?.length > 0) {
      setIntentDetails(intentDetails)
    }

  }, [intentDetail])


  const FranchiseTemplate = (rowData: any) => {
    return (
      <>
        {
          (isDealerLogin || isFranchise || isCustomer) ? rowData : <Link to={`${ROUTER.FRANCHISEE_VIEW}/${intentDetail?.franchiseId}`}>{rowData}</Link>
        }
      </>
    );
  };
  const userView = {
    generalDetails: [
      {
        name: "Franchise",
        value: franchiseList ? FranchiseTemplate(franchiseList?.find((eachValue: any) => eachValue.id == intentDetail?.franchiseId)?.name) : ""
      },
      {
        name: "Intend Number",
        value: intentDetail?.intentNumber
      },
    ],

  }

  if (intentDetail?.status != INTENT_STATUS.REJECTED && intentDetail?.status != INTENT_STATUS.REQUESTED) {
    userView.generalDetails.push({
      name: "Approved By",
      value: intentDetail?.approvedUser
    })
    userView.generalDetails.push({
      name: "Approved Note",
      value: intentDetail?.approvedNote
    })
  }

  if (intentDetail?.status == INTENT_STATUS.REJECTED) {
    userView.generalDetails.push({
      name: "Rejected By",
      value: intentDetail?.rejectedUser
    })
    userView.generalDetails.push({
      name: "Rejected Note",
      value: intentDetail?.rejectedNote
    })
  }






  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    const inputElement = document.getElementById('ScanField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearIntentMessageData());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };


  const IntentDetailnondetail:any = intentDetail?.intentDetails?.filter((item: any) => item.isDeleted != true)

  const handleEditIntent = () => {
    navigate(`${ROUTER.INTENT_EDIT}/${id}`)
  }

  let isAdmin = hasAdminOrEmployeeRoleHandler();
  let isApprovedStatus = true;
  isApprovedStatus = ((isAdmin && (intentDetail?.status == INTENT_STATUS.REQUESTED || intentDetail?.status == INTENT_STATUS.APPROVED || intentDetail?.status == INTENT_STATUS.PENDING_DISPATCH)) || (!isAdmin && (intentDetail?.status == INTENT_STATUS.REQUESTED || intentDetail?.status == INTENT_STATUS.COURIERED))) ? true : false

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );
  };

  const isShowPrintIntend=()=>{
    return (intentDetail?.status == INTENT_STATUS.RECEIVED && (hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler()))
  }

  const isShowEmailIntend=()=>{
    return (intentDetail?.status == INTENT_STATUS.RECEIVED && hasAdminOrEmployeeRoleHandler())
  }

  const printIntend=()=>{
    dispatch(printIntendLoading(id))
  }

  const emailIntend=()=>{
    dispatch(emailIntendLoading(id))
  }
  return (
    <div className="pagescontent " id="view-dealer">
      {visible && dialogContainer(error)}
      <ArchiveModal
        show={generateSKUModal}
        message="The system will automatically generate SKU. when parts cannot be scanned."
        deleteLabel="Yes"
        closeLabel="No"
        onDeleteClick={() => {
          setGenerateSKUModal(false);
        }}
        onCloseClick={() => {
          setGenerateSKUModal(false);
        }}
      />
      <BreadcrumbWithFilter
        title="Intend"
        titlePath={`${ROUTER.INTENT_GRID}`}
        breadcrumbItem="Intend Details"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={isApprovedStatus}
        firstButtonLabel="Edit Intend"
        onFirstButtonClick={handleEditIntent}
        isShowSecondButton={isShowPrintIntend()}
        secondButtonLabel='Print Intend'
        onSecondButtonClick={printIntend}
        isShowThirdButton={isShowEmailIntend()}
        thirdButtonLabel='Email Intend'
        onThirdButtonClick={emailIntend}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <div className="dis-flex-btw">
              <b>Intend Details</b>
              <div
                className={`intent-status ${
                  intentDetail?.status == INTENT_STATUS.APPROVED
                    ? "grn-color"
                    : ""
                } ${
                  intentDetail?.status == INTENT_STATUS.REJECTED
                    ? "red-color"
                    : ""
                }`}
              >
                {intentDetail?.status}
              </div>
            </div>
          </div>

          <div className="subview">
            <div className="parentCls">
              {userView.generalDetails.map((data, index) => {
                return (
                  <div key={index} className={` pt-1 childCls`}>
                    <div>{data.name}</div>
                    <div>
                      {data.value ? (
                        data.value
                      ) : (
                        <div className="none-text">-</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <Row>
              <Col lg={4}>
                <div className="card ps-0 pe-0 mt-4" id="table-border">
                  <DataTable size='large' value={IntentDetailnondetail} showGridlines tableStyle={{ minWidth: '30rem', padding: "30px" }}>
                    <Column field="partNo" header="Part" className='width-80-per'></Column>
                    <Column field="partQuantity" header="Quantity" className="align-right"></Column>
                  </DataTable>
                </div>
              </Col>
            </Row> */}
            {IntentDetailnondetail?.length > 0 && (
              <Row className="mt-2">
                <Col lg={12}>
                  {IntentDetailnondetail?.map((part: any, index: any) => {
                    return (
                      <Row className="mt-2" key={index}>
                        <div className="view">
                          <div className="general-details">
                            <b>{`Part Number: ${part.partNo}`}</b>
                            <b>{`Quantity: ${part.partQuantity}`}</b>
                          </div>
                          <div className="">
                            {part.intentDeliverables?.length>0 ? 
                            <div className="p-2" id="table-border">
                              <DataTable
                                size="large"
                                 scrollable scrollHeight="500px"
                                value={part.intentDeliverables}
                                showGridlines
                                tableStyle={{
                                  minWidth: "30rem",
                                  padding: "30px",
                                }}
                              >
                                <Column
                                  field="partSkuNo"
                                  header="Part Sku Number"
                                ></Column>
                                <Column field="partNo" header="Part"></Column>
                                <Column
                                  field="received"
                                  header="Received"
                                  body={(rowData: any) =>
                                    statusBodyTemplate(rowData, "received")
                                  }
                                ></Column>
                              </DataTable>
                            </div> : <div className='p-3'>No PartSku Found</div>}
                          </div>
                        </div>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
}

export default ViewIntentComponent