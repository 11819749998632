import { audioBrandsInitialState } from "src/models/pages/audioBrandsModel";
import { AudioBrandsTypes } from "./actionTypes";

const initialState: audioBrandsInitialState = {
  loading: false,
  message: "",
  vehicleBrandNameList: [],
  paginatorCount: 0,
  audioBrandsList: {
    values: [],
    totalRecords: 0
  },
  audioBrandDetails: {},
  error: "",
  audioBrandImage: "",
};
export const brandImageDeleteSuccess = "Audio Brand image is deleted successfully";

const AudioBrands = (state = initialState, action: any) => {
  switch (action.type) {
    case AudioBrandsTypes.GET_AUDIO_BRANDS_LIST:
    case AudioBrandsTypes.CREATE_AUDIO_BRANDS_PENDING:
    case AudioBrandsTypes.UPDATE_AUDIO_BRANDS_PENDING:
    case AudioBrandsTypes.GET_BY_ID_AUDIO_BRANDS_PENDING:
    case AudioBrandsTypes.DELETE_AUDIO_BRANDS_PENDING:
    case AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_LOADING:
    case AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_LOADING:
    case AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_LOADING:
    case AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_LOADING:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        paginatorCount: 0
      };

    case AudioBrandsTypes.GET_AUDIO_BRANDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBrandsList: {
          ...state.audioBrandsList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount
        },
        paginatorCount: action.payload.data.items?.length
      };

      case AudioBrandsTypes.AUDIO_BRAND_FILE_CLEAR:
        return{
          ...state,
          audioBrandImage:""
        }

    case AudioBrandsTypes.DELETE_AUDIO_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo ? "Audio brand is restored successfully" : "Audio brand is deleted successfully",
        error: "",
      }

    case AudioBrandsTypes.CREATE_AUDIO_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Audio brand Created Successfully"
      }

    case AudioBrandsTypes.GET_BY_ID__AUDIO_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        audioBrandDetails: action.payload.data
      }
    case AudioBrandsTypes.UPDATE_AUDIO_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Audio brand Updated Successfully"
      }

    case AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicleBrandNameList: action.payload?.data,
      }

    case AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        audioBrandImage: action.payload,
      };

    case AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: brandImageDeleteSuccess,
        audioBrandImage: "",
      };


    case AudioBrandsTypes.RESET_AUDIO_BRAND:
      return {
        ...state,
        message: "",
        error: ""
      }

    case AudioBrandsTypes.GET_AUDIO_BRANDS_LIST_FAIL:
    case AudioBrandsTypes.CREATE_AUDIO_BRANDS_ERROR:
    case AudioBrandsTypes.UPDATE_AUDIO_BRANDS_ERROR:
    case AudioBrandsTypes.GET_BY_ID__AUDIO_BRANDS_ERROR:
    case AudioBrandsTypes.DELETE_AUDIO_BRANDS_FAIL:
    case AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_ERROR:
    case AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_ERROR:
    case AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_ERROR:
    case AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_ERROR:

      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };
    default:
      return { ...state };
  }
};

export default AudioBrands;
