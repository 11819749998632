import { DataTableFilterMeta } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { ExpensesItem, FranchiseDocumnet } from 'src/models/pages/jobsheetModel';
import { additionalExpenseApproveLoading, additionalExpenseGridLoading, additionalExpensesDocumentLinkLoading, clearAdditionalExpenseDocumentLink, getJobsheetsStatus, jobSheetClearMessage } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { Button } from 'primereact/button';
import { useToast } from 'src/components/Common/ReactToaster';
import { useNavigate, useParams } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { hasAdminOrEmployeeRoleHandler } from 'src/helpers/common_helpers';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { JOB_CLOSED } from 'src/constants/JobSheetStatus';


export interface expenseDataStructure {
  amountWithoutTax: number;
  expensesName: string;
  id: number;
  isApproved: boolean;
  taxCode: string;
  taxPercentage: number;
  jobStatus? : string;
}

const additionalExpenseConversation = (values: ExpensesItem[], franchiseDocument: FranchiseDocumnet[]) => {
  const modifeidValue = values?.map((eachValue) => {

    let data: any = {};
    data['id'] = eachValue?.id;
    data['jobId'] = eachValue?.jobId;
    data['expensesName'] = eachValue?.expensesName;
    data['taxCode'] = eachValue?.taxCode;
    data['taxPercentage'] = eachValue?.taxPercentage;
    data['amountWithoutTax'] = eachValue?.amountWithoutTax;
    data['isApproved'] = eachValue?.isApproved;
    data['netAmount'] = eachValue?.netAmount;
    data['jobStatus'] = eachValue?.jobStatus;
    // data['document'] = franchiseDocument?.length > 0 ? franchiseDocument : []
    data['document'] = eachValue?.document != null ? eachValue?.document : "" 
    return data;
  });
  return modifeidValue;
};

const AdditionalExpensesGrid = () => {
  const { id } = useParams();
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.jobsheetReducer.additionalExpenseList || {});
  const navigate = useNavigate();
  const { error, message, franchiseDocumet, franchiseDocumetLink, additionalExpensesLink,jobSheetStatus } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const modifiedValues: any[] = additionalExpenseConversation(values, franchiseDocumet);

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { showToast } = useToast()
  useEffect(() => {
    dispatch(getJobsheetsStatus(jobSheetStatus))
  }, [])
  const filterDetails: DataTableFilterMeta = {
    JobId: { value: id ? id : "", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const handleView = (rowData: any) => {
    navigate(ROUTER.ADDITIONAL_EXPENSE_VIEW + '/' + rowData.id)
  }
  const handleEdit = (rowData: any) => {
    navigate(ROUTER.ADDITIONAL_EXPENSE_EDIT + '/' + rowData.jobId + '/' + rowData.id)
  }

  const actionBodyTemplate = (rowData: expenseDataStructure, value: string) => {
    return (
      <ColumnAction
        displayData={rowData["expensesName"]}
        isView={true}
        isEdit={(rowData["isApproved"] == true || rowData["jobStatus"] == JOB_CLOSED ) ? false : true}
        onViewClick={() => handleView(rowData)}
        onEditClick={() => handleEdit(rowData)}
      />

    )
  }

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };

  const openDocumet = (id: any) => {
    debugger
    dispatch(additionalExpensesDocumentLinkLoading(id))
  }

  useEffect(() => {
    if (additionalExpensesLink != "" && additionalExpensesLink != undefined && additionalExpensesLink != null) {
      const newWindow = window.open(additionalExpensesLink)
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearAdditionalExpenseDocumentLink())
    }
  }, [additionalExpensesLink])

  const documentBodyContent = (rowData: any, field: any) => {
    return (
      <div className='Franchise_Documnets'>
        {
          // franchiseDocumet?.length > 0 ? franchiseDocumet?.map((data: any, i: number) => {
          //   if (data.mimeType == "application/pdf") {
          //     return (
          //       <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-pdf"></i>
          //     )
          //   } else if (data.mimeType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || data.mimeType == "application/vnd.ms-excel") {
          //     return (
          //       <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-csv"></i>
          //     )
          //   } else if (data.mimeType == "image/png" || data.mimeType == "image/gif" || data.mimeType == "image/svg+xml") {
          //     return (
          //       <i key={i} onClick={() => openDocumet(data.id)} className="fas fas fa-image"></i>
          //     )
          //   }
          // }) : <span style={{ fontSize: "14px" }}>No attachement here</span>
          
          (rowData['document'] !== null && rowData['document'] !== "" ) ? (
            <>
              <i  onClick={() => openDocumet(rowData['document']?.['id'])} className="fas fas fa-image"></i>
            </>
          ) : <span style={{ fontSize: "14px" }}>No attachement here</span>
        }
      </div>
    );
  }

  const approveadditionalExpense = (data: expenseDataStructure) => {
    dispatch(additionalExpenseApproveLoading(data.id))
  }

  const approveBody = (rowData: expenseDataStructure) => {
    return (
      (!rowData["isApproved"] && hasAdminOrEmployeeRoleHandler()) ? <Button style={{ padding: "5px" }}
        className="primary-btn me-2"
        label="Approve"
        type="button"
        onClick={() => { approveadditionalExpense(rowData) }
        }
      /> :
        (!rowData["isApproved"] && !hasAdminOrEmployeeRoleHandler()) ? <Button style={{ padding: "5px" }}
          className="primary-btn me-2"
          label="Approve"
          type="button"
          disabled={true}
        />
          : <>
            <div className='Additional_approved'>
              Approved
            </div>
          </>
    )
  };

  const columns: Columns[] = [
    {
      field: "expensesName",
      sortable: false,
      header: "Expenses Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
      body: (rowData) => actionBodyTemplate(rowData, "expensesName"),
    },
    {
      field: "taxCode",
      sortable: false,
      header: "Tax Code",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "amountWithoutTax",
      sortable: false,
      header: "Amount Without Tax",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "taxPercentage",
      sortable: false,
      header: "Tax Percentage",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "netAmount",
      sortable: false,
      header: "Net Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    
    {
      field: "isApproved",
      sortable: false,
      header: "Is Approved",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      body: (rowData: any) => statusBodyTemplate(rowData, 'isApproved'),
      isStatic: true,
    },
    {
      field: "doucment",
      sortable: false,
      header: "Documents",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      body: (rowData: any) => documentBodyContent(rowData, 'document'),
      isStatic: true,
    },
    {
      field: "action",
      sortable: false,
      header: "Action",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "100px" },
      isDefault: true,
      body: (rowData: any) => approveBody(rowData),
      isStatic: true,
    },
  ];
  const callbackFunction = (lazyState: LazyTableState) => {
    // dispatch(additionalExpenseGridLoading(lazyState));
    dispatch(additionalExpenseGridLoading(id));
  };

  useEffect(() => {
    dispatch(jobSheetClearMessage())
    callbackFunction(lazyState);
  }, [lazyState, message]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
    }
  }, [error, message]);

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const header = <div className="table-header-container">
    <div>Additional Expenses</div>
    <div className="flex-grow-1"></div>
    <div className="table-header-container-item">
      {hasAdminOrEmployeeRoleHandler() &&
        <BreadcrumbWithFilter
          title="Jobsheet List"
          titlePath={ROUTER.JOB_SHEETS_GRID}
          breadcrumbItem={"Additional Expenses"}
          isShowCreate={false}
          isShowFilter={false}
          filter={false}
        />
      }
    </div>

  </div>;

  return (
    <React.Fragment>
      <div className="page-content" id="ticket-system">

        {visible && dialogContainer(error)}
        <DataTableWrapper
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          columnDetails={columns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          // loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Additional Expenses  {last} records of {totalRecords} in total"
          // selection={selectedUsers}
          // onSelectionChange={onSelectionChange}
          // selectAll={selectAll}
          // onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default AdditionalExpensesGrid