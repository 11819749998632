import { StockQuantityBySupplierTypes } from "./actionTypes";
import { StockQuantityBySupplierInitialStore } from "src/models/pages/stockQuantityBySupplier";

const initialValue: StockQuantityBySupplierInitialStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  stockQuantityBySupplierList: {
    values: [],
    totalRecords: 0
  },
  supplierDropdown:""
}

const stockQuantityBySupplier = (state = initialValue, action: any) => {
  switch (action.type) {
    case StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_LOADING:
    case StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }

    case StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        stockQuantityBySupplierList: {
          ...state.stockQuantityBySupplierList,
          values: action.payload.data,
          totalRecords: action.payload?.data?.length
        },
        paginatorCount :action.payload.data?.length 
      }

    case StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        supplierDropdown:action.payload.data
      }

    case StockQuantityBySupplierTypes.GET_STOCK_QUANTITY_BY_SUPPLIER_LIST_ERROR:
    case StockQuantityBySupplierTypes.GET_SUPPLIER_DROPDOWN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state };
  }
}


export default stockQuantityBySupplier;