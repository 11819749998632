import { AdditionalExpenseReportsReducerState } from "src/models/pages/additionalExpenseReportsModel";
import { AddtionalExpensesReports } from "./actionTypes"

const initialState: AdditionalExpenseReportsReducerState = {
  error: "",
  loading: false,
  message: "",
  additionalExpenseReportsList: {
    totalCount: 0,
    values: [],
  },
  additionalExpenseDetails:"",
  paginatorCount: 0
};

const additionaExpenseReportsReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_LOADING:
    case AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_LOADING:
    case AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_LOADING:
    case AddtionalExpensesReports.UPDATE_PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message: ""
      };

    case AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_SUCCESS:
      
      return {
        ...state,
        loading: false,
        additionalExpenseReportsList: {
          values: action.payload.data.items,
          totalCount: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items.length
      }

    case AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        additionalExpenseDetails:action.payload.data
      }
      case AddtionalExpensesReports.UPDATE_PAYMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          message:"Payment Detail Updated Successfully",
          error:""
        };
      case AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_SUCCESS:
        return {
          ...state,
          loading: false,
          message:"Adjustment Point Updated Successfully",
          error:""
        };
    case AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_ERROR:
    case AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_FAIL:
    case AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_FAIL:
    case AddtionalExpensesReports.UPDATE_PAYMENT_FAIL:
      return {
        ...state,
        message: "",
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

export default additionaExpenseReportsReducer;