import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import { Columns, LazyTableState, } from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { saveAsExcelFile } from "src/helpers/common_helpers";
import { vehicleBrands } from "src/models/pages/vehicleBrandsModel";
import { ClearVehicleBrandImage, clearVehicleBrandsMessage, deleteVehicleBrandsFromList, getVehicleBrandsList } from "src/store/vehicleBrands/action";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

export default function VehicleBrands() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.vehicleBrandsReducer.vehicleBrandsList);
  const { error, message, paginatorCount } = useSelector((state: any) => state.vehicleBrandsReducer);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.vehicleBrandsReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<vehicleBrands | null>(null);
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Name",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(ClearVehicleBrandImage())
  }, [])

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.VEHICLE_BRANDS_CREATE);
  };

  const viewHandler = (rowData: vehicleBrands) => {
    navigate(`${ROUTER.VEHICLE_BRANDS_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: vehicleBrands) => {
    navigate(`${ROUTER.VEHICLE_BRANDS_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: vehicleBrands) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };

  const statusBodyTemplate = (rowData: vehicleBrands, field: string) => {
    let status = rowData[field] as string
    return (
      <StatusHghlighter status={status} />
    )

  }

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    // {
    //   label: "Awaiting Login Approval",
    //   value: "Awaiting Login Approval",
    // },
    // {
    //   label: "Awaiting Verification",
    //   value: "Awaiting Verification",
    // },
    // {
    //   label: "Awaiting Confirmation",
    //   value: "Awaiting Confirmation",
    // },
    // {
    //   label: "Active Selected",
    //   value: "Active Selected",
    // },
  ];

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);
  

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearVehicleBrandsMessage());
      setTimeout(() => {
        dispatch(getVehicleBrandsList(lazyState));
      }, 1000);
    }
  }, [error, message]);

	const hidePopup = () => {
		setVisible(false);
	};

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      frozen : true,
    },
    {
      field: "Name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => actionBodyTemplate(rowData, "name"),
      isDefault: true,
      isStatic: true,
    },
   
    // {
    //   field: "action",
    //   sortable: false,
    //   header: "Action",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: actionBodyTemplate,
    //   headerStyle: { whiteSpace: "nowrap",width:"200px" },
    // },

  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { id, name } = eachDealer;
      let data: any = {};
      data['Name'] = name;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
	
    try {
      setPdfLoading(true)
      const response = await API.getVehicleBrandsListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "vehicle_brands");
     }else{
      saveDataAsExcel(response?.data?.items, "vehicle_brands");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(values, "vehicle_brands");
    fetchData(false)
  };


  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let data: any = {};
      data['id'] = eachValue.id;
      data['Name'] = eachValue.name;
      return data
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "vehicle_brands");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getVehicleBrandsList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedVehicleBrands, setSelectedVehicleBrands] = useState([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;

    setSelectedVehicleBrands(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedVehicleBrands(values);
    } else {
      setSelectAll(false);
      setSelectedVehicleBrands([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedVehicleBrands([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedVehicleBrands([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.isDeleted == true ? true : rowData[0]?.isDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deleteVehicleBrandsFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      // showToast("Vehicle Brand Deleted Successfully", { type: "success" });
      setSelectedVehicleBrands([]);
      // setTimeout(() => {
      //   dispatch(getVehicleBrandsList(lazyState));
      // }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false)
    setSelectedVehicleBrands([])
    setArchiveModal(false)
  }

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (values.length > 0) {
      setSelectAll(true);
      setSelectedVehicleBrands(values);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedVehicleBrands([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Vehicle Brands List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Vehicle Brands"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedVehicleBrands}
        bulkActionSatusTitle="Vehicle Brands"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedVehicleBrands.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedVehicleBrands.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT} >
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedVehicleBrands.length > 0 && <BulkActionStatus count={selectedVehicleBrands.length} title="Vehicle  Brands"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile isShowCsv={true} isShowPdf={true} exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem mr-1" />
          </TooltipMEL>
        </div>
        {/* <Button type="button" className="customize-table-button mr-1 ml-1" id="ScheduleUpdateTooltip"
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
        <FeatherIcon icon="sliders" className="" />
        Customize table
      </Button> */}
        {/* <TooltipMEL title="Add Vehicle Brand" position="bottom"> */}
        <div className="reponsive_position">
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Vehicle Brand
          </Button>
        </div>
        {/* </TooltipMEL> */}
        {/* <UncontrolledTooltip
        placement="bottom"
        target="ScheduleUpdateTooltip"
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        style={{
          backgroundColor: '#324f6b', // Change tooltip background color

        }}
      >
        Choose fields you want to see in the table
      </UncontrolledTooltip> */}
      </div>

    </div>;


  return (
    <>
      <div className="page-content" id="view-brand">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedVehicleBrands?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedVehicleBrands([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedVehicleBrands.length == 1) {
              handleDeleteUsers(rowData || selectedVehicleBrands);
            } else if (selectedVehicleBrands.length > 1) {
              for (let i = 0; i < selectedVehicleBrands.length; i++) {
                handleDeleteUsers(selectedVehicleBrands[i], selectedVehicleBrands.length, i);
              }
            }

            // if (selectedVehicleBrands.length > 0) {
            //   for (let i = 0; i < selectedVehicleBrands.length; i++) {
            //     handleDeleteUsers(selectedVehicleBrands[i], selectedVehicleBrands.length, i);
            //   }
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these vehicle brands?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedVehicleBrands([]);
          }}
        />
        {/* {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />} */}

        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          value={values}
          lazy
          paginator={true}
          filterIcon={FilterIcon}
          header={header}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Vehicle Brands  {last} records of {totalRecords} in total"
          selection={selectedVehicleBrands}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
