import { StockRegisterTypes } from "./actionTypes";


export const getStockRegisterList = (lazyState: any) => {
  return {
    type: StockRegisterTypes.GET_STOCK_REGISTER_LIST,
    lazyState,
  };
};

export const getStockRegisterById = (id: any) => {
  return {
    type: StockRegisterTypes.GET_STOCK_REGISTER_BY_ID,
    id:id,
  }; 
};
export const getStockRegisterByIdSuccess = (payload: any) => {
  return {
    type: StockRegisterTypes.GET_STOCK_REGISTER_BY_ID_SUCCESS,
    payload:payload,
  }; 
};
export const getStockREGISTERListSuccess = (payload: any) => {
  return {
    type: StockRegisterTypes.GET_STOCK_REGISTER_LIST_SUCCESS,
    payload: payload,
  };
};

export const getStockRegisterListError = (error: any) => {
  return {
    type: StockRegisterTypes.GET_STOCK_REGISTER_LIST_FAIL,
    payload: error,
  };
};


export const deleteStockREGISTERFromList = (payload:any) => {
  return {
    type: StockRegisterTypes.DELETE_STOCK_REGISTER,
    payload: payload,
  }
}

export const deleteStockREGISTERFromListSuccess = (response:any) => {
  return {
    type: StockRegisterTypes.DELETE_STOCK_REGISTER_SUCCESS,
    payload: response,
  }
}

export const deleteStockRegisterFromListError = (error:any) => {
  return {
    type: StockRegisterTypes.DELETE_STOCK_REGISTER_FAIL,
    payload: error,
  }
}

export const updateStockRegister=(StockREGISTERDetails :any )=>{
  return {
    type : StockRegisterTypes.UPDATE_STOCK_REGISTER,
    payload : StockREGISTERDetails
  }
}

export const updateStockRegisterSuccess=(data:any)=>{
  return {
    type : StockRegisterTypes.UPDATE_STOCK_REGISTER_SUCCESS
  }
}

export const updateStockRegisterError=(error : any)=>{
  return {
    type : StockRegisterTypes.UPDATE_STOCK_REGISTER_ERROR,
    payload : error
  }
}
export const createStockRegister=(data :any )=>{
  
  return {
    type : StockRegisterTypes.CREATE_STOCK_REGISTER,
    payload : data
  }
}
export const createStockRegisterSuccess=(data:any)=>{
  return {
    type : StockRegisterTypes.CREATE_STOCK_REGISTER_SUCCESS
  }
}

export const createStockRegisterError=(error : any)=>{
  return {
    type : StockRegisterTypes.CREATE_STOCK_REGISTER_ERROR,
    payload : error
  }
}

//reset StockREGISTER
export const StockRegisterReset=()=>{
  return {
    type : StockRegisterTypes .RESET_STOCK_REGISTER,
  }
}
