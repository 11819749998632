import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { TabPanel, TabView } from 'primereact/tabview'
import FeatherIcon from "feather-icons-react";
import { getWhatsappDocumentLink, getWhatsappMessageById, resetWhatsappMessageMessage } from 'src/store/WhatsApp/action'
import { Link } from 'react-router-dom'
import FileImg from 'src/assets/images/files.png'
import PdfImg from 'src/assets/images/PDF-file.png'
import ExcelImg from 'src/assets/images/Excel-file.png'
import VideoImg from 'src/assets/images/video-file.png'
import { Image } from 'primereact/image'
const ViewWhatsappMessage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { whatsAppDetails,error, message , loading , whatsAppDocumentLink} = useSelector((state: RootReducerState) => state.WhatsappReducer);
  useEffect(() => {
    dispatch(getWhatsappMessageById(id));
    return()=>{
      dispatch(resetWhatsappMessageMessage())
    }
  }, []);


  const BulkSmsDetails = [
    
    {
      name: "User Type",
      value: whatsAppDetails?.toUserTypes ? <div className='d-flex flex-wrap gap-1'>{whatsAppDetails?.toUserTypes.map((item:any,index:number)=><b key={index}>{`${item}${whatsAppDetails?.toUserTypes?.length < 2 ? "" : index+1 == (whatsAppDetails?.toUserTypes?.length) ? "." : ","} `} </b>)}</div> : ""
    },
    {
      name: "Total Count",
      value: whatsAppDetails?.totalCount ? whatsAppDetails?.totalCount : ""
    },
    {
      name: "Sent Count",
      value: whatsAppDetails?.sentCount ? whatsAppDetails?.sentCount : ""
    },
    {
      name: "Pending Count",
      value: whatsAppDetails?.pendingMobileNumber ? whatsAppDetails?.pendingMobileNumber.length : 0
    },
    {
      name: "Failure Count",
      value: whatsAppDetails?.failureCount ? whatsAppDetails?.failureCount  : 0
    },
    {
      name: "Status",
      value: whatsAppDetails?.status ? whatsAppDetails?.status  : ""
    },
  ]
  useEffect(()=>{
if(whatsAppDetails?.documents?.length>0){

  whatsAppDetails?.documents?.forEach((element:any) => {
    dispatch(getWhatsappDocumentLink(element.id))
  });
}
  },[whatsAppDetails])
  useEffect(()=>{
    
  },[whatsAppDocumentLink])
  const AllSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="mail" className="mr-1 view-bulk-mail-icon" />
            <span className="font-bold white-space-nowrap">All Mobile No's</span>
        </div>
    );
};
  const PendingSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="clock" className="mr-1 view-bulk-mail-icon text-warning" />
            <span className="font-bold white-space-nowrap">Pending Mobile No's</span>
        </div>
    );
};
  const SentSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="send" className="mr-1 view-bulk-mail-icon text-success" />
            <span className="font-bold white-space-nowrap d-none-sm">Sent Mobile No's</span>
        </div>
    );
};
  const FailureSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="thumbs-down" className="mr-1 view-bulk-mail-icon text-danger" />
            <span className="font-bold white-space-nowrap">Failure Mobile No's</span>
        </div>
    );
};
const[documentData,setDocumentData]=useState<any>([])
useEffect(()=>{
let data =  whatsAppDetails?.documents?.map((item:any)=>({
  id:item.id,
  mimeType:item.mimeType,
  name:item.name,
  link:whatsAppDocumentLink?.length>0 ? whatsAppDocumentLink?.find((data:any)=>data.id==item.id)?.link : ""
}))
setDocumentData(data)
},[whatsAppDocumentLink])
useEffect(()=>{

},[documentData])

const imageMimeTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp", "image/avif", "image/tiff", "image/svg+xml"];
const pdfMimeTypes = ["application/pdf"];
const excelMimeTypes = ["application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ];
const videoMimeTypes = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv", "video/mpeg", "video/x-flv", "video/webm", "video/ogg", "video/3gpp", "video/3gpp2" ];
  return (
    <React.Fragment>
      <div className="pagecontent" id='pagecontent'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="WhatsApp"
            titlePath={ROUTER.WHATSAPP_MESSAGE_LIST}
            breadcrumbItem="WhatsApp Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
          />
<Row>
    <div className="view">
            <div className="general-details">
              <b>WhatsApp Details</b>
            </div>
            <div className="subview">
            <div className="parentCls">
            {
              BulkSmsDetails?.map((data, index) => {
                return (
                  <div key={index} className={` pt-2 childCls`}>
                    <div>{data.name}</div>
                    <div>{data.value?data.value:<div className="none-text">-</div>}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        </div>
    </Row>
    <Row>
        <div className="view">
            <div className="general-details">
              <b>Chat Content</b>
            </div>
            <div className="subview">
                <div className="text-bold">{whatsAppDetails?.textContent}</div>

                {whatsAppDetails?.documents?.length>0 ? <b className='mt-2'>Documents:</b> : <></>}
               {documentData?.length>0 && <>
               <div className="document-view">
                {documentData.map((item:any,index:any)=>{
                  if(imageMimeTypes.includes(item.mimeType)){

                    return(
                      <div className="document" key={index}>
                        {/* <img src={item.link ? item.link : ""} alt="document" style={{width:"100px",height:"100px"}}/> */}
                        <Image src={item.link ? item.link : ""} alt="document" className='view-image-whatsapp' preview />
                      </div>
                    )
                  }else if(pdfMimeTypes.includes(item.mimeType)){
                    return(
                      <div className="document" key={index}>
                        <Link to={item.link ? item.link : ""} target='_blank' style={{width:"100px",height:"100px"}}><img src={PdfImg} alt="document" style={{width:"70px",height:"100px"}}/> </Link>
                      </div>
                    )
                  }else if(excelMimeTypes.includes(item.mimeType)){
                    return(
                      <div className="document" key={index}>
                        <Link to={item.link ? item.link : ""} target='_blank' style={{width:"100px",height:"100px"}}> <img src={ExcelImg} alt="document" style={{width:"70px",height:"100px"}}/></Link>
                      </div>
                    )
                  }else if(videoMimeTypes.includes(item.mimeType)){
                    return(
                      <div className="document" key={index}>
                        <Link to={item.link ? item.link : ""} target='_blank' style={{width:"100px",height:"100px"}}> <img src={VideoImg} alt="document" style={{width:"70px",height:"100px"}}/></Link>
                      </div>
                    )
                  }else{
                    return(
                      <div className="document" key={index}>
                        <Link to={item.link ? item.link : ""} target='_blank' style={{width:"100px",height:"100px"}}><img src={FileImg} alt="document" style={{width:"70px",height:"100px"}}/> </Link>
                      </div>
                    )
                  }
                })}
               </div>
               </>}
               </div>
        </div>
    </Row>
    <Row>
        <div className="view" id="bulkEmail">
            <TabView >
                <TabPanel header="All Mobile Numbers" headerTemplate={AllSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                        whatsAppDetails?.allMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${whatsAppDetails?.allMobileNumber?.length < 2 ? "" : index+1 == (whatsAppDetails?.allMobileNumber?.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                  <TabPanel header="Pending Mobile Numbers" headerTemplate={PendingSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       whatsAppDetails?.pendingMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${whatsAppDetails?.pendingMobileNumber?.length < 2 ? "" : index+1 == (whatsAppDetails?.pendingMobileNumber?.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                  <TabPanel header="Sent Mobile Numbers"  headerTemplate={SentSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       whatsAppDetails?.sentMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${whatsAppDetails?.sentMobileNumber?.length < 2 ? "" : index+1 == (whatsAppDetails?.sentMobileNumber?.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                <TabPanel header="Failure Mobile Numbers"  headerTemplate={FailureSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       whatsAppDetails?.failureMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${whatsAppDetails?.failureMobileNumber?.length < 2 ? "" : index+1 == (whatsAppDetails?.failureMobileNumber?.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>

                  </TabView>
            </div>
           
    </Row>
 
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewWhatsappMessage