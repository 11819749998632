import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { formatDateMEL, onlyAdminRoleHandler } from 'src/helpers/common_helpers'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { clearIncomingDispatch, getIncomingDispatchBYID, getReceivedBYID, resetIncomingDispatch } from 'src/store/incomingDispatch/action'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import { useToast } from "src/components/Common/ReactToaster";
import { Link } from 'react-router-dom'
import { getProfile } from 'src/store/profile/actions'
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText'


const ViewIncomingDispatch = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      dispatch(getIncomingDispatchBYID(id));
    }
    return()=>{
      dispatch(clearIncomingDispatch())
    }
  }, []);

  const formValue: any = useSelector((state: RootReducerState) => state.IncomingDispatchReducer.incomingDispatch);
  const { error, message, loading,incomingDispatch,ReceivedById } = useSelector((state: RootReducerState) => state.IncomingDispatchReducer);
  const { profile} = useSelector((state: RootReducerState) => state.profilesReducer);

  const dispatch = useDispatch();
  const { showToast } = useToast();
  useEffect(()=>{
    dispatch(getProfile())
    },[])
  
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }



  
  
  useEffect(()=>{
    if(ReceivedById){
      if(ReceivedById.userType=="Employee"){
        navigate(`${ROUTER.USERS_VIEW}/${ReceivedById.melEmployeeId}`)
      }
      if(ReceivedById.userType=="Franchise"){
        navigate(`${ROUTER.FRANCHISEE_VIEW}/${ReceivedById.franchiseId}`)
      }
      if(ReceivedById.userType=="Dealer"){
        navigate(`${ROUTER.DEALERS_VIEW}/${ReceivedById.dealerId}`)
      }
      if(ReceivedById.userType=="Customer"){
        navigate(`${ROUTER.USERS_VIEW}/${ReceivedById.customerId}`)
      }
    }
      },[ReceivedById])

  const userView = {
    generalDetails: [
      {
        name: "Number",
        value: formValue?.number
      },
      {
        name: "Job Sheet",
        // value: formValue?.jobNumber
        value: <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${formValue?.jobId}`}>{formValue?.jobSheetNumber ? ("Job Sheet: " + formValue?.jobSheetNumber) : "-"}</Link>
      },
      {
        name: "Notes",
        value: formValue?.notes ? <HtmltoPlainText htmlString={formValue?.notes} /> : "" 
      },
     
      {
        name: "Received By",
        value: (onlyAdminRoleHandler() || profile?.extraProperties?.IsAdmin==true ) ? <Link to={``} onClick={()=>{dispatch(getReceivedBYID(formValue.receivedId))}}>{formValue?.receivedByName}</Link> :formValue?.receivedByName
      },
      {
        name: "Received Date",
        value: formatDateMEL(formValue?.receivedDate)
      },
      {
        name: "Courier",
        value: formValue?.courierName ? `${formValue?.courierName} ${formValue?.consigneeName ? `: ${formValue?.consigneeName}` : ''} ${formValue?.awbNumber ? `(${formValue?.awbNumber})` : ''}` : "-" 
      },
      {
        name: "Awb Number",
        value: formValue?.awbNumber ? formValue?.awbNumber : "-" 
      },
    ]
  }

  
  
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetIncomingDispatch());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetIncomingDispatch());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  return (
    <React.Fragment>
       {visible && dialogContainer(error)}
      <div className='userpage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Incoming Dispatch" titlePath={ROUTER.INCOMING_DISPATCH} breadcrumbItem='Incoming Dispatch Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Incoming Dispatch Details</b>
              </div>
              <div className="subview">
              <div className="parentCls">
                  {
                    userView.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewIncomingDispatch