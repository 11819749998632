import { Option, tabOption } from "src/models/components/inputWrapperModel";
import {
  INACTIVE, NEW_JOB, AFCS_GENERATED, EXCHANGE_GENERATED, JOB_ASSIGNABLE_TO_TECHNICIAN, JOB_CANCELLED, JOB_CLOSED,
  JOB_ESTIMATED_AWAITING_APPROVAL, JOB_SHEET_CREATED, INVOICED_JOB, IN_PENDING, ASSIGNED_TO_ESTIMATOR, ASSIGNED_TO_TECHNICIAN,
  READY_TO_INVOICE, IS_EXCHANGE, FACULTY_SYATEM_AVAILABLE, PERMIT_FORM_PENDING, READY_FOR_INSPECTION
} from "./JobSheetStatus";
import { ReactComponent as EstimationSvg } from "src/assets/images/Estimation.svg";
import { ReactComponent as IncomingSvg } from "src/assets/images/Incoming-Courier.svg";
import { ReactComponent as OutGoingSvg } from "src/assets/images/Outgoing-Courier.svg";
import { ReactComponent as PendingCommentsSvg } from "src/assets/images/Pending-Comments.svg";
import { ReactComponent as InfoSvg } from "src/assets/images/Info.svg";
import { ReactComponent as AdditionalExpenseSvg } from "src/assets/images/Expenses.svg";

export const errorMessage = "Company address field required"
export const HomeErrorMessage = "Home address field required"
export const TAX_TYPES: any = [
  { value: 1, label: "IGST" },
  { value: 2, label: "SGST+CGST" },
  { value: 3, label: "UTGST" },
];
export const WARRANTY_TYPES: Option[] = [
  { label: "In Warranty", value: "In Warranty" },
  { label: "Out Warranty", value: "Out Warranty" },
  { label: "Warranty Unknown", value: "Warranty Unknown" },
  { label: "Manufacturer Warranty", value: "Manufacturer Warranty" },
]

export const TAX_TYPES_OBJECT: any = {
  1: "IGST",
  2: "SGST+CGST",
  3: "UTGST"
}

export const JOB_RECEIVED_MODE: Option[] = [
  { label: "To Pay", value: "To Pay" },
  { label: "Paid", value: "Paid" },
  { label: "Hand Carry", value: "Hand Carry" },
  { label: "Field service", value: "Field service" },
]

export const TABOPTIONS: tabOption[] = [
  {
    label: "Job Sheet Information",
    value: "JOB_SHEET_INFORMATION",
    access: [NEW_JOB, AFCS_GENERATED, EXCHANGE_GENERATED, JOB_SHEET_CREATED, ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL, JOB_ASSIGNABLE_TO_TECHNICIAN, ASSIGNED_TO_TECHNICIAN, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, IS_EXCHANGE, FACULTY_SYATEM_AVAILABLE, PERMIT_FORM_PENDING, IN_PENDING],
    icon: InfoSvg
  },
  /*  {
     label: "Job Sheet Details", value: "JobSheetDetails", access: [NEW_JOB, AFCS_GENERATED,
     EXCHANGE_GENERATED, JOB_SHEET_CREATED, ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL,
     JOB_ASSIGNABLE_TO_TECHNICIAN, ASSIGNED_TO_TECHNICIAN, READY_TO_INVOICE, READY_FOR_INSPECTION
       , INVOICED_JOB, IS_EXCHANGE, FACULTY_SYATEM_AVAILABLE, PERMIT_FORM_PENDING,
     IN_PENDING], icon: 'grid'
     }, */
  {
    label: "Estimate Details",
    value: "ESTIMATE_DETAILS",
    access: [ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL, JOB_ASSIGNABLE_TO_TECHNICIAN, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, PERMIT_FORM_PENDING, ASSIGNED_TO_TECHNICIAN],
    icon: EstimationSvg
  },
  {
    label: "Incoming Courier",
    value: "INCOMING_COURIER",
    access: [PERMIT_FORM_PENDING, AFCS_GENERATED, JOB_SHEET_CREATED, ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL, JOB_ASSIGNABLE_TO_TECHNICIAN, ASSIGNED_TO_TECHNICIAN, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, IS_EXCHANGE, FACULTY_SYATEM_AVAILABLE, IN_PENDING],
    icon: IncomingSvg
  },
  {
    label: "Inspection Information",
    value: "InspectionInformation",
    access: [FACULTY_SYATEM_AVAILABLE, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, IS_EXCHANGE], icon: "layout"
  },
  {
    label: "Outgoing Courier",
    value: "OUTGOING_COURIER",
    access: [IS_EXCHANGE, EXCHANGE_GENERATED, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, FACULTY_SYATEM_AVAILABLE],
    icon: OutGoingSvg
  },
  {
    label: "Pending Comments",
    value: "PENDING_COMMENTS",
    access: [NEW_JOB, AFCS_GENERATED, EXCHANGE_GENERATED, JOB_SHEET_CREATED, ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL, JOB_ASSIGNABLE_TO_TECHNICIAN, ASSIGNED_TO_TECHNICIAN, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, IS_EXCHANGE, FACULTY_SYATEM_AVAILABLE, PERMIT_FORM_PENDING, IN_PENDING],
    icon: PendingCommentsSvg
  },
  {
    label: "Additional Expenses",
    value: "ADDITIONAL_EXPENSES",
    access: [NEW_JOB, AFCS_GENERATED, EXCHANGE_GENERATED, JOB_SHEET_CREATED, ASSIGNED_TO_ESTIMATOR, JOB_ESTIMATED_AWAITING_APPROVAL, JOB_ASSIGNABLE_TO_TECHNICIAN, ASSIGNED_TO_TECHNICIAN, READY_TO_INVOICE, READY_FOR_INSPECTION, INVOICED_JOB, IS_EXCHANGE, FACULTY_SYATEM_AVAILABLE, PERMIT_FORM_PENDING, IN_PENDING],
    icon: AdditionalExpenseSvg
  }
]

export const TAB_ADD_OPTIONS: tabOption[] = [
  { label: "Job Sheet Information", value: "JobSheetInformation", access: [], icon: InfoSvg },
  { label: "Pending Comments", value: "PendingComments", access: [], icon: PendingCommentsSvg }
]

export const CUSTOMER_TAB_OPTIONS: tabOption[] = [
  { label: "Job Sheet Information", value: "JobSheetInformation", access: [], icon: InfoSvg },
]

export const ACTIVE = "Active"
export const DELETED = "Deleted"
export const EMAIL_VERIFIED = "Email verified"
export const UNLOCKED = "Unlocked"
export const LOCKED = "Locked"
export const HANDCARRY = "handCarry"
export const COURIER = "courier"
export const PACKAGESENT = "packageSent"
export const PACKAGENOTSENT = "packageNotSent"
export const ALL = "all"
export const PAYMENTPENDING = "paymentPending"

export const FILE_UPLOAD_SIZE = 5000000 // file size to upload upto 5MB
export const FILE_UPLOAD_SIZE_2mb = 2097152 // file size to upload upto 2MB


export const INTENT_STATUS = {
  REJECTED: "Rejected",
  REQUESTED: "Requested",
  RECEIVED: "Received",
  APPROVED: "Approved",
  COURIERED: "Couriered",
  PENDING_DISPATCH: "PendingDispatch",
  PENDING_RECEIVED: "PendingReceived",
}


export const JOB_SHEET_TAB_AND_FIELD = [
  {
    tabName: "Job Sheet Information",
    fieldName: ["number", "dealerId", "vehicleBrandId", "permitFormReceivedDate", "estimatorGUId", "technicianGUId", "appointmentTime", "warrantyStatus", "customerId", "dealerAddress", "customerAddress", "serviceLocation", "franchiseNotes", "isExchange", "isFaultySystemAvailable", "franchiseArrived", "vinNumber", "vehicleRegistrationNumber", "vehicleModel", "afcsReceivedDate", "requestDate", "audioModel", "serviceCharge", "serviceChargeTaxPercentage", "serviceChargeTaxAmount", "serviceChargeTotalWithTax", "exchangeAudioSerialNumber", "audioSerialNumber", "historicalJobsForAudioSystem", "audioPartNumber", "dateOfSale", "manufacturedDate", "failedDate", "lastRepairDate", "kmsCovered", "preparedByName", "customerConcern", "dealerObservation", "accessoriesList", "missingAccessories", "additionalRemarks", "melObservations", "completeServiceInformation", "packingComplete", "cleaningComplete", "packagedAt",]
  },
  {
    tabName: "Estimate Details",
    fieldName: ["estimatorComments", "updatedByName", "estimateTotal", "taxTypeId", "approveStatus", "approvalMode", "approvalComments", "partId", "quantityRequired", "unitPrice", "name", "taxDetails", "partsDetails"]
  },
  {
    tabName: "Incoming Courier",
    fieldName: ["jobReceivedMode", "handCarriedPersonName", "handCarriedPersonDesignation", "courierReceivedAt", "courierPackingCondition", "courierAmount", "courierChequeNumber", "courierBankName", "incomingDispatchDto_receivedDate", "incomingDispatchDto_receivedId", "incomingDispatchDto_courierId", "incomingDispatchDto_notes"]
  },
  {
    tabName: "Pending Comments",
    fieldName: ["inPending", "pendingReasonId", "commentedAt", "pendingInformation", "pendingCommentsDto","pendingCommentsDtoNew"]
  },
  {
    tabName: "Additional Expenses",
    fieldName: ["expenseName", "taxCode", "taxPercentage", "amountWithTax", "files", "zeroAdditional"]
  },
  {
    tabName: "Inspection Information",
    fieldName: ["packingComplete", "cleaningComplete", "packagedAt", "inspectedBy", "inspectionCompletedAt", "comments"]
  },
  {
    tabName: "Outgoing Courier",
    fieldName: ["packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById", "approximateValue", "courierId", "notes"]
  }
]