import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumb from 'src/components/Common/Breadcrumb'
import PeopleManagaFormWrapper from 'src/components/Common/Form/PeopleManagementFormWrapper'
import InputCom from 'src/components/Common/Input'
import { administratorformFields } from 'src/constants/administratorFormFields'
import { ROUTER } from 'src/constants/routes';
import { FieldModal } from 'src/models/components/inputWrapperModel'
import * as Yup from 'yup';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Button from 'src/components/UI/Button';
import CheckboxInput from "src/components/UI/CheckboxInput";

const EditAdministrator: React.FC = () => {
  const { id } = useParams()
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const navigate:any=useNavigate();
  const initial = {
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    passwordConfirmation: "",
    detailsVerified: false
  }

  const handleSubmit = async (values: any) => {
    const submittedData = {
      uploadFile: selectedFiles,
      ...values
    }
    
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    mobileNumber: Yup.string().required('Mobile Number is required'),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().required('Password Confirmation is required'),

  });

  const getInitialValue = () => {
    if (id) {
      return {
        name: "karthik",
        email: "karthikpandi901@gmail.com",
        mobileNumber: "8685848710",
        password: "123456",
        passwordConfirmation: "123456",
        detailsVerified: true
      }
    } else {
      return initial
    }
  }

  const initialvalue = getInitialValue();

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeIndex = (i: number) => {
    setselectedFiles((prevFiles: any) => {
      const newFiles = [...prevFiles];
      newFiles.splice(i, 1);
      return newFiles;
    });
  }

const handleReset = (resetForm: any) => {
  resetForm(getEmptyInitialValues());
  if(id){
    navigate(ROUTER.ADMINISTRATOR_GRID)
  }
}
const getEmptyInitialValues = () => {
  const initialValue = localFormFields?.reduce((acc: any, field: any) => {
    if (field.type !== 'checkbox') {
      acc[field.name] = '';
    } else {
      acc[field.name] = false;
    }
    return acc;
  }, {});
  return initialValue;
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Administrator" titlePath={ROUTER.ADMINISTRATOR_GRID}  breadcrumbItem={id ? "Edit Administrator" : "Create Administrator"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["name"] && errors["name"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["email"] && errors["email"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Mobile Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="mobileNumber"
                                onBlur={handleBlur}
                                value={values.mobileNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["mobileNumber"] && errors["mobileNumber"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Password"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["password"] && errors["password"] ? true : false}
                              />
                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Password Confirmation"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="passwordConfirmation"
                                onBlur={handleBlur}
                                value={values.passwordConfirmation}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["passwordConfirmation"] && errors["passwordConfirmation"] ? true : false}
                              />
                            </Col>
                            <Col lg={4} className="mb-2">
                              <CheckboxInput
                                name="detailsVerified"
                                label="Details Verified"
                                value={values["detailsVerified"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>

                          </Row>

                          <Row>

                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Dropzone
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles);
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick mt-2"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                          </div>
                                          <h4>Drop files here or click to upload.</h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div className="dropzone-previews mt-3" id="file-previews">
                                    {selectedFiles.map((f: any, i: number) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col className='col-auto'>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{f.formattedSize}</strong>
                                                </p>
                                              </Col>
                                              <Col className='remove-index'>
                                                <div>
                                                  <FeatherIcon onClick={() => removeIndex(i)} icon="x-square" />
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          {/* <div className="d-flex mt-2">
                            <Button
                              className="btn-secondary mt-2"
                              label={id ? "Update Administrator" : "Create Administrator"}
                              type="submit"
                            />
                          </div> */}
                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              onClick={()=>handleReset(resetForm)}
                              type="button"
                            />
                            <Button
                              className="btn-primary me-2"
                              label={id ? "Update Administrator" : "Create Administrator"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>

  )
}

export default EditAdministrator