import { FieldArray } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import InputCom from 'src/components/Common/Input';
import DropdownInput from 'src/components/UI/DropdownInput';
import TooltipMEL from 'src/components/UI/Tooltip';
import { getInvoiceOnchnageLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { API } from 'src/util/api';

interface TaxesListPageProps {
  values: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  setFieldValue: any;
  touched: any;
  handleChangeAmount: any;
  handleAddTaxes: any;
  handleChangeQuantity?:any;
}

const TaxesDetails = ({ values, handleChange, handleBlur, errors, setFieldValue, touched, handleChangeAmount, handleAddTaxes,handleChangeQuantity }: TaxesListPageProps) => {
  const { taxesList } = useSelector((state: RootReducerState) => state.invoiceReducer);
  const { id, job_id, edit_job_id } = useParams();
  const dispatch = useDispatch();


  const taxDrop = taxesList?.length > 0 ? (taxesList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.name;
    data['label'] = eachTax.name;
    data['id'] = eachTax.id;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []


  useEffect(() => {
    debugger
    const fetchTaxDetails = async () => {
      if (values.taxes?.length === 0 && taxesList?.length > 0) {
        let loopLength = taxesList.length >= 5 ? 5 : taxesList.length;
        for (let index = 0; index < loopLength; index++) {
          const element = taxesList[index];
          setFieldValue(`taxes.${index}.name`, element?.name);

          try {
            const response = await API.getTaxByIdForJobAPI(element?.id);
            setFieldValue(`taxes.${index}.id`, 0);
            setFieldValue(`taxes.${index}.sacNo`, response?.data[0]?.sacNumber);
            setFieldValue(`taxes.${index}.taxPercentage`, response?.data[0]?.taxPercentage);
            setFieldValue(`taxes.${index}.quantity`, 0);
            setFieldValue(`taxes.${index}.unitPrice`, 0);
            setFieldValue(`taxes.${index}.amount`, 0);
            setFieldValue(`taxes.${index}.totalAmount`, 0);
            setFieldValue(`taxes.${index}.taxAmount`, 0);

          } catch (error) {
            console.error("Error fetching tax details:", error);
            // Handle error as needed
          }
        }
        values.taxes = [taxesList?.filter((eachList: any, index: number) => {
          return index < 5;
        })]
      }
    };
    if(!edit_job_id && !job_id){
      fetchTaxDetails();
    }
    
  }, [taxesList])


  return (
    <React.Fragment>
      <Row>
        <Col lg={12} className="dealer-title-header">
          <div>Taxes</div>
        </Col>
        <FieldArray name='taxes'>
          {({ insert, remove, push }) => (
            <div style={{ paddingBottom: "10px" }}>
              {values.taxes?.length > 0 && values?.taxes?.map((value: any, index: any) => {
                const isFirstContact = index === 0;
                return <div key={index}>
                  <div key={index} className="dynamic-form-container">
                    <div className="space-equally">
                      <DropdownInput
                        options={taxDrop}
                        name={`taxes[${index}].name`}
                        label="Name"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        onChange={(e:any)=>{
                          handleChange(e)

                          if (e.target.value) {
                            let dataId: any = taxDrop?.filter((eachName: any) => eachName.label == e.target.value)?.[0]?.id
                            dispatch(getInvoiceOnchnageLoading(dataId, index, setFieldValue, value,values))
                          }
                        }}
                        required={true}
                        value={value?.name}
                        // onBlur={handleBlur}
                        onBlur={(e: any) => {
                          handleBlur(e)
                          
                        }}
                        disabled={false}
                        invalid={touched.taxes?.[index]?.name && errors.taxes?.[index]?.name ? true : false}
                      />
                    </div>
                    <div className="space-equally">
                      <InputCom
                        labelName="Sac Number"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="text"
                        name={`taxes[${index}].sacNo`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isRequired={true}
                        value={value?.sacNo}
                        invalid={touched.taxes?.[index]?.sacNo && errors.taxes?.[index]?.sacNo ? true : false}
                      />
                    </div>

                    <div className="space-equally">
                      <InputCom
                        labelName="Quantity"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="number"
                        name={`taxes[${index}].quantity`}
                        onBlur={(e) => {
                          handleBlur(e)
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === "+" || e.key === "-" || e.key === "e") {
                            e?.preventDefault();
                          }
                        }}
                        onChange={(e:any)=>{handleChange(e)
                          handleChangeQuantity(index, value?.unitPrice, value?.taxPercentage, setFieldValue, values.taxes, values.partSku, values,e.target.value)

                        }}
                        isRequired={true}
                        value={value?.quantity}
                        invalid={touched.taxes?.[index]?.quantity && errors.taxes?.[index]?.quantity ? true : false}
                      />
                    </div>
                    <div className="space-equally">
                      <InputCom
                        labelName="Unit Price"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="number"
                        name={`taxes[${index}].unitPrice`}
                        onBlur={(e) => {
                          handleBlur(e)
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === "+" || e.key === "-" || e.key === "e" ) {
                            e?.preventDefault();
                          }
                        }}
                        onChange={(e:any)=>{handleChange(e)
                          handleChangeAmount(index, e.target.value, value?.taxPercentage, setFieldValue, values.taxes, values.partSku, values,value.quantity)
                          
                        }}
                        isRequired={true}
                        value={value?.unitPrice}
                        invalid={touched.taxes?.[index]?.unitPrice && errors.taxes?.[index]?.unitPrice ? true : false}
                      />
                    </div>
                    <div className="space-equally">
                      <InputCom
                        labelName="Amount"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="number"
                        name={`taxes[${index}].amount`}
                        onBlur={(e) => {
                          handleBlur(e)
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === "+" || e.key === "-" || e.key === "e" ) {
                            e?.preventDefault();
                          }
                        }}
                        onChange={(e:any)=>{handleChange(e)
                          handleChangeAmount(index, e.target.value, value?.taxPercentage, setFieldValue, values.taxes, values.partSku, values)


                        }}
                        disabled={true}
                        isRequired={true}
                        value={value?.amount}
                        invalid={touched.taxes?.[index]?.amount && errors.taxes?.[index]?.amount ? true : false}
                      />
                    </div>
                    <div className="space-equally">
                      <InputCom
                        labelName="Tax Percentage"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="number"
                        name={`taxes[${index}].taxPercentage`}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e)
                          handleChangeAmount(index, value?.unitPrice, e.target.value, setFieldValue, values.taxes, values.partSku, values,value.quantity)
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === "+" || e.key === "-" || e.key === "e") {
                            e?.preventDefault();
                          }
                        }}
                        isRequired={true}
                        value={value?.taxPercentage}
                        invalid={touched.taxes?.[index]?.taxPercentage && errors.taxes?.[index]?.taxPercentage ? true : false}
                      />
                    </div>
                    <div className="space-equally">
                      <InputCom
                        labelName="Tax Amount"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="number"
                        name={`taxes[${index}].taxAmount`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isRequired={true}
                        disabled={true}
                        value={value?.taxAmount}
                        invalid={touched.taxes?.[index]?.taxAmount && errors.taxes?.[index]?.taxAmount ? true : false}
                      />
                    </div>
                    <div className="space-equally">
                      <InputCom
                        labelName="Total Amount"
                        inputclassName="form-control"
                        labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                        type="number"
                        name={`taxes[${index}].totalAmount`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isRequired={true}
                        disabled={true}
                        value={value?.totalAmount}
                        invalid={touched.taxes?.[index]?.totalAmount && errors.taxes?.[index]?.totalAmount ? true : false}
                      />
                    </div>
                    <div className="space-equally-last" >
                      <div className="space-equally-2">
                        <TooltipMEL title="Remove Tax" position='bottom'>
                          <i className="fas fa-times-circle color-red"
                            onClick={() => {
                              handleChangeAmount(index, 0, 0, setFieldValue, values.taxes, values.partSku, values,0)
                              remove(index);
                            }
                            }>
                          </i>
                        </TooltipMEL>
                      </div>
                    </div>
                  </div>
                </div>
              })}
              <div>
                <TooltipMEL title="Add Taxes" position='bottom'>
                  <div className="add-btn-container" onClick={() => {
                    handleAddTaxes(values, push);
                  }}>
                    <i className="fas fa-plus-circle"></i><span>Add</span>
                  </div>
                </TooltipMEL>
              </div>
            </div>
          )}
        </FieldArray>
          <div></div>
      </Row>
    </React.Fragment>
  )
}

export default TaxesDetails