
import { FranchiseeInitialState } from "src/models/pages/franchiseeModel";
import { FranchiseeType } from "./actionTypes";

const initialState: FranchiseeInitialState = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  franchiseeList: {
    totalCount: 0,
    items: []
  },
  franchiseeDetails: {
    companyName: "",
    user: undefined,
    isDeleted: false,
    creationTime: "",
    creatorId: "",
    id: 0
  },
  franchiseImage: "",
  getUploadedList : [],
  documentLink : "",
  franchisePhoneMessage:"",
  franchiseMailMessage:"",
  editedMobileNumber:"",
  createdData:"",
  otpmessage:"",
  updatedmessage:"",
  companyLatLng:"",
  homeLatLng:""
}


export const franchiseImageDeleteSuccess = "Franchise image is deleted successfully"
export const franchisedocumentDeleteSuccess = "Document is deleted successfully"

const Franchisee = (state = initialState, action: any) => {
  switch (action.type) {
    case FranchiseeType.GET_FRANCHISEE_LIST_LOADING:
    case FranchiseeType.CREATE_FRANCHISEE_LOADING:
    case FranchiseeType.GET_FRANCHISEEBYID_LOADING:
    case FranchiseeType.UPDATE_FRANCHISEE_LOADING:
    case FranchiseeType.DELETE_FRANCHISEE_LOADING:
    case FranchiseeType.FRANCHISEE_FILE_DELETE_LOADING:
    case FranchiseeType.FRANCHISEE_FILE_FETCH_LOADING:
    case FranchiseeType.FRANCHISEE_FILE_UPLOAD_LOADING:
    case FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_LOADING:
    case FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_LOADING:
    case FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_LOADING:
    case FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_LOADING:
    case FranchiseeType.FRANCHISE_SET_PHONE_LOADING:
    case FranchiseeType.FRANCHISE_SET_EMAIL_LOADING:
    case FranchiseeType.SEND_OTP_FRANCHISE:
    case FranchiseeType.RESEND_FRANCHISE_OTP:
    case FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_LOADING:
    case FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
        paginatorCount: 0
      }
    case FranchiseeType.GET_FRANCHISEE_LIST_SUCCESS:
      return {
        loading: false,
        franchiseeList: {
          ...state.franchiseeList,
          items: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        },
        paginatorCount: action.payload.data.items.length
      }
    case FranchiseeType.CREATE_FRANCHISEE_SUCCESS:
      console.log(action.payload.data);
      
      return {
        ...state,
        loading: false,
        franchiseeList: {
          ...state.franchiseeList,
          items: [action.payload.data],
        },
        message: "Created Franchise Successfully",
        error: "",
        createdData:action.payload.data
      }

    case FranchiseeType.UPDATE_FRANCHISEE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedmessage: "Updated Franchise Successfully",
        error: ""
      }

    case FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        companyLatLng:action.payload.latLag.data
      }

    case FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        homeLatLng:action.payload.latLag.data
      }
      case FranchiseeType.FRANCHISEE_FILE_CLEAR:
        return{
          ...state,
          franchiseImage:""
        }

    case FranchiseeType.GET_FRANCHISEEBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        franchiseeList: {
          ...state.franchiseeList,
        },
        franchiseeDetails: action.payload.data,
        franchisePhoneMessage:"",
        franchiseMailMessage:""
      };

    case FranchiseeType.DELETE_FRANCHISEE_SUCCESS:
      
      return {
        ...state,
        loading: false,
        error: "",
        message: action.undo ? "Franchise is restored successfully" : "Franchise is deleted successfully",
      }

    case FranchiseeType.RESET_FRANCHISEE:
      debugger
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        paginatorCount: 0,
        franchiseeList: {
          totalCount: 0,
          items: []
        },
        franchiseeDetails: {
          companyName: "",
          user: undefined,
          isDeleted: false,
          creationTime: "",
          creatorId: "",
          id: 0
        },
        franchiseImage: "",
        getUploadedList : [],
        documentLink : "",
        companyLatLng:"",
        homeLatLng:""
      }
    case FranchiseeType.CLEAR_FRANCHISEE_MESSAGE:
      debugger
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        otpmessage:"",
        updatedmessage:""
      }

    case FranchiseeType.FRANCHISEE_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };

    case FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };

    case FranchiseeType.FRANCHISEE_FILE_FETCH_SUCCESS:

      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        franchiseImage: action.payload.data,
      };

    case FranchiseeType.FRANCHISEE_FILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: franchiseImageDeleteSuccess,
        dealerImage: "",
      };

    case FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        getUploadedList : action.payload.data
      };

    case FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message : franchisedocumentDeleteSuccess
      };

    case FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        documentLink : action.payload.data
      };
    case FranchiseeType.FRANCHISE_SET_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        franchisePhoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number

      };
    case FranchiseeType.RESEND_FRANCHISE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        franchisePhoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number

      };
    case FranchiseeType.FRANCHISE_SET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        franchiseMailMessage:"Email Id Updated Successfully"
      };
      case FranchiseeType.SEND_OTP_FRANCHISE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: "",
          otpmessage: "OTP Verified Successfully",
          editedMobileNumber:""
        };
      case FranchiseeType.RESET_FRANCHISE_OTP_MESSAGE:
        return {
          ...state,
          loading: false,
          error: "",
          otpmessage: "",
          editedMobileNumber:"",
          franchiseMailMessage:"",
          franchisePhoneMessage:"",
          updatedmessage:"",
          createdData :"",
        };
    case FranchiseeType.DELETE_FRANCHISEE_ERROR:
    case FranchiseeType.FRANCHISEE_FILE_DELETE_ERROR:
    case FranchiseeType.FRANCHISEE_FILE_FETCH_ERROR:
    case FranchiseeType.FRANCHISEE_FILE_UPLOAD_ERROR:
    case FranchiseeType.UPDATE_FRANCHISEE_ERROR:
    case FranchiseeType.GET_FRANCHISEEBYID_ERROR:
    case FranchiseeType.CREATE_FRANCHISEE_ERROR:
    case FranchiseeType.GET_FRANCHISEE_LIST_ERROR:
    case FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_ERROR:
    case FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_ERROR:
    case FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_ERROR:
    case FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_ERROR:
    case FranchiseeType.FRANCHISE_SET_PHONE_ERROR:
    case FranchiseeType.FRANCHISE_SET_EMAIL_ERROR:
    case FranchiseeType.SEND_OTP_FRANCHISE_ERROR:
      case FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_ERROR:
    case FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        franchisePhoneMessage:""
      }
    default:
      return { ...state }
  }
}

export default Franchisee