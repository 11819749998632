import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { UserDetails } from 'src/models/components/ViewUserDetails'
import { Fieldset } from 'primereact/fieldset'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { formatDateMEL, formatDateTOYYYYMMDD } from 'src/helpers/common_helpers'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { Image } from 'primereact/image'
import FeatherIcon from "feather-icons-react";
import { clearEmployeeProfileMessage, employeeDocumentProfileGetLoading, generateEmployeeDocumentLinkLoading, getEmployeeIdLoading, getEmployeeImageProfileLoading, getEmployeeProfileLoading, resetEmployeeProfile } from 'src/store/EmployeeProfile/action'
import { useToast } from 'src/components/Common/ReactToaster'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'

const EmployeeProfile = () => {

    const{ProfileIdData,error, message, loading,employeedata, employeeImage, documentList, generateLink }=useSelector( (state: RootReducerState) => state.employeeProfileReducer)
    // console.log("Documenmts ---.",documentList)
    const { showToast } = useToast();
  const navigate = useNavigate()

  useEffect(() => {
dispatch(getEmployeeIdLoading())

    return () => {
      dispatch(resetEmployeeProfile())
    }
  }, []);

  useEffect(() => {
    if (generateLink != "" && generateLink != undefined) {
      const newWindow = window.open(generateLink)
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
    }
  }, [generateLink])
  const [id,setId]=useState("")
  useEffect(()=>{
    if(ProfileIdData){
    setId(ProfileIdData?.extraProperties?.MelEmployeeId)
    }
  },[ProfileIdData])
  useEffect(()=>{
    if(employeedata){
    setFormValue(employeedata)
    }
  },[employeedata])
  useEffect(()=>{


 if(id){
  dispatch(getEmployeeProfileLoading(id))
  dispatch(getEmployeeImageProfileLoading(id))
  dispatch(employeeDocumentProfileGetLoading(id))
 }
  },[id])
  const editUserClick = () => {
    navigate(`${ROUTER.USERS_EDIT}/${id}`)
  }

  const [formValue,setFormValue]=useState<any>()



  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )
  }

  const formatTime = (time:any)=>{
    const dateTime =new Date(`1970-01-01T${time}`)
    const formattedTime = dateTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
  });
  return formattedTime
  }

  const userView = {
    generalDetails: [
      {
        name: "Name",
        value: formValue?.['user']?.['name'] //use
      },
      {
        name: "User Name",
        value: formValue?.['user']?.["userName"] //use
      },
      {
        name: "Official Email",
        value: formValue?.['user']?.["email"] //use
      },
      {
        name: "Personal Email",
        value: formValue?.['personalEmail'] //use
      },
      {
        name: "Mobile",
        value: formValue?.['user']?.["phoneNumber"] //use
      },
      {
        name: "Date of Birth",
        value: formValue?.["user"]?.['extraProperties']?.['DateOfBirth'] ? formatDateMEL(formValue?.["user"]?.['extraProperties']?.['DateOfBirth']) : "" //use
      },
      {
        name: "Wedding Date",
        value: formValue?.["user"]?.['extraProperties']?.['WeddingDate'] ? formatDateMEL(formValue?.["user"]?.['extraProperties']?.['WeddingDate']) : "" //use
      },
      {
        name: "Address",
        value: formValue?.["user"]?.['extraProperties']?.["Address"] //use
      },
      {
        name: "In Time",
        value: formValue?.['inTime'] ? formatTime(formValue?.['inTime']) : "" //use
      },
      {
        name: "Out Time",
        value: formValue?.["outTime"] ? formatTime(formValue?.["outTime"]) : "" //use
      },
    ],
    otherDetails: [
      {
        name: "Father / Husband Name",
        value: formValue?.["fatherName"] //use
      },
      {
        name: "Mother Name",
        value: formValue?.["motherName"] //use
      },
      {
        name: "Educational Qualification",
        value: formValue?.["educationalQualification"] //use
      },
      {
        name: "Blood Group",
        value: formValue?.["bloodGroup"] //use
      },
      {
        name: "Joining Date",
        value: formValue?.joiningDate ? formatDateMEL(formValue?.joiningDate) : "" //use
      },
      // {
      //   name: "GST No",
      //   value: formValue?.["user"]?.['extraProperties']?.['GstNumber'] //use
      // },
      {
        name: "Aadhar No",
        value: formValue?.["aadharCardNo"] //use
      },
      {
        name: "PAN No",
        value: formValue?.["user"]?.['extraProperties']?.["PANCardNo"] //use
      },
      {
        name: "ESI No",
        value: formValue?.["esiNo"] //use
      },
      {
        name: "PF No",
        value: formValue?.["pfNo"] //use
      },
      {
        name: "Alternate Number",
        value: formValue?.["alternateContactMobileNo"] //use
      },
      {
        name: "Residence Contact Number",
        value: formValue?.["residenceContactNumber"] //use
      },
      {
        name: "Alternative Address",
        value: formValue?.["user"]?.['extraProperties']?.['HomeAddress'] //use
      },


      {
        name: "Send SMS",
        value: statusBodyTemplate(formValue?.["user"]?.['extraProperties']?.["SendSms"]) //use
      },

      {
        name: "Send Email",
        value: statusBodyTemplate(formValue?.["user"]?.['extraProperties']?.["SendEmail"]) //use
      },
      {
        name: "Send Whatsapp",
        value: statusBodyTemplate(formValue?.["user"]?.['extraProperties']?.["SendWhatsapp"]) //use
      },
      {
        name: "Lockout Enabled",
        value: statusBodyTemplate(formValue?.["user"]?.['lockoutEnabled']) //use
      },
      {
        name: "Mobile Number Verified",
        value: statusBodyTemplate(formValue?.["user"]?.['phoneNumberConfirmed']) //use
      },

    ],
    paySlipInfo:[
      {
        name: "Basic Pay",
        value: formValue?.["basic"] 
      },
      {
        name: "DA",
        value: formValue?.["da"] 
      },
      {
        name: "HRA",
        value: formValue?.["hra"] 
      },
      {
        name: "Special Allowance 1",
        value: formValue?.["specialAllowance1"] 
      },
      {
        name: "Special Allowance 2",
        value: formValue?.["specialAllowance2"] 
      },
      {
        name: "Employee Number",
        value: formValue?.["employeeNo"] 
      },
    ]
  }

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleDownload = (data : any) => {
    debugger
    dispatch(generateEmployeeDocumentLinkLoading(data?.id))
  }
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true)
    }
    if (message !== "" && message != undefined) {
      showToast(message, { type: "success" });
        dispatch(clearEmployeeProfileMessage())
        if (id) {
          dispatch(getEmployeeProfileLoading(id))
        }
      
 
    

    }
  }, [error, message])

  return (
    <React.Fragment>
      <div className='userpage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Profile" titlePath={ROUTER.PROFILE} breadcrumbItem='Employee Profile'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={false}
            firstButtonLabel='Edit Profile'
            onFirstButtonClick={editUserClick}
          />
                {visible && dialogContainer(error)}
                <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
                <Row>
                  <Col lg={6} >
                    <div className="parentCls2">
                      {
                        userView.generalDetails.map((data, index) => {
                          return (
                            <div key={index} className={` pt-1 childCls`}>
                              <div>{data.name}</div>
                              <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                            </div>
                          )
                        })
                      }
                      {/* <hr /> */}

                    </div>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {employeeImage ?
                          <Image src={employeeImage} alt="Image" className='view-image' preview />
                          :
                          <div className='none-text'>-</div>
                        }
                      </Col>
                      {
                        documentList?.length > 0 && (
                          documentList?.filter((data:any)=>data.fileType === "Aadhar").map((res:any)=>(
                            <Col lg={4} key={res.id}>
                               {/* <div style={{fontSize: "16px",fontWeight: 700}}>{res.name}</div> */}
                               <div style={{fontSize: "16px",fontWeight: 700}}>Aadhar Document</div>
                               <div style={{ width: "200px", height: "100px",padding: "10px"}}>
                               <FeatherIcon style={{ width: "100%", height: "100%" }}  onClick={()=>handleDownload(res)} icon="file" className={`icon-sm right-icon pointer`} /> 
                               </div>
                            </Col>
                          ))
                        )
                      }
                      {
                        documentList?.length > 0 && (
                          documentList?.filter((data:any)=>data.fileType === "pancard").map((res:any)=>(
                            <Col lg={4} key={res.id}>
                               {/* <div style={{fontSize: "16px",fontWeight: 700}}>{res.name}</div> */}
                               <div style={{fontSize: "16px",fontWeight: 700}}>PAN Card Document</div>
                               <div style={{ width: "200px", height: "100px",padding: "10px"}}>
                               <FeatherIcon style={{ width: "100%", height: "100%" }}  onClick={()=>handleDownload(res)} icon="file-text" className={`icon-sm right-icon pointer`} /> 
                               </div>
                            </Col>
                          ))
                        )
                      }                      
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>

          <Row>
            <div className="view">
              <div className="general-details">
                <b>More Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    userView.otherDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div className=''>{data.name}</div>
                          <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
          {/* <Row>
            <div className="view">
              <div className="general-details">
                <b>More Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    userView.otherDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div className=''>{data.name}</div>
                          <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row> */}
                    <Row>
            <div className="view">
              <div className="general-details">
                <b>Payslip Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    userView.paySlipInfo.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div className=''>{data.name}</div>
                          <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default EmployeeProfile