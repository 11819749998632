import {StockQuantityByMusicSystemBrandTypes} from './actionTypes'

//grid
export const getStockQuantityByMusicSystemBrandLoading = (lazyState: any) => {
  return {
    type: StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING,
    lazyState,
  };
};
export const getStockQuantityByMusicSystemBrandSuccess = (stockvalueList : any) => {
  return {
    type: StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_SUCCESS,
    payload : stockvalueList   
  };
};
export const getStockQuantityByMusicSystemBrandError = (error : any) => {
  return {
    type: StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_ERROR,
    payload : error
  };
};

export const getAudioBrandLoading = () => {
  return {
    type: StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_LOADING,
  };
};
export const getAudioBrandSuccess = (stockvalueList : any) => {
  return {
    type: StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_SUCCESS,
    payload : stockvalueList   
  };
};
export const getAudioBrandError = (error : any) => {
  return {
    type: StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_ERROR,
    payload : error
  };
};