import { AttendanceReportTypes } from "./actionTypes";

export const getAttendanceReportList = (lazyState: any) => {
    
    return {
      type: AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST,
      lazyState,
    };
  };
  
  export const getAttendanceReportListSuccess = (attendance: any) => {
    return {
      type: AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getAttendanceReportListError = (error: any) => {
    return {
      type: AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST_FAIL,
      payload: error,
    };
  };

  
export const getEmployeeDropdownList = () => {
    return {
      type: AttendanceReportTypes.GET_EMPLOYEE_DROPDOWN_LIST,
    };
  };
  
  export const getEmployeeDropdownListSuccess = (attendance: any) => {
    return {
      type: AttendanceReportTypes.GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getEmployeeDropdownListError = (error: any) => {
    return {
      type: AttendanceReportTypes.GET_EMPLOYEE_DROPDOWN_LIST_FAIL,
      payload: error,
    };
  };
  
export const getFranchiseDropdownList = () => {
    return {
      type: AttendanceReportTypes.GET_FRANCHISE_DROPDOWN_LIST,
    };
  };
  
  export const getFranchiseDropdownListSuccess = (attendance: any) => {
    return {
      type: AttendanceReportTypes.GET_FRANCHISE_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getFranchiseDropdownListError = (error: any) => {
    return {
      type: AttendanceReportTypes.GET_FRANCHISE_DROPDOWN_LIST_FAIL,
      payload: error,
    };
  };
  
export const getDealerDropdownList = () => {
    return {
      type: AttendanceReportTypes.GET_DEALER_DROPDOWN_LIST,
    };
  };
  
  export const getDealerDropdownListSuccess = (attendance: any) => {
    
    return {
      type: AttendanceReportTypes.GET_DEALER_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getDealerDropdownListError = (error: any) => {
    return {
      type: AttendanceReportTypes.GET_DEALER_DROPDOWN_LIST_FAIL,
      payload: error,
    };
  };
  
export const getDealerSubuserDropdownList = () => {
    return {
      type: AttendanceReportTypes.GET_DEALER_SUBUSER_DROPDOWN_LIST,
    };
  };
  
  export const getDealerSubuserDropdownListSuccess = (attendance: any) => {
    
    return {
      type: AttendanceReportTypes.GET_DEALER_SUBUSER_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getDealerSubuserDropdownListError = (error: any) => {
    return {
      type: AttendanceReportTypes.GET_DEALER_SUBUSER_DROPDOWN_LIST_FAIL,
      payload: error,
    };
  };
  
export const getFranchiseSubuserDropdownList = () => {
    return {
      type: AttendanceReportTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST,
    };
  };
  
  export const getFranchiseSubuserDropdownListSuccess = (attendance: any) => {
    return {
      type: AttendanceReportTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getFranchiseSubuserDropdownListError = (error: any) => {
    return {
      type: AttendanceReportTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL,
      payload: error,
    };
  };

  export const ClearAttendanceReportMessage = () => {
    return {
      type: AttendanceReportTypes.CLEAR_ATTENDANCE_REPORT_MESSAGE,
    };
  };
  export const ResetAttendanceReport = () => {
    return {
      type: AttendanceReportTypes.RESET_ATTENDANCE_REPORT,
    };
  };