import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { PendingPartsConsolidatedTypes } from "./actionTypes";
import { getPendingPartsConsolidatedError, getPendingPartsConsolidatedSuccess } from "./action";



//worker function

function* getPendingPartsConsolidateListAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.getPendingPartsConsolidatedListApi, {...action.lazyState})
    yield put(getPendingPartsConsolidatedSuccess(response));
  } catch (error) {
    yield put(getPendingPartsConsolidatedError(error));
  }
}


//watcher function

function* PendingPartsConsolidateSaga() {
  yield takeLatest(PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_LOADING, getPendingPartsConsolidateListAsync);
}
export default PendingPartsConsolidateSaga