import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "src/components/Common/Breadcrumb";
import { useToast } from "src/components/Common/ReactToaster";
import { ROUTER } from "src/constants/routes";
import {
  clearCustomMessage,
  createCustomer,
  customerDetailsReset,
  customerLatLagLoading,
  customerTaxTypeList,
  deleteCustomerImageLoading,
  getCustomerByIdLoading,
  getCustomerImageLoading,
  resendOtpCustomer,
  resetOtpMessage,
  sendCustomerOtpLoading,
  setCustomerEmailIdLoading,
  setCustomerMobileNumberLoading,
  updateCustomerLoading,
  uploadCustomerFileLoading,
} from "src/store/actions";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import Button from "src/components/UI/Button";
import { RootReducerState } from "src/store/reducers";
import {
  formatDateTOYYYYMMDD,
  getLocationLatLng,
  onDropRejectedFileHandler,
} from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import DropdownInput from "src/components/UI/DropdownInput";
import { FILE_UPLOAD_SIZE } from "src/constants/commonConstants";
import ImagePreviewer from "src/components/Common/ImagePreviewer/ImagePreviewer";
import { customerImageDeleteSuccess } from "src/store/customers/reducer";
import DeleteModal from "../Calendar/DeleteModal";
import FeatherIcon from "feather-icons-react";
import { Dialog } from "primereact/dialog";
import OtpScreen from "./OtpScreen";
import { APPENV_URL } from "src/util/appUrlEnv";

const moment = require("moment");

const EditCustomer = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [modifiedCustomer, setModifiedCustomer] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [customError, setcustomError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [address, setAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);
  const [redirect, setRedirect] = useState<any>(false);
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");
  const [apiKey, setApiKey] = useState<string | null>(null);

  let errorMessage = "Address field is required";
  const { showToast } = useToast();
  const navigate = useNavigate();
  const {
    customerDetails,
    error,
    message,
    loading,
    customerTaxList,
    customerImage,
    emailSuccessMessage,
    phoneNumberSuccessMessage,
    createdData,
    otpmessage,
    resendOtp,
    editedmobileNumber,
    updatedmessage,
    customerLatLng
  } = useSelector((state: RootReducerState) => state.customerReducer);

  const TAX_TYPES =
    customerTaxList?.length > 0
      ? customerTaxList?.map((eachTax: any) => {
        let data: any = {};
        data["value"] = eachTax.id;
        data["label"] = eachTax.taxName;
        return data;
      })
      : [];

  const { id } = useParams();
  const dispatch: any = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(customerTaxTypeList());
    if (!id) {
      getLocationLatLng(setLatitude, setLongitude);
    }
    return () => {
      dispatch(clearCustomMessage());
      setLatitude("");
      setLongitude("");
      setAddress("");
      dispatch(resetOtpMessage());
      dispatch(customerDetailsReset());
    };


  }, []);

  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(customerTaxTypeList());
      dispatch(getCustomerByIdLoading(id));
      dispatch(getCustomerImageLoading(id));
    }
  }, [id, dispatch]);

  const [dobError, setDobError] = useState("");
  const [weddingDateError, setWeddingDateError] = useState("");

  useEffect(() => {
    if (customerDetails) {
      const originalDateString =
        customerDetails?.user?.extraProperties?.DateOfBirth;
      const orginalweddingDate =
        customerDetails?.user?.extraProperties?.WeddingDate;
      const formattedDate = originalDateString
        ? formatDateTOYYYYMMDD(originalDateString)
        : "";
      const formattedWeddingDate = orginalweddingDate
        ? formatDateTOYYYYMMDD(orginalweddingDate)
        : "";
      let modified = {
        id: customerDetails.id,
        name: customerDetails?.user?.name,
        mobileNumber: customerDetails?.user?.phoneNumber,
        email: customerDetails?.user?.email,
        gstNumber: customerDetails?.user?.extraProperties?.GstNumber,
        taxType: customerDetails?.taxTypeId,
        dateOfBirth: formattedDate,
        weddingDate: formattedWeddingDate,
        sendEmail: customerDetails?.user?.extraProperties?.SendEmail,
        sendWhatsapp: customerDetails?.user?.extraProperties?.SendWhatsapp,
        sendSms: customerDetails?.user?.extraProperties?.SendSms,
        alternateNumber: customerDetails?.alternateNumber,
        address: customerDetails?.user?.extraProperties?.Address,
        userName: customerDetails?.user?.userName,
        userId: customerDetails?.user?.id,
        isActive: customerDetails?.user?.isActive,
        lockoutEnabled: customerDetails?.user?.lockoutEnabled,
        isVerfied: customerDetails?.user?.extraProperties?.IsVerfied,
        feedbackRequired: customerDetails?.feedbackRequired
      };
      setModifiedCustomer(modified);
    }
  }, [customerDetails]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true;
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    address: Yup.string().required(`Address is required`),
    taxType: Yup.string().required(`Tax Type is required`),
    gstNumber: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "Invalid GST number"
    ),
    userName: Yup.string().required(`User name is required`),
    mobileNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    // alternateNumber: Yup.string()
    //   .matches(/^\d{10}$/, "Alternate number must be 10 digits")
    //   .test(
    //     "notEqual",
    //     "Mobile number and alternate number cannot be the same",
    //     function (value) {
    //       if (value) {
    //         const { mobileNumber } = this.parent;
    //         return value !== mobileNumber;
    //       } else {
    //         return true;
    //       }
    //     }
    //   ),

    dateOfBirth: Yup.date().test(
      "not-after-today",
      "Date of birth must not be today or future date",
      function (value) {
        const currentDate = moment();
        const { dateOfBirth } = this.parent;
        if (dateOfBirth) {
          if (
            (value && moment(value).isSame(currentDate, "day")) ||
            (value && moment(value).isAfter(currentDate))
          ) {
            return false;
          }
        }
        setDobError("");
        return true;
      }
    ),
    weddingDate: Yup.date().test(
      "after-date-of-birth",
      "Wedding date must not be same or before dob",
      function (value) {
        const { dateOfBirth } = this.parent;
        const dobDate = moment(dateOfBirth);

        if (dateOfBirth) {
          if (
            (value && moment(value).isSame(dateOfBirth, "day")) ||
            (value && moment(value).isBefore(dateOfBirth))
          ) {
            return false;
          }
        }
        setWeddingDateError("");
        return true;
      }
    ),
  });

  const EditValidation = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    taxType: Yup.string().required(`Tax type is required`),
    address: Yup.string().required(`Address is required`),
    userName: Yup.string().required(`User name is required`),
    gstNumber: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "Invalid GST number"
    ),
    mobileNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),


    dateOfBirth: Yup.date().test(
      "not-after-today",
      "Date of birth must not be today or future date",
      function (value) {
        const currentDate = moment();
        const { dateOfBirth } = this.parent;

        if (dateOfBirth) {
          if (
            (value && moment(value).isSame(currentDate, "day")) ||
            (value && moment(value).isAfter(currentDate))
          ) {
            return false;
          }
        }
        setDobError("");
        return true;
      }
    ),
    weddingDate: Yup.date().test(
      "after-date-of-birth",
      "Wedding date must not be same or before dob",
      function (value) {
        const { dateOfBirth } = this.parent;
        const dobDate = moment(dateOfBirth);
        if (dateOfBirth) {
          if (
            (value && moment(value).isSame(dateOfBirth, "day")) ||
            (value && moment(value).isBefore(dateOfBirth))
          ) {
            return false;
          }
        }

        setWeddingDateError("");
        return true;
      }
    ),
  });

  const initialvalue: any = {
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    mobileNumber: "",
    gstNumber: "",
    dateOfBirth: "",
    weddingDate: "",
    sendSms: false,
    sendEmail: false,
    sendWhatsapp: false,
    alternateNumber: "",
    taxType: "",
    address: "",
    userName: "",
    isActive: false,
    lockoutEnabled: false,
    isVerfied: false,
    feedbackRequired: false
  };


  const [editInitialValue, setEditInitialvalue] = useState({});
  useEffect(() => {
    if (modifiedCustomer) {
      const editInitialValue = {
        id: modifiedCustomer.userId ? modifiedCustomer.userId : "",
        name: modifiedCustomer.name ? modifiedCustomer.name : "",
        email: modifiedCustomer.email ? modifiedCustomer.email : "",
        mobileNumber: modifiedCustomer.mobileNumber
          ? modifiedCustomer.mobileNumber
          : "",
        gstNumber: modifiedCustomer.gstNumber ? modifiedCustomer.gstNumber : "",
        dateOfBirth: modifiedCustomer?.dateOfBirth
          ? modifiedCustomer?.dateOfBirth
          : "",
        weddingDate: modifiedCustomer?.weddingDate
          ? modifiedCustomer?.weddingDate
          : "",
        sendSms: modifiedCustomer?.sendSms ? modifiedCustomer?.sendSms : false,
        sendEmail: modifiedCustomer?.sendEmail
          ? modifiedCustomer?.sendEmail
          : false,
        sendWhatsapp: modifiedCustomer?.sendWhatsapp
          ? modifiedCustomer?.sendWhatsapp
          : false,
        alternateNumber: modifiedCustomer.alternateNumber
          ? modifiedCustomer.alternateNumber
          : "",
        taxType: modifiedCustomer.taxType ? modifiedCustomer.taxType : "",
        address: modifiedCustomer.address ? modifiedCustomer.address : "",
        userName: modifiedCustomer.userName ? modifiedCustomer.userName : "",
        isActive: modifiedCustomer.isActive ? modifiedCustomer.isActive : false,
        lockoutEnabled: modifiedCustomer.lockoutEnabled
          ? modifiedCustomer.lockoutEnabled
          : false,
        isVerfied: modifiedCustomer.isVerfied ? modifiedCustomer.isVerfied : false,
        password: "",
        confirmpassword: "",
        feedbackRequired: modifiedCustomer.feedbackRequired ? modifiedCustomer.feedbackRequired : false
      };
      setEditInitialvalue(editInitialValue);
    }
  }, [modifiedCustomer]);
  useEffect(() => {
    if (modifiedCustomer.address && id) {
      dispatch(customerLatLagLoading(modifiedCustomer.address));
    }
  }, [modifiedCustomer]);

  const formatDate = (dateString: string) => {
    const newDate = moment(dateString).set({
      hour: 7,
      minute: 43,
      second: 32,
      millisecond: 395,
    });

    return newDate.toISOString();
  };

  function handleAcceptedFiles(files: any) {

    let filesModified = files.map((file: any) => {

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith("image/"),
      });
    });


    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submitFunction = (values: any) => {

    if (isSubmitting) return;
    debugger;
    // if (!mapSelectedPlace) {
    //   return setcustomError(true);
    // }
    if (id) {

      let formated = {
        taxTypeId: values.taxType,
        alternateNumber: String(values.alternateNumber),
        feedbackRequired: values?.feedbackRequired,
        user: {
          id: values.id,
          userName: values.userName,
          name: values.name,
          email: values.email,
          phoneNumber: String(values.mobileNumber),
          address: values.address,
          dateOfBirth: values.dateOfBirth,
          gstNumber: values.gstNumber,
          sendSms: values.sendSms,
          sendEmail: values.sendEmail,
          sendWhatsapp: values.sendWhatsapp,
          weddingDate: values.weddingDate,
          isActive: values.isActive,
          lockoutEnabled: values.lockoutEnabled,
          isVerfied: values.isVerfied,

        },
      };
      if (selectedFiles.length > 0) {
        dispatch(uploadCustomerFileLoading(selectedFiles[0], id));
      }
      dispatch(updateCustomerLoading(formated, id));

    } else {
      let formated = {
        taxTypeId: values.taxType,
        alternateNumber: String(values.alternateNumber),
        feedbackRequired: values?.feedbackRequired,
        user: {
          id: values.userId,
          userName: values.userName,
          name: values.name,
          email: values.email,
          phoneNumber: String(values.mobileNumber),
          address: values.address,
          dateOfBirth: values.dateOfBirth,
          gstNumber: values.gstNumber,
          sendSms: values.sendSms,
          sendEmail: values.sendEmail,
          sendWhatsapp: values.sendWhatsapp,
          weddingDate: values.weddingDate,
          isActive: values.isActive,
          lockoutEnabled: values.lockoutEnabled,
          isVerfied: values.isVerfied,

        },
      };


      dispatch(createCustomer(formated));

    }
    setIsSubmitting(true);
  };

  const handleSubmit = async (values: any) => {
    await submitFunction(values);
  };

  const removeIndex = () => {
    setDeleteModal(false);
    if (customerImage) {
      dispatch(deleteCustomerImageLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    debugger;
    if (message != "" && message != undefined && !redirect) {
      showToast(message, { type: "success" });
      if (message != customerImageDeleteSuccess) {
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      }
      dispatch(clearCustomMessage());
    }

  }, [message]);

  useEffect(() => {
    debugger;
    if (updatedmessage != "" && updatedmessage != undefined && !redirect) {
      showToast(updatedmessage, { type: "success" });
      if (updatedmessage != customerImageDeleteSuccess) {
        setTimeout(() => {
          setIsSubmitting(false);
          navigate(ROUTER.CUSTOMERS_GRID);
        }, 500);
      }
      dispatch(clearCustomMessage());
      setLatitude("");
      setLongitude("");
      setAddress("");
    }

  }, [updatedmessage]);
  useEffect(() => {
    debugger;
    if (createdData && redirect) {
      navigate(`${ROUTER.JOB_SHEETS_CREATE}/${createdData?.user?.phoneNumber}`);
    }
    if (createdData && !redirect) {
      dispatch(setCustomerMobileNumberLoading(createdData?.user?.phoneNumber, createdData?.user?.id));

    }
  }, [createdData]);
  useEffect(() => {
    if (emailSuccessMessage != "" && emailSuccessMessage != undefined) {
      showToast(emailSuccessMessage, { type: "success" });
      dispatch(getCustomerByIdLoading(id));
      setEmailEdit(false);
    }
  }, [emailSuccessMessage]);
  useEffect(() => {
    if (otpmessage != "" && otpmessage != undefined) {
      setOpenOtp(false);
      showToast(otpmessage, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.CUSTOMERS_GRID);
      }, 500);
    }
  }, [otpmessage]);
  const [openOtp, setOpenOtp] = useState<any>(false);
  useEffect(() => {
    if (
      phoneNumberSuccessMessage != "" &&
      phoneNumberSuccessMessage != undefined
    ) {
      showToast(phoneNumberSuccessMessage, { type: "success" });
      setPhoneEdit(false);
      setOpenOtp(true);
    }
  }, [phoneNumberSuccessMessage]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearCustomMessage());
  };

  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup
          error={error}
          visible={customError}
          onHide={hidePopupForCustomValidation}
        />
      </>
    );
  };
  const [otpValue, setOtpValue] = useState<any>(null);
  const handleOtpSubmit = () => {


    if (id) {

      dispatch(sendCustomerOtpLoading(otpValue, customerDetails?.user?.id, editedmobileNumber));
    } else {
      dispatch(sendCustomerOtpLoading(otpValue, createdData?.user?.id, createdData?.user?.phoneNumber));
    }

  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpMessage());
    if (id) {
      dispatch(getCustomerByIdLoading(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };

  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.CUSTOMERS_GRID);
    }
  };

  const preventSpecialChar = (event: any) => {

    if (
      (event.which >= 106 && event.which <= 111) ||
      (event.which >= 187 && event.which <= 191) ||
      event.which === 69
    ) {
      event.preventDefault();
    }
  };

  // const onDropRejectedFileHandler = (fileRejections:FileRejection[],event:DropEvent)=>{

  //   showToast( fileRejections[0]?.errors[0]?.message ,{ type: "error" })
  // }

  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };

  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const [Number, setNumber] = useState<any>("");
  const handleResendOtp = (number: any) => {
    dispatch(resendOtpCustomer(customerDetails?.user?.id, number));
  };
  const onHide = () => {
    setPhoneEdit(false);
  };

  const handleNumberSubmit = (values: any) => {


    setPhoneEdit(false);
    dispatch(
      setCustomerMobileNumberLoading(
        values.pohoneNumber,
        customerDetails?.user?.id
      )
    );
  };
  const EditValidationNumber = Yup.object().shape({

    pohoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                pohoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="pohoneNumber"
                        onBlur={handleBlur}
                        value={values.pohoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["pohoneNumber"] &&
                            errors["pohoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };
  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {

    setEmailEdit(false);
    dispatch(setCustomerEmailIdLoading(values.email, customerDetails?.user?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const handleChangeAddressField = (value: any, event: any,) => {
    // if (!event?.target?.value) return;
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      if (value) {
        dispatch(customerLatLagLoading(value));
      }
    }
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeIndex();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {visible && dialogContainer(error)}
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      {customError && dialogContainerCustomError(errorMessage)}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Customers"
            titlePath={ROUTER.CUSTOMERS_GRID}
            breadcrumbItem={id ? "Edit Customer" : "Create Customer"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={!id ? initialvalue : editInitialValue}
                    validationSchema={!id ? validationSchema : EditValidation}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      setFieldValue,
                      reset,
                      isSubmitting,
                      validateForm,
                      submitForm,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {/* <>
                              <Col lg={3} className="mb-2">
                                <InputCom
                                  labelName={"Password"}
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="password"
                                  name="password"
                                  onBlur={handleBlur}
                                  value={values.password}
                                  onChange={handleChange}
                                  isRequired={!id ? true : false}
                                  invalid={
                                    touched["password"] && errors["password"]
                                      ? true
                                      : false
                                  }
                                />
                              </Col>

                              <Col lg={3} className="mb-2">
                                <InputCom
                                  labelName={"Confirm Password"}
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="password"
                                  name="confirmpassword"
                                  onBlur={handleBlur}
                                  value={values.confirmpassword}
                                  onChange={handleChange}
                                  isRequired={id ? false : true}
                                  invalid={
                                    touched["confirmpassword"] &&
                                    errors["confirmpassword"]
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                            </> */}
                            <Col lg={3} className="mb-2">
                              <div
                                className=""
                                style={{ position: "relative" }}
                              >
                                <InputCom
                                  labelName="Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon
                                    icon="edit"
                                    className={`icon-lg right-icon`}
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "38px",

                                    }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col lg={3} className="mb-2">
                              <div
                                className=""
                                style={{ position: "relative" }}
                              >
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="mobileNumber"
                                  onBlur={handleBlur}
                                  value={values.mobileNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["mobileNumber"] &&
                                      errors["mobileNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (
                                  <FeatherIcon
                                    icon="edit"
                                    className={`icon-lg right-icon`}
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "38px",

                                    }}
                                    onClick={() => { setNumber(values.mobileNumber); setPhoneEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Gst Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="gstNumber"
                                onBlur={handleBlur}
                                value={values.gstNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["gstNumber"] && errors["gstNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={TAX_TYPES}
                                name="taxType"
                                label="Tax Type"
                                onChange={handleChange}
                                required={true}
                                value={values.taxType}
                                onBlur={handleBlur}
                                invalid={
                                  touched["taxType"] && errors["taxType"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="alternateNumber"
                                onBlur={handleBlur}
                                value={values.alternateNumber}
                                onKeyDown={preventSpecialChar}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["alternateNumber"] &&
                                    errors["alternateNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Date of Birth"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="dateOfBirth"
                                onBlur={handleBlur}
                                value={values.dateOfBirth}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["dateOfBirth"] &&
                                    errors["dateOfBirth"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Wedding Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="weddingDate"
                                onBlur={handleBlur}
                                value={values.weddingDate}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["weddingDate"] &&
                                    errors["weddingDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="custom_gap mb-2">
                              <CheckboxInput
                                name="sendSms"
                                label="Send Sms"
                                value={values["sendSms"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              <CheckboxInput
                                name="sendEmail"
                                label="Send Email"
                                value={values["sendEmail"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                            <Col lg={3} className="custom_gap mb-2">
                              <CheckboxInput
                                name="sendWhatsapp"
                                label="Whatsapp"
                                value={values["sendWhatsapp"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              <CheckboxInput
                                name="isActive"
                                label="Is Active"
                                value={values["isActive"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                            <Col lg={3} className="custom_gap mb-2">
                              <Row>
                                <Col lg={6}>
                                  <CheckboxInput
                                    name="lockoutEnabled"
                                    label="Lockout Enabled"
                                    value={values["lockoutEnabled"]}
                                    onChange={handleChange}
                                    {...rest}
                                  />
                                  <CheckboxInput
                                    name="isVerfied"
                                    label="Is Verified"
                                    value={values["isVerfied"]}
                                    onChange={handleChange}
                                    {...rest}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <CheckboxInput
                                    name="feedbackRequired"
                                    label="Feedback Required"
                                    value={values["feedbackRequired"]}
                                    onChange={handleChange}
                                    {...rest}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={12} className="mb-2">
                              {/* <AddressMapFields
                                address={id ? modifiedCustomer.address : address}
                                setAddress={setAddress}
                                lat={id ? null : latitude}
                                lng={id ? null : longitude}
                                title="Address"
                              ></AddressMapFields> */}
                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={customerDetails?.user?.extraProperties?.Address ? customerDetails?.user?.extraProperties?.Address : ""} title="Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}

                              <Col lg={12} className="mb-2">
                                <InputCom
                                  labelName={"Address"}
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="address"
                                  // onBlur={(e:any)=>{handleBlur(e) ;dispatch(customerLatLagLoading(values.address))}}
                                  onBlur={handleBlur}
                                  onKeyDown={(e: any) => handleChangeAddressField(e.target.value, e)}
                                  value={values.address}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={
                                    touched["address"] && errors["address"]
                                      ? true
                                      : false
                                  }
                                />
                                <p style={{ fontSize: "12px" }}>Please press the Enter key after entering the address.</p>
                              </Col>
                              {customerLatLng &&
                                <iframe src={`https://maps.google.com/maps?q=${customerLatLng.lat},${customerLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                            </Col>

                            {id && (
                              <React.Fragment>
                                <div className="mt-1">
                                  <h5>Image</h5>
                                  <hr />
                                  <Row>
                                    <Col sm={12} lg={12} md={12} xs={12}>
                                      <div>
                                        {selectedFiles.length == 0 &&
                                          !customerImage && (
                                            <Dropzone
                                              onDrop={(acceptedFiles) => {
                                                handleAcceptedFiles(
                                                  acceptedFiles
                                                );
                                              }}
                                              onError={(err: Error) => {

                                              }}
                                              onDropRejected={(
                                                fileRejections,
                                                event
                                              ) =>
                                                onDropRejectedFileHandler(
                                                  fileRejections,
                                                  event,
                                                  showToast
                                                )
                                              }
                                              maxFiles={1}
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                      accept="image/*"
                                                    />
                                                    <div className="">
                                                      <i className="display-4 text-muted bx bx-cloud-upload" />
                                                    </div>
                                                    <h4>
                                                      Drop files here or click
                                                      to upload.
                                                    </h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                          )}

                                        {(selectedFiles[0]?.preview ||
                                          customerImage) && (
                                            <ImagePreviewer
                                              selectedFile={
                                                selectedFiles[0]?.preview
                                                  ? selectedFiles[0]?.preview
                                                  : customerImage
                                              }
                                              removeIndex={
                                                deleteConfirmationHandler
                                              }
                                            />
                                          )}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </React.Fragment>
                            )}
                          </Row>

                          <div className="d-flex float-end mt-2 me-0">
                            {(customerDetails?.user?.phoneNumberConfirmed != true && id) &&
                              <Button
                                className="next-status-btn me-2 mt-2"
                                label={"Verify Mobile No"}
                                type="button"
                                onClick={() => handleResendOtp(values.mobileNumber)}
                              />
                            }
                            <Button
                              className="secondary-btn me-2 mt-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary mt-2 btn-primary-shadow"
                              label={id ? "Update Customer" : "Create Customer"}
                              type="submit"
                            />
                            {!id && (
                              <Button
                                className="btn-primary mt-2 btn-primary-shadow"
                                label={"Create and New Job "}
                                type="button"
                                onClick={async () => {
                                  const errors = await validateForm();
                                  if (Object.keys(errors).length === 0) {
                                    setRedirect(true);
                                    submitForm();
                                  } else {
                                    submitForm();
                                    setRedirect(true);
                                  }
                                }}
                              />
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCustomer;
