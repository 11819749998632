
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import DropdownInput from 'src/components/UI/DropdownInput';
import JobTooltipMEL from 'src/components/UI/JobTooltip';
import { ROUTER } from 'src/constants/routes';
import { formatDateTimeMEL, hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler } from 'src/helpers/common_helpers';
import { closeTicketByEmployeeLoading, createEnquiryLoading, getEnquiryDocumentLinkLoading, getInduvidualEnquiryLoading, getReassignEmployeeListLoading, getReplyMessageFromEmployeeLoading, reAssignTicketFromEmployeeLoding, reOpenTicketFromCustomerLoading, replyTheQueryLoading, resetEnquiryState, resetInduvidualQuery, responseTheQueryLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";



const CreateTicketSystem = () => {

  const { id } = useParams()
  const { showToast } = useToast();
  const currentUser: any = localStorage.getItem("authUser");
  const parseUser = JSON.parse(currentUser);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef<any>(null);
  const replyFileInputRef = useRef<any>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [selectedReplyFiles, setSelectedReplyFiles] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const maxSizeInBytes = 5 * 1024 * 1024;
  const [showReply, setShowReply] = useState<boolean>(false);
  const scroll = useRef<any>();
  const { error, message, loading, induvidualEnquiry, enquiryDocument, enquiryDocumentLink, replyMessages, replyDocument, reassignEmployees } = useSelector((state: RootReducerState) => state.ticketSystemReducer)
  console.log("induvidualEnquiry", induvidualEnquiry)
  console.log("parseUser", parseUser)
  console.log("replyMessages", replyMessages)
  console.log("enquiryDocument", enquiryDocument)
  console.log("replyDocument", replyDocument)
  console.log("reassignEmployees", reassignEmployees)
  console.log("selectedFiles", selectedFiles)
  const imageMimeTypes = /^image\/(png|jpeg|jpg|gif|svg\+xml|bmp|webp|tiff|ico)$/;

  const initialvalue = useMemo(() => {
    return {
      category: "",
      priority: "",
      subject: "",
      description: "",
      reply: "",
      reassignEmployee: ""
    }
  }, [])

  const subjectDropdown: any[] = [
    { label: "General query", value: "General query" },
  ]



  const empoyeeDropdown = reassignEmployees.length > 0 ? reassignEmployees?.map((data) => {
    let object = {
      label: data.name + " " + `(${data.phoneNumber})`,
      value: data.userId
    }
    return object
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []

  const priorityOptions: any[] = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
  ]

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (enquiryDocumentLink != "" && enquiryDocumentLink != undefined && enquiryDocumentLink != null) {
      const newWindow = window.open(enquiryDocumentLink)
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(resetEnquiryState());
    }
  }, [enquiryDocumentLink])

  useEffect(() => {
    if (id) {
      dispatch(getInduvidualEnquiryLoading(id))
      dispatch(getReplyMessageFromEmployeeLoading(id))
      dispatch(getReassignEmployeeListLoading())
    }

    return () => {
      dispatch(resetInduvidualQuery())
    }
  }, [id])

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetEnquiryState())
      setIsSubmitting(false);
      navigate(ROUTER.TICKET_SYSTEM);
    }
  }, [message, error])

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetEnquiryState())
    setIsSubmitting(false)
  }

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("category field is required"),
    priority: Yup.string().required("priority field is required"),
    subject: Yup.string().required("subject field is required"),
    description: Yup.string().required("description field is required"),
  })
  const editValidationSchema = Yup.object().shape({
    reassignEmployee: Yup.string(),
    reply: Yup.string(),
  }).test({
    name: "reassignEmployee field required",
    test: function (value: any) {
      const { reassignEmployee } = value;
      if (!reassignEmployee && hasAdminOrEmployeeRoleHandler()) {
        return this.createError({
          path: "reassignEmployee",
          message: "Reassign employee field is required"
        })
      }
    }
  })

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const convertTimeStamp = (timeStamp: any) => {
    let timeAgo = "";
    if (timeStamp) {
      var date = new Date(timeStamp);
      var localOffset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - localOffset);
      return timeAgo = date.toLocaleString();
    }
  }

  const combinedMessages = replyMessages?.map(data => {
    const isCustomer = data.isCustomer;
    const classDecide = data.creatorId;
    const name = isCustomer ? data.customerName : (data.supporterName ? data.supporterName : "test");
    // const className = isCustomer ? "message-align-right" : "message-align-left";
    const className = (classDecide == parseUser?.uid) ? "message-align-right" : "message-align-left";
    const messageClass = (classDecide == parseUser?.uid) ? "message-box-container" : "message-box-container_response"
    return {
      name: name,
      // createdOn: <TimeAgo timeStamp={data.creationTime} />,
      createdOn: convertTimeStamp(data.creationTime),
      description: data.description,
      className: className,
      attachments: data.documents.length > 0 ? data.documents.map(doc => ({
        name: doc.name,
        id: doc.id,
        type: doc.mimeType
      })) : [],
      messageClass: messageClass
    };
  });

  console.log("combinedMessages", combinedMessages)

  const editData: any = {
    category: induvidualEnquiry?.subject,
    description: induvidualEnquiry?.description,
    status: induvidualEnquiry?.status,
    priority: induvidualEnquiry?.priority,
    createdOn: formatDateTimeMEL(induvidualEnquiry?.creationTime),
    attachement: induvidualEnquiry?.documents.map((data) => {
      let object = {
        name: data.name,
        type: data.mimeType,
        id: data.id
      }
      return object
    })
  }

  const handleAddClick = () => {
    fileInputRef.current.click();
  }

  const relpyHandlerAddClick = () => {
    replyFileInputRef.current.click()
  }

  const sendHandler = (values: any, setFieldValue: any, setFieldTouched: any) => {
    debugger
    let { reply } = values;
    if (!reply) {
      setFieldTouched('reply', 'Username is required.');
      return
    }
    let submittedData = {
      Description: reply,
      ParentReplyId: replyMessages?.length > 0 && replyMessages[replyMessages.length - 1].id ? replyMessages[replyMessages.length - 1].id : null,
      FormFiles: selectedReplyFiles,
    }
    if (hasCustomerRoleHandler()) {
      dispatch(replyTheQueryLoading(id, submittedData))
    } else {
      dispatch(responseTheQueryLoading(id, submittedData))
    }
    setFieldValue("reply", "")
    setSelectedReplyFiles([])
    setShowReply(false)
  }

  const handleSubmit = (values: any) => {
    if (isSubmitting) return
    const { category, description, priority, subject, reassignEmployee } = values;
    let submittedValues = {
      Category: category,
      Subject: subject,
      Description: description,
      Priority: priority,
      FormFiles: selectedFiles
    }
    if (id) {
      const getsupporterDetails = reassignEmployees.filter((data) => data.userId == reassignEmployee)
      dispatch(reAssignTicketFromEmployeeLoding(id, getsupporterDetails[0].userId, getsupporterDetails[0].name));
    } else {
      dispatch(createEnquiryLoading(submittedValues))
    }
    setIsSubmitting(true)
  }

  const openDocumet = (id: string) => {
    dispatch(getEnquiryDocumentLinkLoading(id))
  }

  const reOpenTicketHandle = () => {
    dispatch(reOpenTicketFromCustomerLoading(id))
  }
  const colseTicketHandle = () => {
    dispatch(closeTicketByEmployeeLoading(id))
  }

  const handleShowReassignEmployee = () => {
    // setShowReassignEmployee(true)
  }

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [replyMessages])


  const isImageMimeType = (mimeType: any) => {
    const imageMimeTypes = /^image\/(png|jpeg|jpg|gif|svg\+xml|bmp|webp|tiff|ico)$/;
    return imageMimeTypes.test(mimeType);
  }

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className="page-content" id="ticket-system">
        <Container fluid>
          <Breadcrumb title="Ticket system" titlePath={ROUTER.TICKET_SYSTEM} breadcrumbItem={id ? "Edit Ticket System" : "New Ticket System"} />
          <Row>
            <Card>
              <CardBody className=''>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? editValidationSchema : validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldValue, setFieldError, setFieldTouched, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            {!id &&
                              <>
                                <Col lg={6} className="mb-2">
                                  <DropdownInput
                                    options={subjectDropdown}
                                    name="category"
                                    label="Category"
                                    // onChange={(e: any) => {
                                    //   handleChange(e)
                                    //   if (e.target.value == subjectDropdown[0]?.value) {
                                    //     setFieldValue("description", `Problem:\n`)
                                    //   } else if (e.target.value == subjectDropdown[1]?.value) {
                                    //     setFieldValue("description", `Vehicle brand:\nVehicle model:\nAudio model:\nProblem:\n`)
                                    //   } else if (e.target.value == subjectDropdown[2]?.value) {
                                    //     setFieldValue("description", `Job sheet number:\nLast service date:\nProblem:\n`)
                                    //   } else if (e.target.value == subjectDropdown[3]?.value) {
                                    //     setFieldValue("description", `Payment number:\nPayment Date:\nProblem:\n`)
                                    //   }
                                    // }}
                                    onChange={handleChange}
                                    required={true}
                                    value={values.category}
                                    onBlur={handleBlur}
                                    panelClassName='newCourierDropDown'
                                    invalid={
                                      touched["category"] && errors["category"]
                                        ? true
                                        : false
                                    } />

                                </Col>
                                <Col lg={6}>
                                  <DropdownInput
                                    options={priorityOptions}
                                    name="priority"
                                    label="Priority"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    value={values.priority}
                                    panelClassName='newCourierDropDown'
                                    invalid={
                                      touched["priority"] && errors["priority"]
                                        ? true
                                        : false
                                    } />
                                </Col>
                                <Col lg={12}>
                                  <InputCom
                                    labelName="Subject"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="textarea"
                                    name="subject"
                                    onBlur={handleBlur}
                                    value={values.subject}
                                    onChange={handleChange}
                                    isRequired={true}
                                    rowHeight={6}
                                    invalid={touched["subject"] && errors["subject"] ? true : false}
                                  />
                                </Col>
                                <Col lg={12} className="mb-2">
                                  <InputCom
                                    labelName="Description"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="textarea"
                                    name="description"
                                    onBlur={handleBlur}
                                    value={values.description}
                                    onChange={handleChange}
                                    isRequired={true}
                                    rowHeight={6}
                                    invalid={touched["description"] && errors["description"] ? true : false}
                                  />
                                </Col>
                                <Col lg={12} className="mb-2">
                                  <div className='attachment-container'>
                                    <div>Attachment </div>
                                    <div className='attachment-icon'><i className="fas fa-plus-circle" onClick={handleAddClick}></i></div>
                                    <input
                                      type="file"
                                      multiple
                                      ref={fileInputRef}
                                      style={{ display: 'none' }}
                                      onChange={(e: any) => {
                                        const files = Array.from(e.target.files);
                                        const validFiles = files.filter((file: any) => file.size <= maxSizeInBytes);
                                        if (validFiles.length !== files.length) {
                                          showToast(`Some files were too large and have been excluded. The maximum file size is ${maxSizeInBytes / 1024 / 1024} MB.`, { type: "error" });
                                          fileInputRef.current.value = '';
                                          return
                                        }
                                        setSelectedFiles((prevValue: any[]) => {
                                          let files: any[] = [...prevValue];
                                          files = [...files, ...e.target.files];
                                          return files
                                        })
                                      }}
                                    />
                                  </div>
                                </Col>
                              </>
                            }
                            {id &&
                              <>
                                <div className="view-details-container">
                                  <div className={` pt-3 viewTicketCls`}>
                                    <div>Subject</div>
                                    <div className='childTicket'>:</div>
                                    <div className='weight-700'>{editData['category']}</div>
                                  </div>
                                  <div className={` pt-3 viewTicketCls`}>
                                    <div>Status</div>
                                    <div className='childTicket'>:</div>
                                    <div className='weight-700'>{editData['status']}</div>
                                  </div>
                                  <div className={` pt-3 viewTicketCls`}>
                                    <div>Priority</div>
                                    <div className='childTicket'>:</div>
                                    <div className='weight-700'>{editData['priority']}</div>
                                  </div>
                                  <div className={` pt-3 viewTicketCls`}>
                                    <div>Description</div>
                                    <div className='childTicket'>:</div>
                                    <div className='weight-700'>{editData['description']}</div>
                                  </div>
                                  <div className={` pt-3 viewTicketCls`}>
                                    <div>Created On</div>
                                    <div className='childTicket'>:</div>
                                    <div className='weight-700'>{editData['createdOn']}</div>
                                  </div>
                                  <div className={` pt-3 viewTicketCls`}>
                                    <div>Attachements</div>
                                    <div className='childTicket'>:</div>
                                    <div className='weight-700'>
                                      <div className='editedDate_file'>
                                        {
                                          editData?.attachement?.length > 0 ? editData?.attachement?.map((data: any, i: number) => {
                                            if (data.type == "application/pdf") {
                                              return (
                                                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                  <i onClick={() => openDocumet(data.id)} className="fas fa-file-pdf"></i>
                                                </JobTooltipMEL>
                                              )
                                            } else if (data.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || data.type == "application/vnd.ms-excel") {
                                              return (
                                                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                  <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-csv"></i>
                                                </JobTooltipMEL>
                                              )
                                            }
                                            else if (isImageMimeType(data.type)) {
                                              return (
                                                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                  <i key={i} onClick={() => openDocumet(data.id)} className="fas fas fa-image"></i>
                                                </JobTooltipMEL>
                                              )
                                            } else {
                                              return (
                                                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                  <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-alt"></i>
                                                </JobTooltipMEL>
                                              )
                                            }
                                          }) : <span style={{ fontSize: "14px" }}>No attachement here</span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    induvidualEnquiry?.parentId && <><div className={` pt-3 viewTicketCls`}>
                                      <div>Check prevoius conversation</div>
                                      <div className='childTicket'>:</div>
                                      <Link to={ROUTER.VIEW_TICKET_SYSTEM + "/" + induvidualEnquiry?.parentId} >Show</Link>
                                    </div>
                                    </>
                                  }
                                  {/* induvidualEnquiry?.status == "Reopen" */}
                                  {
                                    hasAdminOrEmployeeRoleHandler() && <div className={` pt-3 reassignTicketCls`}>
                                      <div>Reassign Employee</div>
                                      <div className='weight-700'>
                                        <Col lg={2} className="mb-2">
                                          <DropdownInput
                                            options={empoyeeDropdown}
                                            name="reassignEmployee"
                                            label=""
                                            onChange={handleChange}
                                            required={false}
                                            value={values.reassignEmployee}
                                            onBlur={handleBlur}
                                            invalid={
                                              touched["reassignEmployee"] && errors["reassignEmployee"]
                                                ? true
                                                : false
                                            } />

                                        </Col>
                                      </div>
                                    </div>
                                  }

                                </div>


                              </>
                            }
                            {selectedFiles?.length > 0 && <Col lg={12}>
                              <div className='selected-file-container'>
                                <h5>Selected Files</h5>
                                <div className=''>
                                  <Row>
                                    {selectedFiles.map((eachValues: any, index: number) => {
                                      return (<Col lg={12} key={eachValues.name + index}>
                                        <div className='reply-attachment-container'>
                                          <div>{eachValues.name}</div>
                                          <div><i className="far fa-trash-alt selected-file-delete-icon" onClick={() => {
                                            setSelectedFiles((prevFiles: any[]) => {
                                              let data: any = [...prevFiles];
                                              data = data.filter((eachFiles: any, fileIndex: number) => fileIndex != index);
                                              fileInputRef.current.value = '';
                                              return data;
                                            })
                                          }}></i></div>
                                        </div>
                                      </Col>)
                                    })}
                                  </Row>
                                </div>
                              </div>
                            </Col>}




                          </Row>
                          {id && <Row className='reply-container'>
                            <div ref={scroll}>
                              {combinedMessages.map((eachMessage: any, index: number) => {
                                return (
                                  <div key={index} className={eachMessage.className}>
                                    <div className='message-sender-container'>
                                      <div className="weight-700">{eachMessage?.name}</div>
                                      <div className='message-box-date ms-2'>{eachMessage?.createdOn}</div>
                                    </div>
                                    <div className={eachMessage.messageClass}>
                                      {eachMessage?.description}
                                    </div>
                                    <div className='file_structures'>
                                      {
                                        eachMessage?.attachments?.map((data: any, i: number) => {
                                          if (data.type == "application/pdf") {
                                            return (
                                              <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                <i onClick={() => openDocumet(data.id)} className="fas fa-file-pdf"></i>
                                              </JobTooltipMEL>

                                            )
                                          } else if (data.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || data.type == "application/vnd.ms-excel") {
                                            return (
                                              <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                <i onClick={() => openDocumet(data.id)} className="fas fa-file-csv"></i>
                                              </JobTooltipMEL>
                                            )
                                          } else if (isImageMimeType(data.type)) {
                                            return (
                                              <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                <i onClick={() => openDocumet(data.id)} className="fas fas fa-image"></i>
                                              </JobTooltipMEL>
                                            )
                                          } else {
                                            return (
                                              <JobTooltipMEL key={i} title={data.name} position='bottom'>
                                                <i key={i} onClick={() => openDocumet(data.id)} className="fas fa-file-alt"></i>
                                              </JobTooltipMEL>
                                            )
                                          }
                                        })
                                      }
                                    </div>
                                    <div>
                                    </div>
                                  </div>)
                              })}
                            </div>



                            {!showReply && <Col lg={12}>
                              <div className='attachment-container'>
                                <div>Reply </div>
                                <div className='attachment-icon'><i className="fas fa-plus-circle" onClick={() => { setShowReply(true) }}></i></div>
                              </div>
                            </Col>}
                            {showReply && <Col lg={12}>
                              <InputCom
                                labelName=""
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="reply"
                                onBlur={handleBlur}
                                value={values.reply}
                                onChange={handleChange}
                                isRequired={false}
                                rowHeight={6}
                                invalid={touched["reply"] && errors["reply"] ? true : false}
                              />
                              <div className='reply-sent-container'>
                                <div className='attachment-container'>
                                  <div>Attachment </div>
                                  <div className='attachment-icon'><i className="fas fa-plus-circle" onClick={relpyHandlerAddClick}></i></div>
                                  <input
                                    type="file"
                                    multiple
                                    ref={replyFileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={(e: any) => {
                                      const files = Array.from(e.target.files);
                                      const validFiles = files.filter((file: any) => file.size <= maxSizeInBytes);
                                      if (validFiles.length !== files.length) {
                                        showToast(`Some files were too large and have been excluded. The maximum file size is ${maxSizeInBytes / 1024 / 1024} MB.`, { type: "error" });
                                        replyFileInputRef.current.value = '';
                                        return
                                      }
                                      setSelectedReplyFiles((prevValue: any[]) => {
                                        let files: any[] = [...prevValue];
                                        files = [...files, ...e.target.files];
                                        return files
                                      })
                                    }}
                                  />
                                </div>
                                <div className='reply-sent-btn-container'>
                                  <div className='attachment-container '>
                                    <div className='send-btn' onClick={() => setShowReply(false)}>Close <i className='fas fa-times-circle'></i> </div>
                                    <div className='attachment-icon'></div>
                                  </div>
                                  <div className='attachment-container '>
                                    <div className='send-btn' onClick={() => sendHandler(values, setFieldValue, setFieldTouched)}>Send <i className='fas fa-paper-plane'></i> </div>
                                    <div className='attachment-icon'></div>
                                  </div>
                                </div>

                              </div>
                              <div className='reply-attachment-alignment'>
                                <Row>
                                  {selectedReplyFiles.map((eachValues: any, index: number) => {
                                    return (<Col lg={6} key={eachValues.name + index}>
                                      <div className='reply-attachment-container'>
                                        <div>{eachValues.name}</div>
                                        <div><i className="far fa-trash-alt selected-file-delete-icon" onClick={() => {
                                          setSelectedReplyFiles((prevFiles: any[]) => {
                                            let data: any = [...prevFiles];
                                            data = data.filter((eachFiles: any, fileIndex: number) => fileIndex != index);
                                            replyFileInputRef.current.value = '';
                                            return data;
                                          })
                                        }}></i></div>
                                      </div>
                                    </Col>)
                                  })}
                                </Row>
                              </div>

                            </Col>}
                          </Row>}
                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={"Cancel"}
                              type="button"
                              onClick={() => navigate(ROUTER.TICKET_SYSTEM)}
                            />
                            {/* {
                              hasAdminOrEmployeeRoleHandler() && <Button
                                className="btn-primary me-2"
                                label={id ? "Reassign Ticket" : "Create Ticket"}
                                type="submit"
                              />
                            } */}
                            {
                              ((hasAdminOrEmployeeRoleHandler() || hasCustomerRoleHandler()) && !id) && <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={"Create Ticket"}
                                type="submit"
                              />
                            }
                            {/* {
                              ((hasAdminOrEmployeeRoleHandler() && !showReassignEmployee) && <Button
                                className="btn-primary me-2"
                                label={"Reassign Ticket"}
                                type="submit"
                                onClick={handleShowReassignEmployee}
                              />)
                            } */}
                            {/* && induvidualEnquiry?.status == "Reopen" */}
                            {
                              ((hasAdminOrEmployeeRoleHandler() && id) && <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={"Reassign the ticket"}
                                type="submit"
                              />
                              )
                            }

                            {
                              (hasCustomerRoleHandler() && id) && <>
                                <Button
                                  className="btn-primary me-2"
                                  label="Reopen Ticket"
                                  type="button"
                                  onClick={reOpenTicketHandle}
                                />
                                {
                                  (induvidualEnquiry?.status != "Closed" && (hasCustomerRoleHandler())) &&
                                  <Button
                                    className="btn-primary me-2 btn-primary-shadow"
                                    label="Close Ticket"
                                    type="button"
                                    onClick={colseTicketHandle}
                                  />
                                }

                              </>
                            }

                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateTicketSystem