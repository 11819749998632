import { StockValueBySupplierInitialStore } from "src/models/pages/stockValueBySupplier";
import { StockValueBySupplierTypes } from "./actionTypes";

const initialValue: StockValueBySupplierInitialStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  stockValueBySupplierList: {
    values: [],
    totalRecords: 0
  },
  supplierDropdown:""
}

const stockValueBySupplier = (state = initialValue, action: any) => {
  switch (action.type) {
    case StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_LOADING:
    case StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }

    case StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_SUCCESS:      
      return {
        ...state,
        loading: false,
        stockValueBySupplierList: {
          ...state.stockValueBySupplierList,
          values: action.payload.data,
          totalRecords: action.payload.data?.length
        },
        paginatorCount : action.payload.data?.length
      }

    case StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_SUCCESS:      
      return {
        ...state,
        loading: false,
        supplierDropdown:action.payload.data
      }

    case StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_ERROR:
    case StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state };
  }
}


export default stockValueBySupplier;