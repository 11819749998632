import React from "react";

interface columnFilter {
    column : any
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: columnFilter) {
    const count = preFilteredRows.length;
  
    return (
      <input
        className="form-control mt-2"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

export default DefaultColumnFilter;  