import { rewardValueTypes } from "./actionTypes"

export const getRewardValueLoading = () => {
    return {
      type: rewardValueTypes.GET_REWARD_VALUE_LOADING,
    }
  }
  
  export const getRewardValueSuccess = (resp: any) => {
    return {
      type: rewardValueTypes.GET_REWARD_VALUE_SUCCESS,
      payload: resp
    }
  }
  
  export const getRewardValueError = (error: any) => {
    return {
      type: rewardValueTypes.GET_REWARD_VALUE_ERROR,
      payload: error
    }
  }

  export const createRewardValueLoading = (rewardValue: any) => {
    return {
      type: rewardValueTypes.CREATE_REWARD_VALUE_LOADING,
      payload: rewardValue
    }
  }
  export const createRewardValueSuccess = (resp: any) => {
    return {
      type: rewardValueTypes.CREATE_REWARD_VALUE_SUCCESS,
      payload: resp
    }
  }
  export const createRewardValueError = (error: any) => {
    return {
      type: rewardValueTypes.CREATE_REWARD_VALUE_ERROR,
      payload: error
    }
  }
  