import React, { createContext, useContext, ReactNode } from "react";
import { ToastOptions, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastContextProps {
  children: ReactNode;
}

interface ToastContextType {
  showToast: (message: string, options?: ToastOptions) => void;
}

//create Context
const ToastContext = createContext<ToastContextType | undefined>(undefined);

//create Context Provider
export const ToastContextProvider: React.FC<ToastContextProps> = ({
  children,
}) => {
  const showToast = (message: string, options?: ToastOptions) => {
    toast(message, options);
  };
  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

//useContext
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
