import { IncentiveState } from "src/models/pages/incentiveModels";
import { IncentiveRewardTypes } from "./actionTypes";
import { IncentiveRewardState } from "src/models/pages/incentiverewardModel";

const initialState: IncentiveRewardState = {
  error: "",
  loading: false,
  message: "",
  incentiveRewardList: {
    totalCount: 0,
    values: [],
  },
  incentiveDetail:"",
  paginatorCount:0
};



const incentiveRewardReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_LOADING:
    case IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_LOADING:
    case IncentiveRewardTypes.UPDATE_PAYMENT_LOADING:
    case IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message:""
      };

    case IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incentiveRewardList: {
          values: action.payload.data.items,
          totalCount: action.payload.data.totalCount,
        },
        paginatorCount:action.payload.data.items.length
      };

    case IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        incentiveDetail:action.payload.data
      };
    case IncentiveRewardTypes.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Payment Detail Updated Successfully",
        error:""
      };
    case IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Adjustment Point Updated Successfully",
        error:""
      };
    case IncentiveRewardTypes.CLEAR_MESSAGE_INCENTIVE_REWARD:
      return {
        ...state,
        loading: false,
        message:"",
        error:""
      };
    case IncentiveRewardTypes.RESET_MESSAGE_INCENTIVE_REWARD:
      return {
        ...state,
        loading: false,
        message:"",
        error:"",
        incentiveDetail:"",
        incentiveRewardList: {
          totalCount: 0,
          values: [],
        },
      };

    case IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_FAIL:
    case IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_FAIL:
    case IncentiveRewardTypes.UPDATE_PAYMENT_FAIL:
    case IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_FAIL:
      return {
        ...state,
        message: "",
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default incentiveRewardReducer;