import { jobsheetTatReportsActions } from "./actionTypes";

export const getJobsheetTatReportsTatLoading=(lazyState :any)=>{
  return {
    type : jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_LOADING,
    lazyState
  }
}

export const getjobsheetTatReportsTatSuccess=(resp : any)=>{
  return {
    type : jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_SUCCESS,
    payload : resp
  }
}

export const getJobsheetReportsTatError=(error : any)=>{
  return {
    type : jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_ERROR,
    payload : error
  }
}