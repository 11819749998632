import React, { useEffect, useRef, useState } from "react";
import BreadcrumbWithFilter from "src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import ExportSearchClear from "src/components/Common/ExportSearchClear/ExportSearchClear";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta, DataTableRowClickEvent, DataTableFilterMetaData } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { RootReducerState } from "src/store/reducers";
import { CurrencyFormatter, HtmlRowInput, convertTableToCSV, convertToMonthYear, saveAsExcelFile, saveDataAsExcel, saveDataAsPdf } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { deletePartsFromList, getPartsList } from "src/store/parts/action";
import { ROUTER } from "src/constants/routes";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { Dialog } from "primereact/dialog";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { getPartsDropdownListQty, getStockQtybyPartsList, resetStockQtybyPartMessage } from "src/store/actions";
import { StockQtybyPart } from "src/models/pages/stockQtybyPart";
import moment from 'moment';
import jsPDF from "jspdf";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { OverlayPanel } from "primereact/overlaypanel";
import StockValuePartChild from "./StockValuePartChild";



let availableTypes = ["missing", "opening", "rejected", "scrapped", "unused", "used", "closing"];

function formatMonthYear(monthYear: any) {
  const [month, year] = monthYear.split('-');
  return `${year}${month.padStart(2, '0')}`;
}

const stockQtybyPartListConverson = (values: any[]) => {
  console.count("values");

  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['partNumber'] = eachValue?.partNumber;
    data['partDesc'] = eachValue?.partDescription;
    data['audioBrand'] = eachValue?.audioBrand;
    eachValue.monthYears.forEach((res: any) => {
      const month = formatMonthYear(res.monthYear);
      data[`closing${month}`] = res.stockDeatails?.closing;
      data[`missing${month}`] = res.stockDeatails?.missing;
      data[`opening${month}`] = res.stockDeatails?.opening;
      data[`rejected${month}`] = res.stockDeatails?.rejected;
      data[`scrapped${month}`] = res.stockDeatails?.scrapped;
      data[`unused${month}`] = res.stockDeatails?.unused;
      data[`used${month}`] = res.stockDeatails?.used
        ;
    });
    // eachValue.reportData.forEach((res: any) => {
    //   const month = res.month;
    //   data[`unUsed_${month}`] = res.value;
    // });
    return data;
  });
  return modifeidValue;
};


export default function StockQtybyPartComponent() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.stockQtybyPartReducer.stockQtybyPartList);
  const { partsDropdownList } = useSelector((state: RootReducerState) => state.stockQtybyPartReducer);
  const[modifiedValues,setModifiedValues]=useState<any>();
  useEffect(()=>{
    const modifiedVal: any[] = stockQtybyPartListConverson(values);
    setModifiedValues(modifiedVal)
  },[values])

  const formattedResponse = values.map(part => {
    return {
      ...part,
      monthYears: part.monthYears.map(data => ({
        ...data,
        monthYear: formatMonthYear(data.monthYear)
      }))
    };
  });

  const monthsmodi = formattedResponse?.map((data) => data.monthYears?.map((newdata) => newdata.monthYear));
  let monthsArr = Array.from(new Set(monthsmodi.flatMap(item => item.map((data) => data))));

  // const monthsArr = Array.from(new Set(values.flatMap(item => item.reportData?.map((data: any) => data.month))));

  // console.log("modifiedValues", modifiedValues);

  // const months =monthsArr.sort((a,b)=>a-b)

  const parseYearMonth = (str: any) => {
    const year = parseInt(str.slice(0, 4));
    const month = parseInt(str.slice(4));
    return { year, month };
  };
  const sortingMonthDataOrderWise = (data: any) => {
    return data.sort((a: any, b: any) => {
      const aParsed = parseYearMonth(a);
      const bParsed = parseYearMonth(b);

      if (aParsed.year === bParsed.year) {
        return aParsed.month - bParsed.month;
      } else {
        return aParsed.year - bParsed.year;
      }
    });
  };

  const allMonths = monthsArr.sort((a: any, b: any) => a - b);
  const months = sortingMonthDataOrderWise(allMonths);

  const { showToast } = useToast();
  const loading = useSelector((state: RootReducerState) => state.stockQtybyPartReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const { error, message } = useSelector((state: RootReducerState) => state.stockQtybyPartReducer);
  const dispatch = useDispatch();
  const dt:any = useRef<DataTable<any>>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<StockQtybyPart | null>(null);
  const filterDetails: DataTableFilterMeta = {
    StartDate: { value: "", matchMode: "contains" },
    endDate: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.PARTS_CREATE);
  };

  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.PARTS_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.PARTS_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <ColumnAction
        displayData={rowData[field] as string} />
    );
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockQtybyPartMessage());
    }
  }, [error, message]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const currencyBodyTemplate = (rowData: parts, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };


  const headerGroup = (
    <ColumnGroup>
      <Row >
        <Column header="Part Number" colSpan={1} />
        <Column header="Part Description" colSpan={1} />
        <Column header="Audio Brand" colSpan={1} />
        {/* {months.map((month: any) => {
          const date = moment(month, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          return (
            <Column key={month} header={formattedDate} />
          );
        })} */}
        {months.map((month: any) => {
          const date = moment(month, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          return (
            <Column colSpan={7} key={month} header={formattedDate} />
          );
        })}
      </Row>

      <Row>
        <Column field="partNumber" />
        <Column field="partDesc" />
        <Column field="audioBrand" />

        {months.map((month: any) => {
          return availableTypes.map((da) => {
            return (
              <Column rowSpan={7} key={month + `${da}`} header={`${da?.split("")[0].toUpperCase()}${da?.slice(1)}`} field={`${da}${month}`} />
            );
          });
        })}

        {/* {months.map((month: any) => (
          <Column key={month + '_unused'} header="Unused" field={`unUsed_${month}`} />
        ))} */}
      </Row>
    </ColumnGroup>
  );

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const columns: any = [

    {
      field: "partNumber",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      frozen: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isStatic: true,
    },
    {
      field: "partDesc",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "audioBrand",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    ...months.flatMap((month: any) => {
      return availableTypes.map((da) => {
        return {
          field: `${da}${month}`,
          sortable: false,
          header: `${da}${month}`,
          filter: false,
          showFilterMenu: false,
          isDefault: true,
          headerStyle: { whiteSpace: "nowrap" },
          isStatic: false,
          className: "align-right"
        };
      });
    })


    // {
    //   field: "unUsed",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   isDefault: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isStatic: false,
    // },
    // ...months.map((month: any) => ({
    //   field: `unUsed_${month}`,
    //   sortable: false,
    //   header: `unUsed_${month}`,
    //   filter: false,
    //   showFilterMenu: false,
    //   isDefault: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   body: (rowData: any) => headerTemplate(rowData[`unUsed_${month}`]),
    //   isStatic: false,
    // })),
    // {
    //   field: "optionStock1",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   isDefault: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isStatic: false,
    //   className: "align-right"
    // },

    // {
    //     field: "unUsed1",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap", width: "250px"  },
    //     isStatic: true,
    //     className: "align-right"
    //   },
    //   {
    //     field: "used1",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap" },
    //     isStatic: false,
    //     className: "align-right"
    //   },
    //   {
    //     field: "rejected1",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap" },
    //     isStatic: false,
    //     className: "align-right"
    //   },
    //   {
    //     field: "scrapped1",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap" },
    //     isStatic: false,
    //     className: "align-right"
    //   },
    // {
    //   field: "missing1",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   isDefault: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isStatic: false,
    //   className: "align-right"
    // },
    // {
    //   field: "closedStock1",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   isDefault: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isStatic: false,
    //   className: "align-right"
    // },
    // {
    //     field: "optionStock2",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap" },
    //     isStatic: false,
    //     className: "align-right"
    //   },

    //   {
    //       field: "unUsed2",
    //       sortable: false,
    //       header: "",
    //       filter: false,
    //       showFilterMenu: false,
    //       isDefault: true,
    //       headerStyle: { whiteSpace: "nowrap", width: "250px"  },
    //       isStatic: true,
    //       className: "align-right"
    //     },
    //     {
    //       field: "used2",
    //       sortable: false,
    //       header: "",
    //       filter: false,
    //       showFilterMenu: false,
    //       isDefault: true,
    //       headerStyle: { whiteSpace: "nowrap" },
    //       isStatic: false,
    //       className: "align-right"
    //     },
    //     {
    //       field: "rejected2",
    //       sortable: false,
    //       header: "",
    //       filter: false,
    //       showFilterMenu: false,
    //       isDefault: true,
    //       headerStyle: { whiteSpace: "nowrap" },
    //       isStatic: false,
    //       className: "align-right"
    //     },
    //     {
    //       field: "scrapped2",
    //       sortable: false,
    //       header: "",
    //       filter: false,
    //       showFilterMenu: false,
    //       isDefault: true,
    //       headerStyle: { whiteSpace: "nowrap" },
    //       isStatic: false,
    //       className: "align-right"
    //     },
    //   {
    //     field: "missing2",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap" },
    //     isStatic: false,
    //     className: "align-right"
    //   },
    //   {
    //     field: "closedStock2",
    //     sortable: false,
    //     header: "",
    //     filter: false,
    //     showFilterMenu: false,
    //     isDefault: true,
    //     headerStyle: { whiteSpace: "nowrap" },
    //     isStatic: false,
    //     className: "align-right"
    //   },

  ];


  // const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const visibleColumns = columns.filter((eachColumn: Columns) => eachColumn.isDefault);

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachStock) => {
      const { partNumber, partDesc, audioBrand, optionStock1, unUsed1, used1, rejected1, scrapped1, missing1, closedStock1, optionStock2, unUsed2,
        used2, rejected2, scrapped2, missing2, closedStock2 } = eachStock;
      let data: any = {};
      data = {
        'Part Number': partNumber,
        'Part Description': partDesc,
        'Audio Brand': audioBrand,
        'Op.Stock': optionStock1,
        'Unused': unUsed1,
        'Used': used1,
        'Rejected': rejected1,
        'Scrapped': scrapped1,
        'Missing': missing1,
        'Cl.Stock': closedStock1,
        'Op.Stock2': optionStock2,
        'Unused2': unUsed2,
        'Used2': used2,
        'Rejected2': rejected2,
        'Scrapped2': scrapped2,
        'Missing2': missing2,
        'Cl.Stock2': closedStock2,
      };

      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const csvClickHandler = () => {
    debugger
    const dataTable: any = dt.current;
    const table: HTMLTableElement = dataTable.getTable();
    const fileName = "stockQtybyPart";
    import('xlsx').then((xlsx) => {
      const worksheet = convertTableToCSV(table);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });

  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas: any = {
        partNumber: data.partNumber,
        partDescription: data.partDescription,
        audioBrandName: data.audioBrandName

      };
      const formatted = values.flatMap((data) => {
        if (data.reportData?.length > 0) {
          return data.reportData.map((newformat: any) => {
            return {
              month: newformat.month,
              value: newformat.value
            };
          });
        }
        return [];
      });
      datas[formatted[0].month] = formatted[0].value;
      return datas;
    });

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then((autoTable) => {
        columns = columns.filter((a: any) => isNaN(Number(a?.field)));

        let exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));

        const row1 = new HtmlRowInput(document.createElement('tr'));

        row1.push({
          content: 'Part Number', styles: {
            textColor: 'white',
            halign: "center",
            valign: "middle"
          }, colSpan: 1, rowSpan: 2,
        }, {
          content: 'Part Desc', styles: {
            textColor: 'white',
            halign: "center",
            valign: "middle"
          }, colSpan: 1, rowSpan: 2
        }, {
          content: 'Audio Brand', styles: {
            textColor: 'white',
            halign: "center",
            valign: "middle"
          }, colSpan: 1, rowSpan: 2,
        });

        months?.forEach((month: any) => {
          const date = moment(month, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          row1.push({
            content: formattedDate, styles: {
              textColor: 'white',
              halign: "center",
              valign: "middle"
            }, colSpan: 1, rowSpan: 1
          });
        });

        const row2 = new HtmlRowInput(document.createElement('tr'));

        row2.push(
          {
            content: "Unused", styles: {
              textColor: 'white',
              halign: "center",
              valign: "middle"
            }, colSpan: 1, rowSpan: 1
          },
          {
            content: "Unused", styles: {
              textColor: 'white',
              halign: "center",
              valign: "middle"
            }, colSpan: 1, rowSpan: 1
          }
        );

        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        const columnStyles = getColumnStyles();
        autoTable.default(doc, {
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          columnStyles: columnStyles,
          body: modifiedValues,
          showFoot: "everyPage",
          head: [row1, row2],
          tableWidth: "auto",
        });
        // doc.autoTable(exportColumns, products);
        doc.save('stockQuantitybyPart.pdf');
      });
    });
  };


  const getColumnStyles = () => {
    const columnStyles: any = {};
    const month = months;
    month.forEach((month: any) => {
      columnStyles[`unUsed_${month}`] = { halign: 'right' };
    });
    return columnStyles;
  };
  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getStockQtybyPartListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      // let resp=await dealerListConverson(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(response?.data, columns, "stockQuantitybyPart");
      } else {
        saveDataAsExcel(response?.data, "stockQuantitybyPart");
      }
      setPdfLoading(false);
    } catch (error) {
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };
  const pdfClickHandler = () => {

    // saveDataAsPdf(values, visibleColumns, "Stock_Quantity_By_Part");
    fetchData(true);


    //   const dataTable: any = dt.current;
    //   const table: HTMLTableElement = dataTable.getTable();

    //   const fileName = "stockQtybyPart";
    //   const padding = 10; // Adjust the padding value as needed

    // import('html2canvas').then((html2canvas) => {
    //   html2canvas.default(table).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const imgWidth = pdf.internal.pageSize.getWidth();
    //     const imgHeight = canvas.height * imgWidth / canvas.width;
    //     const x = padding;
    //     const y = padding;

    //     pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
    //     pdf.save(`${fileName}.pdf`);
    //   });
    // });

  };
  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getStockQtybyPartsList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    dispatch(deletePartsFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      showToast("Part Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(getStockQtybyPartsList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col: any) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    // setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col: any) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    // setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

    
  const partsOption=partsDropdownList?.length>0 ? partsDropdownList?.map((item:any)=>({
    label:item.partNumber,
    value:item.partNumber
  })) : []

  const calendarRef = useRef<any>(null);
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [startShowDate, setStartShowDate] = useState<any>()
  const [endShowDate, setEndShowDate] = useState<any>()
  const[part,setPart]=useState<any>()
  const[showPart,setShowPart]=useState<any>()
useEffect(()=>{
  dispatch(getPartsDropdownListQty())
},[])
  const searchCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setStartShowDate(startDate)
    setEndShowDate(endDate)
    setShowPart(part)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate:{value:startDate ? `${startDate}-01` : "" ,matchMode:"contains"},
        endDate:{value:endDate ? `${endDate}-01` : "" ,matchMode:"contains"},
        partNumber:{value:part,matchMode:"contains"},
      },
    }));
    
  }
  const clearCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setEndDate(null)
    setStartDate(null)
    setEndShowDate(null)
    setStartShowDate(null)
    setPart(null)
    setShowPart(null)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate:{value:"",matchMode:"contains"},
        endDate:{value:"",matchMode:"contains"},
        partNumber:{value:"",matchMode:"contains"},
      },
    }));
  }
  const clearFilter = () => {
    setEndDate(null)
    setStartDate(null)
    setEndShowDate(null)
    setStartShowDate(null)
    setPart(null)
    setShowPart(null)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate:{value:"",matchMode:"contains"},
        endDate:{value:"",matchMode:"contains"},
        partNumber:{value:"",matchMode:"contains"},
      },
    }));
  }

  const header =
    <div className="table-header-container">
      <div>{'Stock Qty by Part'}</div>
      <div id="calendar" className="calendar_btns" style={{ marginLeft: "10px" }}>
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-soft-primary waves-effect waves-light"
              // onClick={toggleCalendar}
              onClick={(e) => calendarRef.current.toggle(e)}
            >
              {/* <i className="bx bx-calendar font-size-16 align-middle"></i> */}
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={calendarRef}>
        <div className="mb-2">
          <label htmlFor="">Select Start Month</label>
          <input
            className='form-control'
            type="month"
            name="month"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">Select End Month</label>
          <input
            className='form-control'
            type="month"
            name="month"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">Select Part</label>
          <Dropdown value={part} onChange={(e) => setPart(e.value)} options={partsOption} optionLabel="label" placeholder="Select Part" filter className="w-full md:w-14rem" />
        </div>
        <div className="p-column-filter-buttonbar" style={{ display: "flex", justifyContent: "space-between" }}>
          <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel>
      <div className="table-header-container-item align_width">
        <div className="m-2 d-flex flex-wrap gap-1 align_justify">
          {startShowDate &&
          <div className='job-basic-info'>
            <div className='title text-center' style={{ background: "#dff0ff", color: "#749be0", width: "auto", whiteSpace: "nowrap" }}>Start Month</div>
            <b className='m-auto text-center font-size-13' style={{ minWidth: "120px", whiteSpace: "nowrap" }}>{convertToMonthYear(startShowDate)}</b>
          </div>
}
          {endShowDate &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>End Month</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{convertToMonthYear(endShowDate)}</b>
            </div>
          }
          {showPart &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>Select Part</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{showPart}</b>
            </div>
          }
          </div>
          </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} isShowPdf={false} TooltipPosition="left" clearFilter={clearFilter} isFilter={true}/>
      </div>

    </div>;
  const uniqueMonths = Array.from(
    new Set(
      values?.flatMap((item) =>
        item.monthYears.map((monthYear) => monthYear.monthYear)
      )
    )
  ).reverse();
  
  return (
    <>
      <div className="page-content" id="stock-music-system">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <div id="table-border">
        {/* <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          reorderableColumns={false}
          headerColumnGroup={headerGroup}
          stripedRows={true}
          rowHover={true}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          loading={loading}
          emptyMessage="No parts found."
        
        ></DataTableWrapper> */}
    
     
    {/* <div className="table-container">
      <div className="p-datatable p-component p-datatable-hoverable-rows p-datatable-scrollable p-datatable-responsive-scroll p-datatable-striped p-datatable-grouped-header">
        <div className="p-datatable-header">
          {header}
        </div>
      <div className="p-datatable-wrapper" style={{overflow:"auto", maxHeight:"500px"}}>
      <table border={1} className="p-datatable-table p-datatable-scrollable-table" style={{minWidth:"50rem", fontSize:"14px"}}>
        <thead className="p-datatable-thead">
          <tr>
            <th rowSpan={2}>Part Number</th>
            <th rowSpan={2}>Part Description</th>
            <th rowSpan={2}>Audio Brand</th>
            {monthsArr.map((month, index) => 
              {
                const date = moment(month, 'YYYYMM');
                const formattedDate = date.format('MMM-YYYY');
                return(
              <React.Fragment key={index}>
                <th colSpan={7}>{formattedDate}</th>
              </React.Fragment>
                )
              }
            )}
          </tr>
          <tr>
            
            {monthsArr.map((month, index) => (
              <React.Fragment key={index}>
                <th>Missing</th>
                <th>Opening</th>
                <th>Rejected</th>
                <th>Scrapped</th>
                <th>Unused</th>
                <th>Used</th>
                <th>Closing</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody className="p-datatable-tbody">
          {values.map((item, index) => (
            <tr key={index}>
              <td>{item.partNumber}</td>
              <td>{item.partDescription}</td>
              <td>{item.audioBrand}</td>
              {monthsArr.map((month, idx) => {
                const monthData = item.monthYears.find(
                  (my) => formatMonthYear(my.monthYear) === month
                );
                return monthData ? (
                  <React.Fragment key={idx}>
                    <td>{monthData.stockDeatails.missing}</td>
                    <td>{monthData.stockDeatails.opening}</td>
                    <td>{monthData.stockDeatails.rejected}</td>
                    <td>{monthData.stockDeatails.scrapped}</td>
                    <td>{monthData.stockDeatails.unused}</td>
                    <td>{monthData.stockDeatails.used}</td>
                    <td>{monthData.stockDeatails.closing}</td>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={idx}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </div> */}
    <StockValuePartChild referece={dt} header={header} monthsArr={monthsArr} values={values} />
    </div>
        
      </div>
    </>
  );
}
