import { PartInboudReducerInitialState } from "src/models/pages/partsInboundModel";
import { PartsInboundTypes } from "./acionTypes";

const initialState: PartInboudReducerInitialState = {
  error: "",
  loading: false,
  message: "",
  PartsInboundList: {
    values: [],
    totalRecords: 0
  },
  paginatorCount: 0,
  editInbound: null,
  supplierList: null,
  stockLocationList: null,
  purchaseOrderList: null,
  partsList: null
};

const PartsInbound = (state = initialState, action: any) => {
  switch (action.type) {
    case PartsInboundTypes.GET_PARTS_INBOUND_LIST:
    case PartsInboundTypes.DELETE_PARTS_INBOUND:
    case PartsInboundTypes.UPDATE_PARTS_INBOUND:
    case PartsInboundTypes.CREATE_PARTS_INBOUND:
    case PartsInboundTypes.GET_PARTS_INBOUND_BY_ID:
    case PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_LOADING :  
      return {
        ...state,
        loading: true,
        message : "",
        paginatorCount: 0,
       
      };

    case PartsInboundTypes.GET_PARTS_INBOUND_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        PartsInboundList: {
          ...state.PartsInboundList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items.length
      };

    case PartsInboundTypes.DELETE_PARTS_INBOUND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo ? "Parts Inbound  restored successfully" : "Parts Inbound  deleted successfully",
      }
    case PartsInboundTypes.CREATE_PARTS_INBOUND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Parts inbound created successfully"
      }

    case PartsInboundTypes.UPDATE_PARTS_INBOUND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Parts inbound updated successfully"
      }


    case PartsInboundTypes.GET_PARTS_INBOUND_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        editInbound: action.payload.data
      }


    case PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_SUCCESS: 
     return {
        ...state,
        loading: false,
        error : "",
        message : "Parts sku generated successfully"
     }  

    case PartsInboundTypes.RESET_PARTS_INBOUND:
      return {
        ...state,
        message: "",
        error: "",
        editInbound: ""
      }

    case PartsInboundTypes.DELETE_PARTS_INBOUND_FAIL:
    case PartsInboundTypes.GET_PARTS_INBOUND_LIST_FAIL:
    case PartsInboundTypes.CREATE_PARTS_INBOUND_ERROR:
    case PartsInboundTypes.UPDATE_PARTS_INBOUND_ERROR:
    case PartsInboundTypes.GET_PARTS_INBOUND_BY_ID_ERROR:
    case PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_ERROR :  
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PartsInboundTypes.GET_SUPPLIER_DROPDOWN_SUCCESS:      
      return {
        ...state,
        supplierList: action.payload.data
      }

    case PartsInboundTypes.GET_STOCK_LOCATION_DROPDOWN_SUCCESS:

      return {
        ...state,
        stockLocationList: action.payload.data
      }
    case PartsInboundTypes.GET_PURCHASE_ORDER_DROPDOWN_SUCCESS:

      return {
        ...state,
        purchaseOrderList: action.payload.data
      }

    case PartsInboundTypes.GET_PARTS_DROPDOWN_SUCCESS:

      return {
        ...state,
        partsList: action.payload.data
      }

    case PartsInboundTypes.CLEAR_PARTS_INBOUND_MESSAGE :
      return {
        ...state,
        error : "",
        message : ""
      }  
    default:
      return { ...state };
  }
};

export default PartsInbound;
