export enum IntentActionTypes {

  GET_INTENT_LIST_LOADING = '@@intent/GET_INTENT_LIST_LOADING',
  GET_INTENT_LIST_SUCCESS = '@@intent/GET_INTENT_LIST_SUCCESS',
  GET_INTENT_LIST_FAIL = '@@intent/GET_INTENT_LIST_FAIL',

  GET_FRANCHISE_LIST_FOR_INTENT_LOADING = "@@intent/GET_FRANCHISE_LIST_FOR_INTENT_LOADING",
  GET_FRANCHISE_LIST_FOR_INTENT_SUCCESS = "@@intent/GET_FRANCHISE_LIST_FOR_INTENT_SUCCESS",
  GET_FRANCHISE_LIST_FOR_INTENT_ERROR = "@@intent/GET_FRANCHISE_LIST_FOR_INTENT_ERROR",

  DELETE_INTENT_LIST_LOADING = "@@intent/DELETE_INTENT_LIST_LOADING",
  DELETE_INTENT_LIST_SUCCESS = "@@intent/DELETE_INTENT_LIST_SUCCESS",
  DELETE_INTENT_LIST_ERROR = "@@intent/DELETE_INTENT_LIST_ERROR",

  UPDATE_INTENT_LOADING = "@@intent/UPDATE_INTENT_LOADING",
  UPDATE_INTENT_SUCCESS = "@@intent/UPDATE_INTENT_SUCCESS",
  UPDATE_INTENT_ERROR = "@@intent/UPDATE_INTENT_ERROR",

  CREATE_INTENT_LOADING = "@@intent/CREATE_INTENT_LOADING",
  CREATE_INTENT_SUCCESS = "@@intent/CREATE_INTENT_SUCCESS",
  CREATE_INTENT_ERROR = "@@intent/CREATE_INTENT_ERROR",

  GET_INTENT_BY_ID_LOADING = "@@intent/GET_INTENT_BY_ID_LOADING",
  GET_INTENT_BY_ID_SUCCESS = "@@intent/GET_INTENT_BY_ID_SUCCESS",
  GET_INTENT_BY_ID_ERROR = "@@intent/GET_INTENT_BY_ID_ERROR",

  GET_PART_LIST_FOR_INTENT_LOADING = "@@intent/GET_PART_LIST_FOR_INTENT_LOADING",
  GET_PART_LIST_FOR_INTENT_SUCCESS = "@@intent/GET_PART_LIST_FOR_INTENT_SUCCESS",
  GET_PART_LIST_FOR_INTENT_ERROR = "@@intent/GET_PART_LIST_FOR_INTENT_ERROR",

  APPROVE_INTENT_LOADING = "@@intent/APPROVE_INTENT_LOADING",
  APPROVE_INTENT_SUCCESS = "@@intent/APPROVE_INTENT_SUCCESS",
  APPROVE_INTENT_ERROR = "@@intent/APPROVE_INTENT_ERROR",

  REJECT_INTENT_LOADING = "@@intent/REJECT_INTENT_LOADING",
  REJECT_INTENT_SUCCESS = "@@intent/REJECT_INTENT_SUCCESS",
  REJECT_INTENT_ERROR = "@@intent/REJECT_INTENT_ERROR",

  DISPATCH_INTENT_LOADING = "@@intent/DISPATCH_INTENT_LOADING",
  DISPATCH_INTENT_SUCCESS = "@@intent/DISPATCH_INTENT_SUCCESS",
  DISPATCH_INTENT_ERROR = "@@intent/DISPATCH_INTENT_ERROR",

  RECEIVE_INTENT_LOADING = "@@intent/RECEIVE_INTENT_LOADING",
  RECEIVE_INTENT_SUCCESS = "@@intent/RECEIVE_INTENT_SUCCESS",
  RECEIVE_INTENT_ERROR = "@@intent/RECEIVE_INTENT_ERROR",

  CLEAR_INTENT_MESSAGE = "@@intent/CLEAR_INTENT_MESSAGE",
  RESET_INTENT_VALUE = "@@intent/RESET_INTENT_VALUE",

  //get courierList
  GET_INTENT_COURIER_LIST_LOADING = "@@intent/GET_INTENT_COURIER_LIST_LOADING",
  GET_INTENT_COURIER_LIST_SUCCESS = "@@intent/GET_INTENT_COURIER_LIST_SUCCESS",
  GET_INTENT_COURIER_LIST_ERROR = "@@intent/GET_INTENT_COURIER_LIST_ERROR",

  //get deliverableuserList
  GET_INTENT_DELIVERABLE_USER_LIST_LOADING = "@@intent/GET_INTENT_DELIVERABLE_USER_LIST_LOADING",
  GET_INTENT_DELIVERABLE_USER_LIST_SUCCESS = "@@intent/GET_INTENT_DELIVERABLE_USER_LIST_SUCCESS",
  GET_INTENT_DELIVERABLE_USER_LIST_ERROR = "@@intent/GET_INTENT_DELIVERABLE_USER_LIST_ERROR",

  //post deliverable courier intent
  POST_COURIER_INTENT_LOADING = "@@intent/POST_COURIER_INTENT_LOADING",
  POST_COURIER_INTENT_SUCCESS = "@@intent/POST_COURIER_INTENT_SUCCESS",
  POST_COURIER_INTENT_ERROR = "@@intent/POST_COURIER_INTENT_ERROR",

  PARTS_VALIDATE="@@intent/PARTS_VALIDATE",
  PARTS_VALIDATE_SUCCESS="@@intent/PARTS_VALIDATE_SUCCESS",
  PARTS_VALIDATE_ERROR="@@intent/PARTS_VALIDATE_ERROR",

  PRINT_INTEND="@@intent/PRINT_INTEND",
  PRINT_INTEND_SUCCESS="@@intent/PRINT_INTEND_SUCCESS",
  PRINT_INTEND_ERROR="@@intent/PRINT_INTEND_ERROR",

  EMAIL_INTEND="@@intent/EMAIL_INTEND",
  EMAIL_INTEND_SUCCESS="@@intent/EMAIL_INTEND_SUCCESS",
  EMAIL_INTEND_ERROR="@@intent/EMAIL_INTEND_ERROR",
}
