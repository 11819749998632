export enum PurchaseOrderTypes {

    // Grid
    GET_PURCHASE_ORDER_LIST = "@@purchase_order/GET_PURCHASE_ORDER_LIST",
    GET_PURCHASE_ORDER_LIST_SUCCESS = "@@purchase_order/GET_PURCHASE_ORDER_LIST_SUCCESS",
    GET_PURCHASE_ORDER_LIST_FAIL = "@@purchase_order/GET_PURCHASE_ORDER_LIST_FAIL",

    GET_PURCHASE_ORDER_BY_ID="@@purchase_order/GET_PURCHASE_ORDER_BY_ID",
    GET_PURCHASE_ORDER_BY_ID_SUCCESS="@@purchase_order/GET_PURCHASE_ORDER_BY_ID_SUCCESS",
    GET_PURCHASE_ORDER_BY_ID_FAIL = "@@purchase_order/GET_PURCHASE_ORDER_BY_ID_FAIL",
  
    DELETE_PURCHASE_ORDER = "@@purchase_order/DELETE_PURCHASE_ORDER",
    DELETE_PURCHASE_ORDER_SUCCESS = "@@purchase_order/DELETE_PURCHASE_ORDER_SUCCESS",
    DELETE_PURCHASE_ORDER_FAIL = "@@purchase_order/DELETE_PURCHASE_ORDER_FAIL",
    // //reset
    RESET_PURCHASE_ORDER = "@@purchase_order/RESET_PURCHASE_ORDER",
   
    // //update PURCHASE_ORDER
    UPDATE_PURCHASE_ORDER = "@@purchase_order/UPDATE_PURCHASE_ORDER",
    UPDATE_PURCHASE_ORDER_SUCCESS = "@@purchase_order/UPDATE_PURCHASE_ORDER_SUCCESS",
    UPDATE_PURCHASE_ORDER_ERROR = "@@purchase_order/UPDATE_PURCHASE_ORDER_ERROR",

    //create
    CREATE_PURCHASE_ORDER = "@@purchase_order/CREATE_PURCHASE_ORDER",
    CREATE_PURCHASE_ORDER_SUCCESS = "@@purchase_order/CREATE_PURCHASE_ORDER_SUCCESS",
    CREATE_PURCHASE_ORDER_ERROR = "@@purchase_order/CREATE_PURCHASE_ORDER_ERROR",

    // get dropdowns
    GET_DROPDOWN_FOR_PURCHASE_ORDER = "@@purchase_order/GET_DROPDOWN_FOR_PURCHASE_ORDER",
    GET_DROPDOWN_FOR_PURCHASE_ORDER_SUCCESSS = "@@purchase_order/GET_DROPDOWN_FOR_PURCHASE_ORDER_SUCCESSS",
    GET_DROPDOWN_FOR_PURCHASE_ORDER_FAIL = "@@purchase_order/GET_DROPDOWN_FOR_PURCHASE_ORDER_FAIL",

    // Parts partcial order
    GET_AUTOCOMPLETE_PARTS_LIST = "@@purchase_order/GET_AUTOCOMPLETE_PARTS_LIST",
    GET_AUTOCOMPLETE_PARTS_LIST_SUCCESSS = "@@purchase_order/GET_AUTOCOMPLETE_PARTS_LIST_SUCCESSS",
    GET_AUTOCOMPLETE_PARTS_LIST_FAIL = "@@purchase_order/GET_AUTOCOMPLETE_PARTS_LIST_FAIL",

    // DELETE PURCHASE ORDER DETAILS

    DELETE_PURCHASE_ORDER_DETAILS = "@@purchase_order/DELETE_PURCHASE_ORDER_DETAILS",
    DELETE_PURCHASE_ORDER_DETAILS_SUCCESSS = "@@purchase_order/DELETE_PURCHASE_ORDER_DETAILS_SUCCESSS",
    DELETE_PURCHASE_ORDER_DETAILS_FAIL = "@@purchase_order/DELETE_PURCHASE_ORDER_DETAILS_FAIL",

    //get audioModel

    GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_LOADING="@@purchase_order/GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_LOADING",
    GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_SUCCESS="@@purchase_order/GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_SUCCESS",
    GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_ERROR="@@purchase_order/GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_ERROR",

    //  Reset Purchase order index
    RESET_PURCHASE_ORDER_INDEX = "@@purchase_order/RESET_PURCHASE_ORDER_INDEX",

    // sent mail to supplier for that purchase order
    SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER = "@@purchase_order/SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER",
    SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS = "@@purchase_order/SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS",
    SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL = "@@purchase_order/SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL",

    // Download purchase order

    DOWNLOAD_PURCHASE_ORDER_LOADING = "@@purchase_order/DOWNLOAD_PURCHASE_ORDER_LOADING",
    DOWNLOAD_PURCHASE_ORDER_SUCCESS = "@@purchase_order/DOWNLOAD_PURCHASE_ORDER_SUCCESS",
    DOWNLOAD_PURCHASE_ORDER_ERROR = "@@purchase_order/DOWNLOAD_PURCHASE_ORDER_ERROR",

    // Clear the state

    CLEAR_PURCHASE_ORDER  = "@@purchase_order/CLEAR_PURCHASE_ORDER",


  }