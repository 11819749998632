import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { individualAttendanceDetails } from 'src/models/pages/attendanceModel';
import "./attendance.scss"
import { Columns } from 'src/models/components/dataTableWrapperModel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { decimalToTime, formatDateTimeMEL, formatTime } from 'src/helpers/common_helpers';
import moment from "moment";

interface AttendanceModalProps {
  show: any;
  onCloseClick: any;
  viewDetails: individualAttendanceDetails[]
}

const AttendancePopup = ({ show, onCloseClick, viewDetails }: AttendanceModalProps) => {
  

  const formatHoursFormat = (data: any) => {
    const { hours, minutes, seconds } = decimalToTime(data.totalHours);
    return formatTime(hours, minutes, seconds);
  }

  const attendanceColumn: Columns[] = [
    {
      field: "creationTime",
      sortable: false,
      header: "Date",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "logInTime",
      sortable: false,
      header: "Check In Time",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "logOutTime",
      sortable: false,
      header: "Check Out Time",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "totalHours",
      sortable: false,
      header: "Total Hours",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
      body: (rowData) => formatHoursFormat(rowData.totalHours),
    },
  ]

  const formatDateTime = (data: any): string => {
    if (!data?.creationTime || data?.creationTime == null) {
      return "";
    }
    const date = moment.utc(data?.creationTime);

    const dayOfMonth = date.format('DD');
    const month = date.format('MMMM');
    const year = date.format('YYYY');
    const time = date.format('HH:mm');

    return `${dayOfMonth} ${month}, ${year}, ${time}`;
  };

  const formatcheckinTime = (data: any): string => {
    if (!data?.logInTime || data?.logInTime == null) {
      return "";
    }
    const localTime = moment.utc(data?.logInTime).local().format('hh:mm A');
    return localTime;
  }
  const formatcheckoutTime = (data: any): string => {
    if (!data?.logOutTime || data?.logOutTime == null) {
      return "";
    }
    const localTime = moment.utc(data?.logOutTime).local().format('hh:mm A');
    return localTime;
  }

  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={onCloseClick} centered={true} id='custom_modal_body'>
        <ModalBody className="custom_padding">
          <div className='AttendanceDetails' id="AttendanceDetails">
            <Row className='header_style'>
              <Col lg={12} className='Attendance_headerSection'>
                <div className='Attendance_Header_title'>Dispute for {viewDetails[0]?.name} on {formatDateTimeMEL(viewDetails[0]?.creationTime)}</div>
                <div className='close_button' onClick={onCloseClick}>X</div>
              </Col>
            </Row>
            <Row className='pt-4'>
              <Col lg={12}>
                <DataTable value={viewDetails} tableStyle={{ minWidth: '50rem' }} scrollable scrollHeight="400px">
                  <Column field='creationTime' header="Date" body={formatDateTime}></Column>
                  <Column field='logInTime' header="Check In Time" body={formatcheckinTime}></Column>
                  <Column field='logOutTime' header="Check Out Time" body={formatcheckoutTime}></Column>
                  <Column field='totalHours' header="Total Hours" body={formatHoursFormat}></Column>
                </DataTable>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AttendancePopup