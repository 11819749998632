import React from 'react'

interface HtmlPlainTextProps {
    htmlString: any,
    className?: string;
}

const HtmltoPlainText = ({ htmlString,className="" }: HtmlPlainTextProps) => {

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const plainText = tempDiv.textContent || tempDiv.innerText || '';
    return (
        <div className={className}>
            {/* {plainText} */}
            <div dangerouslySetInnerHTML={{ __html: htmlString }} className='pending-information-text'/>
        </div>
    )
}

export default HtmltoPlainText