export enum incomingDispatchTypes {

    // Grid
    GET_INCOMING_DISPATCH_LIST_LOADING = "@@incoming_dispatch/GET_INCOMING_DISPATCH_LIST_LOADING",
    GET_INCOMING_DISPATCH_LIST_SUCCESS = "@@incoming_dispatch/GET_INCOMING_DISPATCH_LIST_SUCCESS",
    GET_INCOMING_DISPATCH_LIST_FAIL = "@@incoming_dispatch/GET_INCOMING_DISPATCH_LIST_FAIL",
 
    GET_RECEIVED_BY_ID_LOADING= "@@RECEIVED/GET_RECEIVED_BY_ID_LOADING",
    GET_RECEIVED_BY_ID_SUCCESS = "@@RECEIVED/GET_RECEIVED_BY_ID_SUCCESS",
    GET_RECEIVED_BY_ID_FAIL = "@@RECEIVED/GET_RECEIVED_BY_ID_FAIL",
 
    GET_INCOMING_DISPATCH_BY_ID_LOADING= "@@incoming_dispatch/GET_INCOMING_DISPATCH_BY_ID_LOADING",
    GET_INCOMING_DISPATCH_BY_ID_SUCCESS = "@@incoming_dispatch/GET_INCOMING_DISPATCH_BY_ID_SUCCESS",
    GET_INCOMING_DISPATCH_BY_ID_FAIL = "@@incoming_dispatch/GET_INCOMING_DISPATCH_BY_ID_FAIL",

    CLEAR_INCOMING_DISPATCH = "@@incoming_dispatch/GET_INCOMING_DISPATCH_BY_ID_FAIL",
    RESET_INCOMING_DISPATCH_MESSAGE = "@@incoming_dispatch/RESET_INCOMING_DISPATCH_MESSAGE",


  }
  