import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { MelTableTypes } from "src/store/melTable/actionTypes";
import { CustomerService } from "src/components/Grid/CustomerService";
import { MelTableFetchError, MelTableFetchSuccess } from "src/store/melTable/actions";

function* MelTableAsyncLoading(action : any) {
  try {
    const response : Promise<any> = yield call(CustomerService.getCustomers,{
        lazyEvent : JSON.stringify(action.lazyState)
    })
    yield put(MelTableFetchSuccess(response));
  } catch (error) {
    yield put(MelTableFetchError(error));
  }
}

function* melTableSaga() {
  yield takeLatest(MelTableTypes.TABLE_LOADING, MelTableAsyncLoading);
}

export default melTableSaga;