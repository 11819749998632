import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { WhatsappTypes } from "./actionTypes";
import { getWhatsappListError, getWhatsappListSuccess, getWhatsappMessageByIdError, getWhatsappMessageByIdSuccess, getWhatsappMessageStatusError, getWhatsappMessageStatusSuccess, createWhatsappMessageError, createWhatsappMessageSuccess, deleteWhatsappMessageError, deleteWhatsappMessageSuccess, getCustomerNumError, getCustomerNumSuccess, getDealerNumError, getDealerNumSuccess, getEmployeeNumError, getEmployeeNumSuccess, getFranchiseNumError, getFranchiseNumSuccess, getSupplierNumError, getSupplierNumSuccess, getWhatsappDocumentLinkSuccess, getWhatsappDocumentLinkError, } from "./action";

function* getWhatsAppListAsync(action: any) {
  try {
    const response: Promise<any> = yield call( API.getWhatsAppListAPI, { ...action.lazyState })
    yield put(getWhatsappListSuccess(response));
  } catch (error) {
    yield put(getWhatsappListError(error));
  }
}


function* getEmployeeDropdownListAsync() {

  try {
    const response: Promise<any> = yield call(API.getEmployeeDrpFromIdentity)
    yield put(getEmployeeNumSuccess(response));
  } catch (error) {
    yield put(getEmployeeNumError(error));
  }
}


function* getDealerListAsync() {
  try {
    const response: Promise<any> = yield call(API.getDealerDropdownApi);
    yield put(getDealerNumSuccess(response));
  } catch (error) {
    yield put(getDealerNumError(error));
  }
}

function* getcustomerDropdownListAsync() {
  try {
    // const response: Promise<any> = yield call(API.getCustomerDropForJobAPI);
    const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getCustomerNumSuccess(response));
  } catch (error) {
    yield put(getCustomerNumError(error));
  }
}

function* getFranchiseDropdownAsync() {
  try {
    const response: Promise<any> = yield call(API.getFranchiseDrpFromIdentity);
    yield put(getFranchiseNumSuccess(response));

  } catch (error) {
    yield put(getFranchiseNumError(error));
  }
}

function* getSupplierDropdownAsync() {
  try {
    const response: Promise<any> = yield call(API.getSupplierDropdown);
    yield put(getSupplierNumSuccess(response));

  } catch (error) {
    yield put(getSupplierNumError(error));
  }
}

function* craeteWhatsappMessageAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.creatWhatsAppMessageApi, action.payload);
    yield put(createWhatsappMessageSuccess(response));
  } catch (error) {
    yield put(createWhatsappMessageError(error));
  }
}

function* deleteWhatsappMessageAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteWhatsAppApi, action.id);
    yield put(deleteWhatsappMessageSuccess(response));

  } catch (error) {
    yield put(deleteWhatsappMessageError(error));
  }
}

function* getWhatsappMessageByIdAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getWhatsAppByIdApi, action.id);
    yield put(getWhatsappMessageByIdSuccess(response));

  } catch (error) {
    yield put(getWhatsappMessageByIdError(error));
  }
}

function* getWhatsappMessageStatusAsync() {
  try {
    const response: Promise<any> = yield call(API.getBulkSmsStatusApi);
    yield put(getWhatsappMessageStatusSuccess(response));

  } catch (error) {
    yield put(getWhatsappMessageStatusError(error));
  }
}

function* getWhatsappDocumentLinkAsync(action:any) {
  try {
    const response: Promise<any> = yield call(API.getWhatsAppDocumentLinkApi,action.id);
    yield put(getWhatsappDocumentLinkSuccess({response:response,id:action.id}));

  } catch (error) {
    yield put(getWhatsappDocumentLinkError(error));
  }
}

function* whatsappSaga() {
  yield takeLatest(WhatsappTypes.GET_WHATSAPP_LIST_LOADING, getWhatsAppListAsync);
  yield takeLatest(WhatsappTypes.GET_EMPLOYEE_NUM_LOADING, getEmployeeDropdownListAsync);
  yield takeLatest(WhatsappTypes.GET_DEALER_NUM_LOADING, getDealerListAsync);
  yield takeLatest(WhatsappTypes.GET_CUSTOMER_NUM_LOADING, getcustomerDropdownListAsync);
  yield takeLatest(WhatsappTypes.GET_FRANCHISE_NUM_LOADING, getFranchiseDropdownAsync);
  yield takeLatest(WhatsappTypes.GET_SUPPLIER_NUM_LOADING, getSupplierDropdownAsync);
  yield takeLatest(WhatsappTypes.CREATE_WHATSAPP_MESSAGE_LOADING, craeteWhatsappMessageAsync);
  yield takeEvery(WhatsappTypes.DELETE_WHATSAPP_MESSAGE_LOADING, deleteWhatsappMessageAsync);
  yield takeLatest(WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_LOADING, getWhatsappMessageByIdAsync);
  yield takeLatest(WhatsappTypes.GET_WHATSAPP_MESSAGE_STATUS_LOADING, getWhatsappMessageStatusAsync);
  yield takeEvery(WhatsappTypes.GET_WHATSAPPDOCUMENT_LINK_LOADING, getWhatsappDocumentLinkAsync);
}

export default whatsappSaga;