import React from "react";
import { Line } from "react-chartjs-2";

import 'chart.js/auto';
import { Chart, CategoryScale } from 'chart.js';

Chart.register(CategoryScale);

const LineCharts = () => {
  const data:any = {
    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September","October"],
      datasets: [
        {
            label: "Sales Analytics",
            fill: true,
            lineTension: 0.5,
            backgroundColor: "rgba(81, 86, 190, 0.2)",
            borderColor: "#5156be",
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: "#5156be",
            pointBackgroundColor: "#fff", //3
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#5156be",
            pointHoverBorderColor: "#fff", //5
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40, 55, 30, 80]
        },
        {
            label: "Monthly Earnings",
            fill: true,
            lineTension: 0.5,
            backgroundColor: "rgba(235, 239, 242, 0.2)",
            borderColor: "#ebeff2",
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: "#ebeff2",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#ebeff2",
            pointHoverBorderColor: "#eef0f2",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [80, 23, 56, 65, 23, 35, 85, 25, 92, 36]
        }
    ]
  };


  return (
    <Line
      width={755}
      height={320}
      data={data}
      options={{ maintainAspectRatio: false }}
    />
  );
};

export default LineCharts;