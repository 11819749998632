import { jobsheetReducerGlobalState } from "src/models/pages/jobSheetReportsModel";
import { jobSheetReportsActions } from "./actionTypes";


const initialState: jobsheetReducerGlobalState = {
  loading: false,
  error: "",
  message: "",
  jobSheetReportList: {
    items: [],
    totalCount: 0
  }
}

const jobSheetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case jobSheetReportsActions.GET_JOBSHEET_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: ""
      }
 
    case jobSheetReportsActions.GET_JOBSHEET_REPORTS_SUCCESS:
      return {
        ...state,
        loading : false,
        jobSheetReportList : {
          ...state.jobSheetReportList,
          items : action.payload.data,
          totalCount : action.payload.data.totalCount,
        }
      }  

    case jobSheetReportsActions.GET_JOBSHEET_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return { ...state }
  }
}


export default jobSheetReducer;