export enum OutGoing_dispatch_Action {
    //grid 
    GET_OUT_GOING_DISPATCH_LIST_LOADING = "@@outgoingDispatch/GET_OUT_GOING_DISPATCH_LIST_LOADING",
    GET_OUT_GOING_DISPATCH_LIST_SUCCESS = "@@outgoingDispatch/GET_OUT_GOING_DISPATCH_LIST_SUCCESS",
    GET_OUT_GOING_DISPATCH_LIST_ERROR = "@@outgoingDispatch/GET_OUT_GOING_DISPATCH_LIST_ERROR",

    //view
    VIEW_OUT_GOING_DISPATCH_LOADING = "@@outgoingDispatch/VIEW_OUT_GOING_DISPATCH_LOADING",
    VIEW_OUT_GOING_DISPATCH_SUCCESS = "@@outgoingDispatch/VIEW_OUT_GOING_DISPATCH_SUCCESS",
    VIEW_OUT_GOING_DISPATCH_ERROR = "@@outgoingDispatch/VIEW_OUT_GOING_DISPATCH_ERROR",

    // sent mail to outgoing dispatch

    SENT_MAIL_TO_OUTGOING_DISPATCH = "@@purchase_order/SENT_MAIL_TO_OUTGOING_DISPATCH",
    SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS = "@@purchase_order/SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS",
    SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL = "@@purchase_order/SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL",

    // sent mail to outgoing dispatch

    DOWNLOAD_OUTGOING_DISPATCH = "@@purchase_order/DOWNLOAD_OUTGOING_DISPATCH",
    DOWNLOAD_OUTGOING_DISPATCH_SUCCESSS = "@@purchase_order/DOWNLOAD_OUTGOING_DISPATCH_SUCCESSS",
    DOWNLOAD_OUTGOING_DISPATCH_FAIL = "@@purchase_order/DOWNLOAD_OUTGOING_DISPATCH_FAIL",

     
    GET_DELIVERIED_BY_ID_LOADING= "@@DELIVERIED/GET_DELIVERIED_BY_ID_LOADING",
    GET_DELIVERIED_BY_ID_SUCCESS = "@@DELIVERIED/GET_DELIVERIED_BY_ID_SUCCESS",
    GET_DELIVERIED_BY_ID_FAIL = "@@DELIVERIED/GET_DELIVERIED_BY_ID_FAIL",

    CLEAR_OUTGOING_DISPATCH_STATE = "@@outgoingDispatch/CLEAR_OUTGOING_DISPATCH_STATE",
    RESET_OUTGOING_DISPATCH_MESSAGE = "@@outgoingDispatch/RESET_OUTGOING_DISPATCH_MESSAGE",

}