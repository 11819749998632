import { ticketSystemInitialState } from "src/models/pages/ticketSystemModel";
import { TicketSystemActions } from "./actiontypes";

const initialState: ticketSystemInitialState = {
  loading: false,
  error: "",
  message: "",
  totalCount: 0,
  enquiryList: [],
  induvidualEnquiry: null,
  enquiryDocument: [],
  enquiryDocumentLink: "",
  replyMessages: [],
  replyDocument: [],
  reassignEmployees : [],
  tickerCreaters : []
}

const ticketSystemReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_LOADING:
    case TicketSystemActions.CREATE_ENQUIRY_LOADING:
    case TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_LOADING:
    case TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_LOADING:
    case TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_LOADING:
    case TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_LOADING:
    case TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_LOADING:
    case TicketSystemActions.GET_REPLY_DOCUMENT_LOADING:
    case TicketSystemActions.CLOSE_TICKET_EMPLOYEE_LOADING:
    case TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_LOADING:
    case TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_LOADING:
    case TicketSystemActions.RESPONSE_ENQUIRY_LOADING:
    case TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_LOADING:
    case TicketSystemActions.GET_CUSTOMER_DATA_LOADING:
      return {
        ...state,
        loading: true,
        message: "",
        error: ""
      }

    case TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.data.totalCount,
        enquiryList: action.payload.data.items
      }

    case TicketSystemActions.CREATE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Successfully Created Ticket"
      }

    case TicketSystemActions.RESET_ENQUIRY_STATE:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        enquiryDocumentLink: ""
      }

    case TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        induvidualEnquiry: action.payload.data
      }

    case TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiryDocument: action.payload.data
      }
    case TicketSystemActions.GET_REPLY_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        replyDocument: action.payload.data
      }

    case TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiryDocumentLink: action.payload.data
      }

    case TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case TicketSystemActions.RESPONSE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        replyMessages: action.payload.data
      }

    case TicketSystemActions.CLOSE_TICKET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Ticket closed successfully"
      }

    case TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading : false,
        message : "The ticket has been reopened for further review"
      }  

    case TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_SUCCESS :
      return {
        ...state,
        loading : false,
        reassignEmployees : action.payload.data?.filter((item:any)=>item.isDeleted != true)
      }  

    case TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_SUCCESS :
      return {
        ...state,
        loading : false,
        message : `The ticket has been reassigned to ${action.payload.supporterName}`  
      }  

    case TicketSystemActions.RESET_INDUVIDUAL_QUERY_DATA :
      return {
        ...state,
        loading : false,
        induvidualEnquiry : null
      }

    case TicketSystemActions.GET_CUSTOMER_DATA_SUCCESS :
      return {
        ...state,
        loading : false,
        tickerCreaters : action.payload.data
      }  

    case TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_ERROR:
    case TicketSystemActions.CREATE_ENQUIRY_ERROR:
    case TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_ERROR:
    case TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_ERROR:
    case TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_ERROR:
    case TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_ERROR:
    case TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_ERROR:
    case TicketSystemActions.GET_REPLY_DOCUMENT_ERROR:
    case TicketSystemActions.CLOSE_TICKET_EMPLOYEE_ERROR:
    case TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_ERROR:
    case TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_ERROR:
    case TicketSystemActions.RESPONSE_ENQUIRY_ERROR:
    case TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_ERROR:
    case TicketSystemActions.GET_CUSTOMER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return { ...state }
  }
}

export default ticketSystemReducer