
import { SubUserType } from "./actionTpes";
import { subUserInitialState } from "src/models/pages/subUserModel";

const initialState: subUserInitialState = {
  loading: false,
  error: "",
  message : "",
  createMessage:"",
  createError : "",
  paginatorCount : 0,
  subUserList: {
    values: [],
    totalCount: 0,
    subUserDetails: undefined
  },
  franchiseSubuserImage:"",
  mailMessage:"",
  phoneMessage:"",
  editedMobileNumber:"",
  createdData:"",
  otpmessage:"",
  updatedmessage:"",
  subuserLatLng:""
}

const SubUser = (state = initialState, action: any) => {
  switch (action.type) {
    case SubUserType.GET_FRANCHISESUBUSER_LIST_LOADING:
    case SubUserType.CREATE_FRANCHISESUBUSER_LOADING:
    case SubUserType.GET_FRANCHISESUBUSERBYID_LOADING:
    case SubUserType.UPDATE_FRANCHISESUBUSER_LOADING:
    case SubUserType.DELETE_FRANCHISESUBUSER_LOADING:
    case SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_LOADING:
    case SubUserType.FRANCHISESUBUSER_SET_PHONE_LOADING:
    case SubUserType.FRANCHISESUBUSER_SET_EMAIL_LOADING:
    case SubUserType.SEND_OTP_FRANCHISESUBUSER:
    case SubUserType.RESEND_FRANCHISESUBUSER_OTP:
    case SubUserType.GET_FRANCHISESUBUSER_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        message : "",
        error : "",
        paginatorCount :0
      }
    case SubUserType.GET_FRANCHISESUBUSER_LIST_SUCCESS:
      return {
        loading: false,
        subUserList: {
          ...state.subUserList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount
        },
        paginatorCount :action.payload.data.items.length 
      }
    case SubUserType.CREATE_FRANCHISESUBUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"",
        createMessage : "Franchise Subuser Created Successfully",
        createdData:action.payload.data
      }
    case SubUserType.GET_FRANCHISESUBUSER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        subuserLatLng:action.payload.latLag.data
      }
    case SubUserType.GET_FRANCHISESUBUSER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"",
        franchiseSubuserImage:action.payload.data
      }
    case SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Profile Deleted Successfully",
      }
    case SubUserType.UPLOAD_FRANCHISESUBUSER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"",
      }

    case SubUserType.UPDATE_FRANCHISESUBUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        createMessage:"",
        updatedmessage : "Franchise Subuser Updated Successfully"
      }

    case SubUserType.GET_FRANCHISESUBUSERBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        subUserList: {
          ...state.subUserList,
          subUserDetails: action.payload.data,
        },
        phoneMessage:"",
        mailMessage:""
      };

    case SubUserType.DELETE_FRANCHISESUBUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        error:"",
        createMessage:"",
        message:action.undo ? "Franchise Subuser Restored Successfully" : "Franchise Subuser Deleted Successfully"
      }
    
    case SubUserType.RESET_FRANCHISESUBUSER : 
       return {
         ...state,
        ...initialState,
        subUserList: {
          values: [],
          totalCount: 0,
          subUserDetails: undefined
        },
        franchiseSubuserImage:"",
        subuserLatLng:""
       }  
    case SubUserType.CLEAR_FRANCHISESUBUSER_MESSAGE : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        updatedmessage:""
       }  
    case SubUserType.FRANCHISESUBUSER_SET_PHONE_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        phoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number
       }  
    case SubUserType.FRANCHISESUBUSER_SET_EMAIL_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        mailMessage:"Email Id Updated Successfully"
       }  
    case SubUserType.SEND_OTP_FRANCHISESUBUSER_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        createError:"",
        otpmessage:"OTP verified Successfully"
       }  
    case SubUserType.RESEND_FRANCHISESUBUSER_OTP_SUCCESS : 
       return {
         ...state,
        loading: false,
        error:"",
        message:"",
        createMessage:"",
        phoneMessage:"OTP sent Successfully",
        editedMobileNumber:action.payload.number
       }  


    case SubUserType.CREATE_FRANCHISESUBUSER_ERROR: 
      return {
        ...state,
        loading: false,
        createError: action.payload,
        error : "",
        updatedmessage:""
      }

    case SubUserType.GET_FRANCHISESUBUSER_LIST_ERROR:    
    case SubUserType.GET_FRANCHISESUBUSERID_ERROR:
    case SubUserType.UPDATE_FRANCHISESUBUSER_ERROR:
    case SubUserType.DELETE_FRANCHISESUBUSER_ERROR:
    case SubUserType.UPLOAD_FRANCHISESUBUSER_PROFILE_ERROR:
    case SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_ERROR:
    case SubUserType.FRANCHISESUBUSER_SET_EMAIL_ERROR:
    case SubUserType.FRANCHISESUBUSER_SET_PHONE_ERROR:
    case SubUserType.SEND_OTP_FRANCHISESUBUSER_ERROR:
    case SubUserType.RESEND_FRANCHISESUBUSER_OTP_ERROR:
    case SubUserType.GET_FRANCHISESUBUSER_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state }
  }
}

export default SubUser