import { StockQtybyPartTypes } from "./actionTypes";
import { stockQtybyPartInitialState } from "src/models/pages/stockQtybyPart";




const initialState: stockQtybyPartInitialState = {
  error: "",
  loading: false,
  message: "",
  stockQtybyPartList: {
    values: [],
    totalRecords: 0
  },
  partsDropdownList:""
};



const StockQtybyPart = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST:
    case StockQtybyPartTypes.GET_PART_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stockQtybyPartList: {
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        },
      };

    case StockQtybyPartTypes.GET_PART_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        partsDropdownList:action.payload.data
      };


      case StockQtybyPartTypes.RESET_STOCK_QTY_BY_PART_LIST:
        return {
          ...state,
          loading: false,
          message: "",
          error: ""
        };

    case StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST_FAIL:
    case StockQtybyPartTypes.GET_PART_LIST_FAIL:
        return {
            ...state,
            loading: false,
            message:"",
            error:""
        };
    
    default:
        return { ...state };
}
  
};

export default StockQtybyPart;
