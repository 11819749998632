import React, { useEffect, useState } from "react";

//import components
import SidebarContent from "./SidebarContent";

const Sidebar = (props: any) => {
  const [design , setDesign] = useState(false);
  const [screenResolution, setScreenResolution] = useState({width: window.innerWidth,});
  const [isClick] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenResolution({
        width: window.innerWidth,
      });
    };
    if(screenResolution.width == 1097){
      setDesign(true)
    }else{
      setDesign(false)
    }
    window.addEventListener('resize', handleResize);
    setScreenResolution({
      width: window.innerWidth,
    });

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);

  function tTogglebtn() {
    var body = document.body;
    // setClick(!isClick);
    if (isClick === false) {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  return (
    <React.Fragment>
    <div onClick={tTogglebtn} className="overlay"></div>
      <div className={`vertical-menu ${design?"vertical-menu-container-absolute":"vertical-menu-container-fixed"}`} >
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
