export enum AudioBrandsTypes {

  // Grid
  GET_AUDIO_BRANDS_LIST = "@@audioBrands/GET_AUDIO_BRANDS_LIST",
  GET_AUDIO_BRANDS_LIST_SUCCESS = "@@audioBrands/GET_AUDIO_BRANDS_LIST_SUCCESS",
  GET_AUDIO_BRANDS_LIST_FAIL = "@@audioBrands/GET_AUDIO_BRANDS_LIST_FAIL",

  //Delete 
  DELETE_AUDIO_BRANDS_PENDING = "@@audioBrands/DELETE_AUDIO_BRANDS_PENDING",
  DELETE_AUDIO_BRANDS_SUCCESS = "@@audioBrands/DELETE_AUDIO_BRANDS_SUCCESS",
  DELETE_AUDIO_BRANDS_FAIL = "@@audioBrands/DELETE_AUDIO_BRANDS_FAIL",

  //Create Audio Brand
  CREATE_AUDIO_BRANDS_PENDING = "@@audioBrands/CREATE_AUDIO_BRANDS_PENDING",
  CREATE_AUDIO_BRANDS_SUCCESS = "@@audioBrands/CREATE_AUDIO_BRANDS_SUCCESS",
  CREATE_AUDIO_BRANDS_ERROR = "@@audioBrands/CREATE_AUDIO_BRANDS_ERROR",

  //Update Audio Brand
  UPDATE_AUDIO_BRANDS_PENDING = "@@audioBrands/UPDATE_AUDIO_BRANDS_PENDING",
  UPDATE_AUDIO_BRANDS_SUCCESS = "@@audioBrands/UPDATE_AUDIO_BRANDS_SUCCESS",
  UPDATE_AUDIO_BRANDS_ERROR = "@@audioBrands/UPDATE_AUDIO_BRANDS_ERROR",

  //GetById Audio Brand
  GET_BY_ID_AUDIO_BRANDS_PENDING = "@@audioBrands/GET_BY_ID_AUDIO_BRANDS_PENDING",
  GET_BY_ID__AUDIO_BRANDS_SUCCESS = "@@audioBrands/GET_BY_ID__AUDIO_BRANDS_SUCCESS",
  GET_BY_ID__AUDIO_BRANDS_ERROR = "@@audioBrands/GET_BY_ID__AUDIO_BRANDS_ERROR",

  //Vehicle brand names
  GET_VEHICLE_BRAND_NAMES_LOADING = "@@audioBrands/GET_VEHICLE_BRAND_NAMES_LOADING",
  GET_VEHICLE_BRAND_NAMES_SUCCESS = "@@audioBrands/GET_VEHICLE_BRAND_NAMES_SUCCESS",
  GET_VEHICLE_BRAND_NAMES_ERROR = "@@audioBrands/GET_VEHICLE_BRAND_NAMES_ERROR",

  //file uppload
  AUDIO_BRAND_FILE_UPLOAD_LOADING="@@audioBrands/AUDIO_BRAND_FILE_UPLOAD_LOADING",
  AUDIO_BRAND_FILE_UPLOAD_SUCCESS="@@audioBrands/AUDIO_BRAND_FILE_UPLOAD_SUCCESS",
  AUDIO_BRAND_FILE_UPLOAD_ERROR="@@audioBrands/AUDIO_BRAND_FILE_UPLOAD_ERROR",

  //file fetch
  AUDIO_BRAND_FILE_FETCH_LOADING="@@audioBrands/AUDIO_BRAND_FILE_FETCH_LOADING",
  AUDIO_BRAND_FILE_FETCH_SUCCESS="@@audioBrands/AUDIO_BRAND_FILE_FETCH_SUCCESS",
  AUDIO_BRAND_FILE_FETCH_ERROR="@@audioBrands/AUDIO_BRAND_FILE_FETCH_ERROR",
  
  //file delete
  AUDIO_BRAND_FILE_DELETE_LOADING="@@audioBrands/AUDIO_BRAND_FILE_DELETE_LOADING",
  AUDIO_BRAND_FILE_DELETE_SUCCESS="@@audioBrands/AUDIO_BRAND_FILE_DELETE_SUCCESS",
  AUDIO_BRAND_FILE_DELETE_ERROR="@@audioBrands/AUDIO_BRAND_FILE_DELETE_ERROR",

  AUDIO_BRAND_FILE_CLEAR = "@@audioBrands/AUDIO_BRAND_FILE_CLEAR",

  //reset
  RESET_AUDIO_BRAND = "@@audioModels/RESET_AUDIO_BRAND"
}
