import React, { Fragment, useEffect, useRef, useState, forwardRef, ForwardedRef, memo } from "react";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import {
  DataTable,
  DataTableFilterMeta,
  DataTableProps,
  DataTableValueArray,
  SortOrder,
} from "primereact/datatable";
import ColumnWrapper from "../Common/ColumnWrapper/ColumnWrapper";
import { Columns, LazyTableState, setCustomFilterProbs } from "src/models/components/dataTableWrapperModel";
import { FilterMatchMode } from "primereact/api";
import TooltipMEL from "../UI/Tooltip";





interface DataTableValueArrayWrapper extends DataTableValueArray { }

type ExtendedDataTableProps<TValue extends DataTableValueArrayWrapper> =
  DataTableProps<TValue> & {
    // Add any additional properties specific to your extension
    // customProp: string;
    columnDetails: Columns[];

  };

const DataTableWrapper = forwardRef((props: ExtendedDataTableProps<DataTableValueArrayWrapper>, ref: ForwardedRef<DataTable<any>>) => {
  const { columnDetails, loading,reorderableColumns=true, ...rest } = props;


  const dt = useRef<DataTable<any> | null>(null);

  const bindColumns = (columnDetails: Columns[]) => {
    return columnDetails.map((eachColumn: Columns, index: number) => (
      <ColumnWrapper
        key={eachColumn.field}
        field={eachColumn.field}
        header={
          eachColumn.tooltip === true ? (
            <TooltipMEL title={eachColumn.tooltipTitle} position="bottom">
              {eachColumn.header}
            </TooltipMEL>
          ) : (
            eachColumn.header
          )
        }
        sortable={eachColumn.sortable ? true : false}
        filter={eachColumn.filter ? true : false}
        filterField={eachColumn.filter ? eachColumn.filterField : eachColumn.field}
        dataType={eachColumn.dataType ? eachColumn.dataType : ""}
        filterPlaceholder={
          eachColumn.filter ? eachColumn.filterPlaceholder : ""
        }
        bodyClassName={eachColumn.className}
        filterElement={eachColumn.filterElement ? eachColumn.filterElement : ""}
        body={eachColumn.body}
        showFilterMenu={eachColumn.showFilterMenu}
        selectionMode={eachColumn.selectionMode}
        headerStyle={eachColumn.headerStyle}
        frozen={eachColumn.frozen ? true : false}
        className={eachColumn.className}
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenuOptions={false}
        sortableColumn={eachColumn.sortableColumn}
      />
    ));
  };

  React.useImperativeHandle(ref, () => dt.current || ({} as DataTable<any>), [dt]);




  return (
    <Fragment>
      <div className="">
        <DataTable
          scrollable scrollHeight="500px"
          reorderableColumns={reorderableColumns}
          stripedRows
          tableStyle={{ minWidth: '50rem', fontSize: "14px" }}
          ref={dt}
          {...rest}
        >
          {bindColumns(props.columnDetails)}
        </DataTable>

      </div>

    </Fragment>
  );
});

DataTableWrapper.displayName = 'DataTableWrapper';
export default memo(DataTableWrapper);
