import { FranchiseAddtionalDetails } from "./actionTypes";
import { } from "./action";
import { FranchiseAdditionalDetailsModel } from "src/models/pages/franchiseAdditionalDetailsModel";

const initialState: FranchiseAdditionalDetailsModel = {
  loading: false,
  error: "",
  getCurrentUserId: null,
  moreDetails: []
}

const FranchiseMoreDetails = (state = initialState, action: any) => {
  switch (action.type) {
    case FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_LOADING:
    case FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_LOADING:
      return {
        ...state,
        loading : true,
        error : ""
      }

    case FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_SUCCESS :
      return {
        ...state,
        loading : false,
        error : "",
        getCurrentUserId : action.payload?.data?.extraProperties?.FranchiseId
      }  

    case FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_SUCCESS :
      return {
        ...state,
        loading : false,
        error : false ,
        moreDetails : action.payload.data
      }  

    case FranchiseAddtionalDetails.GET_ADDITIONAL_DETAILS_RESET :
      return {
        ...state,
        loading : false,
        message : "",
        moreDetails : [],
        getCurrentUserId : null
      }

    case FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_ERROR :
    case FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_ERROR :
      return {
        ...state,
        loading : false,
        error : action.payload
      }  
    default:
      return {...state}  
  }
}

export default FranchiseMoreDetails