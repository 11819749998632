import { call, put, takeLatest } from "redux-saga/effects";
import { permissionTypes } from "./actionTypes";
import { API } from "src/util/api";
import { createRoleError, createRoleSuccess, getPermissionsError, getPermissionsSuccess, getRolesError, getRolesSuccess, updatePermissionsError, updatePermissionsSuccess } from "./action";

function* getUserRoleAsync() {
    try {
      const response: Promise<any> = yield call(API.getUserRoleAPI)
      yield put(getRolesSuccess(response));
    } catch (error) {
      yield put(getRolesError(error));
    }
  }

function* getPermissionDetailsAsync(action:any) {
    try {
        
        
      const response: Promise<any> = yield call(API.getPermissionDetailsAPI,action.role)
      yield put(getPermissionsSuccess(response));
    } catch (error) {
      yield put(getPermissionsError(error));
    }
  }

function* updatePermissionDetailsAsync(action:any) {
    try {
        
        
      const response: Promise<any> = yield call(API.updatePermissionDetailsAPI,action.role,action.permission)
      yield put(updatePermissionsSuccess({response,role:action.role}));
    } catch (error) {
      yield put(updatePermissionsError(error));
    }
  }

function* createRoleAsync(action:any) {
    try {
        
      const response: Promise<any> = yield call(API.createRoleAPI,action.payload)
      yield put(createRoleSuccess(response));
    } catch (error) {
      yield put(createRoleError(error));
    }
  }
  
  
  //watcher function
  
  function* permissionSaga() {
    yield takeLatest(permissionTypes.GET_ROLES_LOADING, getUserRoleAsync);
    yield takeLatest(permissionTypes.GET_PERMISSIONS_LOADING, getPermissionDetailsAsync);
    yield takeLatest(permissionTypes.UPDATE_PERMISSIONS_LOADING, updatePermissionDetailsAsync);
    yield takeLatest(permissionTypes.CREATE_ROLE_LOADING, createRoleAsync);
  }
  export default permissionSaga