import { vehicleBrandsDetails } from "src/models/pages/vehicleBrandsModel";
import { VehicleBrandsTypes } from "./actionTypes";



// For Grid

export const getVehicleBrandsList = (lazyState: any) => {
  return {
    type: VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST,
    lazyState,
  };
};



export const getVehicleBrandsListSuccess = (vehicleBrands: any) => {
  return {
    type: VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST_SUCCESS,
    payload: vehicleBrands,
  };
};

export const getVehicleBrandsListError = (error: any) => {
  return {
    type: VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST_FAIL,
    payload: error,
  };
};

export const clearVehicleBrandsMessage = ()=>{
  return {
    type: VehicleBrandsTypes.RESET_VEHICLE_BRANDS,
  }
}

export const resetVehicleBrand = ()=>{
  return {
    type: VehicleBrandsTypes.CLEAR_VEHICLE_BRANDS,
  }
}

export const deleteVehicleBrandsFromList = (payload:any) => {
  return {
    type: VehicleBrandsTypes.DELETE_VEHICLE_BRANDS,
    payload: payload,
  }
}

export const deleteVehicleBrandsFromListSuccess = (response:any,undo : boolean) => {
  return {
    type: VehicleBrandsTypes.DELETE_VEHICLE_BRANDS_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteVehicleBrandsFromListError = (error:any) => {
  return {
    type: VehicleBrandsTypes.DELETE_VEHICLE_BRANDS_FAIL,
    payload: error,
  }
}



//  update 

// update Vehicle

export const updateVehicleBrands = (vehicle: vehicleBrandsDetails, vehicleId: string = "") => {
  return { type: VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS
    
    , payload: { vehicle, vehicleId } };
};

export const updateVehicleBrandsSuccess = (vehicle: vehicleBrandsDetails) => {
  return { type: VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS_SUCCESS, payload: vehicle };
};

export const updateVehicleBrandsError = (error: any) => {
  return { type: VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS_FAIL, payload: error };
};


// post Vehicle
export const createVehicleBrands = (vehicle: vehicleBrandsDetails) => {
  return { type: VehicleBrandsTypes.POST_VEHICLE_BRANDS, payload: vehicle };
};

export const createVehicleBrandsSuccess = (vehicle: vehicleBrandsDetails) => {
  return { type: VehicleBrandsTypes.POST_VEHICLE_BRANDS_SUCCESS, payload: vehicle };
};

export const createVehicleBrandsError = (error: any) => {
  return { type: VehicleBrandsTypes.POST_VEHICLE_BRANDS_FAIL, payload: error };
};


// get vehicle
export const getVehicleBrandsBYID = (id: string) => {
  return { type: VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID, payload: id };
};

export const getVehicleBrandsBYIDSuccess = (vehicle: vehicleBrandsDetails) => {
  return { type: VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID_SUCCESS, payload: vehicle };
};

export const getVehicleBrandsBYIDError = (error: any) => {
  return { type: VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID_FAIL, payload: error };
};

// get vehicle
export const getDealerNamesBYID = (id: string) => {
  return { type: VehicleBrandsTypes.GET_DEALER_NAMES_BY_ID, payload: id };
};

export const getDealerNamesBYIDSuccess = (vehicle: vehicleBrandsDetails) => {
  return { type: VehicleBrandsTypes.GET_DEALER_NAMES_BY_ID_SUCCESS, payload: vehicle };
};

export const getDealerNamesBYIDError = (error: any) => {
  return { type: VehicleBrandsTypes.GET_DEALER_NAMES_BY_ID_FAIL, payload: error };
};

export const uploadVehicleBrandFileLoading = (file :any,id : any )=>{
  return {
    type : VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_LOADING,
    payload : file,
    id: id
  }
}

export const uploadVehicleBrandFileSuccess=(vehiclebrand : any)=>{
  return {
    type : VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_SUCCESS,
  }
}

export const uploadVehicleBrandFileError=(error : any)=>{
  return {
    type : VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_ERROR,
    payload : error
  }
}
export const getVehicleBrandImageLoading =(vehicleBrandId : any)=>{
  return {
    type: VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_LOADING,
    payload: vehicleBrandId,
  };
}

export const getVehicleBrandImageSuccess =(vehicleBrandDetails : any)=>{
  return {
    type: VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_SUCCESS,
    payload: vehicleBrandDetails,
  }
}

export const getVehicleBrandImageError =(error : any)=>{
  return {
    type : VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_ERROR,
    payload : error
  }
}


// delete Vehicle brand image

export const deleteVehicleBrandImageLoading = (vehicleBrandId : any)=>{
  return {
    type: VehicleBrandsTypes.VEHICLE_BRAND_FILE_DELETE_LOADING,
    payload: vehicleBrandId,
  };
}

export const deleteVehicleBrandImageSuccess = (vehicleBrandDetails : any)=>{
  return {
    type: VehicleBrandsTypes.VEHICLE_BRAND_FILE_DELETE_SUCCESS,
    payload: vehicleBrandDetails,
  }
}

export const deleteVehicleBrandImageError = (error : any)=>{
  return {
    type : VehicleBrandsTypes.VEHICLE_BRAND_FILE_DELETE_ERROR,
    payload : error
  }
}

export const ClearVehicleBrandImage=()=>{
	return {
	  type : VehicleBrandsTypes.VEHICLE_BRAND_FILE_CLEAR,
	}
  }