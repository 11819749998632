
import { attendanceReportInitialState } from "src/models/pages/attendanceReportModel";
import { AttendanceReportTypes } from "./actionTypes";


const initialState: attendanceReportInitialState = {
    error: "",
    loading: false,
    message: "",
    totalCount: 0,
    attendanceReportList: [],
    employeeDropdownList:[],
    franchiseDropdownList:[],
    franchiseSubuserDropdownList:[],
    dealerDropdownList:[],
    dealerSubuserDropdownList:[]
};



const AttendanceReport = (state = initialState, action: any) => {
    switch (action.type) {


        case AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST:
            return {
                ...state,
                loading: true,
                totalCount: 0
            };

        case AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST_SUCCESS:         
            return {
                ...state,
                loading: false,
                attendanceReportList: action.payload.data.items.filter((item:any)=>item.userType != "Customer"),
                totalCount: action.payload.data?.totalCount
            };
        case AttendanceReportTypes.GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS:
            
            return {
                ...state,
                loading: false,
                employeeDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
            };
        case AttendanceReportTypes.GET_FRANCHISE_DROPDOWN_LIST_SUCCESS:
            
            return {
                ...state,
                loading: false,
                franchiseDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
            };
        case AttendanceReportTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS:
   
            return {
                ...state,
                loading: false,
                franchiseSubuserDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
            };
        case AttendanceReportTypes.GET_DEALER_DROPDOWN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerDropdownList:action.payload.data.filter((item:any)=>item.isDeleted!=true)
            };
        case AttendanceReportTypes.GET_DEALER_SUBUSER_DROPDOWN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerSubuserDropdownList:action.payload.data.filter((item:any)=>item.isDeleted != true)
            };

        case AttendanceReportTypes.CLEAR_ATTENDANCE_REPORT_MESSAGE:
            return {
                ...state,
                loading: false,
                error:"",
                message:"",
            };
        case AttendanceReportTypes.RESET_ATTENDANCE_REPORT:
            return {
                ...state,
                loading: false,
                error:"",
                message:"",
                totalCount:0,
                attendanceReportList: [],
                employeeDropdownList:[],
                franchiseDropdownList:[],
                franchiseSubuserDropdownList:[],
                dealerDropdownList:[],
                dealerSubuserDropdownList:[]
            };
        
        case AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST_FAIL:
        case AttendanceReportTypes.GET_EMPLOYEE_DROPDOWN_LIST_FAIL:
        case AttendanceReportTypes.GET_FRANCHISE_DROPDOWN_LIST_FAIL:
        case AttendanceReportTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL:
        case AttendanceReportTypes.GET_DEALER_DROPDOWN_LIST_FAIL:
        case AttendanceReportTypes.GET_DEALER_SUBUSER_DROPDOWN_LIST_FAIL:
            return {
                ...state,
                message: "",
                loading: false,
                error: action.payload
            };

        default:
            return { ...state };
    }
};

export default AttendanceReport;