import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { API } from 'src/util/api';
import { EmployeeProfileTypes } from './actionTypes';
import { employeeDocumentProfileGetError, employeeDocumentProfileGetSucces, generateEmployeeDocumentLinkError, generateEmployeeDocumentLinkSuccess, getEmployeeIdError, getEmployeeIdSuccess, getEmployeeImageProfileError, getEmployeeImageProfileSuccess, getEmployeeProfileError, getEmployeeProfileSuccess } from './action';
import { UserData } from 'src/models/pages/userModel';


function* getEmployeeProfileAsync(payload: any) {
  try {
    const response: UserData = yield call(API.getIndividualUser, payload.lazyState);
    yield put(getEmployeeProfileSuccess(response))
  }
  catch (error) {
    yield put(getEmployeeProfileError(error))
  }
}

function* fetchProfilesForId(action : any) {
    try {
      const response : Promise<any> = yield call(
          API.getProfile
      )
      yield put(getEmployeeIdSuccess(response));
    } catch (error) {
      yield put(getEmployeeIdError(error));
    }
  }

  
function* getEmployeeImageAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.fetchEmployeeImage, payload)
    yield put(getEmployeeImageProfileSuccess(response));
  } catch (error) {
    yield put(getEmployeeImageProfileError(error));
  }
}

function* getDocumentEmployeeAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.fetchEmployeedocumentApi, payload)
    yield put(employeeDocumentProfileGetSucces(response));
  } catch (error) {
    yield put(employeeDocumentProfileGetError(error));
  }
}

function* generateDocumentLinkEmployeeAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.generateEmployeeDoumentLinkApi, { id: action.payload });
    yield put(generateEmployeeDocumentLinkSuccess(response))
  } catch (error) {
    yield put(generateEmployeeDocumentLinkError(error))
  }
}


function* employeeProfileSaga() {
  yield takeLatest(EmployeeProfileTypes.GET_EMPLOYEE_PROFILE_LOADING, getEmployeeProfileAsync);
  yield takeLatest(EmployeeProfileTypes.GET_EMPLOYEE_ID_LOADING, fetchProfilesForId);
  yield takeLatest(EmployeeProfileTypes.EMPLOYEE_FILE_FETCH_LOADING, getEmployeeImageAsync);
  yield takeLatest(EmployeeProfileTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING, getDocumentEmployeeAsync);
  yield takeLatest(EmployeeProfileTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING, generateDocumentLinkEmployeeAsync);
}

export default employeeProfileSaga