import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Col, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { stocklocation } from "src/models/pages/stockLocationModel";
import { RootReducerState } from 'src/store/reducers';
import { getStockLocationBYID } from 'src/store/actions';

const ViewStockLoaction = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading } = useSelector((state: any) => state.stockLocationReducer);
  const formValue: stocklocation = useSelector(
    (state: RootReducerState) => state.stockLocationReducer?.stocklocation as stocklocation
  );

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const editStockLocationClickHandler = () => {
    navigate(`${ROUTER.STOCK_LOCATION_EDIT}/${id}`);
  };

  useEffect(() => {
    if (id) {
      dispatch(getStockLocationBYID(id));
    }
  }, [id,dispatch])

  const stockLocationViewDetails = {
    generalDetails: [   
      {
        name: "Location",
        value: formValue?.locationName
      }
    ]
  }

  return (
    <div className="pagescontent " id="stock-location">
    <BreadcrumbWithFilter
       title="Stock Location"
       titlePath={ROUTER.STOCK_LOCATION_GRID}
      breadcrumbItem="Stock Location Details"
      isShowCreate={false}
      isShowFilter={false}
      filter={false}
      isShowFirstButton={true}
      firstButtonLabel='Edit Stock Location'
      onFirstButtonClick={editStockLocationClickHandler}
      isShowSecondButton={false}
    />

    <Row>
    <div className="view">
            <div className="general-details">
              <b>General Details</b>
            </div>
            <div className="subview">
          <Row>
            {
              stockLocationViewDetails.generalDetails.map((data, index) => {
                return (
                  <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                    <div className='mb-2'>{data.name}</div>
                    <b>{data.value?data.value:<div className="none-text">-</div>}</b>
                  </Col>
                )
              })
            }
          </Row>
        </div>
        </div>
    </Row>

    
  </div>
  )
}

export default ViewStockLoaction