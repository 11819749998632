import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  FormFeedback,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import Select from "src/components/Common/Select";
import Button from "src/components/Common/button";
import { useFormik } from "formik";
import * as Yup from "yup";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
const Form: React.FC = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    selectOption: Yup.string().required(),
    date: Yup.string().required(),
    checkboxChecked: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
    file: Yup.string().required(),
    userStatus: Yup.string().required(),
  });

  const selectedColorOptions: {key: "Tamil" | "English" | "Hindhi"; value: string}[] = [
    { key: "Tamil", value: "Tamil" },
    { key: "English", value: "English" },
    { key: "Hindhi", value: "Hindhi" },
  ];
  // ["Admin", "Technician", "user"]
  const option = [
    {label : "Admin",value :"admin"},
    {label : "Technician",value :"technician"},
    {label : "user",value :"user"}
  ]

  const formik: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      selectOption: "",
      date: "",
      checkboxChecked: false,
      file: null,
      userStatus: "",
      languages: []
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(500);
    },
  });



  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("file", event.currentTarget.files?.[0]);
  };

  const handleRadioChange = (value: string) => {
    formik.handleChange({
      target: {
        name: "userStatus",
        value,
      },
    });
  };

  // Memoized InputCom name component
  const memoizedInputCom = useMemo(
    () => (
      <InputCom
        id="nameField"
        labelClassName="form-Label"
        inputclassName="form-control"
        labelName="Name"
        type="text"
        name="name"
        value={formik.values.name || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        invalid={formik.touched.name && formik.errors.name ? true : false}
      />
    ),
    [formik.values.name, formik.touched.name, formik.errors.name]
  );

  // Memoized InputCom component for email
  const memoizedEmailInputCom = useMemo(
    () => (
      <InputCom
        id="emailField"
        labelClassName="form-Label"
        inputclassName="form-control"
        labelName="Email"
        type="email"
        name="email"
        value={formik.values.email || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        invalid={formik.touched.email && formik.errors.email ? true : false}
      />
    ),
    [formik.values.email, formik.touched.email, formik.errors.email]
  );

  // Memoized Select component
  const memoizedSelect = useMemo(
    () => (
      <Select
        name="selectOption"
        label="userTypes"
        options={option}
        value={formik.values.selectOption || ""}
        onChange={formik.handleChange} // Assuming you have memoizedHandleChange function
        onBlur={formik.handleBlur} // Assuming you have memoizedHandleBlur function
        invalid={
          formik.touched.selectOption && formik.errors.selectOption
            ? true
            : false
        }
      />
    ),
    [
      formik.values.selectOption,
      formik.touched.selectOption,
      formik.errors.selectOption,
    ]
  );

  // Memoized InputCom component for date
  const memoizedDateInputCom = useMemo(
    () => (
      <InputCom
        id="DateField"
        labelClassName="form-Label"
        inputclassName="form-control"
        labelName="Date and time"
        type="date"
        name="date"
        value={formik.values.date || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        invalid={formik.touched.date && formik.errors.date ? true : false}
      />
    ),
    [formik.values.date, formik.touched.date, formik.errors.date]
  );

  // Memoized InputCom component for upload document
  const memoizedUploadDocumentInputCom = useMemo(
    () => (
      <InputCom
        id="uploadDocumentField"
        labelClassName="form-Label"
        inputclassName="form-control"
        labelName="upload Document"
        type="file"
        name="file"
        onChange={handleFileChange}
        onBlur={formik.handleBlur}
        invalid={formik.touched.file && formik.errors.file ? true : false}
      />
    ),
    [formik.values.file, formik.touched.file, formik.errors.file]
  );

  // Memoized radio buttons
  const memoizedRadioButtons = useMemo(
    () => (
      <>
        <div>
          <Label htmlFor="example-date-input" className="form-Label">
            {" "}
            Active status
          </Label>
        </div>
        <div className="custom-radio">
          {/* Assuming you have memoized handleRadioChange function */}
          <InputCom
            id="activeStatustrueField"
            name="userStatus"
            checked={formik.values.userStatus === "true"}
            onChange={() => handleRadioChange("true")}
            onBlur={formik.handleBlur}
            type="radio"
            value="true"
          />{" "}
          <Label htmlFor="activeStatustrueField" check>
            yes
          </Label>
          <InputCom
            id="activeStatusField"
            name="userStatus"
            checked={formik.values.userStatus === "false"}
            onChange={() => handleRadioChange("false")}
            onBlur={formik.handleBlur}
            type="radio"
            value="false"
          />{" "}
          <Label htmlFor="activeStatusField" check>
            No
          </Label>
        </div>
      </>
    ),
    [
      formik.values.userStatus,
      formik.touched.userStatus,
      formik.errors.userStatus,
    ]
  );

  //memoized multiple checkbox
  const memoizedmultiCheckbox = useMemo(
    () =>
      selectedColorOptions.map((colorOption) => (
        <div className="form-group" key={colorOption.key}>
          <div className="form-check">
            <InputCom
              id={`formrow-customCheck${colorOption.key}Field`}
              labelClassName="form-check-label"
              inputclassName="form-check-input"
              labelName={colorOption.value}
              value={colorOption.key}
              type="checkbox"
              name="languages"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.languages && formik.errors.languages ? (
            <div className="form-checkbox-checked">
              {formik.errors.languages}
            </div>
          ) : null}
        </div>
      )),
    [
      formik.values.languages,
      formik.touched.languages,
      formik.errors.languages,
    ]
  );

  // Memoized checkbox
  const memoizedCheckbox = useMemo(
    () => (
      <div className="form-group">
        <div className="form-check">
          <InputCom
            id="formrow-customCheckaField"
            labelClassName="form-check-label"
            inputclassName="form-check-input"
            labelName="Agree the terms and condition"
            type="checkbox"
            name="checkboxChecked"
            checked={formik.values.checkboxChecked}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        {formik.touched.checkboxChecked && formik.errors.checkboxChecked ? (
          <div
          className="form-checkbox-checked"
          >
            {formik.errors.checkboxChecked}
          </div>
        ) : null}
      </div>
    ),
    [
      formik.values.checkboxChecked,
      formik.touched.checkboxChecked,
      formik.errors.checkboxChecked,
    ]
  );

  // Memoized form
  const memoizedForm = useMemo(
    () => (
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          {memoizedInputCom}
          {formik.touched.name && formik.errors.name ? (
            <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          {memoizedEmailInputCom}
          {formik.touched.email && formik.errors.email ? (
            <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">{memoizedSelect}</div>
        <div className="mb-3">
          {memoizedDateInputCom}
          {formik.touched.date && formik.errors.date ? (
            <FormFeedback type="invalid">{formik.errors.date}</FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          {memoizedUploadDocumentInputCom}
          {formik.touched.file && formik.errors.file ? (
            <FormFeedback type="invalid">{formik.errors.file}</FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          {memoizedRadioButtons}
          {formik.touched.userStatus && formik.errors.userStatus ? (
            <div
             className="form-checkbox-checked"
            >
              {formik.errors.userStatus}
            </div>
          ) : null}
        </div>
        <div className="mb-3 d-flex gap-3">{memoizedmultiCheckbox}</div>
        <div className="form-group">{memoizedCheckbox}</div>
        <div className="mt-3">
          <Button
            className="btn btn-primary btn-sm waves-effect waves-light"
            type="submit"
            buttonName="Submit"
          />
        </div>
      </form>
    ),
    [
      memoizedInputCom,
      memoizedEmailInputCom,
      memoizedSelect,
      memoizedDateInputCom,
      memoizedUploadDocumentInputCom,
      memoizedRadioButtons,
      memoizedCheckbox,
      memoizedmultiCheckbox,
      formik.handleSubmit,
      formik.touched.name,
      formik.errors.name,
      formik.touched.email,
      formik.errors.email,
      formik.touched.selectOption,
      formik.errors.selectOption,
      formik.touched.date,
      formik.errors.date,
      formik.touched.file,
      formik.errors.file,
      formik.values.userStatus,
      formik.touched.userStatus,
      formik.errors.userStatus,
      formik.touched.checkboxChecked,
      formik.errors.checkboxChecked,
      formik.values.languages,
      formik.touched.languages,
      formik.errors.languages,
    ]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Forms" breadcrumbItem="Form" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Textual inputs</h4>
                  <p className="card-title-desc">
                    Here are examples of <code>.form-control</code> applied to
                    each textual HTML5 <code>&lt;input&gt;</code>{" "}
                    <code>type</code>.
                  </p>
                </CardHeader>
                <CardBody className="p-4">
                  <React.Fragment>
                    <Row className="justify-content-center">
                      <Col xs={6}>{memoizedForm}</Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Form;
