import React from 'react'
import { ImagePreviewerModel } from 'src/models/components/imagePreviewerModel'
import FeatherIcon from "feather-icons-react";

const ProfileImageViewer = ({selectedFile,removeIndex}:ImagePreviewerModel) => {
  return (
    <div className='image-viewer'>
        <div className="">
        <img src={selectedFile} alt="image" />
        <div className="icon-container">
        <FeatherIcon onClick={removeIndex} icon="trash-2" />
        </div>
        </div>
    </div>
  )
}

export default ProfileImageViewer