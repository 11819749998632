export enum Dashboard {
    GET_MARKET_OVERVIEW = "GET_MARKET_OVERVIEW",
    GET_MARKET_OVERVIEW_SUCCESS = "GET_MARKET_OVERVIEW_SUCCESS",
    GET_MARKET_OVERVIEW_FAIL = "GET_MARKET_OVERVIEW_FAIL",

    GET_WALLENT_BALANCE = "GET_WALLENT_BALANCE",
    GET_WALLENT_BALANCE_SUCCESS = "GET_WALLENT_BALANCE_SUCCESS",
    GET_WALLENT_BALANCE_FAIL = "GET_WALLENT_BALANCE_FAIL",

    GET_Invested_Overview = "GET_Invested_Overview",
    GET_Invested_Overview_SUCCESS = "GET_Invested_Overview_SUCCESS",
    GET_Invested_Overview_FAIL = "GET_Invested_Overview__FAIL",

    GET_DEALER_COUNT = "GET_DEALER_COUNT",
    GET_DEALER_COUNT_SUCCESS = "GET_DEALER_COUNT_SUCCESS",
    GET_DEALER_COUNT_FAIL = "GET_DEALER_COUNT_FAIL",

    GET_SUPPLIER_COUNT = "GET_SUPPLIER_COUNT",
    GET_SUPPLIER_COUNT_SUCCESS = "GET_SUPPLIER_COUNT_SUCCESS",
    GET_SUPPLIER_COUNT_FAIL = "GET_SUPPLIER_COUNT_FAIL",

    GET_FRANCHISEE_COUNT = "GET_FRANCHISEE_COUNT",
    GET_FRANCHISEE_COUNT_SUCCESS = "GET_FRANCHISEE_COUNT_SUCCESS",
    GET_FRANCHISEE_COUNT_FAIL = "GET_FRANCHISEE_COUNT_FAIL",

    GET_CUSTOMER_COUNT = "GET_CUSTOMER_COUNT",
    GET_CUSTOMER_COUNT_SUCCESS = "GET_CUSTOMER_COUNT_SUCCESS",
    GET_CUSTOMER_COUNT_FAIL = "GET_CUSTOMER_COUNT_FAIL",

    GET_JOBSHEET_STATUS_COUNT = "GET_JOBSHEET_STATUS_COUNT",
    GET_JOBSHEET_STATUS_COUNT_SUCCESS = "GET_JOBSHEET_STATUS_COUNT_SUCCESS",
    GET_JOBSHEET_STATUS_COUNT_FAIL = "GET_JOBSHEET_STATUS_COUNT_FAIL",

    GET_JOBSHEET_TREND_COUNT = "GET_JOBSHEET_TREND_COUNT",
    GET_JOBSHEET_TREND_COUNT_SUCCESS = "GET_JOBSHEET_TREND_COUNT_SUCCESS",
    GET_JOBSHEET_TREND_COUNT_FAIL = "GET_JOBSHEET_TREND_COUNT_FAIL",

    GET_DEALER_LIST = "GET_DEALER_LIST",
    GET_DEALER_LIST_SUCCESS = "GET_DEALER_LIST_SUCCESS",
    GET_DEALER_LIST_FAIL = "GET_DEALER_LIST_FAIL",

    GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_LOADING = "@@dashboard/GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_LOADING",
    GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_SUCCESS = "@@dashboard/GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_SUCCESS",
    GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_ERROR = "@@dashboard/GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_ERROR",

    GET_PURCHASE_ORDER_DASHBOARD_DETAILS_LOADING = "@@dashboard/GET_PURCHASE_ORDER_DASHBOARD_DETAILS_LOADING",
    GET_PURCHASE_ORDER_DASHBOARD_DETAILS_SUCCESS = "@@dashboard/GET_PURCHASE_ORDER_DASHBOARD_DETAILS_SUCCESS",
    GET_PURCHASE_ORDER_DASHBOARD_DETAILS_ERROR = "@@dashboard/GET_PURCHASE_ORDER_DASHBOARD_DETAILS_ERROR",

    GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_LOADING = "@@dashboard/GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_LOADING",
    GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_SUCCESS = "@@dashboard/GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_SUCCESS",
    GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_ERROR = "@@dashboard/GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_ERROR",


    //get invoice count
    GET_INVOICE_COUNT_DASHBOARD_LOADING = "@@dashboard/GET_INVOICE_COUNT_DASHBOARD_LOADING",
    GET_INVOICE_COUNT_DASHBOARD_SUCCESS = "@@dashboard/GET_INVOICE_COUNT_DASHBOARD_SUCCESS",
    GET_INVOICE_COUNT_DASHBOARD_ERROR = "@@dashboard/GET_INVOICE_COUNT_DASHBOARD_ERROR",

    //get received paymeny count 
    GET_RECEIVED_PAYMENT_COUNT_LOADING = "@@dashboard/GET_RECEIVED_PAYMENT_COUNT_LOADING",
    GET_RECEIVED_PAYMENT_COUNT_SUCCESS = "@@dashboard/GET_RECEIVED_PAYMENT_COUNT_SUCCESS",
    GET_RECEIVED_PAYMENT_COUNT_ERROR = "@@dashboard/GET_RECEIVED_PAYMENT_COUNT_ERROR",

    //get pending paymnet count

    GET_PENDINGPAYMENT_COUNT_LOADING = "@@dashboard/GET_PENDINGPAYMENT_COUNT_LOADING",
    GET_PENDINGPAYMENT_COUNT_SUCCESS = "@@dashboard/GET_PENDINGPAYMENT_COUNT_SUCCESS",
    GET_PENDINGPAYMENT_COUNT_ERROR = "@@dashboard/GET_PENDINGPAYMENT_COUNT_ERROR",
}


