import React, { useState } from "react";
import { Navigate, Router } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

import Profile from "../pages/Profile/Profile";

//Calendar
import Calendar from "src/pages/Calendar";

//Chat
import Chat from "src/pages/Chat/Chat";

//Email Inbox
import Inbox from "src/pages/Email/Inbox";
import EmailRead from "src/pages/Email/email-read";

//Invoice
// import InvoiceList from "src/pages/Invoices/invoice-list";
import InvoiceDetails from "src/pages/Invoices/InvoiceDetails";

//Contacts
import ContactsGrid from "src/pages/Contacts/contactsGrid";
import ContactsList from "src/pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "src/pages/Contacts/ContactsProfile/contacts-profile";

//Utility
import PageStarter from "src/pages/Utility/PageStarter";
import PageMaintenance from "src/pages/Utility/PageMaintenance";
import PageTimeline from "src/pages/Utility/PageTimeline";
import PageFaqs from "src/pages/Utility/PageFAQs";
import PagePricing from "src/pages/Utility/PagePricing";
import Error404 from "src/pages/Utility/Error404";
import Error500 from "src/pages/Utility/Error500";

//UI Components
import UiAlert from "src/pages/UiComponents/UiAlert";
import UiButton from "src/pages/UiComponents/UiButton";
import UiCard from "src/pages/UiComponents/UiCard";
import UiCarousel from "src/pages/UiComponents/UiCarousel";
import UiDropdowns from "src/pages/UiComponents/UiDropdowns";
import UiGrid from "src/pages/UiComponents/UiGird";
import UiModal from "src/pages/UiComponents/UiModals";
import UiImages from "src/pages/UiComponents/UiImages";
import UiOffCanvas from "src/pages/UiComponents/UiOffCanvas";
import UiProgressbar from "src/pages/UiComponents/UiProgressbar";
import UiPlaceholders from "src/pages/UiComponents/UiPlaceholders";
import UiTabsAccordions from "src/pages/UiComponents/UiTabsAccordions";
import UiTypography from "src/pages/UiComponents/UiTypography";
import UiToast from "src/pages/UiComponents/UiToast";
import UiVideo from "src/pages/Utility/UiVideo";
import UiGeneral from "src/pages/UiComponents/UiGeneral";
import UiColors from "src/pages/UiComponents/UiColors";
import UiUtilities from "src/pages/UiComponents/Uiutilities";

//Extended pages
import UiLightbox from "src/pages/Extended/Lightbox";
import SessionTimeout from "src/pages/Extended/SessionTimeout";
import UiRating from "src/pages/Extended/UiRating";
import Notifications from "src/pages/Extended/Notifications";

//Forms pages
import FormElements from "src/pages/Forms/FormElements";
import FormValidation from "src/pages/Forms/FormValidation";
import AdvancedPlugins from "src/pages/Forms/AdvancedPlugins";
import FormEditors from "src/pages/Forms/FormEditors";
import FormUpload from "src/pages/Forms/FormUpload";
import FormWizard from "src/pages/Forms/FormWizard";
import FormMask from "src/pages/Forms/FormMask";

//Tables
import BasicTable from "src/pages/Tables/BasicTables";
import DatatableTables from "src/pages/Tables/DatatableTables";
import ResponsiveTables from "src/pages/Tables/ResponsiveTables";
import EditableTables from "src/pages/Tables/EditableTables";

//Charts
import Apexchart from "src/pages/Charts/Apexcharts";
import EChart from "src/pages/Charts/EChart";
import ChartjsChart from "src/pages/Charts/ChartjsChart";
import SparklineChart from "src/pages/Charts/SparklineChart";

//blog
import BlogGrid from "../pages/Blog/blogGrid";
import BlogList from "../pages/Blog/blogList";
import BlogDetails from "../pages/Blog/blogDetails";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesomes";

//AuthenticationInner pages
import PageLogin from "src/pages/AuthenticationInner/PageLogin";
import PageRegister from "src/pages/AuthenticationInner/PageRegister";
import RecoverPassword from "src/pages/AuthenticationInner/RecoverPassword";
import LockScreen from "src/pages/AuthenticationInner/LockScreen";
import ConfirmMail from "src/pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "src/pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "src/pages/AuthenticationInner/TwoStepVerfication";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import UserProfile from "src/pages/Authentication/user-profile";
import PagesComingsoon from "src/pages/Utility/PageComingsoon";
import AuthLogout from "../pages/AuthenticationInner/Logout";

//Maps
import MapsGoogle from "src/pages/Maps/MapsGoogle";
import MapsVector from "src/pages/Maps/MapsVector";
import MapsLeaflet from "src/pages/Maps/MapsLeaflet";
import RangeSlider from "src/pages/Extended/RangeSlider/Index";
import ResendConfirmation from "src/components/UI/ResendConfirmation";
import SignUpForm from "src/components/UI/SignUpForm"; import Form from "src/pages/form/Form";
import ReusableTable from "src/pages/Tables/ReusableTable";
import CreateDealer from "src/components/UI/CreateDealer";
import CreateUser from "src/pages/MelUser/CreateUser";
import FormWrapper from "src/components/Common/Form/FormWrapper";
import MelCreateDealer from "src/pages/Dealer/MelCreateDealer";
import EditCustomer from "src/pages/Customers/EditCustomer";
import Customers from "src/pages/Customers/CustomerList";
import EditUser from "src/pages/MelUser/EditUser";
import Dealers from "src/pages/Dealer/DealerList";
import Administrators from "src/pages/Administrator/AdministratorList";
import EditAdministrator from "src/pages/Administrator/EditAdministrator";
import AudioBrands from "src/pages/MasterManagement/AudioBrands/AudioBrands";
import AudioModels from "src/pages/MasterManagement/AudioModels/AudioModels";
import Parts from "src/pages/MasterManagement/Parts/Parts";
import Taxes from "src/pages/MasterManagement/Taxes/Taxes";
import VehicleBrands from "src/pages/MasterManagement/VehicleBrands/VehicleBrands";
import PartSKUs from "src/pages/MasterManagement/PartSKUs/PartSKUs";
import EditAudioBrands from "src/pages/MasterManagement/AudioBrands/AudioBrandEditForm"
import EditAudioModels from "src/pages/MasterManagement/AudioModels/EditAudioModels";
import CreateParts from "src/pages/MasterManagement/Parts/CreateParts";
import AddEditVehicleBrands from "src/pages/MasterManagement/VehicleBrands/AddEditVehicleBrands";
import AddEditTaxes from "src/pages/MasterManagement/Taxes/AddEditTaxes";
import ViewAudioBrand from "src/pages/MasterManagement/AudioBrands/ViewAudioBrand";
import ViewAudioModel from "src/pages/MasterManagement/AudioModels/ViewAudioModel";
import ViewTaxDetails from "src/pages/MasterManagement/Taxes/ViewTaxDetails";
import ViewCustomer from "src/pages/Customers/ViewCustomer";
import DealerForm from "src/pages/Dealer/DealerForm";
import ViewAdministrator from "src/pages/Administrator/ViewAdministrator";
import ViewUser from "src/pages/MelUser/ViewUser";
import { ROUTER } from "src/constants/routes";
import ViewDealer from "src/pages/Dealer/ViewDealer";
import ViewParts from "src/pages/MasterManagement/Parts/ViewParts";
import ViewVehicleBrand from "src/pages/MasterManagement/VehicleBrands/ViewVehicleBrand";
import AddEditFranchisee from "src/pages/Franchisee/AddEditFranchisee";
import LoginCallback from "src/pages/Authentication/LoginCallback";
import FranchiseeList from "src/pages/Franchisee/FranchiseeList";
import ViewFranchisee from "src/pages/Franchisee/ViewFranchisee";
import AddEditSubUser from "src/pages/SubUserManagement/AddEditSubUser";
import SubUserList from "src/pages/SubUserManagement/SubUserList";
import Supplier from "src/pages/MasterManagement/Supplier/Supplier";
import ViewSubUser from "src/pages/SubUserManagement/ViewSubUser";
import PurchaseOrders from "src/pages/Stock Management/Purchase Orders/PurchaseOrders";
import PartsInbound from "src/pages/Stock Management/Parts Inbound/PartsInbound";
import StockAdjustment from "src/pages/Stock Management/Stock Adjustment/StockAdjustment";
import AddorEditPurchaseOrder from "src/pages/Stock Management/Purchase Orders/AddorEditPurchaseOrder";
import ViewPurchaseOrder from "src/pages/Stock Management/Purchase Orders/ViewPurchaseOrder";
import UserList from "src/pages/MelUser/UserList";
import AddorEditPartsInbound from "src/pages/Stock Management/Parts Inbound/AddorEditPartsInbound";
import ViewPartsInbound from "src/pages/Stock Management/Parts Inbound/ViewPartsInbound";
import AddorEditStockAdjustment from "src/pages/Stock Management/Stock Adjustment/AddorEditStockAdjustment";
import ViewStockAdjustment from "src/pages/Stock Management/Stock Adjustment/ViewStockAdjustment";
import StockRegisterList from "src/pages/Stock Management/Stock Register/StockRegisterList";
import AddEditSupplier from "src/pages/MasterManagement/Supplier/AddEditSupplier";
import { UserTypes } from "src/constants/userRoles";
import ViewSupplier from "src/pages/MasterManagement/Supplier/ViewSupplier";
import AddEditDealerSubuser from "src/pages/DealerSubUser/AddEditDealerSubuser";
import ViewDealerSubuser from "src/pages/DealerSubUser/ViewDealerSubuser";
import DealerSubUserList from "src/pages/DealerSubUser/DealerSubUserList";
import StockValueBySupplier from "src/pages/Stock Management/StockValueBySupplier/StockValueBySupplier";
import StockQuantityBySupplier from "src/pages/Stock Management/StockQuantityBySupplier/StockQuantityBySupplier";
import StockValueByMusicSystemBrand from "src/pages/Stock Management/StockValueByMusicSystemBrand/StockValueByMusicSystemBrand";
import StockQuantityMusicSystemBrand from "src/pages/Stock Management/StockQuantityByMusicSystemBrand/StockQuantityMusicSystemBrand";
import StockValuebyPart from "src/pages/Stock Management/StockValuebyPart/StockValuebyPart";
import StockValuebyPartComponent from "src/pages/Stock Management/StockValuebyPart/StockValuebyPart";
import StockQtybyPartComponent from "src/pages/Stock Management/StockValuebyPart/StockQtybyPart";
import StockInspectionList from "src/pages/Stock Management/StockInspection/StockInspectionList";
import ViewStockInspection from "src/pages/Stock Management/StockInspection/ViewStockInspection";
import AddOrEditStockInspection from "src/pages/Stock Management/StockInspection/AddOrEditStockInspection";
import StockLocationList from "src/pages/Stock Management/StockLocation/STockLocationList";
import AddOrEditStockLocation from "src/pages/Stock Management/StockLocation/AddOrEditStockLocation";
import JobSheets from "src/pages/JobSheets/JobSheets/JobSheets";
import EditJobSheets from 'src/pages/JobSheets/JobSheets/JobSheetEditForm';
import ViewStockLoaction from "src/pages/Stock Management/StockLocation/ViewStockLocation";
import InvoiceList from "src/pages/Invoice/InvoiceList";
import AddEditInvoice from "src/pages/Invoice/AddEditInvoice";
import InvoiceView from "src/pages/Invoice/InvoiceView";
import EditProfile from "src/pages/Profile/EditProfile";
import ViewJobSheet from "src/pages/JobSheets/JobSheets/ViewJobSheet";
import InvoiceItemWiseRegisters from "src/pages/Reports/InvoiceItemWiseRegisters/InvoiceItemWiseRegisters";
import FeedbackList from "src/pages/JobSheets/feedback/FeedbackList";
import OutGoingDispatch from "src/pages/Dispatch/OutGoingDispatch";
import IncomingDispatchList from "src/pages/Dispatch/incomingDispatch/IncomingDispatch";
import ViewIncomingDispatch from "src/pages/Dispatch/incomingDispatch/ViewIncomingDispatch";
import EnquiriesList from "src/pages/JobSheets/enquiries/EnquiriesList";
import AddEnquiry from "src/pages/JobSheets/enquiries/AddEnquiry";
import ViewEnquiries from "src/pages/JobSheets/enquiries/ViewEnquiries";
import AttendanceList from "src/pages/Attendance/AttendanceList";
import SkuGeneratorComponent from "src/pages/Stock Management/Parts Inbound/SkuGenerator";
import FloatingStockComponent from "src/pages/Stock Management/FloatingStockManagement/createFloatingStockManagement";
import FloatingStockManagement from "src/pages/Stock Management/FloatingStockManagement/FloatingStockManagement";
import StockScannerComponent from "src/pages/Stock Management/FloatingStockManagement/StockScanner";
import ViewIntentComponent from "src/pages/Stock Management/FloatingStockManagement/viewFloatingStockManagement";
import ViewOutgoingDispatch from "src/pages/Dispatch/ViewOutgoingDispatch";
import CouriersList from "src/pages/Dispatch/couriers/CouriersList";
import ViewCourier from "src/pages/Dispatch/couriers/ViewCourier";
import AddEditCourier from "src/pages/Dispatch/couriers/AddEditCourier";
// import PendingParts from "src/pages/Reports/pendingParts/PendingPartsConsolidated";
// import PendingPartsConsolidated from "src/pages/Reports/pendingParts/PendingPartsConsolidated";
import JobSheetReports from "src/pages/Reports/JobSheetReports/JobSheetReports";
import JobSheetTATReports from "src/pages/Reports/JobSheetTatReports/JobSheetTATReports";
import MonthlyPartsSummary from "src/pages/Reports/MonthlyPartsSummary/MonthlyPartsSummary";
import ReceivedPayment from "src/pages/ReceivedPayment/ReceivedPayment";
import ViewReceivedPayment from "src/pages/ReceivedPayment/ViewReceivedPayment";
import CreateReceivedPayment from "src/pages/ReceivedPayment/CreateReceivedPayment";
import AddOrEditPurchaseOrder from "src/pages/Stock Management/Purchase Orders/AddorEditPurchaseOrder";
import PendingPartsConsolidated from "src/pages/Reports/pendingParts/PendingPartsConsolidated";
import PendingParts from "src/pages/Reports/pendingParts/PendingParts";
import BulkSmsSender from "src/pages/BulkSmsMailSender/BulkSmsSender";
import OutgoingDispatchList from "src/pages/Dispatch/OutGoingDispatch";
import CreateFeedback from "src/pages/JobSheets/feedback/CreateFeedback";
import ViewFeedback from "src/pages/JobSheets/feedback/ViewFeedback";
import IndexPage from "src/pages/TicketSystem/IndexPage";
import TicketSystemListPage from "src/pages/TicketSystem/TicketSystemListPage";
import CreateTicketSystem from "src/pages/TicketSystem/CreateTicketSystem";
import ViewTicketSystem from "src/pages/TicketSystem/ViewTicketSystem";
import FranchiseAdditionalInformation from "src/pages/FranchiseAdditionalInformation/FranchiseAdditionalInformation";
import EmployeeProfile from "src/pages/Profile/EmployeeProfile";
import AttendanceReports from "src/pages/Reports/Attendance/AttendanceReports";
import BulkMailSender from "src/pages/Marketing/BulkMailSender/BulkMailList";
import SendEmail from "src/pages/Marketing/BulkMailSender/SendEmail";
import BulkSmsSenderList from "src/pages/Marketing/BulkSmsSender/BulkSmsList";
import BulkSmsSendingPage from "src/pages/Marketing/BulkSmsSender/BulkSmsSender";
import ViewBulkEmail from "src/pages/Marketing/BulkMailSender/ViewBulkEmail";
import ViewBulkSms from "src/pages/Marketing/BulkSmsSender/ViewBulkSms";
import AdditionalExpensesGrid from "src/pages/JobSheets/JobSheets/AdditionalExpensesGrid";
import ViewAdditionalExpense from "src/pages/JobSheets/JobSheets/ViewAdditionalExpense";
import BarcodePrinter from "src/pages/MasterManagement/PartSKUs/BarcodePrinter";
import IncentivePages from "src/pages/Settings/Incentive/IncentivePages";
import RewardPage from "src/pages/Settings/RewardValue/RewardPage";
import TotalWorkingDaysList from "src/pages/Settings/TotalWorkingDays/TotalWorkingDaysList";
import AddorEditTotalWorkingDays from "src/pages/Settings/TotalWorkingDays/AddorEditTotalWorkingDays";
import AccessDeniedPage from "src/components/atoms/AccessDeniedPage";
import EditAdditionalExpenses from "src/pages/JobSheets/JobSheets/EditAdditionalExpenses";
import PermissionPage from "src/pages/Settings/Permission/Permission";
import IncentiveRewardList from "src/pages/Settings/IncentiveRewardPoint/IncentiveRewardList";
import AdditionalExpenseReportsTable from "src/pages/Settings/AdditionalExpenseReport/AdditionalExpenseReportsTable";
import ViewIncentiveReward from "src/pages/Settings/IncentiveRewardPoint/ViewIncentiveReward";
import ViewAdditionalExpenseSettings from "src/pages/Settings/AdditionalExpenseReport/ViewAdditionalExpense";
import PermissionNew from "src/pages/Settings/Permission/PermissionNew";
import BulkWhatsappList from "src/pages/Marketing/WhatsappSender/WhatsappMessageList";
import ViewWhatsappMessage from "src/pages/Marketing/WhatsappSender/ViewWhatsAppMessage";
import SendWhatsAppMessage from "src/pages/Marketing/WhatsappSender/WhatsAppChatSender";
import { PaymentSuccesss } from "src/pages/Payment/PaymentSuccesss";
import AddEditManufacturer from "src/pages/Manufacturer/AddEditManufacturer";
import ManufacturerList from "src/pages/Manufacturer/ManufacturerList";
import ViewManufacturer from "src/pages/Manufacturer/ViewManufacturer";




interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const storedRole = localStorage.getItem("role")
// const userRoles = JSON.parse(localStorage.getItem("role") || '[]')
let userRoles = null;

if (storedRole) {
  try {
    userRoles = JSON.parse(storedRole);
  } catch {
    userRoles = storedRole;
  }
} else {
  userRoles = []
}

let role = ""

if (Array.isArray(userRoles)) {
  if (userRoles.includes(UserTypes[UserTypes['Dealer']])) {
    role = UserTypes[UserTypes.Dealer]
  } else if (userRoles.includes(UserTypes[UserTypes['Franchise']])) {
    role = UserTypes[UserTypes.Franchise]
  } else if (userRoles.includes(UserTypes[UserTypes['FranchiseSubUser']])) {
    role = UserTypes[UserTypes.FranchiseSubUser]
  } else {
    role = ""
  }
} else {
  role = userRoles
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: ROUTER.PROFILE, component: <Profile /> },
  { path: ROUTER.PROFILE_EMPLOYEE, component: <EmployeeProfile /> },
  // { path: ROUTER.PROFILE_EDIT + ROUTER.ID, component: <EditProfile /> },

  //attendance
  { path: ROUTER.ATTENDANCE, component: <AttendanceList /> },
  { path: ROUTER.SHOW_ATTENDANCE, component: <AttendanceList /> },

  //dashboard
  { path: ROUTER.DASHBOARD, component: <Dashboard /> },

  //Grid
  { path: "/grid", component: <Profile /> },

  //Calendar
  { path: "/apps-calendar", component: <Calendar className="" /> },

  //Chat
  { path: "/apps-chat", component: <Chat /> },

  //Email Inbox
  { path: "/email-inbox", component: <Inbox /> },
  { path: "/email-read", component: <EmailRead /> },

  //Invoice
  // { path: "/invoices-list", component: <InvoiceList /> },
  // { path: "/invoices-detail", component: <InvoiceDetails /> },

  //Contact
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //blog
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-details", component: <BlogDetails /> },

  //Utility
  { path: "/pages-starter", component: <PageStarter /> },
  { path: "/pages-timeline", component: <PageTimeline /> },
  { path: "/pages-faqs", component: <PageFaqs /> },
  { path: "/pages-pricing", component: <PagePricing /> },

  //UI Components
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButton /> },
  { path: "/ui-cards", component: <UiCard /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdowns /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-toasts", component: <UiToast /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-utilities", component: <UiUtilities /> },

  //Extended pages
  { path: "/extended-lightbox", component: <UiLightbox /> },
  { path: "/extended-rangeslider", component: <RangeSlider /> },
  {
    path: "/extended-session-timeout",
    component: <SessionTimeout />,
  },
  { path: "/extended-rating", component: <UiRating /> },
  { path: "/extended-notifications", component: <Notifications /> },

  // Forms pages
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-validation", component: <FormValidation /> },
  { path: "/form-advanced", component: <AdvancedPlugins /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-mask", component: <FormMask /> },

  //tables
  { path: "/tables-basic", component: <BasicTable /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  //Charts
  { path: "/charts-apex", component: <Apexchart /> },
  { path: "/charts-echart", component: <EChart /> },
  { path: "/charts-chartjs", component: <ChartjsChart /> },
  { path: "/charts-sparkline", component: <SparklineChart /> },

  //Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  //Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },


  //form
  { path: "/newForm", component: <Form /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to={ROUTER.JOB_SHEETS_GRID} /> },
  // { path: "/", exact: true, component: role === UserTypes[UserTypes.Dealer] || role === UserTypes[UserTypes.Franchise] ? <Navigate to={ROUTER.JOB_SHEETS_GRID} /> : (role === UserTypes[UserTypes.FranchiseSubUser] ? <Navigate to={ROUTER.ATTENDANCE} /> : <Navigate to={ROUTER.USERS_GRID} />) },

  //reusableTable
  { path: "/reusable", component: <ReusableTable /> },

  // User
  { path: ROUTER.USERS_GRID, component: <UserList /> },
  { path: ROUTER.USERS_CREATE, component: <EditUser /> },
  { path: ROUTER.USERS_EDIT + ROUTER.ID, component: <EditUser /> },
  { path: ROUTER.USERS_VIEW + "/:id", component: <ViewUser /> },

  // User
  { path: ROUTER.INTENT_GRID, component: <FloatingStockManagement /> },
  { path: ROUTER.INTENT_CREATE, component: <FloatingStockComponent /> },
  { path: ROUTER.INTENT_EDIT + ROUTER.ID, component: <FloatingStockComponent /> },
  { path: ROUTER.INTENT_VIEW + "/:id", component: <ViewIntentComponent /> },
  { path: ROUTER.INTENT_SCAN + ROUTER.ID + ROUTER.SUB_ID, component: <StockScannerComponent /> },

  //createDelars
  // { path: "/dealer", component: <MelCreateDealer /> },
  // { path: "/dealer/:dealerId", component: <MelCreateDealer /> },


  // customers
  { path: ROUTER.CUSTOMERS_GRID, component: <Customers /> },
  { path: ROUTER.CUSTOMERS_VIEW + ROUTER.ID, component: <ViewCustomer /> },
  { path: ROUTER.CUSTOMERS_EDIT + ROUTER.ID, component: <EditCustomer /> },
  { path: ROUTER.CUSTOMERS_CREATE, component: <EditCustomer /> },

  //administrator
  { path: ROUTER.ADMINISTRATOR_EDIT + ROUTER.ID, component: <EditAdministrator /> },
  { path: ROUTER.ADMINISTRATOR_GRID, component: <Administrators /> },
  { path: ROUTER.ADMINISTRATOR_CREATE, component: <EditAdministrator /> },
  { path: ROUTER.ADMINISTRATOR_VIEW + "/:id", component: <ViewAdministrator /> },

  //job sheets
  { path: ROUTER.JOB_SHEETS_GRID, component: <JobSheets /> },
  { path: ROUTER.JOB_SHEETS_CREATE, component: <EditJobSheets /> },
  { path: ROUTER.JOB_SHEETS_CREATE +ROUTER.CREATE_CUSTOMER_ID, component: <EditJobSheets /> },
  { path: ROUTER.JOB_SHEETS_VIEW + ROUTER.ID, component: <ViewJobSheet /> },
  { path: ROUTER.JOB_SHEETS_EDIT + ROUTER.ID, component: <EditJobSheets /> },

  //additional expenses
  { path: ROUTER.ADDITIONAL_EXPENSE_GRID + ROUTER.ID, component: <AdditionalExpensesGrid /> },
  { path: ROUTER.ADDITIONAL_EXPENSE_VIEW + ROUTER.ID, component: <ViewAdditionalExpense /> },
  { path: ROUTER.ADDITIONAL_EXPENSE_EDIT + ROUTER.JOB_ID + ROUTER.ID, component: <EditAdditionalExpenses /> },

  //enquiry
  { path: ROUTER.ENQUIRY_GRID, component: <EnquiriesList /> },
  { path: ROUTER.ENQUIRY_CREATE, component: <AddEnquiry /> },
  { path: ROUTER.ENQUIRY_CREATE + ROUTER.JOB_ID, component: <AddEnquiry /> },
  { path: ROUTER.ENQUIRY_VIEW + ROUTER.ID, component: <ViewEnquiries /> },

  //FEEDBACK
  { path: ROUTER.FEEDBACK_GRID, component: <FeedbackList /> },
  { path: ROUTER.FEEDBACK_CREATE + ROUTER.ID, component: <CreateFeedback /> },
  { path: ROUTER.FEEDBACK_VIEW + ROUTER.ID, component: <ViewFeedback /> },

  //audio brands
  { path: ROUTER.AUDIO_BRANDS_GRID, component: <AudioBrands /> },
  { path: ROUTER.AUDIO_BRANDS_CREATE, component: <EditAudioBrands /> },
  { path: ROUTER.AUDIO_BRANDS_VIEW + ROUTER.ID, component: <ViewAudioBrand /> },
  { path: ROUTER.AUDIO_BRANDS_EDIT + ROUTER.ID, component: <EditAudioBrands /> },

  //audio Model
  { path: ROUTER.AUDIO_MODELS_GRID, component: <AudioModels /> },
  { path: ROUTER.AUDIO_MODELS_CREATE, component: <EditAudioModels /> },
  { path: ROUTER.AUDIO_MODELS_VIEW + ROUTER.ID, component: <ViewAudioModel /> },
  { path: ROUTER.AUDIO_MODELS_EDIT + ROUTER.ID, component: <EditAudioModels /> },

  //parts
  { path: ROUTER.PARTS_GRID, component: <Parts /> },
  { path: ROUTER.PARTS_CREATE, component: <CreateParts /> },
  { path: ROUTER.PARTS_EDIT + ROUTER.ID, component: <CreateParts /> },
  { path: ROUTER.PARTS_VIEW + ROUTER.ID, component: <ViewParts /> },

  //vehicles Pages
  { path: ROUTER.VEHICLE_BRANDS_GRID, component: <VehicleBrands /> },
  { path: ROUTER.VEHICLE_BRANDS_CREATE, component: <AddEditVehicleBrands /> },
  { path: ROUTER.VEHICLE_BRANDS_EDIT + ROUTER.ID, component: <AddEditVehicleBrands /> },
  { path: ROUTER.VEHICLE_BRANDS_VIEW + ROUTER.ID, component: <ViewVehicleBrand /> },

  //Taxes Pages
  { path: ROUTER.TAXES_GRID, component: <Taxes /> },
  { path: ROUTER.TAXES_CREATE, component: <AddEditTaxes /> },
  { path: ROUTER.TAXES_VIEW + ROUTER.ID, component: <ViewTaxDetails /> },
  { path: ROUTER.TAXES_EDIT + ROUTER.ID, component: <AddEditTaxes /> },

  // PARTS SKU
  { path: ROUTER.PARTS_SKU + ROUTER.PARTS_SKU_ID, component: <PartSKUs /> },
  { path: ROUTER.PARTS_SKU, component: <PartSKUs /> },
  { path: ROUTER.PARTS_SKU_PRINT, component: <BarcodePrinter /> },

  // dealer
  { path: ROUTER.DEALERS_GRID, component: <Dealers /> },
  { path: ROUTER.DEALERS_CREATE, component: <DealerForm /> },
  { path: ROUTER.DEALERS_VIEW + ROUTER.ID, component: <ViewDealer /> },
  { path: ROUTER.DEALERS_EDIT + ROUTER.ID, component: <DealerForm /> },

  //franchisee
  { path: ROUTER.FRANCHISEE_CREATE, component: <AddEditFranchisee /> },
  { path: ROUTER.FRANCHISEE_EDIT + ROUTER.ID, component: <AddEditFranchisee /> },
  { path: ROUTER.FRANCHISEE_GRID, component: <FranchiseeList /> },
  { path: ROUTER.FRANCHISEE_VIEW + ROUTER.ID, component: <ViewFranchisee /> },

  { path: ROUTER.FRANCHISEE_PROFILE_VIEW, component: <FranchiseAdditionalInformation /> },

  //franchise subUser
  { path: ROUTER.SUB_USER_CREATE, component: <AddEditSubUser /> },
  { path: ROUTER.SUB_USER_CREATE_ID + ROUTER.SUB_ID, component: <AddEditSubUser /> },
  { path: ROUTER.SUB_USER_GRID, component: <SubUserList /> },
  { path: ROUTER.SUB_USER_EDIT + ROUTER.ID + ROUTER.SUB_ID, component: <AddEditSubUser /> },
  { path: ROUTER.SUB_USER_VIEW + ROUTER.ID + ROUTER.SUB_ID, component: <ViewSubUser /> },

  //dealer subUser
  { path: ROUTER.DEALER_SUB_USER_CREATE, component: <AddEditDealerSubuser /> },
  { path: ROUTER.DEALER_SUB_USER_CREATE_ID + ROUTER.SUB_ID, component: <AddEditDealerSubuser /> },
  { path: ROUTER.DEALER_SUB_USER_GRID, component: <DealerSubUserList /> },
  { path: ROUTER.DEALER_SUB_USER_EDIT + ROUTER.ID, component: <AddEditDealerSubuser /> },
  { path: ROUTER.DEALER_SUB_USER_VIEW + ROUTER.ID + ROUTER.SUB_ID, component: <ViewDealerSubuser /> },
  { path: ROUTER.DEALER_SUB_USER_VIEW + ROUTER.ID, component: <ViewDealerSubuser /> },

  //stock management
  //purchase order
  { path: ROUTER.PURCHASE_ORDER_GRID, component: <PurchaseOrders /> },
  { path: ROUTER.PURCHASE_ORDER_CREATE, component: <AddorEditPurchaseOrder /> },
  { path: ROUTER.PURCHASE_ORDER_EDIT + ROUTER.ID, component: <AddOrEditPurchaseOrder /> },
  { path: ROUTER.PURCHASE_ORDER_VIEW + ROUTER.ID, component: <ViewPurchaseOrder /> },

  //parts inbound
  { path: ROUTER.PARTS_INBOUND_GRID, component: <PartsInbound /> },
  { path: ROUTER.PARTS_INBOUND_CREATE, component: <AddorEditPartsInbound /> },
  { path: ROUTER.PARTS_INBOUND_EDIT + ROUTER.ID, component: <AddorEditPartsInbound /> },
  { path: ROUTER.PARTS_INBOUND_VIEW + ROUTER.ID, component: <ViewPartsInbound /> },
  { path: ROUTER.PARTS_SKU_GENERATOR + ROUTER.ID + ROUTER.SUB_ID, component: <SkuGeneratorComponent /> },

  //stock adjustment
  { path: ROUTER.STOCK_ADJUSTMENT_GRID, component: <StockAdjustment /> },
  { path: ROUTER.STOCK_ADJUSTMENT_CREATE, component: <AddorEditStockAdjustment /> },
  { path: ROUTER.STOCK_ADJUSTMENT_EDIT + ROUTER.ID, component: <AddorEditStockAdjustment /> },
  { path: ROUTER.STOCK_ADJUSTMENT_VIEW + ROUTER.ID, component: <ViewStockAdjustment /> },
  { path: ROUTER.STOCK_ADJUSTMENT_PART_VIEW + ROUTER.SUB_ID, component: <ViewStockAdjustment /> },

  //stock register
  { path: ROUTER.STOCK_REGISTER_GRID, component: <StockRegisterList /> },

  //stock inspection
  { path: ROUTER.STOCK_INSPECTION_GRID, component: <StockInspectionList /> },
  { path: ROUTER.STOCK_INSPECTION_CREATE, component: <AddOrEditStockInspection /> },
  { path: ROUTER.STOCK_INSPECTION_EDIT + ROUTER.ID, component: <AddOrEditStockInspection /> },
  { path: ROUTER.STOCK_INSPECTION_VIEW + ROUTER.ID, component: <ViewStockInspection /> },

  { path: ROUTER.STOCK_LOCATION_GRID, component: <StockLocationList /> },
  { path: ROUTER.STOCK_LOCATION_CREATE, component: <AddOrEditStockLocation /> },
  { path: ROUTER.STOCK_LOCATION_EDIT + ROUTER.ID, component: <AddOrEditStockLocation /> },
  { path: ROUTER.STOCK_LOCATION_VIEW + ROUTER.ID, component: <ViewStockLoaction /> },

  //supplier
  { path: ROUTER.SUPPLIER_CREATE, component: <AddEditSupplier /> },
  { path: ROUTER.SUPPLIER_GRID, component: <Supplier /> },
  { path: ROUTER.SUPPLIER_EDIT + ROUTER.ID, component: <AddEditSupplier /> },
  { path: ROUTER.SUPPLIER_VIEW + ROUTER.ID, component: <ViewSupplier /> },

  //stock value by supplier
  { path: ROUTER.STOCK_VALUE_BY_SUPPLIER_GRID, component: <StockValueBySupplier /> },

  //stock quantity by supplier
  { path: ROUTER.STOCK_QUANTITY_BY_SUPPLIER_GRID, component: <StockQuantityBySupplier /> },
  //stock value by music system brand 
  { path: ROUTER.STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_GRID, component: <StockValueByMusicSystemBrand /> },
  //stock quantity by music system brand 
  { path: ROUTER.STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_GRID, component: <StockQuantityMusicSystemBrand /> },

  // Stock Value by Part
  { path: ROUTER.STOCK_VALUE_BY_PART_GRID, component: <StockValuebyPartComponent /> },
  { path: ROUTER.STOCK_QUANTITY_BY_PART_GRID, component: <StockQtybyPartComponent /> },

  //invoices
  { path: ROUTER.INVOICE_GRID, component: <InvoiceList /> },
  { path: ROUTER.INVOICE_CREATE, component: <AddEditInvoice /> },
  { path: ROUTER.INVOICE_CREATE + ROUTER.JOB_ID, component: <AddEditInvoice /> },
  { path: ROUTER.INVOICE_EDIT + ROUTER.ID, component: <AddEditInvoice /> },
  { path: ROUTER.INVOICE_EDIT + ROUTER.ID + ROUTER.EDIT_JOB_ID, component: <AddEditInvoice /> },
  { path: ROUTER.INVOICE_VIEW + ROUTER.ID, component: <InvoiceView /> },
  { path: ROUTER.INVOICE_VIEW_BY_PART + ROUTER.SUB_ID, component: <InvoiceView /> },

  //  Invoice Item Wise Registers
  { path: ROUTER.INVOICE_ITEM_WISE_REGISTERS_GRID, component: <InvoiceItemWiseRegisters /> },
  { path: ROUTER.ATTENDANCE_REPORT, component: <AttendanceReports /> },

  //pending parts
  { path: ROUTER.PENDING_PARTS_CONSOLIDATED, component: <PendingPartsConsolidated /> },
  { path: ROUTER.JOB_SHEET_REPORTS, component: <JobSheetReports /> },
  { path: ROUTER.JOB_SHEET_TAT_REPORTS, component: <JobSheetTATReports /> },
  { path: ROUTER.MONTHLY_PARTS_SUMMARY, component: <MonthlyPartsSummary /> },
  { path: ROUTER.PENDING_PARTS, component: <PendingParts /> },


  //dispatch 
  { path: ROUTER.OUTCOMING_DISPATCH, component: <OutgoingDispatchList /> },
  { path: ROUTER.VIEW_OUTGOING_DISPATCH + ROUTER.ID, component: <ViewOutgoingDispatch /> },
  { path: ROUTER.INCOMING_DISPATCH, component: <IncomingDispatchList /> },
  { path: ROUTER.VIEW_INCOMING_DISPATCH + ROUTER.ID, component: <ViewIncomingDispatch /> },

  //couriers
  { path: ROUTER.COURIER_LIST, component: <CouriersList /> },
  { path: ROUTER.VIEW_COURIER + ROUTER.ID, component: <ViewCourier /> },
  { path: ROUTER.EDIT_COURIER + ROUTER.ID, component: <AddEditCourier /> },
  { path: ROUTER.CREATE_COURIER, component: <AddEditCourier /> },

  //received payment

  { path: ROUTER.RECEIVED_PAYMENT_LIST, component: <ReceivedPayment /> },
  { path: ROUTER.RECEIVED_PAYMENT_VIEW + ROUTER.ID, component: <ViewReceivedPayment /> },
  { path: ROUTER.RECEIVED_PAYMENT_CREATE, component: <CreateReceivedPayment /> },

  //sme , mail sender
  { path: ROUTER.SMS_MAIL_SENSER, component: <BulkSmsSender /> },

  //ticket system
  { path: ROUTER.TICKET_SYSTEM, component: <TicketSystemListPage /> },
  { path: ROUTER.CREATE_TICKET_SYSTEM, component: <CreateTicketSystem /> },
  { path: ROUTER.EDIT_TICKET_SYSTEM + ROUTER.ID, component: <CreateTicketSystem /> },
  { path: ROUTER.VIEW_TICKET_SYSTEM + ROUTER.ID, component: <ViewTicketSystem /> },

  //bulk sms and email
  { path: ROUTER.BULK_EMAIL, component: <BulkMailSender /> },
  { path: ROUTER.BULK_EMAIL_SENDER, component: <SendEmail /> },
  { path: ROUTER.BULK_EMAIL_VIEW + ROUTER.ID, component: <ViewBulkEmail /> },
  { path: ROUTER.BULK_SMS, component: <BulkSmsSenderList /> },
  { path: ROUTER.BULK_SMS_SENDER, component: <BulkSmsSendingPage /> },
  { path: ROUTER.BULK_SMS_VIEW + ROUTER.ID, component: <ViewBulkSms /> },

  //// incentive ui routers
  { path: ROUTER.INCENTIVE_SYSTEM, component: <IncentivePages /> },
  { path: ROUTER.REWARD_VALUE, component: <RewardPage /> },
  { path: ROUTER.TOTAL_WORKING_DAYS, component: <TotalWorkingDaysList /> },
  { path: ROUTER.TOTAL_WORKING_DAYS_CREATE, component: <AddorEditTotalWorkingDays /> },
  { path: ROUTER.TOTAL_WORKING_DAYS_EDIT + ROUTER.ID, component: <AddorEditTotalWorkingDays /> },
  { path: ROUTER.PERMISSION, component: <PermissionPage /> },
  { path: ROUTER.PERMISSION + '_new', component: <PermissionNew /> },

  { path: ROUTER.ACCESS_DENIED_PAGES, component: <AccessDeniedPage /> },

  { path: ROUTER.INCENTIVE_REWARD_SYSTEM, component: <IncentiveRewardList /> },
  { path: ROUTER.INCENTIVE_REWARD_SYSTEM_VIEW + ROUTER.ID, component: <ViewIncentiveReward /> },

  { path: ROUTER.ADDITIONAL_EXPENSES_REPORTS_TABLE, component: <AdditionalExpenseReportsTable /> },
  { path: ROUTER.ADDITIONAL_EXPENSES_REPORTS_VIEW + ROUTER.ID, component: <ViewAdditionalExpenseSettings /> },

  {path:ROUTER.WHATSAPP_MESSAGE_LIST,component:<BulkWhatsappList/>},
  {path:ROUTER.WHATSAPP_MESSAGE_SENDER,component:<SendWhatsAppMessage/>},
  {path:ROUTER.WHATSAPP_MESSAGE_EDIT+ROUTER.ID,component:<SendWhatsAppMessage/>},
  {path:ROUTER.WHATSAPP_MESSAGE_VIEW+ROUTER.ID,component:<ViewWhatsappMessage/>},


  {path:ROUTER.MANUFACTURER_GRID,component:<ManufacturerList/>},
  {path:ROUTER.MANUFACTURER_CREATE,component:<AddEditManufacturer/>},
  {path:ROUTER.MANUFACTURER_EDIT+ROUTER.ID,component:<AddEditManufacturer/>},
  {path:ROUTER.MANUFACTURER_VIEW+ROUTER.ID,component:<ViewManufacturer/>},





];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
  { path: "/authentication/login-callback", component: <LoginCallback /> },
  { path: "/logout", component: <Logout /> },
  { path: "/resend", component: <ResendConfirmation /> },
  { path: "/signup-form", component: <SignUpForm /> },
  
  { path: "/register", component: <Register /> },
  { path: "/recoverpw", component: <ForgetPassword /> },
  
  //AuthenticationInner pages
  { path: "/page-login", component: <PageLogin /> },
  { path: "/page-register", component: <PageRegister /> },
  { path: "/page-recoverpw", component: <RecoverPassword /> },
  { path: "/page-lock-screen", component: <LockScreen /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-email-verification", component: <EmailVerification /> },
  { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
  { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
  { path: "/page-logout", component: <AuthLogout /> },
  
  //utility page
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-maintenance", component: <PageMaintenance /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/payment-verification", component: <Error500 /> },
  {path:ROUTER.PAYMENT_SUCCESS,component:<PaymentSuccesss/>},
];

export { userRoutes, authRoutes };
