import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import { ErrorMessage, FieldArray } from "formik";
import TooltipMEL from "src/components/UI/Tooltip";
import FloatInput from 'src/components/Common/FloatInput';
import { APPROVE, CHECK_VALUE_IN_ARRAY, JOB_SHEET_STATUS, REJECT } from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import {
  clearApproveDocumentLink,
  getApproveDocumentLinkLoading,
  getPartDetailsForJobLoading,
  getTaxesDropdownInJobsheetLoading,
  resetPartDetailsForJob,
  resetTaxDetailsForJob,
  taxDetailsForJobLoading,
} from 'src/store/actions';
import { API } from 'src/util/api';
import { RadioButton } from 'primereact/radiobutton';
import ErrorText from '../ErrorText';
import Dropzone from 'react-dropzone';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import { useToast } from 'src/components/Common/ReactToaster';
import { onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import JobsheetImagePreview from 'src/components/Common/JobsheetImagePreviewer/JobsheetImagePreview';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import TextEditor from 'src/components/atoms/TextEditor';
import FileImg from 'src/assets/images/files.png';

interface TabProps {
  activeTab: string | number;
  status: string;
  handleChange?: any;
  onBlur: any;
  values: any;
  touch: any;
  error: any;
  validationConfiguration?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  accordion?: any;
  changeAccorditionHandler?: any;
  changeFlow: any;
  setApproveDocuments: any;
  approveDocuments: any;
}

const APPROVAL_MODE = [
  {
    label: "Email",
    value: "Email"
  },
  {
    label: "In Person",
    value: "In Person"
  },
  {
    label: "Phone",
    value: "Phone"
  },
  {
    label: "Portal",
    value: "Portal"
  },
]

const EstimateDetails = (props: TabProps) => {

  const { partsList, partsDetails, taxList, taxDetails, taxesDropdownList, jobsheet,approveDocument,approveDocumentLink } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [aprovefiles, setaprovefiles] = useState<any>(null);

  const jobsheetTaxTypes = useMemo(() => {
    return taxesDropdownList?.length > 0 ? taxesDropdownList?.map((eachTax: any) => {
      let data: any = {}
      data['label'] = eachTax?.taxName;
      data['value'] = eachTax?.id;
      return data;
    }).sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase().trim();
      const labelB = b.label.toUpperCase().trim();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    }) : []
  }, [taxesDropdownList])


  useEffect(() => {
    dispatch(getTaxesDropdownInJobsheetLoading())
  }, [])

  useEffect(() => {
    const fetchTaxDetails = async () => {
      if (
        props.values.taxDetails?.length === 0 &&
        props.values.status === JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]] &&
        taxList?.length > 0
      ) {
        let loopLength = taxList.length >= 5 ? 5 : taxList.length;
        for (let index = 0; index < loopLength; index++) {
          const element = taxList[index];
          props.setFieldValue(`taxDetails.${index}.name`, element?.name);
          try {
            const response = await API.getTaxByIdForJobAPI(element?.id);
            
            props.setFieldValue(`taxDetails.${index}.id`, 0);
            props.setFieldValue(`taxDetails.${index}.estimateId`, 0);
            props.setFieldValue(`taxDetails.${index}.name`, response?.data[0]?.taxName);
            props.setFieldValue(`taxDetails.${index}.sacNumber`, response?.data[0]?.sacNumber);
            props.setFieldValue(`taxDetails.${index}.taxPercentage`, response?.data[0]?.taxPercentage);
            props.setFieldValue(`taxDetails.${index}.amount`, "");
            props.setFieldValue(`taxDetails.${index}.priority`, 0);
          } catch (error) {
            console.error("Error fetching tax details:", error);
            // Handle error as needed
          }
        }
        props.values.taxDetails = [taxList?.filter((eachList: any, index: number) => {
          return index < 5;
        })]
        props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
      }
    };

    fetchTaxDetails();
  }, [taxList, props.values.status])

  useEffect(() => {
    if (partsDetails?.index != -1) {
      // props.setFieldValue(`partsDetails.${partsDetails?.index}.quantityRequired`,partsDetails?.value?.quantityRequired)
      props.setFieldValue(`partsDetails.${partsDetails?.index}.totalPrice`, partsDetails?.value?.totalPrice)
      props.setFieldValue(`partsDetails.${partsDetails?.index}.taxPercentage`, partsDetails?.value?.partTaxpercentage)
      props.setFieldValue(`partsDetails.${partsDetails?.index}.unitPrice`, partsDetails?.value?.unitPrice)
      props.setFieldValue(`partsDetails.${partsDetails?.index}.partDescription`, partsDetails?.value?.partDescription)
      dispatch(resetPartDetailsForJob())
    }
  }, [partsDetails])

  const currentUser: any = localStorage.getItem("authUser");
  const parseUser = JSON.parse(currentUser);

  useEffect(() => {

    if (!props.values.updatedByName) {
      props.setFieldValue("updatedByName", parseUser['username'])
    }
  }, [parseUser, props.values.updatedByName])

  useEffect(() => {
    if (taxDetails?.index != -1) {
      
      // props.setFieldValue(`partsDetails.${partsDetails?.index}.quantityRequired`,partsDetails?.value?.quantityRequired)
      props.setFieldValue(`taxDetails.${taxDetails?.index}.sacNumber`, taxDetails?.value[0]?.sacNumber)
      props.setFieldValue(`taxDetails.${taxDetails?.index}.taxPercentage`, taxDetails?.value[0]?.taxPercentage)
      props.setFieldValue(`taxDetails.${taxDetails?.index}.amount`, taxDetails?.value[0]?.amount)
      dispatch(resetTaxDetailsForJob())
    }
  }, [taxDetails])

  const partsDropList = partsList?.length > 0 ? (partsList.map((eachParts: any) => {
    let data: any = {};
    data['value'] = eachParts.id;
    data['label'] = eachParts.partNumber;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []


  const taxesDropList = taxList?.length > 0 ? (taxList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.taxName;
    data['label'] = eachTax.taxName;
    data['id'] = eachTax.id;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []

  const isPartDetailFilled = (values: any) => {
    const contactDetails = values.contactDetails || [];
    return contactDetails.every((contact: any) => {
      // Check for values in all fields except 'alternateNumber'
      const { estimateId, id, ...fieldsExceptSomeField } = contact;
      return Object.values(fieldsExceptSomeField).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  useEffect(()=>{
    if(!props.values.IsAfcsGenerated){
      props.setApproveDocuments(()=>{
        return []
      })
    }
  },[props.values.IsAfcsGenerated])

  useEffect(() => {
    if (props.values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]] && jobsheet?.jobEstimateDto?.length == 0) {
      props.setFieldValue(`estimateTotal`, (props.values.serviceChargeTotalWithTax))
    }
  }, [props.values.serviceChargeTotalWithTax])

  const changeTotal = (currentIndex: any = -1, quantity: any, unitPrice: any, value: any, Values: any, taxAmount: number = 0, taxIndex: number = -1) => {
    if (currentIndex !== -1) {
      if (quantity > 0 && unitPrice > 0) {
        let calculatedValue = (+quantity * +unitPrice) + (((+quantity * +unitPrice) * value.taxPercentage) / 100)
        props.setFieldValue(`partsDetails.${currentIndex}.totalPrice`, parseFloat(calculatedValue.toFixed(2)))
      } else {
        props.setFieldValue(`partsDetails.${currentIndex}.totalPrice`, 0)
      }
    }


    let initialValue = 0;
    let taxInitalValue = props.values.serviceChargeTotalWithTax >= 0 ? props.values.serviceChargeTotalWithTax : 0;
    if (Values.taxDetails?.length > 0) {
      initialValue = props.values.taxDetails?.reduce((accumulator: any, currentValue: any, index: number) => {
        let totalPriceObj = 0

        // if(currentValue.partId == value.partId ){
        if (taxIndex == index) {
          totalPriceObj = +taxAmount + ((+taxAmount * (+currentValue?.taxPercentage)) / 100)
        } else {
          if (+currentValue?.amount > 0) {
            let calculatedValue = +currentValue?.amount + ((+currentValue?.amount * (+currentValue?.taxPercentage)) / 100)
            totalPriceObj = parseFloat(calculatedValue.toFixed(2))
          }
        }

        return +accumulator + +totalPriceObj
      }, taxInitalValue)
    } else {
      initialValue = taxInitalValue;
    }



    let totalEstimate = 0
    if (props.values?.partsDetails?.length > 0) {
      totalEstimate = props.values?.partsDetails?.reduce((accumulator: any, currentValue: any, index: number) => {

        let totalPriceObj = +currentValue?.totalPrice
        // if(currentValue.partId == value.partId ){
        if (currentIndex == index) {
          let calculatedValue = (+quantity * +unitPrice) + (((+quantity * +unitPrice) * value.taxPercentage) / 100)
          totalPriceObj = parseFloat(calculatedValue.toFixed(2));
        }
        return +accumulator + +totalPriceObj
      },
        initialValue)
    } else {
      totalEstimate = initialValue
    }
    props.setFieldValue(`estimateTotal`, parseFloat(totalEstimate.toFixed(2)))
  }

  const handleAddPartDetails = (values: any, push: any, setFieldTouched: any) => {
    push({
      id: 0,
      estimateId: 0,
      partId: "",
      quantityRequired: "",
      unitPrice: "",
      totalPrice: "",
      taxPercentage: "",
    });
    values['partsDetails'] = [{
      id: 0,
      estimateId: 0,
      partId: "",
      quantityRequired: "",
      unitPrice: "",
      totalPrice: "",
      taxPercentage: "",
    }]
    props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
  };

  const handleAddTaxDetails = (values: any, push: any, setFieldTouched: any) => {

    push({
      id: 0,
      estimateId: 0,
      name: "",
      sacNumber: "",
      taxPercentage: "",
      amount: "",
      priority: 0,
    });
    values['partsDetails'] = [{
      id: 0,
      estimateId: 0,
      name: "",
      sacNumber: "",
      taxPercentage: "",
      amount: "",
      priority: 0,
    }]
    props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  

  function handleAcceptedFiles(files: any) {
    
    let filesModified = files.map((file: any) => {
      const isImage = file.type.startsWith('image/');
      const preview = isImage ? URL.createObjectURL(file) : FileImg;
      return Object.assign(file, {
        preview: preview,
        formattedSize: formatBytes(file.size),
        isImage: isImage
      })
    }
    );
    const hasNonImageFile = filesModified.some((file:any) => !file.isImage);
    const hasNonImageFileExistLimt = filesModified.some((file:any) => file?.size > FILE_UPLOAD_SIZE);

    
    // if (!hasNonImageFile) {
      if (!hasNonImageFileExistLimt) {
        props.setApproveDocuments(
          ()=>{
            let fileList = [...files, ...props.approveDocuments]
            return fileList
          }
          );
      } else {
        showToast("File size exceeds 5MB", { type: "error" })
        props.setApproveDocuments( ()=>{
          let fileList = [...props.approveDocuments]
          return fileList
        });
      }
    // } 
    // else {
    //   showToast("Please upload the image file", { type: "error" })
    //   props.setApproveDocuments(
    //     ()=>{
    //       let fileList = [...props.approveDocuments]
    //       return fileList
    //     }
    //   );
    // }
  }


  const deleteConfirmationHandler = (data: any) => {
    setaprovefiles(data)
    setDeleteModal(true);
  }

  const removeApproveDocument = () => {
    const fileName = Array(aprovefiles)
    let deletedFiles = props.approveDocuments?.filter((data: any, index: number) => data.path !== fileName[0]?.path)
    props.setApproveDocuments(deletedFiles)
    setDeleteModal(false);
  };

  const checkDisableOrNotInParsTaxsAdded = () => {
    let currentStatus = jobsheet?.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]]
    let AfcsGenerated = props.values.IsAfcsGenerated;
    if (currentStatus && AfcsGenerated) {
      return false
    } else if (!currentStatus) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (approveDocumentLink != "" && approveDocumentLink != undefined && approveDocumentLink != null) {
      const newWindow = window.open(approveDocumentLink);
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearApproveDocumentLink());
    }
  }, [approveDocumentLink]);


  const viewApprovalDocuments=(data : any)=>{
    dispatch(getApproveDocumentLinkLoading(data.id));
  }

  const disableOrNot = checkDisableOrNotInParsTaxsAdded();
  


  return (
    <div className={`job-sheet-tab-content ${props.activeTab === "Estimate Details" ? "" : "d-none"}`}>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeApproveDocument()
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <Card className='mb-0 pe-0 ps-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'>Estimate Details</div>
        <CardBody className='p-0'>
          <div className='px-3 pb-3'>
            <Row>

              <Col lg={12} className="mt-mb-textarea">
                {/* <FloatInput
                  label="Estimator comments"
                  type="textarea"
                  name="estimatorComments"
                  onChange={props.handleChange}
                  onBlur={props.onBlur}
                  value={props.values.estimatorComments}
                  invalid={props.touch["estimatorComments"] && props.error["estimatorComments"] ? true : false}
                  required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "estimatorComments")}
                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "estimatorComments")}
                /> */}

                <TextEditor
                  onChange={props.handleChange}
                  labelName='Estimator comments'
                  name='estimatorComments'
                  required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "estimatorComments") ? true : false}
                  value={props.values.estimatorComments}
                  invalid={props.touch["estimatorComments"] && props.error["estimatorComments"] ? true : false}
                  setFieldTouched={props.setFieldTouched}
                  setFieldValue={props.setFieldValue}
                  onBlur={props.onBlur}
                  readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "estimatorComments") ? true : false}
                />
              </Col>
              <Col lg={4} className="mt-mb">
                <FloatInput
                  type="text"
                  name="updatedByName"
                  label="Updated by"
                  onChange={props.handleChange}
                  onBlur={props.onBlur}
                  value={props.values.updatedByName}
                  invalid={props.touch["updatedByName"] && props.error["updatedByName"] ? true : false}
                  required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "updatedByName")}
                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "updatedByName")}
                />
              </Col>
              <Col lg={4} className="mt-mb">
                <FloatInput
                  label="Estimate total"
                  type="number"
                  name="estimateTotal"
                  onChange={props.handleChange}
                  onBlur={props.onBlur}
                  value={props.values.estimateTotal}
                  invalid={props.touch["estimateTotal"] && props.error["estimateTotal"] ? true : false}
                  required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "estimateTotal")}
                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "estimateTotal")}
                />
              </Col>
              <Col lg={4} className="mt-mb">
                <FloatInput
                  type="dropdown"
                  name="taxTypeId"
                  label="Tax Type"
                  value={props.values.taxTypeId}
                  options={jobsheetTaxTypes}
                  invalid={props.touch["taxTypeId"] && props.error["taxTypeId"] ? true : false}
                  onChange={props.handleChange}
                  onBlur={props.onBlur}
                  required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "taxTypeId")}
                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "taxTypeId")}
                />
              </Col>
            </Row>

            <Row>
              <FieldArray name="partsDetails">
                {({ insert, remove, push }) => (
                  <div>
                    <Row className='mt-1'>
                      <div className='dealer-title-header font-size-15 border-bottom'>
                        <span className='pull-left'>  Add New Parts</span>
                        <div className="add-btn-container mt-0 pull-right">
                        </div>
                      </div>
                    </Row>
                    {props.values.partsDetails?.length > 0 &&
                      props.values.partsDetails.map(
                        (value: any, index: any) => {
                          return <div key={index}>
                            <Row>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  type="dropdown"
                                  name={`partsDetails[${index}].partId`}
                                  label="Part"
                                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newParts")}
                                  onChange={(e: any) => {
                                    props.handleChange(e)
                                    props.setFieldValue(`partsDetails.${index}.quantityRequired`, "")
                                    props.setFieldValue(`partsDetails.${index}.unitPrice`, "")
                                    props.setFieldValue(`partsDetails.${index}.totalPrice`, "")
                                    props.setFieldValue(`partsDetails.${index}.taxPercentage`, "")
                                    if (e.target.value) {
                                      let description = partsDropList?.filter((eachPart: any) => eachPart.id == e.target.value)?.[0]?.description
                                      props.setFieldValue(`partsDetails.${index}.partDescription`, description)
                                      dispatch(getPartDetailsForJobLoading(e.target.value, index))
                                    } else {
                                      props.setFieldValue(`partsDetails.${index}.partDescription`, "")
                                    }
                                  }}
                                  onBlur={props.onBlur}
                                  required={true}
                                  value={value?.partId}
                                  options={partsDropList}
                                  invalid={props.touch.partsDetails?.[index]?.partId && props.error.partsDetails?.[index]?.partId ? true : false}

                                />
                              </Col>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  label="Part Description"
                                  type="text"
                                  name={`partsDetails[${index}].partDescription`}
                                  onChange={props.handleChange}
                                  onBlur={props.onBlur}
                                  disabled={true}
                                  required={false}
                                  value={value?.partDescription}
                                  invalid={props.touch.partsDetails?.[index]?.partDescription && props.error.partsDetails?.[index]?.partDescription ? true : false}
                                />
                              </Col>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  label="Quantity reqd"
                                  type="number"
                                  name={`partsDetails[${index}].quantityRequired`}
                                  onChange={(e: any) => {
                                    props.handleChange(e);
                                    changeTotal(index, e.target.value, value.unitPrice, value, props.values);
                                  }}
                                  onBlur={props.onBlur}
                                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newParts")}
                                  required={true}
                                  value={value?.quantityRequired}
                                  invalid={props.touch.partsDetails?.[index]?.quantityRequired && props.error.partsDetails?.[index]?.quantityRequired ? true : false}
                                />
                              </Col>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  label="Unit Price"
                                  type="number"
                                  name={`partsDetails[${index}].unitPrice`}
                                  onChange={(e: any) => {
                                    props.handleChange(e);
                                    changeTotal(index, value.quantityRequired, e.target.value, value, props.values);
                                  }}
                                  onBlur={props.onBlur}
                                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newParts")}
                                  required={true}
                                  value={value?.unitPrice}
                                  invalid={props.touch.partsDetails?.[index]?.unitPrice && props.error.partsDetails?.[index]?.unitPrice ? true : false}
                                />
                              </Col>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  label="Part tax Percentage"
                                  type="number"
                                  name={`partsDetails[${index}].taxPercentage`}
                                  onChange={props.handleChange}
                                  onBlur={props.onBlur}
                                  disabled={true}
                                  required={false}
                                  value={value?.taxPercentage}
                                  invalid={props.touch.partsDetails?.[index]?.taxPercentage && props.error.partsDetails?.[index]?.taxPercentage ? true : false}
                                />
                              </Col>
                              <Col lg={2} className="mt-mb-textarea">
                                <div className="d-flex align-items-start">
                                  <div className="d-block w-100">
                                    <FloatInput
                                      label="Total Price"
                                      type="number"
                                      name={`partsDetails[${index}].totalPrice`}
                                      disabled={true}
                                      required={false}
                                      value={value?.totalPrice}
                                      onChange={props.handleChange}
                                      onBlur={props.onBlur}
                                      invalid={props.touch.partsDetails?.[index]?.totalPrice && props.error.partsDetails?.[index]?.totalPrice ? true : false}
                                    />
                                  </div>
                                  {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newParts") &&
                                    <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                      <TooltipMEL title="Remove part" position="left">
                                        <i className="fas fa-times-circle pointer color-red" onClick={() => {
                                          changeTotal(index, 0, 0, value, props.values);
                                          remove(index);
                                        }}></i>
                                      </TooltipMEL>
                                    </div>}
                                </div>
                              </Col>
                            </Row>
                          </div>;
                        }
                      )

                    }

                    {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newParts") &&
                      <div className="add-btn-container">
                        {
                          <div className="test" onClick={() => { handleAddPartDetails(props.values, push, props.setFieldTouched) }}>
                            <TooltipMEL title="Add parts" position='bottom'>
                              <i className="fas fa-plus-circle"></i><span>Add</span>
                            </TooltipMEL>
                          </div>
                        }
                      </div>
                    }



                    <hr className='common-bottom-color' />
                  </div>
                )}
              </FieldArray>
              <FieldArray name="taxDetails">
                {({ insert, remove, push }) => (
                  <div>

                    <Row className='mt-1'>
                      <div className='dealer-title-header font-size-15 border-bottom'>
                        <span className='pull-left'>  Add New Taxes</span>
                        <div className="add-btn-container mt-0 pull-right">
                        </div>
                      </div>
                    </Row>
                    {props.values.taxDetails?.length > 0 &&
                      props.values.taxDetails.map(
                        (value: any, index: any) => {
                          return <div key={index}>
                            <Row>
                              <Col lg={2} className="mt-mb" id='textOverflow'>
                                <FloatInput
                                  type="dropdown"
                                  name={`taxDetails[${index}].name`}
                                  label="Name"
                                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newTaxes")}
                                  onChange={(e: any) => {
                                    props.handleChange(e);
                                    dispatch(taxDetailsForJobLoading(taxesDropList.filter((eachName: any) => eachName.value == e.target.value)?.[0]?.id, index))
                                  }}
                                  onBlur={props.onBlur}
                                  required={true}
                                  value={value?.name}
                                  options={taxesDropList}
                                  invalid={props.touch.taxDetails?.[index]?.name && props.error.taxDetails?.[index]?.name ? true : false}

                                />
                              </Col>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  label="Sac Number"
                                  type="text"
                                  name={`taxDetails[${index}].sacNumber`}
                                  onChange={
                                    props.handleChange
                                  }
                                  onBlur={props.onBlur}
                                  disabled={true}
                                  required={true}
                                  value={value?.sacNumber}
                                  // value={formatDate(currentDate)} 
                                  invalid={props.touch.taxDetails?.[index]?.sacNumber && props.error.taxDetails?.[index]?.sacNumber ? true : false}
                                />
                              </Col>
                              <Col lg={2} className="mt-mb">
                                <FloatInput
                                  label="Amount"
                                  type="number"
                                  name={`taxDetails[${index}].amount`}
                                  onChange={
                                    (e: any) => {
                                      props.handleChange(e);
                                      changeTotal(-1, 0, 0, {}, props.values, e.target.value, index);
                                    }
                                  }
                                  onBlur={props.onBlur}
                                  disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newTaxes")}
                                  required={true}
                                  value={value?.amount}
                                  // value={formatDate(currentDate)} 
                                  invalid={props.touch.taxDetails?.[index]?.amount && props.error.taxDetails?.[index]?.amount ? true : false}
                                />

                              </Col>


                              <Col lg={2} className="mt-mb-textarea">
                                <div className="d-flex align-items-start">
                                  <div className="d-block w-100">
                                    <FloatInput
                                      label="Tax Percentage"
                                      type="number"
                                      name={`taxDetails[${index}].taxPercentage`}
                                      onChange={props.handleChange}
                                      onBlur={props.onBlur}
                                      disabled={true}
                                      required={true}
                                      value={value?.taxPercentage}
                                      // value={formatDate(currentDate)} 
                                      invalid={props.touch.taxDetails?.[index]?.taxPercentage && props.error.taxDetails?.[index]?.taxPercentage ? true : false}
                                    />
                                  </div>
                                  {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newTaxes") && <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                    <TooltipMEL title="Remove tax" position="left">
                                      <i className="fas fa-times-circle pointer color-red" onClick={() => {
                                        changeTotal(-1, 0, 0, {}, props.values, 0, index);
                                        remove(index);
                                      }}></i>
                                    </TooltipMEL>
                                  </div>}
                                </div>
                              </Col>
                            </Row>
                          </div>;
                        }
                      )}


                    {/*  <div className="multiadd-container"> */}
                    {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "newTaxes") && <div className="add-btn-container">
                      {<div className="test" onClick={() => { handleAddTaxDetails(props.values, push, props.setFieldTouched) }}>
                        <TooltipMEL title="Add taxes" position='bottom'>
                          <i className="fas fa-plus-circle"></i><span>Add</span>
                        </TooltipMEL>
                      </div>
                      }
                    </div>}



                    <hr className='common-bottom-color' />
                  </div>
                )}
              </FieldArray>


            </Row>
            
            {
            !CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approveStatusShowFiled") && <>  <br />  <Row>
              <div className='dealer-title-header font-size-15 border-bottom'>
                <span className='pull-left'>Approve Details </span>
                <div className="add-btn-container mt-0 pull-right">

                </div>
              </div>

              {false && <Col lg={6} className="mb-1 d-flex flex-column">
                <div className='radio-container'>
                  <div className='margin-right-10'>
                    <RadioButton inputId="ingredient1" name="approveStatus" value={APPROVE} 
                     onChange={(event) => {
                        props.handleChange(event)
                        props.values["approveStatus"] = APPROVE
                        props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
                      }}
                      checked={props.values.approveStatus == APPROVE} required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "approveStatus")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approveStatus")} />
                    <label htmlFor="ingredient1" className="margin-bottom-0">Approve</label>
                  </div>
                  <div>
                    <RadioButton inputId="ingredient2" name="approveStatus" value={REJECT} 
                        onChange={(event) => {
                            props.handleChange(event)
                            props.values["approveStatus"] = REJECT
                            props.changeFlow(props.values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
                          }}
                      checked={props.values.approveStatus == REJECT} required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "approveStatus")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approveStatus")} />
                    <label htmlFor="ingredient2" className="margin-bottom-0">Reject</label>
                  </div>
                </div>
                <ErrorMessage name="approveStatus" component={ErrorText} />
                </Col>}
              
              {
              // !CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approveStatusEnableField") && 
              <>
                <Col lg={6} className="mt-mb">
                  <FloatInput
                    type="dropdown"
                    name="approvalMode"
                    label="Approval mode"
                    options={APPROVAL_MODE}
                    onChange={props.handleChange}
                    onBlur={props.onBlur}
                    value={props.values.approvalMode}
                    invalid={props.touch["approvalMode"] && props.error["approvalMode"] ? true : false}
                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "approvalMode")}
                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approvalMode")}
                  />
                </Col>
                <Col lg={12} className="mt-mb-textarea">
                  {/* <FloatInput
                    label="Approval comments"
                    type="textarea"
                    name="approvalComments"
                    onChange={props.handleChange}
                    onBlur={props.onBlur}
                    value={props.values.approvalComments}
                    invalid={props.touch["approvalComments"] && props.error["approvalComments"] ? true : false}
                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "approvalComments")}
                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approvalComments")}
                  /> */}

                  <TextEditor
                    onChange={props.handleChange}
                    labelName='Approval comments'
                    name='approvalComments'
                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "approvalComments") ? true : false}
                    value={props.values.approvalComments}
                    invalid={props.touch["approvalComments"] && props.error["approvalComments"] ? true : false}
                    setFieldTouched={props.setFieldTouched}
                    setFieldValue={props.setFieldValue}
                    onBlur={props.onBlur}
                    readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approvalComments") ? true : false}
                  />
                        
                </Col>

                {/* new method introduce to hide the document  */}

                {
                  !CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "approveStatus_document") && <Col lg={12} className="mt-mb">
                    {
                      // props.approveDocuments?.length == 0 &&
                       <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                        onError={(err: Error) => { console.log("file error", err) }}
                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                        maxFiles={10}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} accept="image/*" />
                              <div className="">
                                <i className="display-4 text-muted bx bx-cloud-upload" />
                              </div>
                              <h4>Approve documents.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    }

                    {(props.approveDocuments[0]?.preview) && <JobsheetImagePreview selectedFiles={props.approveDocuments.length > 0 ? props.approveDocuments : []} removeIndex={deleteConfirmationHandler} />}


                    {
                      approveDocument?.length > 0 && (
                        <Row>
                          <Col lg={12} >
                            <div className='franchise_upload_document_header'>Approval Document List</div>
                            {
                              approveDocument?.map((data: any, index: number) => {
                                return (
                                  <div key={index} className='uploaded_franchise_document'>
                                    <div className='uploade_file_name'>{data.name}</div>
                                    <div className='upload_franchise_icon'>
                                      <FeatherIcon size={20} icon="eye" onClick={() => viewApprovalDocuments(data)} />
                                      {/* <FeatherIcon size={20} icon="trash-2" onClick={() => deleteFranchiseDocument(data)} /> */}
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </Col>
                        </Row>
                      )
                    }
                  </Col>
                }
              </>}
            </Row>
            </>
            }
          </div>
        </CardBody>
      </Card>
    </div>

  )
}

export default EstimateDetails;