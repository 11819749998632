import { FranchiseeType } from "./actionTypes";
// import { franchisee } from "src/models/pages/franchiseeModel";


//grid

export const getFranchiseeListLoading = (lazyState: any) => {
	return {
		type: FranchiseeType.GET_FRANCHISEE_LIST_LOADING,
		lazyState
	}
}

export const getFranchiseeListSuccess = (franchisee: any) => {
	return {
		type: FranchiseeType.GET_FRANCHISEE_LIST_SUCCESS,
		payload: franchisee
	}
}

export const getFranchiseeError = (error: any) => {
	return {
		type: FranchiseeType.GET_FRANCHISEE_LIST_ERROR,
		payload: error
	}
}

//delete

export const deleteFranchiseeLoading = (payload: any) => {
	return {
		type: FranchiseeType.DELETE_FRANCHISEE_LOADING,
		payload: payload
	}
}

export const deleteFranchiseeSucess = (response: any, undo: boolean) => {
	return {
		type: FranchiseeType.DELETE_FRANCHISEE_SUCCESS,
		payload: response,
		undo
	}
}

export const deleteFranchiseeError = (error: any) => {
	return {
		type: FranchiseeType.DELETE_FRANCHISEE_ERROR,
		payload: error
	}
}

//create Franchisee

export const createFranchiseeLoading = (franchisee: any) => {
	return {
		type: FranchiseeType.CREATE_FRANCHISEE_LOADING,
		payload: franchisee
	}
}

export const createFranchiseeSucess = (franchise: any) => {
	return {
		type: FranchiseeType.CREATE_FRANCHISEE_SUCCESS,
		payload: franchise
	}
}

export const createFranchiseeError = (error: any) => {
	return {
		type: FranchiseeType.CREATE_FRANCHISEE_ERROR,
		payload: error
	}
}

//update Franchisee

export const updateFranchiseeLoading = (franchisee: any, id: any) => {
	return {
		type: FranchiseeType.UPDATE_FRANCHISEE_LOADING,
		payload: franchisee,
		id: id
	}
}

export const updateFranchiseeSuccess = () => {
	return {
		type: FranchiseeType.UPDATE_FRANCHISEE_SUCCESS,
	}
}

export const updateFranchiseeError = (error: any) => {
	return {
		type: FranchiseeType.UPDATE_FRANCHISEE_ERROR,
		payload: error
	}
}

//getFranchisee detail by id

export const getFranchiseeByIdLoading = (id: string) => {
	return {
		type: FranchiseeType.GET_FRANCHISEEBYID_LOADING,
		payload: id
	}
}

export const getFranchiseeByIdSuccess = (userDetails: any) => {
	return {
		type: FranchiseeType.GET_FRANCHISEEBYID_SUCCESS,
		payload: userDetails,
	};
};

export const getFranchiseeByIdError = (error: any) => {
	return {
		type: FranchiseeType.GET_FRANCHISEEBYID_ERROR,
		payload: error,
	};
};

//reset

export const resetFranchisee = () => {
	return {
		type: FranchiseeType.RESET_FRANCHISEE,
	}
}

export const clearFranchiseeMessage = () => {
	return {
		type: FranchiseeType.CLEAR_FRANCHISEE_MESSAGE,
	}
}



// get Franchisee image

export const getFranchiseeImageLoading = (franchiseeId: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_FETCH_LOADING,
		payload: franchiseeId,
	};
}

export const getFranchiseeImageSuccess = (franchiseeDetails: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_FETCH_SUCCESS,
		payload: franchiseeDetails,
	}
}

export const getFranchiseeImageError = (error: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_FETCH_ERROR,
		payload: error
	}
}



export const deleteFranchiseeImageLoading = (franchiseeId: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_DELETE_LOADING,
		payload: franchiseeId,
	};
}

export const deleteFranchiseeImageSuccess = (franchiseeDetails: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_DELETE_SUCCESS,
		payload: franchiseeDetails,
	}
}

export const deleteFranchiseeImageError = (error: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_DELETE_ERROR,
		payload: error
	}
}



//  upload the file
export const uploadFranchiseeFileLoading = (file: any, id: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_UPLOAD_LOADING,
		payload: file,
		id: id
	}
}


export const uploadFranchiseeFileSuccess = (customer: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_UPLOAD_SUCCESS,
	}
}

export const uploadFranchiseeFileError = (error: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_UPLOAD_ERROR,
		payload: error
	}
}

export const ClearFranchiseeImage = () => {
	return {
		type: FranchiseeType.FRANCHISEE_FILE_CLEAR,
	}
}


//document upload in franchisee

export const uploadDocumentedinFranchiseeLoading = (document: any, id: any, fileType: string) => {
	return {
		type: FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_LOADING,
		payload: document,
		id: id,
		filetype: fileType
	}
}

export const uploadDocumentedinFranchiseeSuccess = (resp: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_SUCCESS
	}
}

export const uploadDocumentedinFranchiseeError = (error: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_ERROR,
		payload: error
	}
}

//get uploaded document

export const getuploadedeDocumentinFranchiseLoading = (id: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_LOADING,
		payload: id
	}
}
export const getuploadedeDocumentinFranchiseSuccess = (resp: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_SUCCESS,
		payload: resp
	}
}
export const getuploadedeDocumentinFranchiseError = (error: any) => {
	return {
		type: FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_ERROR,
		payload: error
	}
}

//delete uploaded document

export const deleteUploadedeDocumentLoading = (id: any) => {
	return {
		type: FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_LOADING,
		payload: id
	}
}
export const deleteUploadedeDocumentSuccess = (resp: any) => {
	return {
		type: FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_SUCCESS,
		payload: resp
	}
}
export const deleteUploadedeDocumentError = (error: any) => {
	return {
		type: FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_ERROR,
		payload: error
	}
}

//generate document link

export const generatedocumentLinkLoading=(id : any)=>{
  return {
	type : FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_LOADING,
	payload : id
  }
}
export const generatedocumentLinkSucces=(resp : any)=>{
  return {
	type : FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_SUCCESS,
	payload : resp
  }
}
export const generatedocumentLinkError=(error : any)=>{
  return {
	type : FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_ERROR,
	payload : error
  }
}

export const setFranchiseMobileNumberLoading = (number:any,id: any) => {
    return {
      type: FranchiseeType.FRANCHISE_SET_PHONE_LOADING,
      payload: {id:id,number:number},
    };
  }
  export const setFranchiseMobileNumberSuccess = (resp: any) => {
    return {
      type: FranchiseeType.FRANCHISE_SET_PHONE_SUCCESS,
      payload: resp,
    };
  }
  export const setFranchiseMobileNumberError = (error: any) => {
    return {
      type: FranchiseeType.FRANCHISE_SET_PHONE_ERROR,
      payload: error,
    };
  }
  
  export const setFranchiseEmailIdLoading = (email:any,id: any) => {
    return {
      type: FranchiseeType.FRANCHISE_SET_EMAIL_LOADING,
      payload: {id:id,email:email},
    };
  }
  export const setFranchiseEmailIdSuccess = (resp: any) => {
    return {
      type: FranchiseeType.FRANCHISE_SET_EMAIL_SUCCESS,
      payload: resp,
    };
  }
  export const setFranchiseEmailIdError = (error: any) => {
    return {
      type: FranchiseeType.FRANCHISE_SET_EMAIL_ERROR,
      payload: error,
    };
  }

  export const sendFranchiseOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
    return {
      type: FranchiseeType.SEND_OTP_FRANCHISE,
      payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
    };
  }
  export const sendFranchiseOtpSuccess = (resp: any) => {
    return {
      type: FranchiseeType.SEND_OTP_FRANCHISE_SUCCESS,
      payload: resp,
    };
  }
  export const sendFranchiseOtpError = (error: any) => {
    return {
      type: FranchiseeType.SEND_OTP_FRANCHISE_ERROR,
      payload: error,
    };
  }
  export const resendOtpFranchise = (id: any,number:any) => {
    return {
      type: FranchiseeType.RESEND_FRANCHISE_OTP,
      payload: id,
      number:number
    };
  }
  export const resendOtpFranchiseSuccess = (resp: any) => {
    return {
      type: FranchiseeType.RESEND_FRANCHISE_OTP_SUCCESS,
      payload: resp,
    };
  }
  export const resendOtpFranchiseError = (resp: any) => {
    return {
      type: FranchiseeType.RESEND_FRANCHISE_OTP_ERROR,
      payload: resp,
    };
  }

  export const resetOtpFranchiseMessage = () => {
    return {
      type: FranchiseeType.RESET_FRANCHISE_OTP_MESSAGE,
    };
  }

  export const franchiseCompanyAddressLatLagLoading = (selectedArea: string) => {
	return {
	  type: FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_LOADING,
	  payload: selectedArea
	}
  }
  export const franchiseCompanyAddressLatLagSuccess = (latLag: any, selectedArea: any) => {
	return {
	  type: FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_SUCCESS,
	  payload: { latLag, selectedArea }
	}
  }
  export const franchiseCompanyAddressLatLagError = (error: any) => {
	return {
	  type: FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_ERROR,
	  payload: error
	}
  }

  export const franchiseHomeAddressLatLagLoading = (selectedArea: string) => {
	return {
	  type: FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_LOADING,
	  payload: selectedArea
	}
  }
  export const franchiseHomeAddressLatLagSuccess = (latLag: any, selectedArea: any) => {
	return {
	  type: FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_SUCCESS,
	  payload: { latLag, selectedArea }
	}
  }
  export const franchiseHomeAddressLatLagError = (error: any) => {
	return {
	  type: FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_ERROR,
	  payload: error
	}
  }