import { IncentiveTypes } from './actionTypes';
import { incentiveListSuccess, incentiveListError, incentiveEmptyListSuccess, incentiveEmptyListError, updateIncentiveSuccess, updateIncentiveError } from './action';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from 'src/util/api';
import { IncenmtiveResponseType } from 'src/models/pages/incentiveModels';

// =============== worker function ================= ;


// ============= get incentive list api logic ===================

function* getIncentiveListAsync(action: any) {
    try {
        const response: IncenmtiveResponseType = yield call(API.getIncentiveListAPI, { ...action.lazyState })
        
        if(response.data.length==0){
            const emptyresponse: Promise<any> = yield call(API.getIncentiveEmptyListAPI)
            yield put(incentiveListSuccess(emptyresponse));
        }else{
            yield put(incentiveListSuccess(response));

        }
        
    } catch (error) {
        yield put(incentiveListError(error));
    }
}

function* updateGetIncentiveAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.UpdateIncentiveListAPI, action.payload )
        yield put(updateIncentiveSuccess(response));   
    } catch (error) {
        yield put(updateIncentiveError(error));
    }
}


function* incentiveSaga() {
    yield takeLatest(IncentiveTypes.GET_INCENTIVE_LIST_LOADING, getIncentiveListAsync);
    yield takeLatest(IncentiveTypes.UPDATE_INCENTIVE_LOADING, updateGetIncentiveAsync);
}

export default incentiveSaga ;

