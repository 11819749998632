import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { VehicleBrandsTypes } from "./actionTypes";
import { createVehicleBrandsError, createVehicleBrandsSuccess, deleteVehicleBrandImageError, deleteVehicleBrandImageSuccess, deleteVehicleBrandsFromListError, deleteVehicleBrandsFromListSuccess, getDealerNamesBYIDError, getDealerNamesBYIDSuccess, getVehicleBrandImageError, getVehicleBrandImageSuccess, getVehicleBrandsBYIDError, getVehicleBrandsBYIDSuccess, getVehicleBrandsList, getVehicleBrandsListError, getVehicleBrandsListSuccess, updateVehicleBrandsError, updateVehicleBrandsSuccess, uploadVehicleBrandFileError, uploadVehicleBrandFileSuccess } from "./action";
import { vehicleBrandData, vehicleBrandsDetails } from "src/models/pages/vehicleBrandsModel";
import { CustomerData } from "src/models/pages/customerModel";

function* getVehicleBrandsListAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.getVehicleBrandsListAPI,{
          ...action.lazyState,
        }
    )
    yield put(getVehicleBrandsListSuccess(response));
  } catch (error) {
    yield put(getVehicleBrandsListError(error));
  }
}

function* deleteVehicleBrandsAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.deleteVehicleBrandsListAPI,{id : action.payload.id ,undo: action.payload.undo}
    )
    yield put(deleteVehicleBrandsFromListSuccess(response,action.payload.undo))  
  } catch (error) {
    yield put(deleteVehicleBrandsFromListError(error));
  }
}

function* createVehicleBrandsAsync({ payload }: any) {
  try {
    const response: vehicleBrandsDetails = yield call(API.postVehicleBrandsAPI, payload);
    yield put(createVehicleBrandsSuccess(response));
    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createVehicleBrandsError(error));
  }
}

function* updateVehicleBrandsAsync({
    payload: { vehicle, vehicleId },
  }: any): Generator<any, void, any> {
    try {
      const { data } = yield call(API.updateVehicleBrandsAPI, vehicle, vehicleId);
   
      yield put(updateVehicleBrandsSuccess(data));
  
    } catch (error) {
      yield put(updateVehicleBrandsError(error));
    }
  }

  function* getVehicleBrandsByIDAsync({ payload }: any): Generator<any, void, any> {
    try {
      let { data } = yield call(API.getVehicleBrandsByIdAPI, payload);
      yield put(getVehicleBrandsBYIDSuccess(data));
  
    } catch (error) {
      yield put(getVehicleBrandsBYIDError(error));
    }
  }

  function* getDealerNamesByIDAsync({ payload }: any): Generator<any, void, any> {
    try {
      let { data } = yield call(API.getDealerNamesByIdAPI, payload);
      yield put(getDealerNamesBYIDSuccess(data));
  
    } catch (error) {
      yield put(getDealerNamesBYIDError(error));
    }
  }
  function* getVehicleBrandImageAsync({ payload }: any) {
    try {
      const response: vehicleBrandData = yield call(API.fetchVehicleBrandImage, payload)
        yield put(getVehicleBrandImageSuccess(response.data));
    } catch (error) {
      yield put(getVehicleBrandImageError(error));
    }
  }
  
  function* deleteVehicleBrandImageAsync({ payload }: any) {
    try {
      const response: vehicleBrandData = yield call(API.deleteVehicleBrandImage, payload)
      yield put(deleteVehicleBrandImageSuccess(response.data));
    } catch (error) {
      yield put(deleteVehicleBrandImageError(error));
    }
  }
  
  //update VehicleBrand
  function* uploadVehicleBrandFileAsync(action: any) {
    try {
      const response: Promise<any>= yield call(API.uploadVehicleBrandImage, action.payload, { id: action.id });
        yield put(uploadVehicleBrandFileSuccess(response))
    } catch (error) {
      yield put(uploadVehicleBrandFileError(error))
    }
  }

function* vehicleBrandsSaga() {
  yield takeLatest(VehicleBrandsTypes.GET_VEHICLE_BRANDS_LIST, getVehicleBrandsListAsync);
  yield takeLatest(VehicleBrandsTypes.DELETE_VEHICLE_BRANDS, deleteVehicleBrandsAsync);
  yield takeLatest(VehicleBrandsTypes.POST_VEHICLE_BRANDS, createVehicleBrandsAsync);
  yield takeLatest(VehicleBrandsTypes.UPDATE_VEHICLE_BRANDS, updateVehicleBrandsAsync);
  yield takeLatest(VehicleBrandsTypes.GET_VEHICLE_BRANDS_BY_ID, getVehicleBrandsByIDAsync);
  yield takeLatest(VehicleBrandsTypes.GET_DEALER_NAMES_BY_ID, getDealerNamesByIDAsync);
  yield takeLatest(VehicleBrandsTypes.VEHICLE_BRAND_FILE_UPLOAD_LOADING, uploadVehicleBrandFileAsync);
  yield takeLatest(VehicleBrandsTypes.VEHICLE_BRAND_FILE_FETCH_LOADING, getVehicleBrandImageAsync);
  yield takeLatest(VehicleBrandsTypes.VEHICLE_BRAND_FILE_DELETE_LOADING, deleteVehicleBrandImageAsync);
}

export default vehicleBrandsSaga;