import { stockRegisterInitialState } from "src/models/pages/stockRegisterModel";
import { StockRegisterTypes } from "./actionTypes";

const initialState: stockRegisterInitialState = {
  error: "",
  loading: false,
  message: "",
  stockRegister: {
    totalRecords : 0,
    values : []
  }
};

const StockRegister = (state = initialState, action: any) => {
  switch (action.type) {
    // Grid

    case StockRegisterTypes.GET_STOCK_REGISTER_LIST:
      return {
        ...state,
        loading: true,
      };

    case StockRegisterTypes.GET_STOCK_REGISTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stockRegister: {
          ...state.stockRegister,
          values: action.payload.data,
          totalRecords: action.payload.data.totalCount,
        }
      };

    case StockRegisterTypes.GET_STOCK_REGISTER_LIST_FAIL:
      return {
        ...state,
        loading: false,

      };

    case StockRegisterTypes.GET_STOCK_REGISTER_BY_ID_SUCCESS:
      return {
        ...state,
        editOrder: action.payload.data

      }

    default:
      return { ...state };
  }
};

export default StockRegister;