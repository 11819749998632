import { StockAdjustmentTypes } from "./actionTypes";

export const getStockAdjustmentList = (lazyState: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST,
    lazyState,
  };
};

export const getStockAdjustmentById = (id: any) => {
  
  
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_ID,
    id:id,
  }; 
};
export const getStockAdjustmentByIdSuccess = (payload: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS,
    payload:payload,
  }; 
};


// -------------------------------
export const getStockAdjustmentByPartId = (id: any) => {
  
  
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID,
    id:id,
  }; 
};
export const getStockAdjustmentByPartIdSuccess = (payload: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID_SUCCESS,
    payload:payload,
  }; 
};

export const getStockAdjustmentByPartIdError = (error: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID_FAIL,
    payload: error,
  };
};

// ----------------

export const getStockAdjustmentListSuccess = (payload: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST_SUCCESS,
    payload: payload,
  };
};

export const getStockAdjustmentListError = (error: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST_FAIL,
    payload: error,
  };
};
export const getStockAdjustmentByIdError = (error: any) => {
  return {
    type: StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_ID_FAIL,
    payload: error,
  };
};


export const deleteStockAdjustmentFromList = (payload:any) => {
  return {
    type: StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT,
    payload: payload,
  }
}

export const deleteStockAdjustmentFromListSuccess = (response:any) => {
  return {
    type: StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT_SUCCESS,
    payload: response,
  }
}

export const deleteStockAdjustmentFromListError = (error:any) => {
  return {
    type: StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT_FAIL,
    payload: error,
  }
}

export const updateStockAdjustment=(StockAdjustmentDetails :any )=>{
  
  
  return {
    type : StockAdjustmentTypes.UPDATE_STOCK_ADJUSTMENT,
    payload : StockAdjustmentDetails
  }
}

export const updateStockAdjustmentSuccess=(data:any)=>{
  return {
    type : StockAdjustmentTypes.UPDATE_STOCK_ADJUSTMENT_SUCCESS
  }
}

export const updateStockAdjustmentError=(error : any)=>{
  return {
    type : StockAdjustmentTypes.UPDATE_STOCK_ADJUSTMENT_ERROR,
    payload : error
  }
}
export const createStockAdjustment=(data :any )=>{  
  return {
    type : StockAdjustmentTypes.CREATE_STOCK_ADJUSTMENT,
    payload : data
  }
}
export const createStockAdjustmentSuccess=(data:any)=>{
  return {
    type : StockAdjustmentTypes.CREATE_STOCK_ADJUSTMENT_SUCCESS
  }
}

export const createStockAdjustmentError=(error : any)=>{
  return {
    type : StockAdjustmentTypes.CREATE_STOCK_ADJUSTMENT_ERROR,
    payload : error
  }
}

//reset StockAdjustment
export const StockAdjustmentReset=()=>{
  return {
    type : StockAdjustmentTypes .RESET_STOCK_ADJUSTMENT,
  }
}

export const getPartsSkuListforStock = (lazyState: any) => {
  
  
  return {
    type: StockAdjustmentTypes.PARTS_SKU_API_CALL,
    lazyState,
  };
};

export const getPartsSkuListSuccessforStock = (payload: any) => {
  return {
    type: StockAdjustmentTypes.PARTS_SKU_API_CALL_SUCCESS,
    payload: payload,
  };
};

export const getPartsSkuListErrorforStock = (error: any) => {
  
  
  return {
    type: StockAdjustmentTypes.PARTS_SKU_API_CALL_ERROR,
    payload: error,
  };
};

export const getSupplierDropdown=()=>{
  
  
  return {
    type : StockAdjustmentTypes.GET_SUPPLIER_DROPDOWN,
  }
}

export const getSupplierDropdownSuccess=(payload:any)=>{
  
  
  return {
    type : StockAdjustmentTypes.GET_SUPPLIER_DROPDOWN_SUCCESS,
    payload:payload
  }
}
export const getSupplierDropdownError=(err:any)=>{
  return {
    type : StockAdjustmentTypes.GET_SUPPLIER_DROPDOWN_ERROR,
    payload:err
  }
}

export const resetStockAdjustemntMessage=()=>{
  return{
    type:StockAdjustmentTypes.RESET_MESSAGE
  }
}

export const getInitiatedByDropdown=()=>{
  
  return {
    type : StockAdjustmentTypes.GET_INITIATED_BY_DROPDOWN,
  }
}

export const getInitiatedByDropdownSuccess=(payload:any)=>{
  
  return {
    type : StockAdjustmentTypes.GET_INITIATED_BY_DROPDOWN_SUCCESS,
    payload:payload
  }
}
export const getInitiatedByDropdownError=(err:any)=>{
  return {
    type : StockAdjustmentTypes.GET_INITIATED_BY_DROPDOWN_ERROR,
    payload:err
  }
}

//  sent mail

export const sentMailToSupplierAdjustment = (id:any)=>{
  
  return {
    type : StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT,
    payload : id
  }
}
export const sentMailToSupplierAdjustmentSuccess = (data :any )=>{  
  return {
    type : StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT_SUCCESS,
    payload : data
  }
}

export const sentMailToSupplierAdjustmentError = (error : any)=>{
  return {
    type : StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT_FAIL,
    payload : error
  }
}

// DOWNLOAD STOCK ADJUSTMENT

export const downloadStockAdjustment = (id:any)=>{
  
  return {
    type : StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT,
    payload : id
  }
}
export const downloadStockAdjustmentSuccess = (data :any )=>{  
  return {
    type : StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT_SUCCESS,
    payload : data
  }
}

export const downloadStockAdjustmentError = (error : any)=>{
  return {
    type : StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT_FAIL,
    payload : error
  }
}

//get courier details

export const getCourierDropdownLoading=()=>{
  return {
    type : StockAdjustmentTypes.GET_COURIER_DROPDOWN_LOADING,
  }
}

export const getCourierDropdownSuccess=(resp : any)=>{
  return {
    type : StockAdjustmentTypes.GET_COURIER_DROPDOWN_SUCCESS,
    payload : resp
  }
}
export const getCourierDropdownError=(error : any)=>{
  return {
    type : StockAdjustmentTypes.GET_COURIER_DROPDOWN_ERROR,
    payload : error
  }
}
export const resetPartSkuIndex=()=>{
  return {
    type : StockAdjustmentTypes.REASET_PARTSKU_INDEX,
  }
}

export const deletePartsSkuList=(index : any)=>{
  return {
    type : StockAdjustmentTypes.DELETE_PATS_SKU_LIST,
    payload : index
  }
}
