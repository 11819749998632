import React from 'react'
import { Col, Row } from 'reactstrap'
import FeatherIcon from "feather-icons-react";
import "./JobsheetImagePreviewer.scss"

interface ImagePreviewerModel {
    selectedFiles: [];
    removeIndex: any
}

const JobsheetImagePreview = ({ selectedFiles, removeIndex }: ImagePreviewerModel) => {
    
    return (
        <div className="dropzone-previews " id="file-previews_jobsheet">
            <div
                className="mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                key={"file"}
            >
                <div className="p-2">
                    <Row className="align-items-center">
                        {
                            selectedFiles?.length > 0 ? selectedFiles?.map((data: any, index: number) => {
                                return (
                                    <Col key={index} className="col-auto  img-container">
                                        <>
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light image-size"
                                                src={data?.preview}
                                            />
                                            <div className="img-del-icon">
                                                <FeatherIcon onClick={()=>removeIndex(data)} icon="trash-2" />
                                            </div>
                                        </>
                                    </Col>
                                )
                            }) : []
                        }


                    </Row>
                </div>
            </div>
        </div>
    )
}

export default JobsheetImagePreview