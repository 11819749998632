import { customersInitialState } from "src/models/pages/customerModel";
import { CustomerTypes } from "./actionTypes";

const initialState: customersInitialState = {
  error: "",
  loading: false,
  message: "",
  paginationCount: 0,
  customerList: {
    values: [],
    totalRecords: 0,
  },
  customerTaxList: [],
  customerDetails: {
    taxTypeId: 0,
    alternateNumber: "",
    user: undefined,
    isDeleted: false,
    deleterId: undefined,
    deletionTime: undefined,
    creationTime: "",
    creatorId: "",
    id: 0
  },
  customerImage: "",  
  customerId : null,
  emailSuccessMessage:"",
  phoneNumberSuccessMessage:"",
  createdData:"",
  otpmessage:"",
  resendOtp:"",
  editedmobileNumber:"",
  updatedmessage:"",
  customerLatLng:""
};


export const customerImageDeleteSuccess = "Customer image is deleted successfully";

const Customer = (state = initialState, action: any) => {
  switch (action.type) {
    case CustomerTypes.GET_CUSTOMERS_LIST:
    case CustomerTypes.GET_CUSTOMERBYID_LOADING:
    case CustomerTypes.CREATE_CUSTOMER_LOADING:
    case CustomerTypes.UPDATE_CUSTOMER_LOADING:
    case CustomerTypes.CUSTOMER_TAX_TYPE_LIST_LOADING:
    case CustomerTypes.CUSTOMER_FILE_UPLOAD_LOADING:
    case CustomerTypes.CUSTOMER_FILE_FETCH_LOADING:
    case CustomerTypes.CUSTOMER_FILE_DELETE_LOADING:
    case CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_LOADING:
    case CustomerTypes.CUSTOMER_SET_EMAIL_LOADING:
    case CustomerTypes.CUSTOMER_SET_PHONE_LOADING:
    case CustomerTypes.SEND_OTP_CUSTOMER:
    case CustomerTypes.GET_CUSTOMER_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        paginationCount: 0
      };
    case CustomerTypes.CUSTOMER_FILE_CLEAR:
      return {
        ...state,
        customerImage: ""
      }
    case CustomerTypes.GET_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customerList: {
          ...state.customerList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginationCount: action.payload.data.items.length
      };

    case CustomerTypes.GET_CUSTOMERBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        customerDetails: action.payload,
        error: "",
        emailSuccessMessage:"",
        phoneNumberSuccessMessage:""
      }
    case CustomerTypes.GET_CUSTOMER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        customerLatLng:action.payload.latLag.data
      }

    case CustomerTypes.CREATE_CUSTOMER_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        error: "",
        message: "Customer Created Successfully",
        createdData:action.payload.data,
      };

    case CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        customerId : action.payload.data
      };

    case CustomerTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        updatedmessage: "Customer Updated Successfully"
      };

    case CustomerTypes.CLEAR_CUSTOMER_MESSAGE:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        updatedmessage:""
      };

    case CustomerTypes.CUSTOMER_FILE_FETCH_SUCCESS:

      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        customerImage: action.payload,
      };

    case CustomerTypes.CUSTOMER_FILE_DELETE_SUCCESS:

      return {
        ...state,
        loading: false,
        error: "",
        message: customerImageDeleteSuccess,
        customerImage: "",
      };

    case CustomerTypes.RESET_CUSTOMER:
      return {
        ...state,
        error: "",
  loading: false,
  message: "",
  paginationCount: 0,
  customerList: {
    values: [],
    totalRecords: 0,
  },
  customerTaxList: [],
  customerDetails: {
    taxTypeId: 0,
    alternateNumber: "",
    user: undefined,
    isDeleted: false,
    deleterId: undefined,
    deletionTime: undefined,
    creationTime: "",
    creatorId: "",
    id: 0
  },
  customerImage: "",  
  customerLatLng:""
      }
    case CustomerTypes.DELETE_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case CustomerTypes.DELETE_CUSTOMERS_SUCCESS:

      return {
        ...state,
        loading: false,
        error: "",
        message: action.undo ? "Customer Restored Successfully" : "Customer Deleted Successfully",

      };

    case CustomerTypes.CUSTOMER_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };


    case CustomerTypes.CUSTOMER_TAX_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        customerTaxList: action.payload.data.taxList
      };
    case CustomerTypes.CUSTOMER_SET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        emailSuccessMessage:"Email Updated Successfully"
      };
    case CustomerTypes.CUSTOMER_SET_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        phoneNumberSuccessMessage:"Otp Sent Successfully",
        editedmobileNumber:action.payload.number
      };
    case CustomerTypes.SEND_OTP_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        otpmessage: "OTP Verified Successfully",
        editedmobileNumber:""
      };
    case CustomerTypes.RESEND_CUSTOMER_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        editedmobileNumber:action.payload.number,
        phoneNumberSuccessMessage: "OTP sent successfully",

      };
    case CustomerTypes.RESET_CUSTOMER_OTP_MESSAGE:
      return {
        ...state,
        loading: false,
        error: "",
        phoneNumberSuccessMessage: "",
        otpmessage:"",
        emailSuccessMessage:"",
        createdData :"",
        editedmobileNumber:"",
        updatedmessage:""
      };

    case CustomerTypes.GET_CUSTOMERS_LIST_FAIL:
    case CustomerTypes.GET_CUSTOMERBYID_ERROR:
    case CustomerTypes.CREATE_CUSTOMER_ERROR:
    case CustomerTypes.UPDATE_CUSTOMER_ERROR:
    case CustomerTypes.DELETE_CUSTOMERS_FAIL:
    case CustomerTypes.CUSTOMER_TAX_TYPE_LIST_ERROR:
    case CustomerTypes.CUSTOMER_FILE_UPLOAD_ERROR:
    case CustomerTypes.CUSTOMER_FILE_FETCH_ERROR:
    case CustomerTypes.CUSTOMER_FILE_DELETE_ERROR:
    case CustomerTypes.CUSTOMER_REDIRECT_WITH_JOB_SHEET_ERROR:
    case CustomerTypes.CUSTOMER_SET_EMAIL_ERROR:
    case CustomerTypes.CUSTOMER_SET_PHONE_ERROR:
    case CustomerTypes.SEND_OTP_CUSTOMER_ERROR:
    case CustomerTypes.GET_CUSTOMER_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };




    default:
      return { ...state };
  }
};

export default Customer;
