import { AdditionalExpenseReportsListModel } from "src/models/pages/additionalExpenseReportsModel"
import { AddtionalExpensesReports } from "./actionTypes"

export const additionalExpenseReportsListLoading = (lazyState: any) => {
  return {
    type: AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_LOADING,
    lazyState,
  }
}

export const additionalExpenseReportsListSucces = (resp: AdditionalExpenseReportsListModel) => {
  return {
    type: AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_SUCCESS,
    payload: resp
  }
}

export const additionalExpenseReportsListError = (error: any) => {
  return {
    type: AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_ERROR,
    payload: error
  }
}

export const additionalExpenseReportsListByIdLoading = (id: any) => {
  return {
      type: AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_LOADING,
      id:id,
  }
}

export const additionalExpenseReportsListByIdSuccess = (response: any) => {
  return {
      type: AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_SUCCESS,
      payload: response
  }
}
export const additionalExpenseReportsListByIdError = (error: any) => {
  return {
      type: AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_FAIL,
      payload: error
  }
}

export const updatePaymentLoading = (paymentDetail: any,id:any) => {
  return {
      type: AddtionalExpensesReports.UPDATE_PAYMENT_LOADING,
      payload:paymentDetail,
      id:id
  }
}

export const updatePaymentSuccess = (response: any) => {
  return {
      type: AddtionalExpensesReports.UPDATE_PAYMENT_SUCCESS,
      payload: response
  }
}
export const updatePaymentError = (error: any) => {
  return {
      type: AddtionalExpensesReports.UPDATE_PAYMENT_FAIL,
      payload: error
  }
}
export const updateAdjustmentPointLoading = (payload: any,id:any) => {
  return {
      type: AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_LOADING,
      payload:payload,
      id:id
  }
}

export const updateAdjustmentPointSuccess = (response: any) => {
  return {
      type: AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_SUCCESS,
      payload: response
  }
}
export const updateAdjustmentPointError = (error: any) => {
  return {
      type: AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_FAIL,
      payload: error
  }
}
