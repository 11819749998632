import { ContactDetail, DealerCreateModel, DealerDetails } from '../../models/pages/dealerModel';
import { DealersModal } from "src/models/pages/dealerModel";
import { IntentActionTypes } from "./actionTypes";
import { IntentDetailById, IntentList, IntentPost } from 'src/models/pages/intentModel';




// get Intent by id

export const getIntentDetail = (id: string) => {
  return { type: IntentActionTypes.GET_INTENT_BY_ID_LOADING, payload: id };
};

export const getIntentDetailSuccess = (intent: IntentDetailById) => {
  return { type: IntentActionTypes.GET_INTENT_BY_ID_SUCCESS, payload: intent };
};

export const getIntentDetailError = (error: any) => {
    return { type: IntentActionTypes.GET_INTENT_BY_ID_ERROR, payload: error };
  };





// update Dealer
export const updateIntent = (intent: IntentPost, id: string = "") => {
  return { type: IntentActionTypes.UPDATE_INTENT_LOADING, payload: { intent, id } };
};

export const updateIntentSuccess = (intent: IntentPost) => {
  return { type: IntentActionTypes.UPDATE_INTENT_SUCCESS, payload: intent };
};

export const updateIntentError = (error: any) => {
  return { type: IntentActionTypes.UPDATE_INTENT_ERROR, payload: error };
};


// post Dealer
export const createIntent = (intent: IntentPost) => {
  return { type: IntentActionTypes.CREATE_INTENT_LOADING, payload: intent };
};

export const createIntentSuccess = (intent: IntentPost) => {
  return { type: IntentActionTypes.CREATE_INTENT_SUCCESS, payload: intent };
};

export const createIntentError = (error: any) => {
  return { type: IntentActionTypes.CREATE_INTENT_ERROR, payload: error };
};

//  delete

export const deleteIntentList = (payload:any) => {
    return {
      type: IntentActionTypes.DELETE_INTENT_LIST_LOADING,
      payload: payload,
    }
  }
  
  export const deleteIntentListSuccess = (response:any) => {
    return {
      type: IntentActionTypes.DELETE_INTENT_LIST_SUCCESS,
      payload: response,
    }
  }
  
  export const deleteIntentListError = (error:any) => {
    return {
      type: IntentActionTypes.DELETE_INTENT_LIST_ERROR,
      payload: error,
    }
  }



// For Grid

// get Intent list

export const getIntentList = (lazyState: any) => {
    return { type: IntentActionTypes.GET_INTENT_LIST_LOADING, lazyState, };
  };
  
  export const getIntentListSuccess = (intents: any) => {
    return { type: IntentActionTypes.GET_INTENT_LIST_SUCCESS, payload: intents };
  };
  
  export const getIntentListError = (error: any) => {
    return { type: IntentActionTypes.GET_INTENT_LIST_FAIL, payload: error };
  };
  


// Dealer Tax Type

export const franchiseListforIntent=()=>{
    return {
      type : IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_LOADING,
    }
  }
  
  export const franchiseListforIntentSuccess=(franchiseList : any)=>{
    return {
      type : IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_SUCCESS,
      payload : franchiseList
    }
  }
  
  export const franchiseListforIntentError=(error : any)=>{
    return {
      type : IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_ERROR,
      payload : error
    }
  }


  //  Parts autocomplete

export const partsAutoCompleteForIntentList = (params: any) => {
  return {
    type: IntentActionTypes.GET_PART_LIST_FOR_INTENT_LOADING,
    params,
  };
};


export const partsAutoCompleteForIntentListSuccess = (payload: any) => {
  return {
    type: IntentActionTypes.GET_PART_LIST_FOR_INTENT_SUCCESS,
    payload: payload,
  };
};

export const partsAutoCompleteForIntentListError = (error: any) => {
  return {
    type: IntentActionTypes.GET_PART_LIST_FOR_INTENT_ERROR,
    payload: error,
  };
};


export const approveIntent = (params: any) => {
  return {
    type: IntentActionTypes.APPROVE_INTENT_LOADING,
    params,
  };
};


export const approveIntentSuccess = (payload: any) => {
  return {
    type: IntentActionTypes.APPROVE_INTENT_SUCCESS,
    payload: payload,
  };
};

export const approveIntentError = (error: any) => {
  return {
    type: IntentActionTypes.APPROVE_INTENT_ERROR,
    payload: error,
  };
};



export const rejectIntent = (params: any) => {
  return {
    type: IntentActionTypes.REJECT_INTENT_LOADING,
    params,
  };
};


export const rejectIntentSuccess = (payload: any) => {
  return {
    type: IntentActionTypes.REJECT_INTENT_SUCCESS,
    payload: payload,
  };
};

export const rejectIntentError = (error: any) => {
  return {
    type: IntentActionTypes.REJECT_INTENT_ERROR,
    payload: error,
  };
};


export const dispatchIntent = (params: any) => {
  return {
    type: IntentActionTypes.DISPATCH_INTENT_LOADING,
    params,
  };
};


export const dispatchIntentSuccess = (payload: any) => {
  return {
    type: IntentActionTypes.DISPATCH_INTENT_SUCCESS,
    payload: payload,
  };
};

export const dispatchIntentError = (error: any) => {
  return {
    type: IntentActionTypes.DISPATCH_INTENT_ERROR,
    payload: error,
  };
};

export const receiveIntent = (params: any) => {
  return {
    type: IntentActionTypes.RECEIVE_INTENT_LOADING,
    params,
  };
};


export const receiveIntentSuccess = (payload: any) => {
  return {
    type: IntentActionTypes.RECEIVE_INTENT_SUCCESS,
    payload: payload,
  };
};

export const receiveIntentError = (error: any) => {
  return {
    type: IntentActionTypes.RECEIVE_INTENT_ERROR,
    payload: error,
  };
};





export const clearIntentMessageData = ()=>{
  return {
    type: IntentActionTypes.CLEAR_INTENT_MESSAGE,
    
  }
}


export const resetIntentValue = ()=>{
  return{
    type: IntentActionTypes.RESET_INTENT_VALUE,
  }
}


//get courierList dropdown

export const getIntentCourierListDropdownLoading=()=>{
  return {
    type : IntentActionTypes.GET_INTENT_COURIER_LIST_LOADING
  }
}

export const getIntentCourierListDropdownSuccess=(courierResponse : any)=>{
  return {
    type : IntentActionTypes.GET_INTENT_COURIER_LIST_SUCCESS,
    payload : courierResponse
  }
}

export const getIntentCourierListDropdownError=(error : any)=>{
  return {
    type : IntentActionTypes.GET_INTENT_COURIER_LIST_ERROR,
    payload : error
  }
}

//get intent delieverd person dropdown

export const getIntentDeliverablePersonDropdownLoading=()=>{
  return {
    type : IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_LOADING
  }
}
export const getIntentDeliverablePersonDropdownSuccess=(deliverablePerson : any)=>{
  return {
    type : IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_SUCCESS,
    payload : deliverablePerson
  }
}
export const getIntentDeliverablePersonDropdownError=(error : any)=>{
  return {
    type : IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_ERROR,
    payload : error
  }
}


//post deliverable courier intent

export const postDeliverableCourierIntentLoading=(postIntentDetails : any,id:any)=>{
  return {
    type : IntentActionTypes.POST_COURIER_INTENT_LOADING,
    payload : postIntentDetails,
    id
  }
}

export const postDeliverableCourierIntentSuccess=(resp : any)=>{
  return {
    type : IntentActionTypes.POST_COURIER_INTENT_SUCCESS,
    payload : resp
  }
}
export const postDeliverableCourierIntentError=(error : any)=>{
  return {
    type : IntentActionTypes.POST_COURIER_INTENT_ERROR,
    payload : error
  }
}
export const partSkuValidate=(payload : any)=>{
  return {
    type : IntentActionTypes.PARTS_VALIDATE,
    payload : payload
  }
}
export const partSkuValidateSuccess=(resp : any)=>{
  return {
    type : IntentActionTypes.PARTS_VALIDATE_SUCCESS,
    payload : resp
  }
}
export const partSkuValidateError=(resp : any)=>{
  return {
    type : IntentActionTypes.PARTS_VALIDATE_ERROR,
    payload : resp
  }
}

export const printIntendLoading=(payload : any)=>{
  return {
    type : IntentActionTypes.PRINT_INTEND,
    payload : payload
  }
}
export const printIntendSuccess=(resp : any)=>{
  return {
    type : IntentActionTypes.PRINT_INTEND_SUCCESS,
    payload : resp
  }
}
export const printIntendError=(resp : any)=>{
  return {
    type : IntentActionTypes.PRINT_INTEND_ERROR,
    payload : resp
  }
}

export const emailIntendLoading=(payload : any)=>{
  return {
    type : IntentActionTypes.EMAIL_INTEND,
    payload : payload
  }
}
export const emailIntendSuccess=(resp : any)=>{
  return {
    type : IntentActionTypes.EMAIL_INTEND_SUCCESS,
    payload : resp
  }
}
export const emailIntendError=(resp : any)=>{
  return {
    type : IntentActionTypes.EMAIL_INTEND_ERROR,
    payload : resp
  }
}







