export enum UserTypes {
  GET_USERLOADING = "GET_USERLOADING",
  GET_USERSUCCESS = "GET_USERSUCCESS",
  GET_USERERROR = "GET_USERERROR",
  // Grid
  GET_USERS_LIST = "@@user/GET_USERS_LIST",
  GET_USERS_LIST_SUCCESS = "@@user/GET_USERS_LIST_SUCCESS",
  GET_USERS_LIST_FAIL = "@@user/GET_USERS_LIST_FAIL",

  DELETE_USER = "@@user/DELETE_USER",
  DELETE_USER_SUCCESS = "@@user/DELETE_USER_SUCCESS",
  DELETE_USER_FAIL = "@@user/DELETE_USER_FAIL",
  //reset
  RESET_USER = "RESET_USER",
  //create user
  CREATE_USER_LOADING = "CREATE_USER_LOADING",
  CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR = "CREATE_USER_ERROR",

  //update user
  UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",

  // clear user
  CLEAR_USER_DATA = "@@user/CLEAR_USER_DATA",


  // Dealer image

  EMPLOYEE_FILE_UPLOAD_LOADING = "@@user/EMPLOYEE_FILE_UPLOAD_LOADING",
  EMPLOYEE_FILE_UPLOAD_SUCCESS = "@@user/EMPLOYEE_FILE_UPLOAD_SUCCESS",
  EMPLOYEE_FILE_UPLOAD_ERROR = "@@user/EMPLOYEE_FILE_UPLOAD_ERROR",

  EMPLOYEE_FILE_FETCH_LOADING = "@@user/EMPLOYEE_FILE_FETCH_LOADING",
  EMPLOYEE_FILE_FETCH_SUCCESS = "@@user/EMPLOYEE_FILE_FETCH_SUCCESS",
  EMPLOYEE_FILE_FETCH_ERROR = "@@user/EMPLOYEE_FILE_FETCH_ERROR",

  EMPLOYEE_FILE_DELETE_LOADING = "@@user/EMPLOYEE_FILE_DELETE_LOADING",
  EMPLOYEE_FILE_DELETE_SUCCESS = "@@user/EMPLOYEE_FILE_DELETE_SUCCESS",
  EMPLOYEE_FILE_DELETE_ERROR = "@@user/EMPLOYEE_FILE_DELETE_ERROR",

  EMPLOYEE_FILE_CLEAR = "@@user/EMPLOYEE_FILE_CLEAR",

  //document upload

  EMPLOYEE_DOCUMENT_UPLOAD_LOADING = "@@user/EMPLOYEE_DOCUMENT_UPLOAD_LOADING",
  EMPLOYEE_DOCUMENT_UPLOAD_SUCCESS = "@@user/EMPLOYEE_DOCUMENT_UPLOAD_SUCCESS",
  EMPLOYEE_DOCUMENT_UPLOAD_ERROR = "@@user/EMPLOYEE_DOCUMENT_UPLOAD_ERROR",


  //get_documented list

  EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING = "@@user/EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING",
  EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS= "@@user/EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS",
  EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR = "@@user/EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR",

  //delete document 

  EMPLOYEE_DELETE_DOCUMENT_LOADING = "@@user/EMPLOYEE_DELETE_DOCUMENT_LOADING",
  EMPLOYEE_DELETE_DOCUMENT_SUCCESS = "@@user/EMPLOYEE_DELETE_DOCUMENT_SUCCESS",
  EMPLOYEE_DELETE_DOCUMENT_ERROR = "@@user/EMPLOYEE_DELETE_DOCUMENT_ERROR",

  //generate link

  EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING = "@@user/EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING",
  EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS = "@@user/EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS",
  EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR = "@@user/EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR",

  //link reset

  EMPLOYEE_DOCUMENTED_LINK_RESET = "@@user/EMPLOYEE_DOCUMENTED_LINK_RESET",

  EMPLOYEE_SET_PHONE_LOADING="@@user/EMPLOYEE_SET_PHONE_LOADING",
  EMPLOYEE_SET_PHONE_SUCCESS="@@user/EMPLOYEE_SET_PHONE_SUCCESS",
  EMPLOYEE_SET_PHONE_ERROR="@@user/EMPLOYEE_SET_PHONE_ERROR",

  EMPLOYEE_SET_EMAIL_LOADING="@@user/EMPLOYEE_SET_EMAIL_LOADING",
  EMPLOYEE_SET_EMAIL_SUCCESS="@@user/EMPLOYEE_SET_EMAIL_SUCCESS",
  EMPLOYEE_SET_EMAIL_ERROR="@@user/EMPLOYEE_SET_EMAIL_ERROR",

  SEND_OTP_EMPLOYEE ="@@user/SEND_OTP_EMPLOYEE",
  SEND_OTP_EMPLOYEE_SUCCESS ="@@user/SEND_OTP_EMPLOYEE_SUCCESS",
  SEND_OTP_EMPLOYEE_ERROR ="@@user/SEND_OTP_EMPLOYEE_ERROR",

  RESEND_EMPLOYEE_OTP = "@@user/RESEND_EMPLOYEE_OTP",
  RESEND_EMPLOYEE_OTP_SUCCESS = "@@user/RESEND_EMPLOYEE_OTP_SUCCESS",
  RESEND_EMPLOYEE_OTP_ERROR = "@@user/RESEND_EMPLOYEE_OTP_ERROR",
  
  RESET_EMPLOYEE_OTP_MESSAGE = "@@user/RESET_EMPLOYEE_OTP_MESSAGE",

  GET_EMPLOYEE_LOCATION_LOADING="@@user/GET_EMPLOYEE_LOCATION_LOADING",
  GET_EMPLOYEE_LOCATION_SUCCESS="@@user/GET_EMPLOYEE_LOCATION_SUCCESS",
  GET_EMPLOYEE_LOCATION_ERROR="@@user/GET_EMPLOYEE_LOCATION_ERROR",
}
