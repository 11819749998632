import { StockQtybyPartTypes } from "./actionTypes";



// For Grid

export const getStockQtybyPartsList = (lazyState: any) => {
  return {
    type: StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST,
    lazyState,
  };
};



export const getStockQtybyPartListSuccess = (stockQtybyPart: any) => {
  return {
    type: StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST_SUCCESS,
    payload: stockQtybyPart,
  };
};

export const getStockQtybyPartListError = (error: any) => {
  return {
    type: StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST_FAIL,
    payload: error,
  };
};

export const resetStockQtybyPartMessage = ()=>{
    return { type: StockQtybyPartTypes.RESET_STOCK_QTY_BY_PART_LIST};
  }

  
  export const getPartsDropdownListQty = () => {
    return {
      type: StockQtybyPartTypes.GET_PART_LIST_LOADING,
    };
  };
  
  export const getPartsDropdownListSuccessQty = (resp: any) => {
    return {
      type: StockQtybyPartTypes.GET_PART_LIST_SUCCESS,
      payload: resp,
    };
  };
  
  export const getPartsDropdownListErrorQty = (error: any) => {
    return {
      type: StockQtybyPartTypes.GET_PART_LIST_FAIL,
      payload: error,
    };
  };