import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { StockAdjustmentTypes } from "./actionTypes";
import { createStockAdjustmentError, createStockAdjustmentSuccess, deleteStockAdjustmentFromListError, deleteStockAdjustmentFromListSuccess, downloadStockAdjustmentError, downloadStockAdjustmentSuccess, getCourierDropdownError, getCourierDropdownSuccess, getInitiatedByDropdownError, getInitiatedByDropdownSuccess, getPartsSkuListErrorforStock,  getPartsSkuListSuccessforStock, getStockAdjustmentByIdError, getStockAdjustmentByIdSuccess, getStockAdjustmentByPartIdError, getStockAdjustmentByPartIdSuccess, getStockAdjustmentList, getStockAdjustmentListError, getStockAdjustmentListSuccess, getSupplierDropdownError, getSupplierDropdownSuccess, sentMailToSupplierAdjustmentError, sentMailToSupplierAdjustmentSuccess, updateStockAdjustmentError, updateStockAdjustmentSuccess } from "./actions";



function* getStockAdjustmentListAsync(action : any) {


  try {
    const response : Promise<any> = yield call(

        API.getStockAdjustmentListAPI,{...action.lazyState}
    )
    yield put(getStockAdjustmentListSuccess(response));
  } catch (error) {
    yield put(getStockAdjustmentListError(error));
  }
}


function* getStockAdjustmentByIdAsync(action : any) {
  
  
  try {
    const response : Promise<any> = yield call(
        API.getStockAdjustmentById,{id : action.id}
    )
    yield put(getStockAdjustmentByIdSuccess(response));
  } catch (error) {
    yield put(getStockAdjustmentByIdError(error))
  }
}
function* getStockAdjustmentByPartIdAsync(action : any) {
  
  
  try {
    const response : Promise<any> = yield call(
        API.getStockAdjusmentFromPartsSkuId,action.id
    )
    yield put(getStockAdjustmentByPartIdSuccess(response));
  } catch (error) {
    yield put(getStockAdjustmentByPartIdError(error))
  }
}


function* createStockAdjustmentAsync(action : any) {  
  try {
    const response : Promise<any> = yield call(
        API.createStockAdjustmentApi,(action.payload)
        )
    yield put(createStockAdjustmentSuccess(response))  
    
    yield put(getStockAdjustmentList(action.lazyState))
  } catch (error) {
    yield put(createStockAdjustmentError(error));
  }
}


function* updateStockAdjustmentAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.updateStockAdjustmentListAPI ,{action}
        )
    yield put(updateStockAdjustmentSuccess(response)) 
    
    yield put(getStockAdjustmentList(action.lazyState))
  } catch (error) {
    yield put(updateStockAdjustmentError(error));
  }
}


function* deleteStockAdjustmentAsync(action : any) {
  
  
  try {
    const response : Promise<any> = yield call(
        API.deleteStockAdjustmentListAPI ,{id : action.payload.id ,lazyEvent : JSON.stringify(action)}
        )
    yield put(deleteStockAdjustmentFromListSuccess (response))
    
    yield put(getStockAdjustmentList(action.lazyState))
  } catch (error) {
    yield put(deleteStockAdjustmentFromListError(error));
  }
}

function* getPartsSkutListAsync(action : any) {


  try {
    const response : Promise<any> = yield call(
        API.getPartsSkuListApiforStock,(action.lazyState)
    )
    let payload = {
      ...action.lazyState,
      response
    }
    yield put(getPartsSkuListSuccessforStock(payload));
  } catch (error) {
    yield put(getPartsSkuListErrorforStock(error));
  }
}
function* getSupplierDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getSupplierListFromMasterManagement,'')
    yield put(getSupplierDropdownSuccess(response))
  }catch (err){
    yield put(getSupplierDropdownError(err))
  }
}
function* getInitiatedBYDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getStockAdjusmentUserListFromMasterManagement)
    yield put(getInitiatedByDropdownSuccess(response))
  }catch (err){
    yield put(getInitiatedByDropdownError(err))
  }
}


function* sentMailToPurchaseOrderAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.sentMailToStockAdjustment,action.payload )
      yield put(sentMailToSupplierAdjustmentSuccess(response));
    } catch (error) {
      yield put(sentMailToSupplierAdjustmentError(error));
    }
  }

function* downloadPurchaseOrderAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.downloadStockAdjustment,action.payload )
      yield put(downloadStockAdjustmentSuccess(response));
    } catch (error) {
      yield put(downloadStockAdjustmentError(error));
    }
  }

function* courierDropdownAsync(){
  try {
    const response : Promise<any> = yield call(API.getCourierDropDowmFromMasterManagement);
    yield put(getCourierDropdownSuccess(response));
  } catch (error) {
    yield put(getCourierDropdownError(error));
  }
}  

function* StockAdjustmentSaga() {
  yield takeLatest(StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_LIST, getStockAdjustmentListAsync);
  yield takeLatest(StockAdjustmentTypes.DELETE_STOCK_ADJUSTMENT, deleteStockAdjustmentAsync);
  yield takeLatest(StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_ID,getStockAdjustmentByIdAsync);
  yield takeLatest(StockAdjustmentTypes.GET_STOCK_ADJUSTMENT_BY_PART_ID,getStockAdjustmentByPartIdAsync);
  yield takeLatest(StockAdjustmentTypes.UPDATE_STOCK_ADJUSTMENT,updateStockAdjustmentAsync);
  yield takeLatest(StockAdjustmentTypes.CREATE_STOCK_ADJUSTMENT,createStockAdjustmentAsync);
  yield takeLatest(StockAdjustmentTypes.PARTS_SKU_API_CALL,getPartsSkutListAsync);
  yield takeLatest(StockAdjustmentTypes.GET_SUPPLIER_DROPDOWN,getSupplierDropdownAsync);
  yield takeLatest(StockAdjustmentTypes.GET_INITIATED_BY_DROPDOWN,getInitiatedBYDropdownAsync)
  yield takeLatest(StockAdjustmentTypes.SENT_EMAIL_TO_STOCK_ADJUSTMENT,sentMailToPurchaseOrderAsync)
  yield takeLatest(StockAdjustmentTypes.DOWNLOAD_STOCK_ADJUSTMENT,downloadPurchaseOrderAsync);
  yield takeLatest(StockAdjustmentTypes.GET_COURIER_DROPDOWN_LOADING,courierDropdownAsync);
}

export default StockAdjustmentSaga;