import React from 'react';
import { Col, Row } from 'reactstrap';
import Button from 'src/components/UI/Button';


interface ApproveRejection {
  onHide: any;
  visible: boolean;
  onSubmit: any
}

const ApproveRejectConfirm = ({onHide,onSubmit,visible} :ApproveRejection) => {
  return (
    <React.Fragment>
      <div className="Approve_Rejection_Dialog" id='Approve_Rejection_Dialog'>
        <div className='close_Menu'>
          <div>Close</div>
          <div className='close_menu_Cross' onClick={onHide}>
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
        </div>
        <div className='dialog_header'>Confirmation</div>
        <div className='content_text'>Are you sure want to reject ?</div>
        <div className='btn_section'>
          <Row>
            <Col lg={6}>
              <Button className="secondary-btn me-2 w-100" label="No" onClick={onHide} type="button" />
            </Col>
            <Col lg={6}>
              <Button  className="btn-primary me-2 w-100" label="Yes" type="button" onClick={onSubmit} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ApproveRejectConfirm