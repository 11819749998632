import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from "react";
import InputController from "./InputController";
import moment from "moment";
import Button from "./Button";
import './commonUI.styles.scss'


interface SignupModal {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
  age: null | number
  preferences: Array<string>
  gender: string
  city: string
  rememberMe: boolean
  dob: Date | null | string
  weddingDate: Date | null | string
  address: string
}


const SignUpForm = () => {

  const savedData: SignupModal = {
    email: "srajanwbc@gmail.com",
    password: "12345678",
    confirmPassword: "12345678",
    firstName: "Sangeeth",
    lastName: "Rajan",
    age: null,
    preferences: [],
    gender: "male",
    city: "Chennai",
    rememberMe: false,
    dob: new Date("2023/12/14"),
    address: "Triplicane",
    weddingDate: new Date("2023/12/16"),
  }

  const initialValues: SignupModal = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    age: null,
    preferences: [],
    gender: "",
    city: "",
    rememberMe: false,
    dob: null,
    address: "",
    weddingDate: null,
  }

  const cities = [
    { name: "Chennai", key: "Chennai", value: "Chennai", },
    { name: "Salem", key: "Salem", value: "Salem", },
    { name: "Thoothukudi", key: "Thoothukudi", value: "Thoothukudi", },
    { name: "Karaikudi", key: "Karaikudi", value: "Karaikudi", },
  ]

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    age: Yup.number().required("Age is required").nullable(),
    password: Yup.number().required("Password is required"),
    confirmPassword: Yup.number().oneOf([Yup.ref("password"), undefined], "Password must match"),
    gender: Yup.string().required("Gender is required"),
    preferences: Yup.array().required("Preferences is required"),
  })

  const [formValues, setFormValues] = useState<SignupModal>(initialValues)

  useEffect(() => {
    setFormValues(savedData)
  }, [])

  const handleSubmit = (values: SignupModal) => {
  }

  const radioOptions = [
    { value: 'male', name: "Male", key: "male" },
    { value: 'female', name: "Female", key: "female" },
  ]

  const preferenceOptions = [
    { value: 'Trichy', name: "Trichy", key: "Trichy" },
    { value: 'Madurai', name: "Madurai", key: "Madurai" },
    { value: 'Chennai', name: "Chennai", key: "Chennai" },
  ]

  return (
    <div className="d-flex p-5  justify-content-center align-items-center">

      <div className="card custom-card-width">

        <div className="card-header link-underline-warning text-center">
          <b>Sign Up</b>
        </div>

        <div className="card-body">
          <Formik initialValues={formValues || initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
            {
              ({ values, handleChange }) => {

                return <Form onChange={handleChange}>

                  {/* <Field name="firstName" component={InputController} type="text" label="First Name" value={values.firstName} onChange={handleChange} control="text"/> */}

                  <InputController control="text" type="text" name="firstName" label="First Name" value={values.firstName} onChange={handleChange} />

                  <InputController control="text" type="text" name="lastName" label="Last Name" value={values.lastName} onChange={handleChange} />

                  <InputController control="text" type="email" name="email" label="Email" value={values.email} onChange={handleChange} />

                  <InputController control="text" type="password" name="password" label="Password" value={values.password} onChange={handleChange} />

                  <InputController control="text" type="password" name="confirmPassword" label="Confirm Password" value={values.confirmPassword} onChange={handleChange} />

                  <InputController label="Gender" control="radio" name="gender" value={values.gender} onChange={handleChange} options={radioOptions} />

                  <InputController control="dropdown" name="city" label="Select a city" value={values.city} options={cities} onChange={handleChange} />

                  <InputController control="date" name="dob" label="Date of birth" value={values.dob} onChange={handleChange} />

                  <InputController control="date" name="weddingDate" label="Wedding Date" value={values.weddingDate} onChange={handleChange} />

                  <InputController control="textarea" name="address" label="Address" value={values.address} onChange={handleChange} />

                  {/* <Field control="checkboxGroup" name="preferences" label="Preferences Known" component={InputController} options={preferenceOptions} value={values.preferences} onChange={handleChange} /> */}

                  <InputController control="checkboxGroup" name="preferences" label="Preferences Known" options={preferenceOptions} value={values.preferences} onChange={handleChange} />

                  <InputController control="checkbox" name="rememberMe" label="Remember Me" value={values.rememberMe} onChange={handleChange} />

                  <Button className="btn-primary" label="Submit" type="submit" />

                  {/* <div className="d-grid">
                    <button className="btn btn-primary " type="submit">Submit</button>
                  </div> */}
                </Form>
              }
            }
          </Formik>
        </div>
      </div>
    </div>
  )
};

export default SignUpForm;
