import { call, put, takeLatest } from "redux-saga/effects";
import { receivedPaymentActions } from "./actionTypes";
import { createReceivedPaymentError, createReceivedPaymentSuccess, downloadReceivedPaymentSuccess, getCustomerReceivedPaymentError, getCustomerReceivedPaymentSuccess, getDealerPaymentError, getDealerPaymentSuccess, getInvoiceDetailsReceivedPaymentError, getInvoiceDetailsReceivedPaymentSuccess, getInvoiceListCreatePageError, getInvoiceListCreatePageSuccess, getInvoiceReceivedPaymentError, getInvoiceReceivedPaymentSuccess, getReceivedPaymentListError, getReceivedPaymentListSuccess, getUserTypeByUuidError, getUserTypeByUuidSuccess, viewReceivedPaymentError, viewReceivedPaymentSuccess } from "./action";
import { API } from "src/util/api";


//worker function

//courier list
function* fetchReceivedPaymentListAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.getReceivedPaymentGrid, { ...action.lazyState });
    yield put(getReceivedPaymentListSuccess(response))
  } catch (error) {
    yield put(getReceivedPaymentListError(error))
  }
}

// downloadRecievedPayment
function* downloadReceivedPayments(action: any) {
  try {
    const response: Promise<any> = yield call(API.downloadPdf, action.payload);
    yield put(downloadReceivedPaymentSuccess())
  } catch (error) {
    yield put(getReceivedPaymentListError(error))
  }
}


// view received payment page

function* viewReceivedPaymentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getReceivedPaymentById, action.payload);
    yield put(viewReceivedPaymentSuccess(response))
  } catch (error) {
    yield put(viewReceivedPaymentError(error));
  }
}

// create received payment

function* createReceivedPaymentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.createReceivedPayment, action.payload);
    yield put(createReceivedPaymentSuccess(response))
  } catch (error) {
    yield put(createReceivedPaymentError(error));
  }
}


//get by id 
// function* getbyIdCourierAsync(action: any) {
//   try {
//     const response: Promise<any> = yield call(API.getByIdCourierApi, action.payload);
//     yield put(getbyIdCourierDetailsSuccess(response))
//   } catch (error) {
//     yield put(getbyIdCourierDetailsError(error));
//   }
// }

//update courier
// function* updateCourierAsync(action: any) {
//   try {
//     const response: Promise<any> = yield call(API.updateCourierApi, action.payload, action.id);
//     yield put(updateCourierSuccess(response))
//   } catch (error) {
//     yield put(updateCourierError(error))
//   }
// }

//watcher function


function* getDealerDropReceivedPaymentAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getDealerDropForReceivedPayment);
    const response: Promise<any> = yield call(API.getDealerDrpFromIdentity);
    yield put(getDealerPaymentSuccess(response))
  } catch (error) {
    yield put(getDealerPaymentError(error));
  }
}

function* getCustomerDropReceivedPaymentAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getCustomerDropForReceivedPayment);
    const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getCustomerReceivedPaymentSuccess(response))
  } catch (error) {
    yield put(getCustomerReceivedPaymentError(error));
  }
}

function* getInvoiceDropReceivedPaymentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getInvoiceDropForReceivedPayment);
    // const response: Promise<any> = yield call(API.getInvoiceDropForReceivedPayNew);
    yield put(getInvoiceReceivedPaymentSuccess(response))
  } catch (error) {
    yield put(getInvoiceReceivedPaymentError(error));
  }
}

function* getInvoiceDetailsReceivedPaymentAsync(action: any) {
  try {
    // const response: Promise<any> = yield call(API.getInvoiceDetailForReceivedPayment,action.payload.id);
    const response: Promise<any> = yield call(API.getInvoiceDetailForReceivedPaymentNew, action.payload.id);
    yield put(getInvoiceDetailsReceivedPaymentSuccess(response, action.payload.index, action.payload.setFieldValue))
  } catch (error) {
    yield put(getInvoiceDetailsReceivedPaymentError(error));
  }
}


function* getUserTypeIdAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getUserTypeIdByuseUuidApi, action.payload);
    yield put(getUserTypeByUuidSuccess(response))
  } catch (error) {
    yield put(getUserTypeByUuidError(error));
  }
}


function* CreatePageInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getInvoiceDropForReceivedPaymentCreate, action.payload.dealerId,action.payload.customerId);
    yield put(getInvoiceListCreatePageSuccess(response))
  } catch (error) {
    yield put(getInvoiceListCreatePageError(error));
  }
}




function* receivedPaymentSaga() {
  yield takeLatest(receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_LOADING, fetchReceivedPaymentListAsync);
  yield takeLatest(receivedPaymentActions.VIEW_RECEIVED_PAYMENT_LOADING, viewReceivedPaymentAsync);
  yield takeLatest(receivedPaymentActions.CREATE_RECEIVED_PAYMENT_LOADING, createReceivedPaymentAsync);
  //   yield takeLatest(courierActionsTypes.GETBY_ID_COURIER_LOADING, getbyIdCourierAsync);
  //   yield takeLatest(courierActionsTypes.UPDATE_COURIER_LOADING, updateCourierAsync)
  yield takeLatest(receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT, getDealerDropReceivedPaymentAsync);
  yield takeLatest(receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT, getCustomerDropReceivedPaymentAsync);
  yield takeLatest(receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT, getInvoiceDropReceivedPaymentAsync);
  yield takeLatest(receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT, getInvoiceDetailsReceivedPaymentAsync);
  yield takeLatest(receivedPaymentActions.DOWNLOAD_RECEIVED_PAYMENT, downloadReceivedPayments);
  yield takeLatest(receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_LOADING, getUserTypeIdAsync);
  yield takeLatest(receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_LOADING, CreatePageInvoiceAsync);



}

export default receivedPaymentSaga;