import { DataTableFilterMetaData } from "primereact/datatable";
import { CustomerService } from "src/components/Grid/CustomerService";
import { DealersModal, contactModal } from "src/models/pages/dealerModel";
import { Franchise } from "src/models/pages/franchiseeModel";
import { subUser } from "src/models/pages/subUserModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APPENV_URL } from "./appUrlEnv";
import { IntentPost } from "src/models/pages/intentModel";
import { getToken } from "src/helpers/common_helpers";
import { OAuthService } from "./auth/oauthService";
import { AUTH_LOGIN_URL } from "./auth/authConfig";
import { method } from "lodash";

export function fetchURL(
  path: string,
  options: any = {},
  definition: string = "",
  isFileDownload: boolean = false,
  downloadFileName: any = ""
) {
  let API_URL: string | undefined;
  switch (definition) {
    case "identity":
      API_URL = APPENV_URL.identityUrl;
      break;
    case "app":
      API_URL = APPENV_URL.AppUrl;
      break;
    case "masterManagement":
      API_URL = APPENV_URL.masterManagementUrl;
      break;
    case "jsonServer":
      API_URL = APPENV_URL.jsonServer;
      break;
    case "JobService":
      API_URL = APPENV_URL.jobServiceUrl;
      break;
    case "Courier":
      API_URL = APPENV_URL.courierServiceUrl;
      break;
    case "account":
      API_URL = APPENV_URL.accountUrl;
      break;
    case "payment":
      API_URL = APPENV_URL.paymentUrl;
      break;
    case "ticketSystem":
      API_URL = APPENV_URL.ticketSystemUrl;
      break;
    case "MassEmail":
      API_URL = APPENV_URL.MassEmailUrl;
      break;
    case "MassSms":
      API_URL = APPENV_URL.MassSmsUrl;
      break;
    case "permissionManagement":
      API_URL = APPENV_URL.permissionUrl;
      break;
    case "whatsApp":
      API_URL = APPENV_URL.whatsAppUrl;
      break;
    default:
      API_URL = APPENV_URL.identityUrl;
  }
  const apiUrl = `${API_URL}/${path}`;
  const token = localStorage.getItem("token");

  if (options.headers) {
    options.headers = new Headers(options.headers);
  } else {
    options.headers = new Headers({
      Authorization: `Bearer ${token}`,
    });
  }
  options.headers.append("Accept", "application/json, text/plain, */*");
  options.headers.append(
    "Access-Control-Expose-Headers",
    "Content-Disposition"
  );

  if (options.body || options.formData) {
    if (!options.formData) {
      options.headers.append("Content-Type", "application/json; text/plain");
      // old code written by Srajan
      // options.body = JSON.stringify(options.body);
      // New code written by Karthi Pandi on 11 March, 2024.
      options.body = JSON.stringify(options.body, function (key, value) {
        if (value instanceof Date) {
          // Return the date in its original format
          return value.toISOString();
        }
        return value;
      });
    } else {
      if (
        options.createquery &&
        !options.replyQuery &&
        !options.additionalExpense &&
        !options.approveDocument &&
        !options.whatsAppFormData
      ) {
        const data = new FormData();
        const { FormFiles, Category, Subject, Priority, Description } =
          options.body;
        if (FormFiles?.length > 0) {
          FormFiles.forEach((element: any) => {
            data.append("FormFiles", element);
          });
          data.append("Category", Category);
          data.append("Subject", Subject);
          data.append("Description", Description);
          data.append("Priority", Priority);
        } else {
          data.append("Category", Category);
          data.append("Subject", Subject);
          data.append("Description", Description);
          data.append("Priority", Priority);
        }
        options.body = data;
      } else if (
        options.replyQuery &&
        !options.createquery &&
        !options.approveDocument &&
        !options.additionalExpense &&
        !options.whatsAppFormData
      ) {
        const data = new FormData();
        const { FormFiles, Description, ParentReplyId } = options.body;
        if (FormFiles?.length > 0) {
          FormFiles.forEach((element: any) => {
            data.append("FormFiles", element);
          });
          data.append("Description", Description);
        } else {
          data.append("Description", Description);
        }
        if (ParentReplyId != undefined && ParentReplyId != null) {
          data.append("ParentReplyId", ParentReplyId);
        }
        options.body = data;
      } else if (
        !options.replyQuery &&
        !options.createquery &&
        !options.approveDocument &&
        options.additionalExpense &&
        !options.whatsAppFormData
      ) {
        const data = new FormData();
        const FormFiles = options.body;
        if (FormFiles?.length > 0) {
          FormFiles.forEach((element: any) => {
            data.append("FormFiles", element);
          });
        }
        options.body = data;
      } else if (
        !options.replyQuery &&
        !options.createquery &&
        !options.additionalExpense &&
        options.approveDocument &&
        !options.whatsAppFormData
      ) {
        const data = new FormData();
        const FormFiles = options.body;
        if (FormFiles?.length > 0) {
          FormFiles.forEach((element: any) => {
            data.append("FormFiles", element);
          });
        }
        options.body = data;
      } else if (
        !options.replyQuery &&
        !options.createquery &&
        !options.additionalExpense &&
        !options.approveDocument &&
        options.whatsAppFormData
      ) {
        debugger;
        const data = new FormData();
        const FormFiles = options.body;
        if (FormFiles?.uploadFiles?.length > 0) {
          FormFiles?.uploadFiles?.forEach((element: any) => {
            data.append("FormFiles", element);
          });
        }
        data.append("Id", FormFiles?.id);
        data.append("TextContent", FormFiles?.TextContent);
        data.append("ToUserTypes", FormFiles?.toUserTypes);
        if (FormFiles?.allMobileNumber?.length > 0) {
          FormFiles?.allMobileNumber?.forEach((element: any) => {
            data.append("AllMobileNumber", element);
          });
        }
        options.body = data;
      } else {
        const data = new FormData();
        data.append("formFile", options.file);
        options.body = data;
      }
    }
  }

  return (
    fetch(apiUrl, options)
      .then((response: any) =>
        checkStatus(response, isFileDownload, downloadFileName)
      )
      // .then(parseJSON)
      .catch((raw) => {
        const error = getApiError(raw);
        throw error;
      })
  );
}

// }

let unAuthorized = false;

function parseJSON(response: any) {
  if (response.status === 204) {
    // Return an empty object or handle it based on your requirements
    return Promise.resolve({
      raw: response,
      data: null, // or an appropriate value
    });
  }

  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.includes("text/plain")) {
    // If the response is text/plain, return the response text
    return response.text().then((text: string) => ({
      raw: response,
      data: text,
    }));
  } else {
    // Otherwise, parse the response as JSON
    return response.json().then((data: any) => ({
      raw: response,
      data,
    }));
  }
}

function getFilenameFromContentDisposition(contentDisposition: string): string {
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = regex.exec(contentDisposition);
  if (matches && matches[1]) {
    return matches[1].replace(/['"]/g, "");
  }
  return "download";
}

function checkStatus(
  response: any,
  isFileDownload: boolean = false,
  downloadFileName: string = ""
) {
  if (response.ok) {
    // Check if the response is a file download
    const contentDisposition = response.headers.get("Content-Disposition");
    if (
      (contentDisposition && contentDisposition.includes("attachment")) ||
      isFileDownload
    ) {
      // Initiate download

      try {
        response.blob().then((blob: any) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = downloadFileName
            ? downloadFileName
            : getFilenameFromContentDisposition(contentDisposition);
          // getFilenameFromContentDisposition(contentDisposition);
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
        return {};
      } catch (e: any) {
        console.log("Print Error ", e);
      }
    }

    // If not a file download, proceed with parsing JSON
    try {
      return parseJSON(response).then((payload: any) => {
        return payload;
      });
    } catch (e: any) {
      console.log("Non Print Error ", e);
      return {};
    }

    // try {
    //   return parseJSON(response).then((payload: any) => {
    //     return payload;
    //   });
    // } catch {
    //   return {};
    // }
  } else if (response.status === 401) {
    debugger;
    if (true) {
      if (localStorage.getItem("PKCE_verifier")) {
        localStorage.removeItem("PKCE_verifier");
      }
      if (!localStorage.getItem("PKCE_verifier")) {
        let count: number = (
          localStorage.getItem("count") ? localStorage.getItem("count") : 0
        ) as number;
        let sum: string = (count + 1) as unknown as string;
        localStorage.setItem("count", sum);
        let oauth = new OAuthService();
        const frondEndUrl: any = localStorage.getItem("fronendUrl");
        oauth
          .createLoginUrl("", "", "", false, {}, false, frondEndUrl)
          .then((data) => {
            // let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
            let authUrl = AUTH_LOGIN_URL + "/connect/authorize";
            let culture = localStorage.getItem("i18nextLng");
            const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;

            // authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL)
            authUrl = authUrl + loginURL;

            window.open(authUrl, "_self");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("PKCE_VERifier are there already");
      }
    }

    throw "";
  } else {
    return parseJSON(response).then((payload: any) => {
      throw payload;
    });
  }
}

function getApiError(rawError: any) {
  if (rawError.data) {
    return rawError.data.error || rawError.data.validationErrors;
  }

  return rawError;
}

// function getQueryParams(params: any) {
//   console.log(params);
  
//   const query = new URLSearchParams();
//   params
//     ? Object.keys(params).forEach((k) => {
//       if (k === "first") {
//         query.append("SkipCount", encodeURIComponent(params[k]));
//       } else if (k === "rows") {
//         query.append("MaxResultCount", encodeURIComponent(params[k]));
//       } else if (k === "sortField" && params["sortOrder"]) {
//         params["sortOrder"] === 1
//           ? query.append("Sorting", encodeURIComponent(params[k]))
//           : query.append("Sorting", `${params[k]} desc`);
//         // query.append("Sorting", encodeURIComponent(params[k]));
//       } else if (k === "filters") {
//         const filterKeys = Object.keys(params[k]);
//         filterKeys.forEach((filterKey) => {
//           const filterValue = params[k][filterKey].value
//             ? params[k][filterKey].value.toString().trim()
//             : "";
//           if (filterValue !== "") {
//             query.append(filterKey, filterValue);
//           }
//         });
//       }
//     })
//     : "";
//   return query.toString();
// }
function getQueryParams(params: any) {
  
  const query = new URLSearchParams();
  params
    ? Object.keys(params).forEach((k) => {
      if (k === "first") {
        query.append("SkipCount", encodeURIComponent(params[k]));
      } else if (k === "rows") {
        query.append("MaxResultCount", encodeURIComponent(params[k]));
      } else if (k === "sortField" && params["sortOrder"]) {
        params["sortOrder"] === 1
          ? query.append("Sorting", encodeURIComponent(params[k]))
          : query.append("Sorting", `${params[k]} desc`);
      } else if (k === "filters") {
        const filterKeys = Object.keys(params[k]);
        filterKeys.forEach((filterKey) => {
          const filterValue = params[k][filterKey].value;

          if (Array.isArray(filterValue)) {
            filterValue.forEach((value: string) => {
              if (value.toString().trim() !== "") {
                query.append(filterKey, value.toString().trim());
              }
            });
          } else if (filterValue && filterValue.toString().trim() !== "") {
            query.append(filterKey, filterValue.toString().trim());
          }
        });
      }
    })
    : "";
  return query.toString();
}

export const API = {
  // Auth
  getToken: (authData: any) => {
    fetchURL("connect/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: authData,
    });
  },

  // User
  verifyAuth: (token: any) =>
    fetchURL("customers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getCustomers: (params: any) => {
    let customerDataURL = "customers?" + getQueryParams(params);
    return fetchURL(customerDataURL, {
      method: "GET",
    });
  },

  getProfile: () => {
    let customerDataURL = "my-profile";
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "account"
    );
  },

  getCurrentUserProfile: () => {
    let customerDataURL = "my-profile";
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "account"
    );
  },

  getFranchiseAdditonalDetailsApi: (params: any) => {
    let url = `franchise/${params}`;
    return fetchURL(url, {
      method: "GET",
    });
  },

  updateProfile: (action: any) => {

    let customerDataURL = "my-profile";
    return fetchURL(
      customerDataURL,
      {
        method: "PUT",
        body: action.action.payload,
      },
      "account"
    );
  },

  getIndividualUser: (params: any) => {
    let userDataURL = `employee-application-services/${params}`;
    return fetchURL(userDataURL, {
      method: "GET",
    });
  },

  getUsersLists: (params: any) => {
    let userDataURL =
      "employee-application-services/paged?" + getQueryParams(params);
    return fetchURL(userDataURL, {
      method: "GET",
    });
  },

  deleteUser: (params: any) => {
    return fetchURL(
      `employee-application-services/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      }
    );
  },

  createMelUser: (user: any) => {
    return fetchURL("employee-application-services", {
      method: "POST",
      body: user,
    });
  },

  updateMelUser: ({ id, employeeDetails }: any) => {
    return fetchURL(`employee-application-services/${id}`, {
      method: "PUT",
      body: employeeDetails,
    });
  },

  uploadEmployeeImage: (employee: any, { id }: any) => {
    return fetchURL(
      `employee-application-services/${id}/profile?fileType=Profile`,
      {
        method: "PUT",
        file: employee,
        formData: true,
      }
    );
  },

  uploadEmployeeDoumentApi: (employee: any, { id, file }: any) => {
    debugger;
    return fetchURL(
      `employee-application-services/${id}/document?filteType=${file}`,
      {
        method: "POST",
        file: employee,
        formData: true,
      }
    );
  },

  generateEmployeeDoumentLinkApi: ({ id }: any) => {
    return fetchURL(`employee-application-services/${id}/document-link`, {
      method: "GET",
    });
  },

  fetchEmployeeImage: (params: any) => {
    return fetchURL(`employee-application-services/${params}/profile-link`, {
      method: "GET",
    });
  },

  fetchEmployeedocumentApi: (params: any) => {
    return fetchURL(`employee-application-services/${params}/document-list`, {
      method: "GET",
    });
  },

  deleteEmployeeImage: (params: any) => {
    return fetchURL(`employee-application-services/${params}/profile`, {
      method: "DELETE",
    });
  },

  deleteEmployeeDocumentApi: (params: any) => {
    return fetchURL(`employee-application-services/${params}/document`, {
      method: "DELETE",
    });
  },

  // Customers
  getCustomersListAPI: (params: any) => {

    let userDataURL =
      "customer-application-services/paged?" + getQueryParams(params);
    return fetchURL(userDataURL, {
      method: "GET",
    });
  },

  deleteCustomersListAPI: (params: any) => {
    return fetchURL(
      `customer-application-services/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      }
    );
  },

  //getByIdCustomer
  getIndividualCustomer: (params: any) => {
    return fetchURL(`customer-application-services/${params}`);
  },

  //createCustomer
  createCustomer: (customer: any) => {
    return fetchURL("customer-application-services", {
      method: "POST",
      body: customer,
    });
  },

  //updateCustomer
  updateCustomer: (customer: any, { id }: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(`customer-application-services/${id}`, {
      method: "PUT",
      body: customer,
    });
  },

  uploadCustomerImage: (customer: any, { id }: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(
      `customer-application-services/${id}/profile?fileType=Profile`,
      {
        method: "PUT",
        file: customer,
        formData: true,
      }
    );
  },

  fetchCustomerImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(`customer-application-services/${params}/profile-link`, {
      method: "GET",
    });
  },

  deleteCustomerImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(`customer-application-services/${params}/profile`, {
      method: "DELETE",
    });
  },

  getCustomerTaxesList: () => {
    let userDataURL =
      "customer-application-services/create-or-update-customer/0";
    return fetchURL(userDataURL, {
      method: "GET",
    });
  },

  //filter customer

  filterCustomer: (params: any) => {
    return fetchURL(`customer-application-services/paged?Filter=${params}`);
  },

  // Dealer

  createDealer: (dealer: DealersModal) => {
    return fetchURL("dealer-application-services", {
      method: "POST",
      body: dealer,
    });
  },

  updateDealer: (dealer: DealersModal, dealerId: string) => {
    return fetchURL(`dealer-application-services/${dealerId}`, {
      method: "PUT",
      body: dealer,
    });
  },

  updateDealerContact: (contactDetails: contactModal) => {
    return fetchURL(`contact-detail/or-update`, {
      method: "POST",
      body: contactDetails,
    });
  },

  getDealerContact: (id: string) => {
    let value = fetchURL(`contact-detail/by-user/${id}`, {
      method: "GET",
    });
    return value;
  },

  deleteDealersContactAPI: (params: any) => {
    return fetchURL(`contact-detail/${params.id}`, {
      method: "DELETE",
    });
  },
  deleteSupplierContactAPI: (params: any) => {
    return fetchURL(
      `supplier-contact-detail/${params.id}`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  getDealer: (id: string) => {
    let value = fetchURL(`dealer-application-services/${id}`, {
      method: "GET",
    });
    return value;
  },


  getCustomerFromJobsheetApi: (currentValue: any, type: any) => {
    let val = {
      filters: {
        email: { value: currentValue.email, matchmode: "contains" },
        phoneNumber: { value: currentValue.phoneNumber, matchmode: "contains" }
      }
    };
    let url = `customer-application-services/customer-detail?` + getQueryParams(val);
    let encodedValue = encodeURIComponent(currentValue);

    // if (type == "email") {
    //   url = `customer-application-services/paged?IsDeleted=false&Email=${encodedValue}`
    // } else {
    //   url = `customer-application-services/customer-detail?Email${encodedValue}`;
    // }
    let value = fetchURL(url, {
      method: "GET",
    });
    return value;
  },

  getDealerTaxesList: () => {
    let userDataURL = "dealer-application-services/create-or-update-dealer/0";
    return fetchURL(userDataURL, {
      method: "GET",
    });
  },

  removeDealer: (id: string) => {
    return fetchURL(`dealers/${id}`, {
      method: "DELETE",
    });
  },

  getDealers: () => {
    return fetchURL(`dealers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getDealersListAPI: (params: any) => {
    // let customerDataURL = "dealersList?" + getQueryParams(params);
    let dealerDataURL =
      "dealer-application-services/paged?" + getQueryParams(params);
    return fetchURL(dealerDataURL, {
      method: "GET",
    });
  },

  deleteDealersListAPI: (params: any) => {
    return fetchURL(
      `dealer-application-services/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      }
    );
  },

  uploadDealerImage: (dealer: any, { id }: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(
      `dealer-application-services/${id}/profile?fileType=Profile`,
      {
        method: "PUT",
        file: dealer,
        formData: true,
      }
    );
  },

  fetchDealerImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(`dealer-application-services/${params}/profile-link`, {
      method: "GET",
    });
  },

  deleteDealerImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(`dealer-application-services/${params}/profile`, {
      method: "DELETE",
    });
  },

  // administrator
  getAdministratorListAPI: (params: any) => {
    let customerDataURL = "administrator?" + getQueryParams(params);
    return fetchURL(customerDataURL, {
      method: "GET",
    });
  },

  deleteAdministratorListAPI: (params: any) => {
    return fetchURL(`administrator/${params.id}`, {
      method: "DELETE",
    });
  },

  getMapAPIKey: () => {
    return fetchURL(`mel-setting-management/gcp-setting`, {
      method: "GET"
    }, "app");
  },

  // audio brands

  getAudioBrandsListAPI: (params: any) => {
    let audiobrandDataURL = "audio-brand/paged-list?" + getQueryParams(params);
    return fetchURL(
      audiobrandDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  createAudioBrandApi: (audiobrand: any) => {
    let audiobrandDataURL = "audio-brand/audio-brand";
    return fetchURL(
      audiobrandDataURL,
      {
        method: "POST",
        body: audiobrand,
      },
      "masterManagement"
    );
  },

  updateAudioBrandApi: (audiobrand: any) => {
    let audiobrandDataURL = `audio-brand/${audiobrand?.id}`;
    return fetchURL(
      audiobrandDataURL,
      {
        method: "PUT",
        body: audiobrand,
      },
      "masterManagement"
    );
  },

  getAudioBrandByIdApi: (id: string) => {
    return fetchURL(
      `audio-brand/${id}/by-id`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteAudioBrandsListAPI: (params: any) => {
    return fetchURL(
      `audio-brand/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  getVehicleBrandListApi: () => {
    return fetchURL(
      `audio-brand/create-or-update-parts/0`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  //audio brand image
  uploadAudioBrandImage: (customer: any, { id }: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(
      `audio-brand/${id}/profile?fileType=Profile`,
      {
        method: "PUT",
        file: customer,
        formData: true,
      },
      "masterManagement"
    );
  },

  fetchAudioBrandImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(
      `audio-brand/${params}/profile-link`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteAudioBrandImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(
      `audio-brand/${params}/profile`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  // audio Models

  getAudioModelsListAPI: (params: any) => {
    let audioModelDataURL = "audio-model/paged-list?" + getQueryParams(params);
    return fetchURL(
      audioModelDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  createAudioModelApi: (audioModel: any) => {
    let audioModelDataURL = "audio-model/audio-model";
    return fetchURL(
      audioModelDataURL,
      {
        method: "POST",
        body: audioModel,
      },
      "masterManagement"
    );
  },

  getAudioModelByIdApi: (id: string) => {
    return fetchURL(
      `audio-model/${id}/by-id`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  updateAudioModelApi: (audioModel: any, id: any) => {
    let audioModelDataURL = `audio-model/${id}`;
    return fetchURL(
      audioModelDataURL,
      {
        method: "PUT",
        body: audioModel,
      },
      "masterManagement"
    );
  },

  getAudioBrandListApi: () => {
    return fetchURL(
      `audio-model/create-or-update-parts/0`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteAudioModelsListAPI: (params: any) => {
    return fetchURL(
      `audio-model/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  // InvoiceItemWiseRegisters
  getInvoiceItemWiseRegistersListAPI: (params: any) => {
    let URL =
      "reports-application-servicve/invoice-item-wise-register?" +
      getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  // parts

  getPartsListAPI: (params: any) => {
    let customerDataURL = "parts/paged?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deletePartsListAPI: (params: any) => {
    return fetchURL(
      `parts/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  postPartsAPI: (parts: any) => {
    let URL = "parts";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: parts,
      },
      "masterManagement"
    );
  },

  updatePartsAPI: (parts: any, partId: string) => {
    let URL = `parts/${partId}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: parts,
      },
      "masterManagement"
    );
  },

  getPartsByIdAPI: (id: string) => {
    let URL = `parts/${id}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getViewPartspurchaseOrderAPI: (id: string, params: any) => {
    let URL = `purchase-order/purchase-order-parts-details/${id}?` + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getViewPartsPartIntentAPI: (id: string, params: any) => {
    let URL = `parts-in-bound/part-inbound-parts-details/${id}?` + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getAudioBrandsListForPartsAPI: () => {
    let URL = `parts/create-or-update-parts/0`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // Supplier

  getSupplierListAPI: (params: any) => {
    let URL = "supplier/paged?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteSupplierListAPI: (params: any) => {
    return fetchURL(
      `supplier/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  postSupplierAPI: (parts: any) => {
    let URL = "supplier";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: parts,
      },
      "masterManagement"
    );
  },
  postSupplierContactAPI: (parts: any) => {
    let URL = "supplier-contact-detail/or-update";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: parts,
      },
      "masterManagement"
    );
  },
  getSupplierContactAPI: (id: any) => {
    let URL = `supplier-contact-detail/${id}/by-user`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  updateSupplierAPI: (supplier: any, supplierId: string) => {
    let URL = `supplier/${supplierId}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: supplier,
      },
      "masterManagement"
    );
  },

  getSupplierByIdAPI: (id: string) => {
    let URL = `supplier/${id}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  uploadSupplierImage: (supplier: any, { id }: any) => {
    return fetchURL(
      `supplier/${id}/profile?fileType=Profile`,
      {
        method: "PUT",
        file: supplier,
        formData: true,
      },
      "masterManagement"
    );
  },

  uploadSupplierDocument: (supplier: any, { id }: any) => {
    return fetchURL(
      `supplier/${id}/document`,
      {
        method: "POST",
        file: supplier,
        formData: true,
      },
      "masterManagement"
    );
  },

  fetchSupplierImage: (params: any) => {
    return fetchURL(
      `supplier/${params}/profile-link`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteSupplierImage: (params: any) => {
    return fetchURL(
      `supplier/${params}/profile`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  //  Taxes

  getTaxesListAPI: (params: any) => {
    let customerDataURL = "taxes/paged-list?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteTaxesListAPI: (params: any) => {
    return fetchURL(
      `taxes/${params.id}/taxes?Undo=${params.undo}`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  postTaxesListAPI: (tax: any) => {
    let URL = "taxes/taxes";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: tax,
      },
      "masterManagement"
    );
  },

  updateTaxesAPI: (tax: any, taxId: string) => {
    let URL = `taxes/${taxId}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: tax,
      },
      "masterManagement"
    );
  },

  getTaxesByIdAPI: (id: string) => {
    let customerDataURL = `taxes/${id}/create-or-update-taxes`;
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getTaxesDropDownListApi: () => {
    let url = "tax-type-application-services";
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  getTaxesPageInvoiceFropdownApi: () => {
    let url = "taxes/invoice-type-drop-down";
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //  Vehicle Brands

  getVehicleBrandsListAPI: (params: any) => {
    let customerDataURL = "vehicle-brand/paged-list?" + getQueryParams(params);

    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  postVehicleBrandsAPI: (vehiclebrand: any) => {
    let URL = "vehicle-brand/vehicle-brand";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: vehiclebrand,
      },
      "masterManagement"
    );
  },

  updateVehicleBrandsAPI: (vehiclebrand: any, vehicleId: string) => {
    let URL = `vehicle-brand/${vehicleId}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: vehiclebrand,
      },
      "masterManagement"
    );
  },

  getVehicleBrandsByIdAPI: (id: string) => {
    let customerDataURL = `vehicle-brand/${id}/by-id`;
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getDealerNamesByIdAPI: (id: string) => {
    let customerDataURL = `dealer-application-services/dealer-by-vehicle-brand-id/${id}`;
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  deleteVehicleBrandsListAPI: (params: any) => {
    return fetchURL(
      `vehicle-brand/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },
  uploadVehicleBrandImage: (customer: any, { id }: any) => {
    return fetchURL(
      `vehicle-brand/${id}/profile?fileType=Profile`,
      {
        method: "PUT",
        file: customer,
        formData: true,
      },
      "masterManagement"
    );
  },

  fetchVehicleBrandImage: (params: any) => {
    return fetchURL(
      `vehicle-brand/${params}/profile-link`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteVehicleBrandImage: (params: any) => {
    return fetchURL(
      `vehicle-brand/${params}/profile`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },
  //  ------------------------------------------

  getPartSkuListAPI: (params: any) => {
    let customerDataURL = "part-skus/paged-list?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //franchisee

  getFranchiseeListAPI: (params: any) => {
    let franchiseeDataUrl = "franchise/paged?" + getQueryParams(params);
    return fetchURL(franchiseeDataUrl, {
      method: "GET",
    });
  },

  createFranchisee: (franchisee: Franchise) => {
    return fetchURL("franchise", {
      method: "POST",
      body: franchisee,
    });
  },

  getIndividualFranchisee: (params: any) => {
    return fetchURL(`franchise/${params}`);
  },

  updateFranchiseeApi: (franchisee: Franchise, { id }: any) => {
    return fetchURL(`franchise/${id}`, {
      method: "PUT",
      body: franchisee,
    });
  },

  deleteFranchiseApi: (params: any) => {
    return fetchURL(`franchise/${params.id}?undo=` + params.undo, {
      method: "DELETE",
    });
  },

  uploadFranchiseImage: (franchise: any, { id }: any) => {
    return fetchURL(`franchise/${id}/profile?fileType=Profile`, {
      method: "PUT",
      file: franchise,
      formData: true,
    });
  },

  uploadFranchiseDocumentApi: (document: any, { id, fileType }: any) => {
    return fetchURL(`franchise/${id}/document?fileType=${fileType}`, {
      method: "POST",
      file: document,
      formData: true,
    });
  },
  getuploadFranchiseDocumentApi: (id: any) => {
    return fetchURL(`franchise/${id}/document-list`, {
      method: "GET",
    });
  },

  deleteuploadFranchiseDocumentApi: (id: any) => {
    return fetchURL(`franchise/${id}/document`, {
      method: "DELETE",
    });
  },
  generatedocumentLinkApi: (id: any) => {
    return fetchURL(`franchise/${id}/document-link`, {
      method: "GET",
    });
  },

  fetchFranchiseImage: (params: any) => {
    return fetchURL(`franchise/${params}/profile-link`, {
      method: "GET",
    });
  },

  deleteFranchiseImage: (params: any) => {
    // return fetchURL(`customer-application-services/${customer.id}`,{
    return fetchURL(`franchise/${params}/profile`, {
      method: "DELETE",
    });
  },

  //subUser

  getFranchiseSubUserListAPI: (params: any) => {
    let subUserDataUrl =
      "franchise/franchise-sub-user-list?" + getQueryParams(params);
    return fetchURL(subUserDataUrl, {
      method: "GET",
    });
  },

  getDealerSubUserListAPI: (params: any) => {
    let subUserDataUrl =
      "dealer-application-services/dealer-sub-user-list?" +
      getQueryParams(params);
    return fetchURL(subUserDataUrl, {
      method: "GET",
    });
  },

  createFranchiseSubUserApi: (subUser: subUser) => {
    return fetchURL(`franchise/sub-user`, {
      method: "POST",
      body: subUser,
    });
  },

  createDealerSubUserApi: (subUser: subUser) => {
    return fetchURL(`dealer-application-services/sub-user`, {
      method: "POST",
      body: subUser,
    });
  },

  getIndividualDealerSubUserApi: (params: any) => {
    return fetchURL(`dealer-application-services/${params}/dealer-sub-user`);
  },

  getIndividualFranchiseSubUserApi: (params: any) => {
    return fetchURL(`franchise/${params}/franchise-sub-user`);
  },

  updateFranchiseSubUserApi: (subUser: subUser, id: any) => {
    return fetchURL(`franchise/${id}/sub-user`, {
      method: "PUT",
      body: subUser,
    });
  },

  updateDealerSubUserApi: (subUser: subUser, id: any) => {
    return fetchURL(`dealer-application-services/${id}/sub-user`, {
      method: "PUT",
      body: subUser,
    });
  },

  deleteFranchiseSubUserApi: (params: any) => {
    return fetchURL(
      `franchise/${params.id}/franchise-sub-user?undo=` + params.undo,
      {
        method: "DELETE",
      }
    );
  },
  deleteDealerSubUserApi: (params: any) => {
    return fetchURL(
      `dealer-application-services/${params.id}/dealer-sub-user?undo=` +
      params.undo,
      {
        method: "DELETE",
      }
    );
  },

  //purchase order
  getPurchaseOrderListAPI: (params: any) => {
    let customerDataURL = "purchase-order/paged?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getPurchaseOrderById: (id: string) => {
    let URL = `purchase-order/${id}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  createPurchaseOrderApi: (parts: any) => {
    let URL = "purchase-order";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: parts,
      },
      "masterManagement"
    );
  },

  updatePurchaseOrderListAPI: (purchase: any, id: string) => {
    let URL = `purchase-order/${id}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: purchase,
      },
      "masterManagement"
    );
  },

  deletePurchaseOrderListAPI: (params: any) => {
    return fetchURL(
      `purchase-order/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  getDropdownListForPurchaseOrderAPI: () => {
    let URL = `purchase-order/create-or-update-po/0`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deletePurchaseOrderDetailsAPI: (params: any) => {
    return fetchURL(
      `purchase-order-details/${params.id}`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  getPartsListAutoCompleteAPI: (params: any) => {
    let URL = `purchase-order/parts?partNo=` + params;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //purchase order related audiomodel dropdown
  getpurchaseOrderAudioModelAPI: (params: any) => {
    let URL = `purchase-order/audio-model/${params}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  sentMailToSupplierForPurchaseOrder: (params: any) => {
    let URL = `purchase-order/${params}/mail-po-to-supplier`;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "masterManagement"
    );
  },

  downLoadPurchaseOrder: (id: any) => {
    let URL = `purchase-order/${id}/download-pdf`;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "masterManagement",
      true,
      "purchase_order.pdf"
    );
  },

  //purchase inbound
  getPartsInboundListAPI: (params: any) => {

    let PartsInboundDataUrl = "parts-in-bound/paged?" + getQueryParams(params);
    return fetchURL(
      PartsInboundDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getPartsInboundById: (params: any) => {
    let PartsInboundDataUrl = `parts-in-bound/${params.id}/create-or-update-part-in-bound`;
    return fetchURL(
      PartsInboundDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  createPartsInboundApi: (data: any) => {
    return fetchURL(
      "parts-in-bound",
      {
        method: "POST",
        body: data,
      },
      "masterManagement"
    );
  },

  updatePartsInboundListAPI: (data: any) => {
    return fetchURL(
      `parts-in-bound/${data.action.payload.id}`,
      {
        method: "PUT",
        body: data.action.payload,
      },
      "masterManagement"
    );
  },
  deletePartsInboundListAPI: (params: any) => {
    return fetchURL(
      `parts-in-bound/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },
  getSupplierDropdownApi: () => {
    let supplierDataUrl = "stock-inspection/supplier-list";
    return fetchURL(
      supplierDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getInitiatedByDropdownApi: () => {
    let initiatedByDataUrl = "stock-adjustment/user-list";
    return fetchURL(
      initiatedByDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getStockLocationDropdownApi: () => {
    let stockLocationDataUrl = "stock-location";
    return fetchURL(
      stockLocationDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getPurchaseOrderDropdownApi: () => {
    let purchaseOrderDataUrl = "parts-in-bound/purchase-order-list";
    return fetchURL(
      purchaseOrderDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getPartsDropdownApi: () => {
    let partsDataUrl = "parts";
    return fetchURL(
      partsDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  partsSkugeneratedApi: (data: any) => {
    let url = "part-skus/part-sku";
    return fetchURL(
      url,
      {
        method: "POST",
        body: data.action,
      },
      "masterManagement"
    );
  },

  //stock Adjustment
  getStockAdjustmentListAPI: (params: any) => {

    let StockAdjustmentDataUrl =
      "stock-adjustment/paged?" + getQueryParams(params);
    return fetchURL(
      StockAdjustmentDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getPartsSkuListApiforStock: (params: any) => {
    let PartsSkuDataUrl = `stock-adjustment/part-sku-details/${params.supplierIds}?SKUNumber=${params.e} `;
    return fetchURL(
      PartsSkuDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getStockAdjustmentById: (params: any) => {
    let StockAdjustmentDataUrl = `stock-adjustment/create-or-update-stock-adjustment/${params.id}`;
    return fetchURL(
      StockAdjustmentDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  createStockAdjustmentApi: (data: any) => {
    return fetchURL(
      "stock-adjustment",
      {
        method: "POST",
        body: data,
      },
      "masterManagement"
    );
  },

  updateStockAdjustmentListAPI: (data: any) => {
    return fetchURL(
      `stock-adjustment/${data.action.payload.id}`,
      {
        method: "PUT",
        body: data.action.payload,
      },
      "masterManagement"
    );
  },
  deleteStockAdjustmentListAPI: (params: any) => {
    return fetchURL(
      `stock-adjustment/${params.id}`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  sentMailToStockAdjustment: (params: any) => {
    let URL = `stock-adjustment/${params}/mail-stock-adjustment-to-supplier`;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "masterManagement"
    );
  },

  downloadStockAdjustment: (params: any) => {
    let URL = `stock-adjustment/${params}/download-pdf`;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "masterManagement",
      true,
      "gatepass.pdf"
    );
  },

  getstokinspectionCourierApi: () => {
    let URL = "stock-adjustment/courier-dropdown-list";
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //stock inspection
  getStockInspectionListAPI: (params: any) => {
    let StockInspectionDataUrl =
      "stock-inspection/paged?" + getQueryParams(params);
    return fetchURL(
      StockInspectionDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  // create stock inspection
  createStockInspection: (stockInspection: any) => {
    return fetchURL(
      "stock-inspection/or-update-inspection",
      {
        method: "POST",
        body: stockInspection,
      },
      "masterManagement"
    );
  },

  // update stock inspection
  updateStockInspection: (data: any, id: string) => {
    return fetchURL(
      `stock-inspection/${id}`,
      {
        method: "PUT",
        body: data,
      },
      "masterManagement"
    );
  },
  deleteStockInspectionApi: (params: any) => {
    return fetchURL(
      `stock-inspection/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  sentMailToStockInspection: (params: any) => {
    let URL = `stock-inspection/${params}/mail-stock-inspection`;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "masterManagement"
    );
  },

  downloadStockInspectionAPI: (params: any) => {
    let StockRegisterDataUrl = `stock-inspection/${params}/download-pdf`;
    return fetchURL(
      StockRegisterDataUrl,
      {
        method: "POST",
      },
      "masterManagement",
      true,
      "stock_inspection.pdf"
    );
  },

  // get skus current status
  getSkuStatuses: (query: string) => {
    return fetchURL(
      `stock-inspection/part-sku-status-list${query}`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  //get by id
  getStockInspectionByIDAPI: (params: any) => {
    let StockInspectionDataUrl = "stock-inspection/" + params;
    return fetchURL(
      StockInspectionDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  //get part Sku By ID
  getStockInspectionPartSkuByIDAPI: (params: any) => {
    let StockInspectionDataUrl =
      "stock-inspection/" + params + "/part-sku-details";
    return fetchURL(
      StockInspectionDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getInitiatedByDropdownApiforStockInspection: () => {
    let initiatedByDataUrl = "stock-inspection/user-list";
    return fetchURL(
      initiatedByDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getSupplierPartSkuDetailApi: (params: any, StockLocationId: any) => {
    let audioBrandDropdownUrl = `stock-inspection/supplier-part-sku-list?supplierId=${params}&locationId=${StockLocationId}`;
    return fetchURL(
      audioBrandDropdownUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getAudioBrandPartSkuDetailApi: (params: any, StockLocationId: any) => {
    let audioBrandDropdownUrl = `stock-inspection/audio-brand-part-sku-list?audioBrandId=${params}&locationId=${StockLocationId}`;
    return fetchURL(
      audioBrandDropdownUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getAudioBrandforStockInspection: () => {
    let audioBrandDataUrl = "stock-inspection/audio-brand-list";
    return fetchURL(
      audioBrandDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getStockLocationforStockInspection: () => {
    let stockLocationDropdownDataUrl = "stock-inspection/stock-location-list";
    return fetchURL(
      stockLocationDropdownDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getScanPartSkuStockInspection: (params: any) => {
    let audioBrandDataUrl =
      "stock-inspection/part-sku-status-list?skuNos=" + params;
    return fetchURL(
      audioBrandDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getScanPartSkuWithLocationStockInspection: (params: any) => {
    debugger;
    let dynamicQuery = params?.supplierId
      ? `&supplierId=${params?.supplierId}`
      : params?.audioBrandId
        ? `&audioBrandId=${params?.audioBrandId}`
        : "";
    let audioBrandDataUrl =
      `stock-inspection/part-sku-detail?partSku=${params?.scanSku}&locationId=${params?.stockLocationId}` +
      dynamicQuery;
    return fetchURL(
      audioBrandDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getSkuNumberWithStatus: (payload: any, isSupplier: boolean) => {
    let url = "";
    if (isSupplier) {
      url = `stock-inspection/supplier-part-sku-list?supplierId=${payload?.supplierId}&locationId=${payload?.stockLocationId}&status=${payload?.status}`;
    } else {
      url = `stock-inspection/audio-brand-part-sku-list?audioBrandId=${payload?.audioBrandId}&locationId=${payload?.stockLocationId}&status=${payload?.status}`;
    }
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  checkPendingInspection: (payload: any, isSupplier: boolean) => {
    let url = "";
    if (isSupplier) {
      url = `stock-inspection/check-pending-inspection?locationId=${payload?.stockLocationId}&supplierId=${payload?.supplierId}`;
    } else {
      url = `stock-inspection/check-pending-inspection?audioBrandId=${payload?.audioBrandId}&locationId=${payload?.stockLocationId}`;
    }
    return fetchURL(
      url,
      {
        method: "POST",
        body: {},
      },
      "masterManagement"
    );
  },

  //stock register
  getStockRegisterListAPI: (params: any) => {
    let StockRegisterDataUrl =
      "stock-register/paged-list?" + getQueryParams(params);
    return fetchURL(
      StockRegisterDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getStockRegisterById: (params: any) => {
    let StockRegisterDataUrl = `stock-register/${params.id}`;
    return fetchURL(
      StockRegisterDataUrl,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  updateStockRegisterListAPI: (data: any) => {
    return fetchURL(`stockRegister/${data.action.payload.id}`, {
      method: "PUT",
      body: data.action.payload,
    });
  },

  deleteStockRegisterListAPI: (params: any) => {
    return fetchURL(`stockRegister/${params.id}`, {
      method: "DELETE",
    });
  },

  // Stock value by part

  getStockValuebyPartListAPI: (params: any) => {
    // let URL = "stock-reports/stock-value-by-part?" + getQueryParams(params);
    let URL = "stock-reports/stock-by-part-value?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
        // body: null,
      },
      "masterManagement"
    );
  },

  getStockQtybyPartListAPI: (params: any) => {
    // let URL = "stock-reports/stock-quantity-by-part?" + getQueryParams(params);
    let URL = "stock-reports/stock-by-part-quantity?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //getStock value by supplier
  getStockValuebySupplierAPI: (params: any) => {
    // let URL = "stock-reports/stock-value-by-supplier?" + getQueryParams(params);
    let URL = "stock-reports/stock-value-by-supplier-reports?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
        // body: null,
      },
      "masterManagement"
    );
  },
  //getStock quantity by supplier
  getStockQuantitybySupplierAPI: (params: any) => {
    // let URL = "stock-reports/stock-quantity-by-supplier?" + getQueryParams(params);
    let URL = "stock-reports/stock-quantity-by-supplier-reports?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
        // body: null,
      },
      "masterManagement"
    );
  },
  //getStock value musicSystemBrand
  getStockValuebymusicSystemBrandAPI: (params: any) => {
    // let URL =
    //   "stock-reports/stock-value-by-audio-brand?" + getQueryParams(params);
    let URL = "stock-reports/stock-value-by-audio-brand-reports?" + getQueryParams(params);
    return fetchURL(URL, { method: "GET" }, "masterManagement");
  },
  //getStock quaantity musicSystemBrand
  getStockQuantitybymusicSystemBrandAPI: (params: any) => {
    // let URL =
    //   "stock-reports/stock-quantity-by-audio-brand?" + getQueryParams(params);
    let URL = "stock-reports/stock-quantity-by-audio-brand-reports?" + getQueryParams(params);
    return fetchURL(URL, { method: "GET" }, "masterManagement");
  },

  //invoices
  getInvoicesList: (params: any) => {
    let URL = "invoice/paged?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  createInvoiceListApi: (data: any) => {
    return fetchURL(
      "invoice",
      {
        method: "POST",
        body: data,
      },
      "JobService"
    );
  },

  getByIdInvoiceApi: (params: any) => {
    let URL = `invoice/${params}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  getByIdInvoiceByPartsSkuIdApi: (params: any) => {
    let URL = `invoice/by-part-sku/${params}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  updateInvoiceApi: (invoice: any, id: string) => {
    let URL = `invoice/${id}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: invoice,
      },
      "JobService"
    );
  },

  dynamicFormFieldApi: () => {
    let url = `dynamicForm`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "jsonServer"
    );
  },

  //outgoingdispatch api

  fetchOutgoingDispatchApi: (params: any) => {
    let URL = "outgoing-dispatch/paged?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  getOutgoingDispatchById: (params: any) => {
    let URL = `outgoing-dispatch/${params}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  sendEmailOutgoingdispatchApi: (params: any) => {
    let URL = `outgoing-dispatch/${params}/send-mail`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: null,
      },
      "Courier"
    );
  },

  downloadPdfOutgoingdispatchApi: (params: any) => {
    let URL = `outgoing-dispatch/${params}/download-pdf`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: null,
      },
      "Courier",
      true,
      "outgoing_dispatch.pdf"
    );
  },

  getincomingDispatchList: (params: any) => {
    let URL = "incoming-dispatch/paged?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "Courier"
    );
  },
  getincomingDispatchById: (params: any) => {
    let incomingDispatchUrl = `incoming-dispatch/${params}`;
    return fetchURL(
      incomingDispatchUrl,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  sentEmailToOutgoingDispatch: (params: any) => {
    let incomingDispatchUrl = `outgoing-dispatch/${params}/send-mail`;
    return fetchURL(
      incomingDispatchUrl,
      {
        method: "POST",
      },
      "Courier"
    );
  },

  downloadOutgoingDispatch: (params: any) => {
    let incomingDispatchUrl = `outgoing-dispatch/${params}/download-pdf`;
    return fetchURL(
      incomingDispatchUrl,
      {
        method: "POST",
      },
      "Courier",
      true,
      "outgoing_dispatch.pdf"
    );
  },

  //stocklocation
  getStockLocationListAPI: (params: any) => {
    let customerDataURL = "stock-location/paged-list?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteStockLocationListAPI: (params: any) => {
    return fetchURL(
      `stock-location/${params.id}`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  postStockLocationAPI: (parts: any) => {
    let URL = "stock-location";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: parts,
      },
      "masterManagement"
    );
  },

  updateStockLocationAPI: (parts: any, partId: string) => {
    let URL = `stock-location/${partId}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: parts,
      },
      "masterManagement"
    );
  },

  getStockLocationByIdAPI: (id: string) => {
    let URL = `stock-location/${id}/by-id`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //Attendance
  getAttendanceListAPI: (params: any) => {
    let customerDataURL =
      "attendance/attendance-list?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  postAttendanceAPI: (attendance: any) => {
    let URL = "attendance/login";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: attendance,
      },
      "identity"
    );
  },

  updateAttendanceAPI: (attendance: any) => {
    let URL = "attendance/log-off";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: attendance,
      },
      "identity"
    );
  },

  getCurrentAttendanceAPI: (id: string) => {
    let URL = `attendance/current-state/${id}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "identity"
    );
  },
  getCurrentUserAttendanceListAPI: (params: any) => {
    let customerDataURL =
      "attendance/my-attendance-list?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  getUserAttendanceHoursAPI: () => {
    let URL = `attendance/total-login-hours`;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "identity"
    );
  },

  getAttendanceDetailByIdAPI: (userId: any, currentDate: any) => {
    let URL = `attendance/attendance-details/${userId}?date=${currentDate}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //attendance report
  getAttendanceReportListAPI: (params: any) => {
    let customerDataURL =
      "attendance/show-attendance-api?" + getQueryParams(params);
    return fetchURL(
      customerDataURL,
      {
        method: "GET",
      },
      "identity"
    );
  },
  //jobsheet

  getJobsheetListAPI: (params: any) => {
    /*  let jobsheetDataURL = "stock-location/paged-list?" + getQueryParams(params); */
    let jobsheetDispatchUrl = `jobsheet`;
    return fetchURL(
      jobsheetDispatchUrl,
      {
        method: "GET",
      },
      "jsonServer"
    );
  },

  deleteJobsheetListAPI: (params: any) => {
    return fetchURL(
      `job/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "JobService"
    );
  },

  //  API for INTENT

  getFranchiseListForIntent: () => {
    let URL = `intent/franchise-list`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getIntentListAPI: (params: any) => {
    let URL = "intent/paged?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  createIntent: (intent: IntentPost) => {
    return fetchURL(
      "intent",
      {
        method: "POST",
        body: intent,
      },
      "masterManagement"
    );
  },

  updateIntent: (intent: any, id: string) => {
    let URL = `intent/${id}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: intent,
      },
      "masterManagement"
    );
  },

  getIntentDetails: (id: string) => {
    let URL = `intent/${id}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  deleteIntentListAPI: (params: any) => {
    let URL = `intent/${params.id}?undo=` + params.undo;
    return fetchURL(
      URL,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },

  getPartsForIntentAutoCompleteAPI: (params: any) => {
    let URL = `intent/parts?partNo=` + params;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getPartSkuAutoCompleteAPI: (params: any) => {
    let URL = `part-skus/part-sku-dropdown-list?skuNumber=` + params;
    return fetchURL(
      URL,
      {
        method: "POST",
      },
      "masterManagement"
    );
  },

  getAudioModelAutoCompleteAPI: (params: any) => {
    let URL = `audio-model/audio-model-dropdown-list?modelNumber=` + params;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //  Intent deliverable

  approveIntent: (id: string, approvedNote: string) => {
    return fetchURL(
      `intent-deliverable/approve-intent/${id}?approvedNote=${approvedNote}`,
      {
        method: "POST",
        body: {},
      },
      "masterManagement"
    );
  },

  rejectIntent: (id: string, rejectedNote: string) => {
    return fetchURL(
      `intent-deliverable/reject-intent/${id}?rejectedNote=${rejectedNote}`,
      {
        method: "POST",
        body: {},
      },
      "masterManagement"
    );
  },

  receivedIntent: (payload: any) => {
    return fetchURL(
      `intent-deliverable/intent-received`,
      {
        method: "PUT",
        body: payload,
      },
      "masterManagement"
    );
  },

  getIntentCourierListApi: () => {
    // let url = `intent-deliverable/courier-dropdown-list`
    let url = `courier/0/courier-drop-down/0`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  getIntentDeliverablePersonApi: () => {
    return fetchURL(
      `intent-deliverable/user-list`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  postCourierIntentApi: (params: any, id: any) => {
    return fetchURL(
      `intent-deliverable/courier-intent/${id}`,
      {
        method: "POST",
        body: params,
      },
      "masterManagement"
    );
  },

  partSkuValidateApi: (params: any) => {
    debugger;
    return fetchURL(
      `part-skus/part-sKUFor-intend/${params.partId}?skuNumber=${params.skuNum}`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  printIntendApi: (params: any) => {
    return fetchURL(
      `intent/${params}/download-pdf`,
      {
        method: "POST",
      },
      "masterManagement",
      true,
      "Intend.pdf"
    );
  },

  emailIntendApi: (params: any) => {
    return fetchURL(
      `intent/${params}/send-mail`,
      {
        method: "POST",
      },
      "masterManagement",
    );
  },

  postIntentCourierStageChange: (params: any) => {
    return fetchURL(
      `intent-deliverable/user-list`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  dispatchIntent: (payload: any) => {
    return fetchURL(
      `intent-deliverable/intent-dispatch`,
      {
        method: "POST",
        body: payload,
      },
      "masterManagement"
    );
  },

  getDealerDropdownListApi: () => {
    let URL = `dealer-application-services/dealers-drop-down-list`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  getVehicleBrandRelatedDealerApi: (params: any) => {
    let URL = `dealer-application-services/create-or-update-dealer/${params}`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  getCustomerDropdownListApi: () => {
    let URL = `customer-application-services/customers-drop-down-list`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "identity"
    );
  },

  getAudioModelJobDeropdownList: (params: any) => {
    let URL = `audio-model/audio-model-drop-down-list/${params}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: null,
      },
      "masterManagement"
    );
  },

  getCourierListApi: (params: any) => {
    let Url = "courier/paged?" + getQueryParams(params);
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  viewCourierPage: (params: number) => {
    let Url = `courier/${params}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "Courier"
    );
  },
  createCourierApi: (courier: any) => {
    let url = `courier`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: courier,
      },
      "Courier"
    );
  },
  getByIdCourierApi: (params: any) => {
    let Url = `courier/${params}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "Courier"
    );
  },
  updateCourierApi: (courier: any, id: any) => {
    let Url = `courier/${id}`;
    return fetchURL(
      Url,
      {
        method: "PUT",
        body: courier,
      },
      "Courier"
    );
  },

  downloadCourierPage: (id: any) => {
    let Url = `courier/${id}/download-pDF`;
    return fetchURL(
      Url,
      {
        method: "POST",
        body: null,
      },
      "Courier",
      true,
      "courier.pdf"
    );
  },

  courierTrackingStatusApi: (id: any) => {
    let Url = `courier/${id}/tracking`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  //Dealer list for courier
  getDealerListApi: () => {
    let Url = `courier/create-or-update-courier/0`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "Courier"
    );
  },
  //dealer dropdown
  getDealerDropdownApi: () => {
    let Url = `dealer-application-services/0/dealers-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //dealer subuser dropdown
  getDealerSubuserDropdownApi: () => {
    let Url = `dealer-application-services/0/dealers-sub-user-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //getjobsheet reports
  getJobSheetReportsApi: (params: any) => {
    let url =
      "reports-application-servicve/job-sheet-reports?" +
      getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  //getJobsheetTat reports
  getJobSheetTatReportsApi: (params: any) => {
    let url =
      "reports-application-servicve/job-sheet-tat-reports?" +
      getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  //pending parts consolidated
  getPendingPartsConsolidatedListApi: (params: any) => {
    let url =
      "part-reports/pending-part-consolidated-report?" + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // Job Sheet -- start

  deleteJobSheetAPI: (params: any) => {
    return fetchURL(
      `job?undo=` + params.undo,
      {
        method: "DELETE",
      },
      "JobService"
    );
  },

  getAudioModelTotalAmountWithTaxForJobAPI: (id: any, isExchange: boolean) => {
    let url = `job/${id}/audio-model-total-amount-with-tax?isExchange=${isExchange}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getCustomerDropForJobAPI: () => {
    let url = `job/customer-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getpendingReasonDropdownList: () => {
    let URL = `pending-reason`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getDealerDropForJobAPI: () => {
    let url = `job/dealer-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getDealerWithVehicleBrandForJobAPI: (id: any) => {
    let url = `job/${id}/dealer-with-vehicle-brand`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getAudioModelDropdownForJobAPI: (id: any) => {
    let url = `job/${id}/audio-model-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getEmployeeDropdownForJobAPI: () => {
    let url = `job/employee-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getCourierDropdownForJobAPI: () => {
    let url = `job/courier-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getCourierDropdownForJobNewApi: (dealerOrCustomerId: any) => {
    let url = `courier/0/courier-drop-down/${dealerOrCustomerId}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  getCourierDropdownForAllJobNewApi: () => {
    let url = `courier/0/courier-drop-down/0`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  getPartsDropdownForJobAPI: () => {
    let url = `job/part`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getPartsDeatilsForJobAPI: (id: any) => {
    let url = `job/${id}/part`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getJobByIdAPI: (params: any) => {
    let url = `job/${params}/create-or-update-job`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getInvoiceDeleteAPI: (payload: any) => {
    let URL = `invoice/${payload.id}`;
    return fetchURL(
      URL,
      {
        method: "DELETE",
      },
      "JobService"
    );
  },

  getTaxByIdForJobAPI: (id: any = 0) => {
    // let url = `job/${id}/estimate-taxes`;
    let url = `taxes/${id}/tax-detail`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  receivedPaymentForInvoiceAPI: (id: any) => {
    let url = `received-payment/payment-by-invoice-id/${id}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  checkInvoiceDetailsFromJobIdApi: (id: any) => {
    let url = `invoice/invoice-by-job-id/${id}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  downloadPdfInvoicePage: (id: any) => {
    let url = `invoice/${id}/download-pdf`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: null,
      },
      "JobService",
      true,
      "invoice.pdf"
    );
  },

  sendEmailInvoicePage: (id: any) => {
    let url = `invoice/${id}/send-mail`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: null,
      },
      "JobService"
    );
  },

  sendWhatsappInvoicePage: (id: any) => {
    let url = `invoice/send-whats-app/${id}`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: null,
      },
      "JobService"
    );
  },

  sendInvoiceEmailForJobsheetApi: (params: any) => {
    let URL = `job/send-invoice-mail/${params}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: null,
      },
      "JobService"
    );
  },

  downloadInvoiceForJobsheetApi: (params: any) => {
    let URL = `job/download-job-invoice-pdf/${params}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: null,
      },
      "JobService",
      true,
      "invoice.pdf"
    );
  },

  // invoiceTypeDropdown
  getInvoicePageInvoiceType: () => {
    let url = `invoice/invoice-type`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  //joblist dropdown

  getInvoicePageJoblistType: () => {
    let url = `invoice/job-sheet-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getEnquiryPageJoblis: () => {
    let url = `job/enquiry-job-sheet-drop-down`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getJobPagesAPI: (params: any) => {
    let url = `job/paged?` + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getJobPagesforFeedbackListApi: (
    dealerOrcustomerId: any,
    feedbackStatus: boolean,
    jobStatus: any
  ) => {
    let url = `job/paged?DealerId=${dealerOrcustomerId}&Status=${jobStatus}&FeedBackPending=${feedbackStatus}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  checkHistoricJobAPI: (params: any) => {
    let url = `job/paged?AudioSerialNumber=` + params;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  printServiceRequestAPI: (params: any) => {
    let url = `job/${params}/download-pdf-for-service-request`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService",
      true,
      "job_sheet.pdf"
    );
  },

  printExchangeInvoiceAPI: (params: any) => {
    let url = `job/download-invoice-pdf/${params}`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService",
      true,
      "exchangeInvoice.pdf"
    );
  },

  downloadJobSheetAPI: (params: any) => {
    let url = `job/${params}/download-pdf`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService",
      true,
      "job_sheet.pdf"
    );
  },

  downloadEstimateForJobAPI: (params: any) => {
    let url = `job/${params}/download-pdf-for-estimate`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService",
      true,
      "estimate.pdf"
    );
  },

  sendMailEstimateForJobAPI: (params: any) => {
    // let url = `job/send-mail/${params}`;
    let url = `job/send-estimation-mail/${params}`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService"
    );
  },

  emailExchangeInvoiceAPI: (params: any) => {
    let url = `job/${params}/send-exchange-invoice-mail`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService"
    );
  },

  emailServiceHistoryAPI: (params: any) => {
    let url = `job/${params}/pending-mail-notifer-test`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService"
    );
  },

  deleteAdditionalAPI: (params: any) => {
    // let url = `job/document/${params}`;
    let url = `additional-expense/${params}`;
    return fetchURL(
      url,
      {
        method: "DELETE",
      },
      "JobService"
    );
  },

  deleteAdditionalDocumentAPI: (params: any) => {
    let url = `job/document/${params}`;
    return fetchURL(
      url,
      {
        method: "DELETE",
      },
      "JobService"
    );
  },

  sendSMSEstimateForJobAPI: (params: any) => {
    let url = `job/send-sms-for-estimation/${params}`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "JobService"
    );
  },

  getConcernImageForJobAPI: (params: any) => {
    let url = `job/signature-link/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getPrepareyIdData: (params: any) => {
    let url = `job/estimator-details?Guid=${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  franchiseArrivedApi: (content: any) => {
    let url = `job/franchise-reached-job`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: content,
      },
      "JobService"
    );
  },

  getLatlngApi: (content: any) => {
    let params = {
      filters : {
        address : {value : content ,matchmode : "contains"}
      }
    }
    let url = `job/lat-lang?` + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  franchiseUploadDocumentApi: (id: any, content: any, type: any) => {
    debugger;
    let url = `job/upload-franchise-job-completed-documents/${id}?type=${type}`;
    const updatedData = content.map(({ type, ...rest }: any) => rest);
    return fetchURL(
      url,
      {
        method: "POST",
        body: content,
        formData: true,
        additionalExpense: true,
      },
      "JobService"
    );
  },

  approvedocumentApi: (id: any, content: any) => {
    let url = `job/upload-estimation-documents/${id}`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: content,
        formData: true,
        approveDocument: true,
      },
      "JobService"
    );
  },

  getAdditionalPagesAPI: (params: any) => {
    // let url = `additional-expense/paged-list?` + getQueryParams(params);
    let url = `additional-expense/job-additional-expenses/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getAdditionalExpenseWithoutQueryAPI: (params: any) => {
    // let url = `additional-expense/paged-list?JobId=${params}`;
    let url = `additional-expense/job-additional-expenses/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  additionalExpenseApproveAPI: (params: any) => {
    let url = `additional-expense/${params}/approve-additional-expense`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: null,
      },
      "JobService"
    );
  },

  additionalExpenseUpdateAPI: (params: any, body: any) => {
    let url = `additional-expense/or-update/${params}`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: body,
      },
      "JobService"
    );
  },

  newadditionalExpenseUpdateAPI: (params: any, body: any) => {
    let url = `additional-expense/${params}`;
    return fetchURL(
      url,
      {
        method: "PUT",
        body: body,
      },
      "JobService"
    );
  },

  additionalExpenseDetailsAPI: (params: any) => {
    let url = `additional-expense/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getFranchiseDoucumentForexpenses: (params: any) => {
    let url = `job/franchise-uploaded-documents/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getApproveDoucumentApi: (params: any) => {
    let url = `job/estimation-documents/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getFranchiseDoucumentLinkApi: (params: any) => {
    let url = `job/document-link/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getAdditionalExpensesDoucumentLinkApi: (params: any) => {
    let url = `additional-expense/file-link/${params}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  updatePendingCommentForJobAPI: (payload: any) => {
    let URL = `job/pending-comment-update`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It update pending comments

  deletePendingCommentForJobAPI: (id: any) => {
    let URL = `job/${id}/pending-comments`;
    return fetchURL(
      URL,
      {
        method: "DELETE",
      },
      "JobService"
    );
  }, // It delete pending commands

  updateIncomingDispatchForJobAPI: (payload: any, id: string) => {
    let URL = `job/${id}/incoming-dispatch`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  updateOutgoingDispatchForJobAPI: (payload: any, id: string) => {
    let URL = `job/${id}/outgoing-dispatch`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  postJobsheetAPI: (payload: any, isNextState: boolean) => {
    let URL = `job/new-job?CreateServiceRequest=${isNextState}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  updateJobsheetAPI: (payload: any) => {
    let URL = `job?Id=${payload.id}`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: payload,
      },
      "JobService"
    );
  },

  additionalExpenseApi: (payload: any, job_id: any) => {
    let URL = `additional-expense/or-update/${job_id}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  updateReplacementSystemDispatchAPI: (payload: any) => {
    let URL = `job/exchange-job-sheet`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  updateCreateJobStatusAPI: (payload: any) => {
    let URL = `job/job-sheet`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It create job

  updateAssignToEstimateJobStatusAPI: (payload: any) => {
    let URL = `job/assign-to-estimator`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It create assogn to estimator job

  updateEstimateCompeteJobStatusAPI: (payload: any) => {
    let URL = `job/estimate-job`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It create assogn to estimator job

  updateEstimateApprovalJobStatusAPI: (payload: any) => {
    let URL = `job/estimation-approval`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  normalUpdateEstimate: (payload: any) => {
    let URL = `job/estimate`;
    return fetchURL(
      URL,
      {
        method: "PUT",
        body: payload,
      },
      "JobService"
    );
  }, 

  rejectEstimationAPI: (payload: any) => {
    let URL = `job/estimation-rejected`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It Reject Estimation

  updateZeroEstimateAssignToTechnicianAPI: (payload: any) => {
    let URL = `job/assignable-to-technician`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It create Job Assignable To Technician

  updateAssignToTechnicianStatusAPI: (payload: any) => {
    let URL = `job/assign-to-technician`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It create Assigned to Technician

  updateReadyToInvoiceStatusAPI: (payload: any) => {
    let URL = `job/ready-to-invoice`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  }, // It create ready to invoice

  updateJobInvoicedStatusAPI: (id: any) => {
    let URL = `job/job-invoiced/${id}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: {},
      },
      "JobService"
    );
  }, // It create ready to invoice

  updateJobClosedStatusAPI: (id: any) => {
    let URL = `job/job-close/${id}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: {},
      },
      "JobService"
    );
  },

  franchiseJobClosedStatusAPI: (id: any, otpValue: any) => {
    let URL = `job/franchise-job-completed/${id}?otpValue=${otpValue}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: {},
      },
      "JobService"
    );
  },

  updateJobCancelStatusAPI: (id: any) => {
    let URL = `job/job-cancel/${id}`;
    return fetchURL(
      URL,
      {
        method: "POST",
        body: {},
      },
      "JobService"
    );
  }, // It create ready to invoice

  //getmonthlypartsSummary Api
  getMonthlyPartsSummarysApi: (params: any) => {
    let url =
      "part-reports/monthly-parts-summary-report?" + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //getPending Parts Api
  getPendingPartsApi: (params: any) => {
    let url = "part-reports/pending-parts-report?" + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //received payment
  getReceivedPaymentListApi: (params: any) => {
    let url = "receivedPayment?" + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "jsonServer"
    );
  },

  viewReceivedPaymentApi: (params: number) => {
    let Url = `receivedPayment/${params}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "jsonServer"
    );
  },
  createReceivedPaymentApi: (params: number) => {
    let Url = `createReceivedPayment`;
    return fetchURL(
      Url,
      {
        method: "POST",
        body: params,
      },
      "jsonServer"
    );
  },

  getTechnicianDropdownApi: () => {
    let Url = `job/franchise-drop-downs`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getEstimtoroneDropdownApi: () => {
    let Url = `job/dealer-drop-down`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  getEstimtorTwoDropdownApi: () => {
    let Url = `job/employee-drop-down`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getVehiclebrandDropdownApi: () => {
    let Url = "job/0/dealer-with-vehicle-brand";
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getInvoiceByJobIdApi: (id: any) => {
    let Url = `invoice/invoice-by-job-id/${id}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  // invoice
  getCustomerDropForInvoice: () => {
    let Url = `invoice/customer-drop-down`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  getDealerDropForInvoice: () => {
    let Url = `invoice/dealer-drop-down`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  getPartsSkuListForInvoice: (partNumber: any) => {
    // let Url = `invoice/part-sku-lists?PartSkuNumber=${partNumber}&Status=Unused`;
    let Url = `part-skus/part-sku-detail-for-invoice?partSkuNo=${partNumber}&Status=Unused`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  getTaxListForInvoice: (id: number = 0) => {
    let Url = `invoice/${id}/taxes`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  createInvoiceApi: (payload: any) => {
    let Url = `invoice`;
    return fetchURL(
      Url,
      {
        method: "POST",
        body: payload,
      },
      "jsonServer"
    );
  },

  //  Received Payment

  getReceivedPaymentById: (id: any) => {
    let Url = `received-payment/${id}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  getReceivedPaymentGrid: (params: any) => {
    debugger;
    let Url = `received-payment/paged?` + getQueryParams(params);
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  // download received payment
  downloadPdf: (id: string) => {
    let Url = `received-payment/${id}/download-pdf`;
    return fetchURL(
      Url,
      {
        method: "POST",
      },
      "payment",
      true,
      "received payment.pdf"
    );
  },

  createReceivedPayment: (payload: any) => {
    let Url = `received-payment`;
    return fetchURL(
      Url,
      {
        method: "POST",
        body: payload,
      },
      "payment"
    );
  },

  getDealerDropForReceivedPayment: () => {
    let Url = `received-payment/dealer-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  getCustomerDropForReceivedPayment: () => {
    let Url = `received-payment/customer-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  getInvoiceDropForReceivedPayment: () => {
    let Url = `received-payment/invoice-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  getInvoiceDropForReceivedPaymentCreate: (dealerId: any, customerId: any) => {
    let Url = "";
    if (dealerId && customerId) {
      Url = `received-payment/invoice-drop-down-list-for-create?dealerId=${dealerId}&customerId=${customerId}`;
    } else if (dealerId && !customerId) {
      Url = `received-payment/invoice-drop-down-list-for-create?dealerId=${dealerId}`;
    } else if (!dealerId && customerId) {
      Url = `received-payment/invoice-drop-down-list-for-create?customerId=${customerId}`;
    } else {
      Url = `received-payment/invoice-drop-down-list-for-create`;
    }
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  getInvoiceDropForReceivedPayNew: () => {
    let Url = `invoice/invoice-drop-down-list/0`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getInvoiceDetailForReceivedPayment: (id: any) => {
    let Url = `received-payment/${id}/invoice-detail`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "payment"
    );
  },

  getInvoiceDetailForReceivedPaymentNew: (id: any) => {
    let Url = `invoice/${id}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getUserTypeIdByuseUuidApi: (id: any) => {
    let url = `users/${id}`;
    return fetchURL(url, {
      method: "GET",
    });
  },

  //  Dropdown service from identity service

  // customer dropdown
  getCustomerDrpFromIdentity: () => {
    let Url = `customer-application-services/0/customer-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //  Dealer dropdown
  getDealerDrpFromIdentity: () => {
    let Url = `dealer-application-services/0/dealers-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //  Employee dropdown
  getEmployeeDrpFromIdentity: () => {
    let Url = `employee-application-services/0/employee-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //  Franchise dropdown
  getFranchiseDrpFromIdentity: () => {
    let Url = `franchise/0/franchise-drop-down-list`;
    // let Url = `franchise/0/franchise-drop-down-list-with-location`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },
  getFranchiseDrpFromIdentityWithLocation: () => {
    let Url = `franchise/0/franchise-drop-down-list-with-location`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },
  getFranchiseSubuserDrpFromIdentity: () => {
    let Url = `franchise/0/franchise-sub-user-drop-down-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //  Dropdown service from Master Management

  // AudioBrand
  getAudioBrandFromMasterManagement: (BrandName: any = "") => {
    let Url = `audio-brand?BrandName=${BrandName}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // AudioModel
  getAudioModelFromMasterManagement: (Number: any = "") => {
    let Url = `audio-model?Number=${Number}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // Intent
  getIntentFromMasterManagement: () => {
    let Url = `intent`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // IntentFranchise
  getIntentFranchiseFromMasterManagement: () => {
    let Url = `intent/franchise-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // CourierDropDowm
  getCourierDropDowmFromMasterManagement: () => {
    // let Url = `intent-deliverable/courier-dropdown-list`;
    let Url = `courier/0/courier-drop-down/0`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "Courier"
    );
  },

  // IntentDeliverableUserList
  getIntentDeliverableUserListFromMasterManagement: () => {
    let Url = `intent-deliverable/user-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // parts
  getPartsFromMasterManagement: () => {
    let Url = `parts`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // PartInBoundDropdownList
  getPartInBoundDropdownListFromMasterManagement: (PartNumber: any) => {
    let Url = `parts-in-bound/part-drop-down-list?PartNumber=${PartNumber}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // PurchaseOrederList
  getPurchaseOrederListFromMasterManagement: (PoNumber: any) => {
    let Url = `parts-in-bound/purchase-order-list?PoNumber=${PoNumber}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // SupplierList

  getSupplierListFromMasterManagement: (Name: any) => {
    let Url = `parts-in-bound/supplier-list?Name=${Name}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // StockAdjusment

  getStockAdjusmentFromMasterManagement: () => {
    let Url = `stock-adjustment`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  getStockAdjusmentFromPartsSkuId: (partsSkuId: any) => {
    let Url = `stock-adjustment/stock-adjustment/${partsSkuId}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // StockAdjusmentUserList
  getStockAdjusmentUserListFromMasterManagement: () => {
    let Url = `stock-adjustment/user-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // StockInspetionList
  getStockInspetionListFromMasterManagement: () => {
    let Url = `stock-inspection`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // StockInspectionAudioBrandList
  getStockInspectionAudioBrandListFromMasterManagement: () => {
    let Url = `stock-inspection/audio-brand-list`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  // StockLocation
  getStockLocationFromMasterManagement: (LocationName: any) => {
    let Url = `stock-location?LocationName=${LocationName}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //  VehicleBrandList
  getVehicleBrandListFromMasterManagement: (Name: any) => {
    let Url = `vehicle-brand?Name=${Name}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  //feedback

  getFeedbackListApi: (params: any) => {
    let Url = `feedback/paged?` + getQueryParams(params);
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getFeedbackVehiclebrandApi: () => {
    let Url = `job/vehicle-brand-data`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  getFeedbackIdDetailsApi: (id: any) => {
    let Url = `feedback/${id}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  createFeedbackForJobsheet: (payload: any) => {
    let Url = `feedback/feedback`;
    return fetchURL(
      Url,
      {
        method: "POST",
        body: payload,
      },
      "JobService"
    );
  },

  //get userID related contact details

  getContactDetailsrelatedUserIdApi: (userId: any) => {
    let Url = `contact-detail/by-user/${userId}`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },

  //get mel employee address
  getmelEmployeeAddressApi: () => {
    let Url = `employee-application-services/mel-address`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "identity"
    );
  },
  fetchSupplierdocumentApi: (params: any) => {
    return fetchURL(
      `supplier-contact-detail/${params}/document-list`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  fetchSupplierLinkdocumentApi: (params: any) => {
    return fetchURL(
      `supplier-contact-detail/${params}/document-link`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  deleteSupplierDocumentApi: (params: any) => {
    return fetchURL(
      `supplier-contact-detail/${params}/document`,
      {
        method: "DELETE",
      },
      "masterManagement"
    );
  },
  uploadSupplierDoumentApi: (supplier: any, { id }: any, fileType: any) => {
    return fetchURL(
      `supplier-contact-detail/${id}/document?fileType=${fileType}`,
      {
        method: "POST",
        file: supplier,
        formData: true,
      },
      "masterManagement"
    );
  },
  //dashboard
  fetchDealerCountApi: () => {
    return fetchURL(
      `dealer-application-services/dealer-count`,
      {
        method: "GET",
      },
      "identity"
    );
  },

  fetchSupplierCountApi: () => {
    return fetchURL(
      `supplier/supplier-count`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  fetchFranchiseeCountApi: () => {
    return fetchURL(
      `franchise/franchise-count`,
      {
        method: "GET",
      },
      "identity"
    );
  },

  fetchCustomerCountApi: () => {
    return fetchURL(
      `customer-application-services/customer-count`,
      {
        method: "GET",
      },
      "identity"
    );
  },

  fetchJobsheetStatusCountApi: (params: any) => {
    if (!params.payload) {
      return fetchURL(
        `job/job-status-dashboard-detail`,
        {
          method: "GET",
        },
        "JobService"
      );
    } else {
      return fetchURL(
        `job/job-status-dashboard-detail?month=${params.payload.month}&year=${params.payload.year}`,
        {
          method: "GET",
        },
        "JobService"
      );
    }
  },

  fetchJobsheetTrendCountApi: (params: any) => {
    return fetchURL(
      `job/trend-chart-dashboard-detail?month=${params.payload.month}&year=${params.payload.year}`,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  fetchstockAdjustmentCountApi: (params: any) => {
    return fetchURL(
      `stock-adjustment/ge-stock-adjusment-dashboard-detail?month=${params.payload.month}&year=${params.payload.year}`,
      {
        method: "POST",
        body: null,
      },
      "masterManagement"
    );
  },

  fetchPurchaseOrderDashboardApi: (params: any) => {
    return fetchURL(
      `purchase-order/p-oDashboard-detail?month=${params.payload.month}&year=${params.payload.year}`,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },

  fetchJobsheetGraphDetailApi: () => {
    return fetchURL(
      `job/dashboard-graph-detail`,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getInvoiceCountApi: () => {
    return fetchURL(
      `invoice/invoice-dashboard-graph-detail`,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getReceivedpaymentCountApi: () => {
    return fetchURL(
      `invoice/invoice-received-payment-dashboard-graph-detail`,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getPendingpaymentCountApi: () => {
    return fetchURL(
      `invoice/invoice-pending-payment-dashboard-graph-detail`,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  //ticket sysytem
  getEnquiryListApi: (params: any) => {
    let url = "enquiry?" + getQueryParams(params);
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "ticketSystem"
    );
  },

  createEnquiryApi: (quirydata: any) => {
    let url = "enquiry";
    return fetchURL(
      url,
      {
        method: "POST",
        formData: true,
        createquery: true,
        body: quirydata,
      },
      "ticketSystem"
    );
  },
  getInduvidualEnquiryApi: (id: string) => {
    let url = `enquiry/${id}/enquiry`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "ticketSystem"
    );
  },
  replyEnquiryApi: (id: string, replyerData: any) => {
    let url = `enquiry/reply/${id}`;
    return fetchURL(
      url,
      {
        method: "POST",
        formData: true,
        createquery: false,
        replyQuery: true,
        body: replyerData,
      },
      "ticketSystem"
    );
  },
  responseEnquiryApi: (id: string, replyerData: any) => {
    let url = `enquiry/response/${id}`;
    return fetchURL(
      url,
      {
        method: "POST",
        formData: true,
        createquery: false,
        replyQuery: true,
        body: replyerData,
      },
      "ticketSystem"
    );
  },

  getEnquiryDocumentApi: (id: string, enquiry: string) => {
    let url = `document/${id}/documents?sourceType=${enquiry}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "ticketSystem"
    );
  },
  getEnquiryDocumentLinkApi: (id: string) => {
    let url = `document/${id}/document-link`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "ticketSystem"
    );
  },
  getReplyMessageFromEmployeeApi: (id: string) => {
    let url = `enquiry/reply-by-ticket-id/${id}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "ticketSystem"
    );
  },
  getBulkEmailListAPI: (params: any) => {
    let bulkEmailListURL = "mass-email/paged?" + getQueryParams(params);
    return fetchURL(
      bulkEmailListURL,
      {
        method: "GET",
      },
      "MassEmail"
    );
  },
  getSupplierDropdown: () => {
    let Url = `supplier/0/supplier-drop-down`;
    return fetchURL(
      Url,
      {
        method: "GET",
      },
      "masterManagement"
    );
  },
  createBulkEmailApi: (params: any) => {
    return fetchURL(
      `mass-email/or-update-mass-mail`,
      {
        method: "POST",
        body: params,
      },
      "MassEmail"
    );
  },

  deleteBulkEmailApi: (id: any) => {
    return fetchURL(
      `mass-email/${id}`,
      {
        method: "DELETE",
      },
      "MassEmail"
    );
  },
  //bulk sms
  getBulkSmsListAPI: (params: any) => {
    let bulkSmsListURL = "bulk-sMS/paged?" + getQueryParams(params);
    return fetchURL(
      bulkSmsListURL,
      {
        method: "GET",
      },
      "MassSms"
    );
  },
  createBulkSmsApi: (params: any) => {
    return fetchURL(
      `bulk-sMS/or-update-bulk-sMS`,
      {
        method: "POST",
        body: params,
      },
      "MassSms"
    );
  },
  deleteBulkSmsApi: (id: any) => {
    return fetchURL(
      `bulk-sMS/${id}/cancel`,
      {
        method: "POST",
      },
      "MassSms"
    );
  },

  reAssignTicketApi: (
    ticketId: string,
    supporterId: string,
    supporterName: string
  ) => {
    return fetchURL(
      `enquiry/reassign?ticketId=${ticketId}&supportorId=${supporterId}&supporterName=${supporterName}`,
      {
        method: "POST",
        body: null,
      },
      "ticketSystem"
    );
  },
  reOpenTicketApi: (ticketId: string) => {
    let url = `enquiry/reopen/${ticketId}`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: null,
      },
      "ticketSystem"
    );
  },
  closeTicketApi: (ticketId: string) => {
    let url = `enquiry/close/${ticketId}`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: null,
      },
      "ticketSystem"
    );
  },

  getBulkEmailByIdApi: (id: any) => {
    return fetchURL(
      `mass-email/${id}`,
      {
        method: "GET",
      },
      "MassEmail"
    );
  },

  getBulkSmsByIdApi: (id: any) => {
    return fetchURL(
      `bulk-sMS/${id}`,
      {
        method: "GET",
      },
      "MassSms"
    );
  },

  getBulkEmailStatusApi: () => {
    return fetchURL(
      `mass-email/status-list`,
      {
        method: "GET",
      },
      "MassEmail"
    );
  },

  getBulkSmsStatusApi: () => {
    return fetchURL(
      `bulk-sMS/status-list`,
      {
        method: "GET",
      },
      "MassSms"
    );
  },

  //incentive list page
  getIncentiveListAPI: (params: any) => {
    let incentiveDataURL = "incentive-master/incentive-master-list";
    return fetchURL(
      incentiveDataURL,
      {
        method: "GET",
      },
      "app"
    );
  },

  getIncentiveEmptyListAPI: () => {
    let incentiveDataURL = "incentive-master/process-details";
    return fetchURL(
      incentiveDataURL,
      {
        method: "GET",
      },
      "app"
    );
  },

  UpdateIncentiveListAPI: (incentive: any) => {

    let incentiveDataURL = "incentive-master/list";
    return fetchURL(
      incentiveDataURL,
      {
        method: "PUT",
        body: incentive,
      },
      "app"
    );
  },

  //reward value page
  getRewardValueAPI: () => {
    let rewardValueDataURL = "reward-value/reward-value";
    return fetchURL(
      rewardValueDataURL,
      {
        method: "GET",
      },
      "app"
    );
  },
  createRewardValueAPI: (rewardValue: any) => {
    let rewardValueDataURL = "reward-value/reward-value";
    return fetchURL(
      rewardValueDataURL,
      {
        method: "PUT",
        body: rewardValue,
      },
      "app"
    );
  },

  //total working days value page
  getTotalWorkingDaysAPI: (params: any) => {
    let totalWorkingDaysDataURL =
      "total-working-days/paged-list?" + getQueryParams(params);
    return fetchURL(
      totalWorkingDaysDataURL,
      {
        method: "GET",
      },
      "app"
    );
  },

  getMonthsForTotalWorkingDaysAPI: () => {
    let totalWorkingDaysDataURL = "total-working-days/month-list";
    return fetchURL(
      totalWorkingDaysDataURL,
      {
        method: "GET",
      },
      "app"
    );
  },
  createTotalDaysAPI: (rewardValue: any) => {
    let rewardValueDataURL = "total-working-days/or-update";
    return fetchURL(
      rewardValueDataURL,
      {
        method: "POST",
        body: rewardValue,
      },
      "app"
    );
  },
  getTotalWorkingDayByIdAPI: (id: any) => {
    let totalWorkingDaysDataURL = `total-working-days/${id}/by-id`;
    return fetchURL(
      totalWorkingDaysDataURL,
      {
        method: "GET",
      },
      "app"
    );
  },

  getJobEnquiryListApi: (params: any) => {
    let URL = "job-enquiry/paged?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  createJobEnquiryListApi: (enquiry: any) => {
    let URL = "job-enquiry/job-enquiriy";
    return fetchURL(
      URL,
      {
        method: "POST",
        body: enquiry,
      },
      "JobService"
    );
  },
  getEnquiryByIdApi: (id: any) => {
    let URL = `job-enquiry/${id}/by-id`;
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },
  getJobEnquiryListForJobsheetViewApi: (params: any) => {
    let URL = "job-enquiry/by-job-id?" + getQueryParams(params);
    return fetchURL(
      URL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getFranchiseJoblistType: (id: any) => {
    let url = `job/enquiry-job-sheet-drop-down/${id}`;
    return fetchURL(
      url,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  //incentive reward
  getIncentiveRewardListAPI: (params: any) => {
    let incentiveRewardDataURL =
      "incentive-reward-point-master/paged-list?" + getQueryParams(params);
    return fetchURL(
      incentiveRewardDataURL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getIncentiveRewardByIdAPI: (id: any) => {
    let incentiveDataURL = `incentive-reward-point-master/${id}/total-incentive-detail`;
    return fetchURL(
      incentiveDataURL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  UpdateAdjustRewardPointAPI: ({ id, payload }: any) => {
    let incentiveDataURL = `incentive-reward-point-master/${id}/adjustment-point`;
    return fetchURL(
      incentiveDataURL,
      {
        method: "PUT",
        body: payload,
      },
      "JobService"
    );
  },

  UpdatePaymentForIncentiveAPI: ({ id, payload }: any) => {
    let incentiveDataURL = `incentive-reward-point-master/${id}/payment-detail`;
    return fetchURL(
      incentiveDataURL,
      {
        method: "PUT",
        body: payload,
      },
      "JobService"
    );
  },

  //Additional Expence
  getAdditionalExpenceListAPI: (params: any) => {
    let incentiveRewardDataURL =
      "monthly-additional-expense-master/paged-list?" + getQueryParams(params);
    return fetchURL(
      incentiveRewardDataURL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  getadditionalExpenseReportsListByIdAPI: (id: any) => {
    let incentiveDataURL = `monthly-additional-expense-master/${id}/additional-expense-detail`;
    return fetchURL(
      incentiveDataURL,
      {
        method: "GET",
      },
      "JobService"
    );
  },

  UpdateAdjustRewardPointForAdditionalExpenseAPI: ({ id, payload }: any) => {
    let additionalExpenseDataURL = `monthly-additional-expense-master/${id}/adjustment-point`;
    return fetchURL(
      additionalExpenseDataURL,
      {
        method: "PUT",
        body: payload,
      },
      "JobService"
    );
  },

  UpdatePaymentForAdditionalExpenseAPI: ({ id, payload }: any) => {
    let additionalExpenseDataURL = `monthly-additional-expense-master/${id}/payment-detail`;
    return fetchURL(
      additionalExpenseDataURL,
      {
        method: "PUT",
        body: payload,
      },
      "JobService"
    );
  },
  getUserRoleAPI: () => {
    let RoleUrl = "roles/all";
    return fetchURL(
      RoleUrl,
      {
        method: "GET",
      },
      "identity"
    );
  },
  getPermissionDetailsAPI: (params: any) => {

    let permissionUrl = `permissions?providerName=R&providerKey=${params}`;
    return fetchURL(
      permissionUrl,
      {
        method: "GET",
      },
      "permissionManagement"
    );
  },
  updatePermissionDetailsAPI: (role: any, permission: any) => {
    let permissionUrl = `permissions?providerName=R&providerKey=${role}`;
    return fetchURL(
      permissionUrl,
      {
        method: "PUT",
        body: permission,
      },
      "permissionManagement"
    );
  },
  createRoleAPI: (role: any) => {
    let roleUrl = "roles";
    return fetchURL(
      roleUrl,
      {
        method: "POST",
        body: role,
      },
      "identity"
    );
  },
  getWhatsAppListAPI: (params: any) => {
    let whatsAppListURL = "bulk-whats-app/paged?" + getQueryParams(params);
    return fetchURL(
      whatsAppListURL,
      {
        method: "GET",
      },
      "whatsApp"
    );
  },
  creatWhatsAppMessageApi: (content: any) => {
    let url = `bulk-whats-app/or-update-bulk-whats-app`;
    return fetchURL(
      url,
      {
        method: "POST",
        body: content,
        formData: true,
        whatsAppFormData: true,
      },
      "whatsApp"
    );
  },
  getWhatsAppByIdApi: (id: any) => {
    return fetchURL(
      `bulk-whats-app/${id}`,
      {
        method: "GET",
      },
      "whatsApp"
    );
  },
  getWhatsAppDocumentLinkApi: (id: any) => {
    return fetchURL(
      `bulk-whats-app/document-link/${id}`,
      {
        method: "GET",
      },
      "whatsApp"
    );
  },
  deleteWhatsAppApi: (id: any) => {
    return fetchURL(
      `bulk-whats-app/${id}/cancel`,
      {
        method: "POST",
      },
      "whatsApp"
    );
  },
  getPaymentLink: (id: any) => {
    let url = `payment-gateway/generate-payment-link/${id}`;
    return fetchURL(
      url,
      {
        method: "POST",
      },
      "payment"
    );
  },

  uploadDealerSubuserImage: (employee: any, id: any) => {
    return fetchURL(
      `dealer-application-services/${id}/sub-dealer-profile?fileType=Profile`,
      {
        method: "PUT",
        file: employee,
        formData: true,
      }
    );
  },
  fetchDealerSubuserImage: (params: any) => {
    return fetchURL(
      `dealer-application-services/${params}/sub-dealer-profile-link`,
      {
        method: "GET",
      }
    );
  },
  deleteDealerSubuserImage: (params: any) => {
    return fetchURL(
      `dealer-application-services/${params}/sub-dealer-profile`,
      {
        method: "DELETE",
      }
    );
  },
  uploadFranchiseSubuserImage: (image: any, id: any) => {
    return fetchURL(`franchise/${id}/sub-franchise-profile?fileType=Profile`, {
      method: "PUT",
      file: image,
      formData: true,
    });
  },
  fetchFranchiseSubuserImage: (params: any) => {
    return fetchURL(`franchise/${params}/sub-franchise-profile-link`, {
      method: "GET",
    });
  },
  deleteFranchiseSubuserImage: (params: any) => {
    return fetchURL(`franchise/${params}/sub-franchise-profile`, {
      method: "DELETE",
    });
  },
  // setCustomerEmailApi: (id: any, email: any) => {
  //   debugger;
  //   const url = new URL( `customer-application-services/set-customer-email/${id}` );
  //   const params = { email:email };
  //   url.search = new URLSearchParams(params).toString();
  //   // return fetchURL(
  //   //   `customer-application-services/set-customer-email/${id}?email=${String(
  //   //     email
  //   //   )}`,
  //   //   {
  //   //     method: "POST",
  //   //   }
  //   // );
  //   return fetchURL(
  //       `customer-application-services/set-customer-email/${id}?email=${String(
  //         email
  //       )}`,
  //       {
  //         method: "POST",
  //       }
  //     );
  // },
  setCustomerEmailApi: (id: any, email: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/customer-application-services/set-customer-email/${id}`);
    const params = { email: email };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },

  setCustomerPhoneApi: (id: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/customer-application-services/send-otp/${id}`);
    const params = { phoneNumber: phoneNumber };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },

  sendCustomerOtp: (id: any, code: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/customer-application-services/verify-otp/${id}`);
    const params = { phoneNumber: phoneNumber, otp: code };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  resendOtpCustomerApi: (id: any) => {
    let url = `customer-application-services/resend-customer-phone-number-confirmation-code/${id}`;
    return fetchURL(url, { method: "POST", });
  },

  setFranchisePhoneApi: (id: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/franchise/set-sub-franchise-phone-number/${id}`);
    const params = { phoneNumber: phoneNumber };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setCFranchiseEmailApi: (id: any, email: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/franchise/set-sub-franchise-email/${id}`);
    const params = { email: email };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },

  setEmployeeEmailApi: (id: any, email: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/employee-application-services/set-email/${id}`);
    const params = { email: email };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },

  setEmployeePhoneApi: (id: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/employee-application-services/set-phone-number/${id}`);
    const params = { phoneNumber: phoneNumber };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setDealerEmailApi: (id: any, email: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/dealer-application-services/set-dealer-email/${id}`);
    const params = { email: email };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setDealerPhoneApi: (id: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/dealer-application-services/set-dealer-phone-number/${id}`);
    const params = { phoneNumber: phoneNumber };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setDealerSubuserEmailApi: (id: any, email: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/dealer-application-services/set-sub-dealer-email/${id}`);
    const params = { email: email };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setDealerSubuserPhoneApi: (id: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/dealer-application-services/set-sub-dealer-phone-number/${id}`);
    const params = { phoneNumber: phoneNumber };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setFranchiseSubuserEmailApi: (id: any, email: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/franchise/set-sub-franchise-email/${id}`);
    const params = { email: email };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");


    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  setFranchiseSubuserPhoneApi: (id: any, phoneNumber: any) => {
    const url = new URL(`${APPENV_URL.identityUrl}/franchise/set-sub-franchise-phone-number/${id}`);
    const params = { phoneNumber: phoneNumber };
    url.search = new URLSearchParams(params).toString();
    const token = localStorage.getItem("token");
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response: any) => checkStatus(response, false, "")).catch((raw) => {
      const error = getApiError(raw);
      throw error;
    });
  },
  getAudioModelForFilter: () => {
    let url = `audio-model/audio-model-multi-select-list`;
    return fetchURL(url,{
        method: "GET",
      },
      "masterManagement"
    );
  },
  getJobsheetForFilter: () => {
    let url = `job/job-sheet-list-drop-down`;
    return fetchURL(url,{
        method: "GET",
      },
      "JobService"
    );
  },

  getAlltypeOfInvoice: () => {
    let url = `invoice/invoice-drop-down`;
    return fetchURL(url,{
        method: "GET",
      },
      "JobService"
    );
  },
  getGstNoForFilter: () => {
    let url = `customer-application-services/gst-down-list`;
    return fetchURL(url,{
        method: "GET",
      },
      "identity"
    );
  },
  createManufacturerApi: (subUser: subUser) => {
    return fetchURL(`manufacturer`, {
      method: "POST",
      body: subUser,
    });
  },
  getManufacturerListAPI: (params: any) => {
    let manufacturerUrl = "manufacturer/paged?" + getQueryParams(params);
    return fetchURL(manufacturerUrl, {
      method: "GET",
    });
  },
  getManufacturerDetailsApi: (params: any) => {
    return fetchURL(`manufacturer/${params}`);
  },
  updateManufacturerApi: (subUser: any, id: any) => {
    return fetchURL(`manufacturer/${id}`, {
      method: "PUT",
      body: subUser,
    });
  },
  uploadManufacturerImage: (image: any, id: any) => {
    return fetchURL(`manufacturer/${id}/profile?fileType=Profile`, {
      method: "PUT",
      file: image,
      formData: true,
    });
  },
  fetchManufacturerImage: (params: any) => {
    return fetchURL(`manufacturer/${params}/profile-link`, {
      method: "GET",
    });
  },
  deleteManufacturerImage: (params: any) => {
    return fetchURL(`manufacturer/${params}/profile`, {
      method: "DELETE",
    });
  },
  
  deleteManufacturerApi: (params: any) => {
    return fetchURL(
      `manufacturer/${params.id}?undo=` + params.undo,
      {
        method: "DELETE",
      }
    );
  },
  getJobPrefixforManufacturer: () => {
    let userDataURL = "audio-brand/job-prefix-drop-down-list";
    return fetchURL(userDataURL, {
      method: "GET",
    },"masterManagement");
  },
};

export const TEST = "ok";
