import { RewardValueState } from "src/models/pages/rewardValueModel";
import { rewardValueTypes } from "./actionTypes";


const initialState: RewardValueState = {
  error: "",
  loading: false,
  message: "",
 rewardValue:""
};



const rewardValue = (state = initialState, action: any) => {
  switch (action.type) {

    case rewardValueTypes.GET_REWARD_VALUE_LOADING:
    case rewardValueTypes.CREATE_REWARD_VALUE_LOADING:
      return {
        ...state,
        loading: true,
        message:""
      };

    case rewardValueTypes.GET_REWARD_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardValue:action.payload.data
      };

    
    case rewardValueTypes.CREATE_REWARD_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Reward Value Updated Successfully",
        error:""
      };

    case rewardValueTypes.GET_REWARD_VALUE_ERROR:
    case rewardValueTypes.CREATE_REWARD_VALUE_ERROR:
      return {
        ...state,
        message: "",
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default rewardValue;