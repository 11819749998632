

import { pendingPartsReducerState } from 'src/models/pages/pendingPartsModel';
import { pendingPartsActions } from './actionTypes';

const initialState: pendingPartsReducerState = {
  loading: false,
  error: "",
  message: "",
  pendingPartsList: {
    items: [],
    totalCount: 0
  }
}


const pendingPartsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case pendingPartsActions.GET_PENDING_PARTS_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: ""
      }

    case pendingPartsActions.GET_PENDING_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingPartsList: {
          ...state.pendingPartsList,
          items: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        }
      }

    case pendingPartsActions.GET_PENDING_PARTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return { ...state }
  }
}

export default pendingPartsReducer;