import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from "src/components/UI/Button";
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";

import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { hasAdminOrEmployeeRoleHandler } from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import moment from "moment";
import TooltipMEL from "src/components/UI/Tooltip";
import DropdownInput from "src/components/UI/DropdownInput";
import { INTENT_STATUS } from "src/constants/commonConstants";
import AutoCompleteComponent from "src/components/Common/AutoComplete/AutoComplete";
import { IntentDetailById, IntentPost } from "src/models/pages/intentModel";
import { approveIntent, clearIntentMessageData, createIntent, franchiseListforIntent, getIntentCourierListDropdownLoading, getIntentDeliverablePersonDropdownLoading, getIntentDetail, partsAutoCompleteForIntentList, postDeliverableCourierIntentLoading, rejectIntent, resetIntentValue, updateIntent } from "src/store/actions";
import ConfirmationDialog from "src/components/Common/ConfirmationDialog/ConfirmationDialog";
import { deliveryType } from "src/constants/purchaseOrderFormFields";
import JobTooltipMEL from "src/components/UI/JobTooltip";

const FloatingStockComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    error,
    message,
    loading,
    partList,
    franchiseList,
    intentDetail: formValue,
    courierDropdown,
    deliverablePerson
  } = useSelector((state: RootReducerState) => state.intentReducer);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);





  // const courierdropdownOptions = courierDropdown?.map((item: any) => ({
  //   label: item.otherCompany,
  //   value: item.id
  // }));

  // const courierdropdownOptions = courierDropdown?.map((item: any) => {
  //   if (item.companyName == "Other") {
  //     return {
  //       label: item.otherCompany,
  //       value: item.id
  //     }
  //   } else {
  //     return {
  //       label: item.companyName,
  //       value: item.id
  //     }
  //   }
  // });

  const courierdropdownOptions = courierDropdown?.length > 0 ? (courierDropdown.map((eachCourier: any) => {
    let data: any = {};
    data['value'] = eachCourier.id;
    data['label'] = eachCourier.companyName == "Other" ? `${eachCourier.otherCompany} ${eachCourier?.packageReceiverName ? `courier :(${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}` : `${eachCourier.companyName} ${eachCourier?.packageReceiverName ? `courier : (${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}`;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];



  const deliverdropdownOptions: any = deliverablePerson?.map((item: any) => ({
    label: item.name,
    value: item.id
  }))?.sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (id) {
      dispatch(franchiseListforIntent());
      dispatch(getIntentDetail(id));
      dispatch(getIntentCourierListDropdownLoading());
      dispatch(getIntentDeliverablePersonDropdownLoading());
    } else {
      dispatch(franchiseListforIntent());
    }

    return () => {
      setVisible(false);
      dispatch(resetIntentValue());
    };
  }, []);

  const preventSpecialChar = (event: any) => {
    if (
      (event.which >= 106 && event.which <= 111) ||
      (event.which >= 187 && event.which <= 191) ||
      event.which === 69
    ) {
      event.preventDefault();
    }
  };

  // const url = process.env.REACT_APP_API_CONFIGURATION + "/api/abp/application-configuration";;
  // const token = localStorage.getItem("token");
  // const hasAdminOrEmployeeRole = useFetchDataAndCheckRoles(url, token);
  const hasAdminOrEmployeeRole = hasAdminOrEmployeeRoleHandler();

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
      // showToast(error, { type: "error" })
    }
    if (message) {
      setIsSubmitting(false);
      showToast(message, { type: "success" });
      dispatch(clearIntentMessageData());
      if (message === "Intend updated successfully") {
        setIsSubmitting(false);
        navigate(ROUTER.INTENT_GRID);
      }
      if (id) {
        dispatch(franchiseListforIntent());
        dispatch(getIntentDetail(id));
      } else {
        navigate(ROUTER.INTENT_GRID);
      }
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearIntentMessageData());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const post = (formValue: IntentPost) => {
    dispatch(createIntent(formValue));
  };

  const update = (formValue: IntentPost) => {
    dispatch(updateIntent(formValue, id));
  };

  const courierIntentStage = (formValue: any) => {
    dispatch(postDeliverableCourierIntentLoading(formValue, id));
  };



  const IntentDetailsnondeleted = formValue?.intentDetails.filter((item: any) => item.isDeleted != true);
  const initialvalue = {
    franchise: formValue?.franchiseId || "",
    intentDetails: ((formValue as IntentDetailById)?.intentDetails.length > 0) ? IntentDetailsnondeleted?.map((eachIntent) => {

      let data: any = {};
      data['id'] = eachIntent.id;
      data['partId'] = { id: eachIntent.partId, partNumber: eachIntent.partNo };
      data['partQuantity'] = eachIntent.partQuantity;
      data['intentID'] = eachIntent.intentID;
      data['isIntentDelivered'] = eachIntent.isIntentDelivered;
      data['isIntentReceived'] = eachIntent.isIntentReceived;
      return data;
    })
      : [
        {
          id: 0,
          partId: {},
          partQuantity: "",
          intentID: 0,
        },
      ],
    courierId: 0,
    packedBy: "",
    packedDate: "",
    notes: "",
    deliveryDate: "",
    deliveryType: "",
    deliveredTo: "",
    deliveredById: "",
    approximateValue: ""
  };


  const getDeliveredPersonName = async (deliveredPersonId: string) => {
    const deliveredPerson = deliverdropdownOptions.find((obj: any) => obj.value === deliveredPersonId);
    return deliveredPerson?.label;
  };

  const intentCourierDetails: any = {};

  const populateDeliveredByName = async (deliveredById: any) => {
    intentCourierDetails.deliveredByName = await getDeliveredPersonName(deliveredById);
  };

  const chageDateFormat = (changeDate: any) => {
    const parsedDate = moment(changeDate);
    const formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    return formattedDate;
  };

  const handleSubmit = async (values: any) => {
    if (formValue?.status == "PendingDispatch") {
      await populateDeliveredByName(values.deliveredById);
    }
    if (true) {
      if (isSubmitting) return;
      const submittedData: any = {
        franchiseId: hasAdminOrEmployeeRole ? values.franchise : 0,
        intentDetails: values.intentDetails.map((eachIntent: any) => {
          let data: any = {};
          data["id"] = eachIntent.id;
          data["partId"] = eachIntent.partId?.id;
          data["partQuantity"] = eachIntent.partQuantity;
          data["intentID"] = eachIntent.intentID;
          return data;
        }),
      };
      const intentCourier: any = {
        courierId: values.courierId ? values.courierId : null,
        packedBy: values.packedBy,
        packedDate: chageDateFormat(values.packedDate),
        notes: values.notes,
        deliveryDate: chageDateFormat(values.deliveryDate),
        deliveredByName: intentCourierDetails.deliveredByName,
        deliveryType: values.deliveryType,
        deliveredTo: values.deliveredTo,
        deliveredById: values.deliveredById,
        approximateValue: values.approximateValue,
      };

      // id ? update(submittedData) : post(submittedData);
      id && formValue?.status == "PendingDispatch" ? courierIntentStage(intentCourier) : id && formValue?.status != "PendingDispatch" ? update(submittedData) : post(submittedData);
      setIsSubmitting(true);
    }
  };

  const validationSchema = Yup.object().shape({
    franchise: hasAdminOrEmployeeRole ? Yup.string().required(`Franchise company name is required`) : Yup.string(),
    intentDetails: Yup.array().of(
      Yup.object().shape({
        partId: Yup.mixed()
          .transform((value) => value.id)
          .required("Part is required")
          .test('is-unique', 'Part must be unique', function (value) {
            let test = this.options?.context?.intentDetails?.filter((detail: any) => detail?.partId?.id === value).length;
            return test == 1;
          }),
        // partQuantity: Yup.string().required("Quantity is required"),
        partQuantity: Yup.number()
          .required("Quantity is required")
          .positive("Quantity must be greater than 0")
          .integer("Quantity must be an integer").test('is-max-7-digits', 'Quantity must be at most a 7-digit number', value =>
            value ? /^[0-9]{1,7}$/.test(value.toString().replace('.', '')) : true
          ),
      })
    ),
  });
  const validationSchemaForEdit = Yup.object().shape({
    franchise: hasAdminOrEmployeeRole ? Yup.string().required(`Franchise company name is required`) : Yup.string(),
    intentDetails: Yup.array().of(
      Yup.object().shape({
        partId: Yup.mixed()
          .transform((value) => value.id)
          .required("Part is required")
          .test('is-unique', 'Part must be unique', function (value) {
            let test = this.options?.context?.intentDetails?.filter((detail: any) => detail?.partId?.id === value).length;
            return test == 1;
          }),
        // partQuantity: Yup.string().required("Quantity is required"),
        partQuantity: Yup.number()
          .required("Quantity is required")
          .positive("Quantity must be greater than 0")
          .integer("Quantity must be an integer").test('is-max-7-digits', 'Quantity must be at most a 7-digit number', value =>
            value ? /^[0-9]{1,7}$/.test(value.toString().replace('.', '')) : true
          )
      })
    ),
  });

  const pendingDispatchSchema = Yup.object().shape({
    packedDate: Yup.string().required('Packed Date is required'),
    deliveryDate: Yup.string().required('Delivery Date is required').test('deliveryDate', 'Delivery Date should be greater than Packed Date', function (value) {
      const packedDate = this.parent.packedDate;
      if (packedDate && value) {
        return new Date(value).getTime() >= new Date(packedDate).getTime();
      }
      return true;
    }),
    approximateValue: Yup.number().required('Approximate value is required'),
    packedBy: Yup.string().required('Packed By is required'),
    courierId: Yup.string(),
    deliveryType: Yup.string().required('Delivery type is required').test({
      name: "courier field require",
      test: function (value: any) {
        const courierMasterId = this.parent.courierId;
        if (value == "Courier" && courierMasterId == "0") {
          return this.createError({
            path: "courierId",
            message: 'Courier field is required',
          });
        }
        return true;
      }
    }),
    // courierId
  });

  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.INTENT_GRID);
    }
  };

  const handleAddIntentDetails = (values: any, push: any) => {
    if (isIntentDetailFilled(values)) {
      push({
        id: 0,
        partId: "",
        partQuantity: "",
        intentID: 0,
      });
    }
  };

  const isIntentDetailFilled = (values: any) => {
    const intentDetails = values.intentDetails || [];
    return intentDetails?.every((intent: any) => {
      // Check for values in all fields except 'alternateNumber'
      const { id, intentID, ...fieldsExceptAlternativeNumber } = intent;
      return Object.values(fieldsExceptAlternativeNumber)?.every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const searchParts = (event: any) => {

    dispatch(partsAutoCompleteForIntentList(event.query));
  };

  const approveConfirmHandler = (value: any) => {
    let data: any = {};
    data['id'] = id;
    // data['approvedNote'] = value?.note ? value?.note : "";
    data['approvedNote'] = value?.franchiseId ? value?.franchiseId : "";
    setStatus("");
    dispatch(approveIntent(data));
  };

  const rejectConfirmHandler = (value: any) => {
    let data: any = {};
    data['id'] = id;
    // data['rejectedNote'] = value?.note ? value?.note : "";
    data['rejectedNote'] = value?.franchiseId ? value?.franchiseId : "";
    setStatus("");
    dispatch(rejectIntent(data));
  };

  const [status, setStatus] = useState<any>("");
  const statusBtnHandler = (status: string) => {
    setStatus(status);
  };

  const allDelivered = formValue?.intentDetails?.filter((item: any) => item.isDeleted != true)?.every((detail: any) => detail.isIntentDelivered);

  const confirmationHideHandler = () => {
    setStatus("");
  };

  const confirmMessage = `Are you sure? You want to ${(status == INTENT_STATUS.APPROVED) ? "approve" : "reject"}!`;

  return (
    <div id="view-dealer">
      <ConfirmationDialog message={confirmMessage} visible={status ? true : false} status={status} onHide={confirmationHideHandler}
        onSubmit={(status == INTENT_STATUS.APPROVED) ? approveConfirmHandler : rejectConfirmHandler} />
      {visible && dialogContainer(error)}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Intends"
            titlePath={ROUTER.INTENT_GRID}
            breadcrumbItem={id ? "Edit Intend" : "Create Intend"}
          />

          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    // validationSchema={id ? validationSchemaForEdit : validationSchema}
                    validationSchema={id && !allDelivered ? validationSchemaForEdit : id && allDelivered ? pendingDispatchSchema : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            {hasAdminOrEmployeeRole && <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={franchiseList.filter((eachValue) => !eachValue.isDeleted).map((eachValue) => {
                                  let data: any = {};
                                  data['label'] = eachValue.name;
                                  data['value'] = eachValue.id;
                                  return data;
                                }).sort((a: any, b: any) => {
                                  const labelA = a.label.toUpperCase().trim();
                                  const labelB = b.label.toUpperCase().trim();
                                  if (labelA < labelB) {
                                    return -1;
                                  }
                                  if (labelA > labelB) {
                                    return 1;
                                  }
                                  return 0;
                                })}
                                name="franchise"
                                label="Franchise"
                                onChange={handleChange}
                                required={hasAdminOrEmployeeRole ? true : false}
                                value={values.franchise}
                                onBlur={handleBlur}
                                disabled={id ? true : false}
                                invalid={
                                  touched["franchise"] && errors["franchise"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>}


                            <Row>
                              <Col lg={12} className="dealer-title-header">
                                <div className="dis-flex-btw">
                                  <div>Intend Details</div>
                                  {id && <div className={`intent-status ${formValue?.status == INTENT_STATUS.APPROVED ? 'grn-color' : ""} ${formValue?.status == INTENT_STATUS.REJECTED ? 'red-color' : ""}`}>{formValue?.status != INTENT_STATUS.PENDING_DISPATCH ? formValue?.status : "Pending dispatch"}</div>}

                                </div>

                              </Col>
                              <FieldArray name="intentDetails">
                                {({ insert, remove, push }) => (
                                  <div>
                                    {values.intentDetails?.length > 0 &&
                                      values.intentDetails.map(
                                        (value: any, index: any) => {
                                          const isLastContact =
                                            index ===
                                            values.intentDetails?.length - 1;
                                          const isFirstContact = index === 0;
                                          const displayIndex = index + 1;
                                          return (
                                            <div key={index}>
                                              <Row>
                                                <Col lg={3} className="mb-2">
                                                  <AutoCompleteComponent
                                                    value={value.partId}
                                                    name={`intentDetails.${index}.partId`}
                                                    field="partNumber"
                                                    options={partList}
                                                    searchMethod={searchParts}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label="Part"
                                                    allowBr={false}
                                                    labelClassName={` ${index == 0
                                                      ? ""
                                                      : "d-sm-none"
                                                      }`}
                                                    inputClassName={` ${index == 0
                                                      ? ""
                                                      : "custom_select"
                                                      }`}
                                                    required={true}
                                                    disabled={(id && formValue?.status !== INTENT_STATUS.REQUESTED) ? true : false}
                                                    invalid={
                                                      touched.intentDetails?.[
                                                        index
                                                      ]?.partId &&
                                                        errors.intentDetails?.[
                                                          index
                                                        ]?.partId
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </Col>

                                                <Col lg={3} className="mb-2">
                                                  <div className="d-flex align-items-end">
                                                    <div className="d-block parts-inbound-width">
                                                      <InputCom
                                                        labelName="Quantity"
                                                        inputclassName="form-control"
                                                        labelClassName={`form-Label ${index == 0
                                                          ? ""
                                                          : "d-sm-none"
                                                          }`}
                                                        type="number"
                                                        name={`intentDetails.${index}.partQuantity`}
                                                        onBlur={handleBlur}
                                                        value={value.partQuantity}
                                                        onChange={handleChange}
                                                        isRequired={true}
                                                        onKeyDown={preventSpecialChar}
                                                        min={1}
                                                        disabled={(id && formValue?.status !== INTENT_STATUS.REQUESTED) ? true : false}
                                                        invalid={
                                                          touched.intentDetails?.[index]?.partQuantity &&
                                                            errors.intentDetails?.[index]?.partQuantity ? true : false
                                                        }
                                                      />
                                                    </div>
                                                    <div className="justify-content-center remove-tooltip-margin align-items-center">

                                                      <JobTooltipMEL
                                                        title={formValue?.status == INTENT_STATUS.COURIERED ? "Scan part" : "Scan part to sent courier"}
                                                        position="bottom"
                                                      >
                                                        {/* !value.isIntentDelivered */}
                                                        {((formValue?.status == INTENT_STATUS.APPROVED || formValue?.status == INTENT_STATUS.PENDING_DISPATCH) && hasAdminOrEmployeeRole && !value.isIntentDelivered) && (
                                                          // <i
                                                          //   className="bx bx-send mr-1 pointer"
                                                          //   style={{fontSize:"25px",marginBottom: "8px"}}
                                                          //   onClick={() => {
                                                          //     navigate(`${ROUTER.INTENT_SCAN}/${id}/${value.id}`)
                                                          //   }}
                                                          // ></i>
                                                          <Button
                                                            className="btn-success me-2"
                                                            label={"Scan part"}
                                                            type="button"
                                                            onClick={() => {
                                                              navigate(`${ROUTER.INTENT_SCAN}/${id}/${value.id}`);
                                                            }}
                                                          />

                                                        )}

                                                        {((formValue?.status == INTENT_STATUS.COURIERED || formValue?.status == INTENT_STATUS.PENDING_RECEIVED) && !hasAdminOrEmployeeRole && !value.isIntentReceived) && (
                                                          // <i
                                                          //   className="bx bx-send remove-icon mr-1 pointer"
                                                          //   onClick={() => {
                                                          //     navigate(`${ROUTER.INTENT_SCAN}/${id}/${value.id}`)
                                                          //   }}
                                                          // ></i>
                                                          <Button
                                                            className="btn-success me-2"
                                                            label={"Scan part"}
                                                            type="button"
                                                            onClick={() => {
                                                              navigate(`${ROUTER.INTENT_SCAN}/${id}/${value.id}`);
                                                            }}
                                                          />
                                                        )}
                                                      </JobTooltipMEL>
                                                      {((id && formValue?.status == INTENT_STATUS.REQUESTED) || !id) && <JobTooltipMEL
                                                        title="Remove Intend"
                                                        position="bottom"

                                                      >
                                                        {(values.intentDetails?.length > 1) && (
                                                          <i
                                                            className="fas fa-times-circle color-red remove-icon pointer"
                                                            onClick={() => {
                                                              remove(index);
                                                            }}
                                                          ></i>
                                                        )}
                                                      </JobTooltipMEL>}
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>

                                              {((id && formValue?.status == INTENT_STATUS.REQUESTED) || !id) && <div className="multiadd-container jus-start">
                                                {isLastContact && (
                                                  <div
                                                    className="add-btn-container"
                                                    onClick={() => {
                                                      handleAddIntentDetails(
                                                        values,
                                                        push
                                                      );
                                                    }}
                                                  >
                                                    <TooltipMEL
                                                      title="Add Parts"
                                                      position="bottom"
                                                    >
                                                      <i className="fas fa-plus-circle remove-icon" ></i>
                                                      <span>Add</span>
                                                    </TooltipMEL>
                                                  </div>
                                                )}
                                              </div>}

                                            </div>
                                          );
                                        }
                                      )}
                                    <hr className="mt-1" />
                                  </div>
                                )}
                              </FieldArray>
                            </Row>

                            {
                              id && allDelivered && formValue?.status == INTENT_STATUS.PENDING_DISPATCH && <Row>
                                <Col lg={12} className="dealer-title-header">
                                  <div className="dis-flex-btw">
                                    <div>Create Courier</div>
                                  </div>
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <DropdownInput
                                    options={deliveryType}
                                    name="deliveryType"
                                    label="Delivery Type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    value={values.deliveryType}
                                    invalid={
                                      touched["deliveryType"] && errors["deliveryType"]
                                        ? true
                                        : false
                                    } />
                                </Col>
                                {(values.deliveryType == "Courier" || !values.deliveryType) && <Col lg={3} className="mb-2">
                                  <DropdownInput
                                    options={courierdropdownOptions}
                                    name="courierId"
                                    label="Courier"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={values.deliveryType == "Courier" ? true : false}
                                    value={values.courierId}
                                    invalid={
                                      touched["courierId"] && errors["courierId"]
                                        ? true
                                        : false
                                    } />
                                </Col>
                                }

                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Packed By"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="packedBy"
                                    onBlur={handleBlur}
                                    value={values.packedBy}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                      touched["packedBy"] && errors["packedBy"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Packed Date"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="packedDate"
                                    onBlur={handleBlur}
                                    value={values.packedDate}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                      touched["packedDate"] && errors["packedDate"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Delivery Date"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="deliveryDate"
                                    onBlur={handleBlur}
                                    value={values.deliveryDate}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                      touched["deliveryDate"] && errors["deliveryDate"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <DropdownInput
                                    options={deliverdropdownOptions}
                                    name="deliveredById"
                                    label="Delivered Person"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    value={values.deliveredById}
                                    invalid={
                                      touched["deliveredById"] && errors["deliveredById"]
                                        ? true
                                        : false
                                    } />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Delivery To"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="deliveredTo"
                                    onBlur={handleBlur}
                                    value={values.deliveredTo}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={
                                      touched["deliveredTo"] && errors["deliveredTo"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Approximate Value"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="approximateValue"
                                    onBlur={handleBlur}
                                    value={values.approximateValue}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                      touched["approximateValue"] && errors["approximateValue"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Notes"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="textarea"
                                    name="notes"
                                    onBlur={handleBlur}
                                    value={values.notes}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={
                                      touched["notes"] && errors["notes"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col>
                              </Row>
                            }


                            <div className="d-flex justify-content-end">
                              <Button
                                className="secondary-btn me-2"
                                label={id ? "Cancel" : "Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              {(formValue?.status === INTENT_STATUS.REQUESTED && hasAdminOrEmployeeRole) ? <>
                                <Button
                                  className="btn-danger me-2"
                                  label="Reject"
                                  type="button"
                                  onClick={() => statusBtnHandler(INTENT_STATUS.REJECTED)}
                                />
                                <Button
                                  className="btn-success me-2"
                                  label="Approve"
                                  type="button"
                                  onClick={() => statusBtnHandler(INTENT_STATUS.APPROVED)}
                                />
                              </> : null
                              }
                              {
                                (
                                  (formValue?.status != INTENT_STATUS.PENDING_DISPATCH) &&
                                  <Button
                                    disabled={((id && INTENT_STATUS.REQUESTED == formValue?.status) || !id) ? false : true}
                                    className="btn-primary me-2 btn-primary-shadow"
                                    label={id ? "Update Intend" : "Create Intend"}
                                    type="submit"
                                  />
                                )
                              }
                              {
                                (formValue?.status === INTENT_STATUS.PENDING_DISPATCH) &&
                                <Button
                                  disabled={allDelivered ? false : true}
                                  className="btn-primary me-2 btn-primary-shadow"
                                  label="Apply Courier"
                                  type="submit"
                                />
                              }
                            </div>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>



        </Container>
      </div>
    </div>
  );
};

export default FloatingStockComponent;
