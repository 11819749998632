import { administrators } from "src/models/pages/administratorModel";
import { AdministratorTypes } from "./actionTypes";



// For Grid

export const getAdministratorList = (lazyState: any) => {
  return {
    type: AdministratorTypes.GET_ADMINISTRATOR_LIST,
    lazyState,
  };
};



export const getAdministratorListSuccess = (administrators: any) => {
  return {
    type: AdministratorTypes.GET_ADMINISTRATOR_LIST_SUCCESS,
    payload: administrators,
  };
};

export const getAdministratorListError = (error: any) => {
  return {
    type: AdministratorTypes.GET_ADMINISTRATOR_LIST_FAIL,
    payload: error,
  };
};


export const deleteAdministratorFromList = (payload: any) => {
  return {
    type: AdministratorTypes.DELETE_ADMINISTRATOR,
    payload: payload,
  };
};

export const deleteAdministratorFromListSuccess = (response: any) => {
  return {
    type: AdministratorTypes.DELETE_ADMINISTRATOR_SUCCESS,
    payload: response,
  };
};

export const deleteAdministratorFromListError = (error: any) => {
  return {
    type: AdministratorTypes.DELETE_ADMINISTRATOR_FAIL,
    payload: error,
  };
};


//update administrator action

export const updateAdministratorLoading = (administratorDetails: administrators) => {
  return {
    type: AdministratorTypes.UPDATE_ADMINISTRATOR_LOADING,
    payload: administratorDetails
  };
};

export const updateAdministratorSuccess = () => {
  return {
    type: AdministratorTypes.UPDATE_ADMINISTRATOR_SUCCESS
  };
};

export const updateAdministratorError = (error: any) => {
  return {
    type: AdministratorTypes.UPDATE_ADMINISTRATOR_ERROR,
    payload: error
  };
};


//reset administrator
export const administratorReset = () => {
  return {
    type: AdministratorTypes.RESET_ADMINISTRATOR,
  };
};



// get map key api

export const getMapKeyApiLoading = () => {
  return {
    type: AdministratorTypes.GET_MAP_KEY_API_LOADING
  };
};

export const getMapKeyApiSuccess = (resp : any) => {
  return {
    type: AdministratorTypes.GET_MAP_KEY_API_SUCCESS,
    payload : resp
  };
};
export const getMapKeyApiError = (error : any) => {
  return {
    type: AdministratorTypes.GET_MAP_KEY_API_ERROR,
    payload : error
  };
};



export const saveMapKey=(MapKey : any)=>{
  return {
    type : AdministratorTypes.SAVE_MAP_KEY,
    payload : MapKey
  }
}