export enum AddtionalExpensesReports {

  //grid actions
  
  GET_ADDITIONAL_EXPENSE_REPORTS_LOADING = "@@additionalExpensesReports/GET_ADDITIONAL_EXPENSE_REPORTS_LOADING",
  GET_ADDITIONAL_EXPENSE_REPORTS_SUCCESS = "@@additionalExpensesReports/GET_ADDITIONAL_EXPENSE_REPORTS_SUCCESS",
  GET_ADDITIONAL_EXPENSE_REPORTS_ERROR = "@@additionalExpensesReports/GET_ADDITIONAL_EXPENSE_REPORTS_ERROR",

  GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_LOADING = "@@additionalExpensesReports/GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_LOADING",
  GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_SUCCESS = "@@additionalExpensesReports/GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_SUCCESS",
  GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_FAIL = "@@additionalExpensesReports/GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_FAIL",
  
  UPDATE_PAYMENT_LOADING = "@@additionalExpensesReports/UPDATE_PAYMENT_LOADING",
  UPDATE_PAYMENT_SUCCESS = "@@additionalExpensesReports/UPDATE_PAYMENT_SUCCESS",
  UPDATE_PAYMENT_FAIL = "@@additionalExpensesReports/UPDATE_PAYMENT_FAIL",

  UPDATE_ADJUSTMENT_POINT_LOADING = "@@additionalExpensesReports/UPDATE_ADJUSTMENT_POINT_LOADING",
  UPDATE_ADJUSTMENT_POINT_SUCCESS = "@@additionalExpensesReports/UPDATE_ADJUSTMENT_POINT_SUCCESS",
  UPDATE_ADJUSTMENT_POINT_FAIL = "@@additionalExpensesReports/UPDATE_ADJUSTMENT_POINT_FAIL",
}