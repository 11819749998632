import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { parts } from "src/models/pages/partsModel";
import { createEnquiryError, createEnquirySuccess, deleteEnquiryFromListError, deleteEnquiryFromListSuccess, getEmployeeDropdownListError, getEmployeeDropdownListSuccess, getEnquiryBYIDError, getEnquiryBYIDSuccess, getEnquiryListError, getEnquiryListSuccess, getFranchiseDropdownListError, getFranchiseDropdownListSuccess, getFranchiseJobsheetDropdownForEnquiryError, getFranchiseJobsheetDropdownForEnquirySuccess, getFranchiseSubuserDropdownListError, getFranchiseSubuserDropdownListSuccess, getJobsheetDropdownForEnquiryError, getJobsheetDropdownForEnquirySuccess } from "./action";
import { enquiryTypes } from "./actionTypes";



function* getEnquiryListAsync(action: any) {

  try {
    const response: Promise<any> = yield call(
      API.getJobEnquiryListApi,  {
        ...action.lazyState,
      }
    )
    yield put(getEnquiryListSuccess(response));
  } catch (error) {
    yield put(getEnquiryListError(error));
  }
}

function* deleteEnquiryAsync(action: any) {

  try {
    const response: Promise<any> = yield call(
      API.deletePartsListAPI, { id: action.payload.id, lazyEvent: JSON.stringify(action) }
    )
    yield put(deleteEnquiryFromListSuccess(response))
    yield put(getEnquiryListSuccess(action.lazyState))
  } catch (error) {
    yield put(deleteEnquiryFromListError(error));
  }
}

// create parts
function* createEnquiryAsync({ payload }: any) {
  try {
    const response:  parts = yield call(API.createJobEnquiryListApi, payload);
    yield put(createEnquirySuccess(response));
    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createEnquiryError(error));
  }
}


function* fetchEnquiry({ payload }: any): Generator<any, void, any> {
  try {

    let { data } = yield call(API.getEnquiryByIdApi, payload);
    yield put(getEnquiryBYIDSuccess(data));

  } catch (error) {
    yield put(getEnquiryBYIDError(error));
  }
}

function* getJobsheetDropdownAsync(): Generator<any, void, any> {
  try {

    let { data } = yield call(API.getEnquiryPageJoblis);
    yield put(getJobsheetDropdownForEnquirySuccess(data));

  } catch (error) {
    yield put(getJobsheetDropdownForEnquiryError(error));
  }
}

function* getFranchiseJobsheetDropdownAsync({id}:any): Generator<any, void, any> {
  try {

    let { data } = yield call(API.getFranchiseJoblistType,id);
    yield put(getFranchiseJobsheetDropdownForEnquirySuccess(data));

  } catch (error) {
    yield put(getFranchiseJobsheetDropdownForEnquiryError(error));
  }
}

function* getEmployeeDropdownListAsync() {
    
  try {
      const response: Promise<any> = yield call( API.getEmployeeDrpFromIdentity )        
      yield put(getEmployeeDropdownListSuccess(response));
  } catch (error) {
      yield put(getEmployeeDropdownListError(error));
  }
}

function* getFranchiseDropdownListAsync() {
  
  try {
      const response: Promise<any> = yield call( API.getFranchiseDrpFromIdentity )        
      yield put(getFranchiseDropdownListSuccess(response));
  } catch (error) {
      yield put(getFranchiseDropdownListError(error));
  }
}

function* getFranchiseSubuserDropdownListAsync() {
  
  try {
      const response: Promise<any> = yield call( API.getFranchiseSubuserDrpFromIdentity )        
      yield put(getFranchiseSubuserDropdownListSuccess(response));
  } catch (error) {
      yield put(getFranchiseSubuserDropdownListError(error));
  }
}


function* enquirySaga() {
  yield takeLatest(enquiryTypes.GET_ENQUIRY_LIST, getEnquiryListAsync);
  yield takeLatest(enquiryTypes.DELETE_ENQUIRY, deleteEnquiryAsync);
  yield takeLatest(enquiryTypes.POST_ENQUIRY, createEnquiryAsync);
  yield takeLatest(enquiryTypes.GET_ENQUIRY_BY_ID, fetchEnquiry);
  yield takeLatest(enquiryTypes.GET_JOBSHEET_DROPDOWN, getJobsheetDropdownAsync);
  yield takeLatest(enquiryTypes.GET_FRANCHISE_JOBSHEET_DROPDOWN, getFranchiseJobsheetDropdownAsync);
  yield takeLatest( enquiryTypes.GET_EMPLOYEE_DROPDOWN_LIST, getEmployeeDropdownListAsync);
  yield takeLatest( enquiryTypes.GET_FRANCHISE_DROPDOWN_LIST, getFranchiseDropdownListAsync);
  yield takeLatest( enquiryTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST, getFranchiseSubuserDropdownListAsync);
}

export default enquirySaga;