import { SupplierTypes } from "./actionTypes";
import { supplierDetails } from "src/models/pages/supplierModel";



// For Grid

export const getSupplierList = (lazyState: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_LIST,
    lazyState,
  };
};



export const getSupplierListSuccess = (supplier: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_LIST_SUCCESS,
    payload: supplier,
  };
};

export const getSupplierListError = (error: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_LIST_FAIL,
    payload: error,
  };
};

export const ResetSupplierMessage = () => {
  return {
    type: SupplierTypes.RESET_SUPPLIER,
  }
}

export const clearSupplierMessage = () => {
  return {
    type: SupplierTypes.CLEAR_SUPPLIER_MESSAGE,
  }
}

export const deleteSupplierFromList = (payload: any) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_BRANDS,
    payload: payload,
  }
}

export const deleteSupplierFromListSuccess = (response: any, undo: boolean) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_BRANDS_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteSupplierFromListError = (error: any) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_BRANDS_FAIL,
    payload: error,
  }
}



//  update 

// update supplier

export const updateSupplier = (supplier: supplierDetails, supplierId: string = "") => {
  return {
    type: SupplierTypes.UPDATE_SUPPLIER

    , payload: { supplier, supplierId }
  };
};

export const updateSupplierSuccess = (supplier: supplierDetails) => {
  return { type: SupplierTypes.UPDATE_SUPPLIER_SUCCESS, payload: supplier };
};

export const updateSupplierError = (error: any) => {
  return { type: SupplierTypes.UPDATE_SUPPLIER_FAIL, payload: error };
};


// post supplier
export const createSupplier = (supplier: supplierDetails) => {
  return { type: SupplierTypes.POST_SUPPLIER, payload: supplier };
};

export const createSupplierSuccess = (vehicle: supplierDetails) => {
  return { type: SupplierTypes.POST_SUPPLIER_SUCCESS, payload: vehicle };
};

export const createSupplierError = (error: any) => {
  return { type: SupplierTypes.POST_SUPPLIER_FAIL, payload: error };
};

//contactDetails
export const createSupplierContactDetails = (supplier: any) => {
  return { type: SupplierTypes.POST_SUPPLIER_CONTACT, payload: supplier };
};

export const createSupplierContactDetailsSuccess = (vehicle: supplierDetails) => {
  return { type: SupplierTypes.POST_SUPPLIER_CONTACT_SUCCESS, payload: vehicle };
};

export const createSupplierContactDetailsError = (error: any) => {
  return { type: SupplierTypes.POST_SUPPLIER_CONTACT_FAIL, payload: error };
};

export const getSupplierContactDetails = (supplier: any) => {
  return { type: SupplierTypes.GET_SUPPLIER_CONTACT, payload: supplier };
};

export const getSupplierContactDetailsSuccess = (val: supplierDetails) => {
  return { type: SupplierTypes.GET_SUPPLIER_CONTACT_SUCCESS, payload: val };
};

export const getSupplierContactDetailsError = (error: any) => {
  return { type: SupplierTypes.GET_SUPPLIER_CONTACT_FAIL, payload: error };
};


export const deleteSupplierContact = (payload: any) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_CONTACT,
    payload: payload,
  }
}

export const deleteSupplierContactSuccess = (response: any) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_CONTACT_SUCCESS,
    payload: response,
  }
}

export const deleteSupplierContactError = (error: any) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_CONTACT_FAIL,
    payload: error,
  }
}

// get supplier
export const getSupplierById = (id: string | any) => {
  return { type: SupplierTypes.GET_SUPPLIER_BY_ID, payload: id };
};

export const getSupplierByIdSuccess = (vehicle: supplierDetails) => {
  return { type: SupplierTypes.GET_SUPPLIER_BY_ID_SUCCESS, payload: vehicle };
};

export const getSupplierByIsError = (error: any) => {
  return { type: SupplierTypes.GET_SUPPLIER_BY_ID_FAIL, payload: error };
};

export const uploadSupplierFileLoading = (file: any, id: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_UPLOAD_LOADING,
    payload: file,
    id: id
  }
}

export const uploadSupplierFileSuccess = (supplier: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_UPLOAD_SUCCESS,
  }
}

export const uploadSupplierFileError = (error: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_UPLOAD_ERROR,
    payload: error
  }
}
export const getSupplierImageLoading = (supplierId: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_FETCH_LOADING,
    payload: supplierId,
  };
}

export const getSupplierImageSuccess = (supplierDetails: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_FETCH_SUCCESS,
    payload: supplierDetails,
  }
}

export const getSupplierImageError = (error: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_FETCH_ERROR,
    payload: error
  }
}


// delete audio brand image

export const deleteSupplierImageLoading = (supplierId: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_DELETE_LOADING,
    payload: supplierId,
  };
}

export const deleteSupplierImageSuccess = (supplierDetails: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_DELETE_SUCCESS,
    payload: supplierDetails,
  }
}

export const deleteSupplierImageError = (error: any) => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_DELETE_ERROR,
    payload: error
  }
}

export const ClearSupplierImage = () => {
  return {
    type: SupplierTypes.SUPPLIER_FILE_CLEAR,
  }
}

export const supplierDocumentUploadLoading = (files: any, id: any, fileType: any) => {
  return {
    type: SupplierTypes.POST_SUPPLIER_DOCUMENT,
    payload: files,
    id: id,
    fileType: fileType
  }
}

export const supplierDocumentUploadSuccess = (customer: any) => {
  return {
    type: SupplierTypes.POST_SUPPLIER_DOCUMENT_SUCCESS
  }
}

export const supplierDocumentUploadError = (error: any) => {
  return {
    type: SupplierTypes.POST_SUPPLIER_DOCUMENT_FAIL,
    payload: error
  }
}

//get uploaded document list

export const supplierDocumentGetLoading = (customerId: any) => {
  console.count("get")
  return {
    type: SupplierTypes.GET_SUPPLIER_DOCUMENT,
    payload: customerId,
  };
}

export const supplierDocumentGetSucces = (customerDetails: any) => {
  console.count("action")
  return {
    type: SupplierTypes.GET_SUPPLIER_DOCUMENT_SUCCESS,
    payload: customerDetails,
  }
}

export const supplierDocumentGetError = (error: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_DOCUMENT_FAIL,
    payload: error
  }
}

export const supplierLinkGetLoading = (id: any, rowId: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_DOCUMENT_LINK,
    payload: { id: id, rowId: rowId },
  };
}

export const supplierLinkGetSucces = (customerDetails: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_DOCUMENT_LINK_SUCCESS,
    payload: customerDetails,
  }
}

export const supplierLinkGetError = (error: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_DOCUMENT_LINK_FAIL,
    payload: error
  }
}


//delete uploaded document


export const deleteSupplierDocumentLoading = (customerId: any) => {
  debugger
  return {
    type: SupplierTypes.DELETE_SUPPLIER_DOCUMENT,
    payload: customerId,
  };
}

export const deleteSupplierDocumentSuccess = (customerDetails: any,deletedId : any) => {
  debugger
  return {
    type: SupplierTypes.DELETE_SUPPLIER_DOCUMENT_SUCCESS,
    payload: {customerDetails,deletedId},
  }
}

export const deleteSupplierDocumentError = (error: any) => {
  return {
    type: SupplierTypes.DELETE_SUPPLIER_DOCUMENT_FAIL,
    payload: error
  }
}


export const resetSuplierContact = () => {
  return {
    type : SupplierTypes.RESET_SUPLIER_CONTACT
  }
}

export const supplierLatLagLoading = (selectedArea: string) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const supplierLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const supplierLatLagError = (error: any) => {
  return {
    type: SupplierTypes.GET_SUPPLIER_LOCATION_ERROR,
    payload: error
  }
}