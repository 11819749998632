import { AttendanceTypes } from "./actionTypes";
import { attendanceInitialState } from "src/models/pages/attendanceModel";

const initialState: attendanceInitialState = {
    error: "",
    loading: false,
    message: "",
    paginatorCount: 0,
    attendanceList: {
        items: [],
        totalCount: 0
    },
    attendance: null,
    workingHours: null,
    reloadData: false,
    isLogout: false,
    attendanceDetail:[]
};



const Attendance = (state = initialState, action: any) => {
    switch (action.type) {

        // Grid
        case AttendanceTypes.GET_ATTENDANCE_LIST:
        case AttendanceTypes.FILTER_PAGE_LOADING:
        case AttendanceTypes.UPDATE_ATTENDANCE:
        case AttendanceTypes.POST_ATTENDANCE:
        case AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST:
        case AttendanceTypes.GET_CURRENT_USER_ATTENDANCE:
        case AttendanceTypes.GET_USER_WORKING_HOURS:
        case AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID:
            return {
                ...state,
                loading: true,
                paginatorCount: 0
            };

        case AttendanceTypes.GET_ATTENDANCE_LIST_SUCCESS:
        case AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                attendanceList: {
                    items: action.payload.data.items,
                    totalCount: action.payload.data.totalCount,
                },
                paginatorCount: action.payload.data.items?.length
            };
        case AttendanceTypes.GET_USER_WORKING_HOURS_SUCCESS:
            return {
                ...state,
                loading: false,
                workingHours: action.payload
            };

        case AttendanceTypes.GET_CURRENT_USER_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "",
                error: "",
                attendance: action.payload
            };

        case AttendanceTypes.UPDATE_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                message: action.payload.data.status,
                isLogout: action.payload.isLogout
            }

        case AttendanceTypes.POST_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                message: action.payload.data.status,
            }
        case AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                attendanceDetail: action.payload,
            }
        
        case AttendanceTypes.RELOAD_USER_WORKING_HOURS:
            return {
                ...state, 
                reloadData: action?.payload
            }

        case AttendanceTypes.GET_RESET_INDUVIDUAL_ATTENDANCE_DETAILS :
            return{
                ...state,
                loading : false,
                attendanceDetail:[]
            }   

        case AttendanceTypes.GET_ATTENDANCE_LIST_FAIL:
        case AttendanceTypes.FILTER_PAGE_ERROR:
        case AttendanceTypes.GET_CURRENT_USER_ATTENDANCE_FAIL:
        case AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST_FAIL:
        case AttendanceTypes.GET_USER_WORKING_HOURS_FAIL:
        case AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID_FAIL:
        // case AttendanceTypes.UPDATE_ATTENDANCE_FAIL:
        // case AttendanceTypes.POST_ATTENDANCE_FAIL:
            return {
                ...state,
                message: "",
                loading: false,
                error: action.payload
            };

        default:
            return { ...state };
    }
};

export default Attendance;
