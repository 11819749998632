import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {StockValueByMusicSystemBrandTypes} from "./actionTypes";
import { API } from "src/util/api";
import {getStockValueByMusicSystemBrandSuccess,getStockValueByMusicSystemBrandError, getAudioBrandSuccess, getAudioBrandError} from "./action";



//worker function
function* getStockValueByMusicSystemBarandListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getStockValuebymusicSystemBrandAPI, {...action.lazyState})
    yield put(getStockValueByMusicSystemBrandSuccess(response));
  } catch (error) {
    yield put(getStockValueByMusicSystemBrandError(error));
  }
}

function* getAudioBrandAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getAudioBrandFromMasterManagement)
    yield put(getAudioBrandSuccess(response));
  } catch (error) {
    yield put(getAudioBrandError(error));
  }
}


//watcher function

function* StockValueByMusicSystemBrandSaga() {
  yield takeLatest(StockValueByMusicSystemBrandTypes.GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING, getStockValueByMusicSystemBarandListAsync);
  yield takeLatest(StockValueByMusicSystemBrandTypes.GET_AUDIO_BRAND_LOADING, getAudioBrandAsync);
}


export default StockValueByMusicSystemBrandSaga