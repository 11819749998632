import { OutGoing_dispatch_Action } from "./actionTypes";


//grid

export const getOutgoingDispatchListLoading = (lazyState: any) => {
	return {
		type: OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_LOADING,
		lazyState,
	};
};


export const getOutgoingDispatchListSuccess = (ougoingDispatchList: any) => {
	return {
		type: OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_SUCCESS,
		payload: ougoingDispatchList
	}
}

export const getOutgoingDispatchListError = (error: any) => {
	return {
		type: OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_ERROR,
		payload: error
	}
}


//view page

export const viewOutgoingDispatchLoading = (id: any) => {
	return {
		type: OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_LOADING,
		payload: id
	}
}

export const viewOutgoingDispatchSuccess = (response: any) => {
	return {
		type: OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_SUCCESS,
		payload: response
	}
}

export const viewOutgoingDispatchError = (error: any) => {
	return {
		type: OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_ERROR,
		payload: error
	}
}



export const clearOutgoingDispatch = ()=>{
	return {
	 type : OutGoing_dispatch_Action.CLEAR_OUTGOING_DISPATCH_STATE,
	}
}

export const resetOutgoingDispatchMessage = ()=>{
	return {
	 type : OutGoing_dispatch_Action.RESET_OUTGOING_DISPATCH_MESSAGE,
	}
}


export const sentMailToOutgoingDispatch = (id:any)=>{
  
	return {
	  type : OutGoing_dispatch_Action.SENT_MAIL_TO_OUTGOING_DISPATCH,
	  payload : id
	}
  }
  export const sentMailToOutgoingDispatchSuccess = (data :any )=>{  
	return {
	  type : OutGoing_dispatch_Action.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS,
	  payload : data
	}
  }
  
  export const sentMailToOutgoingDispatchError = (error : any)=>{
	return {
	  type : OutGoing_dispatch_Action.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL,
	  payload : error
	}
  }

// DOWNLOAD OUTGOING DISPATCH 

export const downloadOutgoingDispatch = (id:any)=>{
  
	return {
	  type : OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH,
	  payload : id
	}
  }
  export const downloadOutgoingDispatchSuccess = (data :any )=>{  
	return {
	  type : OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH_SUCCESSS,
	  payload : data
	}
  }
  
  export const downloadOutgoingDispatchError = (error : any)=>{
	return {
	  type : OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH_FAIL,
	  payload : error
	}
  }

export const getDeliveriedBYID = (id: any) => {
    return { type: OutGoing_dispatch_Action.GET_DELIVERIED_BY_ID_LOADING, id: id };
};

export const getDeliveriedBYIDSuccess = (response: any) => {
    return { type: OutGoing_dispatch_Action.GET_DELIVERIED_BY_ID_SUCCESS, payload: response };
};

export const getDeliveriedBYIDError = (error: any) => {
    return { type: OutGoing_dispatch_Action.GET_DELIVERIED_BY_ID_FAIL, payload: error };
};