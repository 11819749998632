import { ErrorMessage } from 'formik';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import ErrorText from './ErrorText';
import Label from './Label';
import React from 'react';

interface NumberInputProps {
  name: string
  label: string
  value: number
  onChange?: (event: InputNumberChangeEvent) => {}
  required?: boolean
}

const NumberInput = (props: NumberInputProps) => {
  const { name, label, required = false, ...rest } = props
  return (
    <div >
      <Label name={name} label={label} required={required} />
      {/* <label htmlFor={name}>{label}</label> */}
      <InputNumber useGrouping={false} {...rest} />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  )
};

export default NumberInput;
