import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ProfileTypes } from "./actionTypes";
import { CustomerService } from "src/components/Grid/CustomerService";
import { getProfile, getProfileSuccess,getProfileError, updateProfileSuccess, UpdateProfileError, getProfilePictureSuccess, getProfilePictureError, uploaProfilePictureSuccess, uploaProfilePictureError, deleteProfilePictureSuccess, deleteProfilePictureError, uploadProfileMobileNumberSuccess, sendProfileotpSuccess, sendProfileotpError, resendOtpProfileSuccess, resendOtpProfileError } from "./actions";
import {API} from "src/util/api";
import { sendFranchiseOtpError } from "../actions";

function* fetchProfiles(action : any) {
  try {
    const response : Promise<any> = yield call(API.getProfile)
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileError(error));
  }
}
function* UpdateProfiles(action : any) {
  
  try {
    const response : Promise<any> = yield call( API.updateProfile,{action} )
    yield put(updateProfileSuccess(response));
  } catch (error) {
    yield put(UpdateProfileError(error));
  }
}

function* getProfilePictureAsync(action: any) {
  try {
    if(action.user=="Employee"){
      
      const response: Promise<any> = yield call(API.fetchEmployeeImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
    if(action.user=="Franchise"){
      const response: Promise<any> = yield call(API.fetchFranchiseImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
    if(action.user=="Dealer"){
      const response: Promise<any> = yield call(API.fetchDealerImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
    if(action.user=="Customer"){
      const response: Promise<any> = yield call(API.fetchCustomerImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
    if(action.user=="DealerSubUser"){
      const response: Promise<any> = yield call(API.fetchDealerSubuserImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
    if(action.user=="FranchiseSubUser"){
      const response: Promise<any> = yield call(API.fetchFranchiseSubuserImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
    if(action.user=="Manufacturer"){
      const response: Promise<any> = yield call(API.fetchManufacturerImage, action.id)
    yield put(getProfilePictureSuccess(response));
    }
  } catch (error) {
    yield put(getProfilePictureError(error));
  }
}

function* uploadEmployeeFileAsync(action: any) {
  try {
if(action.user=="Employee"){
  const response: Promise<any> = yield call(API.uploadEmployeeImage, action.payload, { id: action.id });
  yield put(uploaProfilePictureSuccess(response))
}
if(action.user=="Franchise"){
  const response: Promise<any> = yield call(API.uploadFranchiseImage, action.payload, { id: action.id });
  yield put(uploaProfilePictureSuccess(response))
}
if(action.user=="Dealer"){
  const response: Promise<any> = yield call(API.uploadDealerImage, action.payload, { id: action.id });
  yield put(uploaProfilePictureSuccess(response))
}
if(action.user=="Customer"){
  const response: Promise<any> = yield call(API.uploadCustomerImage, action.payload, { id: action.id });
  yield put(uploaProfilePictureSuccess(response))
}
if(action.user=="DealerSubUser"){
  const response: Promise<any> = yield call(API.uploadDealerSubuserImage, action.payload,  action.id );
  yield put(uploaProfilePictureSuccess(response))
}
if(action.user=="FranchiseSubUser"){
  const response: Promise<any> = yield call(API.uploadFranchiseSubuserImage, action.payload,   action.id );
  yield put(uploaProfilePictureSuccess(response))
}
if(action.user=="Manufacturer"){
  const response: Promise<any> = yield call(API.uploadManufacturerImage, action.payload,   action.id );
  yield put(uploaProfilePictureSuccess(response))
}

  } catch (error) {
    yield put(uploaProfilePictureError(error))
  }
}

function* deleteEmployeeImageAsync(action: any) {
  try {
    if(action.user=="Employee"){
      const response: Promise<any> = yield call(API.deleteEmployeeImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
    if(action.user=="Franchise"){
      const response: Promise<any> = yield call(API.deleteFranchiseImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
    if(action.user=="Dealer"){
      const response: Promise<any> = yield call(API.deleteDealerImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
    if(action.user=="Customer"){
      const response: Promise<any> = yield call(API.deleteCustomerImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
    if(action.user=="DealerSubUser"){
      const response: Promise<any> = yield call(API.deleteDealerSubuserImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
    if(action.user=="FranchiseSubUser"){
      const response: Promise<any> = yield call(API.deleteFranchiseSubuserImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
    if(action.user=="Manufacturer"){
      const response: Promise<any> = yield call(API.deleteManufacturerImage, action.payload)
      yield put(deleteProfilePictureSuccess(response));
    }
  } catch (error) {
    yield put(deleteProfilePictureError(error));
  }
}
function* uploadProfileNumberAsync(action: any) {
  try {
  const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.id, action.payload);
  yield put(uploadProfileMobileNumberSuccess({response:response,number:action.payload}))


  } catch (error) {
    yield put(uploaProfilePictureError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendProfileotpSuccess(response))
  } catch (error) {
    yield put(sendProfileotpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpProfileSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpProfileError(error))
  }
}

function* profileSaga() {
  yield takeLatest(ProfileTypes.GET_PROFILES, fetchProfiles);
  yield takeLatest(ProfileTypes.UPDATE_PROFILE_DETAIL, UpdateProfiles);
  yield takeLatest(ProfileTypes.GET_PROFILE_PICTURE, getProfilePictureAsync);
  yield takeLatest(ProfileTypes.UPDATE_PROFILE_PICTURE, uploadEmployeeFileAsync);
  yield takeLatest(ProfileTypes.DELETE_PROFILE_PICTURE, deleteEmployeeImageAsync);
  yield takeLatest(ProfileTypes.SET_PROFILE_MOBILE_LOADING, uploadProfileNumberAsync);
  yield takeLatest(ProfileTypes.SEND_OTP_PROFILE, sendOtpAsync);
  yield takeLatest(ProfileTypes.RESEND_PROFILE_OTP, resendOtpAsync);
}

export default profileSaga;