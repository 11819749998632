import React, { useEffect, useRef, useState } from "react";
import BreadcrumbWithFilter from "src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button ,UncontrolledTooltip} from "reactstrap";
import { Form, Formik } from "formik";
import ExportSearchClear from "src/components/Common/ExportSearchClear/ExportSearchClear";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Calendar/DeleteModal";
import ArchiveModal from "../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import { customers } from "src/models/pages/customerModel";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { administrators } from "src/models/pages/administratorModel";
import { deleteAdministratorFromList, getAdministratorList } from "src/store/administrator/action";
import { saveDataAsExcel, saveDataAsPdf } from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";

export default function Administrators() {
  
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.admnistratorReducer.administratorList);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.admnistratorReducer.loading);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<administrators | null>(null);
  const filterDetails: DataTableFilterMeta = {
    name: { value: "", matchMode: "contains" },
    mobileNumber: { value: "", matchMode: "contains" },
    email: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",
    sortOrder: 0,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.ADMINISTRATOR_CREATE);
  };

  const viewHandler = (rowData: administrators) => {
    navigate(`${ROUTER.ADMINISTRATOR_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: administrators) => {
    navigate(`${ROUTER.ADMINISTRATOR_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: administrators) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: administrators, field: string) => {
    return (
      <ColumnAction
      displayData={rowData[field] as string}
      isEdit={true}
      isDelete={true}
      isView={true}
      onViewClick={() => viewHandler(rowData)}
      onEditClick={() => editHandler(rowData)}
      onDeleteClick={() => deleteHandler(rowData)}
    />
    );
  };

  const statusBodyTemplate = (rowData: customers, field: string) => {
    let status = rowData[field]
    return (
      <StatusHghlighter status={status} />
    )

  }

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    {
      label: "Awaiting Login Approval",
      value: "Awaiting Login Approval",
    },
    {
      label: "Awaiting Verification",
      value: "Awaiting Verification",
    },
    {
      label: "Awaiting Confirmation",
      value: "Awaiting Confirmation",
    },
    {
      label: "Active Selected",
      value: "Active Selected",
    },
  ];

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width:"250px" },
      body: (rowData) => actionBodyTemplate(rowData, "name"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "mobileNumber",
      sortable: true,
      header: "Phone",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },

    {
      field: "address",
      sortable: true,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", minWidth: "400px" },
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "action",
    //   sortable: false,
    //   header: "Action",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: actionBodyTemplate,
    //   headerStyle: { whiteSpace: "nowrap" },
    // }, 
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const csvClickHandler = () => {
    saveDataAsExcel(values, "administrator")
  };

  const pdfClickHandler = () => {
    saveDataAsPdf(values, columns, "administrator")
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getAdministratorList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedAdministrator, setSelectedAdministrator] = useState([]);

  const onSelectionChange = (event: any) => {
    
    const value = event.value;

    setSelectedAdministrator(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedAdministrator(values);
    } else {
      setSelectAll(false);
      setSelectedAdministrator([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: formValue["status"],
        }

      },
    }));
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteUsers = (rowData: administrators) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    dispatch(deleteAdministratorFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    showToast("Customer Deleted Successfully", { type: "success" })
  };

  const archiveHandler = () => {
    setSelectAll(false)
    setSelectedAdministrator([])
    setArchiveModal(false)
  }


  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  // const header = <MultiSelect value={visibleColumns.map((eachColumns: Columns) => {
  //   let data: any = {};
  //   data['field'] = eachColumns['field'];
  //   data['header'] = eachColumns['header'];
  //   return data;
  // }

  // ).filter((eachColumn:Columns)=>{
  //   return eachColumn['field'] != 'select';
  // })}
  //   options={columns.map((eachColumns: Columns) => {
  //     let data: any = {};
  //     data['field'] = eachColumns['field'];
  //     data['header'] = eachColumns['header'];
  //     return data;
  //   }).filter((eachColumn:Columns)=>{
  //     return eachColumn['field'] != 'select';
  //   })} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(null);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };
  
  const bulkActionClickHandler = () => {
    if(values.length > 0){
      setSelectAll(true);
      setSelectedAdministrator(values);  
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedAdministrator([]);
  };

  const header = 
  <div className="table-header-container">
    <div className="table-header-container-item">
      {selectedAdministrator.length == 0 && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
        <FeatherIcon icon="layers" className="mr-1"/>
        Bulk actions
      </Button>}
      <AnimatePresence>
      {selectedAdministrator.length > 0 &&
        <>
          <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setDeleteModal(true); }}>
              <FeatherIcon icon="trash-2" className="mr-1" />
              Delete
            </Button>
          </motion.div>
          <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="customize-table-button mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> Cancel selection</Button>
          </motion.div>



        </>
      }
      </AnimatePresence>
   
    </div>
    <div className="flex-grow-1"></div>
    <div className="table-header-container-item">
      <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
        filter className="w-full md:w-14rem" />


      <Button type="button" className="customize-table-button mr-1 ml-1" id="ScheduleUpdateTooltip"
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
        <FeatherIcon icon="sliders" className="" />
        Customize table
      </Button>

      <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
        <div className="feathet-icon">
        <FeatherIcon icon="plus-circle"  />
        </div>
        Add Administrator
      </Button>

      <UncontrolledTooltip
        placement="bottom"
        target="ScheduleUpdateTooltip"
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        className="tooltip-color"
      >
        Choose fields you want to see in the table
      </UncontrolledTooltip>
    </div>

  </div>;



const customizeTableCancelHandler = () => {
  setShowCustomizeTable(false);
};

const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
  let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

  setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  setShowCustomizeTable(false)
};

const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
  let data: any = {};
  data['field'] = eachColumns.field;
  data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
  data['isDisable'] = eachColumns.isStatic;
  data['header'] = eachColumns.header;
  return data;
}).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');



  return (
    <>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              handleDeleteUsers(rowData);
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these administrators?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedAdministrator([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}
        <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Administrators"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedAdministrator}
          bulkActionSatusTitle="Administrators"
        />
          {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear" className="d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={values}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No Administrators found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Administrators {first} to {last} records of {totalRecords} in total"
          selection={selectedAdministrator}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
