import { ErrorMessage } from 'formik';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import ErrorText from './ErrorText';
import Label from './Label';
import React from 'react';
import { Option } from 'src/models/components/inputWrapperModel';
import './DropdownInput.scss'
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';

export interface OptionsModal {
  name: string;
  key: string;
  value: string;
}

interface DropdownProps {
  name: string;
  label: string;
  options: any[];
  value?: string;
  required?: boolean;
  // onChange?: (event: DropdownChangeEvent) => {},
  onChange?: (event: DropdownChangeEvent) => void,
  invalid: boolean;
  labelClassName?: any;
  inputClassName?: string;
  onBlur?: any;
  disabled?: boolean | any;
  customInputClassName?: string;
  onFocusOut?:any;
  showClear?: boolean;
  panelClassName? :string 
}

const DropdownInput = (props: DropdownProps) => {
  const { options, name, label = "", required, invalid, value, labelClassName, inputClassName, onBlur, disabled = false, customInputClassName, panelClassName, showClear=false, ...rest } = props;
  return (
    <div id={`${customInputClassName ? customInputClassName : "single-selector"}`}>
      <Label name={name} label={label} required={required} className={labelClassName} />
      <Dropdown resetFilterOnHide={true} disabled={disabled} options={options} optionLabel="label" id={name} inputId={name} name={name} filter {...rest} className={invalid ? "p-invalid w-full md:w-14rem padding-none" : "w-full md:w-20rem padding-none" + " " +inputClassName} value={value} onBlur={onBlur} dropdownIcon={(opts: any) => {
          return opts.iconProps['data-pr-overlay-visible'] ? <ChevronUpIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
        }} showClear={showClear && !!value} panelClassName={panelClassName ? panelClassName : ""} virtualScrollerOptions={{ itemSize: 35 }}/>
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default DropdownInput;
