import { IncentiveState } from "src/models/pages/incentiveModels";
import { IncentiveTypes } from "./actionTypes";

const initialState: IncentiveState = {
  error: "",
  loading: false,
  message: "",
  incentiveList: {
    values: [],
    totalRecords: 0
  }
};



const incentiveReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case IncentiveTypes.GET_INCENTIVE_LIST_LOADING:
    case IncentiveTypes.UPDATE_INCENTIVE_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message:""
      };

    case IncentiveTypes.GET_INCENTIVE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incentiveList: {
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        },
      };

    case IncentiveTypes.GET_INCENTIVE_EMPTY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incentiveList: {
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        },
      };
    case IncentiveTypes.UPDATE_INCENTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Incentive Updated Successfully",
        error:""
      };

    case IncentiveTypes.GET_INCENTIVE_LIST_FAIL:
    case IncentiveTypes.UPDATE_INCENTIVE_FAIL:
      return {
        ...state,
        message: "",
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default incentiveReducer;
