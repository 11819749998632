import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import {
    Columns,
    LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import { useLocation } from "react-router";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import {
    getAttendanceBYIDList,
    getAttendanceDetailById,
    getAttendanceList,
    reloadUserWorkingHours,
    resetInduvidualAttendance,
} from "src/store/actions";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE } from "src/constants/commonConstants";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { attendance } from "src/models/pages/attendanceModel";
import './Attendance.scss'
import {
    decimalToTime,
    formatTime,
    formatDateTimeMEL,
    saveAsExcelFile,
    formatDateTimeFormServerView,
} from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { useToast } from "src/components/Common/ReactToaster";
import moment from "moment";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import AttendancePopup from "../Calendar/AttendancePopup";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { API } from "src/util/api";

export default function AttendanceList() {
    const { items, totalCount } = useSelector((state: any) => state.attendanceReducer.attendanceList);
    const [attendanceData, setAttendanceData] = useState<any>([]);
    const loading = useSelector((state: any) => state.attendanceReducer.loading);
    const[pdfLoading,setPdfLoading]=useState<boolean>(false)

    const { error, message, paginatorCount, reloadData, attendanceDetail } = useSelector((state: any) => state.attendanceReducer);
    const dispatch = useDispatch();
    const dt = useRef<DataTable<any>>(null);
    const [rowData, setRowData] = useState<attendance | null>(null);
    const [visible, setVisible] = useState(false);
    const Role = JSON.parse(localStorage.getItem("role") || "")
    const calendarRef = useRef<HTMLDivElement>(null);
    const [selectedStatus, setselectedStatus] = useState(ACTIVE);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
    const [showAttendanceModal, setShowAttendanceModal] = useState(false)
    const Location = useLocation();
    const [cityArray, setCityArray] = useState<any>([])
    const { showToast } = useToast();
    const handleClickOutside = (event: MouseEvent) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
            setIsCalendarOpen(false);
        }
    };

    

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const filterDetails: DataTableFilterMeta = {
        StartDate: { value: "", matchMode: "contains" },
        EndDate: { value: "", matchMode: "contains" },
        IsDeleted: { value: "false", matchMode: "contains" },
    };

    let initialLazyState: LazyTableState = {
        first: 0,
        rows: 10,
        page: 1,
        sortField: "creationTime",
        sortOrder: -1,
        filters: filterDetails,
    };

    const [lazyState, setLazyState] = useState(initialLazyState);

    useEffect(() => {
        const preloader: any = document.getElementById("preloader");
        if (loading||pdfLoading) {
            preloader.style.display = "block";
        } else {
            preloader.style.display = "none";
        }
    }, [loading,pdfLoading]);



    useEffect(() => {
        debugger
        if (reloadData) {
            dispatch(reloadUserWorkingHours());
            if (Role === "admin" || Location.pathname === "/attendance") {
                dispatch(getAttendanceList(lazyState));
            } else {
                dispatch(getAttendanceBYIDList(lazyState));
            }
        }
    }, [reloadData])
    useEffect(() => {
        if (error) {
            setVisible(true);
            // showToast(error, { type: "error" })
        }
        if (message) {
            //   showToast(message, { type: "success" });
        }
    }, [error, message]);
    const [detailShow, setDetailShow] = useState<boolean>(false)

    const showDetails = (alldetails: any) => {
        dispatch(getAttendanceDetailById(alldetails))
        setShowAttendanceModal(true)
    }

    const actionBodyTemplate = (rowData: attendance, field: string) => {
        return (
            <ColumnAction
                displayData={rowData[field] as string}
                isView={true}
                onViewClick={() => showDetails(rowData)}
            />
        );
    };
    const actionDateBodyTemplate = (rowData: attendance, field: string) => {
        return (
            <ColumnAction
                displayData={formatDateTimeMEL(rowData[field]) as string}
                isView={false}
            // onViewClick={() => showDetails(rowData.id)}
            />
        );
    };
    const hidePopup = () => {
        setVisible(false);
    };
    const hideAttendance = () => {
        setShowAttendanceModal(false);
        dispatch(resetInduvidualAttendance())
    };
    const dialogContainer = (error: any) => {
        return (
            <>
                <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
            </>
        );
    };
    const AttendancePopupShow = (attendanceDetails: any) => {
        return (
            <>
                <AttendancePopup onCloseClick={hideAttendance} show={showAttendanceModal} viewDetails={attendanceDetails} />
            </>
        );
    };

    const formatDateMEL = (inputDate: any): string => {
        // if (!inputDate || inputDate == null) {
        //     return ""
        // }
        // const date = new Date(inputDate);
        // const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()];
        // const dayOfMonth = date.getDate().toString().padStart(2, '0');
        // const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()];
        // const year = date.getFullYear();
        // return `${dayOfWeek}, ${dayOfMonth}-${month}-${year}`;
        if (!inputDate || inputDate == null) { return ""; }
        const date = moment.utc(inputDate);
        const dayOfWeek = date.format('ddd'); const dayOfMonth = date.format('DD');
        const month = date.format('MMM');
        const year = date.format('YYYY');
        return `${dayOfWeek}, ${dayOfMonth}-${month}-${year}`;
    };

    const formatTimeMEL = (inputTime: any): string => {
        // if (!inputTime || inputTime == null) {
        //     return ""
        // }
        // const date: Date = new Date(inputTime);
        // const localTime: string = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        // return localTime;

        if (!inputTime || inputTime == null) {
            return "";
        }

        const localTime = moment.utc(inputTime).local().format('hh:mm A');
        return localTime;
    }

    const formatStatusTemplate = (rowData: any, logoutTime: any) => {
        return (<button type="button" className="btn">
            <span className={`badge ${rowData ? "bg-success" : rowData === null && logoutTime === null ? "bg-warning" : "bg-danger"} me-1 rounded-circle p-1 pe-1`}><span className="visually-hidden">unread messages</span></span>
            {rowData ? "Active" : rowData === null && logoutTime === null ? "Checkout Missing" : "In Active"} </button>);
    };
    const formatStatusTemplateExcel = (rowData: any, logoutTime: any) => {
        return (rowData ? "Active" : rowData === null && logoutTime === null ? "Checkout Missing" : "In Active");
    };

    const formatHoursFormat = (time: any) => {
        const { hours, minutes, seconds } = decimalToTime(time);
        return formatTime(hours, minutes, seconds);
    }

    const emptyTemplate = (rowData: attendance, field: string) => {
        return rowData[field] ? (rowData[field] as string) : "-";
    }

    /*     useEffect(() => {
            if (items.length > 0) {
                Promise.all(items.map(async (location: { logInLocation: string, id: number }, index: number) => {
                    return await getCurrentLocation(location.logInLocation, location.id);
                })).then((cities: string[]) => {
                    setCityArray(cities)
                    console.log(cities)
                }).catch((error: any) => {
                    console.error('Error fetching locations:', error);
                });
            }
        }, [items]);
    
        const getCityTemplate = (rowData: string) => {
            if (cityArray?.length > 0) {
                const filteredArray = cityArray.filter((obj: any) => {
                    const values = Object.values(obj);
                    return values.includes(rowData);
                });
                const leftSideText = filteredArray.map((obj: any) => Object.keys(obj)[0]);
                return leftSideText.length > 0 ? leftSideText[0] : "-"
            }
        } */
    const TimeShowWithLocation = (time: any, latlong: any) => {
        if (time) {

            return (<div className="location-map">
                <b>{time}</b>
                <Link to={`https://www.google.com/maps/place/${latlong}`} target="_blank"><FeatherIcon icon="map-pin" /></Link>
            </div>
            )
        }
        else {
            return ""
        }
    }
    const columns: Columns[] = [
        {
            field: "name",
            sortable: true,
            header: "User Name",
            filter: false,
            showFilterMenu: false,
            headerStyle: { whiteSpace: "nowrap", width: "100px" },
            isDefault: Role === "admin" || Location.pathname === "/attendance" ? true : false,
            isStatic: false,
            frozen : true,
            body: (rowData) => actionBodyTemplate(rowData, "name"),
        },
        {
            field: "userType",
            sortable: false,
            header: "Role",
            filter: false,
            showFilterMenu: false,
            headerStyle: { whiteSpace: "nowrap" },
            isDefault: Role === "admin" || Location.pathname === "/attendance" ? true : false,
            isStatic: false,
            body: (rowData) => emptyTemplate(rowData, "userType"),
        },
        {
            field: "creationTime",
            sortable: true,
            header: "Date",
            filter: false,
            showFilterMenu: false,
            headerStyle: { whiteSpace: "nowrap", width: "250px" },
            body: (rowData) => Location.pathname === "/attendance" ? formatDateTimeFormServerView(rowData["creationTime"]) : actionDateBodyTemplate(rowData, "creationTime"),
            isDefault: true,
            isStatic: true,
        },
        {
            field: "logInTime",
            sortable: false,
            header: "Check In",
            filter: false,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap" },
            body: (rowData) => TimeShowWithLocation(formatTimeMEL(rowData.logInTime), rowData.logInLocation),
            isDefault: true,
            isStatic: false,
        },

        {
            field: "logOutTime",
            sortable: false,
            header: "Check Out",
            filter: false,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap" },
            body: (rowData) => TimeShowWithLocation(formatTimeMEL(rowData.logOutTime), rowData.logOutLocation),
            isDefault: true,
            isStatic: false,
        },
        {
            field: "totalHours",
            sortable: false,
            header: "Total Hours",
            filter: false,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap" },
            isDefault: true,
            isStatic: false,
            body: (rowData) => formatHoursFormat(rowData.totalHours),
        },

        /*         {
                    field: "logInLocation",
                    sortable: true,
                    header: "Location",
                    filter: false,
                    showFilterMenu: false,
                    headerStyle: { whiteSpace: "nowrap" },
                    isDefault: Role === "admin" || Location.pathname === "/attendance" ? true : false,
                    isStatic: false,
                    body: (rowData) => cityArray?.length ? getCityTemplate(rowData.id) : "", 
                }, */
        {
            field: "status",
            sortable: false,
            header: "Status",
            filter: false,
            showFilterMenu: false,
            headerStyle: { whiteSpace: "nowrap" },
            isDefault: true,
            isStatic: false,
            body: (rowData) => formatStatusTemplate(rowData.totalHours, rowData.logOutTime),
        }
    ];

    const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

    const callbackFunction = (lazyState: LazyTableState) => {
        if (Role === "admin" || Location.pathname === "/attendance") {
            dispatch(getAttendanceList(lazyState));
        } else {
            dispatch(getAttendanceBYIDList(lazyState));
        }
    };

    const onSort = (event: any) => {
        setSelectedAttendance([]);
        setLazyState(event);
    };

    useEffect(() => {
        const InitialAttendanceState: LazyTableState = {
            first: 0,
            rows: 10,
            page: 1,
            sortField: "creationTime",
            sortOrder: -1,
            filters: filterDetails
        }
        setLazyState(InitialAttendanceState)
        if (Role === "admin" || Location.pathname === "/attendance") {
            dispatch(getAttendanceList(lazyState));
        } else {
            dispatch(getAttendanceBYIDList(lazyState));
        }
        setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
        setSelectedDate(null);
        setSelectedEndDate(null);
    }, [Location])

    const [selectedAttendance, setSelectedAttendance] = useState<any>([]);

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        setLazyState(event);
    };

    useEffect(() => {
        callbackFunction(lazyState);
    }, [lazyState]);

    const clearCustomCalendar = () => {
        setSelectedDate(null)
        setSelectedEndDate(null)
        setIsCalendarOpen(false)
        const updatedLazyState = { ...lazyState };
        updatedLazyState.filters.StartDate = { matchMode: "contains", value: "" };
        updatedLazyState.filters.EndDate = { matchMode: "contains", value: "" };
        setLazyState(updatedLazyState);
    }

    const searchCustomCalendar = (event: any) => {
        setIsCalendarOpen(false)
        const updatedLazyState = { ...lazyState };
        updatedLazyState.filters.StartDate = { matchMode: "contains", value: selectedDate ? selectedDate : "" };
        updatedLazyState.filters.EndDate = { matchMode: "contains", value: selectedEndDate ? selectedEndDate : "" };
        setLazyState(updatedLazyState);
    }

    const handleStatusBlur = () => { };

    const FormattedDate = (date: any): string => {
        if (!(date instanceof Date)) {
            return "";
        }
        const formattedDate = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        const [month, day, year] = formattedDate.split(' ');
        const formatDay = day.slice(0, -1);
        const result = `${formatDay}  ${month.toUpperCase()} ${year}`;
        return result;
    };

    const toggleCalendar = (event: any) => {
        event.stopPropagation();
        setIsCalendarOpen(prevState => !prevState);
    };

    const changeColumnDetails = (values: any[]) => {

        let modifiedColumnDetails = values.map((a) => {
            let data: any = {};
            data['name'] = a.name
            data['User Type'] = a.userType
            data['Date'] = formatDateTimeMEL(a.creationTime);
            data['Check In'] = formatTimeMEL(a.logInTime);
            data['Check Out'] = formatTimeMEL(a.logOutTime);
            data['Total Hours'] = formatHoursFormat(a.totalHours);
            data['Status'] = formatStatusTemplateExcel(a.totalHours, a.logOutTime);
            //   return { ...{ Name, Email, Mobile, Address }, ...data };
            return { ...data }
        });
        return modifiedColumnDetails;
    };
    const fetchData = async (isPdf: boolean): Promise<void> => {
        const lazy: any = {
            ...lazyState,
            rows:totalCount>5000 ? 5000 : totalCount<1 ? 1 : totalCount,
            first:0,
            filters: {
                ...lazyState.filters,
            },
        };
    
        try {
            setPdfLoading(true);
    
            let response: any;
            if (Role === "admin" || Location.pathname === "/attendance") {
                response = await API.getAttendanceListAPI(lazy);
            } else {
                response = await API.getCurrentUserAttendanceListAPI(lazy);
            }
    
            if (!response || !response.data) {
                throw new Error('Network response was not ok');
            }
    
    
            if (isPdf) {
                saveDataAsPdf(response.data.items, columns, "My_Attendance");
            } else {
                saveDataAsExcel(response.data.items, "My_Attendance");
            }
        } catch (error: any) {
            showToast(error.message, { type: "error" });
        } finally {
            setPdfLoading(false);
        }
    };
    
    const csvClickHandler = () => {
        // saveDataAsExcel(items, "My_Attendance");
        fetchData(false)
    };

    const pdfClickHandler = () => {
        // saveDataAsPdf(items, columns, "My_Attendance");
        fetchData(true)
    };

    const saveDataAsExcel = async (values: any[], fileName: string) => {
        let modifiedColumnDetails = await changeColumnDetails(values);
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
        const destructureedData = values.map((a) => {
            if (Location.pathname === "/attendance") {
                let data: any = {};
                data['name'] = a.name
                data['userType'] = a.userType
                data['creationTime'] = formatDateTimeMEL(a.creationTime);
                data['logInTime'] = formatTimeMEL(a.logInTime);
                data['logOutTime'] = formatTimeMEL(a.logOutTime);
                data['totalHours'] = formatHoursFormat(a.totalHours);
                data['status'] = formatStatusTemplateExcel(a.totalHours, a.logOutTime);
                return data;
            } else {
                let data: any = {};
                data['name'] = a.name
                data['userType'] = a.userType
                data['creationTime'] = formatDateTimeMEL(a.creationTime);
                data['logInTime'] = formatTimeMEL(a.logInTime);
                data['logOutTime'] = formatTimeMEL(a.logOutTime);
                data['totalHours'] = formatHoursFormat(a.totalHours);
                data['status'] = formatStatusTemplateExcel(a.totalHours, a.logOutTime);
                return data;
            }
        });
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const exportColumns = columns.map((col) => ({
                    title: col.header,
                    dataKey: col.field,
                }));
                const doc = new (jsPDF as any).default(0, 0);
                doc.autoTable(exportColumns, destructureedData);
                doc.save(`${fileName}.pdf`);
            });
        });
    };
    const clearFilter=()=>{
        setSelectedDate(null)
        setSelectedEndDate(null)
        setIsCalendarOpen(false)
        setLazyState(initialLazyState)
      }

    const header =
        <div className="table-header-container calendar-container">
            <div className="">{Location.pathname === "/profile/attendance" ? "My Attendance List" : "Attendance List"} &nbsp;</div>

            {/* <div className={selectedDate && "table-header-border"}>Attendance List &nbsp;</div> */}
            <div className="">
                <div className="main-content-calendar">
                    <span className="startDate">{selectedDate ? FormattedDate(new Date(selectedDate)) : ""}</span>
                    {selectedEndDate && <span className="ps-2 pe-2">-</span>}
                    <span className="endDate">{selectedEndDate ? FormattedDate(new Date(selectedEndDate)) : ""}</span>
                </div>
            </div>
            <div className="table-header-container-item">
                <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
                <div className="btn-group">
                    <button
                        className="btn btn-soft-primary waves-effect waves-light"
                        onClick={toggleCalendar}>
                        <i className="bx bx-calendar font-size-16 align-middle"></i>
                    </button>
                </div>
            </div>
            {isCalendarOpen && (
                <div className="parent-container" ref={calendarRef}>
                    <div className="p-column-filter-overlay p-component p-fluid p-column-filter-overlay-menu p-ripple-disabled p-connected-overlay-enter-done custom-calendar">
                        <div className="p-column-filter-constraints" >
                            <div className="p-column-filter-constraint">
                                <label htmlFor="">Start Date</label>
                                <Input type="date" name="startDate" required value={selectedDate ? selectedDate : ""} max={selectedEndDate ? selectedEndDate : ""}
                                    onChange={(e: any) => {
                                        setSelectedDate(e.target.value)
                                    }} />
                                <br />
                                <label htmlFor="">End Date</label>
                                <Input type="date" name="endDate" required value={selectedEndDate ? selectedEndDate : ""} min={selectedDate ? selectedDate : ""}
                                    onChange={(e: any) => { setSelectedEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="p-column-filter-buttonbar">
                            <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
                                <span className="p-button-label p-c">Clear</span>
                            </button>
                            <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
                                <span className="p-button-label p-c">Apply</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>;

    return (
        <>
            <div className="page-content" id="attendance">
                {visible && dialogContainer(error)}
                {showAttendanceModal && AttendancePopupShow(attendanceDetail)}
                <DataTableWrapper
                    ref={dt}
                    columnDetails={visibleColumns}
                    header={header}
                    value={items}
                    lazy
                    paginator={true}
                    stripedRows={true}
                    rowHover={true}
                    sortIcon={(setOrder) => <SortIcon order={setOrder} />}
                    filterIcon={FilterIcon}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    totalRecords={totalCount}
                    loading={loading}
                    emptyMessage="No attendance found."
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Displaying Attendance  {last} records of {totalRecords} in total"
                    selection={selectedAttendance}
                    first={lazyState.first}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    onFilter={onFilter}
                    filters={lazyState.filters}
                    onPage={onPage}
                    rows={lazyState.rows}
                ></DataTableWrapper>
            </div>
        </>
    );
}
