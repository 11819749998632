import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import InputCom from "src/components/Common/Input";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import FeatherIcon from "feather-icons-react";
import Button from "src/components/UI/Button";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { vehicleBrandsDetails } from "src/models/pages/vehicleBrandsModel";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import MultiSelector from "src/components/Common/MultiSelector";
import ErrorText from "src/components/UI/ErrorText";
import {
  clearBulkSmsMessage,
  createBulkSms,
  getCustomerNumList,
  getDealerNumList,
  getEmployeeNumList,
  getFranchiseNumList,
  getSupplierNumList,
} from "src/store/BulkSms/action";
import MarketingBreadcrumb from "../MarketingBreadcrumb";

const SendEmail: React.FC = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const { id } = useParams();
  const { showToast } = useToast();

  const dispatch = useDispatch();
  const validateMobileNumbers = (array: any) => {
    const mobileNumberRegex = /^\+?\d{10,}$/; // Regular expression for a valid mobile number format (+ followed by at least 10 digits)


    return array.every((mobileNumber: any) => {
      if (!mobileNumber.phone) return true; // Skip validation for null or undefined values
      return mobileNumberRegex.test(mobileNumber.phone); // Check if the mobile number matches the regex pattern
    });
  };

  const validationSchema = Yup.object().shape({
    toUserTypes: Yup.array()
      .of(Yup.string())
      .min(1, "At least one user type must be selected")
      .required("User type is required"),

    smsContent: Yup.string().required("Sms content is required"),
    title: Yup.string().required("Title is required"),
    allMobileNumber: Yup.array()
    .test(
      "is-valid-mobile-numbers",
      "All should be valid mobile numbers",
      function (array) {
        const { otherVariable } = allMobileNum 
        return validateMobileNumbers(allMobileNum) 
      }
    )
    
    .required("Mobile Number List is required"),
  });
  const navigate = useNavigate();

  const formValue: vehicleBrandsDetails = useSelector(
    (state: RootReducerState) =>
      state.vehicleBrandsReducer?.vehicleBrandsDetails as vehicleBrandsDetails
  );

  const {
    error,
    message,
    loading,
    employeeNum,
    dealerNum,
    customerNum,
    franchiseNum,
    supplierNum,
  } = useSelector((state: RootReducerState) => state.BulkSmsReducer);
  const [numbers, setnumbers] = useState<any>([]);
  const [userTypes, setUserTypes] = useState<any>([]);
  const initialvalue: any = {
    toUserTypes: [],
    title: "",
    allMobileNumber: numbers,
    smsContent: "",
  };
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
    }
    if (message) {
      showToast(message, { type: "success" });

      dispatch(clearBulkSmsMessage());
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.BULK_SMS);
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearBulkSmsMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleSubmit = async (values: any) => {
    if(allMobileNum.length<1) return
    setIsSubmitting(true);
    // id ? update(values) : post(values);
    let num=allMobileNum?.map((numbers:any)=>numbers.phone)
    let formatted={
      id:0,
      allMobileNumber:num,
      smsContent:values.smsContent,
      title:values.title,
      toUserTypes:values.toUserTypes.length>0 ? values.toUserTypes : null
    }
    
    post(formatted);
  };

  // const update = (formValue: vehicleBrandsDetails) => {
  //   dispatch(updateVehicleBrands(formValue, id));
  //   if (selectedFiles.length > 0) {
  //     dispatch(uploadVehicleBrandFileLoading(selectedFiles[0], id));
  //   }
  // };

  const post = (formValue:any) => {
    
    
    dispatch(createBulkSms(formValue));
  };

  const handleReset = (resetForm: any) => {
    setContent("");
    resetForm(getEmptyInitialValues());
    setIsSubmitting(false)
  };

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== "checkbox") {
        acc[field.name] = "";
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  const toUserTypes = [
    { label: "Employees", value: "Employees" },
    { label: "Dealer", value: "Dealer" },
    { label: "Customer", value: "Customer" },
    { label: "Franchise", value: "Franchise" },
    // { label: "Dealer Subuser", value: "Dealer Subuser" },
    // { label: "Franchise Subuser", value: "Franchise Subuser" },
    { label: "Supplier", value: "Supplier" },
    { label: "Others", value: "Others" },
  ];
  const [emailValue, setEmailValue] = useState<any>([]);
  useEffect(() => {
    dispatch(getEmployeeNumList());
    dispatch(getDealerNumList());
    dispatch(getCustomerNumList());
    dispatch(getFranchiseNumList());
    dispatch(getSupplierNumList());
    return () => {
      dispatch(clearBulkSmsMessage());
    };
  }, []);
  const [allMobileNum, setAllMobilenum] = useState<any>([]);
  const handleSelect = (selectedOptions: any, setFieldValue: any) => {
    setFieldValue("toUserTypes", selectedOptions);
    // Update allMobileNumber based on selected user types
    const updatedPhoneNum = [];
    if (selectedOptions.includes("Employees")) {
      updatedPhoneNum.push(...employeeNum);
    }
    if (selectedOptions.includes("Dealer")) {
      updatedPhoneNum.push(...dealerNum);
    }
    if (selectedOptions.includes("Customer")) {
      updatedPhoneNum.push(...customerNum);
    }
    if (selectedOptions.includes("Franchise")) {
      updatedPhoneNum.push(...franchiseNum);
    }
    if (selectedOptions.includes("Supplier")) {
      updatedPhoneNum.push(...supplierNum);
    }

    setFieldValue("allMobileNumber", updatedPhoneNum?.filter((item:any)=>item.phone));
    setAllMobilenum(updatedPhoneNum);
  };
  const [content, setContent] = useState("");
  const msgChange = (val: any) => {
    let len = val.length;
    if (len > 1) {
      setContent("");
    }
    let msgCount = Math.ceil(len / 150);
    setContent(`Character length ${len}/msg count(${msgCount})`);
  };

  const handleRemoveParts = (id: number) => {
    const RemoveParts = allMobileNum.filter(
      (item: any, index: number) => index !== id
    );
    setAllMobilenum(RemoveParts);
  };
  const handleMobileAdd = (event: any) => {
    if (event.key == "Enter" || event.key=="Tab" ) {
    event.preventDefault()
      setAllMobilenum((prevState: any) => [...prevState, {name:"",phone:event.target.value.trim()}]);
      setText("")
    }
  };

  const[text,setText]=useState<any>("")
  const [filter, setFilter] = useState('');
  return (
    <React.Fragment>
      {visible && dialogContainer(error)}

      <div className="page-content" id="view-brand">
        <Container fluid>
          <MarketingBreadcrumb
            title="Bulk Sms List"
            titlePath={ROUTER.BULK_SMS}
            breadcrumbItem={`Send Sms`}
            filter={filter}
            setFilter={setFilter}
          />
          
          <Row id="email">
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldValue,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <MultiSelector
                                name="toUserTypes"
                                label="User Types"
                                options={toUserTypes}
                                optionLabel="label"
                                id="toUserTypes"
                                labelClassName="form-Label"
                                //onBlur={handleBlur}
                                onBlur={handleBlur("toUserTypes")}
                                value={values.toUserTypes}
                                onChange={(e: any) => {
                                  handleSelect(e.target.value, setFieldValue);
                                }}
                                isRequired={true}
                                invalid={
                                  touched["toUserTypes"] &&
                                  errors["toUserTypes"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Title"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="title"
                                onBlur={handleBlur}
                                value={values.title}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["title"] && errors["title"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={12} className="mt-2">
                              <InputCom
                                labelName="Sms Content"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="smsContent"
                                onBlur={handleBlur}
                                value={values.smsContent}
                                onChange={(e) => {
                                  handleChange(e);
                                  msgChange(e.target.value);
                                }}
                                isRequired={true}
                                invalid={
                                  touched["smsContent"] && errors["smsContent"]
                                    ? true
                                    : false
                                }
                                rowHeight={7}
                              />
                              <div className="">{content}</div>
                            </Col>
                            {values.toUserTypes?.length>0 && 
                              <Col lg={12} className="mt-2">
                                <label htmlFor="allMobileNumber">
                                  To<span className="text-danger ml-05">*</span>
                                </label>
                                {/* <Chips value={values.allMobileNumber}  onChange={(e) => setFieldValue('allMobileNumber', e.value)} separator="," className={  values["allMobileNumber"].length<1 ||(touched["allMobileNumber"] && errors["allMobileNumber"]) ? "is-invalid" : "chip-email"} /> */}
                                <div className="p-chips p-component p-inputwrapper p-inputwrapper-filled custom-chip">
                                  <ul className="p-inputtext p-chips-multiple-container p-variant-filled" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {allMobileNum.map(
                                      
                                      (item: any, index: any) => {
                                        const isMatch = filter && (
                                          item.name?.toLowerCase().includes(filter.toLowerCase()) ||
                                          item.phone.toLowerCase().includes(filter.toLowerCase())
                                      );
                                        return (
                                        <li key={index}className={isMatch ? "p-chips-token highlight-chip" : "p-chips-token"}>
                                          <span className="p-chips-token-label">{item.name ? `${item.name} - ${item.phone}` : item.phone}</span>
                                          <FeatherIcon icon="x-circle" className="p-icon p-chips-token-icon" onClick={() => handleRemoveParts(index) }/>
                                        </li>
                                      )}
                                    )}
                                    <li className="p-chips-token"  style={{listStyle:"none",background:"none",flex:"1 0 auto"}} id="email-input">
                                      <input
                                        type="text"
                                        style={{backgroundColor:"rgb(235 235 235)",width:"100%",outline:"none",border:"none"}}
                                        onKeyDown={(e) => handleMobileAdd(e)}
                                        value={text}
                                        onChange={(e:any)=>setText(e.target.value)}
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <p style={{fontSize:"12px",marginTop:"-10px"}}>Please press the Enter key after entering the mobile number.</p>
                                {allMobileNum.length>0 && 
                                <ErrorMessage
                                  name={"allMobileNumber" || ""}
                                  component={ErrorText}
                                />
                    }
                                { (isSubmitting && allMobileNum.length<1) ? <div className="text-danger">Mobile Number is required</div>:<></>}
                              </Col>
                            }
                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                className="secondary-btn me-2"
                                label={"Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={"Send Sms"}
                                type="submit"
                                onClick={()=>setIsSubmitting(true)}
                              />
                            </div>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SendEmail;
