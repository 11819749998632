import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';

import './styles.scss'
import { getAudioBrandDropDownListLoading, getByIdAudioModelPending } from 'src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { Link } from 'react-router-dom';
const ViewAudioModel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { audioModels, audioBrandList } = useSelector((state: RootReducerState) => state.audioModelsReducer.audioBrandNames) || {};


  const getAudioBrandName = (audioModel: any) => {
    const matchedNameObject = audioBrandList?.find((item: any) => item.id === audioModel);
    if (matchedNameObject) {
      const matchedName = matchedNameObject.brandName;
      return <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${audioModel}`}> {matchedName}</Link>
    }
  }


  const AudioModelDetails = [
    {
      name: "Model Number",
      value: audioModels?.number ? audioModels?.number : ""
    },
    {
      name: "Description",
      value: audioModels?.description ? audioModels?.description : ""
    },
    {
      name: "Service Charge",
      value: audioModels?.serviceCharge ? audioModels?.serviceCharge : 0
    },
    {
      name: "Exchange Fixed Fee",
      value: audioModels?.exchangeFixedFee ? audioModels?.exchangeFixedFee : 0
    },
    {
      name: "Sac Number",
      value: audioModels?.sacNumber ? audioModels?.sacNumber : ""
    },
    {
      name: "Sac Tax",
      value: audioModels?.taxpercentage ? audioModels?.taxpercentage : "0"
    },
    {
      name: "Audio Brand",
      value: audioModels?.audioBrandId ? getAudioBrandName(audioModels?.audioBrandId) : ""
    },
    {
      name: "Audio Part Number",
      value: audioModels?.audioPartNumber ? audioModels?.audioPartNumber : ""
    },
    // {
    //   name : "Status",
    //   value : "✅"
    // },
  ]

  useEffect(() => {
    dispatch(getByIdAudioModelPending(id))
    dispatch(getAudioBrandDropDownListLoading())
  }, [])



  const editAudio = () => {
    navigate(`${ROUTER.AUDIO_MODELS_EDIT}/${id}`)
  }
  const archiveAudio = () => {

  }



  const createAudioModel = () => {
    navigate(`${ROUTER.AUDIO_MODELS_CREATE}`)
  }
  return (
    <React.Fragment>
      <div className="audiobrandPage" id='audiobrandPage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Audio Model"
            titlePath={ROUTER.AUDIO_MODELS_GRID}
            breadcrumbItem="Audio Model Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Audio Model'
            onFirstButtonClick={editAudio}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Audio Model Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    AudioModelDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div >{data.name}</div>
                          <div>{data.value}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewAudioModel