import { ErrorMessage } from 'formik';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import ErrorText from './ErrorText';
import Label from './Label';
import React from 'react';

interface OptionsModal {
  name: string
  key: string
  value: string
}

interface MultiSelectProps {
  label: string
  name: string
  options: OptionsModal[]
  required?: boolean
  onChange?: (event: MultiSelectChangeEvent) => {}
}

const MultiSelectInput = (props: MultiSelectProps) => {
  const { options, name, label, required, ...rest } = props
  return (
    <div>
      <Label name={name} label={label} required={required} />
      {/* <label htmlFor={name}>{label}</label> */}
      <MultiSelect options={options} optionLabel="name"
        placeholder={label} maxSelectedLabels={3} className="w-full md:w-20rem" {...rest} />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  )
};

export default MultiSelectInput;
