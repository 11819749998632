import React, { PropsWithChildren } from "react"

const ErrorText = (props: PropsWithChildren) => {
  return (
    <div className="text-danger">
      {props && <small>{props?.children}</small>}
    </div>
  )
};

export default ErrorText;