import { ErrorMessage } from 'formik';
import { InputText } from 'primereact/inputtext';
import ErrorText from './ErrorText';
import Label from './Label';
import React, { FC } from 'react';

interface TextInputProps {
  name: string
  label: string
  type: string
  value: string
  required?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => {},
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => {},
}

const TextInput = (props: TextInputProps) => {

  const { label, name, type, value, required = false, ...rest } = props

  return (
    <div className="mb-3">
      <Label name={name} label={label} required={required}/>
      {/* <label htmlFor={name} className='form-label'>{label}</label> */}
      <InputText type={type} name={name} value={value} {...rest} id={name} className="form-control" />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default TextInput;
