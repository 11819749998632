import React from "react";
import DefaultColumnFilter from "./DefaultColumnFilter";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import GlobalFilter from "./GlobalFilter";
import { Table } from "reactstrap";
import MelPagination from "./MelPagination";

interface TableProps {
  columns: any;
  data: any;
  globalFilter: boolean;
  customPageSize: number;
}

const MelTable: React.FC<TableProps> = ({
  columns,
  data,
  globalFilter,
  customPageSize,
}) => {
  const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}),[]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    page,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: customPageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const handleEdit=(data: any)=> {
    // Your logic for handling the edit action goes here
  }

  function handleDelete(data: any) {
    // Your logic for handling the delete action goes here
  }

  return (
    <React.Fragment>
      {globalFilter && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}

      <Table hover className="table mt-2" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th key={column.id}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </div>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : <div className="mb-5"></div>}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <MelPagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        data={data}
        gotoPage={gotoPage}
        nextPage={nextPage}
        page={page}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    </React.Fragment>
  );
};

export default MelTable;
