import React from 'react';

interface timeago {
    timeStamp: any
}

const TimeAgo : React.FC<any> = ({ timeStamp }: timeago) => {
    let timeAgo = "";
    if (timeStamp) {
        var date = new Date(timeStamp);
        var localOffset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - localOffset);
        timeAgo = date.toLocaleString();
    }
    return (
        <span title={timeStamp}>
            &nbsp; <i>{timeAgo}</i>
        </span>
    )
}

export default TimeAgo