import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { PurchaseOrderTypes } from "./actionTypes";
import { createPurchaseOrderError, createPurchaseOrderSuccess, deletePurchaseOrderDetailsError, deletePurchaseOrderDetailsSuccess, deletePurchaseOrderFromListError, deletePurchaseOrderFromListSuccess, downloadPurchaseOrderError, downloadPurchaseOrderSuccess, getDropdownForPurchaseError, getDropdownForPurchaseSuccess, getPurchaseOrderAudioModelDropdownError, getPurchaseOrderAudioModelDropdownSuccess, getPurchaseOrderByIdError, getPurchaseOrderByIdSuccess, getPurchaseOrderList, getPurchaseOrderListError, getPurchaseOrderListSuccess, partsAutoCompleteListError, partsAutoCompleteListSuccess, sentMailToSupplierPurchaseOrderError, sentMailToSupplierPurchaseOrderSuccess, updatePurchaseOrderError, updatePurchaseOrderSuccess } from "./action";



function* getPurchaseOrderListAsync(action : any) {

  try {
    const response : Promise<any> = yield call(
        API.getPurchaseOrderListAPI,{...action.lazyState}
    )
    yield put(getPurchaseOrderListSuccess(response));
  } catch (error) {
    yield put(getPurchaseOrderListError(error));
  }
}

function* fetchPurchaseOrder({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getPurchaseOrderById, payload);
    yield put(getPurchaseOrderByIdSuccess(data));

  } catch (error) {
    yield put(getPurchaseOrderByIdError(error));
  }
}


function* createPurchaseOrderAsync(action : any) {
  
  try {
    const response : Promise<any> = yield call(
        API.createPurchaseOrderApi,(action.payload)
        )
    yield put(createPurchaseOrderSuccess(response))  
    
  } catch (error) {
    yield put(createPurchaseOrderError(error));
  }
}


// function* updatePurchaseOrderAsync(action : any) {
//   try {
//     const response : Promise<any> = yield call(
//         API.updatePurchaseOrderListAPI ,{action}
//         )
//     yield put(updatePurchaseOrderSuccess(response))  
    
//     yield put(getPurchaseOrderList(action.lazyState))
//   } catch (error) {
//     yield put(updatePurchaseOrderError(error));
//   }
// }


function* deletePurchaseOrderAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.deletePurchaseOrderListAPI ,{id : action.payload.id, undo:action.payload.undo}
        )
    yield put(deletePurchaseOrderFromListSuccess (response,action.payload.undo))  
  } catch (error) {
    yield put(deletePurchaseOrderFromListError(error));
  }
}

//getCustomer list
function* getDropdownForPurchaseOrdersAsync() {

  try {
    const response: Promise<any> = yield call(API.getSupplierListFromMasterManagement,'')
    yield put(getDropdownForPurchaseSuccess(response));
  } catch (error) {
    yield put(getDropdownForPurchaseError(error));
  }
}

//  update parts
function* updatePurchaseOrder({
  payload: { purchaseOrderDetails, id },
}: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updatePurchaseOrderListAPI, purchaseOrderDetails, id);
 
    yield put(updatePurchaseOrderSuccess(data));

  } catch (error) {
    yield put(updatePurchaseOrderError(error));
  }
}


function* deletePurchaseOrderDetailsAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.deletePurchaseOrderDetailsAPI ,{id : action.payload.id }
        )
    yield put(deletePurchaseOrderDetailsSuccess(response))  
  } catch (error) {
    yield put(deletePurchaseOrderDetailsError(error));
  }
}


function* getPartsAutocompleteListAsync(action : any) {

  try {
    const response : Promise<any> = yield call(
        API.getPartsListAutoCompleteAPI,action.params
    )
    yield put(partsAutoCompleteListSuccess(response));
  } catch (error) {
    yield put(partsAutoCompleteListError(error));
  }
}


// ======= purchase order audioModel dropdown =======

function* getPurchaseOrderDropdownAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.getpurchaseOrderAudioModelAPI,action.payload.id ? action.payload.id : action.payload )
      yield put(getPurchaseOrderAudioModelDropdownSuccess(response,action.payload.index));
    } catch (error) {
      yield put(getPurchaseOrderAudioModelDropdownError(error));
    }
  }


function* sentMailToPurchaseOrderAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.sentMailToSupplierForPurchaseOrder,action.payload )
      yield put(sentMailToSupplierPurchaseOrderSuccess(response));
    } catch (error) {
      yield put(sentMailToSupplierPurchaseOrderError(error));
    }
  }
  
  
function* downloadPurchaseOrderAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.downLoadPurchaseOrder,action.payload )
      yield put(downloadPurchaseOrderSuccess(response));
    } catch (error) {
      yield put(downloadPurchaseOrderError(error));
    }
  }
  



function* purchaseOrderSaga() {
  yield takeLatest(PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST, getPurchaseOrderListAsync);
  yield takeLatest(PurchaseOrderTypes.DELETE_PURCHASE_ORDER, deletePurchaseOrderAsync);
  yield takeLatest(PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID,fetchPurchaseOrder);
  yield takeLatest(PurchaseOrderTypes.UPDATE_PURCHASE_ORDER,updatePurchaseOrder);
  yield takeLatest(PurchaseOrderTypes.CREATE_PURCHASE_ORDER,createPurchaseOrderAsync);
  yield takeLatest(PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER,getDropdownForPurchaseOrdersAsync);
  yield takeLatest(PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS,deletePurchaseOrderDetailsAsync);
  yield takeLatest(PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST,getPartsAutocompleteListAsync);
  yield takeLatest(PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_LOADING,getPurchaseOrderDropdownAsync);
  yield takeLatest(PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER,sentMailToPurchaseOrderAsync);
  yield takeLatest(PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_LOADING,downloadPurchaseOrderAsync);
  
}

export default purchaseOrderSaga;