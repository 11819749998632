import { subUser } from "src/models/pages/subUserModel";
import { ManufacturerType } from "./actionTpes";



//grid

export const getManufacturerListLoading = (lazyState: any) => {
  debugger
  return {
    type: ManufacturerType.GET_MANUFACTURER_LIST_LOADING,
    lazyState,
  }
}

export const getManufacturerListSuccess = (subuser: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_LIST_SUCCESS,
    payload: subuser
  }
}

export const getManufacturerListError = (error: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_LIST_ERROR,
    payload: error
  }
}

//delete

export const deleteManufacturerLoading = (payload: any) => {
  return {
    type: ManufacturerType.DELETE_MANUFACTURER_LOADING,
    payload: payload,
    
  }
}

export const deleteManufacturerSuccess = (response: any,undo:boolean) => {
  return {
    type: ManufacturerType.DELETE_MANUFACTURER_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteManufacturerError = (error: any) => {
  return {
    type: ManufacturerType.DELETE_MANUFACTURER_ERROR,
    payload: error
  }
}

//create Subuser

export const createManufacturerLoading = (subuser: any) => {
  return {
    type: ManufacturerType.CREATE_MANUFACTURER_LOADING,
    payload: subuser,
    
  }
}

export const createManufacturerSucess = (subuser : any) => {
  return {
    type: ManufacturerType.CREATE_MANUFACTURER_SUCCESS,
    payload : subuser
  }
}

export const createManufacturerError = (error: any) => {
  return {
    type: ManufacturerType.CREATE_MANUFACTURER_ERROR,
    payload: error
  }
}

//update Subuser

export const updateManufacturerLoading = (subuser: any,id : any) => {
  return {
    type: ManufacturerType.UPDATE_MANUFACTURER_LOADING,
    payload: subuser,
    id
  }
}

export const updateManufacturerSuccess = (subUser : any) => {
  return {
    type: ManufacturerType.UPDATE_MANUFACTURER_SUCCESS,
    payload : subUser
  }
}

export const updateManufacturerError = (error: any) => {
  return {
    type: ManufacturerType.UPDATE_MANUFACTURER_ERROR,
    payload: error
  }
}

//getSubuser detail by id

export const getManufacturerByIdLoading = (id: string) => {
  return {
    type: ManufacturerType.GET_MANUFACTURERBYID_LOADING,
    payload: id,
  }
}

export const getManufacturerByIdSuccess = (subUserDetails: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURERBYID_SUCCESS,
    payload: subUserDetails,
  };
};

export const getManufacturerByIdError = (error: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURERID_ERROR,
    payload: error,
  };
};

//reset

export const resetManufacturer = () => {
  return {
    type: ManufacturerType.RESET_MANUFACTURER,
  }
}

export const clearManufacturerMessage = () => {
  return {
    type: ManufacturerType.CLEAR_MANUFACTURER_MESSAGE,
  }
}

export const getManufacturerProfileLoading = (id: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_PROFILE_LOADING,
    id:id
  }
}

export const getManufacturerProfileSuccess = (profile: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_PROFILE_SUCCESS,
    payload: profile,
  };
};

export const getManufacturerProfileError = (error: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_PROFILE_ERROR,
    payload: error,
  };
};

export const uploadManufacturerProfileLoading = (file:any,id: string) => {
  return {
    type: ManufacturerType.UPLOAD_MANUFACTURER_PROFILE_LOADING,
    payload: file,
    id:id
  }
}

export const uploadManufacturerProfileSuccess = (resp: any) => {
  return {
    type: ManufacturerType.UPLOAD_MANUFACTURER_PROFILE_SUCCESS,
    payload: resp,
  };
};

export const uploadManufacturerProfileError = (error: any) => {
  return {
    type: ManufacturerType.UPLOAD_MANUFACTURER_PROFILE_ERROR,
    payload: error,
  };
};

export const deleteMANUFACTURERProfileLoading = (id: any) => {
  return {
    type: ManufacturerType.DELETE_MANUFACTURER_PROFILE_LOADING,
    id:id
  }
}

export const deleteManufacturerProfileSuccess = (MANUFACTURERDetails: any) => {
  return {
    type: ManufacturerType.DELETE_MANUFACTURER_PROFILE_SUCCESS,
    payload: MANUFACTURERDetails,
  };
};

export const deleteManufacturerProfileError = (error: any) => {
  return {
    type: ManufacturerType.DELETE_MANUFACTURER_PROFILE_ERROR,
    payload: error,
  };
};

export const setManufacturerMobileNumberLoading = (number:any,id: any) => {
  return {
    type: ManufacturerType.MANUFACTURER_SET_PHONE_LOADING,
    payload: {id:id,number:number},
  };
}
export const setManufacturerMobileNumberSuccess = (resp: any) => {
  return {
    type: ManufacturerType.MANUFACTURER_SET_PHONE_SUCCESS,
    payload: resp,
  };
}
export const setManufacturerMobileNumberError = (error: any) => {
  return {
    type: ManufacturerType.MANUFACTURER_SET_PHONE_ERROR,
    payload: error,
  };
}

export const setManufacturerEmailIdLoading = (email:any,id: any) => {
  return {
    type: ManufacturerType.MANUFACTURER_SET_EMAIL_LOADING,
    payload: {id:id,email:email},
  };
}
export const setManufacturerEmailIdSuccess = (resp: any) => {
  return {
    type: ManufacturerType.MANUFACTURER_SET_EMAIL_SUCCESS,
    payload: resp,
  };
}
export const setManufacturerEmailIdError = (error: any) => {
  return {
    type: ManufacturerType.MANUFACTURER_SET_EMAIL_ERROR,
    payload: error,
  };
}


export const sendManufacturerOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
  return {
    type: ManufacturerType.SEND_OTP_MANUFACTURER,
    payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
  };
}
export const sendManufacturerOtpSuccess = (resp: any) => {
  return {
    type: ManufacturerType.SEND_OTP_MANUFACTURER_SUCCESS,
    payload: resp,
  };
}
export const sendManufacturerOtpError = (error: any) => {
  return {
    type: ManufacturerType.SEND_OTP_MANUFACTURER_ERROR,
    payload: error,
  };
}
export const resendOtpManufacturer = (id: any,number:any) => {
  return {
    type: ManufacturerType.RESEND_MANUFACTURER_OTP,
    payload: id,
    number:number
  };
}
export const resendOtpManufacturerSuccess = (resp: any) => {
  return {
    type: ManufacturerType.RESEND_MANUFACTURER_OTP_SUCCESS,
    payload: resp,
  };
}
export const resendOtpManufacturerError = (resp: any) => {
  return {
    type: ManufacturerType.RESEND_MANUFACTURER_OTP_ERROR,
    payload: resp,
  };
}

export const resetOtpManufacturerMessage = () => {
  return {
    type: ManufacturerType.RESET_MANUFACTURER_OTP_MESSAGE,
  };
}

export const ManufacturerLatLagLoading = (selectedArea: string) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const ManufacturerLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const ManufacturerLatLagError = (error: any) => {
  return {
    type: ManufacturerType.GET_MANUFACTURER_LOCATION_ERROR,
    payload: error
  }
}

export const manufacturerVehicleBrandList=()=>{
  return {
    type : ManufacturerType.GET_VEHICLE_BRAND_LIST,
  }
}

export const manufacturerVehicleBrandListSuccess=(jobPrefixList : any)=>{
  return {
    type : ManufacturerType.GET_VEHICLE_BRAND_LIST_SUCCESS,
    payload : jobPrefixList
  }
}

export const manufacturerVehicleBrandListError=(error : any)=>{
  return {
    type : ManufacturerType.GET_VEHICLE_BRAND_LIST_ERROR,
    payload : error
  }
}
