import React, { useState, useEffect } from 'react';
import FeatherIcon from "feather-icons-react";
import './CheckIn.scss';
import { attendance, LogInSource } from 'src/models/pages/attendanceModel';
import {
  createAttendance, updateAttendance, clearAttendanceMessage,
  getUserCurrentAttendance, getUserWorkingHours
} from 'src/store/attendance/action';
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { calculateTimeDifference, decimalToTime, formatTime, addTimes, minusTimes, newCalculateTimeDifference } from 'src/helpers/common_helpers';
import moment from 'moment';

const CheckIn: React.FC = () => {
  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);
  const [time, setTime] = useState<any>(0);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState<string>("");
  const formValue: attendance = useSelector(
    (state: RootReducerState) => state.attendanceReducer?.attendance as attendance
  );
  const userWorkingHours: any = useSelector(
    (state: RootReducerState) => state.attendanceReducer?.workingHours as any
  );
  const { error, message, loading } = useSelector(
    (state: RootReducerState) => state.attendanceReducer
  );

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
    return () => {
      dispatch(clearAttendanceMessage());
    };
  }, [loading]);

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      const obj = JSON.parse(getAuthUser);
      const id = obj.uid;
      setUserId(id);
      if (id) {
        dispatch(getUserWorkingHours());
        dispatch(getUserCurrentAttendance(id));
      }
    }
  }, [dispatch]);

  const formatTime = (hours: number, minutes: number, seconds: number): string => {
    const hh = hours < 10 ? '0' + String(hours) : hours.toString();
    const mm = minutes < 10 ? '0' + String(minutes) : minutes.toString();
    const ss = seconds < 10 ? '0' + String(seconds) : seconds.toString();
    return `${hh}:${mm}:${ss}`;
  };

  useEffect(() => {
    // console.log(userWorkingHours)
    let timer: any;
    if (formValue) {
      if (formValue?.status === "Login") {
        formValue.status === "Login" ? setIsCheckedIn(true) : setIsCheckedIn(false);
        let loginTime = new Date(formValue.logInTime);
        const { hours, minutes, seconds } = decimalToTime(userWorkingHours?.totalHours);
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
          return
        }
        const previousWorkingHours = formatTime(hours, minutes, seconds);
        const currentWorkingHours = calculateTimeDifference(loginTime);
        const initialTime = addTimes(previousWorkingHours, currentWorkingHours);
        setTime(initialTime);
        timer = setInterval(() => {

          setTime((prevTime: any) => {
            let [hours, minutes, seconds] = [0, 0, 0];
            try {
              [hours, minutes, seconds] = prevTime?.split(':').map(Number);
            } catch {
              [hours, minutes, seconds] = [0, 0, 0];
            }
            let newSeconds = seconds + 1;
            let newMinutes = minutes;
            let newHours = hours;
            if (newSeconds === 60) {
              newSeconds = 0;
              newMinutes++;
              if (newMinutes === 60) {
                newMinutes = 0;
                newHours++;
              }
            }
            return formatTime(newHours, newMinutes, newSeconds);
          });
        }, 1000);
      }
    } else {
      const { hours, minutes, seconds } = decimalToTime(userWorkingHours?.totalHours);
      const previousWorkingHours = formatTime(hours, minutes, seconds);
      setIsCheckedIn(false);
      setTime(previousWorkingHours ? previousWorkingHours : 0)
    }
    return () => clearInterval(timer);
  }, [formValue, isCheckedIn, userWorkingHours]);

  // useEffect(() => {
  //   let timer : any;
  
  //   if (formValue && formValue.status === "Login") {
  //     if (!formValue.logInTime) {
  //       console.error("Error: logInTime is null or undefined.");
  //       return;
  //     }
  //     const loginTime : any = moment(formValue.logInTime);
  //     console.log("loginTime",loginTime)
  //     if (!loginTime.isValid()) {
  //       console.error("Error: Invalid logInTime format.");
  //       return;
  //     }
  //     const { hours, minutes, seconds } = decimalToTime(userWorkingHours?.totalHours);
  //     if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
  //       return;
  //     }
  //     const previousWorkingHours = formatTime(hours, minutes, seconds);
  //     const currentMoment: moment.Moment = moment();
  //     const currentWorkingHours = newCalculateTimeDifference(loginTime,currentMoment);
  //     const initialTime = addTimes(previousWorkingHours, currentWorkingHours);
  //     setTime(initialTime);
  
  //     timer = setInterval(() => {
  //       setTime((prevTime : any) => {
  //         const timeMoment = moment(prevTime, 'HH:mm:ss');
  //         timeMoment.add(1, 'second');
  //         return timeMoment.format('HH:mm:ss');
  //       });
  //     }, 1000);
  //   } else {
  //     const { hours, minutes, seconds } = decimalToTime(userWorkingHours?.totalHours);
  //     const previousWorkingHours = formatTime(hours, minutes, seconds);
  //     setIsCheckedIn(false);
  //     setTime(previousWorkingHours ? previousWorkingHours : '00:00:00'); // Set initial time string
  //   }
  
  //   // Clear interval on dependency change
  //   return () => clearInterval(timer);
  // }, [formValue, isCheckedIn, userWorkingHours]);

  const handleToggle = () => {
    debugger
    if(loading) return
    const currentSource = navigator;
    
    const Platform = navigator.platform;
    currentSource.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const CheckinDetails = {
          currentLocation: `${latitude},${longitude}`,
          logInLocation: `${latitude},${longitude}`,
          logInSource: Platform === "Win32" ? LogInSource.Web : LogInSource.Mobile
        };
        const CheckOutDetails = {
          logOutLocation: `${latitude},${longitude}`
        };
        isCheckedIn ? update(CheckOutDetails) : post(CheckinDetails);
        // if(formValue){
        //   formValue?.status === "Login" ? update(CheckOutDetails) : post(CheckinDetails);
        // }
      },
      (error) => {
        console.error('Error getting user location:', error);
        if (error.code === error.PERMISSION_DENIED) {
          alert('Location permission is required to perform this action.');
        }
      }
    );
  };



  useEffect(() => {
    if (message === "Logoff") {
      dispatch(getUserWorkingHours());
      dispatch(getUserCurrentAttendance(userId));
      setIsCheckedIn(false);
      setTime(0);
    } else if (message === "Login") {
      dispatch(getUserWorkingHours());
      dispatch(getUserCurrentAttendance(userId));
      setIsCheckedIn(true);
    }
  }, [message]);

  const update = (formValue: attendance) => {
    dispatch(updateAttendance(formValue));
  };

  const post = (formValue: attendance) => {
    dispatch(createAttendance(formValue));
  };

  return (
    <React.Fragment>
      <div className={`d-inline-block dropdown ${isCheckedIn ? 'CheckedOut' : 'CheckedIn'}`}>
        <div className='header-item d-flex ps-0'>
          <button disabled={loading} className="btn m-auto attendance" onClick={handleToggle}>
            <span className='pull-left'>   {isCheckedIn ? 'Check-out' : 'Check-in'}</span>
            <FeatherIcon icon="clock" className="icon-lg pull-right" />
          </button>
        </div>
      </div>
      <div className="d-inline-block">
        <label data-on-label="Yes" data-off-label="No" className="form-label"></label>
        {time != 'NaN:NaN:NaN' && <button className='btn header-item ps-2 right-bar-toggle'> {time ? `${time} Hrs` : `00:00Hrs`}</button>}
      </div>
    </React.Fragment>
  );
};

export default CheckIn;