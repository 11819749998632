import { incomingDispatchTypes } from "./actionTypes";
import { inComingDispatchInitialState } from "src/models/pages/incomingDispatch";




const initialState: inComingDispatchInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  incomingDispatchList: {
    values: [],
    totalRecords: 0
  },
  incomingDispatch: null,
  ReceivedById:""
};



const IncomingDispatch = (state = initialState, action: any) => {
  switch (action.type) {

    case incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_LOADING:
    case incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_LOADING:  
      return {
        ...state,
        loading: true,
        paginatorCount: 0
      };

    case incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_SUCCESS:    
    console.log(action.payload);
     
      return {
        ...state,
        loading: false,
        incomingDispatchList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount : action.payload.data.items?.length
      };

    case incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_SUCCESS:
      
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        incomingDispatch: action.payload
      };
    case incomingDispatchTypes.GET_RECEIVED_BY_ID_SUCCESS:
      
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        ReceivedById: action.payload.data
      };

    case incomingDispatchTypes.CLEAR_INCOMING_DISPATCH:
      
      return {
        error: "",
        loading: false,
        message: "",
        paginatorCount: 0,
        incomingDispatchList: {
          values: [],
          totalRecords: 0
        },
        incomingDispatch: null,
        ReceivedById:""
      };

      case incomingDispatchTypes.RESET_INCOMING_DISPATCH_MESSAGE:
        return {
          ...state,
          error: "",
          loading: false,
          message: "",
          ReceivedById:""
        };

    
    case incomingDispatchTypes.GET_INCOMING_DISPATCH_BY_ID_FAIL:  
    case incomingDispatchTypes.GET_INCOMING_DISPATCH_LIST_FAIL:
    return {
      ...state,
      message:"",
      loading: false,
      error: action.payload
    };


    default:
      return { ...state };
  }
};

export default IncomingDispatch;
