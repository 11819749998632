import { jobsheetTatReportsReducerState } from 'src/models/pages/jobsheetTatReports';
import { jobsheetTatReportsActions } from './actionTypes';



const initialState: jobsheetTatReportsReducerState = {
  loading: false,
  error: "",
  message: "",
  jobsheetTatReportList: {
    items: [],
    totalCount: 0
  }
}


const jobsheetTatReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: ""
      }

    case jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_SUCCESS:

      return {
        ...state,
        loading : false,
        jobsheetTatReportList : {
           ...state.jobsheetTatReportList,
           items : action.payload.data.items,
           totalCount : action.payload.data.totalCount
        }
      }  

    case jobsheetTatReportsActions.GET_JOBSHEETS_TAT_REPORTS_ERROR :
      return {
        ...state,
        loading : false,
        error : action.payload
      }  

    default:
      return { ...state }
  }
}

export default jobsheetTatReportReducer ;