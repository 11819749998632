import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { subUser } from 'src/models/pages/subUserModel';
import { API } from 'src/util/api';
import { SubUserType } from './actionTpes';
import { createSubuserError, createSubuserSucess, deleteFranchiseSubuserProfileError, deleteFranchiseSubuserProfileSuccess, deleteSubuserError, deleteSubuserSuccess, franchiseSubuserLatLagError, franchiseSubuserLatLagSuccess, getFranchiseSubuserProfileError, getFranchiseSubuserProfileSuccess, getSubuserByIdError, getSubuserByIdSuccess, getSubuserListError, getSubuserListSuccess, resendOtpFranchiseSubuserError, resendOtpFranchiseSubuserSuccess, resetSubuser, sendFranchiseSubuserOtpError, sendFranchiseSubuserOtpSuccess, setFranchiseSubuserEmailIdError, setFranchiseSubuserEmailIdSuccess, setFranchiseSubuserMobileNumberError, setFranchiseSubuserMobileNumberSuccess, updateSubuserError, updateSubuserSuccess, uploadFranchiseSubuserProfileError, uploadFranchiseSubuserProfileSuccess } from './action'


//worker function

//getFranchiseeList

function* getSubuserListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getFranchiseSubUserListAPI, { ...action.lazyState })
    yield put(getSubuserListSuccess(response))
  }
  catch (error) {
    yield put(getSubuserListError(error))
  }
}


//create Franchisee
function* createSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.createFranchiseSubUserApi, action.payload);
    yield put(createSubuserSucess(response))
  }
  catch (error) {
    yield put(createSubuserError(error))
  }
}

//get franchisee details by id

function* getInduvidualSubuser(action: any) {
  try {
    const response: Promise<any> = yield call(API.getIndividualFranchiseSubUserApi, action.payload)
    yield put(getSubuserByIdSuccess(response))
  } catch (error) {
    yield put(getSubuserByIdError(error))
  }
}

//update franchisee 

function* updateSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.updateFranchiseSubUserApi, action.payload, action.id);
    yield put(updateSubuserSuccess(response))
  } catch (error) {
    yield put(updateSubuserError(error))
  }
}

//delete franchisee

function* deleteSubuserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteFranchiseSubUserApi, { id: action.payload.id, undo:action.payload.undo });
    yield put(deleteSubuserSuccess(response,action.payload.undo));
  } catch (error) {
    yield put(deleteSubuserError(error))
  }
}

function* getFranchiseSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.fetchFranchiseSubuserImage, action.id);
    yield put(getFranchiseSubuserProfileSuccess(response));
  } catch (error) {
    yield put(getFranchiseSubuserProfileError(error))
  }
}

function* uploadFranchiseSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.uploadFranchiseSubuserImage, action.payload,action.id);
    yield put(uploadFranchiseSubuserProfileSuccess(response));
  } catch (error) {
    yield put(uploadFranchiseSubuserProfileError(error))
  }
}

function* deleteFranchiseSubuserProfileAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteFranchiseSubuserImage, action.id);
    yield put(deleteFranchiseSubuserProfileSuccess(response));
  } catch (error) {
    yield put(deleteFranchiseSubuserProfileError(error))
  }
}

function* setFranchiseSubuserMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setFranchiseSubuserMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setFranchiseSubuserMobileNumberError(error))
  }
}

function* setFranchiseSubuserEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setFranchiseSubuserEmailApi, action.payload.id,action.payload.email);
    yield put(setFranchiseSubuserEmailIdSuccess(response))
  } catch (error) {
    yield put(setFranchiseSubuserEmailIdError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendFranchiseSubuserOtpSuccess(response))
  } catch (error) {
    yield put(sendFranchiseSubuserOtpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpFranchiseSubuserSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpFranchiseSubuserError(error))
  }
}

function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(franchiseSubuserLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(franchiseSubuserLatLagError(error));
  }
}
//watcher function

function* subuserSaga() {
  yield takeLatest(SubUserType.GET_FRANCHISESUBUSER_LIST_LOADING, getSubuserListAsync);
  yield takeLatest(SubUserType.CREATE_FRANCHISESUBUSER_LOADING, createSubuserAsync);
  yield takeEvery(SubUserType.GET_FRANCHISESUBUSERBYID_LOADING, getInduvidualSubuser);
  yield takeLatest(SubUserType.UPDATE_FRANCHISESUBUSER_LOADING, updateSubuserAsync);
  yield takeLatest(SubUserType.DELETE_FRANCHISESUBUSER_LOADING, deleteSubuserAsync)
  yield takeLatest(SubUserType.UPLOAD_FRANCHISESUBUSER_PROFILE_LOADING, uploadFranchiseSubuserProfileAsync)
  yield takeLatest(SubUserType.DELETE_FRANCHISESUBUSER_PROFILE_LOADING, deleteFranchiseSubuserProfileAsync)
  yield takeLatest(SubUserType.GET_FRANCHISESUBUSER_PROFILE_LOADING, getFranchiseSubuserProfileAsync)
  yield takeLatest(SubUserType.FRANCHISESUBUSER_SET_EMAIL_LOADING, setFranchiseSubuserEmailAsync)
  yield takeLatest(SubUserType.FRANCHISESUBUSER_SET_PHONE_LOADING, setFranchiseSubuserMobileAsync)
  yield takeLatest(SubUserType.SEND_OTP_FRANCHISESUBUSER, sendOtpAsync)
  yield takeLatest(SubUserType.RESEND_FRANCHISESUBUSER_OTP, resendOtpAsync)
  yield takeLatest(SubUserType.GET_FRANCHISESUBUSER_LOCATION_LOADING, getlatLngAsync)
}

export default subuserSaga