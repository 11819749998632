import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';
import "./styles.scss";
import { useDispatch, useSelector } from 'react-redux';
import { customerTaxTypeList, getCustomerByIdLoading, getCustomerImageLoading, getInvoicesListLoading, getReceivedPaymentListLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import { formatDateMEL } from 'src/helpers/common_helpers';
import { taxDetails } from 'src/models/pages/customerModel';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import StatusHighlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import { Image } from 'primereact/image';
import { DataTableFilterMeta } from 'primereact/datatable';

const receivedPaymentConverson = (values: any[]) => {
  const modifeidValue = values?.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['paymentDate'] = eachValue?.paymentDate;
    data['amount'] = eachValue?.amount;
    data['tdsdeduction'] = eachValue?.tdsdeduction ? eachValue?.tdsdeduction : 0;
    data['total'] = eachValue?.total;
    data['paymentNotes'] = eachValue?.paymentNotes;
    return data;
  });
  return modifeidValue;
};

const ViewCustomer: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenSize, setScreenSize] = useState("");
  const [personalDetails, setPersonalDetails] = useState<any>([]);
  const { customerDetails } = useSelector(
    (state: RootReducerState) => state.customerReducer
  );
  const { items,total } = useSelector((state: RootReducerState) => state.receivedPaymentReducer.receivedPaymentList);
  const totalRecords = total;
  const modifiedValues = receivedPaymentConverson(items);
  const loading = useSelector((state: any) => state.customerReducer.loading);
  const { error, message, customerTaxList, customerImage } = useSelector(
    (state: RootReducerState) => state.customerReducer
  );
  const invoiceListpage: any = useSelector((state: RootReducerState) => state.invoiceReducer.invoiceList.items);
  const invoiceTotalCount: any = useSelector((state: RootReducerState) => state.invoiceReducer.invoiceList.totalCount);
  const invoiceLoading = useSelector((state: RootReducerState) => state.invoiceReducer.loading);

  const invoicefilterDetails: DataTableFilterMeta = {
    CustomerId: { value: id ? id : "", matchMode: "contains" },
    paymentDate: { value: "", matchMode: "contains" },
    amount: { value: "", matchMode: "contains" },
    tdsdeduction: { value: "", matchMode: "contains" },
    total: { value: "", matchMode: "contains" },
    paymentNotes: { value: "", matchMode: "contains" },
  };

  const receivedfilterDetails: DataTableFilterMeta = {
    CustomerId: { value: id ? id : "", matchMode: "contains" },
    paymentDate: { value: "", matchMode: "contains" },
    amount: { value: "", matchMode: "contains" },
    tdsdeduction: { value: "", matchMode: "contains" },
    total: { value: "", matchMode: "contains" },
    paymentNotes: { value: "", matchMode: "contains" },
  };

  let invoiceLazyState: LazyTableState = {
    first: 0,
    rows: 5,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: invoicefilterDetails,
  };

  let receivedPaymentLazyState: LazyTableState = {
    first: 0,
    rows: 5,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: receivedfilterDetails,
  };

  const [invoiceState, setinvoiceState] = useState(invoiceLazyState);
  const [receivedPaymentState, setReceivedPaymentState] = useState(receivedPaymentLazyState);


  const handleEditCustomer = () => {
    navigate(`${ROUTER.CUSTOMERS_EDIT}/${id}`);
  };
 




  useEffect(() => {
    if (id) {
      dispatch(getCustomerByIdLoading(id));
      dispatch(getCustomerImageLoading(id));
      dispatch(getReceivedPaymentListLoading());
      dispatch(customerTaxTypeList());
    }
  }, []);

  const invoiceCallback = (invoicelazyState: LazyTableState) => {
    dispatch(getInvoicesListLoading(invoicelazyState));
  };

  useEffect(() => {
    invoiceCallback(invoiceState);
  }, [invoiceState]);

  const receivedPaymentCallback = (receivedlazyState: LazyTableState) => {
    dispatch(getReceivedPaymentListLoading(receivedlazyState));
  };


  useEffect(() => {
    receivedPaymentCallback(receivedPaymentState);
  }, [receivedPaymentState]);


  

  const checkScreenSize = () => {
    const width = window.innerWidth;
    if (width >= 992) {
      setScreenSize('lg');
    } else if (width >= 768 && width < 992) {
      setScreenSize('md');
    } else {
      setScreenSize('sm');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const getMarginTopClassName = (screenSize: any) => {
    switch (screenSize) {
      case "lg":
        return "mt-1";
      case "md":
        return "mt-4";
      default:
        return "mt-4";
    }
  };
  const getMarginTopanotherClassName = (screenSize: any) => {
    switch (screenSize) {
      case "lg":
        return "mt-3";
      case "md":
        return "mt-4";
      default:
        return "mt-4";
    }
  };


  const statusBodyTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );
  };

  const statusBodyTemplatess = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || invoiceLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, invoiceLoading]);


  const Invoicecolumns: Columns[] = [
    {
      field: "number",
      sortable: false,
      header: "Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "paidState",
      sortable: false,
      header: "Paid State",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => statusBodyTemplatess(rowData, 'paidState'),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: true,
    },

  ];

  const receivedPaymentcolumns: Columns[] = [
    {
      field: "paymentDate",
      sortable: false,
      header: "Payment Date",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => formatDateMEL(rowData.paymentDate),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: true,
    },
    {
      field: "tdsdeduction",
      sortable: false,
      header: "Tds Deduction",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: true,
    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      className: "align-right pr-2",
      isDefault: true,
      isStatic: true,
    },
    {
      field: "paymentNotes",
      sortable: false,
      header: "Payment Notes",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
  ];

  const invoiceOnFilter = (event: any) => {
    event["first"] = 0;
    setinvoiceState(event);
  };

  const invoiceOnPage = (event: any) => {
    setinvoiceState(event);
  };

  const invoiceOnSort = (event: any) => {
    setinvoiceState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setReceivedPaymentState(event);
  };

  const onPage = (event: any) => {
    setReceivedPaymentState(event);
  };

  const onSort = (event: any) => {
    setReceivedPaymentState(event);
  };

  return (
    <React.Fragment>
      <div className='cusomerPage' id='cusomerPage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Customers"
            titlePath={ROUTER.CUSTOMERS_GRID}
            breadcrumbItem='Customer Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Customer'
            onFirstButtonClick={handleEditCustomer}
            isShowSecondButton={false}
            

          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
                <Row>
                  <Col lg={6}>
                    <div className="parentCls2">
                      <div className={` pt-1 childCls`}>
                        <div >Name</div>
                        <b>{customerDetails?.user?.name ? customerDetails?.user?.name : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div >Email</div>
                        <b>{customerDetails?.user?.email ? customerDetails?.user?.email : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div >Date of Birth</div>
                        <b>{customerDetails?.user?.extraProperties?.DateOfBirth ? formatDateMEL(customerDetails?.user?.extraProperties?.DateOfBirth) : <div className='none-text'>-</div>}</b>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {customerImage ?
                          // <img src={customerImage} className='view-image' />
                          <Image src={customerImage} alt="Image" className='view-image' height='150px' preview />
                          :
                          <div className='none-text'>-</div>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row>
            <div className="view">
              <div className="general-details">
                <b>More Details</b>
              </div>
              <div className="subview">

                <div className="parentCls">


                  <div className={` pt-1 childCls`}>
                    <div>Gst Number</div>
                    <div>{customerDetails?.user?.extraProperties?.GstNumber ? customerDetails?.user?.extraProperties?.GstNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Tax Type</div>
                    <div>{customerDetails?.taxTypeId ? customerTaxList.find((item: taxDetails) => item.id === customerDetails?.taxTypeId)?.taxName : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Mobile</div>
                    <div>{customerDetails?.user?.phoneNumber ? customerDetails?.user?.phoneNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Alternative Number</div>
                    <div>{customerDetails?.alternateNumber ? customerDetails?.alternateNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Address</div>
                    <div>{customerDetails?.user?.extraProperties?.Address ? customerDetails?.user?.extraProperties?.Address : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Wedding Date</div>
                    <div>{customerDetails?.user?.extraProperties?.WeddingDate ? formatDateMEL(customerDetails?.user?.extraProperties?.WeddingDate) : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Is Active</div>
                    <div>{statusBodyTemplate(customerDetails?.user?.isActive)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Sms</div>
                    <div>{statusBodyTemplate(customerDetails?.user?.extraProperties?.SendSms)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Email</div>
                    <div>{statusBodyTemplate(customerDetails?.user?.extraProperties?.SendEmail)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Whatsapp</div>
                    <div>{statusBodyTemplate(customerDetails?.user?.extraProperties?.SendWhatsapp)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Mobile Number Verified</div>
                    <div>{statusBodyTemplate(customerDetails?.user?.phoneNumberConfirmed)}</div>
                  </div>

                </div>
              </div>
            </div>
          </Row>

          {invoiceListpage?.length > 0 &&
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Invoice</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={Invoicecolumns}
                        value={invoiceListpage}
                        paginator={true}
                        stripedRows={true}
                        lazy
                        rowHover={true}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        totalRecords={invoiceTotalCount}
                        emptyMessage="No Received Payment found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Displaying Invoice {last} records of {totalRecords} in total"
                        first={invoiceState.first}
                        onSort={invoiceOnSort}
                        sortField={invoiceState.sortField}
                        sortOrder={invoiceState.sortOrder}
                        filters={invoiceState.filters}
                        onFilter={invoiceOnFilter}
                        onPage={invoiceOnPage}
                        rows={invoiceState.rows}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          }

          {items?.length > 0 &&
            <Row>
              <div className="view">
                <div className="general-details">
                  <b>Received Payment</b>
                </div>
                <div className="subview">
                  <Row>
                    <div className="card ps-0 pe-0">
                      <DataTableWrapper
                        columnDetails={receivedPaymentcolumns}
                        value={modifiedValues}
                        paginator={true}
                        stripedRows={true}
                        lazy
                        rowHover={true}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        totalRecords={totalRecords}
                        emptyMessage="No Received Payment found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Displaying Received Payment {last} records of {totalRecords} in total"
                        first={receivedPaymentState.first}
                        onSort={onSort}
                        sortField={receivedPaymentState.sortField}
                        sortOrder={receivedPaymentState.sortOrder}
                        filters={receivedPaymentState.filters}
                        onFilter={onFilter}
                        onPage={onPage}
                        rows={receivedPaymentState.rows}
                      >
                      </DataTableWrapper>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          }


        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomer;