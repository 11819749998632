import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { getAttendanceReportListError, getAttendanceReportListSuccess, getDealerDropdownListError, getDealerDropdownListSuccess, getDealerSubuserDropdownListError, getDealerSubuserDropdownListSuccess, getEmployeeDropdownListError, getEmployeeDropdownListSuccess, getFranchiseDropdownListError, getFranchiseDropdownListSuccess, getFranchiseSubuserDropdownListError, getFranchiseSubuserDropdownListSuccess } from "./action";
import { AttendanceReportTypes } from "./actionTypes";

function* getAttendanceReportListAsync(action: any) {
    
    try {
        const response: Promise<any> = yield call( API.getAttendanceReportListAPI, { ...action.lazyState, } )
        
        yield put(getAttendanceReportListSuccess(response));
    } catch (error) {
        yield put(getAttendanceReportListError(error));
    }
}

function* getEmployeeDropdownListAsync() {
    
    try {
        const response: Promise<any> = yield call( API.getEmployeeDrpFromIdentity )        
        yield put(getEmployeeDropdownListSuccess(response));
    } catch (error) {
        yield put(getEmployeeDropdownListError(error));
    }
}

function* getFranchiseDropdownListAsync() {
    
    try {
        const response: Promise<any> = yield call( API.getFranchiseDrpFromIdentity )        
        yield put(getFranchiseDropdownListSuccess(response));
    } catch (error) {
        yield put(getFranchiseDropdownListError(error));
    }
}

function* getFranchiseSubuserDropdownListAsync() {
    
    try {
        const response: Promise<any> = yield call( API.getFranchiseSubuserDrpFromIdentity )
        yield put(getFranchiseSubuserDropdownListSuccess(response));
    } catch (error) {
        yield put(getFranchiseSubuserDropdownListError(error));
    }
}

function* getDealerDropdownListAsync() {
    try {
        const response: Promise<any> = yield call(API.getDealerDropdownApi)
        yield put(getDealerDropdownListSuccess(response));
    } catch (error) {
        yield put(getDealerDropdownListError(error));
    }
}

function* getDealerSubuserDropdownListAsync() {
    try {
        const response: Promise<any> = yield call(API.getDealerSubuserDropdownApi)
        yield put(getDealerSubuserDropdownListSuccess(response));
    } catch (error) {
        yield put(getDealerSubuserDropdownListError(error));
    }
}
  
function* attendanceReportSaga() {
  yield takeLatest( AttendanceReportTypes.GET_ATTENDANCE_REPORT_LIST, getAttendanceReportListAsync);
  yield takeLatest( AttendanceReportTypes.GET_EMPLOYEE_DROPDOWN_LIST, getEmployeeDropdownListAsync);
  yield takeLatest( AttendanceReportTypes.GET_FRANCHISE_DROPDOWN_LIST, getFranchiseDropdownListAsync);
  yield takeLatest( AttendanceReportTypes.GET_FRANCHISE_SUBUSER_DROPDOWN_LIST, getFranchiseSubuserDropdownListAsync);
  yield takeLatest( AttendanceReportTypes.GET_DEALER_DROPDOWN_LIST, getDealerDropdownListAsync);
  yield takeLatest( AttendanceReportTypes.GET_DEALER_SUBUSER_DROPDOWN_LIST, getDealerSubuserDropdownListAsync);
}
  
  export default attendanceReportSaga;