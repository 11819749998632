import React, { useEffect, useRef, useState } from "react";
import BreadcrumbWithFilter from "src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import ExportSearchClear from "src/components/Common/ExportSearchClear/ExportSearchClear";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import { saveAsExcelFile, saveDataAsExcel, saveDataAsPdf } from "src/helpers/common_helpers";
import { stocklocation } from "src/models/pages/stockLocationModel";
import { clearStockLocationMessage, deleteStockLocationFromList, getStockLocationList, resetStockLocationMessage } from "src/store/actions";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { Dialog } from "primereact/dialog";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import './stockLocation.scss'
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";

/* const stockLocationListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id;
    data['locationName'] = eachValue?.locationName;
    //data['isDeleted'] = eachValue?.isDeleted;
    return data;
  });
  return modifeidValue;
}
 */
export default function StockLocationList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.stockLocationReducer.stocklocationList);
  /* const modifiedValues: stocklocation[] = stockLocationListConverson(values); */
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.stockLocationReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { error, message, paginatorCount } = useSelector((state: any) => state.stockLocationReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice

  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<stocklocation | null>(null);

  const filterDetails: DataTableFilterMeta = {
    locationName: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "locationName",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  }

  const onCreateClickHandler = () => {
    navigate(ROUTER.STOCK_LOCATION_CREATE);
  };

  const viewHandler = (rowData: stocklocation) => {
    navigate(`${ROUTER.STOCK_LOCATION_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: stocklocation) => {
    navigate(`${ROUTER.STOCK_LOCATION_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: stocklocation) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const toCamelCase = (str: any) => {
    return str.replace(/\b\w/g, (char: any) => char.toUpperCase());
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
     /*  <>
        {
          rowData.isDeleted == false ? (
            <ColumnAction
              displayData={toCamelCase(rowData[field].toLowerCase())}
              isEdit={true}
              isDelete={true}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
            />) : ( */
            <ColumnAction
              displayData={toCamelCase(rowData[field].toLowerCase())}
            />
      /*     )
        }
      </> */
    );
  };
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, {type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockLocationMessage());
    }
  }, [error, message]);

	const hidePopup = () => {
		setVisible(false);
	}

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const columns: Columns[] = [
/*     {
      field: "select",
      sortable: false,
      header: " ",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      isDefault: true,
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isStatic: false,
    }, */
    {
      field: "locationName",
      sortable: true,
      header: "Location",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    }
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { id, locationName } = eachDealer;
      let data: any = {};
      //data['id'] = id,
      data['Location'] = locationName;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      const { id, locationName } = eachValue;
      let data: any = {};
      //data['id'] = eachValue.id;
      data['locationName'] = locationName;
      return data
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getStockLocationListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      // let resp=await dealerListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "Stock_Location");
     }else{
      saveDataAsExcel(response?.data?.items, "Stock_Location");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(values, "Stock_Location");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "Stock_Location");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getStockLocationList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedParts(value);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedParts(values);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedParts([]);
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedParts([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  const handleDeleteStockLocation = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      lazyState,
    };
    dispatch(deleteStockLocationFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      setTimeout(() => {
        dispatch(getStockLocationList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  }

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (values.length > 0) {
      setSelectAll(true);
      setSelectedParts(values);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Stock Location List
      </div>
 {/*      <div className="table-header-container-item table-header-container-item-special">
        {selectedParts.length == 0 && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedParts.length > 0 &&
            <>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1" onClick={() => { setDeleteModal(true); }}>
                  <FeatherIcon icon="trash-2" className="mr-1" />
                  Delete
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> Cancel selection</Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedParts.length > 0 && <BulkActionStatus count={selectedParts.length} title="Stock Locations"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div> */}
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem mr-1" />
        </TooltipMEL>

   {/*      <Button type="button" className="customize-table-button mr-1 ml-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="mr-1" />
        </Button> */}
      {/*   <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Stock Location
        </Button> */}
       {/*  <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip> */}
      </div>

    </div>;

  return (
    <>
      <div className="page-content" id="stock-location">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteStockLocation(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedParts.length == 1) {
              handleDeleteStockLocation(rowData || selectedParts);
            } else if (selectedParts.length > 1) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteStockLocation(selectedParts[i], selectedParts.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
      {/*   {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />} */}

      {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )} 

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={values}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No stock location found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Stock Location  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
