import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { StockValuebyPartTypes } from "./actionTypes";
import { API } from "src/util/api";
import { getPartsDropdownListError, getPartsDropdownListSuccess, getStockValuebyPartListError, getStockValuebyPartListSuccess } from "./action";
import { parts } from "src/models/pages/partsModel";



function* getStockValuebyPartListAsync(action: any) {

  try {
    const response: Promise<any> = yield call(API.getStockValuebyPartListAPI,  {...action.lazyState,})
    yield put(getStockValuebyPartListSuccess(response));
  } catch (error) {
    yield put(getStockValuebyPartListError(error));
  }
}

function* getPartListDropdownAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPartsDropdownApi)
    console.log("action.payload.data",response);    
    yield put(getPartsDropdownListSuccess(response));
  } catch (error) {
    yield put(getPartsDropdownListError(error));
  }
}



function* stockValuebyPartSaga() {
  yield takeLatest(StockValuebyPartTypes.GET_STOCK_VALUE_BY_PART_LIST, getStockValuebyPartListAsync);
  yield takeLatest(StockValuebyPartTypes.GET_STOCK_PART_LIST_LOADING, getPartListDropdownAsync);
}

export default stockValuebyPartSaga;