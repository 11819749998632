import { messages } from 'src/common/data';
import { IntentInitialState } from "src/models/pages/intentModel";
import { IntentActionTypes } from "./actionTypes";

const INITIAL_STATE: IntentInitialState = {
  error: "",
  message: "",
  intentError: "",
  intentMessage: "",
  loading: false,
  franchiseList: [],
  intentList: {
    values: [],
    totalRecords: 0,
  },
  intentDetail: null,
  partList: [],
  paginatorCount: 0,
  courierDropdown: [],
  deliverablePerson: [],
  partSkuValue:"",
  mailmessage:""
};


const IntentReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case IntentActionTypes.GET_INTENT_LIST_LOADING:
    case IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_LOADING:
    case IntentActionTypes.DELETE_INTENT_LIST_LOADING:
    case IntentActionTypes.UPDATE_INTENT_LOADING:
    case IntentActionTypes.CREATE_INTENT_LOADING:
    case IntentActionTypes.GET_INTENT_BY_ID_LOADING:
    case IntentActionTypes.GET_PART_LIST_FOR_INTENT_LOADING:
    case IntentActionTypes.APPROVE_INTENT_LOADING:
    case IntentActionTypes.REJECT_INTENT_LOADING:
    case IntentActionTypes.DISPATCH_INTENT_LOADING:
    case IntentActionTypes.RECEIVE_INTENT_LOADING:
    case IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_LOADING:
    case IntentActionTypes.GET_INTENT_COURIER_LIST_LOADING:
    case IntentActionTypes.POST_COURIER_INTENT_LOADING:
    case IntentActionTypes.PARTS_VALIDATE:
    case IntentActionTypes.PRINT_INTEND:
    case IntentActionTypes.EMAIL_INTEND:

      return {
        ...state,
        // error: "",
        // message: "",
        loading: true,
      };

    case IntentActionTypes.GET_INTENT_BY_ID_SUCCESS:
      return { ...state, intentDetail: payload };

    case IntentActionTypes.CREATE_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Intent created successfully",
      };

    case IntentActionTypes.UPDATE_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Intent updated successfully",
      };

    case IntentActionTypes.GET_INTENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        intentList: {
          ...state.intentList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items.length
      };

    case IntentActionTypes.DELETE_INTENT_LIST_SUCCESS:

      const url = new URL(action.payload.raw.url);
      const undoParam = url.searchParams.get('undo');
      let msg = " ";
      if (undoParam !== null) {
        const undoValue = undoParam === 'true';
        msg = undoValue ? "Intent restored successfully" : "Intent deleted successfully";
      } else {
        msg = "Intent deleted successfully"
      }
      return {
        ...state,
        loading: false,
        message: msg,
        error: ""
      };

    case IntentActionTypes.GET_INTENT_COURIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        courierDropdown: action.payload.data?.filter((eachCourier: any) => !eachCourier.isDeleted).filter((eachCourier: any) => eachCourier.companyName != "")
      }

    case IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        deliverablePerson : action.payload.data
      }

    case IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        mailmessage:"",
        franchiseList: action.payload.data,
      };

    case IntentActionTypes.GET_PART_LIST_FOR_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        partList: action.payload.data,
      };


    case IntentActionTypes.APPROVE_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Intent approved successfully",
        error: ""
      };

    case IntentActionTypes.REJECT_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Intent rejected successfully",
        error: ""
      };

    case IntentActionTypes.DISPATCH_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        // intentMessage: "Intent dispatched successfully",
        intentMessage: "Intent transitions to a pending state successfully.",
        error: "",
        message: "",
        mailmessage:"",
        intentError: ""
      };
    
    case IntentActionTypes.POST_COURIER_INTENT_SUCCESS:
      return {
        ...state,
        loading : false,
        error : "",
        message : "Intent couriered successfully"
      }  

    case IntentActionTypes.RECEIVE_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        intentMessage: "Intent received successfully",
        error: "",
        message: "",
        mailmessage:"",
        intentError: ""

      };

    case IntentActionTypes.PRINT_INTEND_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case IntentActionTypes.EMAIL_INTEND_SUCCESS:
      return {
        ...state,
        loading: false,
        mailmessage:"Intend has been successfully emailed"
      };



    case IntentActionTypes.GET_INTENT_LIST_FAIL:
    case IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_ERROR:
    case IntentActionTypes.DELETE_INTENT_LIST_ERROR:
    case IntentActionTypes.UPDATE_INTENT_ERROR:
    case IntentActionTypes.CREATE_INTENT_ERROR:
    case IntentActionTypes.GET_INTENT_BY_ID_ERROR:
    case IntentActionTypes.GET_PART_LIST_FOR_INTENT_ERROR:
    case IntentActionTypes.APPROVE_INTENT_ERROR:
    case IntentActionTypes.REJECT_INTENT_ERROR:
    case IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_ERROR:
    case IntentActionTypes.GET_INTENT_COURIER_LIST_ERROR:
    case IntentActionTypes.POST_COURIER_INTENT_ERROR:
    case IntentActionTypes.PARTS_VALIDATE_ERROR:
    case IntentActionTypes.PRINT_INTEND_ERROR:
    case IntentActionTypes.EMAIL_INTEND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case IntentActionTypes.DISPATCH_INTENT_ERROR:
    case IntentActionTypes.RECEIVE_INTENT_ERROR:
      return {
        ...state,
        loading: false,
        intentError: action.payload,
        intentMessage: "",
        message: "",
        error: "",
        mailmessage:""
      };

    // clear dealer
    case IntentActionTypes.RESET_INTENT_VALUE:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case IntentActionTypes.CLEAR_INTENT_MESSAGE:
      return {
        ...state,
        message: "",
        error: "",
        intentError: "",
        intentMessage: "",
        loading: false,
        mailmessage:""
      };
    case IntentActionTypes.PARTS_VALIDATE_SUCCESS:
      return {
        ...state,
       partSkuValue:action.payload.data,
        loading: false,
      };

    default:
      return { ...state };
  }
};

export default IntentReducer;
