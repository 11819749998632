import { outGoingDispatchInitialState } from 'src/models/pages/outGoingDispatchModel';
import { OutGoing_dispatch_Action } from './actionTypes';


const initialState: outGoingDispatchInitialState = {
  loading: false,
  error: "",
  paginatorCount: 0,
  outgoingDispatchList: {
    totalRecords: 0,
    items: []
  },
  outGoingDispatchDetails : {},
  message : "",
  DeliveriedById:""
}


const outGoingDispatch = (state = initialState, action: any) => {
  switch (action.type) {

    case OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_LOADING:
    case OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_LOADING : 
    case OutGoing_dispatch_Action.SENT_MAIL_TO_OUTGOING_DISPATCH : 
    case OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH : 

      return {
        ...state,
        loading: true,
        error: "",
        paginatorCount: 0
      }
    case OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_SUCCESS:
      
      return {
        loading: false,
        outgoingDispatchList: {
          ...state.outgoingDispatchList,
          items: action.payload.data.items,
          totalRecords: action.payload.data.totalCount
        },
        paginatorCount: action.payload.data.length
      }
    case OutGoing_dispatch_Action.GET_DELIVERIED_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        DeliveriedById:action.payload.data
      }

    case OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_SUCCESS :
      return {
        ...state,
        loading : false,
        outGoingDispatchDetails : action.payload.data
      }  

    case OutGoing_dispatch_Action.RESET_OUTGOING_DISPATCH_MESSAGE :
      return {
        ...state,
        loading : false,
        message: "",
        error:"",
        DeliveriedById:""
      }  
    case OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH_SUCCESSS :
      return {
        ...state,
        loading : false,
        message: "",
        error:"" 
      }  

    case OutGoing_dispatch_Action.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS :
      return {
        ...state,
        loading : false,
        message: "Successfully emailed the Outgoing Dispatch",
        error:"" 
      }  

    case OutGoing_dispatch_Action.CLEAR_OUTGOING_DISPATCH_STATE :
      return {
        loading: false,
        error: "",
        paginatorCount: 0,
        outgoingDispatchList: {
          totalRecords: 0,
          items: []
        },
        outGoingDispatchDetails : {},
        message : "",
        DeliveriedById:""
      }  



    case OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_ERROR:
    case OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_ERROR :  
    case OutGoing_dispatch_Action.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL :  
    case OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH_FAIL :  

      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return {
        ...state
      }
  }
}

export default outGoingDispatch