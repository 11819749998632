import { call, put, takeLatest } from "redux-saga/effects";
import { StockQuantityByMusicSystemBrandTypes } from "./actionTypes";
import { API } from "src/util/api";
import {getAudioBrandError, getAudioBrandSuccess, getStockQuantityByMusicSystemBrandError,getStockQuantityByMusicSystemBrandSuccess} from "./action";



//worker function

function* getQuantityByMusicSystemBrandListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getStockQuantitybymusicSystemBrandAPI, {...action.lazyState})
    yield put(getStockQuantityByMusicSystemBrandSuccess(response));
  } catch (error) {
    yield put(getStockQuantityByMusicSystemBrandError(error));
  }
}

function* getAudioBrandAsync() {
  try {
    const response: Promise<any> = yield call(API.getAudioBrandFromMasterManagement)
    yield put(getAudioBrandSuccess(response));
  } catch (error) {
    yield put(getAudioBrandError(error));
  }
}

//watcher function

function* StockQuantityByMusicSystemBrandSaga() {
  yield takeLatest(StockQuantityByMusicSystemBrandTypes.GET_STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING, getQuantityByMusicSystemBrandListAsync);
  yield takeLatest(StockQuantityByMusicSystemBrandTypes.GET_AUDIO_BRAND_LOADING, getAudioBrandAsync);
}


export default StockQuantityByMusicSystemBrandSaga