import React, { useEffect, useMemo, useRef, useState } from "react";
import { Row, } from "reactstrap";
import {
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import { DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "src/components/Common/ReactToaster";
import { RootReducerState } from "src/store/reducers";
import { convertTableToCSV, convertToMonthYear, saveAsExcelFile, saveDataAsExcel } from "src/helpers/common_helpers";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { getPartsDropdownList, getStockValuebyPartsList, resetStockValuebyPartMessage } from "src/store/actions";
import moment from 'moment';
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { OverlayPanel } from "primereact/overlaypanel";
import FeatherIcon from "feather-icons-react";
import { Dropdown } from "primereact/dropdown";
import StockValuePartChild from "./StockValuePartChild";

let availableTypes = ["missing", "opening", "rejected", "scrapped", "unused", "used", "closing"];

function formatMonthYear(monthYear: any) {
  const [month, year] = monthYear.split('-');
  return `${year}${month.padStart(2, '0')}`;
}

const stockValuebyPartListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['partNumber'] = eachValue?.partNumber;
    data['partDesc'] = eachValue?.partDescription;
    data['audioBrand'] = eachValue?.audioBrand;
    eachValue?.monthYears?.forEach((res: any) => {
      const month = formatMonthYear(res.monthYear);
      data[`closing${month}`] = res?.stockDeatails?.closing;
      data[`missing${month}`] = res?.stockDeatails?.missing;
      data[`opening${month}`] = res?.stockDeatails?.opening;
      data[`rejected${month}`] = res?.stockDeatails?.rejected;
      data[`scrapped${month}`] = res?.stockDeatails?.scrapped;
      data[`unused${month}`] = res?.stockDeatails?.unused;
      data[`used${month}`] = res?.stockDeatails?.used
        ;
    });
    return data;
  });
  return modifeidValue;
};

const formattedResponse = (values: any) => {
  return values.map((partss: any) => {
    return {
      ...partss,
      monthYears: partss?.monthYears?.map((data: any) => ({
        ...data,
        monthYear: formatMonthYear(data?.monthYear)
      }))
    };
  });
};

export default function StockValuebyPartComponent() {
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.stockValuebyPartReducer.stockValuebyPartList);
  // const modifiedValues: any[] = stockValuebyPartListConverson(values);    
  const modifiedValues = useMemo(() => { return stockValuebyPartListConverson(values); }, [values]);
  const { showToast } = useToast();
  const loading = useSelector((state: RootReducerState) => state.stockValuebyPartReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const { error, message, partDropdownList } = useSelector((state: RootReducerState) => state.stockValuebyPartReducer);
  const dispatch = useDispatch();
  const dt = useRef<any>(null);
  const printRef = useRef<any>();
  const formattedRes = useMemo(() => { return formattedResponse(values); }, [values]);
  // const monthsmodi = formattedRes?.map((data:any) => data?.monthYears?.map((newdata:any) => newdata?.monthYear));  
  const monthsmodi = useMemo(() => {
    return formattedRes?.map((data: any) => data?.monthYears?.map((newdata: any) => newdata?.monthYear));
  }, [formattedRes]);

  let monthsArr: any = useMemo(() => { return Array.from(new Set(monthsmodi?.flatMap((item: any) => item?.map((data: any) => data)))); }, [monthsmodi]);
  const parseYearMonth = (str: any) => {
    const year = parseInt(str.slice(0, 4));
    const month = parseInt(str.slice(4));
    return { year, month };
  };
  const sortingMonthDataOrderWise = (data: any) => {
    return data.sort((a: any, b: any) => {
      const aParsed = parseYearMonth(a);
      const bParsed = parseYearMonth(b);

      if (aParsed.year === bParsed.year) {
        return aParsed.month - bParsed.month;
      } else {
        return aParsed.year - bParsed.year;
      }
    });
  };

  const allMonths = useMemo(() => { return monthsArr.sort((a: any, b: any) => a - b); }, [monthsArr]);
  const months = useMemo(() => { return sortingMonthDataOrderWise(allMonths); }, [allMonths]);

  const filterDetails: DataTableFilterMeta = {
    StartDate: { value: "", matchMode: "contains" },
    endDate: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);


  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockValuebyPartMessage());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const columns = useMemo(() => [
    {
      field: "partNumber",
      sortable: false,
      header: "partNumber",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      frozen: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isStatic: true,
      classNames: "partNumber_Header"
    },
    {
      field: "partDesc",
      sortable: false,
      header: "partDesc",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "audioBrand",
      sortable: false,
      header: "audioBrand",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    ...months.flatMap((month: any) => {
      return availableTypes.map((da) => {
        return {
          field: `${da}${month}`,
          sortable: false,
          header: `${da}${month}`,
          filter: false,
          showFilterMenu: false,
          isDefault: true,
          headerStyle: { whiteSpace: "nowrap" },
          isStatic: false,
          className: "align-right"
        };
      });
    })
  ], [months]);

  const visibleColumns = useMemo(() => columns.filter((eachColumn) => {
    return eachColumn.isDefault;
  }), [columns]);

  const headerGroup = useMemo(() => {
    return <ColumnGroup>
      <Row >
        <Column header="Part Number" colSpan={1} />
        <Column header="Part Description" colSpan={1} />
        <Column header="Audio Brand" colSpan={1} />
        {months.map((month: any) => {
          const date = moment(month, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          return (
            <Column colSpan={7} key={month} header={formattedDate} />
          );
        })}

      </Row>
      <Row>
        <Column field="partNumber" />
        <Column field="partDesc" />
        <Column field="audioBrand" />
        {months.map((month: any) => {
          return availableTypes.map((da) => {
            return (
              <Column rowSpan={7} key={month + `${da}`} header={`${da?.split("")[0].toUpperCase()}${da?.slice(1)}`} field={`${da}${month}`} />
            );
          });
        })}

      </Row>
    </ColumnGroup>;
  }, [months]);





  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {

    const destructureedData = values.map((data) => {
      let datas = {
        partNumber: data.partNumber,
        partDesc: data.partDesc,
        audioBrand: data.audioBrand,
        unUsed: data.unUsed
      };
      return datas;
    });
  };

  const getColumnStyles = () => {
    const columnStyles: any = {};
    const month = months;
    month.forEach((month: any) => {
      columnStyles[`unUsed_${month}`] = { halign: 'right' };
    });
    return columnStyles;
  };


  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };


    try {
      setPdfLoading(true);
      const response = await API.getStockValuebyPartListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      if (isPdf) {

        saveDataAsPdf(response?.data, columns, "stockValuebyPart");
      } else {
        saveDataAsExcel(response?.data, "stockValuebyPart");
      }
      setPdfLoading(false);
    } catch (error) {

      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };

  const csvClickHandler = () => {
    const dataTable: any = dt.current;
    const table: HTMLTableElement = dataTable.getTable();
    const fileName = "stockValuebyPart";
    import('xlsx').then((xlsx) => {
      const worksheet = convertTableToCSV(table);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const pdfClickHandler = () => {
    fetchData(true);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getStockValuebyPartsList(lazyState));
  };



  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    dispatch(getPartsDropdownList());
  }, []);

  const partsOption = partDropdownList?.length > 0 ? partDropdownList?.map((item: any) => ({
    label: item.partNumber,
    value: item.partNumber
  })) : [];

  const calendarRef = useRef<any>(null);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [startShowDate, setStartShowDate] = useState<any>();
  const [endShowDate, setEndShowDate] = useState<any>();
  const [part, setPart] = useState<any>();
  const [showPart, setShowPart] = useState<any>();

  const searchCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event);
    setStartShowDate(startDate);
    setEndShowDate(endDate);
    setShowPart(part);

    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate: { value: startDate ? `${startDate}-01` : "", matchMode: "contains" },
        endDate: { value: endDate ? `${endDate}-01` : "", matchMode: "contains" },
        partNumber: { value: part, matchMode: "contains" },
      },
    }));

  };
  const clearCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event);
    setEndDate(null);
    setStartDate(null);
    setEndShowDate(null);
    setStartShowDate(null);
    setPart(null);
    setShowPart(null);
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate: { value: "", matchMode: "contains" },
        endDate: { value: "", matchMode: "contains" },
        partNumber: { value: "", matchMode: "contains" },
      },
    }));
  };
  const clearFilter = () => {
    setEndDate(null);
    setStartDate(null);
    setEndShowDate(null);
    setStartShowDate(null);
    setPart(null);
    setShowPart(null);
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate: { value: "", matchMode: "contains" },
        endDate: { value: "", matchMode: "contains" },
        partNumber: { value: "", matchMode: "contains" },
      },
    }));
  };


  const header = useMemo(() => (
    <div className="table-header-container">
      <div>{'Stock Value by Part'}</div>
      <div id="calendar" className="calendar_btns" style={{ marginLeft: "10px" }}>
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-soft-primary waves-effect waves-light"
              onClick={(e) => calendarRef.current.toggle(e)}
            >
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={calendarRef}>
        <div className="mb-2">
          <label htmlFor="">Select Start Month</label>
          <input
            className='form-control'
            type="month"
            name="month"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">Select End Month</label>
          <input
            className='form-control'
            type="month"
            name="month"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">Select Part</label>
          <Dropdown value={part} onChange={(e) => setPart(e.value)} options={partsOption} optionLabel="label" placeholder="Select Part" filter className="w-full md:w-14rem" />
        </div>
        <div className="p-column-filter-buttonbar" style={{ display: "flex", justifyContent: "space-between" }}>
          <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel>
      <div className="table-header-container-item align_width">
        <div className="m-2 d-flex flex-wrap gap-1 align_justify">
          {startShowDate &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#dff0ff", color: "#749be0", width: "auto", whiteSpace: "nowrap" }}>Start Month</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "120px", whiteSpace: "nowrap" }}>{convertToMonthYear(startShowDate)}</b>
            </div>
          }
          {endShowDate &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>End Month</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{convertToMonthYear(endShowDate)}</b>
            </div>
          }
          {showPart &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>Select Part</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{showPart}</b>
            </div>
          }
        </div>
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} isShowPdf={false} TooltipPosition="left" clearFilter={clearFilter} isFilter={true} />
      </div>
    </div>
  ), [partsOption, showPart, endShowDate, startShowDate]);



  return (
    <>
      <div className="page-content" id="stock-music-system">
        {visible && dialogContainer(error)}

        <div id="table-border" >
          {/* <DataTableWrapper
            ref={dt}
            columnDetails={visibleColumns}
            reorderableColumns={false}
            header={header}
            value={modifiedValues}
            headerColumnGroup={headerGroup}
            stripedRows={true}
            rowHover={true}
            sortIcon={(setOrder) => <SortIcon order={setOrder} />}
            loading={loading}
            emptyMessage="No parts found."
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Displaying Parts  {last} records of {totalRecords} in total"
          >
          </DataTableWrapper> */}


          {/* <div className="table-container" ref={dt}>
            <div className="p-datatable p-component p-datatable-hoverable-rows p-datatable-scrollable p-datatable-responsive-scroll p-datatable-striped p-datatable-grouped-header">
              <div className="p-datatable-header">
                {header}
              </div>
              <div className="p-datatable-wrapper" style={{ overflow: "auto", maxHeight: "500px" }}>
                <table border={1} className="p-datatable-table p-datatable-scrollable-table" style={{ minWidth: "100%", fontSize: "14px",position:"relative" }}>
                  <thead className="p-datatable-thead" style={{top:"0",position:"sticky"}} >
                    <tr>
                      <th rowSpan={2}>Part Number</th>
                      <th rowSpan={2}>Part Description</th>
                      <th rowSpan={2}>Audio Brand</th>
                      {monthsArr.map((month: any, index: number) => {
                        const date = moment(month, 'YYYYMM');
                        const formattedDate = date.format('MMM-YYYY');
                        return (
                          <React.Fragment key={index}>
                            <th colSpan={7}>{formattedDate}</th>
                          </React.Fragment>
                        );
                      }
                      )}
                    </tr>
                    <tr>
                      {monthsArr.map((month: any, index: number) => (
                        <React.Fragment key={index}>
                          <th>Missing</th>
                          <th>Opening</th>
                          <th>Rejected</th>
                          <th>Scrapped</th>
                          <th>Unused</th>
                          <th>Used</th>
                          <th>Closing</th>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="p-datatable-tbody">
                    {values?.length > 0 ? values.map((item, index) => (
                      <tr key={index}>
                        <td>{item.partNumber}</td>
                        <td>{item.partDescription}</td>
                        <td>{item.audioBrand}</td>
                        {monthsArr.map((month: any, idx: number) => {
                          const monthData = item.monthYears.find(
                            (my) => formatMonthYear(my.monthYear) === month
                          );
                          return monthData ? (
                            <React.Fragment key={idx}>
                              <td className="text-end">{monthData.stockDeatails.missing}</td>
                              <td className="text-end">{monthData.stockDeatails.opening}</td>
                              <td className="text-end">{monthData.stockDeatails.rejected}</td>
                              <td className="text-end">{monthData.stockDeatails.scrapped}</td>
                              <td className="text-end">{monthData.stockDeatails.unused}</td>
                              <td className="text-end">{monthData.stockDeatails.used}</td>
                              <td className="text-end">{monthData.stockDeatails.closing}</td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    )) : <tr>
                       <td colSpan={3}>No parts found</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}

          <StockValuePartChild referece={dt} header={header} monthsArr={monthsArr} values={values} />

        </div>
      </div>
    </>
  );
}






