import { PendingPartsConsolidatedTypes } from "./actionTypes";


//grid
export const getPendingPartsConsolidatedLoading = (lazyState: any) => {
  
  return {
    type: PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_LOADING,
    lazyState,
  };
};
export const getPendingPartsConsolidatedSuccess = (pendingpartsconsolidated : any) => {
  return {
    type: PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_SUCCESS,
    payload : pendingpartsconsolidated   
  };
};
export const getPendingPartsConsolidatedError = (error : any) => {
  return {
    type: PendingPartsConsolidatedTypes.GET_PENDING_PARTS_CONSOLIDATED_LIST_ERROR,
    payload : error
  };
};
