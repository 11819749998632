import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { useNavigate, useParams } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { Image } from 'primereact/image';
import { clearManufacturerMessage, getManufacturerByIdLoading, getManufacturerProfileLoading } from 'src/store/manufacturer/action';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';




const ViewManufacturer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { error, message, loading, manufacturerImage, } = useSelector(
    (state: RootReducerState) => state.ManufacturerReducer
  );
  const {  subUserDetails } = useSelector(
    (state: RootReducerState) => state.ManufacturerReducer.manufacturerList
  );
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
    }
  }, [error, message]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading ) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);
  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getManufacturerByIdLoading(id));
      dispatch(getManufacturerProfileLoading(id));
    }
    return () => {
      dispatch(clearManufacturerMessage());
    };
  }, []);


  const editDealerClickHandler = () => {
    navigate(`${ROUTER.MANUFACTURER_EDIT}/${id}`);
  };

  const dealerViewDetails = {
    personalDetails: [
      {
        label: "Name",
        value: subUserDetails?.['user']?.['name']
      },
      {
        label: "User Name",
        value: subUserDetails?.['user']?.['userName']
      },
      {
        label: "Email",
        value: subUserDetails?.['user']?.['email']
      },
      {
        label: "Mobile",
        value: subUserDetails?.['user']?.['phoneNumber']
      },
      {
        label: "Alternate Number ",
        value: subUserDetails?.['alternateNumber']
      },
    
    ],

  };
 
  return (
    <div className="pagescontent " id="view-dealer">
      {visible && dialogContainer(error)}
      <BreadcrumbWithFilter
        title="Manufacturer"
        titlePath={ROUTER.MANUFACTURER_GRID}
        breadcrumbItem="Manufacturer Details"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={true}
        firstButtonLabel='Edit Manufacturer'
        onFirstButtonClick={editDealerClickHandler}
        isShowSecondButton={false}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <b>General Details</b>
          </div>
          <div className="subview">
            <Row>
              <Col lg={6}>
                <div className="parentCls2">
                  {
                    dealerViewDetails.personalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.label}</div>
                          <div>{data.value ? data.value : <div className="none-text">-</div>}</div>
                        </div>
                      );
                    })
                  }
                </div>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col lg={4} className=''>
                    <div className='mb-2'>Image</div>
                    {manufacturerImage ?
                      <Image src={manufacturerImage} alt="Image" className='view-image' height='150px' preview />
                      :
                      <div className='none-text'>-</div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ViewManufacturer;
