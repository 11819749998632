import { IncentiveRewardTypes } from "./actionTypes"

export const incentiveRewardListLoading = (lazyState: any) => {
    return {
        type: IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_LOADING,
        lazyState,
    }
}

export const incentiveRewardListSuccess = (response: any) => {
    return {
        type: IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_SUCCESS,
        payload: response
    }
}
export const incentiveRewardListError = (error: any) => {
    return {
        type: IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_FAIL,
        payload: error
    }
}
export const incentiveRewardListByIdLoading = (id: any) => {
    return {
        type: IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_LOADING,
        id:id,
    }
}

export const incentiveRewardListByIdSuccess = (response: any) => {
    return {
        type: IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_SUCCESS,
        payload: response
    }
}
export const incentiveRewardListByIdError = (error: any) => {
    return {
        type: IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_FAIL,
        payload: error
    }
}
export const updatePaymentLoading = (paymentDetail: any,id:any) => {
    return {
        type: IncentiveRewardTypes.UPDATE_PAYMENT_LOADING,
        payload:paymentDetail,
        id:id
    }
}

export const updatePaymentSuccess = (response: any) => {
    return {
        type: IncentiveRewardTypes.UPDATE_PAYMENT_SUCCESS,
        payload: response
    }
}
export const updatePaymentError = (error: any) => {
    return {
        type: IncentiveRewardTypes.UPDATE_PAYMENT_FAIL,
        payload: error
    }
}
export const updateAdjustmentPointLoading = (payload: any,id:any) => {
    return {
        type: IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_LOADING,
        payload:payload,
        id:id
    }
}

export const updateAdjustmentPointSuccess = (response: any) => {
    return {
        type: IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_SUCCESS,
        payload: response
    }
}
export const updateAdjustmentPointError = (error: any) => {
    return {
        type: IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_FAIL,
        payload: error
    }
}

export const clearMessageForIncentiveReward = () => {
    return {
        type: IncentiveRewardTypes.CLEAR_MESSAGE_INCENTIVE_REWARD,
    }
}

export const resetIncentiveReward = () => {
    return {
        type: IncentiveRewardTypes.RESET_MESSAGE_INCENTIVE_REWARD,
    }
}