import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { CurrencyFormatter, formatDateMEL } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import {
  clearPurchaseOrder,
  deletePurchaseOrderFromList,
  downloadPurchaseOrder,
  getDropdownForPurchase,
  getPurchaseOrderById,
  resetPurchaseOrder,
  sentMailToSupplierPurchaseOrder,
} from 'src/store/actions';
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DeleteModal from "../../Calendar/DeleteModal";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns } from 'src/models/components/dataTableWrapperModel'
import { Link } from 'react-router-dom'


const ViewParts = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const formValue: any = useSelector(
    (state: RootReducerState) => state.purchaseOrderReducer.purchaseOrderDetails
  );
  const { error, message, loading, supplierList, partList } = useSelector(
    (state: RootReducerState) => state.purchaseOrderReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )
  }


  const [visible, setVisible] = useState(false);
  const { showToast } = useToast();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetPurchaseOrder());
      if (message == "Purchase order is deleted successfully") {
        navigate(`${ROUTER.PURCHASE_ORDER_GRID}`)
      }
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPurchaseOrder())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getDropdownForPurchase());
      dispatch(getPurchaseOrderById(id));
    }
    return () => {
      dispatch(clearPurchaseOrder());
    };
  }, []);

  const currencyBodyTemplate = (totalamount: string, code: string) => {
    let amount = totalamount;
    let currencyCode = code;
    return <div>{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>;
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${field}`}>{rowData}</Link>
    );
  };

  const userView = {
    generalDetails: [
      {
        name: "Number",
        value: formValue?.poNumber
      },
      {
        name: "Generated Date",
        value: formatDateMEL(formValue?.generatedDate)
      },
      {
        name: "Buying Currency",
        value: formValue?.buyingCurrency
        // value:  currencyBodyTemplate(formValue?.totalPrice, formValue?.buyingCurrency)
      },
      {
        name: "Supplier",
        value: supplierBodyTemplate(supplierList?.find((eachSupplier) => formValue?.supplierId === eachSupplier.id)?.name , formValue?.supplierId)
      },
      {
        name: "Total",
        // value: CurrencyFormatter({ value: formValue?.totalPrice })
        value: currencyBodyTemplate(formValue?.totalPrice, formValue?.buyingCurrency)
      },
      {
        name: "Locked",
        value: statusBodyTemplate(formValue?.locked)
      },
      {
        name: "Terms And Conditions",
        value: formValue?.termsAndConditions
      },
      {
        name: "Remarks",
        value: formValue?.remarks
      }
    ]
  }

 

  const editPurchaseOrder = () => {
    navigate(`${ROUTER.PURCHASE_ORDER_EDIT}/${id}`)
  }

  const createPurchaseOrder = () => {
    navigate(`${ROUTER.PURCHASE_ORDER_CREATE}`)
  }

  const sendEmailSupplier = () => {
    dispatch(sentMailToSupplierPurchaseOrder(id))
    // navigate(`${ROUTER.PURCHASE_ORDER_CREATE}`)
  }

  const printPurchaseOrder = () => {
    dispatch(downloadPurchaseOrder(id))
  }

  const archivePurchaseOrder = () => {
    setDeleteModal(true)
  }
  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }
  const partDetailsColumn: Columns[] = [
    {
      field: "partNo",
      sortable: false,
      header: "Part",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "modelName",
      sortable: false,
      header: "Systembrand Model",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "quantity",
      sortable: false,
      header: "Quantity",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.quantity),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "buyingPrice",
      sortable: false,
      header: "Buying Price",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.buyingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalBuyingPrice",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      body:(rowData:any)=>headerTemplate(rowData.totalBuyingPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    }
  ];


  return (
    <React.Fragment>
      <div className='userpage'>
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={false}
          onDeleteClick={() => {
            let payload = {
              id: id,
              undo: false,
            };
            dispatch(deletePurchaseOrderFromList(payload));
            setDeleteModal(false);
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />
        <Container fluid>
          <BreadcrumbWithFilter
            title="Purchase Order" titlePath={ROUTER.PURCHASE_ORDER_GRID} breadcrumbItem='Purchase Order Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}

            isShowFirstButton={formValue?.locked ? false : true}
            firstButtonLabel='Edit Purchase Order'
            onFirstButtonClick={editPurchaseOrder}

            // isShowSecondButton={formValue?.locked ? false : true}
            // secondButtonLabel='Archive Purchase Order'
            // onSecondButtonClick={archivePurchaseOrder}

            isShowThirdButton={formValue?.locked ? true : false}
            thirdButtonLabel='Email Supplier'
            onThirdButtonClick={sendEmailSupplier}

            isShowForthButton={formValue?.locked ? true : false}
            forthButtonLabel='Print Purchase Order'
            forthButtonDisabled={false}
            onForthButtonClick={printPurchaseOrder}

            isShowFifthButton={true}
            fifthButtonLabel='New Purchase Order'
            onFifthButtonClick={createPurchaseOrder}



          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
              <div className="parentCls">
                  {
                    userView.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>           
          </Row>

          <Row>
            {formValue?.purchaseOrderDetailsDtos?.length > 0 &&
              <Row>
                <div className="view">
                  <div className="general-details">
                    <b>Parts Details</b>
                  </div>
                  <div className="subview">
                    <Row>
                      <div className="card ps-0 pe-0">
                        <DataTableWrapper
                          columnDetails={partDetailsColumn}
                          value={formValue?.purchaseOrderDetailsDtos}
                        >
                        </DataTableWrapper>
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
            }
          </Row>

        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewParts