import { PartSkuTypes } from "./actionTypes";



// For Grid

export const getPartSkuList = (lazyState: any) => {
  return {
    type: PartSkuTypes.GET_PART_SKU_LIST,
    lazyState,
  };
};



export const getPartSkuListSuccess = (administrators: any) => {
  return {
    type: PartSkuTypes.GET_PART_SKU_SUCCESS,
    payload: administrators,
  };
};

export const getPartSkuListError = (error: any) => {
  return {
    type: PartSkuTypes.GET_PART_SKU_FAIL,
    payload: error,
  };
};


export const resetPartskumessage=()=>{
  return {
    type: PartSkuTypes.RESET_PART_SKU_MESSAGE,
  }
}

export const getPartAutoCompleteList = (payload: any) => {
  return {
    type: PartSkuTypes.GET_PART_AUTOCOMPLETE,
    params:payload,
  };
};



export const getPartAutoCompleteSuccess = (payload: any) => {
  
  
  return {
    type: PartSkuTypes.GET_PART_AUTOCOMPLETE_SUCCESS,
    payload: payload,
  };
};

export const getPartAutoCompleteError = (error: any) => {
  return {
    type: PartSkuTypes.GET_PART_AUTOCOMPLETE_FAIL,
    payload: error,
  };
};

export const getPartSkuAutoComplete = (payload: any) => {
  return {
    type: PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE,
    params:payload,
  };
};



export const getPartSkuAutoCompleteSuccess = (payload: any) => {
  
  
  return {
    type: PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE_SUCCESS,
    payload: payload,
  };
};

export const getPartSkuAutoCompleteError = (error: any) => {
  return {
    type: PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE_FAIL,
    payload: error,
  };
};
