import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import Button from 'src/components/UI/Button';
import * as Yup from 'yup';
import { ROUTER } from 'src/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFeedbackLoading,
  getJobsheetByIdForFeedback,
  getVehiclebrandDropdownForFeedbackLoading,
  resetFeedbackMessage,
} from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import RatingComponent from 'src/components/Common/RatingComponent';

const CreateFeedback: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { error, message, loading, jobsheet, vehicleBrandDropdown } = useSelector((state: RootReducerState) => state.feedbackReducer);
  const [vehicleBrandName, setVehicleBrandName] = useState<string>("");



  const validationSchema = Yup.object().shape({

    adherenceOfTATRating: Yup.string().required('Adherence of TAT rating is required'),
    adherenceOfTATComments: Yup.string().required('Adherence of TAT comments is required'),
    ontimeSolutionRating: Yup.string().required('Ontime solution rating is required'),
    onTimeSolutionComments: Yup.string().required('Ontime solution comments is required'),
    onTimePartSupportRating: Yup.string().required('On time part support rating is required'),
    onTimePartSupportComments: Yup.string().required('On time part support comments is required'),
    engineerTechnicalSkillRating: Yup.string().required('Engineer technical skill rating is required'),
    engineerTechnicalSkillComments: Yup.string().required('Engineer technical skill comments is required'),
    engineerBehaviorRating: Yup.string().required('Engineer behavior rating is required'),
    engineerBehaviorComments: Yup.string().required('Engineer behavior comments is required'),
  });

  const [validationError, setValidationError] = useState<any>(validationSchema);

  const validationChanged = (values: any, setFieldTouched: any) => {
    let validationData: any = {
      adherenceOfTATRating: Yup.string().required('Adherence of TAT rating is required'),
      ontimeSolutionRating: Yup.string().required('Ontime solution rating is required'),
      onTimePartSupportRating: Yup.string().required('On time part support rating is required'),
      engineerTechnicalSkillRating: Yup.string().required('Engineer technical skill rating is required'),
      engineerBehaviorRating: Yup.string().required('Engineer behavior rating is required'),
    };

    if (values?.adherenceOfTATRating != 10) {
      validationData['adherenceOfTATComments'] = Yup.string().required('Adherence of TAT comments is required');
    } else {
      setFieldTouched('adherenceOfTATComments', false);
    }
    if (values?.ontimeSolutionRating != 10) {
      validationData['onTimeSolutionComments'] = Yup.string().required('Ontime solution comments is required');
    } else {
      setFieldTouched('onTimeSolutionComments', false);
    }
    if (values?.onTimePartSupportRating != 10) {
      validationData['onTimePartSupportComments'] = Yup.string().required('On time part support comments is required');
    } else {
      setFieldTouched('onTimePartSupportComments', false);
    }
    if (values?.engineerTechnicalSkillRating != 10) {
      validationData['engineerTechnicalSkillComments'] = Yup.string().required('Engineer technical skill comments is required');
    } else {
      setFieldTouched('engineerTechnicalSkillComments', false);
    }
    if (values?.engineerBehaviorRating != 10) {
      validationData['engineerBehaviorComments'] = Yup.string().required('Engineer behavior comments is required');
    } else {
      setFieldTouched('engineerBehaviorComments', false);
    }

    return Yup.object().shape({
      ...validationData
    });

  };

  const checkValidation = (values: any, setFieldTouched: any) => {
    let validation = validationChanged(values, setFieldTouched);
    setValidationError(validation);
  };





  useEffect(() => {
    if (jobsheet?.vehicleBrandId && vehicleBrandDropdown?.length > 0) {
      const VehicleBrandNameName = vehicleBrandDropdown?.find((item: any) => item.id == jobsheet?.vehicleBrandId)?.name;
      setVehicleBrandName(VehicleBrandNameName);
    }
  }, [jobsheet, vehicleBrandDropdown]);

  const initialvalue = useMemo(() => {
    return {
      jobsheet: jobsheet?.number ? jobsheet?.number : "",
      dealer: jobsheet?.dealerName ? jobsheet?.dealerName : "",
      dealerCode: jobsheet?.dealerCode ? jobsheet?.dealerCode : "",
      vinNumber: jobsheet?.vinNumber ? jobsheet?.vinNumber : "",
      vehicleModel: vehicleBrandName ? vehicleBrandName : "",
      audioPartNumber: jobsheet?.audioPartNumber ? jobsheet?.audioPartNumber : "",
      audioSerialNumber: jobsheet?.audioSerialNumber ? jobsheet?.audioSerialNumber : "",
      exchangeAudioSerialNumber: jobsheet?.outgoingAudioSerialNumber ? jobsheet?.outgoingAudioSerialNumber : "",
      adherenceOfTATRating: "",
      adherenceOfTATComments: "",
      ontimeSolutionRating: "",
      onTimeSolutionComments: "",
      onTimePartSupportRating: "",
      onTimePartSupportComments: "",
      engineerTechnicalSkillRating: "",
      engineerTechnicalSkillComments: "",
      engineerBehaviorRating: "",
      engineerBehaviorComments: "",
      marks: "",
      grade: "",
    };
  }, [jobsheet,vehicleBrandName]);

  // const initialvalue = getInitialValue();



  const handleSubmit = async (values: any) => {
    debugger;

    let userDetails = JSON.parse(localStorage.getItem('authUser') as string);
    let userId = userDetails?.uid ? userDetails?.uid : "";
    let data: any = {
      "id": 0,
      "jobId": id,
      "userId": userId,
      "adherenceOfTATRating": values?.adherenceOfTATRating,
      "adherenceOfTATComments": values?.adherenceOfTATComments ? values?.adherenceOfTATComments : null,
      "ontimeSolutionRating": values?.ontimeSolutionRating,
      "onTimeSolutionComments": values?.onTimeSolutionComments ? values?.onTimeSolutionComments : null,
      "onTimePartSupportRating": values?.onTimePartSupportRating,
      "onTimePartSupportComments": values?.onTimePartSupportComments ? values?.onTimePartSupportComments : null,
      "engineerTechnicalSkillRating": values?.engineerTechnicalSkillRating,
      "engineerTechnicalSkillComments": values?.engineerTechnicalSkillComments ? values?.engineerTechnicalSkillComments : null,
      "engineerBehaviorRating": values?.engineerBehaviorRating,
      "engineerBehaviorComments": values?.engineerBehaviorComments ? values?.engineerBehaviorComments : null,
      "marks": 0,
      "grade": null
    };
    if (isSubmitting) return;
    dispatch(createFeedbackLoading(data));
    setIsSubmitting(true);
  };




  useEffect(() => {
    if (id) {
      dispatch(getJobsheetByIdForFeedback(id));
      dispatch(getVehiclebrandDropdownForFeedbackLoading());
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (message != "" && message != undefined) {
      showToast(message, { type: "success" });
      dispatch(resetFeedbackMessage());
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.FEEDBACK_GRID);
      }, 500);
    }
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
    }

  }, [error, message]);





  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const handleReset = (resetForm: any) => {
    if (id) {
      navigate(ROUTER.FEEDBACK_GRID);
    } else {
      resetForm();
    }
  };

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetFeedbackMessage());
  };
  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };






  return (
    <React.Fragment>

      {visible && dialogContainer(error)}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Feedbacks" titlePath={ROUTER.FEEDBACK_GRID} breadcrumbItem={"New Feedback"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationError}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldTouched, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Job sheet"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="jobsheet"
                                onBlur={handleBlur}
                                value={values.jobsheet}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["jobsheet"] && errors["jobsheet"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Dealer"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="dealer"
                                onBlur={handleBlur}
                                value={values.dealer}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["dealer"] && errors["dealer"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Dealer code"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="dealerCode"
                                onBlur={handleBlur}
                                value={values.dealerCode}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["dealerCode"] && errors["dealerCode"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Vin number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="vinNumber"
                                onBlur={handleBlur}
                                value={values.vinNumber}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["vinNumber"] && errors["vinNumber"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom labelName="Vehicle Brand"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="vehicleModel"
                                onBlur={handleBlur}
                                value={values.vehicleModel}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["vehicleModel"] && errors["vehicleModel"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom labelName="Audio part number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="audioPartNumber"
                                onBlur={handleBlur}
                                value={values.audioPartNumber}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["audioPartNumber"] && errors["audioPartNumber"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Audio serial number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="audioSerialNumber"
                                onBlur={handleBlur}
                                value={values.audioSerialNumber}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["audioSerialNumber"] && errors["audioSerialNumber"] ? true : false}
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Exchange Audio Serial Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="exchangeAudioSerialNumber"
                                onBlur={handleBlur}
                                value={values.exchangeAudioSerialNumber}
                                onChange={handleChange}
                                isRequired={false}
                                disabled={true}
                                invalid={touched["exchangeAudioSerialNumber"] && errors["exchangeAudioSerialNumber"] ? true : false}
                              />

                            </Col>
                            <Col lg={5} className="mb-2">
                              <RatingComponent name="adherenceOfTATRating" value={values.adherenceOfTATRating} handleChange={(e: any) => {
                                handleChange(e);
                                let data = { ...values };
                                data['adherenceOfTATRating'] = e?.target?.value;
                                checkValidation(data, setFieldTouched);
                              }
                              } stars={10}
                                label={"Adherence of TAT rating"} isRequired={true}></RatingComponent>
                            </Col>
                            <Col lg={7} className="mb-2">
                              <InputCom
                                labelName="Adherence of TAT comments"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="adherenceOfTATComments"
                                rowHeight={4}
                                onBlur={handleBlur}
                                value={values.jobprefix}
                                onChange={handleChange}
                                isRequired={values.adherenceOfTATRating == 10 ? false : true}
                                invalid={
                                  touched["adherenceOfTATComments"] &&
                                    errors["adherenceOfTATComments"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={5} className="mb-2">
                              <RatingComponent name="ontimeSolutionRating" value={values.ontimeSolutionRating} handleChange={(e: any) => {
                                handleChange(e);
                                let data = { ...values };
                                data['ontimeSolutionRating'] = e?.target?.value;
                                checkValidation(data, setFieldTouched);
                              }}
                                stars={10} label={"Ontime solution rating"} isRequired={true}></RatingComponent>
                            </Col>
                            <Col lg={7} className="mb-2">
                              <InputCom
                                labelName="Ontime solution comments"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="onTimeSolutionComments"
                                rowHeight={4}
                                onBlur={handleBlur}
                                value={values.jobprefix}
                                onChange={handleChange}
                                isRequired={values.ontimeSolutionRating == 10 ? false : true}
                                invalid={
                                  touched["onTimeSolutionComments"] &&
                                    errors["onTimeSolutionComments"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={5} className="mb-2">
                              <RatingComponent name="onTimePartSupportRating" value={values.onTimePartSupportRating} handleChange={(e: any) => {
                                handleChange(e);
                                let data = { ...values };
                                data['onTimePartSupportRating'] = e?.target?.value;
                                checkValidation(data, setFieldTouched);
                              }} stars={10} label={"On time part support rating"} isRequired={true}></RatingComponent>
                            </Col>
                            <Col lg={7} className="mb-2">
                              <InputCom
                                labelName="On time part support comments"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="onTimePartSupportComments"
                                rowHeight={4}
                                onBlur={handleBlur}
                                value={values.onTimePartSupportComments}
                                onChange={handleChange}
                                isRequired={values.onTimePartSupportRating == 10 ? false : true}
                                invalid={
                                  touched["onTimePartSupportComments"] &&
                                    errors["onTimePartSupportComments"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={5} className="mb-2">
                              <RatingComponent name="engineerTechnicalSkillRating" value={values.engineerTechnicalSkillRating} handleChange={(e: any) => {
                                handleChange(e);
                                let data = { ...values };
                                data['engineerTechnicalSkillRating'] = e?.target?.value;
                                checkValidation(data, setFieldTouched);
                              }} stars={10}
                                label={"Engineer technical skill rating"} isRequired={true}></RatingComponent>
                            </Col>
                            <Col lg={7} className="mb-2">
                              <InputCom
                                labelName="Engineer technical skill comments"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="engineerTechnicalSkillComments"
                                rowHeight={4}
                                onBlur={handleBlur}
                                value={values.engineerTechnicalSkillComments}
                                onChange={handleChange}
                                isRequired={values.engineerTechnicalSkillRating == 10 ? false : true}
                                invalid={
                                  touched["engineerTechnicalSkillComments"] &&
                                    errors["engineerTechnicalSkillComments"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={5} className="mb-2">
                              <RatingComponent name="engineerBehaviorRating" value={values.engineerBehaviorRating} handleChange={(e: any) => {
                                handleChange(e);
                                let data = { ...values };
                                data['engineerBehaviorRating'] = e?.target?.value;
                                checkValidation(data, setFieldTouched);
                              }} stars={10}
                                label={"Engineer behavior rating"} isRequired={true}></RatingComponent>
                            </Col>
                            <Col lg={7} className="mb-2">
                              <InputCom
                                labelName="Engineer behavior comments"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="engineerBehaviorComments"
                                rowHeight={4}
                                onBlur={handleBlur}
                                value={values.engineerBehaviorComments}
                                onChange={handleChange}
                                isRequired={values.engineerBehaviorRating == 10 ? false : true}
                                invalid={
                                  touched["engineerBehaviorComments"] &&
                                    errors["engineerBehaviorComments"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>


                          </Row>
                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2"
                              label={"Create Feedback"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default CreateFeedback;