import React, { useEffect, useRef, useState } from 'react';
// import DeleteModal from '../Calendar/DeleteModal';
// import ArchiveModal from "../Calendar/DeleteModal";
// import { franchisee } from 'src/models/pages/franchiseeModel';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Form, Formik } from 'formik';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'src/components/Common/Select';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { ROUTER } from 'src/constants/routes';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import CustomizeTable from 'src/components/Common/CustomizeTable/CustomizeTable';
import { Dropdown } from 'primereact/dropdown';
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from 'src/constants/bulkActionVariant';
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import { formatDateTimeFormServerView, formatDateTimeMEL, saveAsExcelFile } from 'src/helpers/common_helpers';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import BulkActionStatus from 'src/components/Common/BulkActionStatus/BulkActionStatus';
import TooltipMEL from 'src/components/UI/Tooltip';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import ArchiveModal from 'src/pages/Calendar/DeleteModal';
import { clearBulkSmsMessage, deleteBulkSms, getBulkSmsList, getBulkSmsStatus } from 'src/store/BulkSms/action';
import { API } from 'src/util/api';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";

const BulkSmsList: React.FC = () => {
  const filterDetails: DataTableFilterMeta = {
    Status: { value: "", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const emailBodyTemplate = (rowData: any, field: string) => {
    let data = rowData[field] as string;
    return (
      <EmailBodyTemplate data={data} />
    );
  };


  const hidePopup = () => { 
    setVisible(false); 
    dispatch(clearBulkSmsMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
         <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className='vw30' onHide={() => { setVisible(false); dispatch(resetFranchisee()); }}>
          <div className="m-0 validation-text">
            {error}
          </div>
        </Dialog> */}
      </>
    );
  };

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      frozen : true,
      isStatic: true,
    },
    {
      field: "title",
      sortable: true,
      header: "Title",
      filter: false,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "title"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "smsContent",
      sortable: true,
      header: "Sms Content",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      // body: (rowData) => actionBodyTemplate(rowData, "smsContent"),
      isDefault: true,
      isStatic: true,
    },
    // {
    //   field: "subject",
    //   sortable: true,
    //   header: "Subject",
    //   filter: false,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    // {
    //   field: "body",
    //   sortable: true,
    //   header: "Body",
    //   filter: false,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "creationTime",
      sortable: true,
      header: "Created At",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>formatDateTimeFormServerView(rowData.creationTime),
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "updatedat",
    //   sortable: false,
    //   header: "Updated",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "totalCount",
      sortable: true,
      header: "Total Count",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>headerTemplate(rowData.totalCount),
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "sentCount",
    //   sortable: true,
    //   header: "Sent Count",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    // {
    //   field: "failureCount",
    //   sortable: true,
    //   header: "Failure Count",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "status",
      sortable: true,
      header: "Status",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedSmsList, setSelectedSmsList] = useState<any>([]);
  const [rowData, setRowData] = useState<any | null>(null);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const [selectedStatus, setselectedStatus] = useState<any>("All");
  const [franchiseeData, setFranchiseeData] = useState<any>([]);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  const initialValues = {
    status: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const dt = useRef<DataTable<any>>(null);
  const { BulkSmsList, totalRecords ,error, message , loading,paginatorCount,statusList } = useSelector((state: RootReducerState) => state.BulkSmsReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [pdfLoading]);



  const actionBodyTemplate = (rowData: any, field: string) => {
    
    return (
      <>
{(rowData.status=="Cancelled"||rowData.status=="Completed") ?
      <ColumnAction
        displayData={rowData[field] as string}
        isView={true}
        onViewClick={() => viewHandler(rowData)}
      />
    :
      <ColumnAction
                displayData={rowData[field] as string}
                isPause={true}
                isView={true}
                onViewClick={() => viewHandler(rowData)}
                onPauseClick={() => deleteHandler(rowData)}
              />

}

      </>

    );
  };

  // const options: Option[] = [
  //   { label: "Active", value: "Active" },
  //   { label: "Deleted", value: "Deleted" }
  // ]
  const status=[{status:"ALL",id:"All"},...statusList]
  const options:any = status?.map((item:any)=>({
label:item.status,
value:item.id
  }))
  // const handleDeleteUsers = (rowData: franchisee) => {
  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    dispatch(deleteBulkSms(rowData["id"]));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setTimeout(() => {
        // dispatch(getBulkSmsList(lazyState));
      }, 500);
      setSelectAll(false);
      setSelectedSmsList([]);
    }

  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedSmsList([]);
    setArchiveModal(false);
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if(formValue=="All"){
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          Status: { value: "", matchMode: "contains" },
        },
      }));
    }else{
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          Status: { value: String(formValue), matchMode: "contains" },
        },
      }));
    }

  
    setIsTooltipVisible(false)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  // const viewHandler = (rowData: franchisee) => {
  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.BULK_SMS_VIEW}/${rowData["id"]}`);
  };


  // const deleteHandler = (rowData: franchisee) => {
  const deleteHandler = (rowData: any) => {
    
    setDeleteModal(true);
    setRowData(rowData);
  };

  const franchiseSubUSer = (rowData: any) => {
    navigate(ROUTER.SUB_USER_CREATE_ID + '/' + rowData['id'], { state: rowData })
  }

  const onSelectionChange = (event: any) => {
    const value = event.value;
    let status=["Completed","Cancelled"]
    let filtered = event.value.filter((item:any)=>!status.includes(item.status))
    setSelectedSmsList(filtered);
    setSelectAll(filtered.length === totalRecords);
    setSelectAll(filtered.length === paginatorCount);
  };
  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
 
    if (selectAll) {
      let status=["Completed","Cancelled"]
      let filtered = BulkSmsList.filter((item:any)=>!status.includes(item.status))
      setSelectAll(filtered.length === totalRecords);
      setSelectAll(filtered.length === paginatorCount)
      setSelectedSmsList(filtered);
    } else {
      setSelectAll(false);
      setSelectedSmsList([]);
    }
  };
  const defaultColumns = {
    ...columns[0]
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedSmsList([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {

    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearBulkSmsMessage());
      dispatch(getBulkSmsList(lazyState));
    }
  }, [error, message]);

  const onPage = (event: any) => {
    setLazyState(event);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.BULK_SMS_SENDER);
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getBulkSmsList(lazyState));
  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const bulkActionClickHandler = () => {
    if (BulkSmsList.length > 0) {
      let status=["Completed","Cancelled"]
      let filtered = BulkSmsList.filter((item:any)=>!status.includes(item.status))
      setSelectAll(filtered.length === totalRecords);
      setSelectAll(filtered.length === paginatorCount)
      setSelectedSmsList(filtered);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedSmsList([]);
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedSmsList([]);
    callbackFunction(lazyState);
  }, [lazyState]);


  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
	
    try {
      setPdfLoading(true)
      const response = await API.getBulkSmsListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      // let resp=await dealerListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "Sms_List");
     }else{
      saveDataAsExcel(response?.data?.items, "Sms_List");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(BulkSmsList, "Sms_List");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(BulkSmsList, columns, "Sms_List");
    fetchData(true)
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus("All")
  }

  const header =
    <div className="table-header-container">
      <div>
        Bulk Sms List
      </div>
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedSmsList.length == 0 && (selectedStatus!="All" &&selectedStatus!==2&&selectedStatus!==3)) && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedSmsList.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
          <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
            <FeatherIcon icon="archive" className="mr-1" />
            Archive
          </Button>
        </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space " onClick={() => { setDeleteModal(true); }}>
                <><FeatherIcon    icon="slash" className="mr-1" /> Cancel</> 
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> Cancel selection</Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedSmsList.length > 0 && <BulkActionStatus count={selectedSmsList.length} title="Bulk Sms"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
      <div style={{ display: "flex" }}>
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={false}/>
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
       <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" /> 
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button>
        </div>
        <div className="reponsive_position">
        {/* <TooltipMEL title="Add Franchisee" position="bottom"> */}
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Send Sms
        </Button>
        </div>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((a) => {
      let data: any = {};
      data['Title'] = a.title;
      data['Sms Content'] = a.smsContent;
      data['Created At'] = formatDateTimeMEL(a.creationTime);
      data['Count'] = a.totalCount;
      data['Status']=a.status
      return data ;
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = async (values: any[], fileName: string) => {
    let modifiedColumnDetails = await changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((a) => {
      let datas = {
        title: a.title,
        smsContent: a.smsContent,
        creationTime: formatDateTimeMEL(a.creationTime),
        totalCount: a.totalCount,
        status:a.status
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
useEffect(()=>{
  dispatch(getBulkSmsStatus())
},[])
  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          message="Are you sure you want to cancel SMS sending"
          onDeleteClick={() => {
            if (selectedSmsList?.length > 10) {
              showToast("Cannot cancel more than 10 items at once", { type: "error" });
              setSelectedSmsList([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedSmsList.length == 1) {
              handleDeleteUsers(rowData || selectedSmsList[0]);
            } else if (selectedSmsList.length > 1) {
              for (let i = 0; i < selectedSmsList.length; i++) {
                handleDeleteUsers(selectedSmsList[i], selectedSmsList.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these administrators?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedSmsList([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={(selectedStatus=="All"||selectedStatus==2||selectedStatus==3)? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          value={BulkSmsList}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          // loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Bulk Sms  {last} records of {totalRecords} in total"
          selection={selectedSmsList}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </React.Fragment>
  );
};

export default BulkSmsList;