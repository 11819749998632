import React, { useImperativeHandle, useRef } from 'react';
import moment from 'moment';

interface ChildTableParts {
  referece: any;
  header: any;
  monthsArr: any;
  values: any;
}

const StockValuePartChild = ({ header, monthsArr, referece, values }: ChildTableParts) => {
  const tableRef = useRef<any>();

  useImperativeHandle(referece, () => ({
    getTable: () => {
      return tableRef.current.querySelector('table');
    }
  }));

  function formatMonthYear(monthYear: any) {
    const [month, year] = monthYear.split('-');
    return `${year}${month.padStart(2, '0')}`;
  }

  return (
    <div className="table-container" ref={tableRef}>
      <div className="p-datatable p-component p-datatable-hoverable-rows p-datatable-scrollable p-datatable-responsive-scroll p-datatable-striped p-datatable-grouped-header">
        <div className="p-datatable-header">
          {header}
        </div>
        <div className="p-datatable-wrapper" style={{ overflow: "auto", maxHeight: "500px" }}>
          <table border={1} className="p-datatable-table p-datatable-scrollable-table" style={{ minWidth: "100%", fontSize: "14px", position: "relative" }}>
            <thead className="p-datatable-thead" style={{ top: "0", position: "sticky" }} >
              <tr>
                <th rowSpan={2}>Part Number</th>
                <th rowSpan={2}>Part Description</th>
                <th rowSpan={2}>Audio Brand</th>
                {monthsArr.map((month: any, index: number) => {
                  const date = moment(month, 'YYYYMM');
                  const formattedDate = date.format('MMM-YYYY');
                  return (
                    <React.Fragment key={index}>
                      <th colSpan={7}>{formattedDate}</th>
                    </React.Fragment>
                  );
                }
                )}
              </tr>
              <tr>
                {monthsArr.map((month: any, index: number) => (
                  <React.Fragment key={index}>
                    <th>Missing</th>
                    <th>Opening</th>
                    <th>Rejected</th>
                    <th>Scrapped</th>
                    <th>Unused</th>
                    <th>Used</th>
                    <th>Closing</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody className="p-datatable-tbody">
              {values?.length > 0 ? values.map((item: any, index: number) => (
                <tr key={index} className={index % 2 == 0 ? "p-row-even" : "p-row-odd"}>
                  <td>{item.partNumber}</td>
                  <td>{item.partDescription}</td>
                  <td>{item.audioBrand}</td>
                  {monthsArr.map((month: any, idx: number) => {
                    const monthData = item.monthYears.find(
                      (my: any) => formatMonthYear(my.monthYear) === month
                    );
                    return monthData ? (
                      <React.Fragment key={idx}>
                        <td className="text-end">{monthData.stockDeatails.missing}</td>
                        <td className="text-end">{monthData.stockDeatails.opening}</td>
                        <td className="text-end">{monthData.stockDeatails.rejected}</td>
                        <td className="text-end">{monthData.stockDeatails.scrapped}</td>
                        <td className="text-end">{monthData.stockDeatails.unused}</td>
                        <td className="text-end">{monthData.stockDeatails.used}</td>
                        <td className="text-end">{monthData.stockDeatails.closing}</td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={idx}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              )) : <tr>
                <td colSpan={3}>No parts found</td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StockValuePartChild;
