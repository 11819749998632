import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getJobsheetTrend } from "src/store/actions";
import 'bootstrap/dist/css/bootstrap.min.css'
import { RootReducerState } from "src/store/reducers";

interface TrendProps {
  date?: any
  realDate?: any
}
const JobsheetTrend = ({ date, realDate }: TrendProps) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<any>("ALL");
  const [modifiedData, setModifiedData] = useState<any>([]);
  const [monthName, setMonthName] = useState<any>([])
  const { jobsheetTrends } = useSelector((state: RootReducerState) => state.dashboard);
  const [month, year] = date.split(' ').map((value: any, index: any) => index === 0 ? new Date(`${value} 1, 2000`).getMonth() + 1 : parseInt(value));
  const onChangehandle = (data: any) => {
    setState(data);
    // dispatch(getWalletBalance(data));
  };



  useEffect(() => {
    if(month && year){
      dispatch(getJobsheetTrend(month, year))
    }
  }, [month, year])

  useEffect(() => {
    if (jobsheetTrends.length > 0) {
      // const monthTotals: any = {};
      // jobsheetTrends.forEach(item => {
      //   Object.keys(item).forEach(key => {
      //     if (key !== 'month') {
      //       if (!monthTotals[key]) {
      //         monthTotals[key] = [0, 0];
      //       }
      //       const monthIndex = item.month - 3;
      //       monthTotals[key][monthIndex] += item[key];
      //     }
      //   });
      // });
      // const series = Object.entries(monthTotals).map(([key, value]) => ({
      //   name: key,
      //   data: value,
      // }));
     
      // setModifiedData(series)
    //   const result = Object.keys(jobsheetTrends[0]) // Get all keys from the first object
    //     .filter(key => (key !== 'month' && key !='year')) // Exclude the 'month' key
    //     .map(name => {
    //       const counts = jobsheetTrends.reduce((acc, obj) => {
    //         acc[obj.month - 1] = (acc[obj.month - 1] || 0) + obj[name];
    //         return acc;
    //       }, []);

    //       return { name, data: counts };
    //     });
    //     const modifileResult=JSON.parse(JSON.stringify(result))?.map((item:any)=> ({name:item.name,data: item.data.map((value:any) => (value == null ? "" : value))}))
    //     let data=modifileResult.map((val:any)=>({
    //       name:val.name,
    //       data:val.data.filter((item:any)=>item!=="")
    //     }))
        

        
    //     setModifiedData(data)
    //     const months = jobsheetTrends.reduce((acc, obj) => {
    //       if (!acc.includes(obj.month)) {
    //         acc.push(obj.month);
    //     }
    //     return acc;
    //   }, []);

    //   const sortingMonth = months.sort((a:any,b:any)=>a - b)
    //   const monthNames = months.map((month: any) => {
    //     switch (month) {
    //       case 1:
    //         return "Jan";
    //       case 2:
    //         return "Feb";
    //       case 3:
    //         return "Mar";
    //       case 4:
    //         return "Apr";
    //       case 5:
    //         return "May";
    //       case 6:
    //         return "Jun";
    //       case 7:
    //         return "Jul";
    //       case 8:
    //         return "Aug";
    //       case 9:
    //         return "Sep";
    //       case 10:
    //         return "Oct";
    //       case 11:
    //         return "Nov";
    //       case 12:
    //         return "Dec";
    //       default:
    //         return "";
    //     }
    //   })
    //   setMonthName(monthNames)
    }
    // else{
    // //   setModifiedData([])
    // //   setMonthName([])
    // }

  }, [jobsheetTrends?.length > 0,jobsheetTrends])



const [sortedData ,setSortedData]=useState<any>([])
useEffect(()=>{

  const sorteddata = jobsheetTrends?.map(item => ({
    x: `${item.year}-${item.month}`,
    y: item.count
  }));
  setSortedData(sorteddata)
},[jobsheetTrends])

// Sort by month and year
const [sortedValue,setSortedValue]=useState<any>([])
useEffect(()=>{

 let sorted = sortedData?.sort((a:any, b:any) => {
    const [yearA, monthA] = a.x.split('-').map(Number);
    const [yearB, monthB] = b.x.split('-').map(Number);
    if (yearA === yearB) {
        return monthA - monthB;
    }
    return yearA - yearB;
  });
  setSortedValue(sorted)
},[sortedData])

let monthNames=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"]
const series = [{
  name: 'Count',
  data: sortedValue?.map((val:any)=>({
    y:val.y,
    x:monthNames[val.x.split('-')[1]-1]
  }))
}];

let maxVal=Math.max(...sortedValue?.map((data:any)=>data.y))

// let highestvalue=300
  const piechartColors = ["#5885AF", "#05445E", "#41729F", "#274472", "#C3E0E5", "#5885AF", "#05445E", "#41729F", "#274472", "#C3E0E5", "#5885AF", "#05445E", "#41729F"];
  const options: object = {
    chart: {
      height: 100,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    // colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Job Trends'
      },
      // min: sortedCount[0]?.count,
      // max: sortedCount[sortedCount?.length - 1]?.count
      min: 0,
      max: maxVal > 350 ? maxVal+10 : 350
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };

  // const series: any[] = [
  //   {
  //     name: "Job Trend",
  //     data: sortedJobsheetTrends?.map((item: any) => item.count),
  //     color: "#41729F"
  //   },

  // ]
  
  
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-h-100">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Jobsheet Trends</h5>
              <div className="ms-auto">
                <div>
                  <b>{date}</b>
                  {false && <>
                    <button
                      type="button"
                      className="btn btn-soft-primary btn-sm"
                      onClick={() => onChangehandle("ALL")}
                    >
                      ALL
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                      onClick={() => onChangehandle("1M")}
                    >
                      1M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                      onClick={() => onChangehandle("6M")}
                    >
                      6M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                      onClick={() => onChangehandle("1Y")}
                    >
                      1Y
                    </button>
                  </>
                  }
                </div>
              </div>
            </div>

            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <Row className="align-items-center">
                    <Col lg={12}>
                      <div className="jobtrendschart" style={{ maxWidth: "100%",maxHeight:"350px" }}>
                        {
                          jobsheetTrends?.length > 0 ?( <ReactApexChart options={options} series={series} type="line" width={'100%'} height={"350px"} />) : (<div>No data found</div>)
                        }
                        
                      </div>
                    </Col>

                  </Row>
                </div>
              </div>

            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default JobsheetTrend;
