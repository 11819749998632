
import { receivedPaymentActions } from "./actionTypes";
import { receivedPaymentReducerState } from "src/models/pages/receivedPaymentModel";


const initialState: receivedPaymentReducerState = {
  loading: false,
  error: "",
  message: "",
  receivedPaymentList: {
    items: [],
    total: 0
  },
  receivedPaymentDetails: null,

  dealerList: [],
  customerList: [],
  invoiceList: [],

  invoiceDetail:{},
  setInvoiceValueHandler:()=>{},
  invoiceIndex: -1,
  getUserDetails : null,
  createInvoiceList : []
}


const receivedPaymentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_LOADING:
    case receivedPaymentActions.VIEW_RECEIVED_PAYMENT_LOADING:
    case receivedPaymentActions.CREATE_RECEIVED_PAYMENT_LOADING:
    case receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT:
    case receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT:
    case receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT:
    case receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT:
    case receivedPaymentActions.DOWNLOAD_RECEIVED_PAYMENT:
    case receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_LOADING:
    case receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        error: "",
        message: ""
      }

    case receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        receivedPaymentList: {
          items: action.payload?.data?.items,
          total: action.payload?.data?.totalCount
        }
      }

    case receivedPaymentActions.CREATE_RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Received Payment Created SuccessFully"
      }



    case receivedPaymentActions.VIEW_RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        receivedPaymentDetails: action.payload.data
      }
// ---------------------------------------------------------
    case receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerList: action.payload.data
      }

    case receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        customerList: action.payload.data
      }

    case receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceList: action.payload.data
      }

    case receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetail: action.payload.resp.data,
        setInvoiceValueHandler: action.payload.setFieldValue,
        invoiceIndex: action.payload.index,
      }

      case receivedPaymentActions.RESET_INVOICE_INDEX_RECEIVED_PAYMENT:
        return {
          ...state,
          loading: false,
          invoiceDetail: {},
          invoiceIndex: -1,
        } 

    
  // ------------------------------------------

    case receivedPaymentActions.CLEAR_RECEIVED_PAYMENT_STATE_MESSAGE:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      }
    
    case receivedPaymentActions.DOWNLOAD_RECEIVED_PAYMENT_SUCCESS :
      return {
        ...state,
        loading : false,
        message : "successfully downloaded the received payment"
      }  

    case receivedPaymentActions.RESET_RECEIVED_PAYMENT_STATE:
      return { ...initialState }

    case receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_SUCCESS :
      return {
        ...state,
        loading : false,
        getUserDetails : action.payload.data
      }  

    case receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_SUCCESS :
      debugger
      return {
        ...state,
        loading : false,
        createInvoiceList : action.payload?.data?.length > 0 ? action.payload?.data : []
      }  

    case receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_ERROR:
    case receivedPaymentActions.CREATE_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.VIEW_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.DOWNLOAD_RECEIVED_PAYMENT_ERROR:
    case receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_ERROR:
    case receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: ""
      }
    default:
      return {
        ...state
      }
  }
}


export default receivedPaymentReducer;