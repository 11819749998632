export enum StockValueByMusicSystemBrandTypes {
    //Grid
    GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING = "@@stockValueByMusicSystemBrand/GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_LOADING",
    GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_SUCCESS = "@@stockValueByMusicSystemBrand/GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_SUCCESS",
    GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_ERROR = "@@stockValueByMusicSystemBrand/GET_STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_LIST_ERROR",

    GET_AUDIO_BRAND_LOADING = "@@stockValueByMusicSystemBrand/GET_AUDIO_BRAND_LOADING",
    GET_AUDIO_BRAND_SUCCESS = "@@stockValueByMusicSystemBrand/GET_AUDIO_BRAND_SUCCESS",
    GET_AUDIO_BRAND_ERROR = "@@stockValueByMusicSystemBrand/GET_AUDIO_BRAND_ERROR",
    
  }