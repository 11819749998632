
import { courierActionsTypes } from "./actionTypes"

//get courier list

export const courierListLoading = (lazyState: any) => {
  return {
    type: courierActionsTypes.GET_COURIERS_LIST_LOADING,
    lazyState
  }
}

export const courierListSuccess = (resp: any) => {
  return {
    type: courierActionsTypes.GET_COURIERS_LIST_SUCCESS,
    payload: resp
  }
}

export const courierListError = (error: any) => {
  return {
    type: courierActionsTypes.GET_COURIERS_LIST_ERROR,
    payload: error
  }
}

export const dealerListLoading = () => {
  return {
    type: courierActionsTypes.GET_DEALER_DROPDOWN_LOADING,
  }
}

export const dealerListSuccess = (resp: any) => {
  return {
    type: courierActionsTypes.GET_DEALER_DROPDOWN_LOADING_SUCCESS,
    payload: resp
  }
}

export const dealerListError = (error: any) => {
  return {
    type: courierActionsTypes.GET_DEALER_DROPDOWN_LOADING_ERROR,
    payload: error
  }
}
//reset courier state

export const resetCourierState = () => {
  return {
    type: courierActionsTypes.RESET_COURIER_STATE
  }
}


//clear Message

export const clearCourierMessage = () => {
  return {
    type: courierActionsTypes.CLEAR_COURIER_STATE_MESSAGE
  }
}

export const clearCourierDetails = () => {
  return {
    type: courierActionsTypes.CLEAR_COURIER_DETAILS
  }
}
//view courier

export const viewCourierLoading = (id: string) => {
  return {
    type: courierActionsTypes.VIEW_COURIER_LOADING,
    payload: id
  }
}

export const viewCourierSuccess = (response: any) => {
  return {
    type: courierActionsTypes.VIEW_COURIER_SUCCESS,
    payload: response
  }
}

export const viewCourierError = (error: any) => {
  return {
    type: courierActionsTypes.VIEW_COURIER_ERROR,
    payload: error
  }
}

//create courier 

export const createCourierLoading = (courierDetails: any) => {
  return {
    type: courierActionsTypes.CREATE_COURIER_LOADING,
    payload: courierDetails
  }
}
export const createCourierSuccess = (resp: any) => {
  return {
    type: courierActionsTypes.CREATE_COURIER_SUCCESS,
    payload: resp
  }
}
export const createCourierError = (error: any) => {
  return {
    type: courierActionsTypes.CREATE_COURIER_ERROR,
    payload: error
  }
}

//getby id courier details

export const getbyIdCourierDetailsLoading = (id: any) => {
  return {
    type: courierActionsTypes.GETBY_ID_COURIER_LOADING,
    payload: id
  }
}

export const getbyIdCourierDetailsSuccess = (resp: any) => {
  return {
    type: courierActionsTypes.GETBY_ID_COURIER_SUCCESS,
    payload: resp
  }
}

export const getbyIdCourierDetailsError = (error: any) => {
  return {
    type: courierActionsTypes.GETBY_ID_COURIER_ERROR,
    payload: error
  }
}

//update courier page

export const updateCourierLoading = (courierDetails: any, id: any) => {
  return {
    type: courierActionsTypes.UPDATE_COURIER_LOADING,
    payload: courierDetails,
    id
  }
}

export const updateCourierSuccess = (resp: any) => {
  return {
    type: courierActionsTypes.UPDATE_COURIER_SUCCESS,
    payload: resp
  }
}

export const updateCourierError = (error: any) => {
  return {
    type: courierActionsTypes.UPDATE_COURIER_ERROR,
    payload: error
  }
}


//get contact details about users sender type

export const getContactDetailsByUSerIDLoading = (selectedUser: any, setFieldValue: any, currentSenderType: any, selectedVaue: any) => {
  return {
    type: courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_LOADING,
    payload: {
      selectedUser,
      setFieldValue,
      currentSenderType,
      selectedVaue
    }
  }
}


export const getContactDetailsByUSerIDSuccess = (response: any, setFieldValue: any, currentSenderType: any, selectedVaue: any) => {
  return {
    type: courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_SUCCESS,
    payload: {
      response,
      setFieldValue,
      currentSenderType,
      selectedVaue
    }
  }
}

export const getContactDetailsByUSerIDError = (error: any) => {
  return {
    type: courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_ERROR,
    payload: error
  }
}


//reset senderData

export const resetSenderData = () => {
  return {
    type: courierActionsTypes.RESET_SENDER_DETAILS
  }
}



// get contact details about users receiver type

export const getContactDetailsByRecievedUserLoading = (selectedUser: any, setFieldValue: any, currentReceiverType: any, selectedVaue: any) => {
  return {
    type: courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_LOADING,
    payload: {
      selectedUser,
      setFieldValue,
      currentReceiverType,
      selectedVaue
    }
  }
}
export const getContactDetailsByRecievedUserSuccess = (response: any, selectedUser: any, setFieldValue: any, currentReceiverType: any, selectedVaue: any) => {
  return {
    type: courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_SUCCESS,
    payload: {
      response,
      selectedUser,
      setFieldValue,
      currentReceiverType,
      selectedVaue
    }
  }
}

export const getContactDetailsByRecievedUserError = (error: any) => {
  return {
    type: courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_ERROR,
    payload: error
  }
}


export const getMelEmployeeAddressLoading = (setFieldValue: any, currentType: any) => {
  return {
    type: courierActionsTypes.GET_MEl_ADDRESS_LOADING,
    payload: {
      setFieldValue,
      currentType
    }
  }
}
export const getMelEmployeeAddressSuccess = (resp: any, setFieldValue: any, currentType: any) => {
  return {
    type: courierActionsTypes.GET_MEl_ADDRESS_SUCCESS,
    payload: {
      resp,
      setFieldValue,
      currentType
    }
  }
}
export const getMelEmployeeAddressError = (error: any) => {
  return {
    type: courierActionsTypes.GET_MEl_ADDRESS_ERROR,
    payload: error
  }
}


//reset mel emloyee address

export const resetMelEmployeeAddress = () => {
  return {
    type: courierActionsTypes.RESET_MEL_EMPLOYEE_ADDRESS
  }
}


//download pdf in courier

export const donloadPdfCourierPageLoading = (id: any) => {
  return {
    type : courierActionsTypes.DOWNLOAD_PDF_COURIER_LOADING,
    payload : id
  }
}

export const donloadPdfCourierPageSuccess = (resp: any) => {
  return {
    type : courierActionsTypes.DOWNLOAD_PDF_COURIER_SUCCESS,
    payload : resp
  }
}

export const donloadPdfCourierPageError = (error: any) => {
  return {
    type : courierActionsTypes.DOWNLOAD_PDF_COURIER_ERROR,
    payload : error
  }
}


export const courierStatusTrackingLoading=(id : any)=>{
  return {
    type : courierActionsTypes.COURIER_TRACKING_STATUS_LOADING,
    payload : id
  }
}

export const courierStatusTrackingSuccess=(resp : any)=>{
  return {
    type : courierActionsTypes.COURIER_TRACKING_STATUS_SUCCESS,
    payload : resp
  }
}

export const courierStatusTrackingError=(error : any)=>{
  return {
    type : courierActionsTypes.COURIER_TRACKING_STATUS_ERROR,
    payload : error
  }
}