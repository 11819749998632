export enum ProfileTypes {
    GET_PROFILES = '@@profile/GET_PROFILES',
    GET_PROFILES_SUCCESS = '@@profile/GET_PROFILES_SUCCESS',
    GET_PROFILES_FAIL = '@@profile/GET_PROFILES_FAIL',
    // GET_INVOICE_DETAIL = '@@invoices/GET_INVOICE_DETAIL',
    // GET_INVOICE_DETAIL_SUCCESS = '@@invoices/GET_INVOICE_DETAIL_SUCCESS',
    // GET_INVOICE_DETAIL_FAIL = '@@invoices/GET_INVOICE_DETAIL_FAIL',

    // ADD_INVOICE_DETAIL = "ADD_INVOICE_DETAIL",
    // ADD_INVOICE_DETAIL_SUCCESS = "ADD_INVOICE_DETAIL_SUCCESS",
    // ADD_INVOICE_DETAIL_FAIL = "ADD_INVOICE_DETAIL_FAIL",

    // DELETE_INVOICE_DETAIL = "DELETE_INVOICE_DETAIL",
    // DELETE_INVOICE_DETAIL_SUCCESS = "DELETE_INVOICE_DETAIL_SUCCESS",
    // DELETE_INVOICE_DETAIL_FAIL = "DELETE_INVOICE_DETAIL_FAIL",

    UPDATE_PROFILE_DETAIL = "UPDATE_PROFILE_DETAIL",
    UPDATE_PROFILE_DETAIL_SUCCESS = "UPDATE_PROFILE_DETAIL_SUCCESS",
    UPDATE_PROFILE_DETAIL_FAIL = "UPDATE_PROFILE_DETAIL_FAIL",

    UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE",
    UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS",
    UPDATE_PROFILE_PICTURE_FAIL = "UPDATE_PROFILE_PICTURE_FAIL",

    GET_PROFILE_PICTURE = "GET_PROFILE_PICTURE",
    GET_PROFILE_PICTURE_SUCCESS = "GET_PROFILE_PICTURE_SUCCESS",
    GET_PROFILE_PICTURE_FAIL = "GET_PROFILE_PICTURE_FAIL",

    DELETE_PROFILE_PICTURE = "DELETE_PROFILE_PICTURE",
    DELETE_PROFILE_PICTURE_SUCCESS = "DELETE_PROFILE_PICTURE_SUCCESS",
    DELETE_PROFILE_PICTURE_FAIL = "DELETE_PROFILE_PICTURE_FAIL",

    CLEAR_PROFILE_MESSAGE="CLEAR_PROFILE_MESSAGE",

    SET_PROFILE_MOBILE_LOADING="SET_PROFILE_MOBILE_LOADING",
    SET_PROFILE_MOBILE_SUCCESS="SET_PROFILE_MOBILE_SUCCESS",
    SET_PROFILE_MOBILE_ERROR="SET_PROFILE_MOBILE_ERROR",
    
    SEND_OTP_PROFILE ="@@dealer/SEND_OTP_PROFILE",
    SEND_OTP_PROFILE_SUCCESS ="@@dealer/SEND_OTP_PROFILE_SUCCESS",
    SEND_OTP_PROFILE_ERROR ="@@dealer/SEND_OTP_PROFILE_ERROR",

    RESEND_PROFILE_OTP = "@@dealer/RESEND_PROFILE_OTP",
    RESEND_PROFILE_OTP_SUCCESS = "@@dealer/RESEND_PROFILE_OTP_SUCCESS",
    RESEND_PROFILE_OTP_ERROR = "@@dealer/RESEND_PROFILE_OTP_ERROR",
    
    RESET_PROFILE_OTP_MESSAGE = "@@dealer/RESET_PROFILE_OTP_MESSAGE",
}
