export enum AttendanceReportTypes {
    // Grid
    GET_ATTENDANCE_REPORT_LIST = "@@attendance_report/GET_ATTENDANCE_REPORT_LIST",
    GET_ATTENDANCE_REPORT_LIST_SUCCESS = "@@attendance_report/GET_ATTENDANCE_REPORT_LIST_SUCCESS",
    GET_ATTENDANCE_REPORT_LIST_FAIL = "@@attendance_report/GET_ATTENDANCE_REPORT_LIST_FAIL",

    // Employee Dropdown
    GET_EMPLOYEE_DROPDOWN_LIST = "@@attendance_report/GET_EMPLOYEE_DROPDOWN_LIST",
    GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS = "@@attendance_report/GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS",
    GET_EMPLOYEE_DROPDOWN_LIST_FAIL = "@@attendance_report/GET_EMPLOYEE_DROPDOWN_LIST_FAIL",

    // Franchise Dropdown
    GET_FRANCHISE_DROPDOWN_LIST = "@@attendance_report/GET_FRANCHISE_DROPDOWN_LIST",
    GET_FRANCHISE_DROPDOWN_LIST_SUCCESS = "@@attendance_report/GET_FRANCHISE_DROPDOWN_LIST_SUCCESS",
    GET_FRANCHISE_DROPDOWN_LIST_FAIL = "@@attendance_report/GET_FRANCHISE_DROPDOWN_LIST_FAIL",

    // Franchise Dropdown
    GET_FRANCHISE_SUBUSER_DROPDOWN_LIST = "@@attendance_report/GET_FRANCHISE_SUBUSER_DROPDOWN_LIST",
    GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS = "@@attendance_report/GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_SUCCESS",
    GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL = "@@attendance_report/GET_FRANCHISE_SUBUSER_DROPDOWN_LIST_FAIL",

    // Dealer Dropdown
    GET_DEALER_DROPDOWN_LIST = "@@attendance_report/GET_DEALER_DROPDOWN_LIST",
    GET_DEALER_DROPDOWN_LIST_SUCCESS = "@@attendance_report/GET_DEALER_DROPDOWN_LIST_SUCCESS",
    GET_DEALER_DROPDOWN_LIST_FAIL = "@@attendance_report/GET_DEALER_DROPDOWN_LIST_FAIL",

    // Dealer Subuser Dropdown
    GET_DEALER_SUBUSER_DROPDOWN_LIST = "@@attendance_report/GET_DEALER_SUBUSER_DROPDOWN_LIST",
    GET_DEALER_SUBUSER_DROPDOWN_LIST_SUCCESS = "@@attendance_report/GET_DEALER_SUBUSER_DROPDOWN_LIST_SUCCESS",
    GET_DEALER_SUBUSER_DROPDOWN_LIST_FAIL = "@@attendance_report/GET_DEALER_SUBUSER_DROPDOWN_LIST_FAIL",

    //clearmessage
    CLEAR_ATTENDANCE_REPORT_MESSAGE = "@@attendance_report/CLEAR_ATTENDANCE_REPORT_MESSAGE",
    RESET_ATTENDANCE_REPORT = "@@attendance_report/RESET_ATTENDANCE_REPORT",
}