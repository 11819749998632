
import {DealerSubUserType} from "./actiontypes"


//grid

export const getDealerSubuserListLoading = (lazyState: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_LIST_LOADING,
    lazyState,
  }
}

export const getDealerSubuserListSuccess = (dealersubuser: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_LIST_SUCCESS,
    payload: dealersubuser
  }
}

export const getDealerSubuserListError = (error: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_LIST_ERROR,
    payload: error
  }
}

//delete

export const deleteDealerSubuserLoading = (payload: any) => {
  return {
    type: DealerSubUserType.DELETE_DEALERSUBUSER_LOADING,
    payload: payload,
    
  }
}

export const deleteDealerSubuserSuccess = (response: any,undo:boolean) => {
  return {
    type: DealerSubUserType.DELETE_DEALERSUBUSER_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteDealerSubuserError = (error: any) => {
  return {
    type: DealerSubUserType.DELETE_DEALERSUBUSER_ERROR,
    payload: error
  }
}

//create Subuser

export const createDealerSubuserLoading = (dealersubuser: any) => {
  return {
    type: DealerSubUserType.CREATE_DEALERSUBUSER_LOADING,
    payload: dealersubuser,
    
  }
}

export const createDealerSubuserSucess = (dealersubuser : any) => {
  return {
    type: DealerSubUserType.CREATE_DEALERSUBUSER_SUCCESS,
    payload : dealersubuser
  }
}

export const createDealerSubuserError = (error: any) => {
  return {
    type: DealerSubUserType.CREATE_DEALERSUBUSER_ERROR,
    payload: error
  }
}

//update Subuser

export const updateDealerSubuserLoading = (dealersubuser: any,id : any) => {
  return {
    type: DealerSubUserType.UPDATE_DEALERSUBUSER_LOADING,
    payload: dealersubuser,
    id
  }
}

export const updateDealerSubuserSuccess = (dealersubuser : any) => {
  return {
    type: DealerSubUserType.UPDATE_DEALERSUBUSER_SUCCESS,
    payload : dealersubuser
  }
}

export const updateDealerSubuserError = (error: any) => {
  return {
    type: DealerSubUserType.UPDATE_DEALERSUBUSER_ERROR,
    payload: error
  }
}

//getSubuser detail by id

export const getDealerSubuserByIdLoading = (id: string) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSERBYID_LOADING,
    payload: id,
  }
}

export const getDealerSubuserByIdSuccess = (DealersubuserDetails: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSERBYID_SUCCESS,
    payload: DealersubuserDetails,
  };
};

export const getDealerSubuserByIdError = (error: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSERID_ERROR,
    payload: error,
  };
};

//reset

export const resetDealerSubuser = () => {
  return {
    type: DealerSubUserType.RESET_DEALERSUBUSER,
  }
}

export const clearErrorMessage = () => {
  return {
    type: DealerSubUserType.CLEAR_ERROR_MESSAGE_DEALERSUBUSER
  }
}

export const getDealerSubuserProfileLoading = (id: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_PROFILE_LOADING,
    id:id
  }
}

export const getDealerSubuserProfileSuccess = (profile: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_PROFILE_SUCCESS,
    payload: profile,
  };
};

export const getDealerSubuserProfileError = (error: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_PROFILE_ERROR,
    payload: error,
  };
};

export const uploadDealerSubuserProfileLoading = (file:any,id: string) => {
  return {
    type: DealerSubUserType.UPLOAD_DEALERSUBUSER_PROFILE_LOADING,
    payload: file,
    id:id
  }
}

export const uploadDealerSubuserProfileSuccess = (DealersubuserDetails: any) => {
  return {
    type: DealerSubUserType.UPLOAD_DEALERSUBUSER_PROFILE_SUCCESS,
    payload: DealersubuserDetails,
  };
};

export const uploadDealerSubuserProfileError = (error: any) => {
  return {
    type: DealerSubUserType.UPLOAD_DEALERSUBUSER_PROFILE_ERROR,
    payload: error,
  };
};

export const deleteDealerSubuserProfileLoading = (id: any) => {
  return {
    type: DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_LOADING,
    id:id
  }
}

export const deleteDealerSubuserProfileSuccess = (DealersubuserDetails: any) => {
  return {
    type: DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_SUCCESS,
    payload: DealersubuserDetails,
  };
};

export const deleteDealerSubuserProfileError = (error: any) => {
  return {
    type: DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_ERROR,
    payload: error,
  };
};

export const setDealerSubuserMobileNumberLoading = (number:any,id: any) => {
  return {
    type: DealerSubUserType.DEALERSUBUSER_SET_PHONE_LOADING,
    payload: {id:id,number:number},
  };
}
export const setDealerSubuserMobileNumberSuccess = (resp: any) => {
  return {
    type: DealerSubUserType.DEALERSUBUSER_SET_PHONE_SUCCESS,
    payload: resp,
  };
}
export const setDealerSubuserMobileNumberError = (error: any) => {
  return {
    type: DealerSubUserType.DEALERSUBUSER_SET_PHONE_ERROR,
    payload: error,
  };
}

export const setDealerSubuserEmailIdLoading = (email:any,id: any) => {
  return {
    type: DealerSubUserType.DEALERSUBUSER_SET_EMAIL_LOADING,
    payload: {id:id,email:email},
  };
}
export const setDealerSubuserEmailIdSuccess = (resp: any) => {
  return {
    type: DealerSubUserType.DEALERSUBUSER_SET_EMAIL_SUCCESS,
    payload: resp,
  };
}
export const setDealerSubuserEmailIdError = (error: any) => {
  return {
    type: DealerSubUserType.DEALERSUBUSER_SET_EMAIL_ERROR,
    payload: error,
  };
}

export const sendDealerSubuserOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
  console.log(id);
  
  return {
    type: DealerSubUserType.SEND_OTP_DEALERSUBUSER,
    payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
  };
}
export const sendDealerSubuserOtpSuccess = (resp: any) => {
  return {
    type: DealerSubUserType.SEND_OTP_DEALERSUBUSER_SUCCESS,
    payload: resp,
  };
}
export const sendDealerSubuserOtpError = (error: any) => {
  return {
    type: DealerSubUserType.SEND_OTP_DEALERSUBUSER_ERROR,
    payload: error,
  };
}
export const resendOtpDealerSubuser = (id: any,number:any) => {
  return {
    type: DealerSubUserType.RESEND_DEALERSUBUSER_OTP,
    payload: id,
    number:number
  };
}
export const resendOtpDealerSubuserSuccess = (resp: any) => {
  return {
    type: DealerSubUserType.RESEND_DEALERSUBUSER_OTP_SUCCESS,
    payload: resp,
  };
}
export const resendOtpDealerSubuserError = (resp: any) => {
  return {
    type: DealerSubUserType.RESEND_DEALERSUBUSER_OTP_ERROR,
    payload: resp,
  };
}

export const resetOtpDealerSubuserMessage = () => {
  return {
    type: DealerSubUserType.RESET_DEALERSUBUSER_OTP_MESSAGE,
  };
}

export const dealerSubuserLatLagLoading = (selectedArea: string) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const dealerSubuserLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const dealerSubuserLatLagError = (error: any) => {
  return {
    type: DealerSubUserType.GET_DEALERSUBUSER_LOCATION_ERROR,
    payload: error
  }
}