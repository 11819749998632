import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
} from "reactstrap";

import PropTypes from "prop-types";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-sm.svg";

//Import config
import config from "../../config";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { createSelector } from "reselect";
import { API } from "src/util/api";
import { AUTH_LOGIN_URL } from "src/util/auth/authConfig";
import { useToast } from "src/components/Common/ReactToaster";
import { OAuthService } from "src/util/auth/oauthService";

interface LoginProps {
  history: object;
}

const Login = (props: any) => {
  localStorage.removeItem("token");

  // useEffect(() => {
  //   let oauth = new OAuthService();
  //   oauth.createLoginUrl().then(data => {
  //     let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
  //     let culture = localStorage.getItem("i18nextLng");
  //     const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
  //     console.log(loginURL)
  //     authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL)
  //     window.open(authUrl, "_self")
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }, []);

  useEffect(() => {
    debugger
    let oauth = new OAuthService();
    oauth.logOut();
    if(!localStorage.getItem('PKCE_verifier')){
      oauth.createLoginUrl("","","",false,{},false,props.router.location.state.prevUrl).then(data => {      
        debugger;  
        localStorage.setItem("fronendUrl","")
        let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
        let culture = localStorage.getItem("i18nextLng");
        const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
        // console.log(loginURL)
        authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL);
        setTimeout(() => {
          window.open(authUrl, "_self");
        }, 500);
      }).catch(error => {
        console.log(error);
      });
    }else{
      console.log("PKCE_VERifier are there already in login")
    }


    // oauth.createLoginUrl().then(data => {
    //   let authUrl = AUTH_LOGIN_URL + "/connect/authorize";
    //   let culture = localStorage.getItem("i18nextLng");
    //   const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
    //   console.log(loginURL)
    //   authUrl = authUrl + loginURL
    //   console.log(String(authUrl))
     
    //   setTimeout(()=>{
    //     window.open(authUrl, "_self")
    //   },500)
    // }).catch(error => {
    //   console.log(error);
    // });
  }, []);


  return (
    <React.Fragment>

    </React.Fragment>
  );
};

export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};