import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FloatInput from 'src/components/Common/FloatInput';
import { JOB_RECEIVED_MODE } from 'src/constants/commonConstants';
import { CHECK_VALUE_IN_ARRAY } from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { courierDropdownForJobLoading, employeeDropdownForJobLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import TextEditor from 'src/components/atoms/TextEditor';

interface TabProps {
    activeTab: string | number;
    status: string;
    handleChange?: any;
    changeFlow: any;
    onBlur?: () => void;
    values: any;
    touch: any;
    error: any;
    validationConfiguration?: any;
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    accordion?: any;
    changeAccorditionHandler?: any;
}

const IncomingCourier = (props: TabProps) => {

    const dispatch = useDispatch()
    const { employeeList, courierList, jobsheet } = useSelector((state: RootReducerState) => state.jobsheetReducer);

    

    useEffect(() => {
        // dispatch(courierDropdownForJobLoading()) // old method call from job service
        dispatch(employeeDropdownForJobLoading())
    }, [])

    useEffect(() => {
        debugger
        if (jobsheet?.dealerId && !jobsheet?.customerId) {
            dispatch(courierDropdownForJobLoading(jobsheet?.dealerId))
        } else if (!jobsheet?.dealerId && jobsheet?.customerId) {
            dispatch(courierDropdownForJobLoading(jobsheet?.customerId))
        } else if (jobsheet?.dealerId && jobsheet?.customerId) {
            dispatch(courierDropdownForJobLoading(jobsheet?.dealerId))
        }
    }, [jobsheet])

    const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
        let data: any = {};
        data['value'] = eachEmployee.userId;
        data['label'] = eachEmployee.name;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : []

    // const courierDropList = courierList?.length > 0 ? (courierList.map((eachCourier: any) => {
    //     let data: any = {};
    //     data['value'] = eachCourier.id;
    //     data['label'] = eachCourier.courierMasterName == "Other" ? eachCourier.otherCompany : eachCourier.courierMasterName;
    //     return data;
    // })).sort((a: any, b: any) => {
    //     const labelA = a.label.toUpperCase().trim();
    //     const labelB = b.label.toUpperCase().trim();
    //     if (labelA < labelB) {
    //         return -1;
    //     }
    //     if (labelA > labelB) {
    //         return 1;
    //     }
    //     return 0;
    // }) : []

    const courierDropList = courierList?.length > 0 ? (courierList.map((eachCourier: any) => {
        let data: any = {};
        data['value'] = eachCourier.id;
         data['label'] = eachCourier.companyName == "Other" ? `${eachCourier.otherCompany} ${eachCourier?.packageReceiverName ? `courier :(${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" :eachCourier?.packageReceiverName  })` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}` : `${eachCourier.companyName} ${eachCourier?.packageReceiverName ? `courier : (${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" :eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}`
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : []

    return (
        <div className={`job-sheet-tab-content ${props.activeTab === "Incoming Courier" ? "" : "d-none"}`}>
            <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
                <div className='dealer-title-header font-size-15 border-bottom'>Incoming Courier Information</div>
                <CardBody className='p-0'>
                    <div className='px-3 pb-3'>
                        <Row>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    type="dropdown"
                                    name="jobReceivedMode"
                                    label="Job received mode"
                                    options={JOB_RECEIVED_MODE}
                                    onChange={(e) => {
                                        props.handleChange(e);
                                        props.values['jobReceivedMode'] = e.target.value;
                                        props.changeFlow(props.values, props.values?.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)

                                    }}
                                    // onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.jobReceivedMode}
                                    invalid={props.touch["jobReceivedMode"] && props.error["jobReceivedMode"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "jobReceivedMode")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "jobReceivedMode")}
                                />
                            </Col>

                            {/* {JSON.stringify(props.values.handCarriedPersonName)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "handCarriedPersonName") && <Col lg={3} md={6} className="mt-mb">  <FloatInput
                                label="Hand carried person name"
                                type="text"
                                name="handCarriedPersonName"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.handCarriedPersonName}
                                invalid={props.touch["handCarriedPersonName"] && props.error["handCarriedPersonName"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "handCarriedPersonName")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "handCarriedPersonName")}
                            />
                            </Col>
                            }



                            {/* {JSON.stringify(props.values.handCarriedPersonDesignation)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "handCarriedPersonDesignation") && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Hand carried person designation"
                                type="text"
                                name="handCarriedPersonDesignation"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.handCarriedPersonDesignation}
                                invalid={props.touch["handCarriedPersonDesignation"] && props.error["handCarriedPersonDesignation"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "handCarriedPersonDesignation")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "handCarriedPersonDesignation")}
                            />  </Col>}


                            {/* {JSON.stringify(props.values.courierReceivedAt)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "courierReceivedAt") && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier received at"
                                type="datetime"
                                name="courierReceivedAt"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.courierReceivedAt}
                                invalid={props.touch["courierReceivedAt"] && props.error["courierReceivedAt"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "courierReceivedAt")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "courierReceivedAt")}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierPackingCondition)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "courierPackingCondition") && <Col lg={3} md={6} className="mt-mb">  <FloatInput
                                label="Courier packing condition"
                                type="text"
                                name="courierPackingCondition"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.courierPackingCondition}
                                invalid={props.touch["courierPackingCondition"] && props.error["courierPackingCondition"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "courierPackingCondition")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "courierPackingCondition")}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierAmount)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "courierAmount") && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier amount"
                                type="number"
                                name="courierAmount"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.courierAmount}
                                invalid={props.touch["courierAmount"] && props.error["courierAmount"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "courierAmount")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "courierAmount")}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierChequeNumber)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "courierChequeNumber") && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier cheque number"
                                type="text"
                                name="courierChequeNumber"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.courierChequeNumber}
                                invalid={props.touch["courierChequeNumber"] && props.error["courierChequeNumber"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "courierChequeNumber")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "courierChequeNumber")}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierBankName)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "courierBankName") && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier bank name"
                                type="text"
                                name="courierBankName"
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.courierBankName}
                                invalid={props.touch["courierBankName"] && props.error["courierBankName"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "courierBankName")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "courierBankName")}
                            /></Col>}

                        </Row>
                        <Row className='mt-2'>
                            <div className='dealer-title-header border-bottom font-size-15'>
                                <span className='pull-left'>Incoming Dispatch</span>
                                {/* {!showIncomingDispatchAddPanel && (
                                    <div className="add-btn-container mt-0 pull-right" onClick={() => { setShowIncomingDispatchAddPanel(true) }}>
                                        <i className="fas fa-plus-circle"></i>
                                    </div>)} */}
                            </div>
                        </Row>
                        <Row id="customer-add-panel">
                            <Col lg={3} md={6} className="mt-mb">
                                {/* {JSON.stringify(props.values.incomingDispatchDto_receivedDate)} */}
                                <FloatInput
                                    label="Received date"
                                    type="date"
                                    name="incomingDispatchDto_receivedDate"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.incomingDispatchDto_receivedDate}
                                    invalid={props.touch["incomingDispatchDto_receivedDate"] && props.error["incomingDispatchDto_receivedDate"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "incomingDispatchDto_receivedDate")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "incomingDispatchDto_receivedDate")}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                {/* {JSON.stringify(props.values.incomingDispatchDto_receivedId)}
                            {JSON.stringify(employeeDropList)} */}
                                <FloatInput
                                    type="dropdown"
                                    name="incomingDispatchDto_receivedId"
                                    label="Received by"
                                    options={employeeDropList}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={props.values.incomingDispatchDto_receivedId}
                                    invalid={props.touch["incomingDispatchDto_receivedId"] && props.error["incomingDispatchDto_receivedId"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "incomingDispatchDto_receivedId")}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "incomingDispatchDto_receivedId")}
                                />
                            </Col>

                            {/* {JSON.stringify(props.values.incomingDispatchDto_courierId)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], "incomingDispatchDto_courierId") && <Col lg={3} md={6} className="mt-mb">  <FloatInput
                                type="dropdown"
                                name="incomingDispatchDto_courierId"
                                label="Courier"
                                options={courierDropList}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={props.values.incomingDispatchDto_courierId}
                                invalid={props.touch["incomingDispatchDto_courierId"] && props.error["incomingDispatchDto_courierId"] ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "incomingDispatchDto_courierId")}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "incomingDispatchDto_courierId")}
                            />  </Col>}

                            <Col lg={3} md={6} className="mt-mb-textarea">
                                {/* {JSON.stringify(props.values.incomingDispatchDto_notes)} */}
                                <div className="d-flex align-items-start">
                                    <div className="d-block  w-100">
                                        {/* <FloatInput
                                            label="Notes"
                                            type="textarea"
                                            name="incomingDispatchDto_notes"
                                            onChange={props.handleChange}
                                            onBlur={props.onBlur}
                                            value={props.values.incomingDispatchDto_notes}
                                            invalid={props.touch["incomingDispatchDto_notes"] && props.error["incomingDispatchDto_notes"] ? true : false}
                                            required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "incomingDispatchDto_notes")}
                                            disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "incomingDispatchDto_notes")}
                                        /> */}

                                        <TextEditor
                                        onChange={props.handleChange}
                                        labelName='Notes'
                                        name='incomingDispatchDto_notes'
                                        required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "incomingDispatchDto_notes") ? true : false}
                                        value={props.values.incomingDispatchDto_notes}
                                        invalid={props.touch["incomingDispatchDto_notes"] && props.error["incomingDispatchDto_notes"] ? true : false}
                                        setFieldTouched={props.setFieldTouched}
                                        setFieldValue={props.setFieldValue}
                                        onBlur={props.onBlur}
                                        readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "incomingDispatchDto_notes") ? true : false}
                                        />
                                    </div>
                                    {/* <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                        <TooltipMEL title="Remove this Part" position="left">
                                            <i className="fas fa-times-circle" onClick={() => { setShowIncomingDispatchAddPanel(false) }}></i>
                                        </TooltipMEL>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default IncomingCourier