import React from 'react';

interface InfoPropsTypes {
  onClick?: () => void;
}

const Info = ({ onClick }: InfoPropsTypes) => {
  return (
    <div className='pointer'>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info" onClick={onClick}>
        <circle cx="12" cy="12" r="10" style={{ color: '#8b8787' }} />
        <line x1="12" y1="16" x2="12" y2="12" style={{ color: '#126ddc' }} />
        <line x1="12" y1="8" x2="12.01" y2="8" style={{ color: '#126ddc' }} />
      </svg>
    </div>
  );
};

export default Info;
