import React from 'react';
import { Rating } from 'primereact/rating';
import { InputProps, Label } from "reactstrap";
import { ErrorMessage } from "formik";
import ErrorText from "../UI/ErrorText";

interface RatingComponentProbs {
    handleChange: any,
    value: any,
    label:string,
    stars:number,
    isRequired:boolean,
    name:string,
    error?: string,
    
}

function RatingComponent({ handleChange, value, label,stars,isRequired,name,error="" }: RatingComponentProbs) {
    return (
        <>
            <Label htmlFor={"rating"} >{label} {isRequired && <span className="text-danger">*</span>} </Label>
            <Rating name={name} id={"rating"} value={value} onChange={handleChange} stars={stars} />
             <ErrorMessage name={name || error || ''} component={ErrorText} />
        </>
    )
}

export default RatingComponent