import React from "react";
import { Link } from "react-router-dom";


interface TableAction {
  cellProps: any;
}

const ActionTable = ({ cellProps }: TableAction) => {
    
  const handleEdit = (data: any) => {
    console.log("data", data);
  };

  const handleDelete = (data: any) => {
    console.log("data", data);
  };

  return (
    <div className="d-flex gap-3">
      <Link className="text-success" to="#">
        <i
          className="mdi mdi-pencil font-size-18"
          id="edittooltip"
          onClick={() => {
            const userData = cellProps.row.original;
            handleEdit(userData);
          }}
        ></i>
      </Link>
      <Link className="text-danger" to="#">
        <i
          className="mdi mdi-delete font-size-18"
          id="deletetooltip"
          onClick={() => {
            const userData = cellProps.row.original;
            handleDelete(userData);
          }}
        ></i>
      </Link>
    </div>
  );
};

export default ActionTable;
