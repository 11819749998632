import { ErrorMessage } from 'formik';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import React from 'react';
import { Option } from 'src/models/components/inputWrapperModel';
import ErrorText from '../UI/ErrorText';
import { Label } from 'reactstrap';
import './MultiSelector.scss';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';


interface MultiSelectProps {
  label: string;
  options: any[];
  onChange?: (event: MultiSelectChangeEvent) => {}|any;
  onBlur?: (event: any) => {};
  name: string;
  value: string;
  invalid: boolean;
  isRequired?: boolean;
  id: string;
  labelClassName?: string;
  optionLabel: string;
  optionValue?: string;
}

const MultiSelector = (props: MultiSelectProps) => {
  const { options, name, label, isRequired, id, labelClassName, invalid,optionLabel,optionValue, onChange, onBlur, ...rest } = props;
  return (
    <div id="multi-selector" onBlur={onBlur}>
      <Label htmlFor={id} className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <br />
      <MultiSelect resetFilterOnHide={true} options={options} optionLabel={optionLabel} display="comma" optionValue={optionValue}
        placeholder={label} maxSelectedLabels={3} filter className={invalid ? "form-control d-flex p-invalid" : "form-control d-flex"} style={invalid ? { padding: 0, borderColor: '#fd625e' } : { padding: 0 }} onBlur={onBlur} onChange={onChange} id={id} {...rest}
        dropdownIcon={(opts: any) => {
          return opts.iconProps['data-pr-overlay-visible'] ? <ChevronUpIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
        }}
      />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default MultiSelector;
