import { AudioModelsTypes } from "./actionTypes";

// For Grid

export const getAudioModelsList = (lazyState: any) => {
  return {
    type: AudioModelsTypes.GET_AUDIO_MODELS_LIST_LOADING,
    lazyState,
  };
};

export const getAudioModelsListSuccess = (administrators: any) => {
  return {
    type: AudioModelsTypes.GET_AUDIO_MODELS_LIST_SUCCESS,
    payload: administrators,
  };
};

export const getAudioModelsListError = (error: any) => {
  return {
    type: AudioModelsTypes.GET_AUDIO_MODELS_LIST_FAIL,
    payload: error,
  };
};


//delete audio model
export const deleteAudioModelsFromList = (payload: any) => {
  return {
    type: AudioModelsTypes.DELETE_AUDIO_MODELS_LOADING,
    payload: payload,
  }
}

export const deleteAudioModelsFromListSuccess = (response: any,undo:boolean) => {
  return {
    type: AudioModelsTypes.DELETE_AUDIO_MODELS_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteAudioModelsFromListError = (error: any) => {
  return {
    type: AudioModelsTypes.DELETE_AUDIO_MODELS_FAIL,
    payload: error,
  }
}


//create audio model

export const createAudioModelPending = (audioModel: any) => {
  return {
    type: AudioModelsTypes.CREATE_AUDIO_MODELS_PENDING,
    payload: audioModel
  }
}

export const createAudioModelSuccess = (audioModel : any) => {
  return {
    type: AudioModelsTypes.CREATE_AUDIO_MODELS_SUCCESS,
    payload : audioModel
  }
}

export const createAudioModelError = (error: any) => {
  return {
    type: AudioModelsTypes.CREATE_AUDIO_MODELS_ERROR,
    payload: error
  }
}

//update audio model

export const updateAudioModelPending = (audioModel: any,id : any) => {
  return {
    type: AudioModelsTypes.UPDATE_AUDIO_MODELS_PENDING,
    payload: audioModel,
    id
  }
}

export const updateAudioModelSuccess = (audioModel : any) => {
  return {
    type: AudioModelsTypes.UPDATE_AUDIO_MODELS_SUCCESS,
    payload : audioModel
  }
}

export const updateAudioModelError = (error: any) => {
  return {
    type: AudioModelsTypes.UPDATE_AUDIO_MODELS_ERROR,
    payload: error
  }
}

//getByID AudioModel

export const getByIdAudioModelPending = (id: any) => {
  return {
    type : AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_PENDING,
    payload : id
  }
}

export const getByIdAudioModelSuccess=(audioModel : any)=>{
  return {
    type : AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_SUCCESS,
    payload : audioModel
  }
}

export const getByIdAudioModelError=(error : any)=>{
  return {
    type : AudioModelsTypes.GET_BY_ID_AUDIO_MODEL_ERROR,
    payload : error
  }
}

//reset Audio Model

export const resetAudioModelState=()=>{
  return {
    type : AudioModelsTypes.RESET_AUDIO_MODEL
  }
}


//get audio brand Name list
export const getAudioBrandDropDownListLoading=()=>{
  return {
    type : AudioModelsTypes.GET_AUDIO_BRAND_NAMES_LOADING,
  }
}
export const getAudioBrandDropDownListSuccess=(audioBrand : any)=>{
  return {
    type : AudioModelsTypes.GET_AUDIO_BRAND_NAMES_SUCCESS,
    payload : audioBrand
  }
}
export const getAudioBrandDropDownListError=(error : any)=>{
  return {
    type : AudioModelsTypes.GET_AUDIO_BRAND_NAMES_ERROR,
    payload : error
  }
}


export const getAudioModelAutoComplete = (payload: any) => {
  return {
    type: AudioModelsTypes.GET_AUDIO_MODEL_AUTOCOMPLETE,
    params:payload,
  };
};



export const getAudioModelAutoCompleteSuccess = (payload: any) => {
  console.log(payload);
  
  return {
    type: AudioModelsTypes.GET_AUDIO_MODEL_AUTOCOMPLETE_SUCCESS,
    payload: payload,
  };
};

export const getAudioModelAutoCompleteError = (error: any) => {
  return {
    type: AudioModelsTypes.GET_AUDIO_MODEL_AUTOCOMPLETE_FAIL,
    payload: error,
  };
};
