export enum InvoiceItemWiseRegistersTypes{

    // Grid
    GET_INVOICE_ITEM_WISE_REGISTERS_LIST = "@@invoiceItemWiseRegisters/GET_INVOICE_ITEM_WISE_REGISTERS_LIST",
    GET_INVOICE_ITEM_WISE_REGISTERS_LIST_SUCCESS = "@@invoiceItemWiseRegisters/GET_INVOICE_ITEM_WISE_REGISTERS_LIST_SUCCESS",
    GET_INVOICE_ITEM_WISE_REGISTERS_LIST_FAIL = "@@invoiceItemWiseRegisters/GET_INVOICE_ITEM_WISE_REGISTERS_LIST_FAIL",

    RESET_INVOICE_ITEM_WISE_REGISTERS_LIST = "@@invoiceItemWiseRegisters/RESET_INVOICE_ITEM_WISE_REGISTERS_LIST",

    GET_GST_NO_LIST = "@@invoiceItemWiseRegisters/GET_GST_NO_LIST",
    GET_GST_NO_LIST_SUCCESS = "@@invoiceItemWiseRegisters/GET_GST_NO_LIST_SUCCESS",
    GET_GST_NO_LIST_FAIL = "@@invoiceItemWiseRegisters/GET_GST_NO_LIST_FAIL",
  }
  