import DateInput from "./DateInput"
import MultiSelectInput from "./MultiSelectInput"
import DropdownInput, { OptionsModal } from "./DropdownInput"
import NumberInput from "./NumberInput"
import RadioInput from "./RadioInput"
import TextAreaInput from "./TextAreaInput"
import TextInput from "./TextInput"
import CheckboxInput from "./CheckboxInput"
import React from "react"
import CheckboxGroup from "./CheckboxGroup"

interface FormFieldProps {
  name: string
  label: string
  value: any
}

interface InputProps extends FormFieldProps {
  control: string
  options?: OptionsModal[]
  type?: string
  onChange?: any
  required?: boolean
  selectionMode?: string
  placeholder?:string
  disabled?:boolean
}

const InputController = (props: InputProps) => {
  const { control, options, type, label, name, value, ...rest } = props
  
  switch (control) {
    case "text":
      return <TextInput type={`${type}` || "text"} name={name} label={label} value={value} {...rest} />
    case "checkbox":
      return <CheckboxInput name={name} label={label} value={value} {...rest} />
    case "checkboxGroup":
      // return <CheckboxGroup name={name} label={label} value={value} options={options || []} {...rest}/>
    case "date":
      return <DateInput name={name} label={label} value={value} {...rest} />
    case "dropdown":
      // return <DropdownInput value={value} name={name} label={label} options={options || []} {...rest} />
    case "multiselect":
      return <MultiSelectInput label={label} name={name} options={options || []} {...rest} />
    case "number":
      return <NumberInput label={label} name={name} value={0}  {...rest} />
    case "radio":
      // return <RadioInput options={options} name={name} label={label} value={value} {...rest} />
    case "textarea":
      return <TextAreaInput name={name} label={label} value={value} />
    default:
      return <div>
        <p>Unknown input element passed with control {control}</p>
      </div>
  }
};

export default InputController;
