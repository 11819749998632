import React from 'react';
import FeatherIcon from "feather-icons-react";
import Button from '../UI/Button';
import { useLocation, useNavigate } from 'react-router';
import { menu } from 'src/constants/sideMenu';
import { getAdminRelatedActivePaths, getDealerRelatedActivePaths } from 'src/helpers/common_helpers';
import { UserTypes } from 'src/constants/userRoles';
import { ROUTER } from 'src/constants/routes';




const AccessDeniedPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const roleItem = localStorage.getItem("role");
    const roles = roleItem ? JSON.parse(roleItem) : null;
    const frontEndUrl: any = localStorage.getItem("fronendUrl")
    const adminActivePath = getAdminRelatedActivePaths(menu);
    const dealerActivePath = getDealerRelatedActivePaths(menu)

    const redirectProperPage = () => {
        debugger
        if (roles.includes('Dealer')) {
            // return navigate(ROUTER.DEALER_SUB_USER_GRID);
        } else if (roles.includes(UserTypes[UserTypes['Franchise']])) {
            // return navigate(ROUTER.SUB_USER_GRID);
        } else if (roles.includes(UserTypes[UserTypes['FranchiseSubUser']]) || roles.includes(UserTypes[UserTypes['DealerSubUser']])) {
            // return navigate(ROUTER.ATTENDANCE);
        } else if (roles.includes(UserTypes[UserTypes['Customer']])) {
            // return navigate(ROUTER.JOB_SHEETS_GRID);
        } else {
            if (adminActivePath.includes(frontEndUrl)) {
                return navigate(frontEndUrl)
            } else {
                return navigate(ROUTER.USERS_GRID)
            }
        }
    }
    return (
        <React.Fragment>
            <div className="page-content" id="ticket-system">
                <div className='AccessDeniedPages' id='AccessDeniedPages'>
                    <div className='Accesss_Denied_icon'>
                        <FeatherIcon icon="lock" size={200} />
                    </div>
                    <div className='ACC_letter1'>Access Restricted</div>
                    <div className='ACC_letter2'>You don't have a permission to view the page</div>
                    <div className='redirect_page'>
                        <Button
                            className="btn-primary me-2"
                            label={"Redirect"}
                            onClick={redirectProperPage}
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccessDeniedPage