import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { StockQtybyPartTypes } from "./actionTypes";
import { API } from "src/util/api";
import {  getPartsDropdownListErrorQty,  getPartsDropdownListSuccessQty, getStockQtybyPartListError, getStockQtybyPartListSuccess } from "./action";
import { parts } from "src/models/pages/partsModel";



function* getStockQtybyPartListAsync(action: any) {

  try {
    const response: Promise<any> = yield call(API.getStockQtybyPartListAPI,  { ...action.lazyState})
    yield put(getStockQtybyPartListSuccess(response));
  } catch (error) {
    yield put(getStockQtybyPartListError(error));
  }
}

function* getPartListDropdownAsync(action: any) {
  debugger
    try {
      const response: Promise<any> = yield call(API.getPartsDropdownApi)
      console.log("action.payload.data",response);    
      yield put(getPartsDropdownListSuccessQty(response));
    } catch (error) {
      yield put(getPartsDropdownListErrorQty(error));
    }
  }
  


function* stockQtybyPartSaga() {
  yield takeLatest(StockQtybyPartTypes.GET_STOCK_QTY_BY_PART_LIST, getStockQtybyPartListAsync);
  yield takeLatest(StockQtybyPartTypes.GET_PART_LIST_LOADING, getPartListDropdownAsync);
}

export default stockQtybyPartSaga;