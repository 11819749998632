import { ErrorMessage } from "formik";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import ErrorText from "./ErrorText";
import React from "react";

interface CheckboxProps {
  name: string;
  label: string;
  onChange?: (event: CheckboxChangeEvent) => void;
  value: string;
  required?: boolean;
  disabled?: boolean | any;
  className?: string;
}

const CheckboxInput = (props: CheckboxProps) => {

  const { label, name, value, disabled = false, onChange, className, ...rest } = props;
  return (
    <>
      {/* <br /> */}
      <div className={`d-flex align-items-center ${className ? className : "mt-2"}`}>
        <Checkbox disabled={disabled} inputId={name} id={name} name={name} checked={value ? true : false} onChange={onChange} />
        <label htmlFor={name} className={`custom-checkbox-label ${disabled ? 'checkbox-disable' : ''}`} >{label}</label>
      </div>
    </>
  );
};

export default CheckboxInput;
