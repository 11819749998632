export enum StockRegisterTypes {
    // Grid
    GET_STOCK_REGISTER_LIST = "@@stock_register/GET_STOCK_REGISTER_LIST",
    GET_STOCK_REGISTER_LIST_SUCCESS = "@@stock_register/GET_STOCK_REGISTER_LIST_SUCCESS",
    GET_STOCK_REGISTER_LIST_FAIL = "@@stock_register/GET_STOCK_REGISTER_LIST_FAIL",
    GET_STOCK_REGISTER_BY_ID="@@stock_register/GET_STOCK_REGISTER_BY_ID",
    GET_STOCK_REGISTER_BY_ID_SUCCESS="@@stock_register/GET_STOCK_REGISTER_BY_ID_SUCCESS",
  
    DELETE_STOCK_REGISTER = "@@stock_register/DELETE_STOCK_REGISTER",
    DELETE_STOCK_REGISTER_SUCCESS = "@@stock_register/DELETE_STOCK_REGISTER_SUCCESS",
    DELETE_STOCK_REGISTER_FAIL = "@@stock_register/DELETE_STOCK_REGISTER_FAIL",
    // //reset
    RESET_STOCK_REGISTER = "@@stock_register/RESET_STOCK_REGISTER",
   
    // //update STOCK_REGISTER
    UPDATE_STOCK_REGISTER = "@@stock_register/UPDATE_STOCK_REGISTER",
    UPDATE_STOCK_REGISTER_SUCCESS = "@@stock_register/UPDATE_STOCK_REGISTER_SUCCESS",
    UPDATE_STOCK_REGISTER_ERROR = "@@stock_register/UPDATE_STOCK_REGISTER_ERROR",

    //create
    CREATE_STOCK_REGISTER = "@@stock_register/CREATE_STOCK_REGISTER",
    CREATE_STOCK_REGISTER_SUCCESS = "@@stock_register/CREATE_STOCK_REGISTER_SUCCESS",
    CREATE_STOCK_REGISTER_ERROR = "@@stock_register/CREATE_STOCK_REGISTER_ERROR",
    
  }