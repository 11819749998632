import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { UserTypes } from "./actionTypes";
import { CustomerService } from "src/components/Grid/CustomerService";
import { CreateMelUserError, CreateMelUserSuccess, GetMeluserDetailsError, GetMeluserDetailsSuccess, UpdateMelUserError, UpdateMelUserSuccess, deleteEmployeeDocumentError, deleteEmployeeDocumentSuccess, deleteEmployeeImageError, deleteEmployeeImageSuccess, deleteUserFromListError, deleteUserFromListSuccess, employeeDocumentGetError, employeeDocumentGetSucces, employeeDocumentUploadError, employeeDocumentUploadSuccess, employeeLatLagError, employeeLatLagSuccess, generateEmployeeDocumentLinkError, generateEmployeeDocumentLinkSuccess, getEmployeeImageError, getEmployeeImageSuccess, getUsersList, getUsersListError, getUsersListSuccess, resendOtpEmployeeError, resendOtpEmployeeSuccess, sendEmployeeOtpError, sendEmployeeOtpSuccess, setEmployeeEmailIdError, setEmployeeEmailIdSuccess, setEmployeeMobileNumberError, setEmployeeMobileNumberSuccess, uploadEmployeeFileError, uploadEmployeeFileSuccess } from "./action";
import { API } from "src/util/api";
import { UserData } from "src/models/pages/userModel";

//worker function
function* getUserAsyncLoading({ payload }: any) {
  try {
    const response: UserData = yield call(API.getIndividualUser, payload);
    yield put(GetMeluserDetailsSuccess(response.data));
  } catch (error) {
    yield put(GetMeluserDetailsError(error));
  }
}

function* getUserListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getUsersLists, { ...action.lazyState });
    const { data }: any = response;
    yield put(getUsersListSuccess(data));
  } catch (error: any) {
    yield put(getUsersListError(error));
  }
}

function* deleteUserAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteUser, { id: action.payload.id, undo: action.payload.undo });
    yield put(deleteUserFromListSuccess(response, action.payload.undo));
    // yield put(getUsersList(action.lazyState));
  } catch (error) {
    yield put(deleteUserFromListError(error));
  }
}


function* CreateUserStartAsync({ payload }: any) {
  try {
    const response: UserData = yield call(API.createMelUser, payload);
    if (response.raw.status == 200) {
      // const createdUser = { id: response.data.id };
      yield put(CreateMelUserSuccess(response));
    }
  } catch (error) {
    yield put(CreateMelUserError(error));
  }
}


function* UpdateUserStartAsync({
  payload: { employeeDetails, id }
}: any
) {
  try {
    const response: UserData = yield call(API.updateMelUser, {
      id: id,
      employeeDetails,
    });
    yield put(UpdateMelUserSuccess());
  } catch (error) {
    yield put(UpdateMelUserError(error));
  }
}

//  -----------------------

function* getEmployeeImageAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.fetchEmployeeImage, payload)
    yield put(getEmployeeImageSuccess(response));
  } catch (error) {
    yield put(getEmployeeImageError(error));
  }
}

function* getDocumentEmployeeAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.fetchEmployeedocumentApi, payload)
    yield put(employeeDocumentGetSucces(response));
  } catch (error) {
    yield put(employeeDocumentGetError(error));
  }
}

function* deleteEmployeeImageAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.deleteEmployeeImage, payload)
    yield put(deleteEmployeeImageSuccess(response));
  } catch (error) {
    yield put(deleteEmployeeImageError(error));
  }
}

function* deleteDocumentEmployeeAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.deleteEmployeeDocumentApi, payload)
    yield put(deleteEmployeeDocumentSuccess(response));
  } catch (error) {
    yield put(deleteEmployeeDocumentError(error));
  }
}

//update customer
function* uploadEmployeeFileAsync(action: any) {
  try {

    const response: Promise<any> = yield call(API.uploadEmployeeImage, action.payload, { id: action.id });
    yield put(uploadEmployeeFileSuccess(response))

  } catch (error) {
    yield put(uploadEmployeeFileError(error))
  }
}

function* uploadDocumentEmployeeAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.uploadEmployeeDoumentApi, action.payload, { id: action.id,file : action.filetype});
    yield put(employeeDocumentUploadSuccess(response))
  } catch (error) {
    yield put(employeeDocumentUploadError(error))
  }
}
function* generateDocumentLinkEmployeeAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.generateEmployeeDoumentLinkApi, { id: action.payload });
    yield put(generateEmployeeDocumentLinkSuccess(response))
  } catch (error) {
    yield put(generateEmployeeDocumentLinkError(error))
  }
}


function* setEmployeeMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setEmployeeMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setEmployeeMobileNumberError(error))
  }
}

function* setEmployeeEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setEmployeeEmailApi, action.payload.id,action.payload.email);
    yield put(setEmployeeEmailIdSuccess(response))
  } catch (error) {
    yield put(setEmployeeEmailIdError(error))
  }
}


function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendEmployeeOtpSuccess(response))
  } catch (error) {
    yield put(sendEmployeeOtpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpEmployeeSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpEmployeeError(error))
  }
}

function* getlatLngAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(employeeLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(employeeLatLagError(error));
  }
}

function* melUserSaga() {
  yield takeLatest(UserTypes.GET_USERS_LIST, getUserListAsync);
  yield takeLatest(UserTypes.DELETE_USER, deleteUserAsync);
  yield takeEvery(UserTypes.GET_USERLOADING, getUserAsyncLoading);
  yield takeLatest(UserTypes.CREATE_USER_LOADING, CreateUserStartAsync);
  yield takeLatest(UserTypes.UPDATE_USER_LOADING, UpdateUserStartAsync);
  yield takeLatest(UserTypes.EMPLOYEE_FILE_UPLOAD_LOADING, uploadEmployeeFileAsync);
  yield takeLatest(UserTypes.EMPLOYEE_FILE_FETCH_LOADING, getEmployeeImageAsync);
  yield takeLatest(UserTypes.EMPLOYEE_FILE_DELETE_LOADING, deleteEmployeeImageAsync);
  yield takeLatest(UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_LOADING, uploadDocumentEmployeeAsync);
  yield takeLatest(UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING, getDocumentEmployeeAsync);
  yield takeLatest(UserTypes.EMPLOYEE_DELETE_DOCUMENT_LOADING, deleteDocumentEmployeeAsync);
  yield takeLatest(UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING, generateDocumentLinkEmployeeAsync);
  yield takeLatest(UserTypes.EMPLOYEE_SET_PHONE_LOADING, setEmployeeMobileAsync);
  yield takeLatest(UserTypes.EMPLOYEE_SET_EMAIL_LOADING, setEmployeeEmailAsync);
  yield takeLatest(UserTypes.SEND_OTP_EMPLOYEE, sendOtpAsync);
  yield takeLatest(UserTypes.RESEND_EMPLOYEE_OTP, resendOtpAsync);
  yield takeLatest(UserTypes.GET_EMPLOYEE_LOCATION_LOADING, getlatLngAsync);

}

export default melUserSaga;