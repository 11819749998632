export enum BulkEmailTypes {

    GET_BULK_EMAIL_LIST_LOADING = "@@bulkEmail/GET_BULK_EMAIL_LIST_LOADING",
    GET_BULK_EMAIL_LIST_SUCCESS = "@@bulkEmail/GET_BULK_EMAIL_LIST_SUCCESS",
    GET_BULK_EMAIL_LIST_ERROR = "@@bulkEmail/GET_BULK_EMAIL_LIST_ERROR",

    GET_EMPLOYEE_EMAIL_LOADING = "@@bulkEmail/GET_EMPLOYEE_EMAIL_LOADING",
    GET_EMPLOYEE_EMAIL_SUCCESS = "@@bulkEmail/GET_EMPLOYEE_EMAIL_SUCCESS",
    GET_EMPLOYEE_EMAIL_ERROR = "@@bulkEmail/GET_EMPLOYEE_EMAIL_ERROR",

    GET_DEALER_EMAIL_LOADING = "@@bulkEmail/GET_DEALER_EMAIL_LOADING",
    GET_DEALER_EMAIL_SUCCESS = "@@bulkEmail/GET_DEALER_EMAIL_SUCCESS",
    GET_DEALER_EMAIL_ERROR = "@@bulkEmail/GET_DEALER_EMAIL_ERROR",

    GET_CUSTOMER_EMAIL_LOADING = "@@bulkEmail/GET_CUSTOMER_EMAIL_LOADING",
    GET_CUSTOMER_EMAIL_SUCCESS = "@@bulkEmail/GET_CUSTOMER_EMAIL_SUCCESS",
    GET_CUSTOMER_EMAIL_ERROR = "@@bulkEmail/GET_CUSTOMER_EMAIL_ERROR",

    GET_FRANCHISE_EMAIL_LOADING = "@@bulkEmail/GET_FRANCHISE_EMAIL_LOADING",
    GET_FRANCHISE_EMAIL_SUCCESS = "@@bulkEmail/GET_FRANCHISE_EMAIL_SUCCESS",
    GET_FRANCHISE_EMAIL_ERROR = "@@bulkEmail/GET_FRANCHISE_EMAIL_ERROR",

    GET_DEALER_SUBUSER_EMAIL_LOADING = "@@bulkEmail/GET_DEALER_SUBUSER_EMAIL_LOADING",
    GET_DEALER_SUBUSER_EMAIL_SUCCESS = "@@bulkEmail/GET_DEALER_SUBUSER_EMAIL_SUCCESS",
    GET_DEALER_SUBUSER_EMAIL_ERROR = "@@bulkEmail/GET_DEALER_SUBUSER_EMAIL_ERROR",

    GET_FRANCHISE_SUBUSER_EMAIL_LOADING = "@@bulkEmail/GET_FRANCHISE_SUBUSER_EMAIL_LOADING",
    GET_FRANCHISE_SUBUSER_EMAIL_SUCCESS = "@@bulkEmail/GET_FRANCHISE_SUBUSER_EMAIL_SUCCESS",
    GET_FRANCHISE_SUBUSER_EMAIL_ERROR = "@@bulkEmail/GET_FRANCHISE_SUBUSER_EMAIL_ERROR",

    GET_SUPPLIER_EMAIL_LOADING = "@@bulkEmail/GET_SUPPLIER_EMAIL_LOADING",
    GET_SUPPLIER_EMAIL_SUCCESS = "@@bulkEmail/GET_SUPPLIER_EMAIL_SUCCESS",
    GET_SUPPLIER_EMAIL_ERROR = "@@bulkEmail/GET_SUPPLIER_EMAIL_ERROR",

    CREATE_BULK_EMAIL_LOADING = "@@bulkEmail/CREATE_BULK_EMAIL_LOADING",
    CREATE_BULK_EMAIL_SUCCESS = "@@bulkEmail/CREATE_BULK_EMAIL_SUCCESS",
    CREATE_BULK_EMAIL_ERROR = "@@bulkEmail/CREATE_BULK_EMAIL_ERROR",

    DELETE_BULK_EMAIL_LOADING = "@@bulkEmail/DELETE_BULK_EMAIL_LOADING",
    DELETE_BULK_EMAIL_SUCCESS = "@@bulkEmail/DELETE_BULK_EMAIL_SUCCESS",
    DELETE_BULK_EMAIL_ERROR = "@@bulkEmail/DELETE_BULK_EMAIL_ERROR",

    GET_BY_ID_BULK_EMAIL_LOADING = "@@bulkEmail/GET_BY_ID_BULK_EMAIL_LOADING",
    GET_BY_ID_BULK_EMAIL_SUCCESS = "@@bulkEmail/GET_BY_ID_BULK_EMAIL_SUCCESS",
    GET_BY_ID_BULK_EMAIL_ERROR = "@@bulkEmail/GET_BY_ID_BULK_EMAIL_ERROR",

    GET_BULK_EMAILSTATUS_LOADING = "@@bulkEmail/GET_BULK_EMAILSTATUS_LOADING",
    GET_BULK_EMAILSTATUS_SUCCESS = "@@bulkEmail/GET_BULK_EMAISTATUSL_SUCCESS",
    GET_BULK_EMAILSTATUS_ERROR = "@@bulkEmail/GET_BULK_EMAILSTATUS_ERROR",
    
    CLEAR_BULK_EMAIL_MESSAGE = "@@bulkEmail/CLEAR_BULK_EMAIL_MESSAGE",
}