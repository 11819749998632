import { call, put, takeLatest } from "redux-saga/effects";
import { AttendanceTypes } from "./actionTypes";
import { API } from "src/util/api";
import { createAttendanceSuccess, createAttendanceError, getAttendanceBYIDList, getAttendanceBYIDListSuccess, getAttendanceBYIDListError, updateAttendanceError, updateAttendanceSuccess, getAttendanceListSuccess, getAttendanceListError, getUserCurrentAttendanceSuccess, getUserCurrentAttendanceError, getUserWorkingHoursSuccess, getUserWorkingHoursError, reloadUserWorkingHours, getAttendanceDetailByIdSuccess, getAttendanceDetailByIdError } from "./action";
import { attendance } from "src/models/pages/attendanceModel";

//grid

function* getAttendanceListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getAttendanceListAPI, {
      ...action.lazyState,
    }
    )
    yield put(reloadUserWorkingHours())
    yield put(getAttendanceListSuccess(response));
  } catch (error) {
    yield put(getAttendanceListError(error));
  }
}

// Checkin Attendance
function* createAttendanceAsync({ payload }: any) {
  try {
    const response: attendance = yield call(API.postAttendanceAPI, payload);
    yield put(createAttendanceSuccess(response));
    yield put(reloadUserWorkingHours(false))
  } catch (error) {
    yield put(createAttendanceError(error));
  }
}

//  Checkout Attendance
function* updateAttendance({ payload }: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updateAttendanceAPI, payload.attendance);
    yield put(updateAttendanceSuccess({data,isLogout:payload.isLogout}));
    yield put(reloadUserWorkingHours(true))

  } catch (error) {
    yield put(updateAttendanceError(error));
  }
}

//Get Current User Attendance
function* fetchCurrentUserAttendanceState({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getCurrentAttendanceAPI, payload);
    yield put(getUserCurrentAttendanceSuccess(data));
  } catch (error) {
    yield put(getUserCurrentAttendanceError(error));
  }
}

function* fetchCurrentUserAttendanceList(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getCurrentUserAttendanceListAPI, {
      ...action.lazyState,
    }
    )
    yield put(getAttendanceBYIDListSuccess(response));
  } catch (error) {
    yield put(getAttendanceBYIDListError(error));
  }
}
/* Attendeance working hours */
function* fetchAttendanceWorkingHours(action: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.getUserAttendanceHoursAPI);
    yield put(getUserWorkingHoursSuccess(data));
  } catch (error) {
    yield put(getUserWorkingHoursError(error));
  }
}

function* fetcAttendanceDetailByIdAsync(action: any) {
  
  try {
    const { data } = yield call(API.getAttendanceDetailByIdAPI,action.payload.userId,action.payload.creationTime);
    yield put(getAttendanceDetailByIdSuccess(data));
  } catch (error) {
    yield put(getAttendanceDetailByIdError(error));
  }
}

function* attendanceSaga() {
  yield takeLatest(AttendanceTypes.GET_ATTENDANCE_LIST, getAttendanceListAsync);
  yield takeLatest(AttendanceTypes.GET_ATTENDANCE_BY_ID_LIST, fetchCurrentUserAttendanceList);
  yield takeLatest(AttendanceTypes.POST_ATTENDANCE, createAttendanceAsync);
  yield takeLatest(AttendanceTypes.GET_CURRENT_USER_ATTENDANCE, fetchCurrentUserAttendanceState);
  yield takeLatest(AttendanceTypes.UPDATE_ATTENDANCE, updateAttendance);
  yield takeLatest(AttendanceTypes.GET_USER_WORKING_HOURS, fetchAttendanceWorkingHours)
  yield takeLatest(AttendanceTypes.GET_ATTENDANCE_DETAIL_BY_ID, fetcAttendanceDetailByIdAsync)
}

export default attendanceSaga;