
import { TicketSystemActions } from "./actiontypes";

//====================== grid =======================

export const getEnquiryListPageLoading = (lazyState: any) => {
  return {
    type: TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_LOADING,
    lazyState
  }
}
export const getEnquiryListPageSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_SUCCESS,
    payload: resp
  }
}
export const getEnquiryListPageError = (error: any) => {
  return {
    type: TicketSystemActions.GET_TICKET_SYSTEM_ENQUIRY_LIST_ERROR,
    payload: error
  }
}

// ================= create enquiry =================

export const createEnquiryLoading = (quiryData: any) => {
  return {
    type: TicketSystemActions.CREATE_ENQUIRY_LOADING,
    payload: { quiryData }

  }
}

export const createEnquirySuccess = (resp: any) => {
  return {
    type: TicketSystemActions.CREATE_ENQUIRY_SUCCESS,
    payload: resp
  }
}

export const createEnquiryError = (error: any) => {
  return {
    type: TicketSystemActions.CREATE_ENQUIRY_ERROR,
    payload: error
  }
}


// ================ reset enquiry state =================

export const resetEnquiryState = () => {
  return {
    type: TicketSystemActions.RESET_ENQUIRY_STATE,
  }
}


// =================== reset enquiry link state  ==========

export const resetEnquiryLink = () => {
  return {
    type: TicketSystemActions.RESET_ENQUIRY_DOCUMENT_LINK,
  }
}

// ================ get induvidual query ==================

export const getInduvidualEnquiryLoading = (id: string | any) => {
  return {
    type: TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_LOADING,
    payload: id
  }
}

export const getInduvidualEnquirySuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_SUCCESS,
    payload: resp
  }
}
export const getInduvidualEnquiryError = (error: any) => {
  return {
    type: TicketSystemActions.GET_INDUVIDUAL_ENQUIRY_ERROR,
    payload: error
  }
}


// ================== get enquiry uploaded data ================== 

export const getEnquiryUpoadedDocumentLoading = (id: any, sourceType: string) => {
  return {
    type: TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_LOADING,
    payload: { id, sourceType }
  }
}
export const getEnquiryUpoadedDocumentSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_SUCCESS,
    payload: resp
  }
}
export const getEnquiryUpoadedDocumentError = (error: any) => {
  return {
    type: TicketSystemActions.GET_ENQUIRY_UPLOADED_DOCUMENT_ERROR,
    payload: error
  }
}


// ========================== GET_ENQUIRY_DOCUMENT_LINK ==================

export const getEnquiryDocumentLinkLoading = (id: any) => {
  return {
    type: TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_LOADING,
    payload: id
  }
}
export const getEnquiryDocumentLinkSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_SUCCESS,
    payload: resp
  }
}
export const getEnquiryDocumentLinkError = (error: any) => {
  return {
    type: TicketSystemActions.GET_ENQUIRY_DOCUMENT_LINK_ERROR,
    payload: error
  }
}


//get reply document list

export const getReplyUpoadedDocumentLoading = (id: any, sourceType: string) => {
  return {
    type: TicketSystemActions.GET_REPLY_DOCUMENT_LOADING,
    payload: { id, sourceType }
  }
}

export const getReplyUpoadedDocumentSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_REPLY_DOCUMENT_SUCCESS,
    payload: resp
  }
}

export const getReplyUpoadedDocumentError = (error: any) => {
  return {
    type: TicketSystemActions.GET_REPLY_DOCUMENT_ERROR,
    payload: error
  }
}






/// =============== replyy the query ===============

export const replyTheQueryLoading = (id: any, responsblePersonDetails: any) => {
  return {
    type: TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_LOADING,
    payload: { id, responsblePersonDetails }
  }
}
export const replyTheQuerySuccess = (response: any) => {
  return {
    type: TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_SUCCESS,
    payload: response
  }
}
export const replyTheQueryError = (error: any) => {
  return {
    type: TicketSystemActions.REPLY_EMPLOYEE_TO_ENQUIRY_ERROR,
    payload: error
  }
}

//  ====================== response the query ====================
export const responseTheQueryLoading = (id: any, responsblePersonDetails: any) => {
  return {
    type: TicketSystemActions.RESPONSE_ENQUIRY_LOADING,
    payload: { id, responsblePersonDetails }
  }
}

export const responseTheQuerySuccess = (resp: any) => {
  return {
    type: TicketSystemActions.RESPONSE_ENQUIRY_SUCCESS,
    payload: resp
  }
}

export const responseTheQueryError = (error: any) => {
  return {
    type: TicketSystemActions.RESPONSE_ENQUIRY_ERROR,
    payload: error
  }
}

// ================ get reply message from employee ==============

export const getReplyMessageFromEmployeeLoading = (id: any) => {
  return {
    type: TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_LOADING,
    payload: id
  }
}
export const getReplyMessageFromEmployeeSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_SUCCESS,
    payload: resp
  }
}
export const getReplyMessageFromEmployeeError = (error: any) => {
  return {
    type: TicketSystemActions.GET_REPLY_MESSAGE_FROM_EMPLOYEE_ERROR,
    payload: error
  }
}


// ================= reassign ticket enquiry ===============

export const reAssignTicketFromEmployeeLoding = (ticketId: string, supportorId: any, supporterName: any) => {
  return {
    type: TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_LOADING,
    payload: { ticketId, supportorId, supporterName }
  }
}
export const reAssignTicketFromEmployeeSuccess = (resp: any, supporterName: any) => {
  return {
    type: TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_SUCCESS,
    payload: { resp, supporterName }
  }
}
export const reAssignTicketFromEmployeeError = (error: any) => {
  return {
    type: TicketSystemActions.REASSIGN_TICKET_BY_EMPLOYEE_ERROR,
    payload: error
  }
}


// ================== reopen ticket =====================

export const reOpenTicketFromCustomerLoading = (ticketId: any) => {
  return {
    type: TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_LOADING,
    payload: ticketId
  }
}
export const reOpenTicketFromCustomerSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_SUCCESS,
    payload: resp
  }
}
export const reOpenTicketFromCustomerError = (error: any) => {
  return {
    type: TicketSystemActions.REOPEN_TICKET_BY_CUSTOMER_ERROR,
    payload: error
  }
}


//================ close ticket action =======================

export const closeTicketByEmployeeLoading = (ticketId: any) => {
  return {
    type: TicketSystemActions.CLOSE_TICKET_EMPLOYEE_LOADING,
    payload: ticketId
  }
}
export const closeTicketByEmployeeSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.CLOSE_TICKET_EMPLOYEE_SUCCESS,
    payload: resp
  }
}
export const closeTicketByEmployeeError = (error: any) => {
  return {
    type: TicketSystemActions.CLOSE_TICKET_EMPLOYEE_ERROR,
    payload: error
  }
}

// =================== get reassign employee list ================

export const getReassignEmployeeListLoading = () => {
  return {
    type: TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_LOADING
  }
}

export const getReassignEmployeeListSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_SUCCESS,
    payload: resp
  }
}

export const getReassignEmployeeListError = (error: any) => {
  return {
    type: TicketSystemActions.GET_REASSIGN_EMPLOYEE_LIST_ERROR,
    payload: error
  }
}

// ================== reset induvidual query ============

export const resetInduvidualQuery = () => {
  return {
    type: TicketSystemActions.RESET_INDUVIDUAL_QUERY_DATA,
  }
}


// =================== get customer list =================

export const getTicketCreateCustomerDataLoading = () => {
  return {
    type: TicketSystemActions.GET_CUSTOMER_DATA_LOADING
  }
}
export const getTicketCreateCustomerDataSuccess = (resp: any) => {
  return {
    type: TicketSystemActions.GET_CUSTOMER_DATA_SUCCESS,
    payload: resp
  }
}
export const getTicketCreateCustomerDataError = (error: any) => {
  return {
    type: TicketSystemActions.GET_CUSTOMER_DATA_ERROR,
    payload: error
  }
}
