import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { useToast } from 'src/components/Common/ReactToaster';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { ROUTER } from 'src/constants/routes';
import { formatDateTimeMEL, hasAdminOrEmployeeRoleHandler } from 'src/helpers/common_helpers';
import { Columns } from 'src/models/components/dataTableWrapperModel';
import { downloadPdfInvoicePageLoading, generatePaymentLinkLoading, getInvoicesByPartIdLoading, getInvoicesIdLoading, receivedPaymentDetailsLoading, resetInvoicePageDetails, resetInvoicePageMessages, resetPaymentLink, sendEmailToDealerInvoiceLoading, sendWhatsappInvoiceLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';

const InvoiceView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const { sub_id } = useParams();
  const { showToast } = useToast();
  const { invoiceDetails, receivedPayment, error, loading, message, paymentLink } = useSelector((state: RootReducerState) => state.invoiceReducer);
  const routerId = id ? id : (invoiceDetails?.id ? invoiceDetails?.id : "");

  useEffect(() => {
    if (id) {
      dispatch(getInvoicesIdLoading(id));
      dispatch(receivedPaymentDetailsLoading(id));
    }
    return () => {
      dispatch(resetInvoicePageDetails());
    };
  }, []);
  useEffect(() => {
    if (paymentLink) {
      try {
        const newWindow = window.open(paymentLink, "", "width=600,height=600");
        if (newWindow === null || typeof newWindow === 'undefined') {
          alert("Enable Popup to open");
        } else {
          newWindow.focus();
        }
      }
      finally {
        dispatch(resetPaymentLink());
      }
    }
  }, [paymentLink]);
  useEffect(() => {
    if (sub_id) {
      dispatch(getInvoicesByPartIdLoading(sub_id));
    }

  }, [sub_id]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      // dispatch(resetInvoicePageMessages())
      dispatch(resetPaymentLink());
    }
  }, [error, message]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }

  }, [loading]);

  const statusBodyTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );
  };

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetInvoicePageMessages());
    // dispatch(resetInvoicePageDetails());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };
  const DealerBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {hasAdminOrEmployeeRoleHandler() ? <Link to={`${ROUTER.DEALERS_VIEW}/${field}`}>{rowData}</Link> : rowData}
      </>
    );
  };

  const JobsheetBodyTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${field}`}>{rowData}</Link>
    );
  };

  const CustomerBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {hasAdminOrEmployeeRoleHandler() ? <Link to={`${ROUTER.CUSTOMERS_VIEW}/${field}`}>{rowData}</Link> : rowData}
      </>
    );
  };

  const invoiceGeneralDetails = {
    generalDetails: [
      {
        name: "Number",
        value: invoiceDetails?.number
      },
      {
        name: "Job Sheet",
        value: JobsheetBodyTemplate(invoiceDetails?.jobSheetNumber, invoiceDetails?.jobId)
      },
      {
        name: "Invoice Type",
        value: invoiceDetails?.invoiceTypeName
      },
      {
        name: "Dealer",
        value: DealerBodyTemplate(invoiceDetails?.dealerName, invoiceDetails?.dealerId)
      },
      {
        name: "Customer",
        value: CustomerBodyTemplate(invoiceDetails?.customerName, invoiceDetails?.customerId)
      },

      {
        name: "Total Price Of Parts",
        value: invoiceDetails?.totalPriceOfParts
      },
      {
        name: "Service Charge",
        value: invoiceDetails?.serviceCharge
      },
      {
        name: "Discount",
        value: invoiceDetails?.discount
      },
      {
        name: "Total Taxes",
        value: invoiceDetails?.totalTaxes
      },
      {
        name: "Total",
        value: invoiceDetails?.total
      },
      {
        name: "Total After Discount",
        value: invoiceDetails?.totalAfterDiscount
      },
      {
        name: "Bill To Name",
        value: invoiceDetails?.billToName
      },
      {
        name: "Locked",
        value: statusBodyTemplate(Boolean(invoiceDetails?.locked))
      },
      {
        name: "Paid State",
        value: statusBodyTemplate(Boolean(invoiceDetails?.paidState))
      },
      {
        name: "Invoice Date",
        value: formatDateTimeMEL(invoiceDetails?.lockedDate)
      },
      {
        name: "Credit Days",
        value: invoiceDetails?.creditDays
      },
      {
        name: "Warranty Status",
        value: invoiceDetails?.warrantyStatus
      },
    ]
  };

  const taxValues = invoiceDetails?.taxes;
  const partSkuList = invoiceDetails?.partSkus;

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const convertLinkPage = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="" style={{ width: "100px" }}  >
          <div className="align-right">
            {
              hasAdminOrEmployeeRoleHandler() ? <Link to={`${ROUTER.RECEIVED_PAYMENT_VIEW}/${val}`}>{`#${val}`}</Link> : <>{`#${val}`}</>
            }
          </div>
        </div>
      </div>
    );
  };
  const columns: Columns[] = [
    {
      field: "name",
      sortable: false,
      header: "Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "sacNo",
      sortable: false,
      header: "Sac No",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "quantity",
      sortable: false,
      header: "Quantity",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.quantity),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "unitPrice",
      sortable: false,
      header: "Unit Price",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.unitPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.amount),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "taxPrc",
      sortable: false,
      header: "Tax Percentage",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.taxPrc),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalTaxAmount",
      sortable: false,
      header: "Applicable Tax",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.totalTaxAmount),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalAmount",
      sortable: false,
      header: "Total Amount",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.totalAmount),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    }
  ];
  const partcolumns: Columns[] = [
    {
      field: "number",
      sortable: false,
      header: "Part Sku",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "partNumber",
      sortable: false,
      header: "Part Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "partDescription",
      sortable: false,
      header: "Part Description",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "partHsnNumber",
      sortable: false,
      header: "Part HSN Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "",
      sortable: false,
      header: "FOC",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "price",
      sortable: false,
      header: "Price",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.price),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalPartTaxAmount",
      sortable: false,
      header: "Tax Amount",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.totalPartTaxAmount),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalPrice",
      sortable: false,
      header: "Total Price",
      filter: false,
      showFilterMenu: false,
      body: (rowData: any) => headerTemplate(rowData.totalPrice),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
  ];

  const receivedPaymentcolumns: Columns[] = [
    {
      field: "receivedPaymentId",
      sortable: false,
      header: "Received Payment",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData: any) => convertLinkPage(rowData.receivedPaymentId),
      isDefault: true,
      isStatic: true,

    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "tdsDeduction",
      sortable: false,
      header: "Tds Deduction",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },

  ];

  const editInVoice = () => {
    navigate(`${ROUTER.INVOICE_EDIT}/${routerId}`);
  };
  const printInvoice = () => {
    dispatch(downloadPdfInvoicePageLoading(routerId));
  };
  const EmailDealer = () => {
    let thirdButtonLabel = ((invoiceDetails?.dealerName && invoiceDetails?.customerName) || (!invoiceDetails?.customerName && invoiceDetails?.dealerName)) ? 'Dealer' : (invoiceDetails?.customerName && !invoiceDetails?.dealerName) ? 'Customer' : 'Dealer';
    dispatch(sendEmailToDealerInvoiceLoading(routerId, thirdButtonLabel));
  };

  const sendWhatsapp=()=>{
    let label = ((invoiceDetails?.dealerName && invoiceDetails?.customerName) || (!invoiceDetails?.customerName && invoiceDetails?.dealerName)) ? 'Dealer' : (invoiceDetails?.customerName && !invoiceDetails?.dealerName) ? 'Customer' : 'Dealer';
    dispatch(sendWhatsappInvoiceLoading(routerId,label))
  }

  const htmlHandler = (htmlElement: string) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlElement }} className='pending-information-Full'/>
      </div>
    );
  };

  const isAdmin = hasAdminOrEmployeeRoleHandler() ? true : false;
  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className='userpage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Invoice" titlePath={ROUTER.INVOICE_GRID} breadcrumbItem='Invoice'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={(invoiceDetails?.locked != true && invoiceDetails?.isDeleted != true && hasAdminOrEmployeeRoleHandler()) ? true : false}
            isShowSecondButton={(invoiceDetails?.locked && invoiceDetails?.isDeleted != true && hasAdminOrEmployeeRoleHandler()) ? true : false}
            isShowThirdButton={(invoiceDetails?.locked && invoiceDetails?.isDeleted != true && hasAdminOrEmployeeRoleHandler()) ? true : false}
            isShowForthButton={(invoiceDetails?.paidState != true && invoiceDetails?.locked == true && invoiceDetails?.isDeleted != true) ? true : false}
            forthButtonLabel={(hasAdminOrEmployeeRoleHandler()) ? 'Generate Payment Link' : 'Pay Now'}
            onForthButtonClick={() => dispatch(generatePaymentLinkLoading(invoiceDetails?.id, isAdmin))}
            secondButtonLabel='Print Invoice'
            secondButtonDisabled={false}
            thirdButtonLabel={((invoiceDetails?.dealerName && invoiceDetails?.customerName) || (!invoiceDetails?.customerName && invoiceDetails?.dealerName)) ? 'Email Dealer' : (invoiceDetails?.customerName && !invoiceDetails?.dealerName) ? 'Email Customer' : 'Email Dealer'}
            thirdButtonDisabled={false}
            firstButtonLabel='Edit Invoice'
            onFirstButtonClick={editInVoice}
            onSecondButtonClick={printInvoice}
            onThirdButtonClick={EmailDealer}
            isShowFifthButton={(invoiceDetails?.locked && invoiceDetails?.isDeleted != true && hasAdminOrEmployeeRoleHandler()) ? true : false}
            fifthButtonLabel={((invoiceDetails?.dealerName && invoiceDetails?.customerName) || (!invoiceDetails?.customerName && invoiceDetails?.dealerName)) ? 'Whatsapp Dealer' : (invoiceDetails?.customerName && !invoiceDetails?.dealerName) ? 'Whatsapp Customer' : 'Whatsapp Dealer'}
            onFifthButtonClick={sendWhatsapp}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Invoice Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    invoiceGeneralDetails.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value}</div>
                        </div>
                      );
                    })
                  }
                </div>
                                    <div className="parent-div mt-2">
                                        <div className='text-bold'>Comments :</div>
                                        <div>{invoiceDetails?.comments ? htmlHandler(invoiceDetails?.comments) : "-"}</div>
                                    </div>
              </div>
            </div>
          </Row>


          <Row>
            {partSkuList?.length > 0 &&
              <Row>
                <div className="view">
                  <div className="general-details">
                    <b>Part Sku Details</b>
                  </div>
                  <div className="subview">
                    <Row>
                      <div className="card ps-0 pe-0">
                        <DataTableWrapper
                          columnDetails={partcolumns}
                          value={partSkuList}
                        >
                        </DataTableWrapper>
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
            }
          </Row>


          <Row>
            {taxValues?.length > 0 &&
              <Row>
                <div className="view">
                  <div className="general-details">
                    <b>Taxes Details</b>
                  </div>
                  <div className="subview">
                    <Row>
                      <div className="card ps-0 pe-0">
                        <DataTableWrapper
                          columnDetails={columns}
                          value={taxValues}
                        >
                        </DataTableWrapper>
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
            }
          </Row>

          {
            invoiceDetails?.locked && <Row>
              <Row>
                <div className="view">
                  <div className="general-details">
                    <b>Received Payments</b>
                  </div>
                  <div className="subview">
                    <Row>
                      <div className="card ps-0 pe-0">
                        <DataTableWrapper
                          columnDetails={receivedPaymentcolumns}
                          value={receivedPayment}
                          emptyMessage="No received payment found"
                        >
                        </DataTableWrapper>
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
            </Row>
          }
        </Container>
      </div >
    </React.Fragment >
  );
};

export default InvoiceView;