import { Image } from 'primereact/image'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { ROUTER } from 'src/constants/routes'
import { hasDealerRoleHandler } from 'src/helpers/common_helpers'
import { getDealerSubuserByIdLoading, getDealerSubuserProfileLoading, resetDealerSubuser } from 'src/store/dealersubuser/action'
import { RootReducerState } from 'src/store/reducers'

const ViewDealerSubuser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {id,sub_id}  = useParams()
  const { loading,dealerSubuserImage } = useSelector((state: RootReducerState) => state.dealerSubUserReducer);
  const { dealerSubUserDetails } = useSelector((state: RootReducerState) => state.dealerSubUserReducer.dealerSubUserList);


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (id) {
      dispatch(getDealerSubuserByIdLoading(id))
      dispatch(getDealerSubuserProfileLoading(id))
    }
    return()=>{
      dispatch(resetDealerSubuser())
    }
  }, [id])

  const editSubuser = () => {
    navigate(`${ROUTER.DEALER_SUB_USER_EDIT}/${id}`)
  }

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }

  const DealerSubuserViewDetails = {
    personalDetails: [
      {
        label: "Name",
        value: dealerSubUserDetails?.['name']
      },
      {
        label: "User Name",
        value: dealerSubUserDetails?.['userName']
      },
      {
        label: "Email",
        value: dealerSubUserDetails?.['email']
      },
      {
        label: "Mobile",
        value: dealerSubUserDetails?.['phoneNumber']
      },
      {
        label: "Alternate Number",
        value: dealerSubUserDetails?.extraProperties?.['AlternateNumber']
      },
      {
        label: "Address",
        value: dealerSubUserDetails?.['extraProperties']?.["Address"]
      },
      {
        label: "Is Active",
        value: statusBodyTemplate(dealerSubUserDetails?.['isActive'])
      },
      // {
      //   label: "Lockout Enabled",
      //   value: statusBodyTemplate(dealerSubUserDetails?.['lockoutEnabled'])
      // },
      {
        label: "Is Verified",
        value: statusBodyTemplate(dealerSubUserDetails?.['extraProperties']?.["IsVerfied"])
      },
      {
        label: "Send Sms",
        value: statusBodyTemplate(dealerSubUserDetails?.['extraProperties']?.["SendSms"])
      },
      {
        label: "Send Email",
        value: statusBodyTemplate(dealerSubUserDetails?.['extraProperties']?.["SendEmail"])
      },
      {
        label: "Send Whatsapp",
        value: statusBodyTemplate(dealerSubUserDetails?.['extraProperties']?.["SendWhatsapp"])
      },
      {
        label: "Mobile Number Verified",
        value: statusBodyTemplate(dealerSubUserDetails?.['phoneNumberConfirmed'])
      },
    ],


  }
  const isDealerLogin = hasDealerRoleHandler()

  return (
    <React.Fragment>
      <div className='subUserPage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Dealer Subuser" 
            titlePath={ isDealerLogin ? `${ROUTER.DEALER_SUB_USER_GRID}`:`${ROUTER.DEALERS_EDIT}/${sub_id}`} 
            breadcrumbItem='Subuser Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Dealer Subuser'
            onFirstButtonClick={editSubuser}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
              <Row>
              <Col lg={6}>
                    <div className="parentCls2">
                      {
                        DealerSubuserViewDetails.personalDetails.map((data, index) => {
                          return (
                            <div key={index} className={` pt-1 childCls`}>
                              <div>{data.label}</div>
                              <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                            </div>
                          )
                        })
                      }
                      {/* <hr /> */}

                    </div>
                  </Col>
                  <Col lg={6}>
                    <Row>
                     <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {dealerSubuserImage ?
                          <Image src={dealerSubuserImage} alt="Image" className='view-image' preview />
                          :
                          <div className='none-text'>-</div>
                        }
                        </Col>
                      </Row>
                      </Col>
              </Row>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewDealerSubuser