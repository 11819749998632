import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { DealerCreateModel } from "src/models/pages/dealerModel";
import DeleteModal from "src/pages/Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { formatDateTOYYYYMMDD } from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import TooltipMEL from "src/components/UI/Tooltip";
import { currency } from "src/constants/purchaseOrderFormFields";
import {
  clearPurchaseOrder,
  createPurchaseOrder,
  getDropdownForPurchase,
  getPurchaseOrderAudioModelDropdownLoading,
  getPurchaseOrderById,
  partsAutoCompleteList,
  resetPurchaseOrder,
  resetPurchaseOrderIndex,
  updatePurchaseOrder,
} from "src/store/purchaseOrders/action";
import DropdownInput from "src/components/UI/DropdownInput";
import { purchaseOrderDetails } from "src/models/pages/purchaseOrders";
import AutoCompleteComponent from "src/components/Common/AutoComplete/AutoComplete";
import { API } from "src/util/api";

const AddOrEditPurchaseOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue: purchaseOrderDetails = useSelector(
    (state: RootReducerState) => state.purchaseOrderReducer.purchaseOrderDetails as purchaseOrderDetails
  );
  const { error, message, loading, supplierList, partList, purchaseOrderrelatedAudioModelDropdown,purchaseOrderIndex } = useSelector(
    (state: RootReducerState) => state.purchaseOrderReducer
  );
  const [canRedirect, setCanRedirect] = useState(true);
  
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const supplierListOption = supplierList?.length > 0 ? (supplierList.map((eachSupplier: any) => {
    let data: any = {};
    data['value'] = eachSupplier.id;
    data['label'] = eachSupplier.name;
    return data;
  })).sort((a, b) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []


  // const AudioModelListOption = purchaseOrderrelatedAudioModelDropdown?.length > 0 ? (purchaseOrderrelatedAudioModelDropdown.map((eachSupplier: any) => {
  //   let data: any = {};
  //   data['value'] = eachSupplier.id;
  //   data['label'] = eachSupplier.number;
  //   return data;
  // })) : []

  const [audioModelListOption,setAudioModelListOption] = useState<any>([]);


useEffect(()=>{
  if(purchaseOrderIndex != -1){
    setAudioModelListOption((prev:any[])=>{
      let data = [...prev]
      data[purchaseOrderIndex] = purchaseOrderrelatedAudioModelDropdown?.length > 0 ? (purchaseOrderrelatedAudioModelDropdown.map((eachSupplier: any) => {
        let data: any = {};
        data['value'] = eachSupplier.id;
        data['label'] = eachSupplier.number;
        return data;
      })) : []
      return data
    })
    dispatch(resetPurchaseOrderIndex())
  }
},[purchaseOrderrelatedAudioModelDropdown,purchaseOrderIndex])


  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [contactDetailFalse, setContactDetailFalse] = useState(false);
  const [visible, setVisible] = useState(false);

  const [dobError, setDobError] = useState("");
  const [weddingDateError, setWeddingDateError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrderById(id));
      dispatch(getDropdownForPurchase())
    } else {
      dispatch(getDropdownForPurchase())
      // dispatch(getPurchaseOrderAudioModelDropdownLoading(0))
    }
   
    return () => {
      setVisible(false);
      dispatch(clearPurchaseOrder());
    };
    
  }, []);

  // useEffect(()=>{
  //   if (formValue?.purchaseOrderDetailsDtos.length > 0) {
  //     for (let i = 0; i < formValue?.purchaseOrderDetailsDtos.length; i++) {
  //       console.log("Edit Form index ---> ",i)
  //       console.log("Edit Form index ---> ",formValue?.purchaseOrderDetailsDtos)
  //       dispatch(getPurchaseOrderAudioModelDropdownLoading(formValue?.purchaseOrderDetailsDtos[i].partId,i))
        
  //     }
  //   }
  // },[formValue?.purchaseOrderDetailsDtos.length > 0])

  useEffect(()=>{
    const getPurchaseOrderDetailDropdown = async () => {
      if (formValue?.purchaseOrderDetailsDtos.length > 0) {
        for (let i = 0; i < formValue?.purchaseOrderDetailsDtos.length; i++) {
          let response = await API.getpurchaseOrderAudioModelAPI(formValue?.purchaseOrderDetailsDtos[i].partId)
          setAudioModelListOption((prev:any[])=>{
            let data = [...prev];
            data[i] = response?.data?.map((eachSupplier: any) => {
              let data: any = {};
              data['value'] = eachSupplier.id;
              data['label'] = eachSupplier.number;
              return data;
            });
            return data;
          })
          // dispatch(getPurchaseOrderAudioModelDropdownLoading(formValue?.purchaseOrderDetailsDtos[i].partId,i))
  
        }
      }
    }
    getPurchaseOrderDetailDropdown()
    
  },[formValue?.purchaseOrderDetailsDtos])

  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };


  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false)
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.PURCHASE_ORDER_GRID);
      }, 500)
      dispatch(resetPurchaseOrder())

    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetPurchaseOrder());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const post = (formValue: DealerCreateModel) => {
    dispatch(createPurchaseOrder(formValue));
  };



  const update = (formValue: DealerCreateModel) => {
    dispatch(updatePurchaseOrder(formValue, id));
  };


  const initialvalue = {
    poNumber: formValue?.['poNumber'] || "",
    generatedDate: formValue?.['generatedDate'] ? formatDateTOYYYYMMDD(formValue?.['generatedDate']) : "",
    buyingCurrency: formValue?.["buyingCurrency"] || "",
    supplierId: formValue?.["supplierId"] || "",
    termsAndConditions: formValue?.["termsAndConditions"] || "",
    remarks: formValue?.["remarks"] || "",
    total: formValue?.["total"] || "",
    locked: formValue?.["locked"] || false,
    createOrUpdatePurchaseOrderDetailsDtos: formValue?.purchaseOrderDetailsDtos?.length > 0 ? formValue?.purchaseOrderDetailsDtos.filter((eachValue: any) => !eachValue.isDeleted).map((eachOrder) => {
      let data: any = {};
      data['id'] = eachOrder.id;
      data['partId'] = { id: eachOrder.partId, partNumber: eachOrder?.partNo };
      data['quantity'] = eachOrder.quantity;
      data['buyingPrice'] = eachOrder.buyingPrice;
      data['purchaseOrderId'] = eachOrder.purchaseOrderId;
      data["audioModelId"] = eachOrder.audioModelId ? eachOrder.audioModelId : ""
      return data;
    }) : [
      {
        partId: {},
        quantity: "",
        buyingPrice: "",
        audioModelId: ""
      }
    ],
  };
  const initialvaluecreate = {
    poNumber: "",
    generatedDate: "",
    buyingCurrency: "INR",
    supplierId: "",
    termsAndConditions: "",
    remarks: "",
    total: "",
    locked: false,
    createOrUpdatePurchaseOrderDetailsDtos: [
      {
        partId: {},
        quantity: "",
        buyingPrice: "",
        audioModelId: ""
      }
    ],
  };









  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: any = {
      "supplierId": values.supplierId,
      "poNumber": values.poNumber,
      "generatedDate": values.generatedDate,
      "buyingCurrency": values.buyingCurrency,
      "locked": values.locked,
      "termsAndConditions": values.termsAndConditions,
      "remarks": values.remarks,
      "createOrUpdatePurchaseOrderDetailsDtos": values.createOrUpdatePurchaseOrderDetailsDtos.map((eachValue: any) => {
        let data: any = { ...eachValue };
        data['partId'] = eachValue?.partId?.id
        return data
      })
    };
    id ? update(submittedData) : post(submittedData);
    setIsSubmitting(true)
  };

  const validateWeddingDate = (dateOfBirth: any, weddingDate: any) => {
    // alert(123);
  };

  const validationSchema = Yup.object().shape({
    generatedDate: Yup.string().required('Generated Date is required').test(
      'is-not-future-date',
      'Generated Date should not be a future date',
      value => {
        // Ensure value is defined and not a future date
        if (!value) return false;
        const today = new Date();
        const inputDate = new Date(value);
        return inputDate <= today;
      }
    ),
    buyingCurrency: Yup.string().required('Buying Currency is required'),
    supplierId: Yup.string().required('Supplier is required'),
    createOrUpdatePurchaseOrderDetailsDtos: Yup.array().of(
      Yup.object().shape({
        // partId: Yup.mixed().transform(value => value.id).required('Part is required').test('existPart','Part Not Found',function(value){
        //   const partId = value;
        //   if (!partId) return false;
        //   if(partList.length==0){
        //     return false
        //   }else{
        //     return true
        //   }
        // }),
        partId: Yup.mixed().transform(value => {
          return value.id
        }).required('Part is required'),

        // quantity: Yup.string().required('Quantity is required').test(
        //   'notEqual',
        //   'Quantity cannot be below 0',
        //   function (value) {
        //     const { quantity } = this.parent;
        //     return (Number(value)) >= 0;
        //   }
        // ),
        quantity : Yup.string()
        .matches(/^\d{1,7}(\.\d{1,5})?$/, 'Quantity must be atmost a 7-digit number')
        .required('Quantity is required'),

        buyingPrice: Yup.string().required('Buying price is required').test(
          'notEqual',
          'Buying price cannot be below 0',
          function (value) {
            const { quantity } = this.parent;
            return (Number(value)) >= 0;
          }
        ),
        audioModelId: Yup.string().required('Audio Model is required')
      })
    ),
  })


  const removeIndex = (i: number) => {
    setselectedFiles((prevFiles: any) => {
      const newFiles = [...prevFiles];
      newFiles.splice(i, 1);
      return newFiles;
    });
  };

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
     * Formats the size
     */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.PURCHASE_ORDER_GRID);
    }
  };
  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.name] = '';
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };


  const handleAddContactDetails = (values: any, push: any, setFieldTouched: any) => {
    if (isContactDetailFilled(values)) {
      push({
        partId: {},
        quantity: "",
        buyingPrice: "",
      });
      setContactDetailFalse(false);
    } else {
      values.createOrUpdatePurchaseOrderDetailsDtos?.forEach((_: any, index: number) => {
        setFieldTouched(`createOrUpdatePurchaseOrderDetailsDtos[${index}].partId`, true);
        setFieldTouched(`createOrUpdatePurchaseOrderDetailsDtos[${index}].quantity`, true);
        setFieldTouched(`createOrUpdatePurchaseOrderDetailsDtos[${index}].buyingPrice`, true);
        setFieldTouched(`createOrUpdatePurchaseOrderDetailsDtos[${index}].audioModelId`, true);
      })

      setContactDetailFalse(true);
    }

  };

  const isContactDetailFilled = (values: any) => {
    const partDetails = values.createOrUpdatePurchaseOrderDetailsDtos || [];
    return partDetails.every((contact: any) => {
      const { id, ...fieldsExceptReOrderLevel } = contact;
      return Object.values(fieldsExceptReOrderLevel).every(
        (value: any) => value !== undefined && value !== null && value !== ""
      );
    });
  };




  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const searchParts = (event: any) => {

    dispatch(partsAutoCompleteList(event.query))
  }

  const callAudioModelApi = (partId: any,i:number) => {
    dispatch(getPurchaseOrderAudioModelDropdownLoading(partId,i))
  }

  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      <DeleteModal
        show={deleteModal || false}
        showIcon={false}
        message="Are you sure want to delete this contact"
        onDeleteClick={() => {
          // deleteContact
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Purchase order"
            titlePath={ROUTER.PURCHASE_ORDER_GRID}
            breadcrumbItem={id ? "Edit Purchase order" : "Create Purchase order"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik onSubmit={handleSubmit} initialValues={id ? initialvalue : initialvaluecreate} validationSchema={validationSchema} enableReinitialize >
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldValue, setFieldTouched, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="poNumber"
                                disabled={true}
                                onBlur={handleBlur}
                                value={values.poNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["number"] && errors["number"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Generated Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="generatedDate"
                                max={formatDateTOYYYYMMDD(new Date())}
                                onBlur={handleBlur}
                                value={values.generatedDate}
                                onChange={handleChange}
                                isRequired={true}
                                error={error}
                                invalid={
                                  touched["generatedDate"] && errors["generatedDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">

                              <DropdownInput
                                options={currency}
                                name="buyingCurrency"
                                label="Buying Currency"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.buyingCurrency}
                                invalid={
                                  touched["buyingCurrency"] && errors["buyingCurrency"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={supplierListOption}
                                name="supplierId"
                                label="Supplier"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.supplierId}
                                invalid={
                                  touched["supplierId"] && errors["supplierId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={6} className="mb-2">
                              <InputCom
                                labelName="Terms and Conditions"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="termsAndConditions"
                                onBlur={handleBlur}
                                value={values.termsAndConditions}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["termsAndConditions"] && errors["termsAndConditions"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={6} className="mb-2">
                              <InputCom
                                labelName="Remarks"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="remarks"
                                onBlur={handleBlur}
                                value={values.remarks}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["remarks"] && errors["remarks"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2 d-flex align-items-center">
                              <CheckboxInput
                                name="locked"
                                label="Locked"
                                value={values.locked}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <hr />
                            <Col lg={12} className="dealer-title-header">
                              <div>Add Parts</div>
                            </Col>
                            <FieldArray name="createOrUpdatePurchaseOrderDetailsDtos">
                              {({ insert, remove, push }) => (
                                <div >
                                  {values.createOrUpdatePurchaseOrderDetailsDtos?.length > 0 &&
                                    values.createOrUpdatePurchaseOrderDetailsDtos.map(
                                      (value: any, index: any) => {
                                        const isLastContact = index === values.createOrUpdatePurchaseOrderDetailsDtos?.length - 1;
                                        const isFirstContact = index === 0;
                                        const displayIndex = index + 1;
                                        return <div key={index}>

                                          <Row>
                                            <Col lg={3} className="mb-2">
                                              {/* <DropdownInput
                                                options={PARTS}
                                                name={`createOrUpdatePurchaseOrderDetailsDtos[${index}].partId`}
                                                label="Part"
                                                labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                                inputClassName={` ${index == 0 ? "" : "custom_select"}`}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                value={value.partId}
                                                invalid={
                                                  touched.createOrUpdatePurchaseOrderDetailsDtos?.[
                                                    index
                                                  ]?.partId &&
                                                    errors.createOrUpdatePurchaseOrderDetailsDtos?.[index]
                                                      ?.partId
                                                    ? true
                                                    : false
                                                } /> */}

                                              <AutoCompleteComponent value={value.partId} name={`createOrUpdatePurchaseOrderDetailsDtos[${index}].partId`} field="partNumber" options={partList}
                                                searchMethod={searchParts} onChange={handleChange}
                                                onSelect={(e:any)=>{

                                                  if(e.value.id){
                                                    callAudioModelApi(e.value.id,index)
                                                  }
                                                }}
                                                onBlur={handleBlur} 
                                                label="Part"
                                                labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                                inputClassName={` ${index == 0 ? "" : "custom_select"}`}
                                                required={true} invalid={
                                                  touched.createOrUpdatePurchaseOrderDetailsDtos?.[
                                                    index
                                                  ]?.partId &&
                                                    errors.createOrUpdatePurchaseOrderDetailsDtos?.[index]
                                                      ?.partId
                                                    ? true
                                                    : false
                                                } />
                                            </Col>
                                            <Col lg={3} className="mb-2">
                                              <InputCom
                                                labelName="Quantity"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                min={0}
                                                name={`createOrUpdatePurchaseOrderDetailsDtos[${index}].quantity`}
                                                onBlur={handleBlur}
                                                onChange={(e) => { handleChange(e);}}
                                                isRequired={true}
                                                value={value.quantity || ""}
                                                onKeyDown={preventSpecialChar}
                                                invalid={
                                                  touched.createOrUpdatePurchaseOrderDetailsDtos?.[
                                                    index
                                                  ]?.quantity &&
                                                    errors.createOrUpdatePurchaseOrderDetailsDtos?.[index]
                                                      ?.quantity
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>

                                            <Col lg={3} className="mb-2">
                                              <InputCom
                                                labelName="Buying Price"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                min={0}
                                                name={`createOrUpdatePurchaseOrderDetailsDtos[${index}].buyingPrice`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={true}
                                                value={value.buyingPrice}
                                                onKeyDown={preventSpecialChar}
                                                invalid={
                                                  touched.createOrUpdatePurchaseOrderDetailsDtos?.[
                                                    index
                                                  ]?.buyingPrice &&
                                                    errors.createOrUpdatePurchaseOrderDetailsDtos?.[index]
                                                      ?.buyingPrice
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>

                                            <Col lg={3} className="mb-2">
                                              <div className="d-flex align-items-end">
                                                <div className="d-block parts-inbound-width">
                                                  <DropdownInput
                                                    options={audioModelListOption[index]}
                                                    name={`createOrUpdatePurchaseOrderDetailsDtos[${index}].audioModelId`}
                                                    label="Audio Model"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    labelClassName={` ${index == 0 ? "" : "d-sm-none"}`}
                                                    inputClassName={` ${index == 0 ? "" : "custom_select"}`}
                                                    required={true}
                                                    value={value.audioModelId}
                                                    invalid={touched.createOrUpdatePurchaseOrderDetailsDtos?.[index]?.audioModelId && errors.createOrUpdatePurchaseOrderDetailsDtos?.[index]?.audioModelId ? true : false}
                                                  />
                                                </div>
                                                <div className="justify-content-center remove-tooltip-margin">
                                                  {values.createOrUpdatePurchaseOrderDetailsDtos.length > 1 && <TooltipMEL title="Remove part" position="bottom">


                                                    <i className="fas fa-times-circle color-red"
                                                      onClick={() => {
                                                        remove(index);
                                                        setContactDetailFalse(false);
                                                      }
                                                      }>
                                                    </i>

                                                  </TooltipMEL>}
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                          <div className="multiadd-container">
                                            {isLastContact &&
                                              <div className="add-btn-container" onClick={() => { handleAddContactDetails(values, push, setFieldTouched); }}>
                                                <TooltipMEL title="Add Parts" position='bottom'>
                                                  <i className="fas fa-plus-circle"></i><span>Add</span>
                                                </TooltipMEL>
                                              </div>
                                            }
                                          </div>
                                        </div>;
                                      }
                                    )}
                                  <hr className="mt-1" />
                                </div>
                              )}
                            </FieldArray>
                          </Row>
                          <div className="d-flex justify-content-end mt-2">
                            {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'> */}
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            {/* </TooltipMEL> */}
                            {/* <TooltipMEL title={id ? "Update Purchase Order" : "Create Purchase Order"} position='top'> */}
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Purchase Order" : "Create Purchase Order"}
                              type="submit"
                            />
                            {/* </TooltipMEL> */}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddOrEditPurchaseOrder;
