import { supplierInitialState } from "src/models/pages/supplierModel";
import { SupplierTypes } from "./actionTypes";
import { vehicleBrandsInitialState } from "src/models/pages/vehicleBrandsModel";

const initialState: supplierInitialState = {
  error: "",
  contacterror: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  supplierDetails: null,
  supplierList: {
    values: [],
    totalRecords: 0,
  },
  supplierImage: "",
  contactDetails: [],
  supplierDocumentList: [],
  documentLink: [],
  documentMessage: "",
  documentuploadMessge: "",
  supplierLatLng:""
};

export const supplierImageDeleteSuccess = "Supplier image is deleted successfully";
export const supplierMessage = "Contact Deleted Successfully";

const SupplierBrands = (state = initialState, action: any) => {
  switch (action.type) {
    // Grid

    case SupplierTypes.GET_SUPPLIER_LIST:
    case SupplierTypes.DELETE_SUPPLIER_BRANDS:
    case SupplierTypes.UPDATE_SUPPLIER:
    case SupplierTypes.POST_SUPPLIER:
    case SupplierTypes.GET_SUPPLIER_BY_ID:
    case SupplierTypes.SUPPLIER_FILE_UPLOAD_LOADING:
    case SupplierTypes.SUPPLIER_FILE_FETCH_LOADING:
    case SupplierTypes.SUPPLIER_FILE_DELETE_LOADING:
    case SupplierTypes.DELETE_SUPPLIER_DOCUMENT:
    case SupplierTypes.POST_SUPPLIER_DOCUMENT:
    case SupplierTypes.GET_SUPPLIER_LOCATION_LOADING:
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        paginatorCount: 0,
        documentMessage: ""
      };

    case SupplierTypes.GET_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length,
      };

    case SupplierTypes.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        // error: "",
        message: "Supplier Updated Successfully",
      };
    case SupplierTypes.GET_SUPPLIER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierLatLng:action.payload.latLag.data
      };

    case SupplierTypes.POST_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Supplier Created Successfully",
      };
    case SupplierTypes.DELETE_SUPPLIER_CONTACT_SUCCESS:
      return {
        ...state,
        error: "",
        message: "Contact Deleted Successfully",
      };
    case SupplierTypes.GET_SUPPLIER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        // error: "",
        // message: "",
        supplierDetails: action.payload,
      };
    case SupplierTypes.GET_SUPPLIER_CONTACT_SUCCESS:
      return {
        ...state,
        contactDetails: action.payload.data,
      };

    case SupplierTypes.DELETE_SUPPLIER_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo
          ? "Supplier is restored successfully"
          : "Supplier is deleted successfully",
      };

    case SupplierTypes.RESET_SUPPLIER:
      return {
        ...initialState,
        supplierLatLng:""
      };

    case SupplierTypes.CLEAR_SUPPLIER_MESSAGE:
      return {
        ...state,
        error: "",
        loading: false,
        message: "",
        contacterror: "",
        documentMessage: ""
      };
    case SupplierTypes.SUPPLIER_FILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        supplierImage: action.payload,
      };
    case SupplierTypes.SUPPLIER_FILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: supplierImageDeleteSuccess,
        supplierImage: "",
      };
    case SupplierTypes.SUPPLIER_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };
    case SupplierTypes.POST_SUPPLIER_CONTACT_FAIL:
      return {
        ...state,
        contacterror: action.payload
      }
    case SupplierTypes.POST_SUPPLIER_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: "",
        documentuploadMessge: "Document Uploaded Successfully"
      }
    case SupplierTypes.GET_SUPPLIER_DOCUMENT_SUCCESS:
      debugger
      const newDocuments = action.payload.data.filter((newDoc:any) => {
        return !state.supplierDocumentList.some((oldDoc:any) => oldDoc.id === newDoc.id);
      });
      return {
        ...state,
        loading: false,
        supplierDocumentList: [
          ...state.supplierDocumentList,
          ...newDocuments
        ]
        // supplierDocumentList: [
        //   ...state.supplierDocumentList,
        //   ...action.payload.data
        // ]
      }
    case SupplierTypes.GET_SUPPLIER_DOCUMENT_LINK_SUCCESS:
      
      return {
        ...state,
        loading: false,
        documentLink: [
          ...state.documentLink,
          {
            id: action.payload.res,
            link: action.payload.response.data,
          }
        ]
      }
    case SupplierTypes.DELETE_SUPPLIER_DOCUMENT_SUCCESS:
      debugger
      let deletedId = action.payload.deletedId      
      const filteredDocument = state.supplierDocumentList.filter((doc:any) => doc.id !== deletedId);
      return {
        ...state,
        loading: false,
        supplierDocumentList: filteredDocument,
        documentMessage: "Document Deleted Successfully"
      }
    case SupplierTypes.POST_SUPPLIER_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        documentMessage: "Contact Uploaded Successfully"
      }
    case SupplierTypes.GET_SUPPLIER_LIST_FAIL:
    case SupplierTypes.DELETE_SUPPLIER_BRANDS_FAIL:
    case SupplierTypes.UPDATE_SUPPLIER_FAIL:
    case SupplierTypes.POST_SUPPLIER_FAIL:
    case SupplierTypes.GET_SUPPLIER_BY_ID_FAIL:
    case SupplierTypes.SUPPLIER_FILE_UPLOAD_ERROR:
    case SupplierTypes.SUPPLIER_FILE_FETCH_ERROR:
    case SupplierTypes.SUPPLIER_FILE_DELETE_ERROR:
    case SupplierTypes.DELETE_SUPPLIER_DOCUMENT_FAIL:
    case SupplierTypes.POST_SUPPLIER_DOCUMENT_FAIL:
    case SupplierTypes.GET_SUPPLIER_LOCATION_ERROR:    

      return {
        ...state,
        loading: false,
        message: "",
        documentMessage: "",
        error: action.payload,
      };

    case SupplierTypes.SUPPLIER_FILE_CLEAR:
      return {
        ...state,
        supplierImage: "",
        documentMessage: "",
      };

    case SupplierTypes.RESET_SUPLIER_CONTACT:
      return {
        ...state,
        loading: false,
        supplierDocumentList: []
      }
    default:
      return { ...state };
  }
};

export default SupplierBrands;
