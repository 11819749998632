import { DealerSubUserType } from "./actiontypes";

import { DealersubuserInitialStore } from "src/models/pages/dealersubuserModel";

const initialState: DealersubuserInitialStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  dealerSubUserList: {
    values: [],
    totalCount: 0,
    dealerSubUserDetails: undefined
  },
  dealerSubuserImage:"",
  mailMessage:"",
  phoneMessage:"",
  otpmessage:"",
  editedMobileNumber:"",
  createdData:"",
  updatedmessage:"",
  dealerSubuserLatLng:""
}

const dealerSubUser = (state = initialState, action: any) => {
  switch (action.type) {
    case DealerSubUserType.CREATE_DEALERSUBUSER_LOADING:
    case DealerSubUserType.GET_DEALERSUBUSERBYID_LOADING:
    case DealerSubUserType.GET_DEALERSUBUSER_LIST_LOADING:
    case DealerSubUserType.DELETE_DEALERSUBUSER_LOADING:
    case DealerSubUserType.UPDATE_DEALERSUBUSER_LOADING:
    case DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_LOADING:
    case DealerSubUserType.DEALERSUBUSER_SET_PHONE_LOADING:
    case DealerSubUserType.DEALERSUBUSER_SET_EMAIL_LOADING:
    case DealerSubUserType.RESEND_DEALERSUBUSER_OTP:
    case DealerSubUserType.GET_DEALERSUBUSER_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
        paginatorCount: 0
      }

    case DealerSubUserType.GET_DEALERSUBUSER_LIST_SUCCESS:
      return {
        loading: false,
        dealerSubUserList: {
          ...state.dealerSubUserList,
          values: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        },
        paginatorCount: action.payload.data.items.length
      }

    case DealerSubUserType.CREATE_DEALERSUBUSER_SUCCESS:
      console.log(action.payload);
      debugger
      return {
        ...state,
        loading: false,
        message: "Dealer Subuser Created Successfully",
        createdData:action.payload.data
      }
    case DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Profile Deleted Successfully"
      }
    case DealerSubUserType.GET_DEALERSUBUSER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerSubuserLatLng:action.payload.latLag.data
      }
    case DealerSubUserType.UPLOAD_DEALERSUBUSER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      }

    case DealerSubUserType.GET_DEALERSUBUSERBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerSubUserList: {
          ...state.dealerSubUserList,
          dealerSubUserDetails: action.payload.data,
        },
        phoneMessage:"",
        mailMessage:""
      };

    case DealerSubUserType.GET_DEALERSUBUSER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerSubuserImage:action.payload.data
      }
    case DealerSubUserType.UPDATE_DEALERSUBUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedmessage: "Dealer Subuser Updated Successfully"
      }

    case DealerSubUserType.DELETE_DEALERSUBUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo ? "Dealer Subuser Restored Successfully" : "Dealer Subuser Deleted Successfully"
      }

    case DealerSubUserType.CLEAR_ERROR_MESSAGE_DEALERSUBUSER :
      return {
        ...state ,
        loading : false ,
        error : "",
        message : ""
      }  
    case DealerSubUserType.DEALERSUBUSER_SET_PHONE_SUCCESS :
      return {
        ...state ,
        loading : false ,
        error : "",
        message : "",
        phoneMessage:"OTP sent successfully",
        editedMobileNumber:action.payload.number
      }  
    case DealerSubUserType.RESEND_DEALERSUBUSER_OTP_SUCCESS :
      return {
        ...state ,
        loading : false ,
        error : "",
        message : "",
        phoneMessage:"OTP Sent Successfully",
        editedMobileNumber:action.payload.number
      }  
    case DealerSubUserType.DEALERSUBUSER_SET_EMAIL_SUCCESS :
      return {
        ...state ,
        loading : false ,
        error : "",
        message : "",
        mailMessage:"Email Id Updated Successfully"
      }  
    case DealerSubUserType.SEND_OTP_DEALERSUBUSER_SUCCESS :
      return {
        ...state ,
        loading : false ,
        error : "",
        message : "",
        otpmessage:"OTP verified successfully",
      }  

    case DealerSubUserType.RESET_DEALERSUBUSER:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        paginatorCount: 0,
        dealerSubUserList: {
          values: [],
          totalCount: 0,
          dealerSubUserDetails: undefined
        },
        dealerSubuserImage:"",
        updatedmessage:"",
        dealerSubuserLatLng:"",
      }

    case DealerSubUserType.RESET_DEALERSUBUSER_OTP_MESSAGE:
      return{
        ...state,
        phoneMessage:"",
        otpmessage:"",
        createdData:"",
        editedMobileNumber:"",
        updatedmessage:"",
      }

    case DealerSubUserType.GET_DEALERSUBUSERID_ERROR:
    case DealerSubUserType.CREATE_DEALERSUBUSER_ERROR:
    case DealerSubUserType.UPDATE_DEALERSUBUSER_ERROR:
    case DealerSubUserType.GET_DEALERSUBUSER_LIST_ERROR:
    case DealerSubUserType.DELETE_DEALERSUBUSER_ERROR:
    case DealerSubUserType.UPLOAD_DEALERSUBUSER_PROFILE_ERROR:
    case DealerSubUserType.DELETE_DEALERSUBUSER_PROFILE_ERROR:
    case DealerSubUserType.DEALERSUBUSER_SET_PHONE_ERROR:
    case DealerSubUserType.DEALERSUBUSER_SET_EMAIL_ERROR:
    case DealerSubUserType.RESEND_DEALERSUBUSER_OTP_ERROR:
    case DealerSubUserType.GET_DEALERSUBUSER_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state }
  }
}

export default dealerSubUser