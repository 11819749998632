export enum TaxesTypes {

  // Grid
  GET_TAXES_LIST = "@@taxes/GET_TAXES_LIST",
  GET_TAXES_LIST_SUCCESS = "@@taxes/GET_TAXES_LIST_SUCCESS",
  GET_TAXES_LIST_FAIL = "@@taxes/GET_TAXES_LIST_FAIL",

  DELETE_TAXES = "@@taxes/DELETE_TAXES",
  DELETE_TAXES_SUCCESS = "@@taxes/DELETE_TAXES_SUCCESS",
  DELETE_TAXES_FAIL = "@@taxes/DELETE_TAXES_FAIL",

  RESET_TAXES = "@@taxes/RESET_TAXES",
  CLEAR_TAXES_MESSAGE = "@@taxes/CLEAR_TAXES_MESSAGE",

  UPDATE_TAXES = "@@taxes/UPDATE_TAXES",
  UPDATE_TAXES_SUCCESS = "@@taxes/UPDATE_TAXES_SUCCESS",
  UPDATE_TAXES_FAIL = "@@taxes/UPDATE_TAXES_FAIL",

  // POST

  POST_TAXES = "@@taxes/POST_TAXES",
  POST_TAXES_SUCCESS = "@@taxes/POST_TAXES_SUCCESS",
  POST_TAXES_FAIL = "@@taxes/POST_TAXES_FAIL",

  // GET

  GET_TAXES_BY_ID = "@@taxes/GET_TAXES_BY_ID",
  GET_TAXES_BY_ID_SUCCESS = "@@taxes/GET_TAXES_BY_ID_SUCCESS",
  GET_TAXES_BY_ID_FAIL = "@@taxes/GET_TAXES_BY_ID_FAIL",

  GET_TAXES_DROPDOWN_LIST_LOADING = "@@taxes/GET_TAXES_DROPDOWN_LIST_LOADING",
  GET_TAXES_DROPDOWN_LIST_SUCCESS = "@@taxes/GET_TAXES_DROPDOWN_LIST_SUCCESS",
  GET_TAXES_DROPDOWN_LIST_ERROR = "@@taxes/GET_TAXES_DROPDOWN_LIST_ERROR",

  GET_TAXES_INVOICETYPE_DROPDOWN_LOADING = "@@taxes/GET_TAXES_INVOICETYPE_DROPDOWN_LOADING",
  GET_TAXES_INVOICETYPE_DROPDOWN_SUCCESS = "@@taxes/GET_TAXES_INVOICETYPE_DROPDOWN_SUCCESS",
  GET_TAXES_INVOICETYPE_DROPDOWN_ERROR = "@@taxes/GET_TAXES_INVOICETYPE_DROPDOWN_ERROR",

}
