import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { IncentiveRewardTypes } from "./actionTypes";
import { incentiveRewardListByIdError, incentiveRewardListByIdSuccess, incentiveRewardListError, incentiveRewardListSuccess, updateAdjustmentPointError, updateAdjustmentPointSuccess, updatePaymentError, updatePaymentSuccess } from "./action";


function* getIncentiveRewardListAsync(action: any) {
    try {
        const Response: Promise<any> = yield call(API.getIncentiveRewardListAPI,action.lazyState)
        yield put(incentiveRewardListSuccess(Response));        
    } catch (error) {
        yield put(incentiveRewardListError(error));
    }
}

function* getIncentiveRewardByIdAsync(action: any) {
    try {
        const Response: Promise<any> = yield call(API.getIncentiveRewardByIdAPI,action.id)
        yield put(incentiveRewardListByIdSuccess(Response));        
    } catch (error) {
        yield put(incentiveRewardListByIdError(error));
    }
}

function* updateAdjustmentPointAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.UpdateAdjustRewardPointAPI, {payload:action.payload,id:action.id} )
        yield put(updateAdjustmentPointSuccess(response));   
    } catch (error) {
        yield put(updateAdjustmentPointError(error));
    }
}

function* updatePaymentAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.UpdatePaymentForIncentiveAPI, {payload:action.payload,id:action.id} )
        yield put(updatePaymentSuccess(response));   
    } catch (error) {
        yield put(updatePaymentError(error));
    }
}

function* incentiveRewardSaga() {
    yield takeLatest(IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_LOADING, getIncentiveRewardListAsync);
    yield takeLatest(IncentiveRewardTypes.GET_INCENTIVE_REWARD_LIST_BY_ID_LOADING, getIncentiveRewardByIdAsync);
    yield takeLatest(IncentiveRewardTypes.UPDATE_ADJUSTMENT_POINT_LOADING, updateAdjustmentPointAsync);
    yield takeLatest(IncentiveRewardTypes.UPDATE_PAYMENT_LOADING, updatePaymentAsync);
}

export default incentiveRewardSaga ;