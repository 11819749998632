import { call, put, takeLatest } from "redux-saga/effects";
import { AddtionalExpensesReports } from "./actionTypes"
import { API } from "src/util/api";
import { additionalExpenseReportsListByIdError, additionalExpenseReportsListByIdSuccess, additionalExpenseReportsListError, additionalExpenseReportsListSucces, updateAdjustmentPointError, updateAdjustmentPointSuccess, updatePaymentError, updatePaymentSuccess } from "./action";
import { AdditionalExpenseReportsListModel } from "src/models/pages/additionalExpenseReportsModel";



// =========== worker function ============

// =========== Additional Expense Reports list ==========

function* additionalExpenseReportsAsync(action: any) {
    try {
        const Response: AdditionalExpenseReportsListModel = yield call(API.getAdditionalExpenceListAPI, action.lazyState)
        yield put(additionalExpenseReportsListSucces(Response));
    } catch (error) {
        yield put(additionalExpenseReportsListError(error));
    }
}

function* getAdditionalExpenseReportsByIdAsync(action: any) {
    try {
        const Response: Promise<any> = yield call(API.getadditionalExpenseReportsListByIdAPI,action.id)
        yield put(additionalExpenseReportsListByIdSuccess(Response));        
    } catch (error) {
        yield put(additionalExpenseReportsListByIdError(error));
    }
}

function* updateAdjustmentPointAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.UpdateAdjustRewardPointForAdditionalExpenseAPI, {payload:action.payload,id:action.id} )
        yield put(updateAdjustmentPointSuccess(response));   
    } catch (error) {
        yield put(updateAdjustmentPointError(error));
    }
}

function* updatePaymentAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.UpdatePaymentForAdditionalExpenseAPI, {payload:action.payload,id:action.id} )
        yield put(updatePaymentSuccess(response));   
    } catch (error) {
        yield put(updatePaymentError(error));
    }
}

// ======= watcher function =======
function* additionalExpenseReportsSaga() {
    yield takeLatest(AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_LOADING, additionalExpenseReportsAsync)
    yield takeLatest(AddtionalExpensesReports.GET_ADDITIONAL_EXPENSE_REPORTS_BY_ID_LOADING, getAdditionalExpenseReportsByIdAsync)
    yield takeLatest(AddtionalExpensesReports.UPDATE_ADJUSTMENT_POINT_LOADING, updateAdjustmentPointAsync);
    yield takeLatest(AddtionalExpensesReports.UPDATE_PAYMENT_LOADING, updatePaymentAsync);
}

export default additionalExpenseReportsSaga;