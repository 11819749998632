import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import { Item } from "src/models/pages/customerModel";
import StatusHighlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { saveAsExcelFile } from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { Dropdown } from "primereact/dropdown";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { ACTIVE, DELETED, TAX_TYPES_OBJECT } from "src/constants/commonConstants";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import {
  ClearSupplierImage,
  ResetSupplierMessage,
  deleteSupplierFromList,
  getSupplierList,
  resetPurchaseOrder,
} from "src/store/actions";
import { RootReducerState } from "src/store/reducers";
import { supplierDetails } from "src/models/pages/supplierModel";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import HtmltoPlainText from "src/components/atoms/HtmltoPlainText";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";



export default function Supplier() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.supplierReducer.supplierList);
  const [supplierData, setSupplierData] = useState<Item[]>([]);
  const { showToast } = useToast();
  const loading = useSelector((state: RootReducerState) => state.supplierReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { error, message, paginatorCount } = useSelector((state: RootReducerState) => state.supplierReducer);
  const dispatch: any = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<any | null>(null);
  const [visible, setVisible] = useState(false);
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    Email: { value: "", matchMode: "contains" },
    MobileNumber: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Name",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  useEffect(()=>{
dispatch(ClearSupplierImage())
  },[])
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block"; rowData
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.SUPPLIER_CREATE);
  };

  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.SUPPLIER_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.SUPPLIER_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {

    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }

      </>
    );
  };

  const statusBodyTemplate = (rowData: any, field: string) => {
    // let status = rowData[field].toString();
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };

  const taskTypeBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field].toString();
    // let status = rowData[field];
    return (
      <>{TAX_TYPES_OBJECT[status]}</>
    );
  };

  const emailBodyTemplate = (rowData: any, field: string) => {
    let data = rowData[field];
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      frozen : true,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "Name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "Name"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "Email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => emailBodyTemplate(rowData, "Email"),
      isStatic: false,
    },

    {
      field: "MobileNumber",
      sortable: true,
      header: "Mobile",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "gstNumber",
      sortable: true,
      header: "Gst Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "iecNumber",
      sortable: false,
      header: "Iec Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",

    },
    {
      field: "accountInformation",
      sortable: false,
      header: "Account Information",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "termsAndConditions",
      sortable: false,
      header: "Terms & Conditions",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body:(rowData:any)=>{return <HtmltoPlainText htmlString={rowData?.termsAndConditions} />},
      className: "table-cell-style"
    },
    {
      field: "address",
      sortable: true,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: false,
      isStatic: false,
      className: "table-cell-style"

    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { id, name, email, mobileNumber, gstNumber, iecNumber, termsAndConditions, accountInformation, address } = eachDealer;
      let data: any = {};
      data['Name'] = name;
      data['Email'] = email;
      data['Mobile'] = mobileNumber;
      data['Gst Number'] = gstNumber;
      data['IEC Number'] = iecNumber;
      data['Terms And Conditions'] = termsAndConditions;
      data['Address'] = address;
      data['Account Information'] = accountInformation;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const hidePopup = () => { setVisible(false); dispatch(ResetSupplierMessage()); }
  
  const dialogContainer = (error: any) => {
    return (
      <>
       <ValidationPopup error={error} visible={visible} onHide={hidePopup} />

        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(ResetSupplierMessage()); }}>

          <div className="m-0 validation-text">
            {error.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>);
            })}
          </div>
        </Dialog> */}
      </>
    );
  };
  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
	
    try {
      setPdfLoading(true)
      const response = await API.getSupplierListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      // let resp=await dealerListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(response?.data?.items, columns, "supplier");
     }else{
      saveDataAsExcel(response?.data?.items, "supplier");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  
  const csvClickHandler = () => {
    // saveDataAsExcel(values, "supplier");
    fetchData(false)
  };
  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let data: any = {};
      data['Name'] = eachValue.name;
      data['Email'] = eachValue.email;
      data['MobileNumber'] = eachValue.mobileNumber;
      data['gstNumber'] = eachValue.gstNumber;
      data['iecNumber'] = eachValue.iecNumber;
      data['termsAndConditions'] = eachValue.termsAndConditions;
      data['accountInformation'] = eachValue.accountInformation;
      data['address'] = eachValue.address;
      return data
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  }

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "supplier");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getSupplierList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<any>([]);

  const onSelectionChange = (event: any) => {

    const value = event.value;
    setSelectedSupplier(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {

    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedSupplier(supplierData);
    } else {
      setSelectAll(false);
      setSelectedSupplier([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedSupplier([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false)
    setSelectedSupplier([])
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetPurchaseOrder());
      setTimeout(() => {
        dispatch(getSupplierList(lazyState));
      }, 500);

    }
  }, [error, message])

  useEffect(() => {
    if (values) {
      const SupplierStructuredData: any[] = values.map((data: supplierDetails | any) => {
        let modified: any = {
          id: data?.id,
          Name: data?.name,
          MobileNumber: data?.mobileNumber,
          Email: data?.email,
          detailsVerified: data?.detailsVerified,
          gstNumber: data?.gstNumber,
          iecNumber: data?.iecNumber,
          accountInformation: data?.accountInformation,
          termsAndConditions: data?.termsAndConditions,
          alternateNumber: data?.alternateNumber,
          address: data?.address,
          isDeleted: data?.isDeleted,
        };
        return modified;
      });
      setSupplierData(SupplierStructuredData);
    } else {
      callbackFunction(lazyState);
    }
  }, [values]);

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.isDeleted == true ? true : rowData[0]?.isDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deleteSupplierFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedSupplier([]);
      // showToast("Supplier Deleted Successfully", { type: "success" });
    }

  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedSupplier([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };



  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (supplierData.length > 0) {
      setSelectAll(true);
      setSelectedSupplier(supplierData);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedSupplier([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }
  const header =
    <div className="table-header-container">
      <div>
        Suppliers List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Customers"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedSupplier}
        bulkActionSatusTitle="Customers"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedSupplier.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedSupplier.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
              <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
                <FeatherIcon icon="archive" className="mr-1" />
                Archive
              </Button>
            </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedSupplier.length > 0 && <BulkActionStatus count={selectedSupplier.length} title="Suppliers"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
      <div style={{ display: "flex" }}>
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button>
        </div>
        {/* <TooltipMEL title="Add Customer" position="bottom"> */}
        <div className="reponsive_position">
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Supplier
        </Button>
        </div>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;


  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');


  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  return (
    <>
      <div className="page-content">
      {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo = {selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedSupplier?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedSupplier([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedSupplier.length > 0) {
              for (let i = 0; i < selectedSupplier.length; i++) {
                handleDeleteUsers(selectedSupplier[i], selectedSupplier.length, i);
              }
            }
            // else {
            //   setDeleteModal(false);
            //   setRowData(null);
            //   setSelectedCustomers([]);
            //   setSelectAll(false);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these suppliers?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setSelectedSupplier([]);
            setSelectAll(false);
            setArchiveModal(false);
          }}
        />
 {visible && dialogContainer(error)}
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={supplierData}
          lazy
          defaultSortOrder={1}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Suppliers  {last} records of {totalRecords} in total"
          selection={selectedSupplier}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
