import { totalWorkingDaysTypes } from "./actionTypes"


export const getTotalWorkingDaysLoading = (lazyState:any) => {
    return {
      type: totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_LOADING,
      payload:lazyState
    }
  }
  
  export const getTotalWorkingDaysSuccess = (resp: any) => {
    return {
      type: totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_SUCCESS,
      payload: resp
    }
  }
  
  export const getTotalWorkingDaysError = (error: any) => {
    return {
      type: totalWorkingDaysTypes.GET_TOTAL_WORKING_DAYS_ERROR,
      payload: error
    }
  }

  export const createTotalWorkingDaysLoading = (TotalWorkingDays: any) => {
    return {
      type: totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_LOADING,
      payload: TotalWorkingDays
    }
  }
  export const createTotalWorkingDaysSuccess = (response: any) => {
    return {
      type: totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_SUCCESS,
      payload: response
    }
  }
  export const createTotalWorkingDaysError = (error: any) => {
    return {
      type: totalWorkingDaysTypes.CREATE_TOTAL_WORKING_DAYS_ERROR,
      payload: error
    }
  }
  
  export const getMonthsLoading = () => {
    return {
      type: totalWorkingDaysTypes.GET_MONTHS_LOADING,
    }
  }
  
  export const getMonthsSuccess = (resp: any) => {
    return {
      type: totalWorkingDaysTypes.GET_MONTHS_SUCCESS,
      payload: resp
    }
  }
  
  export const getMonthsError = (error: any) => {
    return {
      type: totalWorkingDaysTypes.GET_MONTHS_ERROR,
      payload: error
    }
  }
  
  
  export const clearTotalWorkingDaysMessage = () => {
    return {
      type: totalWorkingDaysTypes.CLEAR_TOTAL_WORKING_DAYS,
    }
  }
  
  export const ResetTotalWorkingDaysMessage = () => {
    return {
      type: totalWorkingDaysTypes.RESET_TOTAL_WORKING_DAYS,
    }
  }
  
export const getTotalWorkingDayIdLoading = (id:any) => {
    return {
      type: totalWorkingDaysTypes.GET_TOTAL_WORKING_DAY_BY_ID_LOADING,
      payload:id
    }
  }
  
  export const getTotalWorkingDayByIdSuccess = (resp: any) => {
    return {
      type: totalWorkingDaysTypes.GET_TOTAL_WORKING_DAY_BY_ID_SUCCESS,
      payload: resp
    }
  }
  
  export const getTotalWorkingDayByIdError = (error: any) => {
    return {
      type: totalWorkingDaysTypes.GET_TOTAL_WORKING_DAY_BY_ID_ERROR,
      payload: error
    }
  }