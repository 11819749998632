import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react'
import { useToast } from 'src/components/Common/ReactToaster';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import ArchiveModal from "../../Calendar/DeleteModal";
import CustomizeTable from 'src/components/Common/CustomizeTable/CustomizeTable';
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction';
import { useNavigate } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { Form, Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import Select from 'src/components/Common/Select';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import { Option } from 'src/models/components/inputWrapperModel';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import FeatherIcon from "feather-icons-react";
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAudioBrandLoading, getStockQuantityByMusicSystemBrandLoading } from 'src/store/stockQuantityByMusicSystemBrand/action';
import { RootReducerState } from 'src/store/reducers';
import { convertToMonthYear, HtmlRowInput, saveAsExcelFile } from 'src/helpers/common_helpers';
import moment from 'moment';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { API } from 'src/util/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';


function formatMonthYear(monthYear:any) {
  const [month, year] = monthYear.split('-');
  return `${year}${month.padStart(2, '0')}`;
}


const stockQuantitymusicsystembrand = (values: any) => {
  const modified = values.map((eachValue: any) => {
    const obj: any = {};
    obj['audioBrandName'] = eachValue?.audioBrandName ? eachValue?.audioBrandName : "-";
    // eachValue.reportData.forEach((res: any) => {
    //   const month = res.month;
    //   obj[`unUsed_${month}`] = res.value;
    // })
    eachValue.monthYears.forEach((res: any) => {
      const month = formatMonthYear(res.monthYear);
      obj[`closing${month}`] = res.stockDeatails?.closing;
    })
    return obj;
  });
  return modified
}



const StockQuantityMusicSystemBrand = () => {
  const filterDetails: DataTableFilterMeta = {
    audioBrandName: { value: "", matchMode: "contains" },
    StartDate: { value: "", matchMode: "contains" },
    endDate: { value: "", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "audioBrandName",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const initialValues = {
    status: "",
  };


  const [selectedStockQuantityMusicSystemBrand, setSelectedStockQuantityMusicSystemBrand] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [rowData, setRowData] = useState<any | null>(null);
  const [selectedStatus, setselectedStatus] = useState(null);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [filter, setFilter] = useState(false);


  const { showToast } = useToast();
  const navigate = useNavigate();
  const dt = useRef<DataTable<any>>(null);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const dispatch = useDispatch();
  const { loading, paginatorCount, audioBrandDropdownList } = useSelector((state: RootReducerState) => state.stockQuantityByMusicSystemBrandReducer)
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)

  const { error, message } = useSelector((state: RootReducerState) => state.stockQuantityByMusicSystemBrandReducer)
  const { totalRecords, values } = useSelector((state: RootReducerState) => state.stockQuantityByMusicSystemBrandReducer.stockQuantityByMusicSystemBrandList)

  const modifiedValues = stockQuantitymusicsystembrand(values)
  const formattedResponse = values.map(part => {
    return {
      ...part,
      monthYears: part.monthYears.map((data:any) => ({
        ...data,
        monthYear: formatMonthYear(data.monthYear)
      }))
    };
  });
  const monthsmodi = formattedResponse?.map((data)=>data.monthYears?.map((newdata:any)=>newdata.monthYear))
  let monthsArr = Array.from(new Set(monthsmodi.flatMap(item => item.map((data:any) => data))))
  const parseYearMonth = (str:any) => {
    const year = parseInt(str.slice(0, 4));
    const month = parseInt(str.slice(4));
    return { year, month };
  };
  const sortingMonthDataOrderWise = (data: any) => {
    return data.sort((a: any, b: any) => {
      const aParsed = parseYearMonth(a);
      const bParsed = parseYearMonth(b);

      if (aParsed.year === bParsed.year) {
        return aParsed.month - bParsed.month;
      } else {
        return aParsed.year - bParsed.year;
      }
    })
  }

  const allMonths = monthsArr.sort((a:any, b:any) => a - b)
  const months = sortingMonthDataOrderWise(allMonths)

  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.DEALER_SUB_USER_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.DEALER_SUB_USER_EDIT}/${rowData["id"]}`);
  };

  const changeColumnDetails = (values: any[]) => {    
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { audioBrandName, monthYears } = eachDealer;
      let data: any = {};
      data["Audio Brand Name"] = audioBrandName,
      monthYears.forEach((reportItem: any) => {
          const { monthYear, stockDeatails } = reportItem;
          let monthYearFormat = formatMonthYear(monthYear)
          const date = moment(monthYearFormat, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          data[formattedDate] = stockDeatails?.closing;
        });
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };


  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    debugger
    const destructureedData = values.map((data) => {
      let datas: any = {
        audioBrandName: data.audioBrandName
      };
      const formatted = values.flatMap((data) => {
        if (data.monthYears?.length > 0) {
          return data.monthYears.map((newformat: any) => {
            let monthYearFormat = formatMonthYear(newformat.monthYear)
            return {
              month: monthYearFormat,
              value: newformat?.stockDeatails?.closing
            };
          });
        }
        return [];
      });
      datas[formatted[0].month] = formatted[0].value
      return datas;
    });

    const getColumnStyles = () => {
      const columnStyles: any = {};
      const month = months
      month.forEach((month:any) => {
        columnStyles[`closed${month}`] = { halign: 'right' };
      });
      return columnStyles;
    };
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then((autoTable) => {
        columns = columns.filter((a: any) => isNaN(Number(a?.field)))

        let exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));

        const row1 = new HtmlRowInput(document.createElement('tr'));

        row1.push({
          content: 'Supplier Name', styles: {
            textColor: 'white',
            halign: "center",
            valign: "middle"
          }, colSpan: 1, rowSpan: 2,
        });

        months?.forEach((month:any) => {
          const date = moment(month, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          row1.push({
            content: formattedDate, styles: {
              textColor: 'white',
              halign: "center",
              valign: "middle"
            }, colSpan: 1, rowSpan: 1
          });
        });

        const row2 = new HtmlRowInput(document.createElement('tr'));

        row2.push({
          content: "closed", styles: {
            textColor: 'white',
            halign: "center",
            valign: "middle"
          }, colSpan: 1, rowSpan: 1
        },
          {
            content: "closed", styles: {
              textColor: 'white',
              halign: "center",
              valign: "middle"
            }, colSpan: 1, rowSpan: 1
          }
        );

        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        const columnStyles = getColumnStyles();
        autoTable.default(doc, {
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          columnStyles: columnStyles,
          body: modifiedValues,
          showFoot: "everyPage",
          head: [row1, row2],
          tableWidth: "auto",
        });

        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const fetchData = async (isPdf: boolean) => {
    let state: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true)
      const response = await API.getStockQuantitybymusicSystemBrandAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      // let resp=await dealerListConverson(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(response?.data, columns, "stock quantity Audio brand");
      } else {
        saveDataAsExcel(response?.data, "stock quantity Audio brand");
      }
      setPdfLoading(false)
    } catch (error) {
      // console.log(error);
      setPdfLoading(false)

    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(values, "stock quantity Audio brand");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "stock quantity Audio brand");
    fetchData(true)
  };


  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };


  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <ColumnAction
        displayData={rowData[field] as string}
        isEdit={true}
        isDelete={true}
        isView={true}
        onViewClick={() => viewHandler(rowData)}
        onEditClick={() => editHandler(rowData)}
        onDeleteClick={() => deleteHandler(rowData)}
      />
    );
  };

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    )
  }

  //table column
  const columns: Columns[] = [
    {
      field: "audioBrandName",
      sortable: false,
      header: "Audio Brand Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: true,
      frozen: true,
      isStatic: true,
    },
    ...months.map((month: any) => ({
      field: `closing${month}`,
      sortable: false,
      header: `closing${month}`,
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => headerTemplate(rowData[`closing${month}`]),
      isStatic: false,
    }))
  ];

  const defaultColumns = {
    ...columns[0]
  };


  const visibleColumns = columns.filter((eachColumn: Columns) => eachColumn.isDefault)
  //customize table column
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');


  //archive handler
  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedStockQuantityMusicSystemBrand([]);
    setArchiveModal(false);
  };

  //customize table
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  //bulkaction click
  const bulkActionClickHandler = () => {
    // if (values.length > 0) {
    //   setSelectAll(true);
    //   setSelectedStockValueSupplier(values);
    // }
  };

  //cancel bulk option
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedStockQuantityMusicSystemBrand([]);
  };

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedStockQuantityMusicSystemBrand(value);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      setSelectAll(true);
      setSelectedStockQuantityMusicSystemBrand(values);
    } else {
      setSelectAll(false);
      setSelectedStockQuantityMusicSystemBrand([]);
    }
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setShowCustomizeTable(false);
  };
  useEffect(()=>{
    dispatch(getAudioBrandLoading())
      },[])
  //handle submit 
  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: formValue,
        }
      },
    }));
  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.DEALER_SUB_USER_CREATE);
  };

  //dropdown option
  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    {
      label: "Awaiting Login Approval",
      value: "Awaiting Login Approval",
    },
    {
      label: "Awaiting Verification",
      value: "Awaiting Verification",
    },
    {
      label: "Awaiting Confirmation",
      value: "Awaiting Confirmation",
    },
    {
      label: "Active Selected",
      value: "Active Selected",
    },
  ];

  //handle reset
  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  const audioBrandOptions=audioBrandDropdownList?.length>0 ? audioBrandDropdownList?.map((item:any)=>({
    label:item.brandName,
    value:item.id
  })) : []

  const calendarRef = useRef<any>(null);
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [startShowDate, setStartShowDate] = useState<any>()
  const [endShowDate, setEndShowDate] = useState<any>()
  const[audioBrand,setAudioBrand]=useState<any>()
  const[showAudioBrand,setShowAudioBrand]=useState<any>()
  const searchCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setStartShowDate(startDate)
    setEndShowDate(endDate)
    let name=audioBrandOptions.find((val:any)=>val.value==audioBrand)?.label
    setShowAudioBrand(name)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate:{value:startDate ? `${startDate}-01` : "" ,matchMode:"contains"},
        endDate:{value:endDate ? `${endDate}-01` : "" ,matchMode:"contains"},
        AudioBrandId:{value:audioBrand, matchMode:"contains"},
      },
    }));
    
  }
  const clearCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setEndDate(null)
    setStartDate(null)
    setEndShowDate(null)
    setStartShowDate(null)
    setAudioBrand(null)
    setShowAudioBrand(null)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate:{value:"",matchMode:"contains"},
        endDate:{value:"",matchMode:"contains"},
        AudioBrandId:{value:"",matchMode:"contains"},
      },
    }));
  }
  const clearFilter = () => {
    setEndDate(null)
    setStartDate(null)
    setEndShowDate(null)
    setStartShowDate(null)
    setAudioBrand(null)
    setShowAudioBrand(null)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate:{value:"",matchMode:"contains"},
        endDate:{value:"",matchMode:"contains"},
        AudioBrandId:{value:"",matchMode:"contains"},
      },
    }));
  }

  //table headeer potion
  const header =
    <div className="table-header-container">
      <div>{'Stock Quantity by Music System Brand'}</div>
      <div id="calendar" className="calendar_btns" style={{ marginLeft: "10px" }}>
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-soft-primary waves-effect waves-light"
              // onClick={toggleCalendar}
              onClick={(e) => calendarRef.current.toggle(e)}
            >
              {/* <i className="bx bx-calendar font-size-16 align-middle"></i> */}
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={calendarRef}>
        <div className="mb-2">
          <label htmlFor="">Select Start Month</label>
          <input
            className='form-control'
            type="month"
            name="month"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">Select End Month</label>
          <input
            className='form-control'
            type="month"
            name="month"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
            <label htmlFor="">Select Audio Brand</label>
            <Dropdown value={audioBrand} onChange={(e) => setAudioBrand(e.value)} options={audioBrandOptions} optionLabel="label" placeholder="Select Audio Brand" filter className="w-full md:w-14rem" />
          </div>
        <div className="p-column-filter-buttonbar">
          <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel>
      <div className="table-header-container-item align_width">
        <div className="m-2 d-flex flex-wrap gap-1 align_justify">
          {startShowDate &&
          <div className='job-basic-info'>
            <div className='title text-center' style={{ background: "#dff0ff", color: "#749be0", width: "auto", whiteSpace: "nowrap" }}>Start Month</div>
            <b className='m-auto text-center font-size-13' style={{ minWidth: "120px", whiteSpace: "nowrap" }}>{convertToMonthYear(startShowDate)}</b>
          </div>
}
          {endShowDate &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>End Month</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{convertToMonthYear(endShowDate)}</b>
            </div>
          }
          {showAudioBrand &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>Audio Brand</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{showAudioBrand}</b>
            </div>
          }
          </div>
          </div>

      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} TooltipPosition="left" clearFilter={clearFilter} isFilter={true}/>
      </div>

    </div>;



  //delete dealerSubUser
  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      lazyState,
    };
    // dispatch(deleteDealerSubuserLoading(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setTimeout(() => {
        // dispatch(getDealerSubuserListLoading(lazyState));
      }, 500);
      setSelectAll(false);
      setSelectedStockQuantityMusicSystemBrand([]);

    }
    showToast("StockValueSupplier Deleted Successfully", { type: "success" });
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getStockQuantityByMusicSystemBrandLoading(lazyState));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedStockQuantityMusicSystemBrand([]);
    callbackFunction(lazyState);
  }, [lazyState]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      // dispatch(clearPartsMessage());
    }
  }, [error, message]);
  const showResponse = (response: number) => {
    return response
  }

  const addColumn = (value: any[]) => {
    // value.forEach((data) => {
    //   if (data.reportData.length > 0) {
    //     data.reportData.map((newformat: any) => {
    //       const date = moment(newformat.month, 'YYYYMM');
    //       const formattedDate = date.format('MMM-YYYY');
    //       let object = {
    //         field: newformat.month,
    //         header: formattedDate,
    //         sortable: false,
    //         filter: false,
    //         showFilterMenu: false,
    //         headerStyle: { whiteSpace: "nowrap", width: "200px", marginLeft: "20px" },
    //         // body: () => showResponse(newformat.value),
    //         isDefault: true,
    //         isStatic: false,
    //       }
    //       // setVisibleColumns([...columns, object]);
    //     })
    //   }
    // })
  }

  useEffect(() => {
    if (values?.length > 0) {
      addColumn(values)
    }
  }, [values])

  const hidePopup = () => {
    setVisible(false);
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);

  const headerGroup = (
    <ColumnGroup>
      <Row >
        <Column header="Audio Barnd Name" />
        {months.map((month:any,index : number) => {
          const date = moment(month, 'YYYYMM');
          const formattedDate = date.format('MMM-YYYY');
          return (
            // <Column key={month} header={formattedDate} />
            <Column key={index} header={formattedDate} />
          );
        })}
      </Row>

      <Row>
        {/* <Column field="audioBrandName" />
        {months.map((month:any) => (
          <Column key={month + '_unused'} header="Unused" field={`unUsed_${month}`} />
        ))} */}
        <Column field="audioBrandName" />
        {months.map((month:any) => (
          <Column key={month + 'closing'} header="Closing" field={`closing${month}`} />
        ))}
      </Row>
    </ColumnGroup>
  );

  return (
    <React.Fragment>
      <div className="page-content" id="stock-music-system">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (selectedStockQuantityMusicSystemBrand?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedStockQuantityMusicSystemBrand([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedStockQuantityMusicSystemBrand.length == 1) {
              handleDeleteUsers(rowData || selectedStockQuantityMusicSystemBrand);
            } else if (selectedStockQuantityMusicSystemBrand.length > 1) {
              for (let i = 0; i < selectedStockQuantityMusicSystemBrand.length; i++) {
                handleDeleteUsers(selectedStockQuantityMusicSystemBrand[i], selectedStockQuantityMusicSystemBrand.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these administrators?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedStockQuantityMusicSystemBrand([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          reorderableColumns={false}
          header={header}

          value={modifiedValues}
          headerColumnGroup={headerGroup}

          stripedRows={true}
          rowHover={true}

          loading={loading}
          emptyMessage="No MusicSystem Brand found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"

        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default StockQuantityMusicSystemBrand