import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { deliveryType, status } from "src/constants/purchaseOrderFormFields";
import { StockAdjustmentReset, createStockAdjustment, deletePartsSkuList, getCourierDropdownLoading, getInitiatedByDropdown, getPartsSkuListforStock, getStockAdjustmentById, getSupplierDropdown, resetPartSkuIndex, resetStockAdjustemntMessage, updateStockAdjustment } from "src/store/stockAdjustment/actions";
import TooltipMEL from "src/components/UI/Tooltip";
import DropdownInput from "src/components/UI/DropdownInput";
import { formatDateTOYYYYMMDD } from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { RootReducerState } from "src/store/reducers";
import { DropdownChangeEvent } from "primereact/dropdown";
import StockAdjustmentScanner from "./StockAdjustmentScanner";
import TextEditor from "src/components/atoms/TextEditor";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FeatherIcon from "feather-icons-react";

const AddorEditStockAdjustment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue = useSelector((state: any) => state.StockAdjustmentReducer?.editOrder?.stockAdjustmentDto);
  const supplier = useSelector((state: RootReducerState) => state.StockAdjustmentReducer?.supplierList);
  const initiatedBy = useSelector((state: RootReducerState) => state.StockAdjustmentReducer?.initiatedBy);

  // const partSku = useSelector((state: any) => state.StockAdjustmentReducer.partSku);
  const { partSku, partSkuStockList, partsSkuStockIndex, partsSkuStockSetFieldValue,values } = useSelector((state: RootReducerState) => state.StockAdjustmentReducer);
  const { loading, error, message, courierList, push } = useSelector((state: RootReducerState) => state.StockAdjustmentReducer)



  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [openScanner, setOpenScanner] = useState<boolean>(false); //scanner implementation
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [partsAdjustmentDetails, setPartsAdjustmentDetails] = useState<any>({
    index: 0,
    setFieldValue: null,
    suplierId: "",
    push: () => { }
  })

  const [changeSkuValue, setChangeSkuValue] = useState<any[]>([]);
  const [stockAdjsutments, setStockAdustment] = useState<any>([{
    id: "",
    partSkuNumber: "",
    price: "",
    taxPercentage: "",
    taxPrice: "",
    partNumber: "",
    partDescription: "",
    partHsnNumber: "",
    totalPrice: ""
  }])


  useEffect(() => {
    return () => {
      dispatch(StockAdjustmentReset())
    }
  }, [])

  // const courierListDropdown: any[] = courierList?.length > 0 ? courierList?.map((data: any) => {
  //   if (data.companyName == "Other") {
  //     return {
  //       value: data.id,
  //       label: data.otherCompany,
  //     }
  //   } else {
  //     return {
  //       value: data.id,
  //       label: data.companyName,
  //     }
  //   }
  // }).sort((a: any, b: any) => {
  //   const labelA = a.label.toUpperCase().trim();
  //   const labelB = b.label.toUpperCase().trim();
  //   if (labelA < labelB) {
  //     return -1;
  //   }
  //   if (labelA > labelB) {
  //     return 1;
  //   }
  //   return 0;
  // }) : []

  const courierListDropdown = courierList?.length > 0 ? (courierList.map((eachCourier: any) => {
    let data: any = {};
    data['value'] = eachCourier.id;
    data['label'] = eachCourier.companyName == "Other" ? `${eachCourier.otherCompany} ${eachCourier?.packageReceiverName ? `courier :(${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}` : `${eachCourier.companyName} ${eachCourier?.packageReceiverName ? `courier : (${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}`;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];


  useEffect(() => {
    if (id) {
      setChangeSkuValue((prev: any) => {
        if (formValue?.stockAdjustmentDetailsDtos?.length > 0) {
          return formValue?.stockAdjustmentDetailsDtos?.map((eachValcue: any) => eachValcue.partSkuNo)
        }
        else {
          return [];
        }
      }
      )
    }
  }, [formValue])



  useEffect(() => {
    dispatch(getSupplierDropdown());
    dispatch(getInitiatedByDropdown());
    dispatch(getCourierDropdownLoading())
  }, []);

  // useEffect(() => {
  // debugger
  //   if (partSku) {
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.id`, 0);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.partSkuId`, partSku?.list?.id);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.price`, partSku?.list?.partPrice);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.taxPercentage`, partSku?.list?.partTaxPercentage);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.totalPrice`, partSku?.list?.totalPrice);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.partNumber`, partSku?.list?.partNumber);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.partDescription`, partSku?.list?.partDescription);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.partHsnNumber`, partSku?.list?.partHsnNumber);
  //     partSku.setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partSku.index}.partSkuNumber`, partSku?.list?.number);

  //     console.log(partSku);
  //     if(push){
  //       push({
  //         partSkuNumber: "",
  //         price: "",
  //         taxPercentage: "",
  //         taxPrice: "",
  //         partNumber: "",
  //         partDescription: "",
  //         partHsnNumber: "",
  //         totalPrice: ""
  //       })
  //     }
  //     dispatch(resetPartSkuIndex())
  //   }
  // }, [partSku]);


  useEffect(() => {
    if (partsSkuStockIndex != -1) {
      if(partSkuStockList?.length<2){
      // for (let i = 0; i < partSkuStockList?.length; i++) {
        // debugger
        // const formSkuNumbers = values?.stockAdjustmentDetailsCreateOrUpdateDtos?.filter((val:any)=>val.price !="")?.map((item:any) => item.partSkuId);
        // const filtered:any =formSkuNumbers.length>0 ?  partSkuStockList?.filter((item:any) => formSkuNumbers.includes(item.id)) : [];
        
        // if(filtered?.length<1){

          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.id`, 0);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partSkuId`, partSkuStockList[0]?.id);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.price`, partSkuStockList[0]?.partPrice);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.taxPercentage`, partSkuStockList[0]?.partTaxPercentage);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.totalPrice`, partSkuStockList[0]?.totalPrice);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partNumber`, partSkuStockList[0]?.partNumber);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partDescription`, partSkuStockList[0]?.partDescription);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partHsnNumber`, partSkuStockList[0]?.partHsnNumber);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partSkuNumber`, partSkuStockList[0]?.number);
          partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partId`, partSkuStockList[0]?.partId);
        // }
        if (push) {
          push({
            partSkuNumber: "",
            price: "",
            taxPercentage: "",
            taxPrice: "",
            partNumber: "",
            partDescription: "",
            partHsnNumber: "",
            totalPrice: ""
          })
        }
        dispatch(resetPartSkuIndex())
        // }else{
        //   showToast("This Sku already scanned",{type:"error"})
        //   dispatch(resetPartSkuIndex())
        // }
    }else{
      setDialogVisible(true)
    }
    }

  }, [partsSkuStockIndex])


  const supplierdropdownOptions = supplier?.map((item: any) => ({
    label: item.name,
    value: item.id
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });


  const initatedByOptions = initiatedBy?.map((item: any) => ({
    label: item.name,
    value: item.id
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (id) {
      dispatch(getStockAdjustmentById(id));
    } else {
      //   dispatch(getAudioBrandsListForParts());
    }
    return () => {
      setVisible(false)
    }
  }, [id]);


  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);

      // showToast(error, { type: "error" })
    }

    if (message) {
      const timeoutId = setTimeout(() => {
        setIsSubmitting(false);
        showToast(message, { type: "success" });
        navigate(ROUTER.STOCK_ADJUSTMENT_GRID);
      }, 500);
      dispatch(resetStockAdjustemntMessage())
    }
  }, [error, message]);


  const post = (formValue: any) => {


    dispatch(createStockAdjustment(formValue));
    // navigate(ROUTER.STOCK_ADJUSTMENT_GRID);
  };

  const update = (formValue: any) => {


    dispatch(updateStockAdjustment(formValue));
  };

  const initialvalue = formValue ? {
    number: formValue?.number || "",
    initiatedById: formValue?.initiatedById || "",
    status: formValue?.status || "",
    supplierId: formValue?.supplierId || "",
    totalGatePassValue: formValue?.totalGatepassValue || "",
    deliveryType: formValue?.deliveryType || "",
    deliveryDate: formatDateTOYYYYMMDD(formValue?.deliveryDate) || "",
    courierId: formValue?.courierId || null,
    comments: formValue?.comments || "",
    locked: formValue?.locked || false,
    total: formValue?.total || "",
    lockedDate: formValue?.lockedDate || "",
    stockAdjustmentDetailsCreateOrUpdateDtos: formValue?.stockAdjustmentDetailsDtos?.filter((eachOrder: any) => !eachOrder?.isDeleted).map((eachOrder: any) => {
      let data: any = {}
      data['id'] = eachOrder.id ? eachOrder.id : 0
      data['partSkuId'] = eachOrder.partSkuId ? eachOrder.partSkuId : 0
      data['stockAdjustmentId'] = eachOrder.stockAdjustmentId ? eachOrder.stockAdjustmentId : 0;
      data['price'] = eachOrder.price ? eachOrder.price : 0;
      data['taxPercentage'] = eachOrder.taxPercentage ? eachOrder.taxPercentage : 0;
      data['partNumber'] = eachOrder?.partNumber ? eachOrder.partNumber : 0;
      data['partDescription'] = eachOrder?.partDescription ? eachOrder.partDescription : 0;
      data['partHsnNumber'] = eachOrder?.partHsnNumber ? eachOrder?.partHsnNumber : 0;
      data['totalPrice'] = eachOrder.totalPrice ? eachOrder.totalPrice : 0;
      data['partSkuNumber'] = eachOrder.partSkuNo ? eachOrder.partSkuNo : 0
      data['partId'] = eachOrder.partId ? eachOrder.partId : 0
      return data
    })
  } : {

  }




  const initialvaluecreate = {
    number: "",
    initiatedById: "",
    status: "",
    supplierId: "",
    totalGatePassValue: "",
    deliveryType: "",
    deliveryDate: "",
    courierId: null,
    comments: "",
    locked: false,

    stockAdjustmentDetailsCreateOrUpdateDtos: [
      {
        id: "",
        partSkuId: "",
        partSkuNumber: "",
        price: "",
        taxPercentage: "",
        taxPrice: "",
        partNumber: "",
        partDescription: "",
        partHsnNumber: "",
        partId:""
      }
    ],
  };

  const today = new Date();
  const formattedToday = today.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData = {
      ...values
    };

    if (submittedData['deliveryType'] == "Hand Carry") {
      delete submittedData['courierId']
    }

    if (!submittedData['totalGatePassValue']) {
      submittedData['totalGatePassValue'] = 0
    }


    if (true) {
      if (id) {


        await update({ ...submittedData, id: id });
      } else {
        await post({ ...submittedData, total: 0, lockedDate: null, });
      }
    }
    // }

    setIsSubmitting(true)
  };



  const validatePartSkuNumber = (supplierId: any) => {
    return supplierId && supplierId.trim() !== ''; // Check if supplierId exists and is not empty after trimming
  };

  const isNotErrorUnique = (stockAdjustmentDetailsCreateOrUpdateDtos: any[], value: any) => {
    let test = stockAdjustmentDetailsCreateOrUpdateDtos?.filter((detail: any) => detail?.partNumber === value).length
    if (loading) {
      return true;
    } else {
      return test == 1;
    }
  }

  const isNotValid = (value: any) => {
    if (loading) {
      return true;
    } else {
      return !!value;
    }
  }



  const validationSchema = Yup.object().shape({
    initiatedById: Yup.string().required('Initiated By is required'),
    status: Yup.string().required('Status is required'),
    supplierId: Yup.string().required('Supplier is required'),
    deliveryType: Yup.string().required('Delivery Type is required'),
    deliveryDate: Yup.string().required('Delivery Date is required'),
    totalGatePassValue : Yup.number().integer(),
    courierId: Yup.string().test("courier field check", "Courier field required", function (value) {
      const deliveryType = this.parent.deliveryType;
      if (deliveryType === "Courier" && (value === null || value === undefined)) {
        return false;
      }
      return true;
    }).nullable(),
    stockAdjustmentDetailsCreateOrUpdateDtos: Yup.array().of(
      Yup.object().shape({
        partSkuNumber: Yup.string().required('Part sku number is required'),
          // .test('is-unique', 'Part sku number must be unique', function (value) {
          //   let stockAdjustmentDetailsCreateOrUpdateDtos: any = this.options?.context?.stockAdjustmentDetailsCreateOrUpdateDtos
          //   return isNotErrorUnique(stockAdjustmentDetailsCreateOrUpdateDtos, value);
          // })
          // .test('is-valid-sku', 'Part sku number must be valid', function (value) {
          //   // let test = this.parent?.partSkuId 
          //   // return !!test;
          //   return isNotValid(value);
          // }),
        partSkuId: Yup.string().required('Part sku number is required'),

      })
    )
  });

  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.STOCK_ADJUSTMENT_GRID)
    }
  }
  const hidePopup = () => {
    setVisible(false);
    dispatch(resetStockAdjustemntMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const isContactDetailFilled = (values: any) => {
    const contactDetails = values.stockAdjustmentDetailsCreateOrUpdateDtos || [];
    return contactDetails.every((contact: any) => {
      const { partHsnNumber, partDescription, partNumber, taxPrice, ...fieldsExceptAlternativeNumber } = contact;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };


  const PartsSkuApiCall = (e: any, index: number, setFieldValue: any, values: any) => {
    if (values.supplierId) {
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.id`, 0);
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partSkuId`, "");
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.price`, "");
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.taxPercentage`, "");
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.totalPrice`, "");
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partNumber`, "");
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partDescription`, "");
      setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partHsnNumber`, "");
      // dispatch(getPartsSkuListforStock({ supplierIds: values.supplierId, e, index, setFieldValue }))
    }
  }

  const clearFieldValues = (index: number, setFieldValue: any) => {
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.id`, 0);
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partSkuId`, "");
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.price`, "");
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.taxPercentage`, "");
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.totalPrice`, "");
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partNumber`, "");
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partDescription`, "");
    setFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partHsnNumber`, "");
  }

  const handleAddContactDetails = (values: any, push: any, setFieldTouched: any, clear = false) => {

    if (values.stockAdjustmentDetailsCreateOrUpdateDtos?.length == 1 && !isContactDetailFilled(values) && clear) {
      push({
        partSkuNumber: "",
        price: "",
        taxPercentage: "",
        taxPrice: "",
        partNumber: "",
        partDescription: "",
        partHsnNumber: "",
        totalPrice: ""
      });
    }

    if (isContactDetailFilled(values)) {
      push({
        partSkuNumber: "",
        price: "",
        taxPercentage: "",
        taxPrice: "",
        partNumber: "",
        partDescription: "",
        partHsnNumber: "",
        totalPrice: ""
      });
    } else {
      values.stockAdjustmentDetailsCreateOrUpdateDtos.forEach((_: any, index: number) => {
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partSkuNumber`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.price`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.taxPercentage`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.taxPrice`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partNumber`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partDescription`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partHsnNumber`, true);
        setFieldTouched(`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.totalPrice`, true);
      })

    };
  }

  const formRef = useRef<any>(null);
  const handleSetFieldValue = (fieldName: string, value: any) => {
    if (formRef.current) {
      formRef?.current?.setFieldValue(fieldName, value);
      // Manually mark all fields within the field array as touched
      const { current: formik } = formRef;
      const fieldArrayValues = formik.values[fieldName];
      const touched: any = {};
      fieldArrayValues.forEach((_: any, index: number) => {
        let name: string = `${fieldName}.${index}`
        touched[name] = false;
      });
      formik.setTouched(touched);
    }
  };

  const openScannerHandler = (index: any, values: any, setFieldValue: any, push: any) => {

    setPartsAdjustmentDetails({
      index: index,
      setFieldValue: setFieldValue,
      suplierId: values?.supplierId,
      push: push,
      values:values
    })
    setOpenScanner(true);
  };

  const scannerContainer = () => {
    return <StockAdjustmentScanner visible={openScanner} onHide={() => setOpenScanner(false)} stockDetails={partsAdjustmentDetails}/>;
  };

  // function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
  //   let timeout: NodeJS.Timeout;
  //   return function(this: any, ...args: Parameters<T>) {
  //     if (timeout) clearTimeout(timeout);
  //     timeout = setTimeout(() => func.apply(this, args), delay);
  //   };
  // }
  const handleKeyPress = (event: any, values: any, push: any, index?: number) => {

    if (event.which == 13 || event.which == 32) {
      event.preventDefault();
    }

if (!event?.target?.value) return;

let toScan = event.target.value;
if (event.key === 'Enter' || event.key === "Tab") {
//       let existingValues =values?.stockAdjustmentDetailsCreateOrUpdateDtos?.map((items:any)=>items.partSkuNumber)
//       let isExist = existingValues?.filter((item:any)=>item ==event.target.value)?.length > 1 ? true : false
// if(isExist){
//   return showToast("This Part Sku already scanned",{type:"error"})
// }else{

  dispatch(getPartsSkuListforStock({ supplierIds: partsAdjustmentDetails?.suplierId, e: event.target.value, index: partsAdjustmentDetails.index, setFieldValue: partsAdjustmentDetails?.setFieldValue, push,values }))
// }


    }

  };
  // const handleKeyPress = debounce((event: any, values: any, push: any) => {
  //   if (event.which === 13 || event.which === 32) {
  //     event.preventDefault();
  //   }
  //   console.log('event.which', event.which);

  //   if (!event.target.value) return;

  //   let toScan = event.target.value;

  //   if (event.key === 'Enter' || event.key === 'Tab') {
  //     console.log(partsAdjustmentDetails);
  //     console.log(partsAdjustmentDetails);
  //     dispatch(getPartsSkuListforStock({ supplierIds: partsAdjustmentDetails?.suplierId, e: event.target.value, index: partsAdjustmentDetails.index, setFieldValue: partsAdjustmentDetails?.setFieldValue, push }))
  //   }
  // }, 300);
  const[dialogVisible,setDialogVisible]=useState<boolean>(false)
  const onHide =() =>{
    setDialogVisible(false)
    dispatch(resetPartSkuIndex())
  }
  const renderButton = (rowData:any) => {
    return (
        <FeatherIcon icon="check-circle" style={{color:"green"}} onClick={() => handleClick(rowData)} />
    );
};

const handleClick = (rowData:any) => {
    debugger
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.id`, 0);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partSkuId`, rowData?.id);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.price`, rowData?.partPrice);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.taxPercentage`, rowData?.partTaxPercentage);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.totalPrice`, rowData?.totalPrice);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partNumber`, rowData?.partNumber);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partDescription`, rowData?.partDescription);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partHsnNumber`, rowData?.partHsnNumber);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partSkuNumber`, rowData?.number);
      partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partId`, rowData?.partId);
   setDialogVisible(false)
    if (push) {
      push({
        partSkuNumber: "",
        price: "",
        taxPercentage: "",
        taxPrice: "",
        partNumber: "",
        partDescription: "",
        partHsnNumber: "",
        totalPrice: ""
      })
    }
    dispatch(resetPartSkuIndex())
};

  const ChoosePart = () => {
    // console.log(partSkuStockList);
    // console.log(values)
    // const formSkuNumbers = values?.stockAdjustmentDetailsCreateOrUpdateDtos?.filter((val:any)=>val.price !="")?.map((item:any) => item.partSkuId);

    //     // Filter apValue
    //     const filtered:any = partSkuStockList?.filter(item => !formSkuNumbers.includes(item.id));
    //     let showScreen=(filtered.length>1) ? true : false
    //     if(filtered.length==1){
    //       debugger
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.id`, 0);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partSkuId`, filtered?.[0]?.id);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.price`, filtered?.[0]?.partPrice);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.taxPercentage`, filtered?.[0]?.partTaxPercentage);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.totalPrice`, filtered?.[0]?.totalPrice);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partNumber`, filtered?.[0]?.partNumber);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partDescription`, filtered?.[0]?.partDescription);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partHsnNumber`, filtered?.[0]?.partHsnNumber);
    //       partsSkuStockSetFieldValue(`stockAdjustmentDetailsCreateOrUpdateDtos.${partsSkuStockIndex}.partSkuNumber`, filtered?.[0]?.number);
    //    setDialogVisible(false)
    //     if (push) {
    //       push({
    //         partSkuNumber: "",
    //         price: "",
    //         taxPercentage: "",
    //         taxPrice: "",
    //         partNumber: "",
    //         partDescription: "",
    //         partHsnNumber: "",
    //         totalPrice: ""
    //       })
    //     }
    //     dispatch(resetPartSkuIndex())
    //     }else if(filtered?.length==0){
    //       showToast("This Sku already scanned",{type:"error"})
    //       setDialogVisible(false)
    //       dispatch(resetPartSkuIndex())
    //     }
    return (
      <>
      {/* {showScreen &&  */}
        <Dialog
          header="Choose Part"
          visible={dialogVisible}
          maximizable
          style={{ width: "auto" }}
          onHide={onHide}
        >
          <div>
            {/* {
              partSkuStockList?.map((items:any)=>{
                return(
                <>
                <div className="partList d-flex gap-2">
                  <b>Number : {items.partNumber}</b>
                  <b>Description : {items.partDescription}</b>
                  <b>Price : {items.partPrice}</b>
                  <b>Tax Percentage : {items.partTaxPercentage}</b>
                  <b>Total Price : {items.totalPrice}</b>
                </div>
                </>
                )
              })
            } */}
            <DataTable value={partSkuStockList} tableStyle={{ width: "auto" }}>
              <Column field="partNumber" header="Number"></Column>
              <Column field="partDescription" header="Description"></Column>
              <Column field="partPrice" header="Price"></Column>
              <Column field="partTaxPercentage" header="Tax Percentage"></Column>
              <Column field="totalPrice" header="Total Price"></Column>
              <Column header="Select" body={renderButton}></Column>
            </DataTable>
            
  
          </div>
        </Dialog>
        {/* } */}
      </>
    );
  };
  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      {openScanner && scannerContainer()}
      {dialogVisible && ChoosePart()}
      <DeleteModal
        show={deleteModal || false}
        showIcon={false}
        message="Are you sure want to delete this Stock Adjustment"
        onDeleteClick={() => {
          // deleteContact
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Stock Adjustment"
            titlePath={ROUTER.STOCK_ADJUSTMENT_GRID}
            breadcrumbItem={id ? "Edit Stock Adjustment" : "Create Stock Adjustment"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={id ? initialvalue : initialvaluecreate}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldValue,
                      setFieldTouched,
                      ...rest
                    }: any) => {


                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="number"
                                disabled={true}
                                onBlur={handleBlur}
                                value={values.number}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["number"] && errors["number"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={initatedByOptions}
                                name="initiatedById"
                                label="Initiated By"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.initiatedById}
                                invalid={
                                  touched["initiatedById"] && errors["initiatedById"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">

                              <DropdownInput
                                options={supplierdropdownOptions}
                                name="supplierId"
                                label="Supplier"
                                onChange={(event: DropdownChangeEvent) => {
                                  handleSetFieldValue("stockAdjustmentDetailsCreateOrUpdateDtos", [
                                    {
                                      partSkuNumber: "",
                                      price: "",
                                      partNumber: "",
                                      partDescription: "",
                                      partHsnNumber: "",
                                      partSkuId: "",
                                      taxPercentage: "",
                                      totalPrice: ""
                                    }
                                  ])
                                  handleChange(event);
                                }
                                }
                                onBlur={handleBlur}
                                onFocusOut={handleBlur}
                                required={true}
                                value={values.supplierId}
                                disabled={id ? true : false}
                                invalid={
                                  touched["supplierId"] && errors["supplierId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">

                              <DropdownInput
                                options={status}
                                name="status"
                                label="Status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.status}
                                invalid={
                                  touched["status"] && errors["status"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Total Gate Pass Value"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="totalGatePassValue"
                                onBlur={handleBlur}
                                value={values.totalGatePassValue}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["totalGatePassValue"] && errors["totalGatePassValue"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">

                              <DropdownInput
                                options={deliveryType}
                                name="deliveryType"
                                label="Delivery Type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.deliveryType}
                                invalid={
                                  touched["deliveryType"] && errors["deliveryType"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Delivery Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="deliveryDate"
                                onBlur={handleBlur}
                                value={values.deliveryDate}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["deliveryDate"] && errors["deliveryDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={courierListDropdown}
                                name="courierId"
                                label="Courier"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={values.deliveryType == "Courier" ? true : false}
                                disabled={values.deliveryType == "HandCarry" ? true : false}
                                value={values.deliveryType === "HandCarry" ? "" : values?.courierId}
                                invalid={
                                  touched["courierId"] && errors["courierId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Comments"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="comments"
                                onBlur={handleBlur}
                                value={values.comments}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["comments"] && errors["comments"]
                                    ? true
                                    : false
                                }
                              /> */}
                              <TextEditor
                                onChange={handleChange}
                                labelName='Comments'
                                name='comments'
                                required={false}
                                value={values.comments}
                                invalid={touched["comments"] && errors["comments"]
                                ? true
                                : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2 d-flex align-items-center">
                              <CheckboxInput
                                name="locked"
                                label="Locked"
                                value={values["locked"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                          </Row>

                          {/* dynamic field */}
                          <Row>
                            <hr />
                            <Col lg={12} className="dealer-title-header">
                              <div>Add Parts SKU's {!values.supplierId && <span className="info-msg" >- Please select supplier </span>}</div>
                            </Col>
                            {/* dynamic field added */}
                            <FieldArray name="stockAdjustmentDetailsCreateOrUpdateDtos">
                              {({ insert, remove, push }) => (
                                <div >
                                  {values.stockAdjustmentDetailsCreateOrUpdateDtos?.length > 0 &&
                                    values.stockAdjustmentDetailsCreateOrUpdateDtos.map(
                                      (value: any, index: any) => {
                                        const isLastContact = index === values.stockAdjustmentDetailsCreateOrUpdateDtos.length - 1;
                                        const isFirstContact = index === 0;
                                        const stockAdjustmentss = stockAdjsutments;
                                        return <div key={index}>
                                          <div key={index} className="dynamic-form-container">

                                            <div className="space-equally-3">
                                              <InputCom
                                                labelName="Part SKU Number"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="text"
                                                cameraIcon={true}
                                                camerClick={() => openScannerHandler(index, values, setFieldValue, push)}
                                                camerIconDisable={(!value.partNumber && values.supplierId )? false : true}
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partSkuNumber`}
                                                disabled={(!value.partNumber && values.supplierId )? false : true}
                                                onFocus={() => setPartsAdjustmentDetails({ index: index, setFieldValue: setFieldValue, suplierId: values?.supplierId, push: push })}
                                                onKeyDown={(event: any) => {
                                                  setPartsAdjustmentDetails({ index: index, setFieldValue: setFieldValue, suplierId: values?.supplierId, push: push,values:values });
                                                  handleKeyPress(event, values, push, index)
                                                }}
                                                onBlur={(event) => {
                                                  handleBlur(event);
                                                  //   if (value?.partSkuNumber.trim()) {
                                                  //     console.log("value?.partSkuNumber.trim()", value?.partSkuNumber.trim())
                                                  //     PartsSkuApiCall(value?.partSkuNumber, index, setFieldValue, values);
                                                  //   } else {
                                                  //     clearFieldValues(index, setFieldValue)
                                                  //   }
                                                  // }
                                                }
                                                }
                                                autoFocus={values.stockAdjustmentDetailsCreateOrUpdateDtos.length - 1 == index ? true : false}
                                                value={value.partSkuNumber}
                                                onChange={handleChange}
                                                isRequired={true}
                                                loading={loading}
                                                invalid={
                                                  (touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]?.partSkuNumber &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]?.partSkuNumber && !loading)
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3 d-none">
                                              <InputCom
                                                labelName="id"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="text"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.id`}
                                                disabled={true}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={false}
                                                value={value.id}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                    index
                                                  ]?.id &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                      ?.id
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3 d-none">
                                              <InputCom
                                                labelName="partSkuId"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="text"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partSkuId`}
                                                disabled={true}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={false}
                                                value={value.partSkuId}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                    index
                                                  ]?.partSkuId &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                      ?.partSkuId
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3">
                                              <InputCom
                                                labelName="Price"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="text"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.price`}
                                                disabled={true}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={false}
                                                value={value.price}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                    index
                                                  ]?.price &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                      ?.price
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3">
                                              <InputCom
                                                labelName="Tax Percentage"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="number"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.taxPercentage`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={false}
                                                value={value.taxPercentage}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                    index
                                                  ]?.taxPercentage &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                      ?.taxPercentage
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3">
                                              <InputCom
                                                labelName="Total Price"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="number"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.totalPrice`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={false}
                                                value={value.totalPrice}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                    index
                                                  ]?.totalPrice &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                      ?.totalPrice
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3">
                                              <InputCom
                                                labelName="Part Number"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="text"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partNumber`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={false}
                                                value={value.partNumber}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]?.partNumber
                                                    && errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]?.partNumber ? true : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3">
                                              <InputCom
                                                labelName="Part Description"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                type="text"
                                                name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partDescription`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={false}
                                                value={value.partDescription}
                                                invalid={
                                                  touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                    index
                                                  ]?.partDescription &&
                                                    errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                      ?.partDescription
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="space-equally-3">
                                              <div className="d-flex align-items-end">
                                                <div className="d-block w-100">
                                                  <InputCom
                                                    labelName="Part HSN Number"
                                                    inputclassName="form-control"
                                                    labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"} label-noWrap`}
                                                    type="text"
                                                    name={`stockAdjustmentDetailsCreateOrUpdateDtos.${index}.partHsnNumber`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={true}
                                                    isRequired={false}
                                                    value={value.partHsnNumber}
                                                    invalid={
                                                      touched.stockAdjustmentDetailsCreateOrUpdateDtos?.[
                                                        index
                                                      ]?.partHsnNumber &&
                                                        errors.stockAdjustmentDetailsCreateOrUpdateDtos?.[index]
                                                          ?.partHsnNumber
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </div>
                                                <div className="justify-content-center remove-tooltip-margin">
                                                  <TooltipMEL title="Remove part" position="bottom">
                                                    {values.stockAdjustmentDetailsCreateOrUpdateDtos.length > 0 &&
                                                      <i className="fas fa-times-circle color-red"
                                                        onClick={() => {
                                                          dispatch(deletePartsSkuList(index))
                                                          remove(index);
                                                          if (values.stockAdjustmentDetailsCreateOrUpdateDtos.length == 1) {
                                                            handleAddContactDetails(values, push, setFieldTouched, true);
                                                          }
                                                        }
                                                        }></i>
                                                    }
                                                  </TooltipMEL>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="multiadd-container">
                                            {isLastContact &&
                                              <div className="add-btn-container" onClick={() => { handleAddContactDetails(values, push, setFieldTouched); }}>
                                                <TooltipMEL title="Add Parts" position='bottom'>
                                                  <i className="fas fa-plus-circle"></i><span>Add</span>
                                                </TooltipMEL>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      }
                                    )}
                                  <hr className="mt-1" />
                                </div>
                              )}
                            </FieldArray>
                          </Row>
                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Stock Adjustment" : "Create Stock Adjustment"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};


export default AddorEditStockAdjustment;
