import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classname from "classnames";

import withRouter from "../Common/withRouter";
import { toggleLeftmenu, showRightSidebarAction } from "../../store/actions";
//Import Icons
import FeatherIcon from "feather-icons-react";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { menu } from "../../constants/sideMenu";
import { each } from "chart.js/dist/helpers/helpers.core";
import { ROUTER } from "src/constants/routes";
import { hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler, hasDealerRoleHandler, hasFranchiseRoleHandler } from "src/helpers/common_helpers";
import { UserTypes } from "src/constants/userRoles";

const Navbar = (props: any) => {
  const role: any = localStorage.getItem("role") || '';
  const leftmenuData = createSelector(

    (state: any) => state.Layout,
    (layout) => ({
      leftMenu: layout.leftMenu,
    })
  );
  // Inside your component
  const { leftMenu } = useSelector(leftmenuData);

  const [ui, setui] = useState<boolean>(false);
  const [app, setapp] = useState<boolean>(false);
  const [jobsheet, setjobsheet] = useState<boolean>(false);
  const [dispatchMenu, setDispatchMenu] = useState<boolean>(false);
  const [master, setMaster] = useState<boolean>(false);
  const [people, setPeople] = useState<boolean>(false);
  const [stock, setStock] = useState<boolean>(false);
  const [reports, setReports] = useState<boolean>(false);
  const [tickets, setTickets] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<boolean>(false);
  const [incentive, setIncentive] = useState<boolean>(false);
  const [showRemainMenu, setShowRemainMenu] = useState<boolean>(false);
  const [screenResolution, setScreenResolution] = useState({ width: window.innerWidth, });
  const [dynamicMenu, setdynamicMenu] = useState<any>([]);
  const [remaindynamicMenu, setremaindynamicMenu] = useState<any>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const submenuRef = useRef<HTMLDivElement>(null)
  const newSubMenu = useRef<HTMLDivElement>(null)
  const [activePath, setActivePath] = useState("");
  const [isClick, setClick] = useState<boolean>(true);

  useEffect(() => {
    const handleResize = () => {
      setScreenResolution({
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    setScreenResolution({
      width: window.innerWidth,
    });

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowRemainMenu(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (newSubMenu.current && !newSubMenu.current.contains(event.target as Node)) {
        setapp(false);
        let data = remaindynamicMenu.map((item: any, i: any) => {
          return { ...item, show: !item.show };
        })
        setremaindynamicMenu(data)
        let datas = dynamicMenu.map((item: any, i: any) => {
          return { ...item, show: false };
        })
        setdynamicMenu(datas)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dynamicMenu, remaindynamicMenu]);



  const dispatch = useDispatch();
  const jobtitle = hasDealerRoleHandler() ? "Service Request List" : "Job Sheets";
  const menu = useMemo(() => [
    {
      title: "Dashboard",
      path: ROUTER.DASHBOARD,
      icon: "home",
      isAllow: true,
      onClick: null,
      children: [],
      access: [UserTypes.admin, UserTypes.Employee],
    },
    {
      title: "Job Sheets",
      path: ROUTER.JOB_SHEETS_GRID,
      icon: "clipboard",
      isAllow: true,
      setState: setjobsheet,
      onClick: (e: any, setjobsheet: any) => {
        e.preventDefault();
        setjobsheet(!jobsheet);
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      activePaths: [ROUTER.JOB_SHEETS_GRID, ROUTER.JOB_SHEETS_CREATE, ROUTER.JOB_SHEETS_EDIT, ROUTER.JOB_SHEETS_VIEW, ROUTER.FEEDBACK_CREATE, ROUTER.FEEDBACK_GRID, ROUTER.FEEDBACK_VIEW, ROUTER.ENQUIRY_GRID, ROUTER.ENQUIRY_CREATE, ROUTER.ENQUIRY_VIEW],
      children: [
        {
          title: jobtitle,
          path: ROUTER.JOB_SHEETS_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Franchise, UserTypes.Dealer, UserTypes.Employee, UserTypes.Customer, UserTypes.DealerSubUser,UserTypes.Manufacturer],
          activePaths: [ROUTER.JOB_SHEETS_GRID, ROUTER.JOB_SHEETS_CREATE, ROUTER.JOB_SHEETS_EDIT, ROUTER.JOB_SHEETS_VIEW]
        },
        {
          title: "Enquiries",
          path: ROUTER.ENQUIRY_GRID,
          access: [UserTypes.admin, UserTypes.Franchise, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.ENQUIRY_GRID, ROUTER.ENQUIRY_CREATE, ROUTER.ENQUIRY_VIEW]
        },
        {
          title: "Feedbacks",
          path: ROUTER.FEEDBACK_GRID,
          access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee, UserTypes.Customer],
          activePaths: [ROUTER.FEEDBACK_CREATE, ROUTER.FEEDBACK_GRID, ROUTER.FEEDBACK_VIEW],
          isAllow: true,
        },
      ],
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise,UserTypes.FranchiseSubUser, UserTypes.Dealer, UserTypes.Customer, UserTypes.DealerSubUser,UserTypes.Manufacturer],
    },
    {
      title: "Dispatches",
      path: ROUTER.INCOMING_DISPATCH,
      icon: "truck",
      isAllow: true,
      setState: setDispatchMenu,
      onClick: (e: any, setDispatchMenu: any) => {
        e.preventDefault();
        setDispatchMenu(!dispatchMenu);
        setapp(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      children: [
        {
          title: "Incoming Dispatches",
          path: ROUTER.INCOMING_DISPATCH,
          access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee],
          activePaths: [ROUTER.INCOMING_DISPATCH, ROUTER.VIEW_INCOMING_DISPATCH],
          isAllow: true,
        },
        {
          title: "Outgoing Dispatches",
          path: ROUTER.OUTCOMING_DISPATCH,
          access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee],
          activePaths: [ROUTER.OUTCOMING_DISPATCH, ROUTER.VIEW_OUTGOING_DISPATCH],
          isAllow: true,
        },
        {
          title: "Couriers",
          path: ROUTER.COURIER_LIST,
          access: [UserTypes.admin, UserTypes.Dealer, UserTypes.Employee],
          activePaths: [ROUTER.COURIER_LIST, ROUTER.VIEW_COURIER, ROUTER.EDIT_COURIER, ROUTER.CREATE_COURIER],
          isAllow: true,
        },
      ],
      access: [UserTypes.admin, UserTypes.Employee],
    },
    {
      title: "Invoices",
      path: ROUTER.INVOICE_GRID,
      icon: "file-text",
      isAllow: true,
      onClick: null,
      children: [],
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Dealer, UserTypes.Customer],
      activePaths: [ROUTER.INVOICE_GRID, ROUTER.INVOICE_EDIT, ROUTER.INVOICE_CREATE, ROUTER.INVOICE_VIEW],
    },
    {
      title: "Received Payments",
      path: ROUTER.RECEIVED_PAYMENT_LIST,
      icon: "briefcase",
      isAllow: true,
      onClick: null,
      children: [],
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Customer, UserTypes.Dealer],
      activePaths: [ROUTER.RECEIVED_PAYMENT_LIST, ROUTER.RECEIVED_PAYMENT_VIEW, ROUTER.RECEIVED_PAYMENT_CREATE],
    },
    {
      title: "Master Mgmt",
      path: "/#",
      icon: "box",
      isAllow: true,
      setState: setMaster,
      onClick: (e: any, setMaster: any) => {
        e.preventDefault();
        setMaster(!master);
        setapp(false)
        setDispatchMenu(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      children: [
        {
          title: "Audio Brands",
          path: ROUTER.AUDIO_BRANDS_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.AUDIO_BRANDS_GRID, ROUTER.AUDIO_BRANDS_CREATE, ROUTER.AUDIO_BRANDS_EDIT, ROUTER.AUDIO_BRANDS_VIEW],
          isAllow: true,
        },
        {
          title: "Audio Models",
          path: ROUTER.AUDIO_MODELS_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.AUDIO_MODELS_GRID, ROUTER.AUDIO_MODELS_CREATE, ROUTER.AUDIO_MODELS_EDIT, ROUTER.AUDIO_MODELS_VIEW]
        },
        {
          title: "Part SKUs",
          path: ROUTER.PARTS_SKU,
          access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
          isAllow: true,
          activePaths: [ROUTER.PARTS_SKU],
        },
        {
          title: "Parts",
          path: ROUTER.PARTS_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.PARTS_GRID, ROUTER.PARTS_CREATE, ROUTER.PARTS_EDIT]
        },
        {
          title: "Taxes",
          path: ROUTER.TAXES_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.TAXES_GRID, ROUTER.TAXES_CREATE, ROUTER.TAXES_EDIT, ROUTER.TAXES_VIEW]
        },
        {
          title: "Vehicle Brands",
          path: ROUTER.VEHICLE_BRANDS_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.VEHICLE_BRANDS_GRID, ROUTER.VEHICLE_BRANDS_CREATE, ROUTER.VEHICLE_BRANDS_EDIT, ROUTER.VEHICLE_BRANDS_VIEW]
        },
        {
          title: "Supplier",
          path: ROUTER.SUPPLIER_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.SUPPLIER_GRID, ROUTER.SUPPLIER_CREATE, ROUTER.SUPPLIER_EDIT, ROUTER.SUPPLIER_VIEW]
        },
      ],
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
    },
    {
      title: "People Mgmt",
      path: "/#",
      icon: "users",
      isAllow: true,
      setState: setPeople,
      onClick: (e: any, setPeople: any) => {
        e.preventDefault();
        setPeople(!people);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setStock(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer],
      children: [
        {
          title: "Customers",
          path: ROUTER.CUSTOMERS_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.CUSTOMERS_GRID, ROUTER.CUSTOMERS_CREATE, ROUTER.CUSTOMERS_VIEW, ROUTER.CUSTOMERS_EDIT]
        },
        {
          title: "Dealers",
          path: ROUTER.DEALERS_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.DEALERS_GRID, ROUTER.DEALERS_CREATE, ROUTER.DEALERS_EDIT, ROUTER.DEALERS_VIEW]
        },
        {
          title: "Employees",
          path: ROUTER.USERS_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.USERS_GRID, ROUTER.USERS_CREATE, ROUTER.USERS_EDIT, ROUTER.USERS_VIEW]
        },
        {
          title: "Franchise",
          path: ROUTER.FRANCHISEE_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.FRANCHISEE_GRID, ROUTER.FRANCHISEE_CREATE, ROUTER.FRANCHISEE_EDIT, ROUTER.FRANCHISEE_VIEW]
        },
        {
          title: "Franchise Sub User",
          path: ROUTER.SUB_USER_GRID,
          isAllow: true,
          access: [UserTypes.Franchise],
          activePaths: [ROUTER.SUB_USER_GRID, ROUTER.SUB_USER_CREATE, ROUTER.SUB_USER_EDIT, ROUTER.SUB_USER_VIEW]
        },
        {
          title: "Dealer Sub User",
          path: ROUTER.DEALER_SUB_USER_GRID,
          isAllow: true,
          access: [UserTypes.Dealer],
          activePaths: [ROUTER.DEALER_SUB_USER_GRID, ROUTER.DEALER_SUB_USER_CREATE, ROUTER.DEALER_SUB_USER_EDIT, ROUTER.DEALER_SUB_USER_VIEW]
        },
        {
          title: "Manufacturer",
          path: ROUTER.MANUFACTURER_GRID,
          isAllow: true,
          access: [UserTypes.admin,UserTypes.Employee],
          activePaths: [ROUTER.MANUFACTURER_GRID, ROUTER.MANUFACTURER_CREATE, ROUTER.MANUFACTURER_EDIT, ROUTER.MANUFACTURER_VIEW]
        },
      ],
    },
    {
      title: "Stock Mgmt",
      path: "/#",
      icon: "pie-chart",
      isAllow: true,
      setState: setStock,
      onClick: (e: any, setStock: any) => {
        e.preventDefault();
        setStock(!stock);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
      children: [
        {
          title: "Purchase Orders",
          path: ROUTER.PURCHASE_ORDER_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.PURCHASE_ORDER_GRID, ROUTER.PURCHASE_ORDER_CREATE, ROUTER.PURCHASE_ORDER_EDIT, ROUTER.PURCHASE_ORDER_VIEW]
        },
        {
          title: "Parts Inbound",
          path: ROUTER.PARTS_INBOUND_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.PARTS_INBOUND_GRID, ROUTER.PARTS_INBOUND_CREATE, ROUTER.PARTS_INBOUND_EDIT, ROUTER.PARTS_INBOUND_VIEW, ROUTER.PARTS_SKU_GENERATOR]
        },
        {
          title: "Intend",
          path: ROUTER.INTENT_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
          activePaths: [ROUTER.INTENT_GRID, ROUTER.INTENT_EDIT, ROUTER.INTENT_CREATE, ROUTER.INTENT_VIEW]
        },
        {
          title: "Stock Adjustment",
          path: ROUTER.STOCK_ADJUSTMENT_GRID,
          isAllow: true,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_ADJUSTMENT_GRID, ROUTER.STOCK_ADJUSTMENT_CREATE, ROUTER.STOCK_ADJUSTMENT_EDIT, ROUTER.STOCK_ADJUSTMENT_VIEW]
        },
        {
          title: "Stock Register",
          path: ROUTER.STOCK_REGISTER_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.STOCK_REGISTER_GRID]
        },
        {
          title: "Stock Inspection",
          path: ROUTER.STOCK_INSPECTION_GRID,
          access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise],
          isAllow: true,
          activePaths: [ROUTER.STOCK_INSPECTION_GRID, ROUTER.STOCK_INSPECTION_CREATE, ROUTER.STOCK_INSPECTION_EDIT, ROUTER.STOCK_INSPECTION_VIEW]
        },
        {
          title: "Stock Location",
          path: ROUTER.STOCK_LOCATION_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          isAllow: true,
          activePaths: [ROUTER.STOCK_LOCATION_GRID, ROUTER.STOCK_LOCATION_CREATE, ROUTER.STOCK_LOCATION_EDIT, ROUTER.STOCK_LOCATION_VIEW]
        },
      ],
    },
    {title: "Stock Reports",
      path: "/#",
      icon: "airplay",
      isAllow: true,
      setState: setStock,
      onClick: (e: any, setStock: any) => {
        e.preventDefault();
        setStock(!stock);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setReports(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      access: [UserTypes.admin, UserTypes.Employee],
      children: [
        {
          title: "Stock Value by Supplier",
          path: ROUTER.STOCK_VALUE_BY_SUPPLIER_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_VALUE_BY_SUPPLIER_GRID],
          isAllow: true,
        },
        {
          title: "Stock Qty by Supplier",
          path: ROUTER.STOCK_QUANTITY_BY_SUPPLIER_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_QUANTITY_BY_SUPPLIER_GRID],
          isAllow: true,
        },
        {
          title: "Stock Value by Musicsystem brand",
          path: ROUTER.STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_VALUE_BY_MUSIC_SYSTEM_BRAND_GRID],
          isAllow: true,
        },
        {
          title: "Stock Qty by Musicsystem brand",
          path: ROUTER.STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_QUANTITY_BY_MUSIC_SYSTEM_BRAND_GRID],
          isAllow: true,
        },
        {
          title: "Stock Value by Part",
          path: ROUTER.STOCK_VALUE_BY_PART_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_VALUE_BY_PART_GRID],
          isAllow: true,
        },
        {
          title: "Stock Qty by Part",
          path: ROUTER.STOCK_QUANTITY_BY_PART_GRID,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.STOCK_QUANTITY_BY_PART_GRID],
          isAllow: true,
        },
      ]
    },
    {
      title: "Reports",
      path: "/#",
      icon: "bar-chart-2",
      isAllow: true,
      setState: setReports,
      onClick: (e: any, setReports: any) => {
        e.preventDefault();
        setReports(!reports);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setTickets(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer, UserTypes.FranchiseSubUser, UserTypes.DealerSubUser],
      children: [
        {
          title: "Invoice Item Wise Registers",
          path: ROUTER.INVOICE_ITEM_WISE_REGISTERS_GRID,
          // path: "",
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.INVOICE_ITEM_WISE_REGISTERS_GRID],
          isAllow: true,
        },
        {
          title: "Job Sheet Reports",
          path: ROUTER.JOB_SHEET_REPORTS,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.JOB_SHEET_REPORTS],
          isAllow: true,
        },
        {
          title: "Job Sheet TAT Reports",
          path: ROUTER.JOB_SHEET_TAT_REPORTS,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.JOB_SHEET_TAT_REPORTS],
          isAllow: true,
        },
        {
          title: "Monthly Parts Summary",
          path: ROUTER.MONTHLY_PARTS_SUMMARY,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.MONTHLY_PARTS_SUMMARY],
          isAllow: true,
        },
        {
          title: "Pending Parts",
          path: ROUTER.PENDING_PARTS,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.PENDING_PARTS],
          isAllow: true,
        },
        {
          title: "Pending Parts (Consolidated)",
          path: ROUTER.PENDING_PARTS_CONSOLIDATED,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.PENDING_PARTS_CONSOLIDATED],
          isAllow: true,
        },
        {
          title: "Attendance Reports",
          path: ROUTER.ATTENDANCE_REPORT,
          access: [UserTypes.admin, UserTypes.Employee, UserTypes.Franchise, UserTypes.Dealer, UserTypes.FranchiseSubUser, UserTypes.DealerSubUser],
          activePaths: [ROUTER.ATTENDANCE_REPORT],
          isAllow: true,
        },
      ],
    },
    {
      title: "Show Attendance",
      path: ROUTER.SHOW_ATTENDANCE,
      icon: "calendar",
      isAllow: true,
      dropdown: false,
      activePaths: [ROUTER.SHOW_ATTENDANCE],
      onClick: null,
      children: [],
      access: [UserTypes.admin, UserTypes.Employee],
    },
    {
      title: "Ticket System",
      path: "/#",
      icon: "help-circle",
      isAllow: false,
      dropdown: true,
      setState: setTickets,
      onClick: (e: any, setTickets: any) => {
        e.preventDefault();
        setTickets(!tickets);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setMarketing(false)
        setIncentive(false)
      },
      show: false,
      access: [UserTypes.admin, UserTypes.Employee, UserTypes.Customer],
      children: [
        {
          title: hasCustomerRoleHandler() ? "My tickets" : "All tickets",
          path: ROUTER.TICKET_SYSTEM,
          access: [UserTypes.admin, UserTypes.Employee, UserTypes.Customer],
          activePaths: [ROUTER.TICKET_SYSTEM],
          isAllow: false,
        },
      ]
    },
    {
      title: "Marketing",
      path: "/#",
      icon: "mail",
      isAllow: true,
      dropdown: true,
      setState: setMarketing,
      onClick: (e: any, setMarketing: any) => {
        e.preventDefault();
        setMarketing(!marketing);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setTickets(false)
        setIncentive(false)
      },
      show: false,
      access: [UserTypes.Employee, UserTypes.admin],
      activePaths: [ROUTER.BULK_EMAIL, ROUTER.BULK_EMAIL_SENDER, ROUTER.BULK_EMAIL_VIEW, ROUTER.BULK_SMS, ROUTER.BULK_SMS_SENDER, ROUTER.BULK_SMS_VIEW],
      children: [
        {
          title: "Bulk Email",
          path: ROUTER.BULK_EMAIL,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.BULK_EMAIL, ROUTER.BULK_EMAIL_SENDER, ROUTER.BULK_EMAIL_VIEW],
          isAllow: true,
        },
        {
          title: "Bulk Sms",
          path: ROUTER.BULK_SMS,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.BULK_SMS, ROUTER.BULK_SMS_SENDER, ROUTER.BULK_SMS_VIEW],
          isAllow: true,
        },
        {
          title: "Whats App",
          path: ROUTER.WHATSAPP_MESSAGE_LIST,
          access: [UserTypes.admin, UserTypes.Employee],
          activePaths: [ROUTER.WHATSAPP_MESSAGE_LIST, ROUTER.WHATSAPP_MESSAGE_SENDER, ROUTER.WHATSAPP_MESSAGE_VIEW],
          isAllow: true,
        },
      ]
    },
    {
      title: "Settings",
      path: "/#",
      icon: "settings",
      isAllow: true,
      access: [UserTypes.Employee, UserTypes.admin, UserTypes.Franchise],
      activePaths: [ROUTER.INCENTIVE_SYSTEM, ROUTER.REWARD_VALUE, ROUTER.TOTAL_WORKING_DAYS, ROUTER.TOTAL_WORKING_DAYS_CREATE, ROUTER.TOTAL_WORKING_DAYS_EDIT],
      setState: setIncentive,
      onClick: (e: any, setMarketing: any) => {
        e.preventDefault();
        setIncentive(!incentive);
        setMarketing(false);
        setapp(false)
        setDispatchMenu(false)
        setMaster(false)
        setPeople(false)
        setStock(false)
        setReports(false)
        setTickets(false)
      },
      children: [
        {
          title: "Permission",
          path: ROUTER.PERMISSION,
          isAllow: true,
          access: [UserTypes.admin],
          activePaths: [ROUTER.PERMISSION],
        },
        {
          title: "Incentive",
          path: ROUTER.INCENTIVE_SYSTEM,
          isAllow: true,
          access: [UserTypes.Employee, UserTypes.admin],
          activePaths: [ROUTER.INCENTIVE_SYSTEM],
        },
        {
          title: "Incentive Reports",
          path: ROUTER.INCENTIVE_REWARD_SYSTEM,
          isAllow: true,
          access: [UserTypes.Employee, UserTypes.admin, UserTypes.Franchise],
          activePaths: [ROUTER.INCENTIVE_REWARD_SYSTEM, ROUTER.INCENTIVE_REWARD_SYSTEM_VIEW],
        },
        {
          title: "Reward Value",
          path: ROUTER.REWARD_VALUE,
          isAllow: true,
          access: [UserTypes.Employee, UserTypes.admin],
          activePaths: [ROUTER.REWARD_VALUE],
        },
        {
          title: "Total Working Days",
          path: ROUTER.TOTAL_WORKING_DAYS,
          isAllow: true,
          access: [UserTypes.Employee, UserTypes.admin],
          activePaths: [ROUTER.TOTAL_WORKING_DAYS, ROUTER.TOTAL_WORKING_DAYS_CREATE, ROUTER.TOTAL_WORKING_DAYS_EDIT],
        },
        {
          title: "Additional Expense Reports",
          path: ROUTER.ADDITIONAL_EXPENSES_REPORTS_TABLE,
          isAllow: true,
          access: [UserTypes.Employee, UserTypes.admin, UserTypes.Franchise],
          activePaths: [ROUTER.ADDITIONAL_EXPENSES_REPORTS_TABLE, ROUTER.ADDITIONAL_EXPENSES_REPORTS_VIEW],
        },
      ]
    },
  ], [dynamicMenu, remaindynamicMenu, pathName])


  useEffect(() => {
    debugger
    var matchingMenuItem = null;
    var ul: any = document.getElementById("navigation");
    var items: any = ul.getElementsByTagName("a");
    removeActivation(items);
    // for (var i = 0; i < items.length; ++i) {
    //   if (window.location.pathname === items[i].pathname) {
    //     matchingMenuItem = items[i];
    //     break;
    //   }
    // }
    for (let i = 0; i < items.length; ++i) {
      let itemsActivePaths = items[i].dataset?.path ? JSON.parse(items[i].dataset?.path) : [];
      let itemActivePath = itemsActivePaths.some((path: string) => {
        if (pathName.includes("/edit") || pathName.includes("/view")) {
          const paths = pathName.split("/");
          paths.pop();
          const path_name = paths.join("/");
          return path_name === path;
        }
        return path === pathName;
      });
      if (itemActivePath) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [pathName, activateParentDropdown]);



  // useEffect(() => {
  //   if (pathName) {
  //     if (menu.some(each => each.path === pathName)) {
  //       const ul: any = document.getElementById("navigation");
  //       const items = ul.getElementsByTagName("a");

  //       let activeMenu = null;

  //       for (let i = 0; i < items.length; ++i) {
  //         if (items?.[i].dataset?.parentpath === pathName) {
  //           activeMenu = items?.[i];
  //           break;
  //         }
  //       }

  //       if (activeMenu) {
  //         let data = activeMenu.parentElement
  //         activateParentDropdown(data);
  //       }
  //     }
  //   }
  // }, [pathName]);

  function activateParentDropdown(item: any) {
    debugger
    item.classList.add("active");
    const parent = item.closest(".dropdown-menu")
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const removeActivation = (items: any) => {
    debugger
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };


  useEffect(() => {

    let newRole: any;
    newRole = JSON.parse(role)
    if (!Array.isArray(newRole)) {
      newRole = [newRole];
    }
    if (screenResolution.width > 992 && screenResolution.width <= 1047) {
      const Menu = menu.slice(0, 5);
      setdynamicMenu(Menu);
      const remainItems = menu.slice(Menu.length);
      let menus = remainItems.filter((item: any) => item.access.includes(newRole[0]))
      setremaindynamicMenu(menus)
      setShowRemainMenu(true)
    } else if (screenResolution.width >= 1047 && screenResolution.width <= 1097) {
      const Menu = menu.slice(0, 6);
      setdynamicMenu(Menu);
      const remainItems = menu.slice(Menu.length);
      let menus = remainItems.filter((item: any) => item.access.includes(newRole[0]))
      setremaindynamicMenu(menus)
      setShowRemainMenu(true)
    }
    else if (screenResolution.width >= 1097 && screenResolution.width <= 1280) {
      const Menu = menu.slice(0, 7);
      setdynamicMenu(Menu);
      const remainItems = menu.slice(Menu.length);
      let menus = remainItems.filter((item: any) => item.access.includes(newRole[0]))
      setremaindynamicMenu(menus)
      setShowRemainMenu(true)
    }
    else if (screenResolution.width >= 1600 && screenResolution.width <= 1745) {
      const Menu = menu.slice(0, 9);
      setdynamicMenu(Menu);
      const remainItems = menu.slice(Menu.length);
      let menus = remainItems.filter((item: any) => item.access.includes(newRole[0]))
      setremaindynamicMenu(menus)
      setShowRemainMenu(true)
    } else if (screenResolution.width >= 1446 && screenResolution.width <= 1536) {
      const Menu = menu.slice(0, 8);
      setdynamicMenu(Menu);
      const remainItems = menu.slice(Menu.length);

      let menus = remainItems.filter((item: any) => item.access.includes(newRole[0]))
      setremaindynamicMenu(menus)
      setShowRemainMenu(true)
    }
    else if (screenResolution.width >= 1745 && screenResolution.width <= 2000) {
      const Menu = menu.slice(0, 11);
      setdynamicMenu(Menu);
      const remainItems = menu.slice(Menu.length);
      let menus = remainItems.filter((item: any) => item.access.includes(newRole[0]))
      setremaindynamicMenu(menus)
      setShowRemainMenu(true)
    }
    else {
      setdynamicMenu(menu)
      setremaindynamicMenu([])
      setShowRemainMenu(false)
    }
  }, [screenResolution])

  

  const assignValue = (currentSelect: string, index: number) => {
    toggleSubMenu(index);
  }

  const assignremainValue = (currentSelect: string, index: number) => {
    toggleremainSubMenu(index);
  }
  

  const toggleremainSubMenu = (index: number) => {
    const updatedMenu = remaindynamicMenu.map((item: any, i: any) => {
      if (i === index) {
        return { ...item, show: !item.show };
      } else if (item.show == true) {
        return { ...item, show: !item.show }
      }
      return item;
    });
    setremaindynamicMenu(updatedMenu);
  };
  const toggleSubMenu = (index: number) => {

    const updatedMenu = dynamicMenu.map((item: any, i: any) => {
      if (i === index) {
        return { ...item, show: !item.show };
      } else if (item.show == true) {
        return { ...item, show: !item.show }
      }
      return item;
    });
    setdynamicMenu(updatedMenu);
  };

//   function tTogglebtn() {
//     debugger
//     const navtop = document.getElementById('topnav-menu-content');
//     const overlay = document.querySelector('.overlayHorizon') as HTMLElement | null;
//     if (navtop && overlay) {
//     if (navtop.classList.contains('show')) {
//       overlay.style.display = 'block';
//       navtop.classList.remove("show");
//     } else {
//       overlay.style.display = 'none';
//       navtop.classList.add("show");
//     }
//   }
// }

  return (
    <React.Fragment>
      {/* <div onClick={tTogglebtn} className="overlayHorizon"></div> */}
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation">

            {/* sample menu */}

            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content">
              <ul className="navbar-nav">
                {
                  dynamicMenu?.map((eachMenu: any, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        {
                          eachMenu?.access?.some((accessRole: any) => role.includes('"' + accessRole + '"') && eachMenu?.isAllow) ? (
                            // <li className={`nav-item dropdown  ${activePath === eachMenu.path ? "active" : ""}`}>


                            <li className={`nav-item dropdown ${eachMenu.path?.includes(pathName) ? "active" : ""}`}>
                              {
                                eachMenu?.children?.length === 0 ? (
                                  <>
                                    <Link
                                      className="nav-link dropdown-toggle arrow-none"
                                      to={eachMenu?.path}
                                      data-path={JSON.stringify(eachMenu?.activePaths)}
                                    >
                                      <FeatherIcon icon={eachMenu?.icon} />
                                      {/* <span>{props.t(eachMenu?.title)}</span> */}
                                      <span>{props.t(((hasCustomerRoleHandler() || hasDealerRoleHandler()) && eachMenu.title == "Received Payments") ? "Paid Payment" : eachMenu.title)}</span>
                                    </Link>
                                  </>
                                ) : (
                                  <React.Fragment>
                                    <Link
                                      to="/#"
                                      onClick={(e: any) => {
                                        debugger
                                        e.preventDefault();
                                        assignValue(e.target.innerText, i)
                                      }}
                                      className="nav-link dropdown-toggle arrow-none"
                                    >
                                      <FeatherIcon icon={eachMenu.icon} />
                                      {props.t(eachMenu.title)}
                                      <div className="arrow-down"></div>
                                    </Link>
                                    <div ref={newSubMenu} className={classname("dropdown-menu", { show: eachMenu.show })}>
                                      {eachMenu.children.map((child: any) => (
                                        <React.Fragment key={child.title}>
                                          {child?.access?.some((accessRole: any) => role.includes('"' + accessRole + '"')) && child.isAllow ? (
                                            <Link to={child.path} className="dropdown-item"
                                              data-path={JSON.stringify(child?.activePaths)}
                                              onClick={
                                                () => {
                                                  setShowRemainMenu(false)
                                                  eachMenu.show = false
                                                }
                                              }>
                                              {props.t(child.title)}
                                            </Link>

                                          ) : null}
                                        </React.Fragment>
                                      ))}

                                    </div>
                                  </React.Fragment>
                                )
                              }
                            </li>
                          ) : null
                        }
                      </React.Fragment>
                    );
                  })
                }

                {remaindynamicMenu?.length > 0 && <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <FeatherIcon icon="grid" />
                    {props.t("More Menus")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    {
                      remaindynamicMenu?.map((eachMenu: any, i: number) => {
                        return (
                          <React.Fragment key={i}>
                            {
                              eachMenu?.access?.some((accessRole: any) => role.includes('"' + accessRole + '"') && eachMenu?.isAllow) ? (
                                <li className={`nav-item dropdown ${activePath === eachMenu.path ? "active" : ""}`}>
                                  {
                                    eachMenu?.children?.length === 0 ? (
                                      <>
                                        <Link to={eachMenu.path}
                                          data-path={JSON.stringify(eachMenu?.activePaths)}
                                          className="dropdown-item nav-link dropdown-toggle arrow-none">
                                          <FeatherIcon icon={eachMenu.icon} />
                                          {props.t(eachMenu.title)}
                                        </Link>
                                      </>
                                    ) : (
                                      <React.Fragment>
                                        <Link
                                          to="/#"
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            assignremainValue(e.target.innerText, i)
                                          }}
                                          className="nav-link dropdown-toggle arrow-none"
                                        >
                                          <FeatherIcon icon={eachMenu.icon} />
                                          {props.t(eachMenu.title)}
                                          <div className="arrow-down"></div>
                                        </Link>
                                        <div ref={newSubMenu} className={classname("dropdown-menu", { show: eachMenu.show })}>
                                          {eachMenu.children.map((child: any) => (
                                            <React.Fragment key={child.title}>
                                              {child?.access?.some((accessRole: any) => role.includes('"' + accessRole + '"')) && child.isAllow ? (
                                                <Link to={child.path}
                                                  data-path={JSON.stringify(child?.activePaths)}
                                                  className={`dropdown-item ${activePath === child.path ? "active" : ""}`} onClick={() => {
                                                    setShowRemainMenu(false)
                                                    eachMenu.show = false
                                                  }}>
                                                  {props.t(child.title)}
                                                </Link>

                                              ) : null}
                                            </React.Fragment>
                                          ))}

                                        </div>
                                      </React.Fragment>
                                    )
                                  }
                                </li>
                              ) : null
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                </li>}

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(Navbar));