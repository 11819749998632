import { ErrorMessage } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import ErrorText from './ErrorText';
import Label from './Label';
import React from 'react';

interface TextAreaProps {
  name: string
  label: string
  value: string
  required?: boolean
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => {},
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => {},
}

const TextAreaInput = (props: TextAreaProps) => {
  const { name, label, required = false, ...rest } = props
  return (
    <div className="mb-3">
      <Label name={name} label={label} required={required}/>
      {/* <label htmlFor={name} className="form-label">{label}</label> */}
      <InputTextarea
        id={name}
        name={name}
        rows={2}
        cols={20}
        {...rest}
        className="form-control"
      />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  )
}
export default TextAreaInput;
