export const baseUrl = process.env.REACT_APP_BASE_URL;
export const AUTH_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const REDIRECT_URI = process.env.REACT_APP_LOGIN_REDIRECT_URI;
export const SCOPE = process.env.REACT_APP_SCOPE;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

// export const authConstant = {
//   application: {
//     baseUrl,
//     name: "MelFrontend",
//     logoUrl: "",
//   },
//   oAuthConfig: {
//     issuer: AUTH_LOGIN_URL,
//     redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
//     clientId: process.env.REACT_APP_CLIENT_ID,
//     responseType: process.env.REACT_APP_LOGIN_RESPONSE_TYPE,
//     scope: process.env.REACT_APP_SCOPE,
//     requireHttps: true,
//   },
//   apis: {
//     default: {
//       url: AUTH_LOGIN_URL,
//       rootNamespace: "MEL_ERP.poc",
//     },
//   },
// };

export class AuthConfig {
  redirectUri: string;
  responseType: string;
  oidc: boolean;
  requestAccessToken: boolean;
  loginUrl: string;
  scope: string;
  resource: string;
  customQueryParams: any;
  clientId: string;
  rngUrl: string;
  disablePKCE: boolean;

  constructor(json: any) {
    this.clientId = String(CLIENT_ID);
    this.redirectUri = String(REDIRECT_URI);
    this.loginUrl = "";
    this.scope = String(SCOPE);
    this.resource = "";
    this.rngUrl = "";
    this.oidc = true;
    this.requestAccessToken = true;
    this.disablePKCE = false;
    this.responseType = 'code';
    if (json) {
      Object.assign(this, json);
    }
  }
}
