import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import InputCom from "src/components/Common/Input";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import FeatherIcon from "feather-icons-react";
import Button from "src/components/UI/Button";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { vehicleBrandsDetails } from "src/models/pages/vehicleBrandsModel";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import MultiSelector from "src/components/Common/MultiSelector";
import { Editor } from "primereact/editor";
import { clearBulkEmailMessage, createBulkEmail, getCustomerEmailList, getDealerEmailList, getEmployeeEmailList, getFranchiseEmailList, getSupplierEmailList } from "src/store/BulkEmail/action";
import ErrorText from "src/components/UI/ErrorText";
import MarketingBreadcrumb from "../MarketingBreadcrumb";

const SendEmail: React.FC = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const { id } = useParams();
  const { showToast } = useToast();

  const dispatch = useDispatch();
  const validateEmails = (array:any) => {
    return allMobileNum.every((email:any) => {
      if (!email.phone) return true; 
      return Yup.string().email().isValidSync(email.phone); 
    });
  };

  const validationSchema = Yup.object().shape({
    title: Yup.mixed().required("Title is required"),
    toUserTypes: Yup.array()
    .of(Yup.string())
    .min(1, "At least one user type must be selected")
    .required("User type is required"),
      body: Yup.string()
      .test('is-non-empty', 'Email Content is required', value => {
        if (!value) return false;
  
        const textContent = value.replace(/<[^>]*>/g, '').trim();
        
        return textContent.length > 0; 
      })
      .required("Email Content is required") ,
  
    subject: Yup.string().required("Subject is required"),
    allMailID:  Yup.array()
    .test('is-valid-emails', 'All are should be a valid email', validateEmails)
});
  const navigate = useNavigate();

  const formValue: vehicleBrandsDetails = useSelector(
    (state: RootReducerState) =>
      state.vehicleBrandsReducer?.vehicleBrandsDetails as vehicleBrandsDetails
  );

  const {error, message , loading,employeeEmail, dealerEmail,
  customerEmail,
  franchiseEmail,
  supplierEmail } = useSelector((state: RootReducerState) => state.BulkEmailReducer);
  const [mailIds, setMailIds]=useState<any>([]);
  const [userTypes, setUserTypes]=useState<any>([]);
  const initialvalue:any = {
    toUserTypes: [],
    allMailID:mailIds,
    subject:"",
    title:"",
    body:""
  };
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
    }
    if (message ) {
      showToast(message, { type: "success" });
   
      dispatch(clearBulkEmailMessage())
        setTimeout(() => {
          setIsSubmitting(false);
          navigate(ROUTER.BULK_EMAIL);
        }, 500);
      
    }
  }, [error, message]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearBulkEmailMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    if(allMobileNum.length<1) return
    let num=allMobileNum?.map((numbers:any)=>numbers.phone)
    let formatted={
      id:0,
      allMailID:num,
      body:values.body,
      subject:values.subject,
      title:values.title,
      toUserTypes:values.toUserTypes.length>0 ? values.toUserTypes : null
    }
     post(formatted);
  };


  // const update = (formValue: vehicleBrandsDetails) => {
  //   dispatch(updateVehicleBrands(formValue, id));
  //   if (selectedFiles.length > 0) {
  //     dispatch(uploadVehicleBrandFileLoading(selectedFiles[0], id));
  //   }
  // };

  const post = (formValue: any) => {
    const val={id:0,...formValue}
    dispatch(createBulkEmail(val));
  };

  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    setIsSubmitting(false)
  };

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== "checkbox") {
        acc[field.name] = "";
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  const toUserTypes = [
    { label: "Employees", value: "Employees" },
    { label: "Dealer", value: "Dealer" },
    { label: "Customer", value: "Customer" },
    { label: "Franchise", value: "Franchise" },
    // { label: "Dealer Subuser", value: "Dealer Subuser" },
    // { label: "Franchise Subuser", value: "Franchise Subuser" },
    { label: "Supplier", value: "Supplier" },
    { label: "Others", value: "Others" },
  ];
const [emailValue,setEmailValue]=useState<any>([])
useEffect(()=>{
  dispatch(getEmployeeEmailList())
  dispatch(getDealerEmailList())
  dispatch(getCustomerEmailList())
  dispatch(getFranchiseEmailList())
  dispatch(getSupplierEmailList())
  return()=>{
    dispatch(clearBulkEmailMessage())
  }
},[])

const [allMobileNum, setAllMobilenum] = useState<any>([])
const handleSelect = (selectedOptions:any,setFieldValue:any) => {
setFieldValue('toUserTypes',selectedOptions)
  // Update allMailID based on selected user types
  const updatedMailID = [];
  if (selectedOptions.includes("Employees")) {
      updatedMailID.push(...employeeEmail);
  }
  if (selectedOptions.includes("Dealer")) {
      updatedMailID.push(...dealerEmail);
  }
  if (selectedOptions.includes("Customer")) {
      updatedMailID.push(...customerEmail);
  }
  if (selectedOptions.includes("Franchise")) {
      updatedMailID.push(...franchiseEmail);
  }
  if (selectedOptions.includes("Supplier")) {
      updatedMailID.push(...supplierEmail);
  }
  
      
  setFieldValue("allMailID",updatedMailID)
  setAllMobilenum(updatedMailID);
};
const handleMobileAdd = (event: any) => {
  if (event.key == "Enter" || event.key=="Tab") {
  event.preventDefault()
    setAllMobilenum((prevState: any) => [...prevState, {name:"",phone:event.target.value.trim()}]);
    setText("")
  }
};
const handleRemoveParts = (id: number) => {
  const RemoveParts = allMobileNum.filter(
    (item: any, index: number) => index !== id
  );
  setAllMobilenum(RemoveParts);
};
const[text,setText]=useState<any>("")
const [filter, setFilter] = useState('');
  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className="page-content" id="view-brand">
        <Container fluid>
          <MarketingBreadcrumb
            title="Bulk Email List"
            titlePath={ROUTER.BULK_EMAIL}
            breadcrumbItem={`Send Email`}
            filter={filter}
            setFilter={setFilter}
          />
          <Row id="email">
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldValue,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={4} className="mt-2">
                              <MultiSelector
                                name="toUserTypes"
                                label="User Types"
                                options={toUserTypes}
                                optionLabel="label"
                                id="toUserTypes"
                                labelClassName="form-Label"
                                //onBlur={handleBlur}
                                onBlur={handleBlur("toUserTypes")}
                                value={values.toUserTypes}
                                onChange={(e:any)=>{handleSelect(e.target.value,setFieldValue)}}
                                isRequired={true}
                                invalid={
                                  touched["toUserTypes"] && errors["toUserTypes"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={4} className="mt-2">
                              <InputCom
                                labelName="Title"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="title"
                                onBlur={handleBlur}
                                value={values.title}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["title"] && errors["title"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={4} className="mt-2">
                              <InputCom
                                labelName="Subject"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="subject"
                                onBlur={handleBlur}
                                value={values.subject}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["subject"] && errors["subject"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={12} className="mt-2">
                              <label htmlFor="body">Email Content<span className="text-danger ml-05">*</span></label>
                              <Editor
                                id="body"
                                name="body"
                                value={values.body}
                                onTextChange={(e) => setFieldValue('body', e.htmlValue)}
                                style={{ height: "320px" }}
                                className={  errors["body"] ? "is-invalid-editor" : ""}
                              />
                                <ErrorMessage name={"body" || ''} component={ErrorText} />
                              
                            </Col>
                            {values.toUserTypes?.length>0 &&
                                  <Col lg={12} className="mt-2">
                               
                              <label htmlFor="allMailID">To<span className="text-danger ml-05">*</span></label>
                              {/* <Chips value={values.allMailID}  onChange={(e) => setFieldValue('allMailID', e.value)} separator="," className={  values["allMailID"].length<1 ||(touched["allMailID"] && errors["allMailID"]) ? "is-invalid" : "chip-email"}/> */}
                              <div className="p-chips p-component p-inputwrapper p-inputwrapper-filled custom-chip">
                                  <ul className="p-inputtext p-chips-multiple-container p-variant-filled" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {allMobileNum.map(
                                      
                                      (item: any, index: any) => {
                                        const isMatch = filter && (
                                          item.name?.toLowerCase().includes(filter.toLowerCase()) ||
                                          item.phone.toLowerCase().includes(filter.toLowerCase())
                                      );
                                        return (
                                        <li key={index}className={isMatch ? "p-chips-token highlight-chip" : "p-chips-token"}>
                                          <span className="p-chips-token-label">{item.name ? `${item.name} - ${item.phone}` : item.phone}</span>
                                          <FeatherIcon icon="x-circle" className="p-icon p-chips-token-icon" onClick={() => handleRemoveParts(index) }/>
                                        </li>
                                      )}
                                    )}
                                    <li className="p-chips-token"  style={{listStyle:"none",background:"none",flex:"1 0 auto"}} id="email-input">
                                      <input
                                        type="text"
                                        style={{backgroundColor:"rgb(235 235 235)",width:"100%",outline:"none",border:"none"}}
                                        onKeyDown={(e) => handleMobileAdd(e)}
                                        value={text}
                                        onChange={(e:any)=>setText(e.target.value)}
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <p style={{fontSize:"12px",marginTop:"-10px"}}>Please press the Enter key after entering the Email Id.</p>
                              {allMobileNum.length>0 && 
                                <ErrorMessage
                                  name={"allMailID" || ""}
                                  component={ErrorText}
                                />
                    }
                                { (isSubmitting && allMobileNum.length<1) ? <div className="text-danger">Email Id is required</div>:<></>}
                              </Col>
                            }

                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                className="secondary-btn me-2"
                                label={"Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={"Send Email"}
                                type="submit"
                                onClick={()=>setIsSubmitting(true)}
                              />
                            </div>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SendEmail;
