import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
    Columns,
    LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { CurrencyFormatter, formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";

import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { RootReducerState } from "src/store/reducers";
import { deletePurchaseOrderFromList, getDropdownForPurchase, getPurchaseOrderList, resetPurchaseOrder } from "src/store/actions";
import { purchaseOrderList } from "src/models/pages/purchaseOrders";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import {
    getEmployeeDropdownList,
    getEnquiryList,
    getFranchiseDropdownList,
    getFranchiseSubuserDropdownList,
    getJobsheetDropdownForEnquiry,
} from "src/store/enquiries/action";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";


const purchaseListConverson = (values: any[]) => {
    const modifeidValue = values.map((eachValue) => {
        let data: any = {};

        data['id'] = eachValue?.id;
        data['JobId'] = eachValue?.jobNumber;
        data['dateOfEnquiry'] = eachValue?.dateOfEnquiry;
        data['enquirerName'] = eachValue?.enquirerName;
        data['isCustomer'] = eachValue?.isCustomer;
        data['attendedById'] = eachValue?.userName;
        return data;
    });
    return modifeidValue;
};

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";
const LOCKED = "Locked";
const UNLOCKED = "Unlocked";


export default function EnquiriesList() {
    const navigate = useNavigate();
    const [filter, setFilter] = useState(false);
    const { values, totalRecords } = useSelector((state: RootReducerState) => state.EnquiryReducer.enquiryList);
    const { error, message, loading, jobSheetList, paginatorCount, employeeDropdownList, franchiseDropdownList,franchiseSubuserDropdownList } = useSelector(
        (state: RootReducerState) => state.EnquiryReducer
    );
    const [pdfLoading, setPdfLoading] = useState<boolean>(false);
    const modifiedValues: any[] = purchaseListConverson(values);
    const { showToast } = useToast();
    const dispatch = useDispatch();
    const dt = useRef<DataTable<any>>(null);
    // for delete invoice
    const [deleteModal, setDeleteModal] = useState(false);
    const [archiveModal, setArchiveModal] = useState(false);
    const [rowData, setRowData] = useState<any | null>(null);
    useEffect(() => {
        const auth: any = localStorage.getItem('authUser');
        const parsed = JSON.parse(auth);
        // if (hasAdminOrEmployeeRoleHandler()) {
            dispatch(getJobsheetDropdownForEnquiry());
        // }
        // if (hasFranchiseRoleHandler()) {
        //     dispatch(getFranchiseJobsheetDropdownForEnquiry(parsed?.uid));
        // }

        dispatch(getEmployeeDropdownList());
        dispatch(getFranchiseDropdownList());
        dispatch(getFranchiseSubuserDropdownList());
    }, []);
    const filterDetails: DataTableFilterMeta = {
        JobId: { value: "", matchMode: "contains" },
        dateOfEnquiry: { value: "", matchMode: "contains" },
        StartDateOfEnquiry: { value: "", matchMode: "contains" },
        EndDateOfEnquiry: { value: "", matchMode: "contains" },
        enquirerName: { value: "", matchMode: "contains" },
        attendedById: { value: "", matchMode: "contains" },
    };
    let initialLazyState: LazyTableState = {
        first: 0,
        rows: 10,
        page: 1,
        sortField: "creationTime",
        sortOrder: -1,
        filters: filterDetails,
    };
    const [lazyState, setLazyState] = useState(initialLazyState);

    const initialValues = {
        status: "",
    };

    const onFilterClickHandler = () => {
        setFilter((preState) => !preState);
    };

    const onCreateClickHandler = () => {
        navigate(ROUTER.ENQUIRY_CREATE);
    };

    const viewHandler = (rowData: any) => {

        navigate(`${ROUTER.ENQUIRY_VIEW}/${rowData["id"]}`);
    };

    const deleteHandler = (rowData: any) => {

        setDeleteModal(true);
        setRowData(rowData);
    };


    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (error) {
            setVisible(true);
            // showToast(error, { type: "error" })
        }
        if (message) {
            showToast(message, { type: "success" });
            dispatch(resetPurchaseOrder());

        }
    }, [error, message]);

    const emailBodyTemplate = (rowData: any, field: string) => {

        let data = rowData[field] as string;
        return (
            <EmailBodyTemplate data={data} />
        );
    };

    const supplierBodyTemplate = (rowData: any, field: string) => {
        return (
            <>{rowData['supplierName']}</>
        );
    };

    const hidePopup = () => {
        setVisible(false);
    };
    const dialogContainer = (error: any) => {
        return (
            <>
                <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
            </>
        );
    };

    const actionBodyTemplate = (rowData: purchaseOrderList, value: string) => {

        return (
            <>

                <ColumnAction
                    displayData={rowData[value] as string}

                    isDelete={false}
                    isView={true}
                    onViewClick={() => viewHandler(rowData)}
                    onDeleteClick={() => deleteHandler(rowData)}
                />


            </>
        );
    };



    const statusBodyTemplate = (rowData: any, field: string) => {
        let status = rowData[field];
        return (
            <StatusHghlighter status={status} />
        );

    };
    const currencyBodyTemplate = (rowData: any, field: string, code: string) => {
        let amount = rowData[field];
        let currencyCode = rowData[code];
        return (<div className="align-right">{CurrencyFormatter({ value: amount, currencyCode: currencyCode })}</div>);
    };

    //   const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    //     return <Calendar value={options.value}  onChange={(e: any) => {
    //       options.filterCallback(e.value, options.index);
    //     }} selectionMode="range" />;
    // };


    const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (<>
            <label htmlFor="">Start Date</label>
            <Input type="date"
                required
                value={options?.value?.startDate || ""}
                max={options?.value?.endDate ? options?.value?.endDate : ""}
                onChange={(e: any) => {
                    options;
                    let modifiedDate: any = options.value || { startDate: "", endDate: "" };
                    modifiedDate['startDate'] = e.target.value;
                    options.filterCallback(modifiedDate, options.index);
                }}
            />
            <br />
            <label htmlFor="">End Date</label>
            <Input type="date"
                required
                value={options?.value?.endDate || ""}
                min={options?.value?.startDate ? options?.value?.startDate : ""}
                onChange={(e: any) => {
                    options;
                    let modifiedDate: any = options.value || { startDate: "", endDate: "" };
                    modifiedDate['endDate'] = e.target.value;
                    options.filterCallback(modifiedDate, options.index);
                }} />
        </>);
    };

    // const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    //   return <InputNumber value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    // };






    const JOBSHEET_OPTIONS = jobSheetList?.filter((item: any) => item.number != null)?.map((item: any) => ({ label: item.number, value: item.id })).reverse();


    const jobFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (<>
            <Dropdown
                options={JOBSHEET_OPTIONS || []}
                name="jobId"
                optionLabel="label"
                filter
                onChange={(e: DropdownChangeEvent) => {
                    options.filterCallback(e.value, options.index);
                    return {};
                }}
                required={true}
                value={options.value}
            />
        </>);
    };

    const [compinedUserList, setCompinedUserList] = useState<any>([]);
    useEffect(() => {
        setCompinedUserList([...employeeDropdownList, ...franchiseDropdownList,...franchiseSubuserDropdownList]);

    }, [employeeDropdownList, franchiseDropdownList,franchiseSubuserDropdownList]);


    const userOptions: any = compinedUserList?.map((item: any) => ({
        label: item?.name,
        value: item?.userId
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
    const UserFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (<>
            <Dropdown
                options={userOptions || []}
                name="jobId"
                optionLabel="label"
                filter
                onChange={(e: DropdownChangeEvent) => {
                    options.filterCallback(e.value, options.index);
                    return {};
                }}
                required={true}
                value={options.value}
            />
        </>);
    };



    const options: Option[] = [
        { label: "Active", value: "Active" },
        { label: "Deleted", value: "Deleted" },
        { label: "Locked", value: "Locked" },
        { label: "Unlocked", value: "Unlocked" },
    ];


    const columns: Columns[] = [
        // {
        //     field: "select",
        //     sortable: false,
        //     header: "",
        //     filter: false,
        //     showFilterMenu: false,
        //     selectionMode: "multiple",
        //     headerStyle: { width: "3rem", whiteSpace: "nowrap" },
        //     isDefault: true,
        //     isStatic: false,
        // },
        {
            field: "JobId",
            sortable: true,
            header: "Job Sheet",
            filter: true,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap", width: "250px" },
            body: (rowData) => actionBodyTemplate(rowData, "JobId"),
            isDefault: true,
            frozen: true,
            isStatic: true,
            filterElement: jobFilterTemplate
        },
        {
            field: "dateOfEnquiry",
            sortable: true,
            header: "Date Of Enquiry",
            filter: true,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap" },
            isDefault: true,
            isStatic: false,
            body: (rowData) => formatDateMEL(rowData.dateOfEnquiry),
            filterElement: dateFilterTemplate,
        },
        {
            field: "enquirerName",
            sortable: true,
            header: "Enquirer Name",
            filter: true,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap" },
            isDefault: true,
            isStatic: false,
        },

        {
            field: "isCustomer",
            sortable: true,
            header: "Is Customer",
            filter: false,
            showFilterMenu: false,
            headerStyle: { whiteSpace: "nowrap" },
            body: (rowData: any) => statusBodyTemplate(rowData, 'isCustomer'),
            isDefault: true,
            isStatic: false,

        },
        {
            field: "attendedById",
            sortable: false,
            header: "Attented By",
            filter: true,
            showFilterMenu: true,
            headerStyle: { whiteSpace: "nowrap" },
            isDefault: true,
            isStatic: false,
            filterElement: UserFilterTemplate
        },
    ];

    const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


    const changeColumnDetails = (values: any[]) => {
        let modifiedColumnDetails = values.map((eachValue) => {
            const { id, JobId, enquirerName, isCustomer, dateOfEnquiry, attendedById, } = eachValue;
            let data: any = {};
            data['Job Sheet'] = JobId;
            data['Date Of Enquiry'] = formatDateMEL(dateOfEnquiry);
            data['Enquirer Name'] = enquirerName;
            data['Is Customer'] = isCustomer;
            data['Attented By'] = attendedById;
            return { ...data };
        });
        return modifiedColumnDetails;
    };

    const saveDataAsExcel = (values: any[], fileName: string) => {
        const modifiedColumnDetails = changeColumnDetails(values);
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
        const destructureedData = values.map((data) => {
            let datas = {
                JobId: data.JobId,
                dateOfEnquiry: formatDateMEL(data?.dateOfEnquiry),
                enquirerName: data.enquirerName,
                isCustomer: data?.isCustomer,
                attendedById: data?.attendedById,
            };
            return datas;
        });
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const exportColumns = columns.map((col) => ({
                    title: col.header,
                    dataKey: col.field,
                }));
                const doc = new (jsPDF as any).default(0, 0);
                doc.autoTable(exportColumns, destructureedData);
                doc.save(`${fileName}.pdf`);
            });
        });
    };
    const fetchData = async (isPdf: boolean) => {
        let lazy: any = {
            ...lazyState,
            rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
            first: 0,
            filters: {
                ...lazyState.filters,
            },
        };

        try {
            setPdfLoading(true);
            const response = await API.getJobEnquiryListApi(lazy);
            if (!response) {
                throw new Error('Network response was not ok');
            }
            const modifeidValue = response?.data?.items?.map((eachValue: any) => {
                let data: any = {};

                data['id'] = eachValue?.id;
                data['JobId'] = eachValue?.jobNumber;
                data['dateOfEnquiry'] = eachValue?.dateOfEnquiry;
                data['enquirerName'] = eachValue?.enquirerName;
                data['isCustomer'] = eachValue?.isCustomer;
                data['attendedById'] = eachValue?.userName;
                return data;
            });
            //   let resp=await purchaseListConverson(response?.data?.items)
            if (isPdf) {

                saveDataAsPdf(modifeidValue, columns, "jobsheetEnquiry");
            } else {
                saveDataAsExcel(modifeidValue, "jobsheetEnquiry");
            }
            setPdfLoading(false);
        } catch (error: any) {
            showToast(error.message, { type: "error" });
            setPdfLoading(false);

        } finally {
            setPdfLoading(false);
        }
    };

    const csvClickHandler = () => {
        fetchData(false);
    };

    const pdfClickHandler = () => {
        fetchData(true);

    };

    const validationSchema = Yup.object({});

    const callbackFunction = (lazyState: LazyTableState) => {
        let modifiedLazyState: any = { ...lazyState };
        if (modifiedLazyState.filters && modifiedLazyState.filters.dateOfEnquiry) {
            modifiedLazyState.filters.dateOfEnquiry.value = "";
        }
        dispatch(getEnquiryList(modifiedLazyState));
    };

    const [selectAll, setSelectAll] = useState(false);
    const [selectedDealers, setSelectedDealers] = useState<any[]>([]);


    const onSelectionChange = (event: any) => {
        const value = event.value;
        setSelectedDealers(value);
        // setSelectAll(value.length === totalRecords);
        setSelectAll(value.length === paginatorCount);
    };

    const onSelectAllChange = (event: any) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedDealers(modifiedValues);
        } else {
            setSelectAll(false);
            setSelectedDealers([]);
        }
    };

    const handleStatusBlur = () => { };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };
    const onFilter = (event: any) => {
        event["first"] = 0;
        event['filters']['StartDateOfEnquiry']['value'] = event?.filters?.dateOfEnquiry?.value?.startDate ? event?.filters?.dateOfEnquiry?.value?.startDate : '';
        event['filters']['EndDateOfEnquiry']['value'] = event?.filters?.dateOfEnquiry?.value?.endDate ? event?.filters?.dateOfEnquiry?.value?.endDate : '';

        setLazyState(event);
    };



    const handleSubmit = (formValue: any) => {
        setselectedStatus(formValue);
        if (formValue == ACTIVE) {
            setLazyState((state) => ({
                ...state,
                filters: {
                    ...state.filters,
                    IsDeleted: { value: "false", matchMode: "contains" },
                    Locked: { value: null, matchMode: "contains" },

                },
            }));
        }
        else if (formValue == DELETED) {
            setLazyState((state) => ({
                ...state,
                filters: {
                    ...state.filters,
                    IsDeleted: { value: "true", matchMode: "contains" },
                    Locked: { value: null, matchMode: "contains" },

                },
            }));
        }
        else if (formValue == LOCKED) {

            setLazyState((state) => ({
                ...state,
                filters: {
                    ...state.filters,
                    IsDeleted: { value: null, matchMode: "contains" },
                    Locked: { value: "true", matchMode: "contains" },

                },
            }));
        } else if (formValue == UNLOCKED) {

            setLazyState((state) => ({
                ...state,
                filters: {
                    ...state.filters,
                    IsDeleted: { value: null, matchMode: "contains" },
                    Locked: { value: "false", matchMode: "contains" },

                },
            }));
        }
        else {
            setLazyState((state) => ({
                ...state,
                filters: {
                    ...state.filters,
                    IsDeleted: { value: null, matchMode: "contains" },
                    Locked: { value: null, matchMode: "contains" },
                },
            }));
        }
    };

    const handleReset = (formValue: any) => {
        setLazyState((state) => ({
            ...state,
            filters: {
                ...state.filters,
                status: {
                    ...state.filters.status,
                    value: "",
                }
            },
        }));
    };

    useEffect(() => {
        setSelectAll(false);
        setSelectedDealers([]);
        callbackFunction(lazyState);
        return () => {
            dispatch(resetPurchaseOrder());
        };
    }, [lazyState]);



    const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
        let payload = {
            id: rowData["id"],
            lazyState,
        };

        dispatch(deletePurchaseOrderFromList(payload));

        setDeleteModal(false);
        setRowData(null);

        if (index + 1 === length || index === length) {
            setSelectAll(false);
            setSelectedDealers([]);
            setTimeout(() => {

                dispatch(getDropdownForPurchase());
                dispatch(getPurchaseOrderList(lazyState));
            }, 500);
        }

    };

    const archiveHandler = () => {
        setSelectAll(false);
        setSelectedDealers([]);
        setArchiveModal(false);
    };


    const defaultColumns = {
        ...columns[0]
    };

    const onColumnToggle = (event: MultiSelectChangeEvent) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

        setVisibleColumns(orderedSelectedColumns);
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const [selectedStatus, setselectedStatus] = useState(ACTIVE);
    const [showCustomizeTable, setShowCustomizeTable] = useState(false);
    const customizeTableClickHandler = () => {
        setShowCustomizeTable(true);
    };


    const bulkActionClickHandler = () => {
        if (modifiedValues.length > 0) {
            setSelectAll(true);
            setSelectedDealers(modifiedValues);
        }
    };
    const cancelBulkActionHadler = () => {
        setSelectAll(false);
        setSelectedDealers([]);
    };
    const clearFilter = () => {
        setLazyState(initialLazyState);
        setselectedStatus(ACTIVE);
    };


    const header =
        <div className="table-header-container">
            <div className="font-size-18">Enquiry List</div>

            <div className="table-header-container-item">

            </div>
            <div className="flex-grow-1"></div>
            <div className="table-header-container-item">
                <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />

                <Button type="button" className="customize-table-button mt-2" id="ScheduleUpdateTooltip"
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
                    <FeatherIcon icon="sliders" />
                </Button>
                <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
                    <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
                    Add Enquiry
                </Button>
                <UncontrolledTooltip
                    placement="bottom"
                    target="ScheduleUpdateTooltip"
                    isOpen={tooltipOpen}
                    toggle={toggleTooltip}
                    innerClassName="tooltip-color"
                >
                    Choose fields you want to see in the table
                </UncontrolledTooltip>
            </div>

        </div>;


    const customizeTableCancelHandler = () => {
        setShowCustomizeTable(false);
    };

    const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
        let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

        setVisibleColumns([...orderedSelectedColumns]);
        setShowCustomizeTable(false);
    };

    const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
        let data: any = {};
        data['field'] = eachColumns.field;
        data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
        data['isDisable'] = eachColumns.isStatic;
        data['header'] = eachColumns.header;
        return data;
    }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

    useEffect(() => {
        const preloader: any = document.getElementById("preloader");
        if (loading || pdfLoading) {
            preloader.style.display = "block";
        } else {
            preloader.style.display = "none";
        }
    }, [loading, pdfLoading]);



    useEffect(() => {
        setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
    }, [jobSheetList, employeeDropdownList, franchiseDropdownList, compinedUserList]);
    return (
        <>
            <div className="page-content">
                {visible && dialogContainer(error)}
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={() => {
                        if (rowData) {
                            return handleDeleteUsers(rowData);
                        }
                        if (selectedDealers?.length > 10) {
                            showToast("Cannot delete more than 10 items at once", { type: "error" });
                            setSelectedDealers([]);
                            setDeleteModal(false);
                            setSelectAll(false);
                            return setRowData(null);
                        }

                        if (selectedDealers.length > 0) {
                            for (let i = 0; i < selectedDealers.length; i++) {
                                handleDeleteUsers(selectedDealers[i], selectedDealers.length, i);
                            }
                        }
                    }}
                    onCloseClick={() => {
                        setDeleteModal(false);
                        setRowData(null);
                    }}
                />
                <ArchiveModal
                    show={archiveModal}
                    message="you want to archieve these purchase order?"
                    deleteLabel="Yes"
                    closeLabel="No"
                    onDeleteClick={() => {
                        archiveHandler();
                    }}
                    onCloseClick={() => {
                        setArchiveModal(false);
                        setSelectAll(false);
                        setSelectedDealers([]);
                    }}
                />
                {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


                {filter && (
                    <div className="card">
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                onReset={handleReset}
                                enableReinitialize
                            >
                                {({ values, handleChange }) => {
                                    return (
                                        <Form onChange={handleChange}>
                                            <Row id="export-search-clear">
                                                <Col xs={12} lg={7} >

                                                </Col>
                                                <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                                                    <Select
                                                        label=""
                                                        name="status"
                                                        onBlur={handleStatusBlur}
                                                        value={values.status}
                                                        onChange={handleChange}
                                                        options={options}
                                                        invalid={false}
                                                    />
                                                    <Button className="btn btn-primary ms-2" color="primary" type="reset">
                                                        Clear </Button>
                                                    <Button className="btn btn-primary ms-2" color="primary" type="submit">
                                                        Search </Button>
                                                </Col>

                                            </Row>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                )}

                <DataTableWrapper
                    ref={dt}
                    columnDetails={visibleColumns}
                    header={header}
                    value={modifiedValues}
                    sortIcon={(setOrder) => <SortIcon order={setOrder} />}
                    filterIcon={FilterIcon}
                    lazy
                    paginator={true}
                    stripedRows={true}
                    rowHover={true}
                    // filterDisplay="row"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    totalRecords={totalRecords}
                    loading={loading}
                    emptyMessage="No Enquiry found."
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Displaying enquiries  {last} records of {totalRecords} in total"
                    selection={selectedDealers}
                    onSelectionChange={onSelectionChange}
                    selectAll={selectAll}
                    onSelectAllChange={onSelectAllChange}
                    first={lazyState.first}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    onFilter={onFilter}
                    filters={lazyState.filters}
                    onPage={onPage}
                    rows={lazyState.rows}
                >
                </DataTableWrapper>
            </div>
        </>
    );
}
