import {  Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import InputCom from 'src/components/Common/Input';
import Button from 'src/components/UI/Button';
import { updateAdjustmentPointLoading } from 'src/store/IncentiveReward/action';
import * as Yup from 'yup';

const UpdateAdjustmentPoint = ({details,id,disabled}:any) => {
const dispatch=useDispatch()
    const initialvalue={
        adjustedRewardPoint:details?.adjustedRewardPoint ? JSON.parse(JSON.stringify(details?.adjustedRewardPoint)) : "",
        adjustmentNotes:details?.adjustmentNotes ? JSON.parse(JSON.stringify(details?.adjustmentNotes)) : "",
    }

    const handleSubmit=(values:any)=>{
        
        dispatch(updateAdjustmentPointLoading(values,id))
    }

    const validationSchema=Yup.object().shape({
        adjustedRewardPoint:Yup.number().required('Adjustment Point is required')
        .min(0, 'Adjustment Point cannot be negative'),
        adjustmentNotes:Yup.string().required('Adjustment Notes is required'),
    })

    const handleReset = (setFieldValue: any,setFieldTouched:any) => {
        setFieldValue('adjustedRewardPoint',details?.adjustedRewardPoint)
        setFieldValue('adjustmentNotes', details?.adjustmentNotes)
        setFieldTouched('adjustedRewardPoint',false)
        setFieldTouched('adjustmentNotes',false)
      }

  return (

      <React.Fragment>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialvalue}
          validationSchema={validationSchema}
          enableReinitialize>
          {({ values, handleChange, touched, errors, handleBlur, resetForm,setFieldValue,setFieldTouched, reset, ...rest }: any) => {
            return (
              <Form onChange={handleChange}>
                <Row className=''>
                  <Col lg={4} className='mb-2'>
                    <InputCom labelName="Adjust Reward Point"
                      inputclassName="form-control"
                      labelClassName="form-Label"
                      type="number"
                      name="adjustedRewardPoint"
                      onBlur={handleBlur}
                      value={values.adjustedRewardPoint}
                      onChange={handleChange}
                      isRequired={true}
                      disabled={disabled}
                      invalid={touched["adjustedRewardPoint"] && errors["adjustedRewardPoint"] ? true : false}
                    />

                  </Col>



                  <Col lg={4} className='mb-2'>
                    <InputCom labelName="Adjustment Note"
                      inputclassName="form-control"
                      labelClassName="form-Label"
                      type="textarea"
                      name="adjustmentNotes"
                      onBlur={handleBlur}
                      value={values.adjustmentNotes}
                      onChange={handleChange}
                      isRequired={true}
                      disabled={disabled}
                      invalid={touched["adjustmentNotes"] && errors["adjustmentNotes"] ? true : false}
                    />

                  </Col>
                 
                 
                  <Col lg={4} className='d-flex justify-content-end align-items-center'>
                  {/* <div className="d-flex justify-content-end align-items-center"> */}
                    <Button
                      className="secondary-btn me-2"
                      label="Reset"
                      type="reset"
                      onClick={() => handleReset(setFieldValue,setFieldTouched)}
                    />
                    <Button
                      className="btn-primary me-2 btn-primary-shadow"
                      label="Update"
                      type="submit"
                      disabled={disabled}
                    />
                    {/* </div> */}
                    </Col>
   
                </Row>


              </Form>
            )
          }}
        </Formik>
      </React.Fragment>

  )
}

export default UpdateAdjustmentPoint