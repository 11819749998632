import { PurchaseOrderTypes } from "./actionTypes";

export const getPurchaseOrderList = (lazyState: any) => {
  return {
    type: PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST,
    lazyState,
  };
};


export const getPurchaseOrderListSuccess = (payload: any) => {
  return {
    type: PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST_SUCCESS,
    payload: payload,
  };
};

export const getPurchaseOrderListError = (error: any) => {
  return {
    type: PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST_FAIL,
    payload: error,
  };
};

// ---------------------------------------------------

export const getPurchaseOrderById = (id: any) => {
  return {
    type: PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID,
    payload: id 
  }; 
};
export const getPurchaseOrderByIdSuccess = (payload: any) => {
  return {
    type: PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID_SUCCESS,
    payload:payload,
  }; 
};
export const getPurchaseOrderByIdError = (error: any) => {
  return {
    type: PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID_FAIL,
    payload: error,
  };
};

// --------------------------------------------------------------

export const deletePurchaseOrderFromList = (payload:any) => {
  return {
    type: PurchaseOrderTypes.DELETE_PURCHASE_ORDER,
    payload: payload,
  }
}

export const deletePurchaseOrderFromListSuccess = (response:any,undo:boolean) => {
  let payload = {
    response,
    undo
  }
  return {
    type: PurchaseOrderTypes.DELETE_PURCHASE_ORDER_SUCCESS,
    payload: payload,
  }
}

export const deletePurchaseOrderFromListError = (error:any) => {
  return {
    type: PurchaseOrderTypes.DELETE_PURCHASE_ORDER_FAIL,
    payload: error,
  }
}

// --------------------------------------------

export const updatePurchaseOrder=(purchaseOrderDetails :any ,id: string = "")=>{
  return {
    type : PurchaseOrderTypes.UPDATE_PURCHASE_ORDER,
    payload: { purchaseOrderDetails, id } };
}

export const updatePurchaseOrderSuccess=(data:any)=>{
  return {
    type : PurchaseOrderTypes.UPDATE_PURCHASE_ORDER_SUCCESS,
    payload: data
  }
}

export const updatePurchaseOrderError=(error : any)=>{
  return {
    type : PurchaseOrderTypes.UPDATE_PURCHASE_ORDER_ERROR,
    payload : error
  }
}

// ----------------------------------------

export const createPurchaseOrder=(data :any )=>{
  
  return {
    type : PurchaseOrderTypes.CREATE_PURCHASE_ORDER,
    payload : data
  }
}
export const createPurchaseOrderSuccess=(data:any)=>{
  return {
    type : PurchaseOrderTypes.CREATE_PURCHASE_ORDER_SUCCESS
  }
}

export const createPurchaseOrderError=(error : any)=>{
  return {
    type : PurchaseOrderTypes.CREATE_PURCHASE_ORDER_ERROR,
    payload : error
  }
}

// ----------------------------------

export const getDropdownForPurchase = ()=>{
  
  return {
    type : PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER,
  }
}
export const getDropdownForPurchaseSuccess = (data :any )=>{  
  return {
    type : PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER_SUCCESSS,
    payload : data
  }
}

export const getDropdownForPurchaseError =(error : any)=>{
  return {
    type : PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER_FAIL,
    payload : error
  }
}
// ----------------------------------

export const sentMailToSupplierPurchaseOrder = (id:any)=>{
  
  return {
    type : PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER,
    payload : id
  }
}
export const sentMailToSupplierPurchaseOrderSuccess = (data :any )=>{  
  return {
    type : PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS,
    payload : data
  }
}

export const sentMailToSupplierPurchaseOrderError = (error : any)=>{
  return {
    type : PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL,
    payload : error
  }
}

//  DELETE


export const deletePurchaseOrderDetails = (payload:any)=>{
  return {
    type : PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS,
    payload : payload
  }
}  

export const deletePurchaseOrderDetailsSuccess = (data:any)=>{
  return {
    type : PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS_SUCCESSS,
    payload : data
  }
}  

export const deletePurchaseOrderDetailsError =(error : any)=>{
  return {
    type : PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS_FAIL,
    payload : error
  }
}


// -----------------------------

//reset PurchaseOrder
export const resetPurchaseOrder=()=>{
  return {
    type : PurchaseOrderTypes.RESET_PURCHASE_ORDER,
  }
}

//clear PurchaseOrder
export const clearPurchaseOrder=()=>{
  return {
    type : PurchaseOrderTypes.CLEAR_PURCHASE_ORDER,
  }
}


//  Parts autocomplete

export const partsAutoCompleteList = (params: any) => {
  return {
    type: PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST,
    params,
  };
};


export const partsAutoCompleteListSuccess = (payload: any) => {
  return {
    type: PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST_SUCCESSS,
    payload: payload,
  };
};

export const partsAutoCompleteListError = (error: any) => {
  return {
    type: PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST_FAIL,
    payload: error,
  };
};


//-------get audioModel dropdown ------------

export const getPurchaseOrderAudioModelDropdownLoading=(id : any,index:number)=>{
  return {
    type : PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_LOADING,
    payload : {id,index}
  }
}

export const getPurchaseOrderAudioModelDropdownSuccess=(resp:any,index:number)=>{
  debugger;
  return {
    type : PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_SUCCESS,
    payload : {resp,index}
  }
}

export const getPurchaseOrderAudioModelDropdownError=(error  :any)=>{
  return {
    type :  PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_ERROR,
    payload : error
  }
}


export const resetPurchaseOrderIndex = ()=>{
  return {
    type :  PurchaseOrderTypes.RESET_PURCHASE_ORDER_INDEX
  }
}

// -------------------------

export const downloadPurchaseOrder = (id:any)=>{
  return {
    type :  PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_LOADING,
    payload : id
  }
}

export const downloadPurchaseOrderSuccess = (resp : any)=>{
  return {
    type :  PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_SUCCESS,
    payload : resp
  }
}

export const downloadPurchaseOrderError = (error :any)=>{
  return {
    type :  PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_ERROR,
    payload : error
  }
}


