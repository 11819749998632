export enum WhatsappTypes {

    GET_WHATSAPP_LIST_LOADING = "@@whatsapp/GET_WHATSAPP_LIST_LOADING",
    GET_WHATSAPP_LIST_SUCCESS = "@@whatsapp/GET_WHATSAPP_LIST_SUCCESS",
    GET_WHATSAPP_LIST_ERROR = "@@whatsapp/GET_WHATSAPP_LIST_ERROR",

    GET_EMPLOYEE_NUM_LOADING = "@@whatsapp/GET_EMPLOYEE_NUM_LOADING",
    GET_EMPLOYEE_NUM_SUCCESS = "@@whatsapp/GET_EMPLOYEE_NUM_SUCCESS",
    GET_EMPLOYEE_NUM_ERROR = "@@whatsapp/GET_EMPLOYEE_NUM_ERROR",

    GET_DEALER_NUM_LOADING = "@@whatsapp/GET_DEALER_NUM_LOADING",
    GET_DEALER_NUM_SUCCESS = "@@whatsapp/GET_DEALER_NUM_SUCCESS",
    GET_DEALER_NUM_ERROR = "@@whatsapp/GET_DEALER_NUM_ERROR",

    GET_CUSTOMER_NUM_LOADING = "@@whatsapp/GET_CUSTOMER_NUM_LOADING",
    GET_CUSTOMER_NUM_SUCCESS = "@@whatsapp/GET_CUSTOMER_NUM_SUCCESS",
    GET_CUSTOMER_NUM_ERROR = "@@whatsapp/GET_CUSTOMER_NUM_ERROR",

    GET_FRANCHISE_NUM_LOADING = "@@whatsapp/GET_FRANCHISE_NUM_LOADING",
    GET_FRANCHISE_NUM_SUCCESS = "@@whatsapp/GET_FRANCHISE_NUM_SUCCESS",
    GET_FRANCHISE_NUM_ERROR = "@@whatsapp/GET_FRANCHISE_NUM_ERROR",

    GET_DEALER_SUBUSER_NUM_LOADING = "@@whatsapp/GET_DEALER_SUBUSER_NUM_LOADING",
    GET_DEALER_SUBUSER_NUM_SUCCESS = "@@whatsapp/GET_DEALER_SUBUSER_NUM_SUCCESS",
    GET_DEALER_SUBUSER_NUM_ERROR = "@@whatsapp/GET_DEALER_SUBUSER_NUM_ERROR",

    GET_FRANCHISE_SUBUSER_NUM_LOADING = "@@whatsapp/GET_FRANCHISE_SUBUSER_NUM_LOADING",
    GET_FRANCHISE_SUBUSER_NUM_SUCCESS = "@@whatsapp/GET_FRANCHISE_SUBUSER_NUM_SUCCESS",
    GET_FRANCHISE_SUBUSER_NUM_ERROR = "@@whatsapp/GET_FRANCHISE_SUBUSER_NUM_ERROR",

    GET_SUPPLIER_NUM_LOADING = "@@whatsapp/GET_SUPPLIER_NUM_LOADING",
    GET_SUPPLIER_NUM_SUCCESS = "@@whatsapp/GET_SUPPLIER_NUM_SUCCESS",
    GET_SUPPLIER_NUM_ERROR = "@@whatsapp/GET_SUPPLIER_NUM_ERROR",

    CREATE_WHATSAPP_MESSAGE_LOADING = "@@whatsapp/CREATE_WHATSAPP_MESSAGE_LOADING",
    CREATE_WHATSAPP_MESSAGE_SUCCESS = "@@whatsapp/CREATE_WHATSAPP_MESSAGE_SUCCESS",
    CREATE_WHATSAPP_MESSAGE_ERROR = "@@whatsapp/CREATE_WHATSAPP_MESSAGE_ERROR",

    DELETE_WHATSAPP_MESSAGE_LOADING = "@@whatsapp/DELETE_WHATSAPP_MESSAGE_LOADING",
    DELETE_WHATSAPP_MESSAGE_SUCCESS = "@@whatsapp/DELETE_WHATSAPP_MESSAGE_SUCCESS",
    DELETE_WHATSAPP_MESSAGE_ERROR = "@@whatsapp/DELETE_WHATSAPP_MESSAGE_ERROR",

    GET_BY_ID_WHATSAPP_MESSAGE_LOADING = "@@whatsapp/GET_BY_ID_WHATSAPP_MESSAGE_LOADING",
    GET_BY_ID_WHATSAPP_MESSAGE_SUCCESS = "@@whatsapp/GET_BY_ID_WHATSAPP_MESSAGE_SUCCESS",
    GET_BY_ID_WHATSAPP_MESSAGE_ERROR = "@@whatsapp/GET_BY_ID_WHATSAPP_MESSAGE_ERROR",

    GET_WHATSAPP_MESSAGE_STATUS_LOADING = "@@whatsapp/GET_WHATSAPP_MESSAGE_STATUS_LOADING",
    GET_WHATSAPP_MESSAGE_STATUS_SUCCESS = "@@whatsapp/GET_WHATSAPP_MESSAGE_STATUS_SUCCESS",
    GET_WHATSAPP_MESSAGE_STATUS_ERROR = "@@whatsapp/GET_WHATSAPP_MESSAGE_STATUS_ERROR",
    
    GET_WHATSAPPDOCUMENT_LINK_LOADING = "@@whatsapp/GET_WHATSAPP_DOCUMENT_LINK_LOADING",
    GET_WHATSAPPDOCUMENT_LINK_SUCCESS = "@@whatsapp/GET_WHATSAPP_DOCUMENT_LINK_SUCCESS",
    GET_WHATSAPPDOCUMENT_LINK_ERROR = "@@whatsapp/GET_WHATSAPP_DOCUMENT_LINK_ERROR",

    CLEAR_WHATSAPP_MESSAGE_MESSAGE = "@@whatsapp/CLEAR_WHATSAPP_MESSAGE_MESSAGE",
    RESET_WHATSAPP_MESSAGE_MESSAGE = "@@whatsapp/RESET_WHATSAPP_MESSAGE_MESSAGE",
}