import { ErrorMessage } from 'formik';
import { Calendar } from 'primereact/calendar';
import { FormEvent, Nullable } from "primereact/ts-helpers";
import React, { SyntheticEvent } from 'react';
import ErrorText from './ErrorText';
import Label from './Label';


interface DateInputProps {
  name: string
  label: string
  value?: Nullable<Date>
  required?: boolean
  onChange?: (event: FormEvent<Date, SyntheticEvent<Element, Event>>) => void
}

const DateInput = (props: DateInputProps) => {
  const { name, label, value, required=false, ...rest } = props

  return (
    <div className='custom-date-container'>
      <Label name={name} label={label} required={required}/>
      <Calendar id={name} name={name} value={value} dateFormat="mm/dd/yy" {...rest} showIcon  />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  )
};

export default DateInput;
