import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import "./styles.scss"
import { useDispatch, useSelector } from 'react-redux'
import { getAudioBrandImageLoading, getByIdAudioBrandPending, getVehicleBrandNameListLoading } from 'src/store/actions'
import { RootReducerState } from 'src/store/reducers'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image'

const ViewAudioBrand = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { audioBrandDetails, vehicleBrandNameList, audioBrandImage } = useSelector((state: RootReducerState) => state.audioBrandsReducer);
  useEffect(() => {
    dispatch(getVehicleBrandNameListLoading())
    dispatch(getByIdAudioBrandPending(id));
    dispatch(getAudioBrandImageLoading(id))
  }, []);

  const getVehicleBrandName = (id: number) => {
    const matchedNameObject = vehicleBrandNameList?.find((item: any) => item.id === audioBrandDetails.vehicleBrandId);
    if (matchedNameObject) {
      const matchedName = matchedNameObject.name;
      return <Link to={`${ROUTER.VEHICLE_BRANDS_VIEW}/${audioBrandDetails?.vehicleBrandId}`}> {matchedName}</Link>
    }
  }

  const AudioBrandDetails = [
    // {
    //   name : "Audio BrandName",
    //   value : audioBrandDetails?.audioBrandName ? audioBrandDetails?.audioBrandName : ""
    // },
    {
      name: "Brand Name",
      value: audioBrandDetails?.brandName ? audioBrandDetails?.brandName : ""
    },
    {
      name: "Description",
      value: audioBrandDetails?.description ? audioBrandDetails?.description : ""
    },
    {
      name: "Job Prefix",
      value: audioBrandDetails?.jobPrefix ? audioBrandDetails?.jobPrefix : ""
    },
    {
      name: "Serviced Count",
      value: audioBrandDetails?.servicedCount ? audioBrandDetails?.servicedCount : ""
    },
    {
      name: "Vehicle Brand",
      value: audioBrandDetails?.vehicleBrandId ? getVehicleBrandName(audioBrandDetails?.vehicleBrandId) : ""
    },
  ]

  const editAudioBrand = () => {
    navigate(`${ROUTER.AUDIO_BRANDS_EDIT}/${id}`)
  }
  return (
    <React.Fragment>
      <div className="pagecontent" id='pagecontent'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Audio Brands"
            titlePath={ROUTER.AUDIO_BRANDS_GRID}
            breadcrumbItem="Audio Brand Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Audio Brand'
            onFirstButtonClick={editAudioBrand}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Audio Brand Details</b>
              </div>
              <div className="subview">
                <div className='parentCls'>
                  <div >
                    <div className="parentCls2">
                      {
                        AudioBrandDetails.map((data, index) => {
                          return (
                            <div key={index} className={` pt-1 childCls`}>
                              <div >{data.name}</div>
                              <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div>
                  <div>
                    <Row>
                      <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {audioBrandImage ?
                          // <img src={audioBrandImage} className='view-image' />
                          <Image src={audioBrandImage} alt="Image" className='view-image' height='150px' preview />
                          :
                          <div className='none-text'>-</div>
                        }
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewAudioBrand