import { call, put, takeLatest } from 'redux-saga/effects';
import { FeedbackActions } from "./actionTypes"
import { createFeedbackError, createFeedbackSuccess, getFeedbackIdDetailsError, getFeedbackIdDetailsSuccess, getFeedbackListError, getFeedbackListSuccess, getJobsheetByIdForFeedbackError, getJobsheetByIdForFeedbackSuccess, getVehiclebrandDropdownForFeedbackError, getVehiclebrandDropdownForFeedbackSuccess, getVehicleBrandFeedBackError, getVehicleBrandFeedBackSucces } from "./action"
import { API } from 'src/util/api';


//worker function

//get feedbackList

function* getFeedbackListAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.getFeedbackListApi, { ...action.lazyState })
        yield put(getFeedbackListSuccess(response))
    } catch (error) {
        yield put(getFeedbackListError(error))
    }
}





  function* createFeedbackAsync(action: any) {
   
    try {
      const {data} = yield call(API.createFeedbackForJobsheet, action.payload);
      yield put(createFeedbackSuccess(data))
    } catch (error) {
      yield put(createFeedbackError(error))
    }
  }



function* getVehicleBrandFeedbackAsync() {
    try {
        const response: Promise<any> = yield call(API.getFeedbackVehiclebrandApi)
        yield put(getVehicleBrandFeedBackSucces(response))
    } catch (error) {
        yield put(getVehicleBrandFeedBackError(error))
    }
}


function* getFeedbackIdDetailsAsync(action: any) {
    debugger
    try {
        const response: Promise<any> = yield call(API.getFeedbackIdDetailsApi, action.payload)
        yield put(getFeedbackIdDetailsSuccess(response))
    } catch (error) {
        yield put(getFeedbackIdDetailsError(error))
    }
}



//JobSheet by id
function* fetchJobsheet(action: any): Generator<any, void, any> {
    try {
        console.log("JOBSHEET 123")
        let response = yield call(API.getJobByIdAPI, action.payload);
        yield put(getJobsheetByIdForFeedbackSuccess(response));
    } catch (error) {
        yield put(getJobsheetByIdForFeedbackError(error));
    }
}


function* getVehiclebrandDropdownAsync() {
    try {
      const response : Promise<any> = yield call(API.getVehiclebrandDropdownApi);    
      yield put(getVehiclebrandDropdownForFeedbackSuccess(response));
    } catch (error) {
      yield put(getVehiclebrandDropdownForFeedbackError(error));
    }
  }


  

////watcher function
function* feedbackSaga() {
    yield takeLatest(FeedbackActions.GET_FEEDBACK_LIST_LOADING, getFeedbackListAsync);
    yield takeLatest(FeedbackActions.GET_FEEDBACK_VEHICLEBRAND_DROPDOWN_LOADING, getVehicleBrandFeedbackAsync);
    yield takeLatest(FeedbackActions.GET_FEEDBACK_BYID_LOADING, getFeedbackIdDetailsAsync);
    yield takeLatest(FeedbackActions.GET_JOBSHEET_DETAILS_LOADING, fetchJobsheet);
    yield takeLatest(FeedbackActions.GET_VEHICLEBRAND_DROPDOWN_FOR_FEEDBACK_LOADING, getVehiclebrandDropdownAsync);
    yield takeLatest(FeedbackActions.CREATE_FEEDBACK_LOADING, createFeedbackAsync);
}

export default feedbackSaga