import moment from 'moment';
import * as XLSX from 'xlsx';
import { TABOPTIONS } from 'src/constants/commonConstants';
import { DropEvent, FileRejection } from 'react-dropzone';
import { ToastOptions } from 'react-toastify';
import axios from 'axios';
import { CellDef } from 'jspdf-autotable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_LOGIN_URL, CLIENT_ID, baseUrl } from 'src/util/auth/authConfig';
import { UserTypes } from 'src/constants/userRoles';
import { ROUTER } from 'src/constants/routes';
import { isNull } from 'lodash';

export const CurrencyFormatter = ({ value, currencyCode = 'INR' }: any) => {
  // Format the number as currency
  if (value === "" || value === null || value === undefined) {
    return "-"
  }
  const formattedValue = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currencyCode,
  }).format(value);

  return formattedValue;
};

export const numberFormatter = (value: number) => {
  return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(value);
}

export const printHTMLContent = (printableData: string) => {
  // Create a new window and write the data to be printed
  const printWindow: Window = window.open('', '_blank') as Window;
  printWindow.document.write(`<html><head><title>Print</title></head><body><pre>${printableData}</pre></body></html>`);
  // Close the print window after printing
  setTimeout(() => {
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }, 500)


};

export const saveAsExcelFile = (buffer: any, fileName: string) => {
  import('file-saver').then((module) => {
    if (module && module.default) {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });

      module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
  });
};

export const saveDataAsExcel = (values: any[], fileName: string) => {
  const modifiedColumnDetails = values.map((data) => {
    let object = {
      Number: data?.user?.phoneNumber,
      email: data?.user?.email,
      gstNumber: data?.user?.gstNumber,
      taxTypeId: data?.taxTypeId,
      DOB: data?.user?.dateOfBirth,
      sendEmail: data?.user?.sendEmail,
      sendSms: data?.user?.sendSms,
      Address: data?.user?.address
    }
    return object
  })
  import('xlsx').then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    saveAsExcelFile(excelBuffer, fileName);
  });
};


export const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
  const destructureedData = values.map((data) => {
    let datas = {
      Name: data.user.name,
      PhoneNumber: data.user?.phoneNumber,
      alternateNumber: data.alternateNumber,
      Email: data.user?.email,
      gstNumber: data.user.gstNumber,
      address: data.user.address,
      taxType: data.taxTypeId,
      dateOfBirth: data.user.dateOfBirth,
      weddingDate: data.user.weddingDate,
      sendSms: data.user.sendSms,
      sendEmail: data.user.sendEmail
    }
    return datas
  })
  import("jspdf").then((jsPDF) => {
    import("jspdf-autotable").then(() => {
      const exportColumns = columns.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
      const doc = new (jsPDF as any).default(0, 0);
      doc.autoTable(exportColumns, destructureedData);
      doc.save(`${fileName}.pdf`);
    });
  });
};


export const formatDateTOYYYYMMDD = (orginalweddingDate: any): string => {
  return moment(orginalweddingDate).format('YYYY-MM-DD');
}

export const formatFirstDayOfMonthToYYYYMMDD = (originalDate: any): string => {
  if
    (!moment(originalDate).isValid()) {
    throw new Error('Invalid date format');
  }

  const firstDayOfMonth = moment(originalDate).startOf('month');

  return firstDayOfMonth.format('YYYY-MM-DD');
}
export const formatLastDayOfMonthToYYYYMMDD = (originalDate: any): string => {
  if (!moment(originalDate).isValid()) {
    throw new Error('Invalid date format');
  }

  const lastDayOfMonth = moment(originalDate).endOf('month');

  return lastDayOfMonth.format('YYYY-MM-DD');
}

export const formatDateMEL = (inputDate: any): string => {
  if (!inputDate || inputDate == null) {
    return ""
  }
  // const date = new Date(inputDate);
  // const formattedDate = date.toLocaleDateString('en-US', {
  //   day: 'numeric',
  //   month: 'short',
  //   year: 'numeric',
  // });
  // const [month, day, year] = formattedDate.split(' ');
  // const formatDay = day.slice(0, -1);
  // const result = `${formatDay}  ${month.charAt(0).toUpperCase() + month.slice(1)}, ${year}`;
  // return result;
  const date = moment.utc(inputDate);
  const dayOfMonth = date.format('DD');
  const month = date.format('MMM');
  const year = date.format('YYYY');
  return `${dayOfMonth} ${month}, ${year}`;
};
const manageDate = (date: any) => {
  // console.log(date)
  // return new Date(date)

  const utcDateTime = moment.utc(date);
  const formattedUtcDate = new Date(utcDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
  return formattedUtcDate;
}
export const formatDateTimeMEL = (inputDate: any): string => {
  if (!inputDate || inputDate == null) {
    return "";
  }
  const date = moment.utc(inputDate);

  const dayOfMonth = date.format('DD');
  const month = date.format('MMMM');
  const year = date.format('YYYY');
  const time = date.format('HH:mm');

  return `${dayOfMonth} ${month}, ${year}, ${time}`;
};

export function convertToDateTimeLocalMEL(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = `${(date.getUTCMonth() + 1)}`.padStart(2, '0');
  const day = `${date.getUTCDate()}`.padStart(2, '0');
  const hours = `${date.getUTCHours()}`.padStart(2, '0');
  const minutes = `${date.getUTCMinutes()}`.padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const convertTableToCSV = (table: any) => {
  const ws = XLSX.utils.table_to_sheet(table);
  return ws;
};

export const downloadCSV = (csvData: any, fileName: any) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const applicableTab = (status: string) => {
  const FilterTab = TABOPTIONS.filter((tab, index) => tab.access.includes(status));
  return FilterTab;
};


export const onDropRejectedFileHandler = (fileRejections: FileRejection[], event: DropEvent, showToast: (message: string, options?: ToastOptions<{}> | undefined) => void) => {
  showToast(fileRejections[0]?.errors[0]?.message, { type: "error" })
}
export const getCurrentLocation = async (latitude: number, longitude: number): Promise<any> => {
  try {
    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    const { address } = response.data;
    return address;
  } catch (error) {
    //console.error('Error fetching location:', error);
    throw error;
  }
}

export const formatTime = (hours: number, minutes: number, seconds: number): string => {
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const formatTotalHours = (hours: number, minutes: number): string => {
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

//This function calculate the two date between time difference and convert to hours,minutes,seconds
export const calculateTimeDifference = (loginTime: Date, currentTime: Date = new Date()): string => {
  
  const timeDifference = currentTime.getTime() - loginTime.getTime();
  const hours = Math.floor(timeDifference / (1000 * 3600));

  // const minutes = Math.floor((timeDifference % (1000 * 3600)) / (1000 * 60));
  // const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  // return formatTime(hours, minutes, seconds);

  // Code changed on 14-02-2024
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDifference / (1000)) % 60);

  const formattedHours = hours < 0 ? `00` : hours < 10 ? `0${hours}` : hours
  const formattedMinutes = minutes < 0 ? `00` : minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 0 ? `00` : seconds < 10 ? `0${seconds}` : seconds

  // console.log("formattedHours", formattedHours)
  // console.log("formattedMinutes", formattedMinutes)
  // console.log("formattedSeconds", formattedSeconds)

  const formattedTime = formattedHours + ":" + formattedMinutes + ":" + formattedSeconds
  return formattedTime

};




export const newCalculateTimeDifference = (loginTime: moment.Moment, currentTime: moment.Moment = moment()): string => {
  

  const timeDifference = currentTime.diff(loginTime); // Difference in milliseconds
  const duration = moment.duration(timeDifference); // Convert milliseconds to duration

  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the time components
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  return formattedTime;
};


//This function decimal values convert to hours minites and seconds
export function decimalToTime(decimalHours: number): { hours: number, minutes: number, seconds: number } {
  const hours: number = Math.floor(decimalHours);
  const minutesDecimal: number = (decimalHours - hours) * 60;
  const minutes: number = Math.floor(minutesDecimal);
  const seconds: number = Math.round((minutesDecimal - minutes) * 60);
  return { hours, minutes, seconds };
}

//add two times
export function addTimes(time1: string, time2: string): string {
  const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
  const [hours2, minutes2, seconds2] = time2.split(':').map(Number);

  const totalSeconds = (hours1 * 3600 + minutes1 * 60 + seconds1) + (hours2 * 3600 + minutes2 * 60 + seconds2);
  const totalHours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const totalMinutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsFinal = remainingSeconds % 60;

  return `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}:${String(remainingSecondsFinal).padStart(2, '0')}`;
}
//minus two times
export function minusTimes(time1: string, time2: string): string {
  const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
  const [hours2, minutes2, seconds2] = time2.split(':').map(Number);
  const totalSeconds = (hours1 * 3600 + minutes1 * 60 + seconds1) - (hours2 * 3600 + minutes2 * 60 + seconds2);
  const totalHours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const totalMinutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsFinal = remainingSeconds % 60;
  return `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}:${String(remainingSecondsFinal).padStart(2, '0')}`;
}



export function hasAdminOrEmployeeRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return true;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("Employee");
    } else if (parsedValue == "Employee") {
      return true
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}
export function onlyAdminRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}


export function hasDealerRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return false;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("Dealer");
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}

export function hasManufacturerRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return false;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("Manufacturer");
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}


export const formattedDateForjobSheet = (currentDate: Date) => {
  const options: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    timeZone: 'Asia/Kolkata' // Replace 'Asia/Kolkata' with your desired timezone
  };
  // return currentDate.toLocaleString('en-US', options);
  return moment(currentDate).format('YYYY-MM-DD HH:mm:ss ZZ');
}


export const isJobsheetCreateAllow = () => {
  return hasAdminOrEmployeeRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler()
}



export class HtmlRowInput extends Array<CellDef> {
  _element: HTMLTableRowElement;
  constructor(element: HTMLTableRowElement) {
    super();
    this._element = element;
  }
}


export const getToken = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const code_verifier = localStorage.getItem("PKCE_verifier");
  let model: any = {
    grant_type: 'authorization_code',
    code,
    redirect_uri: baseUrl + "/authentication/login-callback",
    code_verifier,
    client_id: CLIENT_ID
  };
  const formData = new URLSearchParams();

  for (let key in model) {
    formData.append(key, model[key]);
  }

  const axiosInstance = axios.create({
    baseURL: AUTH_LOGIN_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });

  axiosInstance.post("/connect/token", formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }).then(response => {
    localStorage.removeItem("PKCE_verifier");
    const { data } = response;
    const token = data?.access_token;
    const payloadEncoded = token.split('.')?.[1];
    const decodedPayload = JSON.parse(atob(payloadEncoded));
    
    const { email, role, preferred_username: username, sub: uid } = decodedPayload;
    localStorage.setItem('role', JSON.stringify(role));
    localStorage.setItem("token", `${token}`);
    let loggedInUser = { email, role, username, uid };
    localStorage.setItem("authUser", JSON.stringify(loggedInUser));
    if (role.includes(UserTypes[UserTypes['Dealer']])) {
      return navigate(ROUTER.DEALER_SUB_USER_GRID);
    } else if (role.includes(UserTypes[UserTypes['Franchise']])) {
      return navigate(ROUTER.SUB_USER_GRID);
    } else if (role.includes(UserTypes[UserTypes['FranchiseSubUser']])) {
      return navigate(ROUTER.ATTENDANCE);
    }
    navigate(ROUTER.USERS_GRID);

  }).catch(error => {
    console.log("Message Error ...> ", error)
  });
}


export const formatUtcDateForEdit = (date: any) => {
  // const utcDateTime = moment.utc(date);
  // const formattedUtcDate = new Date(utcDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
  // return formattedUtcDate;
  if (!date) return ""
  return new Date(date?.split("T")?.[0])
}

export const formatUtcNewForEditWithTime = (date: any) => {
  // console.log("date date datedate date date date date ", moment.utc(date))

  // if (!date) return ""
  // let timeValue = moment.parseZone(date).utc(true).valueOf()
  // console.log(moment.parseZone(date).utc(true).valueOf())
  // return new Date(timeValue)

  // Parse the local time string
  const momentObj = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  // Convert local time back to UTC
  const utcTime = momentObj.utc().format();
  let dataTimeObject = new Date(utcTime)
  return dataTimeObject;
}




export function hasFranchiseRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return false;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("Franchise");
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}


export function hasFranchiseSubuserRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return false;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("FranchiseSubUser");
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}

export function hasDealerSubuserRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return false;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("DealerSubUser");
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}

export function hasCustomerRoleHandler() {
  try {
    const value = localStorage.getItem("role");
    if (!value) {
      return false; // If no value found in localStorage, return false
    }
    const parsedValue = JSON.parse(value);
    if (parsedValue === "admin") {
      return false;
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.includes("Customer");
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user role:", error);
    return false;
  }
}

// export const ConvertToISOString = (date:any)=>{
//   console.log("hellow")
//   const day = date.getDate(); // Returns the day of the month (1-31)
//   const month = date.getMonth(); // Returns the month (0-11), add 1 to get (1-12)
//   const year = date.getFullYear(); // Returns the year (four digits)
//   // const hours = date.getHours(); // Returns the hours (0-23)
//   // const minutes = date.getMinutes(); // Returns the minutes (0-59)

//   const createdDate = new Date();
//   createdDate.setDate(day);
//   createdDate.setMonth(month);
//   createdDate.setFullYear(year);
//   // createdDate.setHours(hours);
//   // createdDate.setMinutes(minutes);
//   console.log(day,month,year);
//   const stringifiedDate = createdDate.toISOString().split('T')[0];
//   return stringifiedDate
// }

export const ConvertToISOString = (date: any) => {
  const day = date.getDate(); // Returns the day of the month (1-31)
  const month = date.getMonth(); // Returns the month (0-11), add 1 to get (1-12)
  const year = date.getFullYear(); // Returns the year (four digits)
  const hours = date.getUTCHours(); // Returns the hours (0-23) in UTC
  const minutes = date.getUTCMinutes(); // Returns the minutes (0-59) in UTC

  const createdDate = new Date(); // Creating a new Date object representing the current UTC time
  createdDate.setUTCDate(day); // Setting the day
  createdDate.setUTCMonth(month); // Setting the month
  createdDate.setUTCFullYear(year); // Setting the year
  createdDate.setUTCHours(hours); // Setting the hours
  createdDate.setUTCMinutes(minutes); // Setting the minutes

  const stringifiedDate = createdDate.toISOString(); // Convert the new Date object to ISO string
  return stringifiedDate;
};

export const ConvertIsoStringWithTime = (date: any) => {

  
  const localTime = moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  

  if (!date) return ""
  return moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}


export const formatDateTimeForView = (date: any, isTime: boolean = true) => {
  const momentObj = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  // Convert local time back to UTC
  const utcTime = momentObj.utc().format();
  let formattedDate: Date = formatUtcNewForEditWithTime(date) as Date

  //   const options:any = {
  //     day: '2-digit',
  //     month: 'long',
  //     year: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: false
  // };


  if (formattedDate.getHours() === 0 && formattedDate.getMinutes() === 0) {
    // Adjust to "00:00" while keeping the date the same
    formattedDate.setHours(0);
    formattedDate.setMinutes(0);
  }

  const options: any = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: false
  };




  
  let dateString = formattedDate.toLocaleString('en-US', options);
  if (dateString.endsWith("24:00")) {
    // Replace "24:00" with "00:00"
    dateString = dateString.slice(0, -5) + "00:00";
  }

  if (!isTime) {
    const dateOnly = dateString.replace(/ at \d+:\d+/, '');
    return dateOnly
  }


  return dateString;


}

export const formatDateTimeFormServerView = (date: any, isTime: boolean = true) => {
  const momentObj = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  // Convert local time back to UTC
  const utcTime = momentObj.utc().format();
  let formattedDate: Date = new Date(date) as Date

  if (formattedDate.getHours() === 0 && formattedDate.getMinutes() === 0) {
    // Adjust to "00:00" while keeping the date the same
    formattedDate.setHours(0);
    formattedDate.setMinutes(0);
  }

  const options: any = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: false
  };




  let dateString = formattedDate.toLocaleString('en-US', options);
  if (dateString.endsWith("24:00")) {
    // Replace "24:00" with "00:00"
    dateString = dateString.slice(0, -5) + "00:00";
  }

  if (!isTime) {
    const dateOnly = dateString.replace(/ at \d+:\d+/, '');
    return dateOnly
  }


  return dateString.replace(/at/, '');
}

export const formatDateTimeForHistoryView = (date: any, isTime: boolean = true) => {
  const momentObj = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  // Convert local time back to UTC
  const utcTime = momentObj.utc().format();
  let formattedDate: Date = formatUtcNewForEditWithTime(date) as Date




  if (formattedDate.getHours() === 0 && formattedDate.getMinutes() === 0) {
    // Adjust to "00:00" while keeping the date the same
    formattedDate.setHours(0);
    formattedDate.setMinutes(0);
  }

  const options: any = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: false
  };

  
  let dateString = formattedDate.toLocaleString('en-US', options);
  
  if (dateString.endsWith("24:00")) {
    // Replace "24:00" with "00:00"
    dateString = dateString.slice(0, -5) + "00:00";
  }

  if (!isTime) {

    const dateOnly = dateString.replace(/, \d+:\d+/, '');
    const [month, day, year] = dateOnly.split('/');
    return `${day}-${month}-${year}`;
    // return dateOnly
  }


  return dateString;


}


export const convertToMonthYear = (dateString: any) => {
  let dateVal: any = ""
  if (dateString) {
    dateVal = new Date(dateString);
  } else {
    dateVal = new Date()
  }
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };

  return dateVal.toLocaleDateString('en-US', options);
}

export const getMonthYear = (dateString: any) => {
  if (!dateString) {
    return ""
  } else {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return { month, year };
  }
}


export const getAdminRelatedActivePaths = (menu: any[]): string[] => {
  let adminActivePaths: string[] = [];
  const traverseMenu = (menuItems: any[]) => {
    for (const item of menuItems) {
      if (item.access.includes(UserTypes.admin)) {
        if (item.activePaths) {
          adminActivePaths.push(...item.activePaths);
        }
        if (item.children && item.children.length > 0) {
          traverseMenu(item.children);
        }
      }
    }
  };
  traverseMenu(menu);
  return adminActivePaths;
};

export const getCustomerRelatedActivePaths = (menu: any[]): string[] => {
  let customerActivePaths: string[] = [];
  const traverseMenu = (menuItems: any[]) => {
    for (const item of menuItems) {
      if (item.access.includes(UserTypes.Customer)) {
        if (item.activePaths) {
          customerActivePaths.push(...item.activePaths);
        }
        if (item.children && item.children.length > 0) {
          traverseMenu(item.children);
        }
      }
    }
  };
  traverseMenu(menu);
  return customerActivePaths;
};

export const getFranchiseRelatedActivePaths = (menu: any[]): string[] => {
  let franchiseActivePaths: string[] = [];
  const traverseMenu = (menuItems: any[]) => {
    for (const item of menuItems) {
      if (item.access.includes(UserTypes.Franchise)) {
        if (item.activePaths) {
          franchiseActivePaths.push(...item.activePaths);
        }
        if (item.children && item.children.length > 0) {
          traverseMenu(item.children);
        }
      }
    }
  };
  traverseMenu(menu);
  return franchiseActivePaths;
};

export const getDealerRelatedActivePaths = (menu: any[]): string[] => {
  let dealerActivePaths: string[] = [];
  const traverseMenu = (menuItems: any[]) => {
    for (const item of menuItems) {
      if (item.access.includes(UserTypes.Dealer)) {
        if (item.activePaths) {
          dealerActivePaths.push(...item.activePaths);
        }
        if (item.children && item.children.length > 0) {
          traverseMenu(item.children);
        }
      }
    }
  };
  traverseMenu(menu);
  return dealerActivePaths;
};



export const checkIncomingDispatchValidOrNot = (incoming_dispatch: any) => {
  if (!incoming_dispatch) {
    return false;
  }
  const { courierId, id, receivedDate, receivedId } = incoming_dispatch;
  if (courierId !== null && courierId !== undefined) {
    return true;
  }

  if (receivedDate !== null && receivedDate !== undefined) {
    return true;
  }

  if (receivedId !== null && receivedId !== undefined) {
    return true;
  }

  return false;
}


export const checkOutgoingDispatchValidOrNot = (outgoing_dispatch: any) => {
  if (!outgoing_dispatch) {
    return false;
  }
  const { approximateValue, courierId, deliveredById, deliveredTo, deliveryDate, deliveryType, notes, packedBy, packedDate } = outgoing_dispatch;
  if (courierId !== null && courierId !== undefined) {
    return true;
  }
  if (deliveryDate !== null && deliveryDate !== undefined) {
    return true;
  }
  if (approximateValue !== null && approximateValue !== undefined) {
    return true;
  }
  if (deliveredTo !== null && deliveredTo !== undefined) {
    return true;
  }
  if (packedBy !== null && packedBy !== undefined) {
    return true;
  }
  if (packedDate !== null && packedDate !== undefined) {
    return true;
  }
  if (deliveredById !== null && deliveredById !== undefined) {
    return true;
  }
  if (deliveryType !== null && deliveryType !== undefined) {
    return true;
  }
  if (notes !== null && notes !== undefined) {
    return true;
  }

  return false;
}


export const getLocationLatLng = (setLatitude: any, setLongitude: any) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.error('Error getting the location:', error);
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
}



export const jobLatModifiedResponse = (times: any, isTime: boolean = true) => {
  if (times) {
    const momentObj = moment(times, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    let formattedDate: Date = new Date(times) as Date
    if (formattedDate.getHours() === 0 && formattedDate.getMinutes() === 0) {
      formattedDate.setHours(0);
      formattedDate.setMinutes(0);
    }
    const options: any = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: false
    };
    let dateString = formattedDate.toLocaleString('en-US', options);
    if (dateString.endsWith("24:00")) {
      dateString = dateString.slice(0, -5) + "00:00";
    }
    if (!isTime) {
      const dateOnly = dateString.replace(/ at \d+:\d+/, '');
      return dateOnly
    }

    return dateString.replace(/at/, '');
    // console.log("dataeString", dateString)
    // const data = dateString.split(" at ")
    // const q = data[1].split(":")
    // return `${q[0]}:${q[1]}`;
  } else {
    return ""
  }
}



export function convertTagToText(htmlString : any) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  return tempDiv.textContent || tempDiv.innerText || '';
}


//  Important points
//  1. Format the date for view page date generated from the server use  ( formatDateTimeFormServerView() )
//  2. Format the date for view page date generated from the form use  ( formatDateTimeForView() )
//  3. 