import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Crypto Redux States
import { InvoiceTypes } from "./actionTypes";
import { LoadFullInvoiceError, LoadFullInvoiceSuccess, LoadFullJobSheetError, LoadFullJobSheetSuccess, addInvoiceDetailError, addInvoiceDetailSucess, downloadPdfInvoicePageError, downloadPdfInvoicePageSuccess, generatePaymentLinkError, generatePaymentLinkSuccess, getCustomerDropForInvoiceError, getCustomerDropForInvoiceSuccess, getDealerDropForInvoiceError, getDealerDropForInvoiceSuccess, getInvoiceOnchnageError, getInvoiceOnchnageSuccess, getInvoicePageInvoiceListError, getInvoicePageInvoiceListSuccess, getInvoicePageJobSheetIdRelatedDetailsError, getInvoicePageJobSheetIdRelatedDetailsSuccess, getInvoicePageJoblistListError, getInvoicePageJoblistListSuccess, getInvoiceTypeDropdownListError, getInvoiceTypeDropdownListSuccess, getInvoicesByPartIdError, getInvoicesByPartIdSuccess, getInvoicesIdError, getInvoicesIdSuccess, getInvoicesListError, getInvoicesListSuccess, getJobEstimateDtoDetailsError, getJobEstimateDtoDetailsSuccess, getPartsListDropForInvoiceError, getPartsListDropForInvoiceSuccess, getTaxesDetailsForInvoiceError, getTaxesDetailsForInvoiceSuccess, getUndoPartsSkuListError, getUndoPartsSkuListSuccess, getUpdatedByNameError, getUpdatedByNameSuccess, gettaxesListDropForInvoiceError, gettaxesListDropForInvoiceSuccess, invoiceCreateError, invoiceCreateSuccess, invoiceDeleteError, invoiceDeleteSucess, invoiceUpdateError, invoiceUpdateSuccess, jobSheetReturnInvoiceDetailsError, jobSheetReturnInvoiceDetailsSuccess, receivedPaymentDetailsError, receivedPaymentDetailsSuccess, sendEmailToDealerInvoiceError, sendEmailToDealerInvoiceSuccess, sendWhatsappInvoiceError, sendWhatsappInvoiceSuccess, taxDetailsForInvoicePageError, taxDetailsForInvoicePageSuccess, } from "./actions";
import { API } from "src/util/api";
import { TaxTypesInvoiceresponse } from "src/models/pages/invoiceModel";

function* fetchInvoices(action: any) {
  try {
    const response: Promise<any> = yield call(API.getInvoicesList, { ...action.lazyState });
    yield put(getInvoicesListSuccess(response));
  } catch (error) {
    yield put(getInvoicesListError(error));
  }
}

function* fetchInvoiceDetail(action: any) {
  try {
    const response: Promise<any> = yield call(API.getByIdInvoiceApi, action.payload);
    yield put(getInvoicesIdSuccess(response));
  } catch (error) {
    yield put(getInvoicesIdError(error));
  }
}

function* fetchInvoiceByPartSkuDetail(action: any) {
  try {
    const response: Promise<any> = yield call(API.getByIdInvoiceByPartsSkuIdApi, action.payload);
    yield put(getInvoicesByPartIdSuccess(response));
  } catch (error) {
    yield put(getInvoicesByPartIdError(error));
  }
}

function* addInvoiceDetail(action: any) {
  try {
    const response: Promise<any> = yield call(API.createInvoiceListApi, action.payload);
    yield put(addInvoiceDetailSucess(response));
  } catch (error) {
    yield put(addInvoiceDetailError(error));
  }
}

function* onDeleteInvoice({ payload: data }: any) {
  try {
    // const response: Promise<any> = yield call(deleteInvoice, data);
    // yield put(invoiceDeleteSucess(response));
  } catch (error) {
    yield put(invoiceDeleteError(error));
  }
}

function* onUpdateinvoice(action: any) {
  try {
    const response: Promise<any> = yield call(API.updateInvoiceApi, action.payload, action.id);
    yield put(invoiceUpdateSuccess(response));
  } catch (error) {
    yield put(invoiceUpdateError(error));
  }
}


function* createInvoice(action: any) {
  try {
    const response: Promise<any> = yield call(API.createInvoiceApi, action.payload);
    yield put(invoiceCreateSuccess(response));
  } catch (error) {
    yield put(invoiceCreateError(error));
  }
}

function* getCustomerDrop() {
  try {
    // const response: Promise<any> = yield call(API.getCustomerDropForInvoice);
    const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getCustomerDropForInvoiceSuccess(response));
  } catch (error) {
    yield put(getCustomerDropForInvoiceError(error));
  }
}

function* getDealerDrop() {
  try {
    // const response: Promise<any> = yield call(API.getDealerDropForInvoice);
    const response: Promise<any> = yield call(API.getDealerDrpFromIdentity);
    yield put(getDealerDropForInvoiceSuccess(response));
  } catch (error) {
    yield put(getDealerDropForInvoiceError(error));
  }
}

function* getPartsSKu(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPartsSkuListForInvoice, action.payload.partsSkuNumber);
    yield put(getPartsListDropForInvoiceSuccess(response, action.payload.index, action.payload.setFieldValue, action.payload.passpartSkuDetails, action.payload.passtaxesDetails, action.payload.passdeleteState, action.payload.reducerState, action.payload.push));

  } catch (error) {
    yield put(getPartsListDropForInvoiceError(error));
  }
}

function* getTaxesList() {
  try {
    const response: Promise<any> = yield call(API.getTaxListForInvoice);
    yield put(gettaxesListDropForInvoiceSuccess(response));
  } catch (error) {
    yield put(gettaxesListDropForInvoiceError(error));
  }
}


function* getTaxesDetails(action: any) {
  try {
    const response: Promise<any> = yield call(API.getTaxListForInvoice, action.payload);
    yield put(getTaxesDetailsForInvoiceSuccess(response));
  } catch (error) {
    yield put(getTaxesDetailsForInvoiceError(error));
  }
}

// ===== invoices page taxes list by id =====
function* getTaxDetailsForInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getTaxByIdForJobAPI, action.payload.id);
    yield put(taxDetailsForInvoicePageSuccess(response, action.payload.index));
  } catch (error) {
    yield put(taxDetailsForInvoicePageError(error));
  }
}


// -=========== getInvoiceTypesdropdown ======== /
function* getInvoiceTypesInvoiceAsync() {
  try {
    const response: Promise<any> = yield call(API.getInvoicePageInvoiceType);
    yield put(getInvoicePageInvoiceListSuccess(response));
  } catch (error) {
    yield put(getInvoicePageInvoiceListError(error));
  }
}

//========== getInvoiceTypesJoblistdropdown ======== //
function* getInvoiceTypesJoblistAsync() {
  try {
    const response: Promise<any> = yield call(API.getInvoicePageJoblistType);
    yield put(getInvoicePageJoblistListSuccess(response));
  } catch (error) {
    yield put(getInvoicePageJoblistListError(error));
  }
}

// ==== getInvoiceTypesJobIdrelatedDetails === /

function* getInvoiceTypesJobIdrelatedDetailsAsync(action: any) {
  try {
    let response: Promise<any> = yield call(API.getJobByIdAPI, action.payload);
    yield put(getInvoicePageJobSheetIdRelatedDetailsSuccess(response));
  } catch (error) {
    yield put(getInvoicePageJobSheetIdRelatedDetailsError(error));
  }
}


function* deleteInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getInvoiceDeleteAPI, { id: action.payload.id }
    );
    yield put(invoiceDeleteSucess(response));
  } catch (error) {
    yield put(invoiceDeleteError(error));
  }
}

function* getInvoiceOnchangesAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getTaxByIdForJobAPI, action.payload.id);
    yield put(getInvoiceOnchnageSuccess(response, action.payload.index, action.payload.setFieldValue, action.payload.value,action.payload.formValues));
  } catch (error) {
    yield put(getInvoiceOnchnageError(error));
  }
}

function* receivedPaymentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.receivedPaymentForInvoiceAPI, action.payload);
    yield put(receivedPaymentDetailsSuccess(response));
  } catch (error) {
    yield put(receivedPaymentDetailsError(error));
  }
}

function* receivedjobsheetInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.checkInvoiceDetailsFromJobIdApi, action.payload);
    yield put(jobSheetReturnInvoiceDetailsSuccess(response));
  } catch (error) {
    yield put(jobSheetReturnInvoiceDetailsError(error));
  }
}

function* downloadInvoicePdfAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.downloadPdfInvoicePage, action.payload);
    yield put(downloadPdfInvoicePageSuccess(response));
  } catch (error) {
    yield put(downloadPdfInvoicePageError(error));
  }
}

function* sendEmailInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.sendEmailInvoicePage, action.payload);
    yield put(sendEmailToDealerInvoiceSuccess({ response, label: action.label }));
  } catch (error) {
    yield put(sendEmailToDealerInvoiceError(error));
  }
}

function* sendWhatsappInvoiceAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.sendWhatsappInvoicePage, action.payload);
    yield put(sendWhatsappInvoiceSuccess({response,label:action.label}));
  } catch (error) {
    yield put(sendWhatsappInvoiceError(error));
  }
}


// get taxes types

function* getTaxesTypesDropdownAsync() {
  try {
    const response: Promise<TaxTypesInvoiceresponse> = yield call(API.getTaxesDropDownListApi);
    yield put(getInvoiceTypeDropdownListSuccess(response));
  } catch (error) {
    yield put(getInvoiceTypeDropdownListError(error));
  }
}


//get undoPartskuList Async

function* undoPartskuListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPartsSkuListForInvoice, action.payload.partNumber);
    yield put(getUndoPartsSkuListSuccess(response, action.payload.setFieldValue, action.payload.index, action.payload.formvalues, action.payload.id));
  } catch (error) {
    yield put(getUndoPartsSkuListError(error));
  }
}

function* getUpdateByNameAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPrepareyIdData, action.payload.id);
    yield put(getUpdatedByNameSuccess(response, action.payload.id));
  } catch (error) {
    yield put(getUpdatedByNameError(error));
  }
}


function* getJobEstimateDtoDetailsAsync(action: any) {
  try {
    let response: Promise<any> = yield call(API.getJobByIdAPI, action.payload);
    yield put(getJobEstimateDtoDetailsSuccess(response));
  } catch (error) {
    yield put(getJobEstimateDtoDetailsError(error));
  }
}

function* generatePaymentLinkAsync(action: any) {
  try {
    let response: Promise<any> = yield call(API.getPaymentLink, action.payload);
    yield put(generatePaymentLinkSuccess({ response: response, isAdmin: action.isAdmin }));
  } catch (error) {
    yield put(generatePaymentLinkError(error));
  }
}


function* getFulljobsheetLoadAsync() {
  try {
    const response: Promise<any> = yield call(API.getJobsheetForFilter);
    yield put(LoadFullJobSheetSuccess(response));
  } catch (error) {
    yield put(LoadFullJobSheetError(error));
  }
}

function* getAllTypeOfInvoiceAsync() {
  try {
    const response: Promise<any> = yield call(API.getAlltypeOfInvoice);
    yield put(LoadFullInvoiceSuccess(response));
  } catch (error) {
    yield put(LoadFullInvoiceError(error));
  }
}

function* invoiceSaga() {
  yield takeEvery(InvoiceTypes.GET_INVOICES_LOADING, fetchInvoices);
  yield takeEvery(InvoiceTypes.GET_INVOICE_DETAIL_LOADING, fetchInvoiceDetail);
  yield takeEvery(InvoiceTypes.GET_INVOICE_DETAIL_BY_PART_LOADING, fetchInvoiceByPartSkuDetail);
  yield takeEvery(InvoiceTypes.ADD_INVOICE_DETAIL_LOADING, addInvoiceDetail);
  yield takeEvery(InvoiceTypes.DELETE_INVOICE_DETAIL_LOADING, onDeleteInvoice);
  yield takeEvery(InvoiceTypes.UPDATE_INVOICE_DETAIL_LOADING, onUpdateinvoice);
  yield takeEvery(InvoiceTypes.POST_INVOICE_DETAIL_LOADING, createInvoice);
  yield takeEvery(InvoiceTypes.GET_CUSTOMER_DROP_INVOICE_LOADING, getCustomerDrop);
  yield takeEvery(InvoiceTypes.GET_DEALER_DROP_INVOICE_LOADING, getDealerDrop);
  yield takeEvery(InvoiceTypes.GET_PARTS_SKU_LIST_LOADING, getPartsSKu);
  yield takeEvery(InvoiceTypes.GET_TAXES_LIST_FOR_INVOICE_LOADING, getTaxesList);
  yield takeEvery(InvoiceTypes.GET_TAXES_DEATILS_FOR_INVOICE_LOADING, getTaxesDetails);
  yield takeEvery(InvoiceTypes.GET_INVOICEPAGE_TAXESBYID_DETAILS_LOADING, getTaxDetailsForInvoiceAsync);
  yield takeLatest(InvoiceTypes.GET_INVOICEPAGE_INVOICETYPE_LOADING, getInvoiceTypesInvoiceAsync);
  yield takeLatest(InvoiceTypes.GET_INVOICEPAGE_JOBLIST_DROPDOWN_LOADING, getInvoiceTypesJoblistAsync);
  yield takeLatest(InvoiceTypes.GET_INVOICEPAGE_JOBSHEET_ID_RELATED_LOADING, getInvoiceTypesJobIdrelatedDetailsAsync);
  yield takeLatest(InvoiceTypes.DELETE_INVOICE_DETAIL_LOADING, deleteInvoiceAsync);
  yield takeLatest(InvoiceTypes.GET_INVOICE_DETAILS_ONCHANGE_LOADING, getInvoiceOnchangesAsync);
  yield takeLatest(InvoiceTypes.RECEIVED_PAYMENT_LOADING, receivedPaymentAsync);
  yield takeLatest(InvoiceTypes.GET_JOBRETURN_INVOICE_DETAILS_LOADING, receivedjobsheetInvoiceAsync);
  yield takeLatest(InvoiceTypes.INVOICE_PAGE_DOWNLOAD_PDF_LOADING, downloadInvoicePdfAsync);
  yield takeLatest(InvoiceTypes.INVOICE_PAGE_SEND_EMAIL_LOADING, sendEmailInvoiceAsync);
  yield takeLatest(InvoiceTypes.INVOICE_PAGE_SEND_WHATSAPP_LOADING, sendWhatsappInvoiceAsync);
  yield takeLatest(InvoiceTypes.GET_TAXES_TYPES_INVOICE_PAGE_LOADING, getTaxesTypesDropdownAsync);
  yield takeLatest(InvoiceTypes.UNDO_PARTSKU_LIST_LOADING, undoPartskuListAsync);
  yield takeEvery(InvoiceTypes.GET_UPDATED_BY_NAME_LOADING, getUpdateByNameAsync);
  yield takeEvery(InvoiceTypes.GET_JOB_ESTIMATE_DTO_BY_JOB_ID_LOADING, getJobEstimateDtoDetailsAsync);
  yield takeEvery(InvoiceTypes.GENERATE_PAYMENT_LINK, generatePaymentLinkAsync);
  yield takeEvery(InvoiceTypes.LOAD_FULL_JOB_SHEET_LOADING, getFulljobsheetLoadAsync);
  yield takeEvery(InvoiceTypes.LOAD_ALL_TYPE_INVOICE_LOADING, getAllTypeOfInvoiceAsync);
}

export default invoiceSaga







