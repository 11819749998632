
import { EmployeePostModel, UserModel } from "src/models/pages/userModel";
import { UserTypes } from "./actionTypes";


//get user by id
export const GetMeluserDetailsLoading = (userId: any) => {
  return {
    type: UserTypes.GET_USERLOADING,
    payload: userId,
  };
};

export const GetMeluserDetailsSuccess = (userDetails: any) => {
  return {
    type: UserTypes.GET_USERSUCCESS,
    payload: userDetails,
  };
};

export const GetMeluserDetailsError = (error: any) => {
  return {
    type: UserTypes.GET_USERERROR,
    payload: error,
  };
};

//reset user
export const ReserEmployeeDetails = () => {
  return {
    type: UserTypes.RESET_USER,
  }
}

//create user
export const CreateMelUserLoading = (userDetails: EmployeePostModel) => {
  return {
    type: UserTypes.CREATE_USER_LOADING,
    payload: userDetails
  }
}

export const CreateMelUserSuccess = (resp:any) => {
  return {
    type: UserTypes.CREATE_USER_SUCCESS,
    payload:resp
  }
}

export const CreateMelUserError = (error: any) => {
  return {
    type: UserTypes.CREATE_USER_ERROR,
    payload: error
  }
}

//update user
export const UpdateMelUserLoading = (employeeDetails: EmployeePostModel, id: string) => {
  return {
    type: UserTypes.UPDATE_USER_LOADING,
    payload: { employeeDetails, id },

  }
}

export const UpdateMelUserSuccess = () => {
  return {
    type: UserTypes.UPDATE_USER_SUCCESS
  }
}

export const UpdateMelUserError = (error: any) => {
  return {
    type: UserTypes.UPDATE_USER_ERROR,
    payload: error
  }
}

// For Grid

export const getUsersList = (lazyState: any) => {
  return {
    type: UserTypes.GET_USERS_LIST,
    lazyState,
  };
};



export const getUsersListSuccess = (userList: any) => {
  return {
    type: UserTypes.GET_USERS_LIST_SUCCESS,
    payload: userList,
  };
};

export const getUsersListError = (error: any) => {
  return {
    type: UserTypes.GET_USERS_LIST_FAIL,
    payload: error,
  };
};


export const deleteUserFromList = (payload: any) => {
  return {
    type: UserTypes.DELETE_USER,
    payload: payload,
  }
}

export const deleteUserFromListSuccess = (response: any, undo: boolean) => {
  return {
    type: UserTypes.DELETE_USER_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteUserFromListError = (error: any) => {
  return {
    type: UserTypes.DELETE_USER_FAIL,
    payload: error,
  }
}

export const clearUserData = () => {
  return {
    type: UserTypes.CLEAR_USER_DATA,

  }
}



//  upload the file
export const uploadEmployeeFileLoading = (file: any, id: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_UPLOAD_LOADING,
    payload: file,
    id: id
  }
}


export const uploadEmployeeFileSuccess = (customer: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_UPLOAD_SUCCESS,
  }
}

export const uploadEmployeeFileError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_UPLOAD_ERROR,
    payload: error
  }
}



// get Employee image

export const getEmployeeImageLoading = (customerId: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_FETCH_LOADING,
    payload: customerId,
  };
}

export const getEmployeeImageSuccess = (customerDetails: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_FETCH_SUCCESS,
    payload: customerDetails,
  }
}

export const getEmployeeImageError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_FETCH_ERROR,
    payload: error
  }
}


// delete customer image

export const deleteEmployeeImageLoading = (customerId: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_DELETE_LOADING,
    payload: customerId,
  };
}

export const deleteEmployeeImageSuccess = (customerDetails: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_DELETE_SUCCESS,
    payload: customerDetails,
  }
}

export const deleteEmployeeImageError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_FILE_DELETE_ERROR,
    payload: error
  }
}

export const ClearEmployeeImage = () => {
  

  return {
    type: UserTypes.EMPLOYEE_FILE_CLEAR,
  }
}

//employee document download

export const employeeDocumentUploadLoading=(files : any ,id :any,fileType : any)=>{
  return {
    type : UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_LOADING,
    payload : files,
    id : id,
    filetype : fileType
  }
}

export const employeeDocumentUploadSuccess = (customer: any) => {
  return {
    type: UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_SUCCESS
  }
}

export const employeeDocumentUploadError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_DOCUMENT_UPLOAD_ERROR,
    payload: error
  }
}

//get uploaded document list 

export const employeeDocumentGetLoading = (customerId: any) => {
  return {
    type: UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_LOADING,
    payload: customerId,
  };
}

export const employeeDocumentGetSucces = (customerDetails: any) => {
  return {
    type: UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_SUCCESS,
    payload: customerDetails,
  }
}

export const employeeDocumentGetError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_GET_UPLOADED_DOCUMENTED_ERROR,
    payload: error
  }
}


//delete uploaded document


export const deleteEmployeeDocumentLoading = (customerId: any) => {
  return {
    type: UserTypes.EMPLOYEE_DELETE_DOCUMENT_LOADING,
    payload: customerId,
  };
}

export const deleteEmployeeDocumentSuccess = (customerDetails: any) => {
  return {
    type: UserTypes.EMPLOYEE_DELETE_DOCUMENT_SUCCESS,
    payload: customerDetails,
  }
}

export const deleteEmployeeDocumentError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_DELETE_DOCUMENT_ERROR,
    payload: error
  }
}

//generate linnk

export const generateEmployeeDocumentLinkLoading = (customerId: any) => {
  return {
    type: UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_LOADING,
    payload: customerId,
  };
}
export const generateEmployeeDocumentLinkSuccess = (customerId: any) => {
  return {
    type: UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_SUCCESS,
    payload: customerId,
  };
}
export const generateEmployeeDocumentLinkError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_GENERATE_DOCUMENT_LINK_ERROR,
    payload: error,
  };
}

//generate link reset

export const resetGenerateDoucumentLink=()=>{
  return {
    type : UserTypes.EMPLOYEE_DOCUMENTED_LINK_RESET
  }
}

export const setEmployeeMobileNumberLoading = (number:any,id: any) => {
  return {
    type: UserTypes.EMPLOYEE_SET_PHONE_LOADING,
    payload: {id:id,number:number},
  };
}
export const setEmployeeMobileNumberSuccess = (resp: any) => {
  return {
    type: UserTypes.EMPLOYEE_SET_PHONE_SUCCESS,
    payload: resp,
  };
}
export const setEmployeeMobileNumberError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_SET_PHONE_ERROR,
    payload: error,
  };
}

export const setEmployeeEmailIdLoading = (email:any,id: any) => {
  return {
    type: UserTypes.EMPLOYEE_SET_EMAIL_LOADING,
    payload: {id:id,email:email},
  };
}
export const setEmployeeEmailIdSuccess = (resp: any) => {
  return {
    type: UserTypes.EMPLOYEE_SET_EMAIL_SUCCESS,
    payload: resp,
  };
}
export const setEmployeeEmailIdError = (error: any) => {
  return {
    type: UserTypes.EMPLOYEE_SET_EMAIL_ERROR,
    payload: error,
  };
}

export const sendEmployeeOtpLoading = (otp:any,id: any,phoneNUmber:any) => {
  return {
    type: UserTypes.SEND_OTP_EMPLOYEE,
    payload: {id:id,code:otp,phoneNUmber:phoneNUmber},
  };
}
export const sendEmployeeOtpSuccess = (resp: any) => {
  return {
    type: UserTypes.SEND_OTP_EMPLOYEE_SUCCESS,
    payload: resp,
  };
}
export const sendEmployeeOtpError = (error: any) => {
  return {
    type: UserTypes.SEND_OTP_EMPLOYEE_ERROR,
    payload: error,
  };
}
export const resendOtpEmployee = (id: any,number:any) => {
  return {
    type: UserTypes.RESEND_EMPLOYEE_OTP,
    payload: id,
    number:number
  };
}
export const resendOtpEmployeeSuccess = (resp: any) => {
  return {
    type: UserTypes.RESEND_EMPLOYEE_OTP_SUCCESS,
    payload: resp,
  };
}
export const resendOtpEmployeeError = (resp: any) => {
  return {
    type: UserTypes.RESEND_EMPLOYEE_OTP_ERROR,
    payload: resp,
  };
}

export const resetOtpEmployeeMessage = () => {
  return {
    type: UserTypes.RESET_EMPLOYEE_OTP_MESSAGE,
  };
}

export const employeeLatLagLoading = (selectedArea: string) => {
  return {
    type: UserTypes.GET_EMPLOYEE_LOCATION_LOADING,
    payload: selectedArea
  }
}
export const employeeLatLagSuccess = (latLag: any, selectedArea: any) => {
  return {
    type: UserTypes.GET_EMPLOYEE_LOCATION_SUCCESS,
    payload: { latLag, selectedArea }
  }
}
export const employeeLatLagError = (error: any) => {
  return {
    type: UserTypes.GET_EMPLOYEE_LOCATION_ERROR,
    payload: error
  }
}