import { rewardValueTypes } from './actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from 'src/util/api';
import { createRewardValueError, createRewardValueSuccess, getRewardValueError, getRewardValueSuccess } from './action';

function* getRewardValueAsync() {
    try {
        const response: Promise<any> = yield call(API.getRewardValueAPI)
        yield put(getRewardValueSuccess(response));
    } catch (error) {
        yield put(getRewardValueError(error));
    }
}

function* createRewardValueAsync(action: any) {
    try {
        const response: Promise<any> = yield call(API.createRewardValueAPI, action.payload )
        yield put(createRewardValueSuccess(response));   
    } catch (error) {
        yield put(createRewardValueError(error));
    }
}


function* rewardValueSaga() {
    yield takeLatest(rewardValueTypes.GET_REWARD_VALUE_LOADING, getRewardValueAsync);
    yield takeLatest(rewardValueTypes.CREATE_REWARD_VALUE_LOADING, createRewardValueAsync);
}

export default rewardValueSaga ;