import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { TabPanel, TabView } from 'primereact/tabview'
import FeatherIcon from "feather-icons-react";
import { getBulkSmsById } from 'src/store/BulkSms/action'

const ViewBulkSms = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { bulkSmsDetails,error, message , loading } = useSelector((state: RootReducerState) => state.BulkSmsReducer);
  useEffect(() => {
    dispatch(getBulkSmsById(id));
  }, []);


  const BulkSmsDetails = [
    
    {
      name: "User Type",
      value: bulkSmsDetails?.toUserTypes ? <div className='d-flex flex-wrap gap-1'>{bulkSmsDetails?.toUserTypes.map((item:any,index:number)=><b key={index}>{`${item}${bulkSmsDetails.toUserTypes.length < 2 ? "" : index+1 == (bulkSmsDetails.toUserTypes.length) ? "." : ","} `} </b>)}</div> : ""
    },
    {
      name: "Title",
      value: bulkSmsDetails?.title ? bulkSmsDetails?.title : ""
    },
    {
      name: "Total Count",
      value: bulkSmsDetails?.totalCount ? bulkSmsDetails?.totalCount : ""
    },
    {
      name: "Sent Count",
      value: bulkSmsDetails?.sentCount ? bulkSmsDetails?.sentCount : ""
    },
    {
      name: "Pending Count",
      value: bulkSmsDetails?.pendingMobileNumber ? bulkSmsDetails?.pendingMobileNumber.length : 0
    },
    {
      name: "Failure Count",
      value: bulkSmsDetails?.failureCount ? bulkSmsDetails?.failureCount  : 0
    },
    {
      name: "Status",
      value: bulkSmsDetails?.status ? bulkSmsDetails?.status  : ""
    },
  ]

  const AllSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="mail" className="mr-1 view-bulk-mail-icon" />
            <span className="font-bold white-space-nowrap">All Mobile No's</span>
        </div>
    );
};
  const PendingSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="clock" className="mr-1 view-bulk-mail-icon text-warning" />
            <span className="font-bold white-space-nowrap">Pending Mobile No's</span>
        </div>
    );
};
  const SentSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="send" className="mr-1 view-bulk-mail-icon text-success" />
            <span className="font-bold white-space-nowrap d-none-sm">Sent Mobile No's</span>
        </div>
    );
};
  const FailureSmsHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="thumbs-down" className="mr-1 view-bulk-mail-icon text-danger" />
            <span className="font-bold white-space-nowrap">Failure Mobile No's</span>
        </div>
    );
};
  return (
    <React.Fragment>
      <div className="pagecontent" id='pagecontent'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Bulk Sms"
            titlePath={ROUTER.BULK_SMS}
            breadcrumbItem="Bulk Sms Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
          />
<Row>
    <div className="view">
            <div className="general-details">
              <b>Bulk Sms Details</b>
            </div>
            <div className="subview">
            <div className="parentCls">
            {
              BulkSmsDetails?.map((data, index) => {
                return (
                  <div key={index} className={` pt-2 childCls`}>
                    <div>{data.name}</div>
                    <div>{data.value?data.value:<div className="none-text">-</div>}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        </div>
    </Row>
    <Row>
        <div className="view">
            <div className="general-details">
              <b>Sms Content</b>
            </div>
            <div className="subview">
                <div className="text-bold">{bulkSmsDetails?.smsContent}</div>
               </div>
        </div>
    </Row>
    <Row>
        <div className="view" id="bulkEmail">
            <TabView >
                <TabPanel header="All Mobile Numbers" headerTemplate={AllSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                        bulkSmsDetails?.allMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkSmsDetails.allMobileNumber.length < 2 ? "" : index+1 == (bulkSmsDetails.allMobileNumber.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                  <TabPanel header="Pending Mobile Numbers" headerTemplate={PendingSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       bulkSmsDetails?.pendingMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkSmsDetails.pendingMobileNumber.length < 2 ? "" : index+1 == (bulkSmsDetails.pendingMobileNumber.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                  <TabPanel header="Sent Mobile Numbers"  headerTemplate={SentSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       bulkSmsDetails?.sentMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkSmsDetails.sentMobileNumber.length < 2 ? "" : index+1 == (bulkSmsDetails.sentMobileNumber.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                <TabPanel header="Failure Mobile Numbers"  headerTemplate={FailureSmsHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       bulkSmsDetails?.failureMobileNumber?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkSmsDetails.failureMobileNumber.length < 2 ? "" : index+1 == (bulkSmsDetails.failureMobileNumber.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>

                  </TabView>
            </div>
           
    </Row>
 
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewBulkSms