

import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';


interface PaginationProps {
    page : any;
    canPreviousPage : any ;
    previousPage  : any;
    canNextPage : any ;
    nextPage: any;
    data : any;
    pageIndex : number ;
    gotoPage : (data : any)=>void ;
    pageOptions : any ;
}

const MelPagination : React.FC<PaginationProps>= ({canNextPage,canPreviousPage,nextPage,page,data,previousPage,pageIndex,gotoPage,pageOptions}) => {
  return (
    <Row className="align-items-center g-3 text-center text-sm-start">
    <div className="col-sm">
      <div>Showing<span className="fw-normal ms-1">{page?.length}</span> of <span className="fw-normal">{data?.length}</span> Results
      </div>
    </div>
    <div className="col-sm-auto">
      <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
        <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
          <Link to="#" className="page-link" onClick={previousPage}>Previous</Link>
        </li>
        {pageOptions.map((item: any, key: number) => (
          <React.Fragment key={key}>
            <li className="page-item">
              <Link to="#" className={pageIndex === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
            </li>
          </React.Fragment>
        ))}
        <li className={!canNextPage ? "page-item disabled" : "page-item"}>
          <Link to="#" className="page-link" onClick={nextPage}>Next</Link>
        </li>
      </ul>
    </div>
  </Row>
  )
}

export default MelPagination