import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { getFranchiseSubuserProfileLoading, getSubuserByIdLoading, resetSubuser } from 'src/store/actions'
import { RootReducerState } from 'src/store/reducers'
import "./subUserstyle.scss"
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { hasFranchiseRoleHandler } from 'src/helpers/common_helpers'
import { Image } from 'primereact/image'

const ViewSubUser = () => {
  const { id, sub_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { subUserDetails,  } = useSelector((state: RootReducerState) => state.subUserReducer.subUserList);
  const { loading,franchiseSubuserImage } = useSelector((state: RootReducerState) => state.subUserReducer);
  
  useEffect(() => {
    if (id) {
      dispatch(getSubuserByIdLoading(id));
      dispatch(getFranchiseSubuserProfileLoading(id))
    }
    return()=>{
      dispatch(resetSubuser())
    }
  }, [id])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const editSubuser = () => {
    navigate(`${ROUTER.SUB_USER_EDIT}/${id}/${sub_id}`)
  }

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }

  const FranchiseSubuserViewDetails = {
    personalDetails: [
      {
        label: "Name",
        value: subUserDetails?.['name']
      },
      {
        label: "User Name",
        value: subUserDetails?.['userName']
      },
      {
        label: "Email",
        value: subUserDetails?.['email']
      },
      {
        label: "Mobile",
        value: subUserDetails?.['phoneNumber']
      },
      {
        label: "Alternate Mobile Number",
        value: subUserDetails?.['extraProperties']?.['AlternateNumber']
      },
      {
        label: "PAN No",
        value: subUserDetails?.['extraProperties']?.['PANCardNo']
      },
      {
        label: "Address",
        value: subUserDetails?.['extraProperties']?.["Address"] ? subUserDetails?.['extraProperties']?.["Address"] : "-" 
      },
      {
        label: "Is Active",
        value: statusBodyTemplate(subUserDetails?.['isActive'])
      },
      // {
      //   label: "Lockout Enabled",
      //   value: statusBodyTemplate(subUserDetails?.['lockoutEnabled'])
      // },
      {
        label: "Is Verified",
        value: statusBodyTemplate(subUserDetails?.['extraProperties']?.["IsVerfied"])
      },
      {
        label: "Send Sms",
        value: statusBodyTemplate(subUserDetails?.['extraProperties']?.["SendSms"])
      },
      {
        label: "Send Email",
        value: statusBodyTemplate(subUserDetails?.['extraProperties']?.["SendEmail"])
      },
      {
        label: "Send Whatsapp",
        value: statusBodyTemplate(subUserDetails?.['extraProperties']?.["SendWhatsapp"])
      },
      {
        label: "Mobile Number Verified",
        value: statusBodyTemplate(subUserDetails?.['phoneNumberConfirmed'])
      },
    ],


  }
  const isFranchise =hasFranchiseRoleHandler()
  return (
    <React.Fragment>
      <div className='subUserPage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Subuser" titlePath={isFranchise ? `${ROUTER.SUB_USER_GRID}`:`${ROUTER.FRANCHISEE_EDIT}/${sub_id}`} breadcrumbItem='Subuser Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Subuser'
            onFirstButtonClick={editSubuser}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
              <Row>
              <Col lg={6}>
                    <div className="parentCls2">
                      {
                        FranchiseSubuserViewDetails.personalDetails.map((data, index) => {
                          return (
                            <div key={index} className={` pt-1 childCls`}>
                              <div>{data.label}</div>
                              <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                            </div>
                          )
                        })
                      }
                      {/* <hr /> */}

                    </div>
                  </Col>
                  <Col lg={6}>
                    <Row>
                     <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {franchiseSubuserImage ?
                          <Image src={franchiseSubuserImage} alt="Image" className='view-image' preview />
                          :
                          <div className='none-text'>-</div>
                        }
                        </Col>
                      </Row>
                      </Col>
                      </Row>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewSubUser