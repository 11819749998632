export enum VehicleBrandsTypes {

    // Grid
    GET_VEHICLE_BRANDS_LIST = "@@vehicleBrands/GET_VEHICLE_BRANDS_LIST",
    GET_VEHICLE_BRANDS_LIST_SUCCESS = "@@vehicleBrands/GET_VEHICLE_BRANDS_LIST_SUCCESS",
    GET_VEHICLE_BRANDS_LIST_FAIL = "@@vehicleBrands/GET_VEHICLE_BRANDS_LIST_FAIL",
  
    DELETE_VEHICLE_BRANDS = "@@vehicleBrands/DELETE_VEHICLE_BRANDS",
    DELETE_VEHICLE_BRANDS_SUCCESS = "@@vehicleBrands/DELETE_VEHICLE_BRANDS_SUCCESS",
    DELETE_VEHICLE_BRANDS_FAIL = "@@vehicleBrands/DELETE_VEHICLE_BRANDS_FAIL",

    // //reset
    RESET_VEHICLE_BRANDS = "@@vehicleBrands/RESET_VEHICLE_BRANDS",
    CLEAR_VEHICLE_BRANDS = "@@vehicleBrands/CLEAR_VEHICLE_BRANDS",
    // //create user
    // CREATE_USER_LOADING = "CREATE_USER_LOADING",
    // CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
    // CREATE_USER_ERROR = "CREATE_USER_ERROR",
  
    // //update user
    // UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
    // UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    // UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

    UPDATE_VEHICLE_BRANDS = "@@vehicleBrands/UPDATE_VEHICLE_BRANDS",
    UPDATE_VEHICLE_BRANDS_SUCCESS = "@@vehicleBrands/UPDATE_VEHICLE_BRANDS_SUCCESS",
    UPDATE_VEHICLE_BRANDS_FAIL = "@@vehicleBrands/UPDATE_VEHICLE_BRANDS_FAIL",

    // POST

    POST_VEHICLE_BRANDS = "@@vehicleBrands/POST_VEHICLE_BRANDS",
    POST_VEHICLE_BRANDS_SUCCESS = "@@vehicleBrands/POST_VEHICLE_BRANDS_SUCCESS",
    POST_VEHICLE_BRANDS_FAIL = "@@vehicleBrands/POST_VEHICLE_BRANDS_FAIL",

    // GET

    GET_VEHICLE_BRANDS_BY_ID = "@@vehicleBrands/GET_VEHICLE_BRANDS_BY_ID",
    GET_VEHICLE_BRANDS_BY_ID_SUCCESS = "@@vehicleBrands/GET_VEHICLE_BRANDS_BY_ID_SUCCESS",
    GET_VEHICLE_BRANDS_BY_ID_FAIL = "@@vehicleBrands/GET_VEHICLE_BRANDS_BY_ID_FAIL",

    GET_DEALER_NAMES_BY_ID = "@@vehicleBrands/GET_DEALER_NAMES_BY_ID",
    GET_DEALER_NAMES_BY_ID_SUCCESS = "@@vehicleBrands/GET_DEALER_NAMES_BY_ID_SUCCESS",
    GET_DEALER_NAMES_BY_ID_FAIL = "@@vehicleBrands/GET_DEALER_NAMES_BY_ID_FAIL",

//file uppload
VEHICLE_BRAND_FILE_UPLOAD_LOADING="@@audioBrands/VEHICLE_BRAND_FILE_UPLOAD_LOADING",
VEHICLE_BRAND_FILE_UPLOAD_SUCCESS="@@audioBrands/VEHICLE_BRAND_FILE_UPLOAD_SUCCESS",
VEHICLE_BRAND_FILE_UPLOAD_ERROR="@@audioBrands/VEHICLE_BRAND_FILE_UPLOAD_ERROR",

//file fetch
VEHICLE_BRAND_FILE_FETCH_LOADING="@@audioBrands/VEHICLE_BRAND_FILE_FETCH_LOADING",
VEHICLE_BRAND_FILE_FETCH_SUCCESS="@@audioBrands/VEHICLE_BRAND_FILE_FETCH_SUCCESS",
VEHICLE_BRAND_FILE_FETCH_ERROR="@@audioBrands/VEHICLE_BRAND_FILE_FETCH_ERROR",

//file delete
VEHICLE_BRAND_FILE_DELETE_LOADING="@@audioBrands/VEHICLE_BRAND_FILE_DELETE_LOADING",
VEHICLE_BRAND_FILE_DELETE_SUCCESS="@@audioBrands/VEHICLE_BRAND_FILE_DELETE_SUCCESS",
VEHICLE_BRAND_FILE_DELETE_ERROR="@@audioBrands/VEHICLE_BRAND_FILE_DELETE_ERROR",

VEHICLE_BRAND_FILE_CLEAR = "@@audioBrands/VEHICLE_BRAND_FILE_CLEAR",

  }
  