import { jobSheetReportsActions } from "./actionTypes";

//get jobsheet reports

export const getJobSheetReportsLoading = (lazyState: any) => {
  return {
    type: jobSheetReportsActions.GET_JOBSHEET_REPORTS_LOADING,
    lazyState
  }
}

export const getJobSheetReportsSuccess = (resp: any) => {
  return {
    type: jobSheetReportsActions.GET_JOBSHEET_REPORTS_SUCCESS,
    payload: resp
  }
}

export const getJobSheetReportsError = (error: any) => {
  return {
    type: jobSheetReportsActions.GET_JOBSHEET_REPORTS_ERROR,
    payload: error
  }
}