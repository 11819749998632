import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { hasAdminOrEmployeeRoleHandler, hasFranchiseRoleHandler, saveAsExcelFile } from "src/helpers/common_helpers";

import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import { RootReducerState } from "src/store/reducers";
import { deletePurchaseOrderFromList, resetPurchaseOrder } from "src/store/actions";
import { purchaseOrderList } from "src/models/pages/purchaseOrders";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { currency } from "src/constants/purchaseOrderFormFields";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import { incentiveRewardItems } from "src/models/pages/incentiverewardModel";
import { incentiveRewardListLoading } from "src/store/IncentiveReward/action";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";


const incentiveRewardListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue:incentiveRewardItems) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['userName'] = eachValue?.userName;
    data['userType'] = eachValue?.userType;
    data['userId'] = eachValue?.userId;
    data['month'] = eachValue?.month;
    data['rewardValue'] = eachValue?.rewardValue;
    data['totalRewardValue'] = eachValue?.totalRewardValue;
    data['totalRewardPoint'] = eachValue?.totalRewardPoint;
    data['totalTatRewardPoint'] = eachValue?.totalTatRewardPoint;
    data['totalProcessRewardPoint'] = eachValue?.totalProcessRewardPoint;
    data['isPaid'] = eachValue?.isPaid;
    return data;
  });
  return modifeidValue;
};

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";
const LOCKED = "Locked";
const UNLOCKED = "Unlocked";


export default function IncentiveRewardList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalCount } = useSelector((state: RootReducerState) => state.incentiveRewardReducer.incentiveRewardList);
  const { error, message,  paginatorCount,loading } = useSelector(
    (state: RootReducerState) => state.incentiveRewardReducer
  ) || [];
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const modifiedValues: any[] = incentiveRewardListConverson(values);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<any | null>(null);

  const filterDetails: DataTableFilterMeta = {
    userId: { value: "", matchMode: "contains" },
    userName: { value: "", matchMode: "contains" },
    userType: { value: "", matchMode: "contains" },
    month: { value: "", matchMode: "contains" },
    isPaid: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.PURCHASE_ORDER_CREATE);
  };

  const viewHandler = (rowData: any) => {

    navigate(`${ROUTER.INCENTIVE_REWARD_SYSTEM_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: any) => {

    navigate(`${ROUTER.PURCHASE_ORDER_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: any) => {

    setDeleteModal(true);
    setRowData(rowData);
  };


  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(incentiveRewardListLoading(lazyState));

    }
  }, [error, message]);

  const emailBodyTemplate = (rowData: any, field: string) => {

    let data = rowData[field] as string;
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const supplierBodyTemplate = (rowData: any, field: string) => {
    
    return (
      <Link to={`${ROUTER.SUPPLIER_VIEW}/${rowData['SupplierId']}`}>{rowData['supplierName']}</Link>
    );
  };
  
  const hidePopup = () => {
		setVisible(false);
    dispatch(resetPurchaseOrder())
	};

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const actionBodyTemplate = (rowData: purchaseOrderList, value: string) => {
    return (
      <>
            <ColumnAction
              displayData={rowData[value] as string}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
            />
      </>
    );
  };



  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );

  };




  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['startDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['endDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>)
  };

  // const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  //   return <InputNumber value={options.value} onChange={(e: any) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
  // };

  const currencyFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={currency}
        name="buyingCurrency"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
        filter
      />
    </>)
  };






  const toUserTypes = [
    { label: "Employee", value: "Employee" },
    { label: "Dealer", value: "Dealer" },
    { label: "Customer", value: "Customer" },
    { label: "Franchise", value: "Franchise" },
    { label: "Dealer Subuser", value: "DealerSubuser" },
    { label: "Franchise Subuser", value: "FranchiseSubuser" },
    { label: "Supplier", value: "Supplier" },
  ];
  
  const userTypeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={toUserTypes ||  []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };
let ShowMonth=["January","February","March","April","May","June","July","August","September","October","November","December"]
  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: "12" },
  ];

  const monthFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={months ||  []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };



  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Paid", value: "Paid" },
    { label: "Un Paid", value: "Un Paid" },
  ];

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }



  const columns: Columns[] = [
    // {
    //   field: "select",
    //   sortable: false,
    //   header: "",
    //   filter: false,
    //   showFilterMenu: false,
    //   selectionMode: "multiple",
    //   headerStyle: { width: "3rem", whiteSpace: "nowrap" },
    //   isDefault: true,
    //   isStatic: false,
    // },
    {
      field: "userName",
      sortable: hasAdminOrEmployeeRoleHandler() ? true : false,
      header: "User Name",
      filter: hasAdminOrEmployeeRoleHandler() ? true : false,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "userName"),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "userType",
      sortable: hasAdminOrEmployeeRoleHandler() ? true : false,
      header: "User Type",
      filter: hasAdminOrEmployeeRoleHandler() ? true : false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      filterElement:userTypeFilterTemplate
    },
    {
      field: "month",
      sortable: true,
      header: "Month",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>{return(ShowMonth[rowData.month-1])},
      isDefault: true,
      isStatic: false,
      filterElement: monthFilterTemplate
    },

    {
      field: "rewardValue",
      sortable: false,
      header: "Reward Value",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalRewardValue",
      sortable: false,
      header: "Total Reward Value",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "100px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalRewardPoint",
      sortable: false,
      header: "Total Reward Point",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalTatRewardPoint",
      sortable: false,
      header: "Total Tat Reward Point",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "totalProcessRewardPoint",
      sortable: false,
      header: "Total Process Reward Point",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "isPaid",
      sortable: true,
      header: "Paid",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'isPaid'),
      isDefault: true,
      isStatic: false,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  // useEffect(() => {
  //   setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  // }, [supplierList])

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      const { userName, userType, month, rewardValue, totalRewardValue, totalRewardPoint, totalTatRewardPoint, totalProcessRewardPoint, isPaid } = eachValue;
      let data: any = {};
      data['User Name'] = userName;
      data['User Type'] = userType;
      data['Month'] = month;
      data['Reward Value'] = rewardValue;
      data['Total Reward Value'] = totalRewardValue;
      data['Total Reward Point'] = totalRewardPoint;
      data['Total Tat Reward Point'] = totalTatRewardPoint;
      data['Total Process Reward Point'] = totalProcessRewardPoint;
      data['Paid'] = isPaid;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data?.id,
        userName: data?.userName,
        userType: data?.userType,
        userId: data?.userId,
        month: data?.month,
        rewardValue: data?.rewardValue,
        totalRewardValue: data?.totalRewardValue,
        totalRewardPoint: data?.totalRewardPoint,
        totalTatRewardPoint: data?.totalTatRewardPoint,
        totalProcessRewardPoint: data?.totalProcessRewardPoint,
        isPaid: data?.isPaid,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let authUser:any = localStorage.getItem('authUser')
    let uid=JSON.parse(authUser).uid
    let modifiedLazyState: any = { ...lazyState };
    modifiedLazyState.rows.value=totalCount>5000 ? 5000 : totalCount<1 ? 1 : totalCount
    modifiedLazyState.first.value=0
    if(hasFranchiseRoleHandler()){
      modifiedLazyState.filters.userId.value=uid
    }
    	
    try {
      setPdfLoading(true)
      const response = await API.getIncentiveRewardListAPI(modifiedLazyState);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue:incentiveRewardItems) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['userName'] = eachValue?.userName;
        data['userType'] = eachValue?.userType;
        data['userId'] = eachValue?.userId;
        data['month'] = eachValue?.month;
        data['rewardValue'] = eachValue?.rewardValue;
        data['totalRewardValue'] = eachValue?.totalRewardValue;
        data['totalRewardPoint'] = eachValue?.totalRewardPoint;
        data['totalTatRewardPoint'] = eachValue?.totalTatRewardPoint;
        data['totalProcessRewardPoint'] = eachValue?.totalProcessRewardPoint;
        data['isPaid'] = eachValue?.isPaid;
        return data;
      });
      // let resp=await incentiveRewardListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "IncentiveRewardPoint");
     }else{
      saveDataAsExcel(modifeidValue, "IncentiveRewardPoint");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "IncentiveRewardPoint");
    fetchData(false)

  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "IncentiveRewardPoint");
    fetchData(true)

  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let authUser:any = localStorage.getItem('authUser')
    let uid=JSON.parse(authUser).uid
    let modifiedLazyState: any = { ...lazyState };
    if(hasFranchiseRoleHandler()){
      modifiedLazyState.filters.userId.value=uid
    }
    dispatch(incentiveRewardListLoading(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedIncentiveRewards, setSelectedIncentiveRewards] = useState<any[]>([]);


  const onSelectionChange = (event: any) => {
    const value = event.value.filter((eachValue: any) => !eachValue.locked);
    setSelectedIncentiveRewards(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedIncentiveRewards([]);
      } else if (selectedStatus == ACTIVE) {
        let len = modifiedValues?.length;
        let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        if (len === filterLen) {
          setSelectAll(true);
          setSelectedIncentiveRewards(modifiedValues);
        } else {
          setSelectAll(false);
          setSelectedIncentiveRewards(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        }
      }
      else {
        setSelectAll(true);
        setSelectedIncentiveRewards(modifiedValues);
      }
    } else {
      setSelectAll(false);
      setSelectedIncentiveRewards([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    setLazyState(event);
  };



  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == "All") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsPaid: { value: "", matchMode: "contains" },

        },
      }));
    }
    else if (formValue == "Paid") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsPaid: { value: "true", matchMode: "contains" },

        },
      }));
    }
    else if (formValue == "Un Paid") {

      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsPaid: { value: "false", matchMode: "contains" },

        },
      }));
    } 
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsPaid: { value: "", matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedIncentiveRewards([]);
    callbackFunction(lazyState);
    return () => {
      // dispatch(resetIncentiveReward());
    };
  }, [lazyState]);


  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    dispatch(deletePurchaseOrderFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedIncentiveRewards([]);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedIncentiveRewards([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  // const header = <MultiSelect value={visibleColumns.map((eachColumns: Columns) => {
  //   let data: any = {};
  //   data['field'] = eachColumns['field'];
  //   data['header'] = eachColumns['header'];
  //   return data;
  // }

  // ).filter((eachColumn:Columns)=>{
  //   return eachColumn['field'] != 'select';
  // })}
  //   options={columns.map((eachColumns: Columns) => {
  //     let data: any = {};
  //     data['field'] = eachColumns['field'];
  //     data['header'] = eachColumns['header'];
  //     return data;
  //   }).filter((eachColumn:Columns)=>{
  //     return eachColumn['field'] != 'select';
  //   })} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState("All");
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedIncentiveRewards(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedIncentiveRewards([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus("All")
  }

  const header =
    <div className="table-header-container">
      <div>
        Incentive Reward List
      </div>
      <div className="table-header-container-item table-header-container-item-special">

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
      <div style={{ display: "flex" }}>
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label"
            placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>
        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button>
        </div>
        
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;


  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([ ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  // const isSelectable = (data: any) => {
  //   return !data?.locked;
  // }

  // const rowClassName = (data: any) => (isSelectable(data) ? '' : 'p-disabled');



  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedIncentiveRewards?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedIncentiveRewards([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedIncentiveRewards.length > 0) {
              for (let i = 0; i < selectedIncentiveRewards.length; i++) {
                handleDeleteUsers(selectedIncentiveRewards[i], selectedIncentiveRewards.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these purchase order?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedIncentiveRewards([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}

        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Incentive Reward Point {last} records of {totalRecords} in total"
          selection={selectedIncentiveRewards}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
          // rowClassName={rowClassName}
        >
        </DataTableWrapper>
      </div>
    </>
  );
}
