import { AudioBrandsTypes } from "./actionTypes";



// For Grid

export const getAudioBrandsList = (lazyState: any) => {
  return {
    type: AudioBrandsTypes.GET_AUDIO_BRANDS_LIST,
    lazyState,
  };
};

export const getAudioBrandsListSuccess = (audioBrand: any) => {
  return {
    type: AudioBrandsTypes.GET_AUDIO_BRANDS_LIST_SUCCESS,
    payload: audioBrand,
  };
};

export const getAudioBrandsListError = (error: any) => {
  return {
    type: AudioBrandsTypes.GET_AUDIO_BRANDS_LIST_FAIL,
    payload: error,
  };
};

//delete audio brand

export const deleteAudioBrandsFromList = (payload:any) => {
  return {
    type: AudioBrandsTypes.DELETE_AUDIO_BRANDS_PENDING,
    payload: payload,
  }
}

export const deleteAudioBrandsFromListSuccess = (response:any,undo:boolean) => {
  return {
    type: AudioBrandsTypes.DELETE_AUDIO_BRANDS_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteAudioBrandsFromListError = (error:any) => {
  return {
    type: AudioBrandsTypes.DELETE_AUDIO_BRANDS_FAIL,
    payload: error,
  }
}

//create audio brand

export const createAudioBrandPending=(audioBrands : any)=>{
   return {
     type : AudioBrandsTypes.CREATE_AUDIO_BRANDS_PENDING,
     payload : audioBrands
   }
}

export const createAudioBrandSuccess=(audiobrands : any)=>{
   return {
     type : AudioBrandsTypes.CREATE_AUDIO_BRANDS_SUCCESS,
     payload : audiobrands
   }
}

export const createAudioBrandError=(error : any)=>{
  return {
     type : AudioBrandsTypes.CREATE_AUDIO_BRANDS_ERROR,
     payload : error
  }
}


//update audio brand

export const updateAudioBrandPending=(audioBrand : any,id : any)=>{
  return {
    type : AudioBrandsTypes.UPDATE_AUDIO_BRANDS_PENDING,
    payload : audioBrand,
    id
  }
}

export const updateAudioBrandSuccess=(audioBrand :  any)=>{
  return {
     type : AudioBrandsTypes.UPDATE_AUDIO_BRANDS_SUCCESS,
     payload : audioBrand,
  }
}

export const updateAudioBrandError=(error : any)=>{
  return {
    type : AudioBrandsTypes.UPDATE_AUDIO_BRANDS_ERROR,
    payload : error
  }
}

//getById audioBrand

export const getByIdAudioBrandPending=(id : any)=>{
  return {
    type : AudioBrandsTypes.GET_BY_ID_AUDIO_BRANDS_PENDING,
    payload : id
  }
}

export const getByIdAudioBrandSuccess=(audioBrand : any)=>{
  return {
    type : AudioBrandsTypes.GET_BY_ID__AUDIO_BRANDS_SUCCESS,
    payload : audioBrand
  }
}

export const getByIdAudioBrandError=(error : any)=>{
  return {
    type : AudioBrandsTypes.GET_BY_ID__AUDIO_BRANDS_ERROR,
    payload : error
  }
}

//reset audioBrand

export const resetAudioBrandGlobalState=()=>{
  return {
    type : AudioBrandsTypes.RESET_AUDIO_BRAND
  }  
}


//getVehicle brand list

export const getVehicleBrandNameListLoading=()=>{
  return {
    type : AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_LOADING
  }  
}
export const getVehicleBrandNameListSuccess=(vehicleBrandList : any)=>{
  return {
    type : AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_SUCCESS,
    payload : vehicleBrandList
  }  
}
export const getVehicleBrandNameListError=(error : any)=>{
  return {
    type : AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_ERROR,
    payload : error
  }  
}

export const uploadAudioBrandFileLoading = (file :any,id : any )=>{
  return {
    type : AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_LOADING,
    payload : file,
    id: id
  }
}

export const uploadAudioBrandFileSuccess=(audiobrand : any)=>{
  return {
    type : AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_SUCCESS,
  }
}

export const uploadAudioBrandFileError=(error : any)=>{
  return {
    type : AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_ERROR,
    payload : error
  }
}
export const getAudioBrandImageLoading =(customerId : any)=>{
  return {
    type: AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_LOADING,
    payload: customerId,
  };
}

export const getAudioBrandImageSuccess =(audiobrandDetails : any)=>{
  return {
    type: AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_SUCCESS,
    payload: audiobrandDetails,
  }
}

export const getAudioBrandImageError =(error : any)=>{
  return {
    type : AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_ERROR,
    payload : error
  }
}


// delete audio brand image

export const deleteAudioBrandImageLoading = (audiobrandId : any)=>{
  return {
    type: AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_LOADING,
    payload: audiobrandId,
  };
}

export const deleteAudioBrandImageSuccess = (audioBrandDetails : any)=>{
  return {
    type: AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_SUCCESS,
    payload: audioBrandDetails,
  }
}

export const deleteAudioBrandImageError = (error : any)=>{
  return {
    type : AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_ERROR,
    payload : error
  }
}
export const ClearAudioBrandImage=()=>{
	return {
	  type : AudioBrandsTypes.AUDIO_BRAND_FILE_CLEAR,
	}
  }