import { purchaseOrderInitialState } from "src/models/pages/purchaseOrders";
import { PurchaseOrderTypes } from "./actionTypes";
import { messages } from "src/common/data";




const initialState: purchaseOrderInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  purchaseList: {
    values: [],
    totalRecords: 0
  },
  purchaseOrderDetails: null,
  supplierList: [],
  partList: [],
  purchaseOrderrelatedAudioModelDropdown : [],
  purchaseOrderIndex: -1
};



const PurchaseOrder = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST:
    case PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID:
    case PurchaseOrderTypes.DELETE_PURCHASE_ORDER:
    case PurchaseOrderTypes.UPDATE_PURCHASE_ORDER:
    case PurchaseOrderTypes.CREATE_PURCHASE_ORDER:
    case PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER:
    case PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS:
    case PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST:
    case PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_LOADING:
    case PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER:
    case PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_LOADING:

      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message: "",
        error: ""
      };


    case PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount : action.payload.data.items.length
      };
    case PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST_SUCCESSS:
      return {
        ...state,
        loading: false,
        partList: action.payload.data,
      };

    case PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS_SUCCESSS:
      return {
        ...state,
        loading: false,
        message: "",
        error: ""
      };

    case PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: '',
        purchaseOrderDetails: action.payload
      };


    case PurchaseOrderTypes.DELETE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message:  action.payload.undo ? "Purchase order restored successfully" : "Purchase order is deleted successfully"
      }

    case PurchaseOrderTypes.UPDATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Purchase order updated successfully"
      }

    case PurchaseOrderTypes.CREATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Purchase order created successfully"
      }

    case PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER_SUCCESSS:      
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        // partList: action.payload.data.partList,
        supplierList: action.payload.data,
      }

 

    case PurchaseOrderTypes.RESET_PURCHASE_ORDER:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
      }

    case PurchaseOrderTypes.CLEAR_PURCHASE_ORDER:
      return {
        error: "",
        loading: false,
        message: "",
        paginatorCount: 0,
        purchaseList: {
          values: [],
          totalRecords: 0
        },
        purchaseOrderDetails: null,
        supplierList: [],
        partList: [],
        purchaseOrderrelatedAudioModelDropdown : [],
        purchaseOrderIndex: -1
      }


    case PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_SUCCESS:
      debugger;
      return {
        ...state,
        loading: false,
        error : "",
        purchaseOrderrelatedAudioModelDropdown: action.payload.resp.data,
        purchaseOrderIndex: action.payload.index
      }  
    case PurchaseOrderTypes.RESET_PURCHASE_ORDER_INDEX:
      debugger;
      return {
        ...state,
        loading: false,
        error : "",
        purchaseOrderIndex: -1
      }  

    case PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_SUCCESSS:
      return {
        ...state,
        loading: false,
        error : "",
        message: "Successfully emailed the purchase order to the supplier!"
      }  

    case PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error : "",
        message: ""
      }  


    case PurchaseOrderTypes.GET_PURCHASE_ORDER_LIST_FAIL:
    case PurchaseOrderTypes.GET_PURCHASE_ORDER_BY_ID_FAIL:
    case PurchaseOrderTypes.DELETE_PURCHASE_ORDER_FAIL:
    case PurchaseOrderTypes.UPDATE_PURCHASE_ORDER_ERROR:
    case PurchaseOrderTypes.CREATE_PURCHASE_ORDER_ERROR:
    case PurchaseOrderTypes.GET_DROPDOWN_FOR_PURCHASE_ORDER_FAIL:
    case PurchaseOrderTypes.DELETE_PURCHASE_ORDER_DETAILS_FAIL:
    case PurchaseOrderTypes.GET_AUTOCOMPLETE_PARTS_LIST_FAIL:
    case PurchaseOrderTypes.GET_PURCHASEORDER_AUDIOMODEL_DROPDOWN_ERROR:
    case PurchaseOrderTypes.SENT_MAIL_TO_SUPPLIER_PURCHASE_ORDER_FAIL:
    case PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default PurchaseOrder;
