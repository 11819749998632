import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { formatDateMEL } from 'src/helpers/common_helpers'
import { getFeedbackIdDetailsLoading } from 'src/store/actions'
import { RootReducerState } from 'src/store/reducers'

const ViewFeedback = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { feedbackDetails } = useSelector((state: RootReducerState) => state.feedbackReducer)

  useEffect(() => {
    dispatch(getFeedbackIdDetailsLoading(id))
  }, [])

  const feedbackDetailsShow = [
    {
      name: "Job Sheet",
      value: feedbackDetails?.jobNumber ? feedbackDetails?.jobNumber : "-"
    },
    {
      name: "Commenter",
      value: feedbackDetails?.commenterName ? feedbackDetails?.commenterName : "-"
    },
    {
      name: "Vin Number",
      value: feedbackDetails?.vinNumber ? feedbackDetails?.vinNumber : "-"
    },
    {
      name: "Vehicle Model",
      value: feedbackDetails?.vehicleModelName ? feedbackDetails?.vehicleModelName : "-"
    },
    {
      name: "Audio Part Number",
      value: feedbackDetails?.audioPartNumber ? feedbackDetails?.audioPartNumber : "-"
    },
    {
      name: "Audio Serial Number",
      value: feedbackDetails?.audioSerialNumber ? feedbackDetails?.audioSerialNumber : "-"
    },
    {
      name: "Outgoing Audio Serial Number",
      value: feedbackDetails?.outgoingAudioSerialNumber ?feedbackDetails?.outgoingAudioSerialNumber : "-"
    },
    {
      name: "Adherence Of Tat Rating",
      value: feedbackDetails?.adherenceOfTATRating ? feedbackDetails?.adherenceOfTATRating : "-"
    },
    {
      name: "Adherence Of Tat Comments",
      value: feedbackDetails?.adherenceOfTATComments ? feedbackDetails?.adherenceOfTATComments : "-"
    },
    {
      name: "Ontime Solution Rating",
      value: feedbackDetails?.ontimeSolutionRating ? feedbackDetails?.ontimeSolutionRating : "-"
    },
    {
      name: "Ontime Solution Comments",
      value: feedbackDetails?.onTimeSolutionComments ? feedbackDetails?.onTimeSolutionComments : "-"
    },
    {
      name: "On Time Part Support Rating",
      value: feedbackDetails?.onTimePartSupportRating ? feedbackDetails?.onTimePartSupportRating : "-"
    },
    {
      name: "On Time Part Support Comments",
      value: feedbackDetails?.onTimePartSupportComments ? feedbackDetails?.onTimePartSupportComments : "-"
    },
    {
      name: "Engineer Technical Skill Rating",
      value: feedbackDetails?.engineerTechnicalSkillRating ? feedbackDetails?.engineerTechnicalSkillRating : "-"
    },
    {
      name: "Engineer Technical Skill Comments",
      value: feedbackDetails?.engineerTechnicalSkillComments ? feedbackDetails?.engineerTechnicalSkillComments : "-"
    },
    {
      name: "Engineer Behavior Rating",
      value: feedbackDetails?.engineerBehaviorRating ? feedbackDetails?.engineerBehaviorRating : "-"
    },
    {
      name: "Engineer Behavior Comments",
      value: feedbackDetails?.engineerBehaviorComments ? feedbackDetails?.engineerBehaviorComments : "-"
    },
    {
      name: "Marks",
      value: feedbackDetails?.marks ? feedbackDetails?.marks : "-"
    },
    {
      name: "Grade",
      value: feedbackDetails?.grade ? feedbackDetails?.grade : "-"
    },
    {
      name: "Created At",
      value: feedbackDetails?.creationTime ? formatDateMEL(feedbackDetails?.creationTime) : "-"
    },
  ]
  return (
    <React.Fragment>
      <div className="audiobrandPage" id='audiobrandPage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Feedback"
            titlePath={ROUTER.FEEDBACK_GRID}
            breadcrumbItem="Feedback Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={false}
            firstButtonLabel=''
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>Feedback Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    feedbackDetailsShow.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div >{data.name}</div>
                          <div>{data.value}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewFeedback