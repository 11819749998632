import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//For attendance
import { attendanceApplicableUsers } from "src/models/pages/attendanceModel";

// Redux
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../../assets/images/avatar.jpg";

//redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ROUTER } from "src/constants/routes";
import { updateAttendance } from "src/store/actions";
import { RootReducerState } from "src/store/reducers";
import { AUTH_LOGIN_URL } from "src/util/auth/authConfig";
import { OAuthService } from "src/util/auth/oauthService";
import { hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler, hasDealerRoleHandler, hasDealerSubuserRoleHandler, hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler, hasManufacturerRoleHandler } from "src/helpers/common_helpers";
import { getProfile, getProfilePictureLoading } from "src/store/profile/actions";
import { messaging } from "src/Notifications/firebase";
import { APPENV_URL } from "src/util/appUrlEnv";
import { deleteToken } from "firebase/messaging";
import { resetEmployeeProfile } from "src/store/EmployeeProfile/action";

const ProfileMenu = (props: any) => {
  const formValue = useSelector((state: RootReducerState) => state.attendanceReducer?.attendance);
  const { isLogout } = useSelector((state: RootReducerState) => state.attendanceReducer);
  const dispatch = useDispatch();
  const { profile, profilePicture } = useSelector(
    (state: RootReducerState) => state.profilesReducer
  );
  const profiledata = createSelector(

    (state: any) => state.profile,
    (state) => ({
      success: state.success,
    })
  );
  const { success } = useSelector(profiledata);
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("Admin");

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(getAuthUser);
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(getAuthUser);
        setusername(`${obj.username} (${obj.role === "admin" ? "admin" : obj.role == "Employee" ? "Employee" : obj.role?.[0]})`);
      }
    }
  }, [success]);


  const logOff = async () => {
    const clearCookie = (name:any) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };
    const clearAllCookies = () => {
      const cookies = document.cookie?.split(";");
    
      cookies.forEach(cookie => {
        const name = cookie?.split("=")[0]?.trim();
        clearCookie(name);
      });
    };
    clearAllCookies();

     // FireBase Start
    const firebaseToken = localStorage.getItem("firebaseToken");
    
    if (firebaseToken) {
      try {
        let token = localStorage.getItem("token");
        const response = await fetch(APPENV_URL.identityUrl + "/" + `f-cMDetail/f-cMDetail-on-logout?fcmToken=${firebaseToken}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${token}`
          },
        });
        if (firebaseToken) {
          await deleteToken(messaging);
        }
      } catch (error) {
        console.log("error to delete firebase token");
      }
    }

     // FireBase End

    if (formValue?.status === "Login") {
      debugger;
      const permissionStatus = await navigator?.permissions?.query({ name: 'geolocation' });
      const hasPermission = permissionStatus?.state;
      if (hasPermission == "granted") {
        const currentSource = navigator;
        currentSource.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const CheckOutDetails = {
            logOutLocation: `${latitude},${longitude}`
          };
          if (latitude && longitude) {
            update(CheckOutDetails);
          }
        },
          (error) => {
            console.error('Error getting user location:', error);
            localStorage.removeItem("token");
            localStorage.removeItem("PKCE_verifier");
            localStorage.removeItem("role");
            localStorage.removeItem("id_token");
            localStorage.removeItem("nonce");
            localStorage.removeItem("authUser");
            localStorage.removeItem("exp");
            let oauth = new OAuthService();
            oauth.logOut();
            if (!localStorage.getItem('PKCE_verifier')) {
              oauth.createLoginUrl().then(data => {
  
                let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
                let culture = localStorage.getItem("i18nextLng");
                const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
                // console.log(loginURL)
                authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL);
                setTimeout(() => {
                  window.open(authUrl, "_self");
                }, 500);
              }).catch(error => {
                console.log(error);
              });
            } else {
              console.log("PKCE_VERifier are there already in pro1");
            }
          }
        );
      }else{
        return alert("Please enable location permissions to continue")
      }
    }
    else {
      localStorage.removeItem("token");
      localStorage.removeItem("PKCE_verifier");
      localStorage.removeItem("role");
      localStorage.removeItem("id_token");
      localStorage.removeItem("nonce");
      localStorage.removeItem("authUser");
      localStorage.removeItem("exp");
      let oauth = new OAuthService();
      oauth.logOut();
      if (!localStorage.getItem('PKCE_verifier')) {
        oauth.createLoginUrl().then(data => {

          let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
          let culture = localStorage.getItem("i18nextLng");
          const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
          // console.log(loginURL)
          authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL);
          setTimeout(() => {
            window.open(authUrl, "_self");
          }, 500);
        }).catch(error => {
          console.log(error);
        });
      } else {
        console.log("PKCE_VERifier are there already in pro1");
      }
    }

  };

  useEffect(() => {
    if (isLogout) {
      console.log("LogOUT --->");
      localStorage.removeItem("token");
      localStorage.removeItem("PKCE_verifier");
      localStorage.removeItem("role");
      localStorage.removeItem("id_token");
      localStorage.removeItem("nonce");
      localStorage.removeItem("authUser");
      localStorage.removeItem("exp");

      let oauth = new OAuthService();
      oauth.logOut();
      if (!localStorage.getItem('PKCE_verifier')) {
        oauth.createLoginUrl().then(data => {

          let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
          let culture = localStorage.getItem("i18nextLng");
          const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
          // console.log(loginURL)
          authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL);
          setTimeout(() => {
            window.open(authUrl, "_self");
          }, 500);
        }).catch(error => {
          console.log(error);
        });
      } else {
        console.log("PKCE_VERifier are there already in pro");
      }
    }
  }, [isLogout]);

  const update = (formValue: any) => {
    dispatch(updateAttendance(formValue, true));
  };
  useEffect(() => {

    dispatch(getProfile());
    return () => {
      dispatch(resetEmployeeProfile());
    };
  }, []);
  const [id, setId] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    const getAuthUser: any = localStorage.getItem("authUser");
    const obj = JSON.parse(getAuthUser);
    if (hasAdminOrEmployeeRoleHandler()) {
      setUser("Employee");
      setId(profile?.extraProperties?.MelEmployeeId);
    }
    if (hasFranchiseRoleHandler()) {
      setUser("Franchise");
      setId(profile?.extraProperties?.FranchiseId);
    }
    if (hasDealerRoleHandler()) {
      setUser("Dealer");
      setId(profile?.extraProperties?.DealerId);
    }
    if (hasCustomerRoleHandler()) {
      setUser("Customer");
      setId(profile?.extraProperties?.CustomerId);
    }
    if (hasDealerSubuserRoleHandler()) {
      setUser("DealerSubUser");
      setId(obj?.uid);
    }
    if (hasFranchiseSubuserRoleHandler()) {
      setUser("FranchiseSubUser");
      setId(obj?.uid);
    }
    if (hasManufacturerRoleHandler()) {
      setUser("Manufacturer");
      setId(profile?.extraProperties?.ManufacturerId);
    }

  }, [profile]);

  useEffect(() => {
    if (id && user) {

      dispatch(getProfilePictureLoading(id, user));
    }

  }, [id, user]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePicture ? profilePicture : avatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          {true && <Link to={"/profile"} className="dropdown-item" onClick={() => setMenu(false)}>
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            {props.t("Profile")}{" "}
          </Link>
          }

          {false && <Link to={""} className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </Link>}
          {attendanceApplicableUsers.map((eachTitle: any, index: number) => {
            return props.role?.includes(eachTitle.value) ?
              <Link key={index} to={ROUTER.ATTENDANCE} className="dropdown-item" onClick={() => setMenu(false)}>
                <i className="bx bx-calendar font-size-16 align-middle me-1" />
                {props.t("My Attendance")}
              </Link> : null;
          })}
          <div className="dropdown-divider" />
          <div onClick={logOff} className="dropdown-item" style={{ cursor: "pointer" }} >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);
