import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import "./viewBrand.scss"
import { useNavigate, useParams } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { vehicleBrandsDetails } from 'src/models/pages/vehicleBrandsModel';
import { getDealerNamesBYID, getVehicleBrandImageLoading, getVehicleBrandsBYID } from 'src/store/actions';
import { Image } from 'primereact/image';
import { Link } from 'react-router-dom';

const ViewVehicleBrand = () => {

  const { id } = useParams()
  const navigate = useNavigate();
  const editDealerClickHandler = () => {
    navigate(`${ROUTER.VEHICLE_BRANDS_EDIT}/${id}`);
  };
  const archiveDealerClickHandler = () => { };
  const { error, message, loading, vehicleBrandImage,dealerNames } = useSelector((state: RootReducerState) => state.vehicleBrandsReducer);
  const formValue: vehicleBrandsDetails = useSelector(
    (state: RootReducerState) => state.vehicleBrandsReducer?.vehicleBrandsDetails as vehicleBrandsDetails
  );

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getVehicleBrandsBYID(id));
      dispatch(getDealerNamesBYID(id));
      dispatch(getVehicleBrandImageLoading(id))
    }
  }, [id, dispatch]);


  const statusBodyTemplate = (value: string) => {
    let status = value as string;
    return (
      <StatusHghlighter status={status} />
    )

  }

  const dealerViewDetails = {
    personalDetails: [
      {
        label: "Name",
        value: formValue?.name
      },
      {
        label: "Dealers",
        value:  dealerNames.length>0 ?<div className='d-flex gap-2 flex-wrap dealer-name'>{
        dealerNames.map((item:any,index:number)=>{
          return(
            <>
            <Link to={`${ROUTER.DEALERS_VIEW}/${item.id}`}  key={index}>{item.name} {(dealerNames.length>1 && dealerNames.length-1!=index) ? `,` : (dealerNames.length>1 && dealerNames.length-1==index) ? `.` : ""  }</Link>
           
            </>
          )
        })
      }</div> : ""
      },
    ],
  }

  return (
    <div className="pagecontent " id="view-brand">
      <BreadcrumbWithFilter
        title="Vehicle Brands"
        titlePath={ROUTER.VEHICLE_BRANDS_GRID}
        breadcrumbItem="Vehicle Brands Details"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={true}
        firstButtonLabel='Edit Vehicle Brand'
        onFirstButtonClick={editDealerClickHandler}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <b>General Details</b>
          </div>
          <div className="subview">
            <Row>
              {
                dealerViewDetails.personalDetails.map((data, index) => {
                  return (
                    <Col lg={12} key={index} className={"mt-4"}>
                      <div className='mb-2'>{data.label}</div>
                      <b>{data.value ? data.value : <div className='none-text'>-</div>}</b>
                    </Col>
                  )
                })
              }
            </Row>

            <Row>
              <Col lg={4} className='mt-4'>
                <div className='mb-2'>Image</div>
                {vehicleBrandImage ?
                  // <img src={vehicleBrandImage} className='view-image' />
                  <Image src={vehicleBrandImage} alt="Image" className='view-image' height='150px' preview />
                  :
                  <div className='none-text'>-</div>
                }
              </Col>
            </Row>

          </div>
        </div>
      </Row>
    </div>
  );
};

export default ViewVehicleBrand;
