import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import DeleteModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { formatDateTOYYYYMMDD } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import DropdownInput from "src/components/UI/DropdownInput";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import {
  clearEnquiryMessage,
  createEnquiry,
  getJobsheetDropdownForEnquiry,
  resetEnquiryMessage,
} from "src/store/enquiries/action";
import TextEditor from "src/components/atoms/TextEditor";

const AddEnquiry = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { job_id } = useParams()

  const { error, message, loading, jobSheetList } = useSelector(
    (state: RootReducerState) => state.EnquiryReducer
  );

  useEffect(() => {
    const auth: any = localStorage.getItem('authUser')
    const parsed = JSON.parse(auth)
      dispatch(getJobsheetDropdownForEnquiry())

    // if (hasFranchiseRoleHandler()) {
    //   dispatch(getFranchiseJobsheetDropdownForEnquiry(parsed?.uid))
    // }

    return () => {
      dispatch(resetEnquiryMessage())
    }
  }, [])
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [contactDetailFalse, setContactDetailFalse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visible, setVisible] = useState(false);







  useEffect(() => {
    if (error) {
      setIsSubmitting(false)
      setVisible(true)
      // showToast(error, { type: "error" })
    }
    if (message) {

      showToast(message, { type: "success" });
      dispatch(clearEnquiryMessage())
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.ENQUIRY_GRID);
      }, 500)

    }
  }, [error, message])


  const hidePopup = () => {
    setVisible(false);
    dispatch(clearEnquiryMessage())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const post = (formValue: parts) => {
    dispatch(createEnquiry(formValue));
  };


  const [userName, setUserName] = useState<string>();
  const getAuthUser = localStorage.getItem("authUser");
  useEffect(() => {
    if (getAuthUser) {
      const obj = JSON.parse(getAuthUser);
      setUserName(obj.username);
    }
  }, [getAuthUser])

  const initialvalue = {
    jobId: (job_id ? Number(job_id) : "to be generated"),
    enquirerName: (""),
    dateOfEnquiry: (formatDateTOYYYYMMDD(new Date())),
    attentedBy: (userName),
    description: (""),
    actionTaken: (""),
  };



  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: any = {
      "jobId": values.jobId,
      "enquirerName": values.enquirerName,
      "description": values.description,
      "actionTaken": values.actionTaken,
      "isCustomer": values.isCustomer
    }
    setIsSubmitting(true)
    post(submittedData);
  };

  const validationSchema = Yup.object().shape({
    jobId: Yup.string().required(`Jobsheet is required`),
    actionTaken: Yup.string().required(`Acion Taken is required`),
    dateOfEnquiry: Yup.date().required(`Date Of Enquiry is required`),
    enquirerName: Yup.string().required(`Enquirer Name is required`),
    description: Yup.string().required(`Description is required`),
    attentedBy: Yup.string().required(`Attented By is required`),
  });

  const handleReset = (resetForm: any) => {
    resetForm();

  }

  const JOBSHEET_OPTIONS = jobSheetList?.map((item: any) => (
    {
      label: item?.number ? item?.number : "New job" ,
      value: item?.id
    }
  ))?.reverse()


  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      <DeleteModal
        show={deleteModal || false}
        showIcon={false}
        message="Are you sure want to delete this contact"
        onDeleteClick={() => {
          // deleteContact
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Enquiry"
            titlePath={ROUTER.ENQUIRY_GRID}
            breadcrumbItem="Create Enquiry"
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldTouched,
                      setFieldValue,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            {/* {JSON.stringify(values.jobId)} */}
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={JOBSHEET_OPTIONS}
                                name="jobId"
                                label="Job Sheet"
                                onChange={handleChange}
                                required={true}
                                onBlur={handleBlur}
                                value={values.jobId}
                                invalid={
                                  touched["jobId"] && errors["jobId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Enquirer Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="enquirerName"
                                onBlur={handleBlur}
                                value={values.enquirerName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["enquirerName"] && errors["enquirerName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Date Of Enquiry"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="dateOfEnquiry"
                                onBlur={handleBlur}
                                disabled={true}
                                value={values.dateOfEnquiry}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["dateOfEnquiry"] && errors["dateOfEnquiry"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Attented By"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="attentedBy"
                                onBlur={handleBlur}
                                disabled={true}
                                value={values.attentedBy}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["attentedBy"] && errors["attentedBy"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Description"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="description"
                                onBlur={handleBlur}
                                rowHeight={2}
                                value={values.description}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["description"] && errors["description"]
                                    ? true
                                    : false
                                }
                              /> */}
                              <TextEditor
                                onChange={handleChange}
                                labelName='Description'
                                name='description'
                                required={true}
                                value={values.description}
                                invalid={touched["description"] && errors["description"]
                                ? true
                                : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>
                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Action Taken"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                rowHeight={2}
                                name="actionTaken"
                                onBlur={handleBlur}
                                value={values.actionTaken}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["actionTaken"] && errors["actionTaken"]
                                    ? true
                                    : false
                                }
                              /> */}
                              <TextEditor
                                onChange={handleChange}
                                labelName='Action Taken'
                                name='actionTaken'
                                required={true}
                                value={values.actionTaken}
                                invalid={ touched["actionTaken"] && errors["actionTaken"]
                                ? true
                                : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>


                            <Col lg={3}  >
                              <CheckboxInput
                                name="isCustomer"
                                label="Is Customer"
                                value={values["isCustomer"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end mt-2">
                                <Button
                                  className="secondary-btn me-2"
                                  label="Reset"
                                  type="button"
                                  onClick={() => handleReset(resetForm)}
                                />
                                <Button
                                  className="btn-primary me-2 btn-primary-shadow"
                                  label={"Create Enquiry"}
                                  type="submit"
                                />
                              </div>
                            </Col>

                          </Row>



                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddEnquiry;
