import { stocklocation } from "src/models/pages/stockLocationModel";
import { StockLocationTypes } from "./actionTypes";

// For Grid

export const getStockLocationList = (lazyState: any) => {
  return {
    type: StockLocationTypes.GET_STOCKLOCATION_LIST,
    lazyState,
  };
};

export const getStockLocationListSuccess = (stocklocation: any) => {
  return {
    type: StockLocationTypes.GET_STOCKLOCATION_LIST_SUCCESS,
    payload: stocklocation,
  };
};

export const getStockLocationListError = (error: any) => {
  return {
    type: StockLocationTypes.GET_STOCKLOCATION_LIST_FAIL,
    payload: error,
  };
};


export const deleteStockLocationFromList = (payload:any) => {
  return {
    type: StockLocationTypes.DELETE_STOCKLOCATION,
    payload: payload,
  }
}

export const deleteStockLocationFromListSuccess = (response:any) => {
  return {
    type: StockLocationTypes.DELETE_STOCKLOCATION_SUCCESS,
    payload: response,
  }
}

export const deleteStockLocationFromListError = (error:any) => {
  return {
    type: StockLocationTypes.DELETE_STOCKLOCATION_FAIL,
    payload: error,
  }
}

// update stocklocation

export const updateStockLocations = (stocklocation: stocklocation, stocklocationId: string = "") => {
  return { type: StockLocationTypes.UPDATE_STOCKLOCATION, payload: { stocklocation, stocklocationId } };
};

export const updateStockLocationSuccess = (stocklocation: stocklocation) => {
  return { type: StockLocationTypes.UPDATE_STOCKLOCATION_SUCCESS, payload: stocklocation };
};

export const updateStockLocationError = (error: any) => {
  return { type: StockLocationTypes.UPDATE_STOCKLOCATION_FAIL, payload: error };
};

// post stocklocation
export const createStockLocations = (stocklocation: stocklocation) => {
  return { type: StockLocationTypes.POST_STOCKLOCATION, payload: stocklocation };
};

export const createStockLocationSuccess = (stocklocation:stocklocation) => {
  return { type: StockLocationTypes.POST_STOCKLOCATION_SUCCESS, payload: stocklocation };
};

export const createStockLocationError = (error: any) => {
  return { type: StockLocationTypes.POST_STOCKLOCATION_FAIL, payload: error };
};


// get stocklocation
export const getStockLocationBYID = (id: string) => {
  return { type: StockLocationTypes.GET_STOCKLOCATION_BY_ID, payload: id };
};

export const getStockLocationBYIDSuccess = (stocklocation: stocklocation) => {
  return { type: StockLocationTypes.GET_STOCKLOCATION_BY_ID_SUCCESS, payload: stocklocation };
};

export const getStockLocationBYIDError = (error: any) => {
  return { type: StockLocationTypes.GET_STOCKLOCATION_BY_ID_FAIL, payload: error };
};


export const resetStockLocationMessage = ()=>{
  return { type: StockLocationTypes.RESET_STOCKLOCATION};
}

export const clearStockLocationMessage = ()=>{
  return { type: StockLocationTypes.CLEAR_STOCKLOCATION_MESSAGE};
}
