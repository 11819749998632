import { call, put, takeLatest } from "redux-saga/effects";
import { AudioBrandsTypes } from "./actionTypes";
import { API } from "src/util/api";
import { getAudioBrandsListSuccess, getAudioBrandsListError, deleteAudioBrandsFromListError, createAudioBrandSuccess, createAudioBrandError, updateAudioBrandSuccess, updateAudioBrandError, getByIdAudioBrandSuccess, getByIdAudioBrandError, getVehicleBrandNameListSuccess, getVehicleBrandNameListError, deleteAudioBrandsFromListSuccess, getAudioBrandImageSuccess, getAudioBrandImageError, deleteAudioBrandImageSuccess, deleteAudioBrandImageError, uploadAudioBrandFileSuccess, uploadAudioBrandFileError } from "./action";
import { audioBrandDetails, audioBrandErrorState } from "src/models/pages/audioBrandsModel";
import { CustomerData } from "src/models/pages/customerModel";

//worker functions

//get audio brand list api 

function* getAudioBrandsListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getAudioBrandsListAPI,{...action.lazyState })
    yield put(getAudioBrandsListSuccess(response));
  } catch (error) {
    yield put(getAudioBrandsListError(error));
  }
}

//delete audio brand list api

function* deleteAudioBrandsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deleteAudioBrandsListAPI, { id: action.payload.id, undo:action.payload.undo }
    )
    yield put(deleteAudioBrandsFromListSuccess(response,action.payload.undo))
  } catch (error) {
    yield put(deleteAudioBrandsFromListError(error));
  }
}

//create audio brand

function* createAudioBrandAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.createAudioBrandApi,action.payload);
    yield put(createAudioBrandSuccess(response))
  } catch (error : any) {
    yield put(createAudioBrandError(error))
  }
}

//update audio barnds
function* updateAudioBrandAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.updateAudioBrandApi,{...action.payload,id : action.id})
    yield put(updateAudioBrandSuccess(response))
  } catch (error) {
    yield put(updateAudioBrandError(error))
  }
}

//getIdBy audio brands
function* getIdByAudioBrandAsync(action : any){
   try {
    const response : Promise<any> = yield call(API.getAudioBrandByIdApi,action.payload);
    yield put(getByIdAudioBrandSuccess(response))
   } catch (error) {
    yield put(getByIdAudioBrandError(error))
   }
}

//get vehicleBrand List Api
function* getVehicleBarndAsync(){
  try {
    const response : Promise<any> = yield call(API.getVehicleBrandListFromMasterManagement,'');
    yield put(getVehicleBrandNameListSuccess(response))
  } catch (error) {
    yield put(getVehicleBrandNameListError(error))
  }
}

function* getAudioBrandImageAsync({ payload }: any) {
  try {
    const response: audioBrandDetails = yield call(API.fetchAudioBrandImage, payload)
      yield put(getAudioBrandImageSuccess(response.data));
  } catch (error) {
    yield put(getAudioBrandImageError(error));
  }
}

function* deleteAudioBrandImageAsync({ payload }: any) {
  try {
    const response: audioBrandDetails = yield call(API.deleteAudioBrandImage, payload)
    yield put(deleteAudioBrandImageSuccess(response.data));
  } catch (error) {
    yield put(deleteAudioBrandImageError(error));
  }
}

//update audio brand image
function* uploadAudioBrandFileAsync(action: any) {

  try {
    const response: Promise<any>= yield call(API.uploadAudioBrandImage, action.payload, { id: action.id });
      yield put(uploadAudioBrandFileSuccess(response))
    
  } catch (error) {
    yield put(uploadAudioBrandFileError(error))
  }
}


//watcher functions
function* audioBrandsSaga() {
  yield takeLatest(AudioBrandsTypes.GET_AUDIO_BRANDS_LIST, getAudioBrandsListAsync);
  yield takeLatest(AudioBrandsTypes.DELETE_AUDIO_BRANDS_PENDING, deleteAudioBrandsAsync);
  yield takeLatest(AudioBrandsTypes.CREATE_AUDIO_BRANDS_PENDING,createAudioBrandAsync);
  yield takeLatest(AudioBrandsTypes.UPDATE_AUDIO_BRANDS_PENDING,updateAudioBrandAsync);
  yield takeLatest(AudioBrandsTypes.GET_BY_ID_AUDIO_BRANDS_PENDING,getIdByAudioBrandAsync);
  yield takeLatest(AudioBrandsTypes.GET_VEHICLE_BRAND_NAMES_LOADING,getVehicleBarndAsync)
  yield takeLatest(AudioBrandsTypes.AUDIO_BRAND_FILE_UPLOAD_LOADING, uploadAudioBrandFileAsync);
  yield takeLatest(AudioBrandsTypes.AUDIO_BRAND_FILE_FETCH_LOADING, getAudioBrandImageAsync);
  yield takeLatest(AudioBrandsTypes.AUDIO_BRAND_FILE_DELETE_LOADING, deleteAudioBrandImageAsync);
}

export default audioBrandsSaga;