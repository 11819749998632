import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import "./ViewDealer.scss";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import { useNavigate, useParams } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { clearDealerData, dealerTaxTypeList, getDealerDetail, getDealerImageLoading } from 'src/store/dealer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DealerCreateModel } from 'src/models/pages/dealerModel';
import { RootReducerState } from 'src/store/reducers';
import { CurrencyFormatter, formatDateMEL } from 'src/helpers/common_helpers';
import { taxDetails } from 'src/models/pages/customerModel';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getInvoicesListLoading, getReceivedPaymentListLoading } from 'src/store/actions';
import StatusHighlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate';
import { getDealerSubuserListLoading, resetDealerSubuser } from 'src/store/dealersubuser/action';
import { Image } from 'primereact/image';


const receivedPaymentConverson = (values: any[]) => {
  const modifeidValue = values?.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['paymentDate'] = eachValue?.paymentDate;
    data['amount'] = eachValue?.amount;
    data['tdsdeduction'] = eachValue?.tdsdeduction;
    data['total'] = eachValue?.total;
    data['paymentNotes'] = eachValue?.paymentNotes;
    return data;
  });
  return modifeidValue;
};



const ViewDealer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formValue: DealerCreateModel = useSelector(
    (state: RootReducerState) => state.dealer?.dealer as DealerCreateModel
  );
  const { error, message, loading, dealerTaxList, dealerImage } = useSelector(
    (state: RootReducerState) => state.dealer
  );
  const dt = useRef<DataTable<any>>(null);
  const invoiceListpage: any = useSelector((state: RootReducerState) => state.invoiceReducer.invoiceList.items);
  const invoiceTotalCount: any = useSelector((state: RootReducerState) => state.invoiceReducer.invoiceList.totalCount);
  const { items, total } = useSelector((state: RootReducerState) => state.receivedPaymentReducer.receivedPaymentList);
  const totalRecords = total;
  const { values, totalCount } = useSelector((state: RootReducerState) => state.dealerSubUserReducer.dealerSubUserList);

  const reecivedPaymentLoading = useSelector((state: RootReducerState) => state.receivedPaymentReducer.loading);
  const invoiceLoading = useSelector((state: RootReducerState) => state.invoiceReducer.loading);

  const modifiedValues = receivedPaymentConverson(items);
  const currencyBodyTemplate = (amount: any) => {
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || reecivedPaymentLoading || invoiceLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, reecivedPaymentLoading,invoiceLoading]);


  useEffect(() => {
    if (id) {
      dispatch(dealerTaxTypeList());
      dispatch(getDealerDetail(id));
      dispatch(getDealerImageLoading(id));
    }
    return () => {
      dispatch(clearDealerData());
    };
  }, []);


  const editDealerClickHandler = () => {
    navigate(`${ROUTER.DEALERS_EDIT}/${id}`);
  };

  const statusBodyTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const statusBodyForViewTemplatess = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };


  const dealerViewDetails = {
    personalDetails: [
      {
        label: "Name",
        value: formValue?.['user']?.['name']
      },
      {
        label: "User Name",
        value: formValue?.['user']?.['userName']
      },
      {
        label: "Email",
        value: formValue?.['user']?.['email']
      },
      {
        label: "Mobile",
        value: formValue?.['user']?.['phoneNumber']
      },
      {
        label: "Alternate Number ",
        value: formValue?.['user']?.['extraProperties']?.['AlternateNumber']
      },
      // {
      //   label: "Date of Birth",
      //   value: formatDateMEL(formValue?.['user']?.['extraProperties']?.["DateOfBirth"])
      // },
      // {
      //   label: "Wedding Date",
      //   value: formatDateMEL(formValue?.['user']?.['extraProperties']?.["WeddingDate"])
      // },
      {
        label: "Address",
        value: formValue?.['user']?.['extraProperties']?.["Address"]
      },
    ],
    moreDetails: [
      {
        label: "Dealer Code",
        value: formValue?.["dealerCode"]
      },
      {
        label: "GST Number",
        value: formValue?.['user']?.['extraProperties']?.["GstNumber"]
      },
      {
        label: "Payment Type",
        value: formValue?.["paymentType"]
      },
      {
        label: "Credit Amount",
        value: currencyBodyTemplate(formValue?.["creditAmount"])
      },
      {
        label: "Credit Days",
        value: formValue?.["creditDays"]
      },
      {
        label: "Tax Type",
        value: formValue?.taxTypeId ? (dealerTaxList.find((item: taxDetails) => item.id === formValue?.["taxTypeId"])?.taxName) : '-'
      },
      // {
      //   label: "Other Details",
      //   value: formValue?.["otherDetails"]
      // },
      // {
      //   label: "Permit Form Required",
      //   value: statusBodyTemplate(formValue?.["permitFormRequired"])
      // },
      {
        label: "Feedback Required",
        value: statusBodyTemplate(formValue?.["feedbackRequired"])
      },
      {
        label: "Send Sms",
        value: statusBodyTemplate(formValue?.["user"]?.['extraProperties']?.["SendSms"])
      },
      {
        label: "Send Email",
        value: statusBodyTemplate(formValue?.["user"]?.['extraProperties']?.["SendEmail"])
      },
      {
        label: "Send Whatsapp",
        value: statusBodyTemplate(formValue?.["user"]?.['extraProperties']?.["SendWhatsapp"])
      },
      {
        label: "Mobile Number Verified",
        value: statusBodyTemplate(formValue?.["user"]?.["phoneNumberConfirmed"])
      },
      {
        label: "Is Active",
        value: statusBodyTemplate(formValue?.["user"]?.["isActive"])
      },
    ],
    contactDetail: [
      {
        label: "Name",
        value: ""
      },
      {
        label: "Designation",
        value: ""
      },
      {
        label: "Mobile Number",
        value: ""
      },
      {
        label: "Email",
        value: ""
      },
      {
        label: "Alternate Number",
        value: ""
      },
      {
        label: "Send Email",
        value: ""
      },
      {
        label: "Send Sms",
        value: ""
      },
    ]
  };

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const Invoicecolumns: Columns[] = [
    {
      field: "number",
      sortable: false,
      header: "Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "paidState",
      sortable: false,
      header: "Paid State",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => statusBodyForViewTemplatess(rowData, 'paidState'),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => headerTemplate(rowData.total),
      isDefault: true,
      isStatic: true,
    },

  ];

  const receivedPaymentcolumns: Columns[] = [
    {
      field: "paymentDate",
      sortable: false,
      header: "Payment Date",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => formatDateMEL(rowData.paymentDate),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "tdsdeduction",
      sortable: false,
      header: "Tds Deduction",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "total",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "paymentNotes",
      sortable: false,
      header: "Payment Notes",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
  ];

  const emailBodyTemplate = (rowData: any, field: string) => {
    let data = rowData[field];
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const columns: Columns[] = [

    {
      field: "userName",
      sortable: false,
      header: "User Name",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },

    {
      field: "name",
      sortable: false,
      header: "Name",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "200px", marginLeft: "20px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "email",
      sortable: false,
      header: "Email",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => emailBodyTemplate(rowData, "email"),
      isStatic: false,
    },
    {
      field: "phoneNumber",
      sortable: false,
      header: "Mobile",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyForViewTemplatess(rowData, 'isActive'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "lockoutEnabled",
      sortable: false,
      header: "Lockout Enabled",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyForViewTemplatess(rowData, 'lockoutEnabled'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  const filterDetails: DataTableFilterMeta = {
    DealerId: { value: id ? id : "", matchMode: "contains" }
  };
  const receivedfilterDetails: DataTableFilterMeta = {
    DealerId: { value: id ? id : "", matchMode: "contains" },
    paymentDate: { value: "", matchMode: "contains" },
    amount: { value: "", matchMode: "contains" },
    tdsdeduction: { value: "", matchMode: "contains" },
    total: { value: "", matchMode: "contains" },
    paymentNotes: { value: "", matchMode: "contains" },
  };

  const invoicefilterDetails: DataTableFilterMeta = {
    DealerId: { value: id ? id : "", matchMode: "contains" },
    paymentDate: { value: "", matchMode: "contains" },
    amount: { value: "", matchMode: "contains" },
    tdsdeduction: { value: "", matchMode: "contains" },
    total: { value: "", matchMode: "contains" },
    paymentNotes: { value: "", matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 5,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };


  let invoiceLazyState: LazyTableState = {
    first: 0,
    rows: 5,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: invoicefilterDetails,
  };

  let receivedPaymentLazyState: LazyTableState = {
    first: 0,
    rows: 5,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: receivedfilterDetails,
  };



  const [lazyState, setLazyState] = useState(initialLazyState);

  const [receivedPaymentState, setReceivedPaymentState] = useState(receivedPaymentLazyState);

  const [invoiceState, setinvoiceState] = useState(invoiceLazyState);

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getDealerSubuserListLoading(lazyState));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState, id]);

  const receivedPaymentCallback = (receivedlazyState: LazyTableState) => {
    dispatch(getReceivedPaymentListLoading(receivedlazyState));
  };


  useEffect(() => {
    receivedPaymentCallback(receivedPaymentState);
  }, [receivedPaymentState]);

  const invoiceCallback = (invoicelazyState: LazyTableState) => {
    dispatch(getInvoicesListLoading(invoicelazyState));
  };

  useEffect(() => {
    invoiceCallback(invoiceState);
  }, [invoiceState]);



  useEffect(() => {
    return () => {
      dispatch(resetDealerSubuser());
    };
  }, []);



  const onFilter = (event: any) => {
    event["first"] = 0;
    setReceivedPaymentState(event);
  };

  const onPage = (event: any) => {
    setReceivedPaymentState(event);
  };

  const onSort = (event: any) => {
    setReceivedPaymentState(event);
  };

  const invoiceOnFilter = (event: any) => {
    event["first"] = 0;
    setinvoiceState(event);
  };

  const invoiceOnPage = (event: any) => {
    setinvoiceState(event);
  };

  const invoiceOnSort = (event: any) => {
    setinvoiceState(event);
  };


  return (
    <div className="pagescontent " id="view-dealer">

      <BreadcrumbWithFilter
        title="Dealers"
        titlePath={ROUTER.DEALERS_GRID}
        breadcrumbItem="Dealers Details"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={true}
        firstButtonLabel='Edit Dealer'
        onFirstButtonClick={editDealerClickHandler}
        isShowSecondButton={false}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <b>General Details</b>
          </div>
          <div className="subview">
            <Row>
              <Col lg={6}>
                <div className="parentCls2">
                  {
                    dealerViewDetails.personalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.label}</div>
                          <div>{data.value ? data.value : <div className="none-text">-</div>}</div>
                        </div>
                      );
                    })
                  }
                </div>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col lg={4} className=''>
                    <div className='mb-2'>Image</div>
                    {dealerImage ?
                      <Image src={dealerImage} alt="Image" className='view-image' height='150px' preview />
                      :
                      <div className='none-text'>-</div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Row>
        <div className="view">
          <div className="general-details">
            <b>More Details</b>
          </div>
          <div className="subview">
            <div className="parentCls">
              {
                dealerViewDetails.moreDetails.map((data, index) => {
                  return (
                    <div key={index} className={` pt-1 childCls`}>
                      <div>{data.label}</div>
                      <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </Row>

      {formValue?.contactDetails?.length > 0 && <Row>
        <div className="view">
          <div className="general-details">
            <b>Contact Details</b>
          </div>
          <div className="subview">
            <Row>
              <div className="card ps-0 pe-0">
                <DataTable size='large' value={formValue?.contactDetails} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                  <Column field="designation" header="Designation"></Column>
                  <Column field="name" header="Name"></Column>
                  <Column field="mobileNumber" header="Mobile Number"></Column>
                  <Column field="email" header="Email"></Column>
                  <Column field="alternateNumber" header="Alternate Number"></Column>
                  <Column field="sendEmail" header="Send Email" body={(rowData)=>statusBodyTemplate(rowData?.sendEmail)}></Column>
                  <Column field="sendSms" header="Send Sms" body={(rowData)=>statusBodyTemplate(rowData?.sendSms)}></Column>
                </DataTable>
              </div>
            </Row>
          </div>
        </div>
      </Row>
      }

      <Row className='mt-3'>
        <div className="view">
          <div className="general-details">
            <b>Sub-user Details</b>
          </div>
          <div className="subview">
            <DataTableWrapper
              columnDetails={columns}
              value={values}
              stripedRows={true}
              rowHover={true}
              loading={loading}
              emptyMessage="No users found."
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Displaying Users  {last} records of {totalRecords} in total"
            ></DataTableWrapper>
          </div>
        </div>
      </Row>

      {invoiceListpage?.length > 0 &&
        <Row>
          <div className="view">
            <div className="general-details">
              <b>Invoice</b>
            </div>
            <div className="subview">
              <Row>
                <div className="card ps-0 pe-0">
                  <DataTableWrapper
                    columnDetails={Invoicecolumns}
                    value={invoiceListpage}
                    paginator={true}
                    stripedRows={true}
                    lazy
                    rowHover={true}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    totalRecords={invoiceTotalCount}
                    emptyMessage="No Received Payment found"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Displaying Invoice {last} records of {totalRecords} in total"
                    first={invoiceState.first}
                    onSort={invoiceOnSort}
                    sortField={invoiceState.sortField}
                    sortOrder={invoiceState.sortOrder}
                    filters={invoiceState.filters}
                    onFilter={invoiceOnFilter}
                    onPage={invoiceOnPage}
                    rows={invoiceState.rows}
                  >
                  </DataTableWrapper>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      }
      {/* receivedPayment?.length > 0  */}
      {items?.length > 0 &&
        <Row>
          <div className="view">
            <div className="general-details">
              <b>Received Payment</b>
            </div>
            <div className="subview">
              <Row>
                <div className="card ps-0 pe-0">
                  <DataTableWrapper
                    ref={dt}
                    columnDetails={receivedPaymentcolumns}
                    value={modifiedValues}
                    paginator={true}
                    stripedRows={true}
                    lazy
                    rowHover={true}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    totalRecords={totalRecords}
                    emptyMessage="No Received Payment found"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Displaying Received Payment {last} records of {totalRecords} in total"
                    first={receivedPaymentState.first}
                    onSort={onSort}
                    sortField={receivedPaymentState.sortField}
                    sortOrder={receivedPaymentState.sortOrder}
                    filters={receivedPaymentState.filters}
                    onFilter={onFilter}
                    onPage={onPage}
                    rows={receivedPaymentState.rows}
                  >
                  </DataTableWrapper>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      }

    </div>
  );
};

export default ViewDealer;
