import { parts } from "src/models/pages/partsModel";
import { PartsTypes } from "./actionTypes";



// For Grid

export const getPartsList = (lazyState: any) => {
  return {
    type: PartsTypes.GET_PARTS_LIST,
    lazyState,
  };
};



export const getPartsListSuccess = (parts: any) => {
  return {
    type: PartsTypes.GET_PARTS_LIST_SUCCESS,
    payload: parts,
  };
};

export const getPartsListError = (error: any) => {
  return {
    type: PartsTypes.GET_PARTS_LIST_FAIL,
    payload: error,
  };
};


export const deletePartsFromList = (payload:any) => {
  return {
    type: PartsTypes.DELETE_PARTS,
    payload: payload,
  }
}

export const deletePartsFromListSuccess = (response:any,undo:boolean) => {
  return {
    type: PartsTypes.DELETE_PARTS_SUCCESS,
    payload: response,
    undo
  }
}

export const deletePartsFromListError = (error:any) => {
  return {
    type: PartsTypes.DELETE_PARTS_FAIL,
    payload: error,
  }
}




// update parts

export const updateParts = (parts: parts, partId: string = "") => {
  return { type: PartsTypes.UPDATE_PARTS
    
    , payload: { parts, partId } };
};

export const updatePartsSuccess = (parts: parts) => {
  return { type: PartsTypes.UPDATE_PARTS_SUCCESS, payload: parts };
};

export const updatePartsError = (error: any) => {
  return { type: PartsTypes.UPDATE_PARTS_FAIL, payload: error };
};


// post parts
export const createParts = (parts: parts) => {
  return { type: PartsTypes.POST_PARTS, payload: parts };
};

export const createPartsSuccess = (parts: parts) => {
  return { type: PartsTypes.POST_PARTS_SUCCESS, payload: parts };
};

export const createPartsError = (error: any) => {
  return { type: PartsTypes.POST_PARTS_FAIL, payload: error };
};


// get parts
export const getPartsBYID = (id: string) => {
  return { type: PartsTypes.GET_PARTS_BY_ID, payload: id };
};

export const getPartsBYIDSuccess = (parts: parts) => {
  return { type: PartsTypes.GET_PARTS_BY_ID_SUCCESS, payload: parts };
};

export const getPartsBYIDError = (error: any) => {
  return { type: PartsTypes.GET_PARTS_BY_ID_FAIL, payload: error };
};


export const resetPartsMessage = ()=>{
  return { type: PartsTypes.RESET_PARTS};
}

export const clearPartsMessage = ()=>{
  return { type: PartsTypes.CLEAR_PARTS_MESSAGE};
}

export const getAudioBrandsListForParts = ()=>{
  return { type: PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS};
} 

export const getAudioBrandsListForPartsSuccess=(audioList : any)=>{
  return {
    type : PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS_SUCCESS,
    payload : audioList
  }
}

export const getAudioBrandsListForPartsError=(error : any)=>{
  return {
    type : PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS_FAIL,
    payload : error
  }
}


//get purchaseorderDetails part intent view page

export const getViewPartsPurchaseOrderLoading=(data : any)=>{
  return {
    type : PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_LOADING,
    payload : data
  }
}

export const getViewPartsPurchaseOrderSucces=(resp : any)=>{
  return {
    type : PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_SUCCESS,
    payload : resp
  }
}

export const getViewPartsPurchaseOrderError=(error : any)=>{
  return {
    type : PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_ERROR,
    payload : error
  }
}




//get part inbound Details part intent view page

export const getViewPartsPartInboundDetailsLoading=(data : any)=>{
  return {
    type : PartsTypes.PARTS_VIEW_PARTS_INBOUND_LOADING,
    payload : data
  }
}

export const getViewPartsPartInboundDetailsSuccess=(resp : any)=>{
  return {
    type : PartsTypes.PARTS_VIEW_PARTS_INBOUND_SUCCESS,
    payload : resp
  }
}

export const getViewPartsPartInboundDetailsError=(error : any)=>{
  return {
    type : PartsTypes.PARTS_VIEW_PARTS_INBOUND_ERROR,
    payload : error
  }
}