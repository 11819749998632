import React from "react";
import "./StatusHighlighter.scss";

const YES = "YES";
const NO = "NO";

export default function StatusHghlighter({ status }: { status: boolean | any }) {
    let statusTrasformed = status ? YES : NO;

    switch (status) {
        case true:
            return <>
                <div id="status-highlighter" >
                    <div className={`staus-highlighter-wrapper yes`}>
                        {statusTrasformed}
                    </div>
                </div>
            </>
        case false:
            return <>
                <div id="status-highlighter" >
                    <div className={`staus-highlighter-wrapper no`}>
                        {statusTrasformed}
                    </div>
                </div>
            </>
        case 1:
            return <>
                <div id="status-highlighter" >
                    <div className={`staus-highlighter-wrapper one`}>
                        Pending
                    </div>
                </div>
            </>
        case 2:
            return <>
                <div id="status-highlighter" >
                    <div className={`staus-highlighter-wrapper two`}>
                        Partial
                    </div>
                </div>
            </>
        case 3:
            return <>
                <div id="status-highlighter" >
                    <div className={`staus-highlighter-wrapper three`}>
                        Done
                    </div>
                </div>
            </>
        default:
            return <>
            <div id="status-highlighter" >
                <div className={`staus-highlighter-wrapper `}>
                    -
                </div>
            </div>
        </>
    }
    // return(
    //     <div id="status-highlighter" >
    //         <div className={`staus-highlighter-wrapper ${statusTrasformed === YES ? 'yes' : ((statusTrasformed === NO) ?  'no' : '')}`}>
    //             {statusTrasformed}
    //         </div>
    //     </div>
    // )
}