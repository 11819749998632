import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import Button from 'src/components/UI/Button';
import * as Yup from 'yup';
import Dropzone from "react-dropzone";
import { ROUTER } from 'src/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { createAudioBrandPending, deleteAudioBrandImageLoading, getAudioBrandImageLoading, getByIdAudioBrandPending, getVehicleBrandNameListLoading, resetAudioBrandGlobalState, updateAudioBrandPending, uploadAudioBrandFileLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import DropdownInput from 'src/components/UI/DropdownInput';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import { onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import { brandImageDeleteSuccess } from 'src/store/audioBrands/reducer';

const EditAudioBrands: React.FC = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate();
  const initial = {
    brandName: "",
    description: "",
    jobprefix: "",
    servicedcount: "",
    vehicleBrandId: "",
  }
  const { showToast } = useToast();
  const { error, vehicleBrandNameList, message, audioBrandDetails, loading, audioBrandImage } = useSelector((state: RootReducerState) => state.audioBrandsReducer);
  const [deleteModal, setDeleteModal] = useState(false);
  const vehicleBrand = vehicleBrandNameList?.length > 0 ? (vehicleBrandNameList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.id;
    data['label'] = eachTax.name;
    return data;
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  })
 : []

  const validationSchema = Yup.object().shape({
    brandName: Yup.string().required('Brand name is required'),
    // jobprefix: Yup.string().required('Job prefix is required').length(3, 'Job prefix must be exactly 3 characters'),
    jobprefix: Yup.string().required('Job prefix is required'),
    servicedcount: Yup.string().required('Serviced count is required'),
    vehicleBrandId: Yup.string().required('Vehicle brand is required'),
  });




  const getInitialValue = () => {
    if (id) {
      return {
        brandName: audioBrandDetails?.brandName ? audioBrandDetails?.brandName : "",
        description: audioBrandDetails?.description ? audioBrandDetails?.description : "",
        jobprefix: audioBrandDetails?.jobPrefix ? audioBrandDetails?.jobPrefix : "",
        servicedcount: audioBrandDetails?.servicedCount ? audioBrandDetails?.servicedCount : "",
        vehicleBrandId: audioBrandDetails?.vehicleBrandId ? audioBrandDetails?.vehicleBrandId : "",
      }
    } else {
      return initial
    }
  }
  const initialvalue = getInitialValue();


  // function handleAcceptedFiles(files: any) {
  //   files.map((file: any) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );
  //   setselectedFiles(files);
  // }
  /**
     * Formats the size
     */
  // function formatBytes(bytes: any, decimals = 2) {
  //   if (bytes === 0) return "0 Bytes";
  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  // }

  const handleSubmit = async (values: any) => {
    // const submittedData = {
    //   uploadFile: selectedFiles,
    //   ...values
    // }
    if (isSubmitting) return
    if (!id) {
      dispatch(createAudioBrandPending(values))
    } else {
      dispatch(updateAudioBrandPending(values, id))
      if (selectedFiles.length > 0) {
        dispatch(uploadAudioBrandFileLoading(selectedFiles[0], id))
      }
    }
    setIsSubmitting(true)
  };

  // const removeIndex = (i: number) => {
  //   setselectedFiles((prevFiles: any) => {
  //     const newFiles = [...prevFiles];
  //     newFiles.splice(i, 1);
  //     return newFiles;
  //   });
  // }

  //unmount
  useEffect(() => {
    dispatch(getVehicleBrandNameListLoading())
    return () => {
      dispatch(resetAudioBrandGlobalState())
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getAudioBrandImageLoading(id))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (message != "" && message != undefined) {
      showToast(message, { type: "success" })
      if (message != brandImageDeleteSuccess) {
        dispatch(resetAudioBrandGlobalState())
        setTimeout(() => {
          setIsSubmitting(false)
          navigate(ROUTER.AUDIO_BRANDS_GRID)
        }, 500)
      }
    }
    if (error) {
      setIsSubmitting(false)
      setVisible(true)
    }

  }, [error, message])

  // useEffect(() => {
  //   if (message != "" && message != undefined) {
  //     showToast(message, { type: "success" })
  //     dispatch(resetAudioBrandGlobalState())
  //     setTimeout(() => {
  //       setIsSubmitting(false)
  //       navigate(ROUTER.AUDIO_BRANDS_GRID)
  //     }, 500)
  //   }
  // }, [message])

  useEffect(() => {
    if (id) {
      dispatch(getByIdAudioBrandPending(id))
    }
  }, [id])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const handleReset = (resetForm: any) => {
    if (id) {
      navigate(ROUTER.AUDIO_BRANDS_GRID)
    } else {
      resetForm();
    }
  }

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetAudioBrandGlobalState())
  }
  const dialogContainer = (error: any) => {
    return (
      <>
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => {
          setVisible(false);
          dispatch(resetAudioBrandGlobalState())
        }}>
          <div className="m-0 validation-text">
            {error?.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>)
            })}
          </div>
        </Dialog> */}
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />

      </>
    )
  }
  function handleAcceptedFiles(files: any) {
    let filesModified = files.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      })
    }
    );

    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" })
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" })
      setselectedFiles([]);
    }

  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const removeIndex = () => {
    setDeleteModal(false);
    if (audioBrandImage) {
      dispatch(deleteAudioBrandImageLoading(id))
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };

  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeIndex()
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {visible && dialogContainer(error)}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Audio Brands" titlePath={ROUTER.AUDIO_BRANDS_GRID} breadcrumbItem={id ? "Edit Audio Brand" : "New Audio Brand"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Brand name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="brandName"
                                onBlur={handleBlur}
                                value={values.brandName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["brandName"] && errors["brandName"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Description"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="description"
                                onBlur={handleBlur}
                                value={values.description}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["description"] && errors["description"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Job prefix"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="jobprefix"
                                onBlur={handleBlur}
                                value={values.jobprefix}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["jobprefix"] && errors["jobprefix"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <InputCom labelName="Serviced count"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="servicedcount"
                                onBlur={handleBlur}
                                value={values.servicedcount}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["servicedcount"] && errors["servicedcount"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} className="mb-2">
                              <DropdownInput
                                options={vehicleBrand}
                                name="vehicleBrandId"
                                label="Vehicle brand"
                                onChange={handleChange}
                                required={true}
                                value={values.vehicleBrandId}
                                onBlur={handleBlur}
                                invalid={
                                  touched["vehicleBrandId"] && errors["vehicleBrandId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                          </Row>
                          {/* <React.Fragment>
                            <div className='mt-4'>
                              <h5>Logo Image (Max size of file: 5 MB)</h5>
                              <hr />
                            </div>
                          </React.Fragment> */}
                          {/* <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Dropzone
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles);
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick mt-2"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                          </div>
                                          <h4>Please Upload Logo Here!</h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div className="dropzone-previews mt-3" id="file-previews">
                                    {selectedFiles.map((f: any, i: number) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col className='col-auto'>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{f.formattedSize}</strong>
                                                </p>
                                              </Col>
                                              <Col style={{ display: "flex", justifyContent: "end" }}>
                                              <TooltipMEL title="Remove This Picture" position='bottom'>
                                                <div>
                                                  <FeatherIcon onClick={() => removeIndex(i)} icon="x-square" />
                                                </div>
                                                </TooltipMEL>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row> */}
                          {/* <Button
                              className="btn-secondary mt-2"
                              label={id ? "Update Audio Brand" : "Create Audio Brand"}
                              type="submit"
                            /> */}
                          {id && <React.Fragment>
                            <div className="mt-1">
                              <h5>Image</h5>
                              <hr />
                              <Row>
                                <Col sm={12} lg={12} md={12} xs={12}>
                                  <div>
                                    {(selectedFiles.length == 0 && !audioBrandImage) && <Dropzone
                                      onDrop={acceptedFiles => {
                                        handleAcceptedFiles(acceptedFiles);
                                      }}
                                      onError={(err: Error) => { console.log("file error", err) }}
                                      onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                      maxFiles={1}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} accept="image/*" />
                                            <div className="">
                                              <i className="display-4 text-muted bx bx-cloud-upload" />
                                            </div>
                                            <h4>Drop files here or click to upload.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>}

                                    {(selectedFiles[0]?.preview || audioBrandImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : audioBrandImage} removeIndex={deleteConfirmationHandler} />}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>}

                          <div className="d-flex justify-content-end mt-2">
                            {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'> */}
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            {/* </TooltipMEL> */}
                            {/* <TooltipMEL title={id ? "Update Audio Brand" : "Create Audio Brand"} position='top'> */}
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Audio Brand" : "Create Audio Brand"}
                              type="submit"
                            />
                            {/* </TooltipMEL> */}
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

export default EditAudioBrands;