import React from 'react';
import { CSSModule } from '../../util/InputCssModuleutils';

export type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'switch'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'value'
  > {
  type?: InputType;
  bsSize?: 'lg' | 'sm';
  valid?: boolean;
  invalid?: boolean;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  plaintext?: boolean;
  addon?: boolean;
  cssModule?: CSSModule;
  value?: string | any;
  rowHeight?:number;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    type,
    rowHeight,
    bsSize,
    valid,
    invalid,
    tag: Tag = 'input',
    innerRef,
    plaintext,
    addon,
    cssModule,
    max,
    ...rest
  } = props;

  const isTextarea = type === 'textarea';

  const classes = [
    'form-control',
    bsSize ? `form-control-${bsSize}` : '',
    plaintext ? 'plaintext' : '',
    addon ? '-addon' : '',
    valid ? 'is-valid' : '',
    invalid ? 'is-invalid' : '',
  ].filter(Boolean).join(' ');

  return isTextarea ? (
    <textarea
    rows={rowHeight}
    style={rowHeight ? { resize: "none" }:{resize: "vertical"}}
      {...(rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
      className={classes}
      ref={innerRef as React.Ref<HTMLTextAreaElement>}
    />
  ) : (
    <Tag
      type={type}
      className={classes}
      max={max}
      ref={innerRef as React.Ref<HTMLInputElement>}
      {...rest}
    />
  );
};

export default Input;
