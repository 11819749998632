import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { StockValueBySupplierTypes } from "./actionTypes";
import { API } from "src/util/api";
import { getStockValueBySupplierError, getStockValueBySupplierSuccess, getSupplierDropdownError, getSupplierDropdownSuccess } from "./action";



//worker function
function* getStockValueBySupplierListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getStockValuebySupplierAPI, {...action.lazyState})
    yield put(getStockValueBySupplierSuccess(response));
  } catch (error) {
    yield put(getStockValueBySupplierError(error));
  }
}
function* getSupplierDropdownAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getSupplierListFromMasterManagement, '')
    yield put(getSupplierDropdownSuccess(response));
  } catch (error) {
    yield put(getSupplierDropdownError(error));
  }
}


//watcher function

function* StockValueBySupplierSaga() {
  yield takeLatest(StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_LOADING, getStockValueBySupplierListAsync);
  yield takeLatest(StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_LOADING, getSupplierDropdownAsync);
}


export default StockValueBySupplierSaga