//Import Icons
import FeatherIcon from "feather-icons-react";
import React, { MouseEventHandler, PropsWithChildren, useEffect, useRef, useState } from "react";
import './column.scss'
import { ColumnActionProbs } from "src/models/components/columnActionModel";
import { SpeedDial } from 'primereact/speeddial';
import { MenuItem } from "primereact/menuitem";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { motion, AnimatePresence } from 'framer-motion';
import JobTooltipMEL from "src/components/UI/JobTooltip";

const ACTION_EDIT = "EDIT";
const ACTION_DELETE = "DELETE";
const ACTION_VIEW = "VIEW";
const ACTION_DEALER_SUB = "DealerSubUser";
const ACTION_FRANCHISE_SUB = "FranchiseSubUser";
const PAUSE = "PAUSE";
const ACTION_UNDO = "UndoAction";
const ADDITIONAL_EXPENSE = "additional expense"
const APPROVE = "approve"
const REJECT = "reject"
const REOPEN = "reopen"

export default function ColumnActionMarketing({
  onViewClick,
  onEditClick,
  onDeleteClick,
  onDealersubuserClick,
  onFranchiseSubuserClick,
  additionalExpenseClick,
  onPauseClick,
  approveClick,
  rejectClick,
  reopenClick,
  isEdit,
  isView,
  isDelete,
  isPause,
  undo,
  dealerSubUser,
  franchiseSubUser,
  additionalExpense,
  isApprove,
  isReject,
  isReopen,
  displayData = "Action",
  children
}: PropsWithChildren<ColumnActionProbs>) {

  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const clickWrapperFunction = (event: any, title: string) => {
    event.preventDefault();
    switch (title) {
      case ACTION_EDIT:
        isEdit && onEditClick?.();
        break;
      case ACTION_DELETE:
        isDelete && onDeleteClick?.();
        break;
      case ACTION_VIEW:
        isView && onViewClick?.();
        break;
      case ACTION_DEALER_SUB:
        dealerSubUser && onDealersubuserClick?.();
        break;
      case ADDITIONAL_EXPENSE:
        additionalExpense && additionalExpenseClick?.();
        break;
      case ACTION_FRANCHISE_SUB:
        franchiseSubUser && onFranchiseSubuserClick?.();
        break;
      case PAUSE:
        isPause && onPauseClick?.();
        break;
      case ACTION_UNDO:
        undo && onDeleteClick?.()
      break;
      case APPROVE:
        isApprove && approveClick?.();
        break;
      case REJECT:
        isReject && rejectClick?.();
        break;
      case REOPEN:
        isReopen && reopenClick?.();
        break;
    }
  };

  const items: MenuItem[] = [
    {
      label: 'Add',
      icon: 'pi pi-pencil',
      command: () => {
      }
    },
    {
      label: 'Update',
      icon: 'pi pi-refresh',
      command: () => {
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
      }
    },

  ];

  const onClickDataHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setIsOptionSelected(prevState => !prevState);
  };
  const onDataViewClickHandler = () => {
    setIsOptionSelected(true);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOptionSelected(false); // Clicked outside, set isOptionSelected to false
      }
    };

    document.addEventListener('mouseenter', handleClickOutside);
    return () => {
      document.removeEventListener('mouseenter', handleClickOutside);
    };
  }, []);

  const onDataLeaveHandler = () => {
    setIsOptionSelected(false);
  };


  return (
    <React.Fragment>
      <div id="column-action-marketing">
        <div ref={containerRef} onMouseEnter={onDataViewClickHandler}
          onMouseLeave={onDataLeaveHandler} className="columnActionContainer">
          <div onClick={(event) => {
            if (isView) {
              clickWrapperFunction(event, ACTION_VIEW);
            }
          }} className={`display-data-view ${isView ? "display-action-data" : ""}  ${isOptionSelected ? "min-size" : "max-size"}`} >{displayData}</div>
          <div className="flex-grow-1"></div>
          <AnimatePresence>
            {isOptionSelected && <motion.div className="d-flex" initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1 }} key="uniqueKey">

              {isApprove && <JobTooltipMEL title="Approve" position="right"><i className="far fa-check-circle text-approve option-icons special" style={{marginTop:"5px"}} onClick={(event) => clickWrapperFunction(event, APPROVE)}></i></JobTooltipMEL>}
              {isReject && <JobTooltipMEL title="Reject" position="left"><i className="far fa-ban text-reject option-icons special" style={{marginTop:"5px"}} onClick={(event) => clickWrapperFunction(event, REJECT)}></i></JobTooltipMEL>}
              {isView && <i className="mdi mdi-arrow-expand option-icons" onClick={(event) => clickWrapperFunction(event, ACTION_VIEW)}></i> }
              {isEdit && <i className="bx bx-pencil option-icons" onClick={(event) => clickWrapperFunction(event, ACTION_EDIT)}></i>}
              {isDelete && <i className="far fa-trash-alt option-icons" onClick={(event) => clickWrapperFunction(event, ACTION_DELETE)}></i>}
              {isPause && <i className="far fa-ban option-icons special" onClick={(event) => clickWrapperFunction(event, PAUSE)}></i>}
              {isReopen && <i className="far fa-undo-alt option-icons special" onClick={(event) => clickWrapperFunction(event, REOPEN)}></i>}
              {dealerSubUser && <i className="fa fa-users option-icons special" onClick={(event) => clickWrapperFunction(event, ACTION_DEALER_SUB)}></i>
              }
              {additionalExpense && <i className="far fa-rupee-sign option-icons special" onClick={(event) => clickWrapperFunction(event, ADDITIONAL_EXPENSE)}></i>
              }

              {franchiseSubUser && <i className="fa fa-users option-icons special" onClick={(event) => clickWrapperFunction(event, ACTION_FRANCHISE_SUB)}></i>
              }
              {undo && <i className="fa fa-undo option-icons special" onClick={(event) => clickWrapperFunction(event, ACTION_UNDO)}></i>
              }


            </motion.div>
            }
          </AnimatePresence>
          <div className="ml-1" ><i className={`fas fa-ellipsis-v options-btn-icon ${isOptionSelected ? 'active' : ''}`}></i></div>
        </div>
      </div>
    </React.Fragment>
  );
}
