import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { RootReducerState } from "src/store/reducers";
import { CurrencyFormatter, formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import {
  PartsInboundReset,
  deletePartsInboundFromList,
  getPartsInboundList,
  getPurchaseOrderDropdown,
} from "src/store/partsInbound/action";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";



export default function PartsInbound() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.PartsInboundReducer.PartsInboundList) || [];


  const partsListConverson = (values: any) => {

    const modifeidValue = values?.map((eachValue: any) => {
      let data: any = {};
      data['id'] = eachValue.id;
      data['receivedDate'] = eachValue?.receivedDate;
      data['shippingDocumentDate'] = eachValue?.shippingDocumentDate;
      data['invoiceNumber'] = eachValue?.invoiceNumber;
      data['airwayBillNumber'] = eachValue?.airwayBillNumber;
      data['courierName'] = eachValue?.courierName;
      data['buyingCurrency'] = eachValue?.buyingCurrency;
      data['currencyConversionRate'] = eachValue?.currencyConversionRate;
      data['purchaseOrderId'] = eachValue?.purchaseOrder;
      data['purchaseorder']=eachValue?.purchaseOrderId;
      data['noOfItems'] = eachValue?.noOfItems;
      data['isDeleted'] = eachValue?.isDeleted;
      data["labelGeneratedStatus"] = eachValue?.labelGeneratedStatus;
      data["labelGeneratedState"] = eachValue?.labelGeneratedState;
      data['totalPrice']=eachValue?.totalPrice;
      return data;
    });
    return modifeidValue;

  }
  const modifiedValues: any = partsListConverson(values);
  const { showToast } = useToast();
  const { error, loading, message, paginatorCount } = useSelector((state: RootReducerState) => state.PartsInboundReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)
  const purchaseOrder = useSelector((state: RootReducerState) => state.PartsInboundReducer.purchaseOrderList);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<parts | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const filterDetails: DataTableFilterMeta = {
    receivedDate: { value: "", matchMode: "contains" },
    ReceivedStartDate: { value: "", matchMode: "contains" },
    ReceivedEndDate: { value: "", matchMode: "contains" },
    shippingDocumentDate: { value: "", matchMode: "contains" },
    ShippingDocumentStartDate: { value: "", matchMode: "contains" },
    ShippingDocumentEndDate: { value: "", matchMode: "contains" },
    invoiceNumber: { value: "", matchMode: "contains" },
    airwayBillNumber: { value: "", matchMode: "contains" },
    purchaseOrderId: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "",
    // sortOrder: 0,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  useEffect(() => {
    dispatch(getPurchaseOrderDropdown());
  }, [])

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.PARTS_INBOUND_CREATE);
  };

  const viewHandler = (rowData: parts) => {
    navigate(`${ROUTER.PARTS_INBOUND_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: parts) => {
    navigate(`${ROUTER.PARTS_INBOUND_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: parts) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    const data = formatDateMEL(rowData.receivedDate)
    return (
      <>{rowData.isDeleted == false ?
        (<ColumnAction
          displayData={data}
          // isEdit={true}
          isEdit={(rowData?.labelGeneratedState == "Pending" || rowData?.labelGeneratedState == "Partial") ? true : false}
          isDelete={(rowData?.labelGeneratedState == "Pending") ? true : false}
          isView={true}
          onViewClick={() => viewHandler(rowData)}
          onEditClick={() => editHandler(rowData)}
          onDeleteClick={() => deleteHandler(rowData)}
        />)
        :
        (
          <ColumnAction
            displayData={data}
            undo={rowData.isDeleted ? true : false}
            onDeleteClick={() => deleteHandler(rowData)}

          />
        )
      }

      </>
    );
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        dispatch(PartsInboundReset());
        dispatch(getPartsInboundList(lazyState));
      }, 500);
    }
    return () => {
      dispatch(PartsInboundReset());
    }
  }, [error, message]);

	const hidePopup = () => {
		setVisible(false);
	};

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };


  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const currencyBodyTemplate = (rowData: parts, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };

  // const formatDateMEL = (inputDate: any): string => {
  //   if (!inputDate || inputDate == null) {
  //     return ""
  //   }
  //   const date = new Date(inputDate);
  //   const formattedDate = date.toLocaleDateString('en-US', {
  //     day: 'numeric',
  //     month: 'long',
  //     year: 'numeric',
  //   });

  //   const [month, day, year] = formattedDate.split(' ');

  //   const formatDay = day.slice(0, -1);
  //   const result = `${formatDay}  ${month.charAt(0).toUpperCase() + month.slice(1)}, ${year}`;

  //   return result;
  // };


  //custom date filter in table header

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate || ""} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['startDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate || ""} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options
        let modifiedDate: any = options.value || { startDate: "", endDate: "" }
        modifiedDate['endDate'] = e.target.value
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>)
  };

  const PurchaseOrderFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(purchaseOrder?.length > 0) ? (purchaseOrder?.map((eachStatus: any) => {
          let data: any = {};
          data['label'] = eachStatus?.poNumber;
          data['value'] = eachStatus?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        virtualScrollerOptions={{ itemSize: 35 }}
        value={options.value}
      />
    </>)
  };

  const PurchaseOrderTemplate = (rowData: any, field: string) => {
    return (
      <Link to={`${ROUTER.PURCHASE_ORDER_VIEW}/${rowData['purchaseorder']}`}>{rowData[field]}</Link>
    );
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      frozen : true,
    },
    {
      field: "receivedDate",
      sortable: true,
      header: "Received Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "receivedDate"),
      frozen : true,
      filterElement: dateFilterTemplate,
      isDefault: true,
      isStatic: true,
    },
    {
      field: "shippingDocumentDate",
      sortable: true,
      header: "Shipping Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.shippingDocumentDate),
      filterElement: dateFilterTemplate,
      isDefault: true,
      isStatic: false,
    },
    {
      field: "invoiceNumber",
      sortable: true,
      header: "Invoice Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "airwayBillNumber",
      sortable: true,
      header: "Airway Bill Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "courierName",
      sortable: true,
      header: "Courier Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "buyingCurrency",
      sortable: true,
      header: "Buying Currency",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "currencyConversionRate",
      sortable: true,
      header: "CCR",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      isDefault: true,
      isStatic: false,
      className: "align-right",
      tooltip: true,
      tooltipTitle: "Currency Conversion Rate"
    },
    {
      field: "purchaseOrderId",
      sortable: true,
      header: "Purchase Order",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      body:(rowData)=>PurchaseOrderTemplate(rowData,'purchaseOrderId'),
      isStatic: false,
      filterElement: PurchaseOrderFilterTemplate
    },
    {
      field: "labelGeneratedState",
      sortable: false,
      header: "Label Generated State",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "noOfItems",
      sortable: false,
      header: "Items",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "align-right",
      tooltip: true,
      tooltipTitle: "No Of Items"
    },
    {
      field: "totalPrice",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "align-right",
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['Received Date'] = formatDateMEL(eachValue?.receivedDate);
      data['Shipping Document Date'] = formatDateMEL(eachValue?.shippingDocumentDate);
      data['Invoice Number'] = eachValue?.invoiceNumber;
      data['Airway Bill Number'] = eachValue?.airwayBillNumber;
      data['Courier Name'] = eachValue?.courierName;
      data['Buying Currency'] = eachValue?.buyingCurrency;
      data['Currency Conversion Rate'] = eachValue?.currencyConversionRate;
      data['Purchase Order Id'] = eachValue?.purchaseOrderId;
      data['Label Generated State'] = eachValue?.labelGeneratedState;
      data['No Of Items'] = eachValue?.noOfItems;
      data['Total Price']=eachValue?.totalPrice;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        receivedDate: formatDateMEL(data?.receivedDate),
        shippingDocumentDate: formatDateMEL(data?.shippingDocumentDate),
        invoiceNumber: data?.invoiceNumber,
        airwayBillNumber: data?.airwayBillNumber,
        courierName: data?.courierName,
        buyingCurrency: data?.buyingCurrency,
        currencyConversionRate: data?.currencyConversionRate,
        purchaseOrderId: data?.purchaseOrderId,
        labelGeneratedState: data?.labelGeneratedState,
        noOfItems: data?.noOfItems,
        totalPrice:data?.totalPrice,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    }    
    
    try {
      setPdfLoading(true)
      const response = await API.getPartsInboundListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let resp=await partsListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(resp, columns, "parts_inbound");
     }else{
      saveDataAsExcel(resp, "parts_inbound");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "parts_inbound");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "parts_inbound");
    fetchData(true)
  };
  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  }, [purchaseOrder])
  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.receivedDate) {
      modifiedLazyState.filters.receivedDate.value = "";
    }
    if (modifiedLazyState.filters && modifiedLazyState.filters.shippingDocumentDate) {
      modifiedLazyState.filters.shippingDocumentDate.value = "";
    }
    dispatch(getPartsInboundList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    let pending=value.filter((item:any)=>item?.labelGeneratedState == "Pending")
    setSelectedParts(pending);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(pending.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      let pending=modifiedValues.filter((item:any)=>item?.labelGeneratedState == "Pending")
      setSelectedParts(pending);
      setSelectAll(pending.length === paginatorCount);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };


  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['ReceivedStartDate']['value'] = event?.filters?.receivedDate?.value?.startDate ? event?.filters?.receivedDate?.value?.startDate : ''
    event['filters']['ReceivedEndDate']['value'] = event?.filters?.receivedDate?.value?.endDate ? event?.filters?.receivedDate?.value?.endDate : ''
    event['filters']['ShippingDocumentStartDate']['value'] = event?.filters?.shippingDocumentDate?.value?.startDate ? event?.filters?.shippingDocumentDate?.value?.startDate : ''
    event['filters']['ShippingDocumentEndDate']['value'] = event?.filters?.shippingDocumentDate?.value?.startDate ? event?.filters?.shippingDocumentDate?.value?.endDate : ''
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };



  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedParts([]);
    callbackFunction(lazyState);
  }, [lazyState]);



  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.isDeleted == true ? true : rowData[0]?.isDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deletePartsInboundFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      // showToast("Parts Inbound Deleted Successfully", { type: "success" });
      // setTimeout(() => {
      //   dispatch(getPartsInboundList(lazyState));
      // }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      let pending=modifiedValues.filter((item:any)=>item?.labelGeneratedState == "Pending")
      setSelectedParts(pending);
      setSelectAll(pending.length === paginatorCount);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>Parts Inbound List</div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Parts"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedParts}
          bulkActionSatusTitle="Parts"
          showBorderRight={true}
        />  */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedParts.length == 0 && selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 delete-space " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedParts.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedParts.length > 0 && <BulkActionStatus count={selectedParts.length} title="Parts Inbound"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>

        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="mr-1" />
        </Button>
        {/* <TooltipMEL title="Add Parts" position="bottom"> */}
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Parts Inbound
        </Button>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;


useEffect(() => {
  const preloader: any = document.getElementById("preloader");
  if (loading || pdfLoading ) {
    preloader.style.display = "block";
  } else {
    preloader.style.display = "none";
  }
}, [loading, pdfLoading]);




  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No Parts Inbound found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Part Inbound  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
