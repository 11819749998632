import { FranchiseAddtionalDetails } from "./actionTypes"


//get current franchise id

export const getCurrentFranchiseIdLoading = () => {
    return {
        type: FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_LOADING
    }
}

export const getCurrentFranchiseIdSuccess = (resp: any) => {
    return {
        type: FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_SUCCESS,
        payload: resp
    }
}

export const getCurrentFranchiseIdError = (error: any) => {
    return {
        type: FranchiseAddtionalDetails.GET_CURRENT_FRANCHISE_ID_ERROR,
        payload: error
    }
}


//get additional details
export const getAdditionalDetailsLoading = (id: any) => {
    return {
        type: FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_LOADING,
        payload: id
    }
}
export const getAdditionalDetailsSuccess = (resp: any) => {
    return {
        type: FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_SUCCESS,
        payload: resp
    }
}
export const getAdditionalDetailsError = (error: any) => {
    return {
        type: FranchiseAddtionalDetails.GET_FRANCHISE_ADDITIONAL_DETAILS_ERROR,
        payload: error
    }
}

export const resetAdditionalDetails = () => {
    return {
        type: FranchiseAddtionalDetails.GET_ADDITIONAL_DETAILS_RESET
    }
}