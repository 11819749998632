import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import 'bootstrap/dist/css/bootstrap.min.css'
import { RootReducerState } from "src/store/reducers";
interface propTopDealer {
  date?: any
  realDate?: any
}
const TopDealerJobsheetStatusTreeChart = ({ date, realDate }: propTopDealer) => {

  const { dealerList } = useSelector((state: RootReducerState) => state.dashboard);
  const dispatch = useDispatch();
  const [state, setState] = useState<any>("ALL");

  const onChangehandle = (data: any) => {
    setState(data);
    // dispatch(getWalletBalance(data));
  };

  const walletData = createSelector(

    (state: any) => state.dashboard,
    (state) => ({
      WallentBalanceData: state.WallentBalanceData,
    })
  );
  // Inside your component
  // const { WallentBalanceData } = useSelector(walletData);

  // useEffect(() => {
  //   dispatch(getWalletBalance(state));
  // }, [state]);

  // useEffect(() => {
  //   dispatch(getWalletBalance(state));
  // }, [dispatch]);

  //   const piechartColors =  ["#5885AF","#05445E","#AF5E58","#5EAF58","#5E58AF","#AF585E","#5858AF","#AF5E5E","#5EAF5E","#5E5EAF","#AF8E58","#AF58AF"];
  const piechartColors = ["#5885AF", "#05445E", "#41729F", "#274472", "#C3E0E5", "#5885AF", "#05445E", "#41729F", "#274472", "#C3E0E5", "#5885AF", "#05445E", "#41729F"];
  const dataSeries = [{
    data: dealerList?.map((item: any) => item.count).sort((a, b) => b - a),
    xaxis: {
      categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
        'United States', 'China', 'Germany'
      ],
      labels: {
        style: {
          colors: '#ff0000' // Red color for x-axis labels
        },
        fontSize: "16px"
      }
    }
  }]
  var options: object = {
    series: [{
      data: dealerList?.map((item: any) => item.count)
    }],
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      title: {
        text: 'Job sheet', // Group name for the y-axis
        style: {
          color: '#ff0000' // Red color for the group name
        }
      },
      categories: dealerList.sort((a, b) => {
        if (a.count === b.count) {
          return a.dealerName.localeCompare(b.dealerName);
        }
        return b.count - a.count;
      }).map((item)=>item.dealerName),
      labels: {
        style: {
          colors: '#ff0000' // Red color for x-axis labels
        },
        fontSize: "14px"
      }
    },

  };



  const series: any = [44, 55, 13, 43, 22, 44, 55, 13, 43, 22, 15, 40]
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Top Dealers</h5>
              <div className="ms-auto">
                <div>
                  <b>{date}</b>
                  {false && <>
                    <button
                      type="button"
                      className="btn btn-soft-primary btn-sm"
                      onClick={() => onChangehandle("ALL")}
                    >
                      ALL
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                      onClick={() => onChangehandle("1M")}
                    >
                      1M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                      onClick={() => onChangehandle("6M")}
                    >
                      6M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                      onClick={() => onChangehandle("1Y")}
                    >
                      1Y
                    </button>
                  </>}
                </div>
              </div>
            </div>

            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <Row className="align-items-center">
                    <Col lg={12}>
                      <div style={{width:"100%",height:"370px"}}>
                        {dealerList?.length > 0 ?  <ReactApexChart options={options} series={dataSeries} type="bar" width={"100%"} height={"100%"}/> : <div>No data found</div> }
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TopDealerJobsheetStatusTreeChart;
