
import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IntentPost } from "src/models/pages/intentModel";
import { API } from "src/util/api";
import { approveIntentError, approveIntentSuccess, createIntentError, createIntentSuccess, deleteIntentListError, deleteIntentListSuccess, dispatchIntentError, dispatchIntentSuccess, emailIntendError, emailIntendSuccess, franchiseListforIntentError, franchiseListforIntentSuccess, getIntentCourierListDropdownError, getIntentCourierListDropdownSuccess, getIntentDeliverablePersonDropdownError, getIntentDeliverablePersonDropdownSuccess, getIntentDetailError, getIntentDetailSuccess, getIntentListError, getIntentListSuccess, partSkuValidateError, partSkuValidateSuccess, partsAutoCompleteForIntentListError, partsAutoCompleteForIntentListSuccess, postDeliverableCourierIntentError, postDeliverableCourierIntentSuccess, printIntendError, printIntendSuccess, receiveIntentError, receiveIntentSuccess, rejectIntentError, rejectIntentSuccess, updateIntentError, updateIntentSuccess } from "./action";
import { IntentActionTypes } from "./actionTypes";

function* createIntent({ payload }: any) {
  try {
    const response: IntentPost = yield call(API.createIntent, payload);
    yield put(createIntentSuccess(response));

  } catch (error) {
    yield put(createIntentError(error));
  }
}

function* updateIntent({
  payload: { intent, id },
}: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updateIntent, intent, id);
    yield put(updateIntentSuccess(data));
  } catch (error) {
    yield put(updateIntentError(error));
  }
}



function* fetchIntent({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getIntentDetails, payload);
    yield put(getIntentDetailSuccess(data));
  } catch (error) {
    yield put(getIntentDetailError(error));
  }
}

function* getIntentListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getIntentListAPI, {
      ...action.lazyState,
    });
    yield put(getIntentListSuccess(response));
  } catch (error) {
    yield put(getIntentListError(error));
  }
}

function* deleteIntentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteIntentListAPI, {
      id: action.payload.id, undo: action.payload.undo
    });
    yield put(deleteIntentListSuccess(response));
  } catch (error) {
    yield put(deleteIntentListError(error));
  }
}



//getCustomer list
function* getIntentFranchiseListAsync() {
  try {
    const response: Promise<any> = yield call(API.getIntentFranchiseFromMasterManagement)
    yield put(franchiseListforIntentSuccess(response));
  } catch (error) {
    yield put(franchiseListforIntentError(error));
  }

}


function* getPartsAutocompleteListAsync(action: any) {
  console.log("ACTION PARTS ---> ", action)
  try {
    const response: Promise<any> = yield call(
      API.getPartsForIntentAutoCompleteAPI, action.params
    )
    yield put(partsAutoCompleteForIntentListSuccess(response));
  } catch (error) {
    yield put(partsAutoCompleteForIntentListError(error));
  }
}

function* approveIntent(action: any) {
  console.log("ACTION PARTS ---> ", action)
  try {
    const response: Promise<any> = yield call(
      API.approveIntent, action.params.id, action.params.approvedNote
    )
    yield put(approveIntentSuccess(response));
  } catch (error) {
    yield put(approveIntentError(error));
  }
}

function* rejectIntent(action: any) {
  console.log("ACTION PARTS ---> ", action)
  try {
    const response: Promise<any> = yield call(
      API.rejectIntent, action.params.id, action.params.rejectedNote
    )
    yield put(rejectIntentSuccess(response));
  } catch (error) {
    yield put(rejectIntentError(error));
  }
}

function* dispatchIntent(action: any) {
  console.log("ACTION PARTS ---> ", action)
  try {
    const response: Promise<any> = yield call(
      API.dispatchIntent, action.params
    )
    yield put(dispatchIntentSuccess(response));
  } catch (error) {
    yield put(dispatchIntentError(error));
  }
}

function* receiveIntent(action: any) {
  console.log("ACTION PARTS ---> ", action)
  try {
    const response: Promise<any> = yield call(
      API.receivedIntent, action.params
    )
    yield put(receiveIntentSuccess(response));
  } catch (error) {
    yield put(receiveIntentError(error));
  }
}

//=========== courier list ===========//
function* getIntentCourierAsync() {
  try {
    const response: Promise<any> = yield call(API.getIntentCourierListApi);
    yield put(getIntentCourierListDropdownSuccess(response))
  } catch (error) {
    yield put(getIntentCourierListDropdownError(error))
  }
}


// ========== getIntentDeliverablePersonAsync ========== //

function* getIntentDeliverablePersonAsync() {
  try {
    const response: Promise<any> = yield call(API.getIntentDeliverablePersonApi);
    yield put(getIntentDeliverablePersonDropdownSuccess(response))
  } catch (error) {
    yield put(getIntentDeliverablePersonDropdownError(error))
  }
}


//  ============ pending dispatch change to courier received ========== /

function* postCourierIntentAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.postCourierIntentApi, action.payload, action.id);
    yield put(postDeliverableCourierIntentSuccess(response))
  } catch (error) {
    yield put(postDeliverableCourierIntentError(error))
  }
}

function* partSkuValidateAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.partSkuValidateApi, action.payload);
    yield put(partSkuValidateSuccess(response))
  } catch (error) {
    yield put(partSkuValidateError(error))
  }
}

function* printIntendAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.printIntendApi, action.payload);
    yield put(printIntendSuccess(response))
  } catch (error) {
    yield put(printIntendError(error))
  }
}

function* emailIntendAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.emailIntendApi, action.payload);
    yield put(emailIntendSuccess(response))
  } catch (error) {
    yield put(emailIntendError(error))
  }
}


function* IntentSaga() {
  yield takeEvery(IntentActionTypes.GET_INTENT_BY_ID_LOADING, fetchIntent);
  yield takeLatest(IntentActionTypes.CREATE_INTENT_LOADING, createIntent);
  yield takeEvery(IntentActionTypes.UPDATE_INTENT_LOADING, updateIntent);
  yield takeLatest(IntentActionTypes.GET_INTENT_LIST_LOADING, getIntentListAsync);
  yield takeLatest(IntentActionTypes.DELETE_INTENT_LIST_LOADING, deleteIntentAsync);
  yield takeLatest(IntentActionTypes.GET_FRANCHISE_LIST_FOR_INTENT_LOADING, getIntentFranchiseListAsync);
  yield takeLatest(IntentActionTypes.GET_PART_LIST_FOR_INTENT_LOADING, getPartsAutocompleteListAsync);
  yield takeLatest(IntentActionTypes.APPROVE_INTENT_LOADING, approveIntent);
  yield takeLatest(IntentActionTypes.REJECT_INTENT_LOADING, rejectIntent);
  yield takeLatest(IntentActionTypes.DISPATCH_INTENT_LOADING, dispatchIntent);
  yield takeLatest(IntentActionTypes.RECEIVE_INTENT_LOADING, receiveIntent);
  yield takeLatest(IntentActionTypes.GET_INTENT_COURIER_LIST_LOADING, getIntentCourierAsync);
  yield takeLatest(IntentActionTypes.GET_INTENT_DELIVERABLE_USER_LIST_LOADING, getIntentDeliverablePersonAsync);
  yield takeLatest(IntentActionTypes.POST_COURIER_INTENT_LOADING, postCourierIntentAsync)
  yield takeLatest(IntentActionTypes.PARTS_VALIDATE, partSkuValidateAsync)
  yield takeLatest(IntentActionTypes.PRINT_INTEND, printIntendAsync)
  yield takeLatest(IntentActionTypes.EMAIL_INTEND, emailIntendAsync)

}

export default IntentSaga;
