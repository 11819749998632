import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

interface BreadcrumbProps {
  breadcrumbItem: string;
  title?: string;
  titlePath?: string | any;
  filter?:string;
  setFilter?:any
}

const MarketingBreadcrumb = ({ breadcrumbItem, title, titlePath = "" ,filter,setFilter}: BreadcrumbProps) => {
  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 align-items-center">
          <h4 className="mb-0 font-size-18" style={{whiteSpace:"nowrap"}}>{breadcrumbItem}</h4>
          <input type="text" value={filter} onChange={(e:any)=>setFilter(e.target.value)} placeholder="Search here" className="form-control"/>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {title &&
                <>
                  <BreadcrumbItem>
                    <Link to={titlePath ? titlePath : title}>{title}</Link>
                  </BreadcrumbItem>

                  <BreadcrumbItem active>
                    {/* <Link to="#"> */}
                    {breadcrumbItem}
                    {/* </Link> */}
                  </BreadcrumbItem>
                </>
              }
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MarketingBreadcrumb;
