import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { parts } from "src/models/pages/partsModel";
import { getGstNoError, getGstNoSuccess, getInvoiceItemWiseRegistersListError, getInvoiceItemWiseRegistersListSuccess } from "./action";
import { InvoiceItemWiseRegistersTypes } from "./actionTypes";



function* getInvoiceItemWiseRegistersListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getInvoiceItemWiseRegistersListAPI,{...action.lazyState}
    )
    yield put(getInvoiceItemWiseRegistersListSuccess(response));
  } catch (error) {
    yield put(getInvoiceItemWiseRegistersListError(error));
  }
}

function* getGstNoAsync() {
  try {
    const response: Promise<any> = yield call(API.getGstNoForFilter)
    yield put(getGstNoSuccess(response));
  } catch (error) {
    yield put(getGstNoError(error));
  }
}



function* InvoiceItemWiseRegistersListSaga() {
  yield takeLatest(InvoiceItemWiseRegistersTypes.GET_INVOICE_ITEM_WISE_REGISTERS_LIST, getInvoiceItemWiseRegistersListAsync);
  yield takeLatest(InvoiceItemWiseRegistersTypes.GET_GST_NO_LIST, getGstNoAsync);
}

export default InvoiceItemWiseRegistersListSaga;