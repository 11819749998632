export enum receivedPaymentActions {
  //grid
  GET_RECEIVED_PAYMENT_LIST_LOADING = "@@receivedPayment/GET_RECEIVED_PAYMENT_LIST_LOADING",
  GET_RECEIVED_PAYMENT_LIST_SUCCESS = "@@receivedPayment/GET_RECEIVED_PAYMENT_LIST_SUCCESS",
  GET_RECEIVED_PAYMENT_LIST_ERROR = "@@receivedPayment/GET_RECEIVED_PAYMENT_LIST_ERROR",

  //create received payment
  CREATE_RECEIVED_PAYMENT_LOADING = "@@receivedPayment/CREATE_RECEIVED_PAYMENT_LOADING",
  CREATE_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/CREATE_RECEIVED_PAYMENT_SUCCESS",
  CREATE_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/CREATE_RECEIVED_PAYMENT_ERROR",

  //view received payment
  VIEW_RECEIVED_PAYMENT_LOADING = "@@receivedPayment/VIEW_RECEIVED_PAYMENT_LOADING",
  VIEW_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/VIEW_RECEIVED_PAYMENT_SUCCESS",
  VIEW_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/VIEW_RECEIVED_PAYMENT_ERROR",

  //reset
  RESET_RECEIVED_PAYMENT_STATE = "@@receivedPayment/RESET_RECEIVED_PAYMENT_STATE",

  //clear message
  CLEAR_RECEIVED_PAYMENT_STATE_MESSAGE = "@@receivedPayment/CLEAR_RECEIVED_PAYMENT_STATE_MESSAGE",



  GET_DEALER_DROP_FOR_RECEIVED_PAYMENT = "@@receivedPayment/GET_DEALER_DROP_FOR_RECEIVED_PAYMENT",
  GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS",
  GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_ERROR",


  GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT = "@@receivedPayment/GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT",
  GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS",
  GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_ERROR",

  GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT = "@@receivedPayment/GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT",
  GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_SUCCESS",
  GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_ERROR",

  GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT = "@@receivedPayment/GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT",
  GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_SUCCESS",
  GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_ERROR",
  RESET_INVOICE_INDEX_RECEIVED_PAYMENT = "@@receivedPayment/RESET_INVOICE_INDEX_RECEIVED_PAYMENT",

  DOWNLOAD_RECEIVED_PAYMENT = "@@receivedPayment/DOWNLOAD_RECEIVED_PAYMENT",
  DOWNLOAD_RECEIVED_PAYMENT_SUCCESS = "@@receivedPayment/DOWNLOAD_RECEIVED_PAYMENT_SUCCESS",
  DOWNLOAD_RECEIVED_PAYMENT_ERROR = "@@receivedPayment/DOWNLOAD_RECEIVED_PAYMENT_ERROR",

  // ============ get user details =============
  GET_USER_TYPE_DETAILS_BY_UUID_LOADING = "@@receivedPayment/GET_USER_TYPE_DETAILS_BY_UUID_LOADING",
  GET_USER_TYPE_DETAILS_BY_UUID_SUCCESS = "@@receivedPayment/GET_USER_TYPE_DETAILS_BY_UUID_SUCCESS",
  GET_USER_TYPE_DETAILS_BY_UUID_ERROR = "@@receivedPayment/GET_USER_TYPE_DETAILS_BY_UUID_ERROR",


  // =============== create received payment invoice list

  CREATE_RECEIVED_PAYMENT_INVOICE_LOADING = "@@receivedPayment/CREATE_RECEIVED_PAYMENT_INVOICE_LOADING",
  CREATE_RECEIVED_PAYMENT_INVOICE_SUCCESS = "@@receivedPayment/CREATE_RECEIVED_PAYMENT_INVOICE_SUCCESS",
  CREATE_RECEIVED_PAYMENT_INVOICE_ERROR = "@@receivedPayment/CREATE_RECEIVED_PAYMENT_INVOICE_ERROR",
 

  



}