export enum CustomerTypes {
  // Grid
  GET_CUSTOMERS_LIST = "@@customer/GET_CUSTOMERS_LIST",
  GET_CUSTOMERS_LIST_SUCCESS = "@@customer/GET_CUSTOMERS_LIST_SUCCESS",
  GET_CUSTOMERS_LIST_FAIL = "@@customer/GET_CUSTOMERS_LIST_FAIL",

  DELETE_CUSTOMERS = "@@customer/DELETE_CUSTOMERS",
  DELETE_CUSTOMERS_SUCCESS = "@@customer/DELETE_CUSTOMERS_SUCCESS",
  DELETE_CUSTOMERS_FAIL = "@@customer/DELETE_CUSTOMERS_FAIL",

  //GetById
  GET_CUSTOMERBYID_LOADING = "@@customer/GET_CUSTOMERBYID_LOADING",
  GET_CUSTOMERBYID_SUCCESS = "@@customer/GET_CUSTOMERBYID_SUCCESS",
  GET_CUSTOMERBYID_ERROR = "@@customer/GET_CUSTOMERBYID_ERROR",

  //create customer
  CREATE_CUSTOMER_LOADING = "@@customer/CREATE_CUSTOMER_LOADING",
  CREATE_CUSTOMER_SUCCESS = "@@customer/CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_ERROR = "@@customer/CREATE_CUSTOMER_ERROR",

  //update customer
  UPDATE_CUSTOMER_LOADING = "@@customer/UPDATE_CUSTOMER_LOADING",
  UPDATE_CUSTOMER_SUCCESS = "@@customer/UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_ERROR = "@@customer/UPDATE_CUSTOMER_ERROR",

  //reset customer
  RESET_CUSTOMER = "@@customer/RESET_USER",
  CLEAR_CUSTOMER_MESSAGE = "@@customer/CLEAR_CUSTOMER_MESSAGE",

  // Tax Type

  CUSTOMER_TAX_TYPE_LIST_LOADING = "@@customer/CUSTOMER_TAX_TYPE_LIST_LOADING",
  CUSTOMER_TAX_TYPE_LIST_SUCCESS = "@@customer/CUSTOMER_TAX_TYPE_LIST_SUCCESS",
  CUSTOMER_TAX_TYPE_LIST_ERROR = "@@customer/CUSTOMER_TAX_TYPE_LIST_ERROR",


  //  File upload

  CUSTOMER_FILE_UPLOAD_LOADING = "@@customer/CUSTOMER_FILE_UPLOAD_LOADING",
  CUSTOMER_FILE_UPLOAD_SUCCESS = "@@customer/CUSTOMER_FILE_UPLOAD_SUCCESS",
  CUSTOMER_FILE_UPLOAD_ERROR = "@@customer/CUSTOMER_FILE_UPLOAD_ERROR",

  CUSTOMER_FILE_FETCH_LOADING = "@@customer/CUSTOMER_FILE_FETCH_LOADING",
  CUSTOMER_FILE_FETCH_SUCCESS = "@@customer/CUSTOMER_FILE_FETCH_SUCCESS",
  CUSTOMER_FILE_FETCH_ERROR = "@@customer/CUSTOMER_FILE_FETCH_ERROR",

  CUSTOMER_FILE_DELETE_LOADING = "@@customer/CUSTOMER_FILE_DELETE_LOADING",
  CUSTOMER_FILE_DELETE_SUCCESS = "@@customer/CUSTOMER_FILE_DELETE_SUCCESS",
  CUSTOMER_FILE_DELETE_ERROR = "@@customer/CUSTOMER_FILE_DELETE_ERROR",

  CUSTOMER_FILE_CLEAR = "@@customer/CUSTOMER_FILE_CLEAR",

  CUSTOMER_REDIRECT_WITH_JOB_SHEET_LOADING = "@@customer/CUSTOMER_REDIRECT_WITH_JOB_SHEET_LOADING",
  CUSTOMER_REDIRECT_WITH_JOB_SHEET_SUCCESS = "@@customer/CUSTOMER_REDIRECT_WITH_JOB_SHEET_SUCCESS",
  CUSTOMER_REDIRECT_WITH_JOB_SHEET_ERROR = "@@customer/CUSTOMER_REDIRECT_WITH_JOB_SHEET_ERROR",

  CUSTOMER_SET_PHONE_LOADING="@@user/CUSTOMER_SET_PHONE_LOADING",
  CUSTOMER_SET_PHONE_SUCCESS="@@user/CUSTOMER_SET_PHONE_SUCCESS",
  CUSTOMER_SET_PHONE_ERROR="@@user/CUSTOMER_SET_PHONE_ERROR",

  CUSTOMER_SET_EMAIL_LOADING="@@user/CUSTOMER_SET_EMAIL_LOADING",
  CUSTOMER_SET_EMAIL_SUCCESS="@@user/CUSTOMER_SET_EMAIL_SUCCESS",
  CUSTOMER_SET_EMAIL_ERROR="@@user/CUSTOMER_SET_EMAIL_ERROR",

  SEND_OTP_CUSTOMER ="@@user/SEND_OTP_CUSTOMER",
  SEND_OTP_CUSTOMER_SUCCESS ="@@user/SEND_OTP_CUSTOMER_SUCCESS",
  SEND_OTP_CUSTOMER_ERROR ="@@user/SEND_OTP_CUSTOMER_ERROR",

  RESEND_CUSTOMER_OTP = "@@user/RESEND_CUSTOMER_OTP",
  RESEND_CUSTOMER_OTP_SUCCESS = "@@user/RESEND_CUSTOMER_OTP_SUCCESS",
  RESEND_CUSTOMER_OTP_ERROR = "@@user/RESEND_CUSTOMER_OTP_ERROR",
  
  RESET_CUSTOMER_OTP_MESSAGE = "@@user/RESET_CUSTOMER_OTP_MESSAGE",

  GET_CUSTOMER_LOCATION_LOADING="@@user/GET_CUSTOMER_LOCATION_LOADING",
  GET_CUSTOMER_LOCATION_SUCCESS="@@user/GET_CUSTOMER_LOCATION_SUCCESS",
  GET_CUSTOMER_LOCATION_ERROR="@@user/GET_CUSTOMER_LOCATION_ERROR",
}
