// useFetchDataAndCheckRoles.js
import { useState, useEffect } from 'react';

const useFetchDataAndCheckRoles = (url: any, token: any) => {
  const [hasAdminOrEmployeeRole, setHasAdminOrEmployeeRole] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const userRole = data?.["currentUser"]?.["roles"];
        const hasRole = userRole.includes("admin") || userRole.includes("Employee");
        setHasAdminOrEmployeeRole(hasRole);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url, token]);

  return hasAdminOrEmployeeRole;
};

export default useFetchDataAndCheckRoles;
