import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PartsTypes } from "./actionTypes";
import { API } from "src/util/api";
import { getAudioBrandsListForPartsError, getAudioBrandsListForPartsSuccess, deletePartsFromListError, deletePartsFromListSuccess, getPartsListError, getPartsListSuccess, createPartsSuccess, createPartsError, getPartsBYIDSuccess, getPartsBYIDError, updatePartsError, updatePartsSuccess, getViewPartsPurchaseOrderSucces, getViewPartsPurchaseOrderError, getViewPartsPartInboundDetailsSuccess, getViewPartsPartInboundDetailsError } from "./action";
import { parts } from "src/models/pages/partsModel";



function* getPartsListAsync(action: any) {

  try {
    const response: Promise<any> = yield call( API.getPartsListAPI, { ...action.lazyState } )
    yield put(getPartsListSuccess(response));
  } catch (error) {
    yield put(getPartsListError(error));
  }
}

function* deletePartsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deletePartsListAPI, { id: action.payload.id, undo: action.payload.undo }
    )
    yield put(deletePartsFromListSuccess(response, action.payload.undo))
  } catch (error) {
    yield put(deletePartsFromListError(error));
  }
}

//getCustomer list
function* getAudioBrandsListAsync() {

  try {
    const response: Promise<any> = yield call(API.getAudioBrandFromMasterManagement)
    yield put(getAudioBrandsListForPartsSuccess(response));
  } catch (error) {
    yield put(getAudioBrandsListForPartsError(error));
  }
}

// create parts
function* createPartsAsync({ payload }: any) {
  try {
    const response: parts = yield call(API.postPartsAPI, payload);
    yield put(createPartsSuccess(response));

  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createPartsError(error));
  }
}

//  update parts
function* updateParts({
  payload: { parts, partId },
}: any): Generator<any, void, any> {
  try {

    const { data } = yield call(API.updatePartsAPI, parts, partId);

    yield put(updatePartsSuccess(data));

  } catch (error) {
    yield put(updatePartsError(error));
  }
}

function* fetchParts({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getPartsByIdAPI, payload);
    yield put(getPartsBYIDSuccess(data));

  } catch (error) {
    yield put(getPartsBYIDError(error));
  }
}

function* purchaseOrderDetailsAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getViewPartspurchaseOrderAPI, action.payload.id,{ ...action.payload.lazyState });
    yield put(getViewPartsPurchaseOrderSucces(response));
  } catch (error) {
    yield put(getViewPartsPurchaseOrderError(error));
  }
}

function* partInboundDetailsAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.getViewPartsPartIntentAPI, action.payload.id,{ ...action.payload.lazyState });
    yield put(getViewPartsPartInboundDetailsSuccess(response));
  } catch (error) {
    yield put(getViewPartsPartInboundDetailsError(error));
  }
}


function* partsSaga() {
  yield takeLatest(PartsTypes.GET_PARTS_LIST, getPartsListAsync);
  yield takeLatest(PartsTypes.DELETE_PARTS, deletePartsAsync);
  yield takeLatest(PartsTypes.GET_AUDIO_BRANDS_LIST_FOR_PARTS, getAudioBrandsListAsync);
  yield takeLatest(PartsTypes.POST_PARTS, createPartsAsync);
  yield takeLatest(PartsTypes.GET_PARTS_BY_ID, fetchParts);
  yield takeLatest(PartsTypes.UPDATE_PARTS, updateParts);
  yield takeLatest(PartsTypes.PARTS_VIEW_PURCHASE_ORDER_DETAILS_LOADING, purchaseOrderDetailsAsync);
  yield takeLatest(PartsTypes.PARTS_VIEW_PARTS_INBOUND_LOADING, partInboundDetailsAsync);
}

export default partsSaga;