
import { receivedPaymentActions } from "./actionTypes";

//get received payment list

export const getReceivedPaymentListLoading = (lazyState?: any) => {
  return {
    type: receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_LOADING,
    lazyState
  };
};

export const getReceivedPaymentListSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_SUCCESS,
    payload: resp
  };
};

export const getReceivedPaymentListError = (error: any) => {
  return {
    type: receivedPaymentActions.GET_RECEIVED_PAYMENT_LIST_ERROR,
    payload: error
  };
};


//reset received payment state

export const resetReceivedPaymentState = () => {
  return {
    type: receivedPaymentActions.RESET_RECEIVED_PAYMENT_STATE
  };
};


//clear Message

export const clearReceivedPaymentMessage = () => {
  return {
    type: receivedPaymentActions.CLEAR_RECEIVED_PAYMENT_STATE_MESSAGE
  };
};


//view received payment

export const viewReceivedPaymentLoading = (id: string) => {
  return {
    type: receivedPaymentActions.VIEW_RECEIVED_PAYMENT_LOADING,
    payload: id
  };
};

export const viewReceivedPaymentSuccess = (response: any) => {
  return {
    type: receivedPaymentActions.VIEW_RECEIVED_PAYMENT_SUCCESS,
    payload: response
  };
};

export const viewReceivedPaymentError = (error: any) => {
  return {
    type: receivedPaymentActions.VIEW_RECEIVED_PAYMENT_ERROR,
    payload: error
  };
};

//create received payment 

export const createReceivedPaymentLoading = (receivedPayment: any) => {
  return {
    type: receivedPaymentActions.CREATE_RECEIVED_PAYMENT_LOADING,
    payload: receivedPayment
  };
};
export const createReceivedPaymentSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.CREATE_RECEIVED_PAYMENT_SUCCESS,
    payload: resp
  };
};
export const createReceivedPaymentError = (error: any) => {
  return {
    type: receivedPaymentActions.CREATE_RECEIVED_PAYMENT_ERROR,
    payload: error
  };
};


// GET DEALER DROP

export const getDealerReceivedPaymentLoading = () => {
  return {
    type: receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT,
  };
};
export const getDealerPaymentSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS,
    payload: resp
  };
};
export const getDealerPaymentError = (error: any) => {
  return {
    type: receivedPaymentActions.GET_DEALER_DROP_FOR_RECEIVED_PAYMENT_ERROR,
    payload: error
  };
};


//  GET CUSTOMER 

export const getCustomerReceivedPaymentLoading = () => {
  return {
    type: receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT,
  };
};
export const getCustomerReceivedPaymentSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_SUCCESS,
    payload: resp
  };
};
export const getCustomerReceivedPaymentError = (error: any) => {
  return {
    type: receivedPaymentActions.GET_CUSTOMER_DROP_FOR_RECEIVED_PAYMENT_ERROR,
    payload: error
  };
};

//  GET INVOICE

export const getInvoiceReceivedPaymentLoading = () => {
  return {
    type: receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT,
  };
};
export const getInvoiceReceivedPaymentSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_SUCCESS,
    payload: resp
  };
};
export const getInvoiceReceivedPaymentError = (error: any) => {
  return {
    type: receivedPaymentActions.GET_INVOICE_DROP_FOR_RECEIVED_PAYMENT_ERROR,
    payload: error
  };
};


//  GET INVOICE RECEIVED PAYMENT

export const getInvoiceDetailsReceivedPaymentLoading = (id: any, index: number, setFieldValue: any) => {
  return {
    type: receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT,
    payload: { id, index, setFieldValue }
  };
};

export const getInvoiceDetailsReceivedPaymentSuccess = (resp: any, index: number, setFieldValue: any) => {
  return {
    type: receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_SUCCESS,
    payload: { resp, index, setFieldValue }
  };
};
export const getInvoiceDetailsReceivedPaymentError = (error: any) => {
  return {
    type: receivedPaymentActions.GET_INVOICE_DETAILS_FOR_RECEIVED_PAYMENT_ERROR,
    payload: error
  };
};


export const resetInvoiceIndexReceivedPayment = () => {
  return {
    type: receivedPaymentActions.RESET_INVOICE_INDEX_RECEIVED_PAYMENT,
  };
};

export const downloadReceivedPayment = (id: any) => {
  return {
    type: receivedPaymentActions.DOWNLOAD_RECEIVED_PAYMENT,
    payload: id
  };
};

export const downloadReceivedPaymentSuccess = () => {
  return {
    type: receivedPaymentActions.DOWNLOAD_RECEIVED_PAYMENT_SUCCESS,
  };
};

// ======== get user details ===============

export const getUserTypeByUuidLoading = (uuid: any) => {
  return {
    type: receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_LOADING,
    payload: uuid
  };
};
export const getUserTypeByUuidSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_SUCCESS,
    payload: resp
  };
};
export const getUserTypeByUuidError = (error: any) => {
  return {
    type: receivedPaymentActions.GET_USER_TYPE_DETAILS_BY_UUID_ERROR,
    payload: error
  };
};



// ================ get invoice list omn create page ==================

export const getInvoiceListCreatePageLoading = (dealerId?: any, customerId?: any) => {
  return {
    type: receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_LOADING,
    payload: { dealerId, customerId }
  };
};

export const getInvoiceListCreatePageSuccess = (resp: any) => {
  return {
    type: receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_SUCCESS,
    payload: resp
  };
};

export const getInvoiceListCreatePageError = (error: any) => {
  return {
    type: receivedPaymentActions.CREATE_RECEIVED_PAYMENT_INVOICE_ERROR,
    payload: error
  };
};