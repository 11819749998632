import { StockValueBySupplierTypes } from './actionTypes'

//grid
export const getStockValueBySupplierLoading = (lazyState: any) => {
  return {
    type: StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_LOADING,
    lazyState,
  };
};
export const getStockValueBySupplierSuccess = (stockvalueList : any) => {
  return {
    type: StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_SUCCESS,
    payload : stockvalueList   
  };
};
export const getStockValueBySupplierError = (error : any) => {
  return {
    type: StockValueBySupplierTypes.GET_STOCK_VALUE_BY_SUPPLIER_LIST_ERROR,
    payload : error
  };
};
//grid
export const getSupplierDropdownLoading = () => {
  return {
    type: StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_LOADING,
  };
};
export const getSupplierDropdownSuccess = (stockvalueList : any) => {
  return {
    type: StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_SUCCESS,
    payload : stockvalueList   
  };
};
export const getSupplierDropdownError = (error : any) => {
  return {
    type: StockValueBySupplierTypes.GET_SUPPLIER_DROPDOWN_ERROR,
    payload : error
  };
};
