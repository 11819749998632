import { Image } from 'primereact/image'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { formatDateMEL } from 'src/helpers/common_helpers'
import { generatedocumentLinkLoading, getAdditionalDetailsLoading, getCurrentFranchiseIdLoading, getFranchiseeImageLoading, getSubuserListLoading, getuploadedeDocumentinFranchiseLoading, resetAdditionalDetails } from 'src/store/actions'
import { RootReducerState } from 'src/store/reducers';
import FeatherIcon from "feather-icons-react";
import { ROUTER } from 'src/constants/routes'
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel'
import EmailBodyTemplate from 'src/components/Common/EmailBodyTemplate/EmailBodyTemplate'
import StatusHighlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { DataTableFilterMeta } from 'primereact/datatable'


const FranchiseAdditionalInformation = () => {
  const dispatch = useDispatch();
  const { error, getCurrentUserId, loading, moreDetails } = useSelector((state: RootReducerState) => state.franchiseextraDetailsReducer)
  const { franchiseImage, getUploadedList, } = useSelector((state: RootReducerState) => state.franchiseeReducer);
  const { values } = useSelector((state: RootReducerState) => state.subUserReducer.subUserList);


  const filterDetails: DataTableFilterMeta = {
    userName: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    email: { value: "", matchMode: "contains" },
    phoneNumber: { value: "", matchMode: "contains" },
    // FranchiseId: { value: getCurrentUserId ? getCurrentUserId : "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };

  let initialLazyState: any = {
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const [lazyState, setLazyState] = useState(initialLazyState);

  useEffect(() => {
    dispatch(getCurrentFranchiseIdLoading())
  }, [])

  useEffect(() => {
    if (getCurrentUserId) {
      dispatch(getAdditionalDetailsLoading(getCurrentUserId))
      dispatch(getFranchiseeImageLoading(getCurrentUserId))
      dispatch(getuploadedeDocumentinFranchiseLoading(getCurrentUserId));
    }
    return()=>{
      dispatch(resetAdditionalDetails())
    }
  }, [getCurrentUserId])

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getSubuserListLoading(lazyState));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);

  const statusBodyForViewTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )
  }

  const handleDownload = (data: any) => {
    dispatch(generatedocumentLinkLoading(data?.id))
  }

  const emailBodyTemplate = (rowData: any, field: string) => {
    let data = rowData[field];
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHighlighter status={status} />
    );
  };

  const columns: Columns[] = [

    {
      field: "userName",
      sortable: false,
      header: "User Name",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },

    {
      field: "name",
      sortable: false,
      header: "Name",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "200px", marginLeft: "20px" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "email",
      sortable: false,
      header: "Email",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", minWidth: "300px" },
      isDefault: true,
      body: (rowData) => emailBodyTemplate(rowData, "email"),
      isStatic: false,
    },
    {
      field: "phoneNumber",
      sortable: false,
      header: "Phone",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "lockoutEnabled",
      sortable: false,
      header: "Lockout Enabled",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => statusBodyTemplate(rowData, 'lockoutEnabled'),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];

  return (
    <React.Fragment>
      <div className='franchiseePage' id='franchiseePage'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Profile" titlePath={ROUTER.PROFILE} breadcrumbItem='Additional Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={false}
            firstButtonLabel='Edit Franchise'
          />

          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
                <Row>
                  <Col lg={6}>
                    <div className="parentCls2">

                      <div className={` pt-1 childCls`}>
                        <div>Name</div>
                        <b>{moreDetails?.user?.name ? moreDetails?.user?.name : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>User Name</div>
                        <b>{moreDetails?.user?.userName ? moreDetails?.user?.userName : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Email</div>
                        <b>{moreDetails?.user?.email ? moreDetails?.user?.email : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Address</div>
                        <b>{moreDetails?.user?.extraProperties?.Address ? moreDetails?.user?.extraProperties?.Address : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Alternative Address</div>
                        <b>{moreDetails?.alternateAddress ? moreDetails?.alternateAddress : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Date Of Birth</div>
                        <b>{moreDetails?.user?.extraProperties?.DateOfBirth ? formatDateMEL(moreDetails?.user?.extraProperties?.DateOfBirth) : <div className='none-text'>-</div>}</b>
                      </div>
                      <div className={` pt-1 childCls`}>
                        <div>Wedding Date</div>
                        <b>{moreDetails?.user?.extraProperties?.WeddingDate ? formatDateMEL(moreDetails?.user?.extraProperties?.WeddingDate) : <div className='none-text'>-</div>}</b>
                      </div>

                    </div>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg={4}>
                        <div className='mb-2'>Image</div>
                        {franchiseImage ?
                          <Image src={franchiseImage} alt="Image" className='view-image' height='150px' preview /> :
                          <div className='none-text'>-</div>
                        }
                      </Col>
                      {
                        getUploadedList?.length > 0 && (
                          getUploadedList?.filter((data: any) => data.fileType === "GstNumber").map((res: any) => (
                            <Col lg={4} key={res.id}>
                              <div style={{ fontSize: "16px", fontWeight: 700 }}>GST Document</div>
                              <div style={{ width: "200px", height: "100px", padding: "10px" }}>
                                <FeatherIcon style={{ width: "100%", height: "100%" }} onClick={() => handleDownload(res)} icon="file-text" className={`icon-sm right-icon pointer`} />
                              </div>
                            </Col>
                          ))
                        )
                      }
                      {
                        getUploadedList?.length > 0 && (
                          getUploadedList?.filter((data: any) => data.fileType === "pancard").map((res: any) => (
                            <Col lg={4} key={res.id}>
                              <div style={{ fontSize: "16px", fontWeight: 700 }}>PAN Documment</div>
                              <div style={{ width: "200px", height: "100px", padding: "10px" }}>
                                <FeatherIcon style={{ width: "100%", height: "100%" }} onClick={() => handleDownload(res)} icon="file" className={`icon-sm right-icon pointer`} />
                              </div>
                            </Col>
                          ))
                        )
                      }
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>


          {/* more details */}
          <Row className='mt-3'>
            <div className="view">
              <div className="general-details">
                <b>More Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  <div className={` pt-1 childCls`}>
                    <div>Gst Number</div>
                    <div>{moreDetails?.user?.extraProperties?.GstNumber ? moreDetails?.user?.extraProperties?.GstNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>PAN Number</div>
                    <div>{moreDetails?.user?.extraProperties?.PANCardNo ? moreDetails?.user?.extraProperties?.PANCardNo : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Phone</div>
                    <div>{moreDetails?.user?.phoneNumber ? moreDetails?.user?.phoneNumber : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Email</div>
                    <div>{statusBodyForViewTemplate(moreDetails?.user?.extraProperties?.SendEmail == true ? true : false)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Send Sms</div>
                    <div>{statusBodyForViewTemplate(moreDetails?.user?.extraProperties?.SendSms == true ? true : false)}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Company Name</div>
                    <div>{moreDetails?.companyName ? moreDetails?.companyName : <div className='none-text'>-</div>}</div>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Company Address</div>
                    <b>{moreDetails?.companyAddress ? moreDetails?.companyAddress : <div className='none-text'>-</div>}</b>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Alternate Address</div>
                    <b>{moreDetails?.alternateAddress ? moreDetails?.alternateAddress : <div className='none-text'>-</div>}</b>
                  </div>
                  <div className={` pt-1 childCls`}>
                    <div>Proprietor Name</div>
                    <b>{moreDetails?.proprietorName ? moreDetails?.proprietorName + " " : <div className='none-text'>-</div>}</b>
                  </div>

                </div>
              </div>
            </div>
          </Row>

          <Row className='mt-3'>
            <div className="view">
              <div className="general-details">
                <b>Sub-user Details</b>
              </div>
              <div className="subview">
                <DataTableWrapper
                  columnDetails={columns}
                  value={values}
                  stripedRows={true}
                  rowHover={true}
                  loading={loading}
                  emptyMessage="No users found."
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Displaying Users  {last} records of {totalRecords} in total"
                ></DataTableWrapper>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FranchiseAdditionalInformation