import { jobsheetInitialState } from "src/models/pages/jobsheetModel";
import { JobSheetTypes } from "./actionTypes";

const initialState: jobsheetInitialState = {
  error: "",
  loading: false,
  jobListLoading: false,
  message: "",
  paginationCount: 0,
  jobsheetList: {
    values: [],
    totalRecords: 0,
  },
  jobsheet: null,
  dealerList: [],
  vehiclebrandList: [],
  dealerVehiclebrandList: [],
  customerList: [],
  audioModelList: [],
  pendingReason: [],
  audioModelDetails: null,
  employeeList: [],
  courierList: [],
  technicianList: [],
  estimatorList: [],
  vehicleBrandDropdownListPage: [],
  partsList: [],
  taxList: [],
  partsDetails: {
    index: -1,
    value: {}
  },
  taxDetails: {
    index: -1,
    value: {}
  },
  pendingCommandMessage: "",
  invoiceDetails: [],
  historyOfJob: [],
  jobsheetId: -1,
  receivedPayment: [],
  concernImage: "",
  prepareByIdData: null,
  updatedByIdData: null,
  taxesDropdownList: [],
  franchiseArrived: [],
  getLatLng: null,
  selectedArea: "",
  additionalExpenseList: {
    values: [],
    totalRecords: 0,
  },
  additionalExpenseDetails: null,
  franchiseDocumet: [],
  franchiseDocumetLink: "",
  approveDocument: [],
  approveDocumentLink: "",
  technicianByIdData: "",
  estimatorByIdData: "",
  jobSheetStatus: "",
  getjobSheetStatus: "",
  enquiryList: {
    values: [],
    paginatorCount: 0,
    totalRecords: 0,
  },
  withoutQueryAdditionaDetails: [],
  additionalExpensesLink: "",
  franchiseArrivedMessage: "",
  jobsheetFeedbackList: [],
  courierAllList: [],
  feedbackPendingOrNot: false,
  customerGetDetails: null,
  customerProfile:"",
  getcurrentUserLatLng : null,
  jobsheetNumberFilterData:"",
  audioModelFilterData:"",
  additionalExpenseUser:""
};

const Jobsheet = (state = initialState, action: any) => {

  switch (action.type) {
    // case JobSheetTypes.GET_JOBSHEETS_LIST:
    case JobSheetTypes.GET_JOBSHEETBYID:
    case JobSheetTypes.CREATE_JOBSHEET_LOADING:
    case JobSheetTypes.DELETE_JOBSHEETS:
    case JobSheetTypes.DEALERDROPDOWNLIST_LOADING:
    case JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_LOADING:
    case JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_LOADING:
    case JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_LOADING:
    case JobSheetTypes.GET_PENDING_REASON_LOADING:
    case JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_LOADING:
    case JobSheetTypes.SERVICE_REQUEST_JOB:
    case JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH:
    case JobSheetTypes.CREATE_JOB:
    case JobSheetTypes.GET_EMPLOYEE_DROPDOWN_LOADING:
    case JobSheetTypes.GET_COURIER_DROPDOWN_LOADING:
    case JobSheetTypes.UPDATE_PENDING_COMMAND_LOADING:
    case JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_LOADING:
    case JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_LOADING:
    case JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_LOADING:
    case JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB:
    case JobSheetTypes.GET_PARTS_DROPDOWN_LOADING:
    case JobSheetTypes.GET_TAXES_DROPDOWN_LOADING:
    case JobSheetTypes.GET_PART_DETAILS_FOR_JOB:
    case JobSheetTypes.GET_TAX_DETAILS_LOADING:
    case JobSheetTypes.DELETE_PENDING_COMMAND_LOADING:
    case JobSheetTypes.ESTIMATE_COMPLETE_JOB:
    case JobSheetTypes.ESTIMATE_APPROVAL_JOB:
    case JobSheetTypes.CANCEL_JOB:
    case JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN:
    case JobSheetTypes.ASSIGN_TO_TECHNICIAN:
    case JobSheetTypes.READY_TO_INVOICE:
    case JobSheetTypes.JOB_INVOICED:
    case JobSheetTypes.JOB_CLOSE:
    case JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_LOADING:
    case JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA:
    case JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB:
    case JobSheetTypes.DOWNLAOAD_JOB_SHEET:
    case JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET:
    case JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET:
    case JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET:
    case JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET:
    case JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET:
    case JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET:
    case JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE:
    case JobSheetTypes.GET_PREPARED_BYID_LOADING:
    case JobSheetTypes.GET_UPDATED_BYID_USER_LOADING:
    case JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_LOADING:
    case JobSheetTypes.FRANCHISEE_ARRIVED_LOADING:
    case JobSheetTypes.FRANCHISEE_COMPLETED_JOB_LOADING:
    case JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_LOADING:
    case JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_LOADING:
    case JobSheetTypes.ADDITIONAL_EXPENSES_GRID_LOADING:
    case JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_LOADING:
    case JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_LOADING:
    case JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LOADING:
    case JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_LOADING:
    case JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_LOADING:
    case JobSheetTypes.GET_JOBSHEET_ENQUIRY_BY_ID:
    case JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_LOADING:
    case JobSheetTypes.EMAIL_SERVICE_HISTORY_LOADING:
    case JobSheetTypes.PRINT_EXCHANGE_INVOICE_LOADING:
    case JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_LOADING:
    case JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_LOADING:
    case JobSheetTypes.GET_APPROVE_DOCUMENT_LOADING:
    case JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_LOADING:
    case JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_LOADING:
    case JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_LOADING:
    case JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_LOADING:
    case JobSheetTypes.GET_COURIER_DROPDOWN_ALL_DATA_LOADING:
    case JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_LOADING:
    case JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_LOADING:
    case JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_LOADING:
    case JobSheetTypes.GET_JOBSHEET_NUMBER_LOADING:
    case JobSheetTypes.GET_AUDIO_MODEL_LOADING:
    case JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_LOADING:

      return {
        ...state,
        loading: true,
        message: "",
        // error :"",
        paginationCount: 0
      };

    //new joblistLoading handling  

    case JobSheetTypes.GET_JOBSHEETS_LIST:
      return {
        ...state,
        jobListLoading: true,
        message: "",
        // error :"",
        paginationCount: 0
      };

    case JobSheetTypes.GET_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        message: "",
        // error :"",
        paginationCount: 0,
        customerProfile:action.payload.data
      };

    case JobSheetTypes.GET_JOBSHEETS_LIST_SUCCESS:

      return {
        ...state,
        loading: false,
        jobListLoading: false,
        jobsheetList: {

          values: action.payload.data?.items,
          totalRecords: action.payload.data?.totalCount,
        },
        paginationCount: action.payload.data?.items.length
      };

    case JobSheetTypes.GET_JOBSHEETBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        jobsheet: action.payload.data,
        // error: ""
      };

    case JobSheetTypes.GET_JOBSHEET_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        jobsheetNumberFilterData:action.payload.data
      };

    case JobSheetTypes.GET_AUDIO_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        audioModelFilterData:action.payload.data
      };

    case JobSheetTypes.CREATE_JOBSHEET_SUCCESS:

      return {
        ...state,
        loading: false,
        //// error :"",
        message: action.payload?.id ? "Job Sheet Updated Successfully" : "Job Sheet Created Successfully",
        jobsheetId: action.payload?.jobsheet?.id,
      };

    case JobSheetTypes.SERVICE_REQUEST_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        //// error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        //// error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.CREATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        //// error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        //// error :"",
        message: "Job Sheet Updated Successfully"
      };
    case JobSheetTypes.ESTIMATE_COMPLETE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        //// error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.ESTIMATE_APPROVAL_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        //// error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.CANCEL_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };
    case JobSheetTypes.GET_JOBSHEET_STATUS_SUCCESS:
      return {
        ...state,
        getjobSheetStatus: action.payload.payload

      };
    case JobSheetTypes.SET_JOBSHEET_STATUS_SUCCESS:

      return {
        ...state,
        jobSheetStatus: action.payload.payload

      };

    case JobSheetTypes.ASSIGN_TO_TECHNICIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.READY_TO_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.JOB_INVOICED_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.JOB_CLOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.DELETE_JOBSHEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: action.undo ? "Job Sheet restored successfully" : "Job Sheet is deleted successfully"
      };

    case JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_SUCCESS:
      console.log("Reducer", action.payload.data);

      return {
        ...state,
        loading: false,
        // error :"",
        vehiclebrandList: action.payload?.data?.vehicleBrands.filter((item: any) => item.isDeleted != 'True'),
        dealerVehiclebrandList: action?.payload?.data?.dealerVehicleBrands
      };

    case JobSheetTypes.DEALERDROPDOWNLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        dealerList: action.payload.data?.filter((eachDealer: any) => !eachDealer.isDeleted)
      };

    case JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        customerList: action.payload.data?.filter((eachCustomer: any) => !eachCustomer.isDeleted)
      };

    case JobSheetTypes.GET_EMPLOYEE_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        employeeList: action.payload.data?.filter((eachEmployee: any) => !eachEmployee.isDeleted)
      };

    case JobSheetTypes.GET_COURIER_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        courierList: action.payload.data?.filter((eachCourier: any) => !eachCourier.isDeleted).filter((eachCourier: any) => eachCourier.courierMasterName != "")
      };

    case JobSheetTypes.GET_COURIER_DROPDOWN_ALL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        courierAllList: action.payload.data?.filter((eachCourier: any) => !eachCourier.isDeleted).filter((eachCourier: any) => eachCourier.companyName != "")
      };

    case JobSheetTypes.UPDATE_PENDING_COMMAND_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Pending commands updated successfully"
      };

    case JobSheetTypes.RESET_JOBSHEET:

      return {
        ...state,
        customerProfile:"",
        jobsheet: initialState.jobsheet,
      };
    case JobSheetTypes.CLEAR_JOBSHEET:

      return {
        ...state,
        error: "",
        loading: false,
        jobListLoading: false,
        message: "",
        paginationCount: 0,
        jobsheetList: {
          values: [],
          totalRecords: 0,
        },
        jobsheet: null,
        dealerList: [],
        vehiclebrandList: [],
        dealerVehiclebrandList: [],
        customerList: [],
        audioModelList: [],
        pendingReason: [],
        audioModelDetails: null,
        employeeList: [],
        courierList: [],
        technicianList: [],
        estimatorList: [],
        vehicleBrandDropdownListPage: [],
        partsList: [],
        taxList: [],
        partsDetails: {
          index: -1,
          value: {}
        },
        taxDetails: {
          index: -1,
          value: {}
        },
        pendingCommandMessage: "",
        invoiceDetails: [],
        historyOfJob: [],
        jobsheetId: -1,
        receivedPayment: [],
        concernImage: "",
        enquiryList: "",
        getLatLng: null,
        jobsheetFeedbackList: [],
        customerGetDetails: null,
        getcurrentUserLatLng : null
      };

    case JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        audioModelList: action.payload.data
      };

    case JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        audioModelDetails: action.payload.data
      };

    case JobSheetTypes.GET_PENDING_REASON_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingReason: action.payload.data
      };

    case JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        technicianList: action.payload?.data?.filter((eachTechnician: any) => !eachTechnician.isDeleted)
      };

    case JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicleBrandDropdownListPage: action.payload.data
      };


    case JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        estimatorList: action.payload
      };


    case JobSheetTypes.CLEAR_JOBSHEET_MESSAGES:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        pendingCommandMessage: "",
        franchiseArrivedMessage: ""
      };

    case JobSheetTypes.GET_PARTS_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        partsList: action.payload.data,

      };

    case JobSheetTypes.GET_PART_DETAILS_FOR_JOB_SUCCESS:

      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        partsDetails: {
          index: action.payload.index,
          value: action.payload.data
        }
      };
    //  change this --> 
    case JobSheetTypes.GET_TAXES_DROPDOWN_SUCCESS:
console.log(action.payload.data);

      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        taxList: action.payload.data?.filter((val:any)=>val.isDeleted != true)
      };

    case JobSheetTypes.GET_TAX_DETAILS_SUCCESS:

      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        taxDetails: {
          index: action.payload.index,
          value: action.payload.response?.data
        }
      };

    case JobSheetTypes.RESET_TAX_DETAILS_FOR_JOB:

      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        taxDetails: {
          index: -1,
          value: {}
        }
      };


    case JobSheetTypes.RESET_PART_DETAILS_FOR_JOB:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        partsDetails: {
          index: -1,
          value: {}
        }
      };
    case JobSheetTypes.DELETE_PENDING_COMMAND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        pendingCommandMessage: "Pending comment deleted successfully",
      };
    case JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        invoiceDetails: action.payload?.data
      };

    case JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
      };

    case JobSheetTypes.DOWNLAOAD_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
      };

    case JobSheetTypes.GET_PREPARED_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        prepareByIdData: action.payload.data
      };
    case JobSheetTypes.GET_TECHNICIAN_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        technicianByIdData: action.payload.data
      };
    case JobSheetTypes.GET_ESTIMATOR_BYID_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        estimatorByIdData: action.payload.data
      };
    case JobSheetTypes.GET_UPDATED_BYID_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        updatedByIdData: action.payload.data
      };

    case JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
      };

    case JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Invoice has been successfully emailed",
      };

    case JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
      };
    case JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Successfully emailed the estimate",
      };
    case JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "The estimate has been successfully sent via sms",
      };
    case JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        concernImage: action.payload?.data
      };

    case JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET_SUCCESS:
      debugger
      let receivedPayment: any[] = [...state.receivedPayment];
      receivedPayment[action.payload.index] = action.payload?.resp?.data;
      console.log("storereceivedPayment",receivedPayment)

      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        receivedPayment: receivedPayment,
      };

    case JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA_SUCCESS:
      let message = action.payload?.data?.items?.length > 0 ? "Repeat Job" : "";
      return {
        ...state,
        loading: false,
        // error :"",
        message: message,
        historyOfJob: action.payload?.data?.items
      };

    case JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        taxesDropdownList: action.payload?.data
      };

    case JobSheetTypes.FRANCHISEE_ARRIVED_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        franchiseArrivedMessage: "Franchise arrived successfully",
        franchiseArrived: action.payload.data
      };
    case JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        getLatLng: action.payload.latLag.data,
        selectedArea: action.payload.selectedArea
      };

    case JobSheetTypes.FRANCHISEE_COMPLETED_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Job Sheet Updated Successfully"
      };

    case JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: ""
      };

    case JobSheetTypes.ADDITIONAL_EXPENSES_GRID_SUCCESS:
      debugger;
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
        additionalExpenseList: {
          values: action.payload.data,
          totalRecords: action.payload.data?.length,
          // values: action.payload.data?.items,
          // totalRecords: action.payload.data?.totalCount,
        },
      };

    case JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Additional Expenses Approved Successfully"
      };

    case JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        additionalExpenseDetails: action.payload.data
      };

    case JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        franchiseDocumet: action.payload.data
      };

    case JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        franchiseDocumetLink: action.payload.data
      };

    case JobSheetTypes.CLEAR_FRANCHISE_DOCUMENT_LINK:
      return {
        ...state,
        loading: false,
        error: "",
        franchiseDocumetLink: ""
      };
    case JobSheetTypes.GET_JOBSHEET_ENQUIRY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        enquiryList: {
          values: action.payload.data.items,
          paginatorCount: action.payload.data.items?.length,
          totalRecords: action.payload.data.totalCount,
        },
      };

    case JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Additional Expenses Updated Successfully"
      };

    case JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "successfully emailed the exchange invoice!",
      };

    case JobSheetTypes.EMAIL_SERVICE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "successfully emailed the minutes of service!",
      };

    case JobSheetTypes.PRINT_EXCHANGE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "",
      };

    case JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Successfully deleted the additional expenses",
      };

    case JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        message: "Successfully deleted the additional expenses document",
      };

    case JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        withoutQueryAdditionaDetails: action.payload.data
      };

    case JobSheetTypes.GET_APPROVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        approveDocument: action.payload.data
      };

    case JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        approveDocumentLink: action.payload.data
      };

    case JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        // error :"",
        additionalExpensesLink: action.payload.data
      };

    case JobSheetTypes.CLEAR_APPROVE_DOCUMENT_LINK:
      return {
        ...state,
        loading: false,
        error: "",
        approveDocumentLink: ""
      };

    case JobSheetTypes.CLEAR_ADDITIONAL_EXPENSES_DOCUMENT_LINK:
      return {
        ...state,
        loading: false,
        error: "",
        additionalExpensesLink: ""
      };

    case JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        jobsheetFeedbackList: action.payload.data?.items
      };

    case JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        feedbackPendingOrNot: action.payload.data?.feedbackRequired
      };

    case JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        customerGetDetails: action.payload?.data
      };

    case JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_SUCCESS:      
      return {
        ...state,
        loading: false,
        getcurrentUserLatLng: action.payload.data,
      };


    case JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_SUCCESS:      
      return {
        ...state,
        loading: false,
        message : "Additional expenses updated successfully"
      };

    case JobSheetTypes.GET_ADDITIONAL_EXPENSE_USER_DETAILS_SUCCESS:      
      return {
        ...state,
        additionalExpenseUser:action.payload.data
      };


    case JobSheetTypes.GET_JOBSHEETS_LIST_FAIL:
    case JobSheetTypes.GET_JOBSHEETBYID_FAIL:
    case JobSheetTypes.REPLACEMENT_SYSTEM_DISPATCH_FAIL:
    case JobSheetTypes.CREATE_JOBSHEET_FAIL:
    case JobSheetTypes.DELETE_JOBSHEETS_FAIL:
    case JobSheetTypes.DEALERDROPDOWNLIST_ERROR:
    case JobSheetTypes.GETVEHICLEBRAND_RELATEDTO_DEALER_ERROR:
    case JobSheetTypes.GETCUSTOMER_DROPDOWNLIST_ERROR:
    case JobSheetTypes.GET_AUDIOMODEL_JOB_DROPDOWNLIST_ERROR:
    case JobSheetTypes.GET_PENDING_REASON_ERROR:
    case JobSheetTypes.GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_ERROR:
    case JobSheetTypes.SERVICE_REQUEST_JOB_FAIL:
    case JobSheetTypes.CREATE_JOB_FAIL:
    case JobSheetTypes.ASSIGN_TO_ESTIMATE_JOB_FAIL:
    case JobSheetTypes.ESTIMATE_COMPLETE_JOB_FAIL:
    case JobSheetTypes.ESTIMATE_APPROVAL_JOB_FAIL:
    case JobSheetTypes.CANCEL_JOB_FAIL:
    case JobSheetTypes.ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_FAIL:
    case JobSheetTypes.ASSIGN_TO_TECHNICIAN_FAIL:
    case JobSheetTypes.READY_TO_INVOICE_FAIL:
    case JobSheetTypes.JOB_INVOICED_FAIL:
    case JobSheetTypes.JOB_CLOSE_FAIL:
    case JobSheetTypes.GET_JOBSHEETS_HISTORY_DATA_FAIL:
    case JobSheetTypes.DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_FAIL:
    case JobSheetTypes.DOWNLAOAD_JOB_SHEET_FAIL:
    case JobSheetTypes.GET_INVOICE_RECEIVED_PAYMNET_ERROR:
    case JobSheetTypes.DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_FAIL:
    case JobSheetTypes.SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_FAIL:
    case JobSheetTypes.SEND_SMS_ESTIMATE_FOR_JOB_SHEET_FAIL:
    case JobSheetTypes.GET_EMPLOYEE_DROPDOWN_FAIL:
    case JobSheetTypes.GET_COURIER_DROPDOWN_FAIL:
    case JobSheetTypes.UPDATE_PENDING_COMMAND_FAIL:
    case JobSheetTypes.GET_JOBSHEET_TECHNICIAN_DROPDOWN_ERROR:
    case JobSheetTypes.GET_JOBSHEET_ESTIMATOR_DROPDOWN_ERROR:
    case JobSheetTypes.GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_ERROR:
    case JobSheetTypes.GET_PARTS_DROPDOWN_FAIL:
    case JobSheetTypes.GET_PART_DETAILS_FOR_JOB_FAIL:
    case JobSheetTypes.GET_TAXES_DROPDOWN_FAIL:
    case JobSheetTypes.GET_TAX_DETAILS_FAIL:
    case JobSheetTypes.DELETE_PENDING_COMMAND_FAIL:
    case JobSheetTypes.GET_INVOICE_DETAILS_BY_JOB_ID_ERROR:
    case JobSheetTypes.SEND_INVOICE_EMAIL_FOR_JOB_SHEET_FAIL:
    case JobSheetTypes.DOWNLOAD_INVOICE_FOR_JOB_SHEET_FAIL:
    case JobSheetTypes.GET_ESTIMATE_CONCERN_IMAGE_FAIL:
    case JobSheetTypes.GET_PREPARED_BYID_ERROR:
    case JobSheetTypes.GET_UPDATED_BYID_USER_ERROR:
    case JobSheetTypes.GET_TAXES_TYPES_IN_JOBSHEET_ERROR:
    case JobSheetTypes.FRANCHISEE_ARRIVED_ERROR:
    case JobSheetTypes.FRANCHISEE_COMPLETED_JOB_ERROR:
    case JobSheetTypes.GET_DELAER_OR_CUSTOMER_LOCATION_ERROR:
    case JobSheetTypes.ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_ERROR:
    case JobSheetTypes.ADDITIONAL_EXPENSES_GRID_ERROR:
    case JobSheetTypes.ADDITIONAL_EXPENSES_APPROVE_ERROR:
    case JobSheetTypes.GET_ADDITIONAL_EXPENSES_DETAILS_ERROR:
    case JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_ERROR:
    case JobSheetTypes.GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_ERROR:
    case JobSheetTypes.ADDITIONAL_EXPENSES_UPDATE_ERROR:
    case JobSheetTypes.EMAIL_EXCHNAGE_INVOICE_ERROR:
    case JobSheetTypes.EMAIL_SERVICE_HISTORY_ERROR:
    case JobSheetTypes.PRINT_EXCHANGE_INVOICE_ERROR:
    case JobSheetTypes.DELETE_ADDITIONAL_EXPENSES_ERROR:
    case JobSheetTypes.GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_ERROR:
    case JobSheetTypes.GET_APPROVE_DOCUMENT_ERROR:
    case JobSheetTypes.GET_APPROVE_DOCUMENT_LINK_ERROR:
    case JobSheetTypes.FRANCHISE_DOCUMENT_DELETE_ERROR:
    case JobSheetTypes.GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_ERROR:
    case JobSheetTypes.GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_ERROR:
    case JobSheetTypes.GET_COURIER_DROPDOWN_ALL_DATA_ERROR:
    case JobSheetTypes.CHECK_DEALER_HAVE_FEEDBACK_PENDING_ERROR:
    case JobSheetTypes.GET_CUSTOMER_STATUS_IN_JOBSHEET_ERROR:
    case JobSheetTypes.GET_CURRENT_ADDRESS_LAT_LNG_ERROR:
    case JobSheetTypes.GET_JOBSHEET_NUMBER_ERROR:
    case JobSheetTypes.GET_AUDIO_MODEL_ERROR:
    case JobSheetTypes.NEW_ADDITIONAL_EXPENSES_UPDATED_ERROR:
      return {
        ...state,
        loading: false,
        jobListLoading: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
};

export default Jobsheet;
