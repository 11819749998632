import { MelTableTypes } from "./actionTypes";

export interface MelTableState {
  error: string;
  loading: boolean;
  customer : {
    values:[],
    totalRecords:number,

  }
}

const initialState: MelTableState = {
  error: "",
  loading: false,
  customer : {
    values:[],
    totalRecords:0,
  }
};

const MelTable = (state = initialState, action: any) => {

  switch (action.type) {
    case MelTableTypes.TABLE_LOADING:
      return  {
        ...state,
        loading: true,
      };
      
    case MelTableTypes.TABLE_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          values: action.payload.customers,
          totalRecords: action.payload.totalRecords,
        },
        loading: false,
      };
      
    case MelTableTypes.TABLE_FETCHERROR:
      return {
        ...state,
        loading: false,
      };
      
    default:
      return { ...state };
      
  }
};


export default MelTable;
