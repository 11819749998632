import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { formatDateMEL } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { clearTaxesMessage, getTaxesBYID, resetTaxesMessage } from 'src/store/actions'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'


const ViewTaxDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [visible, setVisible] = useState(false);
  const editUserClick = () => {
    navigate(`${ROUTER.TAXES_EDIT}/${id}`)
  }

  const formValue: any = useSelector(
    (state: RootReducerState) => state.taxesReducer.taxDetails
  );
  const { error, message, loading } = useSelector(
    (state: RootReducerState) => state.taxesReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);



  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }


  useEffect(() => {
    if (id) {
      dispatch(getTaxesBYID(id));
    }
    return () => {
      dispatch(resetTaxesMessage())
    }
  }, []);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearTaxesMessage());
  };
  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };
  

  const formatdataStructure = (response: any[]) => {
    const extractedTaxNames = response?.map(item => item.taxName);
    if (extractedTaxNames?.length > 1) {
      const lastIndex = extractedTaxNames?.length - 1;
      extractedTaxNames[lastIndex] = "and " + extractedTaxNames[lastIndex];
    }    
    const joinedTaxNames = extractedTaxNames?.join(", ");
    return joinedTaxNames
  }
  const formatdataInvoicetype = (rowData: any, field?: string) => {
    let response = field ? rowData[field] : rowData
    const extractedTaxNames = response?.map((item: any) => item.name);
    if (extractedTaxNames?.length > 1) {
      const lastIndex = extractedTaxNames?.length - 1;
      extractedTaxNames[lastIndex] = "and " + extractedTaxNames[lastIndex];
    }
    const joinedTaxNames = extractedTaxNames?.join(", ");
    return joinedTaxNames
  }

  const userView = {
    generalDetails: [
      {
        name: "Name",
        value: formValue?.name
      },
      {
        name: "Sac Number",
        value: formValue?.sacNumber
      },
      {
        name: "Tax Percentage",
        value: formValue?.taxPercentage + " %"
      },
      {
        name: "priority",
        value: formValue?.priority
      },
      {
        name: "Starts at",
        value: formatDateMEL(formValue?.startDate)
      },
      {
        name: "Ends at",
        value: formatDateMEL(formValue?.endDate)
      },
      // {
      //   name: "Invoice Types",
      //   value: formValue?.invoicetypes
      // },
      {
        name: "Tax Types",
        value: formatdataStructure(formValue?.taxTypeList)
      },
      {
        name:"Invoice Types",
        value: formatdataInvoicetype(formValue?.invoiceTypeList)
      }
    ]
  }

  return (
    <React.Fragment>
      <div className='userpage'>
      {visible && dialogContainer(error)}
        <Container fluid>
          <BreadcrumbWithFilter
            title="Taxes" titlePath={ROUTER.TAXES_GRID} breadcrumbItem='Tax Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Taxes'
            onFirstButtonClick={editUserClick}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
              <div className="parentCls">
                  {
                    userView.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div >{data.name}</div>
                          <b>{data.value ? data.value : <div className='view-none-text'>-</div>}</b>
                        </div>
                      )
                    })
                  }
                  {/* <hr /> */}

                </div>
              </div>
            </div>
          </Row>
          {/* <Row>
          <div className="view">
              <div className="general-details">
                <b>Other Details</b>
              </div>
              <div className="subview">
                <Row>
                  {
                    userView.otherDetails.map((data, index) => {
                      return (
                        <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                          <div className='mb-2'>{data.name}</div>
                          <b>{data.value?data.value:<div className='none-text'>-</div>}</b>
                        </Col>
                      )
                    })
                  }
                </Row>
                </div>
                </div>
          </Row> */}
        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewTaxDetails