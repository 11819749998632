import { Fieldset } from 'primereact/fieldset';
import React from 'react'
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';
import { AdministratorDetails } from 'src/models/components/ViewAdministratorDetails';

const ViewAdministrator = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const handleEditAdmin=()=>{
    navigate(`${ROUTER.ADMINISTRATOR_EDIT}/${id}`)
  }
  const handleArchiveAdmin=()=>{

  }
  return (
    <React.Fragment>
      <div className='pagecontent'>
        <Container fluid>
          <BreadcrumbWithFilter
            title='Administrator' 
            titlePath={ROUTER.ADMINISTRATOR_GRID} 
            breadcrumbItem='Administrator Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel='Edit Administrator'
            onFirstButtonClick={handleEditAdmin}
            isShowSecondButton={true}
            secondButtonLabel='Archive Administrator'
            onSecondButtonClick={handleArchiveAdmin}

          />
          <Row>
              <Fieldset legend="Administrator Details">
                <Row>
                  {
                    AdministratorDetails.map((data, index) => {
                      return (
                        <Col lg={4} key={index} className={`${index>=3 ? "mt-4" : "mt-1"}`}>
                          <div className='mb-2'>{data.name}</div>
                          <b>{data.value}</b>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Fieldset>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewAdministrator