import React from 'react'
import { APIError } from 'src/models/components/errorModel';
import { Dialog } from "primereact/dialog";

interface ErrorValidationProps {
  error: APIError | string;
  visible: boolean;
  onHide: () => void;
}

const JobsheetValidationPopup = ({ error, visible, onHide }: ErrorValidationProps) => {
  if (typeof error !== 'string') {
    if (error.validationErrors != null && error.validationErrors.length > 0) {
      return (
        <React.Fragment>
          <Dialog header={ "Update below mentioned Inputs" } visible={visible} maximizable className='vw30' onHide={onHide}>
            <div className="m-0 .validation-text">
              {error.validationErrors.map((eachLine: any, index: number) => (
                <div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine?.message}</span></div>
              ))}
            </div>
          </Dialog>
        </React.Fragment>
      );
    } else {
      const resultString = error.message.replace(/([a-z])([A-Z])/g, '$1 $2');
      return (
        <React.Fragment>
          <Dialog header={ "Update below mentioned Inputs"} visible={visible} maximizable className='vw30' onHide={onHide}>
            <div className="m-0 .validation-text">
              {resultString.split(".,").map((eachLine: string, index: number) => (
                <div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>
              ))}
            </div>
          </Dialog>
        </React.Fragment>
      );
    }
  } else {
    return <React.Fragment>
      <Dialog header={"Update below mentioned Inputs" } visible={visible} maximizable className='vw30' onHide={onHide}>
        <div className="m-0 .validation-text">
          <div><span className="validation-error-index">1</span><span className="validation-error-line">{error}</span></div>
        </div>
      </Dialog>
    </React.Fragment>;
  }
}

export default JobsheetValidationPopup
