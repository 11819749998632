import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { getBulkEmailById } from 'src/store/BulkEmail/action'
import { TabPanel, TabView } from 'primereact/tabview'
import FeatherIcon from "feather-icons-react";

const ViewBulkEmail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { bulkEmailDetails,error, message , loading } = useSelector((state: RootReducerState) => state.BulkEmailReducer);
  useEffect(() => {
    dispatch(getBulkEmailById(id));
  }, []);


  const BulkEmailDetails = [
    {
      name: "Title",
      value: bulkEmailDetails?.title ? <div style={{whiteSpace:"wrap"}}>{bulkEmailDetails?.title}</div> : ""
    },
    {
      name: "Subject",
      value: bulkEmailDetails?.subject ? <div style={{whiteSpace:"wrap"}}>{bulkEmailDetails?.subject}</div> : ""
    },
    {
      name: "User Type",
      value: bulkEmailDetails?.toUserTypes ? <div className='d-flex flex-wrap gap-1'>{bulkEmailDetails?.toUserTypes.map((item:any,index:number)=><b key={index}>{`${item}${bulkEmailDetails.toUserTypes.length < 2 ? "" : index+1 == (bulkEmailDetails.toUserTypes.length) ? "." : ","} `} </b>)}</div> : ""
    },
    {
      name: "Total Count",
      value: bulkEmailDetails?.totalCount ? bulkEmailDetails?.totalCount : ""
    },
    {
      name: "Sent Count",
      value: bulkEmailDetails?.sentCount ? bulkEmailDetails?.sentCount : ""
    },
    {
      name: "Pending Count",
      value: bulkEmailDetails?.pendingMailID ? bulkEmailDetails?.pendingMailID.length : 0
    },
    {
      name: "Failure Count",
      value: bulkEmailDetails?.failureCount ? bulkEmailDetails?.failureCount  : 0
    },
    {
      name: "Status",
      value: bulkEmailDetails?.status ? bulkEmailDetails?.status  : 0
    },
  ]

  const AllMailHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="mail" className="mr-1 view-bulk-mail-icon" />
            <span className="font-bold white-space-nowrap">All Email Id's</span>
        </div>
    );
};
  const PendingMailHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="clock" className="mr-1 view-bulk-mail-icon text-warning" />
            <span className="font-bold white-space-nowrap">Pending Email Id's</span>
        </div>
    );
};
  const SentMailHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="send" className="mr-1 view-bulk-mail-icon text-success" />
            <span className="font-bold white-space-nowrap d-none-sm">Sent Email Id's</span>
        </div>
    );
};
  const FailureMailHeader = (options:any) => {
    return (
        <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
            <FeatherIcon icon="thumbs-down" className="mr-1 view-bulk-mail-icon text-danger" />
            <span className="font-bold white-space-nowrap">Failure Email Id's</span>
        </div>
    );
};
  return (
    <React.Fragment>
      <div className="pagecontent" id='pagecontent'>
        <Container fluid>
          <BreadcrumbWithFilter
            title="Bulk Email"
            titlePath={ROUTER.BULK_EMAIL}
            breadcrumbItem="Bulk Email Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
          />
<Row>
    <div className="view">
            <div className="general-details">
              <b>Bulk Email Details</b>
            </div>
            <div className="subview">
            <div className="parentCls">
            {
              BulkEmailDetails?.map((data, index) => {
                return (
                  <div key={index} className={` pt-2 childCls`}>
                    <div>{data.name}</div>
                    <div>{data.value?data.value:<div className="none-text">-</div>}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        </div>
    </Row>
    <Row>
        <div className="view">
            <div className="general-details">
              <b>Email Content</b>
            </div>
            <div className="subview" dangerouslySetInnerHTML={{ __html: bulkEmailDetails?.body }} id="emailpreview"/>
                {/* <div dangerouslySetInnerHTML={{ __html: bulkEmailDetails?.body }}> </div> */}
            {/* </div> */}
        </div>
    </Row>
    <Row>
        <div className="view" id="bulkEmail">
            <TabView >
                <TabPanel header="All Email Id's" headerTemplate={AllMailHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                        bulkEmailDetails?.allMailID?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkEmailDetails.allMailID.length < 2 ? "" : index+1 == (bulkEmailDetails.allMailID.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                  <TabPanel header="Pending Email Id's" headerTemplate={PendingMailHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       bulkEmailDetails?.pendingMailID?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkEmailDetails.pendingMailID.length < 2 ? "" : index+1 == (bulkEmailDetails.pendingMailID.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                  <TabPanel header="Sent Email Id's"  headerTemplate={SentMailHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       bulkEmailDetails?.sentMailID?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkEmailDetails.sentMailID.length < 2 ? "" : index+1 == (bulkEmailDetails.sentMailID.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>
                <TabPanel header="Failure Email Id's"  headerTemplate={FailureMailHeader}>
                <div className='d-flex gap-3 flex-wrap'>
                    {
                       bulkEmailDetails?.failureMailID?.map((item:any,index:any)=>{
                                return(
                                    
                                        <b key={index}>{`${item}${bulkEmailDetails.failureMailID.length < 2 ? "" : index+1 == (bulkEmailDetails.failureMailID.length) ? "." : ","}`}</b>
                                   
                                )
                        })
                        }
                </div>
                  </TabPanel>

                  </TabView>
            </div>
           
    </Row>
 
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewBulkEmail