import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { audioBrands } from "src/models/pages/audioBrandsModel";
import { ClearAudioBrandImage, deleteAudioBrandsFromList, getAudioBrandsList, resetAudioBrandGlobalState } from "src/store/audioBrands/action";
import { RootReducerState } from "src/store/reducers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import TooltipMEL from "src/components/UI/Tooltip";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import { saveAsExcelFile } from "src/helpers/common_helpers";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

export default function AudioBrands() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.audioBrandsReducer.audioBrandsList) || {};
  const [audioBrand, setaudioBrand] = useState<any[]>([]);
  const { error, loading, paginatorCount, message } = useSelector((state: RootReducerState) => state.audioBrandsReducer)
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { showToast } = useToast();
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState<audioBrands | null>(null);


  const filterDetails: DataTableFilterMeta = {
    brandName: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };


  const audioBrandListConverson = (values: any) => {
    const modifeidValue = values?.map((eachValue: any) => {
      let data: any = {};
      // data['id'] = eachValue.id;
      data['brandName'] = eachValue.brandName;
      data['description'] = eachValue.description;
      data['jobPrefix'] = eachValue.jobPrefix;
      data['vehicleBrandName'] = eachValue.vehicleBrandName;
      data['servicedCount'] = eachValue.servicedCount;
      data['vehicleBrandId']=eachValue?.vehicleBrandId;
      return data;
    });
    return modifeidValue;
  }
  const modifiedValues: any = audioBrandListConverson(values);

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "brandName",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  useEffect(() => {
    dispatch(ClearAudioBrandImage())
  }, [])
  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.AUDIO_BRANDS_CREATE);
  };

  const viewHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: audioBrands) => {
    navigate(`${ROUTER.AUDIO_BRANDS_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: audioBrands) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const toCamelCase = (str: any) => {
    return str.replace(/\b\w/g, (char: any) => char.toUpperCase());
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            // displayData={rowData[field] as string}
            displayData={toCamelCase(rowData[field].toLowerCase())}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={toCamelCase(rowData[field].toLowerCase())}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };
  const camelCaseTemplate = (rowData: any, field: string) => {
    return toCamelCase(rowData[field].toLowerCase())
  };

  const statusBodyTemplate = (rowData: audioBrands, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };
  const NavigationTemplate =(rowData: audioBrands, field: any) =>{
    return(
      <Link to={`${ROUTER.VEHICLE_BRANDS_VIEW}/${rowData['vehicleBrandId']}`}>{rowData[field]}</Link>
    )
  }


  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      frozen : true,
      isStatic: false,
    },
    {
      field: "brandName",
      sortable: true,
      header: "Brand Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "brandName"),
      isDefault: true,
      frozen : true,
      isStatic: true,
    },
    {
      field: "description",
      sortable: true,
      header: "Description",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => camelCaseTemplate(rowData, "description"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "jobPrefix",
      sortable: true,
      header: "Job Prefix",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      // body: (rowData) => camelCaseTemplate(rowData, "jobPrefix"),
      isDefault: true,
      isStatic: false,
    },

    {
      field: "vehicleBrandName",
      sortable: true,
      header: "Vehicle Brand",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>NavigationTemplate(rowData,"vehicleBrandName"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "servicedCount",
      sortable: true,
      header: "Serviced Count",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap",width: "150px"  },
      isDefault: true,
      isStatic: false,
      className: "align-right"
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { brandName, description, jobPrefix, vehicleBrandName, servicedCount } = eachDealer;
      let data: any = {};
      data['Brand Name'] = brandName;
      data['Description'] = description;
      data['Job Prefix'] = jobPrefix;
      data['Vehicle BrandName'] = vehicleBrandName;
      data['Serviced Count'] = servicedCount;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getAudioBrandsListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        // data['id'] = eachValue.id;
        data['brandName'] = eachValue.brandName;
        data['description'] = eachValue.description;
        data['jobPrefix'] = eachValue.jobPrefix;
        data['vehicleBrandName'] = eachValue.vehicleBrandName;
        data['servicedCount'] = eachValue.servicedCount;
        data['vehicleBrandId']=eachValue?.vehicleBrandId;
        return data;
      });
      // let resp=await audioBrandListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Audio Brands");
     }else{
      saveDataAsExcel(modifeidValue, "Audio Brands");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Audio Brands");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let data: any = {};
      data['id'] = eachValue.id;
      data['brandName'] = eachValue.brandName;
      data['description'] = eachValue.description;
      data['jobPrefix'] = eachValue.jobPrefix;
      data['vehicleBrandName'] = eachValue.vehicleBrandName;
      data['servicedCount'] = eachValue.servicedCount;
      return data
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "Audio Brands");
    fetchData(true)
  };


  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const validationSchema = Yup.object({});

  function modifyFilters(data: any, dynamicKey: string): any {
    const modifiedData = { ...data };
    if (modifiedData.filters && modifiedData.filters.brandName) {
      modifiedData.filters[dynamicKey] = modifiedData.filters.brandName;
      delete modifiedData.filters.brandName;
      modifiedData.filters[dynamicKey] = modifiedData.filters.brandName;
      delete modifiedData.filters.brandName;
    }
    return modifiedData;
  }

  const callbackFunction = (lazyState: LazyTableState) => {
    // const modifiedData = modifyFilters(lazyState, 'AudioBrandName');
    dispatch(getAudioBrandsList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedAudioBrands, setSelectedAudioBrands] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    if(selectedStatus=="All"){
      const value = event.value.filter((item:any)=>item?.isDeleted != true);
      setSelectedAudioBrands(value)
      setSelectAll(value.length === paginatorCount);
    }else{
    const value = event.value;
    setSelectedAudioBrands(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
    }
  };

  const onSelectAllChange = (event: any) => {
    if(selectedStatus=="All"){
      const selectAll = event.checked;
      if (selectAll) {
      let filteredData=audioBrand?.filter((items:any)=>items?.isDeleted!=true)
      
      setSelectedAudioBrands(filteredData);
      setSelectAll(audioBrand.length==filteredData.length)
      }
    }else{

      const selectAll = event.checked;
      if (selectAll) {
        setSelectAll(true);
        setSelectedAudioBrands(values);
      } else {
        setSelectAll(false);
        setSelectedAudioBrands([]);
      }
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedAudioBrands([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedAudioBrands([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    if (values) {
      setaudioBrand(values)
    }
  }, [values])

  useEffect(() => {
    setTimeout(() => {
      if (error) {
        setVisible(true)
        // dispatch(resetAudioBrandGlobalState())
      }
    }, 500)
  }, [error])

  useEffect(() => {
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetAudioBrandGlobalState());
      dispatch(getAudioBrandsList(lazyState));
    }
  }, [message])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.isDeleted == true ? true : rowData[0]?.isDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deleteAudioBrandsFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      // showToast("Audio Brand Deleted Successfully", { type: "success" });
      setSelectedAudioBrands([])
      // setTimeout(() => {
      //   dispatch(getAudioBrandsList(lazyState))
      // }, 500)
    }

  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedAudioBrands([]);
    setArchiveModal(false);
  };
  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);


  const bulkActionClickHandler = () => {
    if(selectedStatus=="All"){
      
      let filteredData=audioBrand?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedAudioBrands(filteredData);
      setSelectAll(audioBrand.length==filteredData.length)
      
    }else{

      if (values.length > 0) {
        setSelectAll(true);
        setSelectedAudioBrands(values);
      }
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedAudioBrands([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Audio Brands List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Audio Brands"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedAudioBrands}
        bulkActionSatusTitle="Audio Brands"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedAudioBrands.length == 0 && selectedStatus!="All") &&
          // <TooltipMEL title="Bulk Action" position="right">
          <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
            <FeatherIcon icon="layers" className="mr-1" />
            Bulk actions
          </Button>
          // </TooltipMEL>
        }
        <AnimatePresence>
          {selectedAudioBrands.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></>}
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>

              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedAudioBrands.length > 0 && <BulkActionStatus count={selectedAudioBrands.length} title="Audio Brands"></BulkActionStatus>}
              </motion.div>

            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} isFilter={true} clearFilter={clearFilter}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>

          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders"/>
          </Button>
        </div>
        <div className="reponsive_position">
          {/* <TooltipMEL title="Add Audio Brand" position="bottom"> */}
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Audio Brand
          </Button>
        </div>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  return (
    <>
      <div className="page-content">
        {/* <div className={loading ? `loading-container` : `d-none`}>
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" animationDuration=".5s" />
        </div> */}
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {

            if (selectedAudioBrands?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedAudioBrands([])
              setDeleteModal(false);
              setSelectAll(false)
              return setRowData(null);
            }

            if (rowData || selectedAudioBrands.length === 1) {
              handleDeleteUsers(rowData || selectedAudioBrands);
            } else if (selectedAudioBrands.length > 1) {
              for (let i = 0; i < selectedAudioBrands.length; i++) {
                handleDeleteUsers(selectedAudioBrands[i], selectedAudioBrands.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these audio brands?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedAudioBrands([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          value={audioBrand}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          // loadingIcon={<ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" animationDuration=".5s" />}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Audio Brands  {last} records of {totalRecords} in total"
          selection={selectedAudioBrands}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
