import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5Qrcode } from "html5-qrcode";
import { Dialog } from 'primereact/dialog';
import { getPartSkuWithLocation, getPostPartSku } from 'src/store/stockInspection/action';
import { useDispatch } from 'react-redux';
import Button from 'src/components/UI/Button';
import { useToast } from "src/components/Common/ReactToaster";
import { hasFranchiseRoleHandler } from 'src/helpers/common_helpers';
import { partSkuValidate } from 'src/store/actions';


interface ScannerPops {
  visible: boolean;
  onHide: () => void;
  unUsedSkus?: string[];
  scannedSkus?: string[];
  setScanParts?: any;
  isFloatingPage?: boolean;
  isSkuGeneratorPage?:boolean;
  scanParts?: any;
  values?: any;
  intentDetail?:any
}

const Scanner = ({ visible, onHide, unUsedSkus = [], scannedSkus = [], setScanParts, isFloatingPage = false, scanParts, values = null,intentDetail=null,isSkuGeneratorPage=false }: ScannerPops) => {

  const [qrCodeScanner, setQrCodeScanner] = useState<Html5QrcodeScanner | null>(null);
  const [prevScanned, setPrevScanned] = useState<string>("");
  const [paused, setPaused] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [scannedNumbers, setScannedNumbers] = useState<string[]>([]);
  const franchiseLogin = hasFranchiseRoleHandler()

  // const [inspectionValues, setInspectionValues] = useState<any>(null);
  // useEffect(() => {
  //   setInspectionValues(values)
  // }, [values])


  const clearScanner = () => {
    qrCodeScanner?.clear();
    onHide();
    // setQrCodeScanner(null);
  };

  useEffect(() => {
    if (paused) {
      setTimeout(() => {
        qrCodeScanner?.resume();
        setPaused(false)
      }, 2000);
    }
  }, [paused]);

  const resumeScanner = () => {
    qrCodeScanner?.resume();
    setPaused(false);
  };

  const scan = () => {

    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 400, height: 230, },supportedScanTypes:[0], showZoomSliderIfSupported: true, showTorchButtonIfSupported: true, defaultZoomValueIfSupported: 5, useBarCodeDetectorIfSupported: true, formatsToSupport: [0, 5] }, false);

    setQrCodeScanner(html5QrcodeScanner);

    const onScanSuccess = (decodedText: any, decodedResult: any) => {

      // if (unUsedSkus.length && !unUsedSkus.includes(decodedText)) {
      //   return showToast("SKU number not found", { type: "error" });
      // }
      if (isFloatingPage && !isSkuGeneratorPage && decodedText && !scanParts.includes(decodedText)) {
        if(!hasFranchiseRoleHandler()){

          dispatch(partSkuValidate({partId:intentDetail?.partId,skuNum:decodedText}))
        }else {
          setScanParts([...scanParts,{number:decodedText}])
        }
      }
      if(isSkuGeneratorPage){
        if (scanParts?.includes(decodedText)) {
          showToast("SKU already scanned", { type: "error" });
        }else{

          setScanParts([...scanParts,decodedText])
        }
      }
      if (!isFloatingPage && !isSkuGeneratorPage && decodedText) {
        
        setScannedNumbers((prevState: string[]) => {
          if (prevState?.includes(decodedText)) {
            showToast("SKU already scanned", { type: "error" });
            return [...prevState];
          } else {
            values = { ...values, scanSku: decodedText };

            let modifiedData = {}
            if (franchiseLogin) {
              modifiedData = { ...values, stockLocationId: 0 }
            }

            dispatch(getPartSkuWithLocation(franchiseLogin ? modifiedData : values));
          }
          return [...Array.from(new Set([...prevState, decodedText]))];
        });

        // dispatch(getPostPartSku(decodedText));
        values = { ...values, scanSku: decodedText };
        console.log(values);
        // if (scannedNumbers?.includes(decodedText)) {
        //   return showToast("SKU already scanned", { type: "error" })
        // } else {
        //   dispatch(getPartSkuWithLocation(values));
        // }
      }

      if (html5QrcodeScanner) {
        html5QrcodeScanner.pause();
      }

      if (html5QrcodeScanner.getState() === 3) {
        setPaused(true);
      }
    };

    const onScanFailure = (error: any) => {
      // console.warn(`Code scan error = ${error}`);
    };


    html5QrcodeScanner.render(onScanSuccess, onScanFailure);


    // let state = html5QrcodeScanner.getState();
    // console.log(state)

    // Html5Qrcode.getCameras().then(devices => {
    //   console.log("devices devices devices ", devices)
    //   /**
    //    * devices would be an array of objects of type:
    //    * { id: "id", label: "label" }
    //    */
    //   if (devices && devices.length) {
    //     var cameraId = devices[0].id;

    //     const html5QrCode = new Html5Qrcode(/* element id */ "reader");
    //     html5QrCode.start(
    //       cameraId,
    //       {
    //         fps: 10,    // Optional, frame per seconds for qr code scanning
    //         qrbox: { width: 400, height: 200 }  // Optional, if you want bounded box UI
    //       },
    //       (decodedText, decodedResult) => {
    //         console.log("decodedText decodedText decodedText ", decodedText)   
    //         console.log("decodedResult decodedResult decodedResult ", decodedResult)   
    //         // do something when code is read
    //       },
    //       (errorMessage) => {
    //         // parse error, ignore it.
    //       })
    //       .catch((err) => {
    //         // Start failed, handle it.
    //       });
    //     // .. use this to start scanning.
    //   }

    // }).catch(err => {
    //   // handle err
    // });
  };

  // const scan = () => {

  //   const html5QrcodeScanner = new Html5QrcodeScanner(
  //     "reader",
  //     { fps: 10, qrbox: { width: 400, height: 230, }, showZoomSliderIfSupported: true, showTorchButtonIfSupported: true, defaultZoomValueIfSupported: 5, useBarCodeDetectorIfSupported: true, formatsToSupport: [0, 5] }, false);

  //   setQrCodeScanner(html5QrcodeScanner);

  //   const onScanSuccess = (decodedText: any, decodedResult: any) => {
  //     console.log(`Code matched = ${decodedText}`, decodedResult);
  //     if (unUsedSkus.length && !unUsedSkus.includes(decodedText)) {
  //       return showToast("SKU number not found", { type: "error" });
  //     }
  //     if (decodedText) {
  //       dispatch(getPostPartSku(decodedText));
  //     }

  //     html5QrcodeScanner.pause();
  //     if (html5QrcodeScanner.getState() === 3) {
  //       setPaused(true);
  //     }
  //   };

  //   const onScanFailure = (error: any) => {
  //     console.warn(`Code scan error = ${error}`);
  //   };


  //   html5QrcodeScanner.render(onScanSuccess, onScanFailure);


  //   // let state = html5QrcodeScanner.getState();
  //   // console.log(state)

  //   // Html5Qrcode.getCameras().then(devices => {
  //   //   console.log("devices devices devices ", devices)
  //   //   /**
  //   //    * devices would be an array of objects of type:
  //   //    * { id: "id", label: "label" }
  //   //    */
  //   //   if (devices && devices.length) {
  //   //     var cameraId = devices[0].id;

  //   //     const html5QrCode = new Html5Qrcode(/* element id */ "reader");
  //   //     html5QrCode.start(
  //   //       cameraId,
  //   //       {
  //   //         fps: 10,    // Optional, frame per seconds for qr code scanning
  //   //         qrbox: { width: 400, height: 200 }  // Optional, if you want bounded box UI
  //   //       },
  //   //       (decodedText, decodedResult) => {
  //   //         console.log("decodedText decodedText decodedText ", decodedText)   
  //   //         console.log("decodedResult decodedResult decodedResult ", decodedResult)   
  //   //         // do something when code is read
  //   //       },
  //   //       (errorMessage) => {
  //   //         // parse error, ignore it.
  //   //       })
  //   //       .catch((err) => {
  //   //         // Start failed, handle it.
  //   //       });
  //   //     // .. use this to start scanning.
  //   //   }

  //   // }).catch(err => {
  //   //   // handle err
  //   // });
  // };

  return (
    <div>
      {visible && <React.Fragment>
        <Dialog header="Scan Sku here" visible={visible} maximizable onHide={clearScanner} onShow={scan} style={{ width: '33%' }} >
          <div style={{ display: 'grid', placeItems: 'center' }} className={paused ? 'scannerContainer' : ''}>
            <div id="reader" style={{ width: '30vw', height: 'auto', textAlign: 'center' }} ></div>
            <div className='d-flex justify-content-center'></div>
            {/* {paused && <Button className="btn-primary me-2 resumeBtn" label="Resume scanner" type="submit" onClick={resumeScanner} />} */}
          </div>
        </Dialog>
      </React.Fragment>}

    </div>
  );
};

export default Scanner;
