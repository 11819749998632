import { BulkEmailInitialState } from "src/models/pages/BulkEmailModel";
import { messages } from "src/common/data";
import { BulkSmsTypes } from "./actionTypes";
import { BulkSmsInitialState } from "src/models/pages/BulkSmsModel";




const initialState: BulkSmsInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  BulkSmsList: [],
  totalRecords: 0,
  employeeNum:"",
  dealerNum:"",
  customerNum:"",
  franchiseNum:"",
  supplierNum:"",
  bulkSmsDetails:"",
  statusList:[]
};



const BulkSms = (state = initialState, action: any) => {
  switch (action.type) {
    case BulkSmsTypes.GET_BULK_SMS_LIST_LOADING:
    case BulkSmsTypes.CREATE_BULK_SMS_LOADING:
    case BulkSmsTypes.DELETE_BULK_SMS_LOADING:
    case BulkSmsTypes.GET_BY_ID_BULK_SMS_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message: "",
        error: ""
      };


    case BulkSmsTypes.GET_BULK_SMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        BulkSmsList:action.payload.data.items,
        totalRecords : action.payload.data.totalCount,
        paginatorCount:action.payload.data.items.length
      };
    case BulkSmsTypes.GET_BY_ID_BULK_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkSmsDetails:action.payload.data,
      };
    case BulkSmsTypes.GET_EMPLOYEE_NUM_SUCCESS:
      console.log(action.payload.data);
      
      return {
        ...state,
        loading: false,        
        employeeNum: action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };
    case BulkSmsTypes.GET_DEALER_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };

    case BulkSmsTypes.GET_CUSTOMER_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        customerNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };

    case BulkSmsTypes.GET_FRANCHISE_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        franchiseNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };

    case BulkSmsTypes.GET_SUPPLIER_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.mobileNumber}))
      };
    case BulkSmsTypes.CREATE_BULK_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Sms Posted Successfully"
      };
    case BulkSmsTypes.GET_BULK_SMS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusList:action.payload.data
      };
    case BulkSmsTypes.DELETE_BULK_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Sms Sending Cancelled Successfully"
      };
    case BulkSmsTypes.CLEAR_BULK_SMS_MESSAGE:
      return {
        ...state,
        loading: false,
        error:"",
        message:""
      };

    case BulkSmsTypes.GET_BULK_SMS_LIST_ERROR:
    case BulkSmsTypes.CREATE_BULK_SMS_ERROR:
    case BulkSmsTypes.DELETE_BULK_SMS_ERROR:
    case BulkSmsTypes.GET_BY_ID_BULK_SMS_ERROR:
    case BulkSmsTypes.GET_BULK_SMS_STATUS_ERROR:
    case BulkSmsTypes.GET_EMPLOYEE_NUM_ERROR:
    case BulkSmsTypes.GET_DEALER_NUM_ERROR:
    case BulkSmsTypes.GET_CUSTOMER_NUM_ERROR:
    case BulkSmsTypes.GET_FRANCHISE_NUM_ERROR:
    case BulkSmsTypes.GET_SUPPLIER_NUM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default BulkSms;
