import { courierReducerState } from "src/models/pages/couriersModel";
import { courierActionsTypes } from "./actionTypes";


const initialState: courierReducerState = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  courierList: {
    items: [],
    totalCount: 0
  },
  courierInformation: null,
  dealerList: null,
  companyList: null,
  contactDetailsList: [],
  contactSetFieldValue: () => { },
  senderType: "",
  currentSender: "",
  receivercontactDetailsList: [],
  receivercontactSetFieldValue: () => { },
  receiverType: "",
  currentReceiver: "",
  melEmployeeAddress: null,
  melEmplyeesetFieldValue: () => { },
  currentTypeSelected: "",
  courierTrackingStatus: null
}


const courierReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case courierActionsTypes.GET_COURIERS_LIST_LOADING:
    case courierActionsTypes.VIEW_COURIER_LOADING:
    case courierActionsTypes.CREATE_COURIER_LOADING:
    case courierActionsTypes.GETBY_ID_COURIER_LOADING:
    case courierActionsTypes.UPDATE_COURIER_LOADING:
    case courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_LOADING:
    case courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_LOADING:
    case courierActionsTypes.GET_MEl_ADDRESS_LOADING:
    case courierActionsTypes.DOWNLOAD_PDF_COURIER_LOADING:
    case courierActionsTypes.COURIER_TRACKING_STATUS_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        error: "",
        message: ""
      }

    case courierActionsTypes.GET_COURIERS_LIST_SUCCESS:

      return {
        ...state,
        loading: false,
        courierList: {
          ...state.courierList,
          items: action.payload.data.items,
          totalCount: action.payload.data.totalCount
        }
      }

    case courierActionsTypes.CREATE_COURIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Courier Created Successfully"
      }

    case courierActionsTypes.UPDATE_COURIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Courier Updated Successfully."
      }

    case courierActionsTypes.VIEW_COURIER_SUCCESS:
      return {
        ...state,
        loading: false,
        courierInformation: action.payload.data
      }

    case courierActionsTypes.GETBY_ID_COURIER_SUCCESS:
      return {
        ...state,
        loading: false,
        courierInformation: action.payload.data
      }

    case courierActionsTypes.CLEAR_COURIER_STATE_MESSAGE:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      }
    case courierActionsTypes.CLEAR_COURIER_DETAILS:
      return {
        ...state,
        courierInformation: null,
        courierTrackingStatus : null
      }
    case courierActionsTypes.GET_DEALER_DROPDOWN_LOADING_SUCCESS:
      return {
        ...state,
        dealerList: action.payload.data.userList,
        companyList: action.payload.data.courierList?.filter((data: any) => data.name != "Gati")
      }

    case courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        contactSetFieldValue: action.payload.setFieldValue,
        senderType: action.payload.currentSenderType,
        currentSender: action.payload.selectedVaue,
        contactDetailsList: action.payload?.response?.data?.contactDetails
      }

    case courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        receivercontactSetFieldValue: action.payload.setFieldValue,
        receiverType: action.payload.currentReceiverType,
        currentReceiver: action.payload.selectedVaue,
        receivercontactDetailsList: action.payload?.response?.data?.contactDetails
      }

    case courierActionsTypes.RESET_SENDER_DETAILS:
      return {
        ...state,
        loading: false,
        senderType: "",
        currentSender: "",
        contactDetailsList: []
      }

    case courierActionsTypes.GET_MEl_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        melEmployeeAddress: action.payload.resp.data,
        melEmplyeesetFieldValue: action.payload.setFieldValue,
        currentTypeSelected: action.payload.currentType
      }

    case courierActionsTypes.RESET_MEL_EMPLOYEE_ADDRESS:
      return {
        ...state,
        loading: false,
        melEmployeeAddress: null,
        currentTypeSelected: ""
      }

    case courierActionsTypes.DOWNLOAD_PDF_COURIER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: ""
      }

    case courierActionsTypes.COURIER_TRACKING_STATUS_SUCCESS:
      debugger
      return {
        ...state,
        loading: false,
        courierTrackingStatus: action.payload.data
      }


    case courierActionsTypes.RESET_COURIER_STATE:
      return { ...initialState }

    case courierActionsTypes.GET_COURIERS_LIST_ERROR:
    case courierActionsTypes.VIEW_COURIER_ERROR:
    case courierActionsTypes.CREATE_COURIER_ERROR:
    case courierActionsTypes.GETBY_ID_COURIER_ERROR:
    case courierActionsTypes.UPDATE_COURIER_ERROR:
    case courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_ERROR:
    case courierActionsTypes.GET_CONTACT_DETAILS_BYUSERID_RECEIVER_ERROR:
    case courierActionsTypes.GET_MEl_ADDRESS_ERROR:
    case courierActionsTypes.DOWNLOAD_PDF_COURIER_ERROR:
    case courierActionsTypes.GET_DEALER_DROPDOWN_LOADING_ERROR:
    case courierActionsTypes.COURIER_TRACKING_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: ""
      }
    default:
      return {
        ...state
      }
  }
}


export default courierReducer;