import React, { useEffect, useRef, useState } from 'react'
import { Button, Row } from 'reactstrap'
import DataTableWrapper from 'src/components/Grid/DataTableWrapper'
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel'
import avator from "../../assets/images/avatar.jpg"
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction'
import "./Ticketsystem.scss"
import FeatherIcon from "feather-icons-react";
import { useNavigate } from 'react-router'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { getEnquiryListPageLoading, getTicketCreateCustomerDataLoading, reOpenTicketFromCustomerLoading } from 'src/store/actions'
import { DataTableFilterMeta } from 'primereact/datatable'
import { EnquiryItems } from 'src/models/pages/ticketSystemModel'
import { formatDateMEL, formatDateTimeMEL, hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler, saveAsExcelFile } from 'src/helpers/common_helpers'
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon'
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon'
import { useToast } from 'src/components/Common/ReactToaster'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile'
import TooltipMEL from 'src/components/UI/Tooltip'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel'
import { API } from 'src/util/api'


const ticketListConverson = (values: EnquiryItems[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['subject'] = eachValue?.subject;
    data['description'] = eachValue?.description;
    data['category'] = eachValue?.category;
    // data['follow'] = eachValue?.id;
    data['priority'] = eachValue?.priority;
    data['status'] = eachValue?.status;
    data['createdOn'] = eachValue?.creationTime;
    data['createdBy'] = eachValue?.createdByName;

    return data;
  });
  return modifeidValue;
};

const TicketSystemListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { enquiryList, error, loading, totalCount, message, tickerCreaters } = useSelector((state: RootReducerState) => state.ticketSystemReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const modifiedValues: any[] = ticketListConverson(enquiryList);
  const [selectedStatus, setselectedStatus] = useState("ALL");

  const filterDetails: DataTableFilterMeta = {
    SupplierId: { value: "", matchMode: "contains" },
    PoNumber: { value: "", matchMode: "contains" },
    generatedDate: { value: "", matchMode: "contains" },
    GeneratedStartDate: { value: "", matchMode: "contains" },
    GeneratedEndDate: { value: "", matchMode: "contains" },
    BuyingCurrency: { value: "", matchMode: "contains" },
    Locked: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
    CustomerId:{value:"",matchMode:"contains"}
  };
  const [customerId , setCustomerId]=useState("");
  useEffect(()=>{
    if(hasCustomerRoleHandler()){
      let data:any=localStorage.getItem('authUser')
      let parsed = JSON.parse(data)
      setCustomerId(parsed.uid)
    }
  },[])
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const handleEdit = (rowData: any) => {
    navigate(ROUTER.EDIT_TICKET_SYSTEM + '/' + rowData.id)
  }

  const handleView = (rowData: any) => {
    navigate(ROUTER.VIEW_TICKET_SYSTEM + '/' + rowData.id)
  }

  const reOpenTicketHandle = (id:any) => {
    
    dispatch(reOpenTicketFromCustomerLoading(id))

    if(hasCustomerRoleHandler()){
      if(customerId){
      let lazy ={ ...lazyState,
        filters: {
          ...lazyState.filters,
          CustomerId:{value:customerId,matchMode:"contains"},
        },}
        dispatch(getEnquiryListPageLoading(lazy));
      }
    }else{
      dispatch(getEnquiryListPageLoading(lazyState));
    }
  }
  const actionBodyTemplate = (rowData: any, value: string) => {
    if(rowData.status != "Closed"){
    return (
      <ColumnAction
        displayData={rowData["category"]}
        isEdit={true}
        onEditClick={() => handleEdit(rowData)}
        isView={true}
        onViewClick={() => handleView(rowData)}
      />

    )
  }else{
    return (
      <ColumnAction
        displayData={rowData["category"]}
        isView={true}
        isReopen={hasCustomerRoleHandler() ? true : false}
        reopenClick={()=>reOpenTicketHandle(rowData.id)}
        onViewClick={() => handleView(rowData)}
      />

    )
  }
  }

  const stateTemplate = (rowData: any, value: any) => {
    return (
      <div className='status'>
        <span className={rowData.state ? 'statusHighlight' : ""}>{rowData.state}</span>
      </div>
    )
  }

  const priorityTemplate = (rowData: any, value: any) => {
    return (
      <div>
        {
          (rowData.priority == "Medium" || rowData.priority == "medium") ? <span className="MediumStatus">{rowData.priority}</span> : (rowData.priority == "High" || rowData.priority == "high") ? <span className="highStatus">{rowData.priority}</span> : rowData.priority == "urgent" ? <span className="urgentStatus">{rowData.priority}</span> : <span className="low">{rowData.priority}</span>
        }
      </div>
    )
  }

  const statusTemplate = (rowData: any) => {
    return (
      <div>
        {
          rowData.status == "New" ? <span className="open">{rowData.status}</span> : rowData.status == "pending" ? <span className="pending">{rowData.status}</span> : rowData.status == "resolved" ? <span className="resolve">{rowData.status}</span> : rowData.status == "Reopen" ? <span className="reopen">{rowData.status}</span> : rowData.status == "Reassign" ? <span className="reassign">{rowData.status}</span> : <span className="stclose">{rowData.status}</span>
        }
      </div>
    )
  }

  const getcreaterList = (createdId: any) => {
    const customer = tickerCreaters.filter((data) => data.userId == createdId);
    return (
      <div>{customer[0]?.name}</div>
    )
  }
  const columns: Columns[] = [
    {
      field: "category",
      sortable: false,
      header: "Category",
      filter: false,
      showFilterMenu: false,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
      body: (rowData) => actionBodyTemplate(rowData, "category"),
    },
    {
      field: "subject",
      sortable: true,
      header: "Subject",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "description",
      sortable: true,
      header: "Description",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "priority",
      sortable: true,
      header: "Priority",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      body: (rowData) => priorityTemplate(rowData, "priority"),
      isStatic: true,
    },
    {
      field: "status",
      sortable: true,
      header: "Status",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      body: (rowData) => statusTemplate(rowData),
      isStatic: true,
    },
    {
      field: "createdOn",
      sortable: false,
      header: "Created On",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => formatDateTimeMEL(rowData.createdOn),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "createdBy",
      sortable: false,
      header: "Created By",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
  ];

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true)
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      if(hasCustomerRoleHandler()){
        if(customerId){
        let lazy ={ ...lazyState,
          filters: {
            ...lazyState.filters,
            CustomerId:{value:customerId,matchMode:"contains"},
          },}
          dispatch(getEnquiryListPageLoading(lazy));
        }
      }else{
        dispatch(getEnquiryListPageLoading(lazyState));
      }
    }
  }, [error, message])

  // useEffect(() => {
  //   dispatch(getEnquiryListPageLoading(lazyState))
  // }, [])

  const onCreateClickHandler = () => {
    navigate(ROUTER.CREATE_TICKET_SYSTEM);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {

    setLazyState(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const callbackFunction = (lazyState: LazyTableState) => {

    if(hasCustomerRoleHandler()){
      if(customerId){
      let lazy ={ ...lazyState,
        filters: {
          ...lazyState.filters,
          CustomerId:{value:customerId,matchMode:"contains"},
        },}
        dispatch(getEnquiryListPageLoading(lazy));
      }
    }else{
      dispatch(getEnquiryListPageLoading(lazyState));
    }
  };
  useEffect(() => {
    callbackFunction(lazyState);
    dispatch(getTicketCreateCustomerDataLoading())
  }, [lazyState,customerId]);
  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((val) => {
      let data: any = {}
      data['Subject'] = val.subject;
      data['category'] = val.category;
      data['Description'] = val.description;
      data['Priority'] = val?.priority;
      data['Status'] = val?.status;
      data['Created On'] = formatDateTimeMEL(val?.createdOn);
      // data['Created By'] = val?.createdBy;
      return data;
    })
    return modifiedColumnDetails
  }

  const saveDataAsExcel = (values: any[], fileName: string) => {
    let modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    // let modifiedColumnDetails = changeColumnDetails(values)
    const destructureedData = values.map((data) => {
      let datas = {
        category: data.category,
        subject: data.subject,
        description: data.description,
        priority: data?.priority,
        status: data?.status,
        createdOn: formatDateTimeMEL(data?.createdOn),
        createdBy: data?.createdBy,
      }
      return datas
    })
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },
        }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let lazy;
  if(hasCustomerRoleHandler()){
      if(customerId){
      lazy ={ ...lazyState,
        rows:totalCount>5000 ? 5000 : totalCount<1 ? 1 : totalCount,
        first:0,
        filters: {
          ...lazyState.filters,
          CustomerId:{value:customerId,matchMode:"contains"},
        },}
        
      }
    }else{
      lazy={...lazyState,  rows:totalCount>5000 ? 5000 : totalCount<1 ? 1 : totalCount,first:0,}
    }
	
    try {
      setPdfLoading(true)
      const response = await API.getEnquiryListApi(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue:any) => {
        let data: any = {};
        data['id'] = eachValue?.id;
        data['subject'] = eachValue?.subject;
        data['description'] = eachValue?.description;
        data['category'] = eachValue?.category;
        // data['follow'] = eachValue?.id;
        data['priority'] = eachValue?.priority;
        data['status'] = eachValue?.status;
        data['createdOn'] = eachValue?.creationTime;
        data['createdBy'] = eachValue?.createdByName;
    
        return data;
      });
      // let resp=await ticketListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Ticket_System");
     }else{
      saveDataAsExcel(modifeidValue, "Ticket_System");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "Ticket_System");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "Ticket_System");
    fetchData(true)
  };
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
  
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          customerId: { value: formValue, matchMode: "contains" },
        },
      }));
    setIsTooltipVisible(false)
  };
const [customerName,setCustomerName]=useState("")
  const userOptions:any=tickerCreaters?.map((item:any)=>({
    label:item.name,
    value:item.userId
  })).sort((a:any, b:any) => {
    const labelA =a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  })
  const calendarRef = useRef<any>(null);
  const clearCustomCalendar = (e:any) => {
    calendarRef.current.toggle(e)
    setCustomerId("")
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        customerId: { value: "" , matchMode: "contains" },
      },
    }));
    setCustomerName("")
}

const searchCustomCalendar = (event: any) => {
  calendarRef.current.toggle(event)
  setLazyState((state) => ({
    ...state,
    filters: {
      ...state.filters,
      customerId: { value: customerId, matchMode: "contains" },
    },
  }));
  if(customerId){
let customername=userOptions?.find((item:any)=>item.value==customerId).label
setCustomerName(customername)
  }
}
const clearFilter=()=>{
  if(!hasCustomerRoleHandler()){

    setCustomerId("")
    setLazyState((state) => ({
      ...initialLazyState,
      filters: {
        ...state.filters,
        customerId: { value: "" , matchMode: "contains" },
      },
    }));
    setCustomerName("")
  }
  else{
    setLazyState(initialLazyState)
  }
}
  const header =
    <div className="table-header-container">
      <div className="label_margin">
        Ticketing System
      </div>
      {
        hasAdminOrEmployeeRoleHandler() &&
      
      <div id="calendar" className="calendar_btns" style={{marginLeft:"10px"}}>
          <div className="table-header-container-item">
                  <div className="btn-group">
                      <button
                          className="btn btn-soft-primary waves-effect waves-light"
                          // onClick={toggleCalendar}
                          onClick={(e) => calendarRef.current.toggle(e)}
                          >
                          {/* <i className="bx bx-calendar font-size-16 align-middle"></i> */}
                          <FeatherIcon icon="search" style={{Width:"6px"}}/>
                      </button>
                  </div>
          </div>
      </div>
      }
      <OverlayPanel ref={calendarRef} style={{width:"200px"}}>

        <div className="mb-3">
       <label htmlFor="">Select Customer</label>
        <Dropdown value={customerId} onChange={(e) => setCustomerId(e.value)} options={userOptions} optionLabel="label" placeholder="Select Customer"
            filter className="w-full md:w-14rem" /> 
         </div>


            <div className="p-column-filter-buttonbar">
                            <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
                                <span className="p-button-label p-c">Clear</span>
                            </button>
                            <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
                                <span className="p-button-label p-c">Apply</span>
                            </button>
                        </div>
            </OverlayPanel>
            {hasAdminOrEmployeeRoleHandler() && customerId &&
            <div className="table-header-container-item table-header-container-item-special">
      <b style={{marginLeft:"10px"}}>{customerName}</b>
      </div>
}
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item export_btns">
        <div style={{ display: "flex" }}>
          
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        </div>

        {
          hasCustomerRoleHandler() && <div className="reponsive_positio">
            <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
              <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
              Create Ticket
            </Button>
          </div>
        }

      </div>
    </div>;


  const hidePopup = () => {
    setVisible(false);
    // dispatch(ReserEmployeeDetails());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content" id="ticket-system">
        {visible && dialogContainer(error)}
        <DataTableWrapper
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          columnDetails={columns}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          // loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Ticketing System  {last} records of {totalRecords} in total"
          // selection={selectedUsers}
          // onSelectionChange={onSelectionChange}
          // selectAll={selectAll}
          // onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  )
}

export default TicketSystemListPage